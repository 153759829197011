import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { ChildrenElement, ChildrenElementEntityState } from '@api/api-interfaces';
import { ChildrenElementApiService } from '@wip/store/api-services';
import { ChildrenElementGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeChildrenElement } from '@wip/store/configs/normalization';
import { ChildrenElementSelectors } from '@wip/store/selectors';
import { ChildrenElementRelationsIds } from '@wip/store/ids-interfaces';
import { ElementGeneratedActions } from '@wip/store/actions';

export function getDefaultAddChildrenElementActions(
  childrenElement: ChildrenElementEntityState,
  ids?: ChildrenElementRelationsIds
): Action[] {
  const actions: Action[] = [
    ChildrenElementGeneratedActions.normalizeManyChildrenElementsAfterUpsert({ childrenElements: [childrenElement] })
  ];

  if (ids?.element) {
    actions.push(
      ElementGeneratedActions.addManyChildrenElementSuccess({
        idElement: ids.element,
        idChildrenElements: [childrenElement.idChildrenElement]
      })
    );
    actions.push(
      ChildrenElementGeneratedActions.addElementSuccess({
        idChildrenElement: childrenElement.idChildrenElement,
        idElement: ids.element
      })
    );
  }

  return actions;
}

export function getDefaultDeleteChildrenElementActions(childrenElement: ChildrenElementEntityState): Action[] {
  const actions: Action[] = [
    ChildrenElementGeneratedActions.deleteOneChildrenElementSuccess({
      idChildrenElement: childrenElement.idChildrenElement
    })
  ];

  if (childrenElement.element) {
    actions.push(
      ElementGeneratedActions.deleteManyChildrenElementSuccess({
        idChildrenElements: [childrenElement.idChildrenElement],
        idElements: [childrenElement.element as number]
      })
    );
  }

  return actions;
}

export class GeneratedChildrenElementEffects {
  constructor(
    protected actions$: Actions,
    protected childrenElementApiService: ChildrenElementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyChildrenElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChildrenElementGeneratedActions.getManyChildrenElements),
      switchMap(({ params }) =>
        this.childrenElementApiService.getChildrenElements(params).pipe(
          map((childrenElements: ChildrenElement[]) => {
            return ChildrenElementGeneratedActions.normalizeManyChildrenElementsAfterUpsert({ childrenElements });
          }),
          catchError(error => of(ChildrenElementGeneratedActions.childrenElementsFailure({ error })))
        )
      )
    );
  });

  getOneChildrenElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChildrenElementGeneratedActions.getOneChildrenElement),
      switchMap(idChildrenElement =>
        this.childrenElementApiService.getChildrenElement(idChildrenElement).pipe(
          map((childrenElement: ChildrenElement) => {
            return ChildrenElementGeneratedActions.normalizeManyChildrenElementsAfterUpsert({
              childrenElements: [childrenElement]
            });
          }),
          catchError(error => of(ChildrenElementGeneratedActions.childrenElementsFailure({ error })))
        )
      )
    );
  });

  upsertOneChildrenElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChildrenElementGeneratedActions.upsertOneChildrenElement),
      concatMap(
        ({
          childrenElement,
          ids
        }: {
          childrenElement: Partial<ChildrenElement>;
          ids?: ChildrenElementRelationsIds;
        }) => {
          if (childrenElement.idChildrenElement) {
            return this.childrenElementApiService.updateChildrenElement(childrenElement).pipe(
              map((childrenElementReturned: ChildrenElement) => {
                return ChildrenElementGeneratedActions.normalizeManyChildrenElementsAfterUpsert({
                  childrenElements: [childrenElementReturned]
                });
              }),
              catchError(error => of(ChildrenElementGeneratedActions.childrenElementsFailure({ error })))
            );
          } else {
            return this.childrenElementApiService.addChildrenElement(childrenElement).pipe(
              mergeMap((childrenElementReturned: ChildrenElement) =>
                getDefaultAddChildrenElementActions(childrenElementReturned, ids)
              ),
              catchError(error => of(ChildrenElementGeneratedActions.childrenElementsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneChildrenElement$ = createEffect(() => {
    const selectChildrenElementState$ = this.store$.select(ChildrenElementSelectors.selectChildrenElementState);
    return this.actions$.pipe(
      ofType(ChildrenElementGeneratedActions.deleteOneChildrenElement),
      withLatestFrom(selectChildrenElementState$),
      concatMap(([{ idChildrenElement }, state]) =>
        this.childrenElementApiService.deleteChildrenElement(idChildrenElement).pipe(
          mergeMap(_success =>
            getDefaultDeleteChildrenElementActions(state.entities[idChildrenElement] as ChildrenElementEntityState)
          ),
          catchError(error => of(ChildrenElementGeneratedActions.childrenElementsFailure({ error })))
        )
      )
    );
  });

  normalizeManyChildrenElementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChildrenElementGeneratedActions.normalizeManyChildrenElementsAfterUpsert),
      concatMap(({ childrenElements }) => {
        const actions: Action[] = getActionsToNormalizeChildrenElement(childrenElements, StoreActionType.upsert);
        return [getMultiAction(actions, '[ChildrenElement] Normalization After Upsert Success')];
      })
    );
  });
}
