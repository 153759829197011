import { CommunityModuleState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityModuleReducersGeneratedFunctions } from './community-module-generated.reducer';

const communityModuleReducersFunctions = [...communityModuleReducersGeneratedFunctions];

const communityModuleReducer = createReducer(CommunityModuleState.initialState, ...communityModuleReducersFunctions);

export function reducer(state: CommunityModuleState.IState | undefined, action: Action) {
  return communityModuleReducer(state, action);
}
