import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Subject } from 'rxjs';
import { ChartDatasService } from '../chart-services/chart-datas.service';
import { CommunityCaracteristic, OrganizationMilestone } from '@api/api-interfaces';

@Component({
  selector: 'app-chart-display',
  templateUrl: './chart-display.component.html',
  styleUrls: ['./chart-display.component.scss']
})
export class ChartDisplayComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() projectCharacteristicSelected: CommunityCaracteristic;
  @Input() projectMilestonesSelected: OrganizationMilestone[];
  @Input() chartData;
  @ViewChild('characteristicChartByMilestone')
  private characteristicChartByMilestone: ElementRef<HTMLCanvasElement>;

  private destroy$: Subject<void> = new Subject();
  public chart: Chart = null;

  constructor(private chartDatasService: ChartDatasService) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData && this.chartData) {
      setTimeout(() => {
        if (this.chart === null) {
          this.chart = new Chart(this.characteristicChartByMilestone?.nativeElement, {
            data: null,
            type: 'bar',
            plugins: [ChartDataLabels]
          });
        }
        this.chart.options = this.chartDatasService.getChartOptions(this.projectCharacteristicSelected);
        this.chart.data = this.chartDatasService.getChartDatas(this.chartData, this.projectMilestonesSelected);
        this.chart.update();
      }, 100);
    }
  }

  ngAfterViewInit() {
    const defaultFontFamily = `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !default`;
    const defaultFontSize = 14;

    Chart.register(...registerables);
    Chart.defaults.font.family = defaultFontFamily;
    Chart.defaults.font.size = defaultFontSize;
  }
}
