import { ConfirmationChoicesDialogContainerComponent } from '@wip/dialogs/confirmation-choices-dialog';
import { InformationDialogContainerComponent } from '@wip/dialogs/information-dialog';
import { PreviewImageDialogComponent } from '@_shared/dialogs/preview-image-dialog/preview-image-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ElementTypeElement } from '@enums';
import { Element, FolderElement, TimelineElement } from '@api/api-interfaces';
import { DocumentErrors } from '@api/old-api-services';
import { ElementService, FolderElementService } from '@wip/store/services';

@Injectable()
export class DocumentService {
  extension;
  pictures: TimelineElement[] = [];
  indexPicture = 0;

  constructor(
    private newElementService: ElementService,
    private dialog: MatDialog,
    private folderElementService: FolderElementService
  ) {}

  /**
   * @description method that will open and handle the preview actions
   * @param document the timeline element containing the details
   */
  preview(document: Element, documents?: Element[], _element?) {
    const filteredDocuments = documents?.filter(doc => this.isPDF(doc.extension) || this.isPicture(doc.extension));
    return this.dialog.open(PreviewImageDialogComponent, {
      data: {
        isPicture: this.isPicture(document.extension),
        isPDF: this.isPDF(document.extension),
        document: document,
        documents: filteredDocuments || []
      },
      height: '95vh',
      width: '110vw',
      panelClass: ['custom-dialog-fullscreen-xs']
    });
  }

  previewFolder(document: Element, documents?: Element[], _element?) {
    const filteredDocuments = documents.filter(doc => this.isPDF(doc.extension) || this.isPicture(doc.extension));

    this.dialog.open(PreviewImageDialogComponent, {
      data: {
        isPicture: this.isPicture(document.extension),
        isPDF: this.isPDF(document.extension),
        document: document,
        documents: filteredDocuments || []
      },
      height: '95vh',
      width: '100vw',
      panelClass: ['custom-dialog-fullscreen-xs']
    });
  }

  async downloadFileFolder(folderElement: FolderElement) {
    if (folderElement.element.typeElement === ElementTypeElement.elementDocument) {
      this.folderElementService.downloadFile(
        folderElement.idFolderElement,
        folderElement.element.titre + folderElement.element.extension
      );
    }
  }

  async elementDownloadFile(element: Element) {
    if (element.typeElement === ElementTypeElement.elementDocument) {
      this.newElementService.downloadFile(element.idElement, element.titre + element.extension);
    }
  }

  handleDelete(folderElement: FolderElement) {
    const confirmationDialog = this.dialog.open(ConfirmationChoicesDialogContainerComponent, {
      data: {
        title: 'Suppression',
        body: 'Etes vous sûr de vouloir supprimer ce fichier ?'
      }
    });
    confirmationDialog.afterClosed().subscribe(res => {
      if (res) {
        this.folderElementService.deleteOneFolderElement(folderElement.idFolderElement);
      }
    });
  }

  getFileIcon(extension: string): { logoClass: string; colorClass: string } {
    const file = {
      logoClass: 'file-other',
      colorClass: ''
    };
    if (this.isMSWordLike(extension)) {
      file.logoClass = 'file-word';
      file.colorClass = '#07A7F5';
    } else if (this.isPicture(extension)) {
      file.logoClass = 'file-img';
      file.colorClass = '#13BA56';
    } else if (this.isPDF(extension)) {
      file.logoClass = 'file-pdf';
      file.colorClass = '#F46A62';
    } else if (this.isMSPPTLike(extension)) {
      file.logoClass = 'file-ppt';
      file.colorClass = '#FF9A09';
    } else if (this.isMSExcelLike(extension)) {
      file.logoClass = 'file-xls';
      file.colorClass = '#15AC6B';
    } else if (this.isAudio(extension)) {
      file.logoClass = 'file-music';
      file.colorClass = '#D7448E';
    } else if (this.isArchive(extension)) {
      file.logoClass = 'file-zip';
      file.colorClass = '#F6C27E';
    } else if (this.isText(extension)) {
      file.logoClass = 'file-txt';
    } else if (this.isVideo(extension)) {
      file.logoClass = 'file-video';
    }
    return file;
  }

  handleUploadError(err) {
    let errorMessage = '<b>' + "L'élément n'a pu être envoyé pour la/les raison(s) suivante(s) :" + '</b> <br/>';
    if (err && err.error && err.error.documentErrors) {
      err.error.documentErrors.forEach(error => {
        switch (error.code) {
          case DocumentErrors.FILE_EMPTY:
            errorMessage += 'Le fichier envoyé est vide.';
            break;
          case DocumentErrors.FILE_NAME_EMPTY:
            errorMessage += 'Le fichier envoyé ne possède pas de nom';
            break;
          case DocumentErrors.MAX_SIZE_EXCEEDED:
            errorMessage += 'Le fichier envoyé dépasse la limite fixée à 70Mo ';

            break;
          case DocumentErrors.NO_EXTENSION:
            errorMessage += "Le fichier envoyé ne possède pas d'extension";
            break;
        }
      });
    } else {
      errorMessage += 'Une erreur inconnue est survenue. Veuillez contacter le support.';
    }

    this.dialog.open(InformationDialogContainerComponent, {
      data: {
        body: errorMessage
      }
    });
  }

  private isMSWordLike(extension) {
    if (extension) {
      const listeExtension = ['.docx', '.doc', '.docm', '.dotm', '.odp'];
      return listeExtension.includes(extension.toLowerCase());
    } else {
      return false;
    }
  }

  public isPicture(extension) {
    if (extension) {
      const listeExtension = ['.jpg', '.jpeg', '.gif', '.png', '.bmp'];
      return listeExtension.includes(extension.toLowerCase());
    } else {
      return false;
    }
  }

  public isPDF(extension) {
    if (extension && extension.toLowerCase() === '.pdf') {
      return true;
    } else {
      return false;
    }
  }

  private isMSPPTLike(extension) {
    if (extension) {
      const listeExtension = [
        '.ppt',
        '.pptx',
        '.pptm',
        '.potx',
        '.potm',
        '.ppam',
        '.ppsx',
        '.ppsm',
        '.sldx',
        '.sldm',
        '.thmx'
      ];
      return listeExtension.includes(extension.toLowerCase());
    } else {
      return false;
    }
  }

  private isMSExcelLike(extension) {
    if (extension) {
      const listeExtension = ['.xls', '.xlsx', '.xlsm', '.xlts', '.xltm', '.xlsb', '.xlam', '.csv'];
      return listeExtension.includes(extension.toLowerCase());
    } else {
      return false;
    }
  }

  private isAudio(extension) {
    if (extension) {
      const listeExtension = ['.mp3', '.flac', '.wave', '.ogg', '.wma', '.asf'];
      return listeExtension.includes(extension.toLowerCase());
    } else {
      return false;
    }
  }

  private isVideo(extension) {
    if (extension) {
      const listeExtension = ['.avi', '.mp4', '.webm', '.wmw', '.mov'];
      return listeExtension.includes(extension.toLowerCase());
    } else {
      return false;
    }
  }

  private isArchive(extension) {
    if (extension) {
      const listeExtension = [
        '.zip',
        '.rar',
        '.zipx',
        '.7z',
        '.s7z',
        '.tar',
        '.tar.gz',
        '.tgz',
        '.tar.Z',
        '.tar.bz2',
        '.tbz2',
        '.tar.lzma',
        '.tlz'
      ];
      return listeExtension.includes(extension.toLowerCase());
    } else {
      return false;
    }
  }

  private isText(extension) {
    if (extension) {
      const listeExtension = ['.txt', '.srt', '.sub', '.spx', '.me', '.md'];
      return listeExtension.includes(extension.toLowerCase());
    } else {
      return false;
    }
  }
}
