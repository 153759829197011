import { Injectable } from '@angular/core';
import { TypeEnum } from '@enums';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommunityCaracteristic } from '@api/api-interfaces';
import * as communityCaracteristicActions from '@wip/store/actions';
import { CommunityCaracteristicApiService } from '@wip/store/api-services';
import { AppState } from '@wip/store/configs/reducers';
import { catchError, concatMap, map, of } from 'rxjs';
import { GeneratedCommunityCaracteristicEffects } from './community-caracteristic-generated.effects';

@Injectable()
export class CommunityCaracteristicEffects extends GeneratedCommunityCaracteristicEffects {
  constructor(
    actions$: Actions,
    communityCaracteristicApiService: CommunityCaracteristicApiService,
    store$: Store<AppState>
  ) {
    super(actions$, communityCaracteristicApiService, store$);
  }

  fillHandleCaracteristics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityCaracteristicActions.FillHandleCaracteristics),
      concatMap(({ communityCaracteristics }: { communityCaracteristics: Partial<CommunityCaracteristic>[] }) => {
        return this.communityCaracteristicApiService.fillHandleCaracteristics(communityCaracteristics).pipe(
          map((communityCaracteristicReturned: CommunityCaracteristic[]) => {
            return communityCaracteristicActions.CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert(
              {
                communityCaracteristics: communityCaracteristicReturned
              }
            );
          }),
          catchError(error =>
            of(
              communityCaracteristicActions.CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure({
                error
              })
            )
          )
        );
      })
    );
  });

  changeValueType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityCaracteristicActions.changeValueType),
      concatMap(
        ({ idOrganizationCaracteristic, valueType }: { idOrganizationCaracteristic: number; valueType: TypeEnum }) => {
          return this.communityCaracteristicApiService
            .changeValueType({
              idOrganizationCaracteristic,
              valueType
            })
            .pipe(
              map((communityCaracteristicReturned: CommunityCaracteristic[]) => {
                return communityCaracteristicActions.CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert(
                  {
                    communityCaracteristics: communityCaracteristicReturned
                  }
                );
              }),
              catchError(error =>
                of(
                  communityCaracteristicActions.CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure({
                    error
                  })
                )
              )
            );
        }
      )
    );
  });
}
