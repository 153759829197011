<div
  [matTooltip]="tooltipsText"
  (click)="openUserDetail($event)"
  class="avatar-place-holder"
  [ngStyle]="{
    'background-color': color,
    'border-color': color,
    'min-height': backgroundHeight,
    ' height': backgroundHeight,
    'min-width': backgroundWidth,
    'font-size': fontHeight,
    'line-height': backgroundHeight
  }"
>
  <div class="avatar-container">
    {{ initialsText }}
  </div>
</div>
