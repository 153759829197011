import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationCaracteristicRowGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicRowState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationCaracteristicRowEntityState } from '@api/api-interfaces';

export const organizationCaracteristicRowReducersGeneratedFunctions: ReducerTypes<
  OrganizationCaracteristicRowState.IState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationCaracteristicRowGeneratedActions.getOneOrganizationCaracteristicRow,
    (state: OrganizationCaracteristicRowState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicRowGeneratedActions.getManyOrganizationCaracteristicRows,
    (state: OrganizationCaracteristicRowState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicRowGeneratedActions.upsertOneOrganizationCaracteristicRow,
    (state: OrganizationCaracteristicRowState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationCaracteristicRowGeneratedActions.upsertManyOrganizationCaracteristicRowsSuccess,
    (state: OrganizationCaracteristicRowState.IState, { organizationCaracteristicRows }) =>
      OrganizationCaracteristicRowState.adapter.upsertMany(
        organizationCaracteristicRows,
        setLoadingsState(state, false)
      )
  ),
  on(
    OrganizationCaracteristicRowGeneratedActions.deleteOneOrganizationCaracteristicRow,
    (state: OrganizationCaracteristicRowState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicRowGeneratedActions.deleteOneOrganizationCaracteristicRowSuccess,
    (state: OrganizationCaracteristicRowState.IState, { idOrganizationCaracteristicRow }) =>
      OrganizationCaracteristicRowState.adapter.removeOne(
        idOrganizationCaracteristicRow,
        setLoadingsState(state, false)
      )
  ),
  on(
    OrganizationCaracteristicRowGeneratedActions.clearActivesOrganizationCaracteristicRows,
    (state: OrganizationCaracteristicRowState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationCaracteristicRowGeneratedActions.addManyActivesOrganizationCaracteristicRows,
    (state: OrganizationCaracteristicRowState.IState, { idOrganizationCaracteristicRows }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationCaracteristicRows)
    })
  ),
  on(
    OrganizationCaracteristicRowGeneratedActions.deleteOneActiveOrganizationCaracteristicRow,
    (state: OrganizationCaracteristicRowState.IState, { idOrganizationCaracteristicRow }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationCaracteristicRow)
    })
  ),

  on(
    OrganizationCaracteristicRowGeneratedActions.clearOrganizationCaracteristicRows,
    () => OrganizationCaracteristicRowState.initialState
  ),

  on(
    OrganizationCaracteristicRowGeneratedActions.addManyOrganizationCaracteristicValueSuccess,
    (
      state: OrganizationCaracteristicRowState.IState,
      { idOrganizationCaracteristicRow, idOrganizationCaracteristicValues }
    ) => {
      if (!state.entities[idOrganizationCaracteristicRow]) {
        return state;
      }
      const organizationCaracteristicValues =
        (state.entities[idOrganizationCaracteristicRow]?.organizationCaracteristicValues as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristicRow]: {
            ...state.entities[idOrganizationCaracteristicRow],
            organizationCaracteristicValues: organizationCaracteristicValues.concat(
              idOrganizationCaracteristicValues.filter(id => organizationCaracteristicValues.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicRowGeneratedActions.deleteManyOrganizationCaracteristicValueSuccess,
    (
      state: OrganizationCaracteristicRowState.IState,
      { idOrganizationCaracteristicValues, idOrganizationCaracteristicRows }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristicRows.reduce((entities, idOrganizationCaracteristicRow) => {
            if (!state.entities[idOrganizationCaracteristicRow]?.organizationCaracteristicValues) {
              return entities;
            }
            entities[idOrganizationCaracteristicRow] = {
              ...state.entities[idOrganizationCaracteristicRow],
              organizationCaracteristicValues: (
                state.entities[idOrganizationCaracteristicRow]?.organizationCaracteristicValues as number[]
              )?.filter(
                (idOrganizationCaracteristicValue: number) =>
                  !idOrganizationCaracteristicValues.some((id: number) => id === idOrganizationCaracteristicValue)
              )
            } as OrganizationCaracteristicRowEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicRowEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicRowGeneratedActions.addOrganizationCaracteristicSuccess,
    (
      state: OrganizationCaracteristicRowState.IState,
      { idOrganizationCaracteristicRow, idOrganizationCaracteristic }
    ) => {
      if (!state.entities[idOrganizationCaracteristicRow]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristicRow]: {
            ...state.entities[idOrganizationCaracteristicRow],
            organizationCaracteristic: idOrganizationCaracteristic
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicRowGeneratedActions.deleteManyOrganizationCaracteristicSuccess,
    (
      state: OrganizationCaracteristicRowState.IState,
      { idOrganizationCaracteristics, idOrganizationCaracteristicRows }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristicRows.reduce((entities, idOrganizationCaracteristicRow) => {
            if (!state.entities[idOrganizationCaracteristicRow]?.organizationCaracteristic) {
              return entities;
            }
            entities[idOrganizationCaracteristicRow] = {
              ...state.entities[idOrganizationCaracteristicRow],
              organizationCaracteristic: idOrganizationCaracteristics.some(
                (idOrganizationCaracteristic: number) =>
                  idOrganizationCaracteristic ===
                  state.entities[idOrganizationCaracteristicRow]?.organizationCaracteristic
              )
                ? undefined
                : state.entities[idOrganizationCaracteristicRow]?.organizationCaracteristic
            } as OrganizationCaracteristicRowEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicRowEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicRowGeneratedActions.addOrganizationDataColumnSuccess,
    (state: OrganizationCaracteristicRowState.IState, { idOrganizationCaracteristicRow, idOrganizationDataColumn }) => {
      if (!state.entities[idOrganizationCaracteristicRow]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristicRow]: {
            ...state.entities[idOrganizationCaracteristicRow],
            organizationDataColumn: idOrganizationDataColumn
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicRowGeneratedActions.deleteManyOrganizationDataColumnSuccess,
    (
      state: OrganizationCaracteristicRowState.IState,
      { idOrganizationDataColumns, idOrganizationCaracteristicRows }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristicRows.reduce((entities, idOrganizationCaracteristicRow) => {
            if (!state.entities[idOrganizationCaracteristicRow]?.organizationDataColumn) {
              return entities;
            }
            entities[idOrganizationCaracteristicRow] = {
              ...state.entities[idOrganizationCaracteristicRow],
              organizationDataColumn: idOrganizationDataColumns.some(
                (idOrganizationDataColumn: number) =>
                  idOrganizationDataColumn === state.entities[idOrganizationCaracteristicRow]?.organizationDataColumn
              )
                ? undefined
                : state.entities[idOrganizationCaracteristicRow]?.organizationDataColumn
            } as OrganizationCaracteristicRowEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicRowEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationCaracteristicRowState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationCaracteristicRowState.IState {
  return { ...state, isLoaded, isLoading };
}
