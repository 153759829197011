import { CommunityUserState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityUserReducersGeneratedFunctions } from './community-user-generated.reducer';

const communityUserReducersFunctions = [...communityUserReducersGeneratedFunctions];

const communityUserReducer = createReducer(CommunityUserState.initialState, ...communityUserReducersFunctions);

export function reducer(state: CommunityUserState.IState | undefined, action: Action) {
  return communityUserReducer(state, action);
}
