export * from './organization-milestone-generated.actions';
import { createAction, props } from '@ngrx/store';
import { OrganizationMilestone } from '@api/api-interfaces';
import { OrganizationMilestoneRelationsIds } from '@wip/store/ids-interfaces';

export const upsertOneOrganizationMilestoneWithFamilies = createAction(
  '[OrganizationMilestone] Upsert One OrganizationMilestone With Families',
  props<{
    organizationMilestone: Partial<OrganizationMilestone>;
    ids?: OrganizationMilestoneRelationsIds;
  }>()
);
