import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Right, RightEntityState } from '@api/api-interfaces';
import { RightProfil, RightProfilEntityState } from '@api/api-interfaces';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { RightState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const rightRelations: string[] = ['rightProfils', 'profils'];

export const { selectEntities, selectAll } = RightState.adapter.getSelectors();

export const selectRightState = createFeatureSelector<RightState.IState>(RightState.rightFeatureKey);

export const selectIsLoadedRight = createSelector(selectRightState, (state: RightState.IState) => state.isLoaded);

export const selectIsLoadingRight = createSelector(selectRightState, (state: RightState.IState) => state.isLoading);

export const selectIsReadyRight = createSelector(selectRightState, (state: RightState.IState) => !state.isLoading);

export const selectIsReadyAndLoadedRight = createSelector(
  selectRightState,
  (state: RightState.IState) => state.isLoaded && !state.isLoading
);

export const selectRightsEntities = createSelector(selectRightState, selectEntities);

export const selectRightsArray = createSelector(selectRightState, selectAll);

export const selectIdRightsActive = createSelector(selectRightState, (state: RightState.IState) => state.actives);

const rightsInObject = (rights: Dictionary<RightEntityState>) => ({ rights });

const selectRightsEntitiesDictionary = createSelector(selectRightsEntities, rightsInObject);

const selectAllRightsObject = createSelector(selectRightsEntities, rights => {
  return hydrateAll({ rights });
});

const selectOneRightDictionary = (idRight: number) =>
  createSelector(selectRightsEntities, rights => ({
    rights: { [idRight]: rights[idRight] }
  }));

const selectOneRightDictionaryWithoutChild = (idRight: number) =>
  createSelector(selectRightsEntities, rights => ({
    right: rights[idRight]
  }));

const selectActiveRightsEntities = createSelector(
  selectIdRightsActive,
  selectRightsEntities,
  (actives: number[], rights: Dictionary<RightEntityState>) => getRightsFromActives(actives, rights)
);

function getRightsFromActives(actives: number[], rights: Dictionary<RightEntityState>): Dictionary<RightEntityState> {
  return actives.reduce((acc, idActive) => {
    if (rights[idActive]) {
      acc[idActive] = rights[idActive];
    }
    return acc;
  }, {} as Dictionary<RightEntityState>);
}

const selectAllRightsSelectors: Dictionary<Selector> = {};
export function selectAllRights(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Right>(
      schema,
      selectAllRightsSelectors,
      selectRightsEntitiesDictionary,
      getRelationSelectors,
      rightRelations,
      hydrateAll,
      'right'
    );
  } else {
    return selectAllRightsObject;
  }
}

export function selectAllRightsDictionary(schema: SelectSchema = {}, customKey: string = 'rights'): Selector {
  return createSelector(selectAllRights(schema), result => {
    const res = { [customKey]: {} as Dictionary<RightEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.rights.length; i++) {
      res[customKey][result.rights[i].idRight] = result.rights[i];
    }
    return res;
  });
}

export function selectOneRight(schema: SelectSchema = {}, idRight: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneRightDictionary(idRight)];
    selectors.push(...getRelationSelectors(schema, rightRelations, 'right'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneRightDictionaryWithoutChild(idRight);
  }
}

export function selectActiveRights(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveRightsEntities, rights => ({
      rights
    }))
  ];
  selectors.push(...getRelationSelectors(schema, rightRelations, 'right'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  rights: Dictionary<RightEntityState>;
  rightProfils?: Dictionary<RightProfilEntityState>;
  profils?: Dictionary<ProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { rights: (Right | null)[] } {
  const { rights, rightProfils, profils } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    rights: Object.keys(rights).map(idRight => hydrate(rights[idRight] as RightEntityState, rightProfils, profils))
  };
}

function hydrateOne(...args: hydrateArgs[]): { right: RightEntityState | null } {
  const { rights, rightProfils, profils } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const right = Object.values(rights)[0];
  return {
    right: hydrate(right as RightEntityState, rightProfils, profils)
  };
}

function hydrate(
  right: RightEntityState,
  rightProfilEntities?: Dictionary<RightProfilEntityState>,
  profilEntities?: Dictionary<ProfilEntityState>
): Right | null {
  if (!right) {
    return null;
  }

  const rightHydrated: RightEntityState = { ...right };

  if (rightProfilEntities) {
    rightHydrated.rightProfils = ((rightHydrated.rightProfils as number[]) || []).map(
      id => rightProfilEntities[id]
    ) as RightProfil[];
  } else {
    delete rightHydrated.rightProfils;
  }

  if (profilEntities) {
    rightHydrated.profils = ((rightHydrated.profils as number[]) || []).map(id => profilEntities[id]) as Profil[];
  } else {
    delete rightHydrated.profils;
  }

  return rightHydrated as Right;
}
