import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import dayjs from 'dayjs';

@Component({
  selector: 'app-date-picker-dialog',
  templateUrl: './date-picker-dialog.component.html',
  styleUrls: ['./date-picker-dialog.component.scss']
})
export class DatePickerDialogComponent implements OnInit {
  dateInputGroup: FormGroup;
  selectedDate: Date;
  max: Date;
  min: Date;
  constructor(
    public dialogRef: MatDialogRef<DatePickerDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data.date) {
      this.selectedDate = new Date(this.data.date);
    }
    if (this.data.min) {
      this.min = new Date(this.data.min);
    }
    if (this.data.max) {
      this.selectedDate = new Date(this.data.min);
    }

    this.dateInputGroup = this.formBuilder.group({
      date: [this.data.date]
    });
  }

  removeDate(): void {
    this.dialogRef.close(null);
  }

  validate(): void {
    if (this.dateInputGroup.get('date').value) {
      const newDate: Date = new Date(this.dateInputGroup.get('date').value);
      if (this.selectedDate) {
        const hours = this.selectedDate.getHours();
        const minutes = this.selectedDate.getMinutes();
        newDate.setHours(hours);
        newDate.setMinutes(minutes);
      }

      this.dialogRef.close(dayjs(newDate).toDate());
    } else {
      // this.none();
    }
  }
}
