import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { Organization } from '@api/api-interfaces';
import { SharedModule } from '@_app/shared/shared.module';

@Component({
  selector: 'app-organization-choices',
  templateUrl: './organization-choices.component.html',
  styleUrls: ['./organization-choices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationChoicesComponent implements OnInit, OnChanges {
  public initials: string = '';
  public organizationsSorted: Organization[];

  @Input() public currentOrganization: Organization;
  @Input() public listOrganization: Organization[];
  @Output() public selectedOrganization = new EventEmitter<Organization>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.currentOrganization && this.currentOrganization?.raisonSociale) {
      this.initials = '';
      const title = this.currentOrganization.raisonSociale.split(' ');
      for (let i = 0; i < title.length; i++) {
        this.initials += title[i][0];
      }
    }
    if (changes.listOrganization) {
      this.organizationsSorted = this.listOrganization
        .filter(org => org.raisonSociale)
        .sort((a, b) =>
          a.raisonSociale.toLocaleLowerCase() < b.raisonSociale.toLocaleLowerCase()
            ? -1
            : a.raisonSociale.toLocaleLowerCase() > b.raisonSociale.toLocaleLowerCase()
              ? 1
              : 0
        );
    }
  }

  public onOrganizationSelected($event) {
    this.selectedOrganization.emit($event);
  }
}

@NgModule({
  imports: [CommonModule, SharedModule.forRoot()],
  declarations: [OrganizationChoicesComponent],
  exports: [OrganizationChoicesComponent]
})
export class OrganizationChoicesModule {}
