import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserProfilRight, UserProfilRightEntityState } from '@api/api-interfaces';
import { UserProfilRightRelationsIds } from '@wip/store/ids-interfaces';

export const UserProfilRightGeneratedActions = createActionGroup({
  source: 'User Profil Right Generated',
  events: {
    'Get One User Profil Right': props<{ idUserProfilRight: number; params?: any }>(),
    'Get Many User Profil Rights': props<{ params: any }>(),
    'Add Many Actives User Profil Rights': props<{ idUserProfilRights: number[] }>(),
    'Delete One Active User Profil Right': props<{ idUserProfilRight: number }>(),
    'Clear Actives User Profil Rights': emptyProps(),
    'Upsert One User Profil Right': props<{
      userProfilRight: Partial<UserProfilRight>;
      ids?: UserProfilRightRelationsIds;
    }>(),
    'Upsert Many User Profil Rights': props<{
      userProfilRights: Partial<UserProfilRight>[];
      ids?: UserProfilRightRelationsIds;
    }>(),
    'Upsert Many User Profil Rights Success': props<{ userProfilRights: UserProfilRightEntityState[] }>(),
    'Delete One User Profil Right': props<{ idUserProfilRight: number }>(),
    'Delete One User Profil Right Success': props<{ idUserProfilRight: number }>(),
    'Normalize Many User Profil Rights After Upsert': props<{ userProfilRights: UserProfilRightEntityState[] }>(),
    'User Profil Rights Failure': props<{ error: HttpErrorResponse }>(),
    'Clear User Profil Rights': emptyProps(),
    'Add Profil Success': props<{ idUserProfilRight: number; idProfil: number }>(),
    'Delete Many Profil Success': props<{ idProfils: number[]; idUserProfilRights: number[] }>(),
    'Add Organization User Profil Success': props<{ idUserProfilRight: number; idOrganizationUserProfil: number }>(),
    'Delete Many Organization User Profil Success': props<{
      idOrganizationUserProfils: number[];
      idUserProfilRights: number[];
    }>()
  }
});
