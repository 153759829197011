import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationProjectModule, OrganizationProjectModuleEntityState } from '@api/api-interfaces';
import { OrganizationProjectModuleApiService } from '@wip/store/api-services';
import { OrganizationProjectModuleGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationProjectModule } from '@wip/store/configs/normalization';
import { OrganizationProjectModuleSelectors } from '@wip/store/selectors';
import { OrganizationProjectModuleRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { ProjectModuleGeneratedActions } from '@wip/store/actions';

export function getDefaultAddOrganizationProjectModuleActions(
  organizationProjectModule: OrganizationProjectModuleEntityState,
  ids?: OrganizationProjectModuleRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationProjectModuleGeneratedActions.normalizeManyOrganizationProjectModulesAfterUpsert({
      organizationProjectModules: [organizationProjectModule]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationProjectModuleSuccess({
        idOrganization: ids.organization,
        idOrganizationProjectModules: [organizationProjectModule.idOrganizationProjectModule]
      })
    );
    actions.push(
      OrganizationProjectModuleGeneratedActions.addOrganizationSuccess({
        idOrganizationProjectModule: organizationProjectModule.idOrganizationProjectModule,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.projectModule) {
    actions.push(
      ProjectModuleGeneratedActions.addManyOrganizationProjectModuleSuccess({
        idProjectModule: ids.projectModule,
        idOrganizationProjectModules: [organizationProjectModule.idOrganizationProjectModule]
      })
    );
    actions.push(
      OrganizationProjectModuleGeneratedActions.addProjectModuleSuccess({
        idOrganizationProjectModule: organizationProjectModule.idOrganizationProjectModule,
        idProjectModule: ids.projectModule
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationProjectModuleActions(
  organizationProjectModule: OrganizationProjectModuleEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationProjectModuleGeneratedActions.deleteOneOrganizationProjectModuleSuccess({
      idOrganizationProjectModule: organizationProjectModule.idOrganizationProjectModule
    })
  ];

  if (organizationProjectModule.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationProjectModuleSuccess({
        idOrganizationProjectModules: [organizationProjectModule.idOrganizationProjectModule],
        idOrganizations: [organizationProjectModule.organization as number]
      })
    );
  }

  if (organizationProjectModule.projectModule) {
    actions.push(
      ProjectModuleGeneratedActions.deleteManyOrganizationProjectModuleSuccess({
        idOrganizationProjectModules: [organizationProjectModule.idOrganizationProjectModule],
        idProjectModules: [organizationProjectModule.projectModule as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationProjectModuleEffects {
  constructor(
    protected actions$: Actions,
    protected organizationProjectModuleApiService: OrganizationProjectModuleApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationProjectModules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationProjectModuleGeneratedActions.getManyOrganizationProjectModules),
      switchMap(({ params }) =>
        this.organizationProjectModuleApiService.getOrganizationProjectModules(params).pipe(
          map((organizationProjectModules: OrganizationProjectModule[]) => {
            return OrganizationProjectModuleGeneratedActions.normalizeManyOrganizationProjectModulesAfterUpsert({
              organizationProjectModules
            });
          }),
          catchError(error =>
            of(OrganizationProjectModuleGeneratedActions.organizationProjectModulesFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationProjectModule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationProjectModuleGeneratedActions.getOneOrganizationProjectModule),
      switchMap(idOrganizationProjectModule =>
        this.organizationProjectModuleApiService.getOrganizationProjectModule(idOrganizationProjectModule).pipe(
          map((organizationProjectModule: OrganizationProjectModule) => {
            return OrganizationProjectModuleGeneratedActions.normalizeManyOrganizationProjectModulesAfterUpsert({
              organizationProjectModules: [organizationProjectModule]
            });
          }),
          catchError(error =>
            of(OrganizationProjectModuleGeneratedActions.organizationProjectModulesFailure({ error }))
          )
        )
      )
    );
  });

  upsertOneOrganizationProjectModule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationProjectModuleGeneratedActions.upsertOneOrganizationProjectModule),
      concatMap(
        ({
          organizationProjectModule,
          ids
        }: {
          organizationProjectModule: Partial<OrganizationProjectModule>;
          ids?: OrganizationProjectModuleRelationsIds;
        }) => {
          if (organizationProjectModule.idOrganizationProjectModule) {
            return this.organizationProjectModuleApiService
              .updateOrganizationProjectModule(organizationProjectModule)
              .pipe(
                map((organizationProjectModuleReturned: OrganizationProjectModule) => {
                  return OrganizationProjectModuleGeneratedActions.normalizeManyOrganizationProjectModulesAfterUpsert({
                    organizationProjectModules: [organizationProjectModuleReturned]
                  });
                }),
                catchError(error =>
                  of(OrganizationProjectModuleGeneratedActions.organizationProjectModulesFailure({ error }))
                )
              );
          } else {
            return this.organizationProjectModuleApiService
              .addOrganizationProjectModule(organizationProjectModule)
              .pipe(
                mergeMap((organizationProjectModuleReturned: OrganizationProjectModule) =>
                  getDefaultAddOrganizationProjectModuleActions(organizationProjectModuleReturned, ids)
                ),
                catchError(error =>
                  of(OrganizationProjectModuleGeneratedActions.organizationProjectModulesFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationProjectModule$ = createEffect(() => {
    const selectOrganizationProjectModuleState$ = this.store$.select(
      OrganizationProjectModuleSelectors.selectOrganizationProjectModuleState
    );
    return this.actions$.pipe(
      ofType(OrganizationProjectModuleGeneratedActions.deleteOneOrganizationProjectModule),
      withLatestFrom(selectOrganizationProjectModuleState$),
      concatMap(([{ idOrganizationProjectModule }, state]) =>
        this.organizationProjectModuleApiService.deleteOrganizationProjectModule(idOrganizationProjectModule).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationProjectModuleActions(
              state.entities[idOrganizationProjectModule] as OrganizationProjectModuleEntityState
            )
          ),
          catchError(error =>
            of(OrganizationProjectModuleGeneratedActions.organizationProjectModulesFailure({ error }))
          )
        )
      )
    );
  });

  normalizeManyOrganizationProjectModulesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationProjectModuleGeneratedActions.normalizeManyOrganizationProjectModulesAfterUpsert),
      concatMap(({ organizationProjectModules }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationProjectModule(
          organizationProjectModules,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationProjectModule] Normalization After Upsert Success')];
      })
    );
  });
}
