import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationCaracteristicRowEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationCaracteristicRowEntityState>;

export const adapter = createEntityAdapter<OrganizationCaracteristicRowEntityState>({
  selectId: o => o.idOrganizationCaracteristicRow
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationCaracteristicRowFeatureKey = 'organizationCaracteristicRow';
