import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserGroup, UserGroupEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { UserGroupState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const userGroupRelations: string[] = ['users', 'groups'];

export const { selectEntities, selectAll } = UserGroupState.adapter.getSelectors();

export const selectUserGroupState = createFeatureSelector<UserGroupState.IState>(UserGroupState.userGroupFeatureKey);

export const selectIsLoadedUserGroup = createSelector(
  selectUserGroupState,
  (state: UserGroupState.IState) => state.isLoaded
);

export const selectIsLoadingUserGroup = createSelector(
  selectUserGroupState,
  (state: UserGroupState.IState) => state.isLoading
);

export const selectIsReadyUserGroup = createSelector(
  selectUserGroupState,
  (state: UserGroupState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUserGroup = createSelector(
  selectUserGroupState,
  (state: UserGroupState.IState) => state.isLoaded && !state.isLoading
);

export const selectUserGroupsEntities = createSelector(selectUserGroupState, selectEntities);

export const selectUserGroupsArray = createSelector(selectUserGroupState, selectAll);

export const selectIdUserGroupsActive = createSelector(
  selectUserGroupState,
  (state: UserGroupState.IState) => state.actives
);

const userGroupsInObject = (userGroups: Dictionary<UserGroupEntityState>) => ({ userGroups });

const selectUserGroupsEntitiesDictionary = createSelector(selectUserGroupsEntities, userGroupsInObject);

const selectAllUserGroupsObject = createSelector(selectUserGroupsEntities, userGroups => {
  return hydrateAll({ userGroups });
});

const selectOneUserGroupDictionary = (idUserGroup: number) =>
  createSelector(selectUserGroupsEntities, userGroups => ({
    userGroups: { [idUserGroup]: userGroups[idUserGroup] }
  }));

const selectOneUserGroupDictionaryWithoutChild = (idUserGroup: number) =>
  createSelector(selectUserGroupsEntities, userGroups => ({
    userGroup: userGroups[idUserGroup]
  }));

const selectActiveUserGroupsEntities = createSelector(
  selectIdUserGroupsActive,
  selectUserGroupsEntities,
  (actives: number[], userGroups: Dictionary<UserGroupEntityState>) => getUserGroupsFromActives(actives, userGroups)
);

function getUserGroupsFromActives(
  actives: number[],
  userGroups: Dictionary<UserGroupEntityState>
): Dictionary<UserGroupEntityState> {
  return actives.reduce((acc, idActive) => {
    if (userGroups[idActive]) {
      acc[idActive] = userGroups[idActive];
    }
    return acc;
  }, {} as Dictionary<UserGroupEntityState>);
}

const selectAllUserGroupsSelectors: Dictionary<Selector> = {};
export function selectAllUserGroups(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UserGroup>(
      schema,
      selectAllUserGroupsSelectors,
      selectUserGroupsEntitiesDictionary,
      getRelationSelectors,
      userGroupRelations,
      hydrateAll,
      'userGroup'
    );
  } else {
    return selectAllUserGroupsObject;
  }
}

export function selectAllUserGroupsDictionary(schema: SelectSchema = {}, customKey: string = 'userGroups'): Selector {
  return createSelector(selectAllUserGroups(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserGroupEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.userGroups.length; i++) {
      res[customKey][result.userGroups[i].idUserGroup] = result.userGroups[i];
    }
    return res;
  });
}

export function selectOneUserGroup(schema: SelectSchema = {}, idUserGroup: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneUserGroupDictionary(idUserGroup)];
    selectors.push(...getRelationSelectors(schema, userGroupRelations, 'userGroup'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserGroupDictionaryWithoutChild(idUserGroup);
  }
}

export function selectActiveUserGroups(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveUserGroupsEntities, userGroups => ({
      userGroups
    }))
  ];
  selectors.push(...getRelationSelectors(schema, userGroupRelations, 'userGroup'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  userGroups: Dictionary<UserGroupEntityState>;
  users?: Dictionary<UserEntityState>;
  groups?: Dictionary<GroupEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { userGroups: (UserGroup | null)[] } {
  const { userGroups, users, groups } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    userGroups: Object.keys(userGroups).map(idUserGroup =>
      hydrate(userGroups[idUserGroup] as UserGroupEntityState, users, groups)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { userGroup: UserGroupEntityState | null } {
  const { userGroups, users, groups } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const userGroup = Object.values(userGroups)[0];
  return {
    userGroup: hydrate(userGroup as UserGroupEntityState, users, groups)
  };
}

function hydrate(
  userGroup: UserGroupEntityState,
  userEntities?: Dictionary<UserEntityState>,
  groupEntities?: Dictionary<GroupEntityState>
): UserGroup | null {
  if (!userGroup) {
    return null;
  }

  const userGroupHydrated: UserGroupEntityState = { ...userGroup };
  if (userEntities) {
    userGroupHydrated.user = userEntities[userGroup.user as number] as User;
  } else {
    delete userGroupHydrated.user;
  }
  if (groupEntities) {
    userGroupHydrated.group = groupEntities[userGroup.group as number] as Group;
  } else {
    delete userGroupHydrated.group;
  }

  return userGroupHydrated as UserGroup;
}
