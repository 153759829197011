import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Section, SectionEntityState } from '@api/api-interfaces';
import { OrganizationSection, OrganizationSectionEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { SectionState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const sectionRelations: string[] = ['organizationSections', 'organizations'];

export const { selectEntities, selectAll } = SectionState.adapter.getSelectors();

export const selectSectionState = createFeatureSelector<SectionState.IState>(SectionState.sectionFeatureKey);

export const selectIsLoadedSection = createSelector(selectSectionState, (state: SectionState.IState) => state.isLoaded);

export const selectIsLoadingSection = createSelector(
  selectSectionState,
  (state: SectionState.IState) => state.isLoading
);

export const selectIsReadySection = createSelector(
  selectSectionState,
  (state: SectionState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSection = createSelector(
  selectSectionState,
  (state: SectionState.IState) => state.isLoaded && !state.isLoading
);

export const selectSectionsEntities = createSelector(selectSectionState, selectEntities);

export const selectSectionsArray = createSelector(selectSectionState, selectAll);

export const selectIdSectionsActive = createSelector(selectSectionState, (state: SectionState.IState) => state.actives);

const sectionsInObject = (sections: Dictionary<SectionEntityState>) => ({ sections });

const selectSectionsEntitiesDictionary = createSelector(selectSectionsEntities, sectionsInObject);

const selectAllSectionsObject = createSelector(selectSectionsEntities, sections => {
  return hydrateAll({ sections });
});

const selectOneSectionDictionary = (idSection: number) =>
  createSelector(selectSectionsEntities, sections => ({
    sections: { [idSection]: sections[idSection] }
  }));

const selectOneSectionDictionaryWithoutChild = (idSection: number) =>
  createSelector(selectSectionsEntities, sections => ({
    section: sections[idSection]
  }));

const selectActiveSectionsEntities = createSelector(
  selectIdSectionsActive,
  selectSectionsEntities,
  (actives: number[], sections: Dictionary<SectionEntityState>) => getSectionsFromActives(actives, sections)
);

function getSectionsFromActives(
  actives: number[],
  sections: Dictionary<SectionEntityState>
): Dictionary<SectionEntityState> {
  return actives.reduce((acc, idActive) => {
    if (sections[idActive]) {
      acc[idActive] = sections[idActive];
    }
    return acc;
  }, {} as Dictionary<SectionEntityState>);
}

const selectAllSectionsSelectors: Dictionary<Selector> = {};
export function selectAllSections(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Section>(
      schema,
      selectAllSectionsSelectors,
      selectSectionsEntitiesDictionary,
      getRelationSelectors,
      sectionRelations,
      hydrateAll,
      'section'
    );
  } else {
    return selectAllSectionsObject;
  }
}

export function selectAllSectionsDictionary(schema: SelectSchema = {}, customKey: string = 'sections'): Selector {
  return createSelector(selectAllSections(schema), result => {
    const res = { [customKey]: {} as Dictionary<SectionEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.sections.length; i++) {
      res[customKey][result.sections[i].idSection] = result.sections[i];
    }
    return res;
  });
}

export function selectOneSection(schema: SelectSchema = {}, idSection: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneSectionDictionary(idSection)];
    selectors.push(...getRelationSelectors(schema, sectionRelations, 'section'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSectionDictionaryWithoutChild(idSection);
  }
}

export function selectActiveSections(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveSectionsEntities, sections => ({
      sections
    }))
  ];
  selectors.push(...getRelationSelectors(schema, sectionRelations, 'section'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  sections: Dictionary<SectionEntityState>;
  organizationSections?: Dictionary<OrganizationSectionEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { sections: (Section | null)[] } {
  const { sections, organizationSections, organizations } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    sections: Object.keys(sections).map(idSection =>
      hydrate(sections[idSection] as SectionEntityState, organizationSections, organizations)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { section: SectionEntityState | null } {
  const { sections, organizationSections, organizations } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const section = Object.values(sections)[0];
  return {
    section: hydrate(section as SectionEntityState, organizationSections, organizations)
  };
}

function hydrate(
  section: SectionEntityState,
  organizationSectionEntities?: Dictionary<OrganizationSectionEntityState>,
  organizationEntities?: Dictionary<OrganizationEntityState>
): Section | null {
  if (!section) {
    return null;
  }

  const sectionHydrated: SectionEntityState = { ...section };

  if (organizationSectionEntities) {
    sectionHydrated.organizationSections = ((sectionHydrated.organizationSections as number[]) || []).map(
      id => organizationSectionEntities[id]
    ) as OrganizationSection[];
  } else {
    delete sectionHydrated.organizationSections;
  }

  if (organizationEntities) {
    sectionHydrated.organizations = ((sectionHydrated.organizations as number[]) || []).map(
      id => organizationEntities[id]
    ) as Organization[];
  } else {
    delete sectionHydrated.organizations;
  }

  return sectionHydrated as Section;
}
