import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationCaracteristicRow, OrganizationCaracteristicRowEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicValue, OrganizationCaracteristicValueEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { OrganizationDataColumn, OrganizationDataColumnEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationCaracteristicRowState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationCaracteristicRowRelations: string[] = [
  'organizationCaracteristicValues',
  'organizationCaracteristics',
  'organizationDataColumns'
];

export const { selectEntities, selectAll } = OrganizationCaracteristicRowState.adapter.getSelectors();

export const selectOrganizationCaracteristicRowState = createFeatureSelector<OrganizationCaracteristicRowState.IState>(
  OrganizationCaracteristicRowState.organizationCaracteristicRowFeatureKey
);

export const selectIsLoadedOrganizationCaracteristicRow = createSelector(
  selectOrganizationCaracteristicRowState,
  (state: OrganizationCaracteristicRowState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationCaracteristicRow = createSelector(
  selectOrganizationCaracteristicRowState,
  (state: OrganizationCaracteristicRowState.IState) => state.isLoading
);

export const selectIsReadyOrganizationCaracteristicRow = createSelector(
  selectOrganizationCaracteristicRowState,
  (state: OrganizationCaracteristicRowState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationCaracteristicRow = createSelector(
  selectOrganizationCaracteristicRowState,
  (state: OrganizationCaracteristicRowState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationCaracteristicRowsEntities = createSelector(
  selectOrganizationCaracteristicRowState,
  selectEntities
);

export const selectOrganizationCaracteristicRowsArray = createSelector(
  selectOrganizationCaracteristicRowState,
  selectAll
);

export const selectIdOrganizationCaracteristicRowsActive = createSelector(
  selectOrganizationCaracteristicRowState,
  (state: OrganizationCaracteristicRowState.IState) => state.actives
);

const organizationCaracteristicRowsInObject = (
  organizationCaracteristicRows: Dictionary<OrganizationCaracteristicRowEntityState>
) => ({ organizationCaracteristicRows });

const selectOrganizationCaracteristicRowsEntitiesDictionary = createSelector(
  selectOrganizationCaracteristicRowsEntities,
  organizationCaracteristicRowsInObject
);

const selectAllOrganizationCaracteristicRowsObject = createSelector(
  selectOrganizationCaracteristicRowsEntities,
  organizationCaracteristicRows => {
    return hydrateAll({ organizationCaracteristicRows });
  }
);

const selectOneOrganizationCaracteristicRowDictionary = (idOrganizationCaracteristicRow: number) =>
  createSelector(selectOrganizationCaracteristicRowsEntities, organizationCaracteristicRows => ({
    organizationCaracteristicRows: {
      [idOrganizationCaracteristicRow]: organizationCaracteristicRows[idOrganizationCaracteristicRow]
    }
  }));

const selectOneOrganizationCaracteristicRowDictionaryWithoutChild = (idOrganizationCaracteristicRow: number) =>
  createSelector(selectOrganizationCaracteristicRowsEntities, organizationCaracteristicRows => ({
    organizationCaracteristicRow: organizationCaracteristicRows[idOrganizationCaracteristicRow]
  }));

const selectActiveOrganizationCaracteristicRowsEntities = createSelector(
  selectIdOrganizationCaracteristicRowsActive,
  selectOrganizationCaracteristicRowsEntities,
  (actives: number[], organizationCaracteristicRows: Dictionary<OrganizationCaracteristicRowEntityState>) =>
    getOrganizationCaracteristicRowsFromActives(actives, organizationCaracteristicRows)
);

function getOrganizationCaracteristicRowsFromActives(
  actives: number[],
  organizationCaracteristicRows: Dictionary<OrganizationCaracteristicRowEntityState>
): Dictionary<OrganizationCaracteristicRowEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationCaracteristicRows[idActive]) {
      acc[idActive] = organizationCaracteristicRows[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationCaracteristicRowEntityState>);
}

const selectAllOrganizationCaracteristicRowsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationCaracteristicRows(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationCaracteristicRow>(
      schema,
      selectAllOrganizationCaracteristicRowsSelectors,
      selectOrganizationCaracteristicRowsEntitiesDictionary,
      getRelationSelectors,
      organizationCaracteristicRowRelations,
      hydrateAll,
      'organizationCaracteristicRow'
    );
  } else {
    return selectAllOrganizationCaracteristicRowsObject;
  }
}

export function selectAllOrganizationCaracteristicRowsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationCaracteristicRows'
): Selector {
  return createSelector(selectAllOrganizationCaracteristicRows(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationCaracteristicRowEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationCaracteristicRows.length; i++) {
      res[customKey][result.organizationCaracteristicRows[i].idOrganizationCaracteristicRow] =
        result.organizationCaracteristicRows[i];
    }
    return res;
  });
}

export function selectOneOrganizationCaracteristicRow(
  schema: SelectSchema = {},
  idOrganizationCaracteristicRow: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationCaracteristicRowDictionary(idOrganizationCaracteristicRow)];
    selectors.push(
      ...getRelationSelectors(schema, organizationCaracteristicRowRelations, 'organizationCaracteristicRow')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationCaracteristicRowDictionaryWithoutChild(idOrganizationCaracteristicRow);
  }
}

export function selectActiveOrganizationCaracteristicRows(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationCaracteristicRowsEntities, organizationCaracteristicRows => ({
      organizationCaracteristicRows
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationCaracteristicRowRelations, 'organizationCaracteristicRow')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationCaracteristicRows: Dictionary<OrganizationCaracteristicRowEntityState>;
  organizationCaracteristics?: Dictionary<OrganizationCaracteristicEntityState>;
  organizationDataColumns?: Dictionary<OrganizationDataColumnEntityState>;
  organizationCaracteristicValues?: Dictionary<OrganizationCaracteristicValueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationCaracteristicRows: (OrganizationCaracteristicRow | null)[];
} {
  const {
    organizationCaracteristicRows,
    organizationCaracteristics,
    organizationDataColumns,
    organizationCaracteristicValues
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationCaracteristicRows: Object.keys(organizationCaracteristicRows).map(idOrganizationCaracteristicRow =>
      hydrate(
        organizationCaracteristicRows[idOrganizationCaracteristicRow] as OrganizationCaracteristicRowEntityState,
        organizationCaracteristics,
        organizationDataColumns,
        organizationCaracteristicValues
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationCaracteristicRow: OrganizationCaracteristicRowEntityState | null;
} {
  const {
    organizationCaracteristicRows,
    organizationCaracteristics,
    organizationDataColumns,
    organizationCaracteristicValues
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationCaracteristicRow = Object.values(organizationCaracteristicRows)[0];
  return {
    organizationCaracteristicRow: hydrate(
      organizationCaracteristicRow as OrganizationCaracteristicRowEntityState,
      organizationCaracteristics,
      organizationDataColumns,
      organizationCaracteristicValues
    )
  };
}

function hydrate(
  organizationCaracteristicRow: OrganizationCaracteristicRowEntityState,
  organizationCaracteristicEntities?: Dictionary<OrganizationCaracteristicEntityState>,
  organizationDataColumnEntities?: Dictionary<OrganizationDataColumnEntityState>,
  organizationCaracteristicValueEntities?: Dictionary<OrganizationCaracteristicValueEntityState>
): OrganizationCaracteristicRow | null {
  if (!organizationCaracteristicRow) {
    return null;
  }

  const organizationCaracteristicRowHydrated: OrganizationCaracteristicRowEntityState = {
    ...organizationCaracteristicRow
  };
  if (organizationCaracteristicEntities) {
    organizationCaracteristicRowHydrated.organizationCaracteristic = organizationCaracteristicEntities[
      organizationCaracteristicRow.organizationCaracteristic as number
    ] as OrganizationCaracteristic;
  } else {
    delete organizationCaracteristicRowHydrated.organizationCaracteristic;
  }
  if (organizationDataColumnEntities) {
    organizationCaracteristicRowHydrated.organizationDataColumn = organizationDataColumnEntities[
      organizationCaracteristicRow.organizationDataColumn as number
    ] as OrganizationDataColumn;
  } else {
    delete organizationCaracteristicRowHydrated.organizationDataColumn;
  }

  if (organizationCaracteristicValueEntities) {
    organizationCaracteristicRowHydrated.organizationCaracteristicValues = (
      (organizationCaracteristicRowHydrated.organizationCaracteristicValues as number[]) || []
    ).map(id => organizationCaracteristicValueEntities[id]) as OrganizationCaracteristicValue[];
  } else {
    delete organizationCaracteristicRowHydrated.organizationCaracteristicValues;
  }

  return organizationCaracteristicRowHydrated as OrganizationCaracteristicRow;
}
