import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotesHistory, NotesHistoryEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { NotesHistoryState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const notesHistoryRelations: string[] = ['communities'];

export const { selectEntities, selectAll } = NotesHistoryState.adapter.getSelectors();

export const selectNotesHistoryState = createFeatureSelector<NotesHistoryState.IState>(
  NotesHistoryState.notesHistoryFeatureKey
);

export const selectIsLoadedNotesHistory = createSelector(
  selectNotesHistoryState,
  (state: NotesHistoryState.IState) => state.isLoaded
);

export const selectIsLoadingNotesHistory = createSelector(
  selectNotesHistoryState,
  (state: NotesHistoryState.IState) => state.isLoading
);

export const selectIsReadyNotesHistory = createSelector(
  selectNotesHistoryState,
  (state: NotesHistoryState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedNotesHistory = createSelector(
  selectNotesHistoryState,
  (state: NotesHistoryState.IState) => state.isLoaded && !state.isLoading
);

export const selectNotesHistoriesEntities = createSelector(selectNotesHistoryState, selectEntities);

export const selectNotesHistoriesArray = createSelector(selectNotesHistoryState, selectAll);

export const selectIdNotesHistoriesActive = createSelector(
  selectNotesHistoryState,
  (state: NotesHistoryState.IState) => state.actives
);

const notesHistoriesInObject = (notesHistories: Dictionary<NotesHistoryEntityState>) => ({ notesHistories });

const selectNotesHistoriesEntitiesDictionary = createSelector(selectNotesHistoriesEntities, notesHistoriesInObject);

const selectAllNotesHistoriesObject = createSelector(selectNotesHistoriesEntities, notesHistories => {
  return hydrateAll({ notesHistories });
});

const selectOneNotesHistoryDictionary = (idNotesHistory: number) =>
  createSelector(selectNotesHistoriesEntities, notesHistories => ({
    notesHistories: { [idNotesHistory]: notesHistories[idNotesHistory] }
  }));

const selectOneNotesHistoryDictionaryWithoutChild = (idNotesHistory: number) =>
  createSelector(selectNotesHistoriesEntities, notesHistories => ({
    notesHistory: notesHistories[idNotesHistory]
  }));

const selectActiveNotesHistoriesEntities = createSelector(
  selectIdNotesHistoriesActive,
  selectNotesHistoriesEntities,
  (actives: number[], notesHistories: Dictionary<NotesHistoryEntityState>) =>
    getNotesHistoriesFromActives(actives, notesHistories)
);

function getNotesHistoriesFromActives(
  actives: number[],
  notesHistories: Dictionary<NotesHistoryEntityState>
): Dictionary<NotesHistoryEntityState> {
  return actives.reduce((acc, idActive) => {
    if (notesHistories[idActive]) {
      acc[idActive] = notesHistories[idActive];
    }
    return acc;
  }, {} as Dictionary<NotesHistoryEntityState>);
}

const selectAllNotesHistoriesSelectors: Dictionary<Selector> = {};
export function selectAllNotesHistories(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<NotesHistory>(
      schema,
      selectAllNotesHistoriesSelectors,
      selectNotesHistoriesEntitiesDictionary,
      getRelationSelectors,
      notesHistoryRelations,
      hydrateAll,
      'notesHistory'
    );
  } else {
    return selectAllNotesHistoriesObject;
  }
}

export function selectAllNotesHistoriesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'notesHistories'
): Selector {
  return createSelector(selectAllNotesHistories(schema), result => {
    const res = { [customKey]: {} as Dictionary<NotesHistoryEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.notesHistories.length; i++) {
      res[customKey][result.notesHistories[i].idNotesHistory] = result.notesHistories[i];
    }
    return res;
  });
}

export function selectOneNotesHistory(schema: SelectSchema = {}, idNotesHistory: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneNotesHistoryDictionary(idNotesHistory)];
    selectors.push(...getRelationSelectors(schema, notesHistoryRelations, 'notesHistory'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneNotesHistoryDictionaryWithoutChild(idNotesHistory);
  }
}

export function selectActiveNotesHistories(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveNotesHistoriesEntities, notesHistories => ({
      notesHistories
    }))
  ];
  selectors.push(...getRelationSelectors(schema, notesHistoryRelations, 'notesHistory'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  notesHistories: Dictionary<NotesHistoryEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { notesHistories: (NotesHistory | null)[] } {
  const { notesHistories, communities } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    notesHistories: Object.keys(notesHistories).map(idNotesHistory =>
      hydrate(notesHistories[idNotesHistory] as NotesHistoryEntityState, communities)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { notesHistory: NotesHistoryEntityState | null } {
  const { notesHistories, communities } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const notesHistory = Object.values(notesHistories)[0];
  return {
    notesHistory: hydrate(notesHistory as NotesHistoryEntityState, communities)
  };
}

function hydrate(
  notesHistory: NotesHistoryEntityState,
  communityEntities?: Dictionary<CommunityEntityState>
): NotesHistory | null {
  if (!notesHistory) {
    return null;
  }

  const notesHistoryHydrated: NotesHistoryEntityState = { ...notesHistory };
  if (communityEntities) {
    notesHistoryHydrated.community = communityEntities[notesHistory.community as number] as Community;
  } else {
    delete notesHistoryHydrated.community;
  }

  return notesHistoryHydrated as NotesHistory;
}
