import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationSubFamily } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationSubFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationSubFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationSubFamilySelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationSubFamilyService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationSubFamilySelectors.selectIsLoadedOrganizationSubFamily));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationSubFamilySelectors.selectIsLoadingOrganizationSubFamily));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationSubFamilySelectors.selectIsReadyAndLoadedOrganizationSubFamily as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationSubFamilys(schema: SelectSchema = {}): Observable<OrganizationSubFamily[]> {
    return this.store$.pipe(select(OrganizationSubFamilySelectors.selectAllOrganizationSubFamilys(schema))).pipe(
      switchMap(({ organizationSubFamilys }: { organizationSubFamilys: OrganizationSubFamily[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationSubFamilys));
      })
    );
  }

  public selectOneOrganizationSubFamily(
    idOrganizationSubFamily: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationSubFamily> {
    return this.store$
      .pipe(select(OrganizationSubFamilySelectors.selectOneOrganizationSubFamily(schema, idOrganizationSubFamily)))
      .pipe(
        switchMap(({ organizationSubFamily }: { organizationSubFamily: OrganizationSubFamily }) => {
          return this.getReady(schema).pipe(mapTo(organizationSubFamily));
        })
      );
  }

  public selectAllActiveOrganizationSubFamilys(schema: SelectSchema = {}): Observable<OrganizationSubFamily[]> {
    return this.store$.pipe(select(OrganizationSubFamilySelectors.selectActiveOrganizationSubFamilys(schema))).pipe(
      switchMap(({ organizationSubFamilys }: { organizationSubFamilys: OrganizationSubFamily[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationSubFamilys));
      })
    );
  }

  public selectIdOrganizationSubFamilysActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationSubFamilySelectors.selectIdOrganizationSubFamilysActive)).pipe(
      switchMap((idOrganizationSubFamilys: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationSubFamilys));
      })
    );
  }

  public getOneOrganizationSubFamily(
    idOrganizationSubFamily: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationSubFamily> {
    this.store$.dispatch(
      OrganizationSubFamilyGeneratedActions.getOneOrganizationSubFamily({ idOrganizationSubFamily, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationSubFamilyGeneratedActions.normalizeManyOrganizationSubFamilysAfterUpsert,
        OrganizationSubFamilyGeneratedActions.organizationSubFamilysFailure,
        true
      );
    }
  }

  public getManyOrganizationSubFamilys(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationSubFamily[]> {
    this.store$.dispatch(OrganizationSubFamilyGeneratedActions.getManyOrganizationSubFamilys({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationSubFamilyGeneratedActions.normalizeManyOrganizationSubFamilysAfterUpsert,
        OrganizationSubFamilyGeneratedActions.organizationSubFamilysFailure
      );
    }
  }

  public upsertOneOrganizationSubFamily(
    organizationSubFamily: Partial<OrganizationSubFamily>,
    ids: OrganizationSubFamilyRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationSubFamily> {
    this.store$.dispatch(
      OrganizationSubFamilyGeneratedActions.upsertOneOrganizationSubFamily({ organizationSubFamily, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationSubFamilyGeneratedActions.normalizeManyOrganizationSubFamilysAfterUpsert,
        OrganizationSubFamilyGeneratedActions.organizationSubFamilysFailure,
        true
      );
    }
  }

  public deleteOneOrganizationSubFamily(
    idOrganizationSubFamily: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationSubFamilyGeneratedActions.deleteOneOrganizationSubFamily({ idOrganizationSubFamily })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationSubFamilyGeneratedActions.deleteOneOrganizationSubFamilySuccess,
        OrganizationSubFamilyGeneratedActions.organizationSubFamilysFailure
      );
    }
  }

  public setActiveOrganizationSubFamilys(idOrganizationSubFamilys: number[]): void {
    this.store$.dispatch(OrganizationSubFamilyGeneratedActions.clearActivesOrganizationSubFamilys());
    this.store$.dispatch(
      OrganizationSubFamilyGeneratedActions.addManyActivesOrganizationSubFamilys({ idOrganizationSubFamilys })
    );
  }
}
