type str = string | null | undefined;

export const removeAccent = (str: str): str => {
  if (!str) {
    return str;
  }
  return str.normalize('NFD').replace(/\p{Diacritic}/gu, '');
};

/**
 * @param str
 * @returns
 */
export const toLowerCaseAndWithoutAccent = (str: str): str => {
  if (!str) {
    return str;
  }
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
};

/**
 * Check is str2 is included in str1
 * @param str1
 * @param str2
 * @returns
 */
export const caseAndAccentInsensitiveStringComparison = (str1: str, str2: str): boolean => {
  str1 = toLowerCaseAndWithoutAccent(str1);
  str2 = toLowerCaseAndWithoutAccent(str2);

  return str1 && str2 ? str1.includes(str2) : false;
};
