import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { GanttLinkGeneratedActions } from '@wip/store/actions';
import { GanttLinkState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { GanttLinkEntityState } from '@api/api-interfaces';

export const ganttLinkReducersGeneratedFunctions: ReducerTypes<GanttLinkState.IState, readonly ActionCreator[]>[] = [
  on(GanttLinkGeneratedActions.getOneGanttLink, (state: GanttLinkState.IState) => setLoadingsState(state, true)),
  on(GanttLinkGeneratedActions.getManyGanttLinks, (state: GanttLinkState.IState) => setLoadingsState(state, true)),
  on(GanttLinkGeneratedActions.upsertOneGanttLink, (state: GanttLinkState.IState) => setLoadingsState(state, true)),

  on(GanttLinkGeneratedActions.upsertManyGanttLinksSuccess, (state: GanttLinkState.IState, { ganttLinks }) =>
    GanttLinkState.adapter.upsertMany(ganttLinks, setLoadingsState(state, false))
  ),
  on(GanttLinkGeneratedActions.deleteOneGanttLink, (state: GanttLinkState.IState) => setLoadingsState(state, true)),
  on(GanttLinkGeneratedActions.deleteOneGanttLinkSuccess, (state: GanttLinkState.IState, { idGanttLink }) =>
    GanttLinkState.adapter.removeOne(idGanttLink, setLoadingsState(state, false))
  ),
  on(GanttLinkGeneratedActions.clearActivesGanttLinks, (state: GanttLinkState.IState) => ({ ...state, actives: [] })),
  on(GanttLinkGeneratedActions.addManyActivesGanttLinks, (state: GanttLinkState.IState, { idGanttLinks }) => ({
    ...state,
    actives: state.actives.concat(idGanttLinks)
  })),
  on(GanttLinkGeneratedActions.deleteOneActiveGanttLink, (state: GanttLinkState.IState, { idGanttLink }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idGanttLink)
  })),

  on(GanttLinkGeneratedActions.clearGanttLinks, () => GanttLinkState.initialState),

  on(GanttLinkGeneratedActions.addCommunitySuccess, (state: GanttLinkState.IState, { idGanttLink, idCommunity }) => {
    if (!state.entities[idGanttLink]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idGanttLink]: {
          ...state.entities[idGanttLink],
          community: idCommunity
        }
      }
    };
  }),

  on(
    GanttLinkGeneratedActions.deleteManyCommunitySuccess,
    (state: GanttLinkState.IState, { idCommunities, idGanttLinks }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGanttLinks.reduce((entities, idGanttLink) => {
            if (!state.entities[idGanttLink]?.community) {
              return entities;
            }
            entities[idGanttLink] = {
              ...state.entities[idGanttLink],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idGanttLink]?.community
              )
                ? undefined
                : state.entities[idGanttLink]?.community
            } as GanttLinkEntityState;
            return entities;
          }, {} as Dictionary<GanttLinkEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: GanttLinkState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): GanttLinkState.IState {
  return { ...state, isLoaded, isLoading };
}
