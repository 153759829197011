import { CdkDragDrop, CdkDragMove, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Community, OrganizationMilestone, OrganizationStep } from '@api/api-interfaces';
import { CommunityService } from '@wip/store/services';
import { Subject } from 'rxjs';
import { DeviceInformationsService } from '../../../../core/device/device-information.service';

@Component({
  selector: 'app-list-kanban',
  templateUrl: './list-kanban.component.html',
  styleUrls: ['./list-kanban.component.scss']
})
export class ListKanbanComponent implements OnInit, OnChanges, OnDestroy {
  @Input() listStep: OrganizationStep[]; // List
  @Input() listProject: Community[]; // Card
  @Input() listMilestones: OrganizationMilestone[];
  @Input() idOrganization: number;
  @Output() saveScroll: EventEmitter<void> = new EventEmitter();
  @Output() loadScroll: EventEmitter<void> = new EventEmitter();

  @ViewChild('addStepInput') addStepInput: ElementRef;
  @ViewChild('titleInput') titleInput: ElementRef;

  public showList = false;
  public listFormGroup: FormGroup;
  public connectedList: string[];
  public entities: { projectStep: OrganizationStep | Partial<OrganizationStep>; projects: Community[] }[] = [];
  public unclassifiedStep: Partial<OrganizationStep> = {
    idOrganizationStep: -1,
    libelle: 'Non classé'
  };
  public titleToEdit: number = null;

  private destroy$: Subject<void> = new Subject();
  private scrollInterval;

  constructor(
    private newCommunityService: CommunityService,
    public deviceInformationService: DeviceInformationsService
  ) {}

  ngOnInit() {
    this.loadScroll.emit();
    this.initializeForm();
  }

  ngOnDestroy() {
    this.saveScroll.emit();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.listStep || !!changes.listProject) {
      this.initializeData(!!changes.listStep);
    }
  }

  private initializeForm() {
    this.listFormGroup = new FormGroup({
      stepName: new FormControl(null, { validators: [Validators.required] })
    });
  }

  private initializeData(listStepChanged: boolean) {
    if (listStepChanged) {
      this.listStep.sort(
        (projectStepA: OrganizationStep, projectStepB: OrganizationStep) => projectStepA.ordre - projectStepB.ordre
      );
    }
    this.entities = [];
    this.listStep.forEach((projectStep: OrganizationStep) => {
      const listProjectAssociated: Community[] = this.getListProjectAssociated(projectStep);
      this.entities.push({ projectStep, projects: listProjectAssociated });
    });

    const classifiedProjects = this.entities.flatMap(el => el.projects);
    const unclassifiedProjects = this.listProject.filter(el => !classifiedProjects.includes(el));

    this.entities.push({ projectStep: this.unclassifiedStep, projects: unclassifiedProjects });
    this.connectedList = this.entities.map(({ projectStep }) => `${projectStep.idOrganizationStep}`);
  }

  private getListProjectAssociated(projectStep: OrganizationStep): Community[] {
    let projects: Community[] = [];
    if (this.listProject) {
      projects = this.listProject.filter((project: Community) =>
        !!project.idOrganizationFamily
          ? project.idOrganizationStep === projectStep.idOrganizationStep
          : projectStep.idOrganizationStep === 0
      );
      projects.sort((projectA: Community, projectB: Community) => {
        return projectA.nom.toLowerCase().localeCompare(projectB.nom.toLowerCase());
      });
    }
    return projects;
  }

  public dropProject(event: CdkDragDrop<Community[]>) {
    if (event.previousContainer !== event.container) {
      this.updateProjectCurrentStep(event);
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  private updateProjectCurrentStep(event: CdkDragDrop<Community[]>) {
    this.newCommunityService.upsertOneCommunity({
      idCommunity: event.previousContainer.data[event.previousIndex].idCommunity,
      idOrganizationStep: +event.container.id === -1 ? null : +event.container.id
    });
  }

  public dropCurrentStep(event: CdkDragDrop<OrganizationStep[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.entities, event.previousIndex, event.currentIndex);
      this.updateCurrentStepsOrder();
    }
  }

  // public onClickAddList() {
  //   this.newOrganizationStepService.upsertOneOrganizationStep({
  //     idOrganization: this.userChoicesFormGroup.get('organization').value.idOrganization,
  //     libelle: this.translate.instant('template.kanban.Unclassified'),
  //     ordre: this.listStep.length + 1,
  //     idOrganizationFamily: this.userChoicesFormGroup.get('projectFamily').value.idOrganizationFamily
  //   });
  //   this.listFormGroup.get('stepName').patchValue(null);
  // }

  private updateCurrentStepsOrder() {
    const newEntities: Partial<OrganizationStep>[] = [];
    this.entities.forEach(({ projectStep }, index) => {
      projectStep.ordre = index + 1;
      newEntities.push(projectStep as unknown as Partial<OrganizationStep>);
    });
    newEntities.pop();
  }

  public onDragEnd() {
    clearInterval(this.scrollInterval);
    this.scrollInterval = null;
  }

  public onDragMoved(event: CdkDragMove) {
    const mouseX = event.pointerPosition.x;

    // Position de l'élément par rapport à la fenêtre

    const scrollable = document.getElementById('scrollable');
    const scrollStep = 10;
    const isNearRight = mouseX > window.innerWidth - 50;
    const isNotNearRight = !isNearRight;
    const isNearLeft = mouseX < 150;
    const isNotNearLeft = !isNearLeft;

    if (isNearRight && !this.scrollInterval) {
      this.scrollInterval = setInterval(_ => {
        scrollable.scrollLeft += scrollStep;
      }, 20);
      scrollable.scrollLeft += scrollStep;
    } else if (isNearLeft && !this.scrollInterval) {
      this.scrollInterval = setInterval(_ => {
        scrollable.scrollLeft -= scrollStep;
      }, 20);
      scrollable.scrollLeft -= scrollStep;
    } else if (isNotNearLeft && isNotNearRight) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    }
  }
}
