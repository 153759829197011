import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Folder, FolderEntityState } from '@api/api-interfaces';
import { FolderElement, FolderElementEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { FolderState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const folderRelations: string[] = ['folderElements', 'communities'];

export const { selectEntities, selectAll } = FolderState.adapter.getSelectors();

export const selectFolderState = createFeatureSelector<FolderState.IState>(FolderState.folderFeatureKey);

export const selectIsLoadedFolder = createSelector(selectFolderState, (state: FolderState.IState) => state.isLoaded);

export const selectIsLoadingFolder = createSelector(selectFolderState, (state: FolderState.IState) => state.isLoading);

export const selectIsReadyFolder = createSelector(selectFolderState, (state: FolderState.IState) => !state.isLoading);

export const selectIsReadyAndLoadedFolder = createSelector(
  selectFolderState,
  (state: FolderState.IState) => state.isLoaded && !state.isLoading
);

export const selectFoldersEntities = createSelector(selectFolderState, selectEntities);

export const selectFoldersArray = createSelector(selectFolderState, selectAll);

export const selectIdFoldersActive = createSelector(selectFolderState, (state: FolderState.IState) => state.actives);

const foldersInObject = (folders: Dictionary<FolderEntityState>) => ({ folders });

const selectFoldersEntitiesDictionary = createSelector(selectFoldersEntities, foldersInObject);

const selectAllFoldersObject = createSelector(selectFoldersEntities, folders => {
  return hydrateAll({ folders });
});

const selectOneFolderDictionary = (idFolder: number) =>
  createSelector(selectFoldersEntities, folders => ({
    folders: { [idFolder]: folders[idFolder] }
  }));

const selectOneFolderDictionaryWithoutChild = (idFolder: number) =>
  createSelector(selectFoldersEntities, folders => ({
    folder: folders[idFolder]
  }));

const selectActiveFoldersEntities = createSelector(
  selectIdFoldersActive,
  selectFoldersEntities,
  (actives: number[], folders: Dictionary<FolderEntityState>) => getFoldersFromActives(actives, folders)
);

function getFoldersFromActives(
  actives: number[],
  folders: Dictionary<FolderEntityState>
): Dictionary<FolderEntityState> {
  return actives.reduce((acc, idActive) => {
    if (folders[idActive]) {
      acc[idActive] = folders[idActive];
    }
    return acc;
  }, {} as Dictionary<FolderEntityState>);
}

const selectAllFoldersSelectors: Dictionary<Selector> = {};
export function selectAllFolders(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Folder>(
      schema,
      selectAllFoldersSelectors,
      selectFoldersEntitiesDictionary,
      getRelationSelectors,
      folderRelations,
      hydrateAll,
      'folder'
    );
  } else {
    return selectAllFoldersObject;
  }
}

export function selectAllFoldersDictionary(schema: SelectSchema = {}, customKey: string = 'folders'): Selector {
  return createSelector(selectAllFolders(schema), result => {
    const res = { [customKey]: {} as Dictionary<FolderEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.folders.length; i++) {
      res[customKey][result.folders[i].idFolder] = result.folders[i];
    }
    return res;
  });
}

export function selectOneFolder(schema: SelectSchema = {}, idFolder: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneFolderDictionary(idFolder)];
    selectors.push(...getRelationSelectors(schema, folderRelations, 'folder'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneFolderDictionaryWithoutChild(idFolder);
  }
}

export function selectActiveFolders(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveFoldersEntities, folders => ({
      folders
    }))
  ];
  selectors.push(...getRelationSelectors(schema, folderRelations, 'folder'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  folders: Dictionary<FolderEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  folderElements?: Dictionary<FolderElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { folders: (Folder | null)[] } {
  const { folders, communities, folderElements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    folders: Object.keys(folders).map(idFolder =>
      hydrate(folders[idFolder] as FolderEntityState, communities, folderElements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { folder: FolderEntityState | null } {
  const { folders, communities, folderElements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const folder = Object.values(folders)[0];
  return {
    folder: hydrate(folder as FolderEntityState, communities, folderElements)
  };
}

function hydrate(
  folder: FolderEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  folderElementEntities?: Dictionary<FolderElementEntityState>
): Folder | null {
  if (!folder) {
    return null;
  }

  const folderHydrated: FolderEntityState = { ...folder };
  if (communityEntities) {
    folderHydrated.community = communityEntities[folder.community as number] as Community;
  } else {
    delete folderHydrated.community;
  }

  if (folderElementEntities) {
    folderHydrated.folderElements = ((folderHydrated.folderElements as number[]) || []).map(
      id => folderElementEntities[id]
    ) as FolderElement[];
  } else {
    delete folderHydrated.folderElements;
  }

  return folderHydrated as Folder;
}
