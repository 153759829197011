import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { CommunityGroup, CommunityGroupEntityState } from '@api/api-interfaces';
import { UserGroup, UserGroupEntityState } from '@api/api-interfaces';
import { OrganizationAzure, OrganizationAzureEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { GroupState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const groupRelations: string[] = [
  'communityGroups',
  'userGroups',
  'organizationAzures',
  'users',
  'communities',
  'organizations'
];

export const { selectEntities, selectAll } = GroupState.adapter.getSelectors();

export const selectGroupState = createFeatureSelector<GroupState.IState>(GroupState.groupFeatureKey);

export const selectIsLoadedGroup = createSelector(selectGroupState, (state: GroupState.IState) => state.isLoaded);

export const selectIsLoadingGroup = createSelector(selectGroupState, (state: GroupState.IState) => state.isLoading);

export const selectIsReadyGroup = createSelector(selectGroupState, (state: GroupState.IState) => !state.isLoading);

export const selectIsReadyAndLoadedGroup = createSelector(
  selectGroupState,
  (state: GroupState.IState) => state.isLoaded && !state.isLoading
);

export const selectGroupsEntities = createSelector(selectGroupState, selectEntities);

export const selectGroupsArray = createSelector(selectGroupState, selectAll);

export const selectIdGroupsActive = createSelector(selectGroupState, (state: GroupState.IState) => state.actives);

const groupsInObject = (groups: Dictionary<GroupEntityState>) => ({ groups });

const selectGroupsEntitiesDictionary = createSelector(selectGroupsEntities, groupsInObject);

const selectAllGroupsObject = createSelector(selectGroupsEntities, groups => {
  return hydrateAll({ groups });
});

const selectOneGroupDictionary = (idGroup: number) =>
  createSelector(selectGroupsEntities, groups => ({
    groups: { [idGroup]: groups[idGroup] }
  }));

const selectOneGroupDictionaryWithoutChild = (idGroup: number) =>
  createSelector(selectGroupsEntities, groups => ({
    group: groups[idGroup]
  }));

const selectActiveGroupsEntities = createSelector(
  selectIdGroupsActive,
  selectGroupsEntities,
  (actives: number[], groups: Dictionary<GroupEntityState>) => getGroupsFromActives(actives, groups)
);

function getGroupsFromActives(actives: number[], groups: Dictionary<GroupEntityState>): Dictionary<GroupEntityState> {
  return actives.reduce((acc, idActive) => {
    if (groups[idActive]) {
      acc[idActive] = groups[idActive];
    }
    return acc;
  }, {} as Dictionary<GroupEntityState>);
}

const selectAllGroupsSelectors: Dictionary<Selector> = {};
export function selectAllGroups(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Group>(
      schema,
      selectAllGroupsSelectors,
      selectGroupsEntitiesDictionary,
      getRelationSelectors,
      groupRelations,
      hydrateAll,
      'group'
    );
  } else {
    return selectAllGroupsObject;
  }
}

export function selectAllGroupsDictionary(schema: SelectSchema = {}, customKey: string = 'groups'): Selector {
  return createSelector(selectAllGroups(schema), result => {
    const res = { [customKey]: {} as Dictionary<GroupEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.groups.length; i++) {
      res[customKey][result.groups[i].idGroup] = result.groups[i];
    }
    return res;
  });
}

export function selectOneGroup(schema: SelectSchema = {}, idGroup: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneGroupDictionary(idGroup)];
    selectors.push(...getRelationSelectors(schema, groupRelations, 'group'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneGroupDictionaryWithoutChild(idGroup);
  }
}

export function selectActiveGroups(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveGroupsEntities, groups => ({
      groups
    }))
  ];
  selectors.push(...getRelationSelectors(schema, groupRelations, 'group'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  groups: Dictionary<GroupEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  communityGroups?: Dictionary<CommunityGroupEntityState>;
  userGroups?: Dictionary<UserGroupEntityState>;
  organizationAzures?: Dictionary<OrganizationAzureEntityState>;
  users?: Dictionary<UserEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { groups: (Group | null)[] } {
  const { groups, organizations, communityGroups, userGroups, organizationAzures, users, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    groups: Object.keys(groups).map(idGroup =>
      hydrate(
        groups[idGroup] as GroupEntityState,
        organizations,
        communityGroups,
        userGroups,
        organizationAzures,
        users,
        communities
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { group: GroupEntityState | null } {
  const { groups, organizations, communityGroups, userGroups, organizationAzures, users, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const group = Object.values(groups)[0];
  return {
    group: hydrate(
      group as GroupEntityState,
      organizations,
      communityGroups,
      userGroups,
      organizationAzures,
      users,
      communities
    )
  };
}

function hydrate(
  group: GroupEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  communityGroupEntities?: Dictionary<CommunityGroupEntityState>,
  userGroupEntities?: Dictionary<UserGroupEntityState>,
  organizationAzureEntities?: Dictionary<OrganizationAzureEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>
): Group | null {
  if (!group) {
    return null;
  }

  const groupHydrated: GroupEntityState = { ...group };
  if (organizationEntities) {
    groupHydrated.organization = organizationEntities[group.organization as number] as Organization;
  } else {
    delete groupHydrated.organization;
  }

  if (communityGroupEntities) {
    groupHydrated.communityGroups = ((groupHydrated.communityGroups as number[]) || []).map(
      id => communityGroupEntities[id]
    ) as CommunityGroup[];
  } else {
    delete groupHydrated.communityGroups;
  }

  if (userGroupEntities) {
    groupHydrated.userGroups = ((groupHydrated.userGroups as number[]) || []).map(
      id => userGroupEntities[id]
    ) as UserGroup[];
  } else {
    delete groupHydrated.userGroups;
  }

  if (organizationAzureEntities) {
    groupHydrated.organizationAzures = ((groupHydrated.organizationAzures as number[]) || []).map(
      id => organizationAzureEntities[id]
    ) as OrganizationAzure[];
  } else {
    delete groupHydrated.organizationAzures;
  }

  if (userEntities) {
    groupHydrated.users = ((groupHydrated.users as number[]) || []).map(id => userEntities[id]) as User[];
  } else {
    delete groupHydrated.users;
  }

  if (communityEntities) {
    groupHydrated.communities = ((groupHydrated.communities as number[]) || []).map(
      id => communityEntities[id]
    ) as Community[];
  } else {
    delete groupHydrated.communities;
  }

  return groupHydrated as Group;
}
