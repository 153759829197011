import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationApiService } from '@wip/store/api-services';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganization } from '@wip/store/configs/normalization';
import { OrganizationSelectors } from '@wip/store/selectors';
import { OrganizationRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { Community } from '@api/api-interfaces';
import { OrganizationAzureGeneratedActions } from '@wip/store/actions';
import { OrganizationAzure } from '@api/api-interfaces';
import { OrganizationElementGeneratedActions } from '@wip/store/actions';
import { OrganizationElement } from '@api/api-interfaces';
import { GroupGeneratedActions } from '@wip/store/actions';
import { Group } from '@api/api-interfaces';
import { OrganizationUserGeneratedActions } from '@wip/store/actions';
import { OrganizationUser } from '@api/api-interfaces';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationFamily } from '@api/api-interfaces';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestone } from '@api/api-interfaces';
import { OrganizationRiskGeneratedActions } from '@wip/store/actions';
import { OrganizationRisk } from '@api/api-interfaces';
import { OrganizationKpiGeneratedActions } from '@wip/store/actions';
import { OrganizationKpi } from '@api/api-interfaces';
import { OrganizationSubFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationSubFamily } from '@api/api-interfaces';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristic } from '@api/api-interfaces';
import { OrganizationUserProfilGeneratedActions } from '@wip/store/actions';
import { OrganizationUserProfil } from '@api/api-interfaces';
import { OrganizationStepGeneratedActions } from '@wip/store/actions';
import { OrganizationStep } from '@api/api-interfaces';
import { OrganizationProjectModuleGeneratedActions } from '@wip/store/actions';
import { OrganizationProjectModule } from '@api/api-interfaces';
import { OrganizationSectionGeneratedActions } from '@wip/store/actions';
import { OrganizationSection } from '@api/api-interfaces';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { Profil } from '@api/api-interfaces';
import { UserGeneratedActions } from '@wip/store/actions';
import { User } from '@api/api-interfaces';
import { SectionGeneratedActions } from '@wip/store/actions';
import { Section } from '@api/api-interfaces';

export function getDefaultAddOrganizationActions(
  organization: OrganizationEntityState,
  ids?: OrganizationRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert({ organizations: [organization] })
  ];

  if (ids?.communities) {
    if (!Array.isArray(ids.communities)) {
      actions.push(
        CommunityGeneratedActions.upsertOneCommunity({
          community: {
            idOrganization: organization.idOrganization,
            idCommunity: ids.communities as number
          } as Community
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyCommunitySuccess({
          idOrganization: organization.idOrganization,
          idCommunities: [ids.communities as number]
        })
      );
    } else {
      actions.push(
        CommunityGeneratedActions.upsertManyCommunities({
          communities: (ids.communities as number[]).map((idCommunity: number) => ({
            idOrganization: organization.idOrganization,
            idCommunity
          })) as Community[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyCommunitySuccess({
          idOrganization: organization.idOrganization,
          idCommunities: ids.communities as number[]
        })
      );
    }
  }

  if (ids?.organizationAzures) {
    if (!Array.isArray(ids.organizationAzures)) {
      actions.push(
        OrganizationAzureGeneratedActions.upsertOneOrganizationAzure({
          organizationAzure: {
            idOrganization: organization.idOrganization,
            idOrganizationAzure: ids.organizationAzures as number
          } as OrganizationAzure
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationAzureSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationAzures: [ids.organizationAzures as number]
        })
      );
    } else {
      actions.push(
        OrganizationAzureGeneratedActions.upsertManyOrganizationAzures({
          organizationAzures: (ids.organizationAzures as number[]).map((idOrganizationAzure: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationAzure
          })) as OrganizationAzure[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationAzureSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationAzures: ids.organizationAzures as number[]
        })
      );
    }
  }

  if (ids?.organizationElements) {
    if (!Array.isArray(ids.organizationElements)) {
      actions.push(
        OrganizationElementGeneratedActions.upsertOneOrganizationElement({
          organizationElement: {
            idOrganization: organization.idOrganization,
            idOrganizationElement: ids.organizationElements as number
          } as OrganizationElement
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationElementSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationElements: [ids.organizationElements as number]
        })
      );
    } else {
      actions.push(
        OrganizationElementGeneratedActions.upsertManyOrganizationElements({
          organizationElements: (ids.organizationElements as number[]).map((idOrganizationElement: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationElement
          })) as OrganizationElement[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationElementSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationElements: ids.organizationElements as number[]
        })
      );
    }
  }

  if (ids?.groups) {
    if (!Array.isArray(ids.groups)) {
      actions.push(
        GroupGeneratedActions.upsertOneGroup({
          group: {
            idOrganization: organization.idOrganization,
            idGroup: ids.groups as number
          } as Group
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyGroupSuccess({
          idOrganization: organization.idOrganization,
          idGroups: [ids.groups as number]
        })
      );
    } else {
      actions.push(
        GroupGeneratedActions.upsertManyGroups({
          groups: (ids.groups as number[]).map((idGroup: number) => ({
            idOrganization: organization.idOrganization,
            idGroup
          })) as Group[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyGroupSuccess({
          idOrganization: organization.idOrganization,
          idGroups: ids.groups as number[]
        })
      );
    }
  }

  if (ids?.organizationUsers) {
    if (!Array.isArray(ids.organizationUsers)) {
      actions.push(
        OrganizationUserGeneratedActions.upsertOneOrganizationUser({
          organizationUser: {
            idOrganization: organization.idOrganization,
            idOrganizationUser: ids.organizationUsers as number
          } as OrganizationUser
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationUserSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationUsers: [ids.organizationUsers as number]
        })
      );
    } else {
      actions.push(
        OrganizationUserGeneratedActions.upsertManyOrganizationUsers({
          organizationUsers: (ids.organizationUsers as number[]).map((idOrganizationUser: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationUser
          })) as OrganizationUser[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationUserSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationUsers: ids.organizationUsers as number[]
        })
      );
    }
  }

  if (ids?.organizationFamilys) {
    if (!Array.isArray(ids.organizationFamilys)) {
      actions.push(
        OrganizationFamilyGeneratedActions.upsertOneOrganizationFamily({
          organizationFamily: {
            idOrganization: organization.idOrganization,
            idOrganizationFamily: ids.organizationFamilys as number
          } as OrganizationFamily
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationFamilySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationFamilys: [ids.organizationFamilys as number]
        })
      );
    } else {
      actions.push(
        OrganizationFamilyGeneratedActions.upsertManyOrganizationFamilys({
          organizationFamilys: (ids.organizationFamilys as number[]).map((idOrganizationFamily: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationFamily
          })) as OrganizationFamily[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationFamilySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationFamilys: ids.organizationFamilys as number[]
        })
      );
    }
  }

  if (ids?.organizationMilestoneFamilys) {
    if (!Array.isArray(ids.organizationMilestoneFamilys)) {
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.upsertOneOrganizationMilestoneFamily({
          organizationMilestoneFamily: {
            idOrganization: organization.idOrganization,
            idOrganizationMilestoneFamily: ids.organizationMilestoneFamilys as number
          } as OrganizationMilestoneFamily
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationMilestoneFamilys: [ids.organizationMilestoneFamilys as number]
        })
      );
    } else {
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.upsertManyOrganizationMilestoneFamilys({
          organizationMilestoneFamilys: (ids.organizationMilestoneFamilys as number[]).map(
            (idOrganizationMilestoneFamily: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationMilestoneFamily
            })
          ) as OrganizationMilestoneFamily[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationMilestoneFamilys: ids.organizationMilestoneFamilys as number[]
        })
      );
    }
  }

  if (ids?.organizationMilestones) {
    if (!Array.isArray(ids.organizationMilestones)) {
      actions.push(
        OrganizationMilestoneGeneratedActions.upsertOneOrganizationMilestone({
          organizationMilestone: {
            idOrganization: organization.idOrganization,
            idOrganizationMilestone: ids.organizationMilestones as number
          } as OrganizationMilestone
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationMilestoneSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationMilestones: [ids.organizationMilestones as number]
        })
      );
    } else {
      actions.push(
        OrganizationMilestoneGeneratedActions.upsertManyOrganizationMilestones({
          organizationMilestones: (ids.organizationMilestones as number[]).map((idOrganizationMilestone: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationMilestone
          })) as OrganizationMilestone[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationMilestoneSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationMilestones: ids.organizationMilestones as number[]
        })
      );
    }
  }

  if (ids?.organizationRisks) {
    if (!Array.isArray(ids.organizationRisks)) {
      actions.push(
        OrganizationRiskGeneratedActions.upsertOneOrganizationRisk({
          organizationRisk: {
            idOrganization: organization.idOrganization,
            idOrganizationRisk: ids.organizationRisks as number
          } as OrganizationRisk
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationRiskSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationRisks: [ids.organizationRisks as number]
        })
      );
    } else {
      actions.push(
        OrganizationRiskGeneratedActions.upsertManyOrganizationRisks({
          organizationRisks: (ids.organizationRisks as number[]).map((idOrganizationRisk: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationRisk
          })) as OrganizationRisk[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationRiskSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationRisks: ids.organizationRisks as number[]
        })
      );
    }
  }

  if (ids?.organizationKpis) {
    if (!Array.isArray(ids.organizationKpis)) {
      actions.push(
        OrganizationKpiGeneratedActions.upsertOneOrganizationKpi({
          organizationKpi: {
            idOrganization: organization.idOrganization,
            idOrganizationKpi: ids.organizationKpis as number
          } as OrganizationKpi
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationKpiSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationKpis: [ids.organizationKpis as number]
        })
      );
    } else {
      actions.push(
        OrganizationKpiGeneratedActions.upsertManyOrganizationKpis({
          organizationKpis: (ids.organizationKpis as number[]).map((idOrganizationKpi: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationKpi
          })) as OrganizationKpi[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationKpiSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationKpis: ids.organizationKpis as number[]
        })
      );
    }
  }

  if (ids?.organizationSubFamilys) {
    if (!Array.isArray(ids.organizationSubFamilys)) {
      actions.push(
        OrganizationSubFamilyGeneratedActions.upsertOneOrganizationSubFamily({
          organizationSubFamily: {
            idOrganization: organization.idOrganization,
            idOrganizationSubFamily: ids.organizationSubFamilys as number
          } as OrganizationSubFamily
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationSubFamilySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSubFamilys: [ids.organizationSubFamilys as number]
        })
      );
    } else {
      actions.push(
        OrganizationSubFamilyGeneratedActions.upsertManyOrganizationSubFamilys({
          organizationSubFamilys: (ids.organizationSubFamilys as number[]).map((idOrganizationSubFamily: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationSubFamily
          })) as OrganizationSubFamily[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationSubFamilySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSubFamilys: ids.organizationSubFamilys as number[]
        })
      );
    }
  }

  if (ids?.organizationCaracteristics) {
    if (!Array.isArray(ids.organizationCaracteristics)) {
      actions.push(
        OrganizationCaracteristicGeneratedActions.upsertOneOrganizationCaracteristic({
          organizationCaracteristic: {
            idOrganization: organization.idOrganization,
            idOrganizationCaracteristic: ids.organizationCaracteristics as number
          } as OrganizationCaracteristic
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationCaracteristicSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationCaracteristics: [ids.organizationCaracteristics as number]
        })
      );
    } else {
      actions.push(
        OrganizationCaracteristicGeneratedActions.upsertManyOrganizationCaracteristics({
          organizationCaracteristics: (ids.organizationCaracteristics as number[]).map(
            (idOrganizationCaracteristic: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationCaracteristic
            })
          ) as OrganizationCaracteristic[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationCaracteristicSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationCaracteristics: ids.organizationCaracteristics as number[]
        })
      );
    }
  }

  if (ids?.organizationUserProfils) {
    if (!Array.isArray(ids.organizationUserProfils)) {
      actions.push(
        OrganizationUserProfilGeneratedActions.upsertOneOrganizationUserProfil({
          organizationUserProfil: {
            idOrganization: organization.idOrganization,
            idOrganizationUserProfil: ids.organizationUserProfils as number
          } as OrganizationUserProfil
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationUserProfilSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationUserProfils: [ids.organizationUserProfils as number]
        })
      );
    } else {
      actions.push(
        OrganizationUserProfilGeneratedActions.upsertManyOrganizationUserProfils({
          organizationUserProfils: (ids.organizationUserProfils as number[]).map(
            (idOrganizationUserProfil: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationUserProfil
            })
          ) as OrganizationUserProfil[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationUserProfilSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationUserProfils: ids.organizationUserProfils as number[]
        })
      );
    }
  }

  if (ids?.organizationSteps) {
    if (!Array.isArray(ids.organizationSteps)) {
      actions.push(
        OrganizationStepGeneratedActions.upsertOneOrganizationStep({
          organizationStep: {
            idOrganization: organization.idOrganization,
            idOrganizationStep: ids.organizationSteps as number
          } as OrganizationStep
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationStepSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSteps: [ids.organizationSteps as number]
        })
      );
    } else {
      actions.push(
        OrganizationStepGeneratedActions.upsertManyOrganizationSteps({
          organizationSteps: (ids.organizationSteps as number[]).map((idOrganizationStep: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationStep
          })) as OrganizationStep[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationStepSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSteps: ids.organizationSteps as number[]
        })
      );
    }
  }

  if (ids?.organizationProjectModules) {
    if (!Array.isArray(ids.organizationProjectModules)) {
      actions.push(
        OrganizationProjectModuleGeneratedActions.upsertOneOrganizationProjectModule({
          organizationProjectModule: {
            idOrganization: organization.idOrganization,
            idOrganizationProjectModule: ids.organizationProjectModules as number
          } as OrganizationProjectModule
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationProjectModuleSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationProjectModules: [ids.organizationProjectModules as number]
        })
      );
    } else {
      actions.push(
        OrganizationProjectModuleGeneratedActions.upsertManyOrganizationProjectModules({
          organizationProjectModules: (ids.organizationProjectModules as number[]).map(
            (idOrganizationProjectModule: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationProjectModule
            })
          ) as OrganizationProjectModule[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationProjectModuleSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationProjectModules: ids.organizationProjectModules as number[]
        })
      );
    }
  }

  if (ids?.organizationSections) {
    if (!Array.isArray(ids.organizationSections)) {
      actions.push(
        OrganizationSectionGeneratedActions.upsertOneOrganizationSection({
          organizationSection: {
            idOrganization: organization.idOrganization,
            idOrganizationSection: ids.organizationSections as number
          } as OrganizationSection
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationSectionSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSections: [ids.organizationSections as number]
        })
      );
    } else {
      actions.push(
        OrganizationSectionGeneratedActions.upsertManyOrganizationSections({
          organizationSections: (ids.organizationSections as number[]).map((idOrganizationSection: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationSection
          })) as OrganizationSection[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyOrganizationSectionSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSections: ids.organizationSections as number[]
        })
      );
    }
  }

  if (ids?.profils) {
    if (!Array.isArray(ids.profils)) {
      actions.push(
        ProfilGeneratedActions.upsertOneProfil({
          profil: {
            idOrganization: organization.idOrganization,
            idProfil: ids.profils as number
          } as Profil
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyProfilSuccess({
          idOrganization: organization.idOrganization,
          idProfils: [ids.profils as number]
        })
      );
    } else {
      actions.push(
        ProfilGeneratedActions.upsertManyProfils({
          profils: (ids.profils as number[]).map((idProfil: number) => ({
            idOrganization: organization.idOrganization,
            idProfil
          })) as Profil[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyProfilSuccess({
          idOrganization: organization.idOrganization,
          idProfils: ids.profils as number[]
        })
      );
    }
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        UserGeneratedActions.upsertOneUser({
          user: {
            idOrganization: organization.idOrganization,
            idUser: ids.users as number
          } as User & any
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyUserSuccess({
          idOrganization: organization.idOrganization,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        UserGeneratedActions.upsertManyUsers({
          users: (ids.users as number[]).map((idUser: number) => ({
            idOrganization: organization.idOrganization,
            idUser
          })) as User[] & any[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManyUserSuccess({
          idOrganization: organization.idOrganization,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.sections) {
    if (!Array.isArray(ids.sections)) {
      actions.push(
        SectionGeneratedActions.upsertOneSection({
          section: {
            idOrganization: organization.idOrganization,
            idSection: ids.sections as number
          } as Section & any
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManySectionSuccess({
          idOrganization: organization.idOrganization,
          idSections: [ids.sections as number]
        })
      );
    } else {
      actions.push(
        SectionGeneratedActions.upsertManySections({
          sections: (ids.sections as number[]).map((idSection: number) => ({
            idOrganization: organization.idOrganization,
            idSection
          })) as Section[] & any[]
        })
      );
      actions.push(
        OrganizationGeneratedActions.addManySectionSuccess({
          idOrganization: organization.idOrganization,
          idSections: ids.sections as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationActions(organization: OrganizationEntityState): Action[] {
  const actions: Action[] = [
    OrganizationGeneratedActions.deleteOneOrganizationSuccess({ idOrganization: organization.idOrganization })
  ];

  if (organization.communities) {
    actions.push(
      CommunityGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idCommunities: organization.communities as number[]
      })
    );
  }

  if (organization.organizationAzures) {
    actions.push(
      OrganizationAzureGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationAzures: organization.organizationAzures as number[]
      })
    );
  }

  if (organization.organizationElements) {
    actions.push(
      OrganizationElementGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationElements: organization.organizationElements as number[]
      })
    );
  }

  if (organization.groups) {
    actions.push(
      GroupGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idGroups: organization.groups as number[]
      })
    );
  }

  if (organization.organizationUsers) {
    actions.push(
      OrganizationUserGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationUsers: organization.organizationUsers as number[]
      })
    );
  }

  if (organization.organizationFamilys) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationFamilys: organization.organizationFamilys as number[]
      })
    );
  }

  if (organization.organizationMilestoneFamilys) {
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationMilestoneFamilys: organization.organizationMilestoneFamilys as number[]
      })
    );
  }

  if (organization.organizationMilestones) {
    actions.push(
      OrganizationMilestoneGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationMilestones: organization.organizationMilestones as number[]
      })
    );
  }

  if (organization.organizationRisks) {
    actions.push(
      OrganizationRiskGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationRisks: organization.organizationRisks as number[]
      })
    );
  }

  if (organization.organizationKpis) {
    actions.push(
      OrganizationKpiGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationKpis: organization.organizationKpis as number[]
      })
    );
  }

  if (organization.organizationSubFamilys) {
    actions.push(
      OrganizationSubFamilyGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationSubFamilys: organization.organizationSubFamilys as number[]
      })
    );
  }

  if (organization.organizationCaracteristics) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationCaracteristics: organization.organizationCaracteristics as number[]
      })
    );
  }

  if (organization.organizationUserProfils) {
    actions.push(
      OrganizationUserProfilGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationUserProfils: organization.organizationUserProfils as number[]
      })
    );
  }

  if (organization.organizationSteps) {
    actions.push(
      OrganizationStepGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationSteps: organization.organizationSteps as number[]
      })
    );
  }

  if (organization.organizationProjectModules) {
    actions.push(
      OrganizationProjectModuleGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationProjectModules: organization.organizationProjectModules as number[]
      })
    );
  }

  if (organization.organizationSections) {
    actions.push(
      OrganizationSectionGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationSections: organization.organizationSections as number[]
      })
    );
  }

  if (organization.profils) {
    actions.push(
      ProfilGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idProfils: organization.profils as number[]
      })
    );
  }

  if (organization.users) {
    actions.push(
      UserGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idUsers: organization.users as number[]
      })
    );
  }

  if (organization.sections) {
    actions.push(
      SectionGeneratedActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idSections: organization.sections as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationEffects {
  constructor(
    protected actions$: Actions,
    protected organizationApiService: OrganizationApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationGeneratedActions.getManyOrganizations),
      switchMap(({ params }) =>
        this.organizationApiService.getOrganizations(params).pipe(
          map((organizations: Organization[]) => {
            return OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert({ organizations });
          }),
          catchError(error => of(OrganizationGeneratedActions.organizationsFailure({ error })))
        )
      )
    );
  });

  getOneOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationGeneratedActions.getOneOrganization),
      switchMap(idOrganization =>
        this.organizationApiService.getOrganization(idOrganization).pipe(
          map((organization: Organization) => {
            return OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert({
              organizations: [organization]
            });
          }),
          catchError(error => of(OrganizationGeneratedActions.organizationsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationGeneratedActions.upsertOneOrganization),
      concatMap(({ organization, ids }: { organization: Partial<Organization>; ids?: OrganizationRelationsIds }) => {
        if (organization.idOrganization) {
          return this.organizationApiService.updateOrganization(organization).pipe(
            map((organizationReturned: Organization) => {
              return OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert({
                organizations: [organizationReturned]
              });
            }),
            catchError(error => of(OrganizationGeneratedActions.organizationsFailure({ error })))
          );
        } else {
          return this.organizationApiService.addOrganization(organization).pipe(
            mergeMap((organizationReturned: Organization) =>
              getDefaultAddOrganizationActions(organizationReturned, ids)
            ),
            catchError(error => of(OrganizationGeneratedActions.organizationsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneOrganization$ = createEffect(() => {
    const selectOrganizationState$ = this.store$.select(OrganizationSelectors.selectOrganizationState);
    return this.actions$.pipe(
      ofType(OrganizationGeneratedActions.deleteOneOrganization),
      withLatestFrom(selectOrganizationState$),
      concatMap(([{ idOrganization }, state]) =>
        this.organizationApiService.deleteOrganization(idOrganization).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationActions(state.entities[idOrganization] as OrganizationEntityState)
          ),
          catchError(error => of(OrganizationGeneratedActions.organizationsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert),
      concatMap(({ organizations }) => {
        const actions: Action[] = getActionsToNormalizeOrganization(organizations, StoreActionType.upsert);
        return [getMultiAction(actions, '[Organization] Normalization After Upsert Success')];
      })
    );
  });
}
