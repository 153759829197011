import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Organization } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationSelectors.selectIsLoadedOrganization));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationSelectors.selectIsLoadingOrganization));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [OrganizationSelectors.selectIsReadyAndLoadedOrganization as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizations(schema: SelectSchema = {}): Observable<Organization[]> {
    return this.store$.pipe(select(OrganizationSelectors.selectAllOrganizations(schema))).pipe(
      switchMap(({ organizations }: { organizations: Organization[] }) => {
        return this.getReady(schema).pipe(mapTo(organizations));
      })
    );
  }

  public selectOneOrganization(idOrganization: number, schema: SelectSchema = {}): Observable<Organization> {
    return this.store$.pipe(select(OrganizationSelectors.selectOneOrganization(schema, idOrganization))).pipe(
      switchMap(({ organization }: { organization: Organization }) => {
        return this.getReady(schema).pipe(mapTo(organization));
      })
    );
  }

  public selectAllActiveOrganizations(schema: SelectSchema = {}): Observable<Organization[]> {
    return this.store$.pipe(select(OrganizationSelectors.selectActiveOrganizations(schema))).pipe(
      switchMap(({ organizations }: { organizations: Organization[] }) => {
        return this.getReady(schema).pipe(mapTo(organizations));
      })
    );
  }

  public selectIdOrganizationsActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationSelectors.selectIdOrganizationsActive)).pipe(
      switchMap((idOrganizations: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizations));
      })
    );
  }

  public getOneOrganization(
    idOrganization: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Organization> {
    this.store$.dispatch(OrganizationGeneratedActions.getOneOrganization({ idOrganization, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert,
        OrganizationGeneratedActions.organizationsFailure,
        true
      );
    }
  }

  public getManyOrganizations(params: any = {}, getResult?: boolean): void | Observable<Organization[]> {
    this.store$.dispatch(OrganizationGeneratedActions.getManyOrganizations({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert,
        OrganizationGeneratedActions.organizationsFailure
      );
    }
  }

  public upsertOneOrganization(
    organization: Partial<Organization>,
    ids: OrganizationRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Organization> {
    this.store$.dispatch(OrganizationGeneratedActions.upsertOneOrganization({ organization, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert,
        OrganizationGeneratedActions.organizationsFailure,
        true
      );
    }
  }

  public deleteOneOrganization(idOrganization: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(OrganizationGeneratedActions.deleteOneOrganization({ idOrganization }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationGeneratedActions.deleteOneOrganizationSuccess,
        OrganizationGeneratedActions.organizationsFailure
      );
    }
  }

  public setActiveOrganizations(idOrganizations: number[]): void {
    this.store$.dispatch(OrganizationGeneratedActions.clearActivesOrganizations());
    this.store$.dispatch(OrganizationGeneratedActions.addManyActivesOrganizations({ idOrganizations }));
  }
}
