import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { RightProfilGeneratedActions } from '@wip/store/actions';
import { RightProfilState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { RightProfilEntityState } from '@api/api-interfaces';

export const rightProfilReducersGeneratedFunctions: ReducerTypes<RightProfilState.IState, readonly ActionCreator[]>[] =
  [
    on(RightProfilGeneratedActions.getOneRightProfil, (state: RightProfilState.IState) =>
      setLoadingsState(state, true)
    ),
    on(RightProfilGeneratedActions.getManyRightProfils, (state: RightProfilState.IState) =>
      setLoadingsState(state, true)
    ),
    on(RightProfilGeneratedActions.upsertOneRightProfil, (state: RightProfilState.IState) =>
      setLoadingsState(state, true)
    ),

    on(RightProfilGeneratedActions.upsertManyRightProfilsSuccess, (state: RightProfilState.IState, { rightProfils }) =>
      RightProfilState.adapter.upsertMany(rightProfils, setLoadingsState(state, false))
    ),
    on(RightProfilGeneratedActions.deleteOneRightProfil, (state: RightProfilState.IState) =>
      setLoadingsState(state, true)
    ),
    on(RightProfilGeneratedActions.deleteOneRightProfilSuccess, (state: RightProfilState.IState, { idRightProfil }) =>
      RightProfilState.adapter.removeOne(idRightProfil, setLoadingsState(state, false))
    ),
    on(RightProfilGeneratedActions.clearActivesRightProfils, (state: RightProfilState.IState) => ({
      ...state,
      actives: []
    })),
    on(
      RightProfilGeneratedActions.addManyActivesRightProfils,
      (state: RightProfilState.IState, { idRightProfils }) => ({
        ...state,
        actives: state.actives.concat(idRightProfils)
      })
    ),
    on(RightProfilGeneratedActions.deleteOneActiveRightProfil, (state: RightProfilState.IState, { idRightProfil }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idRightProfil)
    })),

    on(RightProfilGeneratedActions.clearRightProfils, () => RightProfilState.initialState),

    on(RightProfilGeneratedActions.addRightSuccess, (state: RightProfilState.IState, { idRightProfil, idRight }) => {
      if (!state.entities[idRightProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idRightProfil]: {
            ...state.entities[idRightProfil],
            right: idRight
          }
        }
      };
    }),

    on(
      RightProfilGeneratedActions.deleteManyRightSuccess,
      (state: RightProfilState.IState, { idRights, idRightProfils }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idRightProfils.reduce((entities, idRightProfil) => {
              if (!state.entities[idRightProfil]?.right) {
                return entities;
              }
              entities[idRightProfil] = {
                ...state.entities[idRightProfil],
                right: idRights.some((idRight: number) => idRight === state.entities[idRightProfil]?.right)
                  ? undefined
                  : state.entities[idRightProfil]?.right
              } as RightProfilEntityState;
              return entities;
            }, {} as Dictionary<RightProfilEntityState>)
          }
        };
      }
    ),

    on(RightProfilGeneratedActions.addProfilSuccess, (state: RightProfilState.IState, { idRightProfil, idProfil }) => {
      if (!state.entities[idRightProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idRightProfil]: {
            ...state.entities[idRightProfil],
            profil: idProfil
          }
        }
      };
    }),

    on(
      RightProfilGeneratedActions.deleteManyProfilSuccess,
      (state: RightProfilState.IState, { idProfils, idRightProfils }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idRightProfils.reduce((entities, idRightProfil) => {
              if (!state.entities[idRightProfil]?.profil) {
                return entities;
              }
              entities[idRightProfil] = {
                ...state.entities[idRightProfil],
                profil: idProfils.some((idProfil: number) => idProfil === state.entities[idRightProfil]?.profil)
                  ? undefined
                  : state.entities[idRightProfil]?.profil
              } as RightProfilEntityState;
              return entities;
            }, {} as Dictionary<RightProfilEntityState>)
          }
        };
      }
    )
  ];

export function setLoadingsState(
  state: RightProfilState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): RightProfilState.IState {
  return { ...state, isLoaded, isLoading };
}
