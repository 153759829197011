import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationDataColumn, OrganizationDataColumnEntityState } from '@api/api-interfaces';
import { OrganizationDataColumnValue, OrganizationDataColumnValueEntityState } from '@api/api-interfaces';
import { Valeur, ValeurEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicRow, OrganizationCaracteristicRowEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationDataColumnState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationDataColumnRelations: string[] = [
  'organizationDataColumnValues',
  'valeurs',
  'organizationCaracteristicRows',
  'organizationCaracteristics',
  'organizationFamilys'
];

export const { selectEntities, selectAll } = OrganizationDataColumnState.adapter.getSelectors();

export const selectOrganizationDataColumnState = createFeatureSelector<OrganizationDataColumnState.IState>(
  OrganizationDataColumnState.organizationDataColumnFeatureKey
);

export const selectIsLoadedOrganizationDataColumn = createSelector(
  selectOrganizationDataColumnState,
  (state: OrganizationDataColumnState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationDataColumn = createSelector(
  selectOrganizationDataColumnState,
  (state: OrganizationDataColumnState.IState) => state.isLoading
);

export const selectIsReadyOrganizationDataColumn = createSelector(
  selectOrganizationDataColumnState,
  (state: OrganizationDataColumnState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationDataColumn = createSelector(
  selectOrganizationDataColumnState,
  (state: OrganizationDataColumnState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationDataColumnsEntities = createSelector(selectOrganizationDataColumnState, selectEntities);

export const selectOrganizationDataColumnsArray = createSelector(selectOrganizationDataColumnState, selectAll);

export const selectIdOrganizationDataColumnsActive = createSelector(
  selectOrganizationDataColumnState,
  (state: OrganizationDataColumnState.IState) => state.actives
);

const organizationDataColumnsInObject = (organizationDataColumns: Dictionary<OrganizationDataColumnEntityState>) => ({
  organizationDataColumns
});

const selectOrganizationDataColumnsEntitiesDictionary = createSelector(
  selectOrganizationDataColumnsEntities,
  organizationDataColumnsInObject
);

const selectAllOrganizationDataColumnsObject = createSelector(
  selectOrganizationDataColumnsEntities,
  organizationDataColumns => {
    return hydrateAll({ organizationDataColumns });
  }
);

const selectOneOrganizationDataColumnDictionary = (idOrganizationDataColumn: number) =>
  createSelector(selectOrganizationDataColumnsEntities, organizationDataColumns => ({
    organizationDataColumns: { [idOrganizationDataColumn]: organizationDataColumns[idOrganizationDataColumn] }
  }));

const selectOneOrganizationDataColumnDictionaryWithoutChild = (idOrganizationDataColumn: number) =>
  createSelector(selectOrganizationDataColumnsEntities, organizationDataColumns => ({
    organizationDataColumn: organizationDataColumns[idOrganizationDataColumn]
  }));

const selectActiveOrganizationDataColumnsEntities = createSelector(
  selectIdOrganizationDataColumnsActive,
  selectOrganizationDataColumnsEntities,
  (actives: number[], organizationDataColumns: Dictionary<OrganizationDataColumnEntityState>) =>
    getOrganizationDataColumnsFromActives(actives, organizationDataColumns)
);

function getOrganizationDataColumnsFromActives(
  actives: number[],
  organizationDataColumns: Dictionary<OrganizationDataColumnEntityState>
): Dictionary<OrganizationDataColumnEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationDataColumns[idActive]) {
      acc[idActive] = organizationDataColumns[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationDataColumnEntityState>);
}

const selectAllOrganizationDataColumnsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationDataColumns(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationDataColumn>(
      schema,
      selectAllOrganizationDataColumnsSelectors,
      selectOrganizationDataColumnsEntitiesDictionary,
      getRelationSelectors,
      organizationDataColumnRelations,
      hydrateAll,
      'organizationDataColumn'
    );
  } else {
    return selectAllOrganizationDataColumnsObject;
  }
}

export function selectAllOrganizationDataColumnsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationDataColumns'
): Selector {
  return createSelector(selectAllOrganizationDataColumns(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationDataColumnEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationDataColumns.length; i++) {
      res[customKey][result.organizationDataColumns[i].idOrganizationDataColumn] = result.organizationDataColumns[i];
    }
    return res;
  });
}

export function selectOneOrganizationDataColumn(schema: SelectSchema = {}, idOrganizationDataColumn: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationDataColumnDictionary(idOrganizationDataColumn)];
    selectors.push(...getRelationSelectors(schema, organizationDataColumnRelations, 'organizationDataColumn'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationDataColumnDictionaryWithoutChild(idOrganizationDataColumn);
  }
}

export function selectActiveOrganizationDataColumns(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationDataColumnsEntities, organizationDataColumns => ({
      organizationDataColumns
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationDataColumnRelations, 'organizationDataColumn'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationDataColumns: Dictionary<OrganizationDataColumnEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  organizationDataColumnValues?: Dictionary<OrganizationDataColumnValueEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  organizationCaracteristicRows?: Dictionary<OrganizationCaracteristicRowEntityState>;
  organizationCaracteristics?: Dictionary<OrganizationCaracteristicEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationDataColumns: (OrganizationDataColumn | null)[] } {
  const {
    organizationDataColumns,
    organizationFamilys,
    organizationDataColumnValues,
    valeurs,
    organizationCaracteristicRows,
    organizationCaracteristics
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationDataColumns: Object.keys(organizationDataColumns).map(idOrganizationDataColumn =>
      hydrate(
        organizationDataColumns[idOrganizationDataColumn] as OrganizationDataColumnEntityState,
        organizationFamilys,
        organizationDataColumnValues,
        valeurs,
        organizationCaracteristicRows,
        organizationCaracteristics
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationDataColumn: OrganizationDataColumnEntityState | null } {
  const {
    organizationDataColumns,
    organizationFamilys,
    organizationDataColumnValues,
    valeurs,
    organizationCaracteristicRows,
    organizationCaracteristics
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationDataColumn = Object.values(organizationDataColumns)[0];
  return {
    organizationDataColumn: hydrate(
      organizationDataColumn as OrganizationDataColumnEntityState,
      organizationFamilys,
      organizationDataColumnValues,
      valeurs,
      organizationCaracteristicRows,
      organizationCaracteristics
    )
  };
}

function hydrate(
  organizationDataColumn: OrganizationDataColumnEntityState,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  organizationDataColumnValueEntities?: Dictionary<OrganizationDataColumnValueEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>,
  organizationCaracteristicRowEntities?: Dictionary<OrganizationCaracteristicRowEntityState>,
  organizationCaracteristicEntities?: Dictionary<OrganizationCaracteristicEntityState>
): OrganizationDataColumn | null {
  if (!organizationDataColumn) {
    return null;
  }

  const organizationDataColumnHydrated: OrganizationDataColumnEntityState = { ...organizationDataColumn };
  if (organizationFamilyEntities) {
    organizationDataColumnHydrated.organizationFamily = organizationFamilyEntities[
      organizationDataColumn.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete organizationDataColumnHydrated.organizationFamily;
  }

  if (organizationDataColumnValueEntities) {
    organizationDataColumnHydrated.organizationDataColumnValues = (
      (organizationDataColumnHydrated.organizationDataColumnValues as number[]) || []
    ).map(id => organizationDataColumnValueEntities[id]) as OrganizationDataColumnValue[];
  } else {
    delete organizationDataColumnHydrated.organizationDataColumnValues;
  }

  if (valeurEntities) {
    organizationDataColumnHydrated.valeurs = ((organizationDataColumnHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete organizationDataColumnHydrated.valeurs;
  }

  if (organizationCaracteristicRowEntities) {
    organizationDataColumnHydrated.organizationCaracteristicRows = (
      (organizationDataColumnHydrated.organizationCaracteristicRows as number[]) || []
    ).map(id => organizationCaracteristicRowEntities[id]) as OrganizationCaracteristicRow[];
  } else {
    delete organizationDataColumnHydrated.organizationCaracteristicRows;
  }

  if (organizationCaracteristicEntities) {
    organizationDataColumnHydrated.organizationCaracteristics = (
      (organizationDataColumnHydrated.organizationCaracteristics as number[]) || []
    ).map(id => organizationCaracteristicEntities[id]) as OrganizationCaracteristic[];
  } else {
    delete organizationDataColumnHydrated.organizationCaracteristics;
  }

  return organizationDataColumnHydrated as OrganizationDataColumn;
}
