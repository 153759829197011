import { InformationDialogContainerComponent } from '@wip/dialogs/information-dialog';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConstraintTypeEnum, DroitInterneEnum, DurationFormat, ElementLibraryTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { formatDateForGantt } from '@utils';
import { Community, GanttLink, GanttReorderInterface, ProjectElement } from '@api/api-interfaces';
import {
  ChildrenElementModel,
  CommunityModel,
  CommunityUserProfilModel,
  ElementLibraryModel,
  ElementModel,
  OrganizationFamilyModel,
  OrganizationMilestoneModel,
  OrganizationUserProfilModel,
  UserModel
} from '@wip/store/selectors-model';
import { GanttLinkService, ProjectElementService } from '@wip/store/services';
import dayjs from 'dayjs';
// import dayjs from 'dayjs';
import { environment } from '@wip/environments';
import { Gantt } from '@wip/gantt-static';
import { Task } from '@api/api-interfaces';
import { Observable, combineLatest, debounceTime, map, startWith, tap } from 'rxjs';
import { CustomDateEditorComponent } from './custom-date-editor/custom-date-editor.component';
import { EditorService } from './services/editor.service';
import { LinkService } from './services/link.service';
import { GanttEventsService } from './services/new-gantt-events.service';
import { MoveTaskStrategy, TaskService } from './services/task.service';
import { setColumns } from './utils/new-gantt-columns';

export function durationFormatterParams(businessDay: boolean, format: DurationFormat) {
  if (!format) {
    format = DurationFormat.auto;
  }

  return {
    enter: 'day',
    store: 'day',
    format,
    daysPerMonth: businessDay ? 20 : 30,
    daysPerYear: businessDay ? 250 : 365,
    short: true,
    labels: {
      day: {
        full: 'jour',
        plural: 'jours',
        short: 'j'
      },
      week: {
        full: 'semaine',
        plural: 'semaines',
        short: 's'
      },
      month: {
        full: 'mois',
        plural: 'mois',
        short: 'm'
      },
      year: {
        full: 'an',
        plural: 'ans',
        short: 'a'
      }
    }
  };
}

@UntilDestroy()
@Component({
  providers: [TaskService, LinkService, GanttEventsService, EditorService],
  encapsulation: ViewEncapsulation.None,
  selector: 'gantt',
  styleUrls: ['./new-gantt.component.scss'],
  template: '<div #gantt_here class="gantt-chart"></div>'
})
export class NewGanttComponent implements OnChanges, OnDestroy {
  @Input() community: Community;
  @Input() scale: string;
  @Input() ganttLite: boolean;
  @Input() cycleSubject;
  @Input() editSubject: boolean = false;

  @ViewChild('gantt_here', { static: true }) ganttContainer!: ElementRef;

  @Output() projectElementUpdate = new EventEmitter<{ task: Task; key: string }>();
  @Output() setPlanning = new EventEmitter<number>();
  @Output() setEmpty = new EventEmitter<boolean>();
  @Output() cycleFound = new EventEmitter<any[]>();
  selectData$: any;

  constructor(
    private ganttEventsService: GanttEventsService,
    private dialog: MatDialog,
    private editorService: EditorService,
    private projectElementService: ProjectElementService,
    private ganttLinkService: GanttLinkService,
    private taskService: TaskService
  ) {}

  private configSet: boolean = false;
  private ganttEvents: string[] = [];

  ngOnDestroy(): void {
    this.projectElementService.filterParams.next({
      ...this.projectElementService.filterParams.value,
      textFilter: '',
      responsibleFilter: null
    });
    while (this.ganttEvents.length) {
      this.ganttEventsService.gantt?.detachEvent(this.ganttEvents.pop());
    }
    setTimeout(_ => {
      this.ganttEventsService.gantt?.destructor();
    }, 50);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.community?.currentValue?.idCommunity !== changes.community?.previousValue?.idCommunity) {
      this.fetchData(this.community.idCommunity);

      if (!this.community.organizationFamily && this.ganttEventsService.gantt) {
        this.ganttEventsService.gantt.clearAll();
        return;
      }
      this.projectElementService.filterParams.next({
        ...this.projectElementService.filterParams.value,
        responsibleFilter: null
      });
      this.taskService.firstScheduleDone = false;

      if (!this.configSet) {
        this.ganttEventsService.gantt = Gantt.getGanttInstance();
        this.setConfig();
        this.setMapping();
        this.ganttEventsService.gantt.init(this.ganttContainer.nativeElement);
        this.projectElementService.filterParams.subscribe({
          next: value => {
            if (this.ganttEventsService.gantt.$services) {
              this.ganttEventsService.gantt.config.scales = this.changeScale(value.scale);
              this.ganttEventsService.gantt?.render();
              this.setEditMode(this.editSubject);
            }
          }
        });

        this.setEvents();
        this.configSet = true;
      }

      this.setWorkingDays(this.community.organizationFamily?.businessDay);
      setColumns(this.ganttEventsService.gantt, this.community);

      this.ganttLite = this.community.organizationFamily?.ganttLite;

      this.selectData$?.unsubscribe();

      this.selectData$ = this.selectData()
        .pipe(
          untilDestroyed(this),
          debounceTime(50),
          tap(([_, data, links]) => {
            const filteredLinks = links.filter(l => !this.ganttLinkService.linksToDeactivate.includes(l.id));
            const previousScrollPosition = this.ganttEventsService.gantt.getScrollState();
            this.ganttEventsService.gantt.clearAll();
            this.ganttEventsService.gantt.parse({ data, links: filteredLinks });
            this.addTodayMarker();
            this.onToday();
            this.checkIfNeededReorder();

            this.setPlanning.emit(this.ganttEventsService.gantt.getTaskCount());

            if (previousScrollPosition) {
              this.ganttEventsService.gantt.scrollTo(previousScrollPosition.x, previousScrollPosition.y);
            }
            this.cycleFound.emit(this.ganttEventsService.gantt.findCycles());

            if (
              !this.taskService.firstScheduleDone &&
              this.community.communityRights.crud.includes(DroitInterneEnum.elementUpdate)
            ) {
              this.ganttEventsService.gantt.autoSchedule();
            }
            if (this.taskService.taskToSelectAfterCreation) {
              const task = this.ganttEventsService.gantt.getTaskByIndex(this.taskService.taskToSelectAfterCreation);
              this.ganttEventsService.gantt.selectTask(+task.id);

              setTimeout(_ => {
                this.taskService.taskToSelectAfterCreation = 0;
              }, 1000);
            }
          })
        )
        .subscribe();
    }
    if (changes.cycleSubject && this.cycleSubject) {
      this.ganttEventsService.openCycleDetail();
      this.cycleSubject = null;
    }
    if (changes.editSubject && this.editSubject !== null) {
      this.setEditMode(this.editSubject);
    }
  }

  public fetchData(idCommunity: number) {
    (
      this.projectElementService.getManyProjectElements(
        { idCommunity: this.community.idCommunity },
        true
      ) as Observable<ProjectElement[]>
    )
      .pipe(
        untilDestroyed(this),
        tap(c => {
          this.setEmpty.emit(c.length === 0);
        })
      )
      .subscribe();
    this.ganttLinkService.getManyGanttLinks({ idCommunity });
  }

  private selectData() {
    return combineLatest([
      this.ganttEventsService.resetData$.pipe(untilDestroyed(this), startWith(null)),
      this.projectElementService
        .selectAllProjectElements({
          include: [
            { model: CommunityModel, include: [OrganizationFamilyModel] },
            {
              model: ElementModel,
              include: [
                ChildrenElementModel,
                OrganizationMilestoneModel,
                {
                  model: ElementLibraryModel,
                  include: [
                    { model: CommunityUserProfilModel, include: [UserModel, OrganizationUserProfilModel] },
                    UserModel
                  ]
                }
              ]
            }
          ]
        })
        .pipe(
          untilDestroyed(this),
          map(pe => pe.filter(el => el.idCommunity === this.community?.idCommunity)),
          map(pe => this.convertProjectElementToTask(pe))
        ),
      this.ganttLinkService.selectAllGanttLinks().pipe(
        untilDestroyed(this),
        map(gl => gl.filter(el => el.idCommunity === this.community?.idCommunity)),
        map(gl => this.convertGanttLinks(gl))
      )
    ]);
  }

  private convertProjectElementToTask(projectElements: ProjectElement[]): Partial<Task>[] {
    const filtered = projectElements.sort((a, b) => a.ordre - b.ordre);

    return filtered.map(pe => {
      const ganttLite = this.ganttLite;
      const startDate = pe.element?.dateStart;
      const endDate = pe.element?.echeance;
      const formattedStartDate = formatDateForGantt(startDate);
      const formattedEndDate = formatDateForGantt(endDate);

      const constraint_type = pe.constraintType || ConstraintTypeEnum.ASAP;

      const ret: Partial<Task> = {
        id: pe.idProjectElement,
        index: pe.ordre,
        $calculate_duration: false,
        start_date: (formattedStartDate as unknown as Date) || null,
        end_date: (formattedEndDate as unknown as Date) || null,
        unscheduled: !formattedStartDate,
        // date_old: pe.element?.echeanceOld,
        text: this.getText(pe),
        ganttLite,
        duration: pe.element?.duration,
        durationFormat: pe.element?.durationFormat,
        constraint_type,
        constraint_date: pe.constraintType === ConstraintTypeEnum.MSO ? (formattedStartDate as unknown as Date) : null,
        isEndDateComputed: pe.isEndDateComputed,
        type: pe.element?.idOrganizationMilestone ? 'milestone' : pe.type !== 'projectElement' ? 'project' : 'task',
        idOrganizationMilestone: pe.element?.idOrganizationMilestone,
        idElement: pe.idElement,
        parent: pe.idParent,
        children: pe.element?.childrenElements,
        responsibles: pe.element?.elementLibraries?.filter(el =>
          [
            ElementLibraryTypeEnum.user_idUser,
            ElementLibraryTypeEnum.user_profile,
            ElementLibraryTypeEnum.user_nom
          ].includes(el.type)
        ),
        reminders: pe.element?.elementLibraries?.filter(el => el.type === ElementLibraryTypeEnum.reminder),
        statusAction: pe.element?.statusAction,
        note: pe.element?.description,
        editable: this.editSubject,
        canUpdate: this.community.communityRights.crud.includes(DroitInterneEnum.elementUpdate),
        canCreate: this.community.communityRights.crud.includes(DroitInterneEnum.elementCreateDelete)
      };

      return ret;
    });
  }

  public getText(projectElement: ProjectElement) {
    if (projectElement.type === 'project') {
      return projectElement.libelle;
    }
    return projectElement.element?.titre
      ? projectElement.element?.titre
      : projectElement.element?.organizationMilestone?.libelle;
  }

  private convertGanttLinks(ganttLinks: GanttLink[]) {
    return ganttLinks.map(ganttLink => {
      return {
        id: ganttLink.idGanttLink,
        source: ganttLink.newSource,
        target: ganttLink.newTarget,
        type: ganttLink.type,
        lag: ganttLink.lag,
        lagFormat: ganttLink.lagFormat
      };
    });
  }

  private setConfig() {
    this.ganttEventsService.gantt.plugins({
      click_drag: true,
      auto_scheduling: true,
      keyboard_navigation: true,
      marker: true,
      export_api: true
      // undo: true
    });
    this.ganttEventsService.gantt.config = {
      ...this.ganttEventsService.gantt.config,
      constraint_types: ConstraintTypeEnum,
      work_time: true,
      drag_links: true,
      drag_move: false,
      drag_progress: false,
      drag_resize: false,
      fit_tasks: true,
      duration_step: 1,
      duration_unit: 'day',
      keyboard_navigation: true,
      keyboard_navigation_cells: false,
      open_tree_initially: true,
      order_branch: 'marker',
      order_branch_free: true,
      readonly: false,
      server_utc: true,
      show_task_cells: false,
      smart_scales: true,
      smart_rendering: true,
      static_background: true,
      show_errors: environment.production ? false : true,
      date_format: '%d-%m-%Y %H:%i',
      date_grid: '%d/%m/%y %H:%i',
      scales: [
        { unit: 'year', step: 1, format: '%Y' },
        {
          unit: 'quarter',
          step: 1,
          format: date => {
            const month = date.getMonth();
            return month <= 2 ? 'T1' : month >= 3 && month <= 5 ? 'T2' : month >= 6 && month <= 8 ? 'T3' : 'T4';
          }
        }
      ],
      editor_types: this.editorService.getEditors(this.ganttEventsService.gantt, this.community),
      row_height: 32
    };
    this.ganttEventsService.gantt.config.lightbox.project_sections = [
      { name: 'description', height: 150, map_to: 'text', type: 'textarea', focus: true },
      { name: 'type', type: 'typeselect', map_to: 'type' },
      { name: 'time', type: 'duration', readonly: true, map_to: 'auto' }
    ];

    this.ganttEventsService.gantt.config.lightbox.milestone_sections = [
      { name: 'description', height: 70, map_to: 'text', type: 'textarea', focus: true },
      { name: 'rollup', type: 'checkbox', map_to: 'rollup' },
      { name: 'hide_bar', type: 'checkbox', map_to: 'hide_bar' },
      { name: 'type', type: 'typeselect', map_to: 'type' },
      { name: 'time', type: 'duration', map_to: 'auto' },
      { name: 'constraint', type: 'constraint' }
    ];

    this.ganttEventsService.gantt.removeShortcut('enter', 'taskRow');
    this.ganttEventsService.gantt.addShortcut('enter', e => {
      const id = this.ganttEventsService.gantt.locate(e);
      this.ganttEventsService.gantt.ext.inlineEditors.startEdit(id, 'start_date');
    });

    this.ganttEventsService.gantt.addShortcut('esc', _ => {
      this.ganttEventsService.gantt.ext.inlineEditors.hide();
    });

    this.ganttEventsService.gantt.templates.rightside_text = function (_, __, task) {
      if (task.type == 'milestone') {
        return task.text;
      }
      return '';
    };
  }

  private afterClosingDateModal(id, data) {
    const task = this.ganttEventsService.gantt.getTask(id);
    this.taskService.updateTaskCustom(
      task as Task,
      data.option,
      data.date,
      data.oldDate,
      this.ganttEventsService.gantt
    );
  }

  public changeScale(scale: string): any {
    if (scale === 'week') {
      return [
        { unit: 'month', step: 1, format: '%Y' },
        {
          unit: 'week',
          step: 1,
          format: date => {
            return this.getDateFormatForScale(date, 'week');
          }
        }
      ];
    } else if (scale === 'month') {
      return [
        { unit: 'year', step: 1, format: '%Y' },
        {
          unit: 'month',
          step: 1,
          format: date => {
            return this.getDateFormatForScale(date, 'month');
          }
        }
      ];
    } else if (scale === 'trimester') {
      return [
        { unit: 'year', step: 1, format: '%Y' },
        {
          unit: 'quarter',
          step: 1,
          format: date => {
            const month = date.getMonth();
            return month <= 2 ? 'T1' : month >= 3 && month <= 5 ? 'T2' : month >= 6 && month <= 8 ? 'T3' : 'T4';
          }
        }
      ];
    } else if (scale === 'year') {
      return [{ unit: 'year', step: 1, format: '%Y' }];
    }
  }

  private addTodayMarker() {
    this.ganttEventsService.gantt.addMarker({
      start_date: new Date(new Date().setHours(0, 0, 0, 0)),
      css: 'today-marker',
      text: "Aujourd'hui",
      title: new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' })
    });
  }

  public onToday() {
    this.ganttEventsService.gantt.showDate(new Date());
  }

  public export(format: string) {
    if (format === 'excel') {
      this.ganttEventsService.gantt.exportToExcel();
    } else if (format === 'pdf') {
      this.ganttEventsService.gantt.exportToPDF();
    } else if (format === 'image') {
      this.ganttEventsService.gantt.exportToPNG();
    }
  }

  public setEvents() {
    this.ganttEventsService.handleClickout();
    this.ganttEvents.push(
      ...[
        this.ganttEventsService.onBeforeTaskDisplay(),
        this.ganttEventsService.onTaskClick(),
        this.ganttEventsService.onTaskDblClick(),
        this.ganttEventsService.onLinkDblClick(),
        this.ganttEventsService.onBeforeRowDragEnd(),
        this.ganttEventsService.onRowDragEnd(),
        this.ganttEventsService.onBeforeEditStart(),
        this.ganttEventsService.onSave(),
        this.ganttEventsService.onAfterAutoSchedule(),
        this.ganttEventsService.onAfterTaskAdd(),
        this.ganttEventsService.onAfterTaskDelete(),
        this.ganttEventsService.onAfterLinkAdd(),
        this.ganttEventsService.onBeforeRowDragMove()
      ]
    );
  }

  private setMapping() {
    this.ganttEventsService.gantt.ext.inlineEditors.setMapping({
      init: inlineEditors => {
        this.ganttEvents.push(
          this.ganttEventsService.gantt.attachEvent('onTaskClick', (id, e) => {
            const cell = inlineEditors.locateCell(e.target);
            if (
              this.shouldStartEdit(cell, inlineEditors) &&
              this.community.communityRights.crud.includes(DroitInterneEnum.elementUpdate) &&
              !e.target.classList.contains('gantt_tree_icon')
            ) {
              inlineEditors.startEdit(cell.id, cell.columnName);
              return false;
            } else {
              if (
                !e.target.classList.contains('date-focus') &&
                this.ganttEventsService.gantt.ext.inlineEditors._columnName === 'start_date'
              ) {
                this.ganttEventsService.gantt.ext.inlineEditors.save();
              }
            }

            if (e.target.classList.contains('delete-' + id)) {
              const task = this.ganttEventsService.gantt.getTask(id);
              task.constraint_date = null;
              task.constraint_type = ConstraintTypeEnum.ASAP;
              this.ganttEventsService.gantt.updateTask(task.id);
              this.ganttEventsService.gantt.autoSchedule(task.id);
            }

            if (e.target.classList.contains('info-' + id)) {
              const task = this.ganttEventsService.gantt.getTask(id);
              const formatFunc = this.ganttEventsService.gantt.date.date_to_str('%d/%m/%y');
              if (isNaN(inlineEditors.getValue())) {
                this.dialog.open(InformationDialogContainerComponent, {
                  data: {
                    title: 'Erreur',
                    body: 'Date invalide'
                  },
                  width: '300px'
                });
              } else {
                const dialogRef = this.dialog.open(CustomDateEditorComponent, {
                  data: {
                    task,
                    newDate: inlineEditors.getValue(),
                    oldDate: task.start_date,
                    newDateFormatted: formatFunc(inlineEditors.getValue()),
                    oldDateFormatted: formatFunc(task.start_date)
                  },
                  height: '660px',
                  width: '700px'
                });
                this.ganttEventsService.gantt.ext.inlineEditors.hide();
                dialogRef.afterClosed().subscribe(data => {
                  if (data) {
                    this.afterClosingDateModal(id, data);
                  }
                });
              }
            }

            return true;
          })
        );
      },
      onShow: (inlineEditors, node) => {
        node.onkeydown = e => {
          // tslint:disable-next-line: deprecation
          e = e || window.event;
          if (e.defaultPrevented) {
            return;
          }

          const keyboard = this.ganttEventsService.gantt.constants.KEY_CODES;
          const cell = inlineEditors.locateCell(e.target);
          const task = this.ganttEventsService.gantt.getTask(cell.id);

          let shouldPrevent = true;
          switch (e.keyCode) {
            case this.ganttEventsService.gantt.keys.edit_save:
              if (cell.columnName === 'start_date') {
                const newDate = dayjs(
                  node.firstElementChild?.firstElementChild?.firstElementChild?.firstElementChild?.value
                );

                if (!newDate.isValid()) {
                  this.taskService.updateTaskCustom(
                    task as Task,
                    MoveTaskStrategy.moveTaskWithChildren,
                    task.start_date,
                    task.start_date,
                    this.ganttEventsService.gantt
                  );
                  return;
                }
                const newDateFormatted = newDate.toDate();

                this.taskService.updateTaskCustom(
                  task as Task,
                  this.ganttEventsService.moveTaskStrategy,
                  newDateFormatted,
                  task.start_date,
                  this.ganttEventsService.gantt
                );
              } else {
                this.editorService.editBotCell(
                  this.ganttEventsService.gantt,
                  inlineEditors.locateCell(e.target),
                  inlineEditors
                );
              }
              break;
            case this.ganttEventsService.gantt.keys.edit_cancel:
              inlineEditors.hide();
              break;
            case keyboard.TAB:
              if (e.shiftKey) {
                this.editorService.editPrevCell(
                  this.ganttEventsService.gantt,
                  inlineEditors.locateCell(e.target),
                  inlineEditors
                );
              } else {
                this.editorService.editRightCell(
                  this.ganttEventsService.gantt,
                  inlineEditors.locateCell(e.target),
                  inlineEditors
                );
              }
              break;
            case keyboard.DELETE:
              task.constraint_date = null;
              task.constraint_type = ConstraintTypeEnum.ASAP;
            default:
              shouldPrevent = false;
              break;
          }

          if (shouldPrevent) {
            e.preventDefault();
          }
        };
      },
      onHide: () => {},
      destroy: () => {}
    });
  }

  private shouldStartEdit(cell, inlineEditors): boolean {
    return (
      cell &&
      inlineEditors.getEditorConfig(cell.columnName) &&
      (!inlineEditors.isVisible() ||
        cell.id !== inlineEditors.getState().id ||
        cell.columnName !== inlineEditors.getState().columnName)
    );
  }

  private checkIfNeededReorder() {
    const params = this.projectElementService.filterParams.getValue();
    if (params.textFilter?.length || params.nextDatesFilter || params.milestoneFilter || params.responsibleFilter) {
      return;
    }
    this.ganttEventsService.gantt.eachTask(task => {
      if (task.index !== task.$index) {
        const reorder: GanttReorderInterface = {
          idCommunity: this.community.idCommunity,
          tasks: {}
        };
        this.ganttEventsService.gantt.eachTask(task => {
          task.index = task.$index;
          this.ganttEventsService.gantt.updateTask(task.id);
          reorder['tasks'][task.id] = { index: task.$index, parent: task.parent };
        });
        this.taskService.reorder(reorder);
      }
    });
  }

  private setWorkingDays(businessDay: boolean) {
    this.ganttEventsService.gantt.setWorkTime({ day: 6, hours: !businessDay });
    this.ganttEventsService.gantt.setWorkTime({ day: 0, hours: !businessDay });
  }

  private setEditMode(editMode: boolean) {
    const count = this.ganttEventsService.gantt.getTaskCount();
    for (let i = 0; i < count; i++) {
      const task = this.ganttEventsService.gantt.getTaskByIndex(i);
      if (task) {
        task.editable = editMode;
        this.ganttEventsService.gantt.updateTask(task.id);
      }
    }
  }

  private getDateFormatForScale(date, format) {
    enum month {
      Janvier,
      Février,
      Mars,
      Avril,
      Mai,
      Juin,
      Juillet,
      Aout,
      Septembre,
      Octobre,
      Novembre,
      Décembre
    }

    if (format === 'week') {
      return date.getDate() + ' ' + month[date.getMonth()].substring(0, 3);
    } else if (format === 'month') {
      return month[date.getMonth()].substring(0, 3);
    }
  }
}
