import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationCaracteristicValue } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationCaracteristicValueRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationCaracteristicValueGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicValueSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationCaracteristicValueService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(
      select(OrganizationCaracteristicValueSelectors.selectIsLoadedOrganizationCaracteristicValue)
    );
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(
      select(OrganizationCaracteristicValueSelectors.selectIsLoadingOrganizationCaracteristicValue)
    );
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationCaracteristicValueSelectors.selectIsReadyAndLoadedOrganizationCaracteristicValue as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationCaracteristicValues(
    schema: SelectSchema = {}
  ): Observable<OrganizationCaracteristicValue[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicValueSelectors.selectAllOrganizationCaracteristicValues(schema)))
      .pipe(
        switchMap(
          ({
            organizationCaracteristicValues
          }: {
            organizationCaracteristicValues: OrganizationCaracteristicValue[];
          }) => {
            return this.getReady(schema).pipe(mapTo(organizationCaracteristicValues));
          }
        )
      );
  }

  public selectOneOrganizationCaracteristicValue(
    idOrganizationCaracteristicValue: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationCaracteristicValue> {
    return this.store$
      .pipe(
        select(
          OrganizationCaracteristicValueSelectors.selectOneOrganizationCaracteristicValue(
            schema,
            idOrganizationCaracteristicValue
          )
        )
      )
      .pipe(
        switchMap(
          ({ organizationCaracteristicValue }: { organizationCaracteristicValue: OrganizationCaracteristicValue }) => {
            return this.getReady(schema).pipe(mapTo(organizationCaracteristicValue));
          }
        )
      );
  }

  public selectAllActiveOrganizationCaracteristicValues(
    schema: SelectSchema = {}
  ): Observable<OrganizationCaracteristicValue[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicValueSelectors.selectActiveOrganizationCaracteristicValues(schema)))
      .pipe(
        switchMap(
          ({
            organizationCaracteristicValues
          }: {
            organizationCaracteristicValues: OrganizationCaracteristicValue[];
          }) => {
            return this.getReady(schema).pipe(mapTo(organizationCaracteristicValues));
          }
        )
      );
  }

  public selectIdOrganizationCaracteristicValuesActive(): Observable<number[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicValueSelectors.selectIdOrganizationCaracteristicValuesActive))
      .pipe(
        switchMap((idOrganizationCaracteristicValues: number[]) => {
          return this.getReady().pipe(mapTo(idOrganizationCaracteristicValues));
        })
      );
  }

  public getOneOrganizationCaracteristicValue(
    idOrganizationCaracteristicValue: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristicValue> {
    this.store$.dispatch(
      OrganizationCaracteristicValueGeneratedActions.getOneOrganizationCaracteristicValue({
        idOrganizationCaracteristicValue,
        params
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicValueGeneratedActions.normalizeManyOrganizationCaracteristicValuesAfterUpsert,
        OrganizationCaracteristicValueGeneratedActions.organizationCaracteristicValuesFailure,
        true
      );
    }
  }

  public getManyOrganizationCaracteristicValues(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristicValue[]> {
    this.store$.dispatch(
      OrganizationCaracteristicValueGeneratedActions.getManyOrganizationCaracteristicValues({ params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicValueGeneratedActions.normalizeManyOrganizationCaracteristicValuesAfterUpsert,
        OrganizationCaracteristicValueGeneratedActions.organizationCaracteristicValuesFailure
      );
    }
  }

  public upsertOneOrganizationCaracteristicValue(
    organizationCaracteristicValue: Partial<OrganizationCaracteristicValue>,
    ids: OrganizationCaracteristicValueRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristicValue> {
    this.store$.dispatch(
      OrganizationCaracteristicValueGeneratedActions.upsertOneOrganizationCaracteristicValue({
        organizationCaracteristicValue,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicValueGeneratedActions.normalizeManyOrganizationCaracteristicValuesAfterUpsert,
        OrganizationCaracteristicValueGeneratedActions.organizationCaracteristicValuesFailure,
        true
      );
    }
  }

  public deleteOneOrganizationCaracteristicValue(
    idOrganizationCaracteristicValue: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationCaracteristicValueGeneratedActions.deleteOneOrganizationCaracteristicValue({
        idOrganizationCaracteristicValue
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicValueGeneratedActions.deleteOneOrganizationCaracteristicValueSuccess,
        OrganizationCaracteristicValueGeneratedActions.organizationCaracteristicValuesFailure
      );
    }
  }

  public setActiveOrganizationCaracteristicValues(idOrganizationCaracteristicValues: number[]): void {
    this.store$.dispatch(OrganizationCaracteristicValueGeneratedActions.clearActivesOrganizationCaracteristicValues());
    this.store$.dispatch(
      OrganizationCaracteristicValueGeneratedActions.addManyActivesOrganizationCaracteristicValues({
        idOrganizationCaracteristicValues
      })
    );
  }
}
