import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProjectModule, ProjectModuleEntityState } from '@api/api-interfaces';
import { ProjectModuleRelationsIds } from '@wip/store/ids-interfaces';

export const ProjectModuleGeneratedActions = createActionGroup({
  source: 'Project Module Generated',
  events: {
    'Get One Project Module': props<{ idProjectModule: number; params?: any }>(),
    'Get Many Project Modules': props<{ params: any }>(),
    'Add Many Actives Project Modules': props<{ idProjectModules: number[] }>(),
    'Delete One Active Project Module': props<{ idProjectModule: number }>(),
    'Clear Actives Project Modules': emptyProps(),
    'Upsert One Project Module': props<{ projectModule: Partial<ProjectModule>; ids?: ProjectModuleRelationsIds }>(),
    'Upsert Many Project Modules': props<{
      projectModules: Partial<ProjectModule>[];
      ids?: ProjectModuleRelationsIds;
    }>(),
    'Upsert Many Project Modules Success': props<{ projectModules: ProjectModuleEntityState[] }>(),
    'Delete One Project Module': props<{ idProjectModule: number }>(),
    'Delete One Project Module Success': props<{ idProjectModule: number }>(),
    'Normalize Many Project Modules After Upsert': props<{ projectModules: ProjectModuleEntityState[] }>(),
    'Project Modules Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Project Modules': emptyProps(),
    'Add Many Community Module Success': props<{ idProjectModule: number; idCommunityModules: number[] }>(),
    'Delete Many Community Module Success': props<{ idCommunityModules: number[]; idProjectModules: number[] }>(),
    'Add Many Organization Project Module Success': props<{
      idProjectModule: number;
      idOrganizationProjectModules: number[];
    }>(),
    'Delete Many Organization Project Module Success': props<{
      idOrganizationProjectModules: number[];
      idProjectModules: number[];
    }>(),
    'Add Many Community Success': props<{ idProjectModule: number; idCommunities: number[] }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idProjectModules: number[] }>()
  }
});
