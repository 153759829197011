import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationMilestoneAssociation, OrganizationMilestoneAssociationEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneAssociationApiService } from '@wip/store/api-services';
import { OrganizationMilestoneAssociationGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationMilestoneAssociation } from '@wip/store/configs/normalization';
import { OrganizationMilestoneAssociationSelectors } from '@wip/store/selectors';
import { OrganizationMilestoneAssociationRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';

export function getDefaultAddOrganizationMilestoneAssociationActions(
  organizationMilestoneAssociation: OrganizationMilestoneAssociationEntityState,
  ids?: OrganizationMilestoneAssociationRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationMilestoneAssociationGeneratedActions.normalizeManyOrganizationMilestoneAssociationsAfterUpsert({
      organizationMilestoneAssociations: [organizationMilestoneAssociation]
    })
  ];

  if (ids?.organizationMilestone) {
    actions.push(
      OrganizationMilestoneGeneratedActions.addManyOrganizationMilestoneAssociationSuccess({
        idOrganizationMilestone: ids.organizationMilestone,
        idOrganizationMilestoneAssociations: [organizationMilestoneAssociation.idOrganizationMilestoneAssociation]
      })
    );
    actions.push(
      OrganizationMilestoneAssociationGeneratedActions.addOrganizationMilestoneSuccess({
        idOrganizationMilestoneAssociation: organizationMilestoneAssociation.idOrganizationMilestoneAssociation,
        idOrganizationMilestone: ids.organizationMilestone
      })
    );
  }

  if (ids?.organizationMilestoneFamily) {
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.addManyOrganizationMilestoneAssociationSuccess({
        idOrganizationMilestoneFamily: ids.organizationMilestoneFamily,
        idOrganizationMilestoneAssociations: [organizationMilestoneAssociation.idOrganizationMilestoneAssociation]
      })
    );
    actions.push(
      OrganizationMilestoneAssociationGeneratedActions.addOrganizationMilestoneFamilySuccess({
        idOrganizationMilestoneAssociation: organizationMilestoneAssociation.idOrganizationMilestoneAssociation,
        idOrganizationMilestoneFamily: ids.organizationMilestoneFamily
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationMilestoneAssociationActions(
  organizationMilestoneAssociation: OrganizationMilestoneAssociationEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationMilestoneAssociationGeneratedActions.deleteOneOrganizationMilestoneAssociationSuccess({
      idOrganizationMilestoneAssociation: organizationMilestoneAssociation.idOrganizationMilestoneAssociation
    })
  ];

  if (organizationMilestoneAssociation.organizationMilestone) {
    actions.push(
      OrganizationMilestoneGeneratedActions.deleteManyOrganizationMilestoneAssociationSuccess({
        idOrganizationMilestoneAssociations: [organizationMilestoneAssociation.idOrganizationMilestoneAssociation],
        idOrganizationMilestones: [organizationMilestoneAssociation.organizationMilestone as number]
      })
    );
  }

  if (organizationMilestoneAssociation.organizationMilestoneFamily) {
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationMilestoneAssociationSuccess({
        idOrganizationMilestoneAssociations: [organizationMilestoneAssociation.idOrganizationMilestoneAssociation],
        idOrganizationMilestoneFamilys: [organizationMilestoneAssociation.organizationMilestoneFamily as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationMilestoneAssociationEffects {
  constructor(
    protected actions$: Actions,
    protected organizationMilestoneAssociationApiService: OrganizationMilestoneAssociationApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationMilestoneAssociations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneAssociationGeneratedActions.getManyOrganizationMilestoneAssociations),
      switchMap(({ params }) =>
        this.organizationMilestoneAssociationApiService.getOrganizationMilestoneAssociations(params).pipe(
          map((organizationMilestoneAssociations: OrganizationMilestoneAssociation[]) => {
            return OrganizationMilestoneAssociationGeneratedActions.normalizeManyOrganizationMilestoneAssociationsAfterUpsert(
              { organizationMilestoneAssociations }
            );
          }),
          catchError(error =>
            of(OrganizationMilestoneAssociationGeneratedActions.organizationMilestoneAssociationsFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationMilestoneAssociation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneAssociationGeneratedActions.getOneOrganizationMilestoneAssociation),
      switchMap(idOrganizationMilestoneAssociation =>
        this.organizationMilestoneAssociationApiService
          .getOrganizationMilestoneAssociation(idOrganizationMilestoneAssociation)
          .pipe(
            map((organizationMilestoneAssociation: OrganizationMilestoneAssociation) => {
              return OrganizationMilestoneAssociationGeneratedActions.normalizeManyOrganizationMilestoneAssociationsAfterUpsert(
                { organizationMilestoneAssociations: [organizationMilestoneAssociation] }
              );
            }),
            catchError(error =>
              of(OrganizationMilestoneAssociationGeneratedActions.organizationMilestoneAssociationsFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationMilestoneAssociation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneAssociationGeneratedActions.upsertOneOrganizationMilestoneAssociation),
      concatMap(
        ({
          organizationMilestoneAssociation,
          ids
        }: {
          organizationMilestoneAssociation: Partial<OrganizationMilestoneAssociation>;
          ids?: OrganizationMilestoneAssociationRelationsIds;
        }) => {
          if (organizationMilestoneAssociation.idOrganizationMilestoneAssociation) {
            return this.organizationMilestoneAssociationApiService
              .updateOrganizationMilestoneAssociation(organizationMilestoneAssociation)
              .pipe(
                map((organizationMilestoneAssociationReturned: OrganizationMilestoneAssociation) => {
                  return OrganizationMilestoneAssociationGeneratedActions.normalizeManyOrganizationMilestoneAssociationsAfterUpsert(
                    { organizationMilestoneAssociations: [organizationMilestoneAssociationReturned] }
                  );
                }),
                catchError(error =>
                  of(
                    OrganizationMilestoneAssociationGeneratedActions.organizationMilestoneAssociationsFailure({ error })
                  )
                )
              );
          } else {
            return this.organizationMilestoneAssociationApiService
              .addOrganizationMilestoneAssociation(organizationMilestoneAssociation)
              .pipe(
                mergeMap((organizationMilestoneAssociationReturned: OrganizationMilestoneAssociation) =>
                  getDefaultAddOrganizationMilestoneAssociationActions(organizationMilestoneAssociationReturned, ids)
                ),
                catchError(error =>
                  of(
                    OrganizationMilestoneAssociationGeneratedActions.organizationMilestoneAssociationsFailure({ error })
                  )
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationMilestoneAssociation$ = createEffect(() => {
    const selectOrganizationMilestoneAssociationState$ = this.store$.select(
      OrganizationMilestoneAssociationSelectors.selectOrganizationMilestoneAssociationState
    );
    return this.actions$.pipe(
      ofType(OrganizationMilestoneAssociationGeneratedActions.deleteOneOrganizationMilestoneAssociation),
      withLatestFrom(selectOrganizationMilestoneAssociationState$),
      concatMap(([{ idOrganizationMilestoneAssociation }, state]) =>
        this.organizationMilestoneAssociationApiService
          .deleteOrganizationMilestoneAssociation(idOrganizationMilestoneAssociation)
          .pipe(
            mergeMap(_success =>
              getDefaultDeleteOrganizationMilestoneAssociationActions(
                state.entities[idOrganizationMilestoneAssociation] as OrganizationMilestoneAssociationEntityState
              )
            ),
            catchError(error =>
              of(OrganizationMilestoneAssociationGeneratedActions.organizationMilestoneAssociationsFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationMilestoneAssociationsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        OrganizationMilestoneAssociationGeneratedActions.normalizeManyOrganizationMilestoneAssociationsAfterUpsert
      ),
      concatMap(({ organizationMilestoneAssociations }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationMilestoneAssociation(
          organizationMilestoneAssociations,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationMilestoneAssociation] Normalization After Upsert Success')];
      })
    );
  });
}
