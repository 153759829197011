<div class="legend-global flex flex-row gap-16">
  <div class="legend-title">Légende&nbsp;:</div>
  <div>
    <span class="legend-box finished-milestone"></span>
    <span class="row-name"> Date passée, livrable terminée </span>
    <span class="legend-box late-milestone"></span>
    <span class="row-name"> Date passée, livrable non terminée </span>
    <span class="legend-box blank-milestone"></span>
    <span class="row-name"> Futurs livrables </span>
    <span class="legend-date entered-date">01/01/2020</span>
    <span class="row-name">: Date saisie</span>
    <span class="legend-date is-computed">01/01/2020</span>
    <span class="row-name">: Date calculée automatiquement</span>
  </div>
</div>
