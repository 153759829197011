import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityRisk, CommunityRiskEntityState } from '@api/api-interfaces';
import { CommunityRiskRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityRiskGeneratedActions = createActionGroup({
  source: 'Community Risk Generated',
  events: {
    'Get One Community Risk': props<{ idCommunityRisk: number; params?: any }>(),
    'Get Many Community Risks': props<{ params: any }>(),
    'Add Many Actives Community Risks': props<{ idCommunityRisks: number[] }>(),
    'Delete One Active Community Risk': props<{ idCommunityRisk: number }>(),
    'Clear Actives Community Risks': emptyProps(),
    'Upsert One Community Risk': props<{ communityRisk: Partial<CommunityRisk>; ids?: CommunityRiskRelationsIds }>(),
    'Upsert Many Community Risks': props<{
      communityRisks: Partial<CommunityRisk>[];
      ids?: CommunityRiskRelationsIds;
    }>(),
    'Upsert Many Community Risks Success': props<{ communityRisks: CommunityRiskEntityState[] }>(),
    'Delete One Community Risk': props<{ idCommunityRisk: number }>(),
    'Delete One Community Risk Success': props<{ idCommunityRisk: number }>(),
    'Normalize Many Community Risks After Upsert': props<{ communityRisks: CommunityRiskEntityState[] }>(),
    'Community Risks Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Risks': emptyProps(),
    'Add Organization Risk Success': props<{ idCommunityRisk: number; idOrganizationRisk: number }>(),
    'Delete Many Organization Risk Success': props<{ idOrganizationRisks: number[]; idCommunityRisks: number[] }>(),
    'Add Community Success': props<{ idCommunityRisk: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityRisks: number[] }>()
  }
});
