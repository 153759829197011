import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationKpi, OrganizationKpiEntityState } from '@api/api-interfaces';
import { OrganizationKpiRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationKpiGeneratedActions = createActionGroup({
  source: 'Organization Kpi Generated',
  events: {
    'Get One Organization Kpi': props<{ idOrganizationKpi: number; params?: any }>(),
    'Get Many Organization Kpis': props<{ params: any }>(),
    'Add Many Actives Organization Kpis': props<{ idOrganizationKpis: number[] }>(),
    'Delete One Active Organization Kpi': props<{ idOrganizationKpi: number }>(),
    'Clear Actives Organization Kpis': emptyProps(),
    'Upsert One Organization Kpi': props<{
      organizationKpi: Partial<OrganizationKpi>;
      ids?: OrganizationKpiRelationsIds;
    }>(),
    'Upsert Many Organization Kpis': props<{
      organizationKpis: Partial<OrganizationKpi>[];
      ids?: OrganizationKpiRelationsIds;
    }>(),
    'Upsert Many Organization Kpis Success': props<{ organizationKpis: OrganizationKpiEntityState[] }>(),
    'Delete One Organization Kpi': props<{ idOrganizationKpi: number }>(),
    'Delete One Organization Kpi Success': props<{ idOrganizationKpi: number }>(),
    'Normalize Many Organization Kpis After Upsert': props<{ organizationKpis: OrganizationKpiEntityState[] }>(),
    'Organization Kpis Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Kpis': emptyProps(),
    'Add Many Community Kpi Success': props<{ idOrganizationKpi: number; idCommunityKpis: number[] }>(),
    'Delete Many Community Kpi Success': props<{ idCommunityKpis: number[]; idOrganizationKpis: number[] }>(),
    'Add Organization Success': props<{ idOrganizationKpi: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationKpis: number[] }>(),
    'Add Organization Family Success': props<{ idOrganizationKpi: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{
      idOrganizationFamilys: number[];
      idOrganizationKpis: number[];
    }>()
  }
});
