import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationMilestone } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import * as organizationMilestoneActions from '@wip/store/actions';
import { OrganizationMilestoneApiService } from '@wip/store/api-services';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import {
  GeneratedOrganizationMilestoneEffects,
  getDefaultAddOrganizationMilestoneActions
} from './organization-milestone-generated.effects';
import { OrganizationMilestoneRelationsIds } from '@wip/store/ids-interfaces';

@Injectable()
export class OrganizationMilestoneEffects extends GeneratedOrganizationMilestoneEffects {
  constructor(
    actions$: Actions,
    organizationMilestoneApiService: OrganizationMilestoneApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationMilestoneApiService, store$);
  }

  upsertOneOrganizationMilestoneWithFamilies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationMilestoneActions.upsertOneOrganizationMilestoneWithFamilies),
      concatMap(
        ({
          organizationMilestone,
          ids
        }: {
          organizationMilestone: Partial<OrganizationMilestone>;
          ids?: OrganizationMilestoneRelationsIds;
        }) => {
          if (organizationMilestone.idOrganizationMilestone) {
            const idsFamilies = ids.organizationMilestoneFamilys;
            return this.organizationMilestoneApiService
              .updateOrganizationMilestoneWithFamilies({
                idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
                idsFamilies
              })
              .pipe(
                map((organizationMilestoneReturned: OrganizationMilestone) => {
                  return organizationMilestoneActions.OrganizationMilestoneGeneratedActions.normalizeManyOrganizationMilestonesAfterUpsert(
                    {
                      organizationMilestones: [organizationMilestoneReturned]
                    }
                  );
                }),
                catchError(error =>
                  of(
                    organizationMilestoneActions.OrganizationMilestoneGeneratedActions.organizationMilestonesFailure({
                      error
                    })
                  )
                )
              );
          } else {
            return this.organizationMilestoneApiService.addOrganizationMilestone(organizationMilestone).pipe(
              mergeMap((organizationMilestoneReturned: OrganizationMilestone) =>
                getDefaultAddOrganizationMilestoneActions(organizationMilestoneReturned, ids)
              ),
              catchError(error =>
                of(
                  organizationMilestoneActions.OrganizationMilestoneGeneratedActions.organizationMilestonesFailure({
                    error
                  })
                )
              )
            );
          }
        }
      )
    );
  });
}
