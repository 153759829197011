import { RepositoryService } from '@wip/services/repository';
import { OrganizationRisk } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationRiskApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationRisks(params?: any): Observable<OrganizationRisk[]> {
    return this.repo.getData<OrganizationRisk[]>('organization-risk', params);
  }

  public getOrganizationRisk(params: { idOrganizationRisk: number; params?: any }): Observable<OrganizationRisk> {
    return this.repo.getData<OrganizationRisk>('organization-risk/' + params.idOrganizationRisk, params.params);
  }

  public addOrganizationRisk(organizationRisk: Partial<OrganizationRisk>): Observable<OrganizationRisk> {
    return this.repo.create<OrganizationRisk>('organization-risk', organizationRisk);
  }

  public updateOrganizationRisk(organizationRisk: Partial<OrganizationRisk>): Observable<OrganizationRisk> {
    return this.repo.update('organization-risk', organizationRisk);
  }

  public deleteOrganizationRisk(idOrganizationRisk: number): Observable<number> {
    return this.repo.delete('organization-risk/' + +idOrganizationRisk);
  }
}
