// import { onBoardStateChanges } from '@_app/utils/ag-grid/status-bar/utils';
import { Component, OnInit } from '@angular/core';
import { BoardTypeEnum, CommunityStatusActifEnum, CommunityTypeStructureEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Community,
  OrganizationCaracteristic,
  OrganizationDataColumn,
  OrganizationFamily,
  OrganizationMilestone,
  OrganizationStep,
  OrganizationUser,
  OrganizationUserProfil,
  UserBoardState
} from '@api/api-interfaces';
import {
  CommunityCaracteristicModel,
  CommunityDataRowModel,
  CommunitySubFamilyModel,
  CommunityUserModel,
  CommunityUserProfilModel,
  ElementModel,
  OrganizationCaracteristicModel,
  OrganizationDataColumnValueModel,
  OrganizationMilestoneAssociationModel,
  OrganizationMilestoneFamilyModel,
  OrganizationSubFamilyModel,
  OrganizationUserModel,
  OrganizationUserProfilModel,
  ProjectElementModel,
  UserBoardStateModel,
  UserModel,
  ValeurModel
} from '@wip/store/selectors-model';
import {
  CommunityCaracteristicService,
  CommunityService,
  CommunityUserProfilService,
  OrganizationCaracteristicService,
  OrganizationDataColumnService,
  OrganizationFamilyService,
  OrganizationMilestoneService,
  OrganizationService,
  OrganizationStepService,
  OrganizationUserProfilService,
  OrganizationUserService,
  UserBoardStateService
} from '@wip/store/services';
import { Observable, ReplaySubject, combineLatest, map, tap } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'wip-project-grid-container',
  templateUrl: './project-grid-container.component.html',
  styleUrls: ['./project-grid-container.component.scss']
})
export class ProjectGridContainerComponent implements OnInit {
  private filters;
  private currentBoardStates: UserBoardState[];

  public communities$: Observable<Community[]>;
  public organizationFamily$: Observable<OrganizationFamily>;
  public organizationSteps$: Observable<OrganizationStep[]>;
  public organizationUserProfils$: Observable<OrganizationUserProfil[]>;
  public organizationCaracteristics$: Observable<OrganizationCaracteristic[]>;
  public organizationMilestones$: Observable<OrganizationMilestone[]>;
  public organizationDataColumns$: Observable<OrganizationDataColumn[]>;
  public organizationUser$: Observable<OrganizationUser>;
  public listOrganizationUsers$: Observable<OrganizationUser[]>;
  public idOrganizationFamily: number;
  public idOrganization: number;
  public idOrganizationMilestoneFamily: number;
  public idOrganizationUser: number;
  public idUserBoardState: number;
  public editMode: boolean;
  public changeBoardstate$ = new ReplaySubject<Partial<UserBoardState>>();
  public create: boolean;
  public save: boolean;
  public saveColumns: boolean;
  public handle: boolean;
  public toggle: boolean;
  public isFilterActivated = false;
  public gridReload: boolean = true;
  public displayRepartition: boolean;

  constructor(
    private newCommunityService: CommunityService,
    private newOrganizationUserService: OrganizationUserService,
    private newOrganizationFamilyService: OrganizationFamilyService,
    private newOrganizationUserProfilService: OrganizationUserProfilService,
    private newOrganizationStepService: OrganizationStepService,
    private newOrganizationMilestoneService: OrganizationMilestoneService,
    private organizationDataColumnService: OrganizationDataColumnService,
    private newOrganizationCaracteristicService: OrganizationCaracteristicService,
    private newCommunityCaracteristicService: CommunityCaracteristicService,
    private newCommunityUserProfilService: CommunityUserProfilService,
    private boardStateService: UserBoardStateService,
    private newOrganizationService: OrganizationService
  ) {}

  ngOnInit(): void {
    this.listOrganizationUsers$ = this.newOrganizationService
      .selectAllActiveOrganizations({ include: [{ model: OrganizationUserModel, include: [UserModel] }] })
      .pipe(
        untilDestroyed(this),
        map(o => o[0]),
        map(org => org?.organizationUsers)
      );
    this.organizationUser$ = this.newOrganizationUserService
      .selectAllActiveOrganizationUsers({ include: [UserBoardStateModel] })
      .pipe(
        untilDestroyed(this),
        map(ou => ou[0])
      );
    combineLatest([
      this.boardStateService.selectAllUserBoardStates({ include: [OrganizationUserModel] }),
      this.newOrganizationUserService.selectAllActiveOrganizationUsers({ include: [UserBoardStateModel] }).pipe(
        untilDestroyed(this),
        map(ou => ou[0])
      )
    ])
      .pipe(
        untilDestroyed(this),
        map(([boardStates, orgaUser]) => {
          if (orgaUser && boardStates) {
            this.idOrganization = orgaUser.idOrganization;
            this.idOrganizationUser = orgaUser.idOrganizationUser;
            const generalBoardState = boardStates.find(
              bs => bs.type === BoardTypeEnum.project && bs.idOrganizationUser === orgaUser.idOrganizationUser
            );
            this.idUserBoardState = generalBoardState?.idUserBoardState;
            if (generalBoardState) {
              this.filters = JSON.parse(generalBoardState.filterState);
              if (this.filters.organizationFamily && this.filters.organizationFamily !== this.idOrganizationFamily) {
                this.idOrganizationFamily = this.filters.organizationFamily;
                this.newCommunityService.getElementsForSynthesis({
                  idOrganization: orgaUser.idOrganization,
                  idOrganizationFamily: this.idOrganizationFamily
                });
              }
              if (this.filters.organizationMilestoneFamily) {
                this.idOrganizationMilestoneFamily =
                  this.filters.organizationMilestoneFamily[this.filters.organizationFamily];
              }
              this.displayRepartition = this.filters.displayRepartition;
              const boardStatesFiltered = boardStates.filter(
                bs =>
                  (bs.idOrganizationUser === orgaUser.idOrganizationUser || bs.shared) &&
                  bs.type === BoardTypeEnum.synthesis
              );
              this.currentBoardStates = boardStatesFiltered;
              if (this.filters?.configuration && this.filters.configuration[this.idOrganizationFamily]) {
                const bState = this.currentBoardStates.find(
                  bs => bs.idUserBoardState === this.filters.configuration[this.idOrganizationFamily]
                );
                this.changeBoardstate$.next(bState || { idUserBoardState: null });
              } else {
                this.changeBoardstate$.next({ idUserBoardState: null });
              }
            }
            this.communities$ = this.newCommunityService
              .selectAllCommunities({
                include: [
                  { model: CommunityDataRowModel, include: [ValeurModel] },
                  { model: CommunityCaracteristicModel, include: [OrganizationCaracteristicModel] },
                  { model: CommunitySubFamilyModel, include: [OrganizationSubFamilyModel] },
                  { model: CommunityUserProfilModel, include: [OrganizationUserProfilModel, UserModel] },
                  { model: CommunityUserModel, include: [UserModel] },
                  { model: ProjectElementModel, include: [ElementModel] }
                ]
              })
              .pipe(
                untilDestroyed(this),
                map(comms =>
                  comms.filter(
                    comm =>
                      comm.idOrganization === orgaUser.idOrganization &&
                      comm.statusActif === CommunityStatusActifEnum.actif &&
                      [CommunityTypeStructureEnum.project, CommunityTypeStructureEnum.development].includes(
                        comm.typeStructure
                      )
                  )
                ),
                map(comms => {
                  if (comms.length) {
                    return this.newCommunityService.filterCommunities(comms, this.filters);
                  }
                })
              );

            this.organizationSteps$ = this.newOrganizationStepService.selectAllOrganizationSteps().pipe(
              untilDestroyed(this),

              map(steps =>
                steps.filter(
                  step =>
                    step.idOrganization === orgaUser.idOrganization &&
                    step.idOrganizationFamily === this.filters.organizationFamily
                )
              )
            );
            this.organizationFamily$ = this.newOrganizationFamilyService.selectOneOrganizationFamily(
              this.idOrganizationFamily
            );
            this.organizationDataColumns$ = this.organizationDataColumnService
              .selectAllOrganizationDataColumns({ include: [OrganizationDataColumnValueModel] })
              .pipe(
                untilDestroyed(this),
                map(data => data.filter(d => d.idOrganizationFamily === this.idOrganizationFamily))
              );
            this.organizationCaracteristics$ = this.newOrganizationCaracteristicService
              .selectAllOrganizationCaracteristics()
              .pipe(
                untilDestroyed(this),

                map(caracs => caracs.filter(carac => carac.idOrganization === orgaUser.idOrganization))
              );
            this.organizationMilestones$ = this.newOrganizationMilestoneService
              .selectAllOrganizationMilestones({
                include: [{ model: OrganizationMilestoneAssociationModel, include: [OrganizationMilestoneFamilyModel] }]
              })
              .pipe(
                untilDestroyed(this),

                map(milestones =>
                  milestones.filter(milestone => milestone.idOrganizationFamily === this.idOrganizationFamily)
                )
              );
            this.organizationUserProfils$ = this.newOrganizationUserProfilService
              .selectAllOrganizationUserProfils()
              .pipe(
                untilDestroyed(this),

                map(oup => oup.filter(el => el.idOrganization === orgaUser.idOrganization))
              );
          }
        })
      )
      .subscribe();

    this.newOrganizationUserService.editMode$.subscribe({
      next: editMode => {
        this.editMode = editMode;
        this.gridReload = false;
        setTimeout(_ => {
          this.gridReload = true;
        });
      }
    });
    this.newOrganizationUserService.createConfiguration$.subscribe({
      next: create => (this.create = create)
    });
    this.newOrganizationUserService.saveConfiguration$.subscribe({
      next: save => (this.save = save)
    });
    this.newOrganizationUserService.saveColumns$.subscribe({
      next: saveColumns => (this.saveColumns = saveColumns)
    });
    this.newOrganizationUserService.handleConfiguration$.subscribe({
      next: handle => (this.handle = handle)
    });
  }

  public onUpdateCaracteristic($event) {
    this.newCommunityCaracteristicService.fillHandleCaracteristics($event);
  }

  public onUpdateCommunity($event) {
    this.newCommunityService.upsertOneCommunity($event);
  }

  public onUpdateUserProfil($event) {
    this.newCommunityUserProfilService.fillHandleUserProfils($event);
  }

  public onUpdateBoardState($event) {
    if ($event.type === 'create') {
      (
        this.boardStateService.upsertOneUserBoardState(
          {
            idOrganizationUser: this.idOrganizationUser,
            idOrganizationFamily: this.idOrganizationFamily,
            libelle: $event.libelle,
            type: BoardTypeEnum.synthesis,
            columnState: $event.columnState,
            filterState: $event.filterState
          },
          null,
          true
        ) as Observable<UserBoardState>
      )
        .pipe(
          untilDestroyed(this),
          tap(boardState => {
            this.filters.configuration[this.idOrganizationFamily] = boardState.idUserBoardState;
            this.boardStateService.upsertOneUserBoardState({
              idUserBoardState: this.idUserBoardState,
              filterState: JSON.stringify(this.filters)
            });
          })
        )
        .subscribe();
    } else if ($event.type === 'update') {
      this.boardStateService.upsertOneUserBoardState({
        idUserBoardState: this.filters.configuration[this.idOrganizationFamily],
        columnState: $event.columnState,
        filterState: $event.filterState
      });
    }
  }

  public onSetUpdateButton($event) {
    this.newOrganizationUserService.setUpdateButton$.next($event);
  }

  public setButtons($event) {
    this.newOrganizationUserService.setButtons$.next($event);
  }
}
