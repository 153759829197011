import { Injectable, OnDestroy } from '@angular/core';
import { User } from '@api/api-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ICustomDateTranslation {
  itemMeetingListeDirective: any;
  itemMeetingDirective: any;
  timelineSeparator: any;
  timelineSeparatorYear: any;
  itemAction: any;
  itemActionWithoutHours: any;
  inputElement: any;
  elementsDocumentsForm: any;
  semaineListInclude: any;
  itemDocumentDirective: any;
  semaineAction: any;
  projectItemAction: any;
  durationDhmOneFormat: any;
  durationDhmManyFormat: any;
}

@Injectable()
export class MomentLocaleService implements OnDestroy {
  currentUser$: Observable<User>;

  customDateTranslation$: BehaviorSubject<ICustomDateTranslation> = new BehaviorSubject(null);

  private semaineActionEn = 'MMM DD';
  private semaineActionFr = 'DD MMM';
  private itemMeetingListeDirectiveEn = {
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    nextWeek: 'currentCommunity [at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: '[Last] currentCommunity [at] LT',
    sameElse: 'currentCommunity[,] MMMM DD YYYY [at] LT'
  };
  private itemMeetingListeDirectiveFr = {
    sameDay: "[Aujourd'hui à ] LT",
    nextDay: '[Demain à] LT',
    nextWeek: 'currentCommunity [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'currentCommunity [dernier à] LT',
    sameElse: 'currentCommunity DD MMMM  YYYY [à] LT'
  };
  private itemDocumentDirectiveEn = {
    sameDay: 'L [at] LT',
    nextDay: 'L [at] LT',
    nextWeek: 'L [at] LT',
    lastDay: 'L [at] LT',
    lastWeek: 'L [at] LT',
    sameElse: 'L [at] LT'
  };
  private itemDocumentDirectiveFr = {
    sameDay: 'L [à] LT',
    nextDay: 'L [à] LT',
    nextWeek: 'L [à] LT',
    lastDay: 'L [à] LT',
    lastWeek: 'L [à] LT',
    sameElse: 'L [à] LT'
  };
  private itemMeetingDirectiveEn = {
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    nextWeek: 'currentCommunity LL [at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: 'currentCommunity LL [at] LT',
    sameElse: 'currentCommunity LL [at] LT'
  };
  private itemMeetingDirectiveFr = {
    sameDay: "[Aujourd'hui à ] LT",
    nextDay: '[Demain à] LT',
    nextWeek: 'currentCommunity LL [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'currentCommunity LL [à] LT',
    sameElse: 'currentCommunity LL [à] LT'
  };
  private timelineSeparatorFr = {
    sameDay: "[Aujourd'hui]",
    nextDay: '[Demain]',
    nextWeek: 'currentCommunity',
    lastDay: '[Hier]',
    lastWeek: 'DD MMM',
    sameElse: 'DD MMM'
  };
  private timelineSeparatorEn = {
    sameDay: '[Today] ',
    nextDay: '[Tomorrow]',
    nextWeek: 'currentCommunity ',
    lastDay: '[Yesterday]',
    lastWeek: 'MMM DD',
    sameElse: 'MMM DD'
  };
  private timelineSeparatorYearFr = {
    sameDay: "[Aujourd'hui]",
    nextDay: '[Demain]',
    nextWeek: 'currentCommunity',
    lastDay: '[Hier]',
    lastWeek: 'DD MMM YYYY',
    sameElse: 'DD MMM YYYY'
  };
  private timelineSeparatorYearEn = {
    sameDay: '[Today] ',
    nextDay: '[Tomorrow]',
    nextWeek: 'currentCommunity ',
    lastDay: '[Yesterday]',
    lastWeek: 'MMM DD YYYY',
    sameElse: 'MMM DD YYYY'
  };
  private itemActionFr = {
    sameDay: "[Aujourd'hui] [à] LT",
    nextDay: '[Demain] [à] LT',
    nextWeek: 'DD MMM [à] LT',
    lastDay: '[Hier] [à] LT',
    lastWeek: 'DD MMM [à] LT',
    sameElse: 'DD MMM YYYY [à] LT'
  };
  private itemActionEn = {
    sameDay: '[Today] [at] LT ',
    nextDay: '[Tomorrow] [at] LT',
    nextWeek: 'MMM DD [at] LT',
    lastDay: '[Yesterday] [at] LT',
    lastWeek: 'MMM DD [at] LT',
    sameElse: 'MMM DD YYYY [at] LT'
  };
  private itemActionWithoutHoursFr = {
    sameDay: "[Aujourd'hui] ",
    nextDay: '[Demain] ',
    nextWeek: 'DD MMM',
    lastDay: '[Hier] ',
    lastWeek: 'DD MMM ',
    sameElse: 'DD MMM YYYY'
  };
  private itemActionWithoutHoursEn = {
    sameDay: '[Today] ',
    nextDay: '[Tomorrow] ',
    nextWeek: 'MMM DD ',
    lastDay: '[Yesterday] ',
    lastWeek: 'MMM DD ',
    sameElse: 'MMM DD YYYY'
  };
  private inputElementFr = {
    sameDay: "[Aujourd'hui]",
    nextDay: '[Demain]',
    nextWeek: 'DD MM',
    lastDay: '[Hier]',
    lastWeek: 'DD MMM',
    sameElse: 'DD MMM'
  };
  private inputElementEn = {
    sameDay: '[Today] ',
    nextDay: '[Tomorrow]',
    nextWeek: 'MMM DD',
    lastDay: '[Yesterday]',
    lastWeek: 'MMM DD',
    sameElse: 'MMM DD'
  };
  private elementsDocumentsFormFr = {
    sameDay: "[Aujourd'hui] [à] LT",
    nextDay: '[Demain] [à] LT',
    nextWeek: 'DD MMMM YYYY [à] LT',
    lastDay: '[Hier] [à] LT',
    lastWeek: 'DD MMMM YYYY [à] LT',
    sameElse: 'DD MMMM YYYY [à] LT'
  };
  private elementsDocumentsFormEn = {
    sameDay: '[Today] [at] LT',
    nextDay: '[Tomorrow] [at] LT',
    nextWeek: 'MMMM DD[,] YYYY [at] LT',
    lastDay: '[Yesterday] [at] LT',
    lastWeek: 'MMMM DD[,] YYYY [at] LT',
    sameElse: 'MMMM DD[,] YYYY [at] LT'
  };
  private semaineListIncludeFr = {
    sameDay: "[Aujourd'hui]",
    nextDay: '[Demain] ',
    nextWeek: 'currentCommunity DD ',
    lastDay: '[Hier] ',
    lastWeek: 'currentCommunity DD ',
    sameElse: 'currentCommunity DD '
  };
  private semaineListIncludeEn = {
    sameDay: '[Today] ',
    nextDay: '[Tomorrow] ',
    nextWeek: 'currentCommunity DD',
    lastDay: '[Yesterday]',
    lastWeek: 'currentCommunity DD ',
    sameElse: 'currentCommunity DD'
  };
  private projectItemActionFr = {
    sameDay: 'DD/MM',
    nextDay: 'DD/MM',
    nextWeek: 'DD/MM',
    lastDay: 'DD/MM',
    lastWeek: 'DD/MM',
    sameElse: 'DD/MM'
  };
  private projectItemActionEn = {
    sameDay: 'MM/DD',
    nextDay: 'MM/DD',
    nextWeek: 'MM/DD',
    lastDay: 'MM/DD',
    lastWeek: 'MM/DD',
    sameElse: 'MM/DD'
  };

  private durationDhmOneFormatFr = 'd [jour] h [h] m [min]';
  private durationDhmOneFormatEn = 'd [day] h [h] m [min]';

  private durationDhmManyFormatFr = 'd [jours] h [h] mm [min]';
  private durationDhmManyFormatEn = 'd [days] h [h] mm [min]';

  // EVENTS
  // on successful applying translations by angular-translate
  // $rootScope.$on('$translateChangeSuccess', function (event, data) {
  //     // asking angular-dynamic-locale to load and apply proper AngularJS $locale setting
  //     tmhDynamicLocale.set(data.language.toLowerCase().replace(/_/g, '-'));
  // });

  constructor(/*, private deviceInformation: DeviceInformation*/) {}
  changeLocale(lang?): ICustomDateTranslation {
    const calendar: ICustomDateTranslation = {
      itemMeetingListeDirective: {},
      itemMeetingDirective: {},
      timelineSeparator: {},
      itemAction: {},
      itemActionWithoutHours: {},
      inputElement: {},
      elementsDocumentsForm: {},
      semaineListInclude: {},
      itemDocumentDirective: {},
      semaineAction: {},
      projectItemAction: {},
      durationDhmOneFormat: {},
      durationDhmManyFormat: {},
      timelineSeparatorYear: {}
    };
    switch (lang) {
      case 'FR_fr':
      case 'FR-fr':
      case 'fr_FR':
      case 'fr-FR':
        calendar.itemMeetingListeDirective = this.itemMeetingListeDirectiveFr;
        calendar.itemMeetingDirective = this.itemMeetingDirectiveFr;
        calendar.timelineSeparator = this.timelineSeparatorFr;
        calendar.timelineSeparatorYear = this.timelineSeparatorYearFr;
        calendar.itemAction = this.itemActionFr;
        calendar.itemActionWithoutHours = this.itemActionWithoutHoursFr;
        calendar.inputElement = this.inputElementFr;
        calendar.elementsDocumentsForm = this.elementsDocumentsFormFr;
        calendar.semaineListInclude = this.semaineListIncludeFr;
        calendar.itemDocumentDirective = this.itemDocumentDirectiveFr;
        calendar.semaineAction = this.semaineActionFr;
        calendar.projectItemAction = this.projectItemActionFr;
        calendar.durationDhmOneFormat = this.durationDhmOneFormatFr;
        calendar.durationDhmManyFormat = this.durationDhmManyFormatFr;
        break;
      case 'en_US':
      case 'en-US':
      case 'EN_us':
      case 'EN-us':
      default:
        calendar.itemMeetingListeDirective = this.itemMeetingListeDirectiveEn;
        calendar.itemMeetingDirective = this.itemMeetingDirectiveEn;
        calendar.timelineSeparator = this.timelineSeparatorEn;
        calendar.timelineSeparatorYear = this.timelineSeparatorYearEn;
        calendar.itemAction = this.itemActionEn;
        calendar.itemActionWithoutHours = this.itemActionWithoutHoursEn;
        calendar.inputElement = this.inputElementEn;
        calendar.elementsDocumentsForm = this.elementsDocumentsFormEn;
        calendar.semaineListInclude = this.semaineListIncludeEn;
        calendar.semaineAction = this.semaineActionEn;
        calendar.itemDocumentDirective = this.itemDocumentDirectiveEn;
        calendar.projectItemAction = this.projectItemActionEn;
        calendar.durationDhmOneFormat = this.durationDhmOneFormatEn;
        calendar.durationDhmManyFormat = this.durationDhmManyFormatEn;
        break;
    }

    return calendar;
  }

  ngOnDestroy() {
    // this.subscriptionCurrentUser$.unsubscribe();
  }
}
