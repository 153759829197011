import { ComponentType } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Community } from '@api/api-interfaces';
import { CommunityTypeStructureEnum, OrganizationElemTypeEnum } from '@enums';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as CommunityAction from '@wip/store/actions';
import { CommunityApiService } from '@wip/store/api-services';
import * as AppState from '@wip/store/configs/reducers';
import { ClearStateUtils } from '@wip/store/utils';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { ConfigurationApiService } from 'libs/api/old-api-services/src';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Observable, Subject } from 'rxjs';
import { GeneratedCommunityService } from './community-generated.service';
import { ProjectElementService } from './project-element.service';

interface InputQueryCommunity {
  communityIds: number[];
  idCommunity: number;
  sameFile: boolean;
  idElement: number;
}

@Injectable({
  providedIn: 'root'
})
export class CommunityService extends GeneratedCommunityService {
  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private communityApiService: CommunityApiService,
    private projectElementService: ProjectElementService,
    private http: HttpClient,
    private configurationApi: ConfigurationApiService,
    private dialog: MatDialog
  ) {
    super(store$, actions$);
  }

  public currentIdCommunity: number;
  public filterCommunitiesForDashboard: boolean = true;
  public searchString$ = new Subject<string>();
  public triggerList: boolean = false;

  public getProjectsForSidelist(
    idOrganization: number,
    idUser: number,
    operationType: CommunityTypeStructureEnum,
    getResult?: boolean
  ): Observable<Community[]> {
    this.store$.dispatch(CommunityAction.getProjectsForSidelist({ idOrganization, idUser, operationType }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure
      );
    }
  }

  public getCommunitiesForSidelist(
    idOrganization: number,
    idUser: number,
    getResult?: boolean
  ): Observable<Community[]> {
    this.store$.dispatch(CommunityAction.getCommunitiesForSidelist({ idOrganization, idUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure
      );
    }
  }

  public getConversationsForSidelist(idUser: number, getResult?: boolean): Observable<Community[]> {
    this.store$.dispatch(CommunityAction.getConversationsForSidelist({ idUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure
      );
    }
  }

  public getProjectTemplates(idOrganization: number, getResult?: boolean): Observable<Community[]> {
    this.store$.dispatch(CommunityAction.getProjectTemplates({ idOrganization }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure
      );
    }
  }

  public getElementsForSynthesis(
    params: { idOrganization: number; idOrganizationFamily?: number },
    getResult?: boolean
  ): Observable<Community[]> {
    this.store$.dispatch(CommunityAction.getElementsForSynthesis(params));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure
      );
    }
  }

  public getPlanningInfo(idOrganization: number, getResult?: boolean): Observable<Community[]> {
    this.store$.dispatch(CommunityAction.getPlanningInfo({ idOrganization }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure
      );
    }
  }

  public getPersonalSpace(idUser: number, getResult?: boolean) {
    this.store$.dispatch(CommunityAction.getPersonalSpace({ idUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure,
        true
      );
    }
  }

  public clearCommunities() {
    this.store$.dispatch(CommunityAction.CommunityGeneratedActions.clearCommunities());
  }

  public duplicate(idCommunitySrc: number, idCommunityDst: number, getResult?: boolean): Observable<Community> {
    this.store$.dispatch(CommunityAction.duplicate({ idCommunitySrc, idCommunityDst }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure
      );
    }
  }

  public createCommunity(params: { community: Partial<Community>; modules: number[] }, getResult?: boolean) {
    this.store$.dispatch(CommunityAction.createCommunity(params));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure,
        true
      );
    }
  }

  public createDirectMessage(params: { community: Partial<Community>; listeUsersAdd: number[] }, getResult?: boolean) {
    this.store$.dispatch(CommunityAction.createDirectMessage(params));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure,
        true
      );
    }
  }

  public filterCommunities(communities: Community[], filterState) {
    let commFiltered = communities;

    if (filterState.organizationFamily) {
      commFiltered = commFiltered.filter(comm => comm.idOrganizationFamily === filterState.organizationFamily);
    }
    if (
      filterState.organizationSubFamilies &&
      filterState.organizationSubFamilies[filterState.organizationFamily]?.length
    ) {
      if (!filterState.subFamilyRule) {
        commFiltered = commFiltered.filter(
          comm =>
            filterState.organizationSubFamilies[filterState.organizationFamily].filter(id =>
              comm.communitySubFamilys.map(csf => csf.idOrganizationSubFamily).includes(id)
            ).length
        );
      } else {
        commFiltered = commFiltered.filter(comm => {
          for (const orgaSubFamily of filterState.organizationSubFamilies[filterState.organizationFamily]) {
            if (!comm.communitySubFamilys.some(csf => csf.idOrganizationSubFamily === orgaSubFamily)) {
              return false;
            }
          }
          return true;
        });
      }
    }

    if (filterState.searchString?.length) {
      const searchString = filterState.searchString.toLowerCase();
      commFiltered = commFiltered.filter(
        comm =>
          comm.nom.toLowerCase().includes(searchString) ||
          (comm.communityCaracteristics?.length &&
            comm.communityCaracteristics.some(cc => cc.valeur?.toLowerCase().includes(searchString))) ||
          (comm.communitySubFamilys?.length &&
            comm.communitySubFamilys.some(csf =>
              csf.organizationSubFamily?.libelle.toLowerCase().includes(searchString)
            )) ||
          (comm.communityUserProfils?.length &&
            comm.communityUserProfils.some(
              cup =>
                cup.user?.nom?.toLowerCase().includes(searchString) ||
                cup.user?.prenom?.toLowerCase().includes(searchString)
            ))
      );
    }
    if (filterState.responsible) {
      commFiltered = commFiltered.filter(comm => {
        if (filterState.isResponsibleOnlyPilot) {
          return comm.communityUserProfils?.find(cu => cu.idUser === filterState.responsible && cu.selected);
        }
        return comm.communityUserProfils?.find(cu => cu.idUser === filterState.responsible);
      });
    }
    return commFiltered;
  }

  public propagateRoles(idCommunity: number) {
    this.communityApiService.propagateRoles(idCommunity).pipe().subscribe();
  }

  public getZonage(codePostal: string) {
    return this.http.get(`${this.configurationApi.fullAddressV2}/community/zonage/${codePostal}`);
  }

  public deleteGantt(idCommunity: number) {
    this.communityApiService.deleteGantt(idCommunity).subscribe();
    this.projectElementService.clearProjectElements();
  }

  public clearStore() {
    const states: (keyof AppState.GeneratedAppState)[] = ClearStateUtils.findAllStateNameToClear();
    this.store$.dispatch(AppState.clearState({ states }));
  }

  public changeMask(params: { idOrganization: number; idTemplate: number }) {
    return this.http.put(this.configurationApi.fullAddressV2 + '/community/changeMask', params);
  }

  public exportNotes(community: Community, input: InputQueryCommunity) {
    const formattedDate = dayjs().format('DD-MM-YY');
    let filename;
    if (input.sameFile) {
      filename = 'Export notes - ' + formattedDate + '.docx';
    } else if (input.communityIds.length > 1) {
      filename = 'Export notes - ' + formattedDate + '.zip';
    } else {
      filename = community.nom + ' - notes.docx';
    }
    return this.http
      .post(this.configurationApi.fullAddressV2 + '/community/exportNotes', input, {
        responseType: 'blob'
      })
      .subscribe((blob: Blob) => {
        saveAs(blob, filename);
      });
  }

  public upsertManyCommunities(
    params: {
      communityIds: number[];
      key: string;
      value: any;
    },
    getResult: boolean = false
  ) {
    this.store$.dispatch(CommunityAction.updateManyCommunities(params));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure,
        true
      );
    }
  }

  public verifyProject(idCommunity: number, getResult: boolean = false) {
    this.store$.dispatch(CommunityAction.verifyProject({ idCommunity }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityAction.CommunityGeneratedActions.communitiesFailure,
        true
      );
    }
  }

  public bulkUpdateGeo(idOrganization: number, updateOnlyInseeCode: boolean) {
    this.store$.dispatch(CommunityAction.bulkUpdateGeo({ idOrganization, updateOnlyInseeCode }));
    return catchApiActions(
      this.actions$,
      CommunityAction.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
      CommunityAction.CommunityGeneratedActions.communitiesFailure,
      false
    );
  }

  public exportToDocx(
    multiple: boolean = false,
    community: Community,
    documentType: OrganizationElemTypeEnum,
    MultipleModale: ComponentType<any>,
    MonoModale: ComponentType<any>
  ) {
    if (multiple) {
      const dialogRef = this.dialog.open(MultipleModale, {
        height: '720px',
        width: 'px'
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.exportNotes(community, res);
        }
      });
    } else {
      const dialogRef = this.dialog.open(MonoModale, {
        data: {
          documentType
        },
        height: '250px'
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.exportNotes(community, {
            idCommunity: community.idCommunity,
            idElement: res,
            communityIds: [community.idCommunity],
            sameFile: false
          });
        }
      });
    }
  }
}
