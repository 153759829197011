import dayjs from 'dayjs';

/**
 * setDuration(startDate, endDate, value, update) :
 * @param {Date} startDate - start date from which  the duration is being calculated
 * @param {Date} endDate - end Date with which the duration is being calculated
 *
 * Calculates the duration between the set start and end dates for a given field (value)
 */

export function calcDuration(startDate: Date, endDate: Date) {
  return Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
}

export function isValidDate(date: any): boolean {
  if (!date) {
    return true;
  }
  const tmp = new Date(date);
  if (Object.prototype.toString.call(tmp) === '[object Date]' && !isNaN(tmp.getTime())) {
    return true;
  } else {
    return false;
  }
}

export function areDatesEqual(date1: Date, date2: Date) {
  return date1.toDateString() === date2.toDateString();
}

export function convertLocalTimeToUTCWithoutChangingHour(date1: Date = new Date()): Date {
  const date = new Date(date1);
  const newDate: Date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  newDate.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return newDate;
}

export function convertUTCToLocalTimeWithoutChangingHour(date: Date = new Date()): Date {
  const newDate: Date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  newDate.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return newDate;
}

export function isDateDiffIn(date1: Date, date2: Date, limit: number): boolean {
  const diff: number = Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
  return limit > 0 ? diff >= 0 && diff <= limit : diff <= 0 && diff >= limit;
}

export function formatDateForGantt(ganttDate: Date) {
  if (!ganttDate) {
    return null;
  }
  const date = new Date(ganttDate);
  let year = date.getFullYear();
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let day = ('0' + date.getDate()).slice(-2);
  let hours = ('0' + date.getHours()).slice(-2);
  let minutes = ('0' + date.getMinutes()).slice(-2);
  return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes;
}

export function calculateDurationWithoutDaysOff(startDate: Date, endDate: Date) {
  let workingDays = 0;
  let currentDate = dayjs(startDate);

  while (currentDate.isBefore(endDate)) {
    // Vérifie si le jour actuel n'est pas un samedi (6) ou un dimanche (0)
    if (currentDate.day() !== 6 && currentDate.day() !== 0) {
      workingDays++;
    }

    // Passe au jour suivant
    currentDate = currentDate.add(1, 'day');
  }

  return workingDays;
}
