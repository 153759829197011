<div class="project-grid-view full-height">
  <ag-grid-angular
    class="ag-theme-quartz grid-sizings multiple-lines-header ag-grid-border-8"
    [gridOptions]="gridOptions"
    [modules]="modules"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</div>
<wip-project-grid-legend />
