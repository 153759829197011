@if (projects?.length) {
  <cdk-virtual-scroll-viewport itemSize="36" minBufferPx="200" maxBufferPx="1000" style="height: 100%">
    <div
      *cdkVirtualFor="let project of projects; let i = index; trackBy: getCommunityId"
      (dblclick)="onCommunityChange(project)"
      (click)="selectCommunity(project)"
      [ngClass]="{
        'selected-community': currentProject && project && project.idCommunity === currentProject.idCommunity
      }"
      [attr.id]="project.idCommunity"
      class="hand community flex flex-row justify-start items-center"
    >
      <div class="ellipsis flex flex-1 box-border" appSideMenuScrollLeftText>{{ project.nom }}</div>
      <div class="flex flex-row items-center" style="padding-left: 8px">
        @for (subfamily of project.communitySubFamilys; track subfamily.idCommunitySubFamily) {
          <span class="margin-left-5">
            <div
              [matTooltip]="subfamily.organizationSubFamily?.libelle"
              class="badge-side-menu"
              [ngStyle]="{ 'background-color': subfamily.organizationSubFamily?.couleur }"
            ></div>
          </span>
        }
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
}
