export * from './element-cash-generated.actions';

import { createAction, props } from '@ngrx/store';

export const getElementForFinancial = createAction(
  '[ElementCash] Get Project ElementCashs For Financial',
  props<{
    idCommunity: number;
  }>()
);
