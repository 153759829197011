import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationUserProfil, OrganizationUserProfilEntityState } from '@api/api-interfaces';
import { OrganizationUserProfilApiService } from '@wip/store/api-services';
import { OrganizationUserProfilGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationUserProfil } from '@wip/store/configs/normalization';
import { OrganizationUserProfilSelectors } from '@wip/store/selectors';
import { OrganizationUserProfilRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityUserProfilGeneratedActions } from '@wip/store/actions';
import { CommunityUserProfil } from '@api/api-interfaces';
import { UserProfilRightGeneratedActions } from '@wip/store/actions';
import { UserProfilRight } from '@api/api-interfaces';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { Profil } from '@api/api-interfaces';

export function getDefaultAddOrganizationUserProfilActions(
  organizationUserProfil: OrganizationUserProfilEntityState,
  ids?: OrganizationUserProfilRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationUserProfilGeneratedActions.normalizeManyOrganizationUserProfilsAfterUpsert({
      organizationUserProfils: [organizationUserProfil]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationUserProfilSuccess({
        idOrganization: ids.organization,
        idOrganizationUserProfils: [organizationUserProfil.idOrganizationUserProfil]
      })
    );
    actions.push(
      OrganizationUserProfilGeneratedActions.addOrganizationSuccess({
        idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyOrganizationUserProfilSuccess({
        idOrganizationFamily: ids.organizationFamily,
        idOrganizationUserProfils: [organizationUserProfil.idOrganizationUserProfil]
      })
    );
    actions.push(
      OrganizationUserProfilGeneratedActions.addOrganizationFamilySuccess({
        idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.communityUserProfils) {
    if (!Array.isArray(ids.communityUserProfils)) {
      actions.push(
        CommunityUserProfilGeneratedActions.upsertOneCommunityUserProfil({
          communityUserProfil: {
            idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
            idCommunityUserProfil: ids.communityUserProfils as number
          } as CommunityUserProfil
        })
      );
      actions.push(
        OrganizationUserProfilGeneratedActions.addManyCommunityUserProfilSuccess({
          idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
          idCommunityUserProfils: [ids.communityUserProfils as number]
        })
      );
    } else {
      actions.push(
        CommunityUserProfilGeneratedActions.upsertManyCommunityUserProfils({
          communityUserProfils: (ids.communityUserProfils as number[]).map((idCommunityUserProfil: number) => ({
            idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
            idCommunityUserProfil
          })) as CommunityUserProfil[]
        })
      );
      actions.push(
        OrganizationUserProfilGeneratedActions.addManyCommunityUserProfilSuccess({
          idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
          idCommunityUserProfils: ids.communityUserProfils as number[]
        })
      );
    }
  }

  if (ids?.userProfilRights) {
    if (!Array.isArray(ids.userProfilRights)) {
      actions.push(
        UserProfilRightGeneratedActions.upsertOneUserProfilRight({
          userProfilRight: {
            idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
            idUserProfilRight: ids.userProfilRights as number
          } as UserProfilRight
        })
      );
      actions.push(
        OrganizationUserProfilGeneratedActions.addManyUserProfilRightSuccess({
          idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
          idUserProfilRights: [ids.userProfilRights as number]
        })
      );
    } else {
      actions.push(
        UserProfilRightGeneratedActions.upsertManyUserProfilRights({
          userProfilRights: (ids.userProfilRights as number[]).map((idUserProfilRight: number) => ({
            idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
            idUserProfilRight
          })) as UserProfilRight[]
        })
      );
      actions.push(
        OrganizationUserProfilGeneratedActions.addManyUserProfilRightSuccess({
          idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
          idUserProfilRights: ids.userProfilRights as number[]
        })
      );
    }
  }

  if (ids?.profils) {
    if (!Array.isArray(ids.profils)) {
      actions.push(
        ProfilGeneratedActions.upsertOneProfil({
          profil: {
            idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
            idProfil: ids.profils as number
          } as Profil & any
        })
      );
      actions.push(
        OrganizationUserProfilGeneratedActions.addManyProfilSuccess({
          idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
          idProfils: [ids.profils as number]
        })
      );
    } else {
      actions.push(
        ProfilGeneratedActions.upsertManyProfils({
          profils: (ids.profils as number[]).map((idProfil: number) => ({
            idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
            idProfil
          })) as Profil[] & any[]
        })
      );
      actions.push(
        OrganizationUserProfilGeneratedActions.addManyProfilSuccess({
          idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil,
          idProfils: ids.profils as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationUserProfilActions(
  organizationUserProfil: OrganizationUserProfilEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationUserProfilGeneratedActions.deleteOneOrganizationUserProfilSuccess({
      idOrganizationUserProfil: organizationUserProfil.idOrganizationUserProfil
    })
  ];

  if (organizationUserProfil.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationUserProfilSuccess({
        idOrganizationUserProfils: [organizationUserProfil.idOrganizationUserProfil],
        idOrganizations: [organizationUserProfil.organization as number]
      })
    );
  }

  if (organizationUserProfil.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationUserProfilSuccess({
        idOrganizationUserProfils: [organizationUserProfil.idOrganizationUserProfil],
        idOrganizationFamilys: [organizationUserProfil.organizationFamily as number]
      })
    );
  }

  if (organizationUserProfil.communityUserProfils) {
    actions.push(
      CommunityUserProfilGeneratedActions.deleteManyOrganizationUserProfilSuccess({
        idOrganizationUserProfils: [organizationUserProfil.idOrganizationUserProfil],
        idCommunityUserProfils: organizationUserProfil.communityUserProfils as number[]
      })
    );
  }

  if (organizationUserProfil.userProfilRights) {
    actions.push(
      UserProfilRightGeneratedActions.deleteManyOrganizationUserProfilSuccess({
        idOrganizationUserProfils: [organizationUserProfil.idOrganizationUserProfil],
        idUserProfilRights: organizationUserProfil.userProfilRights as number[]
      })
    );
  }

  if (organizationUserProfil.profils) {
    actions.push(
      ProfilGeneratedActions.deleteManyOrganizationUserProfilSuccess({
        idOrganizationUserProfils: [organizationUserProfil.idOrganizationUserProfil],
        idProfils: organizationUserProfil.profils as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationUserProfilEffects {
  constructor(
    protected actions$: Actions,
    protected organizationUserProfilApiService: OrganizationUserProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationUserProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUserProfilGeneratedActions.getManyOrganizationUserProfils),
      switchMap(({ params }) =>
        this.organizationUserProfilApiService.getOrganizationUserProfils(params).pipe(
          map((organizationUserProfils: OrganizationUserProfil[]) => {
            return OrganizationUserProfilGeneratedActions.normalizeManyOrganizationUserProfilsAfterUpsert({
              organizationUserProfils
            });
          }),
          catchError(error => of(OrganizationUserProfilGeneratedActions.organizationUserProfilsFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationUserProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUserProfilGeneratedActions.getOneOrganizationUserProfil),
      switchMap(idOrganizationUserProfil =>
        this.organizationUserProfilApiService.getOrganizationUserProfil(idOrganizationUserProfil).pipe(
          map((organizationUserProfil: OrganizationUserProfil) => {
            return OrganizationUserProfilGeneratedActions.normalizeManyOrganizationUserProfilsAfterUpsert({
              organizationUserProfils: [organizationUserProfil]
            });
          }),
          catchError(error => of(OrganizationUserProfilGeneratedActions.organizationUserProfilsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationUserProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUserProfilGeneratedActions.upsertOneOrganizationUserProfil),
      concatMap(
        ({
          organizationUserProfil,
          ids
        }: {
          organizationUserProfil: Partial<OrganizationUserProfil>;
          ids?: OrganizationUserProfilRelationsIds;
        }) => {
          if (organizationUserProfil.idOrganizationUserProfil) {
            return this.organizationUserProfilApiService.updateOrganizationUserProfil(organizationUserProfil).pipe(
              map((organizationUserProfilReturned: OrganizationUserProfil) => {
                return OrganizationUserProfilGeneratedActions.normalizeManyOrganizationUserProfilsAfterUpsert({
                  organizationUserProfils: [organizationUserProfilReturned]
                });
              }),
              catchError(error => of(OrganizationUserProfilGeneratedActions.organizationUserProfilsFailure({ error })))
            );
          } else {
            return this.organizationUserProfilApiService.addOrganizationUserProfil(organizationUserProfil).pipe(
              mergeMap((organizationUserProfilReturned: OrganizationUserProfil) =>
                getDefaultAddOrganizationUserProfilActions(organizationUserProfilReturned, ids)
              ),
              catchError(error => of(OrganizationUserProfilGeneratedActions.organizationUserProfilsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationUserProfil$ = createEffect(() => {
    const selectOrganizationUserProfilState$ = this.store$.select(
      OrganizationUserProfilSelectors.selectOrganizationUserProfilState
    );
    return this.actions$.pipe(
      ofType(OrganizationUserProfilGeneratedActions.deleteOneOrganizationUserProfil),
      withLatestFrom(selectOrganizationUserProfilState$),
      concatMap(([{ idOrganizationUserProfil }, state]) =>
        this.organizationUserProfilApiService.deleteOrganizationUserProfil(idOrganizationUserProfil).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationUserProfilActions(
              state.entities[idOrganizationUserProfil] as OrganizationUserProfilEntityState
            )
          ),
          catchError(error => of(OrganizationUserProfilGeneratedActions.organizationUserProfilsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationUserProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUserProfilGeneratedActions.normalizeManyOrganizationUserProfilsAfterUpsert),
      concatMap(({ organizationUserProfils }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationUserProfil(
          organizationUserProfils,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationUserProfil] Normalization After Upsert Success')];
      })
    );
  });
}
