import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityGroup, CommunityGroupEntityState } from '@api/api-interfaces';
import { CommunityGroupApiService } from '@wip/store/api-services';
import { CommunityGroupGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityGroup } from '@wip/store/configs/normalization';
import { CommunityGroupSelectors } from '@wip/store/selectors';
import { CommunityGroupRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { GroupGeneratedActions } from '@wip/store/actions';

export function getDefaultAddCommunityGroupActions(
  communityGroup: CommunityGroupEntityState,
  ids?: CommunityGroupRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityGroupGeneratedActions.normalizeManyCommunityGroupsAfterUpsert({ communityGroups: [communityGroup] })
  ];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityGroupSuccess({
        idCommunity: ids.community,
        idCommunityGroups: [communityGroup.idCommunityGroup]
      })
    );
    actions.push(
      CommunityGroupGeneratedActions.addCommunitySuccess({
        idCommunityGroup: communityGroup.idCommunityGroup,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.group) {
    actions.push(
      GroupGeneratedActions.addManyCommunityGroupSuccess({
        idGroup: ids.group,
        idCommunityGroups: [communityGroup.idCommunityGroup]
      })
    );
    actions.push(
      CommunityGroupGeneratedActions.addGroupSuccess({
        idCommunityGroup: communityGroup.idCommunityGroup,
        idGroup: ids.group
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCommunityGroupActions(communityGroup: CommunityGroupEntityState): Action[] {
  const actions: Action[] = [
    CommunityGroupGeneratedActions.deleteOneCommunityGroupSuccess({ idCommunityGroup: communityGroup.idCommunityGroup })
  ];

  if (communityGroup.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityGroupSuccess({
        idCommunityGroups: [communityGroup.idCommunityGroup],
        idCommunities: [communityGroup.community as number]
      })
    );
  }

  if (communityGroup.group) {
    actions.push(
      GroupGeneratedActions.deleteManyCommunityGroupSuccess({
        idCommunityGroups: [communityGroup.idCommunityGroup],
        idGroups: [communityGroup.group as number]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityGroupEffects {
  constructor(
    protected actions$: Actions,
    protected communityGroupApiService: CommunityGroupApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityGroupGeneratedActions.getManyCommunityGroups),
      switchMap(({ params }) =>
        this.communityGroupApiService.getCommunityGroups(params).pipe(
          map((communityGroups: CommunityGroup[]) => {
            return CommunityGroupGeneratedActions.normalizeManyCommunityGroupsAfterUpsert({ communityGroups });
          }),
          catchError(error => of(CommunityGroupGeneratedActions.communityGroupsFailure({ error })))
        )
      )
    );
  });

  getOneCommunityGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityGroupGeneratedActions.getOneCommunityGroup),
      switchMap(idCommunityGroup =>
        this.communityGroupApiService.getCommunityGroup(idCommunityGroup).pipe(
          map((communityGroup: CommunityGroup) => {
            return CommunityGroupGeneratedActions.normalizeManyCommunityGroupsAfterUpsert({
              communityGroups: [communityGroup]
            });
          }),
          catchError(error => of(CommunityGroupGeneratedActions.communityGroupsFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityGroupGeneratedActions.upsertOneCommunityGroup),
      concatMap(
        ({ communityGroup, ids }: { communityGroup: Partial<CommunityGroup>; ids?: CommunityGroupRelationsIds }) => {
          if (communityGroup.idCommunityGroup) {
            return this.communityGroupApiService.updateCommunityGroup(communityGroup).pipe(
              map((communityGroupReturned: CommunityGroup) => {
                return CommunityGroupGeneratedActions.normalizeManyCommunityGroupsAfterUpsert({
                  communityGroups: [communityGroupReturned]
                });
              }),
              catchError(error => of(CommunityGroupGeneratedActions.communityGroupsFailure({ error })))
            );
          } else {
            return this.communityGroupApiService.addCommunityGroup(communityGroup).pipe(
              mergeMap((communityGroupReturned: CommunityGroup) =>
                getDefaultAddCommunityGroupActions(communityGroupReturned, ids)
              ),
              catchError(error => of(CommunityGroupGeneratedActions.communityGroupsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCommunityGroup$ = createEffect(() => {
    const selectCommunityGroupState$ = this.store$.select(CommunityGroupSelectors.selectCommunityGroupState);
    return this.actions$.pipe(
      ofType(CommunityGroupGeneratedActions.deleteOneCommunityGroup),
      withLatestFrom(selectCommunityGroupState$),
      concatMap(([{ idCommunityGroup }, state]) =>
        this.communityGroupApiService.deleteCommunityGroup(idCommunityGroup).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityGroupActions(state.entities[idCommunityGroup] as CommunityGroupEntityState)
          ),
          catchError(error => of(CommunityGroupGeneratedActions.communityGroupsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityGroupsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityGroupGeneratedActions.normalizeManyCommunityGroupsAfterUpsert),
      concatMap(({ communityGroups }) => {
        const actions: Action[] = getActionsToNormalizeCommunityGroup(communityGroups, StoreActionType.upsert);
        return [getMultiAction(actions, '[CommunityGroup] Normalization After Upsert Success')];
      })
    );
  });
}
