import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModule, ProjectModuleEntityState } from '@api/api-interfaces';
import { CommunityModule, CommunityModuleEntityState } from '@api/api-interfaces';
import { OrganizationProjectModule, OrganizationProjectModuleEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { ProjectModuleState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const projectModuleRelations: string[] = ['communityModules', 'organizationProjectModules', 'communities'];

export const { selectEntities, selectAll } = ProjectModuleState.adapter.getSelectors();

export const selectProjectModuleState = createFeatureSelector<ProjectModuleState.IState>(
  ProjectModuleState.projectModuleFeatureKey
);

export const selectIsLoadedProjectModule = createSelector(
  selectProjectModuleState,
  (state: ProjectModuleState.IState) => state.isLoaded
);

export const selectIsLoadingProjectModule = createSelector(
  selectProjectModuleState,
  (state: ProjectModuleState.IState) => state.isLoading
);

export const selectIsReadyProjectModule = createSelector(
  selectProjectModuleState,
  (state: ProjectModuleState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProjectModule = createSelector(
  selectProjectModuleState,
  (state: ProjectModuleState.IState) => state.isLoaded && !state.isLoading
);

export const selectProjectModulesEntities = createSelector(selectProjectModuleState, selectEntities);

export const selectProjectModulesArray = createSelector(selectProjectModuleState, selectAll);

export const selectIdProjectModulesActive = createSelector(
  selectProjectModuleState,
  (state: ProjectModuleState.IState) => state.actives
);

const projectModulesInObject = (projectModules: Dictionary<ProjectModuleEntityState>) => ({ projectModules });

const selectProjectModulesEntitiesDictionary = createSelector(selectProjectModulesEntities, projectModulesInObject);

const selectAllProjectModulesObject = createSelector(selectProjectModulesEntities, projectModules => {
  return hydrateAll({ projectModules });
});

const selectOneProjectModuleDictionary = (idProjectModule: number) =>
  createSelector(selectProjectModulesEntities, projectModules => ({
    projectModules: { [idProjectModule]: projectModules[idProjectModule] }
  }));

const selectOneProjectModuleDictionaryWithoutChild = (idProjectModule: number) =>
  createSelector(selectProjectModulesEntities, projectModules => ({
    projectModule: projectModules[idProjectModule]
  }));

const selectActiveProjectModulesEntities = createSelector(
  selectIdProjectModulesActive,
  selectProjectModulesEntities,
  (actives: number[], projectModules: Dictionary<ProjectModuleEntityState>) =>
    getProjectModulesFromActives(actives, projectModules)
);

function getProjectModulesFromActives(
  actives: number[],
  projectModules: Dictionary<ProjectModuleEntityState>
): Dictionary<ProjectModuleEntityState> {
  return actives.reduce((acc, idActive) => {
    if (projectModules[idActive]) {
      acc[idActive] = projectModules[idActive];
    }
    return acc;
  }, {} as Dictionary<ProjectModuleEntityState>);
}

const selectAllProjectModulesSelectors: Dictionary<Selector> = {};
export function selectAllProjectModules(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProjectModule>(
      schema,
      selectAllProjectModulesSelectors,
      selectProjectModulesEntitiesDictionary,
      getRelationSelectors,
      projectModuleRelations,
      hydrateAll,
      'projectModule'
    );
  } else {
    return selectAllProjectModulesObject;
  }
}

export function selectAllProjectModulesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'projectModules'
): Selector {
  return createSelector(selectAllProjectModules(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProjectModuleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.projectModules.length; i++) {
      res[customKey][result.projectModules[i].idProjectModule] = result.projectModules[i];
    }
    return res;
  });
}

export function selectOneProjectModule(schema: SelectSchema = {}, idProjectModule: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneProjectModuleDictionary(idProjectModule)];
    selectors.push(...getRelationSelectors(schema, projectModuleRelations, 'projectModule'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProjectModuleDictionaryWithoutChild(idProjectModule);
  }
}

export function selectActiveProjectModules(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveProjectModulesEntities, projectModules => ({
      projectModules
    }))
  ];
  selectors.push(...getRelationSelectors(schema, projectModuleRelations, 'projectModule'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  projectModules: Dictionary<ProjectModuleEntityState>;
  communityModules?: Dictionary<CommunityModuleEntityState>;
  organizationProjectModules?: Dictionary<OrganizationProjectModuleEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { projectModules: (ProjectModule | null)[] } {
  const { projectModules, communityModules, organizationProjectModules, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    projectModules: Object.keys(projectModules).map(idProjectModule =>
      hydrate(
        projectModules[idProjectModule] as ProjectModuleEntityState,
        communityModules,
        organizationProjectModules,
        communities
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { projectModule: ProjectModuleEntityState | null } {
  const { projectModules, communityModules, organizationProjectModules, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const projectModule = Object.values(projectModules)[0];
  return {
    projectModule: hydrate(
      projectModule as ProjectModuleEntityState,
      communityModules,
      organizationProjectModules,
      communities
    )
  };
}

function hydrate(
  projectModule: ProjectModuleEntityState,
  communityModuleEntities?: Dictionary<CommunityModuleEntityState>,
  organizationProjectModuleEntities?: Dictionary<OrganizationProjectModuleEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>
): ProjectModule | null {
  if (!projectModule) {
    return null;
  }

  const projectModuleHydrated: ProjectModuleEntityState = { ...projectModule };

  if (communityModuleEntities) {
    projectModuleHydrated.communityModules = ((projectModuleHydrated.communityModules as number[]) || []).map(
      id => communityModuleEntities[id]
    ) as CommunityModule[];
  } else {
    delete projectModuleHydrated.communityModules;
  }

  if (organizationProjectModuleEntities) {
    projectModuleHydrated.organizationProjectModules = (
      (projectModuleHydrated.organizationProjectModules as number[]) || []
    ).map(id => organizationProjectModuleEntities[id]) as OrganizationProjectModule[];
  } else {
    delete projectModuleHydrated.organizationProjectModules;
  }

  if (communityEntities) {
    projectModuleHydrated.communities = ((projectModuleHydrated.communities as number[]) || []).map(
      id => communityEntities[id]
    ) as Community[];
  } else {
    delete projectModuleHydrated.communities;
  }

  return projectModuleHydrated as ProjectModule;
}
