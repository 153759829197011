import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunitySubFamily, CommunitySubFamilyEntityState } from '@api/api-interfaces';
import { CommunitySubFamilyRelationsIds } from '@wip/store/ids-interfaces';

export const CommunitySubFamilyGeneratedActions = createActionGroup({
  source: 'Community Sub Family Generated',
  events: {
    'Get One Community Sub Family': props<{ idCommunitySubFamily: number; params?: any }>(),
    'Get Many Community Sub Familys': props<{ params: any }>(),
    'Add Many Actives Community Sub Familys': props<{ idCommunitySubFamilys: number[] }>(),
    'Delete One Active Community Sub Family': props<{ idCommunitySubFamily: number }>(),
    'Clear Actives Community Sub Familys': emptyProps(),
    'Upsert One Community Sub Family': props<{
      communitySubFamily: Partial<CommunitySubFamily>;
      ids?: CommunitySubFamilyRelationsIds;
    }>(),
    'Upsert Many Community Sub Familys': props<{
      communitySubFamilys: Partial<CommunitySubFamily>[];
      ids?: CommunitySubFamilyRelationsIds;
    }>(),
    'Upsert Many Community Sub Familys Success': props<{ communitySubFamilys: CommunitySubFamilyEntityState[] }>(),
    'Delete One Community Sub Family': props<{ idCommunitySubFamily: number }>(),
    'Delete One Community Sub Family Success': props<{ idCommunitySubFamily: number }>(),
    'Normalize Many Community Sub Familys After Upsert': props<{
      communitySubFamilys: CommunitySubFamilyEntityState[];
    }>(),
    'Community Sub Familys Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Sub Familys': emptyProps(),
    'Add Organization Sub Family Success': props<{ idCommunitySubFamily: number; idOrganizationSubFamily: number }>(),
    'Delete Many Organization Sub Family Success': props<{
      idOrganizationSubFamilys: number[];
      idCommunitySubFamilys: number[];
    }>(),
    'Add Community Success': props<{ idCommunitySubFamily: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunitySubFamilys: number[] }>()
  }
});
