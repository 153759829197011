import { RepositoryService } from '@wip/services/repository';
import { CommunityUser } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityUserApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityUsers(params?: any): Observable<CommunityUser[]> {
    return this.repo.getData<CommunityUser[]>('community-user', params);
  }

  public getCommunityUser(params: { idCommunityUser: number; params?: any }): Observable<CommunityUser> {
    return this.repo.getData<CommunityUser>('community-user/' + params.idCommunityUser, params.params);
  }

  public addCommunityUser(communityUser: Partial<CommunityUser>): Observable<CommunityUser> {
    return this.repo.create<CommunityUser>('community-user', communityUser);
  }

  public updateCommunityUser(communityUser: Partial<CommunityUser>): Observable<CommunityUser> {
    return this.repo.update('community-user', communityUser);
  }

  public deleteCommunityUser(idCommunityUser: number): Observable<number> {
    return this.repo.delete('community-user/' + +idCommunityUser);
  }
}
