import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityDataRow, CommunityDataRowEntityState } from '@api/api-interfaces';
import { Valeur, ValeurEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityDataRowState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityDataRowRelations: string[] = ['valeurs', 'communities'];

export const { selectEntities, selectAll } = CommunityDataRowState.adapter.getSelectors();

export const selectCommunityDataRowState = createFeatureSelector<CommunityDataRowState.IState>(
  CommunityDataRowState.communityDataRowFeatureKey
);

export const selectIsLoadedCommunityDataRow = createSelector(
  selectCommunityDataRowState,
  (state: CommunityDataRowState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityDataRow = createSelector(
  selectCommunityDataRowState,
  (state: CommunityDataRowState.IState) => state.isLoading
);

export const selectIsReadyCommunityDataRow = createSelector(
  selectCommunityDataRowState,
  (state: CommunityDataRowState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityDataRow = createSelector(
  selectCommunityDataRowState,
  (state: CommunityDataRowState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityDataRowsEntities = createSelector(selectCommunityDataRowState, selectEntities);

export const selectCommunityDataRowsArray = createSelector(selectCommunityDataRowState, selectAll);

export const selectIdCommunityDataRowsActive = createSelector(
  selectCommunityDataRowState,
  (state: CommunityDataRowState.IState) => state.actives
);

const communityDataRowsInObject = (communityDataRows: Dictionary<CommunityDataRowEntityState>) => ({
  communityDataRows
});

const selectCommunityDataRowsEntitiesDictionary = createSelector(
  selectCommunityDataRowsEntities,
  communityDataRowsInObject
);

const selectAllCommunityDataRowsObject = createSelector(selectCommunityDataRowsEntities, communityDataRows => {
  return hydrateAll({ communityDataRows });
});

const selectOneCommunityDataRowDictionary = (idCommunityDataRow: number) =>
  createSelector(selectCommunityDataRowsEntities, communityDataRows => ({
    communityDataRows: { [idCommunityDataRow]: communityDataRows[idCommunityDataRow] }
  }));

const selectOneCommunityDataRowDictionaryWithoutChild = (idCommunityDataRow: number) =>
  createSelector(selectCommunityDataRowsEntities, communityDataRows => ({
    communityDataRow: communityDataRows[idCommunityDataRow]
  }));

const selectActiveCommunityDataRowsEntities = createSelector(
  selectIdCommunityDataRowsActive,
  selectCommunityDataRowsEntities,
  (actives: number[], communityDataRows: Dictionary<CommunityDataRowEntityState>) =>
    getCommunityDataRowsFromActives(actives, communityDataRows)
);

function getCommunityDataRowsFromActives(
  actives: number[],
  communityDataRows: Dictionary<CommunityDataRowEntityState>
): Dictionary<CommunityDataRowEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityDataRows[idActive]) {
      acc[idActive] = communityDataRows[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityDataRowEntityState>);
}

const selectAllCommunityDataRowsSelectors: Dictionary<Selector> = {};
export function selectAllCommunityDataRows(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityDataRow>(
      schema,
      selectAllCommunityDataRowsSelectors,
      selectCommunityDataRowsEntitiesDictionary,
      getRelationSelectors,
      communityDataRowRelations,
      hydrateAll,
      'communityDataRow'
    );
  } else {
    return selectAllCommunityDataRowsObject;
  }
}

export function selectAllCommunityDataRowsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityDataRows'
): Selector {
  return createSelector(selectAllCommunityDataRows(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityDataRowEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityDataRows.length; i++) {
      res[customKey][result.communityDataRows[i].idCommunityDataRow] = result.communityDataRows[i];
    }
    return res;
  });
}

export function selectOneCommunityDataRow(schema: SelectSchema = {}, idCommunityDataRow: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityDataRowDictionary(idCommunityDataRow)];
    selectors.push(...getRelationSelectors(schema, communityDataRowRelations, 'communityDataRow'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityDataRowDictionaryWithoutChild(idCommunityDataRow);
  }
}

export function selectActiveCommunityDataRows(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityDataRowsEntities, communityDataRows => ({
      communityDataRows
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityDataRowRelations, 'communityDataRow'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityDataRows: Dictionary<CommunityDataRowEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityDataRows: (CommunityDataRow | null)[] } {
  const { communityDataRows, communities, valeurs } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communityDataRows: Object.keys(communityDataRows).map(idCommunityDataRow =>
      hydrate(communityDataRows[idCommunityDataRow] as CommunityDataRowEntityState, communities, valeurs)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityDataRow: CommunityDataRowEntityState | null } {
  const { communityDataRows, communities, valeurs } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communityDataRow = Object.values(communityDataRows)[0];
  return {
    communityDataRow: hydrate(communityDataRow as CommunityDataRowEntityState, communities, valeurs)
  };
}

function hydrate(
  communityDataRow: CommunityDataRowEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>
): CommunityDataRow | null {
  if (!communityDataRow) {
    return null;
  }

  const communityDataRowHydrated: CommunityDataRowEntityState = { ...communityDataRow };
  if (communityEntities) {
    communityDataRowHydrated.community = communityEntities[communityDataRow.community as number] as Community;
  } else {
    delete communityDataRowHydrated.community;
  }

  if (valeurEntities) {
    communityDataRowHydrated.valeurs = ((communityDataRowHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete communityDataRowHydrated.valeurs;
  }

  return communityDataRowHydrated as CommunityDataRow;
}
