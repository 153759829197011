import { RepositoryService } from '@wip/services/repository';
import { CommunityDataRow } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityDataRowApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityDataRows(params?: any): Observable<CommunityDataRow[]> {
    return this.repo.getData<CommunityDataRow[]>('community-data-row', params);
  }

  public getCommunityDataRow(params: { idCommunityDataRow: number; params?: any }): Observable<CommunityDataRow> {
    return this.repo.getData<CommunityDataRow>('community-data-row/' + params.idCommunityDataRow, params.params);
  }

  public addCommunityDataRow(communityDataRow: Partial<CommunityDataRow>): Observable<CommunityDataRow> {
    return this.repo.create<CommunityDataRow>('community-data-row', communityDataRow);
  }

  public updateCommunityDataRow(communityDataRow: Partial<CommunityDataRow>): Observable<CommunityDataRow> {
    return this.repo.update('community-data-row', communityDataRow);
  }

  public deleteCommunityDataRow(idCommunityDataRow: number): Observable<number> {
    return this.repo.delete('community-data-row/' + +idCommunityDataRow);
  }
}
