import { DeleteButtonComponent } from '@_app/ag-grid-templates/delete-button/delete-button.component';
import { EditButtonRendererComponent } from '@_app/ag-grid-templates/edit-button-renderer/edit-button-renderer.component';
import { AgGridUtils } from '@_app/utils/ag-grid/cell-formatter/ag-grid.utils';
import AgGridCellCommonFormatter from '@_app/utils/ag-grid/cell-formatter/common-cell-formatter';
import { ColDef, GridApi, GridOptions, GridReadyEvent, Module } from '@ag-grid-community/core';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrganizationUser, UserBoardState } from '@api/api-interfaces';
import { BoardTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationChoicesDialogContainerComponent } from '@wip/dialogs/confirmation-choices-dialog';
import { ConfirmationChoicesInterface } from '@wip/interfaces';
import { OrganizationUserModel, UserBoardStateModel, UserModel } from '@wip/store/selectors-model';
import { OrganizationUserService, UserBoardStateService, UserService } from '@wip/store/services';
import { AgGridCellIconFormatter, getRowId } from '@wip/wip/front-utils';
import { Observable, ReplaySubject, combineLatest, tap } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'wip-filter-configuration',
  templateUrl: './filter-configuration.component.html',
  styleUrls: ['./filter-configuration.component.scss']
})
export class FilterConfigurationComponent implements OnInit {
  public libelle: string;
  public configControl: FormControl;
  public gridOptions: GridOptions;
  public boardStates: UserBoardState[];
  public modules: Module[] = AgGridUtils.getCommonModules();
  public gridApi: GridApi;
  public buttonTitle: string = 'Personnaliser';

  private gridReady$ = new ReplaySubject<any>(1);
  private generalBoardState;
  private filterState;
  private lastIndex: number;
  private isRowAdded = false;
  private parsedFilterState;
  private selectedBoardState: number;
  private currentOrganizationFamily: number = null;
  private currentOrganizationUser: OrganizationUser = null;
  private allOrgaUsers: OrganizationUser[];
  private selected: boolean = false;
  private stopGenerate: boolean = false;

  constructor(
    private dialog: MatDialog,
    private boardStateService: UserBoardStateService,
    private readonly organizationUserService: OrganizationUserService,
    private readonly userService: UserService,
    public dialogRef: MatDialogRef<FilterConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {
    this.configControl = new FormControl('');
    this.gridOptions = this.getDefaultGridOptions();
    // this.generalBoardState = this.dialogData.organizationUser.userBoardStates.find(
    //   bs => bs.type === BoardTypeEnum.project
    // );
    // this.filterState = this.generalBoardState?.filterState;
    // this.parsedFilterState = this.filterState ? JSON.parse(this.filterState) : {};
    // this.currentOrganizationFamily = this.parsedFilterState?.organizationFamily;
    // if (this.parsedFilterState.configuration) {
    //   this.selectedBoardState =
    //     this.parsedFilterState?.configuration[this.parsedFilterState?.organizationFamily?.toString()];
    // }
    combineLatest([
      this.gridReady$,
      this.boardStateService.selectAllUserBoardStates({
        include: [{ model: OrganizationUserModel, include: [UserModel] }]
      }),
      this.organizationUserService.selectAllActiveOrganizationUsers({ include: [UserBoardStateModel] }),
      this.organizationUserService.selectAllOrganizationUsers({ include: [UserModel] })
    ])
      .pipe(
        untilDestroyed(this),
        tap(([_, boardStates, currentOrganizationUser, allOrgaUsers]) => {
          this.currentOrganizationUser = currentOrganizationUser[0];
          if (boardStates && allOrgaUsers?.length && !this.stopGenerate) {
            this.allOrgaUsers = allOrgaUsers;
            this.gridApi?.setGridOption('rowData', null);
            this.boardStates = boardStates.filter(
              bs =>
                (bs.idOrganizationUser === this.dialogData.organizationUser.idOrganizationUser || bs.shared) &&
                bs.type === BoardTypeEnum.synthesis &&
                bs.idOrganizationFamily === this.dialogData.idOrganizationFamily
            );

            if (!this.selectedBoardState && this.currentOrganizationUser) {
              this.generalBoardState = this.currentOrganizationUser.userBoardStates.find(
                bs => bs.type === BoardTypeEnum.project
              );
              this.filterState = this.generalBoardState?.filterState;
              this.parsedFilterState = this.filterState ? JSON.parse(this.filterState) : {};
              this.currentOrganizationFamily = this.parsedFilterState?.organizationFamily;
              if (this.parsedFilterState.configuration) {
                this.selectedBoardState =
                  this.parsedFilterState?.configuration[this.parsedFilterState?.organizationFamily?.toString()];
              }
              if (this.selectedBoardState) {
                this.stopGenerate = true;
              }
            }

            this.gridApi?.setGridOption('columnDefs', []);
            this.gridApi?.setGridOption('columnDefs', this.createColumnDefs());
            this.gridApi?.setGridOption('rowData', []);
            this.gridApi?.setGridOption('rowData', [
              { libelle: 'Aucune configuration', idUserBoardState: null },
              ...this.boardStates
            ]);
            this.gridApi?.forEachNode(rowNode => {
              if (rowNode.data.idUserBoardState === this.selectedBoardState && !this.selected) {
                rowNode.setSelected(true);
                this.selected = true;
              }
            });
          }
        })
      )
      .subscribe();
  }

  private getDefaultGridOptions(): GridOptions {
    return {
      ...AgGridUtils.getCommonGridOption(),
      columnDefs: this.createColumnDefs(), // Define columns
      columnTypes: {
        action: {
          editable: false,
          filter: false
        }
      },
      context: {
        componentParent: this // allow us to access to the component from the grid
      },
      defaultColDef: {
        ...AgGridUtils.getCommonGridOption().defaultColDef,
        editable: true,
        lockPosition: true,
        filter: false,
        resizable: true,
        sortable: false
      },
      domLayout: 'autoHeight',
      rowMultiSelectWithClick: false,
      suppressRowClickSelection: true,
      onCellValueChanged: this.updateValues.bind(this),
      onGridSizeChanged: this.isFocusChanged.bind(this),
      rowData: [],
      stopEditingWhenCellsLoseFocus: true,
      getRowId: params => getRowId(params.data.idOrganizationSubFamily)
    };
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridReady$.next(1);
  }

  private createColumnDefs(): ColDef[] {
    return [
      {
        headerName: 'Sélection',
        colId: 'redirect',
        width: 70,
        editable: false,
        cellRenderer: (params: { data: UserBoardState }): string =>
          AgGridUtils.makeRadio(params.data.idUserBoardState === this.selectedBoardState),
        cellStyle: { cursor: 'pointer' },
        onCellClicked: (event: { data: UserBoardState }): void => {
          this.selectedBoardState = event.data.idUserBoardState;
          this.boardStateService.upsertOneUserBoardState({
            ...this.generalBoardState,
            filterState: JSON.stringify({
              ...this.parsedFilterState,
              configuration: {
                [this.parsedFilterState.organizationFamily]: this.selectedBoardState
              }
            })
          });
          this.dialogRef.close();
        }
      },
      {
        headerName: 'Nom configuration',
        field: 'libelle',
        width: 200
      },
      {
        colId: 'user',
        field: 'user',
        headerName: 'Créateur',
        tooltipField: 'user.email',
        width: 65,
        editable: false,
        cellRenderer: ({ data }) => {
          return AgGridCellCommonFormatter.cellRendererPillUser(
            data?.organizationUser?.user ||
              this.allOrgaUsers.find(ou => ou.idOrganizationUser === data?.idOrganizationUser)?.user
          );
        }
      },
      {
        headerName: 'Partager',
        headerTooltip: 'Partager cette configuration avec tous les utilisateurs',
        editable: params => params.data.idUserBoardState && this.isOwnByUser(params),
        field: 'shared',
        width: 70,
        cellStyle: { 'text-align': 'center' },
        cellDataType: 'boolean'
      },
      {
        headerName: '',
        field: 'edit',
        editable: false,
        cellClass: 'display-on-hover',
        cellStyle: { 'text-align': 'center' },
        cellRendererSelector: this.displayEditButton.bind(this),
        width: 130
      },
      {
        headerName: '',
        minWidth: 30,
        colId: 'duplicate',
        maxWidth: 30,
        editable: false,
        cellRenderer: (): string =>
          AgGridCellIconFormatter.cellRendererIcon('duplicate', [
            {
              value: 'duplicate',
              icon: 'duplicate',
              text: 'Dupliquer',
              class: 'icon-ic_fluent_copy_select_24_regular display-on-hover'
            }
          ]),
        onCellClicked: (event: { data: UserBoardState }): void => {
          if (event.data?.idUserBoardState === null) {
            this.boardStateService.upsertOneUserBoardState({
              libelle: event.data.libelle + ' (copie)',
              type: BoardTypeEnum.synthesis,
              idOrganizationFamily: this.currentOrganizationFamily,
              idOrganizationUser: this.currentOrganizationUser.idOrganizationUser
            });
          } else {
            this.boardStateService.upsertOneUserBoardState({
              libelle: event.data.libelle + ' (copie)',
              columnState: event.data.columnState,
              filterState: event.data.filterState,
              type: BoardTypeEnum.synthesis,
              idOrganizationFamily: this.currentOrganizationFamily,
              idOrganizationUser: this.currentOrganizationUser.idOrganizationUser
            });
          }
        }
      },
      {
        headerName: ' ',
        field: 'delete',
        type: 'action',
        cellRendererSelector: this.displayDeleteButton.bind(this),
        width: 33,
        cellStyle: { 'text-align': 'center' }
      }
    ];
  }

  public onEdit(event) {
    if (this.isOwnByUser(event.data)) {
      this.selectedBoardState = event.data.idUserBoardState;
      this.boardStateService.upsertOneUserBoardState({
        ...this.generalBoardState,
        filterState: JSON.stringify({
          ...this.parsedFilterState,
          configuration: {
            [this.parsedFilterState.organizationFamily]: this.selectedBoardState
          }
        })
      });
      this.dialogRef.close();
      this.organizationUserService.toggleSideBar$.next(true);
    }
  }

  private displayDeleteButton(params) {
    if (params.data.idUserBoardState) {
      return {
        component: DeleteButtonComponent
      };
    }
    return null;
  }

  private displayEditButton(params) {
    if (this.isOwnByUser(params)) {
      return {
        component: EditButtonRendererComponent
      };
    }
  }

  private isFocusChanged() {
    if (this.isRowAdded) {
      this.focusOnLastRow();
      this.isRowAdded = false;
    }
  }

  private focusOnLastRow() {
    this.gridApi?.startEditingCell({
      rowIndex: this.lastIndex,
      colKey: 'libelle'
    });
  }

  public isOwnByUser(params: any) {
    return (
      this.currentOrganizationUser.idOrganizationUser ===
      (params?.idOrganizationUser || params?.data?.idOrganizationUser)
    );
  }

  public onDelete(event) {
    const body = 'Êtes vous sûr de vouloir supprimer cette configuration ?';

    const actions: ConfirmationChoicesInterface[] = [
      {
        color: 'warn',
        label: 'Supprimer'
      },
      {
        color: '',
        label: 'Annuler'
      }
    ];
    const confirmationDialog: MatDialogRef<ConfirmationChoicesDialogContainerComponent> = this.dialog.open(
      ConfirmationChoicesDialogContainerComponent,
      {
        data: {
          title: 'Suppression',
          body,
          actions
        },
        autoFocus: false
      }
    );

    confirmationDialog.beforeClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        (this.boardStateService.deleteOneUserBoardState(event.data.idUserBoardState, true) as Observable<number>).pipe(
          untilDestroyed(this),
          tap(_ => {
            this.setFilter(null);
          })
        );
      }
    });
  }

  public onClickAddNewRow() {
    (
      this.boardStateService.upsertOneUserBoardState(
        {
          idOrganizationFamily: this.dialogData.idOrganizationFamily,
          idOrganizationUser: this.dialogData.organizationUser.idOrganizationUser,
          libelle: 'Configuration ' + +(this.gridApi?.getLastDisplayedRowIndex() + 1),
          type: BoardTypeEnum.synthesis,
          columnState: JSON.stringify(this.dialogData.columnState),
          filterState: JSON.stringify(this.dialogData.filterState)
        },
        {},
        true
      ) as Observable<UserBoardState>
    )
      .pipe(
        untilDestroyed(this),
        tap(bs => {
          this.setFilter(bs.idUserBoardState);
        })
      )
      .subscribe();
    this.lastIndex = this.gridApi?.getLastDisplayedRowIndex() + 1;
    this.isRowAdded = true;
  }

  public updateValues(event) {
    const newValues = {
      idUserBoardState: event.data.idUserBoardState,
      [event?.colDef?.field]: event?.value
    };
    this.boardStateService.upsertOneUserBoardState(newValues);
  }

  private setFilter(idBoardStateToSet: number) {
    const filterStateJson = JSON.parse(this.filterState);
    if (!filterStateJson.configuration) {
      filterStateJson.configuration = {};
    }
    filterStateJson.configuration[this.dialogData.idOrganizationFamily] = idBoardStateToSet;
    (
      this.boardStateService.upsertOneUserBoardState(
        {
          idUserBoardState: this.generalBoardState.idUserBoardState,
          filterState: JSON.stringify(filterStateJson)
        },
        null,
        true
      ) as Observable<UserBoardState>
    )
      .pipe(
        untilDestroyed(this),
        tap(_ => {
          this.userService.getCurrentUser(this.userService.currentIdUser);
        })
      )
      .subscribe();
  }
}
