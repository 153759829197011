import { ProjectModuleState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { projectModuleReducersGeneratedFunctions } from './project-module-generated.reducer';

const projectModuleReducersFunctions = [...projectModuleReducersGeneratedFunctions];

const projectModuleReducer = createReducer(ProjectModuleState.initialState, ...projectModuleReducersFunctions);

export function reducer(state: ProjectModuleState.IState | undefined, action: Action) {
  return projectModuleReducer(state, action);
}
