import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationCaracteristicRowApiService } from '@wip/store/api-services';
import { GeneratedOrganizationCaracteristicRowEffects } from './organization-caracteristic-row-generated.effects';

@Injectable()
export class OrganizationCaracteristicRowEffects extends GeneratedOrganizationCaracteristicRowEffects {
  constructor(actions$: Actions, organizationCaracteristicRowApiService: OrganizationCaracteristicRowApiService, store$: Store<AppState>) {
    super(actions$, organizationCaracteristicRowApiService, store$);
  }
}
