import { OrganizationSectionState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationSectionReducersGeneratedFunctions } from './organization-section-generated.reducer';

const organizationSectionReducersFunctions = [...organizationSectionReducersGeneratedFunctions];

const organizationSectionReducer = createReducer(
  OrganizationSectionState.initialState,
  ...organizationSectionReducersFunctions
);

export function reducer(state: OrganizationSectionState.IState | undefined, action: Action) {
  return organizationSectionReducer(state, action);
}
