import { createEntityAdapter } from '@ngrx/entity';
import { CommunitySubFamilyEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<CommunitySubFamilyEntityState>;

export const adapter = createEntityAdapter<CommunitySubFamilyEntityState>({
  selectId: o => o.idCommunitySubFamily
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const communitySubFamilyFeatureKey = 'communitySubFamily';
