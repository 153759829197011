export enum ReorderTypeEnum {
  step,
  userProfil,
  caracteristic,
  kpi,
  milestone,
  milestoneFamily,
  risk,
  multipleCaracColumn,
  multipleCaracColumnValue,
  multipleCaracRow
}
