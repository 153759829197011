import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Meeting, MeetingEntityState } from '@api/api-interfaces';
import { MeetingApiService } from '@wip/store/api-services';
import { MeetingGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeMeeting } from '@wip/store/configs/normalization';
import { MeetingSelectors } from '@wip/store/selectors';
import { MeetingRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { MeetingElementGeneratedActions } from '@wip/store/actions';
import { MeetingElement } from '@api/api-interfaces';
import { MeetingUserGeneratedActions } from '@wip/store/actions';
import { MeetingUser } from '@api/api-interfaces';

export function getDefaultAddMeetingActions(meeting: MeetingEntityState, ids?: MeetingRelationsIds): Action[] {
  const actions: Action[] = [MeetingGeneratedActions.normalizeManyMeetingsAfterUpsert({ meetings: [meeting] })];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyMeetingSuccess({
        idCommunity: ids.community,
        idMeetings: [meeting.idMeeting]
      })
    );
    actions.push(
      MeetingGeneratedActions.addCommunitySuccess({
        idMeeting: meeting.idMeeting,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.meetingElements) {
    if (!Array.isArray(ids.meetingElements)) {
      actions.push(
        MeetingElementGeneratedActions.upsertOneMeetingElement({
          meetingElement: {
            idMeeting: meeting.idMeeting,
            idMeetingElement: ids.meetingElements as number
          } as MeetingElement
        })
      );
      actions.push(
        MeetingGeneratedActions.addManyMeetingElementSuccess({
          idMeeting: meeting.idMeeting,
          idMeetingElements: [ids.meetingElements as number]
        })
      );
    } else {
      actions.push(
        MeetingElementGeneratedActions.upsertManyMeetingElements({
          meetingElements: (ids.meetingElements as number[]).map((idMeetingElement: number) => ({
            idMeeting: meeting.idMeeting,
            idMeetingElement
          })) as MeetingElement[]
        })
      );
      actions.push(
        MeetingGeneratedActions.addManyMeetingElementSuccess({
          idMeeting: meeting.idMeeting,
          idMeetingElements: ids.meetingElements as number[]
        })
      );
    }
  }

  if (ids?.meetingUsers) {
    if (!Array.isArray(ids.meetingUsers)) {
      actions.push(
        MeetingUserGeneratedActions.upsertOneMeetingUser({
          meetingUser: {
            idMeeting: meeting.idMeeting,
            idMeetingUser: ids.meetingUsers as number
          } as MeetingUser
        })
      );
      actions.push(
        MeetingGeneratedActions.addManyMeetingUserSuccess({
          idMeeting: meeting.idMeeting,
          idMeetingUsers: [ids.meetingUsers as number]
        })
      );
    } else {
      actions.push(
        MeetingUserGeneratedActions.upsertManyMeetingUsers({
          meetingUsers: (ids.meetingUsers as number[]).map((idMeetingUser: number) => ({
            idMeeting: meeting.idMeeting,
            idMeetingUser
          })) as MeetingUser[]
        })
      );
      actions.push(
        MeetingGeneratedActions.addManyMeetingUserSuccess({
          idMeeting: meeting.idMeeting,
          idMeetingUsers: ids.meetingUsers as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteMeetingActions(meeting: MeetingEntityState): Action[] {
  const actions: Action[] = [MeetingGeneratedActions.deleteOneMeetingSuccess({ idMeeting: meeting.idMeeting })];

  if (meeting.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyMeetingSuccess({
        idMeetings: [meeting.idMeeting],
        idCommunities: [meeting.community as number]
      })
    );
  }

  if (meeting.meetingElements) {
    actions.push(
      MeetingElementGeneratedActions.deleteManyMeetingSuccess({
        idMeetings: [meeting.idMeeting],
        idMeetingElements: meeting.meetingElements as number[]
      })
    );
  }

  if (meeting.meetingUsers) {
    actions.push(
      MeetingUserGeneratedActions.deleteManyMeetingSuccess({
        idMeetings: [meeting.idMeeting],
        idMeetingUsers: meeting.meetingUsers as number[]
      })
    );
  }

  return actions;
}

export class GeneratedMeetingEffects {
  constructor(
    protected actions$: Actions,
    protected meetingApiService: MeetingApiService,
    protected store$: Store<AppState>
  ) {}

  getManyMeetings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingGeneratedActions.getManyMeetings),
      switchMap(({ params }) =>
        this.meetingApiService.getMeetings(params).pipe(
          map((meetings: Meeting[]) => {
            return MeetingGeneratedActions.normalizeManyMeetingsAfterUpsert({ meetings });
          }),
          catchError(error => of(MeetingGeneratedActions.meetingsFailure({ error })))
        )
      )
    );
  });

  getOneMeeting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingGeneratedActions.getOneMeeting),
      switchMap(idMeeting =>
        this.meetingApiService.getMeeting(idMeeting).pipe(
          map((meeting: Meeting) => {
            return MeetingGeneratedActions.normalizeManyMeetingsAfterUpsert({ meetings: [meeting] });
          }),
          catchError(error => of(MeetingGeneratedActions.meetingsFailure({ error })))
        )
      )
    );
  });

  upsertOneMeeting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingGeneratedActions.upsertOneMeeting),
      concatMap(({ meeting, ids }: { meeting: Partial<Meeting>; ids?: MeetingRelationsIds }) => {
        if (meeting.idMeeting) {
          return this.meetingApiService.updateMeeting(meeting).pipe(
            map((meetingReturned: Meeting) => {
              return MeetingGeneratedActions.normalizeManyMeetingsAfterUpsert({ meetings: [meetingReturned] });
            }),
            catchError(error => of(MeetingGeneratedActions.meetingsFailure({ error })))
          );
        } else {
          return this.meetingApiService.addMeeting(meeting).pipe(
            mergeMap((meetingReturned: Meeting) => getDefaultAddMeetingActions(meetingReturned, ids)),
            catchError(error => of(MeetingGeneratedActions.meetingsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneMeeting$ = createEffect(() => {
    const selectMeetingState$ = this.store$.select(MeetingSelectors.selectMeetingState);
    return this.actions$.pipe(
      ofType(MeetingGeneratedActions.deleteOneMeeting),
      withLatestFrom(selectMeetingState$),
      concatMap(([{ idMeeting }, state]) =>
        this.meetingApiService.deleteMeeting(idMeeting).pipe(
          mergeMap(_success => getDefaultDeleteMeetingActions(state.entities[idMeeting] as MeetingEntityState)),
          catchError(error => of(MeetingGeneratedActions.meetingsFailure({ error })))
        )
      )
    );
  });

  normalizeManyMeetingsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingGeneratedActions.normalizeManyMeetingsAfterUpsert),
      concatMap(({ meetings }) => {
        const actions: Action[] = getActionsToNormalizeMeeting(meetings, StoreActionType.upsert);
        return [getMultiAction(actions, '[Meeting] Normalization After Upsert Success')];
      })
    );
  });
}
