import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationUserProfil, OrganizationUserProfilEntityState } from '@api/api-interfaces';
import { CommunityUserProfil, CommunityUserProfilEntityState } from '@api/api-interfaces';
import { UserProfilRight, UserProfilRightEntityState } from '@api/api-interfaces';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationUserProfilState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationUserProfilRelations: string[] = [
  'communityUserProfils',
  'userProfilRights',
  'profils',
  'organizations',
  'organizationFamilys'
];

export const { selectEntities, selectAll } = OrganizationUserProfilState.adapter.getSelectors();

export const selectOrganizationUserProfilState = createFeatureSelector<OrganizationUserProfilState.IState>(
  OrganizationUserProfilState.organizationUserProfilFeatureKey
);

export const selectIsLoadedOrganizationUserProfil = createSelector(
  selectOrganizationUserProfilState,
  (state: OrganizationUserProfilState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationUserProfil = createSelector(
  selectOrganizationUserProfilState,
  (state: OrganizationUserProfilState.IState) => state.isLoading
);

export const selectIsReadyOrganizationUserProfil = createSelector(
  selectOrganizationUserProfilState,
  (state: OrganizationUserProfilState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationUserProfil = createSelector(
  selectOrganizationUserProfilState,
  (state: OrganizationUserProfilState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationUserProfilsEntities = createSelector(selectOrganizationUserProfilState, selectEntities);

export const selectOrganizationUserProfilsArray = createSelector(selectOrganizationUserProfilState, selectAll);

export const selectIdOrganizationUserProfilsActive = createSelector(
  selectOrganizationUserProfilState,
  (state: OrganizationUserProfilState.IState) => state.actives
);

const organizationUserProfilsInObject = (organizationUserProfils: Dictionary<OrganizationUserProfilEntityState>) => ({
  organizationUserProfils
});

const selectOrganizationUserProfilsEntitiesDictionary = createSelector(
  selectOrganizationUserProfilsEntities,
  organizationUserProfilsInObject
);

const selectAllOrganizationUserProfilsObject = createSelector(
  selectOrganizationUserProfilsEntities,
  organizationUserProfils => {
    return hydrateAll({ organizationUserProfils });
  }
);

const selectOneOrganizationUserProfilDictionary = (idOrganizationUserProfil: number) =>
  createSelector(selectOrganizationUserProfilsEntities, organizationUserProfils => ({
    organizationUserProfils: { [idOrganizationUserProfil]: organizationUserProfils[idOrganizationUserProfil] }
  }));

const selectOneOrganizationUserProfilDictionaryWithoutChild = (idOrganizationUserProfil: number) =>
  createSelector(selectOrganizationUserProfilsEntities, organizationUserProfils => ({
    organizationUserProfil: organizationUserProfils[idOrganizationUserProfil]
  }));

const selectActiveOrganizationUserProfilsEntities = createSelector(
  selectIdOrganizationUserProfilsActive,
  selectOrganizationUserProfilsEntities,
  (actives: number[], organizationUserProfils: Dictionary<OrganizationUserProfilEntityState>) =>
    getOrganizationUserProfilsFromActives(actives, organizationUserProfils)
);

function getOrganizationUserProfilsFromActives(
  actives: number[],
  organizationUserProfils: Dictionary<OrganizationUserProfilEntityState>
): Dictionary<OrganizationUserProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationUserProfils[idActive]) {
      acc[idActive] = organizationUserProfils[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationUserProfilEntityState>);
}

const selectAllOrganizationUserProfilsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationUserProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationUserProfil>(
      schema,
      selectAllOrganizationUserProfilsSelectors,
      selectOrganizationUserProfilsEntitiesDictionary,
      getRelationSelectors,
      organizationUserProfilRelations,
      hydrateAll,
      'organizationUserProfil'
    );
  } else {
    return selectAllOrganizationUserProfilsObject;
  }
}

export function selectAllOrganizationUserProfilsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationUserProfils'
): Selector {
  return createSelector(selectAllOrganizationUserProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationUserProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationUserProfils.length; i++) {
      res[customKey][result.organizationUserProfils[i].idOrganizationUserProfil] = result.organizationUserProfils[i];
    }
    return res;
  });
}

export function selectOneOrganizationUserProfil(schema: SelectSchema = {}, idOrganizationUserProfil: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationUserProfilDictionary(idOrganizationUserProfil)];
    selectors.push(...getRelationSelectors(schema, organizationUserProfilRelations, 'organizationUserProfil'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationUserProfilDictionaryWithoutChild(idOrganizationUserProfil);
  }
}

export function selectActiveOrganizationUserProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationUserProfilsEntities, organizationUserProfils => ({
      organizationUserProfils
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationUserProfilRelations, 'organizationUserProfil'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationUserProfils: Dictionary<OrganizationUserProfilEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  communityUserProfils?: Dictionary<CommunityUserProfilEntityState>;
  userProfilRights?: Dictionary<UserProfilRightEntityState>;
  profils?: Dictionary<ProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationUserProfils: (OrganizationUserProfil | null)[] } {
  const {
    organizationUserProfils,
    organizations,
    organizationFamilys,
    communityUserProfils,
    userProfilRights,
    profils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationUserProfils: Object.keys(organizationUserProfils).map(idOrganizationUserProfil =>
      hydrate(
        organizationUserProfils[idOrganizationUserProfil] as OrganizationUserProfilEntityState,
        organizations,
        organizationFamilys,
        communityUserProfils,
        userProfilRights,
        profils
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationUserProfil: OrganizationUserProfilEntityState | null } {
  const {
    organizationUserProfils,
    organizations,
    organizationFamilys,
    communityUserProfils,
    userProfilRights,
    profils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationUserProfil = Object.values(organizationUserProfils)[0];
  return {
    organizationUserProfil: hydrate(
      organizationUserProfil as OrganizationUserProfilEntityState,
      organizations,
      organizationFamilys,
      communityUserProfils,
      userProfilRights,
      profils
    )
  };
}

function hydrate(
  organizationUserProfil: OrganizationUserProfilEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  communityUserProfilEntities?: Dictionary<CommunityUserProfilEntityState>,
  userProfilRightEntities?: Dictionary<UserProfilRightEntityState>,
  profilEntities?: Dictionary<ProfilEntityState>
): OrganizationUserProfil | null {
  if (!organizationUserProfil) {
    return null;
  }

  const organizationUserProfilHydrated: OrganizationUserProfilEntityState = { ...organizationUserProfil };
  if (organizationEntities) {
    organizationUserProfilHydrated.organization = organizationEntities[
      organizationUserProfil.organization as number
    ] as Organization;
  } else {
    delete organizationUserProfilHydrated.organization;
  }
  if (organizationFamilyEntities) {
    organizationUserProfilHydrated.organizationFamily = organizationFamilyEntities[
      organizationUserProfil.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete organizationUserProfilHydrated.organizationFamily;
  }

  if (communityUserProfilEntities) {
    organizationUserProfilHydrated.communityUserProfils = (
      (organizationUserProfilHydrated.communityUserProfils as number[]) || []
    ).map(id => communityUserProfilEntities[id]) as CommunityUserProfil[];
  } else {
    delete organizationUserProfilHydrated.communityUserProfils;
  }

  if (userProfilRightEntities) {
    organizationUserProfilHydrated.userProfilRights = (
      (organizationUserProfilHydrated.userProfilRights as number[]) || []
    ).map(id => userProfilRightEntities[id]) as UserProfilRight[];
  } else {
    delete organizationUserProfilHydrated.userProfilRights;
  }

  if (profilEntities) {
    organizationUserProfilHydrated.profils = ((organizationUserProfilHydrated.profils as number[]) || []).map(
      id => profilEntities[id]
    ) as Profil[];
  } else {
    delete organizationUserProfilHydrated.profils;
  }

  return organizationUserProfilHydrated as OrganizationUserProfil;
}
