import { RepositoryService } from '@wip/services/repository';
import { ProjectElement } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProjectElementApiService {
  constructor(protected repo: RepositoryService) {}

  public getProjectElements(params?: any): Observable<ProjectElement[]> {
    return this.repo.getData<ProjectElement[]>('project-element', params);
  }

  public getProjectElement(params: { idProjectElement: number; params?: any }): Observable<ProjectElement> {
    return this.repo.getData<ProjectElement>('project-element/' + params.idProjectElement, params.params);
  }

  public addProjectElement(projectElement: Partial<ProjectElement>): Observable<ProjectElement> {
    return this.repo.create<ProjectElement>('project-element', projectElement);
  }

  public updateProjectElement(projectElement: Partial<ProjectElement>): Observable<ProjectElement> {
    return this.repo.update('project-element', projectElement);
  }

  public deleteProjectElement(idProjectElement: number): Observable<number> {
    return this.repo.delete('project-element/' + +idProjectElement);
  }
}
