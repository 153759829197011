/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Organization, User } from '@api/api-interfaces';
import { UserDetailDialogComponent } from '@_app/user/user-detail-dialog/user-detail-dialog.component';
import { ElementLibraryTypeEnum, MediaScreenSize } from '@enums';
import { capitalize } from 'lodash-es';
import { ConfigurationApiService } from '@api/old-api-services';
import { DeviceInformationsService } from '../../../core/device/device-information.service';
import { Color } from '../../colorConstant';
import Responsible from '@_app/utils/responsible.utils';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
  @Input() size: string;
  @Input() user?: User; // Soit user , soit MetaUser
  @Input() metaUser?: any;
  @Input() organization?: Organization;
  @Input() height: number;
  @Input() width: number;
  @Input() customTooltips: string;
  @Input() tooltip = true;
  @Input() check?: any;
  @Input() shouldDisplayInfo?: boolean;

  public notSmartphoneNotOnlyTabletTooltip: boolean;
  public tooltipsText: string;
  public urlImage: string;
  public color: Color;
  public backgroundHeight: string;
  public backgroundWidth: string;
  public fontHeight: string;
  public initialsText = '';
  public token: string;
  public userDetailModal: User = null;

  constructor(
    private deviceInformation: DeviceInformationsService,
    public configurationApi: ConfigurationApiService,
    private dialog: MatDialog
  ) {
    this.initialsText = '';
  }

  ngOnChanges() {
    this.calcAvatarData();
  }

  public calcAvatarData() {
    this.tooltipsText = '';

    this.notSmartphoneNotOnlyTabletTooltip =
      this.tooltip !== false && !this.deviceInformation.isMediaScreenSize(MediaScreenSize.ltSm);

    if (this.user) {
      this.tooltipsText = this.calcTooltipFromUser(this.user);
      this.initialsText = this.calcInitialFromUser(this.user);
      this.userDetailModal = this.user;

      this.calcAvatarStyle();
    } else if (this.metaUser) {
      this.calcAvatarStyle();
      if (this.metaUser.type) {
        if (this.metaUser.type === ElementLibraryTypeEnum.user_idUser && this.metaUser.user) {
          this.tooltipsText = this.calcTooltipFromUser(this.metaUser.user);
          this.initialsText = this.calcInitialFromUser(this.metaUser.user);
          this.userDetailModal = this.metaUser.user;
        } else if (this.metaUser.type === ElementLibraryTypeEnum.user_nom) {
          // calcul du tooltip
          if (this.notSmartphoneNotOnlyTabletTooltip) {
            if (this.customTooltips) {
              this.tooltipsText = this.customTooltips;
            }
            if (this.metaUser.nom) {
              this.tooltipsText = capitalize(this.metaUser.nom);
            }
          }

          // calcul de l'initial
          if (this.user?.pseudo) {
            this.initialsText = this.user.pseudo;
          } else if (this.metaUser.nom && this.metaUser.nom.length) {
            this.initialsText = this.metaUser.nom[0];
            if (this.metaUser.nom.length > 1) {
              this.initialsText += (this.metaUser.nom[1] || '').toLowerCase();
            }
          } else {
            this.initialsText = '2';
          }
        } else if (this.metaUser.type === ElementLibraryTypeEnum.user_profile) {
          // calcul du tooltip
          if (this.customTooltips) {
            this.tooltipsText = this.customTooltips;
          } else {
            this.tooltipsText = capitalize(
              this.metaUser.libelle
                ? this.metaUser.libelle
                : this.metaUser.organizationUserProfil?.libelle
                  ? this.metaUser.organizationUserProfil.libelle
                  : this.metaUser.communityUserProfil?.organizationUserProfil?.libelle
                    ? this.metaUser.communityUserProfil.organizationUserProfil.libelle
                    : this.metaUser.communityUserProfil?.libelle
            );
            if (this.metaUser.user || this.metaUser.communityUserProfil?.user) {
              const user = this.metaUser.user || this.metaUser.communityUserProfil?.user;
              const userPrenom = this.calcTooltipFromUser(user);
              if (userPrenom) {
                this.tooltipsText += ' - ' + userPrenom;
              }

              this.tooltipsText = Responsible.getWording(this.metaUser, this.user, this.calcTooltipFromUser(user))[
                'text'
              ];
              this.initialsText = Responsible.getWording(this.metaUser, this.user, this.calcTooltipFromUser(user))[
                'initials'
              ];

              if (user?.idUser) {
                const userPrenom = this.calcTooltipFromUser(user);

                if (userPrenom) {
                  if (this.tooltipsText?.length) {
                    this.tooltipsText += ' - ' + userPrenom;
                  } else {
                    this.tooltipsText = userPrenom;
                  }
                }
                this.userDetailModal = user;
                this.initialsText = this.calcInitialFromUser(user);
              }
            } else if (this.metaUser.communityUserProfil || this.metaUser.idCommunityUserProfil) {
              this.tooltipsText = Responsible.getWording(this.metaUser, this.user, null)['text'];
              this.initialsText = Responsible.getWording(this.metaUser, this.user, null)['initials'];
            }
          }
        } else if (
          this.metaUser.idCommunityUser ||
          this.metaUser.idOrganizationUser ||
          this.metaUser.idMeetingUser ||
          this.metaUser.idElementLibrary ||
          this.metaUser.user
        ) {
          this.tooltipsText = this.calcTooltipFromUser(this.metaUser.user);

          this.initialsText = this.calcInitialFromUser(this.metaUser.user);
          this.userDetailModal = this.metaUser.user;
        }
      } else if (this.organization) {
        if (this.organization.raisonSociale) {
          this.initialsText = this.organization.raisonSociale[0];
          if (this.organization.raisonSociale.length > 1) {
            this.initialsText += (this.organization.raisonSociale[1] || '').toLowerCase();
          }
        }
        if (this.tooltip) {
          this.tooltipsText = this.organization.raisonSociale;
        }
        this.calcAvatarStyle();
      } else {
        this.initialsText = '';
        this.tooltipsText = 'Non attribué';
        this.color = '#FFFFFF00';
      }
    }
  }

  private calcTooltipFromUser(user: User): string {
    let tooltipsText = '';
    if (user && this.notSmartphoneNotOnlyTabletTooltip) {
      if (this.customTooltips) {
        tooltipsText = this.customTooltips;
      } else if (user.prenom) {
        tooltipsText = user.prenom + ' ' + capitalize(user.nom);
      } else if (user.nom) {
        tooltipsText = capitalize(user.nom);
      } else if (user.email) {
        tooltipsText = capitalize(user.email);
      } else {
        // Ne pas afficher le tooltip
      }
    }
    return tooltipsText;
  }

  private calcInitialFromUser(user: User): string {
    let initials = '';
    if (user && user.pseudo) {
      initials = user.pseudo;
    } else if (user && user.prenom && user.prenom.length && user.nom && user.nom.length) {
      initials = user.prenom[0] + user.nom[0];
    } else if (user && user.nom && user.nom.length) {
      initials = user.nom[0];
      if (user.nom.length > 1) {
        initials += (user.nom[1] || '').toLowerCase();
      }
    } else if (user && user.email && user.email.length) {
      initials += (user.email[0] || '').toLowerCase() + (user.email[1] || '').toLowerCase();
    } else {
      initials = '?';
    }
    return initials;
  }

  private calcAvatarStyle() {
    let hashText = '';
    if (this.user) {
      hashText = this.user.email || this.user.nom;
    } else if (this.metaUser) {
      const user = this.metaUser.user || this.metaUser.communityUserProfil?.user;
      if (user && user.email) {
        hashText = user.email;
      } else if (this.metaUser.nom) {
        hashText = this.metaUser.nom;
      } else {
        hashText = Responsible.getWording(this.metaUser, this.user)['text'];
      }
    } else if (this.organization) {
      hashText = this.organization.raisonSociale;
    }

    const hash: string = Responsible.getColorFromString(hashText);
    this.color = this.user?.couleur
      ? this.user?.couleur
      : this.metaUser?.user?.couleur
        ? this.metaUser?.user?.couleur
        : hash;
    this.backgroundHeight = this.height + 'px';
    this.backgroundWidth = this.width ? this.width + 'px' : this.height + 'px';
    this.fontHeight = this.height * 0.5 + 'px';
  }

  openUserDetail($event: MouseEvent) {
    if (this.userDetailModal && this.shouldDisplayInfo) {
      this.dialog.open(UserDetailDialogComponent, { minWidth: '315px', data: { user: this.userDetailModal } });
      $event.stopPropagation();
    }
  }
}

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, MatTooltipModule],
  exports: [AvatarComponent]
})
export class AvatarComponentModule {}
