import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'wip-milestone-renderer',
  templateUrl: './milestone-renderer.component.html',
  styleUrls: ['./milestone-renderer.component.scss']
})
export class MilestoneRendererComponent implements ICellRendererAngularComp {
  public params;

  constructor() {}

  agInit(params) {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
