import { ElementLibraryState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { elementLibraryReducersGeneratedFunctions } from './element-library-generated.reducer';

const elementLibraryReducersFunctions = [...elementLibraryReducersGeneratedFunctions];

const elementLibraryReducer = createReducer(ElementLibraryState.initialState, ...elementLibraryReducersFunctions);

export function reducer(state: ElementLibraryState.IState | undefined, action: Action) {
  return elementLibraryReducer(state, action);
}
