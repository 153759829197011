import Responsible from '@_app/utils/responsible.utils';

interface UserDisplay {
  prenom: string;
  nom: string;
  roleLibelle: string;
}

function getUserDisplay(taskResponsible): UserDisplay {
  const userDisplay: UserDisplay = {
    prenom: '',
    nom: '',
    roleLibelle: ''
  };

  if (taskResponsible.user) {
    userDisplay.nom = taskResponsible.user.nom;
    userDisplay.prenom = taskResponsible.user.prenom;
  } else if (taskResponsible.communityUserProfil?.user) {
    userDisplay.nom = taskResponsible.communityUserProfil.user.nom;
    userDisplay.prenom = taskResponsible.communityUserProfil.user.prenom;
  }
  if (taskResponsible.libelle?.length) {
    userDisplay.roleLibelle = taskResponsible.libelle;
  } else if (taskResponsible.communityUserProfil?.organizationUserProfil?.libelle?.length) {
    userDisplay.roleLibelle = taskResponsible.communityUserProfil.organizationUserProfil.libelle;
  } else if (taskResponsible.communityUserProfil?.libelle?.length) {
    userDisplay.roleLibelle = taskResponsible.communityUserProfil.libelle;
  }

  return userDisplay;
}

function calcTooltipText(userDisplay: UserDisplay) {
  let tooltipText = '';

  if (userDisplay.roleLibelle?.length && userDisplay.nom?.length) {
    // It's a role with a user

    tooltipText = userDisplay.roleLibelle + ' - ' + userDisplay.prenom + ' ' + userDisplay.nom;
  } else {
    if (userDisplay.nom) {
      // It's a user
      tooltipText = userDisplay.prenom + ' ' + userDisplay.nom;
    } else {
      // It's a role alone
      tooltipText = userDisplay.roleLibelle;
    }
  }
  return tooltipText;
}

export function getResponsiblesInnerHtml(task) {
  let color = '';
  let html = '';
  let initialsText = '';
  let tooltipText = '';

  if (task.responsibles?.length) {
    task.responsibles.forEach(taskResponsible => {
      const userDisplay = getUserDisplay(taskResponsible);

      const userFound = taskResponsible.user || taskResponsible.communityUserProfil?.user;

      if (userFound) {
        // Calculer l'image par rapport aux initiales
        color = userFound.couleur ? userFound.couleur : Responsible.getColorFromString(userFound.email);
      } else if (taskResponsible.type === 'user_profile') {
        // Calculer l'image par rapport au rôle

        const profileLibelle = userDisplay.roleLibelle;

        // initialsText = calcInitialsFromRole(profileLibelle);
        color = Responsible.getColorFromString(profileLibelle);
      }

      const prenom = userFound?.prenom?.length ? userFound.prenom : userDisplay.prenom;
      const nom = userFound?.nom?.length ? userFound.nom : userDisplay.nom;
      const roleLibelle = userFound?.roleLibelle?.length ? userFound.roleLibelle : userDisplay.roleLibelle;
      initialsText = Responsible.getWording(userFound, userFound, prenom, nom, roleLibelle)['initials'];
      if (taskResponsible.user?.nom || taskResponsible.type === 'user_profile') {
        tooltipText = calcTooltipText(userDisplay);

        html += `
          <div class="tooltip responsible-style" style="background-color:${color}; display: inline-block;">
            ${initialsText}
            <span class="tooltip-text tooltip-responsible-text-left">${tooltipText}</span>
          </div>`;
      }
    });
  }
  return html;
}
