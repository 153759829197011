import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationDataColumnValue, OrganizationDataColumnValueEntityState } from '@api/api-interfaces';
import { OrganizationDataColumnValueRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationDataColumnValueGeneratedActions = createActionGroup({
  source: 'Organization Data Column Value Generated',
  events: {
    'Get One Organization Data Column Value': props<{ idOrganizationDataColumnValue: number; params?: any }>(),
    'Get Many Organization Data Column Values': props<{ params: any }>(),
    'Add Many Actives Organization Data Column Values': props<{ idOrganizationDataColumnValues: number[] }>(),
    'Delete One Active Organization Data Column Value': props<{ idOrganizationDataColumnValue: number }>(),
    'Clear Actives Organization Data Column Values': emptyProps(),
    'Upsert One Organization Data Column Value': props<{
      organizationDataColumnValue: Partial<OrganizationDataColumnValue>;
      ids?: OrganizationDataColumnValueRelationsIds;
    }>(),
    'Upsert Many Organization Data Column Values': props<{
      organizationDataColumnValues: Partial<OrganizationDataColumnValue>[];
      ids?: OrganizationDataColumnValueRelationsIds;
    }>(),
    'Upsert Many Organization Data Column Values Success': props<{
      organizationDataColumnValues: OrganizationDataColumnValueEntityState[];
    }>(),
    'Delete One Organization Data Column Value': props<{ idOrganizationDataColumnValue: number }>(),
    'Delete One Organization Data Column Value Success': props<{ idOrganizationDataColumnValue: number }>(),
    'Normalize Many Organization Data Column Values After Upsert': props<{
      organizationDataColumnValues: OrganizationDataColumnValueEntityState[];
    }>(),
    'Organization Data Column Values Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Data Column Values': emptyProps(),
    'Add Many Valeur Success': props<{ idOrganizationDataColumnValue: number; idValeurs: number[] }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idOrganizationDataColumnValues: number[] }>(),
    'Add Many Organization Caracteristic Value Success': props<{
      idOrganizationDataColumnValue: number;
      idOrganizationCaracteristicValues: number[];
    }>(),
    'Delete Many Organization Caracteristic Value Success': props<{
      idOrganizationCaracteristicValues: number[];
      idOrganizationDataColumnValues: number[];
    }>(),
    'Add Organization Data Column Success': props<{
      idOrganizationDataColumnValue: number;
      idOrganizationDataColumn: number;
    }>(),
    'Delete Many Organization Data Column Success': props<{
      idOrganizationDataColumns: number[];
      idOrganizationDataColumnValues: number[];
    }>()
  }
});
