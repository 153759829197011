import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { TimelineElementGeneratedActions } from '@wip/store/actions';
import { TimelineElementState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { TimelineElementEntityState } from '@api/api-interfaces';

export const timelineElementReducersGeneratedFunctions: ReducerTypes<
  TimelineElementState.IState,
  readonly ActionCreator[]
>[] = [
  on(TimelineElementGeneratedActions.getOneTimelineElement, (state: TimelineElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(TimelineElementGeneratedActions.getManyTimelineElements, (state: TimelineElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(TimelineElementGeneratedActions.upsertOneTimelineElement, (state: TimelineElementState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    TimelineElementGeneratedActions.upsertManyTimelineElementsSuccess,
    (state: TimelineElementState.IState, { timelineElements }) =>
      TimelineElementState.adapter.upsertMany(timelineElements, setLoadingsState(state, false))
  ),
  on(TimelineElementGeneratedActions.deleteOneTimelineElement, (state: TimelineElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    TimelineElementGeneratedActions.deleteOneTimelineElementSuccess,
    (state: TimelineElementState.IState, { idTimelineElement }) =>
      TimelineElementState.adapter.removeOne(idTimelineElement, setLoadingsState(state, false))
  ),
  on(TimelineElementGeneratedActions.clearActivesTimelineElements, (state: TimelineElementState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    TimelineElementGeneratedActions.addManyActivesTimelineElements,
    (state: TimelineElementState.IState, { idTimelineElements }) => ({
      ...state,
      actives: state.actives.concat(idTimelineElements)
    })
  ),
  on(
    TimelineElementGeneratedActions.deleteOneActiveTimelineElement,
    (state: TimelineElementState.IState, { idTimelineElement }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idTimelineElement)
    })
  ),

  on(TimelineElementGeneratedActions.clearTimelineElements, () => TimelineElementState.initialState),

  on(
    TimelineElementGeneratedActions.addCommunitySuccess,
    (state: TimelineElementState.IState, { idTimelineElement, idCommunity }) => {
      if (!state.entities[idTimelineElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idTimelineElement]: {
            ...state.entities[idTimelineElement],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    TimelineElementGeneratedActions.deleteManyCommunitySuccess,
    (state: TimelineElementState.IState, { idCommunities, idTimelineElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idTimelineElements.reduce((entities, idTimelineElement) => {
            if (!state.entities[idTimelineElement]?.community) {
              return entities;
            }
            entities[idTimelineElement] = {
              ...state.entities[idTimelineElement],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idTimelineElement]?.community
              )
                ? undefined
                : state.entities[idTimelineElement]?.community
            } as TimelineElementEntityState;
            return entities;
          }, {} as Dictionary<TimelineElementEntityState>)
        }
      };
    }
  ),

  on(
    TimelineElementGeneratedActions.addElementSuccess,
    (state: TimelineElementState.IState, { idTimelineElement, idElement }) => {
      if (!state.entities[idTimelineElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idTimelineElement]: {
            ...state.entities[idTimelineElement],
            element: idElement
          }
        }
      };
    }
  ),

  on(
    TimelineElementGeneratedActions.deleteManyElementSuccess,
    (state: TimelineElementState.IState, { idElements, idTimelineElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idTimelineElements.reduce((entities, idTimelineElement) => {
            if (!state.entities[idTimelineElement]?.element) {
              return entities;
            }
            entities[idTimelineElement] = {
              ...state.entities[idTimelineElement],
              element: idElements.some((idElement: number) => idElement === state.entities[idTimelineElement]?.element)
                ? undefined
                : state.entities[idTimelineElement]?.element
            } as TimelineElementEntityState;
            return entities;
          }, {} as Dictionary<TimelineElementEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: TimelineElementState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): TimelineElementState.IState {
  return { ...state, isLoaded, isLoading };
}
