import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneFamilyRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationMilestoneFamilyGeneratedActions = createActionGroup({
  source: 'Organization Milestone Family Generated',
  events: {
    'Get One Organization Milestone Family': props<{ idOrganizationMilestoneFamily: number; params?: any }>(),
    'Get Many Organization Milestone Familys': props<{ params: any }>(),
    'Add Many Actives Organization Milestone Familys': props<{ idOrganizationMilestoneFamilys: number[] }>(),
    'Delete One Active Organization Milestone Family': props<{ idOrganizationMilestoneFamily: number }>(),
    'Clear Actives Organization Milestone Familys': emptyProps(),
    'Upsert One Organization Milestone Family': props<{
      organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>;
      ids?: OrganizationMilestoneFamilyRelationsIds;
    }>(),
    'Upsert Many Organization Milestone Familys': props<{
      organizationMilestoneFamilys: Partial<OrganizationMilestoneFamily>[];
      ids?: OrganizationMilestoneFamilyRelationsIds;
    }>(),
    'Upsert Many Organization Milestone Familys Success': props<{
      organizationMilestoneFamilys: OrganizationMilestoneFamilyEntityState[];
    }>(),
    'Delete One Organization Milestone Family': props<{ idOrganizationMilestoneFamily: number }>(),
    'Delete One Organization Milestone Family Success': props<{ idOrganizationMilestoneFamily: number }>(),
    'Normalize Many Organization Milestone Familys After Upsert': props<{
      organizationMilestoneFamilys: OrganizationMilestoneFamilyEntityState[];
    }>(),
    'Organization Milestone Familys Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Milestone Familys': emptyProps(),
    'Add Many Community Milestone Family Success': props<{
      idOrganizationMilestoneFamily: number;
      idCommunityMilestoneFamilys: number[];
    }>(),
    'Delete Many Community Milestone Family Success': props<{
      idCommunityMilestoneFamilys: number[];
      idOrganizationMilestoneFamilys: number[];
    }>(),
    'Add Many Organization Milestone Association Success': props<{
      idOrganizationMilestoneFamily: number;
      idOrganizationMilestoneAssociations: number[];
    }>(),
    'Delete Many Organization Milestone Association Success': props<{
      idOrganizationMilestoneAssociations: number[];
      idOrganizationMilestoneFamilys: number[];
    }>(),
    'Add Many Organization Milestone Success': props<{
      idOrganizationMilestoneFamily: number;
      idOrganizationMilestones: number[];
    }>(),
    'Delete Many Organization Milestone Success': props<{
      idOrganizationMilestones: number[];
      idOrganizationMilestoneFamilys: number[];
    }>(),
    'Add Organization Success': props<{ idOrganizationMilestoneFamily: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{
      idOrganizations: number[];
      idOrganizationMilestoneFamilys: number[];
    }>(),
    'Add Organization Family Success': props<{ idOrganizationMilestoneFamily: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{
      idOrganizationFamilys: number[];
      idOrganizationMilestoneFamilys: number[];
    }>(),
    'Add Organization Caracteristic Success': props<{
      idOrganizationMilestoneFamily: number;
      idOrganizationCaracteristic: number;
    }>(),
    'Delete Many Organization Caracteristic Success': props<{
      idOrganizationCaracteristics: number[];
      idOrganizationMilestoneFamilys: number[];
    }>()
  }
});
