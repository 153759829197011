import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationCaracteristicColumn, OrganizationCaracteristicColumnEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicColumnApiService } from '@wip/store/api-services';
import { OrganizationCaracteristicColumnGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationCaracteristicColumn } from '@wip/store/configs/normalization';
import { OrganizationCaracteristicColumnSelectors } from '@wip/store/selectors';
import { OrganizationCaracteristicColumnRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicValueGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicValue } from '@api/api-interfaces';

export function getDefaultAddOrganizationCaracteristicColumnActions(
  organizationCaracteristicColumn: OrganizationCaracteristicColumnEntityState,
  ids?: OrganizationCaracteristicColumnRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationCaracteristicColumnGeneratedActions.normalizeManyOrganizationCaracteristicColumnsAfterUpsert({
      organizationCaracteristicColumns: [organizationCaracteristicColumn]
    })
  ];

  if (ids?.organizationCaracteristic) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.addManyOrganizationCaracteristicColumnSuccess({
        idOrganizationCaracteristic: ids.organizationCaracteristic,
        idOrganizationCaracteristicColumns: [organizationCaracteristicColumn.idOrganizationCaracteristicColumn]
      })
    );
    actions.push(
      OrganizationCaracteristicColumnGeneratedActions.addOrganizationCaracteristicSuccess({
        idOrganizationCaracteristicColumn: organizationCaracteristicColumn.idOrganizationCaracteristicColumn,
        idOrganizationCaracteristic: ids.organizationCaracteristic
      })
    );
  }

  if (ids?.organizationCaracteristicValues) {
    if (!Array.isArray(ids.organizationCaracteristicValues)) {
      actions.push(
        OrganizationCaracteristicValueGeneratedActions.upsertOneOrganizationCaracteristicValue({
          organizationCaracteristicValue: {
            idOrganizationCaracteristicColumn: organizationCaracteristicColumn.idOrganizationCaracteristicColumn,
            idOrganizationCaracteristicValue: ids.organizationCaracteristicValues as number
          } as OrganizationCaracteristicValue
        })
      );
      actions.push(
        OrganizationCaracteristicColumnGeneratedActions.addManyOrganizationCaracteristicValueSuccess({
          idOrganizationCaracteristicColumn: organizationCaracteristicColumn.idOrganizationCaracteristicColumn,
          idOrganizationCaracteristicValues: [ids.organizationCaracteristicValues as number]
        })
      );
    } else {
      actions.push(
        OrganizationCaracteristicValueGeneratedActions.upsertManyOrganizationCaracteristicValues({
          organizationCaracteristicValues: (ids.organizationCaracteristicValues as number[]).map(
            (idOrganizationCaracteristicValue: number) => ({
              idOrganizationCaracteristicColumn: organizationCaracteristicColumn.idOrganizationCaracteristicColumn,
              idOrganizationCaracteristicValue
            })
          ) as OrganizationCaracteristicValue[]
        })
      );
      actions.push(
        OrganizationCaracteristicColumnGeneratedActions.addManyOrganizationCaracteristicValueSuccess({
          idOrganizationCaracteristicColumn: organizationCaracteristicColumn.idOrganizationCaracteristicColumn,
          idOrganizationCaracteristicValues: ids.organizationCaracteristicValues as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationCaracteristicColumnActions(
  organizationCaracteristicColumn: OrganizationCaracteristicColumnEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationCaracteristicColumnGeneratedActions.deleteOneOrganizationCaracteristicColumnSuccess({
      idOrganizationCaracteristicColumn: organizationCaracteristicColumn.idOrganizationCaracteristicColumn
    })
  ];

  if (organizationCaracteristicColumn.organizationCaracteristic) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.deleteManyOrganizationCaracteristicColumnSuccess({
        idOrganizationCaracteristicColumns: [organizationCaracteristicColumn.idOrganizationCaracteristicColumn],
        idOrganizationCaracteristics: [organizationCaracteristicColumn.organizationCaracteristic as number]
      })
    );
  }

  if (organizationCaracteristicColumn.organizationCaracteristicValues) {
    actions.push(
      OrganizationCaracteristicValueGeneratedActions.deleteManyOrganizationCaracteristicColumnSuccess({
        idOrganizationCaracteristicColumns: [organizationCaracteristicColumn.idOrganizationCaracteristicColumn],
        idOrganizationCaracteristicValues: organizationCaracteristicColumn.organizationCaracteristicValues as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationCaracteristicColumnEffects {
  constructor(
    protected actions$: Actions,
    protected organizationCaracteristicColumnApiService: OrganizationCaracteristicColumnApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationCaracteristicColumns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicColumnGeneratedActions.getManyOrganizationCaracteristicColumns),
      switchMap(({ params }) =>
        this.organizationCaracteristicColumnApiService.getOrganizationCaracteristicColumns(params).pipe(
          map((organizationCaracteristicColumns: OrganizationCaracteristicColumn[]) => {
            return OrganizationCaracteristicColumnGeneratedActions.normalizeManyOrganizationCaracteristicColumnsAfterUpsert(
              { organizationCaracteristicColumns }
            );
          }),
          catchError(error =>
            of(OrganizationCaracteristicColumnGeneratedActions.organizationCaracteristicColumnsFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationCaracteristicColumn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicColumnGeneratedActions.getOneOrganizationCaracteristicColumn),
      switchMap(idOrganizationCaracteristicColumn =>
        this.organizationCaracteristicColumnApiService
          .getOrganizationCaracteristicColumn(idOrganizationCaracteristicColumn)
          .pipe(
            map((organizationCaracteristicColumn: OrganizationCaracteristicColumn) => {
              return OrganizationCaracteristicColumnGeneratedActions.normalizeManyOrganizationCaracteristicColumnsAfterUpsert(
                { organizationCaracteristicColumns: [organizationCaracteristicColumn] }
              );
            }),
            catchError(error =>
              of(OrganizationCaracteristicColumnGeneratedActions.organizationCaracteristicColumnsFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationCaracteristicColumn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicColumnGeneratedActions.upsertOneOrganizationCaracteristicColumn),
      concatMap(
        ({
          organizationCaracteristicColumn,
          ids
        }: {
          organizationCaracteristicColumn: Partial<OrganizationCaracteristicColumn>;
          ids?: OrganizationCaracteristicColumnRelationsIds;
        }) => {
          if (organizationCaracteristicColumn.idOrganizationCaracteristicColumn) {
            return this.organizationCaracteristicColumnApiService
              .updateOrganizationCaracteristicColumn(organizationCaracteristicColumn)
              .pipe(
                map((organizationCaracteristicColumnReturned: OrganizationCaracteristicColumn) => {
                  return OrganizationCaracteristicColumnGeneratedActions.normalizeManyOrganizationCaracteristicColumnsAfterUpsert(
                    { organizationCaracteristicColumns: [organizationCaracteristicColumnReturned] }
                  );
                }),
                catchError(error =>
                  of(OrganizationCaracteristicColumnGeneratedActions.organizationCaracteristicColumnsFailure({ error }))
                )
              );
          } else {
            return this.organizationCaracteristicColumnApiService
              .addOrganizationCaracteristicColumn(organizationCaracteristicColumn)
              .pipe(
                mergeMap((organizationCaracteristicColumnReturned: OrganizationCaracteristicColumn) =>
                  getDefaultAddOrganizationCaracteristicColumnActions(organizationCaracteristicColumnReturned, ids)
                ),
                catchError(error =>
                  of(OrganizationCaracteristicColumnGeneratedActions.organizationCaracteristicColumnsFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationCaracteristicColumn$ = createEffect(() => {
    const selectOrganizationCaracteristicColumnState$ = this.store$.select(
      OrganizationCaracteristicColumnSelectors.selectOrganizationCaracteristicColumnState
    );
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicColumnGeneratedActions.deleteOneOrganizationCaracteristicColumn),
      withLatestFrom(selectOrganizationCaracteristicColumnState$),
      concatMap(([{ idOrganizationCaracteristicColumn }, state]) =>
        this.organizationCaracteristicColumnApiService
          .deleteOrganizationCaracteristicColumn(idOrganizationCaracteristicColumn)
          .pipe(
            mergeMap(_success =>
              getDefaultDeleteOrganizationCaracteristicColumnActions(
                state.entities[idOrganizationCaracteristicColumn] as OrganizationCaracteristicColumnEntityState
              )
            ),
            catchError(error =>
              of(OrganizationCaracteristicColumnGeneratedActions.organizationCaracteristicColumnsFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationCaracteristicColumnsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicColumnGeneratedActions.normalizeManyOrganizationCaracteristicColumnsAfterUpsert),
      concatMap(({ organizationCaracteristicColumns }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationCaracteristicColumn(
          organizationCaracteristicColumns,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationCaracteristicColumn] Normalization After Upsert Success')];
      })
    );
  });
}
