import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Valeur } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { ValeurRelationsIds } from '@wip/store/ids-interfaces';
import { ValeurGeneratedActions } from '@wip/store/actions';
import { ValeurSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedValeurService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ValeurSelectors.selectIsLoadedValeur));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ValeurSelectors.selectIsLoadingValeur));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [ValeurSelectors.selectIsReadyAndLoadedValeur as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllValeurs(schema: SelectSchema = {}): Observable<Valeur[]> {
    return this.store$.pipe(select(ValeurSelectors.selectAllValeurs(schema))).pipe(
      switchMap(({ valeurs }: { valeurs: Valeur[] }) => {
        return this.getReady(schema).pipe(mapTo(valeurs));
      })
    );
  }

  public selectOneValeur(idValeur: number, schema: SelectSchema = {}): Observable<Valeur> {
    return this.store$.pipe(select(ValeurSelectors.selectOneValeur(schema, idValeur))).pipe(
      switchMap(({ valeur }: { valeur: Valeur }) => {
        return this.getReady(schema).pipe(mapTo(valeur));
      })
    );
  }

  public selectAllActiveValeurs(schema: SelectSchema = {}): Observable<Valeur[]> {
    return this.store$.pipe(select(ValeurSelectors.selectActiveValeurs(schema))).pipe(
      switchMap(({ valeurs }: { valeurs: Valeur[] }) => {
        return this.getReady(schema).pipe(mapTo(valeurs));
      })
    );
  }

  public selectIdValeursActive(): Observable<number[]> {
    return this.store$.pipe(select(ValeurSelectors.selectIdValeursActive)).pipe(
      switchMap((idValeurs: number[]) => {
        return this.getReady().pipe(mapTo(idValeurs));
      })
    );
  }

  public getOneValeur(idValeur: number, params: any = {}, getResult?: boolean): void | Observable<Valeur> {
    this.store$.dispatch(ValeurGeneratedActions.getOneValeur({ idValeur, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ValeurGeneratedActions.normalizeManyValeursAfterUpsert,
        ValeurGeneratedActions.valeursFailure,
        true
      );
    }
  }

  public getManyValeurs(params: any = {}, getResult?: boolean): void | Observable<Valeur[]> {
    this.store$.dispatch(ValeurGeneratedActions.getManyValeurs({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ValeurGeneratedActions.normalizeManyValeursAfterUpsert,
        ValeurGeneratedActions.valeursFailure
      );
    }
  }

  public upsertOneValeur(
    valeur: Partial<Valeur>,
    ids: ValeurRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Valeur> {
    this.store$.dispatch(ValeurGeneratedActions.upsertOneValeur({ valeur, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ValeurGeneratedActions.normalizeManyValeursAfterUpsert,
        ValeurGeneratedActions.valeursFailure,
        true
      );
    }
  }

  public deleteOneValeur(idValeur: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ValeurGeneratedActions.deleteOneValeur({ idValeur }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ValeurGeneratedActions.deleteOneValeurSuccess,
        ValeurGeneratedActions.valeursFailure
      );
    }
  }

  public setActiveValeurs(idValeurs: number[]): void {
    this.store$.dispatch(ValeurGeneratedActions.clearActivesValeurs());
    this.store$.dispatch(ValeurGeneratedActions.addManyActivesValeurs({ idValeurs }));
  }
}
