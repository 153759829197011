@if (formGroup) {
  <div class="flex flex-col">
    <span style="font-weight: bold">Catégories: </span>
    <app-project-sub-families-choices
      [filtersGroup]="formGroup"
      [subFamilyControl]="formGroup.controls.subFamilyControl"
      [organizationSubFamilies]="subFamilies"
    >
    </app-project-sub-families-choices>
    <div class="ag-grid-container">
      <ag-grid-angular
        #agGrid
        class="ag-theme-quartz"
        [ngStyle]="{ height: !fromModal ? 'calc(100vh - 125px)' : '400px' }"
        [gridOptions]="gridOptions"
        [modules]="modules"
        (gridReady)="onGridReady($event)"
        (selectionChanged)="onSelectionChanged()"
        (firstDataRendered)="onFirstDataRendered($event)"
      >
      </ag-grid-angular>
    </div>
  </div>
}
