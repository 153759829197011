import { Color } from '@_app/shared/constants/colorConstant';
import { User } from '@api/api-interfaces';

/**
 * Utilitaire dédié au visuel (texte, css, ...)
 */
namespace Responsible {
  /**
   * Fonction retournant une couleur à partir d'une chaine de caractère
   * @param str
   * @returns
   */
  export function getColorFromString(str: string): string {
    str = str || '';
    let hash = 0;
    let chr;
    const len = str.length;
    if (str.length !== 0) {
      for (let i = 0; i < len; i++) {
        chr = str.charCodeAt(i);
        hash = hash * 31 + chr + chr;
        hash = hash || 0; // Convert to 32bit integer
      }
      hash = Math.abs(hash);
    }

    hash = hash % Color.colors.length;
    return Color.colors[hash];
  }

  /**
   * Fonction retournant le wording d'un responsable
   * @param user
   * @param metaUser
   * @returns
   */
  export const getWording = (metaUser: any, user?: User, prenom?: string, nom?: string, roleLibelle?: string) => {
    let tooltipsText = '';
    if (metaUser?.communityUserProfil?.organizationUserProfil?.idOrganizationUserProfil) {
      tooltipsText = metaUser.communityUserProfil.organizationUserProfil.libelle;
    } else if (metaUser?.organizationUserProfil?.idOrganizationUserProfil) {
      tooltipsText = metaUser.organizationUserProfil.libelle;
    } else if (metaUser?.communityUserProfil?.libelle?.length) {
      tooltipsText = metaUser.communityUserProfil.libelle;
    } else if (metaUser?.libelle) {
      tooltipsText = metaUser.libelle;
    }

    const initialsTextFromName = (libelle: string): string => {
      return libelle.substring(0, 3);
    };
    let initialsText = initialsTextFromName(tooltipsText || roleLibelle || '');
    if (user?.pseudo) {
      initialsText = user?.pseudo;
    } else if (prenom?.length && nom?.length) {
      initialsText = prenom[0] + nom[0];
    } else if (nom?.length) {
      initialsText = nom[0];
    } else if (!initialsText?.length) {
      initialsText = '?';
    }

    if (!tooltipsText?.length && !prenom?.length && !nom?.length) {
      tooltipsText = '?';
    }

    return { text: tooltipsText, initials: initialsText };
  };
}
export default Responsible;
