import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TimelineElement, TimelineElementEntityState } from '@api/api-interfaces';
import { TimelineElementRelationsIds } from '@wip/store/ids-interfaces';

export const TimelineElementGeneratedActions = createActionGroup({
  source: 'Timeline Element Generated',
  events: {
    'Get One Timeline Element': props<{ idTimelineElement: number; params?: any }>(),
    'Get Many Timeline Elements': props<{ params: any }>(),
    'Add Many Actives Timeline Elements': props<{ idTimelineElements: number[] }>(),
    'Delete One Active Timeline Element': props<{ idTimelineElement: number }>(),
    'Clear Actives Timeline Elements': emptyProps(),
    'Upsert One Timeline Element': props<{
      timelineElement: Partial<TimelineElement>;
      ids?: TimelineElementRelationsIds;
    }>(),
    'Upsert Many Timeline Elements': props<{
      timelineElements: Partial<TimelineElement>[];
      ids?: TimelineElementRelationsIds;
    }>(),
    'Upsert Many Timeline Elements Success': props<{ timelineElements: TimelineElementEntityState[] }>(),
    'Delete One Timeline Element': props<{ idTimelineElement: number }>(),
    'Delete One Timeline Element Success': props<{ idTimelineElement: number }>(),
    'Normalize Many Timeline Elements After Upsert': props<{ timelineElements: TimelineElementEntityState[] }>(),
    'Timeline Elements Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Timeline Elements': emptyProps(),
    'Add Community Success': props<{ idTimelineElement: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idTimelineElements: number[] }>(),
    'Add Element Success': props<{ idTimelineElement: number; idElement: number }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idTimelineElements: number[] }>()
  }
});
