import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Right, RightEntityState } from '@api/api-interfaces';
import { RightRelationsIds } from '@wip/store/ids-interfaces';

export const RightGeneratedActions = createActionGroup({
  source: 'Right Generated',
  events: {
    'Get One Right': props<{ idRight: number; params?: any }>(),
    'Get Many Rights': props<{ params: any }>(),
    'Add Many Actives Rights': props<{ idRights: number[] }>(),
    'Delete One Active Right': props<{ idRight: number }>(),
    'Clear Actives Rights': emptyProps(),
    'Upsert One Right': props<{ right: Partial<Right>; ids?: RightRelationsIds }>(),
    'Upsert Many Rights': props<{ rights: Partial<Right>[]; ids?: RightRelationsIds }>(),
    'Upsert Many Rights Success': props<{ rights: RightEntityState[] }>(),
    'Delete One Right': props<{ idRight: number }>(),
    'Delete One Right Success': props<{ idRight: number }>(),
    'Normalize Many Rights After Upsert': props<{ rights: RightEntityState[] }>(),
    'Rights Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Rights': emptyProps(),
    'Add Many Right Profil Success': props<{ idRight: number; idRightProfils: number[] }>(),
    'Delete Many Right Profil Success': props<{ idRightProfils: number[]; idRights: number[] }>(),
    'Add Many Profil Success': props<{ idRight: number; idProfils: number[] }>(),
    'Delete Many Profil Success': props<{ idProfils: number[]; idRights: number[] }>()
  }
});
