import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedElementService } from './element-generated.service';
import { Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import * as ElementAction from '@wip/store/actions';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Element, ReorderBasicInterface } from '@api/api-interfaces';
import { ActionFormDTO } from '@dto';
import { ElementApiService } from '@wip/store/api-services';
import { ElementStatusActionEnum, ElementTypeElement } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class ElementService extends GeneratedElementService {
  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private readonly elementApiService: ElementApiService
  ) {
    super(store$, actions$);
  }

  public getGridDashboardDatas(
    idOrganization: number,
    idOrganizationMilestoneFamily: number,
    getResult?: boolean
  ): Observable<Element[]> {
    this.store$.dispatch(ElementAction.getGridDashboardDatas({ idOrganization, idOrganizationMilestoneFamily }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementAction.ElementGeneratedActions.normalizeManyElementsAfterUpsert,
        ElementAction.ElementGeneratedActions.elementsFailure
      );
    }
  }

  public getPersonalElements(getResult?: boolean): Observable<Element[]> {
    this.store$.dispatch(ElementAction.getPersonalElements({ params: {} }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementAction.ElementGeneratedActions.normalizeManyElementsAfterUpsert,
        ElementAction.ElementGeneratedActions.elementsFailure
      );
    }
  }

  public validateActionForm(controls: ActionFormDTO) {
    this.store$.dispatch(ElementAction.validateActionFormElement(controls));
  }

  public updateElement(element: Partial<Element>) {
    if (element.dateInitial) {
      this.store$.dispatch(ElementAction.updateInitial({ element }));
    } else if (element.dateObjectif) {
      this.store$.dispatch(ElementAction.updateObjectif({ element }));
    }
  }

  public downloadFile(idElement: number, fileName: string, isChildrenElement: boolean = false) {
    return this.elementApiService.downloadFile(idElement, fileName, isChildrenElement);
  }

  public isLate(element) {
    if (!element) {
      return Math.round((new Date(element.echeance).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) < 0;
    }

    const echeance = new Date(element.echeance);
    const now = new Date();
    const heureEcheance = echeance.getHours() + echeance.getMinutes();
    // Si pas de minutes précisées alors verifications uniquement par la date du jour
    if (heureEcheance === 0) {
      now.setHours(0, 0, 0, 0);
    }
    return element.echeance && echeance < now;
  }

  public isOpenOrPauseOrProgress(element: Element) {
    return (
      element.statusAction === ElementStatusActionEnum.open ||
      element.statusAction === ElementStatusActionEnum.blocked ||
      element.statusAction === ElementStatusActionEnum.progress
    );
  }

  public isComment(element: Element) {
    return element.typeElement === ElementTypeElement.elementComment;
  }

  public isAction(element: Element) {
    return element.typeElement === ElementTypeElement.elementAction;
  }

  public isDocument(element: Element) {
    return element.typeElement === ElementTypeElement.elementDocument;
  }

  public isPoj(element: Element) {
    return element.typeElement === ElementTypeElement.elementPoj;
  }

  public reorder(params: ReorderBasicInterface): void {
    this.store$.dispatch(ElementAction.reorder({ params }));
  }
}
