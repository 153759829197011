import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationDataColumnValueApiService } from '@wip/store/api-services';
import { GeneratedOrganizationDataColumnValueEffects } from './organization-data-column-value-generated.effects';

@Injectable()
export class OrganizationDataColumnValueEffects extends GeneratedOrganizationDataColumnValueEffects {
  constructor(
    actions$: Actions,
    organizationDataColumnValueApiService: OrganizationDataColumnValueApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationDataColumnValueApiService, store$);
  }
}
