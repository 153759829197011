import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { sortByMultipleKey, sortNumber } from '@utils';
import { Community, Organization, User } from '@api/api-interfaces';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'wip-responsible-choice',
  templateUrl: './responsible-choice.component.html',
  styleUrls: ['./responsible-choice.component.scss']
})
export class ResponsibleChoiceComponent implements OnInit, OnChanges {
  @Input() filtersGroup: FormGroup;
  @Input() organization: Organization;
  @Input() communities: Community[];
  @Input() displayOnlyPilotToggle?: boolean = false;

  public users: User[];
  public filteredUsers: User[] = [];
  public filterValue = '';
  public selectControl = new FormControl();

  constructor() {}

  ngOnInit(): void {
    this.selectControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(300), // Délai de temporisation pour la saisie du clavier (300 ms dans cet exemple)
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.filterOptions(value);
      });
  }

  ngOnChanges(changes) {
    if (changes.communities && this.communities?.length) {
      this.users = this.communities
        .flatMap(comm => comm?.communityUserProfils.map(cup => cup.user))
        // remove undefined values and duplicated user
        .filter((v, i, a) => !!v && a.findIndex(v2 => v2?.idUser === v?.idUser) === i)
        .sort(sortByMultipleKey([{ name: 'nom', cb: sortNumber }, { name: 'nom' }]));

      this.filteredUsers = this.users;
    }
  }

  filterOptions(value: string) {
    this.filteredUsers = this.users.filter(
      user =>
        user.nom?.toLowerCase().includes(value.toLowerCase()) ||
        user.prenom?.toLowerCase().includes(value.toLowerCase())
    );
  }
}
