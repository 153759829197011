import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityModule, CommunityModuleEntityState } from '@api/api-interfaces';
import { CommunityModuleRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityModuleGeneratedActions = createActionGroup({
  source: 'Community Module Generated',
  events: {
    'Get One Community Module': props<{ idCommunityModule: number; params?: any }>(),
    'Get Many Community Modules': props<{ params: any }>(),
    'Add Many Actives Community Modules': props<{ idCommunityModules: number[] }>(),
    'Delete One Active Community Module': props<{ idCommunityModule: number }>(),
    'Clear Actives Community Modules': emptyProps(),
    'Upsert One Community Module': props<{
      communityModule: Partial<CommunityModule>;
      ids?: CommunityModuleRelationsIds;
    }>(),
    'Upsert Many Community Modules': props<{
      communityModules: Partial<CommunityModule>[];
      ids?: CommunityModuleRelationsIds;
    }>(),
    'Upsert Many Community Modules Success': props<{ communityModules: CommunityModuleEntityState[] }>(),
    'Delete One Community Module': props<{ idCommunityModule: number }>(),
    'Delete One Community Module Success': props<{ idCommunityModule: number }>(),
    'Normalize Many Community Modules After Upsert': props<{ communityModules: CommunityModuleEntityState[] }>(),
    'Community Modules Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Modules': emptyProps(),
    'Add Community Success': props<{ idCommunityModule: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityModules: number[] }>(),
    'Add Project Module Success': props<{ idCommunityModule: number; idProjectModule: number }>(),
    'Delete Many Project Module Success': props<{ idProjectModules: number[]; idCommunityModules: number[] }>()
  }
});
