import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityDataRowGeneratedActions } from '@wip/store/actions';
import { CommunityDataRowState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityDataRowEntityState } from '@api/api-interfaces';

export const communityDataRowReducersGeneratedFunctions: ReducerTypes<
  CommunityDataRowState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunityDataRowGeneratedActions.getOneCommunityDataRow, (state: CommunityDataRowState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityDataRowGeneratedActions.getManyCommunityDataRows, (state: CommunityDataRowState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityDataRowGeneratedActions.upsertOneCommunityDataRow, (state: CommunityDataRowState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    CommunityDataRowGeneratedActions.upsertManyCommunityDataRowsSuccess,
    (state: CommunityDataRowState.IState, { communityDataRows }) =>
      CommunityDataRowState.adapter.upsertMany(communityDataRows, setLoadingsState(state, false))
  ),
  on(CommunityDataRowGeneratedActions.deleteOneCommunityDataRow, (state: CommunityDataRowState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    CommunityDataRowGeneratedActions.deleteOneCommunityDataRowSuccess,
    (state: CommunityDataRowState.IState, { idCommunityDataRow }) =>
      CommunityDataRowState.adapter.removeOne(idCommunityDataRow, setLoadingsState(state, false))
  ),
  on(CommunityDataRowGeneratedActions.clearActivesCommunityDataRows, (state: CommunityDataRowState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    CommunityDataRowGeneratedActions.addManyActivesCommunityDataRows,
    (state: CommunityDataRowState.IState, { idCommunityDataRows }) => ({
      ...state,
      actives: state.actives.concat(idCommunityDataRows)
    })
  ),
  on(
    CommunityDataRowGeneratedActions.deleteOneActiveCommunityDataRow,
    (state: CommunityDataRowState.IState, { idCommunityDataRow }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityDataRow)
    })
  ),

  on(CommunityDataRowGeneratedActions.clearCommunityDataRows, () => CommunityDataRowState.initialState),

  on(
    CommunityDataRowGeneratedActions.addManyValeurSuccess,
    (state: CommunityDataRowState.IState, { idCommunityDataRow, idValeurs }) => {
      if (!state.entities[idCommunityDataRow]) {
        return state;
      }
      const valeurs = (state.entities[idCommunityDataRow]?.valeurs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityDataRow]: {
            ...state.entities[idCommunityDataRow],
            valeurs: valeurs.concat(idValeurs.filter(id => valeurs.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityDataRowGeneratedActions.deleteManyValeurSuccess,
    (state: CommunityDataRowState.IState, { idValeurs, idCommunityDataRows }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityDataRows.reduce((entities, idCommunityDataRow) => {
            if (!state.entities[idCommunityDataRow]?.valeurs) {
              return entities;
            }
            entities[idCommunityDataRow] = {
              ...state.entities[idCommunityDataRow],
              valeurs: (state.entities[idCommunityDataRow]?.valeurs as number[])?.filter(
                (idValeur: number) => !idValeurs.some((id: number) => id === idValeur)
              )
            } as CommunityDataRowEntityState;
            return entities;
          }, {} as Dictionary<CommunityDataRowEntityState>)
        }
      };
    }
  ),

  on(
    CommunityDataRowGeneratedActions.addCommunitySuccess,
    (state: CommunityDataRowState.IState, { idCommunityDataRow, idCommunity }) => {
      if (!state.entities[idCommunityDataRow]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityDataRow]: {
            ...state.entities[idCommunityDataRow],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityDataRowGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityDataRowState.IState, { idCommunities, idCommunityDataRows }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityDataRows.reduce((entities, idCommunityDataRow) => {
            if (!state.entities[idCommunityDataRow]?.community) {
              return entities;
            }
            entities[idCommunityDataRow] = {
              ...state.entities[idCommunityDataRow],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityDataRow]?.community
              )
                ? undefined
                : state.entities[idCommunityDataRow]?.community
            } as CommunityDataRowEntityState;
            return entities;
          }, {} as Dictionary<CommunityDataRowEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityDataRowState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityDataRowState.IState {
  return { ...state, isLoaded, isLoading };
}
