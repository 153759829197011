import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { RightGeneratedActions } from '@wip/store/actions';
import { RightState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { RightEntityState } from '@api/api-interfaces';

export const rightReducersGeneratedFunctions: ReducerTypes<RightState.IState, readonly ActionCreator[]>[] = [
  on(RightGeneratedActions.getOneRight, (state: RightState.IState) => setLoadingsState(state, true)),
  on(RightGeneratedActions.getManyRights, (state: RightState.IState) => setLoadingsState(state, true)),
  on(RightGeneratedActions.upsertOneRight, (state: RightState.IState) => setLoadingsState(state, true)),

  on(RightGeneratedActions.upsertManyRightsSuccess, (state: RightState.IState, { rights }) =>
    RightState.adapter.upsertMany(rights, setLoadingsState(state, false))
  ),
  on(RightGeneratedActions.deleteOneRight, (state: RightState.IState) => setLoadingsState(state, true)),
  on(RightGeneratedActions.deleteOneRightSuccess, (state: RightState.IState, { idRight }) =>
    RightState.adapter.removeOne(idRight, setLoadingsState(state, false))
  ),
  on(RightGeneratedActions.clearActivesRights, (state: RightState.IState) => ({ ...state, actives: [] })),
  on(RightGeneratedActions.addManyActivesRights, (state: RightState.IState, { idRights }) => ({
    ...state,
    actives: state.actives.concat(idRights)
  })),
  on(RightGeneratedActions.deleteOneActiveRight, (state: RightState.IState, { idRight }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idRight)
  })),

  on(RightGeneratedActions.clearRights, () => RightState.initialState),

  on(RightGeneratedActions.addManyRightProfilSuccess, (state: RightState.IState, { idRight, idRightProfils }) => {
    if (!state.entities[idRight]) {
      return state;
    }
    const rightProfils = (state.entities[idRight]?.rightProfils as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idRight]: {
          ...state.entities[idRight],
          rightProfils: rightProfils.concat(idRightProfils.filter(id => rightProfils.indexOf(id) < 0))
        }
      }
    };
  }),

  on(RightGeneratedActions.deleteManyRightProfilSuccess, (state: RightState.IState, { idRightProfils, idRights }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idRights.reduce((entities, idRight) => {
          if (!state.entities[idRight]?.rightProfils) {
            return entities;
          }
          entities[idRight] = {
            ...state.entities[idRight],
            rightProfils: (state.entities[idRight]?.rightProfils as number[])?.filter(
              (idRightProfil: number) => !idRightProfils.some((id: number) => id === idRightProfil)
            )
          } as RightEntityState;
          return entities;
        }, {} as Dictionary<RightEntityState>)
      }
    };
  }),

  on(RightGeneratedActions.addManyProfilSuccess, (state: RightState.IState, { idRight, idProfils }) => {
    if (!state.entities[idRight]) {
      return state;
    }
    const profils = (state.entities[idRight]?.profils as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idRight]: {
          ...state.entities[idRight],
          profils: profils.concat(idProfils.filter(id => profils.indexOf(id) < 0))
        }
      }
    };
  }),

  on(RightGeneratedActions.deleteManyProfilSuccess, (state: RightState.IState, { idProfils, idRights }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idRights.reduce((entities, idRight) => {
          if (!state.entities[idRight]?.profils) {
            return entities;
          }
          entities[idRight] = {
            ...state.entities[idRight],
            profils: (state.entities[idRight]?.profils as number[])?.filter(
              (idProfil: number) => !idProfils.some((id: number) => id === idProfil)
            )
          } as RightEntityState;
          return entities;
        }, {} as Dictionary<RightEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: RightState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): RightState.IState {
  return { ...state, isLoaded, isLoading };
}
