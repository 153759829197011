import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationDataColumn, OrganizationDataColumnEntityState } from '@api/api-interfaces';
import { OrganizationDataColumnApiService } from '@wip/store/api-services';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationDataColumn } from '@wip/store/configs/normalization';
import { OrganizationDataColumnSelectors } from '@wip/store/selectors';
import { OrganizationDataColumnRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnValueGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnValue } from '@api/api-interfaces';
import { ValeurGeneratedActions } from '@wip/store/actions';
import { Valeur } from '@api/api-interfaces';
import { OrganizationCaracteristicRowGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicRow } from '@api/api-interfaces';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristic } from '@api/api-interfaces';

export function getDefaultAddOrganizationDataColumnActions(
  organizationDataColumn: OrganizationDataColumnEntityState,
  ids?: OrganizationDataColumnRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationDataColumnGeneratedActions.normalizeManyOrganizationDataColumnsAfterUpsert({
      organizationDataColumns: [organizationDataColumn]
    })
  ];

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyOrganizationDataColumnSuccess({
        idOrganizationFamily: ids.organizationFamily,
        idOrganizationDataColumns: [organizationDataColumn.idOrganizationDataColumn]
      })
    );
    actions.push(
      OrganizationDataColumnGeneratedActions.addOrganizationFamilySuccess({
        idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.organizationDataColumnValues) {
    if (!Array.isArray(ids.organizationDataColumnValues)) {
      actions.push(
        OrganizationDataColumnValueGeneratedActions.upsertOneOrganizationDataColumnValue({
          organizationDataColumnValue: {
            idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
            idOrganizationDataColumnValue: ids.organizationDataColumnValues as number
          } as OrganizationDataColumnValue
        })
      );
      actions.push(
        OrganizationDataColumnGeneratedActions.addManyOrganizationDataColumnValueSuccess({
          idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
          idOrganizationDataColumnValues: [ids.organizationDataColumnValues as number]
        })
      );
    } else {
      actions.push(
        OrganizationDataColumnValueGeneratedActions.upsertManyOrganizationDataColumnValues({
          organizationDataColumnValues: (ids.organizationDataColumnValues as number[]).map(
            (idOrganizationDataColumnValue: number) => ({
              idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
              idOrganizationDataColumnValue
            })
          ) as OrganizationDataColumnValue[]
        })
      );
      actions.push(
        OrganizationDataColumnGeneratedActions.addManyOrganizationDataColumnValueSuccess({
          idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
          idOrganizationDataColumnValues: ids.organizationDataColumnValues as number[]
        })
      );
    }
  }

  if (ids?.valeurs) {
    if (!Array.isArray(ids.valeurs)) {
      actions.push(
        ValeurGeneratedActions.upsertOneValeur({
          valeur: {
            idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
            idValeur: ids.valeurs as number
          } as Valeur
        })
      );
      actions.push(
        OrganizationDataColumnGeneratedActions.addManyValeurSuccess({
          idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
          idValeurs: [ids.valeurs as number]
        })
      );
    } else {
      actions.push(
        ValeurGeneratedActions.upsertManyValeurs({
          valeurs: (ids.valeurs as number[]).map((idValeur: number) => ({
            idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
            idValeur
          })) as Valeur[]
        })
      );
      actions.push(
        OrganizationDataColumnGeneratedActions.addManyValeurSuccess({
          idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
          idValeurs: ids.valeurs as number[]
        })
      );
    }
  }

  if (ids?.organizationCaracteristicRows) {
    if (!Array.isArray(ids.organizationCaracteristicRows)) {
      actions.push(
        OrganizationCaracteristicRowGeneratedActions.upsertOneOrganizationCaracteristicRow({
          organizationCaracteristicRow: {
            idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
            idOrganizationCaracteristicRow: ids.organizationCaracteristicRows as number
          } as OrganizationCaracteristicRow
        })
      );
      actions.push(
        OrganizationDataColumnGeneratedActions.addManyOrganizationCaracteristicRowSuccess({
          idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
          idOrganizationCaracteristicRows: [ids.organizationCaracteristicRows as number]
        })
      );
    } else {
      actions.push(
        OrganizationCaracteristicRowGeneratedActions.upsertManyOrganizationCaracteristicRows({
          organizationCaracteristicRows: (ids.organizationCaracteristicRows as number[]).map(
            (idOrganizationCaracteristicRow: number) => ({
              idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
              idOrganizationCaracteristicRow
            })
          ) as OrganizationCaracteristicRow[]
        })
      );
      actions.push(
        OrganizationDataColumnGeneratedActions.addManyOrganizationCaracteristicRowSuccess({
          idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
          idOrganizationCaracteristicRows: ids.organizationCaracteristicRows as number[]
        })
      );
    }
  }

  if (ids?.organizationCaracteristics) {
    if (!Array.isArray(ids.organizationCaracteristics)) {
      actions.push(
        OrganizationCaracteristicGeneratedActions.upsertOneOrganizationCaracteristic({
          organizationCaracteristic: {
            idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
            idOrganizationCaracteristic: ids.organizationCaracteristics as number
          } as OrganizationCaracteristic
        })
      );
      actions.push(
        OrganizationDataColumnGeneratedActions.addManyOrganizationCaracteristicSuccess({
          idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
          idOrganizationCaracteristics: [ids.organizationCaracteristics as number]
        })
      );
    } else {
      actions.push(
        OrganizationCaracteristicGeneratedActions.upsertManyOrganizationCaracteristics({
          organizationCaracteristics: (ids.organizationCaracteristics as number[]).map(
            (idOrganizationCaracteristic: number) => ({
              idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
              idOrganizationCaracteristic
            })
          ) as OrganizationCaracteristic[]
        })
      );
      actions.push(
        OrganizationDataColumnGeneratedActions.addManyOrganizationCaracteristicSuccess({
          idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn,
          idOrganizationCaracteristics: ids.organizationCaracteristics as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationDataColumnActions(
  organizationDataColumn: OrganizationDataColumnEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationDataColumnGeneratedActions.deleteOneOrganizationDataColumnSuccess({
      idOrganizationDataColumn: organizationDataColumn.idOrganizationDataColumn
    })
  ];

  if (organizationDataColumn.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationDataColumnSuccess({
        idOrganizationDataColumns: [organizationDataColumn.idOrganizationDataColumn],
        idOrganizationFamilys: [organizationDataColumn.organizationFamily as number]
      })
    );
  }

  if (organizationDataColumn.organizationDataColumnValues) {
    actions.push(
      OrganizationDataColumnValueGeneratedActions.deleteManyOrganizationDataColumnSuccess({
        idOrganizationDataColumns: [organizationDataColumn.idOrganizationDataColumn],
        idOrganizationDataColumnValues: organizationDataColumn.organizationDataColumnValues as number[]
      })
    );
  }

  if (organizationDataColumn.valeurs) {
    actions.push(
      ValeurGeneratedActions.deleteManyOrganizationDataColumnSuccess({
        idOrganizationDataColumns: [organizationDataColumn.idOrganizationDataColumn],
        idValeurs: organizationDataColumn.valeurs as number[]
      })
    );
  }

  if (organizationDataColumn.organizationCaracteristicRows) {
    actions.push(
      OrganizationCaracteristicRowGeneratedActions.deleteManyOrganizationDataColumnSuccess({
        idOrganizationDataColumns: [organizationDataColumn.idOrganizationDataColumn],
        idOrganizationCaracteristicRows: organizationDataColumn.organizationCaracteristicRows as number[]
      })
    );
  }

  if (organizationDataColumn.organizationCaracteristics) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.deleteManyOrganizationDataColumnSuccess({
        idOrganizationDataColumns: [organizationDataColumn.idOrganizationDataColumn],
        idOrganizationCaracteristics: organizationDataColumn.organizationCaracteristics as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationDataColumnEffects {
  constructor(
    protected actions$: Actions,
    protected organizationDataColumnApiService: OrganizationDataColumnApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationDataColumns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDataColumnGeneratedActions.getManyOrganizationDataColumns),
      switchMap(({ params }) =>
        this.organizationDataColumnApiService.getOrganizationDataColumns(params).pipe(
          map((organizationDataColumns: OrganizationDataColumn[]) => {
            return OrganizationDataColumnGeneratedActions.normalizeManyOrganizationDataColumnsAfterUpsert({
              organizationDataColumns
            });
          }),
          catchError(error => of(OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationDataColumn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDataColumnGeneratedActions.getOneOrganizationDataColumn),
      switchMap(idOrganizationDataColumn =>
        this.organizationDataColumnApiService.getOrganizationDataColumn(idOrganizationDataColumn).pipe(
          map((organizationDataColumn: OrganizationDataColumn) => {
            return OrganizationDataColumnGeneratedActions.normalizeManyOrganizationDataColumnsAfterUpsert({
              organizationDataColumns: [organizationDataColumn]
            });
          }),
          catchError(error => of(OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationDataColumn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDataColumnGeneratedActions.upsertOneOrganizationDataColumn),
      concatMap(
        ({
          organizationDataColumn,
          ids
        }: {
          organizationDataColumn: Partial<OrganizationDataColumn>;
          ids?: OrganizationDataColumnRelationsIds;
        }) => {
          if (organizationDataColumn.idOrganizationDataColumn) {
            return this.organizationDataColumnApiService.updateOrganizationDataColumn(organizationDataColumn).pipe(
              map((organizationDataColumnReturned: OrganizationDataColumn) => {
                return OrganizationDataColumnGeneratedActions.normalizeManyOrganizationDataColumnsAfterUpsert({
                  organizationDataColumns: [organizationDataColumnReturned]
                });
              }),
              catchError(error => of(OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure({ error })))
            );
          } else {
            return this.organizationDataColumnApiService.addOrganizationDataColumn(organizationDataColumn).pipe(
              mergeMap((organizationDataColumnReturned: OrganizationDataColumn) =>
                getDefaultAddOrganizationDataColumnActions(organizationDataColumnReturned, ids)
              ),
              catchError(error => of(OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationDataColumn$ = createEffect(() => {
    const selectOrganizationDataColumnState$ = this.store$.select(
      OrganizationDataColumnSelectors.selectOrganizationDataColumnState
    );
    return this.actions$.pipe(
      ofType(OrganizationDataColumnGeneratedActions.deleteOneOrganizationDataColumn),
      withLatestFrom(selectOrganizationDataColumnState$),
      concatMap(([{ idOrganizationDataColumn }, state]) =>
        this.organizationDataColumnApiService.deleteOrganizationDataColumn(idOrganizationDataColumn).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationDataColumnActions(
              state.entities[idOrganizationDataColumn] as OrganizationDataColumnEntityState
            )
          ),
          catchError(error => of(OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationDataColumnsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDataColumnGeneratedActions.normalizeManyOrganizationDataColumnsAfterUpsert),
      concatMap(({ organizationDataColumns }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationDataColumn(
          organizationDataColumns,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationDataColumn] Normalization After Upsert Success')];
      })
    );
  });
}
