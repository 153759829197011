import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedUserProfilRightApiService } from './user-profil-right-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfilRightApiService extends GeneratedUserProfilRightApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}

