import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationStepGeneratedActions } from '@wip/store/actions';
import { OrganizationStepState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationStepEntityState } from '@api/api-interfaces';

export const organizationStepReducersGeneratedFunctions: ReducerTypes<
  OrganizationStepState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationStepGeneratedActions.getOneOrganizationStep, (state: OrganizationStepState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationStepGeneratedActions.getManyOrganizationSteps, (state: OrganizationStepState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationStepGeneratedActions.upsertOneOrganizationStep, (state: OrganizationStepState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationStepGeneratedActions.upsertManyOrganizationStepsSuccess,
    (state: OrganizationStepState.IState, { organizationSteps }) =>
      OrganizationStepState.adapter.upsertMany(organizationSteps, setLoadingsState(state, false))
  ),
  on(OrganizationStepGeneratedActions.deleteOneOrganizationStep, (state: OrganizationStepState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationStepGeneratedActions.deleteOneOrganizationStepSuccess,
    (state: OrganizationStepState.IState, { idOrganizationStep }) =>
      OrganizationStepState.adapter.removeOne(idOrganizationStep, setLoadingsState(state, false))
  ),
  on(OrganizationStepGeneratedActions.clearActivesOrganizationSteps, (state: OrganizationStepState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationStepGeneratedActions.addManyActivesOrganizationSteps,
    (state: OrganizationStepState.IState, { idOrganizationSteps }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationSteps)
    })
  ),
  on(
    OrganizationStepGeneratedActions.deleteOneActiveOrganizationStep,
    (state: OrganizationStepState.IState, { idOrganizationStep }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationStep)
    })
  ),

  on(OrganizationStepGeneratedActions.clearOrganizationSteps, () => OrganizationStepState.initialState),

  on(
    OrganizationStepGeneratedActions.addManyCommunitySuccess,
    (state: OrganizationStepState.IState, { idOrganizationStep, idCommunities }) => {
      if (!state.entities[idOrganizationStep]) {
        return state;
      }
      const communities = (state.entities[idOrganizationStep]?.communities as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStep]: {
            ...state.entities[idOrganizationStep],
            communities: communities.concat(idCommunities.filter(id => communities.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationStepGeneratedActions.deleteManyCommunitySuccess,
    (state: OrganizationStepState.IState, { idCommunities, idOrganizationSteps }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSteps.reduce((entities, idOrganizationStep) => {
            if (!state.entities[idOrganizationStep]?.communities) {
              return entities;
            }
            entities[idOrganizationStep] = {
              ...state.entities[idOrganizationStep],
              communities: (state.entities[idOrganizationStep]?.communities as number[])?.filter(
                (idCommunity: number) => !idCommunities.some((id: number) => id === idCommunity)
              )
            } as OrganizationStepEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStepEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStepGeneratedActions.addOrganizationSuccess,
    (state: OrganizationStepState.IState, { idOrganizationStep, idOrganization }) => {
      if (!state.entities[idOrganizationStep]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStep]: {
            ...state.entities[idOrganizationStep],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationStepGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationStepState.IState, { idOrganizations, idOrganizationSteps }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSteps.reduce((entities, idOrganizationStep) => {
            if (!state.entities[idOrganizationStep]?.organization) {
              return entities;
            }
            entities[idOrganizationStep] = {
              ...state.entities[idOrganizationStep],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationStep]?.organization
              )
                ? undefined
                : state.entities[idOrganizationStep]?.organization
            } as OrganizationStepEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStepEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStepGeneratedActions.addOrganizationFamilySuccess,
    (state: OrganizationStepState.IState, { idOrganizationStep, idOrganizationFamily }) => {
      if (!state.entities[idOrganizationStep]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStep]: {
            ...state.entities[idOrganizationStep],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationStepGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationStepState.IState, { idOrganizationFamilys, idOrganizationSteps }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSteps.reduce((entities, idOrganizationStep) => {
            if (!state.entities[idOrganizationStep]?.organizationFamily) {
              return entities;
            }
            entities[idOrganizationStep] = {
              ...state.entities[idOrganizationStep],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idOrganizationStep]?.organizationFamily
              )
                ? undefined
                : state.entities[idOrganizationStep]?.organizationFamily
            } as OrganizationStepEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStepEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationStepState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationStepState.IState {
  return { ...state, isLoaded, isLoading };
}
