import { RepositoryService } from '@wip/services/repository';
import { MeetingUser } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedMeetingUserApiService {
  constructor(protected repo: RepositoryService) {}

  public getMeetingUsers(params?: any): Observable<MeetingUser[]> {
    return this.repo.getData<MeetingUser[]>('meeting-user', params);
  }

  public getMeetingUser(params: { idMeetingUser: number; params?: any }): Observable<MeetingUser> {
    return this.repo.getData<MeetingUser>('meeting-user/' + params.idMeetingUser, params.params);
  }

  public addMeetingUser(meetingUser: Partial<MeetingUser>): Observable<MeetingUser> {
    return this.repo.create<MeetingUser>('meeting-user', meetingUser);
  }

  public updateMeetingUser(meetingUser: Partial<MeetingUser>): Observable<MeetingUser> {
    return this.repo.update('meeting-user', meetingUser);
  }

  public deleteMeetingUser(idMeetingUser: number): Observable<number> {
    return this.repo.delete('meeting-user/' + +idMeetingUser);
  }
}
