<div style="height: 32px" class="flex flex-row justify-between">
  <h1 class="title">Pilotage</h1>

  @if (this.moduleIndex === 1 && isUserAdmin) {
    <div>
      <ng-container>
        @if (editMode) {
          <button mat-stroked-button color="primary" (click)="onActivateEdition()" class="edit-button">
            Désactiver édition des données
          </button>
        }
        @if (!editMode) {
          <button
            [disabled]="!isUserAdmin || this.moduleIndex !== 1"
            mat-flat-button
            color="primary"
            (click)="onActivateEdition()"
            class="edit-button"
          >
            Activer édition des données
          </button>
        }
      </ng-container>
    </div>
  }
</div>
<div>
  <mat-tab-group
    (selectedTabChange)="onModuleChange($event)"
    [(selectedIndex)]="this.moduleIndex"
    mat-stretch-tabs="false"
    style="margin-left: 24px; height: 41px"
  >
    @for (module of dashboardModules; track module) {
      <mat-tab [label]="module"> </mat-tab>
    }
  </mat-tab-group>
</div>
<app-dashboard-filters
  style="background-color: #f3f4f6"
  [organization]="organization$ | async"
  [communities]="communities$ | async"
  [organizationUser]="organizationUser$ | async"
  [listHashtags]="listHashtags"
  [configurationBoardStates]="(configurationBoardStates$ | async) || []"
  [projectMilestoneFamilies]="(orgaMilestoneFamilies$ | async) || []"
  [projectCharacteristics]="projectCharacteristics"
  [projectMilestones]="projectMilestones"
  [moduleIndex]="moduleIndex"
  [updateButton]="updateButton"
  [chartInputsForm]="chartInputsForm"
  (boardStateEmitter)="emitBoardState($event)"
  (configEmitter)="handleConfiguration($event)"
  (organizationFamilyEmitter)="orgaFamilySubject$.next($event)"
/>

<div style="height: 100%; overflow-x: auto; overflow-y: hidden; background-color: #f3f4f6">
  <router-outlet></router-outlet>
</div>
