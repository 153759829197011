import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityGroupGeneratedActions } from '@wip/store/actions';
import { CommunityGroupState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityGroupEntityState } from '@api/api-interfaces';

export const communityGroupReducersGeneratedFunctions: ReducerTypes<
  CommunityGroupState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunityGroupGeneratedActions.getOneCommunityGroup, (state: CommunityGroupState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityGroupGeneratedActions.getManyCommunityGroups, (state: CommunityGroupState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityGroupGeneratedActions.upsertOneCommunityGroup, (state: CommunityGroupState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    CommunityGroupGeneratedActions.upsertManyCommunityGroupsSuccess,
    (state: CommunityGroupState.IState, { communityGroups }) =>
      CommunityGroupState.adapter.upsertMany(communityGroups, setLoadingsState(state, false))
  ),
  on(CommunityGroupGeneratedActions.deleteOneCommunityGroup, (state: CommunityGroupState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    CommunityGroupGeneratedActions.deleteOneCommunityGroupSuccess,
    (state: CommunityGroupState.IState, { idCommunityGroup }) =>
      CommunityGroupState.adapter.removeOne(idCommunityGroup, setLoadingsState(state, false))
  ),
  on(CommunityGroupGeneratedActions.clearActivesCommunityGroups, (state: CommunityGroupState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    CommunityGroupGeneratedActions.addManyActivesCommunityGroups,
    (state: CommunityGroupState.IState, { idCommunityGroups }) => ({
      ...state,
      actives: state.actives.concat(idCommunityGroups)
    })
  ),
  on(
    CommunityGroupGeneratedActions.deleteOneActiveCommunityGroup,
    (state: CommunityGroupState.IState, { idCommunityGroup }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityGroup)
    })
  ),

  on(CommunityGroupGeneratedActions.clearCommunityGroups, () => CommunityGroupState.initialState),

  on(
    CommunityGroupGeneratedActions.addCommunitySuccess,
    (state: CommunityGroupState.IState, { idCommunityGroup, idCommunity }) => {
      if (!state.entities[idCommunityGroup]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityGroup]: {
            ...state.entities[idCommunityGroup],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityGroupGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityGroupState.IState, { idCommunities, idCommunityGroups }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityGroups.reduce((entities, idCommunityGroup) => {
            if (!state.entities[idCommunityGroup]?.community) {
              return entities;
            }
            entities[idCommunityGroup] = {
              ...state.entities[idCommunityGroup],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityGroup]?.community
              )
                ? undefined
                : state.entities[idCommunityGroup]?.community
            } as CommunityGroupEntityState;
            return entities;
          }, {} as Dictionary<CommunityGroupEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGroupGeneratedActions.addGroupSuccess,
    (state: CommunityGroupState.IState, { idCommunityGroup, idGroup }) => {
      if (!state.entities[idCommunityGroup]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityGroup]: {
            ...state.entities[idCommunityGroup],
            group: idGroup
          }
        }
      };
    }
  ),

  on(
    CommunityGroupGeneratedActions.deleteManyGroupSuccess,
    (state: CommunityGroupState.IState, { idGroups, idCommunityGroups }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityGroups.reduce((entities, idCommunityGroup) => {
            if (!state.entities[idCommunityGroup]?.group) {
              return entities;
            }
            entities[idCommunityGroup] = {
              ...state.entities[idCommunityGroup],
              group: idGroups.some((idGroup: number) => idGroup === state.entities[idCommunityGroup]?.group)
                ? undefined
                : state.entities[idCommunityGroup]?.group
            } as CommunityGroupEntityState;
            return entities;
          }, {} as Dictionary<CommunityGroupEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityGroupState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityGroupState.IState {
  return { ...state, isLoaded, isLoading };
}
