import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Right } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { RightRelationsIds } from '@wip/store/ids-interfaces';
import { RightGeneratedActions } from '@wip/store/actions';
import { RightSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedRightService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(RightSelectors.selectIsLoadedRight));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(RightSelectors.selectIsLoadingRight));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [RightSelectors.selectIsReadyAndLoadedRight as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllRights(schema: SelectSchema = {}): Observable<Right[]> {
    return this.store$.pipe(select(RightSelectors.selectAllRights(schema))).pipe(
      switchMap(({ rights }: { rights: Right[] }) => {
        return this.getReady(schema).pipe(mapTo(rights));
      })
    );
  }

  public selectOneRight(idRight: number, schema: SelectSchema = {}): Observable<Right> {
    return this.store$.pipe(select(RightSelectors.selectOneRight(schema, idRight))).pipe(
      switchMap(({ right }: { right: Right }) => {
        return this.getReady(schema).pipe(mapTo(right));
      })
    );
  }

  public selectAllActiveRights(schema: SelectSchema = {}): Observable<Right[]> {
    return this.store$.pipe(select(RightSelectors.selectActiveRights(schema))).pipe(
      switchMap(({ rights }: { rights: Right[] }) => {
        return this.getReady(schema).pipe(mapTo(rights));
      })
    );
  }

  public selectIdRightsActive(): Observable<number[]> {
    return this.store$.pipe(select(RightSelectors.selectIdRightsActive)).pipe(
      switchMap((idRights: number[]) => {
        return this.getReady().pipe(mapTo(idRights));
      })
    );
  }

  public getOneRight(idRight: number, params: any = {}, getResult?: boolean): void | Observable<Right> {
    this.store$.dispatch(RightGeneratedActions.getOneRight({ idRight, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        RightGeneratedActions.normalizeManyRightsAfterUpsert,
        RightGeneratedActions.rightsFailure,
        true
      );
    }
  }

  public getManyRights(params: any = {}, getResult?: boolean): void | Observable<Right[]> {
    this.store$.dispatch(RightGeneratedActions.getManyRights({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        RightGeneratedActions.normalizeManyRightsAfterUpsert,
        RightGeneratedActions.rightsFailure
      );
    }
  }

  public upsertOneRight(
    right: Partial<Right>,
    ids: RightRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Right> {
    this.store$.dispatch(RightGeneratedActions.upsertOneRight({ right, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        RightGeneratedActions.normalizeManyRightsAfterUpsert,
        RightGeneratedActions.rightsFailure,
        true
      );
    }
  }

  public deleteOneRight(idRight: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(RightGeneratedActions.deleteOneRight({ idRight }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        RightGeneratedActions.deleteOneRightSuccess,
        RightGeneratedActions.rightsFailure
      );
    }
  }

  public setActiveRights(idRights: number[]): void {
    this.store$.dispatch(RightGeneratedActions.clearActivesRights());
    this.store$.dispatch(RightGeneratedActions.addManyActivesRights({ idRights }));
  }
}
