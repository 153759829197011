import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationKpi } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationKpiRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationKpiGeneratedActions } from '@wip/store/actions';
import { OrganizationKpiSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationKpiService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationKpiSelectors.selectIsLoadedOrganizationKpi));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationKpiSelectors.selectIsLoadingOrganizationKpi));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationKpiSelectors.selectIsReadyAndLoadedOrganizationKpi as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationKpis(schema: SelectSchema = {}): Observable<OrganizationKpi[]> {
    return this.store$.pipe(select(OrganizationKpiSelectors.selectAllOrganizationKpis(schema))).pipe(
      switchMap(({ organizationKpis }: { organizationKpis: OrganizationKpi[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationKpis));
      })
    );
  }

  public selectOneOrganizationKpi(idOrganizationKpi: number, schema: SelectSchema = {}): Observable<OrganizationKpi> {
    return this.store$.pipe(select(OrganizationKpiSelectors.selectOneOrganizationKpi(schema, idOrganizationKpi))).pipe(
      switchMap(({ organizationKpi }: { organizationKpi: OrganizationKpi }) => {
        return this.getReady(schema).pipe(mapTo(organizationKpi));
      })
    );
  }

  public selectAllActiveOrganizationKpis(schema: SelectSchema = {}): Observable<OrganizationKpi[]> {
    return this.store$.pipe(select(OrganizationKpiSelectors.selectActiveOrganizationKpis(schema))).pipe(
      switchMap(({ organizationKpis }: { organizationKpis: OrganizationKpi[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationKpis));
      })
    );
  }

  public selectIdOrganizationKpisActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationKpiSelectors.selectIdOrganizationKpisActive)).pipe(
      switchMap((idOrganizationKpis: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationKpis));
      })
    );
  }

  public getOneOrganizationKpi(
    idOrganizationKpi: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationKpi> {
    this.store$.dispatch(OrganizationKpiGeneratedActions.getOneOrganizationKpi({ idOrganizationKpi, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationKpiGeneratedActions.normalizeManyOrganizationKpisAfterUpsert,
        OrganizationKpiGeneratedActions.organizationKpisFailure,
        true
      );
    }
  }

  public getManyOrganizationKpis(params: any = {}, getResult?: boolean): void | Observable<OrganizationKpi[]> {
    this.store$.dispatch(OrganizationKpiGeneratedActions.getManyOrganizationKpis({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationKpiGeneratedActions.normalizeManyOrganizationKpisAfterUpsert,
        OrganizationKpiGeneratedActions.organizationKpisFailure
      );
    }
  }

  public upsertOneOrganizationKpi(
    organizationKpi: Partial<OrganizationKpi>,
    ids: OrganizationKpiRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationKpi> {
    this.store$.dispatch(OrganizationKpiGeneratedActions.upsertOneOrganizationKpi({ organizationKpi, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationKpiGeneratedActions.normalizeManyOrganizationKpisAfterUpsert,
        OrganizationKpiGeneratedActions.organizationKpisFailure,
        true
      );
    }
  }

  public deleteOneOrganizationKpi(idOrganizationKpi: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(OrganizationKpiGeneratedActions.deleteOneOrganizationKpi({ idOrganizationKpi }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationKpiGeneratedActions.deleteOneOrganizationKpiSuccess,
        OrganizationKpiGeneratedActions.organizationKpisFailure
      );
    }
  }

  public setActiveOrganizationKpis(idOrganizationKpis: number[]): void {
    this.store$.dispatch(OrganizationKpiGeneratedActions.clearActivesOrganizationKpis());
    this.store$.dispatch(OrganizationKpiGeneratedActions.addManyActivesOrganizationKpis({ idOrganizationKpis }));
  }
}
