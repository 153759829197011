import { createAction, props } from '@ngrx/store';
import { UploadFileInterface } from '@api/api-interfaces';

export * from './folder-generated.actions';

export const uploadFile = createAction(
  '[Folder] Upload File',
  props<{
    params: UploadFileInterface;
  }>()
);
