import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserGroup, UserGroupEntityState } from '@api/api-interfaces';
import { UserGroupRelationsIds } from '@wip/store/ids-interfaces';

export const UserGroupGeneratedActions = createActionGroup({
  source: 'User Group Generated',
  events: {
    'Get One User Group': props<{ idUserGroup: number; params?: any }>(),
    'Get Many User Groups': props<{ params: any }>(),
    'Add Many Actives User Groups': props<{ idUserGroups: number[] }>(),
    'Delete One Active User Group': props<{ idUserGroup: number }>(),
    'Clear Actives User Groups': emptyProps(),
    'Upsert One User Group': props<{ userGroup: Partial<UserGroup>; ids?: UserGroupRelationsIds }>(),
    'Upsert Many User Groups': props<{ userGroups: Partial<UserGroup>[]; ids?: UserGroupRelationsIds }>(),
    'Upsert Many User Groups Success': props<{ userGroups: UserGroupEntityState[] }>(),
    'Delete One User Group': props<{ idUserGroup: number }>(),
    'Delete One User Group Success': props<{ idUserGroup: number }>(),
    'Normalize Many User Groups After Upsert': props<{ userGroups: UserGroupEntityState[] }>(),
    'User Groups Failure': props<{ error: HttpErrorResponse }>(),
    'Clear User Groups': emptyProps(),
    'Add User Success': props<{ idUserGroup: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idUserGroups: number[] }>(),
    'Add Group Success': props<{ idUserGroup: number; idGroup: number }>(),
    'Delete Many Group Success': props<{ idGroups: number[]; idUserGroups: number[] }>()
  }
});
