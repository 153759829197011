import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { MeetingElementGeneratedActions } from '@wip/store/actions';
import { MeetingElementState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { MeetingElementEntityState } from '@api/api-interfaces';

export const meetingElementReducersGeneratedFunctions: ReducerTypes<
  MeetingElementState.IState,
  readonly ActionCreator[]
>[] = [
  on(MeetingElementGeneratedActions.getOneMeetingElement, (state: MeetingElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(MeetingElementGeneratedActions.getManyMeetingElements, (state: MeetingElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(MeetingElementGeneratedActions.upsertOneMeetingElement, (state: MeetingElementState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    MeetingElementGeneratedActions.upsertManyMeetingElementsSuccess,
    (state: MeetingElementState.IState, { meetingElements }) =>
      MeetingElementState.adapter.upsertMany(meetingElements, setLoadingsState(state, false))
  ),
  on(MeetingElementGeneratedActions.deleteOneMeetingElement, (state: MeetingElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    MeetingElementGeneratedActions.deleteOneMeetingElementSuccess,
    (state: MeetingElementState.IState, { idMeetingElement }) =>
      MeetingElementState.adapter.removeOne(idMeetingElement, setLoadingsState(state, false))
  ),
  on(MeetingElementGeneratedActions.clearActivesMeetingElements, (state: MeetingElementState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    MeetingElementGeneratedActions.addManyActivesMeetingElements,
    (state: MeetingElementState.IState, { idMeetingElements }) => ({
      ...state,
      actives: state.actives.concat(idMeetingElements)
    })
  ),
  on(
    MeetingElementGeneratedActions.deleteOneActiveMeetingElement,
    (state: MeetingElementState.IState, { idMeetingElement }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idMeetingElement)
    })
  ),

  on(MeetingElementGeneratedActions.clearMeetingElements, () => MeetingElementState.initialState),

  on(
    MeetingElementGeneratedActions.addMeetingSuccess,
    (state: MeetingElementState.IState, { idMeetingElement, idMeeting }) => {
      if (!state.entities[idMeetingElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idMeetingElement]: {
            ...state.entities[idMeetingElement],
            meeting: idMeeting
          }
        }
      };
    }
  ),

  on(
    MeetingElementGeneratedActions.deleteManyMeetingSuccess,
    (state: MeetingElementState.IState, { idMeetings, idMeetingElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idMeetingElements.reduce((entities, idMeetingElement) => {
            if (!state.entities[idMeetingElement]?.meeting) {
              return entities;
            }
            entities[idMeetingElement] = {
              ...state.entities[idMeetingElement],
              meeting: idMeetings.some((idMeeting: number) => idMeeting === state.entities[idMeetingElement]?.meeting)
                ? undefined
                : state.entities[idMeetingElement]?.meeting
            } as MeetingElementEntityState;
            return entities;
          }, {} as Dictionary<MeetingElementEntityState>)
        }
      };
    }
  ),

  on(
    MeetingElementGeneratedActions.addElementSuccess,
    (state: MeetingElementState.IState, { idMeetingElement, idElement }) => {
      if (!state.entities[idMeetingElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idMeetingElement]: {
            ...state.entities[idMeetingElement],
            element: idElement
          }
        }
      };
    }
  ),

  on(
    MeetingElementGeneratedActions.deleteManyElementSuccess,
    (state: MeetingElementState.IState, { idElements, idMeetingElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idMeetingElements.reduce((entities, idMeetingElement) => {
            if (!state.entities[idMeetingElement]?.element) {
              return entities;
            }
            entities[idMeetingElement] = {
              ...state.entities[idMeetingElement],
              element: idElements.some((idElement: number) => idElement === state.entities[idMeetingElement]?.element)
                ? undefined
                : state.entities[idMeetingElement]?.element
            } as MeetingElementEntityState;
            return entities;
          }, {} as Dictionary<MeetingElementEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: MeetingElementState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): MeetingElementState.IState {
  return { ...state, isLoaded, isLoading };
}
