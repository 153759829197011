/* eslint-disable @nx/enforce-module-boundaries */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommunityTypeStructureEnum, OrganizationFamilyOperationTypeEnumTradEToFR } from '@enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Community, TodoElement } from '@api/api-interfaces';

@UntilDestroy()
@Component({
  selector: 'app-project-synthesis-dialog',
  templateUrl: './project-synthesis-dialog.component.html',
  styleUrls: ['./project-synthesis-dialog.component.scss']
})
export class ProjectSynthesisDialogComponent {
  public idCommunity: number;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      project: Community;
      todoElements: TodoElement[];
      isFromDashboard: boolean;
      isProjectView: boolean;
      wordingTypeStructure: CommunityTypeStructureEnum;
      typeStructure: OrganizationFamilyOperationTypeEnumTradEToFR;
    },

    private dialogRef: MatDialogRef<ProjectSynthesisDialogComponent>,
    private router: Router
  ) {
    this.idCommunity = this.dialogData.project.idCommunity;
  }

  public cancel() {
    this.dialogRef.close();
  }

  public redirectToCommunityProjectPage() {
    this.router.navigate([`/app/${this.dialogData.typeStructure}/${this.idCommunity}`]);
    this.cancel();
  }
}
