import { IStatusPanelAngularComp } from '@ag-grid-community/angular';
import { IStatusPanelParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganizationUserService, UserService } from '@wip/store/services';

interface StatusBarComponentInputs {
  minimal: boolean;
  onSideBarToggle: () => void;
  resetFilters: () => void;
  resetColumnState: () => void;
  boardStateLibelle: string;
}

@Component({
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements IStatusPanelAngularComp {
  public sideBarOpened = false;
  public minimal: boolean;
  public areColumnStatesChanged: boolean;
  public areFiltersActives: boolean;
  public resetFilters: () => void;
  public resetColumnState: () => void;
  public boardStateLibelle: string = 'Configuration initiale';

  private onSideBarToggle: () => void;
  private handleConfig: boolean = false;
  private saveConfig: boolean = false;
  private saveColumns: boolean = false;

  public displaySaveButton: boolean = false;
  public displayResetButton: boolean = false;

  constructor(
    private organizationUserService: OrganizationUserService,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) {}

  public agInit(params: IStatusPanelParams & StatusBarComponentInputs): void {
    const { minimal, onSideBarToggle, resetFilters, resetColumnState, boardStateLibelle } = params;

    this.minimal = minimal;
    this.onSideBarToggle = onSideBarToggle;
    this.resetFilters = resetFilters;
    this.resetColumnState = resetColumnState;
    if (boardStateLibelle?.length) {
      this.boardStateLibelle = boardStateLibelle;
    }
    this.organizationUserService.toggleSideBar$.subscribe({
      next: _ => {
        this.toggleSidebar();
      }
    });
    this.organizationUserService.setButtons$.subscribe({
      next: params => {
        //@ts-ignore
        if (params.source !== 'api') {
          this.displayResetButton = true;
          this.displaySaveButton =
            params.idUserBoardState !== null && params.organizationUser?.idUser === this.userService.currentIdUser;
        }
      }
    });
  }

  public toggleSidebar(close: boolean = false): void {
    this.onSideBarToggle();
    this.sideBarOpened = !this.sideBarOpened;
    if (close) {
      this.snackBar.open('Configuration sauvegardée', undefined, {
        duration: 3000
      });
    }
  }

  public setAreColumnStatesChanged(areColumnStatesChanged: boolean): void {
    this.areColumnStatesChanged = areColumnStatesChanged;
  }

  public setAreFiltersActives(areFiltersActives: boolean): void {
    this.areFiltersActives = areFiltersActives;
  }

  public onChangeConfig() {
    this.handleConfig = !this.handleConfig;
    this.organizationUserService.handleConfiguration$.next(this.handleConfig);
  }

  public onSaveConfig() {
    this.saveConfig = !this.saveConfig;
    this.organizationUserService.saveConfiguration$.next(this.saveConfig);
  }

  public onSaveFromColumns() {
    this.saveColumns = !this.saveColumns;
    this.organizationUserService.saveColumns$.next(this.saveColumns);
  }

  public onReset() {
    this.resetColumnState();
    this.resetFilters();
    this.resetButtons();
  }

  public resetButtons() {
    this.displayResetButton = false;
    this.displaySaveButton = false;
  }
}
