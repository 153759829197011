<div class="wrap flex gap-8">
  @if (displayResetButton) {
    <button
      mat-stroked-button
      color="warn"
      (click)="onReset()"
      class="mdc-button-filter flex flex-row items-center outlined-field"
    >
      <i class="icon icon-ic_fluent_dismiss_24_filled" style="font-size: 16px; margin-right: 4px; line-height: 8px"></i>
      <span>Rétablir configuration</span>
    </button>
  }
  @if (sideBarOpened) {
    <button
      mat-stroked-button
      [ngClass]="minimal && !areColumnStatesChanged && !sideBarOpened ? 'hide-button' : ''"
      (click)="toggleSidebar(true); onSaveFromColumns(); resetButtons()"
      [color]="sideBarOpened ? 'warn' : ''"
      class="mdc-button-filter"
      class="outlined-field"
    >
      Arrêter personnalisation
    </button>
  }
  <div class="button-group">
    @if (displaySaveButton) {
      <button
        mat-stroked-button
        matTooltip="Sauvegarder la configuration &quot;{{ boardStateLibelle }}&quot;"
        class="outlined-field"
        (click)="onSaveConfig(); resetButtons()"
      >
        <i class="icons more-icon icon-ic_fluent_save_24_regular"></i>
      </button>
    }
    <button class="outlined-field" mat-stroked-button matTooltip="Configurer le tableau" (click)="onChangeConfig()">
      <span>Configurer</span>
    </button>
  </div>
</div>
