import { Injectable } from '@angular/core';
import { UserGroup } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedUserGroupApiService } from './user-group-api-generated.service';
import { upsertUserGroupDTO } from '@wip/store/actions';

@Injectable({
  providedIn: 'root'
})
export class UserGroupApiService extends GeneratedUserGroupApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateAndRemoveUserGroups(params: upsertUserGroupDTO): Observable<UserGroup[]> {
    return this.repo.update('user-group/insert-and-remove', params);
  }
}
