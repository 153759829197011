import { RepositoryService } from '@wip/services/repository';
import { NotesHistory } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedNotesHistoryApiService {
  constructor(protected repo: RepositoryService) {}

  public getNotesHistories(params?: any): Observable<NotesHistory[]> {
    return this.repo.getData<NotesHistory[]>('notes-history', params);
  }

  public getNotesHistory(params: { idNotesHistory: number; params?: any }): Observable<NotesHistory> {
    return this.repo.getData<NotesHistory>('notes-history/' + params.idNotesHistory, params.params);
  }

  public addNotesHistory(notesHistory: Partial<NotesHistory>): Observable<NotesHistory> {
    return this.repo.create<NotesHistory>('notes-history', notesHistory);
  }

  public updateNotesHistory(notesHistory: Partial<NotesHistory>): Observable<NotesHistory> {
    return this.repo.update('notes-history', notesHistory);
  }

  public deleteNotesHistory(idNotesHistory: number): Observable<number> {
    return this.repo.delete('notes-history/' + +idNotesHistory);
  }
}
