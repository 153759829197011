import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedTimelineElementApiService } from './timeline-element-api-generated.service';
import { Element, TimelineElement } from '@api/api-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimelineElementApiService extends GeneratedTimelineElementApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public createTimelineElement(params: {
    idCommunity: number;
    element: Partial<Element>;
  }): Observable<TimelineElement[]> {
    return this.repo.create<TimelineElement[]>('timeline-element/create', params);
  }
}
