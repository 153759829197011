import { CommunityKpiState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityKpiReducersGeneratedFunctions } from './community-kpi-generated.reducer';

const communityKpiReducersFunctions = [...communityKpiReducersGeneratedFunctions];

const communityKpiReducer = createReducer(CommunityKpiState.initialState, ...communityKpiReducersFunctions);

export function reducer(state: CommunityKpiState.IState | undefined, action: Action) {
  return communityKpiReducer(state, action);
}
