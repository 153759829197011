import { GanttLinkState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { ganttLinkReducersGeneratedFunctions } from './gantt-link-generated.reducer';

const ganttLinkReducersFunctions = [...ganttLinkReducersGeneratedFunctions];

const ganttLinkReducer = createReducer(GanttLinkState.initialState, ...ganttLinkReducersFunctions);

export function reducer(state: GanttLinkState.IState | undefined, action: Action) {
  return ganttLinkReducer(state, action);
}
