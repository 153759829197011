import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Valeur, ValeurEntityState } from '@api/api-interfaces';
import { CommunityDataRow, CommunityDataRowEntityState } from '@api/api-interfaces';
import { OrganizationDataColumn, OrganizationDataColumnEntityState } from '@api/api-interfaces';
import { OrganizationDataColumnValue, OrganizationDataColumnValueEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { ValeurState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const valeurRelations: string[] = [
  'communityDataRows',
  'organizationDataColumns',
  'organizationDataColumnValues'
];

export const { selectEntities, selectAll } = ValeurState.adapter.getSelectors();

export const selectValeurState = createFeatureSelector<ValeurState.IState>(ValeurState.valeurFeatureKey);

export const selectIsLoadedValeur = createSelector(selectValeurState, (state: ValeurState.IState) => state.isLoaded);

export const selectIsLoadingValeur = createSelector(selectValeurState, (state: ValeurState.IState) => state.isLoading);

export const selectIsReadyValeur = createSelector(selectValeurState, (state: ValeurState.IState) => !state.isLoading);

export const selectIsReadyAndLoadedValeur = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => state.isLoaded && !state.isLoading
);

export const selectValeursEntities = createSelector(selectValeurState, selectEntities);

export const selectValeursArray = createSelector(selectValeurState, selectAll);

export const selectIdValeursActive = createSelector(selectValeurState, (state: ValeurState.IState) => state.actives);

const valeursInObject = (valeurs: Dictionary<ValeurEntityState>) => ({ valeurs });

const selectValeursEntitiesDictionary = createSelector(selectValeursEntities, valeursInObject);

const selectAllValeursObject = createSelector(selectValeursEntities, valeurs => {
  return hydrateAll({ valeurs });
});

const selectOneValeurDictionary = (idValeur: number) =>
  createSelector(selectValeursEntities, valeurs => ({
    valeurs: { [idValeur]: valeurs[idValeur] }
  }));

const selectOneValeurDictionaryWithoutChild = (idValeur: number) =>
  createSelector(selectValeursEntities, valeurs => ({
    valeur: valeurs[idValeur]
  }));

const selectActiveValeursEntities = createSelector(
  selectIdValeursActive,
  selectValeursEntities,
  (actives: number[], valeurs: Dictionary<ValeurEntityState>) => getValeursFromActives(actives, valeurs)
);

function getValeursFromActives(
  actives: number[],
  valeurs: Dictionary<ValeurEntityState>
): Dictionary<ValeurEntityState> {
  return actives.reduce((acc, idActive) => {
    if (valeurs[idActive]) {
      acc[idActive] = valeurs[idActive];
    }
    return acc;
  }, {} as Dictionary<ValeurEntityState>);
}

const selectAllValeursSelectors: Dictionary<Selector> = {};
export function selectAllValeurs(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Valeur>(
      schema,
      selectAllValeursSelectors,
      selectValeursEntitiesDictionary,
      getRelationSelectors,
      valeurRelations,
      hydrateAll,
      'valeur'
    );
  } else {
    return selectAllValeursObject;
  }
}

export function selectAllValeursDictionary(schema: SelectSchema = {}, customKey: string = 'valeurs'): Selector {
  return createSelector(selectAllValeurs(schema), result => {
    const res = { [customKey]: {} as Dictionary<ValeurEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.valeurs.length; i++) {
      res[customKey][result.valeurs[i].idValeur] = result.valeurs[i];
    }
    return res;
  });
}

export function selectOneValeur(schema: SelectSchema = {}, idValeur: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneValeurDictionary(idValeur)];
    selectors.push(...getRelationSelectors(schema, valeurRelations, 'valeur'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneValeurDictionaryWithoutChild(idValeur);
  }
}

export function selectActiveValeurs(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveValeursEntities, valeurs => ({
      valeurs
    }))
  ];
  selectors.push(...getRelationSelectors(schema, valeurRelations, 'valeur'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  valeurs: Dictionary<ValeurEntityState>;
  communityDataRows?: Dictionary<CommunityDataRowEntityState>;
  organizationDataColumns?: Dictionary<OrganizationDataColumnEntityState>;
  organizationDataColumnValues?: Dictionary<OrganizationDataColumnValueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { valeurs: (Valeur | null)[] } {
  const { valeurs, communityDataRows, organizationDataColumns, organizationDataColumnValues } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    valeurs: Object.keys(valeurs).map(idValeur =>
      hydrate(
        valeurs[idValeur] as ValeurEntityState,
        communityDataRows,
        organizationDataColumns,
        organizationDataColumnValues
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { valeur: ValeurEntityState | null } {
  const { valeurs, communityDataRows, organizationDataColumns, organizationDataColumnValues } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const valeur = Object.values(valeurs)[0];
  return {
    valeur: hydrate(
      valeur as ValeurEntityState,
      communityDataRows,
      organizationDataColumns,
      organizationDataColumnValues
    )
  };
}

function hydrate(
  valeur: ValeurEntityState,
  communityDataRowEntities?: Dictionary<CommunityDataRowEntityState>,
  organizationDataColumnEntities?: Dictionary<OrganizationDataColumnEntityState>,
  organizationDataColumnValueEntities?: Dictionary<OrganizationDataColumnValueEntityState>
): Valeur | null {
  if (!valeur) {
    return null;
  }

  const valeurHydrated: ValeurEntityState = { ...valeur };
  if (communityDataRowEntities) {
    valeurHydrated.communityDataRow = communityDataRowEntities[valeur.communityDataRow as number] as CommunityDataRow;
  } else {
    delete valeurHydrated.communityDataRow;
  }
  if (organizationDataColumnEntities) {
    valeurHydrated.organizationDataColumn = organizationDataColumnEntities[
      valeur.organizationDataColumn as number
    ] as OrganizationDataColumn;
  } else {
    delete valeurHydrated.organizationDataColumn;
  }
  if (organizationDataColumnValueEntities) {
    valeurHydrated.organizationDataColumnValue = organizationDataColumnValueEntities[
      valeur.organizationDataColumnValue as number
    ] as OrganizationDataColumnValue;
  } else {
    delete valeurHydrated.organizationDataColumnValue;
  }

  return valeurHydrated as Valeur;
}
