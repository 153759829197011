import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationDataColumnValue, OrganizationDataColumnValueEntityState } from '@api/api-interfaces';
import { Valeur, ValeurEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicValue, OrganizationCaracteristicValueEntityState } from '@api/api-interfaces';
import { OrganizationDataColumn, OrganizationDataColumnEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationDataColumnValueState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationDataColumnValueRelations: string[] = [
  'valeurs',
  'organizationCaracteristicValues',
  'organizationDataColumns'
];

export const { selectEntities, selectAll } = OrganizationDataColumnValueState.adapter.getSelectors();

export const selectOrganizationDataColumnValueState = createFeatureSelector<OrganizationDataColumnValueState.IState>(
  OrganizationDataColumnValueState.organizationDataColumnValueFeatureKey
);

export const selectIsLoadedOrganizationDataColumnValue = createSelector(
  selectOrganizationDataColumnValueState,
  (state: OrganizationDataColumnValueState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationDataColumnValue = createSelector(
  selectOrganizationDataColumnValueState,
  (state: OrganizationDataColumnValueState.IState) => state.isLoading
);

export const selectIsReadyOrganizationDataColumnValue = createSelector(
  selectOrganizationDataColumnValueState,
  (state: OrganizationDataColumnValueState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationDataColumnValue = createSelector(
  selectOrganizationDataColumnValueState,
  (state: OrganizationDataColumnValueState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationDataColumnValuesEntities = createSelector(
  selectOrganizationDataColumnValueState,
  selectEntities
);

export const selectOrganizationDataColumnValuesArray = createSelector(
  selectOrganizationDataColumnValueState,
  selectAll
);

export const selectIdOrganizationDataColumnValuesActive = createSelector(
  selectOrganizationDataColumnValueState,
  (state: OrganizationDataColumnValueState.IState) => state.actives
);

const organizationDataColumnValuesInObject = (
  organizationDataColumnValues: Dictionary<OrganizationDataColumnValueEntityState>
) => ({ organizationDataColumnValues });

const selectOrganizationDataColumnValuesEntitiesDictionary = createSelector(
  selectOrganizationDataColumnValuesEntities,
  organizationDataColumnValuesInObject
);

const selectAllOrganizationDataColumnValuesObject = createSelector(
  selectOrganizationDataColumnValuesEntities,
  organizationDataColumnValues => {
    return hydrateAll({ organizationDataColumnValues });
  }
);

const selectOneOrganizationDataColumnValueDictionary = (idOrganizationDataColumnValue: number) =>
  createSelector(selectOrganizationDataColumnValuesEntities, organizationDataColumnValues => ({
    organizationDataColumnValues: {
      [idOrganizationDataColumnValue]: organizationDataColumnValues[idOrganizationDataColumnValue]
    }
  }));

const selectOneOrganizationDataColumnValueDictionaryWithoutChild = (idOrganizationDataColumnValue: number) =>
  createSelector(selectOrganizationDataColumnValuesEntities, organizationDataColumnValues => ({
    organizationDataColumnValue: organizationDataColumnValues[idOrganizationDataColumnValue]
  }));

const selectActiveOrganizationDataColumnValuesEntities = createSelector(
  selectIdOrganizationDataColumnValuesActive,
  selectOrganizationDataColumnValuesEntities,
  (actives: number[], organizationDataColumnValues: Dictionary<OrganizationDataColumnValueEntityState>) =>
    getOrganizationDataColumnValuesFromActives(actives, organizationDataColumnValues)
);

function getOrganizationDataColumnValuesFromActives(
  actives: number[],
  organizationDataColumnValues: Dictionary<OrganizationDataColumnValueEntityState>
): Dictionary<OrganizationDataColumnValueEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationDataColumnValues[idActive]) {
      acc[idActive] = organizationDataColumnValues[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationDataColumnValueEntityState>);
}

const selectAllOrganizationDataColumnValuesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationDataColumnValues(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationDataColumnValue>(
      schema,
      selectAllOrganizationDataColumnValuesSelectors,
      selectOrganizationDataColumnValuesEntitiesDictionary,
      getRelationSelectors,
      organizationDataColumnValueRelations,
      hydrateAll,
      'organizationDataColumnValue'
    );
  } else {
    return selectAllOrganizationDataColumnValuesObject;
  }
}

export function selectAllOrganizationDataColumnValuesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationDataColumnValues'
): Selector {
  return createSelector(selectAllOrganizationDataColumnValues(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationDataColumnValueEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationDataColumnValues.length; i++) {
      res[customKey][result.organizationDataColumnValues[i].idOrganizationDataColumnValue] =
        result.organizationDataColumnValues[i];
    }
    return res;
  });
}

export function selectOneOrganizationDataColumnValue(
  schema: SelectSchema = {},
  idOrganizationDataColumnValue: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationDataColumnValueDictionary(idOrganizationDataColumnValue)];
    selectors.push(
      ...getRelationSelectors(schema, organizationDataColumnValueRelations, 'organizationDataColumnValue')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationDataColumnValueDictionaryWithoutChild(idOrganizationDataColumnValue);
  }
}

export function selectActiveOrganizationDataColumnValues(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationDataColumnValuesEntities, organizationDataColumnValues => ({
      organizationDataColumnValues
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationDataColumnValueRelations, 'organizationDataColumnValue'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationDataColumnValues: Dictionary<OrganizationDataColumnValueEntityState>;
  organizationDataColumns?: Dictionary<OrganizationDataColumnEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  organizationCaracteristicValues?: Dictionary<OrganizationCaracteristicValueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationDataColumnValues: (OrganizationDataColumnValue | null)[];
} {
  const { organizationDataColumnValues, organizationDataColumns, valeurs, organizationCaracteristicValues } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationDataColumnValues: Object.keys(organizationDataColumnValues).map(idOrganizationDataColumnValue =>
      hydrate(
        organizationDataColumnValues[idOrganizationDataColumnValue] as OrganizationDataColumnValueEntityState,
        organizationDataColumns,
        valeurs,
        organizationCaracteristicValues
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationDataColumnValue: OrganizationDataColumnValueEntityState | null;
} {
  const { organizationDataColumnValues, organizationDataColumns, valeurs, organizationCaracteristicValues } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationDataColumnValue = Object.values(organizationDataColumnValues)[0];
  return {
    organizationDataColumnValue: hydrate(
      organizationDataColumnValue as OrganizationDataColumnValueEntityState,
      organizationDataColumns,
      valeurs,
      organizationCaracteristicValues
    )
  };
}

function hydrate(
  organizationDataColumnValue: OrganizationDataColumnValueEntityState,
  organizationDataColumnEntities?: Dictionary<OrganizationDataColumnEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>,
  organizationCaracteristicValueEntities?: Dictionary<OrganizationCaracteristicValueEntityState>
): OrganizationDataColumnValue | null {
  if (!organizationDataColumnValue) {
    return null;
  }

  const organizationDataColumnValueHydrated: OrganizationDataColumnValueEntityState = {
    ...organizationDataColumnValue
  };
  if (organizationDataColumnEntities) {
    organizationDataColumnValueHydrated.organizationDataColumn = organizationDataColumnEntities[
      organizationDataColumnValue.organizationDataColumn as number
    ] as OrganizationDataColumn;
  } else {
    delete organizationDataColumnValueHydrated.organizationDataColumn;
  }

  if (valeurEntities) {
    organizationDataColumnValueHydrated.valeurs = ((organizationDataColumnValueHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete organizationDataColumnValueHydrated.valeurs;
  }

  if (organizationCaracteristicValueEntities) {
    organizationDataColumnValueHydrated.organizationCaracteristicValues = (
      (organizationDataColumnValueHydrated.organizationCaracteristicValues as number[]) || []
    ).map(id => organizationCaracteristicValueEntities[id]) as OrganizationCaracteristicValue[];
  } else {
    delete organizationDataColumnValueHydrated.organizationCaracteristicValues;
  }

  return organizationDataColumnValueHydrated as OrganizationDataColumnValue;
}
