import { RepositoryService } from '@wip/services/repository';
import { ChildrenElement } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedChildrenElementApiService {
  constructor(protected repo: RepositoryService) {}

  public getChildrenElements(params?: any): Observable<ChildrenElement[]> {
    return this.repo.getData<ChildrenElement[]>('children-element', params);
  }

  public getChildrenElement(params: { idChildrenElement: number; params?: any }): Observable<ChildrenElement> {
    return this.repo.getData<ChildrenElement>('children-element/' + params.idChildrenElement, params.params);
  }

  public addChildrenElement(childrenElement: Partial<ChildrenElement>): Observable<ChildrenElement> {
    return this.repo.create<ChildrenElement>('children-element', childrenElement);
  }

  public updateChildrenElement(childrenElement: Partial<ChildrenElement>): Observable<ChildrenElement> {
    return this.repo.update('children-element', childrenElement);
  }

  public deleteChildrenElement(idChildrenElement: number): Observable<number> {
    return this.repo.delete('children-element/' + +idChildrenElement);
  }
}
