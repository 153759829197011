/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { LoginResolverRes } from '../login-resolver-res.interface';
import { StorageService } from '../storage.service';
import { Location } from '@angular/common';
import { parseJwt } from '@utils';
import { AuthService } from '../auth.service';
import { UserService } from '@wip/store/services';
import { Observable } from 'rxjs';
import { User } from '@api/api-interfaces';

@Injectable({ providedIn: 'root' })
export class LoginPageResolver implements Resolve<LoginResolverRes | null> {
  constructor(
    private router: Router,
    private location: Location,
    private storageService: StorageService,
    private authService: AuthService,
    private userService: UserService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): LoginResolverRes | null {
    const missingUserLoggedIn: boolean = route.queryParamMap.get('missingUserLoggedIn') === 'true';
    if (missingUserLoggedIn) {
      this.removeQueryParamsWithoutReloadingPage(this.location, this.router);
      return { missingUserLoggedIn };
    }

    const token: string = route.queryParamMap.get('token');

    if (token?.length && token.split('.').length > 1) {
      this.storageService.clearAll();
      this.storageService.changeStorage(true);
      this.storageService.setStorage('login', { token });
      this.authService.getLoginStorage();
      const { idUser } = parseJwt(token);
      (this.userService.getManyUsers({ idUser }, true) as Observable<User[]>).subscribe(() => {
        this.router.navigate(['/app']);
      });
    }

    return null;
  }

  private removeQueryParamsWithoutReloadingPage(location, router): void {
    setTimeout(() => {
      location.go(router.url.split('?')[0]);
    }, 0);
  }
}
