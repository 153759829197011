<mat-form-field class="field">
  @if (placeholder) {
    <mat-label>{{ placeholder }}</mat-label>
  }
  <input
    #input
    matInput
    type="date"
    [attr.data-cy]="dataCy"
    [autocomplete]="autocomplete"
    [disabled]="isDisabled"
    [value]="valueAsString"
    (change)="onSelectionChange($event)"
    (keypress)="onKeypress($event)"
  />
  @if (hint) {
    <mat-hint>{{ hint }}</mat-hint>
  }
  @if (!isDisabled && value && !disabledCross) {
    <i matTextSuffix class="icon-ic_fluent_dismiss_24_regular remove-search" (click)="onClear()"></i>
  }
  @if (isOverMode && value) {
    <span matTextSuffix>
      <i
        class="icon-ic_fluent_circle_24_filled"
        style="vertical-align: middle; margin-left: 8px"
        [ngStyle]="{ color: isOver ? '#579da0' : 'orange' }"
      ></i>
    </span>
  }
</mat-form-field>
