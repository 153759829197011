import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ElementCash, ElementCashEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { ElementCashState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const elementCashRelations: string[] = ['elements'];

export const { selectEntities, selectAll } = ElementCashState.adapter.getSelectors();

export const selectElementCashState = createFeatureSelector<ElementCashState.IState>(
  ElementCashState.elementCashFeatureKey
);

export const selectIsLoadedElementCash = createSelector(
  selectElementCashState,
  (state: ElementCashState.IState) => state.isLoaded
);

export const selectIsLoadingElementCash = createSelector(
  selectElementCashState,
  (state: ElementCashState.IState) => state.isLoading
);

export const selectIsReadyElementCash = createSelector(
  selectElementCashState,
  (state: ElementCashState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedElementCash = createSelector(
  selectElementCashState,
  (state: ElementCashState.IState) => state.isLoaded && !state.isLoading
);

export const selectElementCashsEntities = createSelector(selectElementCashState, selectEntities);

export const selectElementCashsArray = createSelector(selectElementCashState, selectAll);

export const selectIdElementCashsActive = createSelector(
  selectElementCashState,
  (state: ElementCashState.IState) => state.actives
);

const elementCashsInObject = (elementCashs: Dictionary<ElementCashEntityState>) => ({ elementCashs });

const selectElementCashsEntitiesDictionary = createSelector(selectElementCashsEntities, elementCashsInObject);

const selectAllElementCashsObject = createSelector(selectElementCashsEntities, elementCashs => {
  return hydrateAll({ elementCashs });
});

const selectOneElementCashDictionary = (idElementCash: number) =>
  createSelector(selectElementCashsEntities, elementCashs => ({
    elementCashs: { [idElementCash]: elementCashs[idElementCash] }
  }));

const selectOneElementCashDictionaryWithoutChild = (idElementCash: number) =>
  createSelector(selectElementCashsEntities, elementCashs => ({
    elementCash: elementCashs[idElementCash]
  }));

const selectActiveElementCashsEntities = createSelector(
  selectIdElementCashsActive,
  selectElementCashsEntities,
  (actives: number[], elementCashs: Dictionary<ElementCashEntityState>) =>
    getElementCashsFromActives(actives, elementCashs)
);

function getElementCashsFromActives(
  actives: number[],
  elementCashs: Dictionary<ElementCashEntityState>
): Dictionary<ElementCashEntityState> {
  return actives.reduce((acc, idActive) => {
    if (elementCashs[idActive]) {
      acc[idActive] = elementCashs[idActive];
    }
    return acc;
  }, {} as Dictionary<ElementCashEntityState>);
}

const selectAllElementCashsSelectors: Dictionary<Selector> = {};
export function selectAllElementCashs(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ElementCash>(
      schema,
      selectAllElementCashsSelectors,
      selectElementCashsEntitiesDictionary,
      getRelationSelectors,
      elementCashRelations,
      hydrateAll,
      'elementCash'
    );
  } else {
    return selectAllElementCashsObject;
  }
}

export function selectAllElementCashsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'elementCashs'
): Selector {
  return createSelector(selectAllElementCashs(schema), result => {
    const res = { [customKey]: {} as Dictionary<ElementCashEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.elementCashs.length; i++) {
      res[customKey][result.elementCashs[i].idElementCash] = result.elementCashs[i];
    }
    return res;
  });
}

export function selectOneElementCash(schema: SelectSchema = {}, idElementCash: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneElementCashDictionary(idElementCash)];
    selectors.push(...getRelationSelectors(schema, elementCashRelations, 'elementCash'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneElementCashDictionaryWithoutChild(idElementCash);
  }
}

export function selectActiveElementCashs(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveElementCashsEntities, elementCashs => ({
      elementCashs
    }))
  ];
  selectors.push(...getRelationSelectors(schema, elementCashRelations, 'elementCash'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  elementCashs: Dictionary<ElementCashEntityState>;
  elements?: Dictionary<ElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { elementCashs: (ElementCash | null)[] } {
  const { elementCashs, elements } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    elementCashs: Object.keys(elementCashs).map(idElementCash =>
      hydrate(elementCashs[idElementCash] as ElementCashEntityState, elements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { elementCash: ElementCashEntityState | null } {
  const { elementCashs, elements } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const elementCash = Object.values(elementCashs)[0];
  return {
    elementCash: hydrate(elementCash as ElementCashEntityState, elements)
  };
}

function hydrate(
  elementCash: ElementCashEntityState,
  elementEntities?: Dictionary<ElementEntityState>
): ElementCash | null {
  if (!elementCash) {
    return null;
  }

  const elementCashHydrated: ElementCashEntityState = { ...elementCash };
  if (elementEntities) {
    elementCashHydrated.element = elementEntities[elementCash.element as number] as Element;
  } else {
    delete elementCashHydrated.element;
  }

  return elementCashHydrated as ElementCash;
}
