import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityMilestoneFamilyState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityMilestoneFamilyEntityState } from '@api/api-interfaces';

export const communityMilestoneFamilyReducersGeneratedFunctions: ReducerTypes<
  CommunityMilestoneFamilyState.IState,
  readonly ActionCreator[]
>[] = [
  on(
    CommunityMilestoneFamilyGeneratedActions.getOneCommunityMilestoneFamily,
    (state: CommunityMilestoneFamilyState.IState) => setLoadingsState(state, true)
  ),
  on(
    CommunityMilestoneFamilyGeneratedActions.getManyCommunityMilestoneFamilys,
    (state: CommunityMilestoneFamilyState.IState) => setLoadingsState(state, true)
  ),
  on(
    CommunityMilestoneFamilyGeneratedActions.upsertOneCommunityMilestoneFamily,
    (state: CommunityMilestoneFamilyState.IState) => setLoadingsState(state, true)
  ),

  on(
    CommunityMilestoneFamilyGeneratedActions.upsertManyCommunityMilestoneFamilysSuccess,
    (state: CommunityMilestoneFamilyState.IState, { communityMilestoneFamilys }) =>
      CommunityMilestoneFamilyState.adapter.upsertMany(communityMilestoneFamilys, setLoadingsState(state, false))
  ),
  on(
    CommunityMilestoneFamilyGeneratedActions.deleteOneCommunityMilestoneFamily,
    (state: CommunityMilestoneFamilyState.IState) => setLoadingsState(state, true)
  ),
  on(
    CommunityMilestoneFamilyGeneratedActions.deleteOneCommunityMilestoneFamilySuccess,
    (state: CommunityMilestoneFamilyState.IState, { idCommunityMilestoneFamily }) =>
      CommunityMilestoneFamilyState.adapter.removeOne(idCommunityMilestoneFamily, setLoadingsState(state, false))
  ),
  on(
    CommunityMilestoneFamilyGeneratedActions.clearActivesCommunityMilestoneFamilys,
    (state: CommunityMilestoneFamilyState.IState) => ({ ...state, actives: [] })
  ),
  on(
    CommunityMilestoneFamilyGeneratedActions.addManyActivesCommunityMilestoneFamilys,
    (state: CommunityMilestoneFamilyState.IState, { idCommunityMilestoneFamilys }) => ({
      ...state,
      actives: state.actives.concat(idCommunityMilestoneFamilys)
    })
  ),
  on(
    CommunityMilestoneFamilyGeneratedActions.deleteOneActiveCommunityMilestoneFamily,
    (state: CommunityMilestoneFamilyState.IState, { idCommunityMilestoneFamily }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityMilestoneFamily)
    })
  ),

  on(
    CommunityMilestoneFamilyGeneratedActions.clearCommunityMilestoneFamilys,
    () => CommunityMilestoneFamilyState.initialState
  ),

  on(
    CommunityMilestoneFamilyGeneratedActions.addOrganizationMilestoneFamilySuccess,
    (state: CommunityMilestoneFamilyState.IState, { idCommunityMilestoneFamily, idOrganizationMilestoneFamily }) => {
      if (!state.entities[idCommunityMilestoneFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityMilestoneFamily]: {
            ...state.entities[idCommunityMilestoneFamily],
            organizationMilestoneFamily: idOrganizationMilestoneFamily
          }
        }
      };
    }
  ),

  on(
    CommunityMilestoneFamilyGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess,
    (state: CommunityMilestoneFamilyState.IState, { idOrganizationMilestoneFamilys, idCommunityMilestoneFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityMilestoneFamilys.reduce((entities, idCommunityMilestoneFamily) => {
            if (!state.entities[idCommunityMilestoneFamily]?.organizationMilestoneFamily) {
              return entities;
            }
            entities[idCommunityMilestoneFamily] = {
              ...state.entities[idCommunityMilestoneFamily],
              organizationMilestoneFamily: idOrganizationMilestoneFamilys.some(
                (idOrganizationMilestoneFamily: number) =>
                  idOrganizationMilestoneFamily ===
                  state.entities[idCommunityMilestoneFamily]?.organizationMilestoneFamily
              )
                ? undefined
                : state.entities[idCommunityMilestoneFamily]?.organizationMilestoneFamily
            } as CommunityMilestoneFamilyEntityState;
            return entities;
          }, {} as Dictionary<CommunityMilestoneFamilyEntityState>)
        }
      };
    }
  ),

  on(
    CommunityMilestoneFamilyGeneratedActions.addCommunitySuccess,
    (state: CommunityMilestoneFamilyState.IState, { idCommunityMilestoneFamily, idCommunity }) => {
      if (!state.entities[idCommunityMilestoneFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityMilestoneFamily]: {
            ...state.entities[idCommunityMilestoneFamily],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityMilestoneFamilyGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityMilestoneFamilyState.IState, { idCommunities, idCommunityMilestoneFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityMilestoneFamilys.reduce((entities, idCommunityMilestoneFamily) => {
            if (!state.entities[idCommunityMilestoneFamily]?.community) {
              return entities;
            }
            entities[idCommunityMilestoneFamily] = {
              ...state.entities[idCommunityMilestoneFamily],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityMilestoneFamily]?.community
              )
                ? undefined
                : state.entities[idCommunityMilestoneFamily]?.community
            } as CommunityMilestoneFamilyEntityState;
            return entities;
          }, {} as Dictionary<CommunityMilestoneFamilyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityMilestoneFamilyState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityMilestoneFamilyState.IState {
  return { ...state, isLoaded, isLoading };
}
