import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationAzureGeneratedActions } from '@wip/store/actions';
import { OrganizationAzureState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationAzureEntityState } from '@api/api-interfaces';

export const organizationAzureReducersGeneratedFunctions: ReducerTypes<
  OrganizationAzureState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationAzureGeneratedActions.getOneOrganizationAzure, (state: OrganizationAzureState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationAzureGeneratedActions.getManyOrganizationAzures, (state: OrganizationAzureState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationAzureGeneratedActions.upsertOneOrganizationAzure, (state: OrganizationAzureState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationAzureGeneratedActions.upsertManyOrganizationAzuresSuccess,
    (state: OrganizationAzureState.IState, { organizationAzures }) =>
      OrganizationAzureState.adapter.upsertMany(organizationAzures, setLoadingsState(state, false))
  ),
  on(OrganizationAzureGeneratedActions.deleteOneOrganizationAzure, (state: OrganizationAzureState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationAzureGeneratedActions.deleteOneOrganizationAzureSuccess,
    (state: OrganizationAzureState.IState, { idOrganizationAzure }) =>
      OrganizationAzureState.adapter.removeOne(idOrganizationAzure, setLoadingsState(state, false))
  ),
  on(OrganizationAzureGeneratedActions.clearActivesOrganizationAzures, (state: OrganizationAzureState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationAzureGeneratedActions.addManyActivesOrganizationAzures,
    (state: OrganizationAzureState.IState, { idOrganizationAzures }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationAzures)
    })
  ),
  on(
    OrganizationAzureGeneratedActions.deleteOneActiveOrganizationAzure,
    (state: OrganizationAzureState.IState, { idOrganizationAzure }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationAzure)
    })
  ),

  on(OrganizationAzureGeneratedActions.clearOrganizationAzures, () => OrganizationAzureState.initialState),

  on(
    OrganizationAzureGeneratedActions.addOrganizationSuccess,
    (state: OrganizationAzureState.IState, { idOrganizationAzure, idOrganization }) => {
      if (!state.entities[idOrganizationAzure]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationAzure]: {
            ...state.entities[idOrganizationAzure],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationAzureGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationAzureState.IState, { idOrganizations, idOrganizationAzures }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationAzures.reduce((entities, idOrganizationAzure) => {
            if (!state.entities[idOrganizationAzure]?.organization) {
              return entities;
            }
            entities[idOrganizationAzure] = {
              ...state.entities[idOrganizationAzure],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationAzure]?.organization
              )
                ? undefined
                : state.entities[idOrganizationAzure]?.organization
            } as OrganizationAzureEntityState;
            return entities;
          }, {} as Dictionary<OrganizationAzureEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationAzureGeneratedActions.addGroupSuccess,
    (state: OrganizationAzureState.IState, { idOrganizationAzure, idGroup }) => {
      if (!state.entities[idOrganizationAzure]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationAzure]: {
            ...state.entities[idOrganizationAzure],
            group: idGroup
          }
        }
      };
    }
  ),

  on(
    OrganizationAzureGeneratedActions.deleteManyGroupSuccess,
    (state: OrganizationAzureState.IState, { idGroups, idOrganizationAzures }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationAzures.reduce((entities, idOrganizationAzure) => {
            if (!state.entities[idOrganizationAzure]?.group) {
              return entities;
            }
            entities[idOrganizationAzure] = {
              ...state.entities[idOrganizationAzure],
              group: idGroups.some((idGroup: number) => idGroup === state.entities[idOrganizationAzure]?.group)
                ? undefined
                : state.entities[idOrganizationAzure]?.group
            } as OrganizationAzureEntityState;
            return entities;
          }, {} as Dictionary<OrganizationAzureEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationAzureState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationAzureState.IState {
  return { ...state, isLoaded, isLoading };
}
