import { IGroupMetaUsersSuggested } from '@_app/shared/interfaces/MetaUserGroup.interface';
import { SearchUsersDialogComponent } from '@_app/shared/search-users/search-users-dialog/search-users-dialog.component';
import { ColDef, ColGroupDef, GridApi } from '@ag-grid-community/core';
import { Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Community, CommunityUserProfil, OrganizationUser, OrganizationUserProfil, User } from '@api/api-interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommunityUserProfilService, OrganizationUserService } from '@wip/store/services';
import { ReplaySubject, Subject, debounceTime, distinctUntilChanged, tap } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ProjectGridResponsiblesService implements OnInit {
  constructor(
    private readonly organizationUserService: OrganizationUserService,
    private readonly communityUserProfilService: CommunityUserProfilService,
    private readonly dialog: MatDialog
  ) {}

  private editMode: boolean;
  private valuesSubject = new Subject<Partial<CommunityUserProfil>[]>();

  public valuesToUpdate: Partial<CommunityUserProfil>[];
  public listProjects: Community[];
  public listOrganizationUsers: OrganizationUser[];
  public organizationUser: OrganizationUser;
  public gridApi: GridApi;

  ngOnInit(): void {
    this.organizationUserService.editMode$.pipe(untilDestroyed(this)).subscribe(val => (this.editMode = val));

    this.valuesSubject
      .pipe(
        untilDestroyed(this),
        debounceTime(200),
        distinctUntilChanged(),
        tap(values => {
          this.communityUserProfilService.fillHandleUserProfils(values);
          this.valuesToUpdate = [];
        })
      )
      .subscribe();
  }

  public createResponsibleColumnDefsFormat(
    organizationUserProfils: OrganizationUserProfil[],
    idOrganization: number,
    idOrganizationFamily: number,
    openResps: boolean
  ): ColGroupDef {
    const responsiblesForOrganization = organizationUserProfils.filter(
      orgaUser => orgaUser.idOrganization === idOrganization && orgaUser.idOrganizationFamily === idOrganizationFamily
    );

    const responsiblesColDefs: ColDef[] = this.getOpenAndCloseColDefResps(responsiblesForOrganization, idOrganization);

    const responsiblesColumnsGroupDefsDefault: ColGroupDef = {
      headerName: 'Responsables',
      children: responsiblesColDefs,
      columnGroupShow: 'closed',
      openByDefault: openResps,
      groupId: 'resps'
    };

    return responsiblesColumnsGroupDefsDefault;
  }

  private getOpenAndCloseColDefResps(
    orgaUserProfils: OrganizationUserProfil[],
    idOrganization: number,
    handleChange: ReplaySubject<void> = null
  ): ColDef[] {
    return orgaUserProfils
      .sort((a, b) => a.ordre - b.ordre)
      .map((orgaUserProfil: OrganizationUserProfil): ColDef => {
        if (orgaUserProfil.idOrganization === idOrganization) {
          return {
            headerName: orgaUserProfil.libelle,
            enableRowGroup: true,
            cellStyle: { backgroundColor: this.editMode ? '#ceeaff' : '' },
            field: orgaUserProfil.idOrganizationUserProfil.toString(),
            columnGroupShow: orgaUserProfil.shouldDisplayInDashboard ? undefined : 'open',
            onCellDoubleClicked: this.editMode ? this.onCellClicked.bind(this) : null,
            width: 100,
            editable: this.editMode,
            resizable: true,
            valueGetter: this.getValue,
            valueSetter: this.setValue,
            filterParams: {
              valueFormatter: params => {
                if (params && params.value) {
                  return JSON.parse(params.value).displayName;
                }
                return '';
              }
            },
            valueFormatter: params => {
              if (params && params.value) {
                return JSON.parse(params.value)?.displayName;
              }
              return '';
            },
            onCellValueChanged: params => {
              this.valuesToUpdate.push({
                idCommunityUserProfil:
                  params.data[orgaUserProfil.idOrganizationUserProfil.toString()].idCommunityUserProfil,
                idOrganizationUserProfil: orgaUserProfil.idOrganizationUserProfil,
                idUser: JSON.parse(params.newValue)?.idUser,
                idCommunity: params.node.data.idCommunity
              });
              this.valuesSubject.next(this.valuesToUpdate);
              handleChange.next();
            }
          };
        }
      });
  }

  private getValue(params) {
    return params.data ? params.data[params.column.colId]?.value : null;
  }

  private setValue(params) {
    params.data[params.column.colId] = { ...params.data[params.column.colId], value: params.newValue };
    return true;
  }

  public onCellClicked(event) {
    const community = this.listProjects.find(c => c.idCommunity === event.data.idCommunity);
    const userProfils = community.communityUserProfils.filter(cup => cup.idUser);
    const communityUsers = this.listOrganizationUsers;

    const searchUsersListAdd = [];

    const searchUsersListRmv = [];

    const suggestions: IGroupMetaUsersSuggested[] = [
      {
        label: 'Vous',
        users: communityUsers.filter(cu => cu.idUser === this.organizationUser.idUser),
        checked: false,
        request: false
      },
      {
        users: userProfils,
        request: true,
        label: "Membres de l'équipe projet",
        checked: false
      },
      {
        users: communityUsers,
        request: true,
        label: 'Autres utilisateurs',
        checked: false
      }
    ];
    const dialogRef: MatDialogRef<SearchUsersDialogComponent> = this.dialog.open(SearchUsersDialogComponent, {
      data: {
        idCommunity: event.data.idCommunity,
        groupMetaUsersSuggested: suggestions,
        metaUsersSelected: [],
        add: searchUsersListAdd,
        rmv: searchUsersListRmv,
        displayList: 'list',
        maxChips: 1,
        selecteOnlyOnce: true,
        canAddName: true,
        textPlaceholder: 'Ajouter un responsable'
      },
      width: '500px',
      height: '700px',
      panelClass: ['dialog-content-no-paddings', 'custom-dialog-fullscreen-xs']
    });

    dialogRef.afterClosed().subscribe(data => {
      let newResp: User;
      if (data?.listUserSelected?.length) {
        newResp = data.listUserSelected[0].user;
        this.communityUserProfilService.fillHandleUserProfils([
          { idUser: newResp.idUser, idOrganizationUserProfil: +event.column.colId, idCommunity: community.idCommunity }
        ]);
        this.gridApi.getRowNode(event.node.id).setDataValue(
          event.column.colId,
          JSON.stringify({
            idUser: newResp.idUser,
            displayName: newResp.prenom + ' ' + newResp.nom
          })
        );
      }
    });
  }
}
