import { RepositoryService } from '@wip/services/repository';
import { Community } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunities(params?: any): Observable<Community[]> {
    return this.repo.getData<Community[]>('community', params);
  }

  public getCommunity(params: { idCommunity: number; params?: any }): Observable<Community> {
    return this.repo.getData<Community>('community/' + params.idCommunity, params.params);
  }

  public addCommunity(community: Partial<Community>): Observable<Community> {
    return this.repo.create<Community>('community', community);
  }

  public updateCommunity(community: Partial<Community>): Observable<Community> {
    return this.repo.update('community', community);
  }

  public deleteCommunity(idCommunity: number): Observable<number> {
    return this.repo.delete('community/' + +idCommunity);
  }
}
