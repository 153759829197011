import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationFamily, ReorderInterface } from '@api/api-interfaces';
import * as OrganizationFamilyAction from '@wip/store/actions';
import { OrganizationFamilyApiService } from '@wip/store/api-services';
import * as AppState from '@wip/store/configs/reducers';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Observable } from 'rxjs';
import { GeneratedOrganizationFamilyService } from './organization-family-generated.service';
import { IChartData } from '@api/projects/get-analyse-projects';

@Injectable({
  providedIn: 'root'
})
export class OrganizationFamilyService extends GeneratedOrganizationFamilyService {
  public currentIdOrganizationFamily: number = 0;

  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private readonly organizationFamilyApiService: OrganizationFamilyApiService
  ) {
    super(store$, actions$);
  }

  public setCurrentId(id: number) {
    this.currentIdOrganizationFamily = id;
  }

  public reorder(params: ReorderInterface, getResult: boolean = false): void | Observable<OrganizationFamily> {
    this.store$.dispatch(OrganizationFamilyAction.reorderFamily({ value: params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationFamilyAction.OrganizationFamilyGeneratedActions.deleteOneOrganizationFamilySuccess,
        OrganizationFamilyAction.OrganizationFamilyGeneratedActions.organizationFamilysFailure
      );
    }
  }

  public getChartDatas(datas: IChartData) {
    return this.organizationFamilyApiService.getChartDatas(datas);
  }
}
