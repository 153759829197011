import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationAzure, OrganizationAzureEntityState } from '@api/api-interfaces';
import { OrganizationAzureRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationAzureGeneratedActions = createActionGroup({
  source: 'Organization Azure Generated',
  events: {
    'Get One Organization Azure': props<{ idOrganizationAzure: number; params?: any }>(),
    'Get Many Organization Azures': props<{ params: any }>(),
    'Add Many Actives Organization Azures': props<{ idOrganizationAzures: number[] }>(),
    'Delete One Active Organization Azure': props<{ idOrganizationAzure: number }>(),
    'Clear Actives Organization Azures': emptyProps(),
    'Upsert One Organization Azure': props<{
      organizationAzure: Partial<OrganizationAzure>;
      ids?: OrganizationAzureRelationsIds;
    }>(),
    'Upsert Many Organization Azures': props<{
      organizationAzures: Partial<OrganizationAzure>[];
      ids?: OrganizationAzureRelationsIds;
    }>(),
    'Upsert Many Organization Azures Success': props<{ organizationAzures: OrganizationAzureEntityState[] }>(),
    'Delete One Organization Azure': props<{ idOrganizationAzure: number }>(),
    'Delete One Organization Azure Success': props<{ idOrganizationAzure: number }>(),
    'Normalize Many Organization Azures After Upsert': props<{ organizationAzures: OrganizationAzureEntityState[] }>(),
    'Organization Azures Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Azures': emptyProps(),
    'Add Organization Success': props<{ idOrganizationAzure: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationAzures: number[] }>(),
    'Add Group Success': props<{ idOrganizationAzure: number; idGroup: number }>(),
    'Delete Many Group Success': props<{ idGroups: number[]; idOrganizationAzures: number[] }>()
  }
});
