import { SharedModule } from '@_app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  Community,
  Organization,
  OrganizationCaracteristic,
  OrganizationFamily,
  OrganizationMilestone,
  OrganizationMilestoneFamily,
  OrganizationSubFamily,
  OrganizationUser,
  UserBoardState
} from '@api/api-interfaces';
import { BoardTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, tap } from 'rxjs';
import { BoardConfigurationChoiceComponent } from './board-configuration-choice/board-configuration-choice.component';
import { LibrariesParentsChoicesComponent } from './libraries-parents-choices/libraries-parents-choices.component';
import { ProjectFamiliesChoicesComponent } from './project-families-choices/project-families-choices.component';
import { ProjectSubFamiliesChoicesComponent } from './project-sub-families-choices/project-sub-families-choices.component';
import { ResponsibleChoiceComponent } from './responsible-choice/responsible-choice.component';
import { TotalMilestoneComponent } from './total-milestone-choices/total-milestone-choices.component';
import { ChartInputComponent } from '../features/charts/chart-input/chart-input.component';
import { OrganizationMilestoneFamilyService } from '@wip/store/services';

interface IFilters {
  subFamilyRule: FormControl<any>;
  searchString: FormControl<any>;
  organizationFamily: FormControl<any>;
  organizationMilestoneFamily: FormControl<any>;
  milestone: FormControl<any>;
  caracteristic: FormControl<any>;
  responsible: FormControl<any>;
  isResponsibleOnlyPilot: FormControl<any>;
  displayRepartition: FormControl<any>;
  configuration: FormControl<any>;
}
@UntilDestroy()
@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.scss']
})
export class DashboardFiltersComponent implements OnInit, OnChanges {
  @Input() organization: Organization;
  @Input() communities: Community[];
  @Input() organizationUser: OrganizationUser;
  @Input() moduleIndex: number;
  @Input() configurationBoardStates: UserBoardState[];
  @Input() projectMilestoneFamilies: OrganizationMilestoneFamily[];
  @Input() projectMilestones: OrganizationMilestone[];
  @Input() projectCharacteristics: OrganizationCaracteristic[];
  @Input() listHashtags: OrganizationSubFamily[];
  @Input() updateButton: boolean;
  @Input() chartInputsForm: FormGroup;

  @Output() boardStateEmitter = new EventEmitter<any>();
  @Output() configEmitter = new EventEmitter<any>();
  @Output() organizationFamilyEmitter = new EventEmitter<number>();
  @Output() organizationMilestoneFamilyEmitter = new EventEmitter<number>();

  public filtersGroup: FormGroup<IFilters>;
  public subFamilyControl: FormControl;
  public subFamilies: OrganizationSubFamily[];
  public formGroupLoaded: boolean = false;
  public filters;
  public showHashtags: boolean = false;
  public idUserBoardState: number;
  public generalBoardState: UserBoardState;
  public organizationFamilySelected: OrganizationFamily;
  public organizationMilestoneFamilySelected: OrganizationMilestoneFamily;
  public displayOnlyPilotToggle: boolean = false;

  private filterStatesForSynthesis = [];
  private currentIdOrganizationFamily: number = 0;

  constructor(
    private fb: FormBuilder,
    private readonly organizationMilestoneFamilyService: OrganizationMilestoneFamilyService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes) {
    this.displayOnlyPilotToggle = this.moduleIndex === 1;

    if (this.organization && this.organizationUser && this.configurationBoardStates) {
      this.generalBoardState = this.configurationBoardStates.find(
        bs => bs.type === BoardTypeEnum.project && bs.idOrganizationUser === this.organizationUser.idOrganizationUser
      );
      if (this.generalBoardState) {
        this.idUserBoardState = this.generalBoardState?.idUserBoardState;
        const newFilterStatesForSynthesis = this.configurationBoardStates.filter(
          bs =>
            bs.type === BoardTypeEnum.synthesis && bs.idOrganizationUser === this.organizationUser.idOrganizationUser
        );
        if (newFilterStatesForSynthesis !== this.filterStatesForSynthesis) {
          this.formGroupLoaded = false;
        }
        this.filterStatesForSynthesis = newFilterStatesForSynthesis;
        this.filters = JSON.parse(this.generalBoardState?.filterState);
        this.organizationFamilySelected = this.organization.organizationFamilys.find(
          orgaFamily => orgaFamily.idOrganizationFamily === this.filters.organizationFamily
        );
        this.subFamilies = this.organizationFamilySelected?.organizationSubFamilys;

        if (this.filters.organizationMilestoneFamily) {
          this.organizationMilestoneFamilySelected = this.organizationFamilySelected?.organizationMilestoneFamilys.find(
            milestoneFamily =>
              milestoneFamily.idOrganizationMilestoneFamily ===
              this.filters.organizationMilestoneFamily[this.organizationFamilySelected?.idOrganizationFamily]
          );
        }
        if (
          this.organizationFamilySelected &&
          this.organizationFamilySelected.idOrganizationFamily !== this.currentIdOrganizationFamily
        ) {
          this.currentIdOrganizationFamily = this.organizationFamilySelected.idOrganizationFamily;
          this.organizationFamilyEmitter.emit(this.organizationFamilySelected.idOrganizationFamily);
        }

        if (!this.formGroupLoaded) {
          this.filtersGroup = this.fb.group({
            subFamilyRule: this.filters.subFamilyRule,
            searchString: this.filters.searchString,
            organizationFamily: this.filters.organizationFamily,
            organizationMilestoneFamily: this.filters.organizationMilestoneFamily
              ? this.filters.organizationMilestoneFamily[this.organizationFamilySelected?.idOrganizationFamily]
              : null,
            milestone: this.filters.organizationMilestone
              ? this.filters.organizationMilestone[
                  this.organizationMilestoneFamilySelected?.idOrganizationMilestoneFamily
                ]
              : null,
            caracteristic: this.filters.organizationCaracteristic
              ? this.filters.organizationCaracteristic[this.organizationMilestoneFamilySelected?.idOrganizationFamily]
              : null,
            responsible: this.filters.responsible,
            isResponsibleOnlyPilot: this.filters.isResponsibleOnlyPilot,
            displayRepartition: this.filters.displayRepartition,
            configuration: this.filters.configuration
              ? this.filters.configuration[this.organizationFamilySelected?.idOrganizationFamily]
              : null
          });
          this.subFamilyControl = new FormControl(
            this.organizationFamilySelected && this.filters.organizationSubFamilies
              ? (this.filters.organizationSubFamilies[this.organizationFamilySelected?.idOrganizationFamily] ?? [])
              : []
          );
          this.formGroupLoaded = true;

          this.onFiltersGroupChange();
        }
      } else {
        this.boardStateEmitter.emit('reload');
      }
    }
    if (changes.projectCharacteristics && this.projectCharacteristics?.length) {
      this.chartInputsForm.get('projectCharacteristics').setValue(this.projectCharacteristics[0]);
    }
  }

  public onChangeValueField(formControlName: string) {
    this.filtersGroup
      .get(formControlName)
      .valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(300),
        tap(value => {
          this.filters[formControlName] = value;
          this.emitBoardState();
        })
      )
      .subscribe();
  }

  public onConfiguration($event) {
    this.configEmitter.emit($event);
  }

  public emitBoardState() {
    this.boardStateEmitter.emit({
      idUserBoardState: this.idUserBoardState,
      filterState: JSON.stringify(this.filters)
    });
  }

  public onChangeValueFieldOrganizationMilestoneFamily() {
    this.filtersGroup
      .get('organizationMilestoneFamily')
      .valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(300),
        tap(value => {
          if (!this.filters.organizationMilestoneFamily) {
            this.filters.organizationMilestoneFamily = {};
          }

          this.filters.organizationMilestoneFamily[this.organizationFamilySelected.idOrganizationFamily] = value;
          this.organizationMilestoneFamilySelected = this.organizationFamilySelected.organizationMilestoneFamilys.find(
            orgaFamily => orgaFamily.idOrganizationFamily === this.filters.organizationFamily
          );

          if (this.filters.organizationMilestone && this.organizationMilestoneFamilySelected) {
            this.filtersGroup
              .get('milestone')
              .patchValue(
                this.filters.organizationMilestone[
                  this.organizationMilestoneFamilySelected.idOrganizationMilestoneFamily
                ],
                { emitEvent: false }
              );
          }
          this.emitBoardState();
        })
      )
      .subscribe();
  }

  public onChangeValueFieldMilestone() {
    this.filtersGroup
      .get('milestone')
      .valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(300),
        tap(value => {
          if (!this.filters.organizationMilestone) {
            this.filters.organizationMilestone = {};
          }
          this.filters.organizationMilestone[this.organizationMilestoneFamilySelected.idOrganizationMilestoneFamily] =
            value;
          this.emitBoardState();
        })
      )
      .subscribe();
  }

  public onChangeValueFieldOrganizationFamily() {
    this.filtersGroup
      .get('organizationFamily')
      .valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(300),
        tap(value => {
          this.filters.organizationFamily = value;

          this.organizationFamilySelected = this.organization.organizationFamilys.find(
            orgaFamily => orgaFamily.idOrganizationFamily === this.filters.organizationFamily
          );
          if (
            this.filters.organizationSubFamilies &&
            this.filters.organizationSubFamilies[this.organizationFamilySelected.idOrganizationFamily]
          ) {
            this.subFamilyControl.patchValue(
              this.filters.organizationSubFamilies[this.organizationFamilySelected.idOrganizationFamily],
              { emitEvent: false }
            );
          }
          if (this.filters.organizationMilestoneFamily) {
            this.filtersGroup
              .get('organizationMilestoneFamily')
              // patching value on the field will trigger this.emitBoardState();
              .patchValue(
                this.filters.organizationMilestoneFamily[this.organizationFamilySelected.idOrganizationFamily]
              );
          } else {
            this.emitBoardState();
          }
        })
      )
      .subscribe();
  }

  public onChangeValueConfiguration() {
    this.filtersGroup
      .get('configuration')
      .valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(300),
        tap(value => {
          if (!this.filters.configuration) {
            this.filters.configuration = {};
          }
          this.filters.configuration[this.organizationFamilySelected.idOrganizationFamily] = value;
        })
      )
      .subscribe();
  }

  public onChangeValueFieldOrganizationSubFamily() {
    this.subFamilyControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(300),
        tap(value => {
          if (!this.filters.organizationSubFamilies) {
            this.filters.organizationSubFamilies = {};
          }
          this.filters.organizationSubFamilies[this.organizationFamilySelected.idOrganizationFamily] = value;
          this.emitBoardState();
        })
      )
      .subscribe();
  }

  public onFiltersGroupChange() {
    this.onChangeValueField('searchString');
    this.onChangeValueField('responsible');
    this.onChangeValueField('isResponsibleOnlyPilot');
    this.onChangeValueField('displayRepartition');
    this.onChangeValueField('subFamilyRule');
    this.onChangeValueFieldOrganizationMilestoneFamily();
    this.onChangeValueFieldMilestone();
    this.onChangeValueFieldOrganizationFamily();
    this.onChangeValueConfiguration();
    this.onChangeValueFieldOrganizationSubFamily();
  }

  public isCharacteristicsSelectOpened(isOpened: boolean): void {
    this.organizationMilestoneFamilyService.isProjectCharacteristicsSelectOpened.next(isOpened);
  }

  public isMilestoneSelectOpened(isOpened: boolean): void {
    this.organizationMilestoneFamilyService.isProjectMileStoneSelectOpened.next(isOpened);
  }
}

@NgModule({
  imports: [CommonModule, SharedModule.forRoot()],
  declarations: [
    ChartInputComponent,
    DashboardFiltersComponent,
    ProjectFamiliesChoicesComponent,
    ProjectSubFamiliesChoicesComponent,
    LibrariesParentsChoicesComponent,
    ResponsibleChoiceComponent,
    TotalMilestoneComponent,
    BoardConfigurationChoiceComponent
  ],
  exports: [DashboardFiltersComponent, ProjectSubFamiliesChoicesComponent, ChartInputComponent]
})
export class DashboardFilterModule {}
