import { RepositoryService } from '@wip/services/repository';
import { UserBoardState } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserBoardStateApiService {
  constructor(protected repo: RepositoryService) {}

  public getUserBoardStates(params?: any): Observable<UserBoardState[]> {
    return this.repo.getData<UserBoardState[]>('user-board-state', params);
  }

  public getUserBoardState(params: { idUserBoardState: number; params?: any }): Observable<UserBoardState> {
    return this.repo.getData<UserBoardState>('user-board-state/' + params.idUserBoardState, params.params);
  }

  public addUserBoardState(userBoardState: Partial<UserBoardState>): Observable<UserBoardState> {
    return this.repo.create<UserBoardState>('user-board-state', userBoardState);
  }

  public updateUserBoardState(userBoardState: Partial<UserBoardState>): Observable<UserBoardState> {
    return this.repo.update('user-board-state', userBoardState);
  }

  public deleteUserBoardState(idUserBoardState: number): Observable<number> {
    return this.repo.delete('user-board-state/' + +idUserBoardState);
  }
}
