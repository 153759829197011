import { IMetaUser } from '@_app/shared/interfaces/MetaUser.interface';
import { IGroupMetaUsersSuggested } from '@_app/shared/interfaces/MetaUserGroup.interface';
import { SearchUsersComponent } from '@_app/shared/search-users/search-users/search-users.component';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash-es';
@UntilDestroy()
@Component({
  selector: 'app-search-users-dialog',
  templateUrl: './search-users-dialog.component.html',
  styleUrls: ['./search-users-dialog.component.scss']
})
export class SearchUsersDialogComponent implements OnInit {
  @Output() setAsDirty = new EventEmitter<void>();

  @ViewChild(SearchUsersComponent, { static: true }) searchUsersComponent: SearchUsersComponent;
  private initialData: any;

  constructor(
    public dialogRef: MatDialogRef<SearchUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.initialData = cloneDeep(this.data);
    if (this.dialogRef) {
      this.dialogRef.backdropClick().subscribe(() => {
        this.cancel();
      });
    }
  }

  cancel(): void {
    const initialDataToAdd: any = this.initialData.add ? Array.from(this.initialData.add) : [];
    const initialDataToRmv: any = this.initialData.remove ? Array.from(this.initialData.remove) : [];
    const initialDataToSelect: any = this.initialData.metaUsersSelected
      ? Array.from(this.initialData.metaUsersSelected)
      : [];
    // Pour muter le tableau
    this.searchUsersComponent.add.splice(0);
    this.searchUsersComponent.add = initialDataToAdd;
    this.searchUsersComponent.rmv.splice(0);
    this.searchUsersComponent.rmv = initialDataToRmv;
    this.searchUsersComponent.metaUsersSelected.splice(0);
    this.searchUsersComponent.metaUsersSelected = initialDataToSelect;
    this.dialogRef.close(false);
  }

  save() {
    // Pour muter le tableau
    this.dialogRef.close({
      listeUsersAdd: [...this.searchUsersComponent.add],
      listeUsersRemove: [...this.searchUsersComponent.rmv],
      userlist: [...this.searchUsersComponent.suggestedElementsDisplay],
      setAsDirty: this.searchUsersComponent.setAsDirty,
      listUserSelected: [...this.searchUsersComponent.metaUsersSelected]
    });
  }
}

export interface IsearchUserDialog {
  listeUsersAdd: IMetaUser[];
  listeUsersRemove: IMetaUser[];
  userlist: IGroupMetaUsersSuggested[];
  setAsDirty: any;
  listUserSelected: IMetaUser[];
}
