/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { CommunityTypeStructureEnum, OrganizationUserRoleEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationModel, OrganizationUserModel } from '@wip/store/selectors-model';
import { CommunityService, ProjectModuleService } from '@wip/store/services';
import { OrganizationService } from '@wip/store/services';
import { OrganizationUserService } from '@wip/store/services';
import { UserService } from '@wip/store/services';
import { Observable, Subject, map, of, takeUntil, tap } from 'rxjs';
import * as Sentry from '@sentry/angular';

@UntilDestroy()
@Injectable()
export class AppResolver implements Resolve<boolean> {
  loginJwt$: Observable<string>;
  personalSpace$ = new Subject<void>();
  userFound$ = new Subject<void>();

  constructor(
    public router: Router,
    private newOrganizationService: OrganizationService,
    private newCommunityService: CommunityService,
    private newUserService: UserService,
    private newOrganizationUserService: OrganizationUserService,
    private projectModuleService: ProjectModuleService
  ) {}

  resolve() {
    const idUser = this.newUserService?.currentIdUser;
    if (idUser) {
      this.newUserService.getCurrentUser(idUser);
      this.newUserService.setActiveUsers([idUser]);
      this.newOrganizationService.getManyOrganizationsWithIdUser(idUser);
      this.projectModuleService.getManyProjectModules();
      this.newUserService
        .selectAllActiveUsers({ include: [{ model: OrganizationUserModel, include: [OrganizationModel] }] })
        .pipe(
          untilDestroyed(this),
          takeUntil(this.userFound$),
          map(users => users[0]),
          tap(user => {
            if (user) {
              if (user && user.organizationUsers.length) {
                this.userFound$.next();
                let selected = user.organizationUsers.find(orgaUser => orgaUser.selected);

                this.newOrganizationUserService.isCurrentUserAdmin =
                  selected?.role === OrganizationUserRoleEnum.admin ||
                  selected?.role === OrganizationUserRoleEnum.superAdmin;
                if (!selected && user.organizationUsers.length) {
                  this.newOrganizationUserService.upsertOneOrganizationUser({
                    idOrganizationUser: user.organizationUsers[0]?.idOrganizationUser,
                    selected: true
                  });
                  selected = user.organizationUsers[0];
                }

                this.newOrganizationService.setActiveAndCurrentOrganization([
                  selected ? selected.idOrganization : user.organizationUsers[0]?.idOrganization
                ]);
                this.newOrganizationUserService.setActiveOrganizationUsers([
                  selected ? selected.idOrganizationUser : user.organizationUsers[0]?.idOrganizationUser
                ]);
                Sentry.setContext('cellance_user', { idUser: user.idUser, email: user.email });

                Sentry.setContext('cellance_organization', {
                  idOrganization: selected.organization.idOrganization,
                  nom: selected.organization.raisonSociale
                });
              }
            }
          })
        )
        .subscribe();

      this.newCommunityService
        .selectAllCommunities()
        .pipe(
          untilDestroyed(this),
          takeUntil(this.personalSpace$),
          tap(comms => {
            const personal = comms.filter(comm => comm.typeStructure === CommunityTypeStructureEnum.private);
            if (personal.length > 0) {
              this.personalSpace$.next();
            }
          })
        )
        .subscribe();

      return of(true);
    } else {
      return of(false);
    }
  }
}
