import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { NotesHistory, NotesHistoryEntityState } from '@api/api-interfaces';
import { NotesHistoryApiService } from '@wip/store/api-services';
import { NotesHistoryGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeNotesHistory } from '@wip/store/configs/normalization';
import { NotesHistorySelectors } from '@wip/store/selectors';
import { NotesHistoryRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';

export function getDefaultAddNotesHistoryActions(
  notesHistory: NotesHistoryEntityState,
  ids?: NotesHistoryRelationsIds
): Action[] {
  const actions: Action[] = [
    NotesHistoryGeneratedActions.normalizeManyNotesHistoriesAfterUpsert({ notesHistories: [notesHistory] })
  ];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyNotesHistorySuccess({
        idCommunity: ids.community,
        idNotesHistories: [notesHistory.idNotesHistory]
      })
    );
    actions.push(
      NotesHistoryGeneratedActions.addCommunitySuccess({
        idNotesHistory: notesHistory.idNotesHistory,
        idCommunity: ids.community
      })
    );
  }

  return actions;
}

export function getDefaultDeleteNotesHistoryActions(notesHistory: NotesHistoryEntityState): Action[] {
  const actions: Action[] = [
    NotesHistoryGeneratedActions.deleteOneNotesHistorySuccess({ idNotesHistory: notesHistory.idNotesHistory })
  ];

  if (notesHistory.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyNotesHistorySuccess({
        idNotesHistories: [notesHistory.idNotesHistory],
        idCommunities: [notesHistory.community as number]
      })
    );
  }

  return actions;
}

export class GeneratedNotesHistoryEffects {
  constructor(
    protected actions$: Actions,
    protected notesHistoryApiService: NotesHistoryApiService,
    protected store$: Store<AppState>
  ) {}

  getManyNotesHistories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotesHistoryGeneratedActions.getManyNotesHistories),
      switchMap(({ params }) =>
        this.notesHistoryApiService.getNotesHistories(params).pipe(
          map((notesHistories: NotesHistory[]) => {
            return NotesHistoryGeneratedActions.normalizeManyNotesHistoriesAfterUpsert({ notesHistories });
          }),
          catchError(error => of(NotesHistoryGeneratedActions.notesHistoriesFailure({ error })))
        )
      )
    );
  });

  getOneNotesHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotesHistoryGeneratedActions.getOneNotesHistory),
      switchMap(idNotesHistory =>
        this.notesHistoryApiService.getNotesHistory(idNotesHistory).pipe(
          map((notesHistory: NotesHistory) => {
            return NotesHistoryGeneratedActions.normalizeManyNotesHistoriesAfterUpsert({
              notesHistories: [notesHistory]
            });
          }),
          catchError(error => of(NotesHistoryGeneratedActions.notesHistoriesFailure({ error })))
        )
      )
    );
  });

  upsertOneNotesHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotesHistoryGeneratedActions.upsertOneNotesHistory),
      concatMap(({ notesHistory, ids }: { notesHistory: Partial<NotesHistory>; ids?: NotesHistoryRelationsIds }) => {
        if (notesHistory.idNotesHistory) {
          return this.notesHistoryApiService.updateNotesHistory(notesHistory).pipe(
            map((notesHistoryReturned: NotesHistory) => {
              return NotesHistoryGeneratedActions.normalizeManyNotesHistoriesAfterUpsert({
                notesHistories: [notesHistoryReturned]
              });
            }),
            catchError(error => of(NotesHistoryGeneratedActions.notesHistoriesFailure({ error })))
          );
        } else {
          return this.notesHistoryApiService.addNotesHistory(notesHistory).pipe(
            mergeMap((notesHistoryReturned: NotesHistory) =>
              getDefaultAddNotesHistoryActions(notesHistoryReturned, ids)
            ),
            catchError(error => of(NotesHistoryGeneratedActions.notesHistoriesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneNotesHistory$ = createEffect(() => {
    const selectNotesHistoryState$ = this.store$.select(NotesHistorySelectors.selectNotesHistoryState);
    return this.actions$.pipe(
      ofType(NotesHistoryGeneratedActions.deleteOneNotesHistory),
      withLatestFrom(selectNotesHistoryState$),
      concatMap(([{ idNotesHistory }, state]) =>
        this.notesHistoryApiService.deleteNotesHistory(idNotesHistory).pipe(
          mergeMap(_success =>
            getDefaultDeleteNotesHistoryActions(state.entities[idNotesHistory] as NotesHistoryEntityState)
          ),
          catchError(error => of(NotesHistoryGeneratedActions.notesHistoriesFailure({ error })))
        )
      )
    );
  });

  normalizeManyNotesHistoriesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotesHistoryGeneratedActions.normalizeManyNotesHistoriesAfterUpsert),
      concatMap(({ notesHistories }) => {
        const actions: Action[] = getActionsToNormalizeNotesHistory(notesHistories, StoreActionType.upsert);
        return [getMultiAction(actions, '[NotesHistory] Normalization After Upsert Success')];
      })
    );
  });
}
