import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserProfilRight, UserProfilRightEntityState } from '@api/api-interfaces';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { OrganizationUserProfil, OrganizationUserProfilEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { UserProfilRightState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const userProfilRightRelations: string[] = ['profils', 'organizationUserProfils'];

export const { selectEntities, selectAll } = UserProfilRightState.adapter.getSelectors();

export const selectUserProfilRightState = createFeatureSelector<UserProfilRightState.IState>(
  UserProfilRightState.userProfilRightFeatureKey
);

export const selectIsLoadedUserProfilRight = createSelector(
  selectUserProfilRightState,
  (state: UserProfilRightState.IState) => state.isLoaded
);

export const selectIsLoadingUserProfilRight = createSelector(
  selectUserProfilRightState,
  (state: UserProfilRightState.IState) => state.isLoading
);

export const selectIsReadyUserProfilRight = createSelector(
  selectUserProfilRightState,
  (state: UserProfilRightState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUserProfilRight = createSelector(
  selectUserProfilRightState,
  (state: UserProfilRightState.IState) => state.isLoaded && !state.isLoading
);

export const selectUserProfilRightsEntities = createSelector(selectUserProfilRightState, selectEntities);

export const selectUserProfilRightsArray = createSelector(selectUserProfilRightState, selectAll);

export const selectIdUserProfilRightsActive = createSelector(
  selectUserProfilRightState,
  (state: UserProfilRightState.IState) => state.actives
);

const userProfilRightsInObject = (userProfilRights: Dictionary<UserProfilRightEntityState>) => ({ userProfilRights });

const selectUserProfilRightsEntitiesDictionary = createSelector(
  selectUserProfilRightsEntities,
  userProfilRightsInObject
);

const selectAllUserProfilRightsObject = createSelector(selectUserProfilRightsEntities, userProfilRights => {
  return hydrateAll({ userProfilRights });
});

const selectOneUserProfilRightDictionary = (idUserProfilRight: number) =>
  createSelector(selectUserProfilRightsEntities, userProfilRights => ({
    userProfilRights: { [idUserProfilRight]: userProfilRights[idUserProfilRight] }
  }));

const selectOneUserProfilRightDictionaryWithoutChild = (idUserProfilRight: number) =>
  createSelector(selectUserProfilRightsEntities, userProfilRights => ({
    userProfilRight: userProfilRights[idUserProfilRight]
  }));

const selectActiveUserProfilRightsEntities = createSelector(
  selectIdUserProfilRightsActive,
  selectUserProfilRightsEntities,
  (actives: number[], userProfilRights: Dictionary<UserProfilRightEntityState>) =>
    getUserProfilRightsFromActives(actives, userProfilRights)
);

function getUserProfilRightsFromActives(
  actives: number[],
  userProfilRights: Dictionary<UserProfilRightEntityState>
): Dictionary<UserProfilRightEntityState> {
  return actives.reduce((acc, idActive) => {
    if (userProfilRights[idActive]) {
      acc[idActive] = userProfilRights[idActive];
    }
    return acc;
  }, {} as Dictionary<UserProfilRightEntityState>);
}

const selectAllUserProfilRightsSelectors: Dictionary<Selector> = {};
export function selectAllUserProfilRights(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UserProfilRight>(
      schema,
      selectAllUserProfilRightsSelectors,
      selectUserProfilRightsEntitiesDictionary,
      getRelationSelectors,
      userProfilRightRelations,
      hydrateAll,
      'userProfilRight'
    );
  } else {
    return selectAllUserProfilRightsObject;
  }
}

export function selectAllUserProfilRightsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'userProfilRights'
): Selector {
  return createSelector(selectAllUserProfilRights(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserProfilRightEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.userProfilRights.length; i++) {
      res[customKey][result.userProfilRights[i].idUserProfilRight] = result.userProfilRights[i];
    }
    return res;
  });
}

export function selectOneUserProfilRight(schema: SelectSchema = {}, idUserProfilRight: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneUserProfilRightDictionary(idUserProfilRight)];
    selectors.push(...getRelationSelectors(schema, userProfilRightRelations, 'userProfilRight'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserProfilRightDictionaryWithoutChild(idUserProfilRight);
  }
}

export function selectActiveUserProfilRights(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveUserProfilRightsEntities, userProfilRights => ({
      userProfilRights
    }))
  ];
  selectors.push(...getRelationSelectors(schema, userProfilRightRelations, 'userProfilRight'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  userProfilRights: Dictionary<UserProfilRightEntityState>;
  profils?: Dictionary<ProfilEntityState>;
  organizationUserProfils?: Dictionary<OrganizationUserProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { userProfilRights: (UserProfilRight | null)[] } {
  const { userProfilRights, profils, organizationUserProfils } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    userProfilRights: Object.keys(userProfilRights).map(idUserProfilRight =>
      hydrate(userProfilRights[idUserProfilRight] as UserProfilRightEntityState, profils, organizationUserProfils)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { userProfilRight: UserProfilRightEntityState | null } {
  const { userProfilRights, profils, organizationUserProfils } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const userProfilRight = Object.values(userProfilRights)[0];
  return {
    userProfilRight: hydrate(userProfilRight as UserProfilRightEntityState, profils, organizationUserProfils)
  };
}

function hydrate(
  userProfilRight: UserProfilRightEntityState,
  profilEntities?: Dictionary<ProfilEntityState>,
  organizationUserProfilEntities?: Dictionary<OrganizationUserProfilEntityState>
): UserProfilRight | null {
  if (!userProfilRight) {
    return null;
  }

  const userProfilRightHydrated: UserProfilRightEntityState = { ...userProfilRight };
  if (profilEntities) {
    userProfilRightHydrated.profil = profilEntities[userProfilRight.profil as number] as Profil;
  } else {
    delete userProfilRightHydrated.profil;
  }
  if (organizationUserProfilEntities) {
    userProfilRightHydrated.organizationUserProfil = organizationUserProfilEntities[
      userProfilRight.organizationUserProfil as number
    ] as OrganizationUserProfil;
  } else {
    delete userProfilRightHydrated.organizationUserProfil;
  }

  return userProfilRightHydrated as UserProfilRight;
}
