import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { RightProfil, RightProfilEntityState } from '@api/api-interfaces';
import { RightProfilApiService } from '@wip/store/api-services';
import { RightProfilGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeRightProfil } from '@wip/store/configs/normalization';
import { RightProfilSelectors } from '@wip/store/selectors';
import { RightProfilRelationsIds } from '@wip/store/ids-interfaces';
import { RightGeneratedActions } from '@wip/store/actions';
import { ProfilGeneratedActions } from '@wip/store/actions';

export function getDefaultAddRightProfilActions(
  rightProfil: RightProfilEntityState,
  ids?: RightProfilRelationsIds
): Action[] {
  const actions: Action[] = [
    RightProfilGeneratedActions.normalizeManyRightProfilsAfterUpsert({ rightProfils: [rightProfil] })
  ];

  if (ids?.right) {
    actions.push(
      RightGeneratedActions.addManyRightProfilSuccess({
        idRight: ids.right,
        idRightProfils: [rightProfil.idRightProfil]
      })
    );
    actions.push(
      RightProfilGeneratedActions.addRightSuccess({
        idRightProfil: rightProfil.idRightProfil,
        idRight: ids.right
      })
    );
  }

  if (ids?.profil) {
    actions.push(
      ProfilGeneratedActions.addManyRightProfilSuccess({
        idProfil: ids.profil,
        idRightProfils: [rightProfil.idRightProfil]
      })
    );
    actions.push(
      RightProfilGeneratedActions.addProfilSuccess({
        idRightProfil: rightProfil.idRightProfil,
        idProfil: ids.profil
      })
    );
  }

  return actions;
}

export function getDefaultDeleteRightProfilActions(rightProfil: RightProfilEntityState): Action[] {
  const actions: Action[] = [
    RightProfilGeneratedActions.deleteOneRightProfilSuccess({ idRightProfil: rightProfil.idRightProfil })
  ];

  if (rightProfil.right) {
    actions.push(
      RightGeneratedActions.deleteManyRightProfilSuccess({
        idRightProfils: [rightProfil.idRightProfil],
        idRights: [rightProfil.right as number]
      })
    );
  }

  if (rightProfil.profil) {
    actions.push(
      ProfilGeneratedActions.deleteManyRightProfilSuccess({
        idRightProfils: [rightProfil.idRightProfil],
        idProfils: [rightProfil.profil as number]
      })
    );
  }

  return actions;
}

export class GeneratedRightProfilEffects {
  constructor(
    protected actions$: Actions,
    protected rightProfilApiService: RightProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyRightProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RightProfilGeneratedActions.getManyRightProfils),
      switchMap(({ params }) =>
        this.rightProfilApiService.getRightProfils(params).pipe(
          map((rightProfils: RightProfil[]) => {
            return RightProfilGeneratedActions.normalizeManyRightProfilsAfterUpsert({ rightProfils });
          }),
          catchError(error => of(RightProfilGeneratedActions.rightProfilsFailure({ error })))
        )
      )
    );
  });

  getOneRightProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RightProfilGeneratedActions.getOneRightProfil),
      switchMap(idRightProfil =>
        this.rightProfilApiService.getRightProfil(idRightProfil).pipe(
          map((rightProfil: RightProfil) => {
            return RightProfilGeneratedActions.normalizeManyRightProfilsAfterUpsert({ rightProfils: [rightProfil] });
          }),
          catchError(error => of(RightProfilGeneratedActions.rightProfilsFailure({ error })))
        )
      )
    );
  });

  upsertOneRightProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RightProfilGeneratedActions.upsertOneRightProfil),
      concatMap(({ rightProfil, ids }: { rightProfil: Partial<RightProfil>; ids?: RightProfilRelationsIds }) => {
        if (rightProfil.idRightProfil) {
          return this.rightProfilApiService.updateRightProfil(rightProfil).pipe(
            map((rightProfilReturned: RightProfil) => {
              return RightProfilGeneratedActions.normalizeManyRightProfilsAfterUpsert({
                rightProfils: [rightProfilReturned]
              });
            }),
            catchError(error => of(RightProfilGeneratedActions.rightProfilsFailure({ error })))
          );
        } else {
          return this.rightProfilApiService.addRightProfil(rightProfil).pipe(
            mergeMap((rightProfilReturned: RightProfil) => getDefaultAddRightProfilActions(rightProfilReturned, ids)),
            catchError(error => of(RightProfilGeneratedActions.rightProfilsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneRightProfil$ = createEffect(() => {
    const selectRightProfilState$ = this.store$.select(RightProfilSelectors.selectRightProfilState);
    return this.actions$.pipe(
      ofType(RightProfilGeneratedActions.deleteOneRightProfil),
      withLatestFrom(selectRightProfilState$),
      concatMap(([{ idRightProfil }, state]) =>
        this.rightProfilApiService.deleteRightProfil(idRightProfil).pipe(
          mergeMap(_success =>
            getDefaultDeleteRightProfilActions(state.entities[idRightProfil] as RightProfilEntityState)
          ),
          catchError(error => of(RightProfilGeneratedActions.rightProfilsFailure({ error })))
        )
      )
    );
  });

  normalizeManyRightProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RightProfilGeneratedActions.normalizeManyRightProfilsAfterUpsert),
      concatMap(({ rightProfils }) => {
        const actions: Action[] = getActionsToNormalizeRightProfil(rightProfils, StoreActionType.upsert);
        return [getMultiAction(actions, '[RightProfil] Normalization After Upsert Success')];
      })
    );
  });
}
