import { environment } from '@wip/environments';
import { Injectable } from '@angular/core';

@Injectable()
export class GoogleMapsService {
  private apiLoaded = false;
  private apiKey = '';
  mapLoading = false;
  mapLoaded = false;
  constructor() {
    this.apiKey = environment.apiKeyGoogleMap;
  }

  public async loadApi(): Promise<void> {
    if (this.apiLoaded) {
      return;
    }
    // create a custom scripts tag
    // https://stackblitz.com/edit/angular-sym9gg?file=src%2Fapp%2Fhello.component.ts
    this.mapLoading = true;
    const mapsScript = document.createElement('script');
    mapsScript.setAttribute('async', '');
    mapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey;

    mapsScript.addEventListener('load', () => {
      this.apiLoaded = true;
      this.mapLoaded = true;
      this.mapLoading = false;
    });
    document.head.appendChild(mapsScript);
  }
  public isApiLoaded(): boolean {
    return this.apiLoaded;
  }
}
