import { DisplayIconHelper } from '@api/api-interfaces';

namespace AgGridCellIconFormatter {
  export function cellRendererIcon(
    value: string,
    iconsConfigs: DisplayIconHelper[],
    iconsConfigDefault: DisplayIconHelper = null,
    text: string = null,
    width: number = null
  ): string {
    if (value) {
      let res = '';

      if (text === 'auto') {
        text = iconsConfigs.find(o => o.value === value) ? iconsConfigs.find(o => o.value === value).text : null;
      }

      for (const iconConfig of iconsConfigs) {
        if (iconConfig.children) {
          res += cellRendererIcon(value, iconConfig.children, iconsConfigDefault, text);
        } else if (iconConfig.id?.toString() === value) {
          res += getCellInnerHtml(iconConfig, text, width);
          break;
        } else if (iconConfig.value === value) {
          res += getCellInnerHtml(iconConfig, text, width);
          break;
        }
      }

      return res.length > 0 ? res : getCellInnerHtml(iconsConfigDefault, text, width);
    }
  }

  export function getCellInnerHtml(iconConfig: DisplayIconHelper, text: string, width: number): string {
    let widthStyle = '';
    if (width) {
      widthStyle = 'width: ' + width + 'px ;';
    }
    if (iconConfig) {
      if (text) {
        return `<div title="${iconConfig.text}" class="icon-cell-text"><i class="${iconConfig.icon} ${
          iconConfig.class ? iconConfig.class : ''
        }" style="padding-right: 3px;"></i><span>${text}</span></div>`;
      }
      return `<div title="${iconConfig.text}" class="icon-cell" ><i class="${iconConfig.class} ${iconConfig.icon}" style="padding-right: 0; ${widthStyle}"></i></div>`;
    }
    return '';
  }
}
export default AgGridCellIconFormatter;
