/* eslint-disable @nx/enforce-module-boundaries */
import { CapacitorService } from '@_app/core/device/capacitor-device-ready.service';
import { DocumentViewService } from '@_app/document/document-view.service';
import { NeverUpdatedPipe } from '@_app/meeting/meeting-history/meeting-element-history-wrapper/same-day.pipe';
import { DurationPickerDialogComponent } from '@_app/shared/dialogs/duration-picker-dialog/duration-picker-dialog.component';
import { OldUserService } from '@wip/store/services';
import { PasteFileDirective } from '@_app/timeline/directives/paste-file.directive';
import { ExcedentUsersDialogComponent } from '@_app/user/excedent-users-dialog/excedent-users-dialog.component';
import { UserDetailDialogComponent } from '@_app/user/user-detail-dialog/user-detail-dialog.component';
import { FileDropModule } from '@_shared/directives/file-drop/file-drop.module';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigurationApiService } from '@api/old-api-services';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DisplayDurationPipe } from '../meeting/meeting-info-bar/display-duration.pipe';
import { MeetingListItemComponent } from '../meeting/meeting-list/meeting-list-item/meeting-list-item.component';
import { OldTodoService } from '../todo/todo.service';
import { AvatarListComponent } from './avatars/avatar-list/avatar-list.component';
import { AvatarComponentModule } from './avatars/avatar/avatar.component';
import { AddGroupsComponent } from './dialogs/add-groups/add-groups.component';
import { ConfirmationChoicesDialogContainerComponent } from '@wip/dialogs/confirmation-choices-dialog';
import { DatePickerDialogComponent } from './dialogs/date-picker-dialog/date-picker-dialog.component';
import { PreviewImageDialogComponent } from './dialogs/preview-image-dialog/preview-image-dialog.component';
import { ProjectVerificationDialogComponent } from './dialogs/project-verification-dialog/project-verification-dialog.component';
import { TextareaDialogComponent } from './dialogs/textarea-dialog/textarea-dialog.component';
import { TimePickerDialogComponent } from './dialogs/time-picker-dialog/time-picker-dialog.component';
import { CustomAutofocusDirective } from './directives/custom-auto-focus.directive';
import { ActionDisplayComponent } from './elements/actions/action-display/action-display.component';
import { ActionInputComponent } from './elements/actions/action-input/action-input.component';
import { ActionReminderListComponent } from './elements/actions/action-reminder-list/action-reminder-list.component';
import { CommentService } from './elements/comments/comment.service';
import { DocumentListFormComponent } from './elements/document-list-form/document-list-form.component';
import { DocumentListItemComponent } from './elements/document-list-form/document-list-item/document-list-item.component';
import { DocumentDisplayComponent } from './elements/documents/document-display/document-display.component';
import { ElementBottomSheetComponent } from './elements/element-bottom-sheet/element-bottom-sheet.component';
import { ElementHoverComponent } from './elements/element-hover/element-hover.component';
import { ElementsService } from './elements/elements.service';
import { MessageDisplayComponent } from './elements/messages/message-display/message-display.component';
import { MessageInputComponent } from './elements/messages/message-input/message-input.component';
import { MessageService } from './elements/messages/message.service';
import { PojService } from './elements/pojs/poj.service';
import { DateInputComponent } from './form-component/date-input/date-input.component';
import {
  AppValidDateValidator,
  DatePickerCustomComponent
} from './form-component/date-picker-custom/date-picker-custom.component';
import { SelectorCustomComponent } from './form-component/selector-custom/selector-custom.component';
import { RemoveYearIfCurrentPipe } from './pipes/concat-year-if-not-current/concat-year-if-not-current.pipe';
import { FirstLetterFirstWordUppercasePipe } from './pipes/first-word-uppercase/first-word-uppercase.pipe';
import { ChipContentComponent } from './search-users/chip-content/chip-content.component';
import { SearchUserDisplayComponent } from './search-users/search-user-display/search-user-display.component';
import { SearchUsersDialogComponent } from './search-users/search-users-dialog/search-users-dialog.component';
import { SearchUsersComponent } from './search-users/search-users/search-users.component';
import { SharedMaterialModule } from './shared-material/shared-material.module';

@NgModule({
  imports: [
    CommonModule,
    FileDropModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedMaterialModule,
    TranslateModule.forChild(),
    MatDialogModule,
    AvatarComponentModule,
    PdfViewerModule,
    ConfirmationChoicesDialogContainerComponent
  ],
  declarations: [
    AvatarListComponent,
    ActionDisplayComponent,
    ActionInputComponent,
    TextareaDialogComponent,
    DatePickerDialogComponent,
    DateInputComponent,
    DocumentDisplayComponent,
    ElementBottomSheetComponent,
    ElementHoverComponent,
    MessageDisplayComponent,
    MessageInputComponent,
    PreviewImageDialogComponent,
    SearchUsersComponent,
    TimePickerDialogComponent,
    DurationPickerDialogComponent,
    NeverUpdatedPipe,
    SearchUsersDialogComponent,
    ChipContentComponent,
    SelectorCustomComponent,
    DatePickerCustomComponent,
    UserDetailDialogComponent,
    ExcedentUsersDialogComponent,
    PasteFileDirective,
    ExcedentUsersDialogComponent,
    SearchUserDisplayComponent,
    DocumentListFormComponent,
    DocumentListItemComponent,
    AppValidDateValidator,
    ActionReminderListComponent,
    MeetingListItemComponent,
    DisplayDurationPipe,
    FirstLetterFirstWordUppercasePipe,
    RemoveYearIfCurrentPipe,
    CustomAutofocusDirective,
    AddGroupsComponent,
    ProjectVerificationDialogComponent
  ],
  exports: [
    FileDropModule,
    AvatarListComponent,
    ActionDisplayComponent,
    ActionReminderListComponent,
    ActionInputComponent,
    DocumentDisplayComponent,
    ConfirmationChoicesDialogContainerComponent,
    TextareaDialogComponent,
    FormsModule,
    AvatarComponentModule,
    DateInputComponent,
    ReactiveFormsModule,
    RouterModule,
    SharedMaterialModule,
    SearchUsersComponent,
    TranslateModule,
    ElementHoverComponent,
    MessageDisplayComponent,
    DatePickerCustomComponent,
    UserDetailDialogComponent,
    SelectorCustomComponent,
    ExcedentUsersDialogComponent,
    PasteFileDirective,
    DocumentListFormComponent,
    DisplayDurationPipe,
    MeetingListItemComponent,
    FirstLetterFirstWordUppercasePipe,
    CustomAutofocusDirective
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CapacitorService,
        ConfigurationApiService,
        CommentService,
        ElementsService,
        DocumentViewService,
        MessageService,
        PojService,
        OldTodoService,
        OldUserService
      ]
    };
  }
}
