import { Component } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'wip-edit-button-renderer',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './edit-button-renderer.component.html',
  styleUrl: './edit-button-renderer.component.scss'
})
export class EditButtonRendererComponent implements ICellRendererAngularComp {
  private params;
  public componentParent;

  agInit(params): void {
    this.params = params;
    this.componentParent = params.context.componentParent;
  }

  public onEdit() {
    this.componentParent.onEdit(this.params);
  }

  refresh(): boolean {
    return true;
  }
}
