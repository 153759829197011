import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'wip-filter-save',
  templateUrl: './filter-save.component.html',
  styleUrls: ['./filter-save.component.scss']
})
export class FilterSaveComponent implements OnInit {
  public configControl: FormControl;

  constructor(
    public dialogRef: MatDialogRef<FilterSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.configControl.value);
  }

  ngOnInit() {
    this.configControl = new FormControl('');
  }
}
