import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CommunityCaracteristic } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { CommunityCaracteristicRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityCaracteristicGeneratedActions } from '@wip/store/actions';
import { CommunityCaracteristicSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCommunityCaracteristicService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CommunityCaracteristicSelectors.selectIsLoadedCommunityCaracteristic));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CommunityCaracteristicSelectors.selectIsLoadingCommunityCaracteristic));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      CommunityCaracteristicSelectors.selectIsReadyAndLoadedCommunityCaracteristic as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCommunityCaracteristics(schema: SelectSchema = {}): Observable<CommunityCaracteristic[]> {
    return this.store$.pipe(select(CommunityCaracteristicSelectors.selectAllCommunityCaracteristics(schema))).pipe(
      switchMap(({ communityCaracteristics }: { communityCaracteristics: CommunityCaracteristic[] }) => {
        return this.getReady(schema).pipe(mapTo(communityCaracteristics));
      })
    );
  }

  public selectOneCommunityCaracteristic(
    idCommunityCaracteristic: number,
    schema: SelectSchema = {}
  ): Observable<CommunityCaracteristic> {
    return this.store$
      .pipe(select(CommunityCaracteristicSelectors.selectOneCommunityCaracteristic(schema, idCommunityCaracteristic)))
      .pipe(
        switchMap(({ communityCaracteristic }: { communityCaracteristic: CommunityCaracteristic }) => {
          return this.getReady(schema).pipe(mapTo(communityCaracteristic));
        })
      );
  }

  public selectAllActiveCommunityCaracteristics(schema: SelectSchema = {}): Observable<CommunityCaracteristic[]> {
    return this.store$.pipe(select(CommunityCaracteristicSelectors.selectActiveCommunityCaracteristics(schema))).pipe(
      switchMap(({ communityCaracteristics }: { communityCaracteristics: CommunityCaracteristic[] }) => {
        return this.getReady(schema).pipe(mapTo(communityCaracteristics));
      })
    );
  }

  public selectIdCommunityCaracteristicsActive(): Observable<number[]> {
    return this.store$.pipe(select(CommunityCaracteristicSelectors.selectIdCommunityCaracteristicsActive)).pipe(
      switchMap((idCommunityCaracteristics: number[]) => {
        return this.getReady().pipe(mapTo(idCommunityCaracteristics));
      })
    );
  }

  public getOneCommunityCaracteristic(
    idCommunityCaracteristic: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<CommunityCaracteristic> {
    this.store$.dispatch(
      CommunityCaracteristicGeneratedActions.getOneCommunityCaracteristic({ idCommunityCaracteristic, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert,
        CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure,
        true
      );
    }
  }

  public getManyCommunityCaracteristics(
    params: any = {},
    getResult?: boolean
  ): void | Observable<CommunityCaracteristic[]> {
    this.store$.dispatch(CommunityCaracteristicGeneratedActions.getManyCommunityCaracteristics({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert,
        CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure
      );
    }
  }

  public upsertOneCommunityCaracteristic(
    communityCaracteristic: Partial<CommunityCaracteristic>,
    ids: CommunityCaracteristicRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CommunityCaracteristic> {
    this.store$.dispatch(
      CommunityCaracteristicGeneratedActions.upsertOneCommunityCaracteristic({ communityCaracteristic, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert,
        CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure,
        true
      );
    }
  }

  public deleteOneCommunityCaracteristic(
    idCommunityCaracteristic: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      CommunityCaracteristicGeneratedActions.deleteOneCommunityCaracteristic({ idCommunityCaracteristic })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityCaracteristicGeneratedActions.deleteOneCommunityCaracteristicSuccess,
        CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure
      );
    }
  }

  public setActiveCommunityCaracteristics(idCommunityCaracteristics: number[]): void {
    this.store$.dispatch(CommunityCaracteristicGeneratedActions.clearActivesCommunityCaracteristics());
    this.store$.dispatch(
      CommunityCaracteristicGeneratedActions.addManyActivesCommunityCaracteristics({ idCommunityCaracteristics })
    );
  }
}
