<div mat-dialog-title>
  <h1>Utilisateurs</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1" (click)="cancel()"></button>
</div>
<div mat-dialog-content class="flex-1">
  <app-search-users-directive
    #seachUser
    [findExactMatch]="data.findExactMatch"
    [searchService]="data.searchService"
    [searchMethod]="data.searchMethod"
    [metaUsersBlacklist]="data.metaUsersBlacklist"
    [metaUsersSelected]="data.metaUsersSelected"
    [groupMetaUsersSuggestedOrigin]="data.groupMetaUsersSuggested"
    [canAddName]="data.canAddName"
    [textPlaceholder]="data.textPlaceholder"
    [displayList]="data.displayList"
    [maxChips]="data.maxChips"
    [minChips]="data.minChips"
    [add]="data.add"
    [rmv]="data.rmv"
    [listOrigin]="data.listOrigin"
    [selectOnlyOnce]="data.selectOnlyOnce"
    [labelSuggestion]="data.labelSuggestion"
    [focus]="data.focus"
    [defaultMetaUserAttributes]="data.defaultMetaUserAttributes"
  />
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="save()" color="primary" tabindex="-1">Valider</button>
</div>
