import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationFamilyEntityState } from '@api/api-interfaces';

export const organizationFamilyReducersGeneratedFunctions: ReducerTypes<
  OrganizationFamilyState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationFamilyGeneratedActions.getOneOrganizationFamily, (state: OrganizationFamilyState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationFamilyGeneratedActions.getManyOrganizationFamilys, (state: OrganizationFamilyState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationFamilyGeneratedActions.upsertOneOrganizationFamily, (state: OrganizationFamilyState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationFamilyGeneratedActions.upsertManyOrganizationFamilysSuccess,
    (state: OrganizationFamilyState.IState, { organizationFamilys }) =>
      OrganizationFamilyState.adapter.upsertMany(organizationFamilys, setLoadingsState(state, false))
  ),
  on(OrganizationFamilyGeneratedActions.deleteOneOrganizationFamily, (state: OrganizationFamilyState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationFamilyGeneratedActions.deleteOneOrganizationFamilySuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily }) =>
      OrganizationFamilyState.adapter.removeOne(idOrganizationFamily, setLoadingsState(state, false))
  ),
  on(OrganizationFamilyGeneratedActions.clearActivesOrganizationFamilys, (state: OrganizationFamilyState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationFamilyGeneratedActions.addManyActivesOrganizationFamilys,
    (state: OrganizationFamilyState.IState, { idOrganizationFamilys }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationFamilys)
    })
  ),
  on(
    OrganizationFamilyGeneratedActions.deleteOneActiveOrganizationFamily,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationFamily)
    })
  ),

  on(OrganizationFamilyGeneratedActions.clearOrganizationFamilys, () => OrganizationFamilyState.initialState),

  on(
    OrganizationFamilyGeneratedActions.addManyOrganizationSubFamilySuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganizationSubFamilys }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const organizationSubFamilys = (state.entities[idOrganizationFamily]?.organizationSubFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organizationSubFamilys: organizationSubFamilys.concat(
              idOrganizationSubFamilys.filter(id => organizationSubFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationSubFamilySuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationSubFamilys, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organizationSubFamilys) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organizationSubFamilys: (
                state.entities[idOrganizationFamily]?.organizationSubFamilys as number[]
              )?.filter(
                (idOrganizationSubFamily: number) =>
                  !idOrganizationSubFamilys.some((id: number) => id === idOrganizationSubFamily)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyCommunitySuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idCommunities }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const communities = (state.entities[idOrganizationFamily]?.communities as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            communities: communities.concat(idCommunities.filter(id => communities.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyCommunitySuccess,
    (state: OrganizationFamilyState.IState, { idCommunities, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.communities) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              communities: (state.entities[idOrganizationFamily]?.communities as number[])?.filter(
                (idCommunity: number) => !idCommunities.some((id: number) => id === idCommunity)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyOrganizationStepSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganizationSteps }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const organizationSteps = (state.entities[idOrganizationFamily]?.organizationSteps as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organizationSteps: organizationSteps.concat(
              idOrganizationSteps.filter(id => organizationSteps.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationStepSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationSteps, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organizationSteps) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organizationSteps: (state.entities[idOrganizationFamily]?.organizationSteps as number[])?.filter(
                (idOrganizationStep: number) => !idOrganizationSteps.some((id: number) => id === idOrganizationStep)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyOrganizationKpiSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganizationKpis }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const organizationKpis = (state.entities[idOrganizationFamily]?.organizationKpis as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organizationKpis: organizationKpis.concat(idOrganizationKpis.filter(id => organizationKpis.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationKpiSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationKpis, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organizationKpis) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organizationKpis: (state.entities[idOrganizationFamily]?.organizationKpis as number[])?.filter(
                (idOrganizationKpi: number) => !idOrganizationKpis.some((id: number) => id === idOrganizationKpi)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyOrganizationDataColumnSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganizationDataColumns }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const organizationDataColumns = (state.entities[idOrganizationFamily]?.organizationDataColumns as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organizationDataColumns: organizationDataColumns.concat(
              idOrganizationDataColumns.filter(id => organizationDataColumns.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationDataColumnSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationDataColumns, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organizationDataColumns) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organizationDataColumns: (
                state.entities[idOrganizationFamily]?.organizationDataColumns as number[]
              )?.filter(
                (idOrganizationDataColumn: number) =>
                  !idOrganizationDataColumns.some((id: number) => id === idOrganizationDataColumn)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyOrganizationMilestoneFamilySuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganizationMilestoneFamilys }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const organizationMilestoneFamilys =
        (state.entities[idOrganizationFamily]?.organizationMilestoneFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organizationMilestoneFamilys: organizationMilestoneFamilys.concat(
              idOrganizationMilestoneFamilys.filter(id => organizationMilestoneFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationMilestoneFamilys, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organizationMilestoneFamilys) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organizationMilestoneFamilys: (
                state.entities[idOrganizationFamily]?.organizationMilestoneFamilys as number[]
              )?.filter(
                (idOrganizationMilestoneFamily: number) =>
                  !idOrganizationMilestoneFamilys.some((id: number) => id === idOrganizationMilestoneFamily)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyOrganizationCaracteristicSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganizationCaracteristics }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const organizationCaracteristics =
        (state.entities[idOrganizationFamily]?.organizationCaracteristics as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organizationCaracteristics: organizationCaracteristics.concat(
              idOrganizationCaracteristics.filter(id => organizationCaracteristics.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationCaracteristicSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationCaracteristics, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organizationCaracteristics) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organizationCaracteristics: (
                state.entities[idOrganizationFamily]?.organizationCaracteristics as number[]
              )?.filter(
                (idOrganizationCaracteristic: number) =>
                  !idOrganizationCaracteristics.some((id: number) => id === idOrganizationCaracteristic)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyOrganizationMilestoneSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganizationMilestones }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const organizationMilestones = (state.entities[idOrganizationFamily]?.organizationMilestones as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organizationMilestones: organizationMilestones.concat(
              idOrganizationMilestones.filter(id => organizationMilestones.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationMilestoneSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationMilestones, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organizationMilestones) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organizationMilestones: (
                state.entities[idOrganizationFamily]?.organizationMilestones as number[]
              )?.filter(
                (idOrganizationMilestone: number) =>
                  !idOrganizationMilestones.some((id: number) => id === idOrganizationMilestone)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyOrganizationRiskSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganizationRisks }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const organizationRisks = (state.entities[idOrganizationFamily]?.organizationRisks as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organizationRisks: organizationRisks.concat(
              idOrganizationRisks.filter(id => organizationRisks.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationRiskSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationRisks, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organizationRisks) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organizationRisks: (state.entities[idOrganizationFamily]?.organizationRisks as number[])?.filter(
                (idOrganizationRisk: number) => !idOrganizationRisks.some((id: number) => id === idOrganizationRisk)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyOrganizationUserProfilSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganizationUserProfils }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const organizationUserProfils = (state.entities[idOrganizationFamily]?.organizationUserProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organizationUserProfils: organizationUserProfils.concat(
              idOrganizationUserProfils.filter(id => organizationUserProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationUserProfilSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationUserProfils, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organizationUserProfils) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organizationUserProfils: (
                state.entities[idOrganizationFamily]?.organizationUserProfils as number[]
              )?.filter(
                (idOrganizationUserProfil: number) =>
                  !idOrganizationUserProfils.some((id: number) => id === idOrganizationUserProfil)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addManyUserBoardStateSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idUserBoardStates }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      const userBoardStates = (state.entities[idOrganizationFamily]?.userBoardStates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            userBoardStates: userBoardStates.concat(idUserBoardStates.filter(id => userBoardStates.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyUserBoardStateSuccess,
    (state: OrganizationFamilyState.IState, { idUserBoardStates, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.userBoardStates) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              userBoardStates: (state.entities[idOrganizationFamily]?.userBoardStates as number[])?.filter(
                (idUserBoardState: number) => !idUserBoardStates.some((id: number) => id === idUserBoardState)
              )
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.addOrganizationSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizationFamily, idOrganization }) => {
      if (!state.entities[idOrganizationFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationFamily]: {
            ...state.entities[idOrganizationFamily],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationFamilyGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationFamilyState.IState, { idOrganizations, idOrganizationFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationFamilys.reduce((entities, idOrganizationFamily) => {
            if (!state.entities[idOrganizationFamily]?.organization) {
              return entities;
            }
            entities[idOrganizationFamily] = {
              ...state.entities[idOrganizationFamily],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationFamily]?.organization
              )
                ? undefined
                : state.entities[idOrganizationFamily]?.organization
            } as OrganizationFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationFamilyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationFamilyState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationFamilyState.IState {
  return { ...state, isLoaded, isLoading };
}
