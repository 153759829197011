import { AgGridUtils } from '@_app/utils/ag-grid/cell-formatter/ag-grid.utils';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { OrganizationFamily, OrganizationMilestone } from '@api/api-interfaces';
import { ConstraintTypeEnum, ElementStatusActionEnum } from '@enums';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ProjectGridMilestonesService {
  public createMilestonesColumnDefsFormat(
    milestones: OrganizationMilestone[],
    organizationFamily: OrganizationFamily
  ): ColGroupDef {
    const sortedMilestones = milestones.sort((a, b) => a.ordre - b.ordre);

    const milestoneColumnsDefs: ColDef[] = sortedMilestones
      .map(milestone => {
        if (milestone.idOrganization === organizationFamily.idOrganization) {
          const initialCol: ColDef = {
            headerName:
              milestone.libelle +
              '\n' +
              (organizationFamily.dateInitialNom ? organizationFamily.dateInitialNom : 'Initial'),
            field: milestone.idOrganizationMilestone.toString() + '-initial',
            width: 80,
            hide: true,
            cellClass: 'other-dates',
            valueGetter: (params): string => {
              if (params.data && params.data[milestone.idOrganizationMilestone.toString() + '-initial']) {
                const date = params.data[milestone.idOrganizationMilestone.toString() + '-initial'];
                return date ? date : '';
              }
            },
            cellRenderer: params => {
              if (params.data && params.data[milestone.idOrganizationMilestone.toString() + '-initial']) {
                const date = params.data[milestone.idOrganizationMilestone.toString() + '-initial'];
                return date
                  ? new Date(date).toLocaleDateString('fr-FR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit'
                    })
                  : '';
              }
            }
          };

          const realName = !(organizationFamily.dateInitialEnable && organizationFamily.dateObjectiveEnable)
            ? ''
            : organizationFamily.dateReelNom
              ? organizationFamily.dateReelNom
              : 'Réel';

          const realCol: ColDef = {
            headerName: milestone.libelle + '\n' + realName,
            field: milestone.idOrganizationMilestone.toString(),
            width: 80,
            filter: 'agDateColumnFilter',
            filterParams: AgGridUtils.getDefaultDateFilterParams(),
            editable: false,
            cellClassRules: {
              'unfinished-milestone': params =>
                params.data &&
                params.data[milestone.idOrganizationMilestone.toString()] &&
                params.data[milestone.idOrganizationMilestone.toString()]?.statusAction !==
                  ElementStatusActionEnum['not applicable'] &&
                new Date(params.data[milestone.idOrganizationMilestone.toString()].echeance) > new Date(),
              'finished-milestone': params =>
                params.data &&
                params.data[milestone.idOrganizationMilestone.toString()] &&
                params.data[milestone.idOrganizationMilestone.toString()]?.statusAction !==
                  ElementStatusActionEnum['not applicable'] &&
                new Date() > new Date(params.data[milestone.idOrganizationMilestone.toString()].echeance) &&
                params.data[milestone.idOrganizationMilestone.toString()]?.statusAction !==
                  ElementStatusActionEnum.open,
              'late-milestone': params =>
                params.data &&
                params.data[milestone.idOrganizationMilestone.toString()] &&
                params.data[milestone.idOrganizationMilestone.toString()].echeance &&
                params.data[milestone.idOrganizationMilestone.toString()]?.statusAction !==
                  ElementStatusActionEnum['not applicable'] &&
                new Date() > new Date(params.data[milestone.idOrganizationMilestone.toString()].echeance) &&
                params.data[milestone.idOrganizationMilestone.toString()]?.statusAction ===
                  ElementStatusActionEnum.open,
              'is-computed': params =>
                params.data && params.data[milestone.idOrganizationMilestone.toString()]
                  ? params.data[milestone.idOrganizationMilestone.toString()]?.statusAction !==
                      ElementStatusActionEnum['not applicable'] &&
                    params.data[milestone.idOrganizationMilestone.toString()]?.constraintType?.toLowerCase() !==
                      ConstraintTypeEnum.MSO
                  : false,
              'is-entered': params =>
                params.data && params.data[milestone.idOrganizationMilestone.toString()]
                  ? params.data[milestone.idOrganizationMilestone.toString()]?.constraintType?.toLowerCase() ===
                      ConstraintTypeEnum.MSO &&
                    params.data[milestone.idOrganizationMilestone.toString()]?.statusAction !==
                      ElementStatusActionEnum['not applicable']
                  : false,
              'not-applicable-milestone': params =>
                params.data && params.data[milestone.idOrganizationMilestone.toString()]
                  ? params.data[milestone.idOrganizationMilestone.toString()]?.statusAction ===
                    ElementStatusActionEnum['not applicable']
                  : false,

              'all-cells': () => true
            },
            valueGetter: (params): string => {
              if (params.data && params.data[milestone.idOrganizationMilestone.toString()]) {
                if (
                  params.data[milestone.idOrganizationMilestone.toString()]?.statusAction ===
                  ElementStatusActionEnum['not applicable']
                ) {
                  return 'SO';
                }
                const date = params.data[milestone.idOrganizationMilestone.toString()].echeance;
                return date ? date : '';
              }
            },
            cellRenderer: params => {
              if (params.data && params.data[milestone.idOrganizationMilestone.toString()]) {
                if (
                  params.data[milestone.idOrganizationMilestone.toString()]?.statusAction ===
                  ElementStatusActionEnum['not applicable']
                ) {
                  return 'SO';
                }
                const date = params.data[milestone.idOrganizationMilestone.toString()].echeance;
                return date
                  ? new Date(date).toLocaleDateString('fr-FR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit'
                    })
                  : '';
              }
            }
          };

          const objectiveCol: ColDef = {
            headerName:
              milestone.libelle +
              '\n' +
              (organizationFamily.dateObjectiveNom ? organizationFamily.dateObjectiveNom : 'Objectif'),
            field: milestone.idOrganizationMilestone.toString() + '-objective',
            width: 80,
            hide: true,
            cellClass: 'other-dates',
            valueGetter: (params): string => {
              if (params.data && params.data[milestone.idOrganizationMilestone.toString() + '-objective']) {
                const date = params.data[milestone.idOrganizationMilestone.toString() + '-objective'];
                return date ? date : '';
              }
            },
            cellRenderer: params => {
              if (params.data && params.data[milestone.idOrganizationMilestone.toString() + '-objective']) {
                const date = params.data[milestone.idOrganizationMilestone.toString() + '-objective'];
                return date
                  ? new Date(date).toLocaleDateString('fr-FR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit'
                    })
                  : '';
              }
            }
          };

          const objectiveDisplay = milestone.objectiveDisplay && organizationFamily.dateObjectiveEnable;
          const initialDisplay = milestone.initialDisplay && organizationFamily.dateInitialEnable;

          return objectiveDisplay && initialDisplay
            ? [initialCol, realCol, objectiveCol]
            : objectiveDisplay
              ? [realCol, objectiveCol]
              : initialDisplay
                ? [initialCol, realCol]
                : [realCol];
        }
      })
      .flat();

    const milestoneColumnsGroupDefsDefault: ColGroupDef = {
      headerName: 'Etapes clés',
      children: [...milestoneColumnsDefs]
    };

    return milestoneColumnsGroupDefsDefault;
  }
}
