import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationDataColumn, OrganizationFamily } from '@api/api-interfaces';
import {
  OrganizationDataColumnActions,
  OrganizationDataColumnGeneratedActions,
  OrganizationFamilyGeneratedActions
} from '@wip/store/actions';
import { OrganizationDataColumnApiService } from '@wip/store/api-services';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationDataColumnRelationsIds } from '@wip/store/ids-interfaces';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import {
  GeneratedOrganizationDataColumnEffects,
  getDefaultAddOrganizationDataColumnActions
} from './organization-data-column-generated.effects';
@Injectable()
export class OrganizationDataColumnEffects extends GeneratedOrganizationDataColumnEffects {
  constructor(
    actions$: Actions,
    organizationDataColumnApiService: OrganizationDataColumnApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationDataColumnApiService, store$);
  }

  upsertDataColumn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDataColumnActions.upsertDataColumn),
      concatMap(
        ({
          organizationDataColumn,
          ids
        }: {
          organizationDataColumn: Partial<OrganizationDataColumn>;
          ids?: OrganizationDataColumnRelationsIds;
        }) => {
          if (organizationDataColumn) {
            return this.organizationDataColumnApiService.updateDataColumn(organizationDataColumn).pipe(
              map((organizationFamilyReturned: OrganizationFamily) => {
                return OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert({
                  organizationFamilys: [organizationFamilyReturned]
                });
              }),
              catchError(error => of(OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure({ error })))
            );
          } else {
            return this.organizationDataColumnApiService.addOrganizationDataColumn(organizationDataColumn).pipe(
              mergeMap((organizationDataColumnReturned: OrganizationDataColumn) =>
                getDefaultAddOrganizationDataColumnActions(organizationDataColumnReturned, ids)
              ),
              catchError(error => of(OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure({ error })))
            );
          }
        }
      )
    );
  });
}
