import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { OrganizationAzure, OrganizationAzureEntityState } from '@api/api-interfaces';
import { OrganizationElement, OrganizationElementEntityState } from '@api/api-interfaces';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { OrganizationUser, OrganizationUserEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { OrganizationRisk, OrganizationRiskEntityState } from '@api/api-interfaces';
import { OrganizationKpi, OrganizationKpiEntityState } from '@api/api-interfaces';
import { OrganizationSubFamily, OrganizationSubFamilyEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { OrganizationUserProfil, OrganizationUserProfilEntityState } from '@api/api-interfaces';
import { OrganizationStep, OrganizationStepEntityState } from '@api/api-interfaces';
import { OrganizationProjectModule, OrganizationProjectModuleEntityState } from '@api/api-interfaces';
import { OrganizationSection, OrganizationSectionEntityState } from '@api/api-interfaces';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { Section, SectionEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationRelations: string[] = [
  'communities',
  'organizationAzures',
  'organizationElements',
  'groups',
  'organizationUsers',
  'organizationFamilys',
  'organizationMilestoneFamilys',
  'organizationMilestones',
  'organizationRisks',
  'organizationKpis',
  'organizationSubFamilys',
  'organizationCaracteristics',
  'organizationUserProfils',
  'organizationSteps',
  'organizationProjectModules',
  'organizationSections',
  'profils',
  'users',
  'sections'
];

export const { selectEntities, selectAll } = OrganizationState.adapter.getSelectors();

export const selectOrganizationState = createFeatureSelector<OrganizationState.IState>(
  OrganizationState.organizationFeatureKey
);

export const selectIsLoadedOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState.IState) => state.isLoaded
);

export const selectIsLoadingOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState.IState) => state.isLoading
);

export const selectIsReadyOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationsEntities = createSelector(selectOrganizationState, selectEntities);

export const selectOrganizationsArray = createSelector(selectOrganizationState, selectAll);

export const selectIdOrganizationsActive = createSelector(
  selectOrganizationState,
  (state: OrganizationState.IState) => state.actives
);

const organizationsInObject = (organizations: Dictionary<OrganizationEntityState>) => ({ organizations });

const selectOrganizationsEntitiesDictionary = createSelector(selectOrganizationsEntities, organizationsInObject);

const selectAllOrganizationsObject = createSelector(selectOrganizationsEntities, organizations => {
  return hydrateAll({ organizations });
});

const selectOneOrganizationDictionary = (idOrganization: number) =>
  createSelector(selectOrganizationsEntities, organizations => ({
    organizations: { [idOrganization]: organizations[idOrganization] }
  }));

const selectOneOrganizationDictionaryWithoutChild = (idOrganization: number) =>
  createSelector(selectOrganizationsEntities, organizations => ({
    organization: organizations[idOrganization]
  }));

const selectActiveOrganizationsEntities = createSelector(
  selectIdOrganizationsActive,
  selectOrganizationsEntities,
  (actives: number[], organizations: Dictionary<OrganizationEntityState>) =>
    getOrganizationsFromActives(actives, organizations)
);

function getOrganizationsFromActives(
  actives: number[],
  organizations: Dictionary<OrganizationEntityState>
): Dictionary<OrganizationEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizations[idActive]) {
      acc[idActive] = organizations[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationEntityState>);
}

const selectAllOrganizationsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizations(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Organization>(
      schema,
      selectAllOrganizationsSelectors,
      selectOrganizationsEntitiesDictionary,
      getRelationSelectors,
      organizationRelations,
      hydrateAll,
      'organization'
    );
  } else {
    return selectAllOrganizationsObject;
  }
}

export function selectAllOrganizationsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizations'
): Selector {
  return createSelector(selectAllOrganizations(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizations.length; i++) {
      res[customKey][result.organizations[i].idOrganization] = result.organizations[i];
    }
    return res;
  });
}

export function selectOneOrganization(schema: SelectSchema = {}, idOrganization: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationDictionary(idOrganization)];
    selectors.push(...getRelationSelectors(schema, organizationRelations, 'organization'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationDictionaryWithoutChild(idOrganization);
  }
}

export function selectActiveOrganizations(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationsEntities, organizations => ({
      organizations
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationRelations, 'organization'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizations: Dictionary<OrganizationEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  organizationAzures?: Dictionary<OrganizationAzureEntityState>;
  organizationElements?: Dictionary<OrganizationElementEntityState>;
  groups?: Dictionary<GroupEntityState>;
  organizationUsers?: Dictionary<OrganizationUserEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  organizationMilestoneFamilys?: Dictionary<OrganizationMilestoneFamilyEntityState>;
  organizationMilestones?: Dictionary<OrganizationMilestoneEntityState>;
  organizationRisks?: Dictionary<OrganizationRiskEntityState>;
  organizationKpis?: Dictionary<OrganizationKpiEntityState>;
  organizationSubFamilys?: Dictionary<OrganizationSubFamilyEntityState>;
  organizationCaracteristics?: Dictionary<OrganizationCaracteristicEntityState>;
  organizationUserProfils?: Dictionary<OrganizationUserProfilEntityState>;
  organizationSteps?: Dictionary<OrganizationStepEntityState>;
  organizationProjectModules?: Dictionary<OrganizationProjectModuleEntityState>;
  organizationSections?: Dictionary<OrganizationSectionEntityState>;
  profils?: Dictionary<ProfilEntityState>;
  users?: Dictionary<UserEntityState>;
  sections?: Dictionary<SectionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizations: (Organization | null)[] } {
  const {
    organizations,
    communities,
    organizationAzures,
    organizationElements,
    groups,
    organizationUsers,
    organizationFamilys,
    organizationMilestoneFamilys,
    organizationMilestones,
    organizationRisks,
    organizationKpis,
    organizationSubFamilys,
    organizationCaracteristics,
    organizationUserProfils,
    organizationSteps,
    organizationProjectModules,
    organizationSections,
    profils,
    users,
    sections
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizations: Object.keys(organizations).map(idOrganization =>
      hydrate(
        organizations[idOrganization] as OrganizationEntityState,
        communities,
        organizationAzures,
        organizationElements,
        groups,
        organizationUsers,
        organizationFamilys,
        organizationMilestoneFamilys,
        organizationMilestones,
        organizationRisks,
        organizationKpis,
        organizationSubFamilys,
        organizationCaracteristics,
        organizationUserProfils,
        organizationSteps,
        organizationProjectModules,
        organizationSections,
        profils,
        users,
        sections
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organization: OrganizationEntityState | null } {
  const {
    organizations,
    communities,
    organizationAzures,
    organizationElements,
    groups,
    organizationUsers,
    organizationFamilys,
    organizationMilestoneFamilys,
    organizationMilestones,
    organizationRisks,
    organizationKpis,
    organizationSubFamilys,
    organizationCaracteristics,
    organizationUserProfils,
    organizationSteps,
    organizationProjectModules,
    organizationSections,
    profils,
    users,
    sections
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organization = Object.values(organizations)[0];
  return {
    organization: hydrate(
      organization as OrganizationEntityState,
      communities,
      organizationAzures,
      organizationElements,
      groups,
      organizationUsers,
      organizationFamilys,
      organizationMilestoneFamilys,
      organizationMilestones,
      organizationRisks,
      organizationKpis,
      organizationSubFamilys,
      organizationCaracteristics,
      organizationUserProfils,
      organizationSteps,
      organizationProjectModules,
      organizationSections,
      profils,
      users,
      sections
    )
  };
}

function hydrate(
  organization: OrganizationEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  organizationAzureEntities?: Dictionary<OrganizationAzureEntityState>,
  organizationElementEntities?: Dictionary<OrganizationElementEntityState>,
  groupEntities?: Dictionary<GroupEntityState>,
  organizationUserEntities?: Dictionary<OrganizationUserEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  organizationMilestoneFamilyEntities?: Dictionary<OrganizationMilestoneFamilyEntityState>,
  organizationMilestoneEntities?: Dictionary<OrganizationMilestoneEntityState>,
  organizationRiskEntities?: Dictionary<OrganizationRiskEntityState>,
  organizationKpiEntities?: Dictionary<OrganizationKpiEntityState>,
  organizationSubFamilyEntities?: Dictionary<OrganizationSubFamilyEntityState>,
  organizationCaracteristicEntities?: Dictionary<OrganizationCaracteristicEntityState>,
  organizationUserProfilEntities?: Dictionary<OrganizationUserProfilEntityState>,
  organizationStepEntities?: Dictionary<OrganizationStepEntityState>,
  organizationProjectModuleEntities?: Dictionary<OrganizationProjectModuleEntityState>,
  organizationSectionEntities?: Dictionary<OrganizationSectionEntityState>,
  profilEntities?: Dictionary<ProfilEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  sectionEntities?: Dictionary<SectionEntityState>
): Organization | null {
  if (!organization) {
    return null;
  }

  const organizationHydrated: OrganizationEntityState = { ...organization };

  if (communityEntities) {
    organizationHydrated.communities = ((organizationHydrated.communities as number[]) || []).map(
      id => communityEntities[id]
    ) as Community[];
  } else {
    delete organizationHydrated.communities;
  }

  if (organizationAzureEntities) {
    organizationHydrated.organizationAzures = ((organizationHydrated.organizationAzures as number[]) || []).map(
      id => organizationAzureEntities[id]
    ) as OrganizationAzure[];
  } else {
    delete organizationHydrated.organizationAzures;
  }

  if (organizationElementEntities) {
    organizationHydrated.organizationElements = ((organizationHydrated.organizationElements as number[]) || []).map(
      id => organizationElementEntities[id]
    ) as OrganizationElement[];
  } else {
    delete organizationHydrated.organizationElements;
  }

  if (groupEntities) {
    organizationHydrated.groups = ((organizationHydrated.groups as number[]) || []).map(
      id => groupEntities[id]
    ) as Group[];
  } else {
    delete organizationHydrated.groups;
  }

  if (organizationUserEntities) {
    organizationHydrated.organizationUsers = ((organizationHydrated.organizationUsers as number[]) || []).map(
      id => organizationUserEntities[id]
    ) as OrganizationUser[];
  } else {
    delete organizationHydrated.organizationUsers;
  }

  if (organizationFamilyEntities) {
    organizationHydrated.organizationFamilys = ((organizationHydrated.organizationFamilys as number[]) || []).map(
      id => organizationFamilyEntities[id]
    ) as OrganizationFamily[];
  } else {
    delete organizationHydrated.organizationFamilys;
  }

  if (organizationMilestoneFamilyEntities) {
    organizationHydrated.organizationMilestoneFamilys = (
      (organizationHydrated.organizationMilestoneFamilys as number[]) || []
    ).map(id => organizationMilestoneFamilyEntities[id]) as OrganizationMilestoneFamily[];
  } else {
    delete organizationHydrated.organizationMilestoneFamilys;
  }

  if (organizationMilestoneEntities) {
    organizationHydrated.organizationMilestones = ((organizationHydrated.organizationMilestones as number[]) || []).map(
      id => organizationMilestoneEntities[id]
    ) as OrganizationMilestone[];
  } else {
    delete organizationHydrated.organizationMilestones;
  }

  if (organizationRiskEntities) {
    organizationHydrated.organizationRisks = ((organizationHydrated.organizationRisks as number[]) || []).map(
      id => organizationRiskEntities[id]
    ) as OrganizationRisk[];
  } else {
    delete organizationHydrated.organizationRisks;
  }

  if (organizationKpiEntities) {
    organizationHydrated.organizationKpis = ((organizationHydrated.organizationKpis as number[]) || []).map(
      id => organizationKpiEntities[id]
    ) as OrganizationKpi[];
  } else {
    delete organizationHydrated.organizationKpis;
  }

  if (organizationSubFamilyEntities) {
    organizationHydrated.organizationSubFamilys = ((organizationHydrated.organizationSubFamilys as number[]) || []).map(
      id => organizationSubFamilyEntities[id]
    ) as OrganizationSubFamily[];
  } else {
    delete organizationHydrated.organizationSubFamilys;
  }

  if (organizationCaracteristicEntities) {
    organizationHydrated.organizationCaracteristics = (
      (organizationHydrated.organizationCaracteristics as number[]) || []
    ).map(id => organizationCaracteristicEntities[id]) as OrganizationCaracteristic[];
  } else {
    delete organizationHydrated.organizationCaracteristics;
  }

  if (organizationUserProfilEntities) {
    organizationHydrated.organizationUserProfils = (
      (organizationHydrated.organizationUserProfils as number[]) || []
    ).map(id => organizationUserProfilEntities[id]) as OrganizationUserProfil[];
  } else {
    delete organizationHydrated.organizationUserProfils;
  }

  if (organizationStepEntities) {
    organizationHydrated.organizationSteps = ((organizationHydrated.organizationSteps as number[]) || []).map(
      id => organizationStepEntities[id]
    ) as OrganizationStep[];
  } else {
    delete organizationHydrated.organizationSteps;
  }

  if (organizationProjectModuleEntities) {
    organizationHydrated.organizationProjectModules = (
      (organizationHydrated.organizationProjectModules as number[]) || []
    ).map(id => organizationProjectModuleEntities[id]) as OrganizationProjectModule[];
  } else {
    delete organizationHydrated.organizationProjectModules;
  }

  if (organizationSectionEntities) {
    organizationHydrated.organizationSections = ((organizationHydrated.organizationSections as number[]) || []).map(
      id => organizationSectionEntities[id]
    ) as OrganizationSection[];
  } else {
    delete organizationHydrated.organizationSections;
  }

  if (profilEntities) {
    organizationHydrated.profils = ((organizationHydrated.profils as number[]) || []).map(
      id => profilEntities[id]
    ) as Profil[];
  } else {
    delete organizationHydrated.profils;
  }

  if (userEntities) {
    organizationHydrated.users = ((organizationHydrated.users as number[]) || []).map(id => userEntities[id]) as User[];
  } else {
    delete organizationHydrated.users;
  }

  if (sectionEntities) {
    organizationHydrated.sections = ((organizationHydrated.sections as number[]) || []).map(
      id => sectionEntities[id]
    ) as Section[];
  } else {
    delete organizationHydrated.sections;
  }

  return organizationHydrated as Organization;
}
