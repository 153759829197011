import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import * as OrganizationElementAction from '@wip/store/actions';
import { GeneratedOrganizationElementService } from './organization-element-generated.service';
import { Actions } from '@ngrx/effects';
import { OrganizationElement, UploadFileInterface } from '@api/api-interfaces';
import { Observable } from 'rxjs';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { IupdateOrganizationElement } from '@dto';

@Injectable({
  providedIn: 'root'
})
export class OrganizationElementService extends GeneratedOrganizationElementService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public uploadFile(params: UploadFileInterface, getResult?: boolean): void | Observable<OrganizationElement> {
    this.store$.dispatch(
      OrganizationElementAction.uploadNewFile({
        params: { formData: params.formData, idOrganization: params.idOrganization, ordre: params.ordre }
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationElementAction.OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert,
        OrganizationElementAction.OrganizationElementGeneratedActions.organizationElementsFailure,
        true
      );
    }
  }

  public updateOrganizationElement(
    params: IupdateOrganizationElement,
    getResult?: boolean
  ): void | Observable<OrganizationElement> {
    this.store$.dispatch(OrganizationElementAction.updateOrganizationElement({ params }));

    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationElementAction.OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert,
        OrganizationElementAction.OrganizationElementGeneratedActions.organizationElementsFailure,
        true
      );
    }
  }
}
