import { Action, createReducer } from '@ngrx/store';
import { communityDataRowReducersGeneratedFunctions } from './community-data-row-generated.reducer';
import { CommunityDataRowGeneratedState, CommunityDataRowState } from '@wip/store/states';

const communityDataRowReducersFunctions = [...communityDataRowReducersGeneratedFunctions];

const communityDataRowReducer = createReducer(
  CommunityDataRowGeneratedState.initialState,
  ...communityDataRowReducersFunctions
);

export function reducer(state: CommunityDataRowState.IState | undefined, action: Action) {
  return communityDataRowReducer(state, action);
}
