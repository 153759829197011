import { CommunityState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityReducersGeneratedFunctions } from './community-generated.reducer';

const communityReducersFunctions = [...communityReducersGeneratedFunctions];

const communityReducer = createReducer(CommunityState.initialState, ...communityReducersFunctions);

export function reducer(state: CommunityState.IState | undefined, action: Action) {
  return communityReducer(state, action);
}
