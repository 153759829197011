import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedOrganizationMilestoneService } from './organization-milestone-generated.service';
import { OrganizationMilestoneRelationsIds } from '@wip/store/ids-interfaces';
import { Actions } from '@ngrx/effects';
import * as OrganizationMilestoneAction from '@wip/store/actions';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { OrganizationMilestone } from '@api/api-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationMilestoneService extends GeneratedOrganizationMilestoneService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public upsertOneOrganizationMilestoneWithFamilies(
    organizationMilestone: Partial<OrganizationMilestone>,
    ids: OrganizationMilestoneRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestone> {
    this.store$.dispatch(
      OrganizationMilestoneAction.upsertOneOrganizationMilestoneWithFamilies({ organizationMilestone, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneAction.OrganizationMilestoneGeneratedActions
          .normalizeManyOrganizationMilestonesAfterUpsert,
        OrganizationMilestoneAction.OrganizationMilestoneGeneratedActions.organizationMilestonesFailure,
        true
      );
    }
  }
}
