import { ConfirmationChoicesInterface } from '@wip/interfaces';
import { ConfirmationChoicesDialogContainerComponent } from '@wip/dialogs/confirmation-choices-dialog';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ElementsService {
  private focusBehaviorSubject = new BehaviorSubject<boolean>(false);
  private manageUpdateBehaviorSubject = new BehaviorSubject<boolean>(false);
  private subscribeAfterEditingSubject = new BehaviorSubject<boolean>(false);
  currentFocus$ = this.focusBehaviorSubject.asObservable();
  currentUpdate$ = this.manageUpdateBehaviorSubject.asObservable();
  isEditing$ = this.subscribeAfterEditingSubject.asObservable();

  constructor(private dialog: MatDialog) {}

  changeFocus(focus: boolean) {
    this.focusBehaviorSubject.next(focus);
  }

  subscribeAfterEditing(isEditing: boolean) {
    this.subscribeAfterEditingSubject.next(isEditing);
  }

  manageUpdateActionWhenTinyMCEQuit(manageUpdate: boolean) {
    this.manageUpdateBehaviorSubject.next(manageUpdate);
  }

  /**
   * @description Handle a confirmation before cancel an edit
   * @param elementForm The Form(s) concerned, to access his (their) dirty attribute (must be FormGroup or FormArray)
   * @param cb To return the user choice
   */
  confirmBeforeCancel(elementForm: any, cb: Function) {
    if (elementForm.dirty) {
      const actions: ConfirmationChoicesInterface[] = [
        {
          color: 'warn',
          label: 'Quitter et annuler'
        },
        {
          color: '',
          label: 'Rester'
        }
      ];

      const confirmationDialog = this.dialog.open(ConfirmationChoicesDialogContainerComponent, {
        data: {
          title: 'Avertissement',
          body: 'Vous avez des modifications non sauvegardées. Etes-vous sûr de vouloir quitter ?',
          actions: actions
        }
      });

      confirmationDialog
        .afterClosed()
        .pipe(untilDestroyed(this), take(1))
        .subscribe(res => {
          return cb(res);
        });
    } else {
      return cb(true);
    }
  }

  /**
   * @description Handle a confirmation before delete an element
   * @param customBodyTranslation As the description message change depending of the element, we need to give it
   * @param cb To return the user choice
   */
  confirmBeforeDelete(customBodyTranslation: string): Observable<boolean> {
    const actions: ConfirmationChoicesInterface[] = [
      {
        color: 'warn',
        label: 'Supprimer'
      },
      {
        color: '',
        label: 'Annuler'
      }
    ];

    return this.dialog
      .open(ConfirmationChoicesDialogContainerComponent, {
        data: {
          title: 'Suppression',
          body: customBodyTranslation,
          actions
        }
      })
      .afterClosed()
      .pipe(untilDestroyed(this));
  }
}
