@if (!isMilestone() || !isOpenedFromMask) {
  <div [formGroup]="actionForm">
    @if (!idProjectElement) {
      <up-date-input style="width: 140px" formControlName="echeance" [isDisabled]="false" [placeholder]="'Date'">
      </up-date-input>
    }
    @if (!isOpenedFromMask && idProjectElement) {
      <div class="flex flex-row gap-16">
        <!-- duration -->
        @if (!ganttLite && !isMilestone()) {
          <div style="height: 48px">
            <div>
              <mat-form-field
                style="width: 75px"
                floatLabel="always"
                (keydown.enter)="$event.preventDefault()"
                (keyup.enter)="$event.stopPropagation()"
              >
                <mat-label>Durée</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  formControlName="duration"
                  type="text"
                  class="day-input"
                  appTest
                  [ngClass]="{ 'bold-font': checkMustDurationBeDisplayedInBold }"
                />
                @if (actionForm.get('duration').enabled && actionForm.get('duration').value) {
                  <button
                    matSuffix
                    mat-icon-button
                    [disabled]="isViewer"
                    aria-label="Clear"
                    style="margin-top: 3px"
                    class="close-button"
                    (click)="resetDuration()"
                  >
                    <mat-icon class="close-button">close</mat-icon>
                  </button>
                }
              </mat-form-field>
            </div>
            @if (actionForm.hasError('needEcheance')) {
              <mat-error class="error-msg"> Vous devez indiquer une échéance </mat-error>
            }
          </div>
        }
        <!-- Date start (gantt avancé) -->
        @if (!ganttLite && !isMilestone()) {
          <div>
            <up-date-input style="width: 140px" formControlName="dateStart" [placeholder]="'Debut'"> </up-date-input>
          </div>
        }
        <!-- Echeance -->
        <div>
          <up-date-input
            style="width: 140px"
            formControlName="echeance"
            [placeholder]="organizationFamily.dateReelNom ? organizationFamily.dateReelNom : 'Écheance'"
          >
          </up-date-input>
        </div>
        <!-- Initial -->
        @if (isMilestone() && element.organizationMilestone?.initialDisplay && organizationFamily.dateInitialEnable) {
          <div>
            <up-date-input
              style="width: 140px"
              formControlName="dateInitial"
              [placeholder]="organizationFamily.dateInitialNom ? organizationFamily.dateInitialNom : 'Initial'"
            >
            </up-date-input>
          </div>
        }
        <!-- Objectif -->
        @if (
          isMilestone() && element.organizationMilestone?.objectiveDisplay && organizationFamily.dateObjectiveEnable
        ) {
          <div>
            <up-date-input
              style="width: 140px"
              formControlName="dateObjective"
              [placeholder]="organizationFamily.dateObjectiveNom ? organizationFamily.dateObjectiveNom : 'Objectif'"
            >
            </up-date-input>
          </div>
        }
      </div>
    }
  </div>
}
