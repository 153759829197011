import { GoogleMapsEmbedModule } from '@_app/shared/google-maps-embed/google-maps-embed.module';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Community } from '@api/api-interfaces';
@Component({
  selector: 'wip-project-programme',
  templateUrl: './project-programme.component.html',
  styleUrls: ['./project-programme.component.scss'],
  standalone: true,
  imports: [GoogleMapsEmbedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectProgrammeComponent implements OnChanges {
  @Input() community: Community;
  public adresse: string;
  public title: string;
  public width = 300;
  public height = 300;

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.community && this.community) {
      this.adresse = this.community?.codePostal + ' | ' + this.community?.commune + ' | ' + this.community?.adresse;
    }
  }

  public addAdress() {
    this.router.navigate([`app/project/${this.community.idCommunity}/caracteristics`]);
  }
}
