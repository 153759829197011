import { IStatusPanelAngularComp } from '@ag-grid-community/angular';
import { IStatusPanelParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import AgGridCellCommonFormatter from '@_app/utils/ag-grid/cell-formatter/common-cell-formatter';
import { AgGridUtils } from '@_app/utils/ag-grid/cell-formatter/ag-grid.utils';
import { tradFrAgGrid } from '@utils';

interface FilterVisualComponentInputs {
  getColumnState: () => any;

  resetFilter(newFilter: any): () => void;

  getFilterModel: () => any;
  getColumnDef: (s: string) => any;
  getColumnDefs: () => any;
  setColumnState: () => void;
  getQuickFilter: () => any;
  resetQuickFilter: () => void;
}

interface Ifilter {
  column: string;
  values: string;
  key: string;
}

interface nameColumn {
  column: string;
  parent: boolean;
  element: any;
}

export function capitalizeFirstLetter(string): string {
  return string?.length ? string[0].toUpperCase() + string.slice(1) : '';
}

// const translateOrder = {
//   asc: 'croissant',
//   desc: 'décroissant'
// };
@Component({
  templateUrl: './filter-visual.component.html',
  styleUrls: ['./filter-visual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterVisualComponent implements IStatusPanelAngularComp {
  getColumnState: () => any;
  resetFilter: (newFilter: any, manually: boolean) => void;
  getFilterModel: () => any;
  getColumnDefs: () => any;
  getColumnDef: (s: string) => any;
  setColumnState: () => void;
  getQuickFilter: () => any;
  resetQuickFilter: () => void;
  filterList: Ifilter[] = [];
  columnStateList: Ifilter[] = [];
  quickFilterText: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public agInit(params: IStatusPanelParams & FilterVisualComponentInputs): void {
    const {
      getColumnState,
      getFilterModel,
      getColumnDef,
      getColumnDefs,
      resetFilter,
      setColumnState,
      getQuickFilter,
      resetQuickFilter
    } = params;

    this.getColumnState = getColumnState;
    this.getFilterModel = getFilterModel;
    this.getColumnDefs = getColumnDefs;
    this.resetFilter = resetFilter;
    this.setColumnState = setColumnState;
    this.getColumnDef = getColumnDef;
    this.getQuickFilter = getQuickFilter;
    this.resetQuickFilter = resetQuickFilter;
  }

  public async displayFilter(): Promise<void> {
    await this.setFilterModelText(this.getFilterModel());
    this.setQuickFilter();
  }

  public setQuickFilter() {
    this.quickFilterText = this.getQuickFilter()?.trim();
    this.changeDetectorRef.markForCheck();
  }

  public removeColumnState() {
    this.setColumnState();
  }

  public removeFilter(element: any) {
    this.resetFilter(element.key, true);
  }

  public removeQuickFilter() {
    this.resetQuickFilter();
  }

  //  A décommenter quand il faudra mettre les filtres visuals pour les order

  // private getColumnStateTradText(s: string) {
  //   const text = "Dans l'ordre ";
  //   const ordre = translateOrder[s];
  //   if (ordre) {
  //     return text + ordre;
  //   } else {
  //     return 'ordre inconnu';
  //   }
  // }
  // private setColumnStateText(colunmState: any[]) {
  //   this.columnStateList = [];
  //   for (let index = 0; index < colunmState?.length; index++) {
  //     const element = colunmState[index];
  //     if (element['sort']) {
  //       const r: Ifilter = {
  //         column: capitalizeFirstLetter(this.findNameColumn(element.colId)),
  //         values: this.getColumnStateTradText(element.sort),
  //         key: element.colId
  //       };
  //       this.columnStateList.push(r);
  //     }
  //   }
  // }

  private findNameColumn(s: string): string {
    const columndef = this.getColumnDefs();
    for (let index = 0; index < columndef.length; index++) {
      const element = columndef[index];
      const r = this.findName(element, s);
      if (r.column != '' && !r.parent) {
        return r.column;
      } else if (r.parent) {
        return this.findName(element, s, true).column;
      }
    }
  }

  private findName(columDefElement: any, s: string, parentMode: boolean = false): nameColumn {
    if (parentMode) {
      return { column: columDefElement.headerName, parent: true, element: columDefElement };
    }
    if (columDefElement.children) {
      return this.findName(columDefElement.children, s);
    } else {
      for (let x = 0; x < columDefElement.length; x++) {
        const element = columDefElement[x];
        if (element.colId === s) {
          if (element.headerName) {
            return { column: element.headerName, parent: false, element: columDefElement };
          } else {
            return { column: '', parent: true, element: columDefElement };
          }
        }
      }
      return { column: '', parent: false, element: columDefElement };
    }
  }

  private handleText(element: any, key: string) {
    let value;
    if (
      [
        'lessThan',
        'greaterThan',
        'lessThanOrEqual',
        'greaterThanOrEqual',
        'equals',
        'notEqual',
        'notContains',
        'contains',
        'startsWith',
        'endsWith'
      ].includes(element.type)
    ) {
      value = tradFrAgGrid.default[element.type] + ' "' + element.filter + '"';
    } else if (element.type === 'blank') {
      value = 'Valeur Vide';
    } else if (element.type === 'notBlank') {
      value = 'Valeur Non Vide';
    }
    const r: Ifilter = {
      column: this.findNameColumn(key),
      values: value,
      key: key
    };
    this.filterList.push(r);
  }

  private handleNumber(element: any, key: string) {
    let value;
    if (
      ['lessThan', 'greaterThan', 'lessThanOrEqual', 'greaterThanOrEqual', 'equals', 'notEqual'].includes(element.type)
    ) {
      value = 'Valeur ' + tradFrAgGrid.default[element.type] + ' ' + element.filter;
    } else if (element.type === 'inRange') {
      value = this.inRangeNumberValue(element);
    } else if (element.type === 'blank') {
      value = 'Valeur Vide';
    } else if (element.type === 'notBlank') {
      value = 'Valeur Non Vide';
    } else if (element.type === 'inRangeBlank') {
      value = 'entre ' + element.filter + ' et ' + element.filterTo + ' + vide';
    }
    const r: Ifilter = {
      column: this.findNameColumn(key),
      values: value,
      key: key
    };
    this.filterList.push(r);
  }

  private handleDate(element: any, key: string) {
    const customFilter = AgGridUtils.myCustomFilterDate.find(e => e.displayKey === element.type);
    if (customFilter) {
      const r: Ifilter = {
        column: this.findNameColumn(key),
        values: customFilter.displayName,
        key: key
      };
      this.filterList.push(r);
    } else {
      let value: string;
      if (element.type === 'inRange') {
        value = this.inRangeDateValue(element);
      } else if (element.type === 'equals') {
        value = this.equalsDateValue(element);
      }
      const r: Ifilter = {
        column: capitalizeFirstLetter(this.findNameColumn(key)),
        values: value,
        key: key
      };
      this.filterList.push(r);
    }
  }

  private equalsDateValue(element: any): string {
    return 'date Egal à ' + tradFrAgGrid.default.equals + ' ' + AgGridCellCommonFormatter.formatDate(element.dateFrom);
  }

  private inRangeDateValue(element: any): string {
    return (
      'date entre le ' +
      AgGridCellCommonFormatter.formatDate(element.dateFrom) +
      ' et le ' +
      AgGridCellCommonFormatter.formatDate(element.dateTo)
    );
  }

  private inRangeNumberValue(element: any): string {
    return 'valeur entre ' + element.filter + ' et ' + element.filterTo;
  }

  private async setFilterModelText(filterModel: any) {
    this.filterList = [];
    for (const key in filterModel) {
      const element = filterModel[key];
      if (element.filterType == 'set') {
        const r: Ifilter = {
          column: capitalizeFirstLetter(this.findNameColumn(key)),
          values: element.values?.join(', '),
          key: key
        };
        this.filterList.push(r);
      } else if (element.filterType == 'date') {
        this.handleDate(element, key);
      } else if (element.filterType == 'number') {
        this.handleNumber(element, key);
      } else if (element.filterType == 'text') {
        this.handleText(element, key);
      }
    }
  }
}
