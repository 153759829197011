import { CommunityMilestoneState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityMilestoneReducersGeneratedFunctions } from './community-milestone-generated.reducer';

const communityMilestoneReducersFunctions = [...communityMilestoneReducersGeneratedFunctions];

const communityMilestoneReducer = createReducer(
  CommunityMilestoneState.initialState,
  ...communityMilestoneReducersFunctions
);

export function reducer(state: CommunityMilestoneState.IState | undefined, action: Action) {
  return communityMilestoneReducer(state, action);
}
