import { DumpTaskElementFormatInspector } from '@_app/shared/project-external-services/format-inspectors/DumpTaskElementFormatInspector';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConstraintTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { isValidDate } from '@utils';
import { Element, OrganizationFamily } from '@api/api-interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'wip-new-action-date-section',
  templateUrl: './new-action-date-section.component.html',
  styleUrls: ['./new-action-date-section.component.scss']
})
export class NewActionDateSectionComponent implements OnInit {
  @Input() isMso: boolean;
  @Input() isEndDateComputed: boolean;
  @Input() idProjectElement: number;
  @Input() element: Element;
  @Input() isViewer: boolean;
  @Input() placeholdersTranslations: any;
  @Input() isOpenedFromMask = false;
  @Input() isTaskLinked: boolean = false;
  @Input() businessDay: boolean;
  @Input() actionForm: FormGroup;
  @Input() ganttLite: boolean;
  @Input() organizationFamily: OrganizationFamily;

  private destroy$: Subject<void> = new Subject();

  public isProjectElement: boolean;
  public checkMustDurationBeDisplayedInBold: boolean;
  public checkMustStartDateBeDisplayedInBold: boolean;
  public checkMustEndDateBeDisplayedInBold: boolean;
  public dumpTaskElementFormatInspector: DumpTaskElementFormatInspector;

  constructor(
    private router: Router,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    this.isOpenedFromMask = this.isCurrentlyInMask(this.router.url);
    [this.isProjectElement, this.isMso, this.isEndDateComputed] = this.computeConstraintStyles();
    this.subscribeToHasDurationChanges();
    this.handleCanSetMilestoneChanges();
    this.handleDateStartChanges();
    this.handleDateInitialChanges();
    this.setDumpTaskElementFormatInspection();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private isCurrentlyInMask(url: string): boolean {
    return url.includes('/mask/');
  }

  private computeConstraintStyles(): [boolean, boolean, boolean] {
    let isProjectElement = false;
    let isMso: boolean;
    let isEndDateComputed: boolean;
    isProjectElement = true;
    isMso = this.isMso;
    isEndDateComputed = this.isEndDateComputed;
    return [isProjectElement, isMso, isEndDateComputed];
  }

  private handleCanSetMilestoneChanges() {
    this.placeholdersTranslations = {
      DateStart: 'General.StartDate',
      Deadline: 'General.Date'
    };
  }

  private handleDateStartChanges() {
    this.actionForm
      .get('dateStart')
      .valueChanges.pipe(untilDestroyed(this), takeUntil(this.destroy$))
      .subscribe((dateStart: any) => {
        if (dateStart === null || isValidDate(dateStart)) {
          //
        }
      });
  }

  private handleDateInitialChanges() {
    if (this.actionForm.controls.dateInitial) {
      this.actionForm
        .get('dateInitial')
        .valueChanges.pipe(untilDestroyed(this), takeUntil(this.destroy$))
        .subscribe((dateInitial: any) => {
          const dateStart = this.actionForm.get('dateStart').value;
          if (!dateStart) {
            this.actionForm.get('dateStart').patchValue(dateInitial);
          }
        });
    }
  }

  private subscribeToHasDurationChanges() {
    this.actionForm.get('hasDuration').valueChanges.subscribe(() => {
      this.setDatePlaceholderTranslations();

      this.actionForm.get('dateStart').markAsDirty();
    });
  }

  resetDate(): void {
    this.actionForm.get('echeance').reset();

    this.actionForm.get('echeance').markAsDirty();

    if (!this.isProjectElement) {
      this.actionForm.get('duration').reset();
    }
  }

  resetDateStart(): void {
    this.actionForm.get('dateStart').reset();

    this.actionForm.get('dateStart').markAsDirty();

    if (!this.isProjectElement) {
      this.actionForm.get('duration').reset();
    }
  }

  resetDateInitial(): void {
    this.actionForm.get('dateInitial').reset();

    this.actionForm.get('dateInitial').markAsDirty();
  }

  resetDuration() {
    this.actionForm.get('duration').reset();

    this.actionForm.get('duration').markAsDirty();
  }

  setDatePlaceholderTranslations() {
    this.placeholdersTranslations = {
      DateStart: 'General.StartDate'
    };
    if (this.hasDurationValue && this.actionForm && !this.actionForm.get('canSetMilestone').value) {
      this.placeholdersTranslations = {
        ...this.placeholdersTranslations,
        Deadline: 'General.Deadline'
      };
      if (this.actionForm.get('dateStart').value === null) {
        this.actionForm.get('dateStart').patchValue(this.actionForm.get('echeance').value, {
          emitEvent: false
        });
        this.actionForm.get('dateStart').markAsPristine();
      }
    } else {
      this.placeholdersTranslations = {
        ...this.placeholdersTranslations,
        Deadline: 'General.Date'
      };
    }
  }

  private setDumpTaskElementFormatInspection(): void {
    if (this.dumpTaskElementFormatInspector) {
      delete this.dumpTaskElementFormatInspector;
    }
    if (this.idProjectElement) {
      this.dumpTaskElementFormatInspector = new DumpTaskElementFormatInspector({
        constraintType: this.actionForm.get('constraintType').value as ConstraintTypeEnum,
        isEndDateComputed: this.actionForm.get('isEndDateComputed').value as boolean
      });
    }
    this.checkMustDurationBeDisplayedInBold = this.mustDurationBeDisplayedInBold();
    this.checkMustStartDateBeDisplayedInBold = this.mustStartDateBeDisplayedInBold();
    this.checkMustEndDateBeDisplayedInBold = this.mustEndDateBeDisplayedInBold();
  }

  get hasDurationValue() {
    const hasDurationValue = this.actionForm ? this.actionForm.get('hasDuration').value : false;
    if (this.actionForm && this.actionForm.get('canSetMilestone').value) {
      return false;
    }
    return !!hasDurationValue;
  }

  get echeanceValue() {
    return this.actionForm.get('echeance').value;
  }

  private mustDurationBeDisplayedInBold(): boolean {
    if (!this.idProjectElement) {
      return false;
    }
    return this.dumpTaskElementFormatInspector.durationMustBeDisplayedInBold();
  }

  private mustStartDateBeDisplayedInBold(): boolean {
    if (!this.idProjectElement) {
      return false;
    }
    return this.dumpTaskElementFormatInspector.startDateMustBeDisplayedInBold();
  }

  private mustEndDateBeDisplayedInBold(): boolean {
    if (!this.idProjectElement) {
      return false;
    }
    return this.dumpTaskElementFormatInspector.endDateMustBeDisplayedInBold();
  }

  public isMilestone() {
    return this.element.idOrganizationMilestone;
  }
}
