import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CommunityDataRow } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { CommunityDataRowRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityDataRowGeneratedActions } from '@wip/store/actions';
import { CommunityDataRowSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCommunityDataRowService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CommunityDataRowSelectors.selectIsLoadedCommunityDataRow));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CommunityDataRowSelectors.selectIsLoadingCommunityDataRow));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      CommunityDataRowSelectors.selectIsReadyAndLoadedCommunityDataRow as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCommunityDataRows(schema: SelectSchema = {}): Observable<CommunityDataRow[]> {
    return this.store$.pipe(select(CommunityDataRowSelectors.selectAllCommunityDataRows(schema))).pipe(
      switchMap(({ communityDataRows }: { communityDataRows: CommunityDataRow[] }) => {
        return this.getReady(schema).pipe(mapTo(communityDataRows));
      })
    );
  }

  public selectOneCommunityDataRow(
    idCommunityDataRow: number,
    schema: SelectSchema = {}
  ): Observable<CommunityDataRow> {
    return this.store$
      .pipe(select(CommunityDataRowSelectors.selectOneCommunityDataRow(schema, idCommunityDataRow)))
      .pipe(
        switchMap(({ communityDataRow }: { communityDataRow: CommunityDataRow }) => {
          return this.getReady(schema).pipe(mapTo(communityDataRow));
        })
      );
  }

  public selectAllActiveCommunityDataRows(schema: SelectSchema = {}): Observable<CommunityDataRow[]> {
    return this.store$.pipe(select(CommunityDataRowSelectors.selectActiveCommunityDataRows(schema))).pipe(
      switchMap(({ communityDataRows }: { communityDataRows: CommunityDataRow[] }) => {
        return this.getReady(schema).pipe(mapTo(communityDataRows));
      })
    );
  }

  public selectIdCommunityDataRowsActive(): Observable<number[]> {
    return this.store$.pipe(select(CommunityDataRowSelectors.selectIdCommunityDataRowsActive)).pipe(
      switchMap((idCommunityDataRows: number[]) => {
        return this.getReady().pipe(mapTo(idCommunityDataRows));
      })
    );
  }

  public getOneCommunityDataRow(
    idCommunityDataRow: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<CommunityDataRow> {
    this.store$.dispatch(CommunityDataRowGeneratedActions.getOneCommunityDataRow({ idCommunityDataRow, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityDataRowGeneratedActions.normalizeManyCommunityDataRowsAfterUpsert,
        CommunityDataRowGeneratedActions.communityDataRowsFailure,
        true
      );
    }
  }

  public getManyCommunityDataRows(params: any = {}, getResult?: boolean): void | Observable<CommunityDataRow[]> {
    this.store$.dispatch(CommunityDataRowGeneratedActions.getManyCommunityDataRows({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityDataRowGeneratedActions.normalizeManyCommunityDataRowsAfterUpsert,
        CommunityDataRowGeneratedActions.communityDataRowsFailure
      );
    }
  }

  public upsertOneCommunityDataRow(
    communityDataRow: Partial<CommunityDataRow>,
    ids: CommunityDataRowRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CommunityDataRow> {
    this.store$.dispatch(CommunityDataRowGeneratedActions.upsertOneCommunityDataRow({ communityDataRow, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityDataRowGeneratedActions.normalizeManyCommunityDataRowsAfterUpsert,
        CommunityDataRowGeneratedActions.communityDataRowsFailure,
        true
      );
    }
  }

  public deleteOneCommunityDataRow(idCommunityDataRow: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CommunityDataRowGeneratedActions.deleteOneCommunityDataRow({ idCommunityDataRow }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityDataRowGeneratedActions.deleteOneCommunityDataRowSuccess,
        CommunityDataRowGeneratedActions.communityDataRowsFailure
      );
    }
  }

  public setActiveCommunityDataRows(idCommunityDataRows: number[]): void {
    this.store$.dispatch(CommunityDataRowGeneratedActions.clearActivesCommunityDataRows());
    this.store$.dispatch(CommunityDataRowGeneratedActions.addManyActivesCommunityDataRows({ idCommunityDataRows }));
  }
}
