export enum CommunityTypeStructureEnum {
  general = 'general',
  private = 'private',
  directMessage = 'directMessage',
  project = 'project',
  community = 'community',
  meeting = 'meeting',
  project_template = 'project_template',
  support = 'support',
  dashboard = 'dashboard',
  development = 'development'
}
