import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityKpiGeneratedActions } from '@wip/store/actions';
import { CommunityKpiState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityKpiEntityState } from '@api/api-interfaces';

export const communityKpiReducersGeneratedFunctions: ReducerTypes<
  CommunityKpiState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunityKpiGeneratedActions.getOneCommunityKpi, (state: CommunityKpiState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityKpiGeneratedActions.getManyCommunityKpis, (state: CommunityKpiState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityKpiGeneratedActions.upsertOneCommunityKpi, (state: CommunityKpiState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    CommunityKpiGeneratedActions.upsertManyCommunityKpisSuccess,
    (state: CommunityKpiState.IState, { communityKpis }) =>
      CommunityKpiState.adapter.upsertMany(communityKpis, setLoadingsState(state, false))
  ),
  on(CommunityKpiGeneratedActions.deleteOneCommunityKpi, (state: CommunityKpiState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityKpiGeneratedActions.deleteOneCommunityKpiSuccess, (state: CommunityKpiState.IState, { idCommunityKpi }) =>
    CommunityKpiState.adapter.removeOne(idCommunityKpi, setLoadingsState(state, false))
  ),
  on(CommunityKpiGeneratedActions.clearActivesCommunityKpis, (state: CommunityKpiState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    CommunityKpiGeneratedActions.addManyActivesCommunityKpis,
    (state: CommunityKpiState.IState, { idCommunityKpis }) => ({
      ...state,
      actives: state.actives.concat(idCommunityKpis)
    })
  ),
  on(
    CommunityKpiGeneratedActions.deleteOneActiveCommunityKpi,
    (state: CommunityKpiState.IState, { idCommunityKpi }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityKpi)
    })
  ),

  on(CommunityKpiGeneratedActions.clearCommunityKpis, () => CommunityKpiState.initialState),

  on(
    CommunityKpiGeneratedActions.addOrganizationKpiSuccess,
    (state: CommunityKpiState.IState, { idCommunityKpi, idOrganizationKpi }) => {
      if (!state.entities[idCommunityKpi]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityKpi]: {
            ...state.entities[idCommunityKpi],
            organizationKpi: idOrganizationKpi
          }
        }
      };
    }
  ),

  on(
    CommunityKpiGeneratedActions.deleteManyOrganizationKpiSuccess,
    (state: CommunityKpiState.IState, { idOrganizationKpis, idCommunityKpis }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityKpis.reduce((entities, idCommunityKpi) => {
            if (!state.entities[idCommunityKpi]?.organizationKpi) {
              return entities;
            }
            entities[idCommunityKpi] = {
              ...state.entities[idCommunityKpi],
              organizationKpi: idOrganizationKpis.some(
                (idOrganizationKpi: number) => idOrganizationKpi === state.entities[idCommunityKpi]?.organizationKpi
              )
                ? undefined
                : state.entities[idCommunityKpi]?.organizationKpi
            } as CommunityKpiEntityState;
            return entities;
          }, {} as Dictionary<CommunityKpiEntityState>)
        }
      };
    }
  ),

  on(
    CommunityKpiGeneratedActions.addCommunitySuccess,
    (state: CommunityKpiState.IState, { idCommunityKpi, idCommunity }) => {
      if (!state.entities[idCommunityKpi]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityKpi]: {
            ...state.entities[idCommunityKpi],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityKpiGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityKpiState.IState, { idCommunities, idCommunityKpis }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityKpis.reduce((entities, idCommunityKpi) => {
            if (!state.entities[idCommunityKpi]?.community) {
              return entities;
            }
            entities[idCommunityKpi] = {
              ...state.entities[idCommunityKpi],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityKpi]?.community
              )
                ? undefined
                : state.entities[idCommunityKpi]?.community
            } as CommunityKpiEntityState;
            return entities;
          }, {} as Dictionary<CommunityKpiEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityKpiState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityKpiState.IState {
  return { ...state, isLoaded, isLoading };
}
