<div mat-dialog-title class="flex flex-row justify-between gap-8">
  <div class="flex flex-row gap-8 justify-start items-center">
    <h1>{{ dialogData.idProjectElement ? 'Edition livrable' : 'Edition tâche' }}</h1>
    @if (dialogData.isViewer) {
      <i
        [matTooltip]="'Vous n\'avez pas les droits nécessaires pour modifier cet élément'"
        class="icon icon-ic_fluent_lock_closed_24_regular"
        style="font-size: 20px"
      ></i>
    }
  </div>
  <button mat-icon-button class="gulp gulp-34-close" (click)="cancel()"></button>
</div>

@if (actionForm && currentElement) {
  <div mat-dialog-content>
    @if (dialogData.type !== 'project') {
      <div class="flex flex-col gap-8">
        <div class="flex flex-row gap-8">
          <wip-new-action-status-section
            [isViewer]="dialogData.isViewer"
            [actionService]="actionService"
            [element]="currentElement"
            [actionForm]="actionForm"
          >
          </wip-new-action-status-section>
          <wip-action-form-title-section [actionForm]="actionForm" class="flex-1" />
          @if (milestones && dialogData.idProjectElement && !dialogData.fromSynthesisComponent) {
            <wip-new-action-milestone
              [element]="currentElement"
              [milestones]="milestones"
              [actionForm]="actionForm"
              style="min-width: 250px"
            >
            </wip-new-action-milestone>
          }
        </div>
        <wip-new-action-date-section
          [ganttLite]="dialogData.community?.ganttLite"
          [actionForm]="actionForm"
          [isViewer]="dialogData.isViewer"
          [element]="currentElement"
          [idProjectElement]="dialogData.idProjectElement"
          [isTaskLinked]="dialogData.isTaskLinked"
          [businessDay]="dialogData.businessDay"
          [organizationFamily]="dialogData.community?.organizationFamily"
          [isMso]="dialogData.constraintType === 'mso'"
          [isEndDateComputed]="dialogData.isEndDateComputed"
        >
        </wip-new-action-date-section>
        <wip-new-action-responsibles
          class="w-full"
          [actionForm]="actionForm"
          [url]="router.url"
          [element]="currentElement"
          [isViewer]="dialogData.isViewer"
          [actionService]="actionService"
          (remindEvent)="onRemindEvent($event)"
          (hasResponsibles)="hasResponsibles = $event"
        >
        </wip-new-action-responsibles>
        <div class="flex flex-row jusitfy-between w-full">
          <div class="flex-1 flex gap-4">
            @if (hasCommentary) {
              <button
                [disabled]="dialogData.isViewer"
                (click)="hasCommentary = !hasCommentary"
                type="button"
                mat-mini-fab
                color="primary"
                matTooltip="Commentaire"
                style="box-shadow: none !important"
              >
                <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
                  <i class="icon icon-ic_fluent_chat_24_regular" style="font-size: 24px"></i>
                </span>
              </button>
            }
            @if (!hasCommentary) {
              <button
                [disabled]="dialogData.isViewer"
                (click)="hasCommentary = !hasCommentary"
                type="button"
                mat-icon-button
                matTooltip="Commentaire"
              >
                <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
                  <i class="icon icon-ic_fluent_chat_24_regular" style="font-size: 24px"></i>
                </span>
              </button>
            }
            @if (hasDocuments) {
              <button
                [disabled]="dialogData.isViewer"
                (click)="hasDocuments = !hasDocuments"
                type="button"
                mat-mini-fab
                matTooltip="Documents"
                color="primary"
                style="box-shadow: none !important"
              >
                <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
                  <i class="icon icon-ic_fluent_attach_24_regular" style="font-size: 24px"></i>
                </span>
              </button>
            }
            @if (!hasDocuments) {
              <button
                [disabled]="dialogData.isViewer"
                (click)="hasDocuments = !hasDocuments"
                type="button"
                mat-icon-button
                matTooltip="Documents"
              >
                <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
                  <i class="icon icon-ic_fluent_attach_24_regular" style="font-size: 24px"></i>
                </span>
              </button>
            }
            @if (hasChecklist) {
              <button
                [disabled]="dialogData.isViewer"
                (click)="hasChecklist = !hasChecklist"
                type="button"
                mat-mini-fab
                color="primary"
                style="box-shadow: none !important"
                matTooltip="Sous-tâches"
              >
                <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
                  <i class="icon icon-ic_fluent_task_list_ltr_24_regular" style="font-size: 24px"></i>
                </span>
              </button>
            }
            @if (!hasChecklist) {
              <button
                [disabled]="dialogData.isViewer"
                (click)="hasChecklist = !hasChecklist"
                type="button"
                mat-icon-button
                matTooltip="Sous-tâches"
              >
                <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
                  <i class="icon icon-ic_fluent_task_list_ltr_24_regular" style="font-size: 24px"></i>
                </span>
              </button>
            }
            @if (hasReminders) {
              <button
                [disabled]="dialogData.isViewer"
                (click)="hasReminders = !hasReminders"
                type="button"
                style="box-shadow: none !important"
                mat-mini-fab
                color="primary"
                matTooltip="Rappels"
              >
                <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
                  <i class="icon icon-ic_fluent_alert_24_regular" style="font-size: 24px"></i>
                </span>
              </button>
            }
            @if (!hasReminders) {
              <button
                [disabled]="dialogData.isViewer"
                (click)="hasReminders = !hasReminders"
                type="button"
                mat-icon-button
                matTooltip="Rappels"
              >
                <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
                  <i class="icon icon-ic_fluent_alert_24_regular" style="font-size: 24px"></i>
                </span>
              </button>
            }
          </div>
          <div class="flex flex-row gap-4">
            @if (!dialogData.isViewer) {
              <button
                mat-button
                type="button"
                [disabled]="!hasResponsibles"
                (click)="responsiblesComponent?.onReminder()"
                class="reminder-height"
                matTooltip="Envoyer un mail au responsable de la tâche"
              >
                <span class="gulp gulp-34-mail icon" style="margin-right: 4px"></span>
                Relancer responsable
              </button>
            }
            @if (!dialogData.isViewer) {
              <button
                mat-button
                type="button"
                (click)="responsiblesComponent?.informUsers()"
                class="reminder-height"
                matTooltip="Envoyer un mail pour informer sur l'avancement de la tâche"
              >
                <span class="gulp gulp-34-mail icon" style="margin-right: 4px"></span>
                Informer un tiers
              </button>
            }
          </div>
        </div>
        @if (hasCommentary) {
          <mat-form-field
            [formGroup]="actionForm"
            (keyup.enter)="$event.stopPropagation()"
            floatLabel="always"
            class="w-full"
          >
            <mat-label>Commentaire</mat-label>
            <textarea
              matInput
              style="font-size: 12px"
              formControlName="description"
              matTextareaAutosize
              matAutosizeMinRows="2"
              matAutosizeMaxRows="15"
            ></textarea>
          </mat-form-field>
        }
        @if (hasChecklist) {
          <wip-new-action-checklist
            class="half-width"
            [actionForm]="actionForm"
            [isViewer]="dialogData.isViewer"
            [actionService]="actionService"
            [element]="currentElement"
            [subtaskInput]="subtaskInput"
            (allCheckListDone)="onAllCheckListDone()"
          >
          </wip-new-action-checklist>
        }
        @if (hasDocuments) {
          <app-document-list-form
            class="half-width"
            [documents]="linkedDocuments"
            [IDparent]="currentElement.idElement"
            [uploadHandler]="uploadFile"
            [previewFile]="previewFile"
            [deletionHandler]="deleteFile"
            [downloadHandler]="downloadFile"
            [isViewer]="dialogData.isViewer"
            (added)="setAddedDocuments($event)"
            (removed)="setRemovedDocuments($event)"
          >
          </app-document-list-form>
        }
        @if (hasReminders) {
          <app-action-reminder
            class="element-margin half-width"
            #actionReminderListComponent
            [actionForm]="actionForm"
            [reminders]="reminders"
            [idElement]="currentElement.idElement"
            [isViewer]="dialogData.isViewer"
            (newReminderList)="getChangedReminders($event)"
          />
        }
      </div>
    }
  </div>
}
<div mat-dialog-actions class="flex flex-col w-full">
  <div class="flex flex-row" style="align-self: end">
    <button [disabled]="dialogData.isViewer" mat-button type="button" color="accent" (click)="delete()">
      Supprimer
    </button>
    <button [disabled]="dialogData.isViewer" mat-flat-button type="button" color="primary" (click)="save()">
      Enregistrer
    </button>
  </div>
  @if (currentElement && dialogData.type !== 'project') {
    <div class="footer-info flex flex-col w-full justify-center items-center">
      <div class="text-center toggle-description" [hidden]="!currentElement.createdAt">
        Crée le
        {{ formatDate(currentElement?.createdAt) }}
        @if (createdBy?.prenom) {
          <span
            >par
            <strong>{{ createdBy?.prenom }} {{ createdBy?.nom }}</strong>
          </span>
        }
      </div>
      @if (currentElement.updatedAt !== currentElement.createdAt) {
        <div class="text-center toggle-description" [hidden]="!currentElement.updatedAt">
          Modifié le
          {{ formatDate(currentElement.updatedAt) }}
          @if (updatedBy) {
            <span>
              par
              <strong>{{ updatedBy?.prenom }} {{ updatedBy?.nom }}</strong>
            </span>
          }
        </div>
      }
    </div>
  }
</div>
