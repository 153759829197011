import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChartsContainerComponent } from './features/charts/charts-container/charts-container.component';
import { GridContainerComponent } from './features/grid/grid-container/grid-container.component';
import { KanbanContainerComponent } from './features/kanban/list-kanban/kanban-container/kanban-container.component';
import { ProjectGridContainerComponent } from './features/synthesis/project-grid/project-grid-container/project-grid-container.component';
import { DashboardComponent } from './dashboard.component';
import { GlobalMapComponent } from './features/global-map/global-map.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'kanban',
        component: KanbanContainerComponent
      },
      {
        path: 'synthesis',
        component: ProjectGridContainerComponent
      },
      {
        path: 'charts',
        component: ChartsContainerComponent
      },
      {
        path: 'grid',
        component: GridContainerComponent
      },
      {
        path: 'map',
        component: GlobalMapComponent
      },
      {
        path: '**',
        redirectTo: 'kanban'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DashboardRouting {}
