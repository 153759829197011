import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TimelineElement } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import * as timelineElementActions from '@wip/store/actions';
import { TimelineElementApiService } from '@wip/store/api-services';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GeneratedTimelineElementEffects } from './timeline-element-generated.effects';
@Injectable()
export class TimelineElementEffects extends GeneratedTimelineElementEffects {
  constructor(actions$: Actions, timelineElementApiService: TimelineElementApiService, store$: Store<AppState>) {
    super(actions$, timelineElementApiService, store$);
  }

  createTimelineElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(timelineElementActions.createTimelineElement),
      switchMap(params =>
        this.timelineElementApiService.createTimelineElement(params.params).pipe(
          map((timelineElements: TimelineElement[]) => {
            return timelineElementActions.TimelineElementGeneratedActions.normalizeManyTimelineElementsAfterUpsert({
              timelineElements
            });
          }),
          catchError(error =>
            of(timelineElementActions.TimelineElementGeneratedActions.timelineElementsFailure({ error }))
          )
        )
      )
    );
  });
}
