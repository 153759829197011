import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { MeetingElement } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { MeetingElementRelationsIds } from '@wip/store/ids-interfaces';
import { MeetingElementGeneratedActions } from '@wip/store/actions';
import { MeetingElementSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedMeetingElementService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(MeetingElementSelectors.selectIsLoadedMeetingElement));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(MeetingElementSelectors.selectIsLoadingMeetingElement));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      MeetingElementSelectors.selectIsReadyAndLoadedMeetingElement as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllMeetingElements(schema: SelectSchema = {}): Observable<MeetingElement[]> {
    return this.store$.pipe(select(MeetingElementSelectors.selectAllMeetingElements(schema))).pipe(
      switchMap(({ meetingElements }: { meetingElements: MeetingElement[] }) => {
        return this.getReady(schema).pipe(mapTo(meetingElements));
      })
    );
  }

  public selectOneMeetingElement(idMeetingElement: number, schema: SelectSchema = {}): Observable<MeetingElement> {
    return this.store$.pipe(select(MeetingElementSelectors.selectOneMeetingElement(schema, idMeetingElement))).pipe(
      switchMap(({ meetingElement }: { meetingElement: MeetingElement }) => {
        return this.getReady(schema).pipe(mapTo(meetingElement));
      })
    );
  }

  public selectAllActiveMeetingElements(schema: SelectSchema = {}): Observable<MeetingElement[]> {
    return this.store$.pipe(select(MeetingElementSelectors.selectActiveMeetingElements(schema))).pipe(
      switchMap(({ meetingElements }: { meetingElements: MeetingElement[] }) => {
        return this.getReady(schema).pipe(mapTo(meetingElements));
      })
    );
  }

  public selectIdMeetingElementsActive(): Observable<number[]> {
    return this.store$.pipe(select(MeetingElementSelectors.selectIdMeetingElementsActive)).pipe(
      switchMap((idMeetingElements: number[]) => {
        return this.getReady().pipe(mapTo(idMeetingElements));
      })
    );
  }

  public getOneMeetingElement(
    idMeetingElement: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<MeetingElement> {
    this.store$.dispatch(MeetingElementGeneratedActions.getOneMeetingElement({ idMeetingElement, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert,
        MeetingElementGeneratedActions.meetingElementsFailure,
        true
      );
    }
  }

  public getManyMeetingElements(params: any = {}, getResult?: boolean): void | Observable<MeetingElement[]> {
    this.store$.dispatch(MeetingElementGeneratedActions.getManyMeetingElements({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert,
        MeetingElementGeneratedActions.meetingElementsFailure
      );
    }
  }

  public upsertOneMeetingElement(
    meetingElement: Partial<MeetingElement>,
    ids: MeetingElementRelationsIds = {},
    getResult?: boolean
  ): void | Observable<MeetingElement> {
    this.store$.dispatch(MeetingElementGeneratedActions.upsertOneMeetingElement({ meetingElement, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert,
        MeetingElementGeneratedActions.meetingElementsFailure,
        true
      );
    }
  }

  public deleteOneMeetingElement(idMeetingElement: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(MeetingElementGeneratedActions.deleteOneMeetingElement({ idMeetingElement }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingElementGeneratedActions.deleteOneMeetingElementSuccess,
        MeetingElementGeneratedActions.meetingElementsFailure
      );
    }
  }

  public setActiveMeetingElements(idMeetingElements: number[]): void {
    this.store$.dispatch(MeetingElementGeneratedActions.clearActivesMeetingElements());
    this.store$.dispatch(MeetingElementGeneratedActions.addManyActivesMeetingElements({ idMeetingElements }));
  }
}
