import { ConfirmationChoicesDialogContainerComponent } from '@wip/dialogs/confirmation-choices-dialog';
import { TextareaDialogComponent } from '@_app/shared/dialogs/textarea-dialog/textarea-dialog.component';
import { ActionService } from '@_app/shared/elements/actions/action.service';
import { IMetaUser } from '@_app/shared/interfaces/MetaUser.interface';
import { ISeachUserChanges } from '@_app/shared/interfaces/SearchUserChanges.interface';
import { SearchUsersDialogComponent } from '@_app/shared/search-users/search-users-dialog/search-users-dialog.component';
import { CommunityTypeStructureEnum, CommunityUserRoleEnum, ElementStatusActionEnum, ViewMode } from '@enums';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Community, CommunityUser, Element, User } from '@api/api-interfaces';
import { CommunityService, OrganizationUserService } from '@wip/store/services';
import { CommunityUserModel, UserModel } from '@wip/store/selectors-model';
import { CommunityUserProfilModel } from '@wip/store/selectors-model';
import { OrganizationUserProfilModel } from '@wip/store/selectors-model';
import { UserService } from '@wip/store/services';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { combineLatest } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { TodoElementApiService } from '@wip/store/api-services';

@UntilDestroy()
@Component({
  selector: 'wip-new-action-responsibles',
  templateUrl: './new-action-responsibles.component.html',
  styleUrls: ['./new-action-responsibles.component.scss']
})
export class NewActionResponsiblesComponent implements OnInit, OnChanges {
  @Input() element: Element;
  @Input() isViewer: boolean;
  @Input() module: string;
  @Input() actionService: ActionService;
  @Input() actionForm: FormGroup;
  @Input() url: string;
  @Output() remindEvent = new EventEmitter<any[]>();
  @Output() hasResponsibles = new EventEmitter<boolean>();

  private listeCommunity_usersAdd: CommunityUser[] = [];
  private listeCommunity_usersRemove: CommunityUser[] = [];
  private listeCommunity_usersSelected: CommunityUser[] = [];

  public suggestions: any[];
  public fb: FormBuilder = new FormBuilder();
  public communitySubForm: FormGroup = this.fb.group({});
  public viewMode = ViewMode;
  public mailContent: string = null;
  public communityUsersList: CommunityUser[] = [];
  public isToggled: boolean;
  public reminderSent: boolean;
  public currentUser: User;
  public community: Community;

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private dialog2: MatDialog,
    private serviceApi: TodoElementApiService,
    private newCommunityService: CommunityService,
    private newUserService: UserService,
    private organizationUserService: OrganizationUserService
  ) {}

  ngOnInit() {
    this.isToggled = false;
    this.reminderSent = false;
    this.initForms();

    this.newCommunityService.selectAllActiveCommunities().pipe(
      untilDestroyed(this),
      map(comms => comms[0]),
      map(comm => {
        this.community = comm;
        this.communityUsersList = this.community.communityUsers;
        this.communityUsersList = this.community.communityUsers.filter(user => user.idUser !== this.currentUser.idUser);
      })
    );
    this.getSuggestedUsers();
  }

  ngOnChanges() {
    this.initForms();
  }

  private initForms() {
    this.communitySubForm = this.fb.group({
      listeCommunity_usersAdd: [],
      listeCommunity_usersUpdate: [],
      listeCommunity_usersRemove: []
    });
    if (this.isViewer) {
      this.communitySubForm.disable();
    }
  }

  public responsibleChange($event: ISeachUserChanges) {
    this.actionForm.controls.listeResponsables.markAsDirty();
    this.actionForm.controls.listeElementsLibrariesAdd.markAsDirty();
    this.actionForm.controls.listeElementsLibrariesRemove.markAsDirty();

    if ($event.metaUsersSelected) {
      this.actionForm.controls.listeResponsables.patchValue($event.metaUsersSelected);
    }
    if ($event.add) {
      this.actionForm.controls.listeElementsLibrariesAdd.patchValue($event.add);
    }
    if ($event.rmv) {
      this.actionForm.controls.listeElementsLibrariesRemove.patchValue($event.rmv);
    }

    this.hasResponsibles.emit(this.actionForm.controls.listeResponsables.value.length > 0);
  }

  getSuggestedUsers() {
    combineLatest([
      this.newCommunityService
        .selectAllActiveCommunities({
          include: [
            { model: CommunityUserModel, include: [UserModel] },
            { model: CommunityUserProfilModel, include: [UserModel, OrganizationUserProfilModel] }
          ]
        })
        .pipe(
          untilDestroyed(this),
          map(comms => comms[0])
        ),
      this.newUserService.selectAllActiveUsers().pipe(
        untilDestroyed(this),
        map(users => users[0])
      ),
      this.organizationUserService.selectAllOrganizationUsers({ include: [UserModel] })
    ])
      .pipe(
        untilDestroyed(this),
        tap(([community, user, orgaUsers]) => {
          if (community && user && orgaUsers?.length) {
            const authorizedUsers = orgaUsers.filter(ou =>
              community.authorizedUsers.some(au => au.idUser === ou.idUser)
            );
            this.currentUser = user;
            this.suggestions = [
              {
                label: this.translateService.instant('General.You'),
                users: [{ user }],
                checked: false,
                request: false
              }
            ];
            if (community.typeStructure === CommunityTypeStructureEnum.community) {
              this.suggestions.push({
                label: this.translateService.instant('directives.searchUsers.CommunityUsers'),
                users: community.communityUsers,
                checked: false,
                request: false
              });
            } else if (
              community.typeStructure === CommunityTypeStructureEnum.project ||
              community.typeStructure === CommunityTypeStructureEnum.development
            ) {
              this.suggestions.push({
                label: 'Équipe',
                users: community.communityUserProfils,
                checked: false,
                request: false
              });
              this.suggestions.push({
                label: 'Autres utilisateurs',
                users: authorizedUsers,
                checked: false,
                request: false
              });
            }
          }
        })
      )
      .subscribe();
  }

  getResponsableIdUser(): boolean {
    const resp = this.actionForm.controls.listeResponsables.value;
    if (resp && resp.length) {
      return resp.map((responsable: { user: { idUser: unknown } }) => responsable.user.idUser).length > 0;
    } else {
      return false;
    }
  }

  public onReminder() {
    if (this.actionForm.get('sendMail').value) {
      return;
    }
    const dialogRef2 = this.dialog2.open(TextareaDialogComponent, {
      width: '350px',
      data: {
        title: this.translateService.instant('templates.project.elements_livrable_dialog.textarea_dialog.title'),
        defaultValue: this.translateService.instant(
          'templates.project.elements_livrable_dialog.textarea_dialog.defaultValueRemind',
          {
            user: this.currentUser.prenom + ' ' + this.currentUser.nom
          }
        )
      }
    });
    dialogRef2.beforeClosed().subscribe(data => {
      if (data) {
        this.mailContent = data.content;
        const responsibleIds = this.actionForm.controls.listeResponsables.value.map(r => r.idUser);
        this.serviceApi
          .informUsers(
            responsibleIds,
            (this.element as any).idTodoElement,
            (this.element as any).idElement,
            this.url,
            {
              nom: this.currentUser.nom,
              prenom: this.currentUser.prenom
            },
            data.content
          )
          .subscribe(() => {
            if (data.checkbox) {
              const existingComment = this.actionForm.get('description').value
                ? this.actionForm.get('description').value
                : '';
              const comment =
                this.translateService.instant('templates.project.informComment', {
                  date: dayjs().format('DD/MM/YY'),
                  user: this.currentUser.prenom + ' ' + this.currentUser.nom
                }) +
                ' : ' +
                data.content;
              this.element.description = existingComment + '\n' + comment;
              this.actionForm.get('description').patchValue(this.element.description);
              this.actionForm.markAsDirty();
            }
          });
      } else {
        this.mailContent = '';
      }
    });
  }

  saveBeforeRemind() {
    if (this.actionForm.markAsDirty) {
      const dialogRef = this.dialog.open(ConfirmationChoicesDialogContainerComponent, {
        data: {
          title: 'Souhaitez vous enregistrer ?',
          body:
            "Afin d'envoyer un email au(x) responsable(s) vous devez enregristrer vos changements. Voulez-vous enregistrer maintenant ? " +
            '<br>' +
            "Si vous n'enregistrez pas aucun email ne sera envoyé."
        }
      });
      dialogRef.beforeClosed().subscribe(value => {
        if (value) {
          this.reminderSent = true;
          this.actionService.callSave.next(true);
          this.actionForm.markAsPristine();
        }
      });
    } else if (this.mailContent) {
      this.actionService.setReminder(this.element.idElement, this.community.idCommunity, this.url, this.mailContent);
      this.reminderSent = true;
    }
  }

  public informUsers() {
    if (this.communityUsersList.length) {
      const currentCommunityUser: IMetaUser = this.communityUsersList.find(
        communityUser => communityUser.user.idUser === this.currentUser.idUser
      );
      if (currentCommunityUser) {
        currentCommunityUser.readOnly = true;
      }
    }

    const dialogRef = this.dialog.open(SearchUsersDialogComponent, {
      data: {
        add: this.listeCommunity_usersAdd,
        remove: this.listeCommunity_usersRemove,
        metaUsersSelected: cloneDeep(this.listeCommunity_usersSelected),
        metaUsersBlacklist: [this.currentUser],
        canAddName: false,
        groupMetaUsersSuggested: this.suggestions,
        textPlaceholder: 'Sélectionner un ou plusieurs utilisateurs à ajouter ou supprimer'
      },
      width: '500px',
      height: '700px',
      panelClass: ['dialog-content-no-paddings', 'custom-dialog-fullscreen-xs'],
      autoFocus: false
    });

    dialogRef
      .beforeClosed()
      .pipe(
        untilDestroyed(this),
        filter(user => !!user),
        map(users => {
          this.listeCommunity_usersSelected = users.listUserSelected;
          if (users.listeUsersAdd.length > 0) {
            this.listeCommunity_usersAdd = users.listeUsersAdd;
            users.listeUsersAdd.forEach(user => (user.role = CommunityUserRoleEnum.member));
            this.communitySubForm.controls.listeCommunity_usersAdd.setValue(users.listeUsersAdd);
          }
          if (users.listeUsersRemove.length > 0) {
            this.communitySubForm.controls.listeCommunity_usersRemove.setValue(users.listeUsersRemove);
            this.listeCommunity_usersRemove = users.listUsersRemove;
          }
          this.communityUsersList.forEach(user => {
            if (!user.role) {
              user.role = CommunityUserRoleEnum.member;
            }
          });
          this.communityUsersList.forEach(user => {
            if (!user.idCommunity) {
              user.role = CommunityUserRoleEnum.member;
            }
          });
          this.sortCommunityUsersList();
          this.communitySubForm.markAsDirty();
          return this.listeCommunity_usersSelected.map(user => user.user.idUser);
        }),
        mergeMap(listidUser => {
          const dialogRef2 = this.dialog2.open(TextareaDialogComponent, {
            width: '350px',
            data: {
              title: 'Contenu du message',
              defaultValue: this.translateService.instant(
                "{{user}} a voulu vous informer à propos de l'avancement de cette tâche",
                {
                  user: this.currentUser.prenom + ' ' + this.currentUser.nom
                }
              )
            }
          });
          return dialogRef2.beforeClosed().pipe(
            untilDestroyed(this),
            filter(data => !!data),
            map(data => [data.checkbox, data.content, listidUser])
          );
        })
      )
      .subscribe(([checkbox, mailContent, listidUser]) => {
        this.serviceApi
          .informUsers(
            listidUser,
            (this.element as any).idTodoElement,
            (this.element as any).idElement,
            this.url,
            {
              nom: this.currentUser.nom,
              prenom: this.currentUser.prenom
            },
            mailContent
          )
          .subscribe(() => {
            if (checkbox) {
              const existingComment = this.actionForm.get('description').value
                ? this.actionForm.get('description').value
                : '';
              const comment =
                this.translateService.instant('templates.project.informComment', {
                  date: dayjs().format('DD/MM/YY'),
                  user: this.currentUser.prenom + ' ' + this.currentUser.nom
                }) +
                ' : ' +
                mailContent;
              this.element.description = existingComment + '\n' + comment;
              this.actionForm.get('description').patchValue(this.element.description);
              this.actionForm.markAsDirty();
            }
          });
      });
  }

  private sortCommunityUsersList() {
    if (Array.isArray(this.communityUsersList)) {
      this.communityUsersList.sort(function (user1, user2) {
        if (
          user1.user.prenom &&
          typeof user1.user.prenom === 'string' &&
          user2.user.prenom &&
          typeof user2.user.prenom === 'string'
        ) {
          return user1.user.prenom.toLowerCase().localeCompare(user2.user.prenom.toLowerCase());
        } else {
          return user1.user.prenom > user2.user.prenom ? 1 : user1.user.prenom < user2.user.prenom ? -1 : 0;
        }
      });
    }
  }

  getResponsableIdUserDirty(): boolean {
    const resp = this.actionForm.controls.listeResponsables.value;
    if (resp && resp.length) {
      return resp.map(responsable => (responsable.user ? responsable.user.idUser : responsable.libelle)).length > 0;
    } else {
      return null;
    }
  }

  checkActionStatus() {
    return ![ElementStatusActionEnum.open, ElementStatusActionEnum.progress, ElementStatusActionEnum.blocked].includes(
      this.element.statusAction
    );
  }
}
