<div [formGroup]="actionForm">
  <ng-container>
    <span class="form-title-separator">Sous-tâches</span>
    <div (keyup.enter)="$event.stopPropagation()" style="margin-bottom: 4px">
      <div formArrayName="children">
        <mat-list dense class="subtask-list" style="padding: 0 !important">
          @for (subAction of getListChildren.controls; track subAction; let i = $index) {
            <div class="subtask-item flex flex-col" [formGroupName]="i">
              <div
                (mouseenter)="setHover(i)"
                (mouseleave)="setHover(i)"
                class="subtask-item subtask-general flex flex-1 box-border flex-row justify-between items-center"
              >
                <div class="flex flex-row">
                  <button
                    mat-icon-button
                    type="button"
                    (click)="setChildActionNextStatus(subAction)"
                    class="buttonIcon status-button"
                    [disabled]="isViewer"
                  >
                    <i [ngClass]="checkboxStatus[subAction.value.statusAction]"></i>
                  </button>
                  <div class="flex flex-col" style="margin-left: -14px">
                    @if (!subAction.value.isEditing) {
                      <div class="child-action-display" style="padding-top: 7px">
                        {{ subAction.value.titre }}
                      </div>
                    } @else {
                      <mat-form-field style="min-width: 400px" class="remove-infix full-width">
                        <input
                          #inputListField
                          matInput
                          formControlName="titre"
                          type="text"
                          maxlength="255"
                          (keydown.enter)="saveChildTask($event, subAction)"
                        />
                      </mat-form-field>
                    }

                    @if (subAction.value.description || subAction.value.isEditing) {
                      <div class="subtask-item" style="font-size: 90%">
                        @if (!subAction.value.isEditing) {
                          <div class="flex flex-row gap-8" style="margin: 3px 0 0 4px">
                            <span
                              class="child-action-display"
                              style="color: grey; font-size: 10px; line-height: 12px"
                              >{{ subAction.value.description }}</span
                            >
                          </div>
                        } @else {
                          <mat-form-field class="remove-infix full-width" style="margin-top: 5px" floatLabel="never">
                            <span matPrefix
                              ><i class="icon icon-ic_fluent_pin_24_regular" style="font-size: 24px"></i>
                            </span>
                            <input
                              matInput
                              formControlName="description"
                              type="text"
                              maxlength="255"
                              placeholder="Commentaire"
                              (keydown.enter)="saveChildTask($event, subAction)"
                            />
                          </mat-form-field>
                        }
                      </div>
                    }

                    @if (subAction.value.isEditing) {
                      <mat-form-field>
                        <input
                          matInput
                          [matDatepicker]="editPicker"
                          formControlName="echeance"
                          placeholder="MM/DD/YYYY"
                        />
                        <mat-datepicker-toggle matIconSuffix [for]="editPicker" />
                        <mat-datepicker #editPicker />
                      </mat-form-field>
                    }

                    @if (subAction.value.echeance && !subAction.value.isEditing) {
                      <span>{{ convertDate(subAction.value.echeance) }}</span>
                    }
                  </div>
                </div>
                <div class="flex flex-row items-center">
                  @if (subAction.value.isEditing) {
                    <div style="margin-left: 5px">
                      <button mat-button color="primary" (click)="saveChildTask($event, subAction)">Enregistrer</button>
                    </div>
                  }

                  @if (!!hover[i] && !subAction.value.isEditing && !isViewer && !subAction.value.hasJustBeenCreated) {
                    <button mat-icon-button class="size-30" (click)="editChildTask(subAction)">
                      <mat-icon class="gulp gulp-34-pen" />
                    </button>
                  }

                  @if (!!hover[i] && !isViewer) {
                    <button mat-icon-button class="size-30" (click)="removeChildAction(i)">
                      <mat-icon class="gulp gulp-34-trash" />
                    </button>
                  }
                </div>
              </div>
            </div>
          }
        </mat-list>
      </div>

      <div
        class="subtask-input flex flex-col"
        formGroupName="subAction"
        [ngStyle]="{ display: displayCheckListInput ? 'block' : 'none' }"
      >
        <div class="flex flex-row justify-start items-center gap-8">
          <mat-icon class="gulp gulp-34-plus second-color flex justify-center items-center" color="accent"> </mat-icon>

          <div class="flex flex-col flex-1 box-border" style="padding-top: 10px">
            <mat-form-field class="remove-infix" floatLabel="never">
              <input
                #inputNewField
                matInput
                autocomplete="off"
                class="new-subaction-input remove-infix"
                formControlName="subActionName"
                placeholder="Sous-tâche"
                (keydown.enter)="$event.preventDefault()"
                (keyup.enter)="$event.stopPropagation(); addSubAction()"
                #subtaskInput
              />
            </mat-form-field>

            <mat-form-field class="remove-infix input-comment" floatLabel="never">
              <span matPrefix>
                <i class="icon icon-ic_fluent_pin_24_regular" style="font-size: 24px"></i>
              </span>
              <input
                matInput
                autocomplete="off"
                class="new-subaction-input remove-infix"
                formControlName="subActionComment"
                placeholder="Commentaire"
                (keydown.enter)="$event.preventDefault()"
                (keyup.enter)="$event.stopPropagation(); addSubAction()"
              />
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                [matDatepicker]="creationPicker"
                formControlName="subActionDate"
                placeholder="MM/DD/YYYY"
                (keydown.enter)="$event.preventDefault()"
                (keyup.enter)="$event.stopPropagation(); addSubAction()"
              />
              <mat-datepicker-toggle matIconSuffix [for]="creationPicker" />
              <mat-datepicker #creationPicker />
            </mat-form-field>
          </div>

          <button
            mat-button
            type="button"
            class="add-subtask-button option"
            color="primary"
            (click)="addSubAction()"
            [disabled]="!subActionCtl.controls.subActionName.value || !subActionCtl.controls.subActionName.value.length"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="flex flex-row gap-4 justify-start">
    @if (!displayCheckListInput && !isViewer) {
      <button (click)="toggleShowCheckListInput()" mat-stroked-button color="accent" class="option">Ajouter</button>
    }
    @if (displayCheckListInput) {
      <button (click)="toggleShowCheckListInput()" mat-button color="black" class="option">Annuler</button>
    }
    @if (allChecked && getListChildren.controls.length && !isViewer) {
      <button mat-button type="button" (click)="uncheckAll()" class="option">Tout décocher</button>
    }
    @if (!allChecked && getListChildren.controls.length && !isViewer) {
      <button mat-button type="button" (click)="checkAll()" class="option">Tout cocher</button>
    }
  </div>
</div>
