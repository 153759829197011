import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GanttLink, GanttLinkEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { GanttLinkState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const ganttLinkRelations: string[] = ['communities'];

export const { selectEntities, selectAll } = GanttLinkState.adapter.getSelectors();

export const selectGanttLinkState = createFeatureSelector<GanttLinkState.IState>(GanttLinkState.ganttLinkFeatureKey);

export const selectIsLoadedGanttLink = createSelector(
  selectGanttLinkState,
  (state: GanttLinkState.IState) => state.isLoaded
);

export const selectIsLoadingGanttLink = createSelector(
  selectGanttLinkState,
  (state: GanttLinkState.IState) => state.isLoading
);

export const selectIsReadyGanttLink = createSelector(
  selectGanttLinkState,
  (state: GanttLinkState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedGanttLink = createSelector(
  selectGanttLinkState,
  (state: GanttLinkState.IState) => state.isLoaded && !state.isLoading
);

export const selectGanttLinksEntities = createSelector(selectGanttLinkState, selectEntities);

export const selectGanttLinksArray = createSelector(selectGanttLinkState, selectAll);

export const selectIdGanttLinksActive = createSelector(
  selectGanttLinkState,
  (state: GanttLinkState.IState) => state.actives
);

const ganttLinksInObject = (ganttLinks: Dictionary<GanttLinkEntityState>) => ({ ganttLinks });

const selectGanttLinksEntitiesDictionary = createSelector(selectGanttLinksEntities, ganttLinksInObject);

const selectAllGanttLinksObject = createSelector(selectGanttLinksEntities, ganttLinks => {
  return hydrateAll({ ganttLinks });
});

const selectOneGanttLinkDictionary = (idGanttLink: number) =>
  createSelector(selectGanttLinksEntities, ganttLinks => ({
    ganttLinks: { [idGanttLink]: ganttLinks[idGanttLink] }
  }));

const selectOneGanttLinkDictionaryWithoutChild = (idGanttLink: number) =>
  createSelector(selectGanttLinksEntities, ganttLinks => ({
    ganttLink: ganttLinks[idGanttLink]
  }));

const selectActiveGanttLinksEntities = createSelector(
  selectIdGanttLinksActive,
  selectGanttLinksEntities,
  (actives: number[], ganttLinks: Dictionary<GanttLinkEntityState>) => getGanttLinksFromActives(actives, ganttLinks)
);

function getGanttLinksFromActives(
  actives: number[],
  ganttLinks: Dictionary<GanttLinkEntityState>
): Dictionary<GanttLinkEntityState> {
  return actives.reduce((acc, idActive) => {
    if (ganttLinks[idActive]) {
      acc[idActive] = ganttLinks[idActive];
    }
    return acc;
  }, {} as Dictionary<GanttLinkEntityState>);
}

const selectAllGanttLinksSelectors: Dictionary<Selector> = {};
export function selectAllGanttLinks(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<GanttLink>(
      schema,
      selectAllGanttLinksSelectors,
      selectGanttLinksEntitiesDictionary,
      getRelationSelectors,
      ganttLinkRelations,
      hydrateAll,
      'ganttLink'
    );
  } else {
    return selectAllGanttLinksObject;
  }
}

export function selectAllGanttLinksDictionary(schema: SelectSchema = {}, customKey: string = 'ganttLinks'): Selector {
  return createSelector(selectAllGanttLinks(schema), result => {
    const res = { [customKey]: {} as Dictionary<GanttLinkEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.ganttLinks.length; i++) {
      res[customKey][result.ganttLinks[i].idGanttLink] = result.ganttLinks[i];
    }
    return res;
  });
}

export function selectOneGanttLink(schema: SelectSchema = {}, idGanttLink: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneGanttLinkDictionary(idGanttLink)];
    selectors.push(...getRelationSelectors(schema, ganttLinkRelations, 'ganttLink'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneGanttLinkDictionaryWithoutChild(idGanttLink);
  }
}

export function selectActiveGanttLinks(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveGanttLinksEntities, ganttLinks => ({
      ganttLinks
    }))
  ];
  selectors.push(...getRelationSelectors(schema, ganttLinkRelations, 'ganttLink'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  ganttLinks: Dictionary<GanttLinkEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { ganttLinks: (GanttLink | null)[] } {
  const { ganttLinks, communities } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    ganttLinks: Object.keys(ganttLinks).map(idGanttLink =>
      hydrate(ganttLinks[idGanttLink] as GanttLinkEntityState, communities)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { ganttLink: GanttLinkEntityState | null } {
  const { ganttLinks, communities } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const ganttLink = Object.values(ganttLinks)[0];
  return {
    ganttLink: hydrate(ganttLink as GanttLinkEntityState, communities)
  };
}

function hydrate(
  ganttLink: GanttLinkEntityState,
  communityEntities?: Dictionary<CommunityEntityState>
): GanttLink | null {
  if (!ganttLink) {
    return null;
  }

  const ganttLinkHydrated: GanttLinkEntityState = { ...ganttLink };
  if (communityEntities) {
    ganttLinkHydrated.community = communityEntities[ganttLink.community as number] as Community;
  } else {
    delete ganttLinkHydrated.community;
  }

  return ganttLinkHydrated as GanttLink;
}
