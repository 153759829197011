import { Action, createReducer } from '@ngrx/store';
import { organizationDataColumnReducersGeneratedFunctions } from './organization-data-column-generated.reducer';
import { OrganizationDataColumnGeneratedState, OrganizationDataColumnState } from '@wip/store/states';

const organizationDataColumnReducersFunctions = [...organizationDataColumnReducersGeneratedFunctions];

const organizationDataColumnReducer = createReducer(
  OrganizationDataColumnGeneratedState.initialState,
  ...organizationDataColumnReducersFunctions
);

export function reducer(state: OrganizationDataColumnState.IState | undefined, action: Action) {
  return organizationDataColumnReducer(state, action);
}
