import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SectionGeneratedActions } from '@wip/store/actions';
import { SectionState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { SectionEntityState } from '@api/api-interfaces';

export const sectionReducersGeneratedFunctions: ReducerTypes<SectionState.IState, readonly ActionCreator[]>[] = [
  on(SectionGeneratedActions.getOneSection, (state: SectionState.IState) => setLoadingsState(state, true)),
  on(SectionGeneratedActions.getManySections, (state: SectionState.IState) => setLoadingsState(state, true)),
  on(SectionGeneratedActions.upsertOneSection, (state: SectionState.IState) => setLoadingsState(state, true)),

  on(SectionGeneratedActions.upsertManySectionsSuccess, (state: SectionState.IState, { sections }) =>
    SectionState.adapter.upsertMany(sections, setLoadingsState(state, false))
  ),
  on(SectionGeneratedActions.deleteOneSection, (state: SectionState.IState) => setLoadingsState(state, true)),
  on(SectionGeneratedActions.deleteOneSectionSuccess, (state: SectionState.IState, { idSection }) =>
    SectionState.adapter.removeOne(idSection, setLoadingsState(state, false))
  ),
  on(SectionGeneratedActions.clearActivesSections, (state: SectionState.IState) => ({ ...state, actives: [] })),
  on(SectionGeneratedActions.addManyActivesSections, (state: SectionState.IState, { idSections }) => ({
    ...state,
    actives: state.actives.concat(idSections)
  })),
  on(SectionGeneratedActions.deleteOneActiveSection, (state: SectionState.IState, { idSection }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idSection)
  })),

  on(SectionGeneratedActions.clearSections, () => SectionState.initialState),

  on(
    SectionGeneratedActions.addManyOrganizationSectionSuccess,
    (state: SectionState.IState, { idSection, idOrganizationSections }) => {
      if (!state.entities[idSection]) {
        return state;
      }
      const organizationSections = (state.entities[idSection]?.organizationSections as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSection]: {
            ...state.entities[idSection],
            organizationSections: organizationSections.concat(
              idOrganizationSections.filter(id => organizationSections.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SectionGeneratedActions.deleteManyOrganizationSectionSuccess,
    (state: SectionState.IState, { idOrganizationSections, idSections }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSections.reduce((entities, idSection) => {
            if (!state.entities[idSection]?.organizationSections) {
              return entities;
            }
            entities[idSection] = {
              ...state.entities[idSection],
              organizationSections: (state.entities[idSection]?.organizationSections as number[])?.filter(
                (idOrganizationSection: number) =>
                  !idOrganizationSections.some((id: number) => id === idOrganizationSection)
              )
            } as SectionEntityState;
            return entities;
          }, {} as Dictionary<SectionEntityState>)
        }
      };
    }
  ),

  on(
    SectionGeneratedActions.addManyOrganizationSuccess,
    (state: SectionState.IState, { idSection, idOrganizations }) => {
      if (!state.entities[idSection]) {
        return state;
      }
      const organizations = (state.entities[idSection]?.organizations as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSection]: {
            ...state.entities[idSection],
            organizations: organizations.concat(idOrganizations.filter(id => organizations.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    SectionGeneratedActions.deleteManyOrganizationSuccess,
    (state: SectionState.IState, { idOrganizations, idSections }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSections.reduce((entities, idSection) => {
            if (!state.entities[idSection]?.organizations) {
              return entities;
            }
            entities[idSection] = {
              ...state.entities[idSection],
              organizations: (state.entities[idSection]?.organizations as number[])?.filter(
                (idOrganization: number) => !idOrganizations.some((id: number) => id === idOrganization)
              )
            } as SectionEntityState;
            return entities;
          }, {} as Dictionary<SectionEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: SectionState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): SectionState.IState {
  return { ...state, isLoaded, isLoading };
}
