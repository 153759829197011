import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationDataColumn, OrganizationDataColumnEntityState } from '@api/api-interfaces';
import { OrganizationDataColumnRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationDataColumnGeneratedActions = createActionGroup({
  source: 'Organization Data Column Generated',
  events: {
    'Get One Organization Data Column': props<{ idOrganizationDataColumn: number; params?: any }>(),
    'Get Many Organization Data Columns': props<{ params: any }>(),
    'Add Many Actives Organization Data Columns': props<{ idOrganizationDataColumns: number[] }>(),
    'Delete One Active Organization Data Column': props<{ idOrganizationDataColumn: number }>(),
    'Clear Actives Organization Data Columns': emptyProps(),
    'Upsert One Organization Data Column': props<{
      organizationDataColumn: Partial<OrganizationDataColumn>;
      ids?: OrganizationDataColumnRelationsIds;
    }>(),
    'Upsert Many Organization Data Columns': props<{
      organizationDataColumns: Partial<OrganizationDataColumn>[];
      ids?: OrganizationDataColumnRelationsIds;
    }>(),
    'Upsert Many Organization Data Columns Success': props<{
      organizationDataColumns: OrganizationDataColumnEntityState[];
    }>(),
    'Delete One Organization Data Column': props<{ idOrganizationDataColumn: number }>(),
    'Delete One Organization Data Column Success': props<{ idOrganizationDataColumn: number }>(),
    'Normalize Many Organization Data Columns After Upsert': props<{
      organizationDataColumns: OrganizationDataColumnEntityState[];
    }>(),
    'Organization Data Columns Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Data Columns': emptyProps(),
    'Add Many Organization Data Column Value Success': props<{
      idOrganizationDataColumn: number;
      idOrganizationDataColumnValues: number[];
    }>(),
    'Delete Many Organization Data Column Value Success': props<{
      idOrganizationDataColumnValues: number[];
      idOrganizationDataColumns: number[];
    }>(),
    'Add Many Valeur Success': props<{ idOrganizationDataColumn: number; idValeurs: number[] }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idOrganizationDataColumns: number[] }>(),
    'Add Many Organization Caracteristic Row Success': props<{
      idOrganizationDataColumn: number;
      idOrganizationCaracteristicRows: number[];
    }>(),
    'Delete Many Organization Caracteristic Row Success': props<{
      idOrganizationCaracteristicRows: number[];
      idOrganizationDataColumns: number[];
    }>(),
    'Add Many Organization Caracteristic Success': props<{
      idOrganizationDataColumn: number;
      idOrganizationCaracteristics: number[];
    }>(),
    'Delete Many Organization Caracteristic Success': props<{
      idOrganizationCaracteristics: number[];
      idOrganizationDataColumns: number[];
    }>(),
    'Add Organization Family Success': props<{ idOrganizationDataColumn: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{
      idOrganizationFamilys: number[];
      idOrganizationDataColumns: number[];
    }>()
  }
});
