import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@api/api-interfaces';
import { UserService } from '@wip/store/services';

@Component({
  selector: 'app-user-detail-dialog',
  templateUrl: './user-detail-dialog.component.html',
  styleUrls: ['./user-detail-dialog.component.scss']
})
export class UserDetailDialogComponent implements OnInit {
  user: User;
  currentUserId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private newUserService: UserService
  ) {}

  ngOnInit() {
    this.user = { ...this.data.user };
    this.currentUserId = this.newUserService.currentIdUser;
  }
}
