export enum ConstraintTypeEnum {
  // As Soon As Possible
  ASAP = 'asap',
  // As Late As Possible
  ALAP = 'alap',
  // Start No Earlier Than
  SNET = 'snet',
  // Start No Later Than
  SNLT = 'snlt',
  // Finish No Earlier Than
  FNET = 'fnet',
  // Finish No Later Than
  FNLT = 'fnlt',
  // Must Start On
  MSO = 'mso',
  // Must Finish On
  MFO = 'mfo'
}
