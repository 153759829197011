/* eslint-disable @angular-eslint/component-selector */
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent implements ICellRendererAngularComp {
  private params;
  private componentParent;

  agInit(params): void {
    this.params = params;
    this.componentParent = params.context.componentParent;
  }

  public onDelete() {
    this.componentParent.onDelete(this.params);
  }

  refresh(): boolean {
    return true;
  }
}
