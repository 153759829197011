import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProjectModuleGeneratedActions } from '@wip/store/actions';
import { ProjectModuleState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProjectModuleEntityState } from '@api/api-interfaces';

export const projectModuleReducersGeneratedFunctions: ReducerTypes<
  ProjectModuleState.IState,
  readonly ActionCreator[]
>[] = [
  on(ProjectModuleGeneratedActions.getOneProjectModule, (state: ProjectModuleState.IState) =>
    setLoadingsState(state, true)
  ),
  on(ProjectModuleGeneratedActions.getManyProjectModules, (state: ProjectModuleState.IState) =>
    setLoadingsState(state, true)
  ),
  on(ProjectModuleGeneratedActions.upsertOneProjectModule, (state: ProjectModuleState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    ProjectModuleGeneratedActions.upsertManyProjectModulesSuccess,
    (state: ProjectModuleState.IState, { projectModules }) =>
      ProjectModuleState.adapter.upsertMany(projectModules, setLoadingsState(state, false))
  ),
  on(ProjectModuleGeneratedActions.deleteOneProjectModule, (state: ProjectModuleState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    ProjectModuleGeneratedActions.deleteOneProjectModuleSuccess,
    (state: ProjectModuleState.IState, { idProjectModule }) =>
      ProjectModuleState.adapter.removeOne(idProjectModule, setLoadingsState(state, false))
  ),
  on(ProjectModuleGeneratedActions.clearActivesProjectModules, (state: ProjectModuleState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    ProjectModuleGeneratedActions.addManyActivesProjectModules,
    (state: ProjectModuleState.IState, { idProjectModules }) => ({
      ...state,
      actives: state.actives.concat(idProjectModules)
    })
  ),
  on(
    ProjectModuleGeneratedActions.deleteOneActiveProjectModule,
    (state: ProjectModuleState.IState, { idProjectModule }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idProjectModule)
    })
  ),

  on(ProjectModuleGeneratedActions.clearProjectModules, () => ProjectModuleState.initialState),

  on(
    ProjectModuleGeneratedActions.addManyCommunityModuleSuccess,
    (state: ProjectModuleState.IState, { idProjectModule, idCommunityModules }) => {
      if (!state.entities[idProjectModule]) {
        return state;
      }
      const communityModules = (state.entities[idProjectModule]?.communityModules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProjectModule]: {
            ...state.entities[idProjectModule],
            communityModules: communityModules.concat(idCommunityModules.filter(id => communityModules.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ProjectModuleGeneratedActions.deleteManyCommunityModuleSuccess,
    (state: ProjectModuleState.IState, { idCommunityModules, idProjectModules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProjectModules.reduce((entities, idProjectModule) => {
            if (!state.entities[idProjectModule]?.communityModules) {
              return entities;
            }
            entities[idProjectModule] = {
              ...state.entities[idProjectModule],
              communityModules: (state.entities[idProjectModule]?.communityModules as number[])?.filter(
                (idCommunityModule: number) => !idCommunityModules.some((id: number) => id === idCommunityModule)
              )
            } as ProjectModuleEntityState;
            return entities;
          }, {} as Dictionary<ProjectModuleEntityState>)
        }
      };
    }
  ),

  on(
    ProjectModuleGeneratedActions.addManyOrganizationProjectModuleSuccess,
    (state: ProjectModuleState.IState, { idProjectModule, idOrganizationProjectModules }) => {
      if (!state.entities[idProjectModule]) {
        return state;
      }
      const organizationProjectModules =
        (state.entities[idProjectModule]?.organizationProjectModules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProjectModule]: {
            ...state.entities[idProjectModule],
            organizationProjectModules: organizationProjectModules.concat(
              idOrganizationProjectModules.filter(id => organizationProjectModules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProjectModuleGeneratedActions.deleteManyOrganizationProjectModuleSuccess,
    (state: ProjectModuleState.IState, { idOrganizationProjectModules, idProjectModules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProjectModules.reduce((entities, idProjectModule) => {
            if (!state.entities[idProjectModule]?.organizationProjectModules) {
              return entities;
            }
            entities[idProjectModule] = {
              ...state.entities[idProjectModule],
              organizationProjectModules: (
                state.entities[idProjectModule]?.organizationProjectModules as number[]
              )?.filter(
                (idOrganizationProjectModule: number) =>
                  !idOrganizationProjectModules.some((id: number) => id === idOrganizationProjectModule)
              )
            } as ProjectModuleEntityState;
            return entities;
          }, {} as Dictionary<ProjectModuleEntityState>)
        }
      };
    }
  ),

  on(
    ProjectModuleGeneratedActions.addManyCommunitySuccess,
    (state: ProjectModuleState.IState, { idProjectModule, idCommunities }) => {
      if (!state.entities[idProjectModule]) {
        return state;
      }
      const communities = (state.entities[idProjectModule]?.communities as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProjectModule]: {
            ...state.entities[idProjectModule],
            communities: communities.concat(idCommunities.filter(id => communities.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ProjectModuleGeneratedActions.deleteManyCommunitySuccess,
    (state: ProjectModuleState.IState, { idCommunities, idProjectModules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProjectModules.reduce((entities, idProjectModule) => {
            if (!state.entities[idProjectModule]?.communities) {
              return entities;
            }
            entities[idProjectModule] = {
              ...state.entities[idProjectModule],
              communities: (state.entities[idProjectModule]?.communities as number[])?.filter(
                (idCommunity: number) => !idCommunities.some((id: number) => id === idCommunity)
              )
            } as ProjectModuleEntityState;
            return entities;
          }, {} as Dictionary<ProjectModuleEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ProjectModuleState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ProjectModuleState.IState {
  return { ...state, isLoaded, isLoading };
}
