import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationDataColumnValueEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationDataColumnValueEntityState>;

export const adapter = createEntityAdapter<OrganizationDataColumnValueEntityState>({
  selectId: o => o.idOrganizationDataColumnValue
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationDataColumnValueFeatureKey = 'organizationDataColumnValue';
