export enum OrganizationUserRoleEnum {
  superAdmin = 'superAdmin',
  admin = 'admin',
  user = 'user',
  userPlus = 'userPlus'
}

export enum OrganizationUserRoleEnumTrad {
  superAdmin = 'Super Administrateur',
  admin = 'Administrateur',
  user = 'Utilisateur',
  userPlus = 'Utilisateur +'
}
