import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserBoardState, UserBoardStateEntityState } from '@api/api-interfaces';
import { UserBoardStateRelationsIds } from '@wip/store/ids-interfaces';

export const UserBoardStateGeneratedActions = createActionGroup({
  source: 'User Board State Generated',
  events: {
    'Get One User Board State': props<{ idUserBoardState: number; params?: any }>(),
    'Get Many User Board States': props<{ params: any }>(),
    'Add Many Actives User Board States': props<{ idUserBoardStates: number[] }>(),
    'Delete One Active User Board State': props<{ idUserBoardState: number }>(),
    'Clear Actives User Board States': emptyProps(),
    'Upsert One User Board State': props<{
      userBoardState: Partial<UserBoardState>;
      ids?: UserBoardStateRelationsIds;
    }>(),
    'Upsert Many User Board States': props<{
      userBoardStates: Partial<UserBoardState>[];
      ids?: UserBoardStateRelationsIds;
    }>(),
    'Upsert Many User Board States Success': props<{ userBoardStates: UserBoardStateEntityState[] }>(),
    'Delete One User Board State': props<{ idUserBoardState: number }>(),
    'Delete One User Board State Success': props<{ idUserBoardState: number }>(),
    'Normalize Many User Board States After Upsert': props<{ userBoardStates: UserBoardStateEntityState[] }>(),
    'User Board States Failure': props<{ error: HttpErrorResponse }>(),
    'Clear User Board States': emptyProps(),
    'Add Organization User Success': props<{ idUserBoardState: number; idOrganizationUser: number }>(),
    'Delete Many Organization User Success': props<{ idOrganizationUsers: number[]; idUserBoardStates: number[] }>(),
    'Add Organization Family Success': props<{ idUserBoardState: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{ idOrganizationFamilys: number[]; idUserBoardStates: number[] }>()
  }
});
