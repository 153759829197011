import { CommunityMilestoneFamilyState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityMilestoneFamilyReducersGeneratedFunctions } from './community-milestone-family-generated.reducer';

const communityMilestoneFamilyReducersFunctions = [...communityMilestoneFamilyReducersGeneratedFunctions];

const communityMilestoneFamilyReducer = createReducer(
  CommunityMilestoneFamilyState.initialState,
  ...communityMilestoneFamilyReducersFunctions
);

export function reducer(state: CommunityMilestoneFamilyState.IState | undefined, action: Action) {
  return communityMilestoneFamilyReducer(state, action);
}
