import { createEntityAdapter } from '@ngrx/entity';
import { FolderEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<FolderEntityState>;

export const adapter = createEntityAdapter<FolderEntityState>({
  selectId: o => o.idFolder
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const folderFeatureKey = 'folder';
