import { createEntityAdapter } from '@ngrx/entity';
import { ElementEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<ElementEntityState>;

export const adapter = createEntityAdapter<ElementEntityState>({
  selectId: o => o.idElement
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const elementFeatureKey = 'element';
