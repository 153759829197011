import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationDataColumn } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationDataColumnRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationDataColumnService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationDataColumnSelectors.selectIsLoadedOrganizationDataColumn));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationDataColumnSelectors.selectIsLoadingOrganizationDataColumn));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationDataColumnSelectors.selectIsReadyAndLoadedOrganizationDataColumn as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationDataColumns(schema: SelectSchema = {}): Observable<OrganizationDataColumn[]> {
    return this.store$.pipe(select(OrganizationDataColumnSelectors.selectAllOrganizationDataColumns(schema))).pipe(
      switchMap(({ organizationDataColumns }: { organizationDataColumns: OrganizationDataColumn[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationDataColumns));
      })
    );
  }

  public selectOneOrganizationDataColumn(
    idOrganizationDataColumn: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationDataColumn> {
    return this.store$
      .pipe(select(OrganizationDataColumnSelectors.selectOneOrganizationDataColumn(schema, idOrganizationDataColumn)))
      .pipe(
        switchMap(({ organizationDataColumn }: { organizationDataColumn: OrganizationDataColumn }) => {
          return this.getReady(schema).pipe(mapTo(organizationDataColumn));
        })
      );
  }

  public selectAllActiveOrganizationDataColumns(schema: SelectSchema = {}): Observable<OrganizationDataColumn[]> {
    return this.store$.pipe(select(OrganizationDataColumnSelectors.selectActiveOrganizationDataColumns(schema))).pipe(
      switchMap(({ organizationDataColumns }: { organizationDataColumns: OrganizationDataColumn[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationDataColumns));
      })
    );
  }

  public selectIdOrganizationDataColumnsActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationDataColumnSelectors.selectIdOrganizationDataColumnsActive)).pipe(
      switchMap((idOrganizationDataColumns: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationDataColumns));
      })
    );
  }

  public getOneOrganizationDataColumn(
    idOrganizationDataColumn: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationDataColumn> {
    this.store$.dispatch(
      OrganizationDataColumnGeneratedActions.getOneOrganizationDataColumn({ idOrganizationDataColumn, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationDataColumnGeneratedActions.normalizeManyOrganizationDataColumnsAfterUpsert,
        OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure,
        true
      );
    }
  }

  public getManyOrganizationDataColumns(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationDataColumn[]> {
    this.store$.dispatch(OrganizationDataColumnGeneratedActions.getManyOrganizationDataColumns({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationDataColumnGeneratedActions.normalizeManyOrganizationDataColumnsAfterUpsert,
        OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure
      );
    }
  }

  public upsertOneOrganizationDataColumn(
    organizationDataColumn: Partial<OrganizationDataColumn>,
    ids: OrganizationDataColumnRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationDataColumn> {
    this.store$.dispatch(
      OrganizationDataColumnGeneratedActions.upsertOneOrganizationDataColumn({ organizationDataColumn, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationDataColumnGeneratedActions.normalizeManyOrganizationDataColumnsAfterUpsert,
        OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure,
        true
      );
    }
  }

  public deleteOneOrganizationDataColumn(
    idOrganizationDataColumn: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationDataColumnGeneratedActions.deleteOneOrganizationDataColumn({ idOrganizationDataColumn })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationDataColumnGeneratedActions.deleteOneOrganizationDataColumnSuccess,
        OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure
      );
    }
  }

  public setActiveOrganizationDataColumns(idOrganizationDataColumns: number[]): void {
    this.store$.dispatch(OrganizationDataColumnGeneratedActions.clearActivesOrganizationDataColumns());
    this.store$.dispatch(
      OrganizationDataColumnGeneratedActions.addManyActivesOrganizationDataColumns({ idOrganizationDataColumns })
    );
  }
}
