<div class="flex flex-row h-full">
  <wip-global-map-list
    style="min-width: 300px; max-width: 300px"
    [projects]="(communities$ | async) || []"
    [selectedProjectId]="selectedProjectId"
    (selectProject)="changeSelectedProject($event)"
  />
  <wip-google-maps
    class="w-full"
    [projects]="(communities$ | async) || []"
    [searchString]="searchString"
    [selectedProjectId]="selectedProjectId"
    (selectedProjectChange)="changeSelectedProject($event)"
    (selectProjectsInBounds)="filterProjectsInBounds($event)"
  />
</div>
