import { ValeurState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { valeurReducersGeneratedFunctions } from './valeur-generated.reducer';
import { ValeurGeneratedState } from '@wip/store/states';

const valeurReducersFunctions = [...valeurReducersGeneratedFunctions];

const valeurReducer = createReducer(ValeurGeneratedState.initialState, ...valeurReducersFunctions);

export function reducer(state: ValeurState.IState | undefined, action: Action) {
  return valeurReducer(state, action);
}
