import { RepositoryService } from '@wip/services/repository';
import { Valeur } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedValeurApiService {
  constructor(protected repo: RepositoryService) {}

  public getValeurs(params?: any): Observable<Valeur[]> {
    return this.repo.getData<Valeur[]>('valeur', params);
  }

  public getValeur(params: { idValeur: number; params?: any }): Observable<Valeur> {
    return this.repo.getData<Valeur>('valeur/' + params.idValeur, params.params);
  }

  public addValeur(valeur: Partial<Valeur>): Observable<Valeur> {
    return this.repo.create<Valeur>('valeur', valeur);
  }

  public updateValeur(valeur: Partial<Valeur>): Observable<Valeur> {
    return this.repo.update('valeur', valeur);
  }

  public deleteValeur(idValeur: number): Observable<number> {
    return this.repo.delete('valeur/' + +idValeur);
  }
}
