import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ValeurApiService } from '@wip/store/api-services';
import { GeneratedValeurEffects } from './valeur-generated.effects';

@Injectable()
export class ValeurEffects extends GeneratedValeurEffects {
  constructor(actions$: Actions, valeurApiService: ValeurApiService, store$: Store<AppState>) {
    super(actions$, valeurApiService, store$);
  }
}
