import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { Injectable, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommunityService, OrganizationUserService } from '@wip/store/services';
import { Subject } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ProjectGridAdressService implements OnInit {
  constructor(
    private readonly organizationUserService: OrganizationUserService,
    private readonly communityService: CommunityService
  ) {}

  private editMode: boolean;

  ngOnInit(): void {
    this.organizationUserService.editMode$.pipe(untilDestroyed(this)).subscribe(val => (this.editMode = val));
  }

  public createAdressColumnDefsFormat(openAdress: boolean): ColGroupDef {
    const adressColumnsDefs: ColDef[] = this.getOpenAndCloseColDefAdress();

    const adressColumnsGroupDefsDefault: ColGroupDef = {
      headerName: 'Opération',
      children: adressColumnsDefs,
      columnGroupShow: 'open',
      openByDefault: openAdress,
      groupId: 'adress'
    };

    return adressColumnsGroupDefsDefault;
  }

  public getOpenAndCloseColDefAdress(handleChange: Subject<void> = null): ColDef[] {
    return [
      {
        headerName: 'Nom',
        resizable: true,
        field: 'nom',
        width: 250,
        pinned: 'left',
        menuTabs: ['columnsMenuTab']
      },
      {
        headerName: 'Adresse',
        field: 'adresse',
        columnGroupShow: 'open',
        editable: this.editMode,
        width: 250,
        pinned: 'left',
        cellStyle: { textAlign: 'left', backgroundColor: this.editMode ? '#ceeaff' : '' },
        resizable: true,
        valueGetter: params => params.data?.adresse,
        valueSetter: params => {
          params.data.adresse = params.newValue;
          return true;
        },
        onCellValueChanged: params => {
          if (!!params.newValue) {
            this.communityService.upsertOneCommunity({
              idCommunity: params.data.idCommunity,
              ['adresse']: params.newValue
            });
            handleChange.next();
          }
        }
      },
      {
        headerName: 'Code Postal',
        field: 'codePostal',
        columnGroupShow: 'open',
        enableRowGroup: true,
        editable: this.editMode,
        width: 80,
        pinned: 'left',
        cellStyle: { textAlign: 'left', backgroundColor: this.editMode ? '#ceeaff' : '' },
        resizable: true,
        valueGetter: params => params.data?.codePostal,
        valueSetter: params => {
          if (isNaN(params.newValue)) {
            return false;
          }
          params.data.codePostal = params.newValue;
          return true;
        },
        onCellValueChanged: params => {
          if (!!params.newValue && !isNaN(params.newValue)) {
            this.communityService.upsertOneCommunity({
              idCommunity: params.data.idCommunity,
              ['codePostal']: params.newValue
            });
            handleChange.next();
          }
        }
      },
      {
        headerName: 'Commune',
        field: 'commune',
        columnGroupShow: 'open',
        enableRowGroup: true,
        editable: this.editMode,
        width: 100,
        pinned: 'left',
        resizable: true,
        cellStyle: { textAlign: 'left', backgroundColor: this.editMode ? '#ceeaff' : '' },
        valueGetter: params => params.data?.commune,
        valueSetter: params => {
          params.data.commune = params.newValue;
          return true;
        },
        onCellValueChanged: params => {
          if (!!params.newValue) {
            this.communityService.upsertOneCommunity({
              idCommunity: params.data.idCommunity,
              ['commune']: params.newValue
            });
            handleChange.next();
          }
        }
      }
    ];
  }
}
