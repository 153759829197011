import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Folder, UploadFileInterface } from '@api/api-interfaces';
import * as AppState from '@wip/store/configs/reducers';
import * as FolderAction from '@wip/store/actions';
import { Observable, Subject } from 'rxjs';
import { GeneratedFolderService } from './folder-generated.service';
@Injectable({
  providedIn: 'root'
})
export class FolderService extends GeneratedFolderService {
  public uploadInProgress = new Subject<boolean>();

  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public uploadFile(params: UploadFileInterface, getResult?: boolean): Observable<Folder> {
    const paramsToSend: UploadFileInterface = {
      formData: params.formData,
      idCommunity: params.idCommunity,
      idFolder: params.idFolder || 0,
      idMeetingElement: params.idMeetingElement || 0,
      idElementParent: params.idElementParent || 0,
      isTimeline: params.isTimeline || false,
      ordre: params.ordre || 0
    };

    this.store$.dispatch(FolderAction.uploadFile({ params: paramsToSend }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FolderAction.FolderGeneratedActions.normalizeManyFoldersAfterUpsert,
        FolderAction.FolderGeneratedActions.foldersFailure,
        true
      );
    }
  }
}
