import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Group } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { GroupRelationsIds } from '@wip/store/ids-interfaces';
import { GroupGeneratedActions } from '@wip/store/actions';
import { GroupSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedGroupService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(GroupSelectors.selectIsLoadedGroup));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(GroupSelectors.selectIsLoadingGroup));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [GroupSelectors.selectIsReadyAndLoadedGroup as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllGroups(schema: SelectSchema = {}): Observable<Group[]> {
    return this.store$.pipe(select(GroupSelectors.selectAllGroups(schema))).pipe(
      switchMap(({ groups }: { groups: Group[] }) => {
        return this.getReady(schema).pipe(mapTo(groups));
      })
    );
  }

  public selectOneGroup(idGroup: number, schema: SelectSchema = {}): Observable<Group> {
    return this.store$.pipe(select(GroupSelectors.selectOneGroup(schema, idGroup))).pipe(
      switchMap(({ group }: { group: Group }) => {
        return this.getReady(schema).pipe(mapTo(group));
      })
    );
  }

  public selectAllActiveGroups(schema: SelectSchema = {}): Observable<Group[]> {
    return this.store$.pipe(select(GroupSelectors.selectActiveGroups(schema))).pipe(
      switchMap(({ groups }: { groups: Group[] }) => {
        return this.getReady(schema).pipe(mapTo(groups));
      })
    );
  }

  public selectIdGroupsActive(): Observable<number[]> {
    return this.store$.pipe(select(GroupSelectors.selectIdGroupsActive)).pipe(
      switchMap((idGroups: number[]) => {
        return this.getReady().pipe(mapTo(idGroups));
      })
    );
  }

  public getOneGroup(idGroup: number, params: any = {}, getResult?: boolean): void | Observable<Group> {
    this.store$.dispatch(GroupGeneratedActions.getOneGroup({ idGroup, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        GroupGeneratedActions.normalizeManyGroupsAfterUpsert,
        GroupGeneratedActions.groupsFailure,
        true
      );
    }
  }

  public getManyGroups(params: any = {}, getResult?: boolean): void | Observable<Group[]> {
    this.store$.dispatch(GroupGeneratedActions.getManyGroups({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        GroupGeneratedActions.normalizeManyGroupsAfterUpsert,
        GroupGeneratedActions.groupsFailure
      );
    }
  }

  public upsertOneGroup(
    group: Partial<Group>,
    ids: GroupRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Group> {
    this.store$.dispatch(GroupGeneratedActions.upsertOneGroup({ group, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        GroupGeneratedActions.normalizeManyGroupsAfterUpsert,
        GroupGeneratedActions.groupsFailure,
        true
      );
    }
  }

  public deleteOneGroup(idGroup: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(GroupGeneratedActions.deleteOneGroup({ idGroup }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        GroupGeneratedActions.deleteOneGroupSuccess,
        GroupGeneratedActions.groupsFailure
      );
    }
  }

  public setActiveGroups(idGroups: number[]): void {
    this.store$.dispatch(GroupGeneratedActions.clearActivesGroups());
    this.store$.dispatch(GroupGeneratedActions.addManyActivesGroups({ idGroups }));
  }
}
