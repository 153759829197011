<div mat-dialog-title>
  <h1>Liste des projets</h1>
  <button mat-icon-button class="gulp gulp-34-close" [mat-dialog-close]="false" tabindex="-1"></button>
</div>
<div mat-dialog-content>
  <span style="font-weight: bold; color: black">Choisissez les projets que vous souhaitez exporter</span>
  <wip-projects-checklist
    [communities]="(communities$ | async) || []"
    [showFamilies]="showFamilies"
    [organizationFamilies]="(orgaFamilies$ | async) || []"
    [subFamilies]="subFamilies"
    [fromModal]="true"
  />
</div>
<div mat-dialog-actions class="flex flex-row gap-16" style="justify-content: end !important">
  <p matTooltip="Si non coché, archive avec un fichier par projet exporté">
    <mat-checkbox [formControl]="sameFileFormControl">Mettre toutes les notes dans un seul fichier</mat-checkbox>
  </p>
  <button mat-raised-button (click)="cancel()" color="accent" type="submit">Annuler</button>
  <button mat-raised-button (click)="confirm()" color="primary" type="submit">Confirmer</button>
</div>
