import { IupdateOrganizationElement } from '@dto';
import { createAction, props } from '@ngrx/store';

export * from './organization-element-generated.actions';

export const uploadNewFile = createAction(
  '[OrganizationElement] Upload File',
  props<{
    params: { formData: FormData; idOrganization: number; ordre?: number };
  }>()
);

export const updateOrganizationElement = createAction(
  '[OrganizationElement] Update Organization Element',
  props<{
    params: IupdateOrganizationElement;
  }>()
);
