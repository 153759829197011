import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationProjectModuleApiService } from '@wip/store/api-services';
import { GeneratedOrganizationProjectModuleEffects } from './organization-project-module-generated.effects';

@Injectable()
export class OrganizationProjectModuleEffects extends GeneratedOrganizationProjectModuleEffects {
  constructor(
    actions$: Actions,
    organizationProjectModuleApiService: OrganizationProjectModuleApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationProjectModuleApiService, store$);
  }
}
