<div class="form-title-separator flex flex-row justify-between items-center gap-8">
  <span>Rappels automatiques</span>
</div>

<div (keyup.enter)="$event.stopPropagation()" [formGroup]="actionForm">
  @if (listReminders) {
    <div class="subtask-list gap-8" formArrayName="listReminders" style="margin-bottom: 4px">
      @for (reminder of listReminders.controls; track reminder; let i = $index) {
        <div class="subtask-item flex flex-row" style="height: 40px" [formGroupName]="i">
          <span class="flex flex-none justify-start items-center">
            {{ reminder.value.value < 0 ? -reminder.value.value : reminder.value.value }}
            jours&nbsp;
          </span>
          <span class="flex flex-1 box-border justify-start items-center">
            {{ reminder.value.value < 0 ? 'après' : 'avant' }}
            {{ 'General.' + reminder.value.reminder | translate }}
          </span>
          @if (!isViewer) {
            <button
              mat-icon-button
              class="trash flex flex-none justify-center items-end"
              type="button"
              (click)="removeReminder(i)"
            >
              <mat-icon class="gulp gulp-34-trash" />
            </button>
          }
        </div>
      }
    </div>
  }
  @if (newReminder) {
    <form [formGroup]="newReminder" [ngStyle]="{ display: displayInput ? 'block' : 'none' }" style="margin-bottom: 4px">
      <div class="subtask-input delay flex flex-row justify-start items-center gap-8">
        <mat-form-field class="remove-infix delay-input flex flex-row" floatLabel="never">
          <input
            matInput
            autocomplete="off"
            class="remove-infix"
            formControlName="value"
            placeholder="Nb"
            type="number"
            min="0"
            (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="$event.stopPropagation(); addReminder()"
          />
          <span matSuffix>j</span>
        </mat-form-field>
        <div>
          <mat-form-field class="remove-infix from-date-input">
            <mat-select formControlName="beforeOrAfter" required>
              <mat-option value="before"> avant </mat-option>
              <mat-option value="after"> après </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field class="remove-infix from-date-input">
          <mat-select formControlName="reminder">
            @for (choice of reminderChoices; track choice) {
              <mat-option [value]="choice.value">
                {{ choice.display }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <button
          [disabled]="newReminder.get('value').value === null"
          mat-button
          type="button"
          class="add-subtask-button option"
          color="primary"
          (click)="addReminder()"
        >
          <span>Ajouter</span>
        </button>
      </div>
    </form>
  }
  @if (!isViewer) {
    <div>
      @if (!displayInput) {
        <button mat-stroked-button (click)="toogleShowInput()" color="accent" class="justify-start option">
          Ajouter
        </button>
      }
      @if (displayInput) {
        <button mat-stroked-button (click)="toogleShowInput()" class="justify-start option">Annuler</button>
      }
    </div>
  }
</div>
