import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationUser, OrganizationUserEntityState } from '@api/api-interfaces';
import { OrganizationUserRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationUserGeneratedActions = createActionGroup({
  source: 'Organization User Generated',
  events: {
    'Get One Organization User': props<{ idOrganizationUser: number; params?: any }>(),
    'Get Many Organization Users': props<{ params: any }>(),
    'Add Many Actives Organization Users': props<{ idOrganizationUsers: number[] }>(),
    'Delete One Active Organization User': props<{ idOrganizationUser: number }>(),
    'Clear Actives Organization Users': emptyProps(),
    'Upsert One Organization User': props<{
      organizationUser: Partial<OrganizationUser>;
      ids?: OrganizationUserRelationsIds;
    }>(),
    'Upsert Many Organization Users': props<{
      organizationUsers: Partial<OrganizationUser>[];
      ids?: OrganizationUserRelationsIds;
    }>(),
    'Upsert Many Organization Users Success': props<{ organizationUsers: OrganizationUserEntityState[] }>(),
    'Delete One Organization User': props<{ idOrganizationUser: number }>(),
    'Delete One Organization User Success': props<{ idOrganizationUser: number }>(),
    'Normalize Many Organization Users After Upsert': props<{ organizationUsers: OrganizationUserEntityState[] }>(),
    'Organization Users Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Users': emptyProps(),
    'Add Many User Board State Success': props<{ idOrganizationUser: number; idUserBoardStates: number[] }>(),
    'Delete Many User Board State Success': props<{ idUserBoardStates: number[]; idOrganizationUsers: number[] }>(),
    'Add Many User Right Success': props<{ idOrganizationUser: number; idUserRights: number[] }>(),
    'Delete Many User Right Success': props<{ idUserRights: number[]; idOrganizationUsers: number[] }>(),
    'Add Many Profil Success': props<{ idOrganizationUser: number; idProfils: number[] }>(),
    'Delete Many Profil Success': props<{ idProfils: number[]; idOrganizationUsers: number[] }>(),
    'Add Organization Success': props<{ idOrganizationUser: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationUsers: number[] }>(),
    'Add User Success': props<{ idOrganizationUser: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idOrganizationUsers: number[] }>()
  }
});
