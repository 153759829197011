import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { TodoElement, TodoElementEntityState } from '@api/api-interfaces';
import { ElementCash, ElementCashEntityState } from '@api/api-interfaces';
import { MeetingElement, MeetingElementEntityState } from '@api/api-interfaces';
import { TimelineElement, TimelineElementEntityState } from '@api/api-interfaces';
import { FolderElement, FolderElementEntityState } from '@api/api-interfaces';
import { ProjectElement, ProjectElementEntityState } from '@api/api-interfaces';
import { OrganizationElement, OrganizationElementEntityState } from '@api/api-interfaces';
import { ChildrenElement, ChildrenElementEntityState } from '@api/api-interfaces';
import { ElementLibrary, ElementLibraryEntityState } from '@api/api-interfaces';
import { Todo, TodoEntityState } from '@api/api-interfaces';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { ElementState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const elementRelations: string[] = [
  'todoElements',
  'elementCashs',
  'meetingElements',
  'timelineElements',
  'folderElements',
  'projectElements',
  'organizationElements',
  'childrenElements',
  'elementLibraries',
  'todos',
  'organizationMilestones'
];

export const { selectEntities, selectAll } = ElementState.adapter.getSelectors();

export const selectElementState = createFeatureSelector<ElementState.IState>(ElementState.elementFeatureKey);

export const selectIsLoadedElement = createSelector(selectElementState, (state: ElementState.IState) => state.isLoaded);

export const selectIsLoadingElement = createSelector(
  selectElementState,
  (state: ElementState.IState) => state.isLoading
);

export const selectIsReadyElement = createSelector(
  selectElementState,
  (state: ElementState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedElement = createSelector(
  selectElementState,
  (state: ElementState.IState) => state.isLoaded && !state.isLoading
);

export const selectElementsEntities = createSelector(selectElementState, selectEntities);

export const selectElementsArray = createSelector(selectElementState, selectAll);

export const selectIdElementsActive = createSelector(selectElementState, (state: ElementState.IState) => state.actives);

const elementsInObject = (elements: Dictionary<ElementEntityState>) => ({ elements });

const selectElementsEntitiesDictionary = createSelector(selectElementsEntities, elementsInObject);

const selectAllElementsObject = createSelector(selectElementsEntities, elements => {
  return hydrateAll({ elements });
});

const selectOneElementDictionary = (idElement: number) =>
  createSelector(selectElementsEntities, elements => ({
    elements: { [idElement]: elements[idElement] }
  }));

const selectOneElementDictionaryWithoutChild = (idElement: number) =>
  createSelector(selectElementsEntities, elements => ({
    element: elements[idElement]
  }));

const selectActiveElementsEntities = createSelector(
  selectIdElementsActive,
  selectElementsEntities,
  (actives: number[], elements: Dictionary<ElementEntityState>) => getElementsFromActives(actives, elements)
);

function getElementsFromActives(
  actives: number[],
  elements: Dictionary<ElementEntityState>
): Dictionary<ElementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (elements[idActive]) {
      acc[idActive] = elements[idActive];
    }
    return acc;
  }, {} as Dictionary<ElementEntityState>);
}

const selectAllElementsSelectors: Dictionary<Selector> = {};
export function selectAllElements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Element>(
      schema,
      selectAllElementsSelectors,
      selectElementsEntitiesDictionary,
      getRelationSelectors,
      elementRelations,
      hydrateAll,
      'element'
    );
  } else {
    return selectAllElementsObject;
  }
}

export function selectAllElementsDictionary(schema: SelectSchema = {}, customKey: string = 'elements'): Selector {
  return createSelector(selectAllElements(schema), result => {
    const res = { [customKey]: {} as Dictionary<ElementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.elements.length; i++) {
      res[customKey][result.elements[i].idElement] = result.elements[i];
    }
    return res;
  });
}

export function selectOneElement(schema: SelectSchema = {}, idElement: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneElementDictionary(idElement)];
    selectors.push(...getRelationSelectors(schema, elementRelations, 'element'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneElementDictionaryWithoutChild(idElement);
  }
}

export function selectActiveElements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveElementsEntities, elements => ({
      elements
    }))
  ];
  selectors.push(...getRelationSelectors(schema, elementRelations, 'element'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  elements: Dictionary<ElementEntityState>;
  organizationMilestones?: Dictionary<OrganizationMilestoneEntityState>;
  todoElements?: Dictionary<TodoElementEntityState>;
  elementCashs?: Dictionary<ElementCashEntityState>;
  meetingElements?: Dictionary<MeetingElementEntityState>;
  timelineElements?: Dictionary<TimelineElementEntityState>;
  folderElements?: Dictionary<FolderElementEntityState>;
  projectElements?: Dictionary<ProjectElementEntityState>;
  organizationElements?: Dictionary<OrganizationElementEntityState>;
  childrenElements?: Dictionary<ChildrenElementEntityState>;
  elementLibraries?: Dictionary<ElementLibraryEntityState>;
  todos?: Dictionary<TodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { elements: (Element | null)[] } {
  const {
    elements,
    organizationMilestones,
    todoElements,
    elementCashs,
    meetingElements,
    timelineElements,
    folderElements,
    projectElements,
    organizationElements,
    childrenElements,
    elementLibraries,
    todos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    elements: Object.keys(elements).map(idElement =>
      hydrate(
        elements[idElement] as ElementEntityState,
        organizationMilestones,
        todoElements,
        elementCashs,
        meetingElements,
        timelineElements,
        folderElements,
        projectElements,
        organizationElements,
        childrenElements,
        elementLibraries,
        todos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { element: ElementEntityState | null } {
  const {
    elements,
    organizationMilestones,
    todoElements,
    elementCashs,
    meetingElements,
    timelineElements,
    folderElements,
    projectElements,
    organizationElements,
    childrenElements,
    elementLibraries,
    todos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const element = Object.values(elements)[0];
  return {
    element: hydrate(
      element as ElementEntityState,
      organizationMilestones,
      todoElements,
      elementCashs,
      meetingElements,
      timelineElements,
      folderElements,
      projectElements,
      organizationElements,
      childrenElements,
      elementLibraries,
      todos
    )
  };
}

function hydrate(
  element: ElementEntityState,
  organizationMilestoneEntities?: Dictionary<OrganizationMilestoneEntityState>,
  todoElementEntities?: Dictionary<TodoElementEntityState>,
  elementCashEntities?: Dictionary<ElementCashEntityState>,
  meetingElementEntities?: Dictionary<MeetingElementEntityState>,
  timelineElementEntities?: Dictionary<TimelineElementEntityState>,
  folderElementEntities?: Dictionary<FolderElementEntityState>,
  projectElementEntities?: Dictionary<ProjectElementEntityState>,
  organizationElementEntities?: Dictionary<OrganizationElementEntityState>,
  childrenElementEntities?: Dictionary<ChildrenElementEntityState>,
  elementLibraryEntities?: Dictionary<ElementLibraryEntityState>,
  todoEntities?: Dictionary<TodoEntityState>
): Element | null {
  if (!element) {
    return null;
  }

  const elementHydrated: ElementEntityState = { ...element };
  if (organizationMilestoneEntities) {
    elementHydrated.organizationMilestone = organizationMilestoneEntities[
      element.organizationMilestone as number
    ] as OrganizationMilestone;
  } else {
    delete elementHydrated.organizationMilestone;
  }

  if (todoElementEntities) {
    elementHydrated.todoElements = ((elementHydrated.todoElements as number[]) || []).map(
      id => todoElementEntities[id]
    ) as TodoElement[];
  } else {
    delete elementHydrated.todoElements;
  }

  if (elementCashEntities) {
    elementHydrated.elementCashs = ((elementHydrated.elementCashs as number[]) || []).map(
      id => elementCashEntities[id]
    ) as ElementCash[];
  } else {
    delete elementHydrated.elementCashs;
  }

  if (meetingElementEntities) {
    elementHydrated.meetingElements = ((elementHydrated.meetingElements as number[]) || []).map(
      id => meetingElementEntities[id]
    ) as MeetingElement[];
  } else {
    delete elementHydrated.meetingElements;
  }

  if (timelineElementEntities) {
    elementHydrated.timelineElements = ((elementHydrated.timelineElements as number[]) || []).map(
      id => timelineElementEntities[id]
    ) as TimelineElement[];
  } else {
    delete elementHydrated.timelineElements;
  }

  if (folderElementEntities) {
    elementHydrated.folderElements = ((elementHydrated.folderElements as number[]) || []).map(
      id => folderElementEntities[id]
    ) as FolderElement[];
  } else {
    delete elementHydrated.folderElements;
  }

  if (projectElementEntities) {
    elementHydrated.projectElements = ((elementHydrated.projectElements as number[]) || []).map(
      id => projectElementEntities[id]
    ) as ProjectElement[];
  } else {
    delete elementHydrated.projectElements;
  }

  if (organizationElementEntities) {
    elementHydrated.organizationElements = ((elementHydrated.organizationElements as number[]) || []).map(
      id => organizationElementEntities[id]
    ) as OrganizationElement[];
  } else {
    delete elementHydrated.organizationElements;
  }

  if (childrenElementEntities) {
    elementHydrated.childrenElements = ((elementHydrated.childrenElements as number[]) || []).map(
      id => childrenElementEntities[id]
    ) as ChildrenElement[];
  } else {
    delete elementHydrated.childrenElements;
  }

  if (elementLibraryEntities) {
    elementHydrated.elementLibraries = ((elementHydrated.elementLibraries as number[]) || []).map(
      id => elementLibraryEntities[id]
    ) as ElementLibrary[];
  } else {
    delete elementHydrated.elementLibraries;
  }

  if (todoEntities) {
    elementHydrated.todos = ((elementHydrated.todos as number[]) || []).map(id => todoEntities[id]) as Todo[];
  } else {
    delete elementHydrated.todos;
  }

  return elementHydrated as Element;
}
