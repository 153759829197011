import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationUserGeneratedActions } from '@wip/store/actions';
import { OrganizationUserState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationUserEntityState } from '@api/api-interfaces';

export const organizationUserReducersGeneratedFunctions: ReducerTypes<
  OrganizationUserState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationUserGeneratedActions.getOneOrganizationUser, (state: OrganizationUserState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationUserGeneratedActions.getManyOrganizationUsers, (state: OrganizationUserState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationUserGeneratedActions.upsertOneOrganizationUser, (state: OrganizationUserState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationUserGeneratedActions.upsertManyOrganizationUsersSuccess,
    (state: OrganizationUserState.IState, { organizationUsers }) =>
      OrganizationUserState.adapter.upsertMany(organizationUsers, setLoadingsState(state, false))
  ),
  on(OrganizationUserGeneratedActions.deleteOneOrganizationUser, (state: OrganizationUserState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationUserGeneratedActions.deleteOneOrganizationUserSuccess,
    (state: OrganizationUserState.IState, { idOrganizationUser }) =>
      OrganizationUserState.adapter.removeOne(idOrganizationUser, setLoadingsState(state, false))
  ),
  on(OrganizationUserGeneratedActions.clearActivesOrganizationUsers, (state: OrganizationUserState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationUserGeneratedActions.addManyActivesOrganizationUsers,
    (state: OrganizationUserState.IState, { idOrganizationUsers }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationUsers)
    })
  ),
  on(
    OrganizationUserGeneratedActions.deleteOneActiveOrganizationUser,
    (state: OrganizationUserState.IState, { idOrganizationUser }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationUser)
    })
  ),

  on(OrganizationUserGeneratedActions.clearOrganizationUsers, () => OrganizationUserState.initialState),

  on(
    OrganizationUserGeneratedActions.addManyUserBoardStateSuccess,
    (state: OrganizationUserState.IState, { idOrganizationUser, idUserBoardStates }) => {
      if (!state.entities[idOrganizationUser]) {
        return state;
      }
      const userBoardStates = (state.entities[idOrganizationUser]?.userBoardStates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUser]: {
            ...state.entities[idOrganizationUser],
            userBoardStates: userBoardStates.concat(idUserBoardStates.filter(id => userBoardStates.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationUserGeneratedActions.deleteManyUserBoardStateSuccess,
    (state: OrganizationUserState.IState, { idUserBoardStates, idOrganizationUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUsers.reduce((entities, idOrganizationUser) => {
            if (!state.entities[idOrganizationUser]?.userBoardStates) {
              return entities;
            }
            entities[idOrganizationUser] = {
              ...state.entities[idOrganizationUser],
              userBoardStates: (state.entities[idOrganizationUser]?.userBoardStates as number[])?.filter(
                (idUserBoardState: number) => !idUserBoardStates.some((id: number) => id === idUserBoardState)
              )
            } as OrganizationUserEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationUserGeneratedActions.addManyUserRightSuccess,
    (state: OrganizationUserState.IState, { idOrganizationUser, idUserRights }) => {
      if (!state.entities[idOrganizationUser]) {
        return state;
      }
      const userRights = (state.entities[idOrganizationUser]?.userRights as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUser]: {
            ...state.entities[idOrganizationUser],
            userRights: userRights.concat(idUserRights.filter(id => userRights.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationUserGeneratedActions.deleteManyUserRightSuccess,
    (state: OrganizationUserState.IState, { idUserRights, idOrganizationUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUsers.reduce((entities, idOrganizationUser) => {
            if (!state.entities[idOrganizationUser]?.userRights) {
              return entities;
            }
            entities[idOrganizationUser] = {
              ...state.entities[idOrganizationUser],
              userRights: (state.entities[idOrganizationUser]?.userRights as number[])?.filter(
                (idUserRight: number) => !idUserRights.some((id: number) => id === idUserRight)
              )
            } as OrganizationUserEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationUserGeneratedActions.addManyProfilSuccess,
    (state: OrganizationUserState.IState, { idOrganizationUser, idProfils }) => {
      if (!state.entities[idOrganizationUser]) {
        return state;
      }
      const profils = (state.entities[idOrganizationUser]?.profils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUser]: {
            ...state.entities[idOrganizationUser],
            profils: profils.concat(idProfils.filter(id => profils.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationUserGeneratedActions.deleteManyProfilSuccess,
    (state: OrganizationUserState.IState, { idProfils, idOrganizationUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUsers.reduce((entities, idOrganizationUser) => {
            if (!state.entities[idOrganizationUser]?.profils) {
              return entities;
            }
            entities[idOrganizationUser] = {
              ...state.entities[idOrganizationUser],
              profils: (state.entities[idOrganizationUser]?.profils as number[])?.filter(
                (idProfil: number) => !idProfils.some((id: number) => id === idProfil)
              )
            } as OrganizationUserEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationUserGeneratedActions.addOrganizationSuccess,
    (state: OrganizationUserState.IState, { idOrganizationUser, idOrganization }) => {
      if (!state.entities[idOrganizationUser]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUser]: {
            ...state.entities[idOrganizationUser],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationUserGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationUserState.IState, { idOrganizations, idOrganizationUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUsers.reduce((entities, idOrganizationUser) => {
            if (!state.entities[idOrganizationUser]?.organization) {
              return entities;
            }
            entities[idOrganizationUser] = {
              ...state.entities[idOrganizationUser],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationUser]?.organization
              )
                ? undefined
                : state.entities[idOrganizationUser]?.organization
            } as OrganizationUserEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationUserGeneratedActions.addUserSuccess,
    (state: OrganizationUserState.IState, { idOrganizationUser, idUser }) => {
      if (!state.entities[idOrganizationUser]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUser]: {
            ...state.entities[idOrganizationUser],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    OrganizationUserGeneratedActions.deleteManyUserSuccess,
    (state: OrganizationUserState.IState, { idUsers, idOrganizationUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUsers.reduce((entities, idOrganizationUser) => {
            if (!state.entities[idOrganizationUser]?.user) {
              return entities;
            }
            entities[idOrganizationUser] = {
              ...state.entities[idOrganizationUser],
              user: idUsers.some((idUser: number) => idUser === state.entities[idOrganizationUser]?.user)
                ? undefined
                : state.entities[idOrganizationUser]?.user
            } as OrganizationUserEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationUserState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationUserState.IState {
  return { ...state, isLoaded, isLoading };
}
