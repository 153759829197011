import { createActionGroup, props } from '@ngrx/store';
import { ReorderProfilInterface } from '@api/api-interfaces';

export const ProfilActions = createActionGroup({
  source: 'Profil',
  events: {
    'Reorder Profils': props<{ params: ReorderProfilInterface }>(),
    'Duplicate Profil': props<{ idProfil: number }>()
  }
});
