export const environment = {
  production: false,
  development: false,
  integration: true,
  qualif: false,
  test: false,
  hmr: false,
  electron: false,
  capacitor: false,
  reduxTools: false,
  useDevtool: false,
  frontUrl: 'https://app.integration.wipbycellance.com',
  apiV1Url: 'https://api.integration.wipbycellance.com',
  apiV2Url: 'https://apiv2.integration.wipbycellance.com/apiV2',
  apiKeyGoogleMap: 'AIzaSyAb-a0RxvG1gzxkFUBRaYFyAthgGdKJh7k',
  geoApi: 'https://api.integration.geo.cellance.com'
};
