<div mat-dialog-title>
  <h1>{{ title }}</h1>
  <button mat-icon-button class="gulp gulp-34-close" [mat-dialog-close]="false" tabindex="-1"></button>
</div>
<div mat-dialog-content>
  <p class="text-padding" [innerHTML]="body"></p>
</div>
<div mat-dialog-actions class="gap-8">
  <button mat-button type="button" [mat-dialog-close]="false" [color]="actions.get('cancel')?.color" tabindex="-1">
    {{ actions.get('cancel')?.label }}
  </button>
  <button mat-flat-button type="button" [mat-dialog-close]="true" [color]="actions.get('confirm')?.color">
    {{ actions.get('confirm')?.label }}
  </button>
</div>
