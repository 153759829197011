<div>
  <div class="margin-bottom">
    <h2>Programme</h2>

    @if (community && (community.adresse || community.codePostal || community.commune)) {
      <div class="user-select margin-bottom">
        @if (community.adresse) {
          <span> {{ community.adresse }} </span>
        }
        @if (community.adresse) {
          <span style="color: lightgray">|</span>
        }
        @if (community.codePostal) {
          <span> {{ community.codePostal }} </span>
        }
        @if (community.commune) {
          <span style="color: lightgray">|</span>
        }
        @if (community.commune) {
          <span> {{ community.commune }} </span>
        }
      </div>
    }
  </div>
</div>
<wip-google-maps-embed [title]="title" [adresse]="adresse" [width]="width" [height]="height" class="border-radius" />
