export * from './meeting-element-generated.actions';
import { createAction, props } from '@ngrx/store';
import { ReorderBasicInterface } from '@api/api-interfaces';

export const reorderMeetingElements = createAction(
  '[MeetingElement] Reorder Meeting Elements',
  props<{
    params: ReorderBasicInterface;
  }>()
);
