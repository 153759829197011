<div
  (dblclick)="handleDblClick()"
  (mouseenter)="setHover()"
  (mouseleave)="setHover()"
  class="document-list-item flex flex-row gap-8 flex-1 box-border"
>
  <!-- @if (!isLoading) {
    <div [ngStyle]="{ color: fileIcon.colorClass }" class="gulp {{ fileIcon.logoClass }} icon-document-display"></div>
  } -->

  <mat-icon class="align-self-center" [ngStyle]="{ color: fileIcon.colorClass }" svgIcon="{{ fileIcon.logoClass }}">
  </mat-icon>
  <span class="document-title flex justify-start items-center"> {{ document.titre + document.extension }} </span>

  @if (!!hover) {
    <div class="flex flex-1 box-border justify-end items-center">
      @if (isPicture || isPDF) {
        <button mat-icon-button (click)="previewItem()">
          <i class="icon icon-ic_fluent_eye_show_24_regular" style="font-size: 24px"></i>
        </button>
      }
      <button mat-icon-button (click)="downloadItem()">
        <i class="icon icon-ic_fluent_cloud_download_24_regular" style="font-size: 24px"></i>
      </button>
      @if (!isViewer) {
        <button mat-icon-button (click)="deleteItem()">
          <i class="icon icon-ic_fluent_delete_24_regular" style="font-size: 24px"></i>
        </button>
      }
    </div>
  }
</div>
