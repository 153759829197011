import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationSubFamily, OrganizationSubFamilyEntityState } from '@api/api-interfaces';
import { OrganizationSubFamilyApiService } from '@wip/store/api-services';
import { OrganizationSubFamilyGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationSubFamily } from '@wip/store/configs/normalization';
import { OrganizationSubFamilySelectors } from '@wip/store/selectors';
import { OrganizationSubFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { CommunitySubFamilyGeneratedActions } from '@wip/store/actions';
import { CommunitySubFamily } from '@api/api-interfaces';
import { UserRightGeneratedActions } from '@wip/store/actions';
import { UserRight } from '@api/api-interfaces';

export function getDefaultAddOrganizationSubFamilyActions(
  organizationSubFamily: OrganizationSubFamilyEntityState,
  ids?: OrganizationSubFamilyRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationSubFamilyGeneratedActions.normalizeManyOrganizationSubFamilysAfterUpsert({
      organizationSubFamilys: [organizationSubFamily]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationSubFamilySuccess({
        idOrganization: ids.organization,
        idOrganizationSubFamilys: [organizationSubFamily.idOrganizationSubFamily]
      })
    );
    actions.push(
      OrganizationSubFamilyGeneratedActions.addOrganizationSuccess({
        idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyOrganizationSubFamilySuccess({
        idOrganizationFamily: ids.organizationFamily,
        idOrganizationSubFamilys: [organizationSubFamily.idOrganizationSubFamily]
      })
    );
    actions.push(
      OrganizationSubFamilyGeneratedActions.addOrganizationFamilySuccess({
        idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.communitySubFamilys) {
    if (!Array.isArray(ids.communitySubFamilys)) {
      actions.push(
        CommunitySubFamilyGeneratedActions.upsertOneCommunitySubFamily({
          communitySubFamily: {
            idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
            idCommunitySubFamily: ids.communitySubFamilys as number
          } as CommunitySubFamily
        })
      );
      actions.push(
        OrganizationSubFamilyGeneratedActions.addManyCommunitySubFamilySuccess({
          idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
          idCommunitySubFamilys: [ids.communitySubFamilys as number]
        })
      );
    } else {
      actions.push(
        CommunitySubFamilyGeneratedActions.upsertManyCommunitySubFamilys({
          communitySubFamilys: (ids.communitySubFamilys as number[]).map((idCommunitySubFamily: number) => ({
            idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
            idCommunitySubFamily
          })) as CommunitySubFamily[]
        })
      );
      actions.push(
        OrganizationSubFamilyGeneratedActions.addManyCommunitySubFamilySuccess({
          idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
          idCommunitySubFamilys: ids.communitySubFamilys as number[]
        })
      );
    }
  }

  if (ids?.userRights) {
    if (!Array.isArray(ids.userRights)) {
      actions.push(
        UserRightGeneratedActions.upsertOneUserRight({
          userRight: {
            idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
            idUserRight: ids.userRights as number
          } as UserRight
        })
      );
      actions.push(
        OrganizationSubFamilyGeneratedActions.addManyUserRightSuccess({
          idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
          idUserRights: [ids.userRights as number]
        })
      );
    } else {
      actions.push(
        UserRightGeneratedActions.upsertManyUserRights({
          userRights: (ids.userRights as number[]).map((idUserRight: number) => ({
            idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
            idUserRight
          })) as UserRight[]
        })
      );
      actions.push(
        OrganizationSubFamilyGeneratedActions.addManyUserRightSuccess({
          idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily,
          idUserRights: ids.userRights as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationSubFamilyActions(
  organizationSubFamily: OrganizationSubFamilyEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationSubFamilyGeneratedActions.deleteOneOrganizationSubFamilySuccess({
      idOrganizationSubFamily: organizationSubFamily.idOrganizationSubFamily
    })
  ];

  if (organizationSubFamily.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationSubFamilySuccess({
        idOrganizationSubFamilys: [organizationSubFamily.idOrganizationSubFamily],
        idOrganizations: [organizationSubFamily.organization as number]
      })
    );
  }

  if (organizationSubFamily.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationSubFamilySuccess({
        idOrganizationSubFamilys: [organizationSubFamily.idOrganizationSubFamily],
        idOrganizationFamilys: [organizationSubFamily.organizationFamily as number]
      })
    );
  }

  if (organizationSubFamily.communitySubFamilys) {
    actions.push(
      CommunitySubFamilyGeneratedActions.deleteManyOrganizationSubFamilySuccess({
        idOrganizationSubFamilys: [organizationSubFamily.idOrganizationSubFamily],
        idCommunitySubFamilys: organizationSubFamily.communitySubFamilys as number[]
      })
    );
  }

  if (organizationSubFamily.userRights) {
    actions.push(
      UserRightGeneratedActions.deleteManyOrganizationSubFamilySuccess({
        idOrganizationSubFamilys: [organizationSubFamily.idOrganizationSubFamily],
        idUserRights: organizationSubFamily.userRights as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationSubFamilyEffects {
  constructor(
    protected actions$: Actions,
    protected organizationSubFamilyApiService: OrganizationSubFamilyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationSubFamilys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationSubFamilyGeneratedActions.getManyOrganizationSubFamilys),
      switchMap(({ params }) =>
        this.organizationSubFamilyApiService.getOrganizationSubFamilys(params).pipe(
          map((organizationSubFamilys: OrganizationSubFamily[]) => {
            return OrganizationSubFamilyGeneratedActions.normalizeManyOrganizationSubFamilysAfterUpsert({
              organizationSubFamilys
            });
          }),
          catchError(error => of(OrganizationSubFamilyGeneratedActions.organizationSubFamilysFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationSubFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationSubFamilyGeneratedActions.getOneOrganizationSubFamily),
      switchMap(idOrganizationSubFamily =>
        this.organizationSubFamilyApiService.getOrganizationSubFamily(idOrganizationSubFamily).pipe(
          map((organizationSubFamily: OrganizationSubFamily) => {
            return OrganizationSubFamilyGeneratedActions.normalizeManyOrganizationSubFamilysAfterUpsert({
              organizationSubFamilys: [organizationSubFamily]
            });
          }),
          catchError(error => of(OrganizationSubFamilyGeneratedActions.organizationSubFamilysFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationSubFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationSubFamilyGeneratedActions.upsertOneOrganizationSubFamily),
      concatMap(
        ({
          organizationSubFamily,
          ids
        }: {
          organizationSubFamily: Partial<OrganizationSubFamily>;
          ids?: OrganizationSubFamilyRelationsIds;
        }) => {
          if (organizationSubFamily.idOrganizationSubFamily) {
            return this.organizationSubFamilyApiService.updateOrganizationSubFamily(organizationSubFamily).pipe(
              map((organizationSubFamilyReturned: OrganizationSubFamily) => {
                return OrganizationSubFamilyGeneratedActions.normalizeManyOrganizationSubFamilysAfterUpsert({
                  organizationSubFamilys: [organizationSubFamilyReturned]
                });
              }),
              catchError(error => of(OrganizationSubFamilyGeneratedActions.organizationSubFamilysFailure({ error })))
            );
          } else {
            return this.organizationSubFamilyApiService.addOrganizationSubFamily(organizationSubFamily).pipe(
              mergeMap((organizationSubFamilyReturned: OrganizationSubFamily) =>
                getDefaultAddOrganizationSubFamilyActions(organizationSubFamilyReturned, ids)
              ),
              catchError(error => of(OrganizationSubFamilyGeneratedActions.organizationSubFamilysFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationSubFamily$ = createEffect(() => {
    const selectOrganizationSubFamilyState$ = this.store$.select(
      OrganizationSubFamilySelectors.selectOrganizationSubFamilyState
    );
    return this.actions$.pipe(
      ofType(OrganizationSubFamilyGeneratedActions.deleteOneOrganizationSubFamily),
      withLatestFrom(selectOrganizationSubFamilyState$),
      concatMap(([{ idOrganizationSubFamily }, state]) =>
        this.organizationSubFamilyApiService.deleteOrganizationSubFamily(idOrganizationSubFamily).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationSubFamilyActions(
              state.entities[idOrganizationSubFamily] as OrganizationSubFamilyEntityState
            )
          ),
          catchError(error => of(OrganizationSubFamilyGeneratedActions.organizationSubFamilysFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationSubFamilysAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationSubFamilyGeneratedActions.normalizeManyOrganizationSubFamilysAfterUpsert),
      concatMap(({ organizationSubFamilys }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationSubFamily(
          organizationSubFamilys,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationSubFamily] Normalization After Upsert Success')];
      })
    );
  });
}
