import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OrganizationSubFamily } from '@api/api-interfaces';
import { ChartDatasService } from '../../features/charts/chart-services/chart-datas.service';

@Component({
  selector: 'app-project-sub-families-choices',
  templateUrl: './project-sub-families-choices.component.html',
  styleUrls: ['./project-sub-families-choices.component.scss']
})
export class ProjectSubFamiliesChoicesComponent implements OnInit, OnChanges {
  @Input() filtersGroup: FormGroup;
  @Input() organizationSubFamilies: OrganizationSubFamily[];
  @Input() subFamilyControl: FormControl;
  @Input() idOrganizationFamily?: number;

  public filteredOrganizationSubFamilies: OrganizationSubFamily[];

  constructor(private chartService: ChartDatasService) {}

  ngOnInit(): void {
    this.filteringOrgaSubFamily();
  }

  ngOnChanges(): void {
    this.filteringOrgaSubFamily();
  }

  openedChange(isOpened: boolean) {
    this.chartService.changeStateProjectSubFamilySelect(isOpened);
  }

  public setHashtag(hashtag: OrganizationSubFamily) {
    const isActive = this.subFamilyControl.value.some(val => val === hashtag.idOrganizationSubFamily);

    if (hashtag.idOrganizationSubFamily && !isActive) {
      this.subFamilyControl.setValue([...this.subFamilyControl.value, hashtag.idOrganizationSubFamily]);
    } else if (isActive) {
      const index = this.subFamilyControl.value.findIndex(val => val === hashtag.idOrganizationSubFamily);
      this.subFamilyControl.value.splice(index, 1);
      this.subFamilyControl.setValue([...this.subFamilyControl.value]);
    }
  }

  public setHashtagStyle(hashtag) {
    const selected = this.subFamilyControl.value.some(val => val === hashtag.idOrganizationSubFamily);

    const transparentColor = this.addOpacity(hashtag.couleur, 0.05);

    return {
      color: selected ? 'white' : hashtag.couleur,
      backgroundColor: selected ? hashtag.couleur : transparentColor,
      border: '1px solid ' + hashtag.couleur,
      boxSizing: 'border-box',
      padding: '2px 8px',
      height: '21px',
      borderRadius: '16px',
      whiteSpace: 'nowrap',
      fontSize: '12px'
    };
  }

  public eraseFilters() {
    this.subFamilyControl.setValue([]);
  }

  private addOpacity(hexColor, opacity) {
    const hex = hexColor?.replace(/^#/, '');
    const rgb = parseInt(hex, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = rgb & 0xff;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  private filteringOrgaSubFamily() {
    if (this.idOrganizationFamily) {
      this.filteredOrganizationSubFamilies = this.organizationSubFamilies.filter(
        el => el.idOrganizationFamily === this.idOrganizationFamily
      );
    } else {
      this.filteredOrganizationSubFamilies = this.organizationSubFamilies;
    }
  }
}
