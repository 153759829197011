import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NotesHistory, NotesHistoryEntityState } from '@api/api-interfaces';
import { NotesHistoryRelationsIds } from '@wip/store/ids-interfaces';

export const NotesHistoryGeneratedActions = createActionGroup({
  source: 'Notes History Generated',
  events: {
    'Get One Notes History': props<{ idNotesHistory: number; params?: any }>(),
    'Get Many Notes Histories': props<{ params: any }>(),
    'Add Many Actives Notes Histories': props<{ idNotesHistories: number[] }>(),
    'Delete One Active Notes History': props<{ idNotesHistory: number }>(),
    'Clear Actives Notes Histories': emptyProps(),
    'Upsert One Notes History': props<{ notesHistory: Partial<NotesHistory>; ids?: NotesHistoryRelationsIds }>(),
    'Upsert Many Notes Histories': props<{ notesHistories: Partial<NotesHistory>[]; ids?: NotesHistoryRelationsIds }>(),
    'Upsert Many Notes Histories Success': props<{ notesHistories: NotesHistoryEntityState[] }>(),
    'Delete One Notes History': props<{ idNotesHistory: number }>(),
    'Delete One Notes History Success': props<{ idNotesHistory: number }>(),
    'Normalize Many Notes Histories After Upsert': props<{ notesHistories: NotesHistoryEntityState[] }>(),
    'Notes Histories Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Notes Histories': emptyProps(),
    'Add Community Success': props<{ idNotesHistory: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idNotesHistories: number[] }>()
  }
});
