import { RepositoryService } from '@wip/services/repository';
import { CommunityMilestone } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityMilestoneApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityMilestones(params?: any): Observable<CommunityMilestone[]> {
    return this.repo.getData<CommunityMilestone[]>('community-milestone', params);
  }

  public getCommunityMilestone(params: { idCommunityMilestone: number; params?: any }): Observable<CommunityMilestone> {
    return this.repo.getData<CommunityMilestone>('community-milestone/' + params.idCommunityMilestone, params.params);
  }

  public addCommunityMilestone(communityMilestone: Partial<CommunityMilestone>): Observable<CommunityMilestone> {
    return this.repo.create<CommunityMilestone>('community-milestone', communityMilestone);
  }

  public updateCommunityMilestone(communityMilestone: Partial<CommunityMilestone>): Observable<CommunityMilestone> {
    return this.repo.update('community-milestone', communityMilestone);
  }

  public deleteCommunityMilestone(idCommunityMilestone: number): Observable<number> {
    return this.repo.delete('community-milestone/' + +idCommunityMilestone);
  }
}
