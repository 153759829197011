@if (gridReload && (changeBoardstate$ | async)) {
  <wip-project-grid
    [idOrganization]="idOrganization"
    [organizationUser]="organizationUser$ | async"
    [organizationFamily]="organizationFamily$ | async"
    [idOrganizationMilestoneFamily]="idOrganizationMilestoneFamily"
    [idOrganizationFamily]="idOrganizationFamily"
    [displayRepartition]="displayRepartition"
    [listProjects]="(communities$ | async) || []"
    [listSteps]="(organizationSteps$ | async) || []"
    [listOrganizationUserProfils]="(organizationUserProfils$ | async) || []"
    [listOrganizationUsers]="(listOrganizationUsers$ | async) || []"
    [listCaracteristics]="(organizationCaracteristics$ | async) || []"
    [listMilestones]="(organizationMilestones$ | async) || []"
    [organizationDataColumns]="(organizationDataColumns$ | async) || []"
    [editMode]="editMode"
    [create]="create"
    [save]="save"
    [saveColumns]="saveColumns"
    [handle]="handle"
    [toggle]="toggle"
    [boardState]="changeBoardstate$ | async"
    (updateCaracteristic)="onUpdateCaracteristic($event)"
    (updateCommunity)="onUpdateCommunity($event)"
    (updateUserProfil)="onUpdateUserProfil($event)"
    (updateBoardState)="onUpdateBoardState($event)"
    (setUpdateButton)="onSetUpdateButton($event)"
    (boardStateChanges)="setButtons($event)"
    (disabledFilter)="isFilterActivated = !$event"
  />
}
