import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TimelineElement, TimelineElementEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { TimelineElementState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const timelineElementRelations: string[] = ['communities', 'elements'];

export const { selectEntities, selectAll } = TimelineElementState.adapter.getSelectors();

export const selectTimelineElementState = createFeatureSelector<TimelineElementState.IState>(
  TimelineElementState.timelineElementFeatureKey
);

export const selectIsLoadedTimelineElement = createSelector(
  selectTimelineElementState,
  (state: TimelineElementState.IState) => state.isLoaded
);

export const selectIsLoadingTimelineElement = createSelector(
  selectTimelineElementState,
  (state: TimelineElementState.IState) => state.isLoading
);

export const selectIsReadyTimelineElement = createSelector(
  selectTimelineElementState,
  (state: TimelineElementState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedTimelineElement = createSelector(
  selectTimelineElementState,
  (state: TimelineElementState.IState) => state.isLoaded && !state.isLoading
);

export const selectTimelineElementsEntities = createSelector(selectTimelineElementState, selectEntities);

export const selectTimelineElementsArray = createSelector(selectTimelineElementState, selectAll);

export const selectIdTimelineElementsActive = createSelector(
  selectTimelineElementState,
  (state: TimelineElementState.IState) => state.actives
);

const timelineElementsInObject = (timelineElements: Dictionary<TimelineElementEntityState>) => ({ timelineElements });

const selectTimelineElementsEntitiesDictionary = createSelector(
  selectTimelineElementsEntities,
  timelineElementsInObject
);

const selectAllTimelineElementsObject = createSelector(selectTimelineElementsEntities, timelineElements => {
  return hydrateAll({ timelineElements });
});

const selectOneTimelineElementDictionary = (idTimelineElement: number) =>
  createSelector(selectTimelineElementsEntities, timelineElements => ({
    timelineElements: { [idTimelineElement]: timelineElements[idTimelineElement] }
  }));

const selectOneTimelineElementDictionaryWithoutChild = (idTimelineElement: number) =>
  createSelector(selectTimelineElementsEntities, timelineElements => ({
    timelineElement: timelineElements[idTimelineElement]
  }));

const selectActiveTimelineElementsEntities = createSelector(
  selectIdTimelineElementsActive,
  selectTimelineElementsEntities,
  (actives: number[], timelineElements: Dictionary<TimelineElementEntityState>) =>
    getTimelineElementsFromActives(actives, timelineElements)
);

function getTimelineElementsFromActives(
  actives: number[],
  timelineElements: Dictionary<TimelineElementEntityState>
): Dictionary<TimelineElementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (timelineElements[idActive]) {
      acc[idActive] = timelineElements[idActive];
    }
    return acc;
  }, {} as Dictionary<TimelineElementEntityState>);
}

const selectAllTimelineElementsSelectors: Dictionary<Selector> = {};
export function selectAllTimelineElements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<TimelineElement>(
      schema,
      selectAllTimelineElementsSelectors,
      selectTimelineElementsEntitiesDictionary,
      getRelationSelectors,
      timelineElementRelations,
      hydrateAll,
      'timelineElement'
    );
  } else {
    return selectAllTimelineElementsObject;
  }
}

export function selectAllTimelineElementsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'timelineElements'
): Selector {
  return createSelector(selectAllTimelineElements(schema), result => {
    const res = { [customKey]: {} as Dictionary<TimelineElementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.timelineElements.length; i++) {
      res[customKey][result.timelineElements[i].idTimelineElement] = result.timelineElements[i];
    }
    return res;
  });
}

export function selectOneTimelineElement(schema: SelectSchema = {}, idTimelineElement: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneTimelineElementDictionary(idTimelineElement)];
    selectors.push(...getRelationSelectors(schema, timelineElementRelations, 'timelineElement'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneTimelineElementDictionaryWithoutChild(idTimelineElement);
  }
}

export function selectActiveTimelineElements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveTimelineElementsEntities, timelineElements => ({
      timelineElements
    }))
  ];
  selectors.push(...getRelationSelectors(schema, timelineElementRelations, 'timelineElement'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  timelineElements: Dictionary<TimelineElementEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  elements?: Dictionary<ElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { timelineElements: (TimelineElement | null)[] } {
  const { timelineElements, communities, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    timelineElements: Object.keys(timelineElements).map(idTimelineElement =>
      hydrate(timelineElements[idTimelineElement] as TimelineElementEntityState, communities, elements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { timelineElement: TimelineElementEntityState | null } {
  const { timelineElements, communities, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const timelineElement = Object.values(timelineElements)[0];
  return {
    timelineElement: hydrate(timelineElement as TimelineElementEntityState, communities, elements)
  };
}

function hydrate(
  timelineElement: TimelineElementEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  elementEntities?: Dictionary<ElementEntityState>
): TimelineElement | null {
  if (!timelineElement) {
    return null;
  }

  const timelineElementHydrated: TimelineElementEntityState = { ...timelineElement };
  if (communityEntities) {
    timelineElementHydrated.community = communityEntities[timelineElement.community as number] as Community;
  } else {
    delete timelineElementHydrated.community;
  }
  if (elementEntities) {
    timelineElementHydrated.element = elementEntities[timelineElement.element as number] as Element;
  } else {
    delete timelineElementHydrated.element;
  }

  return timelineElementHydrated as TimelineElement;
}
