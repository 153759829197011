import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Valeur, ValeurEntityState } from '@api/api-interfaces';
import { ValeurApiService } from '@wip/store/api-services';
import { ValeurGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeValeur } from '@wip/store/configs/normalization';
import { ValeurSelectors } from '@wip/store/selectors';
import { ValeurRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityDataRowGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnValueGeneratedActions } from '@wip/store/actions';

export function getDefaultAddValeurActions(valeur: ValeurEntityState, ids?: ValeurRelationsIds): Action[] {
  const actions: Action[] = [ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs: [valeur] })];

  if (ids?.communityDataRow) {
    actions.push(
      CommunityDataRowGeneratedActions.addManyValeurSuccess({
        idCommunityDataRow: ids.communityDataRow,
        idValeurs: [valeur.idValeur]
      })
    );
    actions.push(
      ValeurGeneratedActions.addCommunityDataRowSuccess({
        idValeur: valeur.idValeur,
        idCommunityDataRow: ids.communityDataRow
      })
    );
  }

  if (ids?.organizationDataColumn) {
    actions.push(
      OrganizationDataColumnGeneratedActions.addManyValeurSuccess({
        idOrganizationDataColumn: ids.organizationDataColumn,
        idValeurs: [valeur.idValeur]
      })
    );
    actions.push(
      ValeurGeneratedActions.addOrganizationDataColumnSuccess({
        idValeur: valeur.idValeur,
        idOrganizationDataColumn: ids.organizationDataColumn
      })
    );
  }

  if (ids?.organizationDataColumnValue) {
    actions.push(
      OrganizationDataColumnValueGeneratedActions.addManyValeurSuccess({
        idOrganizationDataColumnValue: ids.organizationDataColumnValue,
        idValeurs: [valeur.idValeur]
      })
    );
    actions.push(
      ValeurGeneratedActions.addOrganizationDataColumnValueSuccess({
        idValeur: valeur.idValeur,
        idOrganizationDataColumnValue: ids.organizationDataColumnValue
      })
    );
  }

  return actions;
}

export function getDefaultDeleteValeurActions(valeur: ValeurEntityState): Action[] {
  const actions: Action[] = [ValeurGeneratedActions.deleteOneValeurSuccess({ idValeur: valeur.idValeur })];

  if (valeur.communityDataRow) {
    actions.push(
      CommunityDataRowGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idCommunityDataRows: [valeur.communityDataRow as number]
      })
    );
  }

  if (valeur.organizationDataColumn) {
    actions.push(
      OrganizationDataColumnGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idOrganizationDataColumns: [valeur.organizationDataColumn as number]
      })
    );
  }

  if (valeur.organizationDataColumnValue) {
    actions.push(
      OrganizationDataColumnValueGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idOrganizationDataColumnValues: [valeur.organizationDataColumnValue as number]
      })
    );
  }

  return actions;
}

export class GeneratedValeurEffects {
  constructor(
    protected actions$: Actions,
    protected valeurApiService: ValeurApiService,
    protected store$: Store<AppState>
  ) {}

  getManyValeurs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.getManyValeurs),
      switchMap(({ params }) =>
        this.valeurApiService.getValeurs(params).pipe(
          map((valeurs: Valeur[]) => {
            return ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs });
          }),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        )
      )
    );
  });

  getOneValeur$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.getOneValeur),
      switchMap(idValeur =>
        this.valeurApiService.getValeur(idValeur).pipe(
          map((valeur: Valeur) => {
            return ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs: [valeur] });
          }),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        )
      )
    );
  });

  upsertOneValeur$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.upsertOneValeur),
      concatMap(({ valeur, ids }: { valeur: Partial<Valeur>; ids?: ValeurRelationsIds }) => {
        if (valeur.idValeur) {
          return this.valeurApiService.updateValeur(valeur).pipe(
            map((valeurReturned: Valeur) => {
              return ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs: [valeurReturned] });
            }),
            catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
          );
        } else {
          return this.valeurApiService.addValeur(valeur).pipe(
            mergeMap((valeurReturned: Valeur) => getDefaultAddValeurActions(valeurReturned, ids)),
            catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneValeur$ = createEffect(() => {
    const selectValeurState$ = this.store$.select(ValeurSelectors.selectValeurState);
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.deleteOneValeur),
      withLatestFrom(selectValeurState$),
      concatMap(([{ idValeur }, state]) =>
        this.valeurApiService.deleteValeur(idValeur).pipe(
          mergeMap(_success => getDefaultDeleteValeurActions(state.entities[idValeur] as ValeurEntityState)),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        )
      )
    );
  });

  normalizeManyValeursAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.normalizeManyValeursAfterUpsert),
      concatMap(({ valeurs }) => {
        const actions: Action[] = getActionsToNormalizeValeur(valeurs, StoreActionType.upsert);
        return [getMultiAction(actions, '[Valeur] Normalization After Upsert Success')];
      })
    );
  });
}
