import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GeneratedUserProfilRightEffects } from './user-profil-right-generated.effects';
import { UserProfilRightApiService } from '@wip/store/api-services';

@Injectable()
export class UserProfilRightEffects extends GeneratedUserProfilRightEffects {
  constructor(actions$: Actions, userProfilRightApiService: UserProfilRightApiService, store$: Store<AppState>) {
    super(actions$, userProfilRightApiService, store$);
  }
}
