import { ColumnState, GridApi } from '@ag-grid-community/core';

export function containsFilters(gridApi: GridApi): boolean {
  return Object.keys(gridApi.getFilterModel()).length > 0 || gridApi.isQuickFilterPresent();
}

export function getMarge(prix: number, vnc: number): number {
  return prix && vnc ? prix - vnc : 0;
}

export function removeDuplicateFromObjectArray(array: any[], identifieur: string): any[] {
  return array.filter((el, index, self) => self.findIndex(it => el[identifieur] === it[identifieur]) === index);
}

export function formatFloat(num: number, nbDecimal = 3): number {
  return parseFloat(num?.toFixed(nbDecimal));
}

export function hasColumnStatesChanges(
  currentColumnStates: ColumnState[],
  defaultColumnStates: ColumnState[]
): boolean {
  const newCurrent = JSON.parse(JSON.stringify(currentColumnStates || []));
  const newDefault = JSON.parse(JSON.stringify(defaultColumnStates || []));
  if (!newCurrent || !newDefault) {
    return true;
  }

  newCurrent.splice(
    newCurrent.findIndex((el: { colId: string }) => el.colId === '0' || el.colId === '1'),
    1
  );
  newDefault.splice(
    newDefault.findIndex((el: { colId: string }) => el.colId === '0' || el.colId === '1'),
    1
  );

  if (newCurrent.length === newDefault.length) {
    let hasChanges = false;

    const currentColumnStatesLength = newCurrent.length;

    const keys = ['colId', 'hide', 'pinned', 'rowGroup', 'sort'];

    const keysLength = keys.length;

    for (let i = 0; i < currentColumnStatesLength; i++) {
      let detectChanges = false;

      for (let j = 0; j < keysLength; j++) {
        const key = keys[j];

        if (newCurrent[i][key] !== newDefault[i][key]) {
          detectChanges = true;
          break;
        }
      }

      if (detectChanges) {
        hasChanges = true;
        break;
      }
    }

    return hasChanges;
  } else {
    return true;
  }
}

export function isSorted(columnStates: ColumnState[]): boolean {
  return columnStates?.length ? !!columnStates.find(columnState => !!columnState.sort) : false;
}

// export function onBoardStateChanges(boardState: Partial<UserBoardState>): void {
//   (this as BoardStatePersister).boardStateService.upsertOneUserBoardState({
//     idUserBoardState: (this as BoardStatePersister).boardState.idUserBoardState,
//     ...boardState
//   });
// }
