import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { TimelineElement, TimelineElementEntityState } from '@api/api-interfaces';
import { TimelineElementApiService } from '@wip/store/api-services';
import { TimelineElementGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeTimelineElement } from '@wip/store/configs/normalization';
import { TimelineElementSelectors } from '@wip/store/selectors';
import { TimelineElementRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { ElementGeneratedActions } from '@wip/store/actions';

export function getDefaultAddTimelineElementActions(
  timelineElement: TimelineElementEntityState,
  ids?: TimelineElementRelationsIds
): Action[] {
  const actions: Action[] = [
    TimelineElementGeneratedActions.normalizeManyTimelineElementsAfterUpsert({ timelineElements: [timelineElement] })
  ];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyTimelineElementSuccess({
        idCommunity: ids.community,
        idTimelineElements: [timelineElement.idTimelineElement]
      })
    );
    actions.push(
      TimelineElementGeneratedActions.addCommunitySuccess({
        idTimelineElement: timelineElement.idTimelineElement,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.element) {
    actions.push(
      ElementGeneratedActions.addManyTimelineElementSuccess({
        idElement: ids.element,
        idTimelineElements: [timelineElement.idTimelineElement]
      })
    );
    actions.push(
      TimelineElementGeneratedActions.addElementSuccess({
        idTimelineElement: timelineElement.idTimelineElement,
        idElement: ids.element
      })
    );
  }

  return actions;
}

export function getDefaultDeleteTimelineElementActions(timelineElement: TimelineElementEntityState): Action[] {
  const actions: Action[] = [
    TimelineElementGeneratedActions.deleteOneTimelineElementSuccess({
      idTimelineElement: timelineElement.idTimelineElement
    })
  ];

  if (timelineElement.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyTimelineElementSuccess({
        idTimelineElements: [timelineElement.idTimelineElement],
        idCommunities: [timelineElement.community as number]
      })
    );
  }

  if (timelineElement.element) {
    actions.push(
      ElementGeneratedActions.deleteManyTimelineElementSuccess({
        idTimelineElements: [timelineElement.idTimelineElement],
        idElements: [timelineElement.element as number]
      })
    );
  }

  return actions;
}

export class GeneratedTimelineElementEffects {
  constructor(
    protected actions$: Actions,
    protected timelineElementApiService: TimelineElementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyTimelineElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineElementGeneratedActions.getManyTimelineElements),
      switchMap(({ params }) =>
        this.timelineElementApiService.getTimelineElements(params).pipe(
          map((timelineElements: TimelineElement[]) => {
            return TimelineElementGeneratedActions.normalizeManyTimelineElementsAfterUpsert({ timelineElements });
          }),
          catchError(error => of(TimelineElementGeneratedActions.timelineElementsFailure({ error })))
        )
      )
    );
  });

  getOneTimelineElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineElementGeneratedActions.getOneTimelineElement),
      switchMap(idTimelineElement =>
        this.timelineElementApiService.getTimelineElement(idTimelineElement).pipe(
          map((timelineElement: TimelineElement) => {
            return TimelineElementGeneratedActions.normalizeManyTimelineElementsAfterUpsert({
              timelineElements: [timelineElement]
            });
          }),
          catchError(error => of(TimelineElementGeneratedActions.timelineElementsFailure({ error })))
        )
      )
    );
  });

  upsertOneTimelineElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineElementGeneratedActions.upsertOneTimelineElement),
      concatMap(
        ({
          timelineElement,
          ids
        }: {
          timelineElement: Partial<TimelineElement>;
          ids?: TimelineElementRelationsIds;
        }) => {
          if (timelineElement.idTimelineElement) {
            return this.timelineElementApiService.updateTimelineElement(timelineElement).pipe(
              map((timelineElementReturned: TimelineElement) => {
                return TimelineElementGeneratedActions.normalizeManyTimelineElementsAfterUpsert({
                  timelineElements: [timelineElementReturned]
                });
              }),
              catchError(error => of(TimelineElementGeneratedActions.timelineElementsFailure({ error })))
            );
          } else {
            return this.timelineElementApiService.addTimelineElement(timelineElement).pipe(
              mergeMap((timelineElementReturned: TimelineElement) =>
                getDefaultAddTimelineElementActions(timelineElementReturned, ids)
              ),
              catchError(error => of(TimelineElementGeneratedActions.timelineElementsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneTimelineElement$ = createEffect(() => {
    const selectTimelineElementState$ = this.store$.select(TimelineElementSelectors.selectTimelineElementState);
    return this.actions$.pipe(
      ofType(TimelineElementGeneratedActions.deleteOneTimelineElement),
      withLatestFrom(selectTimelineElementState$),
      concatMap(([{ idTimelineElement }, state]) =>
        this.timelineElementApiService.deleteTimelineElement(idTimelineElement).pipe(
          mergeMap(_success =>
            getDefaultDeleteTimelineElementActions(state.entities[idTimelineElement] as TimelineElementEntityState)
          ),
          catchError(error => of(TimelineElementGeneratedActions.timelineElementsFailure({ error })))
        )
      )
    );
  });

  normalizeManyTimelineElementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TimelineElementGeneratedActions.normalizeManyTimelineElementsAfterUpsert),
      concatMap(({ timelineElements }) => {
        const actions: Action[] = getActionsToNormalizeTimelineElement(timelineElements, StoreActionType.upsert);
        return [getMultiAction(actions, '[TimelineElement] Normalization After Upsert Success')];
      })
    );
  });
}
