import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedMeetingApiService } from './meeting-api-generated.service';
import { saveAs } from 'file-saver';
import { ConfigurationApiService } from 'libs/api/old-api-services/src';

@Injectable({
  providedIn: 'root'
})
export class MeetingApiService extends GeneratedMeetingApiService {
  constructor(
    repo: RepositoryService,
    private http: HttpClient,
    private configurationApi: ConfigurationApiService
  ) {
    super(repo);
  }

  public export2docx(params: { idMeeting: number; withDocs: boolean }): any {
    return this.http
      .get(this.configurationApi.fullAddressV2 + '/meeting/exportMeeting2Docx', { params, responseType: 'blob' })
      .subscribe((blob: any) => {
        saveAs(blob, 'output');
      });
  }

  public exportMeeting2docx(values: any) {
    const params: HttpParams = this.configurationApi.setParams(values);
    return this.http.get(this.configurationApi.fullAddressV2 + '/meeting/exportMeeting2docx', { params });
  }

  public requestOrUpdateCalendarIcs(values: any) {
    return this.http.post(this.configurationApi.fullAddressV2 + '/meeting/requestOrUpdateCalendarIcs', values);
  }
}
