import { RepositoryService } from '@wip/services/repository';
import { OrganizationMilestoneAssociation } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationMilestoneAssociationApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationMilestoneAssociations(params?: any): Observable<OrganizationMilestoneAssociation[]> {
    return this.repo.getData<OrganizationMilestoneAssociation[]>('organization-milestone-association', params);
  }

  public getOrganizationMilestoneAssociation(params: {
    idOrganizationMilestoneAssociation: number;
    params?: any;
  }): Observable<OrganizationMilestoneAssociation> {
    return this.repo.getData<OrganizationMilestoneAssociation>(
      'organization-milestone-association/' + params.idOrganizationMilestoneAssociation,
      params.params
    );
  }

  public addOrganizationMilestoneAssociation(
    organizationMilestoneAssociation: Partial<OrganizationMilestoneAssociation>
  ): Observable<OrganizationMilestoneAssociation> {
    return this.repo.create<OrganizationMilestoneAssociation>(
      'organization-milestone-association',
      organizationMilestoneAssociation
    );
  }

  public updateOrganizationMilestoneAssociation(
    organizationMilestoneAssociation: Partial<OrganizationMilestoneAssociation>
  ): Observable<OrganizationMilestoneAssociation> {
    return this.repo.update('organization-milestone-association', organizationMilestoneAssociation);
  }

  public deleteOrganizationMilestoneAssociation(idOrganizationMilestoneAssociation: number): Observable<number> {
    return this.repo.delete('organization-milestone-association/' + +idOrganizationMilestoneAssociation);
  }
}
