import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AvatarComponentModule } from '@_app/shared/avatars/avatar/avatar.component';

import { SharedMaterialModule } from '../shared/shared-material/shared-material.module';
import { ActionCheckboxRendererComponent } from './action-checkbox-renderer/action-checkbox-renderer.component';
import { AvatarRendererComponent } from './ag-grid-avatar-renderer/ag-grid-avatar-renderer.component';
import { ColorCellEditorComponent } from './color-cell-editor/color-cell-editor.component';
import { ColorCellRendererComponent } from './color-cell-renderer/color-cell-renderer.component';
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { MaterialCheckboxCustomComponent } from './material-checkbox-custom/material-checkbox-custom.component';
import { MaterialCheckboxComponent } from './material-checkbox/material-checkbox.component';
import { MaterialColorComponent } from './material-color/material-color.component';
import { MaterialListChoiceComponent } from './material-list-choice/material-list-choice.component';
import { MaterialSelectComponent } from './material-select/material-select.component';
import { MaterialShowKanbanComponent } from './material-show-kanban/material-show-kanban.component';
import { SelectMultipleCellEditorComponent } from './select-multiple-cell-editor/select-multiple-cell-editor.component';
import { MilestoneRendererComponent } from './milestone-renderer/milestone-renderer.component';
import { CustomizableButtonComponent } from './customizable-button/customizable-button.component';

@NgModule({
  declarations: [
    ColorCellRendererComponent,
    ColorCellEditorComponent,
    MaterialCheckboxCustomComponent,
    MaterialCheckboxComponent,
    MaterialListChoiceComponent,
    MaterialShowKanbanComponent,
    ActionCheckboxRendererComponent,
    MaterialSelectComponent,
    MaterialColorComponent,
    DeleteButtonComponent,
    SelectMultipleCellEditorComponent,
    AvatarRendererComponent,
    MilestoneRendererComponent,
    CustomizableButtonComponent
  ],
  imports: [
    CommonModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    AvatarComponentModule
  ]
})
export class AgGridTemplatesModule {}
