import { OrganizationSubFamilyState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationSubFamilyReducersGeneratedFunctions } from './organization-sub-family-generated.reducer';

const organizationSubFamilyReducersFunctions = [...organizationSubFamilyReducersGeneratedFunctions];

const organizationSubFamilyReducer = createReducer(
  OrganizationSubFamilyState.initialState,
  ...organizationSubFamilyReducersFunctions
);

export function reducer(state: OrganizationSubFamilyState.IState | undefined, action: Action) {
  return organizationSubFamilyReducer(state, action);
}
