import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationMilestoneAssociationApiService } from './organization-milestone-association-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationMilestoneAssociationApiService extends GeneratedOrganizationMilestoneAssociationApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}

