import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChoicesEnum } from './choices.enum';
import { ChoicesInterface } from './choices.interface';

@Component({
  selector: 'app-textarea-dialog',
  templateUrl: './textarea-dialog.component.html',
  styleUrls: ['./textarea-dialog.component.scss']
})
export class TextareaDialogComponent implements OnInit {
  public title: string;
  public content: string;
  public actions: Map<string, ChoicesInterface>;
  public checkbox = true;

  constructor(
    public dialogRef: MatDialogRef<TextareaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.content = this.dialogData.defaultValue ? this.dialogData.defaultValue : '';
    this.title = this.dialogData.title;
  }

  ngOnInit() {
    this.actions = new Map();
    if (this.dialogData.actions) {
      this.dialogData.actions.forEach((action: ChoicesInterface) => {
        this.actions.set(action.color ? ChoicesEnum.CONFIRM : ChoicesEnum.CANCEL, {
          color: action.color,
          label: action.label
        });
      });
    } else {
      this.actions.set(ChoicesEnum.CONFIRM, {
        color: 'primary',
        label: 'Confirmer'
      });
      this.actions.set(ChoicesEnum.CANCEL, {
        color: '',
        label: 'Annuler'
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close({ content: this.content, checkbox: this.checkbox });
  }
}
