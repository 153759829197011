import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CommunityKpi } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { CommunityKpiRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityKpiGeneratedActions } from '@wip/store/actions';
import { CommunityKpiSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCommunityKpiService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CommunityKpiSelectors.selectIsLoadedCommunityKpi));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CommunityKpiSelectors.selectIsLoadingCommunityKpi));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [CommunityKpiSelectors.selectIsReadyAndLoadedCommunityKpi as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCommunityKpis(schema: SelectSchema = {}): Observable<CommunityKpi[]> {
    return this.store$.pipe(select(CommunityKpiSelectors.selectAllCommunityKpis(schema))).pipe(
      switchMap(({ communityKpis }: { communityKpis: CommunityKpi[] }) => {
        return this.getReady(schema).pipe(mapTo(communityKpis));
      })
    );
  }

  public selectOneCommunityKpi(idCommunityKpi: number, schema: SelectSchema = {}): Observable<CommunityKpi> {
    return this.store$.pipe(select(CommunityKpiSelectors.selectOneCommunityKpi(schema, idCommunityKpi))).pipe(
      switchMap(({ communityKpi }: { communityKpi: CommunityKpi }) => {
        return this.getReady(schema).pipe(mapTo(communityKpi));
      })
    );
  }

  public selectAllActiveCommunityKpis(schema: SelectSchema = {}): Observable<CommunityKpi[]> {
    return this.store$.pipe(select(CommunityKpiSelectors.selectActiveCommunityKpis(schema))).pipe(
      switchMap(({ communityKpis }: { communityKpis: CommunityKpi[] }) => {
        return this.getReady(schema).pipe(mapTo(communityKpis));
      })
    );
  }

  public selectIdCommunityKpisActive(): Observable<number[]> {
    return this.store$.pipe(select(CommunityKpiSelectors.selectIdCommunityKpisActive)).pipe(
      switchMap((idCommunityKpis: number[]) => {
        return this.getReady().pipe(mapTo(idCommunityKpis));
      })
    );
  }

  public getOneCommunityKpi(
    idCommunityKpi: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<CommunityKpi> {
    this.store$.dispatch(CommunityKpiGeneratedActions.getOneCommunityKpi({ idCommunityKpi, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityKpiGeneratedActions.normalizeManyCommunityKpisAfterUpsert,
        CommunityKpiGeneratedActions.communityKpisFailure,
        true
      );
    }
  }

  public getManyCommunityKpis(params: any = {}, getResult?: boolean): void | Observable<CommunityKpi[]> {
    this.store$.dispatch(CommunityKpiGeneratedActions.getManyCommunityKpis({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityKpiGeneratedActions.normalizeManyCommunityKpisAfterUpsert,
        CommunityKpiGeneratedActions.communityKpisFailure
      );
    }
  }

  public upsertOneCommunityKpi(
    communityKpi: Partial<CommunityKpi>,
    ids: CommunityKpiRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CommunityKpi> {
    this.store$.dispatch(CommunityKpiGeneratedActions.upsertOneCommunityKpi({ communityKpi, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityKpiGeneratedActions.normalizeManyCommunityKpisAfterUpsert,
        CommunityKpiGeneratedActions.communityKpisFailure,
        true
      );
    }
  }

  public deleteOneCommunityKpi(idCommunityKpi: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CommunityKpiGeneratedActions.deleteOneCommunityKpi({ idCommunityKpi }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityKpiGeneratedActions.deleteOneCommunityKpiSuccess,
        CommunityKpiGeneratedActions.communityKpisFailure
      );
    }
  }

  public setActiveCommunityKpis(idCommunityKpis: number[]): void {
    this.store$.dispatch(CommunityKpiGeneratedActions.clearActivesCommunityKpis());
    this.store$.dispatch(CommunityKpiGeneratedActions.addManyActivesCommunityKpis({ idCommunityKpis }));
  }
}
