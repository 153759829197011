import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationSubFamily, OrganizationSubFamilyEntityState } from '@api/api-interfaces';
import { OrganizationSubFamilyRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationSubFamilyGeneratedActions = createActionGroup({
  source: 'Organization Sub Family Generated',
  events: {
    'Get One Organization Sub Family': props<{ idOrganizationSubFamily: number; params?: any }>(),
    'Get Many Organization Sub Familys': props<{ params: any }>(),
    'Add Many Actives Organization Sub Familys': props<{ idOrganizationSubFamilys: number[] }>(),
    'Delete One Active Organization Sub Family': props<{ idOrganizationSubFamily: number }>(),
    'Clear Actives Organization Sub Familys': emptyProps(),
    'Upsert One Organization Sub Family': props<{
      organizationSubFamily: Partial<OrganizationSubFamily>;
      ids?: OrganizationSubFamilyRelationsIds;
    }>(),
    'Upsert Many Organization Sub Familys': props<{
      organizationSubFamilys: Partial<OrganizationSubFamily>[];
      ids?: OrganizationSubFamilyRelationsIds;
    }>(),
    'Upsert Many Organization Sub Familys Success': props<{
      organizationSubFamilys: OrganizationSubFamilyEntityState[];
    }>(),
    'Delete One Organization Sub Family': props<{ idOrganizationSubFamily: number }>(),
    'Delete One Organization Sub Family Success': props<{ idOrganizationSubFamily: number }>(),
    'Normalize Many Organization Sub Familys After Upsert': props<{
      organizationSubFamilys: OrganizationSubFamilyEntityState[];
    }>(),
    'Organization Sub Familys Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Sub Familys': emptyProps(),
    'Add Many Community Sub Family Success': props<{
      idOrganizationSubFamily: number;
      idCommunitySubFamilys: number[];
    }>(),
    'Delete Many Community Sub Family Success': props<{
      idCommunitySubFamilys: number[];
      idOrganizationSubFamilys: number[];
    }>(),
    'Add Many User Right Success': props<{ idOrganizationSubFamily: number; idUserRights: number[] }>(),
    'Delete Many User Right Success': props<{ idUserRights: number[]; idOrganizationSubFamilys: number[] }>(),
    'Add Organization Success': props<{ idOrganizationSubFamily: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationSubFamilys: number[] }>(),
    'Add Organization Family Success': props<{ idOrganizationSubFamily: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{
      idOrganizationFamilys: number[];
      idOrganizationSubFamilys: number[];
    }>()
  }
});
