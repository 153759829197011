import { Injectable } from '@angular/core';import { RepositoryService } from '@wip/services/repository';
import { GeneratedRightApiService } from './right-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class RightApiService extends GeneratedRightApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
