import { RepositoryService } from '@wip/services/repository';
import { OrganizationDataColumn } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationDataColumnApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationDataColumns(params?: any): Observable<OrganizationDataColumn[]> {
    return this.repo.getData<OrganizationDataColumn[]>('organization-data-column', params);
  }

  public getOrganizationDataColumn(params: {
    idOrganizationDataColumn: number;
    params?: any;
  }): Observable<OrganizationDataColumn> {
    return this.repo.getData<OrganizationDataColumn>(
      'organization-data-column/' + params.idOrganizationDataColumn,
      params.params
    );
  }

  public addOrganizationDataColumn(
    organizationDataColumn: Partial<OrganizationDataColumn>
  ): Observable<OrganizationDataColumn> {
    return this.repo.create<OrganizationDataColumn>('organization-data-column', organizationDataColumn);
  }

  public updateOrganizationDataColumn(
    organizationDataColumn: Partial<OrganizationDataColumn>
  ): Observable<OrganizationDataColumn> {
    return this.repo.update('organization-data-column', organizationDataColumn);
  }

  public deleteOrganizationDataColumn(idOrganizationDataColumn: number): Observable<number> {
    return this.repo.delete('organization-data-column/' + +idOrganizationDataColumn);
  }
}
