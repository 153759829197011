import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityKpi, CommunityKpiEntityState } from '@api/api-interfaces';
import { CommunityKpiApiService } from '@wip/store/api-services';
import { CommunityKpiGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityKpi } from '@wip/store/configs/normalization';
import { CommunityKpiSelectors } from '@wip/store/selectors';
import { CommunityKpiRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationKpiGeneratedActions } from '@wip/store/actions';
import { CommunityGeneratedActions } from '@wip/store/actions';

export function getDefaultAddCommunityKpiActions(
  communityKpi: CommunityKpiEntityState,
  ids?: CommunityKpiRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityKpiGeneratedActions.normalizeManyCommunityKpisAfterUpsert({ communityKpis: [communityKpi] })
  ];

  if (ids?.organizationKpi) {
    actions.push(
      OrganizationKpiGeneratedActions.addManyCommunityKpiSuccess({
        idOrganizationKpi: ids.organizationKpi,
        idCommunityKpis: [communityKpi.idCommunityKpi]
      })
    );
    actions.push(
      CommunityKpiGeneratedActions.addOrganizationKpiSuccess({
        idCommunityKpi: communityKpi.idCommunityKpi,
        idOrganizationKpi: ids.organizationKpi
      })
    );
  }

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityKpiSuccess({
        idCommunity: ids.community,
        idCommunityKpis: [communityKpi.idCommunityKpi]
      })
    );
    actions.push(
      CommunityKpiGeneratedActions.addCommunitySuccess({
        idCommunityKpi: communityKpi.idCommunityKpi,
        idCommunity: ids.community
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCommunityKpiActions(communityKpi: CommunityKpiEntityState): Action[] {
  const actions: Action[] = [
    CommunityKpiGeneratedActions.deleteOneCommunityKpiSuccess({ idCommunityKpi: communityKpi.idCommunityKpi })
  ];

  if (communityKpi.organizationKpi) {
    actions.push(
      OrganizationKpiGeneratedActions.deleteManyCommunityKpiSuccess({
        idCommunityKpis: [communityKpi.idCommunityKpi],
        idOrganizationKpis: [communityKpi.organizationKpi as number]
      })
    );
  }

  if (communityKpi.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityKpiSuccess({
        idCommunityKpis: [communityKpi.idCommunityKpi],
        idCommunities: [communityKpi.community as number]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityKpiEffects {
  constructor(
    protected actions$: Actions,
    protected communityKpiApiService: CommunityKpiApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityKpis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityKpiGeneratedActions.getManyCommunityKpis),
      switchMap(({ params }) =>
        this.communityKpiApiService.getCommunityKpis(params).pipe(
          map((communityKpis: CommunityKpi[]) => {
            return CommunityKpiGeneratedActions.normalizeManyCommunityKpisAfterUpsert({ communityKpis });
          }),
          catchError(error => of(CommunityKpiGeneratedActions.communityKpisFailure({ error })))
        )
      )
    );
  });

  getOneCommunityKpi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityKpiGeneratedActions.getOneCommunityKpi),
      switchMap(idCommunityKpi =>
        this.communityKpiApiService.getCommunityKpi(idCommunityKpi).pipe(
          map((communityKpi: CommunityKpi) => {
            return CommunityKpiGeneratedActions.normalizeManyCommunityKpisAfterUpsert({
              communityKpis: [communityKpi]
            });
          }),
          catchError(error => of(CommunityKpiGeneratedActions.communityKpisFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityKpi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityKpiGeneratedActions.upsertOneCommunityKpi),
      concatMap(({ communityKpi, ids }: { communityKpi: Partial<CommunityKpi>; ids?: CommunityKpiRelationsIds }) => {
        if (communityKpi.idCommunityKpi) {
          return this.communityKpiApiService.updateCommunityKpi(communityKpi).pipe(
            map((communityKpiReturned: CommunityKpi) => {
              return CommunityKpiGeneratedActions.normalizeManyCommunityKpisAfterUpsert({
                communityKpis: [communityKpiReturned]
              });
            }),
            catchError(error => of(CommunityKpiGeneratedActions.communityKpisFailure({ error })))
          );
        } else {
          return this.communityKpiApiService.addCommunityKpi(communityKpi).pipe(
            mergeMap((communityKpiReturned: CommunityKpi) =>
              getDefaultAddCommunityKpiActions(communityKpiReturned, ids)
            ),
            catchError(error => of(CommunityKpiGeneratedActions.communityKpisFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneCommunityKpi$ = createEffect(() => {
    const selectCommunityKpiState$ = this.store$.select(CommunityKpiSelectors.selectCommunityKpiState);
    return this.actions$.pipe(
      ofType(CommunityKpiGeneratedActions.deleteOneCommunityKpi),
      withLatestFrom(selectCommunityKpiState$),
      concatMap(([{ idCommunityKpi }, state]) =>
        this.communityKpiApiService.deleteCommunityKpi(idCommunityKpi).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityKpiActions(state.entities[idCommunityKpi] as CommunityKpiEntityState)
          ),
          catchError(error => of(CommunityKpiGeneratedActions.communityKpisFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityKpisAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityKpiGeneratedActions.normalizeManyCommunityKpisAfterUpsert),
      concatMap(({ communityKpis }) => {
        const actions: Action[] = getActionsToNormalizeCommunityKpi(communityKpis, StoreActionType.upsert);
        return [getMultiAction(actions, '[CommunityKpi] Normalization After Upsert Success')];
      })
    );
  });
}
