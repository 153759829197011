import { DocumentService } from '@_app/shared/elements/documents/document.service';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChildrenElement, Element } from '@api/api-interfaces';
import { ElementApiService } from '@wip/store/api-services';
import { ElementService } from '@wip/store/services';

const LEFT_ARROW = 'ArrowLeft';
const RIGHT_ARROW = 'ArrowRight';

@Component({
  selector: 'app-preview-image-dialog',
  templateUrl: './preview-image-dialog.component.html',
  styleUrls: ['./preview-image-dialog.component.scss']
})
export class PreviewImageDialogComponent implements OnInit {
  currentDocument: Element | ChildrenElement;
  public pdfSrc: Uint8Array;
  public isPicture: boolean = true;

  @ViewChild('preview', { static: false }) image: ElementRef;
  public title: string;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.didPressLeftArrow(event)) {
      this.goPreviousPicture();
    } else if (this.didPressRightArrow(event)) {
      this.goNextPicture();
    }
  }

  constructor(
    public dialogRef: MatDialogRef<PreviewImageDialogComponent>,
    private newElementService: ElementService,
    private elementApiService: ElementApiService,
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { document: Element; documents?: Element[] }
  ) {}

  ngOnInit() {
    this.currentDocument = this.dialogData.document;
    this.loadImage();
  }

  didPressLeftArrow(event) {
    return event.key === LEFT_ARROW;
  }

  didPressRightArrow(event) {
    return event.key === RIGHT_ARROW;
  }

  onClickDownload() {
    // Téléchargement du document en créant un lien invisible de téléchargement
    this.newElementService.downloadFile(this.currentDocument.idElement, this.currentDocument.titre);
  }

  onClickDelete() {
    this.dialogRef.close({ deleted: true });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  goPreviousPicture() {
    if (this.dialogData.documents) {
      const indexCurrentElement = this.getCurrentDocumentIndexFromArray();
      if (indexCurrentElement > 0) {
        this.currentDocument = this.dialogData.documents[indexCurrentElement - 1];
      }

      this.loadImage();
      this.hasPreviousPicture();
      this.hasNextPicture();
    }
  }
  hasPreviousPicture(): boolean {
    const indexCurrentElement = this.getCurrentDocumentIndexFromArray();
    if (indexCurrentElement > 0) {
      return true;
    } else {
      return false;
    }
  }

  goNextPicture() {
    if (this.dialogData.documents) {
      const indexCurrentElement = this.getCurrentDocumentIndexFromArray();
      if (indexCurrentElement < this.dialogData.documents.length - 1) {
        this.currentDocument = this.dialogData.documents[indexCurrentElement + 1];
      }

      this.loadImage();
      this.hasPreviousPicture();
      this.hasNextPicture();
    }
  }
  hasNextPicture(): boolean {
    const indexCurrentElement = this.getCurrentDocumentIndexFromArray();
    if (indexCurrentElement < this.dialogData.documents.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentDocumentIndexFromArray() {
    if (this.dialogData.documents) {
      const indexCurrentElement = this.dialogData.documents.findIndex(o => {
        if (o.UUID === this.currentDocument.UUID) {
          return true;
        } else {
          return false;
        }
      });
      return indexCurrentElement;
    }
  }

  loadImage() {
    if (this.currentDocument) {
      this.title = this.currentDocument.titre;
      const sizeUnusedBorders = 220;

      const isChildren = this.currentDocument.hasOwnProperty('idChildrenElement');

      this.elementApiService
        .preview({
          idElement: this.currentDocument.idElement,
          idChildrenElement: isChildren ? (this.currentDocument as ChildrenElement).idChildrenElement : 0,
          size: Math.floor(
            (window.innerHeight < window.innerWidth ? window.innerHeight : window.innerWidth) *
              window.devicePixelRatio -
              sizeUnusedBorders
          ),
          thumbnail: 'false'
        })
        .subscribe(resPreview => {
          if (this.documentService.isPicture(this.currentDocument.extension)) {
            this.isPicture = true;
            this.image.nativeElement.src = window.URL.createObjectURL(resPreview);
          } else {
            this.isPicture = false;
            const fileReader = new FileReader();
            fileReader.onload = () => {
              this.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
            };
            fileReader.readAsArrayBuffer(resPreview);
          }
        });
    }
  }
}
