import { GoogleMapsComponentModule } from '@_app/google-maps/google-maps.module';
import { GoogleMapsService } from '@_app/google-maps/googleMaps.service';
import { ProjectDashBoardModule } from '@_app/project-dashboard/project-dashboard.module';
import { AvatarComponentModule } from '@_app/shared/avatars/avatar/avatar.component';
import { ProjectSynthesisDialogComponent } from '@_app/shared/dialogs/project-synthesis/project-synthesis-dialog/project-synthesis-dialog.component';
import { SharedModule } from '@_app/shared/shared.module';
import { FilterVisualComponent } from '@_app/utils/ag-grid/status-bar/filter-visual/filter-visual.component';
import { StatusBarComponent } from '@_app/utils/ag-grid/status-bar/status-bar/status-bar.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DashboardComponent } from './dashboard.component';
import { DashboardRouting } from './dashboard.routing';
import { ChartDisplayComponent } from './features/charts/chart-display/chart-display.component';
import { ChartsContainerComponent } from './features/charts/charts-container/charts-container.component';
import { ChartsComponent } from './features/charts/charts.component';
import { GlobalMapListComponent } from './features/global-map/global-map-list/global-map-list.component';
import { GlobalMapComponent } from './features/global-map/global-map.component';
import { GridContainerComponent } from './features/grid/grid-container/grid-container.component';
import { GridComponent } from './features/grid/grid.component';
import { KanbanContainerComponent } from './features/kanban/list-kanban/kanban-container/kanban-container.component';
import { ListKanbanComponent } from './features/kanban/list-kanban/list-kanban.component';
import { ProjectKanbanComponent } from './features/kanban/project-kanban/project-kanban.component';
import { ProjectGridLegendComponent } from './features/synthesis/project-grid-legend/project-grid-legend.component';
import { FilterChipsComponent } from './features/synthesis/project-grid/filter-chips/filter-chips.component';
import { FilterConfigurationComponent } from './features/synthesis/project-grid/filter-configuration/filter-configuration.component';
import { FilterConfirmationDialogComponent } from './features/synthesis/project-grid/filter-confirmation-dialog/filter-confirmation-dialog.component';
import { FilterSaveComponent } from './features/synthesis/project-grid/filter-save/filter-save.component';
import { ProjectGridContainerComponent } from './features/synthesis/project-grid/project-grid-container/project-grid-container.component';
import { ProjectGridComponent } from './features/synthesis/project-grid/project-grid.component';
import { DashboardFilterModule } from './filters/dashboard-filters.component';
import { OrganizationChoicesModule } from './filters/organization-choices/organization-choices.component';

@NgModule({
  imports: [
    CommonModule,
    DashboardRouting,
    SharedModule.forRoot(),
    AgGridModule,
    DragDropModule,
    OrganizationChoicesModule,
    ProjectDashBoardModule,
    DashboardFilterModule,
    AvatarComponentModule,
    GoogleMapsComponentModule,
    MatSnackBarModule
  ],
  declarations: [
    DashboardComponent,
    KanbanContainerComponent,
    ListKanbanComponent,
    ProjectKanbanComponent,
    ProjectGridContainerComponent,
    ProjectGridComponent,
    ProjectGridLegendComponent,
    GridContainerComponent,
    GridComponent,
    ChartsContainerComponent,
    ChartsComponent,
    ChartDisplayComponent,
    StatusBarComponent,
    FilterVisualComponent,
    FilterSaveComponent,
    FilterChipsComponent,
    FilterConfirmationDialogComponent,
    FilterConfigurationComponent,
    ProjectSynthesisDialogComponent,
    GlobalMapComponent,
    GlobalMapListComponent
  ],
  providers: [GoogleMapsService]
})
export class DashboardModule {}
