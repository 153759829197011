import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationElementEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationElementEntityState>;

export const adapter = createEntityAdapter<OrganizationElementEntityState>({
  selectId: o => o.idOrganizationElement
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationElementFeatureKey = 'organizationElement';
