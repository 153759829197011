import { FilterChangedEvent } from '@ag-grid-community/core';
import { BoardStateManager } from './board-states';

export interface StatusBarOptions {
  component: BoardStateManager;
  onFilterChanged?: (event: FilterChangedEvent) => void;
  minimal?: boolean;
}

export enum ToolPanelsIds {
  columns = 'columns'
}
