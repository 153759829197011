import { OrganizationKpiState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationKpiReducersGeneratedFunctions } from './organization-kpi-generated.reducer';

const organizationKpiReducersFunctions = [...organizationKpiReducersGeneratedFunctions];

const organizationKpiReducer = createReducer(OrganizationKpiState.initialState, ...organizationKpiReducersFunctions);

export function reducer(state: OrganizationKpiState.IState | undefined, action: Action) {
  return organizationKpiReducer(state, action);
}
