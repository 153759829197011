import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityUserProfilGeneratedActions } from '@wip/store/actions';
import { CommunityUserProfilState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityUserProfilEntityState } from '@api/api-interfaces';

export const communityUserProfilReducersGeneratedFunctions: ReducerTypes<
  CommunityUserProfilState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunityUserProfilGeneratedActions.getOneCommunityUserProfil, (state: CommunityUserProfilState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityUserProfilGeneratedActions.getManyCommunityUserProfils, (state: CommunityUserProfilState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityUserProfilGeneratedActions.upsertOneCommunityUserProfil, (state: CommunityUserProfilState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    CommunityUserProfilGeneratedActions.upsertManyCommunityUserProfilsSuccess,
    (state: CommunityUserProfilState.IState, { communityUserProfils }) =>
      CommunityUserProfilState.adapter.upsertMany(communityUserProfils, setLoadingsState(state, false))
  ),
  on(CommunityUserProfilGeneratedActions.deleteOneCommunityUserProfil, (state: CommunityUserProfilState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    CommunityUserProfilGeneratedActions.deleteOneCommunityUserProfilSuccess,
    (state: CommunityUserProfilState.IState, { idCommunityUserProfil }) =>
      CommunityUserProfilState.adapter.removeOne(idCommunityUserProfil, setLoadingsState(state, false))
  ),
  on(
    CommunityUserProfilGeneratedActions.clearActivesCommunityUserProfils,
    (state: CommunityUserProfilState.IState) => ({ ...state, actives: [] })
  ),
  on(
    CommunityUserProfilGeneratedActions.addManyActivesCommunityUserProfils,
    (state: CommunityUserProfilState.IState, { idCommunityUserProfils }) => ({
      ...state,
      actives: state.actives.concat(idCommunityUserProfils)
    })
  ),
  on(
    CommunityUserProfilGeneratedActions.deleteOneActiveCommunityUserProfil,
    (state: CommunityUserProfilState.IState, { idCommunityUserProfil }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityUserProfil)
    })
  ),

  on(CommunityUserProfilGeneratedActions.clearCommunityUserProfils, () => CommunityUserProfilState.initialState),

  on(
    CommunityUserProfilGeneratedActions.addManyElementLibrarySuccess,
    (state: CommunityUserProfilState.IState, { idCommunityUserProfil, idElementLibraries }) => {
      if (!state.entities[idCommunityUserProfil]) {
        return state;
      }
      const elementLibraries = (state.entities[idCommunityUserProfil]?.elementLibraries as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityUserProfil]: {
            ...state.entities[idCommunityUserProfil],
            elementLibraries: elementLibraries.concat(idElementLibraries.filter(id => elementLibraries.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityUserProfilGeneratedActions.deleteManyElementLibrarySuccess,
    (state: CommunityUserProfilState.IState, { idElementLibraries, idCommunityUserProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityUserProfils.reduce((entities, idCommunityUserProfil) => {
            if (!state.entities[idCommunityUserProfil]?.elementLibraries) {
              return entities;
            }
            entities[idCommunityUserProfil] = {
              ...state.entities[idCommunityUserProfil],
              elementLibraries: (state.entities[idCommunityUserProfil]?.elementLibraries as number[])?.filter(
                (idElementLibrary: number) => !idElementLibraries.some((id: number) => id === idElementLibrary)
              )
            } as CommunityUserProfilEntityState;
            return entities;
          }, {} as Dictionary<CommunityUserProfilEntityState>)
        }
      };
    }
  ),

  on(
    CommunityUserProfilGeneratedActions.addCommunitySuccess,
    (state: CommunityUserProfilState.IState, { idCommunityUserProfil, idCommunity }) => {
      if (!state.entities[idCommunityUserProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityUserProfil]: {
            ...state.entities[idCommunityUserProfil],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityUserProfilGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityUserProfilState.IState, { idCommunities, idCommunityUserProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityUserProfils.reduce((entities, idCommunityUserProfil) => {
            if (!state.entities[idCommunityUserProfil]?.community) {
              return entities;
            }
            entities[idCommunityUserProfil] = {
              ...state.entities[idCommunityUserProfil],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityUserProfil]?.community
              )
                ? undefined
                : state.entities[idCommunityUserProfil]?.community
            } as CommunityUserProfilEntityState;
            return entities;
          }, {} as Dictionary<CommunityUserProfilEntityState>)
        }
      };
    }
  ),

  on(
    CommunityUserProfilGeneratedActions.addUserSuccess,
    (state: CommunityUserProfilState.IState, { idCommunityUserProfil, idUser }) => {
      if (!state.entities[idCommunityUserProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityUserProfil]: {
            ...state.entities[idCommunityUserProfil],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    CommunityUserProfilGeneratedActions.deleteManyUserSuccess,
    (state: CommunityUserProfilState.IState, { idUsers, idCommunityUserProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityUserProfils.reduce((entities, idCommunityUserProfil) => {
            if (!state.entities[idCommunityUserProfil]?.user) {
              return entities;
            }
            entities[idCommunityUserProfil] = {
              ...state.entities[idCommunityUserProfil],
              user: idUsers.some((idUser: number) => idUser === state.entities[idCommunityUserProfil]?.user)
                ? undefined
                : state.entities[idCommunityUserProfil]?.user
            } as CommunityUserProfilEntityState;
            return entities;
          }, {} as Dictionary<CommunityUserProfilEntityState>)
        }
      };
    }
  ),

  on(
    CommunityUserProfilGeneratedActions.addOrganizationUserProfilSuccess,
    (state: CommunityUserProfilState.IState, { idCommunityUserProfil, idOrganizationUserProfil }) => {
      if (!state.entities[idCommunityUserProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityUserProfil]: {
            ...state.entities[idCommunityUserProfil],
            organizationUserProfil: idOrganizationUserProfil
          }
        }
      };
    }
  ),

  on(
    CommunityUserProfilGeneratedActions.deleteManyOrganizationUserProfilSuccess,
    (state: CommunityUserProfilState.IState, { idOrganizationUserProfils, idCommunityUserProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityUserProfils.reduce((entities, idCommunityUserProfil) => {
            if (!state.entities[idCommunityUserProfil]?.organizationUserProfil) {
              return entities;
            }
            entities[idCommunityUserProfil] = {
              ...state.entities[idCommunityUserProfil],
              organizationUserProfil: idOrganizationUserProfils.some(
                (idOrganizationUserProfil: number) =>
                  idOrganizationUserProfil === state.entities[idCommunityUserProfil]?.organizationUserProfil
              )
                ? undefined
                : state.entities[idCommunityUserProfil]?.organizationUserProfil
            } as CommunityUserProfilEntityState;
            return entities;
          }, {} as Dictionary<CommunityUserProfilEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityUserProfilState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityUserProfilState.IState {
  return { ...state, isLoaded, isLoading };
}
