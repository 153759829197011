import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserHistoryGeneratedActions } from '@wip/store/actions';
import { UserHistoryState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserHistoryEntityState } from '@api/api-interfaces';

export const userHistoryReducersGeneratedFunctions: ReducerTypes<UserHistoryState.IState, readonly ActionCreator[]>[] =
  [
    on(UserHistoryGeneratedActions.getOneUserHistory, (state: UserHistoryState.IState) =>
      setLoadingsState(state, true)
    ),
    on(UserHistoryGeneratedActions.getManyUserHistories, (state: UserHistoryState.IState) =>
      setLoadingsState(state, true)
    ),
    on(UserHistoryGeneratedActions.upsertOneUserHistory, (state: UserHistoryState.IState) =>
      setLoadingsState(state, true)
    ),

    on(
      UserHistoryGeneratedActions.upsertManyUserHistoriesSuccess,
      (state: UserHistoryState.IState, { userHistories }) =>
        UserHistoryState.adapter.upsertMany(userHistories, setLoadingsState(state, false))
    ),
    on(UserHistoryGeneratedActions.deleteOneUserHistory, (state: UserHistoryState.IState) =>
      setLoadingsState(state, true)
    ),
    on(UserHistoryGeneratedActions.deleteOneUserHistorySuccess, (state: UserHistoryState.IState, { idUserHistory }) =>
      UserHistoryState.adapter.removeOne(idUserHistory, setLoadingsState(state, false))
    ),
    on(UserHistoryGeneratedActions.clearActivesUserHistories, (state: UserHistoryState.IState) => ({
      ...state,
      actives: []
    })),
    on(
      UserHistoryGeneratedActions.addManyActivesUserHistories,
      (state: UserHistoryState.IState, { idUserHistories }) => ({
        ...state,
        actives: state.actives.concat(idUserHistories)
      })
    ),
    on(UserHistoryGeneratedActions.deleteOneActiveUserHistory, (state: UserHistoryState.IState, { idUserHistory }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idUserHistory)
    })),

    on(UserHistoryGeneratedActions.clearUserHistories, () => UserHistoryState.initialState),

    on(UserHistoryGeneratedActions.addUserSuccess, (state: UserHistoryState.IState, { idUserHistory, idUser }) => {
      if (!state.entities[idUserHistory]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserHistory]: {
            ...state.entities[idUserHistory],
            user: idUser
          }
        }
      };
    }),

    on(
      UserHistoryGeneratedActions.deleteManyUserSuccess,
      (state: UserHistoryState.IState, { idUsers, idUserHistories }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idUserHistories.reduce((entities, idUserHistory) => {
              if (!state.entities[idUserHistory]?.user) {
                return entities;
              }
              entities[idUserHistory] = {
                ...state.entities[idUserHistory],
                user: idUsers.some((idUser: number) => idUser === state.entities[idUserHistory]?.user)
                  ? undefined
                  : state.entities[idUserHistory]?.user
              } as UserHistoryEntityState;
              return entities;
            }, {} as Dictionary<UserHistoryEntityState>)
          }
        };
      }
    )
  ];

export function setLoadingsState(
  state: UserHistoryState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): UserHistoryState.IState {
  return { ...state, isLoaded, isLoading };
}
