import { ExcelStyle } from '@ag-grid-community/core';

export const excelStyles: ExcelStyle[] = [
  {
    id: 'header',
    alignment: {
      horizontal: 'Center',
      vertical: 'Center',
      wrapText: true
    },
    font: {
      bold: true
    },
    borders: {
      borderBottom: {
        color: '#000000',
        lineStyle: 'Continuous',
        weight: 1
      }
    }
  },
  {
    id: 'all-cells',
    alignment: {
      horizontal: 'Center'
    }
  },
  {
    id: 'other-dates',
    dataType: 'DateTime',
    alignment: {
      horizontal: 'Center'
    },
    numberFormat: { format: 'dd/MM/yyyy' }
  },
  {
    id: 'finished-milestone',
    font: {
      color: '#ffffff'
    },
    interior: {
      color: '#13998A',
      pattern: 'Solid'
    },
    dataType: 'DateTime',
    numberFormat: { format: 'dd/MM/yyyy' }
  },
  {
    id: 'late-milestone',
    font: {
      color: '#ffffff'
    },
    interior: {
      color: '#EE755F',
      pattern: 'Solid'
    },
    dataType: 'DateTime',
    numberFormat: { format: 'dd/MM/yyyy' }
  },
  {
    id: 'is-computed',
    font: {
      color: '#a7a7a7',
      italic: true
    },
    dataType: 'DateTime',
    numberFormat: { format: 'dd/MM/yyyy' }
  },
  {
    id: 'is-entered',
    font: {
      bold: true
    },
    dataType: 'DateTime',
    numberFormat: { format: 'dd/MM/yyyy' }
  }
];

export enum RetourTypeEnum {
  'Entier' = 'valeurInteger',
  'Décimal' = 'valeurDecimal',
  'Binaire' = 'valeurBooleen',
  'Date' = 'valeurDate',
  'Texte court' = 'valeurString',
  'Texte long' = 'valeurLongText',
  'Liste' = 'idOrganizationDataColumnValue',
  'Lien hypertexte' = 'valeurString',
  'Téléphone' = 'valeurString',
  'Email' = 'valeurString'
}
