import { Injectable } from '@angular/core';
import { TypeEnum } from '@enums';
import { CommunityCaracteristic } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedCommunityCaracteristicApiService } from './community-caracteristic-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityCaracteristicApiService extends GeneratedCommunityCaracteristicApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public changeValueType(params: {
    idOrganizationCaracteristic: number;
    valueType: TypeEnum;
  }): Observable<CommunityCaracteristic[]> {
    return this.repo.update('community-caracteristic/changeValueType', params);
  }

  public fillHandleCaracteristics(
    communityCaracteristics: Partial<CommunityCaracteristic>[]
  ): Observable<CommunityCaracteristic[]> {
    return this.repo.update('community-caracteristic/fillHandleCaracteristics', communityCaracteristics);
  }
}
