import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Section, SectionEntityState } from '@api/api-interfaces';
import { SectionRelationsIds } from '@wip/store/ids-interfaces';

export const SectionGeneratedActions = createActionGroup({
  source: 'Section Generated',
  events: {
    'Get One Section': props<{ idSection: number; params?: any }>(),
    'Get Many Sections': props<{ params: any }>(),
    'Add Many Actives Sections': props<{ idSections: number[] }>(),
    'Delete One Active Section': props<{ idSection: number }>(),
    'Clear Actives Sections': emptyProps(),
    'Upsert One Section': props<{ section: Partial<Section>; ids?: SectionRelationsIds }>(),
    'Upsert Many Sections': props<{ sections: Partial<Section>[]; ids?: SectionRelationsIds }>(),
    'Upsert Many Sections Success': props<{ sections: SectionEntityState[] }>(),
    'Delete One Section': props<{ idSection: number }>(),
    'Delete One Section Success': props<{ idSection: number }>(),
    'Normalize Many Sections After Upsert': props<{ sections: SectionEntityState[] }>(),
    'Sections Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Sections': emptyProps(),
    'Add Many Organization Section Success': props<{ idSection: number; idOrganizationSections: number[] }>(),
    'Delete Many Organization Section Success': props<{ idOrganizationSections: number[]; idSections: number[] }>(),
    'Add Many Organization Success': props<{ idSection: number; idOrganizations: number[] }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idSections: number[] }>()
  }
});
