import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationDataColumn } from '@api/api-interfaces';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnActions } from '@wip/store/actions';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationDataColumnRelationsIds } from '@wip/store/ids-interfaces';
import { catchApiActions } from '@wip/store/utils';
import { Observable } from 'rxjs';
import { GeneratedOrganizationDataColumnService } from './organization-data-column-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationDataColumnService extends GeneratedOrganizationDataColumnService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public upsertDataColumn(
    organizationDataColumn: Partial<OrganizationDataColumn>,
    ids: OrganizationDataColumnRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationDataColumn> {
    this.store$.dispatch(
      OrganizationDataColumnActions.upsertDataColumn({
        organizationDataColumn,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationDataColumnGeneratedActions.normalizeManyOrganizationDataColumnsAfterUpsert,
        OrganizationDataColumnGeneratedActions.organizationDataColumnsFailure,
        true
      );
    }
  }
}
