import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ValeurGeneratedActions } from '@wip/store/actions';
import { ValeurState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { ValeurEntityState } from '@api/api-interfaces';

export const valeurReducersGeneratedFunctions: ReducerTypes<ValeurState.IState, readonly ActionCreator[]>[] = [
  on(ValeurGeneratedActions.getOneValeur, (state: ValeurState.IState) => setLoadingsState(state, true)),
  on(ValeurGeneratedActions.getManyValeurs, (state: ValeurState.IState) => setLoadingsState(state, true)),
  on(ValeurGeneratedActions.upsertOneValeur, (state: ValeurState.IState) => setLoadingsState(state, true)),

  on(ValeurGeneratedActions.upsertManyValeursSuccess, (state: ValeurState.IState, { valeurs }) =>
    ValeurState.adapter.upsertMany(valeurs, setLoadingsState(state, false))
  ),
  on(ValeurGeneratedActions.deleteOneValeur, (state: ValeurState.IState) => setLoadingsState(state, true)),
  on(ValeurGeneratedActions.deleteOneValeurSuccess, (state: ValeurState.IState, { idValeur }) =>
    ValeurState.adapter.removeOne(idValeur, setLoadingsState(state, false))
  ),
  on(ValeurGeneratedActions.clearActivesValeurs, (state: ValeurState.IState) => ({ ...state, actives: [] })),
  on(ValeurGeneratedActions.addManyActivesValeurs, (state: ValeurState.IState, { idValeurs }) => ({
    ...state,
    actives: state.actives.concat(idValeurs)
  })),
  on(ValeurGeneratedActions.deleteOneActiveValeur, (state: ValeurState.IState, { idValeur }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idValeur)
  })),

  on(ValeurGeneratedActions.clearValeurs, () => ValeurState.initialState),

  on(
    ValeurGeneratedActions.addCommunityDataRowSuccess,
    (state: ValeurState.IState, { idValeur, idCommunityDataRow }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            communityDataRow: idCommunityDataRow
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyCommunityDataRowSuccess,
    (state: ValeurState.IState, { idCommunityDataRows, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.communityDataRow) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              communityDataRow: idCommunityDataRows.some(
                (idCommunityDataRow: number) => idCommunityDataRow === state.entities[idValeur]?.communityDataRow
              )
                ? undefined
                : state.entities[idValeur]?.communityDataRow
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addOrganizationDataColumnSuccess,
    (state: ValeurState.IState, { idValeur, idOrganizationDataColumn }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            organizationDataColumn: idOrganizationDataColumn
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyOrganizationDataColumnSuccess,
    (state: ValeurState.IState, { idOrganizationDataColumns, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.organizationDataColumn) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              organizationDataColumn: idOrganizationDataColumns.some(
                (idOrganizationDataColumn: number) =>
                  idOrganizationDataColumn === state.entities[idValeur]?.organizationDataColumn
              )
                ? undefined
                : state.entities[idValeur]?.organizationDataColumn
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.addOrganizationDataColumnValueSuccess,
    (state: ValeurState.IState, { idValeur, idOrganizationDataColumnValue }) => {
      if (!state.entities[idValeur]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeur]: {
            ...state.entities[idValeur],
            organizationDataColumnValue: idOrganizationDataColumnValue
          }
        }
      };
    }
  ),

  on(
    ValeurGeneratedActions.deleteManyOrganizationDataColumnValueSuccess,
    (state: ValeurState.IState, { idOrganizationDataColumnValues, idValeurs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurs.reduce((entities, idValeur) => {
            if (!state.entities[idValeur]?.organizationDataColumnValue) {
              return entities;
            }
            entities[idValeur] = {
              ...state.entities[idValeur],
              organizationDataColumnValue: idOrganizationDataColumnValues.some(
                (idOrganizationDataColumnValue: number) =>
                  idOrganizationDataColumnValue === state.entities[idValeur]?.organizationDataColumnValue
              )
                ? undefined
                : state.entities[idValeur]?.organizationDataColumnValue
            } as ValeurEntityState;
            return entities;
          }, {} as Dictionary<ValeurEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ValeurState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ValeurState.IState {
  return { ...state, isLoaded, isLoading };
}
