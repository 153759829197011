import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { GanttLink } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { GanttLinkRelationsIds } from '@wip/store/ids-interfaces';
import { GanttLinkGeneratedActions } from '@wip/store/actions';
import { GanttLinkSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedGanttLinkService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(GanttLinkSelectors.selectIsLoadedGanttLink));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(GanttLinkSelectors.selectIsLoadingGanttLink));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [GanttLinkSelectors.selectIsReadyAndLoadedGanttLink as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllGanttLinks(schema: SelectSchema = {}): Observable<GanttLink[]> {
    return this.store$.pipe(select(GanttLinkSelectors.selectAllGanttLinks(schema))).pipe(
      switchMap(({ ganttLinks }: { ganttLinks: GanttLink[] }) => {
        return this.getReady(schema).pipe(mapTo(ganttLinks));
      })
    );
  }

  public selectOneGanttLink(idGanttLink: number, schema: SelectSchema = {}): Observable<GanttLink> {
    return this.store$.pipe(select(GanttLinkSelectors.selectOneGanttLink(schema, idGanttLink))).pipe(
      switchMap(({ ganttLink }: { ganttLink: GanttLink }) => {
        return this.getReady(schema).pipe(mapTo(ganttLink));
      })
    );
  }

  public selectAllActiveGanttLinks(schema: SelectSchema = {}): Observable<GanttLink[]> {
    return this.store$.pipe(select(GanttLinkSelectors.selectActiveGanttLinks(schema))).pipe(
      switchMap(({ ganttLinks }: { ganttLinks: GanttLink[] }) => {
        return this.getReady(schema).pipe(mapTo(ganttLinks));
      })
    );
  }

  public selectIdGanttLinksActive(): Observable<number[]> {
    return this.store$.pipe(select(GanttLinkSelectors.selectIdGanttLinksActive)).pipe(
      switchMap((idGanttLinks: number[]) => {
        return this.getReady().pipe(mapTo(idGanttLinks));
      })
    );
  }

  public getOneGanttLink(idGanttLink: number, params: any = {}, getResult?: boolean): void | Observable<GanttLink> {
    this.store$.dispatch(GanttLinkGeneratedActions.getOneGanttLink({ idGanttLink, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        GanttLinkGeneratedActions.normalizeManyGanttLinksAfterUpsert,
        GanttLinkGeneratedActions.ganttLinksFailure,
        true
      );
    }
  }

  public getManyGanttLinks(params: any = {}, getResult?: boolean): void | Observable<GanttLink[]> {
    this.store$.dispatch(GanttLinkGeneratedActions.getManyGanttLinks({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        GanttLinkGeneratedActions.normalizeManyGanttLinksAfterUpsert,
        GanttLinkGeneratedActions.ganttLinksFailure
      );
    }
  }

  public upsertOneGanttLink(
    ganttLink: Partial<GanttLink>,
    ids: GanttLinkRelationsIds = {},
    getResult?: boolean
  ): void | Observable<GanttLink> {
    this.store$.dispatch(GanttLinkGeneratedActions.upsertOneGanttLink({ ganttLink, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        GanttLinkGeneratedActions.normalizeManyGanttLinksAfterUpsert,
        GanttLinkGeneratedActions.ganttLinksFailure,
        true
      );
    }
  }

  public deleteOneGanttLink(idGanttLink: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(GanttLinkGeneratedActions.deleteOneGanttLink({ idGanttLink }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        GanttLinkGeneratedActions.deleteOneGanttLinkSuccess,
        GanttLinkGeneratedActions.ganttLinksFailure
      );
    }
  }

  public setActiveGanttLinks(idGanttLinks: number[]): void {
    this.store$.dispatch(GanttLinkGeneratedActions.clearActivesGanttLinks());
    this.store$.dispatch(GanttLinkGeneratedActions.addManyActivesGanttLinks({ idGanttLinks }));
  }
}
