import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserDeviceGeneratedActions } from '@wip/store/actions';
import { UserDeviceState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserDeviceEntityState } from '@api/api-interfaces';

export const userDeviceReducersGeneratedFunctions: ReducerTypes<UserDeviceState.IState, readonly ActionCreator[]>[] = [
  on(UserDeviceGeneratedActions.getOneUserDevice, (state: UserDeviceState.IState) => setLoadingsState(state, true)),
  on(UserDeviceGeneratedActions.getManyUserDevices, (state: UserDeviceState.IState) => setLoadingsState(state, true)),
  on(UserDeviceGeneratedActions.upsertOneUserDevice, (state: UserDeviceState.IState) => setLoadingsState(state, true)),

  on(UserDeviceGeneratedActions.upsertManyUserDevicesSuccess, (state: UserDeviceState.IState, { userDevices }) =>
    UserDeviceState.adapter.upsertMany(userDevices, setLoadingsState(state, false))
  ),
  on(UserDeviceGeneratedActions.deleteOneUserDevice, (state: UserDeviceState.IState) => setLoadingsState(state, true)),
  on(UserDeviceGeneratedActions.deleteOneUserDeviceSuccess, (state: UserDeviceState.IState, { idUserDevice }) =>
    UserDeviceState.adapter.removeOne(idUserDevice, setLoadingsState(state, false))
  ),
  on(UserDeviceGeneratedActions.clearActivesUserDevices, (state: UserDeviceState.IState) => ({
    ...state,
    actives: []
  })),
  on(UserDeviceGeneratedActions.addManyActivesUserDevices, (state: UserDeviceState.IState, { idUserDevices }) => ({
    ...state,
    actives: state.actives.concat(idUserDevices)
  })),
  on(UserDeviceGeneratedActions.deleteOneActiveUserDevice, (state: UserDeviceState.IState, { idUserDevice }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idUserDevice)
  })),

  on(UserDeviceGeneratedActions.clearUserDevices, () => UserDeviceState.initialState),

  on(UserDeviceGeneratedActions.addUserSuccess, (state: UserDeviceState.IState, { idUserDevice, idUser }) => {
    if (!state.entities[idUserDevice]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUserDevice]: {
          ...state.entities[idUserDevice],
          user: idUser
        }
      }
    };
  }),

  on(UserDeviceGeneratedActions.deleteManyUserSuccess, (state: UserDeviceState.IState, { idUsers, idUserDevices }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUserDevices.reduce((entities, idUserDevice) => {
          if (!state.entities[idUserDevice]?.user) {
            return entities;
          }
          entities[idUserDevice] = {
            ...state.entities[idUserDevice],
            user: idUsers.some((idUser: number) => idUser === state.entities[idUserDevice]?.user)
              ? undefined
              : state.entities[idUserDevice]?.user
          } as UserDeviceEntityState;
          return entities;
        }, {} as Dictionary<UserDeviceEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: UserDeviceState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): UserDeviceState.IState {
  return { ...state, isLoaded, isLoading };
}
