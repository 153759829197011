import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationFamilyApiService } from './organization-family-api-generated.service';
import { OrganizationFamily, ReorderInterface } from '@api/api-interfaces';
import { Observable } from 'rxjs';
import { IChartData } from '@api/projects/get-analyse-projects';

@Injectable({
  providedIn: 'root'
})
export class OrganizationFamilyApiService extends GeneratedOrganizationFamilyApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public reorder(params: ReorderInterface): Observable<OrganizationFamily> {
    return this.repo.update('organization-family/reorder', params);
  }

  public getChartDatas(params: IChartData) {
    return this.repo.create('organization-family/chartData', params);
  }
}
