import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { TodoElementGeneratedActions } from '@wip/store/actions';
import { TodoElementState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { TodoElementEntityState } from '@api/api-interfaces';

export const todoElementReducersGeneratedFunctions: ReducerTypes<TodoElementState.IState, readonly ActionCreator[]>[] =
  [
    on(TodoElementGeneratedActions.getOneTodoElement, (state: TodoElementState.IState) =>
      setLoadingsState(state, true)
    ),
    on(TodoElementGeneratedActions.getManyTodoElements, (state: TodoElementState.IState) =>
      setLoadingsState(state, true)
    ),
    on(TodoElementGeneratedActions.upsertOneTodoElement, (state: TodoElementState.IState) =>
      setLoadingsState(state, true)
    ),

    on(TodoElementGeneratedActions.upsertManyTodoElementsSuccess, (state: TodoElementState.IState, { todoElements }) =>
      TodoElementState.adapter.upsertMany(todoElements, setLoadingsState(state, false))
    ),
    on(TodoElementGeneratedActions.deleteOneTodoElement, (state: TodoElementState.IState) =>
      setLoadingsState(state, true)
    ),
    on(TodoElementGeneratedActions.deleteOneTodoElementSuccess, (state: TodoElementState.IState, { idTodoElement }) =>
      TodoElementState.adapter.removeOne(idTodoElement, setLoadingsState(state, false))
    ),
    on(TodoElementGeneratedActions.clearActivesTodoElements, (state: TodoElementState.IState) => ({
      ...state,
      actives: []
    })),
    on(
      TodoElementGeneratedActions.addManyActivesTodoElements,
      (state: TodoElementState.IState, { idTodoElements }) => ({
        ...state,
        actives: state.actives.concat(idTodoElements)
      })
    ),
    on(TodoElementGeneratedActions.deleteOneActiveTodoElement, (state: TodoElementState.IState, { idTodoElement }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idTodoElement)
    })),

    on(TodoElementGeneratedActions.clearTodoElements, () => TodoElementState.initialState),

    on(TodoElementGeneratedActions.addTodoSuccess, (state: TodoElementState.IState, { idTodoElement, idTodo }) => {
      if (!state.entities[idTodoElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idTodoElement]: {
            ...state.entities[idTodoElement],
            todo: idTodo
          }
        }
      };
    }),

    on(
      TodoElementGeneratedActions.deleteManyTodoSuccess,
      (state: TodoElementState.IState, { idTodos, idTodoElements }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idTodoElements.reduce((entities, idTodoElement) => {
              if (!state.entities[idTodoElement]?.todo) {
                return entities;
              }
              entities[idTodoElement] = {
                ...state.entities[idTodoElement],
                todo: idTodos.some((idTodo: number) => idTodo === state.entities[idTodoElement]?.todo)
                  ? undefined
                  : state.entities[idTodoElement]?.todo
              } as TodoElementEntityState;
              return entities;
            }, {} as Dictionary<TodoElementEntityState>)
          }
        };
      }
    ),

    on(
      TodoElementGeneratedActions.addElementSuccess,
      (state: TodoElementState.IState, { idTodoElement, idElement }) => {
        if (!state.entities[idTodoElement]) {
          return state;
        }
        return {
          ...state,
          entities: {
            ...state.entities,
            [idTodoElement]: {
              ...state.entities[idTodoElement],
              element: idElement
            }
          }
        };
      }
    ),

    on(
      TodoElementGeneratedActions.deleteManyElementSuccess,
      (state: TodoElementState.IState, { idElements, idTodoElements }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idTodoElements.reduce((entities, idTodoElement) => {
              if (!state.entities[idTodoElement]?.element) {
                return entities;
              }
              entities[idTodoElement] = {
                ...state.entities[idTodoElement],
                element: idElements.some((idElement: number) => idElement === state.entities[idTodoElement]?.element)
                  ? undefined
                  : state.entities[idTodoElement]?.element
              } as TodoElementEntityState;
              return entities;
            }, {} as Dictionary<TodoElementEntityState>)
          }
        };
      }
    )
  ];

export function setLoadingsState(
  state: TodoElementState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): TodoElementState.IState {
  return { ...state, isLoaded, isLoading };
}
