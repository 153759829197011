import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationRiskApiService } from './organization-risk-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationRiskApiService extends GeneratedOrganizationRiskApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
