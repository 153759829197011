import { RepositoryService } from '@wip/services/repository';
import { OrganizationFamily } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationFamilyApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationFamilys(params?: any): Observable<OrganizationFamily[]> {
    return this.repo.getData<OrganizationFamily[]>('organization-family', params);
  }

  public getOrganizationFamily(params: { idOrganizationFamily: number; params?: any }): Observable<OrganizationFamily> {
    return this.repo.getData<OrganizationFamily>('organization-family/' + params.idOrganizationFamily, params.params);
  }

  public addOrganizationFamily(organizationFamily: Partial<OrganizationFamily>): Observable<OrganizationFamily> {
    return this.repo.create<OrganizationFamily>('organization-family', organizationFamily);
  }

  public updateOrganizationFamily(organizationFamily: Partial<OrganizationFamily>): Observable<OrganizationFamily> {
    return this.repo.update('organization-family', organizationFamily);
  }

  public deleteOrganizationFamily(idOrganizationFamily: number): Observable<number> {
    return this.repo.delete('organization-family/' + +idOrganizationFamily);
  }
}
