import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityKpi, CommunityKpiEntityState } from '@api/api-interfaces';
import { OrganizationKpi, OrganizationKpiEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityKpiState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityKpiRelations: string[] = ['organizationKpis', 'communities'];

export const { selectEntities, selectAll } = CommunityKpiState.adapter.getSelectors();

export const selectCommunityKpiState = createFeatureSelector<CommunityKpiState.IState>(
  CommunityKpiState.communityKpiFeatureKey
);

export const selectIsLoadedCommunityKpi = createSelector(
  selectCommunityKpiState,
  (state: CommunityKpiState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityKpi = createSelector(
  selectCommunityKpiState,
  (state: CommunityKpiState.IState) => state.isLoading
);

export const selectIsReadyCommunityKpi = createSelector(
  selectCommunityKpiState,
  (state: CommunityKpiState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityKpi = createSelector(
  selectCommunityKpiState,
  (state: CommunityKpiState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityKpisEntities = createSelector(selectCommunityKpiState, selectEntities);

export const selectCommunityKpisArray = createSelector(selectCommunityKpiState, selectAll);

export const selectIdCommunityKpisActive = createSelector(
  selectCommunityKpiState,
  (state: CommunityKpiState.IState) => state.actives
);

const communityKpisInObject = (communityKpis: Dictionary<CommunityKpiEntityState>) => ({ communityKpis });

const selectCommunityKpisEntitiesDictionary = createSelector(selectCommunityKpisEntities, communityKpisInObject);

const selectAllCommunityKpisObject = createSelector(selectCommunityKpisEntities, communityKpis => {
  return hydrateAll({ communityKpis });
});

const selectOneCommunityKpiDictionary = (idCommunityKpi: number) =>
  createSelector(selectCommunityKpisEntities, communityKpis => ({
    communityKpis: { [idCommunityKpi]: communityKpis[idCommunityKpi] }
  }));

const selectOneCommunityKpiDictionaryWithoutChild = (idCommunityKpi: number) =>
  createSelector(selectCommunityKpisEntities, communityKpis => ({
    communityKpi: communityKpis[idCommunityKpi]
  }));

const selectActiveCommunityKpisEntities = createSelector(
  selectIdCommunityKpisActive,
  selectCommunityKpisEntities,
  (actives: number[], communityKpis: Dictionary<CommunityKpiEntityState>) =>
    getCommunityKpisFromActives(actives, communityKpis)
);

function getCommunityKpisFromActives(
  actives: number[],
  communityKpis: Dictionary<CommunityKpiEntityState>
): Dictionary<CommunityKpiEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityKpis[idActive]) {
      acc[idActive] = communityKpis[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityKpiEntityState>);
}

const selectAllCommunityKpisSelectors: Dictionary<Selector> = {};
export function selectAllCommunityKpis(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityKpi>(
      schema,
      selectAllCommunityKpisSelectors,
      selectCommunityKpisEntitiesDictionary,
      getRelationSelectors,
      communityKpiRelations,
      hydrateAll,
      'communityKpi'
    );
  } else {
    return selectAllCommunityKpisObject;
  }
}

export function selectAllCommunityKpisDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityKpis'
): Selector {
  return createSelector(selectAllCommunityKpis(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityKpiEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityKpis.length; i++) {
      res[customKey][result.communityKpis[i].idCommunityKpi] = result.communityKpis[i];
    }
    return res;
  });
}

export function selectOneCommunityKpi(schema: SelectSchema = {}, idCommunityKpi: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityKpiDictionary(idCommunityKpi)];
    selectors.push(...getRelationSelectors(schema, communityKpiRelations, 'communityKpi'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityKpiDictionaryWithoutChild(idCommunityKpi);
  }
}

export function selectActiveCommunityKpis(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityKpisEntities, communityKpis => ({
      communityKpis
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityKpiRelations, 'communityKpi'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityKpis: Dictionary<CommunityKpiEntityState>;
  organizationKpis?: Dictionary<OrganizationKpiEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityKpis: (CommunityKpi | null)[] } {
  const { communityKpis, organizationKpis, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communityKpis: Object.keys(communityKpis).map(idCommunityKpi =>
      hydrate(communityKpis[idCommunityKpi] as CommunityKpiEntityState, organizationKpis, communities)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityKpi: CommunityKpiEntityState | null } {
  const { communityKpis, organizationKpis, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communityKpi = Object.values(communityKpis)[0];
  return {
    communityKpi: hydrate(communityKpi as CommunityKpiEntityState, organizationKpis, communities)
  };
}

function hydrate(
  communityKpi: CommunityKpiEntityState,
  organizationKpiEntities?: Dictionary<OrganizationKpiEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>
): CommunityKpi | null {
  if (!communityKpi) {
    return null;
  }

  const communityKpiHydrated: CommunityKpiEntityState = { ...communityKpi };
  if (organizationKpiEntities) {
    communityKpiHydrated.organizationKpi = organizationKpiEntities[
      communityKpi.organizationKpi as number
    ] as OrganizationKpi;
  } else {
    delete communityKpiHydrated.organizationKpi;
  }
  if (communityEntities) {
    communityKpiHydrated.community = communityEntities[communityKpi.community as number] as Community;
  } else {
    delete communityKpiHydrated.community;
  }

  return communityKpiHydrated as CommunityKpi;
}
