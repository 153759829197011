import { createEntityAdapter } from '@ngrx/entity';
import { CommunityDataRowEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<CommunityDataRowEntityState>;

export const adapter = createEntityAdapter<CommunityDataRowEntityState>({
  selectId: o => o.idCommunityDataRow
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const communityDataRowFeatureKey = 'communityDataRow';
