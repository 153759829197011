import { createEntityAdapter } from '@ngrx/entity';
import { GanttLinkEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<GanttLinkEntityState>;

export const adapter = createEntityAdapter<GanttLinkEntityState>({
  selectId: o => o.idGanttLink
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const ganttLinkFeatureKey = 'ganttLink';
