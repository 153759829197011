import { createAction, props } from '@ngrx/store';

export * from './user-right-generated.actions';

export const applyManyUserRights = createAction(
  '[UserRight] Apply Many User Rights',
  props<{
    params: { idProfil: number; idOrganizationSubFamily: number; ids: number[] };
  }>()
);

export const deleteManyUserRights = createAction(
  '[UserRight] Delete Many User Rights',
  props<{
    params: { ids: number[] };
  }>()
);
