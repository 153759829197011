import { createAction, props } from '@ngrx/store';
import { Element } from '@api/api-interfaces';

export * from './timeline-element-generated.actions';

export const createTimelineElement = createAction(
  '[TimelineElement] Create Timeline Element',
  props<{
    params: { idCommunity: number; element: Partial<Element> };
  }>()
);
