import { UserHistoryState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userHistoryReducersGeneratedFunctions } from './user-history-generated.reducer';

const userHistoryReducersFunctions = [...userHistoryReducersGeneratedFunctions];

const userHistoryReducer = createReducer(UserHistoryState.initialState, ...userHistoryReducersFunctions);

export function reducer(state: UserHistoryState.IState | undefined, action: Action) {
  return userHistoryReducer(state, action);
}
