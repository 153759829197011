<div mat-dialog-title class="dialog-header gap-8">
  <h1 mat-dialog-title>Synthèse du {{ dialogData.wordingTypeStructure | lowercase }}</h1>
  <button mat-icon-button class="gulp gulp-34-close" (click)="cancel()"></button>
</div>

<wip-project-dashboard
  mat-dialog-content
  style="max-width: 1500px"
  [idCurrentCommunity]="idCommunity"
  [isFromDashboard]="true"
/>
<mat-dialog-actions>
  @if (!dialogData.isProjectView) {
    <button class="button" (click)="redirectToCommunityProjectPage()" mat-raised-button>
      Accès au {{ dialogData.wordingTypeStructure | lowercase }}
    </button>
  }
</mat-dialog-actions>
