import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedMeetingUserService } from './meeting-user-generated.service';
import { Actions } from '@ngrx/effects';
import { Observable, map } from 'rxjs';
import { MeetingUser } from '@api/api-interfaces';
import { UserService } from '@wip/store/services';

@Injectable({
  providedIn: 'root'
})
export class MeetingUserService extends GeneratedMeetingUserService {
  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private newUserService: UserService
  ) {
    super(store$, actions$);
  }

  public selectCurrentMeetingUser(): Observable<MeetingUser> {
    return this.selectAllActiveMeetingUsers().pipe(
      map(mu => mu.find(m => m.idUser === this.newUserService.currentIdUser))
    );
  }
}
