import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationCaracteristicRow, OrganizationCaracteristicRowEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicRowApiService } from '@wip/store/api-services';
import { OrganizationCaracteristicRowGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationCaracteristicRow } from '@wip/store/configs/normalization';
import { OrganizationCaracteristicRowSelectors } from '@wip/store/selectors';
import { OrganizationCaracteristicRowRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicValueGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicValue } from '@api/api-interfaces';

export function getDefaultAddOrganizationCaracteristicRowActions(
  organizationCaracteristicRow: OrganizationCaracteristicRowEntityState,
  ids?: OrganizationCaracteristicRowRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationCaracteristicRowGeneratedActions.normalizeManyOrganizationCaracteristicRowsAfterUpsert({
      organizationCaracteristicRows: [organizationCaracteristicRow]
    })
  ];

  if (ids?.organizationCaracteristic) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.addManyOrganizationCaracteristicRowSuccess({
        idOrganizationCaracteristic: ids.organizationCaracteristic,
        idOrganizationCaracteristicRows: [organizationCaracteristicRow.idOrganizationCaracteristicRow]
      })
    );
    actions.push(
      OrganizationCaracteristicRowGeneratedActions.addOrganizationCaracteristicSuccess({
        idOrganizationCaracteristicRow: organizationCaracteristicRow.idOrganizationCaracteristicRow,
        idOrganizationCaracteristic: ids.organizationCaracteristic
      })
    );
  }

  if (ids?.organizationDataColumn) {
    actions.push(
      OrganizationDataColumnGeneratedActions.addManyOrganizationCaracteristicRowSuccess({
        idOrganizationDataColumn: ids.organizationDataColumn,
        idOrganizationCaracteristicRows: [organizationCaracteristicRow.idOrganizationCaracteristicRow]
      })
    );
    actions.push(
      OrganizationCaracteristicRowGeneratedActions.addOrganizationDataColumnSuccess({
        idOrganizationCaracteristicRow: organizationCaracteristicRow.idOrganizationCaracteristicRow,
        idOrganizationDataColumn: ids.organizationDataColumn
      })
    );
  }

  if (ids?.organizationCaracteristicValues) {
    if (!Array.isArray(ids.organizationCaracteristicValues)) {
      actions.push(
        OrganizationCaracteristicValueGeneratedActions.upsertOneOrganizationCaracteristicValue({
          organizationCaracteristicValue: {
            idOrganizationCaracteristicRow: organizationCaracteristicRow.idOrganizationCaracteristicRow,
            idOrganizationCaracteristicValue: ids.organizationCaracteristicValues as number
          } as OrganizationCaracteristicValue
        })
      );
      actions.push(
        OrganizationCaracteristicRowGeneratedActions.addManyOrganizationCaracteristicValueSuccess({
          idOrganizationCaracteristicRow: organizationCaracteristicRow.idOrganizationCaracteristicRow,
          idOrganizationCaracteristicValues: [ids.organizationCaracteristicValues as number]
        })
      );
    } else {
      actions.push(
        OrganizationCaracteristicValueGeneratedActions.upsertManyOrganizationCaracteristicValues({
          organizationCaracteristicValues: (ids.organizationCaracteristicValues as number[]).map(
            (idOrganizationCaracteristicValue: number) => ({
              idOrganizationCaracteristicRow: organizationCaracteristicRow.idOrganizationCaracteristicRow,
              idOrganizationCaracteristicValue
            })
          ) as OrganizationCaracteristicValue[]
        })
      );
      actions.push(
        OrganizationCaracteristicRowGeneratedActions.addManyOrganizationCaracteristicValueSuccess({
          idOrganizationCaracteristicRow: organizationCaracteristicRow.idOrganizationCaracteristicRow,
          idOrganizationCaracteristicValues: ids.organizationCaracteristicValues as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationCaracteristicRowActions(
  organizationCaracteristicRow: OrganizationCaracteristicRowEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationCaracteristicRowGeneratedActions.deleteOneOrganizationCaracteristicRowSuccess({
      idOrganizationCaracteristicRow: organizationCaracteristicRow.idOrganizationCaracteristicRow
    })
  ];

  if (organizationCaracteristicRow.organizationCaracteristic) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.deleteManyOrganizationCaracteristicRowSuccess({
        idOrganizationCaracteristicRows: [organizationCaracteristicRow.idOrganizationCaracteristicRow],
        idOrganizationCaracteristics: [organizationCaracteristicRow.organizationCaracteristic as number]
      })
    );
  }

  if (organizationCaracteristicRow.organizationDataColumn) {
    actions.push(
      OrganizationDataColumnGeneratedActions.deleteManyOrganizationCaracteristicRowSuccess({
        idOrganizationCaracteristicRows: [organizationCaracteristicRow.idOrganizationCaracteristicRow],
        idOrganizationDataColumns: [organizationCaracteristicRow.organizationDataColumn as number]
      })
    );
  }

  if (organizationCaracteristicRow.organizationCaracteristicValues) {
    actions.push(
      OrganizationCaracteristicValueGeneratedActions.deleteManyOrganizationCaracteristicRowSuccess({
        idOrganizationCaracteristicRows: [organizationCaracteristicRow.idOrganizationCaracteristicRow],
        idOrganizationCaracteristicValues: organizationCaracteristicRow.organizationCaracteristicValues as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationCaracteristicRowEffects {
  constructor(
    protected actions$: Actions,
    protected organizationCaracteristicRowApiService: OrganizationCaracteristicRowApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationCaracteristicRows$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicRowGeneratedActions.getManyOrganizationCaracteristicRows),
      switchMap(({ params }) =>
        this.organizationCaracteristicRowApiService.getOrganizationCaracteristicRows(params).pipe(
          map((organizationCaracteristicRows: OrganizationCaracteristicRow[]) => {
            return OrganizationCaracteristicRowGeneratedActions.normalizeManyOrganizationCaracteristicRowsAfterUpsert({
              organizationCaracteristicRows
            });
          }),
          catchError(error =>
            of(OrganizationCaracteristicRowGeneratedActions.organizationCaracteristicRowsFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationCaracteristicRow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicRowGeneratedActions.getOneOrganizationCaracteristicRow),
      switchMap(idOrganizationCaracteristicRow =>
        this.organizationCaracteristicRowApiService
          .getOrganizationCaracteristicRow(idOrganizationCaracteristicRow)
          .pipe(
            map((organizationCaracteristicRow: OrganizationCaracteristicRow) => {
              return OrganizationCaracteristicRowGeneratedActions.normalizeManyOrganizationCaracteristicRowsAfterUpsert(
                { organizationCaracteristicRows: [organizationCaracteristicRow] }
              );
            }),
            catchError(error =>
              of(OrganizationCaracteristicRowGeneratedActions.organizationCaracteristicRowsFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationCaracteristicRow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicRowGeneratedActions.upsertOneOrganizationCaracteristicRow),
      concatMap(
        ({
          organizationCaracteristicRow,
          ids
        }: {
          organizationCaracteristicRow: Partial<OrganizationCaracteristicRow>;
          ids?: OrganizationCaracteristicRowRelationsIds;
        }) => {
          if (organizationCaracteristicRow.idOrganizationCaracteristicRow) {
            return this.organizationCaracteristicRowApiService
              .updateOrganizationCaracteristicRow(organizationCaracteristicRow)
              .pipe(
                map((organizationCaracteristicRowReturned: OrganizationCaracteristicRow) => {
                  return OrganizationCaracteristicRowGeneratedActions.normalizeManyOrganizationCaracteristicRowsAfterUpsert(
                    { organizationCaracteristicRows: [organizationCaracteristicRowReturned] }
                  );
                }),
                catchError(error =>
                  of(OrganizationCaracteristicRowGeneratedActions.organizationCaracteristicRowsFailure({ error }))
                )
              );
          } else {
            return this.organizationCaracteristicRowApiService
              .addOrganizationCaracteristicRow(organizationCaracteristicRow)
              .pipe(
                mergeMap((organizationCaracteristicRowReturned: OrganizationCaracteristicRow) =>
                  getDefaultAddOrganizationCaracteristicRowActions(organizationCaracteristicRowReturned, ids)
                ),
                catchError(error =>
                  of(OrganizationCaracteristicRowGeneratedActions.organizationCaracteristicRowsFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationCaracteristicRow$ = createEffect(() => {
    const selectOrganizationCaracteristicRowState$ = this.store$.select(
      OrganizationCaracteristicRowSelectors.selectOrganizationCaracteristicRowState
    );
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicRowGeneratedActions.deleteOneOrganizationCaracteristicRow),
      withLatestFrom(selectOrganizationCaracteristicRowState$),
      concatMap(([{ idOrganizationCaracteristicRow }, state]) =>
        this.organizationCaracteristicRowApiService
          .deleteOrganizationCaracteristicRow(idOrganizationCaracteristicRow)
          .pipe(
            mergeMap(_success =>
              getDefaultDeleteOrganizationCaracteristicRowActions(
                state.entities[idOrganizationCaracteristicRow] as OrganizationCaracteristicRowEntityState
              )
            ),
            catchError(error =>
              of(OrganizationCaracteristicRowGeneratedActions.organizationCaracteristicRowsFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationCaracteristicRowsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicRowGeneratedActions.normalizeManyOrganizationCaracteristicRowsAfterUpsert),
      concatMap(({ organizationCaracteristicRows }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationCaracteristicRow(
          organizationCaracteristicRows,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationCaracteristicRow] Normalization After Upsert Success')];
      })
    );
  });
}
