import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedCommunityRiskApiService } from './community-risk-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityRiskApiService extends GeneratedCommunityRiskApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
