import { RepositoryService } from '@wip/services/repository';
import { CommunityKpi } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityKpiApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityKpis(params?: any): Observable<CommunityKpi[]> {
    return this.repo.getData<CommunityKpi[]>('community-kpi', params);
  }

  public getCommunityKpi(params: { idCommunityKpi: number; params?: any }): Observable<CommunityKpi> {
    return this.repo.getData<CommunityKpi>('community-kpi/' + params.idCommunityKpi, params.params);
  }

  public addCommunityKpi(communityKpi: Partial<CommunityKpi>): Observable<CommunityKpi> {
    return this.repo.create<CommunityKpi>('community-kpi', communityKpi);
  }

  public updateCommunityKpi(communityKpi: Partial<CommunityKpi>): Observable<CommunityKpi> {
    return this.repo.update('community-kpi', communityKpi);
  }

  public deleteCommunityKpi(idCommunityKpi: number): Observable<number> {
    return this.repo.delete('community-kpi/' + +idCommunityKpi);
  }
}
