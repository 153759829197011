import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CommunitySubFamily } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { CommunitySubFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { CommunitySubFamilyGeneratedActions } from '@wip/store/actions';
import { CommunitySubFamilySelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCommunitySubFamilyService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CommunitySubFamilySelectors.selectIsLoadedCommunitySubFamily));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CommunitySubFamilySelectors.selectIsLoadingCommunitySubFamily));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      CommunitySubFamilySelectors.selectIsReadyAndLoadedCommunitySubFamily as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCommunitySubFamilys(schema: SelectSchema = {}): Observable<CommunitySubFamily[]> {
    return this.store$.pipe(select(CommunitySubFamilySelectors.selectAllCommunitySubFamilys(schema))).pipe(
      switchMap(({ communitySubFamilys }: { communitySubFamilys: CommunitySubFamily[] }) => {
        return this.getReady(schema).pipe(mapTo(communitySubFamilys));
      })
    );
  }

  public selectOneCommunitySubFamily(
    idCommunitySubFamily: number,
    schema: SelectSchema = {}
  ): Observable<CommunitySubFamily> {
    return this.store$
      .pipe(select(CommunitySubFamilySelectors.selectOneCommunitySubFamily(schema, idCommunitySubFamily)))
      .pipe(
        switchMap(({ communitySubFamily }: { communitySubFamily: CommunitySubFamily }) => {
          return this.getReady(schema).pipe(mapTo(communitySubFamily));
        })
      );
  }

  public selectAllActiveCommunitySubFamilys(schema: SelectSchema = {}): Observable<CommunitySubFamily[]> {
    return this.store$.pipe(select(CommunitySubFamilySelectors.selectActiveCommunitySubFamilys(schema))).pipe(
      switchMap(({ communitySubFamilys }: { communitySubFamilys: CommunitySubFamily[] }) => {
        return this.getReady(schema).pipe(mapTo(communitySubFamilys));
      })
    );
  }

  public selectIdCommunitySubFamilysActive(): Observable<number[]> {
    return this.store$.pipe(select(CommunitySubFamilySelectors.selectIdCommunitySubFamilysActive)).pipe(
      switchMap((idCommunitySubFamilys: number[]) => {
        return this.getReady().pipe(mapTo(idCommunitySubFamilys));
      })
    );
  }

  public getOneCommunitySubFamily(
    idCommunitySubFamily: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<CommunitySubFamily> {
    this.store$.dispatch(CommunitySubFamilyGeneratedActions.getOneCommunitySubFamily({ idCommunitySubFamily, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunitySubFamilyGeneratedActions.normalizeManyCommunitySubFamilysAfterUpsert,
        CommunitySubFamilyGeneratedActions.communitySubFamilysFailure,
        true
      );
    }
  }

  public getManyCommunitySubFamilys(params: any = {}, getResult?: boolean): void | Observable<CommunitySubFamily[]> {
    this.store$.dispatch(CommunitySubFamilyGeneratedActions.getManyCommunitySubFamilys({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunitySubFamilyGeneratedActions.normalizeManyCommunitySubFamilysAfterUpsert,
        CommunitySubFamilyGeneratedActions.communitySubFamilysFailure
      );
    }
  }

  public upsertOneCommunitySubFamily(
    communitySubFamily: Partial<CommunitySubFamily>,
    ids: CommunitySubFamilyRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CommunitySubFamily> {
    this.store$.dispatch(CommunitySubFamilyGeneratedActions.upsertOneCommunitySubFamily({ communitySubFamily, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunitySubFamilyGeneratedActions.normalizeManyCommunitySubFamilysAfterUpsert,
        CommunitySubFamilyGeneratedActions.communitySubFamilysFailure,
        true
      );
    }
  }

  public deleteOneCommunitySubFamily(idCommunitySubFamily: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CommunitySubFamilyGeneratedActions.deleteOneCommunitySubFamily({ idCommunitySubFamily }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunitySubFamilyGeneratedActions.deleteOneCommunitySubFamilySuccess,
        CommunitySubFamilyGeneratedActions.communitySubFamilysFailure
      );
    }
  }

  public setActiveCommunitySubFamilys(idCommunitySubFamilys: number[]): void {
    this.store$.dispatch(CommunitySubFamilyGeneratedActions.clearActivesCommunitySubFamilys());
    this.store$.dispatch(
      CommunitySubFamilyGeneratedActions.addManyActivesCommunitySubFamilys({ idCommunitySubFamilys })
    );
  }
}
