import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserRight } from '@api/api-interfaces';
import { UserRightGeneratedActions, applyManyUserRights, deleteManyUserRights } from '@wip/store/actions';
import { UserRightApiService } from '@wip/store/api-services';
import { AppState } from '@wip/store/configs/reducers';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedUserRightEffects } from './user-right-generated.effects';

@Injectable()
export class UserRightEffects extends GeneratedUserRightEffects {
  constructor(actions$: Actions, userRightApiService: UserRightApiService, store$: Store<AppState>) {
    super(actions$, userRightApiService, store$);
  }

  applyManyUserRights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(applyManyUserRights),
      concatMap(({ params }) => {
        return this.userRightApiService.applyManyUserRights(params).pipe(
          map((userRightsReturned: UserRight[]) => {
            return UserRightGeneratedActions.normalizeManyUserRightsAfterUpsert({ userRights: userRightsReturned });
          }),
          catchError(error => of(UserRightGeneratedActions.userRightsFailure({ error })))
        );
      })
    );
  });

  deleteManyUserRights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteManyUserRights),
      concatMap(({ params }) => {
        return this.userRightApiService.deleteManyUserRights(params).pipe(
          map((userRightsReturned: UserRight[]) => {
            return UserRightGeneratedActions.normalizeManyUserRightsAfterUpsert({ userRights: userRightsReturned });
          }),
          catchError(error => of(UserRightGeneratedActions.userRightsFailure({ error })))
        );
      })
    );
  });
}
