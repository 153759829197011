import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Community } from '@api/api-interfaces';
import { TaskOptions } from '../services/editor.service';
import { NewActionFormContainerComponent } from '../new-action-form-container/new-action-form-container.component';
import { DroitInterneEnum } from '@enums';
import { gantt } from '@wip/gantt-static';

@Component({
  selector: 'wip-new-task-options',
  templateUrl: './new-task-options.component.html',
  styleUrls: ['./new-task-options.component.scss']
})
export class NewTaskOptionsComponent implements OnInit {
  @Input() project: Community;
  @Input() parent?: number;

  private task;

  public canShiftLeft = false;
  public canShiftRight = false;
  public canShiftUp = false;
  public canShiftDown = false;
  public expandSons = false;
  public collapseSons = false;
  public duplicate = false;
  public options: TaskOptions;

  constructor(
    public dialogRef: MatDialogRef<NewTaskOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.options = this.dialogData.options;
    this.task = this.dialogData.task;

    if (this.task) {
      if (this.task.type === 'milestone' || this.task.type === 'task') {
        this.duplicate = !!this.options.duplicate;
      }
    }
  }

  public onEdit() {
    this.closeOverlay();
    let dialogRef = this.dialog.open(NewActionFormContainerComponent, {
      data: {
        idProjectElement: this.task.id,
        idElement: this.task.idElement,
        constraintType: this.task.constraint_type,
        isEndDateComputed: this.task.isEndDateComputed,
        rightNeeded: DroitInterneEnum.elementUpdate,
        community: this.dialogData.community,
        isTaskLinked: this.task.$source.length || this.task.$target.length,
        isViewer: !this.task.canUpdate,
        businessDay: this.dialogData.community.businessDay,
        ganttLinksRelated: gantt
          .getLinks()
          .filter(link => link.source === +this.task.id || link.target === +this.task.id)
      },
      maxWidth: '100vw',
      width: '800px',
      panelClass: ['custom-dialog-fullheight', 'custom-dialog-fullscreen-xs'],
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        dialogRef = null;
        this.dialogRef.close(res);
        this.dialogRef = null;
      }
    });
  }

  public onLivrable(): void {
    this.dialogRef.close('addTask');
  }

  public onDuplicate(): void {
    this.dialogRef.close('duplicate');
  }

  public onStep(): void {
    this.dialogRef.close('addStep');
  }

  public onExcelExport(): void {
    this.closeOverlay();
  }

  public onPDFExport(): void {
    this.exportProject('pdf');
    this.closeOverlay();
  }

  public onPngExport(): void {
    this.exportProject('png');
    this.closeOverlay();
  }

  private exportProject(_type: 'pdf' | 'png'): void {}

  public onExpandSons(): void {
    this.closeOverlay();
  }

  public onCollapseSons(): void {
    this.closeOverlay();
  }

  public onShiftUp(): void {
    this.closeOverlay();
  }

  public onShiftLeft(): void {
    this.closeOverlay();
  }

  public onShiftRight(): void {
    this.closeOverlay();
  }

  public onShiftDown(): void {
    this.closeOverlay();
  }

  public onDeleteAll(): void {
    this.closeOverlay();
  }

  public onDelete(): void {
    this.dialogRef.close('deleteTask');
  }

  public onEditionActivate(): void {
    this.closeOverlay();
  }

  private closeOverlay(): void {
    this.dialogRef.close();
  }
}
