import { RepositoryService } from '@wip/services/repository';
import { OrganizationProjectModule } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationProjectModuleApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationProjectModules(params?: any): Observable<OrganizationProjectModule[]> {
    return this.repo.getData<OrganizationProjectModule[]>('organization-project-module', params);
  }

  public getOrganizationProjectModule(params: {
    idOrganizationProjectModule: number;
    params?: any;
  }): Observable<OrganizationProjectModule> {
    return this.repo.getData<OrganizationProjectModule>(
      'organization-project-module/' + params.idOrganizationProjectModule,
      params.params
    );
  }

  public addOrganizationProjectModule(
    organizationProjectModule: Partial<OrganizationProjectModule>
  ): Observable<OrganizationProjectModule> {
    return this.repo.create<OrganizationProjectModule>('organization-project-module', organizationProjectModule);
  }

  public updateOrganizationProjectModule(
    organizationProjectModule: Partial<OrganizationProjectModule>
  ): Observable<OrganizationProjectModule> {
    return this.repo.update('organization-project-module', organizationProjectModule);
  }

  public deleteOrganizationProjectModule(idOrganizationProjectModule: number): Observable<number> {
    return this.repo.delete('organization-project-module/' + +idOrganizationProjectModule);
  }
}
