import { RepositoryService } from '@wip/services/repository';
import { CommunityUserProfil } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityUserProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityUserProfils(params?: any): Observable<CommunityUserProfil[]> {
    return this.repo.getData<CommunityUserProfil[]>('community-user-profil', params);
  }

  public getCommunityUserProfil(params: {
    idCommunityUserProfil: number;
    params?: any;
  }): Observable<CommunityUserProfil> {
    return this.repo.getData<CommunityUserProfil>(
      'community-user-profil/' + params.idCommunityUserProfil,
      params.params
    );
  }

  public addCommunityUserProfil(communityUserProfil: Partial<CommunityUserProfil>): Observable<CommunityUserProfil> {
    return this.repo.create<CommunityUserProfil>('community-user-profil', communityUserProfil);
  }

  public updateCommunityUserProfil(communityUserProfil: Partial<CommunityUserProfil>): Observable<CommunityUserProfil> {
    return this.repo.update('community-user-profil', communityUserProfil);
  }

  public deleteCommunityUserProfil(idCommunityUserProfil: number): Observable<number> {
    return this.repo.delete('community-user-profil/' + +idCommunityUserProfil);
  }
}
