import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GanttLinkApiService } from '@wip/store/api-services';
import { GeneratedGanttLinkEffects } from './gantt-link-generated.effects';

@Injectable()
export class GanttLinkEffects extends GeneratedGanttLinkEffects {
  constructor(actions$: Actions, ganttLinkApiService: GanttLinkApiService, store$: Store<AppState>) {
    super(actions$, ganttLinkApiService, store$);
  }
}
