import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationUser, OrganizationUserEntityState } from '@api/api-interfaces';
import { UserBoardState, UserBoardStateEntityState } from '@api/api-interfaces';
import { UserRight, UserRightEntityState } from '@api/api-interfaces';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationUserState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationUserRelations: string[] = [
  'userBoardStates',
  'userRights',
  'profils',
  'organizations',
  'users'
];

export const { selectEntities, selectAll } = OrganizationUserState.adapter.getSelectors();

export const selectOrganizationUserState = createFeatureSelector<OrganizationUserState.IState>(
  OrganizationUserState.organizationUserFeatureKey
);

export const selectIsLoadedOrganizationUser = createSelector(
  selectOrganizationUserState,
  (state: OrganizationUserState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationUser = createSelector(
  selectOrganizationUserState,
  (state: OrganizationUserState.IState) => state.isLoading
);

export const selectIsReadyOrganizationUser = createSelector(
  selectOrganizationUserState,
  (state: OrganizationUserState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationUser = createSelector(
  selectOrganizationUserState,
  (state: OrganizationUserState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationUsersEntities = createSelector(selectOrganizationUserState, selectEntities);

export const selectOrganizationUsersArray = createSelector(selectOrganizationUserState, selectAll);

export const selectIdOrganizationUsersActive = createSelector(
  selectOrganizationUserState,
  (state: OrganizationUserState.IState) => state.actives
);

const organizationUsersInObject = (organizationUsers: Dictionary<OrganizationUserEntityState>) => ({
  organizationUsers
});

const selectOrganizationUsersEntitiesDictionary = createSelector(
  selectOrganizationUsersEntities,
  organizationUsersInObject
);

const selectAllOrganizationUsersObject = createSelector(selectOrganizationUsersEntities, organizationUsers => {
  return hydrateAll({ organizationUsers });
});

const selectOneOrganizationUserDictionary = (idOrganizationUser: number) =>
  createSelector(selectOrganizationUsersEntities, organizationUsers => ({
    organizationUsers: { [idOrganizationUser]: organizationUsers[idOrganizationUser] }
  }));

const selectOneOrganizationUserDictionaryWithoutChild = (idOrganizationUser: number) =>
  createSelector(selectOrganizationUsersEntities, organizationUsers => ({
    organizationUser: organizationUsers[idOrganizationUser]
  }));

const selectActiveOrganizationUsersEntities = createSelector(
  selectIdOrganizationUsersActive,
  selectOrganizationUsersEntities,
  (actives: number[], organizationUsers: Dictionary<OrganizationUserEntityState>) =>
    getOrganizationUsersFromActives(actives, organizationUsers)
);

function getOrganizationUsersFromActives(
  actives: number[],
  organizationUsers: Dictionary<OrganizationUserEntityState>
): Dictionary<OrganizationUserEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationUsers[idActive]) {
      acc[idActive] = organizationUsers[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationUserEntityState>);
}

const selectAllOrganizationUsersSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationUser>(
      schema,
      selectAllOrganizationUsersSelectors,
      selectOrganizationUsersEntitiesDictionary,
      getRelationSelectors,
      organizationUserRelations,
      hydrateAll,
      'organizationUser'
    );
  } else {
    return selectAllOrganizationUsersObject;
  }
}

export function selectAllOrganizationUsersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationUsers'
): Selector {
  return createSelector(selectAllOrganizationUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationUserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationUsers.length; i++) {
      res[customKey][result.organizationUsers[i].idOrganizationUser] = result.organizationUsers[i];
    }
    return res;
  });
}

export function selectOneOrganizationUser(schema: SelectSchema = {}, idOrganizationUser: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationUserDictionary(idOrganizationUser)];
    selectors.push(...getRelationSelectors(schema, organizationUserRelations, 'organizationUser'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationUserDictionaryWithoutChild(idOrganizationUser);
  }
}

export function selectActiveOrganizationUsers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationUsersEntities, organizationUsers => ({
      organizationUsers
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationUserRelations, 'organizationUser'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationUsers: Dictionary<OrganizationUserEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  users?: Dictionary<UserEntityState>;
  userBoardStates?: Dictionary<UserBoardStateEntityState>;
  userRights?: Dictionary<UserRightEntityState>;
  profils?: Dictionary<ProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationUsers: (OrganizationUser | null)[] } {
  const { organizationUsers, organizations, users, userBoardStates, userRights, profils } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationUsers: Object.keys(organizationUsers).map(idOrganizationUser =>
      hydrate(
        organizationUsers[idOrganizationUser] as OrganizationUserEntityState,
        organizations,
        users,
        userBoardStates,
        userRights,
        profils
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationUser: OrganizationUserEntityState | null } {
  const { organizationUsers, organizations, users, userBoardStates, userRights, profils } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationUser = Object.values(organizationUsers)[0];
  return {
    organizationUser: hydrate(
      organizationUser as OrganizationUserEntityState,
      organizations,
      users,
      userBoardStates,
      userRights,
      profils
    )
  };
}

function hydrate(
  organizationUser: OrganizationUserEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  userBoardStateEntities?: Dictionary<UserBoardStateEntityState>,
  userRightEntities?: Dictionary<UserRightEntityState>,
  profilEntities?: Dictionary<ProfilEntityState>
): OrganizationUser | null {
  if (!organizationUser) {
    return null;
  }

  const organizationUserHydrated: OrganizationUserEntityState = { ...organizationUser };
  if (organizationEntities) {
    organizationUserHydrated.organization = organizationEntities[
      organizationUser.organization as number
    ] as Organization;
  } else {
    delete organizationUserHydrated.organization;
  }
  if (userEntities) {
    organizationUserHydrated.user = userEntities[organizationUser.user as number] as User;
  } else {
    delete organizationUserHydrated.user;
  }

  if (userBoardStateEntities) {
    organizationUserHydrated.userBoardStates = ((organizationUserHydrated.userBoardStates as number[]) || []).map(
      id => userBoardStateEntities[id]
    ) as UserBoardState[];
  } else {
    delete organizationUserHydrated.userBoardStates;
  }

  if (userRightEntities) {
    organizationUserHydrated.userRights = ((organizationUserHydrated.userRights as number[]) || []).map(
      id => userRightEntities[id]
    ) as UserRight[];
  } else {
    delete organizationUserHydrated.userRights;
  }

  if (profilEntities) {
    organizationUserHydrated.profils = ((organizationUserHydrated.profils as number[]) || []).map(
      id => profilEntities[id]
    ) as Profil[];
  } else {
    delete organizationUserHydrated.profils;
  }

  return organizationUserHydrated as OrganizationUser;
}
