import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Todo, TodoEntityState } from '@api/api-interfaces';
import { TodoElement, TodoElementEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { TodoState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const todoRelations: string[] = ['todoElements', 'elements', 'communities'];

export const { selectEntities, selectAll } = TodoState.adapter.getSelectors();

export const selectTodoState = createFeatureSelector<TodoState.IState>(TodoState.todoFeatureKey);

export const selectIsLoadedTodo = createSelector(selectTodoState, (state: TodoState.IState) => state.isLoaded);

export const selectIsLoadingTodo = createSelector(selectTodoState, (state: TodoState.IState) => state.isLoading);

export const selectIsReadyTodo = createSelector(selectTodoState, (state: TodoState.IState) => !state.isLoading);

export const selectIsReadyAndLoadedTodo = createSelector(
  selectTodoState,
  (state: TodoState.IState) => state.isLoaded && !state.isLoading
);

export const selectTodosEntities = createSelector(selectTodoState, selectEntities);

export const selectTodosArray = createSelector(selectTodoState, selectAll);

export const selectIdTodosActive = createSelector(selectTodoState, (state: TodoState.IState) => state.actives);

const todosInObject = (todos: Dictionary<TodoEntityState>) => ({ todos });

const selectTodosEntitiesDictionary = createSelector(selectTodosEntities, todosInObject);

const selectAllTodosObject = createSelector(selectTodosEntities, todos => {
  return hydrateAll({ todos });
});

const selectOneTodoDictionary = (idTodo: number) =>
  createSelector(selectTodosEntities, todos => ({
    todos: { [idTodo]: todos[idTodo] }
  }));

const selectOneTodoDictionaryWithoutChild = (idTodo: number) =>
  createSelector(selectTodosEntities, todos => ({
    todo: todos[idTodo]
  }));

const selectActiveTodosEntities = createSelector(
  selectIdTodosActive,
  selectTodosEntities,
  (actives: number[], todos: Dictionary<TodoEntityState>) => getTodosFromActives(actives, todos)
);

function getTodosFromActives(actives: number[], todos: Dictionary<TodoEntityState>): Dictionary<TodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (todos[idActive]) {
      acc[idActive] = todos[idActive];
    }
    return acc;
  }, {} as Dictionary<TodoEntityState>);
}

const selectAllTodosSelectors: Dictionary<Selector> = {};
export function selectAllTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Todo>(
      schema,
      selectAllTodosSelectors,
      selectTodosEntitiesDictionary,
      getRelationSelectors,
      todoRelations,
      hydrateAll,
      'todo'
    );
  } else {
    return selectAllTodosObject;
  }
}

export function selectAllTodosDictionary(schema: SelectSchema = {}, customKey: string = 'todos'): Selector {
  return createSelector(selectAllTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<TodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.todos.length; i++) {
      res[customKey][result.todos[i].idTodo] = result.todos[i];
    }
    return res;
  });
}

export function selectOneTodo(schema: SelectSchema = {}, idTodo: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneTodoDictionary(idTodo)];
    selectors.push(...getRelationSelectors(schema, todoRelations, 'todo'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneTodoDictionaryWithoutChild(idTodo);
  }
}

export function selectActiveTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveTodosEntities, todos => ({
      todos
    }))
  ];
  selectors.push(...getRelationSelectors(schema, todoRelations, 'todo'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  todos: Dictionary<TodoEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  todoElements?: Dictionary<TodoElementEntityState>;
  elements?: Dictionary<ElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { todos: (Todo | null)[] } {
  const { todos, communities, todoElements, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    todos: Object.keys(todos).map(idTodo =>
      hydrate(todos[idTodo] as TodoEntityState, communities, todoElements, elements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { todo: TodoEntityState | null } {
  const { todos, communities, todoElements, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const todo = Object.values(todos)[0];
  return {
    todo: hydrate(todo as TodoEntityState, communities, todoElements, elements)
  };
}

function hydrate(
  todo: TodoEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  todoElementEntities?: Dictionary<TodoElementEntityState>,
  elementEntities?: Dictionary<ElementEntityState>
): Todo | null {
  if (!todo) {
    return null;
  }

  const todoHydrated: TodoEntityState = { ...todo };
  if (communityEntities) {
    todoHydrated.community = communityEntities[todo.community as number] as Community;
  } else {
    delete todoHydrated.community;
  }

  if (todoElementEntities) {
    todoHydrated.todoElements = ((todoHydrated.todoElements as number[]) || []).map(
      id => todoElementEntities[id]
    ) as TodoElement[];
  } else {
    delete todoHydrated.todoElements;
  }

  if (elementEntities) {
    todoHydrated.elements = ((todoHydrated.elements as number[]) || []).map(id => elementEntities[id]) as Element[];
  } else {
    delete todoHydrated.elements;
  }

  return todoHydrated as Todo;
}
