import { ProjectChecklistComponent } from '@_app/admin/verification-campaign/verification-campaign-display/projects-checklist.component';
import { SharedModule } from '@_app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Community, OrganizationFamily, OrganizationSubFamily } from '@api/api-interfaces';
import { CommunityService } from '@wip/store/services';
import { CommunitySubFamilyModel, OrganizationFamilyModel, OrganizationModel } from '@wip/store/selectors-model';
import { CommunityUserProfilModel } from '@wip/store/selectors-model';
import { OrganizationService } from '@wip/store/services';
import { OrganizationFamilyService } from '@wip/store/services';
import { OrganizationStepModel } from '@wip/store/selectors-model';
import { OrganizationSubFamilyModel } from '@wip/store/selectors-model';
import { UserModel } from '@wip/store/selectors-model';
import { Observable, map, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'wip-list-projects-dialog',
  standalone: true,
  imports: [ProjectChecklistComponent, CommonModule, SharedModule],
  templateUrl: './list-projects-dialog.component.html',
  styleUrl: './list-projects-dialog.component.scss'
})
export class ListProjectsDialogComponent implements OnInit {
  public showFamilies: boolean;
  public subFamilies: OrganizationSubFamily[];
  public communities$: Observable<Community[]>;
  public orgaFamilies$: Observable<OrganizationFamily[]>;
  public sameFileFormControl: FormControl;

  @ViewChild(ProjectChecklistComponent) gridComponent: ProjectChecklistComponent;

  constructor(
    public dialogRef: MatDialogRef<ListProjectsDialogComponent>,
    private newOrganizationFamilyService: OrganizationFamilyService,
    private newOrganizationService: OrganizationService,
    private newCommunityService: CommunityService
  ) {}

  ngOnInit(): void {
    this.sameFileFormControl = new FormControl(true);
    this.orgaFamilies$ = this.newOrganizationFamilyService
      .selectAllOrganizationFamilys({ include: [OrganizationSubFamilyModel] })
      .pipe(
        untilDestroyed(this),
        map(orgaFamily =>
          orgaFamily.filter(fam => fam.idOrganization === this.newOrganizationService.currentIdOrganization)
        ),
        tap(c => {
          this.showFamilies = c.length > 1;
          this.subFamilies = c.map(orgFamily => orgFamily.organizationSubFamilys).flat();
        })
      );

    this.communities$ = this.newCommunityService
      .selectAllCommunities({
        include: [
          OrganizationModel,
          OrganizationFamilyModel,
          OrganizationStepModel,
          CommunitySubFamilyModel,
          { model: CommunityUserProfilModel, include: [UserModel] }
        ]
      })
      .pipe(
        untilDestroyed(this),
        map(comms => comms.filter(c => c.idOrganization === this.newOrganizationService.currentIdOrganization))
      );
  }

  public cancel() {
    this.dialogRef.close();
  }

  public confirm() {
    const communityIds = this.gridComponent.gridApi?.getSelectedNodes().map(node => node.data.idCommunity);
    this.dialogRef.close({
      idCommunity: this.newCommunityService.currentIdCommunity,
      communityIds,
      sameFile: this.sameFileFormControl.value
    });
  }
}
