import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserRight, UserRightEntityState } from '@api/api-interfaces';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { OrganizationSubFamily, OrganizationSubFamilyEntityState } from '@api/api-interfaces';
import { OrganizationUser, OrganizationUserEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { UserRightState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const userRightRelations: string[] = ['profils', 'organizationSubFamilys', 'organizationUsers'];

export const { selectEntities, selectAll } = UserRightState.adapter.getSelectors();

export const selectUserRightState = createFeatureSelector<UserRightState.IState>(UserRightState.userRightFeatureKey);

export const selectIsLoadedUserRight = createSelector(
  selectUserRightState,
  (state: UserRightState.IState) => state.isLoaded
);

export const selectIsLoadingUserRight = createSelector(
  selectUserRightState,
  (state: UserRightState.IState) => state.isLoading
);

export const selectIsReadyUserRight = createSelector(
  selectUserRightState,
  (state: UserRightState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUserRight = createSelector(
  selectUserRightState,
  (state: UserRightState.IState) => state.isLoaded && !state.isLoading
);

export const selectUserRightsEntities = createSelector(selectUserRightState, selectEntities);

export const selectUserRightsArray = createSelector(selectUserRightState, selectAll);

export const selectIdUserRightsActive = createSelector(
  selectUserRightState,
  (state: UserRightState.IState) => state.actives
);

const userRightsInObject = (userRights: Dictionary<UserRightEntityState>) => ({ userRights });

const selectUserRightsEntitiesDictionary = createSelector(selectUserRightsEntities, userRightsInObject);

const selectAllUserRightsObject = createSelector(selectUserRightsEntities, userRights => {
  return hydrateAll({ userRights });
});

const selectOneUserRightDictionary = (idUserRight: number) =>
  createSelector(selectUserRightsEntities, userRights => ({
    userRights: { [idUserRight]: userRights[idUserRight] }
  }));

const selectOneUserRightDictionaryWithoutChild = (idUserRight: number) =>
  createSelector(selectUserRightsEntities, userRights => ({
    userRight: userRights[idUserRight]
  }));

const selectActiveUserRightsEntities = createSelector(
  selectIdUserRightsActive,
  selectUserRightsEntities,
  (actives: number[], userRights: Dictionary<UserRightEntityState>) => getUserRightsFromActives(actives, userRights)
);

function getUserRightsFromActives(
  actives: number[],
  userRights: Dictionary<UserRightEntityState>
): Dictionary<UserRightEntityState> {
  return actives.reduce((acc, idActive) => {
    if (userRights[idActive]) {
      acc[idActive] = userRights[idActive];
    }
    return acc;
  }, {} as Dictionary<UserRightEntityState>);
}

const selectAllUserRightsSelectors: Dictionary<Selector> = {};
export function selectAllUserRights(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UserRight>(
      schema,
      selectAllUserRightsSelectors,
      selectUserRightsEntitiesDictionary,
      getRelationSelectors,
      userRightRelations,
      hydrateAll,
      'userRight'
    );
  } else {
    return selectAllUserRightsObject;
  }
}

export function selectAllUserRightsDictionary(schema: SelectSchema = {}, customKey: string = 'userRights'): Selector {
  return createSelector(selectAllUserRights(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserRightEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.userRights.length; i++) {
      res[customKey][result.userRights[i].idUserRight] = result.userRights[i];
    }
    return res;
  });
}

export function selectOneUserRight(schema: SelectSchema = {}, idUserRight: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneUserRightDictionary(idUserRight)];
    selectors.push(...getRelationSelectors(schema, userRightRelations, 'userRight'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserRightDictionaryWithoutChild(idUserRight);
  }
}

export function selectActiveUserRights(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveUserRightsEntities, userRights => ({
      userRights
    }))
  ];
  selectors.push(...getRelationSelectors(schema, userRightRelations, 'userRight'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  userRights: Dictionary<UserRightEntityState>;
  profils?: Dictionary<ProfilEntityState>;
  organizationSubFamilys?: Dictionary<OrganizationSubFamilyEntityState>;
  organizationUsers?: Dictionary<OrganizationUserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { userRights: (UserRight | null)[] } {
  const { userRights, profils, organizationSubFamilys, organizationUsers } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    userRights: Object.keys(userRights).map(idUserRight =>
      hydrate(userRights[idUserRight] as UserRightEntityState, profils, organizationSubFamilys, organizationUsers)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { userRight: UserRightEntityState | null } {
  const { userRights, profils, organizationSubFamilys, organizationUsers } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const userRight = Object.values(userRights)[0];
  return {
    userRight: hydrate(userRight as UserRightEntityState, profils, organizationSubFamilys, organizationUsers)
  };
}

function hydrate(
  userRight: UserRightEntityState,
  profilEntities?: Dictionary<ProfilEntityState>,
  organizationSubFamilyEntities?: Dictionary<OrganizationSubFamilyEntityState>,
  organizationUserEntities?: Dictionary<OrganizationUserEntityState>
): UserRight | null {
  if (!userRight) {
    return null;
  }

  const userRightHydrated: UserRightEntityState = { ...userRight };
  if (profilEntities) {
    userRightHydrated.profil = profilEntities[userRight.profil as number] as Profil;
  } else {
    delete userRightHydrated.profil;
  }
  if (organizationSubFamilyEntities) {
    userRightHydrated.organizationSubFamily = organizationSubFamilyEntities[
      userRight.organizationSubFamily as number
    ] as OrganizationSubFamily;
  } else {
    delete userRightHydrated.organizationSubFamily;
  }
  if (organizationUserEntities) {
    userRightHydrated.organizationUser = organizationUserEntities[
      userRight.organizationUser as number
    ] as OrganizationUser;
  } else {
    delete userRightHydrated.organizationUser;
  }

  return userRightHydrated as UserRight;
}
