import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedOrganizationRiskService } from './organization-risk-generated.service';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class OrganizationRiskService extends GeneratedOrganizationRiskService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}
