import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationMilestoneFamilyEntityState>;

export const adapter = createEntityAdapter<OrganizationMilestoneFamilyEntityState>({
  selectId: o => o.idOrganizationMilestoneFamily
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationMilestoneFamilyFeatureKey = 'organizationMilestoneFamily';
