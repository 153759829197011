import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationProjectModuleEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationProjectModuleEntityState>;

export const adapter = createEntityAdapter<OrganizationProjectModuleEntityState>({
  selectId: o => o.idOrganizationProjectModule
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationProjectModuleFeatureKey = 'organizationProjectModule';
