<div mat-dialog-title>
  <h1>{{ title }}</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
</div>
<div mat-dialog-content>
  <mat-form-field style="width: 100%">
    <textarea matInput [placeholder]="'Votre message'" [(ngModel)]="content"></textarea>
  </mat-form-field>
  <mat-checkbox (change)="checkbox = !checkbox" checked="checkbox" style="font-size: 85%"
    >Noter cette relance dans le champ commentaire</mat-checkbox
  >
</div>
<div mat-dialog-actions class="gap-8">
  <button mat-button type="button" (click)="cancel()" [color]="actions.get('cancel').color" tabindex="-1">
    {{ actions.get('cancel').label }}
  </button>
  <button
    mat-flat-button
    type="button"
    (click)="confirm()"
    [disabled]="!content"
    [color]="actions.get('confirm').color"
  >
    {{ actions.get('confirm').label }}
  </button>
</div>
