@if (formGroupLoaded) {
  <div class="flex flex-row gap-16 items-center content-container">
    @if (moduleIndex === 0 || moduleIndex === 1 || moduleIndex === 2 || moduleIndex === 4) {
      <div class="container">
        <div [formGroup]="filtersGroup" class="outlined-field flex items-center" style="width: 200px">
          <input data-cy="Rechercher" type="text" placeholder="Rechercher" formControlName="searchString" />
          <i class="icon-ic_fluent_search_24_regular icons search-icon flex-none"></i>
          @if (filtersGroup.get('searchString').value?.length) {
            <i
              aria-label="Clear"
              (click)="researchCtl.patchValue('')"
              class="icon-ic_fluent_dismiss_24_filled flex-none icons search-icon"
            ></i>
          }
        </div>
      </div>
    }
    <app-project-families-choices
      class="outlined-field"
      [filtersGroup]="filtersGroup"
      [organization]="organization"
      [organizationUser]="organizationUser"
    />
    @if (moduleIndex === 0 || moduleIndex === 1 || moduleIndex === 4) {
      <wip-responsible-choice
        [communities]="communities"
        [filtersGroup]="filtersGroup"
        [displayOnlyPilotToggle]="displayOnlyPilotToggle"
      />
    }
    @if (organizationFamilySelected && moduleIndex === 2) {
      <app-libraries-parents-choices [filtersGroup]="filtersGroup" [organizationFamily]="organizationFamilySelected" />
    }
    @if (organizationMilestoneFamilySelected && organizationFamilySelected && moduleIndex === 2) {
      <app-total-milestone-choices
        [filtersGroup]="filtersGroup"
        [organizationFamily]="organizationFamilySelected"
        [organizationMilestoneFamily]="organizationMilestoneFamilySelected"
      />
    }
    @if (moduleIndex === 3) {
      <app-chart-input
        [chartInputsForm]="chartInputsForm"
        [projectCharacteristics]="projectCharacteristics"
        [projectMilestones]="projectMilestones"
        [projectMilestoneFamilies]="projectMilestoneFamilies"
        (isCharacteristicsOpened)="isCharacteristicsSelectOpened($event)"
        (isMilestoneOpened)="isMilestoneSelectOpened($event)"
      />
    }
    @if (moduleIndex === 1) {
      <mat-checkbox
        color="displayRepartition"
        [formControl]="filtersGroup.get('displayRepartition')"
        style="margin-left: 5px; margin-bottom: -5px"
      >
        <div class="flex flex-row justify-start items-center">
          <div>Afficher répartition des lots</div>
        </div>
      </mat-checkbox>
    }
    <div>
      @if (subFamilyControl.value?.length) {
        <button
          (click)="showHashtags = !showHashtags"
          [matTooltip]="showHashtags ? 'Masquer Catégories' : 'Afficher Catégories'"
          type="button"
          style="box-shadow: none !important"
          mat-mini-fab
          color="primary"
        >
          <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
            <i class="icon icon-ic_fluent_filter_24_regular" style="font-size: 24px"></i>
          </span>
        </button>
      } @else {
        <button
          (click)="showHashtags = !showHashtags"
          [matTooltip]="showHashtags ? 'Masquer Catégories' : 'Afficher Catégories'"
          type="button"
          style="box-shadow: none !important"
          mat-icon-button
        >
          <span class="flex flex-row flex-nowrap gap-8 justify-center items-center">
            <i class="icon icon-ic_fluent_filter_24_regular" style="font-size: 24px"></i>
          </span>
        </button>
      }
    </div>
  </div>
}
@if (showHashtags) {
  <div class="flex flex-row">
    @if (organizationFamilySelected) {
      <app-project-sub-families-choices
        [filtersGroup]="filtersGroup"
        [subFamilyControl]="subFamilyControl"
        [organizationSubFamilies]="listHashtags"
        [idOrganizationFamily]="this.organizationFamilySelected.idOrganizationFamily"
      >
      </app-project-sub-families-choices>
    }
  </div>
}
