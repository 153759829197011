export function createEmptyYear(year: number) {
  return {
    year: year,
    '01': {},
    '02': {},
    '03': {},
    '04': {},
    '05': {},
    '06': {},
    '07': {},
    '08': {},
    '09': {},
    '10': {},
    '11': {},
    '12': {}
  };
}
