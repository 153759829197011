import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FolderService } from '@wip/store/services';
import { OldUserService } from '@wip/store/services';

@UntilDestroy()
@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  token: string | null = null;
  idUser: number | null = null;
  login$: any;
  unauthorizedRequest: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private folderService: FolderService,
    private oldUserService: OldUserService
  ) {
    this.unauthorizedRequest = false;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = JSON.parse(localStorage.getItem('login') || '{}')?.token;

    if (this.token) {
      let authReq = new HttpParams({ fromString: req.params.toString() });
      const requestClone = req.clone({
        params: authReq,
        headers: req.headers.set('Authorization', this.token)
      });

      return next.handle(requestClone).pipe(
        untilDestroyed(this),
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 403) {
                this.unauthorizedRequest = true;
                this.oldUserService.logOut(true);
              }
              if (err.status === 401 && !this.unauthorizedRequest) {
                this.unauthorizedRequest = true;
              }
              if (err.status === 413) {
                this.snackBar.open("ATTENTION : Fichier trop volumineux. Il n'a pas été chargé", undefined, {
                  duration: 3000
                });
                this.folderService.uploadInProgress.next(false);
              }
              if (err.status === 415) {
                this.snackBar.open("ATTENTION : Format de fichier non supporté. Il n'a pas été chargé", undefined, {
                  duration: 3000
                });
                this.folderService.uploadInProgress.next(false);
              }
            }
          }
        )
      );
    } else {
      return next.handle(req).pipe(
        untilDestroyed(this),
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 403) {
                this.unauthorizedRequest = true;
                this.oldUserService.logOut(true);
              }
            }
          }
        )
      );
    }
  }
}
