import { FolderState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { folderReducersGeneratedFunctions } from './folder-generated.reducer';

const folderReducersFunctions = [...folderReducersGeneratedFunctions];

const folderReducer = createReducer(FolderState.initialState, ...folderReducersFunctions);

export function reducer(state: FolderState.IState | undefined, action: Action) {
  return folderReducer(state, action);
}
