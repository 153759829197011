import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommunityKpiApiService } from '@wip/store/api-services';
import { GeneratedCommunityKpiEffects } from './community-kpi-generated.effects';

@Injectable()
export class CommunityKpiEffects extends GeneratedCommunityKpiEffects {
  constructor(actions$: Actions, communityKpiApiService: CommunityKpiApiService, store$: Store<AppState>) {
    super(actions$, communityKpiApiService, store$);
  }
}
