import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationDataColumnValueGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnValueState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationDataColumnValueEntityState } from '@api/api-interfaces';

export const organizationDataColumnValueReducersGeneratedFunctions: ReducerTypes<
  OrganizationDataColumnValueState.IState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationDataColumnValueGeneratedActions.getOneOrganizationDataColumnValue,
    (state: OrganizationDataColumnValueState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationDataColumnValueGeneratedActions.getManyOrganizationDataColumnValues,
    (state: OrganizationDataColumnValueState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationDataColumnValueGeneratedActions.upsertOneOrganizationDataColumnValue,
    (state: OrganizationDataColumnValueState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationDataColumnValueGeneratedActions.upsertManyOrganizationDataColumnValuesSuccess,
    (state: OrganizationDataColumnValueState.IState, { organizationDataColumnValues }) =>
      OrganizationDataColumnValueState.adapter.upsertMany(organizationDataColumnValues, setLoadingsState(state, false))
  ),
  on(
    OrganizationDataColumnValueGeneratedActions.deleteOneOrganizationDataColumnValue,
    (state: OrganizationDataColumnValueState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationDataColumnValueGeneratedActions.deleteOneOrganizationDataColumnValueSuccess,
    (state: OrganizationDataColumnValueState.IState, { idOrganizationDataColumnValue }) =>
      OrganizationDataColumnValueState.adapter.removeOne(idOrganizationDataColumnValue, setLoadingsState(state, false))
  ),
  on(
    OrganizationDataColumnValueGeneratedActions.clearActivesOrganizationDataColumnValues,
    (state: OrganizationDataColumnValueState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationDataColumnValueGeneratedActions.addManyActivesOrganizationDataColumnValues,
    (state: OrganizationDataColumnValueState.IState, { idOrganizationDataColumnValues }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationDataColumnValues)
    })
  ),
  on(
    OrganizationDataColumnValueGeneratedActions.deleteOneActiveOrganizationDataColumnValue,
    (state: OrganizationDataColumnValueState.IState, { idOrganizationDataColumnValue }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationDataColumnValue)
    })
  ),

  on(
    OrganizationDataColumnValueGeneratedActions.clearOrganizationDataColumnValues,
    () => OrganizationDataColumnValueState.initialState
  ),

  on(
    OrganizationDataColumnValueGeneratedActions.addManyValeurSuccess,
    (state: OrganizationDataColumnValueState.IState, { idOrganizationDataColumnValue, idValeurs }) => {
      if (!state.entities[idOrganizationDataColumnValue]) {
        return state;
      }
      const valeurs = (state.entities[idOrganizationDataColumnValue]?.valeurs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationDataColumnValue]: {
            ...state.entities[idOrganizationDataColumnValue],
            valeurs: valeurs.concat(idValeurs.filter(id => valeurs.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationDataColumnValueGeneratedActions.deleteManyValeurSuccess,
    (state: OrganizationDataColumnValueState.IState, { idValeurs, idOrganizationDataColumnValues }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationDataColumnValues.reduce((entities, idOrganizationDataColumnValue) => {
            if (!state.entities[idOrganizationDataColumnValue]?.valeurs) {
              return entities;
            }
            entities[idOrganizationDataColumnValue] = {
              ...state.entities[idOrganizationDataColumnValue],
              valeurs: (state.entities[idOrganizationDataColumnValue]?.valeurs as number[])?.filter(
                (idValeur: number) => !idValeurs.some((id: number) => id === idValeur)
              )
            } as OrganizationDataColumnValueEntityState;
            return entities;
          }, {} as Dictionary<OrganizationDataColumnValueEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationDataColumnValueGeneratedActions.addManyOrganizationCaracteristicValueSuccess,
    (
      state: OrganizationDataColumnValueState.IState,
      { idOrganizationDataColumnValue, idOrganizationCaracteristicValues }
    ) => {
      if (!state.entities[idOrganizationDataColumnValue]) {
        return state;
      }
      const organizationCaracteristicValues =
        (state.entities[idOrganizationDataColumnValue]?.organizationCaracteristicValues as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationDataColumnValue]: {
            ...state.entities[idOrganizationDataColumnValue],
            organizationCaracteristicValues: organizationCaracteristicValues.concat(
              idOrganizationCaracteristicValues.filter(id => organizationCaracteristicValues.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationDataColumnValueGeneratedActions.deleteManyOrganizationCaracteristicValueSuccess,
    (
      state: OrganizationDataColumnValueState.IState,
      { idOrganizationCaracteristicValues, idOrganizationDataColumnValues }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationDataColumnValues.reduce((entities, idOrganizationDataColumnValue) => {
            if (!state.entities[idOrganizationDataColumnValue]?.organizationCaracteristicValues) {
              return entities;
            }
            entities[idOrganizationDataColumnValue] = {
              ...state.entities[idOrganizationDataColumnValue],
              organizationCaracteristicValues: (
                state.entities[idOrganizationDataColumnValue]?.organizationCaracteristicValues as number[]
              )?.filter(
                (idOrganizationCaracteristicValue: number) =>
                  !idOrganizationCaracteristicValues.some((id: number) => id === idOrganizationCaracteristicValue)
              )
            } as OrganizationDataColumnValueEntityState;
            return entities;
          }, {} as Dictionary<OrganizationDataColumnValueEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationDataColumnValueGeneratedActions.addOrganizationDataColumnSuccess,
    (state: OrganizationDataColumnValueState.IState, { idOrganizationDataColumnValue, idOrganizationDataColumn }) => {
      if (!state.entities[idOrganizationDataColumnValue]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationDataColumnValue]: {
            ...state.entities[idOrganizationDataColumnValue],
            organizationDataColumn: idOrganizationDataColumn
          }
        }
      };
    }
  ),

  on(
    OrganizationDataColumnValueGeneratedActions.deleteManyOrganizationDataColumnSuccess,
    (state: OrganizationDataColumnValueState.IState, { idOrganizationDataColumns, idOrganizationDataColumnValues }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationDataColumnValues.reduce((entities, idOrganizationDataColumnValue) => {
            if (!state.entities[idOrganizationDataColumnValue]?.organizationDataColumn) {
              return entities;
            }
            entities[idOrganizationDataColumnValue] = {
              ...state.entities[idOrganizationDataColumnValue],
              organizationDataColumn: idOrganizationDataColumns.some(
                (idOrganizationDataColumn: number) =>
                  idOrganizationDataColumn === state.entities[idOrganizationDataColumnValue]?.organizationDataColumn
              )
                ? undefined
                : state.entities[idOrganizationDataColumnValue]?.organizationDataColumn
            } as OrganizationDataColumnValueEntityState;
            return entities;
          }, {} as Dictionary<OrganizationDataColumnValueEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationDataColumnValueState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationDataColumnValueState.IState {
  return { ...state, isLoaded, isLoading };
}
