import { createAction, props } from '@ngrx/store';
import { ReorderInterface } from '@api/api-interfaces';

export * from './organization-family-generated.actions';

export const reorderFamily = createAction(
  '[OrganizationFamily] Reorder',
  props<{
    value: ReorderInterface;
  }>()
);
