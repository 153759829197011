import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityMilestone, CommunityMilestoneEntityState } from '@api/api-interfaces';
import { CommunityMilestoneRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityMilestoneGeneratedActions = createActionGroup({
  source: 'Community Milestone Generated',
  events: {
    'Get One Community Milestone': props<{ idCommunityMilestone: number; params?: any }>(),
    'Get Many Community Milestones': props<{ params: any }>(),
    'Add Many Actives Community Milestones': props<{ idCommunityMilestones: number[] }>(),
    'Delete One Active Community Milestone': props<{ idCommunityMilestone: number }>(),
    'Clear Actives Community Milestones': emptyProps(),
    'Upsert One Community Milestone': props<{
      communityMilestone: Partial<CommunityMilestone>;
      ids?: CommunityMilestoneRelationsIds;
    }>(),
    'Upsert Many Community Milestones': props<{
      communityMilestones: Partial<CommunityMilestone>[];
      ids?: CommunityMilestoneRelationsIds;
    }>(),
    'Upsert Many Community Milestones Success': props<{ communityMilestones: CommunityMilestoneEntityState[] }>(),
    'Delete One Community Milestone': props<{ idCommunityMilestone: number }>(),
    'Delete One Community Milestone Success': props<{ idCommunityMilestone: number }>(),
    'Normalize Many Community Milestones After Upsert': props<{
      communityMilestones: CommunityMilestoneEntityState[];
    }>(),
    'Community Milestones Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Milestones': emptyProps(),
    'Add Organization Milestone Success': props<{ idCommunityMilestone: number; idOrganizationMilestone: number }>(),
    'Delete Many Organization Milestone Success': props<{
      idOrganizationMilestones: number[];
      idCommunityMilestones: number[];
    }>(),
    'Add Community Success': props<{ idCommunityMilestone: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityMilestones: number[] }>()
  }
});
