import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentService } from '@_shared/elements/documents/document.service';
import { Element } from '@api/api-interfaces';

@Component({
  selector: 'app-document-list-item',
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss']
})
export class DocumentListItemComponent implements OnInit {
  @Input() document: Element;
  @Input() isLoading = false;
  @Input() isViewer = false;
  @Output() preview = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() download = new EventEmitter<void>();

  hover = false;
  isPicture = false;
  isPDF = false;
  fileIcon: { logoClass: string; colorClass: string };

  constructor(private documentService: DocumentService) {}

  ngOnInit() {
    this.isPicture = this.documentService.isPicture(this.document.extension);
    this.isPDF = this.documentService.isPDF(this.document.extension);
    this.fileIcon = this.documentService.getFileIcon(this.document.extension);
  }

  setHover = () => (this.hover = !this.hover);

  handleDblClick = () => (this.isPicture || this.isPDF ? this.previewItem() : this.downloadItem());

  previewItem = () => this.preview.emit();

  deleteItem = () => this.delete.emit();

  downloadItem = () => this.download.emit();
}
