import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationSubFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationSubFamilyState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationSubFamilyEntityState } from '@api/api-interfaces';

export const organizationSubFamilyReducersGeneratedFunctions: ReducerTypes<
  OrganizationSubFamilyState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationSubFamilyGeneratedActions.getOneOrganizationSubFamily, (state: OrganizationSubFamilyState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationSubFamilyGeneratedActions.getManyOrganizationSubFamilys, (state: OrganizationSubFamilyState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationSubFamilyGeneratedActions.upsertOneOrganizationSubFamily, (state: OrganizationSubFamilyState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationSubFamilyGeneratedActions.upsertManyOrganizationSubFamilysSuccess,
    (state: OrganizationSubFamilyState.IState, { organizationSubFamilys }) =>
      OrganizationSubFamilyState.adapter.upsertMany(organizationSubFamilys, setLoadingsState(state, false))
  ),
  on(OrganizationSubFamilyGeneratedActions.deleteOneOrganizationSubFamily, (state: OrganizationSubFamilyState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationSubFamilyGeneratedActions.deleteOneOrganizationSubFamilySuccess,
    (state: OrganizationSubFamilyState.IState, { idOrganizationSubFamily }) =>
      OrganizationSubFamilyState.adapter.removeOne(idOrganizationSubFamily, setLoadingsState(state, false))
  ),
  on(
    OrganizationSubFamilyGeneratedActions.clearActivesOrganizationSubFamilys,
    (state: OrganizationSubFamilyState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationSubFamilyGeneratedActions.addManyActivesOrganizationSubFamilys,
    (state: OrganizationSubFamilyState.IState, { idOrganizationSubFamilys }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationSubFamilys)
    })
  ),
  on(
    OrganizationSubFamilyGeneratedActions.deleteOneActiveOrganizationSubFamily,
    (state: OrganizationSubFamilyState.IState, { idOrganizationSubFamily }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationSubFamily)
    })
  ),

  on(OrganizationSubFamilyGeneratedActions.clearOrganizationSubFamilys, () => OrganizationSubFamilyState.initialState),

  on(
    OrganizationSubFamilyGeneratedActions.addManyCommunitySubFamilySuccess,
    (state: OrganizationSubFamilyState.IState, { idOrganizationSubFamily, idCommunitySubFamilys }) => {
      if (!state.entities[idOrganizationSubFamily]) {
        return state;
      }
      const communitySubFamilys = (state.entities[idOrganizationSubFamily]?.communitySubFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSubFamily]: {
            ...state.entities[idOrganizationSubFamily],
            communitySubFamilys: communitySubFamilys.concat(
              idCommunitySubFamilys.filter(id => communitySubFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationSubFamilyGeneratedActions.deleteManyCommunitySubFamilySuccess,
    (state: OrganizationSubFamilyState.IState, { idCommunitySubFamilys, idOrganizationSubFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSubFamilys.reduce((entities, idOrganizationSubFamily) => {
            if (!state.entities[idOrganizationSubFamily]?.communitySubFamilys) {
              return entities;
            }
            entities[idOrganizationSubFamily] = {
              ...state.entities[idOrganizationSubFamily],
              communitySubFamilys: (state.entities[idOrganizationSubFamily]?.communitySubFamilys as number[])?.filter(
                (idCommunitySubFamily: number) =>
                  !idCommunitySubFamilys.some((id: number) => id === idCommunitySubFamily)
              )
            } as OrganizationSubFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSubFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSubFamilyGeneratedActions.addManyUserRightSuccess,
    (state: OrganizationSubFamilyState.IState, { idOrganizationSubFamily, idUserRights }) => {
      if (!state.entities[idOrganizationSubFamily]) {
        return state;
      }
      const userRights = (state.entities[idOrganizationSubFamily]?.userRights as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSubFamily]: {
            ...state.entities[idOrganizationSubFamily],
            userRights: userRights.concat(idUserRights.filter(id => userRights.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationSubFamilyGeneratedActions.deleteManyUserRightSuccess,
    (state: OrganizationSubFamilyState.IState, { idUserRights, idOrganizationSubFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSubFamilys.reduce((entities, idOrganizationSubFamily) => {
            if (!state.entities[idOrganizationSubFamily]?.userRights) {
              return entities;
            }
            entities[idOrganizationSubFamily] = {
              ...state.entities[idOrganizationSubFamily],
              userRights: (state.entities[idOrganizationSubFamily]?.userRights as number[])?.filter(
                (idUserRight: number) => !idUserRights.some((id: number) => id === idUserRight)
              )
            } as OrganizationSubFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSubFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSubFamilyGeneratedActions.addOrganizationSuccess,
    (state: OrganizationSubFamilyState.IState, { idOrganizationSubFamily, idOrganization }) => {
      if (!state.entities[idOrganizationSubFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSubFamily]: {
            ...state.entities[idOrganizationSubFamily],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationSubFamilyGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationSubFamilyState.IState, { idOrganizations, idOrganizationSubFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSubFamilys.reduce((entities, idOrganizationSubFamily) => {
            if (!state.entities[idOrganizationSubFamily]?.organization) {
              return entities;
            }
            entities[idOrganizationSubFamily] = {
              ...state.entities[idOrganizationSubFamily],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationSubFamily]?.organization
              )
                ? undefined
                : state.entities[idOrganizationSubFamily]?.organization
            } as OrganizationSubFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSubFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSubFamilyGeneratedActions.addOrganizationFamilySuccess,
    (state: OrganizationSubFamilyState.IState, { idOrganizationSubFamily, idOrganizationFamily }) => {
      if (!state.entities[idOrganizationSubFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSubFamily]: {
            ...state.entities[idOrganizationSubFamily],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationSubFamilyGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationSubFamilyState.IState, { idOrganizationFamilys, idOrganizationSubFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSubFamilys.reduce((entities, idOrganizationSubFamily) => {
            if (!state.entities[idOrganizationSubFamily]?.organizationFamily) {
              return entities;
            }
            entities[idOrganizationSubFamily] = {
              ...state.entities[idOrganizationSubFamily],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idOrganizationSubFamily]?.organizationFamily
              )
                ? undefined
                : state.entities[idOrganizationSubFamily]?.organizationFamily
            } as OrganizationSubFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSubFamilyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationSubFamilyState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationSubFamilyState.IState {
  return { ...state, isLoaded, isLoading };
}
