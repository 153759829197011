import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedChildrenElementApiService } from './children-element-api-generated.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChildrenElementApiService extends GeneratedChildrenElementApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public deleteOneChildrenElementCustom(params: {
    idCommunity: number;
    idChildrenElement: number;
  }): Observable<number> {
    return this.repo.delete('children-element/' + +params.idChildrenElement + '?idCommunity=' + +params.idCommunity);
  }
}
