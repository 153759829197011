<div class="flex flex-row justify-start items-center">
  <mat-form-field [formGroup]="actionForm" class="flex" style="width: 100%" floatLabel="never">
    <mat-label>Livrable</mat-label>
    <textarea
      #input
      formControlName="titleControl"
      matInput
      placeholder="Livrable"
      maxlength="255"
      rows="1"
      [cdkTextareaAutosize]="true"
      (keydown.enter)="$event.preventDefault()"
    ></textarea>
    @if (actionForm.controls.titleControl.value.length > 254) {
      <mat-hint [align]="'start'">Max 255 caractères</mat-hint>
    }
    @if (actionForm.controls.titleControl.value.length === 0) {
      <mat-error>Un titre est requis</mat-error>
    }
  </mat-form-field>
</div>
