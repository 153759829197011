import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationCaracteristicColumn } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationCaracteristicColumnRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationCaracteristicColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicColumnSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationCaracteristicColumnService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(
      select(OrganizationCaracteristicColumnSelectors.selectIsLoadedOrganizationCaracteristicColumn)
    );
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(
      select(OrganizationCaracteristicColumnSelectors.selectIsLoadingOrganizationCaracteristicColumn)
    );
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationCaracteristicColumnSelectors.selectIsReadyAndLoadedOrganizationCaracteristicColumn as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationCaracteristicColumns(
    schema: SelectSchema = {}
  ): Observable<OrganizationCaracteristicColumn[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicColumnSelectors.selectAllOrganizationCaracteristicColumns(schema)))
      .pipe(
        switchMap(
          ({
            organizationCaracteristicColumns
          }: {
            organizationCaracteristicColumns: OrganizationCaracteristicColumn[];
          }) => {
            return this.getReady(schema).pipe(mapTo(organizationCaracteristicColumns));
          }
        )
      );
  }

  public selectOneOrganizationCaracteristicColumn(
    idOrganizationCaracteristicColumn: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationCaracteristicColumn> {
    return this.store$
      .pipe(
        select(
          OrganizationCaracteristicColumnSelectors.selectOneOrganizationCaracteristicColumn(
            schema,
            idOrganizationCaracteristicColumn
          )
        )
      )
      .pipe(
        switchMap(
          ({
            organizationCaracteristicColumn
          }: {
            organizationCaracteristicColumn: OrganizationCaracteristicColumn;
          }) => {
            return this.getReady(schema).pipe(mapTo(organizationCaracteristicColumn));
          }
        )
      );
  }

  public selectAllActiveOrganizationCaracteristicColumns(
    schema: SelectSchema = {}
  ): Observable<OrganizationCaracteristicColumn[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicColumnSelectors.selectActiveOrganizationCaracteristicColumns(schema)))
      .pipe(
        switchMap(
          ({
            organizationCaracteristicColumns
          }: {
            organizationCaracteristicColumns: OrganizationCaracteristicColumn[];
          }) => {
            return this.getReady(schema).pipe(mapTo(organizationCaracteristicColumns));
          }
        )
      );
  }

  public selectIdOrganizationCaracteristicColumnsActive(): Observable<number[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicColumnSelectors.selectIdOrganizationCaracteristicColumnsActive))
      .pipe(
        switchMap((idOrganizationCaracteristicColumns: number[]) => {
          return this.getReady().pipe(mapTo(idOrganizationCaracteristicColumns));
        })
      );
  }

  public getOneOrganizationCaracteristicColumn(
    idOrganizationCaracteristicColumn: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristicColumn> {
    this.store$.dispatch(
      OrganizationCaracteristicColumnGeneratedActions.getOneOrganizationCaracteristicColumn({
        idOrganizationCaracteristicColumn,
        params
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicColumnGeneratedActions.normalizeManyOrganizationCaracteristicColumnsAfterUpsert,
        OrganizationCaracteristicColumnGeneratedActions.organizationCaracteristicColumnsFailure,
        true
      );
    }
  }

  public getManyOrganizationCaracteristicColumns(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristicColumn[]> {
    this.store$.dispatch(
      OrganizationCaracteristicColumnGeneratedActions.getManyOrganizationCaracteristicColumns({ params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicColumnGeneratedActions.normalizeManyOrganizationCaracteristicColumnsAfterUpsert,
        OrganizationCaracteristicColumnGeneratedActions.organizationCaracteristicColumnsFailure
      );
    }
  }

  public upsertOneOrganizationCaracteristicColumn(
    organizationCaracteristicColumn: Partial<OrganizationCaracteristicColumn>,
    ids: OrganizationCaracteristicColumnRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristicColumn> {
    this.store$.dispatch(
      OrganizationCaracteristicColumnGeneratedActions.upsertOneOrganizationCaracteristicColumn({
        organizationCaracteristicColumn,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicColumnGeneratedActions.normalizeManyOrganizationCaracteristicColumnsAfterUpsert,
        OrganizationCaracteristicColumnGeneratedActions.organizationCaracteristicColumnsFailure,
        true
      );
    }
  }

  public deleteOneOrganizationCaracteristicColumn(
    idOrganizationCaracteristicColumn: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationCaracteristicColumnGeneratedActions.deleteOneOrganizationCaracteristicColumn({
        idOrganizationCaracteristicColumn
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicColumnGeneratedActions.deleteOneOrganizationCaracteristicColumnSuccess,
        OrganizationCaracteristicColumnGeneratedActions.organizationCaracteristicColumnsFailure
      );
    }
  }

  public setActiveOrganizationCaracteristicColumns(idOrganizationCaracteristicColumns: number[]): void {
    this.store$.dispatch(
      OrganizationCaracteristicColumnGeneratedActions.clearActivesOrganizationCaracteristicColumns()
    );
    this.store$.dispatch(
      OrganizationCaracteristicColumnGeneratedActions.addManyActivesOrganizationCaracteristicColumns({
        idOrganizationCaracteristicColumns
      })
    );
  }
}
