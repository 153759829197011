import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserRightGeneratedActions } from '@wip/store/actions';
import { UserRightState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserRightEntityState } from '@api/api-interfaces';

export const userRightReducersGeneratedFunctions: ReducerTypes<UserRightState.IState, readonly ActionCreator[]>[] = [
  on(UserRightGeneratedActions.getOneUserRight, (state: UserRightState.IState) => setLoadingsState(state, true)),
  on(UserRightGeneratedActions.getManyUserRights, (state: UserRightState.IState) => setLoadingsState(state, true)),
  on(UserRightGeneratedActions.upsertOneUserRight, (state: UserRightState.IState) => setLoadingsState(state, true)),

  on(UserRightGeneratedActions.upsertManyUserRightsSuccess, (state: UserRightState.IState, { userRights }) =>
    UserRightState.adapter.upsertMany(userRights, setLoadingsState(state, false))
  ),
  on(UserRightGeneratedActions.deleteOneUserRight, (state: UserRightState.IState) => setLoadingsState(state, true)),
  on(UserRightGeneratedActions.deleteOneUserRightSuccess, (state: UserRightState.IState, { idUserRight }) =>
    UserRightState.adapter.removeOne(idUserRight, setLoadingsState(state, false))
  ),
  on(UserRightGeneratedActions.clearActivesUserRights, (state: UserRightState.IState) => ({ ...state, actives: [] })),
  on(UserRightGeneratedActions.addManyActivesUserRights, (state: UserRightState.IState, { idUserRights }) => ({
    ...state,
    actives: state.actives.concat(idUserRights)
  })),
  on(UserRightGeneratedActions.deleteOneActiveUserRight, (state: UserRightState.IState, { idUserRight }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idUserRight)
  })),

  on(UserRightGeneratedActions.clearUserRights, () => UserRightState.initialState),

  on(UserRightGeneratedActions.addProfilSuccess, (state: UserRightState.IState, { idUserRight, idProfil }) => {
    if (!state.entities[idUserRight]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUserRight]: {
          ...state.entities[idUserRight],
          profil: idProfil
        }
      }
    };
  }),

  on(UserRightGeneratedActions.deleteManyProfilSuccess, (state: UserRightState.IState, { idProfils, idUserRights }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUserRights.reduce((entities, idUserRight) => {
          if (!state.entities[idUserRight]?.profil) {
            return entities;
          }
          entities[idUserRight] = {
            ...state.entities[idUserRight],
            profil: idProfils.some((idProfil: number) => idProfil === state.entities[idUserRight]?.profil)
              ? undefined
              : state.entities[idUserRight]?.profil
          } as UserRightEntityState;
          return entities;
        }, {} as Dictionary<UserRightEntityState>)
      }
    };
  }),

  on(
    UserRightGeneratedActions.addOrganizationSubFamilySuccess,
    (state: UserRightState.IState, { idUserRight, idOrganizationSubFamily }) => {
      if (!state.entities[idUserRight]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserRight]: {
            ...state.entities[idUserRight],
            organizationSubFamily: idOrganizationSubFamily
          }
        }
      };
    }
  ),

  on(
    UserRightGeneratedActions.deleteManyOrganizationSubFamilySuccess,
    (state: UserRightState.IState, { idOrganizationSubFamilys, idUserRights }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserRights.reduce((entities, idUserRight) => {
            if (!state.entities[idUserRight]?.organizationSubFamily) {
              return entities;
            }
            entities[idUserRight] = {
              ...state.entities[idUserRight],
              organizationSubFamily: idOrganizationSubFamilys.some(
                (idOrganizationSubFamily: number) =>
                  idOrganizationSubFamily === state.entities[idUserRight]?.organizationSubFamily
              )
                ? undefined
                : state.entities[idUserRight]?.organizationSubFamily
            } as UserRightEntityState;
            return entities;
          }, {} as Dictionary<UserRightEntityState>)
        }
      };
    }
  ),

  on(
    UserRightGeneratedActions.addOrganizationUserSuccess,
    (state: UserRightState.IState, { idUserRight, idOrganizationUser }) => {
      if (!state.entities[idUserRight]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserRight]: {
            ...state.entities[idUserRight],
            organizationUser: idOrganizationUser
          }
        }
      };
    }
  ),

  on(
    UserRightGeneratedActions.deleteManyOrganizationUserSuccess,
    (state: UserRightState.IState, { idOrganizationUsers, idUserRights }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserRights.reduce((entities, idUserRight) => {
            if (!state.entities[idUserRight]?.organizationUser) {
              return entities;
            }
            entities[idUserRight] = {
              ...state.entities[idUserRight],
              organizationUser: idOrganizationUsers.some(
                (idOrganizationUser: number) => idOrganizationUser === state.entities[idUserRight]?.organizationUser
              )
                ? undefined
                : state.entities[idUserRight]?.organizationUser
            } as UserRightEntityState;
            return entities;
          }, {} as Dictionary<UserRightEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: UserRightState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): UserRightState.IState {
  return { ...state, isLoaded, isLoading };
}
