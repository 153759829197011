import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationMilestoneFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneFamilySelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationMilestoneFamilyService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationMilestoneFamilySelectors.selectIsLoadedOrganizationMilestoneFamily));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationMilestoneFamilySelectors.selectIsLoadingOrganizationMilestoneFamily));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationMilestoneFamilySelectors.selectIsReadyAndLoadedOrganizationMilestoneFamily as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationMilestoneFamilys(schema: SelectSchema = {}): Observable<OrganizationMilestoneFamily[]> {
    return this.store$
      .pipe(select(OrganizationMilestoneFamilySelectors.selectAllOrganizationMilestoneFamilys(schema)))
      .pipe(
        switchMap(
          ({ organizationMilestoneFamilys }: { organizationMilestoneFamilys: OrganizationMilestoneFamily[] }) => {
            return this.getReady(schema).pipe(mapTo(organizationMilestoneFamilys));
          }
        )
      );
  }

  public selectOneOrganizationMilestoneFamily(
    idOrganizationMilestoneFamily: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationMilestoneFamily> {
    return this.store$
      .pipe(
        select(
          OrganizationMilestoneFamilySelectors.selectOneOrganizationMilestoneFamily(
            schema,
            idOrganizationMilestoneFamily
          )
        )
      )
      .pipe(
        switchMap(({ organizationMilestoneFamily }: { organizationMilestoneFamily: OrganizationMilestoneFamily }) => {
          return this.getReady(schema).pipe(mapTo(organizationMilestoneFamily));
        })
      );
  }

  public selectAllActiveOrganizationMilestoneFamilys(
    schema: SelectSchema = {}
  ): Observable<OrganizationMilestoneFamily[]> {
    return this.store$
      .pipe(select(OrganizationMilestoneFamilySelectors.selectActiveOrganizationMilestoneFamilys(schema)))
      .pipe(
        switchMap(
          ({ organizationMilestoneFamilys }: { organizationMilestoneFamilys: OrganizationMilestoneFamily[] }) => {
            return this.getReady(schema).pipe(mapTo(organizationMilestoneFamilys));
          }
        )
      );
  }

  public selectIdOrganizationMilestoneFamilysActive(): Observable<number[]> {
    return this.store$
      .pipe(select(OrganizationMilestoneFamilySelectors.selectIdOrganizationMilestoneFamilysActive))
      .pipe(
        switchMap((idOrganizationMilestoneFamilys: number[]) => {
          return this.getReady().pipe(mapTo(idOrganizationMilestoneFamilys));
        })
      );
  }

  public getOneOrganizationMilestoneFamily(
    idOrganizationMilestoneFamily: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestoneFamily> {
    this.store$.dispatch(
      OrganizationMilestoneFamilyGeneratedActions.getOneOrganizationMilestoneFamily({
        idOrganizationMilestoneFamily,
        params
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert,
        OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure,
        true
      );
    }
  }

  public getManyOrganizationMilestoneFamilys(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestoneFamily[]> {
    this.store$.dispatch(OrganizationMilestoneFamilyGeneratedActions.getManyOrganizationMilestoneFamilys({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert,
        OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure
      );
    }
  }

  public upsertOneOrganizationMilestoneFamily(
    organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>,
    ids: OrganizationMilestoneFamilyRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestoneFamily> {
    this.store$.dispatch(
      OrganizationMilestoneFamilyGeneratedActions.upsertOneOrganizationMilestoneFamily({
        organizationMilestoneFamily,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert,
        OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure,
        true
      );
    }
  }

  public deleteOneOrganizationMilestoneFamily(
    idOrganizationMilestoneFamily: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationMilestoneFamilyGeneratedActions.deleteOneOrganizationMilestoneFamily({
        idOrganizationMilestoneFamily
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneFamilyGeneratedActions.deleteOneOrganizationMilestoneFamilySuccess,
        OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure
      );
    }
  }

  public setActiveOrganizationMilestoneFamilys(idOrganizationMilestoneFamilys: number[]): void {
    this.store$.dispatch(OrganizationMilestoneFamilyGeneratedActions.clearActivesOrganizationMilestoneFamilys());
    this.store$.dispatch(
      OrganizationMilestoneFamilyGeneratedActions.addManyActivesOrganizationMilestoneFamilys({
        idOrganizationMilestoneFamilys
      })
    );
  }
}
