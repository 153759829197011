import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Organization, OrganizationFamily, OrganizationUser } from '@api/api-interfaces';
import { OrganizationFamilyOperationTypeEnum, OrganizationFamilyOperationTypeEnumTradEToFR } from '@enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries

interface IfilteredProjectFamilies {
  [x: string]: OrganizationFamily[];
}

@Component({
  selector: 'app-project-families-choices',
  templateUrl: './project-families-choices.component.html',
  styleUrls: ['./project-families-choices.component.scss']
})
export class ProjectFamiliesChoicesComponent implements OnChanges {
  @Input() filtersGroup: FormGroup;
  @Input() listProjectFamilies: OrganizationFamily[];
  @Input() organization: Organization;
  @Input() organizationUser: OrganizationUser;

  public filteredProjectFamilies: IfilteredProjectFamilies = {};
  public filteredOrganizationFamilies: OrganizationFamily[];
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['organization']) {
      const sections = Object.values(OrganizationFamilyOperationTypeEnum);
      this.filteredOrganizationFamilies = this.organization.organizationFamilys;
      if (!this.organizationUser.hasDevelopments) {
        this.filteredOrganizationFamilies = this.filteredOrganizationFamilies.filter(
          s => s.operationType !== OrganizationFamilyOperationTypeEnum.development
        );
      }
      if (!this.organizationUser.hasProjects) {
        this.filteredOrganizationFamilies = this.filteredOrganizationFamilies.filter(
          s => s.operationType !== OrganizationFamilyOperationTypeEnum.project
        );
      }

      sections.forEach(operationType => {
        const filteredProjectFamilies = this.organization.organizationFamilys.filter(el => {
          return el.operationType === operationType;
        });
        if (filteredProjectFamilies.length) {
          this.filteredProjectFamilies[OrganizationFamilyOperationTypeEnumTradEToFR[operationType]] =
            filteredProjectFamilies;
        }
      });
    }
  }
}
