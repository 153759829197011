import { RightProfilState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { rightProfilReducersGeneratedFunctions } from './right-profil-generated.reducer';

const rightProfilReducersFunctions = [...rightProfilReducersGeneratedFunctions];

const rightProfilReducer = createReducer(RightProfilState.initialState, ...rightProfilReducersFunctions);

export function reducer(state: RightProfilState.IState | undefined, action: Action) {
  return rightProfilReducer(state, action);
}
