import { TimelineElementState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { timelineElementReducersGeneratedFunctions } from './timeline-element-generated.reducer';

const timelineElementReducersFunctions = [...timelineElementReducersGeneratedFunctions];

const timelineElementReducer = createReducer(TimelineElementState.initialState, ...timelineElementReducersFunctions);

export function reducer(state: TimelineElementState.IState | undefined, action: Action) {
  return timelineElementReducer(state, action);
}
