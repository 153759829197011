import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationRisk, OrganizationRiskEntityState } from '@api/api-interfaces';
import { OrganizationRiskApiService } from '@wip/store/api-services';
import { OrganizationRiskGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationRisk } from '@wip/store/configs/normalization';
import { OrganizationRiskSelectors } from '@wip/store/selectors';
import { OrganizationRiskRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityRiskGeneratedActions } from '@wip/store/actions';
import { CommunityRisk } from '@api/api-interfaces';

export function getDefaultAddOrganizationRiskActions(
  organizationRisk: OrganizationRiskEntityState,
  ids?: OrganizationRiskRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationRiskGeneratedActions.normalizeManyOrganizationRisksAfterUpsert({
      organizationRisks: [organizationRisk]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationRiskSuccess({
        idOrganization: ids.organization,
        idOrganizationRisks: [organizationRisk.idOrganizationRisk]
      })
    );
    actions.push(
      OrganizationRiskGeneratedActions.addOrganizationSuccess({
        idOrganizationRisk: organizationRisk.idOrganizationRisk,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyOrganizationRiskSuccess({
        idOrganizationFamily: ids.organizationFamily,
        idOrganizationRisks: [organizationRisk.idOrganizationRisk]
      })
    );
    actions.push(
      OrganizationRiskGeneratedActions.addOrganizationFamilySuccess({
        idOrganizationRisk: organizationRisk.idOrganizationRisk,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.communityRisks) {
    if (!Array.isArray(ids.communityRisks)) {
      actions.push(
        CommunityRiskGeneratedActions.upsertOneCommunityRisk({
          communityRisk: {
            idOrganizationRisk: organizationRisk.idOrganizationRisk,
            idCommunityRisk: ids.communityRisks as number
          } as CommunityRisk
        })
      );
      actions.push(
        OrganizationRiskGeneratedActions.addManyCommunityRiskSuccess({
          idOrganizationRisk: organizationRisk.idOrganizationRisk,
          idCommunityRisks: [ids.communityRisks as number]
        })
      );
    } else {
      actions.push(
        CommunityRiskGeneratedActions.upsertManyCommunityRisks({
          communityRisks: (ids.communityRisks as number[]).map((idCommunityRisk: number) => ({
            idOrganizationRisk: organizationRisk.idOrganizationRisk,
            idCommunityRisk
          })) as CommunityRisk[]
        })
      );
      actions.push(
        OrganizationRiskGeneratedActions.addManyCommunityRiskSuccess({
          idOrganizationRisk: organizationRisk.idOrganizationRisk,
          idCommunityRisks: ids.communityRisks as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationRiskActions(organizationRisk: OrganizationRiskEntityState): Action[] {
  const actions: Action[] = [
    OrganizationRiskGeneratedActions.deleteOneOrganizationRiskSuccess({
      idOrganizationRisk: organizationRisk.idOrganizationRisk
    })
  ];

  if (organizationRisk.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationRiskSuccess({
        idOrganizationRisks: [organizationRisk.idOrganizationRisk],
        idOrganizations: [organizationRisk.organization as number]
      })
    );
  }

  if (organizationRisk.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationRiskSuccess({
        idOrganizationRisks: [organizationRisk.idOrganizationRisk],
        idOrganizationFamilys: [organizationRisk.organizationFamily as number]
      })
    );
  }

  if (organizationRisk.communityRisks) {
    actions.push(
      CommunityRiskGeneratedActions.deleteManyOrganizationRiskSuccess({
        idOrganizationRisks: [organizationRisk.idOrganizationRisk],
        idCommunityRisks: organizationRisk.communityRisks as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationRiskEffects {
  constructor(
    protected actions$: Actions,
    protected organizationRiskApiService: OrganizationRiskApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationRisks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationRiskGeneratedActions.getManyOrganizationRisks),
      switchMap(({ params }) =>
        this.organizationRiskApiService.getOrganizationRisks(params).pipe(
          map((organizationRisks: OrganizationRisk[]) => {
            return OrganizationRiskGeneratedActions.normalizeManyOrganizationRisksAfterUpsert({ organizationRisks });
          }),
          catchError(error => of(OrganizationRiskGeneratedActions.organizationRisksFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationRisk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationRiskGeneratedActions.getOneOrganizationRisk),
      switchMap(idOrganizationRisk =>
        this.organizationRiskApiService.getOrganizationRisk(idOrganizationRisk).pipe(
          map((organizationRisk: OrganizationRisk) => {
            return OrganizationRiskGeneratedActions.normalizeManyOrganizationRisksAfterUpsert({
              organizationRisks: [organizationRisk]
            });
          }),
          catchError(error => of(OrganizationRiskGeneratedActions.organizationRisksFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationRisk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationRiskGeneratedActions.upsertOneOrganizationRisk),
      concatMap(
        ({
          organizationRisk,
          ids
        }: {
          organizationRisk: Partial<OrganizationRisk>;
          ids?: OrganizationRiskRelationsIds;
        }) => {
          if (organizationRisk.idOrganizationRisk) {
            return this.organizationRiskApiService.updateOrganizationRisk(organizationRisk).pipe(
              map((organizationRiskReturned: OrganizationRisk) => {
                return OrganizationRiskGeneratedActions.normalizeManyOrganizationRisksAfterUpsert({
                  organizationRisks: [organizationRiskReturned]
                });
              }),
              catchError(error => of(OrganizationRiskGeneratedActions.organizationRisksFailure({ error })))
            );
          } else {
            return this.organizationRiskApiService.addOrganizationRisk(organizationRisk).pipe(
              mergeMap((organizationRiskReturned: OrganizationRisk) =>
                getDefaultAddOrganizationRiskActions(organizationRiskReturned, ids)
              ),
              catchError(error => of(OrganizationRiskGeneratedActions.organizationRisksFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationRisk$ = createEffect(() => {
    const selectOrganizationRiskState$ = this.store$.select(OrganizationRiskSelectors.selectOrganizationRiskState);
    return this.actions$.pipe(
      ofType(OrganizationRiskGeneratedActions.deleteOneOrganizationRisk),
      withLatestFrom(selectOrganizationRiskState$),
      concatMap(([{ idOrganizationRisk }, state]) =>
        this.organizationRiskApiService.deleteOrganizationRisk(idOrganizationRisk).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationRiskActions(state.entities[idOrganizationRisk] as OrganizationRiskEntityState)
          ),
          catchError(error => of(OrganizationRiskGeneratedActions.organizationRisksFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationRisksAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationRiskGeneratedActions.normalizeManyOrganizationRisksAfterUpsert),
      concatMap(({ organizationRisks }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationRisk(organizationRisks, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganizationRisk] Normalization After Upsert Success')];
      })
    );
  });
}
