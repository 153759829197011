import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationStepApiService } from '@wip/store/api-services';
import { GeneratedOrganizationStepEffects } from './organization-step-generated.effects';

@Injectable()
export class OrganizationStepEffects extends GeneratedOrganizationStepEffects {
  constructor(actions$: Actions, organizationStepApiService: OrganizationStepApiService, store$: Store<AppState>) {
    super(actions$, organizationStepApiService, store$);
  }
}
