import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationApiService } from '@wip/store/api-services';
import { GeneratedOrganizationEffects } from './organization-generated.effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Organization } from '@api/api-interfaces';
import * as organizationActions from '@wip/store/actions';
import { of } from 'rxjs';

@Injectable()
export class OrganizationEffects extends GeneratedOrganizationEffects {
  constructor(actions$: Actions, organizationApiService: OrganizationApiService, store$: Store<AppState>) {
    super(actions$, organizationApiService, store$);
  }

  getManyOrganizationsWithIdUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationActions.getManyOrganizationsWithIdUser),
      switchMap(params =>
        this.organizationApiService.getUserOrganizations(params.idUser).pipe(
          map((organizations: Organization[]) => {
            return organizationActions.OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert({
              organizations
            });
          }),
          catchError(error => of(organizationActions.OrganizationGeneratedActions.organizationsFailure({ error })))
        )
      )
    );
  });

  getManyOrganizationsForSynthesis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationActions.getManyOrganizationsForSynthesis),
      switchMap(params =>
        this.organizationApiService.getSynthesisOrganizations(params.idUser).pipe(
          map((organizations: Organization[]) => {
            return organizationActions.OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert({
              organizations
            });
          }),
          catchError(error => of(organizationActions.OrganizationGeneratedActions.organizationsFailure({ error })))
        )
      )
    );
  });

  getManyOrganizationsFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationActions.getOrganizationsFilters),
      switchMap(params =>
        this.organizationApiService.getOrganizationsFilters(params.idUser).pipe(
          map((organizations: Organization[]) => {
            return organizationActions.OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert({
              organizations
            });
          }),
          catchError(error => of(organizationActions.OrganizationGeneratedActions.organizationsFailure({ error })))
        )
      )
    );
  });
}
