<div class="searchBackground">
  <div>
    <app-search-users-directive
      [textPlaceholder]="'Responsables'"
      [fromGantt]="true"
      [canAddName]="true"
      [metaUsersSelected]="userInfo"
      [groupMetaUsersSuggestedOrigin]="suggestions"
      [add]="userInfo"
      [rmv]="[]"
      [displayList]="'dropDown'"
    />
  </div>
</div>
