import { RepositoryService } from '@wip/services/repository';
import { OrganizationKpi } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationKpiApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationKpis(params?: any): Observable<OrganizationKpi[]> {
    return this.repo.getData<OrganizationKpi[]>('organization-kpi', params);
  }

  public getOrganizationKpi(params: { idOrganizationKpi: number; params?: any }): Observable<OrganizationKpi> {
    return this.repo.getData<OrganizationKpi>('organization-kpi/' + params.idOrganizationKpi, params.params);
  }

  public addOrganizationKpi(organizationKpi: Partial<OrganizationKpi>): Observable<OrganizationKpi> {
    return this.repo.create<OrganizationKpi>('organization-kpi', organizationKpi);
  }

  public updateOrganizationKpi(organizationKpi: Partial<OrganizationKpi>): Observable<OrganizationKpi> {
    return this.repo.update('organization-kpi', organizationKpi);
  }

  public deleteOrganizationKpi(idOrganizationKpi: number): Observable<number> {
    return this.repo.delete('organization-kpi/' + +idOrganizationKpi);
  }
}
