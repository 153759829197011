import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Folder } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { FolderRelationsIds } from '@wip/store/ids-interfaces';
import { FolderGeneratedActions } from '@wip/store/actions';
import { FolderSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedFolderService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(FolderSelectors.selectIsLoadedFolder));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(FolderSelectors.selectIsLoadingFolder));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [FolderSelectors.selectIsReadyAndLoadedFolder as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllFolders(schema: SelectSchema = {}): Observable<Folder[]> {
    return this.store$.pipe(select(FolderSelectors.selectAllFolders(schema))).pipe(
      switchMap(({ folders }: { folders: Folder[] }) => {
        return this.getReady(schema).pipe(mapTo(folders));
      })
    );
  }

  public selectOneFolder(idFolder: number, schema: SelectSchema = {}): Observable<Folder> {
    return this.store$.pipe(select(FolderSelectors.selectOneFolder(schema, idFolder))).pipe(
      switchMap(({ folder }: { folder: Folder }) => {
        return this.getReady(schema).pipe(mapTo(folder));
      })
    );
  }

  public selectAllActiveFolders(schema: SelectSchema = {}): Observable<Folder[]> {
    return this.store$.pipe(select(FolderSelectors.selectActiveFolders(schema))).pipe(
      switchMap(({ folders }: { folders: Folder[] }) => {
        return this.getReady(schema).pipe(mapTo(folders));
      })
    );
  }

  public selectIdFoldersActive(): Observable<number[]> {
    return this.store$.pipe(select(FolderSelectors.selectIdFoldersActive)).pipe(
      switchMap((idFolders: number[]) => {
        return this.getReady().pipe(mapTo(idFolders));
      })
    );
  }

  public getOneFolder(idFolder: number, params: any = {}, getResult?: boolean): void | Observable<Folder> {
    this.store$.dispatch(FolderGeneratedActions.getOneFolder({ idFolder, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FolderGeneratedActions.normalizeManyFoldersAfterUpsert,
        FolderGeneratedActions.foldersFailure,
        true
      );
    }
  }

  public getManyFolders(params: any = {}, getResult?: boolean): void | Observable<Folder[]> {
    this.store$.dispatch(FolderGeneratedActions.getManyFolders({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FolderGeneratedActions.normalizeManyFoldersAfterUpsert,
        FolderGeneratedActions.foldersFailure
      );
    }
  }

  public upsertOneFolder(
    folder: Partial<Folder>,
    ids: FolderRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Folder> {
    this.store$.dispatch(FolderGeneratedActions.upsertOneFolder({ folder, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FolderGeneratedActions.normalizeManyFoldersAfterUpsert,
        FolderGeneratedActions.foldersFailure,
        true
      );
    }
  }

  public deleteOneFolder(idFolder: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(FolderGeneratedActions.deleteOneFolder({ idFolder }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FolderGeneratedActions.deleteOneFolderSuccess,
        FolderGeneratedActions.foldersFailure
      );
    }
  }

  public setActiveFolders(idFolders: number[]): void {
    this.store$.dispatch(FolderGeneratedActions.clearActivesFolders());
    this.store$.dispatch(FolderGeneratedActions.addManyActivesFolders({ idFolders }));
  }
}
