import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityModule, CommunityModuleEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { ProjectModule, ProjectModuleEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityModuleState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityModuleRelations: string[] = ['communities', 'projectModules'];

export const { selectEntities, selectAll } = CommunityModuleState.adapter.getSelectors();

export const selectCommunityModuleState = createFeatureSelector<CommunityModuleState.IState>(
  CommunityModuleState.communityModuleFeatureKey
);

export const selectIsLoadedCommunityModule = createSelector(
  selectCommunityModuleState,
  (state: CommunityModuleState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityModule = createSelector(
  selectCommunityModuleState,
  (state: CommunityModuleState.IState) => state.isLoading
);

export const selectIsReadyCommunityModule = createSelector(
  selectCommunityModuleState,
  (state: CommunityModuleState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityModule = createSelector(
  selectCommunityModuleState,
  (state: CommunityModuleState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityModulesEntities = createSelector(selectCommunityModuleState, selectEntities);

export const selectCommunityModulesArray = createSelector(selectCommunityModuleState, selectAll);

export const selectIdCommunityModulesActive = createSelector(
  selectCommunityModuleState,
  (state: CommunityModuleState.IState) => state.actives
);

const communityModulesInObject = (communityModules: Dictionary<CommunityModuleEntityState>) => ({ communityModules });

const selectCommunityModulesEntitiesDictionary = createSelector(
  selectCommunityModulesEntities,
  communityModulesInObject
);

const selectAllCommunityModulesObject = createSelector(selectCommunityModulesEntities, communityModules => {
  return hydrateAll({ communityModules });
});

const selectOneCommunityModuleDictionary = (idCommunityModule: number) =>
  createSelector(selectCommunityModulesEntities, communityModules => ({
    communityModules: { [idCommunityModule]: communityModules[idCommunityModule] }
  }));

const selectOneCommunityModuleDictionaryWithoutChild = (idCommunityModule: number) =>
  createSelector(selectCommunityModulesEntities, communityModules => ({
    communityModule: communityModules[idCommunityModule]
  }));

const selectActiveCommunityModulesEntities = createSelector(
  selectIdCommunityModulesActive,
  selectCommunityModulesEntities,
  (actives: number[], communityModules: Dictionary<CommunityModuleEntityState>) =>
    getCommunityModulesFromActives(actives, communityModules)
);

function getCommunityModulesFromActives(
  actives: number[],
  communityModules: Dictionary<CommunityModuleEntityState>
): Dictionary<CommunityModuleEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityModules[idActive]) {
      acc[idActive] = communityModules[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityModuleEntityState>);
}

const selectAllCommunityModulesSelectors: Dictionary<Selector> = {};
export function selectAllCommunityModules(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityModule>(
      schema,
      selectAllCommunityModulesSelectors,
      selectCommunityModulesEntitiesDictionary,
      getRelationSelectors,
      communityModuleRelations,
      hydrateAll,
      'communityModule'
    );
  } else {
    return selectAllCommunityModulesObject;
  }
}

export function selectAllCommunityModulesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityModules'
): Selector {
  return createSelector(selectAllCommunityModules(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityModuleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityModules.length; i++) {
      res[customKey][result.communityModules[i].idCommunityModule] = result.communityModules[i];
    }
    return res;
  });
}

export function selectOneCommunityModule(schema: SelectSchema = {}, idCommunityModule: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityModuleDictionary(idCommunityModule)];
    selectors.push(...getRelationSelectors(schema, communityModuleRelations, 'communityModule'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityModuleDictionaryWithoutChild(idCommunityModule);
  }
}

export function selectActiveCommunityModules(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityModulesEntities, communityModules => ({
      communityModules
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityModuleRelations, 'communityModule'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityModules: Dictionary<CommunityModuleEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  projectModules?: Dictionary<ProjectModuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityModules: (CommunityModule | null)[] } {
  const { communityModules, communities, projectModules } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communityModules: Object.keys(communityModules).map(idCommunityModule =>
      hydrate(communityModules[idCommunityModule] as CommunityModuleEntityState, communities, projectModules)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityModule: CommunityModuleEntityState | null } {
  const { communityModules, communities, projectModules } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communityModule = Object.values(communityModules)[0];
  return {
    communityModule: hydrate(communityModule as CommunityModuleEntityState, communities, projectModules)
  };
}

function hydrate(
  communityModule: CommunityModuleEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  projectModuleEntities?: Dictionary<ProjectModuleEntityState>
): CommunityModule | null {
  if (!communityModule) {
    return null;
  }

  const communityModuleHydrated: CommunityModuleEntityState = { ...communityModule };
  if (communityEntities) {
    communityModuleHydrated.community = communityEntities[communityModule.community as number] as Community;
  } else {
    delete communityModuleHydrated.community;
  }
  if (projectModuleEntities) {
    communityModuleHydrated.projectModule = projectModuleEntities[
      communityModule.projectModule as number
    ] as ProjectModule;
  } else {
    delete communityModuleHydrated.projectModule;
  }

  return communityModuleHydrated as CommunityModule;
}
