import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedFolderElementApiService } from './folder-element-api-generated.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { ConfigurationApiService } from 'libs/api/old-api-services/src';

@Injectable({
  providedIn: 'root'
})
export class FolderElementApiService extends GeneratedFolderElementApiService {
  constructor(
    repo: RepositoryService,
    private configurationApi: ConfigurationApiService,
    private http: HttpClient
  ) {
    super(repo);
  }

  public downloadFile(idFolderElement: number, fileName: string) {
    return this.http
      .get(this.configurationApi.fullAddressV2 + '/folder-element/downloadFile/' + idFolderElement, {
        responseType: 'blob'
      })
      .subscribe((blob: any) => {
        saveAs(blob, fileName);
      });
  }
}
