import { Injectable } from '@angular/core';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedOrganizationMilestoneFamilyApiService } from './organization-milestone-family-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationMilestoneFamilyApiService extends GeneratedOrganizationMilestoneFamilyApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateOrganizationMilestoneFamilySelected(
    organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>
  ): Observable<OrganizationMilestoneFamily[]> {
    return this.repo.update<OrganizationMilestoneFamily[]>(
      'organization-milestone-family',
      organizationMilestoneFamily
    );
  }

  public upsertOneOrganizationFamilyWithMilestones(organizationMilestoneFamily: {
    idOrganizationMilestoneFamily: number;
    idsMilestones: number | number[];
  }): Observable<OrganizationMilestoneFamily> {
    return this.repo.update('organization-milestone-family/updateWithMilestones', organizationMilestoneFamily);
  }
}
