import { AgGridUtils } from '@_app/utils/ag-grid/cell-formatter/ag-grid.utils';
import AgGridCellCommonFormatter from '@_app/utils/ag-grid/cell-formatter/common-cell-formatter';
import { ColDef, ColGroupDef, ValueFormatterParams } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { OrganizationDataColumn } from '@api/api-interfaces';
import { CaracteristiqueTypeEnum } from '@enums';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ProjectGridDataService {
  public createDataColumnDefsFormat(columns: OrganizationDataColumn[]): ColGroupDef {
    const cols: ColDef[] = columns
      .sort((a, b) => a.ordre - b.ordre)
      .map(col => {
        const type = col.type;
        const ret: ColDef = {
          headerName: col.libelle,
          resizable: false,
          enableRowGroup: true,
          colId: col.idOrganizationDataColumn.toString() + '-rep',
          field: col.idOrganizationDataColumn.toString() + '-rep',
          width: col.largeur,
          cellClass:
            type === CaracteristiqueTypeEnum.decimal || type === CaracteristiqueTypeEnum.integer
              ? 'ag-align-right-placeholder-10'
              : '',
          valueGetter: this.getValue,
          valueSetter: this.setValue,
          valueFormatter: (params: ValueFormatterParams): string => {
            if (
              (type === CaracteristiqueTypeEnum.decimal || type === CaracteristiqueTypeEnum.integer) &&
              params.value
            ) {
              return AgGridCellCommonFormatter.formatNumberWithSpace(params.value);
            } else if (type === CaracteristiqueTypeEnum.choix) {
              return col.organizationDataColumnValues.find(val => val.idOrganizationDataColumnValue === params.value)
                ?.value;
            } else if (type === CaracteristiqueTypeEnum.date) {
              if (params.value) {
                return AgGridCellCommonFormatter.formatDateOrNull(params.value);
              } else {
                return '';
              }
            } else {
              return params.value;
            }
          }
        };
        if (type === CaracteristiqueTypeEnum.decimal || type === CaracteristiqueTypeEnum.integer) {
          ret.aggFunc = 'sum';
        }
        if (type === CaracteristiqueTypeEnum.choix && col.organizationDataColumnValues?.length) {
          ret.cellEditor = 'agRichSelectCellEditor';
          ret.cellEditorParams = (_): any => {
            return {
              values: col.organizationDataColumnValues.map(c => c.idOrganizationDataColumnValue),
              formatValue: data =>
                col.organizationDataColumnValues.find(c => c.idOrganizationDataColumnValue === data)?.value
            };
          };
        } else if (type === CaracteristiqueTypeEnum.date) {
          ret.cellEditor = AgGridUtils.NativeDatePickerEditor;
        }
        return ret;
      });
    return {
      headerName: 'Répartition des lots',
      children: cols
    };
  }

  private getValue(params) {
    return params.data ? params.data[params.column.colId]?.value : null;
  }

  private setValue(params) {
    params.data[params.column.colId] = { ...params.data[params.column.colId], value: params.newValue };
    return true;
  }
}
