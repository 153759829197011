import { createEntityAdapter } from '@ngrx/entity';
import { CommunityEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<CommunityEntityState>;

export const adapter = createEntityAdapter<CommunityEntityState>({
  selectId: o => o.idCommunity
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const communityFeatureKey = 'community';
