import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedUserService } from './user-generated.service';
import { Actions } from '@ngrx/effects';
import { User } from '@api/api-interfaces';
import { Observable } from 'rxjs';
import * as UserAction from '@wip/store/actions';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { parseJwt } from '@utils';

@Injectable({
  providedIn: 'root'
})
export class UserService extends GeneratedUserService {
  private _currentIdUser: number;
  public _token: string;
  public _isRoot: boolean;

  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public getCurrentUser(idUser: number, getResult?: boolean): Observable<User[]> | undefined {
    this.store$.dispatch(UserAction.getCurrentUser({ idUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserAction.UserGeneratedActions.normalizeManyUsersAfterUpsert,
        UserAction.UserGeneratedActions.usersFailure
      );
    }
  }

  public get currentIdUser() {
    if (!this._currentIdUser) {
      const token = JSON.parse(localStorage.getItem('login') || '{}').token;
      if (token) {
        this._currentIdUser = parseJwt(token)?.idUser;
      }
    }

    return this._currentIdUser;
  }

  public set currentIdUser(idUser: number) {
    this._currentIdUser = idUser;
  }

  public get token() {
    return JSON.parse(localStorage.getItem('login') || '{}')?.token;
  }

  public get isRoot() {
    return this._isRoot;
  }

  public set isRoot(isRoot: boolean) {
    this._isRoot = isRoot;
  }
}
