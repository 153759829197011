import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { NotesHistoryGeneratedActions } from '@wip/store/actions';
import { NotesHistoryState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { NotesHistoryEntityState } from '@api/api-interfaces';

export const notesHistoryReducersGeneratedFunctions: ReducerTypes<
  NotesHistoryState.IState,
  readonly ActionCreator[]
>[] = [
  on(NotesHistoryGeneratedActions.getOneNotesHistory, (state: NotesHistoryState.IState) =>
    setLoadingsState(state, true)
  ),
  on(NotesHistoryGeneratedActions.getManyNotesHistories, (state: NotesHistoryState.IState) =>
    setLoadingsState(state, true)
  ),
  on(NotesHistoryGeneratedActions.upsertOneNotesHistory, (state: NotesHistoryState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    NotesHistoryGeneratedActions.upsertManyNotesHistoriesSuccess,
    (state: NotesHistoryState.IState, { notesHistories }) =>
      NotesHistoryState.adapter.upsertMany(notesHistories, setLoadingsState(state, false))
  ),
  on(NotesHistoryGeneratedActions.deleteOneNotesHistory, (state: NotesHistoryState.IState) =>
    setLoadingsState(state, true)
  ),
  on(NotesHistoryGeneratedActions.deleteOneNotesHistorySuccess, (state: NotesHistoryState.IState, { idNotesHistory }) =>
    NotesHistoryState.adapter.removeOne(idNotesHistory, setLoadingsState(state, false))
  ),
  on(NotesHistoryGeneratedActions.clearActivesNotesHistories, (state: NotesHistoryState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    NotesHistoryGeneratedActions.addManyActivesNotesHistories,
    (state: NotesHistoryState.IState, { idNotesHistories }) => ({
      ...state,
      actives: state.actives.concat(idNotesHistories)
    })
  ),
  on(
    NotesHistoryGeneratedActions.deleteOneActiveNotesHistory,
    (state: NotesHistoryState.IState, { idNotesHistory }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idNotesHistory)
    })
  ),

  on(NotesHistoryGeneratedActions.clearNotesHistories, () => NotesHistoryState.initialState),

  on(
    NotesHistoryGeneratedActions.addCommunitySuccess,
    (state: NotesHistoryState.IState, { idNotesHistory, idCommunity }) => {
      if (!state.entities[idNotesHistory]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idNotesHistory]: {
            ...state.entities[idNotesHistory],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    NotesHistoryGeneratedActions.deleteManyCommunitySuccess,
    (state: NotesHistoryState.IState, { idCommunities, idNotesHistories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idNotesHistories.reduce((entities, idNotesHistory) => {
            if (!state.entities[idNotesHistory]?.community) {
              return entities;
            }
            entities[idNotesHistory] = {
              ...state.entities[idNotesHistory],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idNotesHistory]?.community
              )
                ? undefined
                : state.entities[idNotesHistory]?.community
            } as NotesHistoryEntityState;
            return entities;
          }, {} as Dictionary<NotesHistoryEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: NotesHistoryState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): NotesHistoryState.IState {
  return { ...state, isLoaded, isLoading };
}
