import { createAction, props } from '@ngrx/store';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import { OrganizationMilestoneFamilyRelationsIds } from '@wip/store/ids-interfaces';

export * from './organization-milestone-family-generated.actions';

export const upsertOneOrganizationMilestoneFamilySelected = createAction(
  '[OrganizationMilestoneFamily] Upsert One OrganizationMilestoneFamily Selected',
  props<{
    organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>;
    ids?: OrganizationMilestoneFamilyRelationsIds;
  }>()
);

export const upsertOneOrganizationFamilyWithMilestones = createAction(
  '[OrganizationMilestone] Upsert One OrganizationMilestoneFamily With Milestones',
  props<{
    organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>;
    ids?: OrganizationMilestoneFamilyRelationsIds;
  }>()
);
