<div class="margin-bottom">
  <h2 class="title-synthesis">Equipe {{ wordingTypeStructure | lowercase }}</h2>
</div>

<ul class="liste">
  <div style="display: flex; flex-direction: column">
    @for (communityUserProfil of communityUserProfil; track communityUserProfil) {
      <li class="user-select" [class.active]="communityUserProfil.selected">
        @if (communityUserProfil?.selected) {
          <i class="icon icon-ic_fluent_star_24_filled icon-star"></i>
        }
        {{
          communityUserProfil?.libelle
            ? communityUserProfil?.libelle
            : communityUserProfil?.organizationUserProfil?.libelle
        }}
        :
        @if (communityUserProfil?.user) {
          <span class="bold user-select">
            &nbsp; {{ communityUserProfil?.user?.prenom }} {{ communityUserProfil?.user?.nom }}</span
          >
        }
        &nbsp;
        @if (!communityUserProfil?.user?.nom) {
          <div>
            @if (canEdit) {
              <div class="cursor-pointer" (click)="addTeam()" [matTooltip]="'Compléter équipe projet'">
                <i class="icon icon-ic_fluent_arrow_circle_right_24_filled go-team"></i>
              </div>
            }
          </div>
        }
      </li>
    }
  </div>
</ul>
