import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cycle-found-dialog',
  templateUrl: 'cycle-found-dialog.component.html'
})
export class CycleFoundDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.data.ganttTasks.sort((a, b) => a.index - b.index);
  }
}
