import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommunitySubFamilyApiService } from '@wip/store/api-services';
import { GeneratedCommunitySubFamilyEffects } from './community-sub-family-generated.effects';

@Injectable()
export class CommunitySubFamilyEffects extends GeneratedCommunitySubFamilyEffects {
  constructor(actions$: Actions, communitySubFamilyApiService: CommunitySubFamilyApiService, store$: Store<AppState>) {
    super(actions$, communitySubFamilyApiService, store$);
  }
}
