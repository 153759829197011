@if (displayData && excelStyles) {
  <wip-grid
    [excelStyles]="excelStyles"
    [datas]="datas$ | async"
    [listCaracteristics]="(organizationCaracteristics$ | async) || []"
    [listOrganizationUserProfils]="(organizationUserProfils$ | async) || []"
    [listProject]="(communities$ | async) || []"
    [listOrganizationMilestones]="(organizationMilestones$ | async) || []"
    [idOrganizationMilestone]="idOrganizationMilestone"
    [idOrganization]="idOrganization"
    [idOrganizationFamily]="idOrganizationFamily"
    [idOrganizationCaracteristic]="idOrganizationCaracteristic"
    [isLoading]="isLoading"
  />
}
@if (!displayData) {
  <div class="flex justify-center" style="margin-top: 25px">Choisissez un planning</div>
}
