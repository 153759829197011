import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { ProjectModule, ProjectModuleEntityState } from '@api/api-interfaces';
import { ProjectModuleApiService } from '@wip/store/api-services';
import { ProjectModuleGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeProjectModule } from '@wip/store/configs/normalization';
import { ProjectModuleSelectors } from '@wip/store/selectors';
import { ProjectModuleRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityModuleGeneratedActions } from '@wip/store/actions';
import { CommunityModule } from '@api/api-interfaces';
import { OrganizationProjectModuleGeneratedActions } from '@wip/store/actions';
import { OrganizationProjectModule } from '@api/api-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { Community } from '@api/api-interfaces';

export function getDefaultAddProjectModuleActions(
  projectModule: ProjectModuleEntityState,
  ids?: ProjectModuleRelationsIds
): Action[] {
  const actions: Action[] = [
    ProjectModuleGeneratedActions.normalizeManyProjectModulesAfterUpsert({ projectModules: [projectModule] })
  ];

  if (ids?.communityModules) {
    if (!Array.isArray(ids.communityModules)) {
      actions.push(
        CommunityModuleGeneratedActions.upsertOneCommunityModule({
          communityModule: {
            idProjectModule: projectModule.idProjectModule,
            idCommunityModule: ids.communityModules as number
          } as CommunityModule
        })
      );
      actions.push(
        ProjectModuleGeneratedActions.addManyCommunityModuleSuccess({
          idProjectModule: projectModule.idProjectModule,
          idCommunityModules: [ids.communityModules as number]
        })
      );
    } else {
      actions.push(
        CommunityModuleGeneratedActions.upsertManyCommunityModules({
          communityModules: (ids.communityModules as number[]).map((idCommunityModule: number) => ({
            idProjectModule: projectModule.idProjectModule,
            idCommunityModule
          })) as CommunityModule[]
        })
      );
      actions.push(
        ProjectModuleGeneratedActions.addManyCommunityModuleSuccess({
          idProjectModule: projectModule.idProjectModule,
          idCommunityModules: ids.communityModules as number[]
        })
      );
    }
  }

  if (ids?.organizationProjectModules) {
    if (!Array.isArray(ids.organizationProjectModules)) {
      actions.push(
        OrganizationProjectModuleGeneratedActions.upsertOneOrganizationProjectModule({
          organizationProjectModule: {
            idProjectModule: projectModule.idProjectModule,
            idOrganizationProjectModule: ids.organizationProjectModules as number
          } as OrganizationProjectModule
        })
      );
      actions.push(
        ProjectModuleGeneratedActions.addManyOrganizationProjectModuleSuccess({
          idProjectModule: projectModule.idProjectModule,
          idOrganizationProjectModules: [ids.organizationProjectModules as number]
        })
      );
    } else {
      actions.push(
        OrganizationProjectModuleGeneratedActions.upsertManyOrganizationProjectModules({
          organizationProjectModules: (ids.organizationProjectModules as number[]).map(
            (idOrganizationProjectModule: number) => ({
              idProjectModule: projectModule.idProjectModule,
              idOrganizationProjectModule
            })
          ) as OrganizationProjectModule[]
        })
      );
      actions.push(
        ProjectModuleGeneratedActions.addManyOrganizationProjectModuleSuccess({
          idProjectModule: projectModule.idProjectModule,
          idOrganizationProjectModules: ids.organizationProjectModules as number[]
        })
      );
    }
  }

  if (ids?.communities) {
    if (!Array.isArray(ids.communities)) {
      actions.push(
        CommunityGeneratedActions.upsertOneCommunity({
          community: {
            idProjectModule: projectModule.idProjectModule,
            idCommunity: ids.communities as number
          } as Community & any
        })
      );
      actions.push(
        ProjectModuleGeneratedActions.addManyCommunitySuccess({
          idProjectModule: projectModule.idProjectModule,
          idCommunities: [ids.communities as number]
        })
      );
    } else {
      actions.push(
        CommunityGeneratedActions.upsertManyCommunities({
          communities: (ids.communities as number[]).map((idCommunity: number) => ({
            idProjectModule: projectModule.idProjectModule,
            idCommunity
          })) as Community[] & any[]
        })
      );
      actions.push(
        ProjectModuleGeneratedActions.addManyCommunitySuccess({
          idProjectModule: projectModule.idProjectModule,
          idCommunities: ids.communities as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProjectModuleActions(projectModule: ProjectModuleEntityState): Action[] {
  const actions: Action[] = [
    ProjectModuleGeneratedActions.deleteOneProjectModuleSuccess({ idProjectModule: projectModule.idProjectModule })
  ];

  if (projectModule.communityModules) {
    actions.push(
      CommunityModuleGeneratedActions.deleteManyProjectModuleSuccess({
        idProjectModules: [projectModule.idProjectModule],
        idCommunityModules: projectModule.communityModules as number[]
      })
    );
  }

  if (projectModule.organizationProjectModules) {
    actions.push(
      OrganizationProjectModuleGeneratedActions.deleteManyProjectModuleSuccess({
        idProjectModules: [projectModule.idProjectModule],
        idOrganizationProjectModules: projectModule.organizationProjectModules as number[]
      })
    );
  }

  if (projectModule.communities) {
    actions.push(
      CommunityGeneratedActions.deleteManyProjectModuleSuccess({
        idProjectModules: [projectModule.idProjectModule],
        idCommunities: projectModule.communities as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProjectModuleEffects {
  constructor(
    protected actions$: Actions,
    protected projectModuleApiService: ProjectModuleApiService,
    protected store$: Store<AppState>
  ) {}

  getManyProjectModules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectModuleGeneratedActions.getManyProjectModules),
      switchMap(({ params }) =>
        this.projectModuleApiService.getProjectModules(params).pipe(
          map((projectModules: ProjectModule[]) => {
            return ProjectModuleGeneratedActions.normalizeManyProjectModulesAfterUpsert({ projectModules });
          }),
          catchError(error => of(ProjectModuleGeneratedActions.projectModulesFailure({ error })))
        )
      )
    );
  });

  getOneProjectModule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectModuleGeneratedActions.getOneProjectModule),
      switchMap(idProjectModule =>
        this.projectModuleApiService.getProjectModule(idProjectModule).pipe(
          map((projectModule: ProjectModule) => {
            return ProjectModuleGeneratedActions.normalizeManyProjectModulesAfterUpsert({
              projectModules: [projectModule]
            });
          }),
          catchError(error => of(ProjectModuleGeneratedActions.projectModulesFailure({ error })))
        )
      )
    );
  });

  upsertOneProjectModule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectModuleGeneratedActions.upsertOneProjectModule),
      concatMap(
        ({ projectModule, ids }: { projectModule: Partial<ProjectModule>; ids?: ProjectModuleRelationsIds }) => {
          if (projectModule.idProjectModule) {
            return this.projectModuleApiService.updateProjectModule(projectModule).pipe(
              map((projectModuleReturned: ProjectModule) => {
                return ProjectModuleGeneratedActions.normalizeManyProjectModulesAfterUpsert({
                  projectModules: [projectModuleReturned]
                });
              }),
              catchError(error => of(ProjectModuleGeneratedActions.projectModulesFailure({ error })))
            );
          } else {
            return this.projectModuleApiService.addProjectModule(projectModule).pipe(
              mergeMap((projectModuleReturned: ProjectModule) =>
                getDefaultAddProjectModuleActions(projectModuleReturned, ids)
              ),
              catchError(error => of(ProjectModuleGeneratedActions.projectModulesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProjectModule$ = createEffect(() => {
    const selectProjectModuleState$ = this.store$.select(ProjectModuleSelectors.selectProjectModuleState);
    return this.actions$.pipe(
      ofType(ProjectModuleGeneratedActions.deleteOneProjectModule),
      withLatestFrom(selectProjectModuleState$),
      concatMap(([{ idProjectModule }, state]) =>
        this.projectModuleApiService.deleteProjectModule(idProjectModule).pipe(
          mergeMap(_success =>
            getDefaultDeleteProjectModuleActions(state.entities[idProjectModule] as ProjectModuleEntityState)
          ),
          catchError(error => of(ProjectModuleGeneratedActions.projectModulesFailure({ error })))
        )
      )
    );
  });

  normalizeManyProjectModulesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectModuleGeneratedActions.normalizeManyProjectModulesAfterUpsert),
      concatMap(({ projectModules }) => {
        const actions: Action[] = getActionsToNormalizeProjectModule(projectModules, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProjectModule] Normalization After Upsert Success')];
      })
    );
  });
}
