import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { SelectSchema, Selector } from '@wip/store/utils';

@Injectable({
  providedIn: 'root'
})
export class NgrxHelperService {
  constructor() {}

  public static hydrateChild<T>(childId: number, childStateEntities: Dictionary<T> = {}): T {
    return childStateEntities[childId] as T;
  }

  /**
   *  Permet de récuper les entitées complètes grace à leur Ids
   * @static
   * @template T
   * @param {number[]} [childrenIds=[]]
   * @param {Dictionary<T>} [childrenStateEntities={}]
   * @returns {T[]}
   * @memberof NgrxHelperService
   */
  public static hydrateChildren<T>(childrenIds: number[] = [], childrenStateEntities: Dictionary<T> = {}): T[] {
    return childrenIds.map(id => childrenStateEntities[id] as T);
  }
}

/**
 * Hash an object and convert it to a number
 */
function hashSchema(schema: SelectSchema): number {
  let hash = 0;
  const strSchema: string = JSON.stringify(schema);
  const schemaLength: number = strSchema.length;

  if (schemaLength === 0) {
    return hash;
  }

  for (let i = 0; i < schemaLength; i++) {
    const char = strSchema.charCodeAt(i);
    // tslint:disable-next-line: no-bitwise
    hash = (hash << 5) - hash + char;
    // tslint:disable-next-line: no-bitwise
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash;
}

/**
 * On récupère les selecteurs correspondant au schema
 */
export function findOrCreateSelector<T>(
  schema: SelectSchema,
  selectorsDictionary: Dictionary<Selector>,
  selectEntitiesDictionary: MemoizedSelector<any, any>,
  getRelationSelectors: (schema: SelectSchema, relations: string[], entity: string) => Selector[],
  entityRelations: string[],
  hydrateAll: (...args: any[]) => { [entityName: string]: (T | null)[] },
  customKey: string
): Selector {
  const schemaHash: number = hashSchema(schema);
  if (!selectorsDictionary[schemaHash]) {
    const selectors: Selector[] = [selectEntitiesDictionary];
    selectors.push(...getRelationSelectors(schema, entityRelations, customKey));
    selectorsDictionary[schemaHash] = (createSelector as any)(...selectors, hydrateAll);
  }
  return selectorsDictionary[schemaHash] as Selector;
}
