import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserGeneratedActions } from '@wip/store/actions';
import { UserState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserEntityState } from '@api/api-interfaces';

export const userReducersGeneratedFunctions: ReducerTypes<UserState.IState, readonly ActionCreator[]>[] = [
  on(UserGeneratedActions.getOneUser, (state: UserState.IState) => setLoadingsState(state, true)),
  on(UserGeneratedActions.getManyUsers, (state: UserState.IState) => setLoadingsState(state, true)),
  on(UserGeneratedActions.upsertOneUser, (state: UserState.IState) => setLoadingsState(state, true)),

  on(UserGeneratedActions.upsertManyUsersSuccess, (state: UserState.IState, { users }) =>
    UserState.adapter.upsertMany(users, setLoadingsState(state, false))
  ),
  on(UserGeneratedActions.deleteOneUser, (state: UserState.IState) => setLoadingsState(state, true)),
  on(UserGeneratedActions.deleteOneUserSuccess, (state: UserState.IState, { idUser }) =>
    UserState.adapter.removeOne(idUser, setLoadingsState(state, false))
  ),
  on(UserGeneratedActions.clearActivesUsers, (state: UserState.IState) => ({ ...state, actives: [] })),
  on(UserGeneratedActions.addManyActivesUsers, (state: UserState.IState, { idUsers }) => ({
    ...state,
    actives: state.actives.concat(idUsers)
  })),
  on(UserGeneratedActions.deleteOneActiveUser, (state: UserState.IState, { idUser }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idUser)
  })),

  on(UserGeneratedActions.clearUsers, () => UserState.initialState),

  on(
    UserGeneratedActions.addManyCommunityUserProfilSuccess,
    (state: UserState.IState, { idUser, idCommunityUserProfils }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const communityUserProfils = (state.entities[idUser]?.communityUserProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            communityUserProfils: communityUserProfils.concat(
              idCommunityUserProfils.filter(id => communityUserProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyCommunityUserProfilSuccess,
    (state: UserState.IState, { idCommunityUserProfils, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.communityUserProfils) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              communityUserProfils: (state.entities[idUser]?.communityUserProfils as number[])?.filter(
                (idCommunityUserProfil: number) =>
                  !idCommunityUserProfils.some((id: number) => id === idCommunityUserProfil)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(UserGeneratedActions.addManyUserGroupSuccess, (state: UserState.IState, { idUser, idUserGroups }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const userGroups = (state.entities[idUser]?.userGroups as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          userGroups: userGroups.concat(idUserGroups.filter(id => userGroups.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserGeneratedActions.deleteManyUserGroupSuccess, (state: UserState.IState, { idUserGroups, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.userGroups) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            userGroups: (state.entities[idUser]?.userGroups as number[])?.filter(
              (idUserGroup: number) => !idUserGroups.some((id: number) => id === idUserGroup)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(UserGeneratedActions.addManyCommunityUserSuccess, (state: UserState.IState, { idUser, idCommunityUsers }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const communityUsers = (state.entities[idUser]?.communityUsers as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          communityUsers: communityUsers.concat(idCommunityUsers.filter(id => communityUsers.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserGeneratedActions.deleteManyCommunityUserSuccess, (state: UserState.IState, { idCommunityUsers, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.communityUsers) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            communityUsers: (state.entities[idUser]?.communityUsers as number[])?.filter(
              (idCommunityUser: number) => !idCommunityUsers.some((id: number) => id === idCommunityUser)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(
    UserGeneratedActions.addManyOrganizationUserSuccess,
    (state: UserState.IState, { idUser, idOrganizationUsers }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const organizationUsers = (state.entities[idUser]?.organizationUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            organizationUsers: organizationUsers.concat(
              idOrganizationUsers.filter(id => organizationUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyOrganizationUserSuccess,
    (state: UserState.IState, { idOrganizationUsers, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.organizationUsers) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              organizationUsers: (state.entities[idUser]?.organizationUsers as number[])?.filter(
                (idOrganizationUser: number) => !idOrganizationUsers.some((id: number) => id === idOrganizationUser)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(UserGeneratedActions.addUserDeviceSuccess, (state: UserState.IState, { idUser, idUserDevice }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          userDevice: idUserDevice
        }
      }
    };
  }),

  on(UserGeneratedActions.deleteUserDeviceSuccess, (state: UserState.IState, { idUserDevices, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.userDevice) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            userDevice: idUserDevices.some((idUserDevice: number) => idUserDevice === state.entities[idUser].userDevice)
              ? undefined
              : state.entities[idUser].userDevice
          };
          return entities;
        }, {})
      }
    };
  }),
  on(UserGeneratedActions.addManyMeetingUserSuccess, (state: UserState.IState, { idUser, idMeetingUsers }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const meetingUsers = (state.entities[idUser]?.meetingUsers as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          meetingUsers: meetingUsers.concat(idMeetingUsers.filter(id => meetingUsers.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserGeneratedActions.deleteManyMeetingUserSuccess, (state: UserState.IState, { idMeetingUsers, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.meetingUsers) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            meetingUsers: (state.entities[idUser]?.meetingUsers as number[])?.filter(
              (idMeetingUser: number) => !idMeetingUsers.some((id: number) => id === idMeetingUser)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(UserGeneratedActions.addManyUserHistorySuccess, (state: UserState.IState, { idUser, idUserHistories }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const userHistories = (state.entities[idUser]?.userHistories as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          userHistories: userHistories.concat(idUserHistories.filter(id => userHistories.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserGeneratedActions.deleteManyUserHistorySuccess, (state: UserState.IState, { idUserHistories, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.userHistories) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            userHistories: (state.entities[idUser]?.userHistories as number[])?.filter(
              (idUserHistory: number) => !idUserHistories.some((id: number) => id === idUserHistory)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(UserGeneratedActions.addManyElementLibrarySuccess, (state: UserState.IState, { idUser, idElementLibraries }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const elementLibraries = (state.entities[idUser]?.elementLibraries as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          elementLibraries: elementLibraries.concat(idElementLibraries.filter(id => elementLibraries.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    UserGeneratedActions.deleteManyElementLibrarySuccess,
    (state: UserState.IState, { idElementLibraries, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.elementLibraries) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              elementLibraries: (state.entities[idUser]?.elementLibraries as number[])?.filter(
                (idElementLibrary: number) => !idElementLibraries.some((id: number) => id === idElementLibrary)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(UserGeneratedActions.addManyOrganizationSuccess, (state: UserState.IState, { idUser, idOrganizations }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const organizations = (state.entities[idUser]?.organizations as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          organizations: organizations.concat(idOrganizations.filter(id => organizations.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserGeneratedActions.deleteManyOrganizationSuccess, (state: UserState.IState, { idOrganizations, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.organizations) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            organizations: (state.entities[idUser]?.organizations as number[])?.filter(
              (idOrganization: number) => !idOrganizations.some((id: number) => id === idOrganization)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(UserGeneratedActions.addManyCommunitySuccess, (state: UserState.IState, { idUser, idCommunities }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const communities = (state.entities[idUser]?.communities as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          communities: communities.concat(idCommunities.filter(id => communities.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserGeneratedActions.deleteManyCommunitySuccess, (state: UserState.IState, { idCommunities, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.communities) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            communities: (state.entities[idUser]?.communities as number[])?.filter(
              (idCommunity: number) => !idCommunities.some((id: number) => id === idCommunity)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(UserGeneratedActions.addManyGroupSuccess, (state: UserState.IState, { idUser, idGroups }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const groups = (state.entities[idUser]?.groups as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          groups: groups.concat(idGroups.filter(id => groups.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserGeneratedActions.deleteManyGroupSuccess, (state: UserState.IState, { idGroups, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.groups) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            groups: (state.entities[idUser]?.groups as number[])?.filter(
              (idGroup: number) => !idGroups.some((id: number) => id === idGroup)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: UserState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): UserState.IState {
  return { ...state, isLoaded, isLoading };
}
