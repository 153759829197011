import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Community, ProjectElement, ProjectElementEntityState } from '@api/api-interfaces';
import * as communityActions from '@wip/store/actions';
import * as projectElementActions from '@wip/store/actions';
import { ProjectElementApiService } from '@wip/store/api-services';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { AppState } from '@wip/store/configs/reducers';
import { ProjectElementSelectors } from '@wip/store/selectors';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  GeneratedProjectElementEffects,
  getDefaultDeleteProjectElementActions
} from './project-element-generated.effects';

@Injectable()
export class ProjectElementEffects extends GeneratedProjectElementEffects {
  constructor(
    actions$: Actions,
    projectElementApiService: ProjectElementApiService,
    store$: Store<AppState>
    // private projectElementService: ProjectElementService,
    // private ganttLinkService: GanttLinkService,
    // private communityService: CommunityService
  ) {
    super(actions$, projectElementApiService, store$);
  }

  validateActionForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(projectElementActions.validateActionForm),
      switchMap(controls => {
        return this.projectElementApiService.validateActionForm(controls).pipe(
          map((communityReturned: Community) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({
              communities: [communityReturned]
            });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        );
      })
    );
  });

  save$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(projectElementActions.save),
      switchMap(state => {
        return this.projectElementApiService.save(state).pipe(
          map((projectElementsReturned: ProjectElement[]) => {
            return projectElementActions.ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert({
              projectElements: projectElementsReturned
            });
          }),
          catchError(error =>
            of(projectElementActions.ProjectElementGeneratedActions.projectElementsFailure({ error }))
          )
        );
      })
    );
  });

  insertProjectElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(projectElementActions.insertProjectElement),
      switchMap(state => {
        return this.projectElementApiService.insertProjectElement(state).pipe(
          map((projectElementsReturned: ProjectElement) => {
            return projectElementActions.ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert({
              projectElements: [projectElementsReturned]
            });
          }),
          catchError(error =>
            of(projectElementActions.ProjectElementGeneratedActions.projectElementsFailure({ error }))
          )
        );
      })
    );
  });

  reorderProjectElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(projectElementActions.reorderProjectElements),
      switchMap(state => {
        return this.projectElementApiService.reorder(state).pipe(
          map((projectElementsReturned: ProjectElement[]) => {
            return projectElementActions.ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert({
              projectElements: projectElementsReturned
            });
          }),
          catchError(error =>
            of(projectElementActions.ProjectElementGeneratedActions.projectElementsFailure({ error }))
          )
        );
      })
    );
  });

  reschedule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(projectElementActions.reschedule),
      switchMap(params => {
        return this.projectElementApiService.reschedule(params.data).pipe(
          map((projectReturned: Community) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({
              communities: [projectReturned]
            });
          }),
          catchError(error =>
            of(projectElementActions.ProjectElementGeneratedActions.projectElementsFailure({ error }))
          )
        );
      })
    );
  });

  deleteOneProjectElementCustom$ = createEffect(() => {
    const selectProjectElementState$ = this.store$.select(ProjectElementSelectors.selectProjectElementState);
    return this.actions$.pipe(
      ofType(projectElementActions.deleteOneProjectElementCustom),
      withLatestFrom(selectProjectElementState$),
      concatMap(([{ idProjectElement }, state]) =>
        this.projectElementApiService.deleteProjectElementCustom(idProjectElement).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteProjectElementActions(state.entities[idProjectElement] as ProjectElementEntityState),
              projectElementActions.deleteOneProjectElementCustom.type
            )
          ]),
          catchError(error =>
            of(projectElementActions.ProjectElementGeneratedActions.projectElementsFailure({ error }))
          )
        )
      )
    );
  });
}
