import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { MeetingUserGeneratedActions } from '@wip/store/actions';
import { MeetingUserState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { MeetingUserEntityState } from '@api/api-interfaces';

export const meetingUserReducersGeneratedFunctions: ReducerTypes<MeetingUserState.IState, readonly ActionCreator[]>[] =
  [
    on(MeetingUserGeneratedActions.getOneMeetingUser, (state: MeetingUserState.IState) =>
      setLoadingsState(state, true)
    ),
    on(MeetingUserGeneratedActions.getManyMeetingUsers, (state: MeetingUserState.IState) =>
      setLoadingsState(state, true)
    ),
    on(MeetingUserGeneratedActions.upsertOneMeetingUser, (state: MeetingUserState.IState) =>
      setLoadingsState(state, true)
    ),

    on(MeetingUserGeneratedActions.upsertManyMeetingUsersSuccess, (state: MeetingUserState.IState, { meetingUsers }) =>
      MeetingUserState.adapter.upsertMany(meetingUsers, setLoadingsState(state, false))
    ),
    on(MeetingUserGeneratedActions.deleteOneMeetingUser, (state: MeetingUserState.IState) =>
      setLoadingsState(state, true)
    ),
    on(MeetingUserGeneratedActions.deleteOneMeetingUserSuccess, (state: MeetingUserState.IState, { idMeetingUser }) =>
      MeetingUserState.adapter.removeOne(idMeetingUser, setLoadingsState(state, false))
    ),
    on(MeetingUserGeneratedActions.clearActivesMeetingUsers, (state: MeetingUserState.IState) => ({
      ...state,
      actives: []
    })),
    on(
      MeetingUserGeneratedActions.addManyActivesMeetingUsers,
      (state: MeetingUserState.IState, { idMeetingUsers }) => ({
        ...state,
        actives: state.actives.concat(idMeetingUsers)
      })
    ),
    on(MeetingUserGeneratedActions.deleteOneActiveMeetingUser, (state: MeetingUserState.IState, { idMeetingUser }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idMeetingUser)
    })),

    on(MeetingUserGeneratedActions.clearMeetingUsers, () => MeetingUserState.initialState),

    on(
      MeetingUserGeneratedActions.addMeetingSuccess,
      (state: MeetingUserState.IState, { idMeetingUser, idMeeting }) => {
        if (!state.entities[idMeetingUser]) {
          return state;
        }
        return {
          ...state,
          entities: {
            ...state.entities,
            [idMeetingUser]: {
              ...state.entities[idMeetingUser],
              meeting: idMeeting
            }
          }
        };
      }
    ),

    on(
      MeetingUserGeneratedActions.deleteManyMeetingSuccess,
      (state: MeetingUserState.IState, { idMeetings, idMeetingUsers }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idMeetingUsers.reduce((entities, idMeetingUser) => {
              if (!state.entities[idMeetingUser]?.meeting) {
                return entities;
              }
              entities[idMeetingUser] = {
                ...state.entities[idMeetingUser],
                meeting: idMeetings.some((idMeeting: number) => idMeeting === state.entities[idMeetingUser]?.meeting)
                  ? undefined
                  : state.entities[idMeetingUser]?.meeting
              } as MeetingUserEntityState;
              return entities;
            }, {} as Dictionary<MeetingUserEntityState>)
          }
        };
      }
    ),

    on(MeetingUserGeneratedActions.addUserSuccess, (state: MeetingUserState.IState, { idMeetingUser, idUser }) => {
      if (!state.entities[idMeetingUser]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idMeetingUser]: {
            ...state.entities[idMeetingUser],
            user: idUser
          }
        }
      };
    }),

    on(
      MeetingUserGeneratedActions.deleteManyUserSuccess,
      (state: MeetingUserState.IState, { idUsers, idMeetingUsers }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idMeetingUsers.reduce((entities, idMeetingUser) => {
              if (!state.entities[idMeetingUser]?.user) {
                return entities;
              }
              entities[idMeetingUser] = {
                ...state.entities[idMeetingUser],
                user: idUsers.some((idUser: number) => idUser === state.entities[idMeetingUser]?.user)
                  ? undefined
                  : state.entities[idMeetingUser]?.user
              } as MeetingUserEntityState;
              return entities;
            }, {} as Dictionary<MeetingUserEntityState>)
          }
        };
      }
    )
  ];

export function setLoadingsState(
  state: MeetingUserState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): MeetingUserState.IState {
  return { ...state, isLoaded, isLoading };
}
