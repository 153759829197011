import { ElementState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { elementReducersGeneratedFunctions } from './element-generated.reducer';

const elementReducersFunctions = [...elementReducersGeneratedFunctions];

const elementReducer = createReducer(ElementState.initialState, ...elementReducersFunctions);

export function reducer(state: ElementState.IState | undefined, action: Action) {
  return elementReducer(state, action);
}
