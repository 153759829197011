import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommunityGroupEntityState } from '@api/api-interfaces';
import * as communityGroupActions from '@wip/store/actions';
import { CommunityGroupApiService } from '@wip/store/api-services';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { AppState } from '@wip/store/configs/reducers';
import { catchError, concatMap, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import {
  GeneratedCommunityGroupEffects,
  getDefaultDeleteCommunityGroupActions
} from './community-group-generated.effects';
import { CommunityGroupSelectors } from '@wip/store/selectors';

@Injectable()
export class CommunityGroupEffects extends GeneratedCommunityGroupEffects {
  constructor(actions$: Actions, communityGroupApiService: CommunityGroupApiService, store$: Store<AppState>) {
    super(actions$, communityGroupApiService, store$);
  }

  deleteOneCommunityGroupAndCommunityUsersAssociated$ = createEffect(() => {
    let idCommunityGroupDeleted: number;

    const selectCommunityGroupState$ = this.store$.select(CommunityGroupSelectors.selectCommunityGroupState);
    return this.actions$.pipe(
      ofType(communityGroupActions.deleteOneCommunityGroupAndCommunityUsersAssociated),
      withLatestFrom(selectCommunityGroupState$),
      concatMap(([params, state]) =>
        this.communityGroupApiService.deleteCommunityGroupAndUsers(params.idCommunity, params.idGroup).pipe(
          tap(id => {
            idCommunityGroupDeleted = id;
          }),
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCommunityGroupActions(
                state.entities[idCommunityGroupDeleted] as CommunityGroupEntityState
              ),
              communityGroupActions.CommunityGroupGeneratedActions.deleteOneCommunityGroup.type
            )
          ]),
          catchError(error =>
            of(communityGroupActions.CommunityGroupGeneratedActions.communityGroupsFailure({ error }))
          )
        )
      )
    );
  });
}
