import { ProjectElementState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { projectElementReducersGeneratedFunctions } from './project-element-generated.reducer';

const projectElementReducersFunctions = [...projectElementReducersGeneratedFunctions];

const projectElementReducer = createReducer(ProjectElementState.initialState, ...projectElementReducersFunctions);

export function reducer(state: ProjectElementState.IState | undefined, action: Action) {
  return projectElementReducer(state, action);
}
