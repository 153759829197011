import { OrganizationMilestoneAssociationState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationMilestoneAssociationReducersGeneratedFunctions } from './organization-milestone-association-generated.reducer';

const organizationMilestoneAssociationReducersFunctions = [
  ...organizationMilestoneAssociationReducersGeneratedFunctions
];

const organizationMilestoneAssociationReducer = createReducer(
  OrganizationMilestoneAssociationState.initialState,
  ...organizationMilestoneAssociationReducersFunctions
);

export function reducer(state: OrganizationMilestoneAssociationState.IState | undefined, action: Action) {
  return organizationMilestoneAssociationReducer(state, action);
}
