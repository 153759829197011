import { OrganizationUserState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationUserReducersGeneratedFunctions } from './organization-user-generated.reducer';

const organizationUserReducersFunctions = [...organizationUserReducersGeneratedFunctions];

const organizationUserReducer = createReducer(OrganizationUserState.initialState, ...organizationUserReducersFunctions);

export function reducer(state: OrganizationUserState.IState | undefined, action: Action) {
  return organizationUserReducer(state, action);
}
