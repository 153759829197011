import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserHistory, UserHistoryEntityState } from '@api/api-interfaces';
import { UserHistoryRelationsIds } from '@wip/store/ids-interfaces';

export const UserHistoryGeneratedActions = createActionGroup({
  source: 'User History Generated',
  events: {
    'Get One User History': props<{ idUserHistory: number; params?: any }>(),
    'Get Many User Histories': props<{ params: any }>(),
    'Add Many Actives User Histories': props<{ idUserHistories: number[] }>(),
    'Delete One Active User History': props<{ idUserHistory: number }>(),
    'Clear Actives User Histories': emptyProps(),
    'Upsert One User History': props<{ userHistory: Partial<UserHistory>; ids?: UserHistoryRelationsIds }>(),
    'Upsert Many User Histories': props<{ userHistories: Partial<UserHistory>[]; ids?: UserHistoryRelationsIds }>(),
    'Upsert Many User Histories Success': props<{ userHistories: UserHistoryEntityState[] }>(),
    'Delete One User History': props<{ idUserHistory: number }>(),
    'Delete One User History Success': props<{ idUserHistory: number }>(),
    'Normalize Many User Histories After Upsert': props<{ userHistories: UserHistoryEntityState[] }>(),
    'User Histories Failure': props<{ error: HttpErrorResponse }>(),
    'Clear User Histories': emptyProps(),
    'Add User Success': props<{ idUserHistory: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idUserHistories: number[] }>()
  }
});
