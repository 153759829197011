import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedFolderElementService } from './folder-element-generated.service';
import { Actions } from '@ngrx/effects';
import { FolderElementApiService } from '@wip/store/api-services';

@Injectable({
  providedIn: 'root'
})
export class FolderElementService extends GeneratedFolderElementService {
  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private readonly folderElementApiService: FolderElementApiService
  ) {
    super(store$, actions$);
  }

  public downloadFile(idFolderElement: number, fileName: string) {
    return this.folderElementApiService.downloadFile(idFolderElement, fileName);
  }
}
