import { OrganizationCaracteristicState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationCaracteristicReducersGeneratedFunctions } from './organization-caracteristic-generated.reducer';

const organizationCaracteristicReducersFunctions = [...organizationCaracteristicReducersGeneratedFunctions];

const organizationCaracteristicReducer = createReducer(
  OrganizationCaracteristicState.initialState,
  ...organizationCaracteristicReducersFunctions
);

export function reducer(state: OrganizationCaracteristicState.IState | undefined, action: Action) {
  return organizationCaracteristicReducer(state, action);
}
