import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationStep } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationStepRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationStepGeneratedActions } from '@wip/store/actions';
import { OrganizationStepSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationStepService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationStepSelectors.selectIsLoadedOrganizationStep));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationStepSelectors.selectIsLoadingOrganizationStep));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationStepSelectors.selectIsReadyAndLoadedOrganizationStep as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationSteps(schema: SelectSchema = {}): Observable<OrganizationStep[]> {
    return this.store$.pipe(select(OrganizationStepSelectors.selectAllOrganizationSteps(schema))).pipe(
      switchMap(({ organizationSteps }: { organizationSteps: OrganizationStep[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationSteps));
      })
    );
  }

  public selectOneOrganizationStep(
    idOrganizationStep: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationStep> {
    return this.store$
      .pipe(select(OrganizationStepSelectors.selectOneOrganizationStep(schema, idOrganizationStep)))
      .pipe(
        switchMap(({ organizationStep }: { organizationStep: OrganizationStep }) => {
          return this.getReady(schema).pipe(mapTo(organizationStep));
        })
      );
  }

  public selectAllActiveOrganizationSteps(schema: SelectSchema = {}): Observable<OrganizationStep[]> {
    return this.store$.pipe(select(OrganizationStepSelectors.selectActiveOrganizationSteps(schema))).pipe(
      switchMap(({ organizationSteps }: { organizationSteps: OrganizationStep[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationSteps));
      })
    );
  }

  public selectIdOrganizationStepsActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationStepSelectors.selectIdOrganizationStepsActive)).pipe(
      switchMap((idOrganizationSteps: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationSteps));
      })
    );
  }

  public getOneOrganizationStep(
    idOrganizationStep: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationStep> {
    this.store$.dispatch(OrganizationStepGeneratedActions.getOneOrganizationStep({ idOrganizationStep, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationStepGeneratedActions.normalizeManyOrganizationStepsAfterUpsert,
        OrganizationStepGeneratedActions.organizationStepsFailure,
        true
      );
    }
  }

  public getManyOrganizationSteps(params: any = {}, getResult?: boolean): void | Observable<OrganizationStep[]> {
    this.store$.dispatch(OrganizationStepGeneratedActions.getManyOrganizationSteps({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationStepGeneratedActions.normalizeManyOrganizationStepsAfterUpsert,
        OrganizationStepGeneratedActions.organizationStepsFailure
      );
    }
  }

  public upsertOneOrganizationStep(
    organizationStep: Partial<OrganizationStep>,
    ids: OrganizationStepRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationStep> {
    this.store$.dispatch(OrganizationStepGeneratedActions.upsertOneOrganizationStep({ organizationStep, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationStepGeneratedActions.normalizeManyOrganizationStepsAfterUpsert,
        OrganizationStepGeneratedActions.organizationStepsFailure,
        true
      );
    }
  }

  public deleteOneOrganizationStep(idOrganizationStep: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(OrganizationStepGeneratedActions.deleteOneOrganizationStep({ idOrganizationStep }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationStepGeneratedActions.deleteOneOrganizationStepSuccess,
        OrganizationStepGeneratedActions.organizationStepsFailure
      );
    }
  }

  public setActiveOrganizationSteps(idOrganizationSteps: number[]): void {
    this.store$.dispatch(OrganizationStepGeneratedActions.clearActivesOrganizationSteps());
    this.store$.dispatch(OrganizationStepGeneratedActions.addManyActivesOrganizationSteps({ idOrganizationSteps }));
  }
}
