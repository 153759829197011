import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationSubFamilyApiService } from '@wip/store/api-services';
import { GeneratedOrganizationSubFamilyEffects } from './organization-sub-family-generated.effects';

@Injectable()
export class OrganizationSubFamilyEffects extends GeneratedOrganizationSubFamilyEffects {
  constructor(
    actions$: Actions,
    organizationSubFamilyApiService: OrganizationSubFamilyApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationSubFamilyApiService, store$);
  }
}
