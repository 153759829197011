import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserBoardStateGeneratedActions } from '@wip/store/actions';
import { UserBoardStateState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserBoardStateEntityState } from '@api/api-interfaces';

export const userBoardStateReducersGeneratedFunctions: ReducerTypes<
  UserBoardStateState.IState,
  readonly ActionCreator[]
>[] = [
  on(UserBoardStateGeneratedActions.getOneUserBoardState, (state: UserBoardStateState.IState) =>
    setLoadingsState(state, true)
  ),
  on(UserBoardStateGeneratedActions.getManyUserBoardStates, (state: UserBoardStateState.IState) =>
    setLoadingsState(state, true)
  ),
  on(UserBoardStateGeneratedActions.upsertOneUserBoardState, (state: UserBoardStateState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    UserBoardStateGeneratedActions.upsertManyUserBoardStatesSuccess,
    (state: UserBoardStateState.IState, { userBoardStates }) =>
      UserBoardStateState.adapter.upsertMany(userBoardStates, setLoadingsState(state, false))
  ),
  on(UserBoardStateGeneratedActions.deleteOneUserBoardState, (state: UserBoardStateState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    UserBoardStateGeneratedActions.deleteOneUserBoardStateSuccess,
    (state: UserBoardStateState.IState, { idUserBoardState }) =>
      UserBoardStateState.adapter.removeOne(idUserBoardState, setLoadingsState(state, false))
  ),
  on(UserBoardStateGeneratedActions.clearActivesUserBoardStates, (state: UserBoardStateState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    UserBoardStateGeneratedActions.addManyActivesUserBoardStates,
    (state: UserBoardStateState.IState, { idUserBoardStates }) => ({
      ...state,
      actives: state.actives.concat(idUserBoardStates)
    })
  ),
  on(
    UserBoardStateGeneratedActions.deleteOneActiveUserBoardState,
    (state: UserBoardStateState.IState, { idUserBoardState }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idUserBoardState)
    })
  ),

  on(UserBoardStateGeneratedActions.clearUserBoardStates, () => UserBoardStateState.initialState),

  on(
    UserBoardStateGeneratedActions.addOrganizationUserSuccess,
    (state: UserBoardStateState.IState, { idUserBoardState, idOrganizationUser }) => {
      if (!state.entities[idUserBoardState]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserBoardState]: {
            ...state.entities[idUserBoardState],
            organizationUser: idOrganizationUser
          }
        }
      };
    }
  ),

  on(
    UserBoardStateGeneratedActions.deleteManyOrganizationUserSuccess,
    (state: UserBoardStateState.IState, { idOrganizationUsers, idUserBoardStates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserBoardStates.reduce((entities, idUserBoardState) => {
            if (!state.entities[idUserBoardState]?.organizationUser) {
              return entities;
            }
            entities[idUserBoardState] = {
              ...state.entities[idUserBoardState],
              organizationUser: idOrganizationUsers.some(
                (idOrganizationUser: number) =>
                  idOrganizationUser === state.entities[idUserBoardState]?.organizationUser
              )
                ? undefined
                : state.entities[idUserBoardState]?.organizationUser
            } as UserBoardStateEntityState;
            return entities;
          }, {} as Dictionary<UserBoardStateEntityState>)
        }
      };
    }
  ),

  on(
    UserBoardStateGeneratedActions.addOrganizationFamilySuccess,
    (state: UserBoardStateState.IState, { idUserBoardState, idOrganizationFamily }) => {
      if (!state.entities[idUserBoardState]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserBoardState]: {
            ...state.entities[idUserBoardState],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    UserBoardStateGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: UserBoardStateState.IState, { idOrganizationFamilys, idUserBoardStates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserBoardStates.reduce((entities, idUserBoardState) => {
            if (!state.entities[idUserBoardState]?.organizationFamily) {
              return entities;
            }
            entities[idUserBoardState] = {
              ...state.entities[idUserBoardState],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idUserBoardState]?.organizationFamily
              )
                ? undefined
                : state.entities[idUserBoardState]?.organizationFamily
            } as UserBoardStateEntityState;
            return entities;
          }, {} as Dictionary<UserBoardStateEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: UserBoardStateState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): UserBoardStateState.IState {
  return { ...state, isLoaded, isLoading };
}
