import { TodoElementState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { todoElementReducersGeneratedFunctions } from './todo-element-generated.reducer';

const todoElementReducersFunctions = [...todoElementReducersGeneratedFunctions];

const todoElementReducer = createReducer(TodoElementState.initialState, ...todoElementReducersFunctions);

export function reducer(state: TodoElementState.IState | undefined, action: Action) {
  return todoElementReducer(state, action);
}
