import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wip-project-grid-legend',
  templateUrl: './project-grid-legend.component.html',
  styleUrls: ['./project-grid-legend.component.scss']
})
export class ProjectGridLegendComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
