import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedSectionApiService } from './section-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SectionApiService extends GeneratedSectionApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}

