import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommunityCaracteristic, OrganizationMilestone, OrganizationMilestoneFamily } from '@api/api-interfaces';

@Component({
  selector: 'app-chart-input',
  templateUrl: './chart-input.component.html',
  styleUrls: ['./chart-input.component.scss']
})
export class ChartInputComponent implements OnInit {
  @Input() chartInputsForm: FormGroup;
  @Input() projectCharacteristics: CommunityCaracteristic[];
  @Input() projectMilestones: OrganizationMilestone[];
  @Input() projectMilestoneFamilies: OrganizationMilestoneFamily[];
  @Output() isCharacteristicsOpened: EventEmitter<boolean> = new EventEmitter();
  @Output() isMilestoneOpened: EventEmitter<boolean> = new EventEmitter();
  public startDatePlaceholder: string;
  public endDatePlaceholder: string;
  public characteristicsPlaceholder: string;
  public milestonePlaceholder: string;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.startDatePlaceholder = this.translate.instant('General.StartDate');
    this.endDatePlaceholder = this.translate.instant('General.Deadline');
    this.characteristicsPlaceholder = this.translate.instant('General.Characteristic');
    this.milestonePlaceholder = this.translate.instant('template.projectSynthesis.milestones');
  }

  public openedChangeCharacteristics(opened: boolean): void {
    this.isCharacteristicsOpened.emit(opened);
  }

  public openedChangeMilestone(opened: boolean): void {
    this.isMilestoneOpened.emit(opened);
  }
}
