import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ElementApiService } from '@wip/store/api-services';
import { GeneratedElementEffects } from './element-generated.effects';
import { catchError, concatMap, map, of, switchMap } from 'rxjs';
import * as elementActions from '@wip/store/actions';
import { Element } from '@api/api-interfaces';

@Injectable()
export class ElementEffects extends GeneratedElementEffects {
  constructor(actions$: Actions, elementApiService: ElementApiService, store$: Store<AppState>) {
    super(actions$, elementApiService, store$);
  }

  getGridDashboardDatas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(elementActions.getGridDashboardDatas),
      switchMap(params =>
        this.elementApiService.getGridDashboardDatas(params.idOrganization, params.idOrganizationMilestoneFamily).pipe(
          map((elements: Element[]) => {
            return elementActions.ElementGeneratedActions.normalizeManyElementsAfterUpsert({ elements });
          }),
          catchError(error => of(elementActions.ElementGeneratedActions.elementsFailure({ error })))
        )
      )
    );
  });

  getPersonalElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(elementActions.getPersonalElements),
      switchMap(_ =>
        this.elementApiService.getPersonalElements().pipe(
          map((elements: Element[]) => {
            return elementActions.ElementGeneratedActions.normalizeManyElementsAfterUpsert({ elements });
          }),
          catchError(error => of(elementActions.ElementGeneratedActions.elementsFailure({ error })))
        )
      )
    );
  });

  validateActionFormElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(elementActions.validateActionFormElement),
      switchMap(controls => {
        return this.elementApiService.validateActionForm(controls).pipe(
          map((elementReturned: Element) => {
            return elementActions.ElementGeneratedActions.normalizeManyElementsAfterUpsert({
              elements: [elementReturned]
            });
          }),
          catchError(error => of(elementActions.ElementGeneratedActions.elementsFailure({ error })))
        );
      })
    );
  });

  updateInitial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(elementActions.updateInitial),
      switchMap(controls => {
        return this.elementApiService.updateInitial(controls.element).pipe(
          map((elementReturned: Element) => {
            return elementActions.ElementGeneratedActions.normalizeManyElementsAfterUpsert({
              elements: [elementReturned]
            });
          }),
          catchError(error => of(elementActions.ElementGeneratedActions.elementsFailure({ error })))
        );
      })
    );
  });

  updateObjectif$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(elementActions.updateObjectif),
      switchMap(controls => {
        return this.elementApiService.updateObjectif(controls.element).pipe(
          map((elementReturned: Element) => {
            return elementActions.ElementGeneratedActions.normalizeManyElementsAfterUpsert({
              elements: [elementReturned]
            });
          }),
          catchError(error => of(elementActions.ElementGeneratedActions.elementsFailure({ error })))
        );
      })
    );
  });

  reorderElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(elementActions.reorder),
      concatMap(params => {
        return this.elementApiService.reorder(params.params).pipe(
          map((elementsReturned: Element[]) => {
            return elementActions.ElementGeneratedActions.normalizeManyElementsAfterUpsert({
              elements: elementsReturned
            });
          }),
          catchError(error => of(elementActions.ElementGeneratedActions.elementsFailure({ error })))
        );
      })
    );
  });
}
