import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MeetingUser, MeetingUserEntityState } from '@api/api-interfaces';
import { Meeting, MeetingEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { MeetingUserState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const meetingUserRelations: string[] = ['meetings', 'users'];

export const { selectEntities, selectAll } = MeetingUserState.adapter.getSelectors();

export const selectMeetingUserState = createFeatureSelector<MeetingUserState.IState>(
  MeetingUserState.meetingUserFeatureKey
);

export const selectIsLoadedMeetingUser = createSelector(
  selectMeetingUserState,
  (state: MeetingUserState.IState) => state.isLoaded
);

export const selectIsLoadingMeetingUser = createSelector(
  selectMeetingUserState,
  (state: MeetingUserState.IState) => state.isLoading
);

export const selectIsReadyMeetingUser = createSelector(
  selectMeetingUserState,
  (state: MeetingUserState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedMeetingUser = createSelector(
  selectMeetingUserState,
  (state: MeetingUserState.IState) => state.isLoaded && !state.isLoading
);

export const selectMeetingUsersEntities = createSelector(selectMeetingUserState, selectEntities);

export const selectMeetingUsersArray = createSelector(selectMeetingUserState, selectAll);

export const selectIdMeetingUsersActive = createSelector(
  selectMeetingUserState,
  (state: MeetingUserState.IState) => state.actives
);

const meetingUsersInObject = (meetingUsers: Dictionary<MeetingUserEntityState>) => ({ meetingUsers });

const selectMeetingUsersEntitiesDictionary = createSelector(selectMeetingUsersEntities, meetingUsersInObject);

const selectAllMeetingUsersObject = createSelector(selectMeetingUsersEntities, meetingUsers => {
  return hydrateAll({ meetingUsers });
});

const selectOneMeetingUserDictionary = (idMeetingUser: number) =>
  createSelector(selectMeetingUsersEntities, meetingUsers => ({
    meetingUsers: { [idMeetingUser]: meetingUsers[idMeetingUser] }
  }));

const selectOneMeetingUserDictionaryWithoutChild = (idMeetingUser: number) =>
  createSelector(selectMeetingUsersEntities, meetingUsers => ({
    meetingUser: meetingUsers[idMeetingUser]
  }));

const selectActiveMeetingUsersEntities = createSelector(
  selectIdMeetingUsersActive,
  selectMeetingUsersEntities,
  (actives: number[], meetingUsers: Dictionary<MeetingUserEntityState>) =>
    getMeetingUsersFromActives(actives, meetingUsers)
);

function getMeetingUsersFromActives(
  actives: number[],
  meetingUsers: Dictionary<MeetingUserEntityState>
): Dictionary<MeetingUserEntityState> {
  return actives.reduce((acc, idActive) => {
    if (meetingUsers[idActive]) {
      acc[idActive] = meetingUsers[idActive];
    }
    return acc;
  }, {} as Dictionary<MeetingUserEntityState>);
}

const selectAllMeetingUsersSelectors: Dictionary<Selector> = {};
export function selectAllMeetingUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<MeetingUser>(
      schema,
      selectAllMeetingUsersSelectors,
      selectMeetingUsersEntitiesDictionary,
      getRelationSelectors,
      meetingUserRelations,
      hydrateAll,
      'meetingUser'
    );
  } else {
    return selectAllMeetingUsersObject;
  }
}

export function selectAllMeetingUsersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'meetingUsers'
): Selector {
  return createSelector(selectAllMeetingUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<MeetingUserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.meetingUsers.length; i++) {
      res[customKey][result.meetingUsers[i].idMeetingUser] = result.meetingUsers[i];
    }
    return res;
  });
}

export function selectOneMeetingUser(schema: SelectSchema = {}, idMeetingUser: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneMeetingUserDictionary(idMeetingUser)];
    selectors.push(...getRelationSelectors(schema, meetingUserRelations, 'meetingUser'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneMeetingUserDictionaryWithoutChild(idMeetingUser);
  }
}

export function selectActiveMeetingUsers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveMeetingUsersEntities, meetingUsers => ({
      meetingUsers
    }))
  ];
  selectors.push(...getRelationSelectors(schema, meetingUserRelations, 'meetingUser'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  meetingUsers: Dictionary<MeetingUserEntityState>;
  meetings?: Dictionary<MeetingEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { meetingUsers: (MeetingUser | null)[] } {
  const { meetingUsers, meetings, users } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    meetingUsers: Object.keys(meetingUsers).map(idMeetingUser =>
      hydrate(meetingUsers[idMeetingUser] as MeetingUserEntityState, meetings, users)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { meetingUser: MeetingUserEntityState | null } {
  const { meetingUsers, meetings, users } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const meetingUser = Object.values(meetingUsers)[0];
  return {
    meetingUser: hydrate(meetingUser as MeetingUserEntityState, meetings, users)
  };
}

function hydrate(
  meetingUser: MeetingUserEntityState,
  meetingEntities?: Dictionary<MeetingEntityState>,
  userEntities?: Dictionary<UserEntityState>
): MeetingUser | null {
  if (!meetingUser) {
    return null;
  }

  const meetingUserHydrated: MeetingUserEntityState = { ...meetingUser };
  if (meetingEntities) {
    meetingUserHydrated.meeting = meetingEntities[meetingUser.meeting as number] as Meeting;
  } else {
    delete meetingUserHydrated.meeting;
  }
  if (userEntities) {
    meetingUserHydrated.user = userEntities[meetingUser.user as number] as User;
  } else {
    delete meetingUserHydrated.user;
  }

  return meetingUserHydrated as MeetingUser;
}
