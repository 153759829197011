import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationDataColumnEntityState } from '@api/api-interfaces';

export const organizationDataColumnReducersGeneratedFunctions: ReducerTypes<
  OrganizationDataColumnState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationDataColumnGeneratedActions.getOneOrganizationDataColumn, (state: OrganizationDataColumnState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationDataColumnGeneratedActions.getManyOrganizationDataColumns,
    (state: OrganizationDataColumnState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationDataColumnGeneratedActions.upsertOneOrganizationDataColumn,
    (state: OrganizationDataColumnState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationDataColumnGeneratedActions.upsertManyOrganizationDataColumnsSuccess,
    (state: OrganizationDataColumnState.IState, { organizationDataColumns }) =>
      OrganizationDataColumnState.adapter.upsertMany(organizationDataColumns, setLoadingsState(state, false))
  ),
  on(
    OrganizationDataColumnGeneratedActions.deleteOneOrganizationDataColumn,
    (state: OrganizationDataColumnState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationDataColumnGeneratedActions.deleteOneOrganizationDataColumnSuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationDataColumn }) =>
      OrganizationDataColumnState.adapter.removeOne(idOrganizationDataColumn, setLoadingsState(state, false))
  ),
  on(
    OrganizationDataColumnGeneratedActions.clearActivesOrganizationDataColumns,
    (state: OrganizationDataColumnState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationDataColumnGeneratedActions.addManyActivesOrganizationDataColumns,
    (state: OrganizationDataColumnState.IState, { idOrganizationDataColumns }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationDataColumns)
    })
  ),
  on(
    OrganizationDataColumnGeneratedActions.deleteOneActiveOrganizationDataColumn,
    (state: OrganizationDataColumnState.IState, { idOrganizationDataColumn }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationDataColumn)
    })
  ),

  on(
    OrganizationDataColumnGeneratedActions.clearOrganizationDataColumns,
    () => OrganizationDataColumnState.initialState
  ),

  on(
    OrganizationDataColumnGeneratedActions.addManyOrganizationDataColumnValueSuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationDataColumn, idOrganizationDataColumnValues }) => {
      if (!state.entities[idOrganizationDataColumn]) {
        return state;
      }
      const organizationDataColumnValues =
        (state.entities[idOrganizationDataColumn]?.organizationDataColumnValues as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationDataColumn]: {
            ...state.entities[idOrganizationDataColumn],
            organizationDataColumnValues: organizationDataColumnValues.concat(
              idOrganizationDataColumnValues.filter(id => organizationDataColumnValues.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationDataColumnGeneratedActions.deleteManyOrganizationDataColumnValueSuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationDataColumnValues, idOrganizationDataColumns }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationDataColumns.reduce((entities, idOrganizationDataColumn) => {
            if (!state.entities[idOrganizationDataColumn]?.organizationDataColumnValues) {
              return entities;
            }
            entities[idOrganizationDataColumn] = {
              ...state.entities[idOrganizationDataColumn],
              organizationDataColumnValues: (
                state.entities[idOrganizationDataColumn]?.organizationDataColumnValues as number[]
              )?.filter(
                (idOrganizationDataColumnValue: number) =>
                  !idOrganizationDataColumnValues.some((id: number) => id === idOrganizationDataColumnValue)
              )
            } as OrganizationDataColumnEntityState;
            return entities;
          }, {} as Dictionary<OrganizationDataColumnEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationDataColumnGeneratedActions.addManyValeurSuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationDataColumn, idValeurs }) => {
      if (!state.entities[idOrganizationDataColumn]) {
        return state;
      }
      const valeurs = (state.entities[idOrganizationDataColumn]?.valeurs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationDataColumn]: {
            ...state.entities[idOrganizationDataColumn],
            valeurs: valeurs.concat(idValeurs.filter(id => valeurs.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationDataColumnGeneratedActions.deleteManyValeurSuccess,
    (state: OrganizationDataColumnState.IState, { idValeurs, idOrganizationDataColumns }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationDataColumns.reduce((entities, idOrganizationDataColumn) => {
            if (!state.entities[idOrganizationDataColumn]?.valeurs) {
              return entities;
            }
            entities[idOrganizationDataColumn] = {
              ...state.entities[idOrganizationDataColumn],
              valeurs: (state.entities[idOrganizationDataColumn]?.valeurs as number[])?.filter(
                (idValeur: number) => !idValeurs.some((id: number) => id === idValeur)
              )
            } as OrganizationDataColumnEntityState;
            return entities;
          }, {} as Dictionary<OrganizationDataColumnEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationDataColumnGeneratedActions.addManyOrganizationCaracteristicRowSuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationDataColumn, idOrganizationCaracteristicRows }) => {
      if (!state.entities[idOrganizationDataColumn]) {
        return state;
      }
      const organizationCaracteristicRows =
        (state.entities[idOrganizationDataColumn]?.organizationCaracteristicRows as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationDataColumn]: {
            ...state.entities[idOrganizationDataColumn],
            organizationCaracteristicRows: organizationCaracteristicRows.concat(
              idOrganizationCaracteristicRows.filter(id => organizationCaracteristicRows.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationDataColumnGeneratedActions.deleteManyOrganizationCaracteristicRowSuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationCaracteristicRows, idOrganizationDataColumns }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationDataColumns.reduce((entities, idOrganizationDataColumn) => {
            if (!state.entities[idOrganizationDataColumn]?.organizationCaracteristicRows) {
              return entities;
            }
            entities[idOrganizationDataColumn] = {
              ...state.entities[idOrganizationDataColumn],
              organizationCaracteristicRows: (
                state.entities[idOrganizationDataColumn]?.organizationCaracteristicRows as number[]
              )?.filter(
                (idOrganizationCaracteristicRow: number) =>
                  !idOrganizationCaracteristicRows.some((id: number) => id === idOrganizationCaracteristicRow)
              )
            } as OrganizationDataColumnEntityState;
            return entities;
          }, {} as Dictionary<OrganizationDataColumnEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationDataColumnGeneratedActions.addManyOrganizationCaracteristicSuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationDataColumn, idOrganizationCaracteristics }) => {
      if (!state.entities[idOrganizationDataColumn]) {
        return state;
      }
      const organizationCaracteristics =
        (state.entities[idOrganizationDataColumn]?.organizationCaracteristics as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationDataColumn]: {
            ...state.entities[idOrganizationDataColumn],
            organizationCaracteristics: organizationCaracteristics.concat(
              idOrganizationCaracteristics.filter(id => organizationCaracteristics.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationDataColumnGeneratedActions.deleteManyOrganizationCaracteristicSuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationCaracteristics, idOrganizationDataColumns }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationDataColumns.reduce((entities, idOrganizationDataColumn) => {
            if (!state.entities[idOrganizationDataColumn]?.organizationCaracteristics) {
              return entities;
            }
            entities[idOrganizationDataColumn] = {
              ...state.entities[idOrganizationDataColumn],
              organizationCaracteristics: (
                state.entities[idOrganizationDataColumn]?.organizationCaracteristics as number[]
              )?.filter(
                (idOrganizationCaracteristic: number) =>
                  !idOrganizationCaracteristics.some((id: number) => id === idOrganizationCaracteristic)
              )
            } as OrganizationDataColumnEntityState;
            return entities;
          }, {} as Dictionary<OrganizationDataColumnEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationDataColumnGeneratedActions.addOrganizationFamilySuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationDataColumn, idOrganizationFamily }) => {
      if (!state.entities[idOrganizationDataColumn]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationDataColumn]: {
            ...state.entities[idOrganizationDataColumn],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationDataColumnGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationDataColumnState.IState, { idOrganizationFamilys, idOrganizationDataColumns }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationDataColumns.reduce((entities, idOrganizationDataColumn) => {
            if (!state.entities[idOrganizationDataColumn]?.organizationFamily) {
              return entities;
            }
            entities[idOrganizationDataColumn] = {
              ...state.entities[idOrganizationDataColumn],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idOrganizationDataColumn]?.organizationFamily
              )
                ? undefined
                : state.entities[idOrganizationDataColumn]?.organizationFamily
            } as OrganizationDataColumnEntityState;
            return entities;
          }, {} as Dictionary<OrganizationDataColumnEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationDataColumnState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationDataColumnState.IState {
  return { ...state, isLoaded, isLoading };
}
