import { createAction, props } from '@ngrx/store';
import { CommunityUserProfil } from '@api/api-interfaces';
export * from './community-user-profil-generated.actions';

export const convertCommunityUserProfils = createAction(
  '[CommunityUserProfil] Convert CommunityUserProfils',
  props<{
    ids: number[];
  }>()
);

export const FillHandleUserProfils = createAction(
  '[CommunityCaracteristic] Fill Handle User Profils',
  props<{
    userProfils: Partial<CommunityUserProfil>[];
  }>()
);
