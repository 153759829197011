import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CommunityGroup } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { CommunityGroupRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGroupGeneratedActions } from '@wip/store/actions';
import { CommunityGroupSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCommunityGroupService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CommunityGroupSelectors.selectIsLoadedCommunityGroup));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CommunityGroupSelectors.selectIsLoadingCommunityGroup));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      CommunityGroupSelectors.selectIsReadyAndLoadedCommunityGroup as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCommunityGroups(schema: SelectSchema = {}): Observable<CommunityGroup[]> {
    return this.store$.pipe(select(CommunityGroupSelectors.selectAllCommunityGroups(schema))).pipe(
      switchMap(({ communityGroups }: { communityGroups: CommunityGroup[] }) => {
        return this.getReady(schema).pipe(mapTo(communityGroups));
      })
    );
  }

  public selectOneCommunityGroup(idCommunityGroup: number, schema: SelectSchema = {}): Observable<CommunityGroup> {
    return this.store$.pipe(select(CommunityGroupSelectors.selectOneCommunityGroup(schema, idCommunityGroup))).pipe(
      switchMap(({ communityGroup }: { communityGroup: CommunityGroup }) => {
        return this.getReady(schema).pipe(mapTo(communityGroup));
      })
    );
  }

  public selectAllActiveCommunityGroups(schema: SelectSchema = {}): Observable<CommunityGroup[]> {
    return this.store$.pipe(select(CommunityGroupSelectors.selectActiveCommunityGroups(schema))).pipe(
      switchMap(({ communityGroups }: { communityGroups: CommunityGroup[] }) => {
        return this.getReady(schema).pipe(mapTo(communityGroups));
      })
    );
  }

  public selectIdCommunityGroupsActive(): Observable<number[]> {
    return this.store$.pipe(select(CommunityGroupSelectors.selectIdCommunityGroupsActive)).pipe(
      switchMap((idCommunityGroups: number[]) => {
        return this.getReady().pipe(mapTo(idCommunityGroups));
      })
    );
  }

  public getOneCommunityGroup(
    idCommunityGroup: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<CommunityGroup> {
    this.store$.dispatch(CommunityGroupGeneratedActions.getOneCommunityGroup({ idCommunityGroup, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityGroupGeneratedActions.normalizeManyCommunityGroupsAfterUpsert,
        CommunityGroupGeneratedActions.communityGroupsFailure,
        true
      );
    }
  }

  public getManyCommunityGroups(params: any = {}, getResult?: boolean): void | Observable<CommunityGroup[]> {
    this.store$.dispatch(CommunityGroupGeneratedActions.getManyCommunityGroups({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityGroupGeneratedActions.normalizeManyCommunityGroupsAfterUpsert,
        CommunityGroupGeneratedActions.communityGroupsFailure
      );
    }
  }

  public upsertOneCommunityGroup(
    communityGroup: Partial<CommunityGroup>,
    ids: CommunityGroupRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CommunityGroup> {
    this.store$.dispatch(CommunityGroupGeneratedActions.upsertOneCommunityGroup({ communityGroup, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityGroupGeneratedActions.normalizeManyCommunityGroupsAfterUpsert,
        CommunityGroupGeneratedActions.communityGroupsFailure,
        true
      );
    }
  }

  public deleteOneCommunityGroup(idCommunityGroup: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CommunityGroupGeneratedActions.deleteOneCommunityGroup({ idCommunityGroup }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityGroupGeneratedActions.deleteOneCommunityGroupSuccess,
        CommunityGroupGeneratedActions.communityGroupsFailure
      );
    }
  }

  public setActiveCommunityGroups(idCommunityGroups: number[]): void {
    this.store$.dispatch(CommunityGroupGeneratedActions.clearActivesCommunityGroups());
    this.store$.dispatch(CommunityGroupGeneratedActions.addManyActivesCommunityGroups({ idCommunityGroups }));
  }
}
