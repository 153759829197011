import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedProfilApiService } from './profil-api-generated.service';
import { Profil, ReorderProfilInterface } from '@api/api-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfilApiService extends GeneratedProfilApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public reorderProfils(params?: ReorderProfilInterface): Observable<Profil[]> {
    return this.repo.update<Profil[]>('profil/reorder', params);
  }

  public duplicateProfil(idProfil: number): Observable<Profil> {
    return this.repo.create<Profil>('profil/duplicate', { idProfil });
  }
}
