import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@wip/store/configs/reducers';
import { GeneratedChildrenElementService } from './children-element-generated.service';
import { ChildrenElementGeneratedActions } from '@wip/store/actions';
import { ChildrenElementActions } from '@wip/store/actions';
import { Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchApiActions } from '@wip/store/utils';

@Injectable({
  providedIn: 'root'
})
export class ChildrenElementService extends GeneratedChildrenElementService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public deleteOneChildrenElementCustom(
    params: { idChildrenElement: number; idCommunity: number },
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ChildrenElementActions.deleteOneChildrenElementCustom({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ChildrenElementGeneratedActions.deleteOneChildrenElementSuccess,
        ChildrenElementGeneratedActions.childrenElementsFailure
      );
    }
  }
}
