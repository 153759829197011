import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedUserDeviceApiService } from './user-device-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UserDeviceApiService extends GeneratedUserDeviceApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
