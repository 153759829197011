import { RepositoryService } from '@wip/services/repository';
import { CommunityMilestoneFamily } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityMilestoneFamilyApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityMilestoneFamilys(params?: any): Observable<CommunityMilestoneFamily[]> {
    return this.repo.getData<CommunityMilestoneFamily[]>('community-milestone-family', params);
  }

  public getCommunityMilestoneFamily(params: {
    idCommunityMilestoneFamily: number;
    params?: any;
  }): Observable<CommunityMilestoneFamily> {
    return this.repo.getData<CommunityMilestoneFamily>(
      'community-milestone-family/' + params.idCommunityMilestoneFamily,
      params.params
    );
  }

  public addCommunityMilestoneFamily(
    communityMilestoneFamily: Partial<CommunityMilestoneFamily>
  ): Observable<CommunityMilestoneFamily> {
    return this.repo.create<CommunityMilestoneFamily>('community-milestone-family', communityMilestoneFamily);
  }

  public updateCommunityMilestoneFamily(
    communityMilestoneFamily: Partial<CommunityMilestoneFamily>
  ): Observable<CommunityMilestoneFamily> {
    return this.repo.update('community-milestone-family', communityMilestoneFamily);
  }

  public deleteCommunityMilestoneFamily(idCommunityMilestoneFamily: number): Observable<number> {
    return this.repo.delete('community-milestone-family/' + +idCommunityMilestoneFamily);
  }
}
