<span class="flex flex-row justify-start items-center">
  @if (!fromAdminPage) {
    <span class="form-title-separator">Documents</span>
  }
</span>
<div (fileDrop)="addFiles($event)" style="margin-bottom: 4px" appFileDrop class="document-form">
  @for (file of files; track file; let i = $index) {
    <div>
      <app-document-list-item
        (delete)="removeItem(i)"
        (download)="downloadFile(i)"
        (preview)="showFile(i)"
        [document]="file.document"
        [isLoading]="file.isLoading"
        [isViewer]="isViewer"
      >
      </app-document-list-item>
    </div>
  }
</div>
<div class="flex flex-row justify-start">
  @if (!fromAdminPage && !isViewer) {
    <button (click)="browseFiles()" mat-stroked-button color="accent" class="option">Ajouter</button>
  }
  <input #fileInput (change)="addFiles($event)" multiple type="file" />
  @if (uploading) {
    <mat-spinner [diameter]="24" />
  }
</div>
