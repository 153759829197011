import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunitySubFamily, CommunitySubFamilyEntityState } from '@api/api-interfaces';
import { OrganizationSubFamily, OrganizationSubFamilyEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunitySubFamilyState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communitySubFamilyRelations: string[] = ['organizationSubFamilys', 'communities'];

export const { selectEntities, selectAll } = CommunitySubFamilyState.adapter.getSelectors();

export const selectCommunitySubFamilyState = createFeatureSelector<CommunitySubFamilyState.IState>(
  CommunitySubFamilyState.communitySubFamilyFeatureKey
);

export const selectIsLoadedCommunitySubFamily = createSelector(
  selectCommunitySubFamilyState,
  (state: CommunitySubFamilyState.IState) => state.isLoaded
);

export const selectIsLoadingCommunitySubFamily = createSelector(
  selectCommunitySubFamilyState,
  (state: CommunitySubFamilyState.IState) => state.isLoading
);

export const selectIsReadyCommunitySubFamily = createSelector(
  selectCommunitySubFamilyState,
  (state: CommunitySubFamilyState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunitySubFamily = createSelector(
  selectCommunitySubFamilyState,
  (state: CommunitySubFamilyState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunitySubFamilysEntities = createSelector(selectCommunitySubFamilyState, selectEntities);

export const selectCommunitySubFamilysArray = createSelector(selectCommunitySubFamilyState, selectAll);

export const selectIdCommunitySubFamilysActive = createSelector(
  selectCommunitySubFamilyState,
  (state: CommunitySubFamilyState.IState) => state.actives
);

const communitySubFamilysInObject = (communitySubFamilys: Dictionary<CommunitySubFamilyEntityState>) => ({
  communitySubFamilys
});

const selectCommunitySubFamilysEntitiesDictionary = createSelector(
  selectCommunitySubFamilysEntities,
  communitySubFamilysInObject
);

const selectAllCommunitySubFamilysObject = createSelector(selectCommunitySubFamilysEntities, communitySubFamilys => {
  return hydrateAll({ communitySubFamilys });
});

const selectOneCommunitySubFamilyDictionary = (idCommunitySubFamily: number) =>
  createSelector(selectCommunitySubFamilysEntities, communitySubFamilys => ({
    communitySubFamilys: { [idCommunitySubFamily]: communitySubFamilys[idCommunitySubFamily] }
  }));

const selectOneCommunitySubFamilyDictionaryWithoutChild = (idCommunitySubFamily: number) =>
  createSelector(selectCommunitySubFamilysEntities, communitySubFamilys => ({
    communitySubFamily: communitySubFamilys[idCommunitySubFamily]
  }));

const selectActiveCommunitySubFamilysEntities = createSelector(
  selectIdCommunitySubFamilysActive,
  selectCommunitySubFamilysEntities,
  (actives: number[], communitySubFamilys: Dictionary<CommunitySubFamilyEntityState>) =>
    getCommunitySubFamilysFromActives(actives, communitySubFamilys)
);

function getCommunitySubFamilysFromActives(
  actives: number[],
  communitySubFamilys: Dictionary<CommunitySubFamilyEntityState>
): Dictionary<CommunitySubFamilyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communitySubFamilys[idActive]) {
      acc[idActive] = communitySubFamilys[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunitySubFamilyEntityState>);
}

const selectAllCommunitySubFamilysSelectors: Dictionary<Selector> = {};
export function selectAllCommunitySubFamilys(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunitySubFamily>(
      schema,
      selectAllCommunitySubFamilysSelectors,
      selectCommunitySubFamilysEntitiesDictionary,
      getRelationSelectors,
      communitySubFamilyRelations,
      hydrateAll,
      'communitySubFamily'
    );
  } else {
    return selectAllCommunitySubFamilysObject;
  }
}

export function selectAllCommunitySubFamilysDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communitySubFamilys'
): Selector {
  return createSelector(selectAllCommunitySubFamilys(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunitySubFamilyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communitySubFamilys.length; i++) {
      res[customKey][result.communitySubFamilys[i].idCommunitySubFamily] = result.communitySubFamilys[i];
    }
    return res;
  });
}

export function selectOneCommunitySubFamily(schema: SelectSchema = {}, idCommunitySubFamily: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunitySubFamilyDictionary(idCommunitySubFamily)];
    selectors.push(...getRelationSelectors(schema, communitySubFamilyRelations, 'communitySubFamily'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunitySubFamilyDictionaryWithoutChild(idCommunitySubFamily);
  }
}

export function selectActiveCommunitySubFamilys(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunitySubFamilysEntities, communitySubFamilys => ({
      communitySubFamilys
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communitySubFamilyRelations, 'communitySubFamily'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communitySubFamilys: Dictionary<CommunitySubFamilyEntityState>;
  organizationSubFamilys?: Dictionary<OrganizationSubFamilyEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communitySubFamilys: (CommunitySubFamily | null)[] } {
  const { communitySubFamilys, organizationSubFamilys, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communitySubFamilys: Object.keys(communitySubFamilys).map(idCommunitySubFamily =>
      hydrate(
        communitySubFamilys[idCommunitySubFamily] as CommunitySubFamilyEntityState,
        organizationSubFamilys,
        communities
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communitySubFamily: CommunitySubFamilyEntityState | null } {
  const { communitySubFamilys, organizationSubFamilys, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communitySubFamily = Object.values(communitySubFamilys)[0];
  return {
    communitySubFamily: hydrate(
      communitySubFamily as CommunitySubFamilyEntityState,
      organizationSubFamilys,
      communities
    )
  };
}

function hydrate(
  communitySubFamily: CommunitySubFamilyEntityState,
  organizationSubFamilyEntities?: Dictionary<OrganizationSubFamilyEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>
): CommunitySubFamily | null {
  if (!communitySubFamily) {
    return null;
  }

  const communitySubFamilyHydrated: CommunitySubFamilyEntityState = { ...communitySubFamily };
  if (organizationSubFamilyEntities) {
    communitySubFamilyHydrated.organizationSubFamily = organizationSubFamilyEntities[
      communitySubFamily.organizationSubFamily as number
    ] as OrganizationSubFamily;
  } else {
    delete communitySubFamilyHydrated.organizationSubFamily;
  }
  if (communityEntities) {
    communitySubFamilyHydrated.community = communityEntities[communitySubFamily.community as number] as Community;
  } else {
    delete communitySubFamilyHydrated.community;
  }

  return communitySubFamilyHydrated as CommunitySubFamily;
}
