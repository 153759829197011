import { RepositoryService } from '@wip/services/repository';
import { Todo } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getTodos(params?: any): Observable<Todo[]> {
    return this.repo.getData<Todo[]>('todo', params);
  }

  public getTodo(params: { idTodo: number; params?: any }): Observable<Todo> {
    return this.repo.getData<Todo>('todo/' + params.idTodo, params.params);
  }

  public addTodo(todo: Partial<Todo>): Observable<Todo> {
    return this.repo.create<Todo>('todo', todo);
  }

  public updateTodo(todo: Partial<Todo>): Observable<Todo> {
    return this.repo.update('todo', todo);
  }

  public deleteTodo(idTodo: number): Observable<number> {
    return this.repo.delete('todo/' + +idTodo);
  }
}
