import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommunityModuleApiService } from '@wip/store/api-services';
import { GeneratedCommunityModuleEffects } from './community-module-generated.effects';

@Injectable()
export class CommunityModuleEffects extends GeneratedCommunityModuleEffects {
  constructor(actions$: Actions, communityModuleApiService: CommunityModuleApiService, store$: Store<AppState>) {
    super(actions$, communityModuleApiService, store$);
  }
}
