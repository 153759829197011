<div mat-dialog-title>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
  <h1>Sélectionner une heure</h1>
</div>
<form [formGroup]="timeInputGroup">
  <div mat-dialog-content class="flex justify-center items-center">
    <div class="flex flex-col justify-center items-center">
      <mat-form-field>
        <input
          matInput
          autocomplete="off"
          type="time"
          formControlName="time"
          pattern="([1]?[0-9]|2[0-3]):[0-5][0-9]"
          min="00:00"
          max="23:59"
          step="900"
          #inputElement
        />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="button" (click)="none()">Aucune</button>
    <button mat-raised-button type="submit" (click)="validate()" color="primary" tabindex="0">Ok</button>
  </div>
</form>
