import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationUserProfilApiService } from '@wip/store/api-services';
import { GeneratedOrganizationUserProfilEffects } from './organization-user-profil-generated.effects';

@Injectable()
export class OrganizationUserProfilEffects extends GeneratedOrganizationUserProfilEffects {
  constructor(
    actions$: Actions,
    organizationUserProfilApiService: OrganizationUserProfilApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationUserProfilApiService, store$);
  }
}
