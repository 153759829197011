import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationProjectModuleApiService } from './organization-project-module-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationProjectModuleApiService extends GeneratedOrganizationProjectModuleApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}

