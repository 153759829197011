import { createEntityAdapter } from '@ngrx/entity';
import { CommunityUserEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<CommunityUserEntityState>;

export const adapter = createEntityAdapter<CommunityUserEntityState>({
  selectId: o => o.idCommunityUser
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const communityUserFeatureKey = 'communityUser';
