import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ElementGeneratedActions } from '@wip/store/actions';
import { ElementState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { ElementEntityState } from '@api/api-interfaces';

export const elementReducersGeneratedFunctions: ReducerTypes<ElementState.IState, readonly ActionCreator[]>[] = [
  on(ElementGeneratedActions.getOneElement, (state: ElementState.IState) => setLoadingsState(state, true)),
  on(ElementGeneratedActions.getManyElements, (state: ElementState.IState) => setLoadingsState(state, true)),
  on(ElementGeneratedActions.upsertOneElement, (state: ElementState.IState) => setLoadingsState(state, true)),

  on(ElementGeneratedActions.upsertManyElementsSuccess, (state: ElementState.IState, { elements }) =>
    ElementState.adapter.upsertMany(elements, setLoadingsState(state, false))
  ),
  on(ElementGeneratedActions.deleteOneElement, (state: ElementState.IState) => setLoadingsState(state, true)),
  on(ElementGeneratedActions.deleteOneElementSuccess, (state: ElementState.IState, { idElement }) =>
    ElementState.adapter.removeOne(idElement, setLoadingsState(state, false))
  ),
  on(ElementGeneratedActions.clearActivesElements, (state: ElementState.IState) => ({ ...state, actives: [] })),
  on(ElementGeneratedActions.addManyActivesElements, (state: ElementState.IState, { idElements }) => ({
    ...state,
    actives: state.actives.concat(idElements)
  })),
  on(ElementGeneratedActions.deleteOneActiveElement, (state: ElementState.IState, { idElement }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idElement)
  })),

  on(ElementGeneratedActions.clearElements, () => ElementState.initialState),

  on(ElementGeneratedActions.addManyTodoElementSuccess, (state: ElementState.IState, { idElement, idTodoElements }) => {
    if (!state.entities[idElement]) {
      return state;
    }
    const todoElements = (state.entities[idElement]?.todoElements as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idElement]: {
          ...state.entities[idElement],
          todoElements: todoElements.concat(idTodoElements.filter(id => todoElements.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    ElementGeneratedActions.deleteManyTodoElementSuccess,
    (state: ElementState.IState, { idTodoElements, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.todoElements) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              todoElements: (state.entities[idElement]?.todoElements as number[])?.filter(
                (idTodoElement: number) => !idTodoElements.some((id: number) => id === idTodoElement)
              )
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  ),

  on(ElementGeneratedActions.addManyElementCashSuccess, (state: ElementState.IState, { idElement, idElementCashs }) => {
    if (!state.entities[idElement]) {
      return state;
    }
    const elementCashs = (state.entities[idElement]?.elementCashs as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idElement]: {
          ...state.entities[idElement],
          elementCashs: elementCashs.concat(idElementCashs.filter(id => elementCashs.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    ElementGeneratedActions.deleteManyElementCashSuccess,
    (state: ElementState.IState, { idElementCashs, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.elementCashs) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              elementCashs: (state.entities[idElement]?.elementCashs as number[])?.filter(
                (idElementCash: number) => !idElementCashs.some((id: number) => id === idElementCash)
              )
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.addManyMeetingElementSuccess,
    (state: ElementState.IState, { idElement, idMeetingElements }) => {
      if (!state.entities[idElement]) {
        return state;
      }
      const meetingElements = (state.entities[idElement]?.meetingElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElement]: {
            ...state.entities[idElement],
            meetingElements: meetingElements.concat(idMeetingElements.filter(id => meetingElements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.deleteManyMeetingElementSuccess,
    (state: ElementState.IState, { idMeetingElements, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.meetingElements) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              meetingElements: (state.entities[idElement]?.meetingElements as number[])?.filter(
                (idMeetingElement: number) => !idMeetingElements.some((id: number) => id === idMeetingElement)
              )
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.addManyTimelineElementSuccess,
    (state: ElementState.IState, { idElement, idTimelineElements }) => {
      if (!state.entities[idElement]) {
        return state;
      }
      const timelineElements = (state.entities[idElement]?.timelineElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElement]: {
            ...state.entities[idElement],
            timelineElements: timelineElements.concat(idTimelineElements.filter(id => timelineElements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.deleteManyTimelineElementSuccess,
    (state: ElementState.IState, { idTimelineElements, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.timelineElements) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              timelineElements: (state.entities[idElement]?.timelineElements as number[])?.filter(
                (idTimelineElement: number) => !idTimelineElements.some((id: number) => id === idTimelineElement)
              )
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.addManyFolderElementSuccess,
    (state: ElementState.IState, { idElement, idFolderElements }) => {
      if (!state.entities[idElement]) {
        return state;
      }
      const folderElements = (state.entities[idElement]?.folderElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElement]: {
            ...state.entities[idElement],
            folderElements: folderElements.concat(idFolderElements.filter(id => folderElements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.deleteManyFolderElementSuccess,
    (state: ElementState.IState, { idFolderElements, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.folderElements) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              folderElements: (state.entities[idElement]?.folderElements as number[])?.filter(
                (idFolderElement: number) => !idFolderElements.some((id: number) => id === idFolderElement)
              )
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.addManyProjectElementSuccess,
    (state: ElementState.IState, { idElement, idProjectElements }) => {
      if (!state.entities[idElement]) {
        return state;
      }
      const projectElements = (state.entities[idElement]?.projectElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElement]: {
            ...state.entities[idElement],
            projectElements: projectElements.concat(idProjectElements.filter(id => projectElements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.deleteManyProjectElementSuccess,
    (state: ElementState.IState, { idProjectElements, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.projectElements) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              projectElements: (state.entities[idElement]?.projectElements as number[])?.filter(
                (idProjectElement: number) => !idProjectElements.some((id: number) => id === idProjectElement)
              )
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.addManyOrganizationElementSuccess,
    (state: ElementState.IState, { idElement, idOrganizationElements }) => {
      if (!state.entities[idElement]) {
        return state;
      }
      const organizationElements = (state.entities[idElement]?.organizationElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElement]: {
            ...state.entities[idElement],
            organizationElements: organizationElements.concat(
              idOrganizationElements.filter(id => organizationElements.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.deleteManyOrganizationElementSuccess,
    (state: ElementState.IState, { idOrganizationElements, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.organizationElements) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              organizationElements: (state.entities[idElement]?.organizationElements as number[])?.filter(
                (idOrganizationElement: number) =>
                  !idOrganizationElements.some((id: number) => id === idOrganizationElement)
              )
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.addManyChildrenElementSuccess,
    (state: ElementState.IState, { idElement, idChildrenElements }) => {
      if (!state.entities[idElement]) {
        return state;
      }
      const childrenElements = (state.entities[idElement]?.childrenElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElement]: {
            ...state.entities[idElement],
            childrenElements: childrenElements.concat(idChildrenElements.filter(id => childrenElements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.deleteManyChildrenElementSuccess,
    (state: ElementState.IState, { idChildrenElements, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.childrenElements) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              childrenElements: (state.entities[idElement]?.childrenElements as number[])?.filter(
                (idChildrenElement: number) => !idChildrenElements.some((id: number) => id === idChildrenElement)
              )
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.addManyElementLibrarySuccess,
    (state: ElementState.IState, { idElement, idElementLibraries }) => {
      if (!state.entities[idElement]) {
        return state;
      }
      const elementLibraries = (state.entities[idElement]?.elementLibraries as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElement]: {
            ...state.entities[idElement],
            elementLibraries: elementLibraries.concat(idElementLibraries.filter(id => elementLibraries.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.deleteManyElementLibrarySuccess,
    (state: ElementState.IState, { idElementLibraries, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.elementLibraries) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              elementLibraries: (state.entities[idElement]?.elementLibraries as number[])?.filter(
                (idElementLibrary: number) => !idElementLibraries.some((id: number) => id === idElementLibrary)
              )
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  ),

  on(ElementGeneratedActions.addManyTodoSuccess, (state: ElementState.IState, { idElement, idTodos }) => {
    if (!state.entities[idElement]) {
      return state;
    }
    const todos = (state.entities[idElement]?.todos as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idElement]: {
          ...state.entities[idElement],
          todos: todos.concat(idTodos.filter(id => todos.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ElementGeneratedActions.deleteManyTodoSuccess, (state: ElementState.IState, { idTodos, idElements }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idElements.reduce((entities, idElement) => {
          if (!state.entities[idElement]?.todos) {
            return entities;
          }
          entities[idElement] = {
            ...state.entities[idElement],
            todos: (state.entities[idElement]?.todos as number[])?.filter(
              (idTodo: number) => !idTodos.some((id: number) => id === idTodo)
            )
          } as ElementEntityState;
          return entities;
        }, {} as Dictionary<ElementEntityState>)
      }
    };
  }),

  on(
    ElementGeneratedActions.addOrganizationMilestoneSuccess,
    (state: ElementState.IState, { idElement, idOrganizationMilestone }) => {
      if (!state.entities[idElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElement]: {
            ...state.entities[idElement],
            organizationMilestone: idOrganizationMilestone
          }
        }
      };
    }
  ),

  on(
    ElementGeneratedActions.deleteManyOrganizationMilestoneSuccess,
    (state: ElementState.IState, { idOrganizationMilestones, idElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElements.reduce((entities, idElement) => {
            if (!state.entities[idElement]?.organizationMilestone) {
              return entities;
            }
            entities[idElement] = {
              ...state.entities[idElement],
              organizationMilestone: idOrganizationMilestones.some(
                (idOrganizationMilestone: number) =>
                  idOrganizationMilestone === state.entities[idElement]?.organizationMilestone
              )
                ? undefined
                : state.entities[idElement]?.organizationMilestone
            } as ElementEntityState;
            return entities;
          }, {} as Dictionary<ElementEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ElementState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ElementState.IState {
  return { ...state, isLoaded, isLoading };
}
