import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedCommunityGroupApiService } from './community-group-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityGroupApiService extends GeneratedCommunityGroupApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public deleteCommunityGroupAndUsers(idCommunity: number, idGroup: number): Observable<number> {
    return this.repo.delete('community-group/deleteByCommunity/' + +idCommunity + '/' + +idGroup);
  }
}
