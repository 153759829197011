import { createEntityAdapter } from '@ngrx/entity';
import { ProfilEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<ProfilEntityState>;

export const adapter = createEntityAdapter<ProfilEntityState>({
  selectId: o => o.idProfil
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const profilFeatureKey = 'profil';
