import { EventEmitter, Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class DocumentViewService {
  public router: Router;
  public route: ActivatedRoute;
  public sidenav: MatSidenav;
  public sortTabGroupIndex$: EventEmitter<number>;
  public folderTabGroupIndex$: EventEmitter<number>;

  constructor() {}

  setRouteParams(
    tabIndex?: number,
    folderID?: number | string,
    folderElementID?: number,
    sortBy?: string,
    sort?: string,
    tagID?: number,
    searchString?: string
  ) {
    this.router.navigate(['.'], {
      queryParams: {
        index: tabIndex,
        id: folderID,
        edit: folderElementID,
        tag: tagID,
        sortBy: sortBy,
        order: sort,
        search: searchString
      },
      replaceUrl: true,
      relativeTo: this.route
    });
  }

  openSideNav() {
    this.sidenav.open();
  }

  closeSidenav() {
    this.sidenav.close();
  }
}
