import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { FolderElement, FolderElementEntityState } from '@api/api-interfaces';
import { FolderElementApiService } from '@wip/store/api-services';
import { FolderElementGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeFolderElement } from '@wip/store/configs/normalization';
import { FolderElementSelectors } from '@wip/store/selectors';
import { FolderElementRelationsIds } from '@wip/store/ids-interfaces';
import { FolderGeneratedActions } from '@wip/store/actions';
import { ElementGeneratedActions } from '@wip/store/actions';

export function getDefaultAddFolderElementActions(
  folderElement: FolderElementEntityState,
  ids?: FolderElementRelationsIds
): Action[] {
  const actions: Action[] = [
    FolderElementGeneratedActions.normalizeManyFolderElementsAfterUpsert({ folderElements: [folderElement] })
  ];

  if (ids?.folder) {
    actions.push(
      FolderGeneratedActions.addManyFolderElementSuccess({
        idFolder: ids.folder,
        idFolderElements: [folderElement.idFolderElement]
      })
    );
    actions.push(
      FolderElementGeneratedActions.addFolderSuccess({
        idFolderElement: folderElement.idFolderElement,
        idFolder: ids.folder
      })
    );
  }

  if (ids?.element) {
    actions.push(
      ElementGeneratedActions.addManyFolderElementSuccess({
        idElement: ids.element,
        idFolderElements: [folderElement.idFolderElement]
      })
    );
    actions.push(
      FolderElementGeneratedActions.addElementSuccess({
        idFolderElement: folderElement.idFolderElement,
        idElement: ids.element
      })
    );
  }

  return actions;
}

export function getDefaultDeleteFolderElementActions(folderElement: FolderElementEntityState): Action[] {
  const actions: Action[] = [
    FolderElementGeneratedActions.deleteOneFolderElementSuccess({ idFolderElement: folderElement.idFolderElement })
  ];

  if (folderElement.folder) {
    actions.push(
      FolderGeneratedActions.deleteManyFolderElementSuccess({
        idFolderElements: [folderElement.idFolderElement],
        idFolders: [folderElement.folder as number]
      })
    );
  }

  if (folderElement.element) {
    actions.push(
      ElementGeneratedActions.deleteManyFolderElementSuccess({
        idFolderElements: [folderElement.idFolderElement],
        idElements: [folderElement.element as number]
      })
    );
  }

  return actions;
}

export class GeneratedFolderElementEffects {
  constructor(
    protected actions$: Actions,
    protected folderElementApiService: FolderElementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyFolderElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FolderElementGeneratedActions.getManyFolderElements),
      switchMap(({ params }) =>
        this.folderElementApiService.getFolderElements(params).pipe(
          map((folderElements: FolderElement[]) => {
            return FolderElementGeneratedActions.normalizeManyFolderElementsAfterUpsert({ folderElements });
          }),
          catchError(error => of(FolderElementGeneratedActions.folderElementsFailure({ error })))
        )
      )
    );
  });

  getOneFolderElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FolderElementGeneratedActions.getOneFolderElement),
      switchMap(idFolderElement =>
        this.folderElementApiService.getFolderElement(idFolderElement).pipe(
          map((folderElement: FolderElement) => {
            return FolderElementGeneratedActions.normalizeManyFolderElementsAfterUpsert({
              folderElements: [folderElement]
            });
          }),
          catchError(error => of(FolderElementGeneratedActions.folderElementsFailure({ error })))
        )
      )
    );
  });

  upsertOneFolderElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FolderElementGeneratedActions.upsertOneFolderElement),
      concatMap(
        ({ folderElement, ids }: { folderElement: Partial<FolderElement>; ids?: FolderElementRelationsIds }) => {
          if (folderElement.idFolderElement) {
            return this.folderElementApiService.updateFolderElement(folderElement).pipe(
              map((folderElementReturned: FolderElement) => {
                return FolderElementGeneratedActions.normalizeManyFolderElementsAfterUpsert({
                  folderElements: [folderElementReturned]
                });
              }),
              catchError(error => of(FolderElementGeneratedActions.folderElementsFailure({ error })))
            );
          } else {
            return this.folderElementApiService.addFolderElement(folderElement).pipe(
              mergeMap((folderElementReturned: FolderElement) =>
                getDefaultAddFolderElementActions(folderElementReturned, ids)
              ),
              catchError(error => of(FolderElementGeneratedActions.folderElementsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneFolderElement$ = createEffect(() => {
    const selectFolderElementState$ = this.store$.select(FolderElementSelectors.selectFolderElementState);
    return this.actions$.pipe(
      ofType(FolderElementGeneratedActions.deleteOneFolderElement),
      withLatestFrom(selectFolderElementState$),
      concatMap(([{ idFolderElement }, state]) =>
        this.folderElementApiService.deleteFolderElement(idFolderElement).pipe(
          mergeMap(_success =>
            getDefaultDeleteFolderElementActions(state.entities[idFolderElement] as FolderElementEntityState)
          ),
          catchError(error => of(FolderElementGeneratedActions.folderElementsFailure({ error })))
        )
      )
    );
  });

  normalizeManyFolderElementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FolderElementGeneratedActions.normalizeManyFolderElementsAfterUpsert),
      concatMap(({ folderElements }) => {
        const actions: Action[] = getActionsToNormalizeFolderElement(folderElements, StoreActionType.upsert);
        return [getMultiAction(actions, '[FolderElement] Normalization After Upsert Success')];
      })
    );
  });
}
