import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ElementCashGeneratedActions } from '@wip/store/actions';
import { ElementCashState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { ElementCashEntityState } from '@api/api-interfaces';

export const elementCashReducersGeneratedFunctions: ReducerTypes<ElementCashState.IState, readonly ActionCreator[]>[] =
  [
    on(ElementCashGeneratedActions.getOneElementCash, (state: ElementCashState.IState) =>
      setLoadingsState(state, true)
    ),
    on(ElementCashGeneratedActions.getManyElementCashs, (state: ElementCashState.IState) =>
      setLoadingsState(state, true)
    ),
    on(ElementCashGeneratedActions.upsertOneElementCash, (state: ElementCashState.IState) =>
      setLoadingsState(state, true)
    ),

    on(ElementCashGeneratedActions.upsertManyElementCashsSuccess, (state: ElementCashState.IState, { elementCashs }) =>
      ElementCashState.adapter.upsertMany(elementCashs, setLoadingsState(state, false))
    ),
    on(ElementCashGeneratedActions.deleteOneElementCash, (state: ElementCashState.IState) =>
      setLoadingsState(state, true)
    ),
    on(ElementCashGeneratedActions.deleteOneElementCashSuccess, (state: ElementCashState.IState, { idElementCash }) =>
      ElementCashState.adapter.removeOne(idElementCash, setLoadingsState(state, false))
    ),
    on(ElementCashGeneratedActions.clearActivesElementCashs, (state: ElementCashState.IState) => ({
      ...state,
      actives: []
    })),
    on(
      ElementCashGeneratedActions.addManyActivesElementCashs,
      (state: ElementCashState.IState, { idElementCashs }) => ({
        ...state,
        actives: state.actives.concat(idElementCashs)
      })
    ),
    on(ElementCashGeneratedActions.deleteOneActiveElementCash, (state: ElementCashState.IState, { idElementCash }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idElementCash)
    })),

    on(ElementCashGeneratedActions.clearElementCashs, () => ElementCashState.initialState),

    on(
      ElementCashGeneratedActions.addElementSuccess,
      (state: ElementCashState.IState, { idElementCash, idElement }) => {
        if (!state.entities[idElementCash]) {
          return state;
        }
        return {
          ...state,
          entities: {
            ...state.entities,
            [idElementCash]: {
              ...state.entities[idElementCash],
              element: idElement
            }
          }
        };
      }
    ),

    on(
      ElementCashGeneratedActions.deleteManyElementSuccess,
      (state: ElementCashState.IState, { idElements, idElementCashs }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idElementCashs.reduce((entities, idElementCash) => {
              if (!state.entities[idElementCash]?.element) {
                return entities;
              }
              entities[idElementCash] = {
                ...state.entities[idElementCash],
                element: idElements.some((idElement: number) => idElement === state.entities[idElementCash]?.element)
                  ? undefined
                  : state.entities[idElementCash]?.element
              } as ElementCashEntityState;
              return entities;
            }, {} as Dictionary<ElementCashEntityState>)
          }
        };
      }
    )
  ];

export function setLoadingsState(
  state: ElementCashState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ElementCashState.IState {
  return { ...state, isLoaded, isLoading };
}
