import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserBoardStateApiService } from '@wip/store/api-services';
import { GeneratedUserBoardStateEffects } from './user-board-state-generated.effects';

@Injectable()
export class UserBoardStateEffects extends GeneratedUserBoardStateEffects {
  constructor(actions$: Actions, userBoardStateApiService: UserBoardStateApiService, store$: Store<AppState>) {
    super(actions$, userBoardStateApiService, store$);
  }
}
