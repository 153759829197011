import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { ProfilRelationsIds } from '@wip/store/ids-interfaces';

export const ProfilGeneratedActions = createActionGroup({
  source: 'Profil Generated',
  events: {
    'Get One Profil': props<{ idProfil: number; params?: any }>(),
    'Get Many Profils': props<{ params: any }>(),
    'Add Many Actives Profils': props<{ idProfils: number[] }>(),
    'Delete One Active Profil': props<{ idProfil: number }>(),
    'Clear Actives Profils': emptyProps(),
    'Upsert One Profil': props<{ profil: Partial<Profil>; ids?: ProfilRelationsIds }>(),
    'Upsert Many Profils': props<{ profils: Partial<Profil>[]; ids?: ProfilRelationsIds }>(),
    'Upsert Many Profils Success': props<{ profils: ProfilEntityState[] }>(),
    'Delete One Profil': props<{ idProfil: number }>(),
    'Delete One Profil Success': props<{ idProfil: number }>(),
    'Normalize Many Profils After Upsert': props<{ profils: ProfilEntityState[] }>(),
    'Profils Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Profils': emptyProps(),
    'Add Many Right Profil Success': props<{ idProfil: number; idRightProfils: number[] }>(),
    'Delete Many Right Profil Success': props<{ idRightProfils: number[]; idProfils: number[] }>(),
    'Add Many User Right Success': props<{ idProfil: number; idUserRights: number[] }>(),
    'Delete Many User Right Success': props<{ idUserRights: number[]; idProfils: number[] }>(),
    'Add Many User Profil Right Success': props<{ idProfil: number; idUserProfilRights: number[] }>(),
    'Delete Many User Profil Right Success': props<{ idUserProfilRights: number[]; idProfils: number[] }>(),
    'Add Many Right Success': props<{ idProfil: number; idRights: number[] }>(),
    'Delete Many Right Success': props<{ idRights: number[]; idProfils: number[] }>(),
    'Add Many Organization User Profil Success': props<{ idProfil: number; idOrganizationUserProfils: number[] }>(),
    'Delete Many Organization User Profil Success': props<{
      idOrganizationUserProfils: number[];
      idProfils: number[];
    }>(),
    'Add Many Organization User Success': props<{ idProfil: number; idOrganizationUsers: number[] }>(),
    'Delete Many Organization User Success': props<{ idOrganizationUsers: number[]; idProfils: number[] }>(),
    'Add Organization Success': props<{ idProfil: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idProfils: number[] }>()
  }
});
