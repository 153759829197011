import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationFamily } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import * as organizationFamilyActions from '@wip/store/actions';
import { OrganizationFamilyApiService } from '@wip/store/api-services';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GeneratedOrganizationFamilyEffects } from './organization-family-generated.effects';

@Injectable()
export class OrganizationFamilyEffects extends GeneratedOrganizationFamilyEffects {
  constructor(actions$: Actions, organizationFamilyApiService: OrganizationFamilyApiService, store$: Store<AppState>) {
    super(actions$, organizationFamilyApiService, store$);
  }

  reorderFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationFamilyActions.reorderFamily),
      switchMap(params =>
        this.organizationFamilyApiService.reorder(params.value).pipe(
          map((organizationFamily: OrganizationFamily) => {
            return organizationFamilyActions.OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert(
              {
                organizationFamilys: [organizationFamily]
              }
            );
          }),
          catchError(error =>
            of(organizationFamilyActions.OrganizationFamilyGeneratedActions.organizationFamilysFailure({ error }))
          )
        )
      )
    );
  });
}
