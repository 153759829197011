<div [formGroup]="filtersGroup" class="flex flex-row flex-wrap gap-4 items-center" style="margin: 8px 0">
  <mat-checkbox formControlName="subFamilyRule" color="primary" style="margin-right: 24px"
    >Doit tout inclure</mat-checkbox
  >
  @for (hashtag of filteredOrganizationSubFamilies; track hashtag.idOrganizationSubFamily) {
    <div
      (click)="setHashtag(hashtag)"
      [ngStyle]="setHashtagStyle(hashtag)"
      class="flex flex-row justify-start items-center hand"
    >
      <span>{{ hashtag.libelle }}</span>
    </div>
  }
  <div
    [formGroup]="filtersGroup"
    class="my-projects-checkbox flex flex-row gap-8 items-center"
    style="margin-left: 16px"
  >
    @if (subFamilyControl?.value?.length) {
      <button (click)="eraseFilters()" style="height: 24px !important" mat-stroked-button color="accent">
        Réinitialiser
      </button>
    }
  </div>
</div>
