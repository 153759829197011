/* eslint-disable @nx/enforce-module-boundaries */
import { ListDocumentsDialogComponent } from '@_app/shared/dialogs/list-documents-dialog/list-documents-dialog.component';
import { ListProjectsDialogComponent } from '@_app/shared/dialogs/list-projects-dialog/list-projects-dialog.component';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Community, Element, OrganizationUser, TodoElement } from '@api/api-interfaces';
import {
  DroitInterneEnum,
  ElementStatusActionEnum,
  OrganizationElemTypeEnum,
  OrganizationFamilyOperationTypeEnumTradEToFR,
  OrganizationUserRoleEnum
} from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ChildrenElementModel,
  CommunityCaracteristicModel,
  CommunityUserProfilModel,
  ElementModel,
  OrganizationCaracteristicModel,
  OrganizationFamilyModel,
  OrganizationMilestoneModel,
  OrganizationUserProfilModel,
  ProfilModel,
  ProjectElementModel,
  RightModel,
  UserModel
} from '@wip/store/selectors-model';
import { CommunityService, ElementService, OrganizationUserService, UserService } from '@wip/store/services';
import { Observable, combineLatest, filter, map, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'wip-project-dashboard',
  templateUrl: './project-dashboard.container.html',
  styleUrls: ['./project-dashboard.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDashboardComponent implements OnInit {
  @Input() idCurrentCommunity: number;
  @Input() isFromDashboard: boolean;

  public wordingTypeStructure: OrganizationFamilyOperationTypeEnumTradEToFR;
  public community$: Observable<Community>;
  public community: Community;
  public idCommunity: number;
  public todosElement$: Observable<TodoElement>;
  public canEdit: boolean = undefined;
  public organizationUser$: Observable<OrganizationUser>;

  constructor(
    private newCommunityService: CommunityService,
    private newOrganizationUserService: OrganizationUserService,
    private newUserService: UserService,
    private newElementService: ElementService,
    private router: Router,
    public dialog: MatDialog
  ) {
    if (this.router.url.includes('development')) {
      const newUrl = this.router.url.replace('synthesis', 'caracteristics');
      this.router.navigate([newUrl]);
      this.wordingTypeStructure = OrganizationFamilyOperationTypeEnumTradEToFR.development;
    } else if (this.router.url.includes('project')) {
      this.wordingTypeStructure = OrganizationFamilyOperationTypeEnumTradEToFR.project;
    }
  }

  ngOnInit() {
    if (this.isFromDashboard) {
      this.newCommunityService.getOneCommunity(this.idCurrentCommunity);
    }
    combineLatest([
      this.newCommunityService.selectAllActiveCommunities().pipe(
        untilDestroyed(this),
        filter(c => !!c),
        map(comms => comms[0])
      ),
      (this.organizationUser$ = this.newOrganizationUserService
        .selectAllActiveOrganizationUsers({
          include: [{ model: ProfilModel, include: [RightModel] }]
        })
        .pipe(
          untilDestroyed(this),
          map(o => o[0])
        ))
    ])
      .pipe(
        untilDestroyed(this),
        tap(([community, orgaUser]: [Community, OrganizationUser]) => {
          if (this.newUserService.isRoot) {
            this.canEdit = true;
          } else if (orgaUser) {
            this.canEdit =
              orgaUser.role === OrganizationUserRoleEnum.admin ||
              orgaUser.role === OrganizationUserRoleEnum.superAdmin ||
              community?.communityRights?.crud.includes(DroitInterneEnum.elementUpdate);
          }
        })
      )
      .subscribe();
    if (this.idCurrentCommunity) {
      this.community$ = this.newCommunityService
        .selectOneCommunity(this.idCurrentCommunity, {
          include: [
            OrganizationFamilyModel,
            { model: CommunityCaracteristicModel, include: [OrganizationCaracteristicModel] },
            { model: CommunityUserProfilModel, include: [OrganizationUserProfilModel, UserModel] },
            {
              model: ProjectElementModel,
              include: [{ model: ElementModel, include: [ChildrenElementModel, OrganizationMilestoneModel] }]
            }
          ]
        })
        .pipe(untilDestroyed(this));
    } else {
      this.community$ = this.newCommunityService
        .selectAllActiveCommunities({
          include: [
            OrganizationFamilyModel,
            { model: CommunityCaracteristicModel, include: [OrganizationCaracteristicModel] },
            { model: CommunityUserProfilModel, include: [OrganizationUserProfilModel, UserModel] },
            {
              model: ProjectElementModel,
              include: [{ model: ElementModel, include: [ChildrenElementModel, OrganizationMilestoneModel] }]
            }
          ]
        })
        .pipe(
          untilDestroyed(this),
          map(communities => communities[0])
        );
      this.community$
        .pipe(
          untilDestroyed(this),
          tap(com => {
            this.idCommunity = com?.idCommunity;
            this.community = com;
          })
        )
        .subscribe();
    }
  }

  public updateElement(element: Partial<Element>) {
    if (this.canEdit) {
      this.newElementService.updateElement({ ...element, idCommunity: this.newCommunityService.currentIdCommunity });
    }
  }

  public updateStatus(element: Partial<Element>) {
    if (this.canEdit) {
      this.newElementService.upsertOneElement({
        idElement: element.idElement,
        rightNeeded: DroitInterneEnum.elementUpdate,
        statusAction:
          element.statusAction === ElementStatusActionEnum.open
            ? ElementStatusActionEnum.progress
            : element.statusAction === ElementStatusActionEnum.progress
              ? ElementStatusActionEnum.close
              : ElementStatusActionEnum.open
      });
    }
  }

  exportToDocx(multiple: boolean = false) {
    this.newCommunityService.exportToDocx(
      multiple,
      this.community,
      OrganizationElemTypeEnum.synthesis,
      ListProjectsDialogComponent,
      ListDocumentsDialogComponent
    );
  }
}
