import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityMilestone, CommunityMilestoneEntityState } from '@api/api-interfaces';
import { CommunityMilestoneApiService } from '@wip/store/api-services';
import { CommunityMilestoneGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityMilestone } from '@wip/store/configs/normalization';
import { CommunityMilestoneSelectors } from '@wip/store/selectors';
import { CommunityMilestoneRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { CommunityGeneratedActions } from '@wip/store/actions';

export function getDefaultAddCommunityMilestoneActions(
  communityMilestone: CommunityMilestoneEntityState,
  ids?: CommunityMilestoneRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityMilestoneGeneratedActions.normalizeManyCommunityMilestonesAfterUpsert({
      communityMilestones: [communityMilestone]
    })
  ];

  if (ids?.organizationMilestone) {
    actions.push(
      OrganizationMilestoneGeneratedActions.addManyCommunityMilestoneSuccess({
        idOrganizationMilestone: ids.organizationMilestone,
        idCommunityMilestones: [communityMilestone.idCommunityMilestone]
      })
    );
    actions.push(
      CommunityMilestoneGeneratedActions.addOrganizationMilestoneSuccess({
        idCommunityMilestone: communityMilestone.idCommunityMilestone,
        idOrganizationMilestone: ids.organizationMilestone
      })
    );
  }

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityMilestoneSuccess({
        idCommunity: ids.community,
        idCommunityMilestones: [communityMilestone.idCommunityMilestone]
      })
    );
    actions.push(
      CommunityMilestoneGeneratedActions.addCommunitySuccess({
        idCommunityMilestone: communityMilestone.idCommunityMilestone,
        idCommunity: ids.community
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCommunityMilestoneActions(communityMilestone: CommunityMilestoneEntityState): Action[] {
  const actions: Action[] = [
    CommunityMilestoneGeneratedActions.deleteOneCommunityMilestoneSuccess({
      idCommunityMilestone: communityMilestone.idCommunityMilestone
    })
  ];

  if (communityMilestone.organizationMilestone) {
    actions.push(
      OrganizationMilestoneGeneratedActions.deleteManyCommunityMilestoneSuccess({
        idCommunityMilestones: [communityMilestone.idCommunityMilestone],
        idOrganizationMilestones: [communityMilestone.organizationMilestone as number]
      })
    );
  }

  if (communityMilestone.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityMilestoneSuccess({
        idCommunityMilestones: [communityMilestone.idCommunityMilestone],
        idCommunities: [communityMilestone.community as number]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityMilestoneEffects {
  constructor(
    protected actions$: Actions,
    protected communityMilestoneApiService: CommunityMilestoneApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityMilestones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityMilestoneGeneratedActions.getManyCommunityMilestones),
      switchMap(({ params }) =>
        this.communityMilestoneApiService.getCommunityMilestones(params).pipe(
          map((communityMilestones: CommunityMilestone[]) => {
            return CommunityMilestoneGeneratedActions.normalizeManyCommunityMilestonesAfterUpsert({
              communityMilestones
            });
          }),
          catchError(error => of(CommunityMilestoneGeneratedActions.communityMilestonesFailure({ error })))
        )
      )
    );
  });

  getOneCommunityMilestone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityMilestoneGeneratedActions.getOneCommunityMilestone),
      switchMap(idCommunityMilestone =>
        this.communityMilestoneApiService.getCommunityMilestone(idCommunityMilestone).pipe(
          map((communityMilestone: CommunityMilestone) => {
            return CommunityMilestoneGeneratedActions.normalizeManyCommunityMilestonesAfterUpsert({
              communityMilestones: [communityMilestone]
            });
          }),
          catchError(error => of(CommunityMilestoneGeneratedActions.communityMilestonesFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityMilestone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityMilestoneGeneratedActions.upsertOneCommunityMilestone),
      concatMap(
        ({
          communityMilestone,
          ids
        }: {
          communityMilestone: Partial<CommunityMilestone>;
          ids?: CommunityMilestoneRelationsIds;
        }) => {
          if (communityMilestone.idCommunityMilestone) {
            return this.communityMilestoneApiService.updateCommunityMilestone(communityMilestone).pipe(
              map((communityMilestoneReturned: CommunityMilestone) => {
                return CommunityMilestoneGeneratedActions.normalizeManyCommunityMilestonesAfterUpsert({
                  communityMilestones: [communityMilestoneReturned]
                });
              }),
              catchError(error => of(CommunityMilestoneGeneratedActions.communityMilestonesFailure({ error })))
            );
          } else {
            return this.communityMilestoneApiService.addCommunityMilestone(communityMilestone).pipe(
              mergeMap((communityMilestoneReturned: CommunityMilestone) =>
                getDefaultAddCommunityMilestoneActions(communityMilestoneReturned, ids)
              ),
              catchError(error => of(CommunityMilestoneGeneratedActions.communityMilestonesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCommunityMilestone$ = createEffect(() => {
    const selectCommunityMilestoneState$ = this.store$.select(
      CommunityMilestoneSelectors.selectCommunityMilestoneState
    );
    return this.actions$.pipe(
      ofType(CommunityMilestoneGeneratedActions.deleteOneCommunityMilestone),
      withLatestFrom(selectCommunityMilestoneState$),
      concatMap(([{ idCommunityMilestone }, state]) =>
        this.communityMilestoneApiService.deleteCommunityMilestone(idCommunityMilestone).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityMilestoneActions(
              state.entities[idCommunityMilestone] as CommunityMilestoneEntityState
            )
          ),
          catchError(error => of(CommunityMilestoneGeneratedActions.communityMilestonesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityMilestonesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityMilestoneGeneratedActions.normalizeManyCommunityMilestonesAfterUpsert),
      concatMap(({ communityMilestones }) => {
        const actions: Action[] = getActionsToNormalizeCommunityMilestone(communityMilestones, StoreActionType.upsert);
        return [getMultiAction(actions, '[CommunityMilestone] Normalization After Upsert Success')];
      })
    );
  });
}
