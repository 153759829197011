import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationMilestoneGeneratedActions = createActionGroup({
  source: 'Organization Milestone Generated',
  events: {
    'Get One Organization Milestone': props<{ idOrganizationMilestone: number; params?: any }>(),
    'Get Many Organization Milestones': props<{ params: any }>(),
    'Add Many Actives Organization Milestones': props<{ idOrganizationMilestones: number[] }>(),
    'Delete One Active Organization Milestone': props<{ idOrganizationMilestone: number }>(),
    'Clear Actives Organization Milestones': emptyProps(),
    'Upsert One Organization Milestone': props<{
      organizationMilestone: Partial<OrganizationMilestone>;
      ids?: OrganizationMilestoneRelationsIds;
    }>(),
    'Upsert Many Organization Milestones': props<{
      organizationMilestones: Partial<OrganizationMilestone>[];
      ids?: OrganizationMilestoneRelationsIds;
    }>(),
    'Upsert Many Organization Milestones Success': props<{
      organizationMilestones: OrganizationMilestoneEntityState[];
    }>(),
    'Delete One Organization Milestone': props<{ idOrganizationMilestone: number }>(),
    'Delete One Organization Milestone Success': props<{ idOrganizationMilestone: number }>(),
    'Normalize Many Organization Milestones After Upsert': props<{
      organizationMilestones: OrganizationMilestoneEntityState[];
    }>(),
    'Organization Milestones Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Milestones': emptyProps(),
    'Add Many Community Milestone Success': props<{
      idOrganizationMilestone: number;
      idCommunityMilestones: number[];
    }>(),
    'Delete Many Community Milestone Success': props<{
      idCommunityMilestones: number[];
      idOrganizationMilestones: number[];
    }>(),
    'Add Many Organization Milestone Association Success': props<{
      idOrganizationMilestone: number;
      idOrganizationMilestoneAssociations: number[];
    }>(),
    'Delete Many Organization Milestone Association Success': props<{
      idOrganizationMilestoneAssociations: number[];
      idOrganizationMilestones: number[];
    }>(),
    'Add Many Element Success': props<{ idOrganizationMilestone: number; idElements: number[] }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idOrganizationMilestones: number[] }>(),
    'Add Many Organization Milestone Family Success': props<{
      idOrganizationMilestone: number;
      idOrganizationMilestoneFamilys: number[];
    }>(),
    'Delete Many Organization Milestone Family Success': props<{
      idOrganizationMilestoneFamilys: number[];
      idOrganizationMilestones: number[];
    }>(),
    'Add Organization Success': props<{ idOrganizationMilestone: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationMilestones: number[] }>(),
    'Add Organization Family Success': props<{ idOrganizationMilestone: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{
      idOrganizationFamilys: number[];
      idOrganizationMilestones: number[];
    }>()
  }
});
