import { OrganizationAzureState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationAzureReducersGeneratedFunctions } from './organization-azure-generated.reducer';

const organizationAzureReducersFunctions = [...organizationAzureReducersGeneratedFunctions];

const organizationAzureReducer = createReducer(
  OrganizationAzureState.initialState,
  ...organizationAzureReducersFunctions
);

export function reducer(state: OrganizationAzureState.IState | undefined, action: Action) {
  return organizationAzureReducer(state, action);
}
