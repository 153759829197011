import { createActionGroup, props } from '@ngrx/store';
import { OrganizationDataColumn } from '@api/api-interfaces';
import { OrganizationDataColumnRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationDataColumnActions = createActionGroup({
  source: 'Organization Data Column',
  events: {
    'Upsert Data Column': props<{
      organizationDataColumn: Partial<OrganizationDataColumn>;
      ids?: OrganizationDataColumnRelationsIds;
    }>()
  }
});
