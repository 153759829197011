import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationCaracteristicGeneratedActions = createActionGroup({
  source: 'Organization Caracteristic Generated',
  events: {
    'Get One Organization Caracteristic': props<{ idOrganizationCaracteristic: number; params?: any }>(),
    'Get Many Organization Caracteristics': props<{ params: any }>(),
    'Add Many Actives Organization Caracteristics': props<{ idOrganizationCaracteristics: number[] }>(),
    'Delete One Active Organization Caracteristic': props<{ idOrganizationCaracteristic: number }>(),
    'Clear Actives Organization Caracteristics': emptyProps(),
    'Upsert One Organization Caracteristic': props<{
      organizationCaracteristic: Partial<OrganizationCaracteristic>;
      ids?: OrganizationCaracteristicRelationsIds;
    }>(),
    'Upsert Many Organization Caracteristics': props<{
      organizationCaracteristics: Partial<OrganizationCaracteristic>[];
      ids?: OrganizationCaracteristicRelationsIds;
    }>(),
    'Upsert Many Organization Caracteristics Success': props<{
      organizationCaracteristics: OrganizationCaracteristicEntityState[];
    }>(),
    'Delete One Organization Caracteristic': props<{ idOrganizationCaracteristic: number }>(),
    'Delete One Organization Caracteristic Success': props<{ idOrganizationCaracteristic: number }>(),
    'Normalize Many Organization Caracteristics After Upsert': props<{
      organizationCaracteristics: OrganizationCaracteristicEntityState[];
    }>(),
    'Organization Caracteristics Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Caracteristics': emptyProps(),
    'Add Many Community Caracteristic Success': props<{
      idOrganizationCaracteristic: number;
      idCommunityCaracteristics: number[];
    }>(),
    'Delete Many Community Caracteristic Success': props<{
      idCommunityCaracteristics: number[];
      idOrganizationCaracteristics: number[];
    }>(),
    'Add Many Organization Milestone Family Success': props<{
      idOrganizationCaracteristic: number;
      idOrganizationMilestoneFamilys: number[];
    }>(),
    'Delete Many Organization Milestone Family Success': props<{
      idOrganizationMilestoneFamilys: number[];
      idOrganizationCaracteristics: number[];
    }>(),
    'Add Many Organization Caracteristic Column Success': props<{
      idOrganizationCaracteristic: number;
      idOrganizationCaracteristicColumns: number[];
    }>(),
    'Delete Many Organization Caracteristic Column Success': props<{
      idOrganizationCaracteristicColumns: number[];
      idOrganizationCaracteristics: number[];
    }>(),
    'Add Many Organization Caracteristic Row Success': props<{
      idOrganizationCaracteristic: number;
      idOrganizationCaracteristicRows: number[];
    }>(),
    'Delete Many Organization Caracteristic Row Success': props<{
      idOrganizationCaracteristicRows: number[];
      idOrganizationCaracteristics: number[];
    }>(),
    'Add Organization Success': props<{ idOrganizationCaracteristic: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationCaracteristics: number[] }>(),
    'Add Organization Family Success': props<{ idOrganizationCaracteristic: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{
      idOrganizationFamilys: number[];
      idOrganizationCaracteristics: number[];
    }>(),
    'Add Organization Data Column Success': props<{
      idOrganizationCaracteristic: number;
      idOrganizationDataColumn: number;
    }>(),
    'Delete Many Organization Data Column Success': props<{
      idOrganizationDataColumns: number[];
      idOrganizationCaracteristics: number[];
    }>()
  }
});
