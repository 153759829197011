import { Action, createReducer } from '@ngrx/store';
import { rightReducersGeneratedFunctions } from './right-generated.reducer';
import { RightState } from '@wip/store/states';

const rightReducersFunctions = [...rightReducersGeneratedFunctions];

const rightReducer = createReducer(RightState.initialState, ...rightReducersFunctions);

export function reducer(state: RightState.IState | undefined, action: Action) {
  return rightReducer(state, action);
}
