import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ChildrenElement } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { ChildrenElementRelationsIds } from '@wip/store/ids-interfaces';
import { ChildrenElementGeneratedActions } from '@wip/store/actions';
import { ChildrenElementSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedChildrenElementService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ChildrenElementSelectors.selectIsLoadedChildrenElement));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ChildrenElementSelectors.selectIsLoadingChildrenElement));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ChildrenElementSelectors.selectIsReadyAndLoadedChildrenElement as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllChildrenElements(schema: SelectSchema = {}): Observable<ChildrenElement[]> {
    return this.store$.pipe(select(ChildrenElementSelectors.selectAllChildrenElements(schema))).pipe(
      switchMap(({ childrenElements }: { childrenElements: ChildrenElement[] }) => {
        return this.getReady(schema).pipe(mapTo(childrenElements));
      })
    );
  }

  public selectOneChildrenElement(idChildrenElement: number, schema: SelectSchema = {}): Observable<ChildrenElement> {
    return this.store$.pipe(select(ChildrenElementSelectors.selectOneChildrenElement(schema, idChildrenElement))).pipe(
      switchMap(({ childrenElement }: { childrenElement: ChildrenElement }) => {
        return this.getReady(schema).pipe(mapTo(childrenElement));
      })
    );
  }

  public selectAllActiveChildrenElements(schema: SelectSchema = {}): Observable<ChildrenElement[]> {
    return this.store$.pipe(select(ChildrenElementSelectors.selectActiveChildrenElements(schema))).pipe(
      switchMap(({ childrenElements }: { childrenElements: ChildrenElement[] }) => {
        return this.getReady(schema).pipe(mapTo(childrenElements));
      })
    );
  }

  public selectIdChildrenElementsActive(): Observable<number[]> {
    return this.store$.pipe(select(ChildrenElementSelectors.selectIdChildrenElementsActive)).pipe(
      switchMap((idChildrenElements: number[]) => {
        return this.getReady().pipe(mapTo(idChildrenElements));
      })
    );
  }

  public getOneChildrenElement(
    idChildrenElement: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<ChildrenElement> {
    this.store$.dispatch(ChildrenElementGeneratedActions.getOneChildrenElement({ idChildrenElement, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ChildrenElementGeneratedActions.normalizeManyChildrenElementsAfterUpsert,
        ChildrenElementGeneratedActions.childrenElementsFailure,
        true
      );
    }
  }

  public getManyChildrenElements(params: any = {}, getResult?: boolean): void | Observable<ChildrenElement[]> {
    this.store$.dispatch(ChildrenElementGeneratedActions.getManyChildrenElements({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ChildrenElementGeneratedActions.normalizeManyChildrenElementsAfterUpsert,
        ChildrenElementGeneratedActions.childrenElementsFailure
      );
    }
  }

  public upsertOneChildrenElement(
    childrenElement: Partial<ChildrenElement>,
    ids: ChildrenElementRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ChildrenElement> {
    this.store$.dispatch(ChildrenElementGeneratedActions.upsertOneChildrenElement({ childrenElement, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ChildrenElementGeneratedActions.normalizeManyChildrenElementsAfterUpsert,
        ChildrenElementGeneratedActions.childrenElementsFailure,
        true
      );
    }
  }

  public deleteOneChildrenElement(idChildrenElement: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ChildrenElementGeneratedActions.deleteOneChildrenElement({ idChildrenElement }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ChildrenElementGeneratedActions.deleteOneChildrenElementSuccess,
        ChildrenElementGeneratedActions.childrenElementsFailure
      );
    }
  }

  public setActiveChildrenElements(idChildrenElements: number[]): void {
    this.store$.dispatch(ChildrenElementGeneratedActions.clearActivesChildrenElements());
    this.store$.dispatch(ChildrenElementGeneratedActions.addManyActivesChildrenElements({ idChildrenElements }));
  }
}
