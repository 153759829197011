import { RepositoryService } from '@wip/services/repository';
import { UserGroup } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserGroupApiService {
  constructor(protected repo: RepositoryService) {}

  public getUserGroups(params?: any): Observable<UserGroup[]> {
    return this.repo.getData<UserGroup[]>('user-group', params);
  }

  public getUserGroup(params: { idUserGroup: number; params?: any }): Observable<UserGroup> {
    return this.repo.getData<UserGroup>('user-group/' + params.idUserGroup, params.params);
  }

  public addUserGroup(userGroup: Partial<UserGroup>): Observable<UserGroup> {
    return this.repo.create<UserGroup>('user-group', userGroup);
  }

  public updateUserGroup(userGroup: Partial<UserGroup>): Observable<UserGroup> {
    return this.repo.update('user-group', userGroup);
  }

  public deleteUserGroup(idUserGroup: number): Observable<number> {
    return this.repo.delete('user-group/' + +idUserGroup);
  }
}
