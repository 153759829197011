export enum MediaScreenSize {
  'xs' = 'xs', // 'screen and (max-width: 599px)'
  'sm' = 'sm', // 'screen and (min-width: 600px) and (max-width: 959px)'
  'md' = 'md', // 'screen and (min-width: 960px) and (max-width: 1279px)'
  'lg' = 'lg', // 'screen and (min-width: 1280px) and (max-width: 1919px)'
  'xl' = 'xl', // 'screen and (min-width: 1920px) and (max-width: 5000px)'
  'ltSm' = 'lt-sm', // 'screen and (max-width: 599px)'
  'ltMd' = 'lt-md', // 'screen and (max-width: 959px)'
  'ltLg' = 'lt-lg', // 'screen and (max-width: 1279px)'
  'ltXl' = 'lt-xl', // 'screen and (max-width: 1919px)'
  'gtSm' = 'gt-sm', // 'screen and (min-width: 960px)'
  'gtXs' = 'gt-xs', // 'screen and (min-width: 600px)'
  'gtMd' = 'gt-md', // 'screen and (min-width: 1280px)'
  'gtLg' = 'gt-lg', // 'screen and (min-width: 1920px)'
}
