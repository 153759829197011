import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Community } from '@api/api-interfaces';
import * as communityActions from '@wip/store/actions';
import { AppState } from '@wip/store/configs/reducers';
import { catchError, concatMap, map, of, switchMap } from 'rxjs';
import { GeneratedCommunityEffects } from './community-generated.effects';
import { CommunityService } from '@wip/store/services';
import { CommunityApiService } from '@wip/store/api-services';

@Injectable()
export class CommunityEffects extends GeneratedCommunityEffects {
  constructor(
    actions$: Actions,
    communityApiService: CommunityApiService,
    store$: Store<AppState>,
    private readonly newCommunityService: CommunityService
  ) {
    super(actions$, communityApiService, store$);
  }

  getProjectsForSidelist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.getProjectsForSidelist),
      switchMap(params =>
        this.communityApiService
          .getProjectsForSidelist(params.idOrganization, params.idUser, params.operationType)
          .pipe(
            map((communities: Community[]) => {
              const indexToRemove = communities.findIndex(
                comm => comm.idCommunity === this.newCommunityService.currentIdCommunity
              );
              if (indexToRemove !== -1) {
                communities.splice(indexToRemove, 1);
              }
              return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities });
            }),
            catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
          )
      )
    );
  });

  duplicate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.duplicate),
      switchMap(params =>
        this.communityApiService.duplicate(params.idCommunitySrc, params.idCommunityDst).pipe(
          map((community: Community) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({
              communities: [community]
            });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  getCommunitiesForSidelist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.getCommunitiesForSidelist),
      switchMap(params =>
        this.communityApiService.getCommunitiesForSidelist(params.idOrganization, params.idUser).pipe(
          map((communities: Community[]) => {
            const indexToRemove = communities.findIndex(
              comm => comm.idCommunity === this.newCommunityService.currentIdCommunity
            );
            if (indexToRemove !== -1) {
              communities.splice(indexToRemove, 1);
            }
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  getConversationsForSidelist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.getConversationsForSidelist),
      switchMap(params =>
        this.communityApiService.getConversationsForSidelist(params.idUser).pipe(
          map((communities: Community[]) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  getProjectTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.getProjectTemplates),
      switchMap(params =>
        this.communityApiService.getProjectTemplates(params.idOrganization).pipe(
          map((communities: Community[]) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  updateManyCommunities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.updateManyCommunities),
      switchMap(params =>
        this.communityApiService.updateManyCommunities(params).pipe(
          map((communities: Community[]) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  getElementsForSynthesis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.getElementsForSynthesis),
      switchMap(params =>
        this.communityApiService
          .getElementsForSynthesis({
            idOrganization: params.idOrganization,
            idOrganizationFamily: params.idOrganizationFamily
          })
          .pipe(
            map((communities: Community[]) => {
              return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities });
            }),
            catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
          )
      )
    );
  });

  getPlanningInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.getPlanningInfo),
      switchMap(params =>
        this.communityApiService.getPlanningInfo(params.idOrganization).pipe(
          map((communities: Community[]) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  getPersonalSpace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.getPersonalSpace),
      switchMap(params =>
        this.communityApiService.getPersonalSpace(params.idUser).pipe(
          map((community: Community) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({
              communities: [community]
            });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  createCommunity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.createCommunity),
      concatMap(({ community, modules }: { community: Partial<Community>; modules: number[] }) => {
        return this.communityApiService.createCommunity({ community, modules }).pipe(
          map((communityReturned: Community) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({
              communities: [communityReturned]
            });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        );
      })
    );
  });

  createDirectMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.createDirectMessage),
      concatMap(({ community, listeUsersAdd }: { community: Partial<Community>; listeUsersAdd: number[] }) => {
        return this.communityApiService.createDirectMessage({ community, listeUsersAdd }).pipe(
          map((communityReturned: Community) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({
              communities: [communityReturned]
            });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        );
      })
    );
  });

  verifyProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.verifyProject),
      concatMap(({ idCommunity }: { idCommunity }) => {
        return this.communityApiService.verifyProject(idCommunity).pipe(
          map((communityReturned: Community) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({
              communities: [communityReturned]
            });
          }),
          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        );
      })
    );
  });

  bulkUpdateGeo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityActions.bulkUpdateGeo),
      switchMap(({ idOrganization, updateOnlyInseeCode }: { idOrganization: number; updateOnlyInseeCode: boolean }) => {
        return this.communityApiService.bulkUpdateGeo(idOrganization, updateOnlyInseeCode).pipe(
          map((communities: Community[]) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities });
          }),

          catchError(error => of(communityActions.CommunityGeneratedActions.communitiesFailure({ error })))
        );
      })
    );
  });
}
