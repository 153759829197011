import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationCaracteristicRow, OrganizationCaracteristicRowEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicRowRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationCaracteristicRowGeneratedActions = createActionGroup({
  source: 'Organization Caracteristic Row Generated',
  events: {
    'Get One Organization Caracteristic Row': props<{ idOrganizationCaracteristicRow: number; params?: any }>(),
    'Get Many Organization Caracteristic Rows': props<{ params: any }>(),
    'Add Many Actives Organization Caracteristic Rows': props<{ idOrganizationCaracteristicRows: number[] }>(),
    'Delete One Active Organization Caracteristic Row': props<{ idOrganizationCaracteristicRow: number }>(),
    'Clear Actives Organization Caracteristic Rows': emptyProps(),
    'Upsert One Organization Caracteristic Row': props<{
      organizationCaracteristicRow: Partial<OrganizationCaracteristicRow>;
      ids?: OrganizationCaracteristicRowRelationsIds;
    }>(),
    'Upsert Many Organization Caracteristic Rows': props<{
      organizationCaracteristicRows: Partial<OrganizationCaracteristicRow>[];
      ids?: OrganizationCaracteristicRowRelationsIds;
    }>(),
    'Upsert Many Organization Caracteristic Rows Success': props<{
      organizationCaracteristicRows: OrganizationCaracteristicRowEntityState[];
    }>(),
    'Delete One Organization Caracteristic Row': props<{ idOrganizationCaracteristicRow: number }>(),
    'Delete One Organization Caracteristic Row Success': props<{ idOrganizationCaracteristicRow: number }>(),
    'Normalize Many Organization Caracteristic Rows After Upsert': props<{
      organizationCaracteristicRows: OrganizationCaracteristicRowEntityState[];
    }>(),
    'Organization Caracteristic Rows Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Caracteristic Rows': emptyProps(),
    'Add Many Organization Caracteristic Value Success': props<{
      idOrganizationCaracteristicRow: number;
      idOrganizationCaracteristicValues: number[];
    }>(),
    'Delete Many Organization Caracteristic Value Success': props<{
      idOrganizationCaracteristicValues: number[];
      idOrganizationCaracteristicRows: number[];
    }>(),
    'Add Organization Caracteristic Success': props<{
      idOrganizationCaracteristicRow: number;
      idOrganizationCaracteristic: number;
    }>(),
    'Delete Many Organization Caracteristic Success': props<{
      idOrganizationCaracteristics: number[];
      idOrganizationCaracteristicRows: number[];
    }>(),
    'Add Organization Data Column Success': props<{
      idOrganizationCaracteristicRow: number;
      idOrganizationDataColumn: number;
    }>(),
    'Delete Many Organization Data Column Success': props<{
      idOrganizationDataColumns: number[];
      idOrganizationCaracteristicRows: number[];
    }>()
  }
});
