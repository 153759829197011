import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationRisk } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationRiskRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationRiskGeneratedActions } from '@wip/store/actions';
import { OrganizationRiskSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationRiskService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationRiskSelectors.selectIsLoadedOrganizationRisk));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationRiskSelectors.selectIsLoadingOrganizationRisk));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationRiskSelectors.selectIsReadyAndLoadedOrganizationRisk as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationRisks(schema: SelectSchema = {}): Observable<OrganizationRisk[]> {
    return this.store$.pipe(select(OrganizationRiskSelectors.selectAllOrganizationRisks(schema))).pipe(
      switchMap(({ organizationRisks }: { organizationRisks: OrganizationRisk[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationRisks));
      })
    );
  }

  public selectOneOrganizationRisk(
    idOrganizationRisk: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationRisk> {
    return this.store$
      .pipe(select(OrganizationRiskSelectors.selectOneOrganizationRisk(schema, idOrganizationRisk)))
      .pipe(
        switchMap(({ organizationRisk }: { organizationRisk: OrganizationRisk }) => {
          return this.getReady(schema).pipe(mapTo(organizationRisk));
        })
      );
  }

  public selectAllActiveOrganizationRisks(schema: SelectSchema = {}): Observable<OrganizationRisk[]> {
    return this.store$.pipe(select(OrganizationRiskSelectors.selectActiveOrganizationRisks(schema))).pipe(
      switchMap(({ organizationRisks }: { organizationRisks: OrganizationRisk[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationRisks));
      })
    );
  }

  public selectIdOrganizationRisksActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationRiskSelectors.selectIdOrganizationRisksActive)).pipe(
      switchMap((idOrganizationRisks: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationRisks));
      })
    );
  }

  public getOneOrganizationRisk(
    idOrganizationRisk: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationRisk> {
    this.store$.dispatch(OrganizationRiskGeneratedActions.getOneOrganizationRisk({ idOrganizationRisk, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationRiskGeneratedActions.normalizeManyOrganizationRisksAfterUpsert,
        OrganizationRiskGeneratedActions.organizationRisksFailure,
        true
      );
    }
  }

  public getManyOrganizationRisks(params: any = {}, getResult?: boolean): void | Observable<OrganizationRisk[]> {
    this.store$.dispatch(OrganizationRiskGeneratedActions.getManyOrganizationRisks({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationRiskGeneratedActions.normalizeManyOrganizationRisksAfterUpsert,
        OrganizationRiskGeneratedActions.organizationRisksFailure
      );
    }
  }

  public upsertOneOrganizationRisk(
    organizationRisk: Partial<OrganizationRisk>,
    ids: OrganizationRiskRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationRisk> {
    this.store$.dispatch(OrganizationRiskGeneratedActions.upsertOneOrganizationRisk({ organizationRisk, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationRiskGeneratedActions.normalizeManyOrganizationRisksAfterUpsert,
        OrganizationRiskGeneratedActions.organizationRisksFailure,
        true
      );
    }
  }

  public deleteOneOrganizationRisk(idOrganizationRisk: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(OrganizationRiskGeneratedActions.deleteOneOrganizationRisk({ idOrganizationRisk }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationRiskGeneratedActions.deleteOneOrganizationRiskSuccess,
        OrganizationRiskGeneratedActions.organizationRisksFailure
      );
    }
  }

  public setActiveOrganizationRisks(idOrganizationRisks: number[]): void {
    this.store$.dispatch(OrganizationRiskGeneratedActions.clearActivesOrganizationRisks());
    this.store$.dispatch(OrganizationRiskGeneratedActions.addManyActivesOrganizationRisks({ idOrganizationRisks }));
  }
}
