import { createEntityAdapter } from '@ngrx/entity';
import { TimelineElementEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<TimelineElementEntityState>;

export const adapter = createEntityAdapter<TimelineElementEntityState>({
  selectId: o => o.idTimelineElement
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const timelineElementFeatureKey = 'timelineElement';
