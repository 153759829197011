import { RepositoryService } from '@wip/services/repository';
import { Group } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedGroupApiService {
  constructor(protected repo: RepositoryService) {}

  public getGroups(params?: any): Observable<Group[]> {
    return this.repo.getData<Group[]>('group', params);
  }

  public getGroup(params: { idGroup: number; params?: any }): Observable<Group> {
    return this.repo.getData<Group>('group/' + params.idGroup, params.params);
  }

  public addGroup(group: Partial<Group>): Observable<Group> {
    return this.repo.create<Group>('group', group);
  }

  public updateGroup(group: Partial<Group>): Observable<Group> {
    return this.repo.update('group', group);
  }

  public deleteGroup(idGroup: number): Observable<number> {
    return this.repo.delete('group/' + +idGroup);
  }
}
