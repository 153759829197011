import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityKpi, CommunityKpiEntityState } from '@api/api-interfaces';
import { CommunityKpiRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityKpiGeneratedActions = createActionGroup({
  source: 'Community Kpi Generated',
  events: {
    'Get One Community Kpi': props<{ idCommunityKpi: number; params?: any }>(),
    'Get Many Community Kpis': props<{ params: any }>(),
    'Add Many Actives Community Kpis': props<{ idCommunityKpis: number[] }>(),
    'Delete One Active Community Kpi': props<{ idCommunityKpi: number }>(),
    'Clear Actives Community Kpis': emptyProps(),
    'Upsert One Community Kpi': props<{ communityKpi: Partial<CommunityKpi>; ids?: CommunityKpiRelationsIds }>(),
    'Upsert Many Community Kpis': props<{ communityKpis: Partial<CommunityKpi>[]; ids?: CommunityKpiRelationsIds }>(),
    'Upsert Many Community Kpis Success': props<{ communityKpis: CommunityKpiEntityState[] }>(),
    'Delete One Community Kpi': props<{ idCommunityKpi: number }>(),
    'Delete One Community Kpi Success': props<{ idCommunityKpi: number }>(),
    'Normalize Many Community Kpis After Upsert': props<{ communityKpis: CommunityKpiEntityState[] }>(),
    'Community Kpis Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Kpis': emptyProps(),
    'Add Organization Kpi Success': props<{ idCommunityKpi: number; idOrganizationKpi: number }>(),
    'Delete Many Organization Kpi Success': props<{ idOrganizationKpis: number[]; idCommunityKpis: number[] }>(),
    'Add Community Success': props<{ idCommunityKpi: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityKpis: number[] }>()
  }
});
