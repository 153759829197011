import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Valeur, ValeurEntityState } from '@api/api-interfaces';
import { ValeurRelationsIds } from '@wip/store/ids-interfaces';

export const ValeurGeneratedActions = createActionGroup({
  source: 'Valeur Generated',
  events: {
    'Get One Valeur': props<{ idValeur: number; params?: any }>(),
    'Get Many Valeurs': props<{ params: any }>(),
    'Add Many Actives Valeurs': props<{ idValeurs: number[] }>(),
    'Delete One Active Valeur': props<{ idValeur: number }>(),
    'Clear Actives Valeurs': emptyProps(),
    'Upsert One Valeur': props<{ valeur: Partial<Valeur>; ids?: ValeurRelationsIds }>(),
    'Upsert Many Valeurs': props<{ valeurs: Partial<Valeur>[]; ids?: ValeurRelationsIds }>(),
    'Upsert Many Valeurs Success': props<{ valeurs: ValeurEntityState[] }>(),
    'Delete One Valeur': props<{ idValeur: number }>(),
    'Delete One Valeur Success': props<{ idValeur: number }>(),
    'Normalize Many Valeurs After Upsert': props<{ valeurs: ValeurEntityState[] }>(),
    'Valeurs Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Valeurs': emptyProps(),
    'Add Community Data Row Success': props<{ idValeur: number; idCommunityDataRow: number }>(),
    'Delete Many Community Data Row Success': props<{ idCommunityDataRows: number[]; idValeurs: number[] }>(),
    'Add Organization Data Column Success': props<{ idValeur: number; idOrganizationDataColumn: number }>(),
    'Delete Many Organization Data Column Success': props<{
      idOrganizationDataColumns: number[];
      idValeurs: number[];
    }>(),
    'Add Organization Data Column Value Success': props<{ idValeur: number; idOrganizationDataColumnValue: number }>(),
    'Delete Many Organization Data Column Value Success': props<{
      idOrganizationDataColumnValues: number[];
      idValeurs: number[];
    }>()
  }
});
