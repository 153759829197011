import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserGroupGeneratedActions } from '@wip/store/actions';
import { UserGroupState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserGroupEntityState } from '@api/api-interfaces';

export const userGroupReducersGeneratedFunctions: ReducerTypes<UserGroupState.IState, readonly ActionCreator[]>[] = [
  on(UserGroupGeneratedActions.getOneUserGroup, (state: UserGroupState.IState) => setLoadingsState(state, true)),
  on(UserGroupGeneratedActions.getManyUserGroups, (state: UserGroupState.IState) => setLoadingsState(state, true)),
  on(UserGroupGeneratedActions.upsertOneUserGroup, (state: UserGroupState.IState) => setLoadingsState(state, true)),

  on(UserGroupGeneratedActions.upsertManyUserGroupsSuccess, (state: UserGroupState.IState, { userGroups }) =>
    UserGroupState.adapter.upsertMany(userGroups, setLoadingsState(state, false))
  ),
  on(UserGroupGeneratedActions.deleteOneUserGroup, (state: UserGroupState.IState) => setLoadingsState(state, true)),
  on(UserGroupGeneratedActions.deleteOneUserGroupSuccess, (state: UserGroupState.IState, { idUserGroup }) =>
    UserGroupState.adapter.removeOne(idUserGroup, setLoadingsState(state, false))
  ),
  on(UserGroupGeneratedActions.clearActivesUserGroups, (state: UserGroupState.IState) => ({ ...state, actives: [] })),
  on(UserGroupGeneratedActions.addManyActivesUserGroups, (state: UserGroupState.IState, { idUserGroups }) => ({
    ...state,
    actives: state.actives.concat(idUserGroups)
  })),
  on(UserGroupGeneratedActions.deleteOneActiveUserGroup, (state: UserGroupState.IState, { idUserGroup }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idUserGroup)
  })),

  on(UserGroupGeneratedActions.clearUserGroups, () => UserGroupState.initialState),

  on(UserGroupGeneratedActions.addUserSuccess, (state: UserGroupState.IState, { idUserGroup, idUser }) => {
    if (!state.entities[idUserGroup]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUserGroup]: {
          ...state.entities[idUserGroup],
          user: idUser
        }
      }
    };
  }),

  on(UserGroupGeneratedActions.deleteManyUserSuccess, (state: UserGroupState.IState, { idUsers, idUserGroups }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUserGroups.reduce((entities, idUserGroup) => {
          if (!state.entities[idUserGroup]?.user) {
            return entities;
          }
          entities[idUserGroup] = {
            ...state.entities[idUserGroup],
            user: idUsers.some((idUser: number) => idUser === state.entities[idUserGroup]?.user)
              ? undefined
              : state.entities[idUserGroup]?.user
          } as UserGroupEntityState;
          return entities;
        }, {} as Dictionary<UserGroupEntityState>)
      }
    };
  }),

  on(UserGroupGeneratedActions.addGroupSuccess, (state: UserGroupState.IState, { idUserGroup, idGroup }) => {
    if (!state.entities[idUserGroup]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUserGroup]: {
          ...state.entities[idUserGroup],
          group: idGroup
        }
      }
    };
  }),

  on(UserGroupGeneratedActions.deleteManyGroupSuccess, (state: UserGroupState.IState, { idGroups, idUserGroups }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUserGroups.reduce((entities, idUserGroup) => {
          if (!state.entities[idUserGroup]?.group) {
            return entities;
          }
          entities[idUserGroup] = {
            ...state.entities[idUserGroup],
            group: idGroups.some((idGroup: number) => idGroup === state.entities[idUserGroup]?.group)
              ? undefined
              : state.entities[idUserGroup]?.group
          } as UserGroupEntityState;
          return entities;
        }, {} as Dictionary<UserGroupEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: UserGroupState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): UserGroupState.IState {
  return { ...state, isLoaded, isLoading };
}
