import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserBoardState, UserBoardStateEntityState } from '@api/api-interfaces';
import { OrganizationUser, OrganizationUserEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { UserBoardStateState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const userBoardStateRelations: string[] = ['organizationUsers', 'organizationFamilys'];

export const { selectEntities, selectAll } = UserBoardStateState.adapter.getSelectors();

export const selectUserBoardStateState = createFeatureSelector<UserBoardStateState.IState>(
  UserBoardStateState.userBoardStateFeatureKey
);

export const selectIsLoadedUserBoardState = createSelector(
  selectUserBoardStateState,
  (state: UserBoardStateState.IState) => state.isLoaded
);

export const selectIsLoadingUserBoardState = createSelector(
  selectUserBoardStateState,
  (state: UserBoardStateState.IState) => state.isLoading
);

export const selectIsReadyUserBoardState = createSelector(
  selectUserBoardStateState,
  (state: UserBoardStateState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUserBoardState = createSelector(
  selectUserBoardStateState,
  (state: UserBoardStateState.IState) => state.isLoaded && !state.isLoading
);

export const selectUserBoardStatesEntities = createSelector(selectUserBoardStateState, selectEntities);

export const selectUserBoardStatesArray = createSelector(selectUserBoardStateState, selectAll);

export const selectIdUserBoardStatesActive = createSelector(
  selectUserBoardStateState,
  (state: UserBoardStateState.IState) => state.actives
);

const userBoardStatesInObject = (userBoardStates: Dictionary<UserBoardStateEntityState>) => ({ userBoardStates });

const selectUserBoardStatesEntitiesDictionary = createSelector(selectUserBoardStatesEntities, userBoardStatesInObject);

const selectAllUserBoardStatesObject = createSelector(selectUserBoardStatesEntities, userBoardStates => {
  return hydrateAll({ userBoardStates });
});

const selectOneUserBoardStateDictionary = (idUserBoardState: number) =>
  createSelector(selectUserBoardStatesEntities, userBoardStates => ({
    userBoardStates: { [idUserBoardState]: userBoardStates[idUserBoardState] }
  }));

const selectOneUserBoardStateDictionaryWithoutChild = (idUserBoardState: number) =>
  createSelector(selectUserBoardStatesEntities, userBoardStates => ({
    userBoardState: userBoardStates[idUserBoardState]
  }));

const selectActiveUserBoardStatesEntities = createSelector(
  selectIdUserBoardStatesActive,
  selectUserBoardStatesEntities,
  (actives: number[], userBoardStates: Dictionary<UserBoardStateEntityState>) =>
    getUserBoardStatesFromActives(actives, userBoardStates)
);

function getUserBoardStatesFromActives(
  actives: number[],
  userBoardStates: Dictionary<UserBoardStateEntityState>
): Dictionary<UserBoardStateEntityState> {
  return actives.reduce((acc, idActive) => {
    if (userBoardStates[idActive]) {
      acc[idActive] = userBoardStates[idActive];
    }
    return acc;
  }, {} as Dictionary<UserBoardStateEntityState>);
}

const selectAllUserBoardStatesSelectors: Dictionary<Selector> = {};
export function selectAllUserBoardStates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UserBoardState>(
      schema,
      selectAllUserBoardStatesSelectors,
      selectUserBoardStatesEntitiesDictionary,
      getRelationSelectors,
      userBoardStateRelations,
      hydrateAll,
      'userBoardState'
    );
  } else {
    return selectAllUserBoardStatesObject;
  }
}

export function selectAllUserBoardStatesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'userBoardStates'
): Selector {
  return createSelector(selectAllUserBoardStates(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserBoardStateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.userBoardStates.length; i++) {
      res[customKey][result.userBoardStates[i].idUserBoardState] = result.userBoardStates[i];
    }
    return res;
  });
}

export function selectOneUserBoardState(schema: SelectSchema = {}, idUserBoardState: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneUserBoardStateDictionary(idUserBoardState)];
    selectors.push(...getRelationSelectors(schema, userBoardStateRelations, 'userBoardState'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserBoardStateDictionaryWithoutChild(idUserBoardState);
  }
}

export function selectActiveUserBoardStates(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveUserBoardStatesEntities, userBoardStates => ({
      userBoardStates
    }))
  ];
  selectors.push(...getRelationSelectors(schema, userBoardStateRelations, 'userBoardState'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  userBoardStates: Dictionary<UserBoardStateEntityState>;
  organizationUsers?: Dictionary<OrganizationUserEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { userBoardStates: (UserBoardState | null)[] } {
  const { userBoardStates, organizationUsers, organizationFamilys } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    userBoardStates: Object.keys(userBoardStates).map(idUserBoardState =>
      hydrate(userBoardStates[idUserBoardState] as UserBoardStateEntityState, organizationUsers, organizationFamilys)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { userBoardState: UserBoardStateEntityState | null } {
  const { userBoardStates, organizationUsers, organizationFamilys } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const userBoardState = Object.values(userBoardStates)[0];
  return {
    userBoardState: hydrate(userBoardState as UserBoardStateEntityState, organizationUsers, organizationFamilys)
  };
}

function hydrate(
  userBoardState: UserBoardStateEntityState,
  organizationUserEntities?: Dictionary<OrganizationUserEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>
): UserBoardState | null {
  if (!userBoardState) {
    return null;
  }

  const userBoardStateHydrated: UserBoardStateEntityState = { ...userBoardState };
  if (organizationUserEntities) {
    userBoardStateHydrated.organizationUser = organizationUserEntities[
      userBoardState.organizationUser as number
    ] as OrganizationUser;
  } else {
    delete userBoardStateHydrated.organizationUser;
  }
  if (organizationFamilyEntities) {
    userBoardStateHydrated.organizationFamily = organizationFamilyEntities[
      userBoardState.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete userBoardStateHydrated.organizationFamily;
  }

  return userBoardStateHydrated as UserBoardState;
}
