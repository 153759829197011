export enum MainNavigation {
  personal = 'personal',
  dashboard = 'dashboard',
  newdashboard = 'newdashboard',
  project = 'project',
  community = 'community',
  chat = 'chat',
  admin = 'admin',
  user = 'user',
  logout = 'logout',
  development = 'development'
}
