import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { CommunityMilestone, CommunityMilestoneEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneAssociation, OrganizationMilestoneAssociationEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationMilestoneState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationMilestoneRelations: string[] = [
  'communityMilestones',
  'organizationMilestoneAssociations',
  'elements',
  'organizationMilestoneFamilys',
  'organizations',
  'organizationFamilys'
];

export const { selectEntities, selectAll } = OrganizationMilestoneState.adapter.getSelectors();

export const selectOrganizationMilestoneState = createFeatureSelector<OrganizationMilestoneState.IState>(
  OrganizationMilestoneState.organizationMilestoneFeatureKey
);

export const selectIsLoadedOrganizationMilestone = createSelector(
  selectOrganizationMilestoneState,
  (state: OrganizationMilestoneState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationMilestone = createSelector(
  selectOrganizationMilestoneState,
  (state: OrganizationMilestoneState.IState) => state.isLoading
);

export const selectIsReadyOrganizationMilestone = createSelector(
  selectOrganizationMilestoneState,
  (state: OrganizationMilestoneState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationMilestone = createSelector(
  selectOrganizationMilestoneState,
  (state: OrganizationMilestoneState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationMilestonesEntities = createSelector(selectOrganizationMilestoneState, selectEntities);

export const selectOrganizationMilestonesArray = createSelector(selectOrganizationMilestoneState, selectAll);

export const selectIdOrganizationMilestonesActive = createSelector(
  selectOrganizationMilestoneState,
  (state: OrganizationMilestoneState.IState) => state.actives
);

const organizationMilestonesInObject = (organizationMilestones: Dictionary<OrganizationMilestoneEntityState>) => ({
  organizationMilestones
});

const selectOrganizationMilestonesEntitiesDictionary = createSelector(
  selectOrganizationMilestonesEntities,
  organizationMilestonesInObject
);

const selectAllOrganizationMilestonesObject = createSelector(
  selectOrganizationMilestonesEntities,
  organizationMilestones => {
    return hydrateAll({ organizationMilestones });
  }
);

const selectOneOrganizationMilestoneDictionary = (idOrganizationMilestone: number) =>
  createSelector(selectOrganizationMilestonesEntities, organizationMilestones => ({
    organizationMilestones: { [idOrganizationMilestone]: organizationMilestones[idOrganizationMilestone] }
  }));

const selectOneOrganizationMilestoneDictionaryWithoutChild = (idOrganizationMilestone: number) =>
  createSelector(selectOrganizationMilestonesEntities, organizationMilestones => ({
    organizationMilestone: organizationMilestones[idOrganizationMilestone]
  }));

const selectActiveOrganizationMilestonesEntities = createSelector(
  selectIdOrganizationMilestonesActive,
  selectOrganizationMilestonesEntities,
  (actives: number[], organizationMilestones: Dictionary<OrganizationMilestoneEntityState>) =>
    getOrganizationMilestonesFromActives(actives, organizationMilestones)
);

function getOrganizationMilestonesFromActives(
  actives: number[],
  organizationMilestones: Dictionary<OrganizationMilestoneEntityState>
): Dictionary<OrganizationMilestoneEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationMilestones[idActive]) {
      acc[idActive] = organizationMilestones[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationMilestoneEntityState>);
}

const selectAllOrganizationMilestonesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationMilestones(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationMilestone>(
      schema,
      selectAllOrganizationMilestonesSelectors,
      selectOrganizationMilestonesEntitiesDictionary,
      getRelationSelectors,
      organizationMilestoneRelations,
      hydrateAll,
      'organizationMilestone'
    );
  } else {
    return selectAllOrganizationMilestonesObject;
  }
}

export function selectAllOrganizationMilestonesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationMilestones'
): Selector {
  return createSelector(selectAllOrganizationMilestones(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationMilestoneEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationMilestones.length; i++) {
      res[customKey][result.organizationMilestones[i].idOrganizationMilestone] = result.organizationMilestones[i];
    }
    return res;
  });
}

export function selectOneOrganizationMilestone(schema: SelectSchema = {}, idOrganizationMilestone: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationMilestoneDictionary(idOrganizationMilestone)];
    selectors.push(...getRelationSelectors(schema, organizationMilestoneRelations, 'organizationMilestone'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationMilestoneDictionaryWithoutChild(idOrganizationMilestone);
  }
}

export function selectActiveOrganizationMilestones(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationMilestonesEntities, organizationMilestones => ({
      organizationMilestones
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationMilestoneRelations, 'organizationMilestone'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationMilestones: Dictionary<OrganizationMilestoneEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  communityMilestones?: Dictionary<CommunityMilestoneEntityState>;
  organizationMilestoneAssociations?: Dictionary<OrganizationMilestoneAssociationEntityState>;
  elements?: Dictionary<ElementEntityState>;
  organizationMilestoneFamilys?: Dictionary<OrganizationMilestoneFamilyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationMilestones: (OrganizationMilestone | null)[] } {
  const {
    organizationMilestones,
    organizations,
    organizationFamilys,
    communityMilestones,
    organizationMilestoneAssociations,
    elements,
    organizationMilestoneFamilys
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationMilestones: Object.keys(organizationMilestones).map(idOrganizationMilestone =>
      hydrate(
        organizationMilestones[idOrganizationMilestone] as OrganizationMilestoneEntityState,
        organizations,
        organizationFamilys,
        communityMilestones,
        organizationMilestoneAssociations,
        elements,
        organizationMilestoneFamilys
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationMilestone: OrganizationMilestoneEntityState | null } {
  const {
    organizationMilestones,
    organizations,
    organizationFamilys,
    communityMilestones,
    organizationMilestoneAssociations,
    elements,
    organizationMilestoneFamilys
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationMilestone = Object.values(organizationMilestones)[0];
  return {
    organizationMilestone: hydrate(
      organizationMilestone as OrganizationMilestoneEntityState,
      organizations,
      organizationFamilys,
      communityMilestones,
      organizationMilestoneAssociations,
      elements,
      organizationMilestoneFamilys
    )
  };
}

function hydrate(
  organizationMilestone: OrganizationMilestoneEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  communityMilestoneEntities?: Dictionary<CommunityMilestoneEntityState>,
  organizationMilestoneAssociationEntities?: Dictionary<OrganizationMilestoneAssociationEntityState>,
  elementEntities?: Dictionary<ElementEntityState>,
  organizationMilestoneFamilyEntities?: Dictionary<OrganizationMilestoneFamilyEntityState>
): OrganizationMilestone | null {
  if (!organizationMilestone) {
    return null;
  }

  const organizationMilestoneHydrated: OrganizationMilestoneEntityState = { ...organizationMilestone };
  if (organizationEntities) {
    organizationMilestoneHydrated.organization = organizationEntities[
      organizationMilestone.organization as number
    ] as Organization;
  } else {
    delete organizationMilestoneHydrated.organization;
  }
  if (organizationFamilyEntities) {
    organizationMilestoneHydrated.organizationFamily = organizationFamilyEntities[
      organizationMilestone.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete organizationMilestoneHydrated.organizationFamily;
  }

  if (communityMilestoneEntities) {
    organizationMilestoneHydrated.communityMilestones = (
      (organizationMilestoneHydrated.communityMilestones as number[]) || []
    ).map(id => communityMilestoneEntities[id]) as CommunityMilestone[];
  } else {
    delete organizationMilestoneHydrated.communityMilestones;
  }

  if (organizationMilestoneAssociationEntities) {
    organizationMilestoneHydrated.organizationMilestoneAssociations = (
      (organizationMilestoneHydrated.organizationMilestoneAssociations as number[]) || []
    ).map(id => organizationMilestoneAssociationEntities[id]) as OrganizationMilestoneAssociation[];
  } else {
    delete organizationMilestoneHydrated.organizationMilestoneAssociations;
  }

  if (elementEntities) {
    organizationMilestoneHydrated.elements = ((organizationMilestoneHydrated.elements as number[]) || []).map(
      id => elementEntities[id]
    ) as Element[];
  } else {
    delete organizationMilestoneHydrated.elements;
  }

  if (organizationMilestoneFamilyEntities) {
    organizationMilestoneHydrated.organizationMilestoneFamilys = (
      (organizationMilestoneHydrated.organizationMilestoneFamilys as number[]) || []
    ).map(id => organizationMilestoneFamilyEntities[id]) as OrganizationMilestoneFamily[];
  } else {
    delete organizationMilestoneHydrated.organizationMilestoneFamilys;
  }

  return organizationMilestoneHydrated as OrganizationMilestone;
}
