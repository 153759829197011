import { createAction, props } from '@ngrx/store';
export * from './user-group-generated.actions';

export interface upsertUserGroupDTO {
  idGroup: number;
  usersToAdd: number[];
  usersToRemove: number[];
}

export const upsertAndRemoveUserGroups = createAction(
  '[UserGroup] Upsert And Remove User Groups',
  props<{
    params: upsertUserGroupDTO;
  }>()
);
