<div [formGroup]="actionForm" class="flex flex-col gap-8">
  <button
    [disabled]="isViewer"
    mat-icon-button
    [matMenuTriggerFor]="statusMenu"
    [matTooltip]="setTraduction(actionForm.controls.statusAction.value)"
  >
    <i class="transform-button" [ngClass]="checkboxStatus[actionForm.controls.statusAction.value]"></i>
  </button>
  <mat-menu #statusMenu class="mat-select-input">
    @for (status of actionService.statusList; track status) {
      <mat-menu-item mat-menu-item>
        <div class="flex flex-row justify-start items-center gap-8 item-hover" (click)="setStatus(status.value)">
          <i [ngClass]="checkboxStatus[status.value]"></i>
          <span>{{ status.text }}</span>
        </div>
      </mat-menu-item>
    }
  </mat-menu>
</div>
