import { OrganizationRiskState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationRiskReducersGeneratedFunctions } from './organization-risk-generated.reducer';

const organizationRiskReducersFunctions = [...organizationRiskReducersGeneratedFunctions];

const organizationRiskReducer = createReducer(OrganizationRiskState.initialState, ...organizationRiskReducersFunctions);

export function reducer(state: OrganizationRiskState.IState | undefined, action: Action) {
  return organizationRiskReducer(state, action);
}
