import { createEntityAdapter } from '@ngrx/entity';
import { CommunityModuleEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<CommunityModuleEntityState>;

export const adapter = createEntityAdapter<CommunityModuleEntityState>({
  selectId: o => o.idCommunityModule
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const communityModuleFeatureKey = 'communityModule';
