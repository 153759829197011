@if (listProject.length) {
  <div
    cdkDropList
    [cdkDropListData]="listStep"
    (cdkDropListDropped)="dropCurrentStep($event)"
    class="kanban-container flex flew-row gap-16"
    id="scrollable"
  >
    @for (entity of entities; track entity.projectStep.idOrganizationStep) {
      <div class="project-current-step-container">
        @if (titleToEdit !== entity.projectStep.idOrganizationStep) {
          <div class="card-header flex flex-justify-between items-center">
            <h2 class="ellipsis project-step-title">{{ entity.projectStep.libelle }}</h2>
          </div>
        } @else {
          <form class="title-form" #titleForm="ngForm">
            <mat-form-field class="form-field-width">
              <input
                #titleInput
                matInput
                type="text"
                placeholder="Titre"
                name="nom"
                [(ngModel)]="entity.projectStep.libelle"
                autocomplete="off"
              />
            </mat-form-field>
          </form>
        }
        <div
          cdkDropList
          cdkDropListOrientation="vertical"
          class="projects-list scrolling-wrap"
          style="max-height: calc(100vh - 180px); overflow-x: auto"
          id="{{ entity.projectStep.idOrganizationStep }}"
          (cdkDropListDropped)="dropProject($event)"
          [cdkDropListData]="entity.projects"
          [cdkDropListConnectedTo]="connectedList"
          [cdkDropListAutoScrollDisabled]="false"
        >
          @for (project of entity.projects; track project.idCommunity) {
            <div class="project-box-inner" cdkDrag [cdkDragData]="project" (cdkDragMoved)="onDragMoved($event)">
              <app-project-kanban
                [project]="project"
                [idProjectFamily]="project.idOrganizationFamily"
                [selectedOrganizationId]="idOrganization"
                class="project-kanban-card"
              >
              </app-project-kanban>
              <div class="project-box-placeholder" *cdkDragPlaceholder></div>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
