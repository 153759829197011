import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationStep, OrganizationStepEntityState } from '@api/api-interfaces';
import { OrganizationStepRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationStepGeneratedActions = createActionGroup({
  source: 'Organization Step Generated',
  events: {
    'Get One Organization Step': props<{ idOrganizationStep: number; params?: any }>(),
    'Get Many Organization Steps': props<{ params: any }>(),
    'Add Many Actives Organization Steps': props<{ idOrganizationSteps: number[] }>(),
    'Delete One Active Organization Step': props<{ idOrganizationStep: number }>(),
    'Clear Actives Organization Steps': emptyProps(),
    'Upsert One Organization Step': props<{
      organizationStep: Partial<OrganizationStep>;
      ids?: OrganizationStepRelationsIds;
    }>(),
    'Upsert Many Organization Steps': props<{
      organizationSteps: Partial<OrganizationStep>[];
      ids?: OrganizationStepRelationsIds;
    }>(),
    'Upsert Many Organization Steps Success': props<{ organizationSteps: OrganizationStepEntityState[] }>(),
    'Delete One Organization Step': props<{ idOrganizationStep: number }>(),
    'Delete One Organization Step Success': props<{ idOrganizationStep: number }>(),
    'Normalize Many Organization Steps After Upsert': props<{ organizationSteps: OrganizationStepEntityState[] }>(),
    'Organization Steps Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Steps': emptyProps(),
    'Add Many Community Success': props<{ idOrganizationStep: number; idCommunities: number[] }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idOrganizationSteps: number[] }>(),
    'Add Organization Success': props<{ idOrganizationStep: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationSteps: number[] }>(),
    'Add Organization Family Success': props<{ idOrganizationStep: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{
      idOrganizationFamilys: number[];
      idOrganizationSteps: number[];
    }>()
  }
});
