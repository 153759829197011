import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { ProjectElement, ProjectElementEntityState } from '@api/api-interfaces';
import { ProjectElementApiService } from '@wip/store/api-services';
import { ProjectElementGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeProjectElement } from '@wip/store/configs/normalization';
import { ProjectElementSelectors } from '@wip/store/selectors';
import { ProjectElementRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { ElementGeneratedActions } from '@wip/store/actions';

export function getDefaultAddProjectElementActions(
  projectElement: ProjectElementEntityState,
  ids?: ProjectElementRelationsIds
): Action[] {
  const actions: Action[] = [
    ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert({ projectElements: [projectElement] })
  ];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyProjectElementSuccess({
        idCommunity: ids.community,
        idProjectElements: [projectElement.idProjectElement]
      })
    );
    actions.push(
      ProjectElementGeneratedActions.addCommunitySuccess({
        idProjectElement: projectElement.idProjectElement,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.element) {
    actions.push(
      ElementGeneratedActions.addManyProjectElementSuccess({
        idElement: ids.element,
        idProjectElements: [projectElement.idProjectElement]
      })
    );
    actions.push(
      ProjectElementGeneratedActions.addElementSuccess({
        idProjectElement: projectElement.idProjectElement,
        idElement: ids.element
      })
    );
  }

  return actions;
}

export function getDefaultDeleteProjectElementActions(projectElement: ProjectElementEntityState): Action[] {
  const actions: Action[] = [
    ProjectElementGeneratedActions.deleteOneProjectElementSuccess({ idProjectElement: projectElement.idProjectElement })
  ];

  if (projectElement.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyProjectElementSuccess({
        idProjectElements: [projectElement.idProjectElement],
        idCommunities: [projectElement.community as number]
      })
    );
  }

  if (projectElement.element) {
    actions.push(
      ElementGeneratedActions.deleteManyProjectElementSuccess({
        idProjectElements: [projectElement.idProjectElement],
        idElements: [projectElement.element as number]
      })
    );
  }

  return actions;
}

export class GeneratedProjectElementEffects {
  constructor(
    protected actions$: Actions,
    protected projectElementApiService: ProjectElementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyProjectElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectElementGeneratedActions.getManyProjectElements),
      switchMap(({ params }) =>
        this.projectElementApiService.getProjectElements(params).pipe(
          map((projectElements: ProjectElement[]) => {
            return ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert({ projectElements });
          }),
          catchError(error => of(ProjectElementGeneratedActions.projectElementsFailure({ error })))
        )
      )
    );
  });

  getOneProjectElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectElementGeneratedActions.getOneProjectElement),
      switchMap(idProjectElement =>
        this.projectElementApiService.getProjectElement(idProjectElement).pipe(
          map((projectElement: ProjectElement) => {
            return ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert({
              projectElements: [projectElement]
            });
          }),
          catchError(error => of(ProjectElementGeneratedActions.projectElementsFailure({ error })))
        )
      )
    );
  });

  upsertOneProjectElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectElementGeneratedActions.upsertOneProjectElement),
      concatMap(
        ({ projectElement, ids }: { projectElement: Partial<ProjectElement>; ids?: ProjectElementRelationsIds }) => {
          if (projectElement.idProjectElement) {
            return this.projectElementApiService.updateProjectElement(projectElement).pipe(
              map((projectElementReturned: ProjectElement) => {
                return ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert({
                  projectElements: [projectElementReturned]
                });
              }),
              catchError(error => of(ProjectElementGeneratedActions.projectElementsFailure({ error })))
            );
          } else {
            return this.projectElementApiService.addProjectElement(projectElement).pipe(
              mergeMap((projectElementReturned: ProjectElement) =>
                getDefaultAddProjectElementActions(projectElementReturned, ids)
              ),
              catchError(error => of(ProjectElementGeneratedActions.projectElementsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProjectElement$ = createEffect(() => {
    const selectProjectElementState$ = this.store$.select(ProjectElementSelectors.selectProjectElementState);
    return this.actions$.pipe(
      ofType(ProjectElementGeneratedActions.deleteOneProjectElement),
      withLatestFrom(selectProjectElementState$),
      concatMap(([{ idProjectElement }, state]) =>
        this.projectElementApiService.deleteProjectElement(idProjectElement).pipe(
          mergeMap(_success =>
            getDefaultDeleteProjectElementActions(state.entities[idProjectElement] as ProjectElementEntityState)
          ),
          catchError(error => of(ProjectElementGeneratedActions.projectElementsFailure({ error })))
        )
      )
    );
  });

  normalizeManyProjectElementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert),
      concatMap(({ projectElements }) => {
        const actions: Action[] = getActionsToNormalizeProjectElement(projectElements, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProjectElement] Normalization After Upsert Success')];
      })
    );
  });
}
