import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationUserProfil } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationUserProfilRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationUserProfilGeneratedActions } from '@wip/store/actions';
import { OrganizationUserProfilSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationUserProfilService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationUserProfilSelectors.selectIsLoadedOrganizationUserProfil));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationUserProfilSelectors.selectIsLoadingOrganizationUserProfil));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationUserProfilSelectors.selectIsReadyAndLoadedOrganizationUserProfil as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationUserProfils(schema: SelectSchema = {}): Observable<OrganizationUserProfil[]> {
    return this.store$.pipe(select(OrganizationUserProfilSelectors.selectAllOrganizationUserProfils(schema))).pipe(
      switchMap(({ organizationUserProfils }: { organizationUserProfils: OrganizationUserProfil[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationUserProfils));
      })
    );
  }

  public selectOneOrganizationUserProfil(
    idOrganizationUserProfil: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationUserProfil> {
    return this.store$
      .pipe(select(OrganizationUserProfilSelectors.selectOneOrganizationUserProfil(schema, idOrganizationUserProfil)))
      .pipe(
        switchMap(({ organizationUserProfil }: { organizationUserProfil: OrganizationUserProfil }) => {
          return this.getReady(schema).pipe(mapTo(organizationUserProfil));
        })
      );
  }

  public selectAllActiveOrganizationUserProfils(schema: SelectSchema = {}): Observable<OrganizationUserProfil[]> {
    return this.store$.pipe(select(OrganizationUserProfilSelectors.selectActiveOrganizationUserProfils(schema))).pipe(
      switchMap(({ organizationUserProfils }: { organizationUserProfils: OrganizationUserProfil[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationUserProfils));
      })
    );
  }

  public selectIdOrganizationUserProfilsActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationUserProfilSelectors.selectIdOrganizationUserProfilsActive)).pipe(
      switchMap((idOrganizationUserProfils: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationUserProfils));
      })
    );
  }

  public getOneOrganizationUserProfil(
    idOrganizationUserProfil: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationUserProfil> {
    this.store$.dispatch(
      OrganizationUserProfilGeneratedActions.getOneOrganizationUserProfil({ idOrganizationUserProfil, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationUserProfilGeneratedActions.normalizeManyOrganizationUserProfilsAfterUpsert,
        OrganizationUserProfilGeneratedActions.organizationUserProfilsFailure,
        true
      );
    }
  }

  public getManyOrganizationUserProfils(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationUserProfil[]> {
    this.store$.dispatch(OrganizationUserProfilGeneratedActions.getManyOrganizationUserProfils({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationUserProfilGeneratedActions.normalizeManyOrganizationUserProfilsAfterUpsert,
        OrganizationUserProfilGeneratedActions.organizationUserProfilsFailure
      );
    }
  }

  public upsertOneOrganizationUserProfil(
    organizationUserProfil: Partial<OrganizationUserProfil>,
    ids: OrganizationUserProfilRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationUserProfil> {
    this.store$.dispatch(
      OrganizationUserProfilGeneratedActions.upsertOneOrganizationUserProfil({ organizationUserProfil, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationUserProfilGeneratedActions.normalizeManyOrganizationUserProfilsAfterUpsert,
        OrganizationUserProfilGeneratedActions.organizationUserProfilsFailure,
        true
      );
    }
  }

  public deleteOneOrganizationUserProfil(
    idOrganizationUserProfil: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationUserProfilGeneratedActions.deleteOneOrganizationUserProfil({ idOrganizationUserProfil })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationUserProfilGeneratedActions.deleteOneOrganizationUserProfilSuccess,
        OrganizationUserProfilGeneratedActions.organizationUserProfilsFailure
      );
    }
  }

  public setActiveOrganizationUserProfils(idOrganizationUserProfils: number[]): void {
    this.store$.dispatch(OrganizationUserProfilGeneratedActions.clearActivesOrganizationUserProfils());
    this.store$.dispatch(
      OrganizationUserProfilGeneratedActions.addManyActivesOrganizationUserProfils({ idOrganizationUserProfils })
    );
  }
}
