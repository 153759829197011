import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Todo } from '@api/api-interfaces';
import { TodoElementApiService } from '@wip/store/api-services';
import { TodoService } from '@wip/store/services';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class OldTodoService implements OnInit {
  public router: Router;
  public route: ActivatedRoute;
  public selectedTag$: Observable<number>;
  public selectedTodoID$: Observable<number | string>;
  public tabGroupIndex$: Observable<number>;
  public selectedTodoElementID$: Observable<number>;
  public todoElements$: Observable<TodoElementApiService[]>;
  public incomingTodoCount$ = new Subject<{ late: number; inTime: number }>();
  public allTodoCount$ = new Subject<{ late: number; inTime: number }>();

  constructor(private newTodoService: TodoService) {}

  ngOnInit() {}

  public emitNewSubjectValue<T>(subject$: Subject<T>, value: T) {
    subject$.next(value);
  }

  setRouteParams(todoId?: number | string) {
    this.router.navigate(['.'], {
      queryParams: {
        id: todoId
      },
      replaceUrl: true,
      relativeTo: this.route
    });
  }

  editInline(todo: Todo) {
    this.newTodoService.setActiveTodos([todo.idTodo]);
  }
}
