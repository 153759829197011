import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedMeetingUserApiService } from './meeting-user-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingUserApiService extends GeneratedMeetingUserApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
