import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ExcelExportParams, ExcelStyle, GridOptions, ICellEditorComp, Module } from '@ag-grid-community/core';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { EnterpriseCoreModule } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import dayjs from 'dayjs';
import localeText from '../locale/fr';
import { includeStatusBarOptions } from '../status-bar';
import { StatusBarOptions } from '../status-bar/types';
import { AgGridCellIconFormatter } from '@wip/wip/front-utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars

// eslint-disable-next-line @typescript-eslint/no-namespace

export namespace AgGridUtils {
  interface GetCommonGridOptionsConfiguration {
    statusBar: StatusBarOptions;
  }

  export function getDefaultColDef(): any {
    return {
      resizable: true,
      filter: true,
      filterParams: { excelMode: 'mac', debounceMs: 200 },
      menuTabs: ['filterMenuTab'],
      enablePivot: false,
      sortable: true,
      editable: true,
      groupRowsSticky: true,
      groupDisplayType: 'groupRows',
      headerCheckboxSelectionFilteredOnly: true,
      getQuickFilterText: (params: { value: string }): string => {
        if (typeof params.value === 'string' && !parseInt(params.value)) {
          return params.value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        }
        return params.value;
      }
    };
  }

  export function makeRadio(data: boolean | { value: boolean }): string {
    if ((typeof data === 'object' && data.value) || (typeof data === 'boolean' && data)) {
      return AgGridCellIconFormatter.cellRendererIcon('true', [
        {
          value: 'true',
          icon: 'check_box',
          text: 'oui',
          class: 'icon-ic_fluent_radio_button_24_filled icons green'
        }
      ]);
    } else {
      return AgGridCellIconFormatter.cellRendererIcon('false', [
        {
          value: 'false',
          icon: 'check_box_outline_blank',
          text: 'non',
          class: 'icon-ic_fluent_radio_button_24_regular '
        }
      ]);
    }
  }

  export function getCommonGridOption(
    isInModal: boolean = false,
    configuration: GetCommonGridOptionsConfiguration = {
      statusBar: null
    }
  ): GridOptions {
    const gridOptions: GridOptions = {
      maintainColumnOrder: true,
      singleClickEdit: false,
      excelStyles: defaultExcelStyle,
      overlayLoadingTemplate: `<span class="ag-overlay-loading-center flex">
      Chargement des données <div class="lds-ring" style="padding-top: 2px; margin-left: 8px;"><div></div><div></div><div></div><div></div></div>
      </span>`,
      overlayNoRowsTemplate: `<span class="ag-overlay-loading-center">
      Pas de donnée.
      </span>`,
      stopEditingWhenCellsLoseFocus: true,
      rowSelection: 'single',
      localeText,
      popupParent: isInModal ? document.querySelector('#modal-body') : document.querySelector('body'),
      defaultColDef: getDefaultColDef(),
      icons: {
        groupExpanded: '<i class="icon icon-ic_fluent_caret_down_24_filled"></i>',
        groupContracted: '<i class="icon icon-ic_fluent_caret_right_24_filled"></i>',
        menu: '<i class="icon icon-ic_fluent_chevron_down_24_filled"></i>',
        menuAlt: '<i class="icon icon-ic_fluent_chevron_down_24_filled"></i>',
        filter: '<i class="icon icon-ic_fluent_filter_24_regular"></i>',
        sortAscending: '<i class="icon icon-ic_fluent_arrow_down_24_filled"></i>',
        sortDescending: '<i class="icon icon-ic_fluent_arrow_up_24_filled"></i>'
      },
      rowHeight: 35,
      headerHeight: 35,
      defaultExcelExportParams: getDefaultExcelExportParams(),
      suppressMenuHide: false,
      columnMenu: 'legacy'
    };

    const { statusBar } = configuration;

    if (statusBar) {
      return includeStatusBarOptions(gridOptions, statusBar);
    }

    return gridOptions;
  }

  export function getDefaultExcelExportParams(): ExcelExportParams {
    return {
      fontSize: 11,
      skipRowGroups: true,
      sheetName: 'Export Wip',
      fileName: `Export Wip - ${dayjs().format('YY-MM-DD')} `,
      rowGroupExpandState: 'match'
    };
  }

  export function getCommonModules(): Module[] {
    return [
      EnterpriseCoreModule,
      ClientSideRowModelModule,
      SetFilterModule,
      RowGroupingModule,
      RichSelectModule,
      RangeSelectionModule,
      ExcelExportModule,
      ClipboardModule,
      MenuModule,
      FiltersToolPanelModule,
      ColumnsToolPanelModule,
      StatusBarModule,
      GridChartsModule
    ];
  }

  export function makeStarCheckBox(data: boolean | { value: boolean }, revert: boolean = false): string {
    const yesCheckbox = AgGridCellIconFormatter.cellRendererIcon(
      'true',
      [
        {
          value: 'true',
          icon: 'check_box',
          text: 'oui',
          class: 'icon-yellow cursor font-size icon-ic_fluent_star_24_filled '
        }
      ],
      null,
      null,
      null
    );

    const noCheckbox = AgGridCellIconFormatter.cellRendererIcon(
      'false',
      [
        {
          value: 'false',
          icon: 'check_box_outline_blank',
          text: 'non',
          class: 'icon-yellow cursor font-size icon-ic_fluent_star_24_regular'
        }
      ],
      null,
      null,
      null
    );

    if ((typeof data === 'object' && data?.value) || (typeof data === 'boolean' && data)) {
      return !revert ? yesCheckbox : noCheckbox;
    } else {
      return !revert ? noCheckbox : yesCheckbox;
    }
  }

  export function dateComparator(date1: Date, date2: Date): number {
    const format = 'D MMMM YYYY';
    const date1Number = monthToComparableNumber(dayjs(date1, format).format('DD-MM-YYYY'));
    const date2Number = monthToComparableNumber(dayjs(date2, format).format('DD-MM-YYYY'));

    if (date1Number === null && date2Number === null) {
      return -1;
    }
    if (date1Number === null) {
      return 0;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }

  export function newDateComparator(filterLocalDateAtMidnight, cellValue): number {
    if (cellValue) {
      const fullCellDate = new Date(cellValue);
      const cellDate = new Date(fullCellDate?.getFullYear(), fullCellDate?.getMonth(), fullCellDate?.getDate());
      return cellDate < filterLocalDateAtMidnight ? -1 : cellDate > filterLocalDateAtMidnight ? 1 : 0;
    }
    return 0;
  }

  export function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 10) {
      return null;
    }
    const yearNumber = date.substring(6, 10);
    const monthNumber = date.substring(3, 5);
    const dayNumber = date.substring(0, 2);
    const result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
  }

  export class NativeDatePickerEditor implements ICellEditorComp {
    private eInput: HTMLInputElement;
    private key_delete = 46;

    public init(params): void {
      this.eInput = document.createElement('input');
      this.eInput.setAttribute('type', 'date');
      if (params?.min) {
        this.eInput.setAttribute('min', dayjs(params.min).format('YYYY-MM-DD'));
      }
      this.eInput.style.width = '100%';
      this.eInput.style.height = '100%';
      this.eInput.value =
        params.value && params.keyPress != this.key_delete ? new Date(params.value).toISOString().substr(0, 10) : '';
    }

    public getGui(): HTMLInputElement {
      return this.eInput;
    }

    public afterGuiAttached(): void {
      this.eInput.select();
    }

    public getValue(): string | null {
      return this.eInput.value ? new Date(this.eInput.value).toISOString() : null;
    }

    public isPopup(): boolean {
      return true;
    }
  }

  export const myCustomFilterDate = [
    {
      displayKey: 'inWeek',
      displayName: 'Dans la semaine',
      hideFilterInput: true,
      test: (_filterValue: any, cellValue: any): boolean => {
        const value = new Date(cellValue).getTime();
        const firstDayWeek = dayjs().startOf('week');
        const lastDayWeek = dayjs().endOf('week');
        return value >= firstDayWeek.toDate().getTime() && value <= lastDayWeek.toDate().getTime();
      }
    },
    {
      displayKey: '7days',
      displayName: 'Depuis 7 jours',
      hideFilterInput: true,
      test: (_filterValue: any, cellValue: any): boolean => {
        const value = new Date(cellValue).getTime();
        const now = dayjs().toDate().getTime();
        return value >= dayjs().subtract(7, 'days').toDate().getTime() && value <= now;
      }
    },
    {
      displayKey: 'lastWeek',
      displayName: 'La semaine dernière',
      hideFilterInput: true,
      test: (_filterValue: any, cellValue: any): boolean => {
        const value = new Date(cellValue).getTime();
        const firstDayLastWeek = dayjs().subtract(1, 'week').startOf('week');
        const lastDayLastWeek = dayjs().subtract(1, 'week').endOf('week');
        return value >= firstDayLastWeek.toDate().getTime() && value <= lastDayLastWeek.toDate().getTime();
      }
    },
    {
      displayKey: 'inMonth',
      displayName: 'Dans le mois',
      hideFilterInput: true,
      test: (_filterValue: any, cellValue: any): boolean => {
        const value = new Date(cellValue).getTime();
        const firstDayMonth = dayjs().startOf('month');
        const lastDayMonth = dayjs().endOf('month');
        return value >= firstDayMonth.toDate().getTime() && value <= lastDayMonth.toDate().getTime();
      }
    },
    {
      displayKey: 'inYear',
      displayName: "Dans l'année",
      hideFilterInput: true,
      test: (_filterValue: any, cellValue: any): boolean => {
        const value = new Date(cellValue).getTime();
        const firstDayMonth = dayjs().startOf('year');
        const lastDayMonth = dayjs().endOf('year');
        return value >= firstDayMonth.toDate().getTime() && value <= lastDayMonth.toDate().getTime();
      }
    },
    {
      displayKey: '30days',
      displayName: 'Depuis 30 jours',
      hideFilterInput: true,
      test: (_filterValue: any, cellValue: any): boolean => {
        const value = new Date(cellValue).getTime();
        const now = dayjs().toDate().getTime();
        return value >= dayjs().subtract(30, 'days').toDate().getTime() && value <= now;
      }
    },
    {
      displayKey: 'lastMonth',
      displayName: 'Le mois dernier',
      hideFilterInput: true,
      test: (_filterValue: any, cellValue: any): boolean => {
        const value = new Date(cellValue).getTime();
        const firstDayLastMonth = dayjs().subtract(1, 'month').startOf('month');
        const lastDayLastMonth = dayjs().subtract(1, 'month').endOf('month');
        return value >= firstDayLastMonth.toDate().getTime() && value <= lastDayLastMonth.toDate().getTime();
      }
    },
    {
      displayKey: 'withoutDate',
      displayName: 'Sans date',
      hideFilterInput: true,
      test: (_filterValue: any, cellValue: any): boolean => {
        return !cellValue;
      }
    }
  ];

  export function getDefaultDateFilterParams(): any {
    return {
      defaultOption: 'inRange',
      filterOptions: ['empty', 'inRange', 'equals', 'greaterThan', 'lessThan', ...myCustomFilterDate],
      comparator: newDateComparator,
      inRangeInclusive: true
    };
  }

  export const defaultExcelStyle: ExcelStyle[] = [
    {
      id: 'dateType',
      dataType: 'DateTime',
      numberFormat: {
        format: 'dd/mm/yyyy'
      }
    },
    {
      id: 'stringType',
      dataType: 'String'
    },
    {
      id: 'numberType',
      numberFormat: {
        format: '0'
      }
    },
    {
      id: 'percentType',
      numberFormat: {
        format: '0%'
      }
    },
    {
      id: 'currencyFormat',
      numberFormat: {
        format: '#,##0.00 €'
      }
    },
    {
      id: 'negativeInBrackets',
      numberFormat: {
        format: '$[blue] #,##0;$ [red](#,##0)'
      }
    },
    {
      id: 'booleanType',
      dataType: 'Boolean'
    },
    {
      id: 'header',
      alignment: { vertical: 'Center' },
      interior: {
        color: '#d9d9d9',
        pattern: 'Solid',
        patternColor: ''
      },
      borders: {
        borderBottom: {
          color: '#000000',
          lineStyle: 'Continuous',
          weight: 1
        }
      }
    }
  ];
}
