import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ProjectSynthesisDialogComponent } from '@_app/shared/dialogs/project-synthesis/project-synthesis-dialog/project-synthesis-dialog.component';
import { CommunityTypeStructureEnum, OrganizationFamilyOperationTypeEnumTradEToFR } from '@enums';
import {
  Community,
  CommunitySubFamily,
  OrganizationMilestone,
  OrganizationSubFamily,
  TodoElement,
  User
} from '@api/api-interfaces';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-project-kanban',
  templateUrl: './project-kanban.component.html',
  styleUrls: ['./project-kanban.component.scss']
})
export class ProjectKanbanComponent implements OnInit, OnChanges, OnDestroy {
  @Input() project: Community;
  @Input() idProjectFamily: number;
  @Input() listMilestones: OrganizationMilestone[];
  @Input() selectedOrganizationId: number;
  user: User = null;
  private destroy$ = new Subject<void>();

  public typeStructureEnum = OrganizationFamilyOperationTypeEnumTradEToFR;
  public listMilestonesFiltered: OrganizationMilestone[];
  public listTodoElements: TodoElement[] = [];

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.project.communityUserProfils.length) {
      const selected = this.project.communityUserProfils.find(c => c.selected === true);
      if (selected) {
        this.user = selected.user;
      } else if (this.project.communityUserProfils[0].user?.idUser) {
        this.user = this.project.communityUserProfils[0].user;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.listProjectSubFamilies) {
      this.project.communitySubFamilys.forEach((projectSubFamily: CommunitySubFamily) => {
        const currentProjectSubfamily: OrganizationSubFamily = changes.listProjectSubFamilies.currentValue.find(
          subFamily => subFamily.idOrganizationSubFamily === projectSubFamily.idOrganizationSubFamily
        );
        if (!!currentProjectSubfamily) {
          projectSubFamily.couleur = currentProjectSubfamily.couleur;
          projectSubFamily.libelle = currentProjectSubfamily.libelle;
        } else {
          projectSubFamily = undefined;
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public redirectToCommunity($event: MouseEvent, idCommunity: number, typeStructure: CommunityTypeStructureEnum) {
    $event.stopPropagation();
    const newTypeStructure = typeStructure === CommunityTypeStructureEnum.project ? 'project' : 'development';
    this.router.navigate([`/app/${newTypeStructure}/${idCommunity}`]);
  }

  public initiateElements() {
    const ids = this.listMilestonesFiltered.map(milestone => milestone.idOrganizationMilestone);
    this.project.todos.forEach(todo =>
      todo.todoElements.forEach(todoElement => {
        if (ids.includes(todoElement.element.idOrganizationMilestone)) {
          this.listTodoElements.push(todoElement);
        }
      })
    );
  }

  public showCommunityDetails(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement; // Obtenez l'élément sur lequel vous avez cliqué
    const classes = clickedElement.classList; // Obtenez l'ensemble de classes de l'élément
    if (Array.from(classes).includes('open-project-icon')) {
      this.dialog.open(ProjectSynthesisDialogComponent, {
        data: {
          project: this.project,
          todoElements: this.listTodoElements,
          isFromDashboard: true,
          wordingTypeStructure: OrganizationFamilyOperationTypeEnumTradEToFR[this.project.typeStructure],
          typeStructure: this.project.typeStructure
        },
        maxWidth: '100vw',
        width: '950px',
        autoFocus: false,
        panelClass: 'custom-project-dialog-height'
      });
    } else {
      this.redirectToCommunity(event, this.project.idCommunity, this.project.typeStructure);
    }
  }
}
