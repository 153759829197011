export default {
  // Set Filter
  selectAll: '(Sélectionner tout)',
  selectAllSearchResults: '(Sélectionner tous les résultats de recherche)',
  addCurrentSelectionToFilter: 'Ajouter la sélection actuelle au filtre',
  searchOoo: 'Chercher...',
  blanks: '(Vides)',
  noMatches: 'Aucune correspondance',

  // Number Filter & Text Filter
  filterOoo: 'Filtrer...',
  equals: 'Égal à',
  notEqual: "N'est pas égal à",
  blank: 'Vide',
  notBlank: 'Non vide',
  empty: 'Choisissez-en un',

  // Number Filter
  lessThan: 'Moins de',
  greaterThan: 'Plus de',
  lessThanOrEqual: 'Moins de ou égal à',
  greaterThanOrEqual: 'Plus de ou égal à',
  inRange: 'Entre',
  inRangeStart: 'De',
  inRangeEnd: 'À',

  // Text Filter
  contains: 'Contient',
  notContains: 'Ne contient pas',
  startsWith: 'Commence par',
  endsWith: 'Se termine par',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',
  before: 'Avant',
  after: 'Après',

  // Filter Conditions
  andCondition: 'ET',
  orCondition: 'OU',

  // Filter Buttons
  applyFilter: 'Appliquer',
  resetFilter: 'Réinitialiser',
  clearFilter: 'Effacer',
  cancelFilter: 'Annuler',

  // Filter Titles
  textFilter: 'Filtre de texte',
  numberFilter: 'Filtre numérique',
  dateFilter: 'Filtre de date',
  setFilter: 'Filtre de jeu',

  // Group Column Filter
  groupFilterSelect: 'Sélectionner le champ :',

  // Advanced Filter
  advancedFilterContains: 'contient',
  advancedFilterNotContains: 'ne contient pas',
  advancedFilterTextEquals: 'égal',
  advancedFilterTextNotEqual: "n'est pas égal",
  advancedFilterStartsWith: 'commence par',
  advancedFilterEndsWith: 'se termine par',
  advancedFilterBlank: 'est vide',
  advancedFilterNotBlank: "n'est pas vide",
  advancedFilterEquals: '=',
  advancedFilterNotEqual: '!=',
  advancedFilterGreaterThan: '>',
  advancedFilterGreaterThanOrEqual: '>=',
  advancedFilterLessThan: '<',
  advancedFilterLessThanOrEqual: '<=',
  advancedFilterTrue: 'est vrai',
  advancedFilterFalse: 'est faux',
  advancedFilterAnd: 'ET',
  advancedFilterOr: 'OU',
  advancedFilterApply: 'Appliquer',
  advancedFilterBuilder: 'Constructeur',
  advancedFilterValidationMissingColumn: 'La colonne est manquante',
  advancedFilterValidationMissingOption: "L'option est manquante",
  advancedFilterValidationMissingValue: 'La valeur est manquante',
  advancedFilterValidationInvalidColumn: 'Colonne non trouvée',
  advancedFilterValidationInvalidOption: 'Option non trouvée',
  advancedFilterValidationMissingQuote: 'Il manque une guillemet de fin à la valeur',
  advancedFilterValidationNotANumber: "La valeur n'est pas un nombre",
  advancedFilterValidationInvalidDate: "La valeur n'est pas une date valide",
  advancedFilterValidationMissingCondition: 'La condition est manquante',
  advancedFilterValidationJoinOperatorMismatch:
    "Les opérateurs de jonction au sein d'une condition doivent être identiques",
  advancedFilterValidationInvalidJoinOperator: 'Opérateur de jonction non trouvé',
  advancedFilterValidationMissingEndBracket: 'Guillemet de fin manquante',
  advancedFilterValidationExtraEndBracket: 'Trop de crochets de fin',
  advancedFilterValidationMessage: "L'expression contient une erreur. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "L'expression contient une erreur. ${variable} à la fin de l'expression.",
  advancedFilterBuilderTitle: 'Filtre Avancé',
  advancedFilterBuilderApply: 'Appliquer',
  advancedFilterBuilderCancel: 'Annuler',
  advancedFilterBuilderAddButtonTooltip: 'Ajouter un filtre ou un groupe',
  advancedFilterBuilderRemoveButtonTooltip: 'Supprimer',
  advancedFilterBuilderMoveUpButtonTooltip: 'Monter',
  advancedFilterBuilderMoveDownButtonTooltip: 'Descendre',
  advancedFilterBuilderAddJoin: 'Ajouter un groupe',
  advancedFilterBuilderAddCondition: 'Ajouter un filtre',
  advancedFilterBuilderSelectColumn: 'Sélectionner une colonne',
  advancedFilterBuilderSelectOption: 'Sélectionner une option',
  advancedFilterBuilderEnterValue: 'Entrer une valeur...',
  advancedFilterBuilderValidationAlreadyApplied: 'Le filtre actuel est déjà appliqué.',
  advancedFilterBuilderValidationIncomplete: 'Toutes les conditions ne sont pas remplies.',
  advancedFilterBuilderValidationSelectColumn: 'Vous devez sélectionner une colonne.',
  advancedFilterBuilderValidationSelectOption: 'Vous devez sélectionner une option.',
  advancedFilterBuilderValidationEnterValue: 'Vous devez entrer une valeur.',

  // Side Bar
  columns: 'Colonnes',
  filters: 'Filtres',

  // columns tool panel
  pivotMode: 'Mode pivot',
  groups: 'Groupes de lignes',
  rowGroupColumnsEmptyMessage: 'Faites glisser ici pour définir les groupes de lignes',
  values: 'Valeurs',
  valueColumnsEmptyMessage: 'Faites glisser ici pour agréger',
  pivots: 'Étiquettes de colonnes',
  pivotColumnsEmptyMessage: 'Faites glisser ici pour définir les étiquettes de colonnes',

  // Header of the Default Group Column
  group: 'Groupe',

  // Row Drag
  rowDragRow: 'rang',
  rowDragRows: 'rangs',

  // Other
  loadingOoo: 'Chargement...',
  loadingError: 'ERR',
  noRowsToShow: 'Aucune ligne à afficher',
  enabled: 'Activé',

  // Menu
  pinColumn: 'Épingler la colonne',
  pinLeft: 'Épingler à gauche',
  pinRight: 'Épingler à droite',
  noPin: "Pas d'épingle",
  valueAggregation: 'Agrégation de valeur',
  noAggregation: 'Aucun',
  autosizeThisColumn: 'Ajuster automatiquement cette colonne',
  autosizeAllColumns: 'Ajuster automatiquement toutes les colonnes',
  groupBy: 'Grouper par',
  ungroupBy: 'Dégrouper par',
  ungroupAll: 'Dégrouper tout',
  addToValues: 'Ajouter ${variable} aux valeurs',
  removeFromValues: 'Retirer ${variable} des valeurs',
  addToLabels: 'Ajouter ${variable} aux étiquettes',
  removeFromLabels: 'Retirer ${variable} des étiquettes',
  resetColumns: 'Réinitialiser les colonnes',
  expandAll: 'Développer tous les groupes de lignes',
  collapseAll: 'Fermer tous les groupes de lignes',
  copy: 'Copier',
  ctrlC: 'Ctrl+C',
  ctrlX: 'Ctrl+X',
  copyWithHeaders: 'Copier avec en-têtes',
  copyWithGroupHeaders: 'Copier avec en-têtes de groupe',
  cut: 'Couper',
  paste: 'Coller',
  ctrlV: 'Ctrl+V',
  export: 'Exporter',
  csvExport: 'Export CSV',
  excelExport: 'Export Excel',
  columnFilter: 'Filtre de colonne',
  columnChooser: 'Choisir les colonnes',
  sortAscending: 'Trier par ordre croissant',
  sortDescending: 'Trier par ordre décroissant',
  sortUnSort: 'Effacer le tri',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Somme',
  first: 'Premier',
  last: 'Dernier',
  min: 'Min',
  max: 'Max',
  none: 'Aucun',
  count: 'Nombre',
  avg: 'Moyenne',
  filteredRows: 'Filtré',
  selectedRows: 'Sélectionné',
  totalRows: 'Total des Lignes',
  totalAndFilteredRows: 'Lignes',
  more: 'Plus',
  to: 'à',
  of: 'de',
  page: 'Page',
  pageLastRowUnknown: '?',
  nextPage: 'Page Suivante',
  lastPage: 'Dernière Page',
  firstPage: 'Première Page',
  previousPage: 'Page Précédente',
  pageSizeSelectorLabel: 'Taille de la Page :',
  footerTotal: 'Total',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Graphique pivot et mode pivot',
  pivotChart: 'Graphique pivot',
  chartRange: 'Plage de graphique',
  columnChart: 'Colonne',
  groupedColumn: 'Groupé',
  stackedColumn: 'Empilé',
  normalizedColumn: '100% empilé',
  barChart: 'Barre',
  groupedBar: 'Groupé',
  stackedBar: 'Empilé',
  normalizedBar: '100% empilé',
  pieChart: 'Camembert',
  pie: 'Camembert',
  donut: 'Donut',
  line: 'Ligne',
  xyChart: 'X Y (Nuage de points)',
  scatter: 'Nuage de points',
  bubble: 'Bulle',
  areaChart: 'Aire',
  area: 'Aire',
  stackedArea: 'Empilé',
  normalizedArea: '100% empilé',
  histogramChart: 'Histogramme',
  polarChart: 'Polaire',
  radarLine: 'Ligne radar',
  radarArea: 'Aire radar',
  nightingale: 'Nightingale',
  radialColumn: 'Colonne radiale',
  radialBar: 'Barre radiale',
  statisticalChart: 'Statistique',
  boxPlot: 'Boîte à moustaches',
  rangeBar: 'Barre de plage',
  rangeArea: 'Aire de plage',
  hierarchicalChart: 'Hiérarchique',
  treemap: 'Arborescence',
  sunburst: 'Rayonnement',
  specializedChart: 'Spécialisé',
  waterfall: 'Cascade',
  heatmap: 'Carte thermique',
  combinationChart: 'Combinaison',
  columnLineCombo: 'Colonne et ligne',
  AreaColumnCombo: 'Aire et colonne',

  // Charts
  pivotChartTitle: 'Graphique croisé dynamique',
  rangeChartTitle: 'Graphique en plage',
  settings: 'Graphique',
  data: 'Configurer',
  format: 'Personnaliser',
  categories: 'Catégories',
  defaultCategory: '(Aucun)',
  series: 'Séries',
  switchCategorySeries: 'Changer Catégorie / Séries',
  categoryValues: 'Valeurs de catégorie',
  seriesLabels: 'Étiquettes de séries',
  aggregate: 'Agrégat',
  xyValues: 'Valeurs X Y',
  paired: 'Mode apparié',
  axis: 'Axe',
  xAxis: 'Axe horizontal',
  yAxis: 'Axe vertical',
  polarAxis: 'Axe polaire',
  radiusAxis: 'Axe de rayon',
  navigator: 'Navigateur',
  zoom: 'Zoom',
  animation: 'Animation',
  crosshair: 'Réticule',
  color: 'Couleur',
  thickness: 'Épaisseur',
  preferredLength: 'Longueur préférée',
  xType: 'Type X',
  axisType: "Type d'axe",
  automatic: 'Automatique',
  category: 'Catégorie',
  number: 'Nombre',
  time: 'Temps',
  timeFormat: 'Format de temps',
  autoRotate: 'Rotation auto',
  labelRotation: 'Rotation',
  circle: 'Cercle',
  polygon: 'Polygone',
  square: 'Carré',
  cross: 'Croix',
  diamond: 'Diamant',
  plus: 'Plus',
  triangle: 'Triangle',
  heart: 'Cœur',
  orientation: 'Orientation',
  fixed: 'Fixé',
  parallel: 'Parallèle',
  perpendicular: 'Perpendiculaire',
  radiusAxisPosition: 'Position',
  ticks: 'Graduations',
  gridLines: 'Lignes de grille',
  width: 'Largeur',
  height: 'Hauteur',
  length: 'Longueur',
  padding: 'Rembourrage',
  spacing: 'Espacement',
  chartStyle: 'Style de graphique',
  title: 'Titre',
  chartTitles: 'Titres',
  chartTitle: 'Titre du graphique',
  chartSubtitle: 'Sous-titre',
  horizontalAxisTitle: "Titre de l'axe horizontal",
  verticalAxisTitle: "Titre de l'axe vertical",
  polarAxisTitle: "Titre de l'axe polaire",
  titlePlaceholder: 'Titre du graphique',
  background: 'Arrière-plan',
  font: 'Police',
  weight: 'Poids',
  top: 'Haut',
  right: 'Droite',
  bottom: 'Bas',
  left: 'Gauche',
  labels: 'Étiquettes',
  calloutLabels: "Étiquettes d'appel",
  sectorLabels: 'Étiquettes de secteur',
  positionRatio: 'Ratio de position',
  size: 'Taille',
  shape: 'Forme',
  minSize: 'Taille minimum',
  maxSize: 'Taille maximum',
  legend: 'Légende',
  position: 'Position',
  markerSize: 'Taille du marqueur',
  markerStroke: 'Contour du marqueur',
  markerPadding: 'Rembourrage du marqueur',
  itemSpacing: 'Espacement des éléments',
  itemPaddingX: "Rembourrage d'élément X",
  itemPaddingY: "Rembourrage d'élément Y",
  layoutHorizontalSpacing: 'Espacement horizontal',
  layoutVerticalSpacing: 'Espacement vertical',
  strokeWidth: 'Épaisseur du trait',
  offset: 'Décalage',
  offsets: 'Décalages',
  tooltips: 'Info-bulles',
  callout: 'Appel',
  markers: 'Marqueurs',
  shadow: 'Ombre',
  blur: 'Flou',
  xOffset: 'Décalage X',
  yOffset: 'Décalage Y',
  lineWidth: 'Épaisseur de la ligne',
  lineDash: 'Tirets de la ligne',
  lineDashOffset: 'Décalage des tirets',
  scrollingZoom: 'Défilement',
  scrollingStep: 'Étape de défilement',
  selectingZoom: 'Sélection',
  durationMillis: 'Durée (ms)',
  crosshairLabel: 'Étiquette',
  crosshairSnap: 'Accrocher au nœud',
  normal: 'Normal',
  bold: 'Gras',
  italic: 'Italique',
  boldItalic: 'Gras Italique',
  predefined: 'Prédéfini',
  fillOpacity: 'Opacité de remplissage',
  strokeColor: 'Couleur du trait',
  strokeOpacity: 'Opacité du trait',
  miniChart: 'Mini-Graphique',
  histogramBinCount: 'Nombre de bins',
  connectorLine: 'Ligne de connexion',
  seriesItems: 'Éléments de série',
  seriesItemType: "Type d'élément",
  seriesItemPositive: 'Positif',
  seriesItemNegative: 'Négatif',
  seriesItemLabels: "Étiquettes d'éléments",
  columnGroup: 'Colonne',
  barGroup: 'Barre',
  pieGroup: 'Camembert',
  lineGroup: 'Ligne',
  scatterGroup: 'X Y (Nuage de points)',
  areaGroup: 'Zone',
  polarGroup: 'Polaire',
  statisticalGroup: 'Statistique',
  hierarchicalGroup: 'Hiérarchique',
  specializedGroup: 'Spécialisé',
  combinationGroup: 'Combinaison',
  groupedColumnTooltip: 'Groupe',
  stackedColumnTooltip: 'Empilé',
  normalizedColumnTooltip: '100% Empilé',
  groupedBarTooltip: 'Groupe',
  stackedBarTooltip: 'Empilé',
  normalizedBarTooltip: '100% Empilé',
  pieTooltip: 'Camembert',
  donutTooltip: 'Donut',
  lineTooltip: 'Ligne',
  groupedAreaTooltip: 'Zone',
  stackedAreaTooltip: 'Empilé',
  normalizedAreaTooltip: '100% Empilé',
  scatterTooltip: 'Nuage de points',
  bubbleTooltip: 'Bulle',
  histogramTooltip: 'Histogramme',
  radialColumnTooltip: 'Colonne radiale',
  radialBarTooltip: 'Barre radiale',
  radarLineTooltip: 'Ligne radar',
  radarAreaTooltip: 'Zone radar',
  nightingaleTooltip: 'Nightingale',
  rangeBarTooltip: 'Barre de plage',
  rangeAreaTooltip: 'Zone de plage',
  boxPlotTooltip: 'Boîte à moustaches',
  treemapTooltip: 'Treemap',
  sunburstTooltip: 'Rayon de soleil',
  waterfallTooltip: "Chute d'eau",
  heatmapTooltip: 'Carte thermique',
  columnLineComboTooltip: 'Colonne & Ligne',
  areaColumnComboTooltip: 'Zone & Colonne',
  customComboTooltip: 'Combinaison Personnalisée',
  innerRadius: 'Rayon intérieur',
  startAngle: 'Angle de départ',
  endAngle: 'Angle de fin',
  reverseDirection: 'Inverser la direction',
  groupPadding: 'Rembourrage de groupe',
  seriesPadding: 'Rembourrage de séries',
  tile: 'Tuile',
  whisker: 'Moustache',
  cap: 'Capuchon',
  capLengthRatio: 'Ratio de longueur',
  labelPlacement: 'Placement',
  inside: "À l'intérieur",
  outside: "À l'extérieur",
  noDataToChart: 'Aucune donnée disponible à afficher.',
  pivotChartRequiresPivotMode: 'Le graphique croisé dynamique nécessite le mode croisé activé.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Lié à la grille',
  chartUnlinkToolbarTooltip: 'Détaché de la grille',
  chartDownloadToolbarTooltip: 'Télécharger le graphique',
  chartMenuToolbarTooltip: 'Menu',
  chartEdit: 'Modifier le graphique',
  chartAdvancedSettings: 'Paramètres avancés',
  chartLink: 'Lier à la grille',
  chartUnlink: 'Détacher de la grille',
  chartDownload: 'Télécharger le graphique',
  histogramFrequency: 'Fréquence',
  seriesChartType: 'Type de graphique de séries',
  seriesType: 'Type de série',
  secondaryAxis: 'Axe secondaire',
  seriesAdd: 'Ajouter une série',
  categoryAdd: 'Ajouter une catégorie',
  bar: 'Barre',
  column: 'Colonne',
  histogram: 'Histogramme',
  advancedSettings: 'Paramètres avancés',
  direction: 'Direction',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  seriesGroupType: 'Type de groupe',
  groupedSeriesGroupType: 'Groupé',
  stackedSeriesGroupType: 'Empilé',
  normalizedSeriesGroupType: '100% Empilé',
  legendEnabled: 'Activé',
  invalidColor: 'La valeur de couleur est invalide',
  groupedColumnFull: 'Colonne groupée',
  stackedColumnFull: 'Colonne empilée',
  normalizedColumnFull: 'Colonne 100% empilée',
  groupedBarFull: 'Barre groupée',
  stackedBarFull: 'Barre empilée',
  normalizedBarFull: 'Barre 100% empilée',
  stackedAreaFull: 'Zone empilée',
  normalizedAreaFull: 'Zone 100% empilée',
  customCombo: 'Combinaison personnalisée',

  // ARIA
  ariaAdvancedFilterBuilderItem: '${variable}. Niveau ${variable}. Appuyez sur ENTER pour éditer.',
  ariaAdvancedFilterBuilderItemValidation:
    '${variable}. Niveau ${variable}. ${variable} Appuyez sur ENTER pour éditer.',
  ariaAdvancedFilterBuilderList: 'Liste de cadre de filtre avancé',
  ariaAdvancedFilterBuilderFilterItem: 'Condition de filtre',
  ariaAdvancedFilterBuilderGroupItem: 'Groupe de filtre',
  ariaAdvancedFilterBuilderColumn: 'Colonne',
  ariaAdvancedFilterBuilderOption: 'Option',
  ariaAdvancedFilterBuilderValueP: 'Valeur',
  ariaAdvancedFilterBuilderJoinOperator: 'Opérateur de jonction',
  ariaAdvancedFilterInput: 'Entrée de filtre avancé',
  ariaChecked: 'coché',
  ariaColumn: 'Colonne',
  ariaColumnGroup: 'Groupe de colonnes',
  ariaColumnFiltered: 'Colonne filtrée',
  ariaColumnSelectAll: 'Basculer la sélection de toutes les colonnes',
  ariaDateFilterInput: 'Entrée de filtre de date',
  ariaDefaultListName: 'Liste',
  ariaFilterColumnsInput: 'Entrée de filtre de colonnes',
  ariaFilterFromValue: 'Filtrer depuis la valeur',
  ariaFilterInput: 'Entrée de filtre',
  ariaFilterList: 'Liste de filtres',
  ariaFilterToValue: "Filtrer jusqu'à la valeur",
  ariaFilterValue: 'Valeur du filtre',
  ariaFilterMenuOpen: 'Ouvrir le menu de filtre',
  ariaFilteringOperator: 'Opérateur de filtrage',
  ariaHidden: 'caché',
  ariaIndeterminate: 'indéterminé',
  ariaInputEditor: "Éditeur d'entrée",
  ariaMenuColumn: 'Appuyez sur ALT BAS pour ouvrir le menu de colonne',
  ariaFilterColumn: 'Appuyez sur CTRL ENTER pour ouvrir le filtre',
  ariaRowDeselect: 'Appuyez sur ESPACE pour désélectionner cette ligne',
  ariaRowSelectAll: 'Appuyez sur ESPACE pour basculer la sélection de toutes les lignes',
  ariaRowToggleSelection: 'Appuyez sur ESPACE pour basculer la sélection de la ligne',
  ariaRowSelect: 'Appuyez sur ESPACE pour sélectionner cette ligne',
  ariaRowSelectionDisabled: 'La sélection de lignes est désactivée pour cette ligne',
  ariaSearch: 'Rechercher',
  ariaSortableColumn: 'Appuyez sur ENTER pour trier',
  ariaToggleVisibility: 'Appuyez sur ESPACE pour basculer la visibilité',
  ariaToggleCellValue: 'Appuyez sur ESPACE pour basculer la valeur de la cellule',
  ariaUnchecked: 'non coché',
  ariaVisible: 'visible',
  ariaSearchFilterValues: 'Rechercher les valeurs de filtre',
  ariaPageSizeSelectorLabel: 'Taille de page',
  ariaChartMenuClose: "Fermer le menu d'édition de graphique",
  ariaChartSelected: 'Sélectionné',
  ariaSkeletonCellLoadingFailed: 'Le chargement de la ligne a échoué',
  ariaSkeletonCellLoading: 'Les données de la ligne sont en cours de chargement',

  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: 'Groupes de lignes',
  ariaValuesDropZonePanelLabel: 'Valeurs',
  ariaPivotDropZonePanelLabel: 'Étiquettes de colonnes',
  ariaDropZoneColumnComponentDescription: 'Appuyez sur SUPPRIMER pour enlever',
  ariaDropZoneColumnValueItemDescription: "Appuyez sur ENTRÉE pour changer le type d'agrégation",
  ariaDropZoneColumnGroupItemDescription: 'Appuyez sur ENTRÉE pour trier',

  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: ' de ',
  ariaDropZoneColumnComponentSortAscending: 'ascendant',
  ariaDropZoneColumnComponentSortDescending: 'descendant',
  ariaLabelDialog: 'Dialogue',
  ariaLabelColumnMenu: 'Menu de colonne',
  ariaLabelColumnFilter: 'Filtre de colonne',
  ariaLabelCellEditor: 'Éditeur de cellule',
  ariaLabelSelectField: 'Champ de sélection',

  // aria labels for rich select
  ariaLabelRichSelectField: 'Champ de sélection enrichie',
  ariaLabelRichSelectToggleSelection: 'Appuyez sur ESPACE pour basculer la sélection',
  ariaLabelRichSelectDeselectAllItems: 'Appuyez sur SUPPRIMER pour désélectionner tous les éléments',
  ariaLabelRichSelectDeleteSelection: "Appuyez sur SUPPRIMER pour désélectionner l'élément",
  ariaLabelTooltip: 'Info-bulle',
  ariaLabelContextMenu: 'Menu contextuel',
  ariaLabelSubMenu: 'Sous-menu',
  ariaLabelAggregationFunction: "Fonction d'agrégation",
  ariaLabelAdvancedFilterAutocomplete: 'Filtre avancé auto-complétion',
  ariaLabelAdvancedFilterBuilderAddField: 'Filtre avancé constructeur Ajouter un champ',
  ariaLabelAdvancedFilterBuilderColumnSelectField: 'Filtre avancé constructeur Sélectionnez le champ de colonne',
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Filtre avancé constructeur Sélectionnez le champ d'option",
  ariaLabelAdvancedFilterBuilderJoinSelectField:
    "Filtre avancé constructeur Sélectionnez le champ de l'opérateur de jointure",

  // ARIA Labels for the Side Bar
  ariaColumnPanelList: 'Liste des colonnes',
  ariaFilterPanelList: 'Liste des filtres',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ',',
  decimalSeparator: '.',

  // Data types
  true: 'Vrai',
  false: 'Faux',
  invalidDate: 'Date invalide',
  invalidNumber: 'Nombre invalide',
  january: 'Janvier',
  february: 'Février',
  march: 'Mars',
  april: 'Avril',
  may: 'Mai',
  june: 'Juin',
  july: 'Juillet',
  august: 'Août',
  september: 'Septembre',
  october: 'Octobre',
  november: 'Novembre',
  december: 'Décembre',

  // Time formats
  timeFormatSlashesDDMMYYYY: 'JJ/MM/AAAA',
  timeFormatSlashesMMDDYYYY: 'MM/JJ/AAAA',
  timeFormatSlashesDDMMYY: 'JJ/MM/AA',
  timeFormatSlashesMMDDYY: 'MM/JJ/AA',
  timeFormatDotsDDMYY: 'JJ.M.AA',
  timeFormatDotsMDDYY: 'M.JJ.AA',
  timeFormatDashesYYYYMMDD: 'AAAA-MM-JJ',
  timeFormatSpacesDDMMMMYYYY: 'JJ MMMM AAAA',
  timeFormatHHMMSS: 'HH:MM:SS',
  timeFormatHHMMSSAmPm: 'HH:MM:SS AM/PM',

  // TODO : traduire cet objet en fonction du contexte de chaque propriété durant l'utilisatioon d'ag-grid
  // for filter panel
  // https://www.ag-grid.com/angular-data-grid/localisation/

  next: 'Suivant',
  previous: 'Précédent',
  rowGroupColumns: 'Groupe de colonnes',
  valueColumns: 'Valeur des colonnes',
  // prettier-ignore
  toolPanelButton: 'Panneau d\'outils',
  toolPanel: 'laTool Panelo',
  excelXmlExport: 'Exporter sous Excel au format .xml',
  average: 'laAverage'
};
