import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ElementCashApiService } from '@wip/store/api-services';
import { GeneratedElementCashEffects } from './element-cash-generated.effects';
import { ElementCash } from '@api/api-interfaces';
import { switchMap, map, catchError, of } from 'rxjs';
import * as ElementCashActions from '@wip/store/actions';

@Injectable()
export class ElementCashEffects extends GeneratedElementCashEffects {
  constructor(actions$: Actions, elementCashApiService: ElementCashApiService, store$: Store<AppState>) {
    super(actions$, elementCashApiService, store$);
  }

  getElementForFinancial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementCashActions.getElementForFinancial),
      switchMap(params =>
        this.elementCashApiService.getElementForFinancial(params.idCommunity).pipe(
          map((elementCashs: ElementCash[]) => {
            return ElementCashActions.ElementCashGeneratedActions.normalizeManyElementCashsAfterUpsert({
              elementCashs
            });
          }),
          catchError(error => of(ElementCashActions.ElementCashGeneratedActions.elementCashsFailure({ error })))
        )
      )
    );
  });
}
