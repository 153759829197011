import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityUserProfil, CommunityUserProfilEntityState } from '@api/api-interfaces';
import { CommunityUserProfilRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityUserProfilGeneratedActions = createActionGroup({
  source: 'Community User Profil Generated',
  events: {
    'Get One Community User Profil': props<{ idCommunityUserProfil: number; params?: any }>(),
    'Get Many Community User Profils': props<{ params: any }>(),
    'Add Many Actives Community User Profils': props<{ idCommunityUserProfils: number[] }>(),
    'Delete One Active Community User Profil': props<{ idCommunityUserProfil: number }>(),
    'Clear Actives Community User Profils': emptyProps(),
    'Upsert One Community User Profil': props<{
      communityUserProfil: Partial<CommunityUserProfil>;
      ids?: CommunityUserProfilRelationsIds;
    }>(),
    'Upsert Many Community User Profils': props<{
      communityUserProfils: Partial<CommunityUserProfil>[];
      ids?: CommunityUserProfilRelationsIds;
    }>(),
    'Upsert Many Community User Profils Success': props<{ communityUserProfils: CommunityUserProfilEntityState[] }>(),
    'Delete One Community User Profil': props<{ idCommunityUserProfil: number }>(),
    'Delete One Community User Profil Success': props<{ idCommunityUserProfil: number }>(),
    'Normalize Many Community User Profils After Upsert': props<{
      communityUserProfils: CommunityUserProfilEntityState[];
    }>(),
    'Community User Profils Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community User Profils': emptyProps(),
    'Add Many Element Library Success': props<{ idCommunityUserProfil: number; idElementLibraries: number[] }>(),
    'Delete Many Element Library Success': props<{ idElementLibraries: number[]; idCommunityUserProfils: number[] }>(),
    'Add Community Success': props<{ idCommunityUserProfil: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityUserProfils: number[] }>(),
    'Add User Success': props<{ idCommunityUserProfil: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idCommunityUserProfils: number[] }>(),
    'Add Organization User Profil Success': props<{
      idCommunityUserProfil: number;
      idOrganizationUserProfil: number;
    }>(),
    'Delete Many Organization User Profil Success': props<{
      idOrganizationUserProfils: number[];
      idCommunityUserProfils: number[];
    }>()
  }
});
