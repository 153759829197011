import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationCaracteristicRow } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationCaracteristicRowRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationCaracteristicRowGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicRowSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationCaracteristicRowService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationCaracteristicRowSelectors.selectIsLoadedOrganizationCaracteristicRow));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationCaracteristicRowSelectors.selectIsLoadingOrganizationCaracteristicRow));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationCaracteristicRowSelectors.selectIsReadyAndLoadedOrganizationCaracteristicRow as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationCaracteristicRows(schema: SelectSchema = {}): Observable<OrganizationCaracteristicRow[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicRowSelectors.selectAllOrganizationCaracteristicRows(schema)))
      .pipe(
        switchMap(
          ({ organizationCaracteristicRows }: { organizationCaracteristicRows: OrganizationCaracteristicRow[] }) => {
            return this.getReady(schema).pipe(mapTo(organizationCaracteristicRows));
          }
        )
      );
  }

  public selectOneOrganizationCaracteristicRow(
    idOrganizationCaracteristicRow: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationCaracteristicRow> {
    return this.store$
      .pipe(
        select(
          OrganizationCaracteristicRowSelectors.selectOneOrganizationCaracteristicRow(
            schema,
            idOrganizationCaracteristicRow
          )
        )
      )
      .pipe(
        switchMap(
          ({ organizationCaracteristicRow }: { organizationCaracteristicRow: OrganizationCaracteristicRow }) => {
            return this.getReady(schema).pipe(mapTo(organizationCaracteristicRow));
          }
        )
      );
  }

  public selectAllActiveOrganizationCaracteristicRows(
    schema: SelectSchema = {}
  ): Observable<OrganizationCaracteristicRow[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicRowSelectors.selectActiveOrganizationCaracteristicRows(schema)))
      .pipe(
        switchMap(
          ({ organizationCaracteristicRows }: { organizationCaracteristicRows: OrganizationCaracteristicRow[] }) => {
            return this.getReady(schema).pipe(mapTo(organizationCaracteristicRows));
          }
        )
      );
  }

  public selectIdOrganizationCaracteristicRowsActive(): Observable<number[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicRowSelectors.selectIdOrganizationCaracteristicRowsActive))
      .pipe(
        switchMap((idOrganizationCaracteristicRows: number[]) => {
          return this.getReady().pipe(mapTo(idOrganizationCaracteristicRows));
        })
      );
  }

  public getOneOrganizationCaracteristicRow(
    idOrganizationCaracteristicRow: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristicRow> {
    this.store$.dispatch(
      OrganizationCaracteristicRowGeneratedActions.getOneOrganizationCaracteristicRow({
        idOrganizationCaracteristicRow,
        params
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicRowGeneratedActions.normalizeManyOrganizationCaracteristicRowsAfterUpsert,
        OrganizationCaracteristicRowGeneratedActions.organizationCaracteristicRowsFailure,
        true
      );
    }
  }

  public getManyOrganizationCaracteristicRows(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristicRow[]> {
    this.store$.dispatch(OrganizationCaracteristicRowGeneratedActions.getManyOrganizationCaracteristicRows({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicRowGeneratedActions.normalizeManyOrganizationCaracteristicRowsAfterUpsert,
        OrganizationCaracteristicRowGeneratedActions.organizationCaracteristicRowsFailure
      );
    }
  }

  public upsertOneOrganizationCaracteristicRow(
    organizationCaracteristicRow: Partial<OrganizationCaracteristicRow>,
    ids: OrganizationCaracteristicRowRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristicRow> {
    this.store$.dispatch(
      OrganizationCaracteristicRowGeneratedActions.upsertOneOrganizationCaracteristicRow({
        organizationCaracteristicRow,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicRowGeneratedActions.normalizeManyOrganizationCaracteristicRowsAfterUpsert,
        OrganizationCaracteristicRowGeneratedActions.organizationCaracteristicRowsFailure,
        true
      );
    }
  }

  public deleteOneOrganizationCaracteristicRow(
    idOrganizationCaracteristicRow: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationCaracteristicRowGeneratedActions.deleteOneOrganizationCaracteristicRow({
        idOrganizationCaracteristicRow
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicRowGeneratedActions.deleteOneOrganizationCaracteristicRowSuccess,
        OrganizationCaracteristicRowGeneratedActions.organizationCaracteristicRowsFailure
      );
    }
  }

  public setActiveOrganizationCaracteristicRows(idOrganizationCaracteristicRows: number[]): void {
    this.store$.dispatch(OrganizationCaracteristicRowGeneratedActions.clearActivesOrganizationCaracteristicRows());
    this.store$.dispatch(
      OrganizationCaracteristicRowGeneratedActions.addManyActivesOrganizationCaracteristicRows({
        idOrganizationCaracteristicRows
      })
    );
  }
}
