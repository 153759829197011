import { Injectable } from '@angular/core';import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationCaracteristicColumnApiService } from './organization-caracteristic-column-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationCaracteristicColumnApiService extends GeneratedOrganizationCaracteristicColumnApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
