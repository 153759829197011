import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CommunityUserProfil } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { CommunityUserProfilRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityUserProfilGeneratedActions } from '@wip/store/actions';
import { CommunityUserProfilSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCommunityUserProfilService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CommunityUserProfilSelectors.selectIsLoadedCommunityUserProfil));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CommunityUserProfilSelectors.selectIsLoadingCommunityUserProfil));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      CommunityUserProfilSelectors.selectIsReadyAndLoadedCommunityUserProfil as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCommunityUserProfils(schema: SelectSchema = {}): Observable<CommunityUserProfil[]> {
    return this.store$.pipe(select(CommunityUserProfilSelectors.selectAllCommunityUserProfils(schema))).pipe(
      switchMap(({ communityUserProfils }: { communityUserProfils: CommunityUserProfil[] }) => {
        return this.getReady(schema).pipe(mapTo(communityUserProfils));
      })
    );
  }

  public selectOneCommunityUserProfil(
    idCommunityUserProfil: number,
    schema: SelectSchema = {}
  ): Observable<CommunityUserProfil> {
    return this.store$
      .pipe(select(CommunityUserProfilSelectors.selectOneCommunityUserProfil(schema, idCommunityUserProfil)))
      .pipe(
        switchMap(({ communityUserProfil }: { communityUserProfil: CommunityUserProfil }) => {
          return this.getReady(schema).pipe(mapTo(communityUserProfil));
        })
      );
  }

  public selectAllActiveCommunityUserProfils(schema: SelectSchema = {}): Observable<CommunityUserProfil[]> {
    return this.store$.pipe(select(CommunityUserProfilSelectors.selectActiveCommunityUserProfils(schema))).pipe(
      switchMap(({ communityUserProfils }: { communityUserProfils: CommunityUserProfil[] }) => {
        return this.getReady(schema).pipe(mapTo(communityUserProfils));
      })
    );
  }

  public selectIdCommunityUserProfilsActive(): Observable<number[]> {
    return this.store$.pipe(select(CommunityUserProfilSelectors.selectIdCommunityUserProfilsActive)).pipe(
      switchMap((idCommunityUserProfils: number[]) => {
        return this.getReady().pipe(mapTo(idCommunityUserProfils));
      })
    );
  }

  public getOneCommunityUserProfil(
    idCommunityUserProfil: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<CommunityUserProfil> {
    this.store$.dispatch(
      CommunityUserProfilGeneratedActions.getOneCommunityUserProfil({ idCommunityUserProfil, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert,
        CommunityUserProfilGeneratedActions.communityUserProfilsFailure,
        true
      );
    }
  }

  public getManyCommunityUserProfils(params: any = {}, getResult?: boolean): void | Observable<CommunityUserProfil[]> {
    this.store$.dispatch(CommunityUserProfilGeneratedActions.getManyCommunityUserProfils({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert,
        CommunityUserProfilGeneratedActions.communityUserProfilsFailure
      );
    }
  }

  public upsertOneCommunityUserProfil(
    communityUserProfil: Partial<CommunityUserProfil>,
    ids: CommunityUserProfilRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CommunityUserProfil> {
    this.store$.dispatch(
      CommunityUserProfilGeneratedActions.upsertOneCommunityUserProfil({ communityUserProfil, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert,
        CommunityUserProfilGeneratedActions.communityUserProfilsFailure,
        true
      );
    }
  }

  public deleteOneCommunityUserProfil(idCommunityUserProfil: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CommunityUserProfilGeneratedActions.deleteOneCommunityUserProfil({ idCommunityUserProfil }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserProfilGeneratedActions.deleteOneCommunityUserProfilSuccess,
        CommunityUserProfilGeneratedActions.communityUserProfilsFailure
      );
    }
  }

  public setActiveCommunityUserProfils(idCommunityUserProfils: number[]): void {
    this.store$.dispatch(CommunityUserProfilGeneratedActions.clearActivesCommunityUserProfils());
    this.store$.dispatch(
      CommunityUserProfilGeneratedActions.addManyActivesCommunityUserProfils({ idCommunityUserProfils })
    );
  }
}
