import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CommonModule } from '@angular/common';
import { InformationDialogDumbComponent } from '../information-dialog-dumb/information-dialog-dumb.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'wip-information-dialog-container',
  standalone: true,
  imports: [CommonModule, InformationDialogDumbComponent],
  templateUrl: './information-dialog-container.component.html',
  styleUrls: ['./information-dialog-container.component.scss']
})
export class InformationDialogContainerComponent implements OnInit {
  public title!: string;
  public body!: string;

  constructor(
    public dialogRef: MatDialogRef<InformationDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { title: string; body: string }
  ) {}

  ngOnInit() {
    this.title = this.dialogData.title;
    this.body = this.dialogData.body;
  }

  emitConfirm() {
    this.dialogRef.close(true);
  }
}
