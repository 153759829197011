import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedCommunityUserProfilService } from './community-user-profil-generated.service';
import * as CommunityUserProfilAction from '@wip/store/actions';

import { Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { CommunityUserProfil } from '@api/api-interfaces';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';

@Injectable({
  providedIn: 'root'
})
export class CommunityUserProfilService extends GeneratedCommunityUserProfilService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public convertCommunityUserProfils(ids: number[], getResult?: boolean): void | Observable<CommunityUserProfil> {
    this.store$.dispatch(CommunityUserProfilAction.convertCommunityUserProfils({ ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserProfilAction.CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert,
        CommunityUserProfilAction.CommunityUserProfilGeneratedActions.communityUserProfilsFailure,
        true
      );
    }
  }

  public fillHandleUserProfils(
    userProfils: Partial<CommunityUserProfil>[],
    getResult?: boolean
  ): void | Observable<CommunityUserProfil[]> {
    this.store$.dispatch(CommunityUserProfilAction.FillHandleUserProfils({ userProfils }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserProfilAction.CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert,
        CommunityUserProfilAction.CommunityUserProfilGeneratedActions.communityUserProfilsFailure,
        true
      );
    }
  }

  public clearCommunityUserProfils() {
    this.store$.dispatch(CommunityUserProfilAction.CommunityUserProfilGeneratedActions.clearCommunityUserProfils());
  }
}
