import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationRisk, OrganizationRiskEntityState } from '@api/api-interfaces';
import { OrganizationRiskRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationRiskGeneratedActions = createActionGroup({
  source: 'Organization Risk Generated',
  events: {
    'Get One Organization Risk': props<{ idOrganizationRisk: number; params?: any }>(),
    'Get Many Organization Risks': props<{ params: any }>(),
    'Add Many Actives Organization Risks': props<{ idOrganizationRisks: number[] }>(),
    'Delete One Active Organization Risk': props<{ idOrganizationRisk: number }>(),
    'Clear Actives Organization Risks': emptyProps(),
    'Upsert One Organization Risk': props<{
      organizationRisk: Partial<OrganizationRisk>;
      ids?: OrganizationRiskRelationsIds;
    }>(),
    'Upsert Many Organization Risks': props<{
      organizationRisks: Partial<OrganizationRisk>[];
      ids?: OrganizationRiskRelationsIds;
    }>(),
    'Upsert Many Organization Risks Success': props<{ organizationRisks: OrganizationRiskEntityState[] }>(),
    'Delete One Organization Risk': props<{ idOrganizationRisk: number }>(),
    'Delete One Organization Risk Success': props<{ idOrganizationRisk: number }>(),
    'Normalize Many Organization Risks After Upsert': props<{ organizationRisks: OrganizationRiskEntityState[] }>(),
    'Organization Risks Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Risks': emptyProps(),
    'Add Many Community Risk Success': props<{ idOrganizationRisk: number; idCommunityRisks: number[] }>(),
    'Delete Many Community Risk Success': props<{ idCommunityRisks: number[]; idOrganizationRisks: number[] }>(),
    'Add Organization Success': props<{ idOrganizationRisk: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationRisks: number[] }>(),
    'Add Organization Family Success': props<{ idOrganizationRisk: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{
      idOrganizationFamilys: number[];
      idOrganizationRisks: number[];
    }>()
  }
});
