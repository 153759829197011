import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrganizationCaracteristic, OrganizationMilestone, OrganizationMilestoneFamily } from '@api/api-interfaces';
import { CaracteristiqueTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  OrganizationCaracteristicService,
  OrganizationFamilyService,
  OrganizationMilestoneFamilyService
} from '@wip/store/services';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { ChartDatasService } from './chart-services/chart-datas.service';
@UntilDestroy()
@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() idOrganizationSelected: number;
  @Input() idProjectFamily: number;
  @Input() projectMilestoneFamilies: OrganizationMilestoneFamily[];
  @Input() idsProjectSubFamily: number[];
  @Input() idOrganizationFamily: number;
  @Input() chartParams: any;

  private destroy$ = new Subject<void>();

  public selectedProjectCharacteristicsIds: number[];
  public projectCharacteristics: OrganizationCaracteristic[];
  public projectMilestones: OrganizationMilestone[];
  public chartInputsForm: FormGroup;
  public areDatasOk: boolean;
  public chartData: any;

  constructor(
    private chartService: ChartDatasService,
    private newOrganizationMilestoneFamilyService: OrganizationMilestoneFamilyService,
    private newOrganizationCaracteristicService: OrganizationCaracteristicService,
    private newOrganizationFamilyService: OrganizationFamilyService
  ) {}

  ngOnInit() {
    this.areDatasOk = false;
    this.initChartInputs();
    this.getAllCharacteristics();
    this.subscribeDatas();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.idOrganizationelected || !!changes.idProjectFamily) {
      this.resetCharacteristics();
    }
  }

  private initChartInputs(): void {
    this.chartInputsForm = this.newOrganizationMilestoneFamilyService.chartInputsForm;
  }

  private getAllCharacteristics(): void {
    this.newOrganizationCaracteristicService
      .selectAllOrganizationCaracteristics()
      .pipe(
        untilDestroyed(this),
        map(orgaCaracs =>
          orgaCaracs.filter(
            orgCarac =>
              orgCarac.idOrganizationFamily === this.idOrganizationFamily &&
              (orgCarac.type === CaracteristiqueTypeEnum.decimal || orgCarac.type === CaracteristiqueTypeEnum.integer)
          )
        ),
        tap(orgCaracs => {
          this.projectCharacteristics = orgCaracs;
          this.chartInputsForm.get('projectCharacteristics').setValue(this.projectCharacteristics[0]);
          this.newOrganizationMilestoneFamilyService.isProjectCharacteristicsSelectOpened.next(false);
        })
      )
      .subscribe();
  }

  private subscribeDatas(): void {
    const characteristics$: Observable<OrganizationCaracteristic[]> =
      this.newOrganizationMilestoneFamilyService.isProjectCharacteristicsSelectOpened.pipe(
        untilDestroyed(this),
        filter(isCharacteristicsSelectOpened => !isCharacteristicsSelectOpened),
        map(() =>
          this.chartInputsForm.get('projectCharacteristics').value
            ? [this.chartInputsForm.get('projectCharacteristics').value]
            : []
        ),
        distinctUntilChanged()
      );

    const milestones$: Observable<OrganizationMilestone[]> =
      this.newOrganizationMilestoneFamilyService.isProjectMileStoneSelectOpened.pipe(
        untilDestroyed(this),
        filter(isMilestoneSelectOpened => !isMilestoneSelectOpened),
        map(() => this.chartInputsForm.get('projectMilestone').value),
        distinctUntilChanged()
      );

    const projectSubFamily$: Observable<number[]> = this.chartService.isProjectSubFamilySelectOpened.pipe(
      untilDestroyed(this),
      filter(isProjectSubFamilySelectOpened => !isProjectSubFamilySelectOpened),
      map(() => this.idsProjectSubFamily),
      distinctUntilChanged()
    );

    combineLatest([
      this.chartInputsForm
        .get('dateStart')
        .valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()) as Observable<Date>,
      this.chartInputsForm
        .get('dateEnd')
        .valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()) as Observable<Date>,
      characteristics$,
      milestones$,
      projectSubFamily$
    ])
      .pipe(untilDestroyed(this), takeUntil(this.destroy$))
      .subscribe(([dateStart, dateEnd, projectCharacteristics, projectMilestones, projectSubFamiliesIds]) => {
        const projectCharacteristicsIds: number[] = this.getIdsFromOrganizationLibraries(projectCharacteristics, true);
        const milestoneIds: number[] = this.getIdsFromOrganizationLibraries(projectMilestones, false);

        if (this.chartInputsForm.valid) {
          this.newOrganizationFamilyService
            .getChartDatas({
              dateStart,
              dateEnd,
              idOrganization: this.idOrganizationSelected,
              projectFamilyId: this.idProjectFamily,
              projectSubFamiliesIds,
              projectCharacteristicsIds,
              milestoneIds
            })
            .pipe(
              untilDestroyed(this),
              map(res => (this.chartData = res))
            )
            .subscribe();
        }
        this.areDatasOk = this.chartInputsForm.valid;
      });

    this.applyChartParamsFromStore();
  }

  private applyChartParamsFromStore(): void {
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31);

    this.chartInputsForm.get('dateStart').setValue(startDate);
    this.chartInputsForm.get('dateEnd').setValue(endDate);
  }

  private getIdsFromOrganizationLibraries(
    abstractLibraries: OrganizationMilestone[] | OrganizationCaracteristic[] = [],
    isCaracteristic: boolean
  ): number[] {
    if (isCaracteristic) {
      return abstractLibraries?.map(abstractLibrary =>
        abstractLibrary ? abstractLibrary.idOrganizationCaracteristic : undefined
      );
    } else {
      return abstractLibraries?.map(abstractLibrary =>
        abstractLibrary ? abstractLibrary.idOrganizationMilestone : undefined
      );
    }
  }

  private resetCharacteristics(): void {
    if (!!this.chartInputsForm) {
      this.getAllCharacteristics();
    }
  }
}
