import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityDataRow, CommunityDataRowEntityState } from '@api/api-interfaces';
import { CommunityDataRowApiService } from '@wip/store/api-services';
import { CommunityDataRowGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityDataRow } from '@wip/store/configs/normalization';
import { CommunityDataRowSelectors } from '@wip/store/selectors';
import { CommunityDataRowRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { ValeurGeneratedActions } from '@wip/store/actions';
import { Valeur } from '@api/api-interfaces';

export function getDefaultAddCommunityDataRowActions(
  communityDataRow: CommunityDataRowEntityState,
  ids?: CommunityDataRowRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityDataRowGeneratedActions.normalizeManyCommunityDataRowsAfterUpsert({
      communityDataRows: [communityDataRow]
    })
  ];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityDataRowSuccess({
        idCommunity: ids.community,
        idCommunityDataRows: [communityDataRow.idCommunityDataRow]
      })
    );
    actions.push(
      CommunityDataRowGeneratedActions.addCommunitySuccess({
        idCommunityDataRow: communityDataRow.idCommunityDataRow,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.valeurs) {
    if (!Array.isArray(ids.valeurs)) {
      actions.push(
        ValeurGeneratedActions.upsertOneValeur({
          valeur: {
            idCommunityDataRow: communityDataRow.idCommunityDataRow,
            idValeur: ids.valeurs as number
          } as Valeur
        })
      );
      actions.push(
        CommunityDataRowGeneratedActions.addManyValeurSuccess({
          idCommunityDataRow: communityDataRow.idCommunityDataRow,
          idValeurs: [ids.valeurs as number]
        })
      );
    } else {
      actions.push(
        ValeurGeneratedActions.upsertManyValeurs({
          valeurs: (ids.valeurs as number[]).map((idValeur: number) => ({
            idCommunityDataRow: communityDataRow.idCommunityDataRow,
            idValeur
          })) as Valeur[]
        })
      );
      actions.push(
        CommunityDataRowGeneratedActions.addManyValeurSuccess({
          idCommunityDataRow: communityDataRow.idCommunityDataRow,
          idValeurs: ids.valeurs as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCommunityDataRowActions(communityDataRow: CommunityDataRowEntityState): Action[] {
  const actions: Action[] = [
    CommunityDataRowGeneratedActions.deleteOneCommunityDataRowSuccess({
      idCommunityDataRow: communityDataRow.idCommunityDataRow
    })
  ];

  if (communityDataRow.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityDataRowSuccess({
        idCommunityDataRows: [communityDataRow.idCommunityDataRow],
        idCommunities: [communityDataRow.community as number]
      })
    );
  }

  if (communityDataRow.valeurs) {
    actions.push(
      ValeurGeneratedActions.deleteManyCommunityDataRowSuccess({
        idCommunityDataRows: [communityDataRow.idCommunityDataRow],
        idValeurs: communityDataRow.valeurs as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityDataRowEffects {
  constructor(
    protected actions$: Actions,
    protected communityDataRowApiService: CommunityDataRowApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityDataRows$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityDataRowGeneratedActions.getManyCommunityDataRows),
      switchMap(({ params }) =>
        this.communityDataRowApiService.getCommunityDataRows(params).pipe(
          map((communityDataRows: CommunityDataRow[]) => {
            return CommunityDataRowGeneratedActions.normalizeManyCommunityDataRowsAfterUpsert({ communityDataRows });
          }),
          catchError(error => of(CommunityDataRowGeneratedActions.communityDataRowsFailure({ error })))
        )
      )
    );
  });

  getOneCommunityDataRow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityDataRowGeneratedActions.getOneCommunityDataRow),
      switchMap(idCommunityDataRow =>
        this.communityDataRowApiService.getCommunityDataRow(idCommunityDataRow).pipe(
          map((communityDataRow: CommunityDataRow) => {
            return CommunityDataRowGeneratedActions.normalizeManyCommunityDataRowsAfterUpsert({
              communityDataRows: [communityDataRow]
            });
          }),
          catchError(error => of(CommunityDataRowGeneratedActions.communityDataRowsFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityDataRow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityDataRowGeneratedActions.upsertOneCommunityDataRow),
      concatMap(
        ({
          communityDataRow,
          ids
        }: {
          communityDataRow: Partial<CommunityDataRow>;
          ids?: CommunityDataRowRelationsIds;
        }) => {
          if (communityDataRow.idCommunityDataRow) {
            return this.communityDataRowApiService.updateCommunityDataRow(communityDataRow).pipe(
              map((communityDataRowReturned: CommunityDataRow) => {
                return CommunityDataRowGeneratedActions.normalizeManyCommunityDataRowsAfterUpsert({
                  communityDataRows: [communityDataRowReturned]
                });
              }),
              catchError(error => of(CommunityDataRowGeneratedActions.communityDataRowsFailure({ error })))
            );
          } else {
            return this.communityDataRowApiService.addCommunityDataRow(communityDataRow).pipe(
              mergeMap((communityDataRowReturned: CommunityDataRow) =>
                getDefaultAddCommunityDataRowActions(communityDataRowReturned, ids)
              ),
              catchError(error => of(CommunityDataRowGeneratedActions.communityDataRowsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCommunityDataRow$ = createEffect(() => {
    const selectCommunityDataRowState$ = this.store$.select(CommunityDataRowSelectors.selectCommunityDataRowState);
    return this.actions$.pipe(
      ofType(CommunityDataRowGeneratedActions.deleteOneCommunityDataRow),
      withLatestFrom(selectCommunityDataRowState$),
      concatMap(([{ idCommunityDataRow }, state]) =>
        this.communityDataRowApiService.deleteCommunityDataRow(idCommunityDataRow).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityDataRowActions(state.entities[idCommunityDataRow] as CommunityDataRowEntityState)
          ),
          catchError(error => of(CommunityDataRowGeneratedActions.communityDataRowsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityDataRowsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityDataRowGeneratedActions.normalizeManyCommunityDataRowsAfterUpsert),
      concatMap(({ communityDataRows }) => {
        const actions: Action[] = getActionsToNormalizeCommunityDataRow(communityDataRows, StoreActionType.upsert);
        return [getMultiAction(actions, '[CommunityDataRow] Normalization After Upsert Success')];
      })
    );
  });
}
