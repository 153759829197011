import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Organization } from '@api/api-interfaces';

@Component({
  selector: 'app-libraries-parents-choices',
  templateUrl: './libraries-parents-choices.component.html',
  styleUrls: ['./libraries-parents-choices.component.scss']
})
export class LibrariesParentsChoicesComponent {
  @Input() filtersGroup: FormGroup;
  @Input() organizationFamily: Organization;
}
