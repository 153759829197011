import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectElement, ProjectElementEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { ProjectElementState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const projectElementRelations: string[] = ['communities', 'elements'];

export const { selectEntities, selectAll } = ProjectElementState.adapter.getSelectors();

export const selectProjectElementState = createFeatureSelector<ProjectElementState.IState>(
  ProjectElementState.projectElementFeatureKey
);

export const selectIsLoadedProjectElement = createSelector(
  selectProjectElementState,
  (state: ProjectElementState.IState) => state.isLoaded
);

export const selectIsLoadingProjectElement = createSelector(
  selectProjectElementState,
  (state: ProjectElementState.IState) => state.isLoading
);

export const selectIsReadyProjectElement = createSelector(
  selectProjectElementState,
  (state: ProjectElementState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedProjectElement = createSelector(
  selectProjectElementState,
  (state: ProjectElementState.IState) => state.isLoaded && !state.isLoading
);

export const selectProjectElementsEntities = createSelector(selectProjectElementState, selectEntities);

export const selectProjectElementsArray = createSelector(selectProjectElementState, selectAll);

export const selectIdProjectElementsActive = createSelector(
  selectProjectElementState,
  (state: ProjectElementState.IState) => state.actives
);

const projectElementsInObject = (projectElements: Dictionary<ProjectElementEntityState>) => ({ projectElements });

const selectProjectElementsEntitiesDictionary = createSelector(selectProjectElementsEntities, projectElementsInObject);

const selectAllProjectElementsObject = createSelector(selectProjectElementsEntities, projectElements => {
  return hydrateAll({ projectElements });
});

const selectOneProjectElementDictionary = (idProjectElement: number) =>
  createSelector(selectProjectElementsEntities, projectElements => ({
    projectElements: { [idProjectElement]: projectElements[idProjectElement] }
  }));

const selectOneProjectElementDictionaryWithoutChild = (idProjectElement: number) =>
  createSelector(selectProjectElementsEntities, projectElements => ({
    projectElement: projectElements[idProjectElement]
  }));

const selectActiveProjectElementsEntities = createSelector(
  selectIdProjectElementsActive,
  selectProjectElementsEntities,
  (actives: number[], projectElements: Dictionary<ProjectElementEntityState>) =>
    getProjectElementsFromActives(actives, projectElements)
);

function getProjectElementsFromActives(
  actives: number[],
  projectElements: Dictionary<ProjectElementEntityState>
): Dictionary<ProjectElementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (projectElements[idActive]) {
      acc[idActive] = projectElements[idActive];
    }
    return acc;
  }, {} as Dictionary<ProjectElementEntityState>);
}

const selectAllProjectElementsSelectors: Dictionary<Selector> = {};
export function selectAllProjectElements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProjectElement>(
      schema,
      selectAllProjectElementsSelectors,
      selectProjectElementsEntitiesDictionary,
      getRelationSelectors,
      projectElementRelations,
      hydrateAll,
      'projectElement'
    );
  } else {
    return selectAllProjectElementsObject;
  }
}

export function selectAllProjectElementsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'projectElements'
): Selector {
  return createSelector(selectAllProjectElements(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProjectElementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.projectElements.length; i++) {
      res[customKey][result.projectElements[i].idProjectElement] = result.projectElements[i];
    }
    return res;
  });
}

export function selectOneProjectElement(schema: SelectSchema = {}, idProjectElement: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneProjectElementDictionary(idProjectElement)];
    selectors.push(...getRelationSelectors(schema, projectElementRelations, 'projectElement'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProjectElementDictionaryWithoutChild(idProjectElement);
  }
}

export function selectActiveProjectElements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveProjectElementsEntities, projectElements => ({
      projectElements
    }))
  ];
  selectors.push(...getRelationSelectors(schema, projectElementRelations, 'projectElement'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  projectElements: Dictionary<ProjectElementEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  elements?: Dictionary<ElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { projectElements: (ProjectElement | null)[] } {
  const { projectElements, communities, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    projectElements: Object.keys(projectElements).map(idProjectElement =>
      hydrate(projectElements[idProjectElement] as ProjectElementEntityState, communities, elements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { projectElement: ProjectElementEntityState | null } {
  const { projectElements, communities, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const projectElement = Object.values(projectElements)[0];
  return {
    projectElement: hydrate(projectElement as ProjectElementEntityState, communities, elements)
  };
}

function hydrate(
  projectElement: ProjectElementEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  elementEntities?: Dictionary<ElementEntityState>
): ProjectElement | null {
  if (!projectElement) {
    return null;
  }

  const projectElementHydrated: ProjectElementEntityState = { ...projectElement };
  if (communityEntities) {
    projectElementHydrated.community = communityEntities[projectElement.community as number] as Community;
  } else {
    delete projectElementHydrated.community;
  }
  if (elementEntities) {
    projectElementHydrated.element = elementEntities[projectElement.element as number] as Element;
  } else {
    delete projectElementHydrated.element;
  }

  return projectElementHydrated as ProjectElement;
}
