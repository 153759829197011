import { TodoState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { todoReducersGeneratedFunctions } from './todo-generated.reducer';

const todoReducersFunctions = [...todoReducersGeneratedFunctions];

const todoReducer = createReducer(TodoState.initialState, ...todoReducersFunctions);

export function reducer(state: TodoState.IState | undefined, action: Action) {
  return todoReducer(state, action);
}
