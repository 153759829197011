import { Injectable } from '@angular/core';import { Store } from '@ngrx/store';
import { AppState } from '@wip/store/configs/reducers';
import { GeneratedOrganizationCaracteristicColumnService } from './organization-caracteristic-column-generated.service';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class OrganizationCaracteristicColumnService extends GeneratedOrganizationCaracteristicColumnService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}

