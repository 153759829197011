import { createEntityAdapter } from '@ngrx/entity';
import { ChildrenElementEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<ChildrenElementEntityState>;

export const adapter = createEntityAdapter<ChildrenElementEntityState>({
  selectId: o => o.idChildrenElement
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const childrenElementFeatureKey = 'childrenElement';
