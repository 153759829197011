import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneFamilyApiService } from '@wip/store/api-services';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationMilestoneFamily } from '@wip/store/configs/normalization';
import { OrganizationMilestoneFamilySelectors } from '@wip/store/selectors';
import { OrganizationMilestoneFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { CommunityMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityMilestoneFamily } from '@api/api-interfaces';
import { OrganizationMilestoneAssociationGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneAssociation } from '@api/api-interfaces';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestone } from '@api/api-interfaces';

export function getDefaultAddOrganizationMilestoneFamilyActions(
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState,
  ids?: OrganizationMilestoneFamilyRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert({
      organizationMilestoneFamilys: [organizationMilestoneFamily]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
        idOrganization: ids.organization,
        idOrganizationMilestoneFamilys: [organizationMilestoneFamily.idOrganizationMilestoneFamily]
      })
    );
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.addOrganizationSuccess({
        idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
        idOrganizationFamily: ids.organizationFamily,
        idOrganizationMilestoneFamilys: [organizationMilestoneFamily.idOrganizationMilestoneFamily]
      })
    );
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.addOrganizationFamilySuccess({
        idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.organizationCaracteristic) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
        idOrganizationCaracteristic: ids.organizationCaracteristic,
        idOrganizationMilestoneFamilys: [organizationMilestoneFamily.idOrganizationMilestoneFamily]
      })
    );
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.addOrganizationCaracteristicSuccess({
        idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
        idOrganizationCaracteristic: ids.organizationCaracteristic
      })
    );
  }

  if (ids?.communityMilestoneFamilys) {
    if (!Array.isArray(ids.communityMilestoneFamilys)) {
      actions.push(
        CommunityMilestoneFamilyGeneratedActions.upsertOneCommunityMilestoneFamily({
          communityMilestoneFamily: {
            idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
            idCommunityMilestoneFamily: ids.communityMilestoneFamilys as number
          } as CommunityMilestoneFamily
        })
      );
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.addManyCommunityMilestoneFamilySuccess({
          idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
          idCommunityMilestoneFamilys: [ids.communityMilestoneFamilys as number]
        })
      );
    } else {
      actions.push(
        CommunityMilestoneFamilyGeneratedActions.upsertManyCommunityMilestoneFamilys({
          communityMilestoneFamilys: (ids.communityMilestoneFamilys as number[]).map(
            (idCommunityMilestoneFamily: number) => ({
              idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
              idCommunityMilestoneFamily
            })
          ) as CommunityMilestoneFamily[]
        })
      );
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.addManyCommunityMilestoneFamilySuccess({
          idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
          idCommunityMilestoneFamilys: ids.communityMilestoneFamilys as number[]
        })
      );
    }
  }

  if (ids?.organizationMilestoneAssociations) {
    if (!Array.isArray(ids.organizationMilestoneAssociations)) {
      actions.push(
        OrganizationMilestoneAssociationGeneratedActions.upsertOneOrganizationMilestoneAssociation({
          organizationMilestoneAssociation: {
            idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
            idOrganizationMilestoneAssociation: ids.organizationMilestoneAssociations as number
          } as OrganizationMilestoneAssociation
        })
      );
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.addManyOrganizationMilestoneAssociationSuccess({
          idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
          idOrganizationMilestoneAssociations: [ids.organizationMilestoneAssociations as number]
        })
      );
    } else {
      actions.push(
        OrganizationMilestoneAssociationGeneratedActions.upsertManyOrganizationMilestoneAssociations({
          organizationMilestoneAssociations: (ids.organizationMilestoneAssociations as number[]).map(
            (idOrganizationMilestoneAssociation: number) => ({
              idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
              idOrganizationMilestoneAssociation
            })
          ) as OrganizationMilestoneAssociation[]
        })
      );
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.addManyOrganizationMilestoneAssociationSuccess({
          idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
          idOrganizationMilestoneAssociations: ids.organizationMilestoneAssociations as number[]
        })
      );
    }
  }

  if (ids?.organizationMilestones) {
    if (!Array.isArray(ids.organizationMilestones)) {
      actions.push(
        OrganizationMilestoneGeneratedActions.upsertOneOrganizationMilestone({
          organizationMilestone: {
            idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
            idOrganizationMilestone: ids.organizationMilestones as number
          } as OrganizationMilestone & any
        })
      );
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.addManyOrganizationMilestoneSuccess({
          idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
          idOrganizationMilestones: [ids.organizationMilestones as number]
        })
      );
    } else {
      actions.push(
        OrganizationMilestoneGeneratedActions.upsertManyOrganizationMilestones({
          organizationMilestones: (ids.organizationMilestones as number[]).map((idOrganizationMilestone: number) => ({
            idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
            idOrganizationMilestone
          })) as OrganizationMilestone[] & any[]
        })
      );
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.addManyOrganizationMilestoneSuccess({
          idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
          idOrganizationMilestones: ids.organizationMilestones as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationMilestoneFamilyActions(
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationMilestoneFamilyGeneratedActions.deleteOneOrganizationMilestoneFamilySuccess({
      idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily
    })
  ];

  if (organizationMilestoneFamily.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess({
        idOrganizationMilestoneFamilys: [organizationMilestoneFamily.idOrganizationMilestoneFamily],
        idOrganizations: [organizationMilestoneFamily.organization as number]
      })
    );
  }

  if (organizationMilestoneFamily.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess({
        idOrganizationMilestoneFamilys: [organizationMilestoneFamily.idOrganizationMilestoneFamily],
        idOrganizationFamilys: [organizationMilestoneFamily.organizationFamily as number]
      })
    );
  }

  if (organizationMilestoneFamily.organizationCaracteristic) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess({
        idOrganizationMilestoneFamilys: [organizationMilestoneFamily.idOrganizationMilestoneFamily],
        idOrganizationCaracteristics: [organizationMilestoneFamily.organizationCaracteristic as number]
      })
    );
  }

  if (organizationMilestoneFamily.communityMilestoneFamilys) {
    actions.push(
      CommunityMilestoneFamilyGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess({
        idOrganizationMilestoneFamilys: [organizationMilestoneFamily.idOrganizationMilestoneFamily],
        idCommunityMilestoneFamilys: organizationMilestoneFamily.communityMilestoneFamilys as number[]
      })
    );
  }

  if (organizationMilestoneFamily.organizationMilestoneAssociations) {
    actions.push(
      OrganizationMilestoneAssociationGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess({
        idOrganizationMilestoneFamilys: [organizationMilestoneFamily.idOrganizationMilestoneFamily],
        idOrganizationMilestoneAssociations: organizationMilestoneFamily.organizationMilestoneAssociations as number[]
      })
    );
  }

  if (organizationMilestoneFamily.organizationMilestones) {
    actions.push(
      OrganizationMilestoneGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess({
        idOrganizationMilestoneFamilys: [organizationMilestoneFamily.idOrganizationMilestoneFamily],
        idOrganizationMilestones: organizationMilestoneFamily.organizationMilestones as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationMilestoneFamilyEffects {
  constructor(
    protected actions$: Actions,
    protected organizationMilestoneFamilyApiService: OrganizationMilestoneFamilyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationMilestoneFamilys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneFamilyGeneratedActions.getManyOrganizationMilestoneFamilys),
      switchMap(({ params }) =>
        this.organizationMilestoneFamilyApiService.getOrganizationMilestoneFamilys(params).pipe(
          map((organizationMilestoneFamilys: OrganizationMilestoneFamily[]) => {
            return OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert({
              organizationMilestoneFamilys
            });
          }),
          catchError(error =>
            of(OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationMilestoneFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneFamilyGeneratedActions.getOneOrganizationMilestoneFamily),
      switchMap(idOrganizationMilestoneFamily =>
        this.organizationMilestoneFamilyApiService.getOrganizationMilestoneFamily(idOrganizationMilestoneFamily).pipe(
          map((organizationMilestoneFamily: OrganizationMilestoneFamily) => {
            return OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert({
              organizationMilestoneFamilys: [organizationMilestoneFamily]
            });
          }),
          catchError(error =>
            of(OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure({ error }))
          )
        )
      )
    );
  });

  upsertOneOrganizationMilestoneFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneFamilyGeneratedActions.upsertOneOrganizationMilestoneFamily),
      concatMap(
        ({
          organizationMilestoneFamily,
          ids
        }: {
          organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>;
          ids?: OrganizationMilestoneFamilyRelationsIds;
        }) => {
          if (organizationMilestoneFamily.idOrganizationMilestoneFamily) {
            return this.organizationMilestoneFamilyApiService
              .updateOrganizationMilestoneFamily(organizationMilestoneFamily)
              .pipe(
                map((organizationMilestoneFamilyReturned: OrganizationMilestoneFamily) => {
                  return OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert(
                    { organizationMilestoneFamilys: [organizationMilestoneFamilyReturned] }
                  );
                }),
                catchError(error =>
                  of(OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure({ error }))
                )
              );
          } else {
            return this.organizationMilestoneFamilyApiService
              .addOrganizationMilestoneFamily(organizationMilestoneFamily)
              .pipe(
                mergeMap((organizationMilestoneFamilyReturned: OrganizationMilestoneFamily) =>
                  getDefaultAddOrganizationMilestoneFamilyActions(organizationMilestoneFamilyReturned, ids)
                ),
                catchError(error =>
                  of(OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationMilestoneFamily$ = createEffect(() => {
    const selectOrganizationMilestoneFamilyState$ = this.store$.select(
      OrganizationMilestoneFamilySelectors.selectOrganizationMilestoneFamilyState
    );
    return this.actions$.pipe(
      ofType(OrganizationMilestoneFamilyGeneratedActions.deleteOneOrganizationMilestoneFamily),
      withLatestFrom(selectOrganizationMilestoneFamilyState$),
      concatMap(([{ idOrganizationMilestoneFamily }, state]) =>
        this.organizationMilestoneFamilyApiService
          .deleteOrganizationMilestoneFamily(idOrganizationMilestoneFamily)
          .pipe(
            mergeMap(_success =>
              getDefaultDeleteOrganizationMilestoneFamilyActions(
                state.entities[idOrganizationMilestoneFamily] as OrganizationMilestoneFamilyEntityState
              )
            ),
            catchError(error =>
              of(OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationMilestoneFamilysAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert),
      concatMap(({ organizationMilestoneFamilys }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationMilestoneFamily(
          organizationMilestoneFamilys,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationMilestoneFamily] Normalization After Upsert Success')];
      })
    );
  });
}
