import { OrganizationElementState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationElementReducersGeneratedFunctions } from './organization-element-generated.reducer';

const organizationElementReducersFunctions = [...organizationElementReducersGeneratedFunctions];

const organizationElementReducer = createReducer(
  OrganizationElementState.initialState,
  ...organizationElementReducersFunctions
);

export function reducer(state: OrganizationElementState.IState | undefined, action: Action) {
  return organizationElementReducer(state, action);
}
