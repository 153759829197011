import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Todo, TodoEntityState } from '@api/api-interfaces';
import { TodoApiService } from '@wip/store/api-services';
import { TodoGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeTodo } from '@wip/store/configs/normalization';
import { TodoSelectors } from '@wip/store/selectors';
import { TodoRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { TodoElementGeneratedActions } from '@wip/store/actions';
import { TodoElement } from '@api/api-interfaces';
import { ElementGeneratedActions } from '@wip/store/actions';
import { Element } from '@api/api-interfaces';

export function getDefaultAddTodoActions(todo: TodoEntityState, ids?: TodoRelationsIds): Action[] {
  const actions: Action[] = [TodoGeneratedActions.normalizeManyTodosAfterUpsert({ todos: [todo] })];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyTodoSuccess({
        idCommunity: ids.community,
        idTodos: [todo.idTodo]
      })
    );
    actions.push(
      TodoGeneratedActions.addCommunitySuccess({
        idTodo: todo.idTodo,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.todoElements) {
    if (!Array.isArray(ids.todoElements)) {
      actions.push(
        TodoElementGeneratedActions.upsertOneTodoElement({
          todoElement: {
            idTodo: todo.idTodo,
            idTodoElement: ids.todoElements as number
          } as TodoElement
        })
      );
      actions.push(
        TodoGeneratedActions.addManyTodoElementSuccess({
          idTodo: todo.idTodo,
          idTodoElements: [ids.todoElements as number]
        })
      );
    } else {
      actions.push(
        TodoElementGeneratedActions.upsertManyTodoElements({
          todoElements: (ids.todoElements as number[]).map((idTodoElement: number) => ({
            idTodo: todo.idTodo,
            idTodoElement
          })) as TodoElement[]
        })
      );
      actions.push(
        TodoGeneratedActions.addManyTodoElementSuccess({
          idTodo: todo.idTodo,
          idTodoElements: ids.todoElements as number[]
        })
      );
    }
  }

  if (ids?.elements) {
    if (!Array.isArray(ids.elements)) {
      actions.push(
        ElementGeneratedActions.upsertOneElement({
          element: {
            idTodo: todo.idTodo,
            idElement: ids.elements as number
          } as Element & any
        })
      );
      actions.push(
        TodoGeneratedActions.addManyElementSuccess({
          idTodo: todo.idTodo,
          idElements: [ids.elements as number]
        })
      );
    } else {
      actions.push(
        ElementGeneratedActions.upsertManyElements({
          elements: (ids.elements as number[]).map((idElement: number) => ({
            idTodo: todo.idTodo,
            idElement
          })) as Element[] & any[]
        })
      );
      actions.push(
        TodoGeneratedActions.addManyElementSuccess({
          idTodo: todo.idTodo,
          idElements: ids.elements as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteTodoActions(todo: TodoEntityState): Action[] {
  const actions: Action[] = [TodoGeneratedActions.deleteOneTodoSuccess({ idTodo: todo.idTodo })];

  if (todo.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyTodoSuccess({
        idTodos: [todo.idTodo],
        idCommunities: [todo.community as number]
      })
    );
  }

  if (todo.todoElements) {
    actions.push(
      TodoElementGeneratedActions.deleteManyTodoSuccess({
        idTodos: [todo.idTodo],
        idTodoElements: todo.todoElements as number[]
      })
    );
  }

  if (todo.elements) {
    actions.push(
      ElementGeneratedActions.deleteManyTodoSuccess({
        idTodos: [todo.idTodo],
        idElements: todo.elements as number[]
      })
    );
  }

  return actions;
}

export class GeneratedTodoEffects {
  constructor(
    protected actions$: Actions,
    protected todoApiService: TodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TodoGeneratedActions.getManyTodos),
      switchMap(({ params }) =>
        this.todoApiService.getTodos(params).pipe(
          map((todos: Todo[]) => {
            return TodoGeneratedActions.normalizeManyTodosAfterUpsert({ todos });
          }),
          catchError(error => of(TodoGeneratedActions.todosFailure({ error })))
        )
      )
    );
  });

  getOneTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TodoGeneratedActions.getOneTodo),
      switchMap(idTodo =>
        this.todoApiService.getTodo(idTodo).pipe(
          map((todo: Todo) => {
            return TodoGeneratedActions.normalizeManyTodosAfterUpsert({ todos: [todo] });
          }),
          catchError(error => of(TodoGeneratedActions.todosFailure({ error })))
        )
      )
    );
  });

  upsertOneTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TodoGeneratedActions.upsertOneTodo),
      concatMap(({ todo, ids }: { todo: Partial<Todo>; ids?: TodoRelationsIds }) => {
        if (todo.idTodo) {
          return this.todoApiService.updateTodo(todo).pipe(
            map((todoReturned: Todo) => {
              return TodoGeneratedActions.normalizeManyTodosAfterUpsert({ todos: [todoReturned] });
            }),
            catchError(error => of(TodoGeneratedActions.todosFailure({ error })))
          );
        } else {
          return this.todoApiService.addTodo(todo).pipe(
            mergeMap((todoReturned: Todo) => getDefaultAddTodoActions(todoReturned, ids)),
            catchError(error => of(TodoGeneratedActions.todosFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneTodo$ = createEffect(() => {
    const selectTodoState$ = this.store$.select(TodoSelectors.selectTodoState);
    return this.actions$.pipe(
      ofType(TodoGeneratedActions.deleteOneTodo),
      withLatestFrom(selectTodoState$),
      concatMap(([{ idTodo }, state]) =>
        this.todoApiService.deleteTodo(idTodo).pipe(
          mergeMap(_success => getDefaultDeleteTodoActions(state.entities[idTodo] as TodoEntityState)),
          catchError(error => of(TodoGeneratedActions.todosFailure({ error })))
        )
      )
    );
  });

  normalizeManyTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TodoGeneratedActions.normalizeManyTodosAfterUpsert),
      concatMap(({ todos }) => {
        const actions: Action[] = getActionsToNormalizeTodo(todos, StoreActionType.upsert);
        return [getMultiAction(actions, '[Todo] Normalization After Upsert Success')];
      })
    );
  });
}
