import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationElement, OrganizationElementEntityState } from '@api/api-interfaces';
import { OrganizationElementApiService } from '@wip/store/api-services';
import { OrganizationElementGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationElement } from '@wip/store/configs/normalization';
import { OrganizationElementSelectors } from '@wip/store/selectors';
import { OrganizationElementRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { ElementGeneratedActions } from '@wip/store/actions';

export function getDefaultAddOrganizationElementActions(
  organizationElement: OrganizationElementEntityState,
  ids?: OrganizationElementRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert({
      organizationElements: [organizationElement]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationElementSuccess({
        idOrganization: ids.organization,
        idOrganizationElements: [organizationElement.idOrganizationElement]
      })
    );
    actions.push(
      OrganizationElementGeneratedActions.addOrganizationSuccess({
        idOrganizationElement: organizationElement.idOrganizationElement,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.element) {
    actions.push(
      ElementGeneratedActions.addManyOrganizationElementSuccess({
        idElement: ids.element,
        idOrganizationElements: [organizationElement.idOrganizationElement]
      })
    );
    actions.push(
      OrganizationElementGeneratedActions.addElementSuccess({
        idOrganizationElement: organizationElement.idOrganizationElement,
        idElement: ids.element
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationElementActions(
  organizationElement: OrganizationElementEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationElementGeneratedActions.deleteOneOrganizationElementSuccess({
      idOrganizationElement: organizationElement.idOrganizationElement
    })
  ];

  if (organizationElement.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationElementSuccess({
        idOrganizationElements: [organizationElement.idOrganizationElement],
        idOrganizations: [organizationElement.organization as number]
      })
    );
  }

  if (organizationElement.element) {
    actions.push(
      ElementGeneratedActions.deleteManyOrganizationElementSuccess({
        idOrganizationElements: [organizationElement.idOrganizationElement],
        idElements: [organizationElement.element as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationElementEffects {
  constructor(
    protected actions$: Actions,
    protected organizationElementApiService: OrganizationElementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationElementGeneratedActions.getManyOrganizationElements),
      switchMap(({ params }) =>
        this.organizationElementApiService.getOrganizationElements(params).pipe(
          map((organizationElements: OrganizationElement[]) => {
            return OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert({
              organizationElements
            });
          }),
          catchError(error => of(OrganizationElementGeneratedActions.organizationElementsFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationElementGeneratedActions.getOneOrganizationElement),
      switchMap(idOrganizationElement =>
        this.organizationElementApiService.getOrganizationElement(idOrganizationElement).pipe(
          map((organizationElement: OrganizationElement) => {
            return OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert({
              organizationElements: [organizationElement]
            });
          }),
          catchError(error => of(OrganizationElementGeneratedActions.organizationElementsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationElementGeneratedActions.upsertOneOrganizationElement),
      concatMap(
        ({
          organizationElement,
          ids
        }: {
          organizationElement: Partial<OrganizationElement>;
          ids?: OrganizationElementRelationsIds;
        }) => {
          if (organizationElement.idOrganizationElement) {
            return this.organizationElementApiService.updateOrganizationElement(organizationElement).pipe(
              map((organizationElementReturned: OrganizationElement) => {
                return OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert({
                  organizationElements: [organizationElementReturned]
                });
              }),
              catchError(error => of(OrganizationElementGeneratedActions.organizationElementsFailure({ error })))
            );
          } else {
            return this.organizationElementApiService.addOrganizationElement(organizationElement).pipe(
              mergeMap((organizationElementReturned: OrganizationElement) =>
                getDefaultAddOrganizationElementActions(organizationElementReturned, ids)
              ),
              catchError(error => of(OrganizationElementGeneratedActions.organizationElementsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationElement$ = createEffect(() => {
    const selectOrganizationElementState$ = this.store$.select(
      OrganizationElementSelectors.selectOrganizationElementState
    );
    return this.actions$.pipe(
      ofType(OrganizationElementGeneratedActions.deleteOneOrganizationElement),
      withLatestFrom(selectOrganizationElementState$),
      concatMap(([{ idOrganizationElement }, state]) =>
        this.organizationElementApiService.deleteOrganizationElement(idOrganizationElement).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationElementActions(
              state.entities[idOrganizationElement] as OrganizationElementEntityState
            )
          ),
          catchError(error => of(OrganizationElementGeneratedActions.organizationElementsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationElementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert),
      concatMap(({ organizationElements }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationElement(
          organizationElements,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationElement] Normalization After Upsert Success')];
      })
    );
  });
}
