import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ChildrenElementGeneratedActions } from '@wip/store/actions';
import { ChildrenElementState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { ChildrenElementEntityState } from '@api/api-interfaces';

export const childrenElementReducersGeneratedFunctions: ReducerTypes<
  ChildrenElementState.IState,
  readonly ActionCreator[]
>[] = [
  on(ChildrenElementGeneratedActions.getOneChildrenElement, (state: ChildrenElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(ChildrenElementGeneratedActions.getManyChildrenElements, (state: ChildrenElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(ChildrenElementGeneratedActions.upsertOneChildrenElement, (state: ChildrenElementState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    ChildrenElementGeneratedActions.upsertManyChildrenElementsSuccess,
    (state: ChildrenElementState.IState, { childrenElements }) =>
      ChildrenElementState.adapter.upsertMany(childrenElements, setLoadingsState(state, false))
  ),
  on(ChildrenElementGeneratedActions.deleteOneChildrenElement, (state: ChildrenElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    ChildrenElementGeneratedActions.deleteOneChildrenElementSuccess,
    (state: ChildrenElementState.IState, { idChildrenElement }) =>
      ChildrenElementState.adapter.removeOne(idChildrenElement, setLoadingsState(state, false))
  ),
  on(ChildrenElementGeneratedActions.clearActivesChildrenElements, (state: ChildrenElementState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    ChildrenElementGeneratedActions.addManyActivesChildrenElements,
    (state: ChildrenElementState.IState, { idChildrenElements }) => ({
      ...state,
      actives: state.actives.concat(idChildrenElements)
    })
  ),
  on(
    ChildrenElementGeneratedActions.deleteOneActiveChildrenElement,
    (state: ChildrenElementState.IState, { idChildrenElement }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idChildrenElement)
    })
  ),

  on(ChildrenElementGeneratedActions.clearChildrenElements, () => ChildrenElementState.initialState),

  on(
    ChildrenElementGeneratedActions.addElementSuccess,
    (state: ChildrenElementState.IState, { idChildrenElement, idElement }) => {
      if (!state.entities[idChildrenElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idChildrenElement]: {
            ...state.entities[idChildrenElement],
            element: idElement
          }
        }
      };
    }
  ),

  on(
    ChildrenElementGeneratedActions.deleteManyElementSuccess,
    (state: ChildrenElementState.IState, { idElements, idChildrenElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idChildrenElements.reduce((entities, idChildrenElement) => {
            if (!state.entities[idChildrenElement]?.element) {
              return entities;
            }
            entities[idChildrenElement] = {
              ...state.entities[idChildrenElement],
              element: idElements.some((idElement: number) => idElement === state.entities[idChildrenElement]?.element)
                ? undefined
                : state.entities[idChildrenElement]?.element
            } as ChildrenElementEntityState;
            return entities;
          }, {} as Dictionary<ChildrenElementEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ChildrenElementState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ChildrenElementState.IState {
  return { ...state, isLoaded, isLoading };
}
