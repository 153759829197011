import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MeetingElement } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import * as meetingElementActions from '@wip/store/actions';
import { MeetingElementApiService } from '@wip/store/api-services';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedMeetingElementEffects } from './meeting-element-generated.effects';

@Injectable()
export class MeetingElementEffects extends GeneratedMeetingElementEffects {
  constructor(actions$: Actions, meetingElementApiService: MeetingElementApiService, store$: Store<AppState>) {
    super(actions$, meetingElementApiService, store$);
  }

  reorderMeetingElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(meetingElementActions.reorderMeetingElements),
      concatMap(params => {
        return this.meetingElementApiService.reorder(params.params).pipe(
          map((meetingElementsReturned: MeetingElement[]) => {
            return meetingElementActions.MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert({
              meetingElements: meetingElementsReturned
            });
          }),
          catchError(error =>
            of(meetingElementActions.MeetingElementGeneratedActions.meetingElementsFailure({ error }))
          )
        );
      })
    );
  });
}
