import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App, AppState as AppStateCapacitor } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Camera, CameraResultType, CameraSource, ImageOptions, Photo } from '@capacitor/camera';
import { Keyboard } from '@capacitor/keyboard';
import { ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications';
import { StatusBar, Style } from '@capacitor/status-bar';

import { DeviceInformationsService } from '@_app/core/device/device-information.service';
import { ElectronService } from '@_app/core/device/electron.service';
import { UsersDeviceApiService } from '@api/old-api-services';

@Injectable()
export class CapacitorService {
  constructor(
    private router: Router,
    private deviceInformation: DeviceInformationsService,
    private ApiUsers_devices: UsersDeviceApiService,
    private electronService: ElectronService
  ) {
    document.addEventListener('deviceready', () => {
      App.addListener('appStateChange', (state: AppStateCapacitor) => {
        if (state.isActive) {
          this.onResume();
        }
      });

      Keyboard.setAccessoryBarVisible({ isVisible: false });
      Keyboard.setScroll({ isDisabled: true });

      StatusBar.setStyle({
        style: Style.Dark
      });

      StatusBar.show();

      PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
        // Notification was received on device tray and tapped by the user.
        if (
          notification &&
          notification.notification &&
          notification.notification.data &&
          notification.notification.data.idCommunity
        ) {
          this.router.navigate(['./app/community/', notification.notification.data.idCommunity, 'timeline']);
        }
      });
    });
  }

  public onResume(): void {
    PushNotifications.removeAllDeliveredNotifications();
  }

  register(idUser) {
    document.addEventListener('deviceready', () => {
      PushNotifications.register();
      PushNotifications.addListener('registration', (token: Token) => {
        this.ApiUsers_devices.create({
          idUser: idUser,
          idDevice: token.value,
          firebase: true
        }).subscribe(_retour => {});
      });
    });
  }

  // Today, Capacitor doesn't provide any way to unregister an user. You can implement it if Capacitor has been updated
  // unregister(_idUser) {
  //
  // }

  async takePicture(canEdit) {
    const options: ImageOptions = {
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera, // CameraSource.Prompt
      allowEditing: canEdit,
      saveToGallery: false,
      correctOrientation: true,
      quality: 80,
      height: 2048
    };
    const image: Photo = await Camera.getPhoto(options);
    const filename = 'photo';
    const extension = '.' + image.format;
    const picture: File = this.dataURLtoFile(image.base64String, filename + extension, filename + extension);
    return { file: picture, filename, extension };
  }

  private dataURLtoFile(dataURI, filename, mimeType): File {
    // Attention code Quirk compatible avec iOS qui ne supporte pas l'API File
    const byteString = atob(dataURI);

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const picture: any = new Blob([ab], { type: mimeType });
    picture.lastModified = new Date();
    picture.name = filename;

    return <File>picture;
  }

  async openUrl($event) {
    if ($event && $event.target.href) {
      if (this.deviceInformation.isElectron()) {
        $event.preventDefault();
        this.electronService.openUrl($event.target.href);
      } else if (this.deviceInformation.isMobileOS()) {
        $event.preventDefault();
        await Browser.open({ url: $event.target.href });
      } else {
        window.open($event.target.href, '_blank', 'location=yes');
      }
    }
  }
}
