import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProjectElement } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { ProjectElementRelationsIds } from '@wip/store/ids-interfaces';
import { ProjectElementGeneratedActions } from '@wip/store/actions';
import { ProjectElementSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedProjectElementService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProjectElementSelectors.selectIsLoadedProjectElement));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProjectElementSelectors.selectIsLoadingProjectElement));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ProjectElementSelectors.selectIsReadyAndLoadedProjectElement as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProjectElements(schema: SelectSchema = {}): Observable<ProjectElement[]> {
    return this.store$.pipe(select(ProjectElementSelectors.selectAllProjectElements(schema))).pipe(
      switchMap(({ projectElements }: { projectElements: ProjectElement[] }) => {
        return this.getReady(schema).pipe(mapTo(projectElements));
      })
    );
  }

  public selectOneProjectElement(idProjectElement: number, schema: SelectSchema = {}): Observable<ProjectElement> {
    return this.store$.pipe(select(ProjectElementSelectors.selectOneProjectElement(schema, idProjectElement))).pipe(
      switchMap(({ projectElement }: { projectElement: ProjectElement }) => {
        return this.getReady(schema).pipe(mapTo(projectElement));
      })
    );
  }

  public selectAllActiveProjectElements(schema: SelectSchema = {}): Observable<ProjectElement[]> {
    return this.store$.pipe(select(ProjectElementSelectors.selectActiveProjectElements(schema))).pipe(
      switchMap(({ projectElements }: { projectElements: ProjectElement[] }) => {
        return this.getReady(schema).pipe(mapTo(projectElements));
      })
    );
  }

  public selectIdProjectElementsActive(): Observable<number[]> {
    return this.store$.pipe(select(ProjectElementSelectors.selectIdProjectElementsActive)).pipe(
      switchMap((idProjectElements: number[]) => {
        return this.getReady().pipe(mapTo(idProjectElements));
      })
    );
  }

  public getOneProjectElement(
    idProjectElement: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<ProjectElement> {
    this.store$.dispatch(ProjectElementGeneratedActions.getOneProjectElement({ idProjectElement, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert,
        ProjectElementGeneratedActions.projectElementsFailure,
        true
      );
    }
  }

  public getManyProjectElements(params: any = {}, getResult?: boolean): void | Observable<ProjectElement[]> {
    this.store$.dispatch(ProjectElementGeneratedActions.getManyProjectElements({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert,
        ProjectElementGeneratedActions.projectElementsFailure
      );
    }
  }

  public upsertOneProjectElement(
    projectElement: Partial<ProjectElement>,
    ids: ProjectElementRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ProjectElement> {
    this.store$.dispatch(ProjectElementGeneratedActions.upsertOneProjectElement({ projectElement, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert,
        ProjectElementGeneratedActions.projectElementsFailure,
        true
      );
    }
  }

  public deleteOneProjectElement(idProjectElement: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ProjectElementGeneratedActions.deleteOneProjectElement({ idProjectElement }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectElementGeneratedActions.deleteOneProjectElementSuccess,
        ProjectElementGeneratedActions.projectElementsFailure
      );
    }
  }

  public setActiveProjectElements(idProjectElements: number[]): void {
    this.store$.dispatch(ProjectElementGeneratedActions.clearActivesProjectElements());
    this.store$.dispatch(ProjectElementGeneratedActions.addManyActivesProjectElements({ idProjectElements }));
  }
}
