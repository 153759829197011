import { createEntityAdapter } from '@ngrx/entity';
import { MeetingEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<MeetingEntityState>;

export const adapter = createEntityAdapter<MeetingEntityState>({
  selectId: o => o.idMeeting
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const meetingFeatureKey = 'meeting';
