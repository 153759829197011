import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Todo } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { TodoRelationsIds } from '@wip/store/ids-interfaces';
import { TodoGeneratedActions } from '@wip/store/actions';
import { TodoSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedTodoService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(TodoSelectors.selectIsLoadedTodo));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(TodoSelectors.selectIsLoadingTodo));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [TodoSelectors.selectIsReadyAndLoadedTodo as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllTodos(schema: SelectSchema = {}): Observable<Todo[]> {
    return this.store$.pipe(select(TodoSelectors.selectAllTodos(schema))).pipe(
      switchMap(({ todos }: { todos: Todo[] }) => {
        return this.getReady(schema).pipe(mapTo(todos));
      })
    );
  }

  public selectOneTodo(idTodo: number, schema: SelectSchema = {}): Observable<Todo> {
    return this.store$.pipe(select(TodoSelectors.selectOneTodo(schema, idTodo))).pipe(
      switchMap(({ todo }: { todo: Todo }) => {
        return this.getReady(schema).pipe(mapTo(todo));
      })
    );
  }

  public selectAllActiveTodos(schema: SelectSchema = {}): Observable<Todo[]> {
    return this.store$.pipe(select(TodoSelectors.selectActiveTodos(schema))).pipe(
      switchMap(({ todos }: { todos: Todo[] }) => {
        return this.getReady(schema).pipe(mapTo(todos));
      })
    );
  }

  public selectIdTodosActive(): Observable<number[]> {
    return this.store$.pipe(select(TodoSelectors.selectIdTodosActive)).pipe(
      switchMap((idTodos: number[]) => {
        return this.getReady().pipe(mapTo(idTodos));
      })
    );
  }

  public getOneTodo(idTodo: number, params: any = {}, getResult?: boolean): void | Observable<Todo> {
    this.store$.dispatch(TodoGeneratedActions.getOneTodo({ idTodo, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TodoGeneratedActions.normalizeManyTodosAfterUpsert,
        TodoGeneratedActions.todosFailure,
        true
      );
    }
  }

  public getManyTodos(params: any = {}, getResult?: boolean): void | Observable<Todo[]> {
    this.store$.dispatch(TodoGeneratedActions.getManyTodos({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TodoGeneratedActions.normalizeManyTodosAfterUpsert,
        TodoGeneratedActions.todosFailure
      );
    }
  }

  public upsertOneTodo(todo: Partial<Todo>, ids: TodoRelationsIds = {}, getResult?: boolean): void | Observable<Todo> {
    this.store$.dispatch(TodoGeneratedActions.upsertOneTodo({ todo, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TodoGeneratedActions.normalizeManyTodosAfterUpsert,
        TodoGeneratedActions.todosFailure,
        true
      );
    }
  }

  public deleteOneTodo(idTodo: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(TodoGeneratedActions.deleteOneTodo({ idTodo }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TodoGeneratedActions.deleteOneTodoSuccess,
        TodoGeneratedActions.todosFailure
      );
    }
  }

  public setActiveTodos(idTodos: number[]): void {
    this.store$.dispatch(TodoGeneratedActions.clearActivesTodos());
    this.store$.dispatch(TodoGeneratedActions.addManyActivesTodos({ idTodos }));
  }
}
