import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectModuleApiService } from '@wip/store/api-services';
import { GeneratedProjectModuleEffects } from './project-module-generated.effects';

@Injectable()
export class ProjectModuleEffects extends GeneratedProjectModuleEffects {
  constructor(actions$: Actions, projectModuleApiService: ProjectModuleApiService, store$: Store<AppState>) {
    super(actions$, projectModuleApiService, store$);
  }
}
