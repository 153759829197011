import { ConfirmationChoicesInterface } from '@wip/interfaces';
import { ActionService } from '@_app/shared/elements/actions/action.service';
import { ConfirmationChoicesDialogContainerComponent } from '@wip/dialogs/confirmation-choices-dialog';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Element } from '@api/api-interfaces';
import dayjs from 'dayjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'wip-new-action-checklist',
  templateUrl: './new-action-checklist.component.html',
  styleUrls: ['./new-action-checklist.component.scss']
})
export class NewActionChecklistComponent implements OnInit {
  @Input() actionForm: FormGroup;
  @Input() isViewer: boolean;
  @Input() actionService: ActionService;
  @Input() element: Element;
  @Input() subtaskInput: ElementRef;

  @Output() allCheckListDone = new EventEmitter();

  @ViewChild('inputListField') inputListField: ElementRef;
  @ViewChild('inputNewField') inputNewField: ElementRef;

  public checkboxStatus = {
    open: 'status-icon icon icon-ic_fluent_circle_24_regular black',
    delete: 'status-icon icon icon-ic_fluent_dismiss_circle_24_regular red',
    close: 'status-icon icon icon-ic_fluent_checkmark_circle_24_regular green',
    progress: 'status-icon icon icon-ic_fluent_arrow_circle_right_24_regular blue',
    blocked: 'status-icon icon icon-ic_fluent_error_circle_24_regular red',
    abort: 'status-icon icon icon-ic_fluent_dismiss_circle_24_regular red',
    replace: 'status-icon icon icon-ic_fluent_arrow_sync_circle_24_regular red',
    ['not applicable']: 'status-icon icon icon-ic_fluent_circle_24_filled grey'
  };
  public hover: boolean[] = [];
  public allChecked: boolean;
  public displayCheckListInput = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  get subActionCtl(): FormGroup {
    return this.actionForm.get('subAction') as FormGroup;
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.didCheckAllItems();
  }

  checkAll(): void {
    this.getListChildren.controls.forEach(element => {
      element.get('statusAction').setValue('close');
    });
    this.didCheckAllItems();
  }

  uncheckAll(): void {
    this.getListChildren.controls.forEach(element => {
      element.get('statusAction').setValue('open');
    });
    this.didCheckAllItems();
  }

  toggleShowCheckListInput() {
    this.displayCheckListInput = !this.displayCheckListInput;
    setTimeout(() => {
      this.inputNewField.nativeElement.focus();
    }, 0);
  }

  setChildActionNextStatus(subActionForm): void {
    subActionForm.patchValue({
      ...subActionForm.value,
      statusAction: this.actionService.toggleTwoSubStatusAction(subActionForm.value.statusAction)
    });
    this.didCheckAllItems();
  }

  public editChildTask(childActionForm: AbstractControl<any, any>) {
    childActionForm.get('isEditing').patchValue(true);
    setTimeout(() => {
      this.inputListField.nativeElement.focus();
    }, 0);
  }

  public saveChildTask($event, childActionForm) {
    $event.stopImmediatePropagation();
    if (childActionForm.value.titre) {
      childActionForm.controls.isEditing.patchValue(false);
    } else {
      $event.preventDefault();
    }
  }

  removeChildAction(itemIndex: number) {
    this.getListChildren.removeAt(itemIndex);

    this.actionForm.markAsDirty();
    this.didCheckAllItems();
  }

  /**
   * setFocusOnSubtaskInput(): void
   *
   * This method sets the focus on the newsubtaskInput
   */
  setFocusOnSubtaskInput(): void {
    this.subtaskInput.nativeElement.focus();
  }

  /**
   * addSubAction(): void
   *
   * This method creates a new subtask, initializes it's form group, and adds it to the actionForm
   * The new subtask is then pushed into the array of new subtasks
   * sent in the request updating the task and its subtasks
   */
  public addSubAction(): void {
    if (this.actionForm.get('subAction').get('subActionName').value) {
      const subaction = {
        idElementParent: this.element.idElement,
        titre: this.getSubActionName(),
        description: this.getSubActionComment(),
        statusAction: 'open',
        echeance: this.getSubActionDate(),
        typeElement: 'elements_actions',
        important: false,
        ordre: this.getListChildren.value.length + 1,
        hasJustBeenCreated: true
      };

      const subActionGroup = this.fb.group(subaction);
      this.initSubActionValueChanges(subActionGroup, subaction);
      this.getListChildren.push(subActionGroup);
      this.actionForm.get('subAction').get('subActionName').setValue('');
      this.actionForm.get('subAction').get('subActionDate').setValue('');
      this.actionForm.get('subAction').get('subActionComment').setValue('');
    }
    this.didCheckAllItems();
  }

  /**
   * initSubActionValueChanges(subActionFormGroup: FormGroup, subaction: SubAction): void
   *
   * @param {FormGroup} subActionFormGroup  FormGroup initialised with the subaction's properties
   * @param {SubAction} subaction - the subaction with which the subactionFormGroup has been initialised
   *
   * This method initialises the subActionFormGroup's value changes for it's updatable properties
   * the value changes update the subaction object in order to keep it up to date at all time
   */
  initSubActionValueChanges(subActionFormGroup: FormGroup, subaction: any): void {
    subActionFormGroup.controls.titre.valueChanges.subscribe(titre => {
      subActionFormGroup.markAsDirty();
      subaction.titre = titre;
    });
    subActionFormGroup.controls.echeance.valueChanges.subscribe(echeance => {
      subActionFormGroup.markAsDirty();
      subaction.echeance = echeance;
    });
    subActionFormGroup.controls.important.valueChanges.subscribe(important => {
      subaction.important = important;
      subActionFormGroup.markAsDirty();
    });
    subActionFormGroup.controls.statusAction.valueChanges.subscribe(statusAction => {
      subaction.statusAction = statusAction;
      subActionFormGroup.markAsDirty();
    });
    subActionFormGroup.controls.description.valueChanges.subscribe(description => {
      subActionFormGroup.markAsDirty();
      subaction.description = description;
    });
  }

  /**
   * didCheckAllItems(): void
   *
   * This method checks if all subtasks have been checked.
   * Once the user checks the last subtask, calls openConfirmationDialog()
   */
  didCheckAllItems(): void {
    let numberOfCheckedItems = 0;
    if (!this.actionForm) {
      return;
    }

    this.getListChildren.value.forEach(element => {
      if (element.statusAction === 'close') {
        numberOfCheckedItems++;
      }
    });
    this.allChecked =
      numberOfCheckedItems === this.getListChildren.value.length && this.getListChildren.value.length > 0;
    if (
      this.allChecked &&
      this.getListChildren.value.length > 0 &&
      this.getListChildren.dirty &&
      this.actionForm.controls.statusAction.value !== 'close'
    ) {
      this.openConfirmationDialog();
    }
  }

  /**
   * openConfirmationDialog(): void
   *
   * This method opens a dialog that prompts the user to set the task as done
   */
  openConfirmationDialog(): void {
    const actions: ConfirmationChoicesInterface[] = [
      {
        color: 'primary',
        label: 'Oui'
      },
      {
        color: '',
        label: 'Non'
      }
    ];

    const confirmDialogRef = this.dialog.open(ConfirmationChoicesDialogContainerComponent, {
      data: {
        title: 'Cocher le livrable',
        body: 'Toutes les sous-tâches ont été clôturées. Souhaitez-vous clôturer le livrable également ?',
        actions
      }
    });

    confirmDialogRef
      .afterClosed()
      .pipe(untilDestroyed(this), takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.actionForm.get('statusAction').setValue('close');
          this.allCheckListDone.emit();
        }
      });
  }

  getSubActionName(): string {
    return this.actionForm.get('subAction').get('subActionName').value;
  }

  getSubActionComment(): string {
    return this.actionForm.get('subAction').get('subActionComment').value;
  }

  getSubActionDate(): string {
    return this.actionForm.get('subAction').get('subActionDate').value;
  }

  getStatusIcon(fromGroup?): string {
    if (!fromGroup) {
      fromGroup = this.actionForm;
    }
    return this.actionService.getActionStatus(
      fromGroup.controls?.statusAction?.value || fromGroup.value.statusAction,
      true
    ).icon;
  }

  get getListChildren() {
    return this.actionForm.get('children') as FormArray;
  }

  public convertDate(date) {
    return dayjs(date).format('DD/MM/YYYY');
  }

  setHover = (i: number) => (this.hover[i] = !this.hover[i]);
}
