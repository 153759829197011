import { GeneratedAppState, generatedAppState } from '@wip/store/configs/reducers';

export namespace ClearStateUtils {
  export function findAllStateNameToClear(): (keyof GeneratedAppState)[] {
    const stateToKeep: (keyof GeneratedAppState)[] = [
      'user',
      'organizationUser',
      'community',
      'communityUserProfil',
      'communitySubFamily',
      'organization',
      'organizationFamily',
      'organizationSubFamily',
      'userBoardState',
      'userRight',
      'userProfilRight',
      'profil',
      'section',
      'projectModule',
      'communityCaracteristic'
    ];

    const storeToclear: (keyof GeneratedAppState)[] = Object.keys(generatedAppState).filter(
      key => !filterByRegex(key) && !stateToKeep.some(k => k === key)
    ) as (keyof GeneratedAppState)[];
    return storeToclear;
  }

  function filterByRegex(stateKey: string): boolean {
    const regexRules = '^organization[a-zA-Z]*|^company[a-zA-Z]*';
    return new RegExp(regexRules).test(stateKey);
  }
}
