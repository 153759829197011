import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationUser } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationUserRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationUserGeneratedActions } from '@wip/store/actions';
import { OrganizationUserSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationUserService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationUserSelectors.selectIsLoadedOrganizationUser));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationUserSelectors.selectIsLoadingOrganizationUser));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationUserSelectors.selectIsReadyAndLoadedOrganizationUser as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationUsers(schema: SelectSchema = {}): Observable<OrganizationUser[]> {
    return this.store$.pipe(select(OrganizationUserSelectors.selectAllOrganizationUsers(schema))).pipe(
      switchMap(({ organizationUsers }: { organizationUsers: OrganizationUser[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationUsers));
      })
    );
  }

  public selectOneOrganizationUser(
    idOrganizationUser: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationUser> {
    return this.store$
      .pipe(select(OrganizationUserSelectors.selectOneOrganizationUser(schema, idOrganizationUser)))
      .pipe(
        switchMap(({ organizationUser }: { organizationUser: OrganizationUser }) => {
          return this.getReady(schema).pipe(mapTo(organizationUser));
        })
      );
  }

  public selectAllActiveOrganizationUsers(schema: SelectSchema = {}): Observable<OrganizationUser[]> {
    return this.store$.pipe(select(OrganizationUserSelectors.selectActiveOrganizationUsers(schema))).pipe(
      switchMap(({ organizationUsers }: { organizationUsers: OrganizationUser[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationUsers));
      })
    );
  }

  public selectIdOrganizationUsersActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationUserSelectors.selectIdOrganizationUsersActive)).pipe(
      switchMap((idOrganizationUsers: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationUsers));
      })
    );
  }

  public getOneOrganizationUser(
    idOrganizationUser: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationUser> {
    this.store$.dispatch(OrganizationUserGeneratedActions.getOneOrganizationUser({ idOrganizationUser, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationUserGeneratedActions.normalizeManyOrganizationUsersAfterUpsert,
        OrganizationUserGeneratedActions.organizationUsersFailure,
        true
      );
    }
  }

  public getManyOrganizationUsers(params: any = {}, getResult?: boolean): void | Observable<OrganizationUser[]> {
    this.store$.dispatch(OrganizationUserGeneratedActions.getManyOrganizationUsers({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationUserGeneratedActions.normalizeManyOrganizationUsersAfterUpsert,
        OrganizationUserGeneratedActions.organizationUsersFailure
      );
    }
  }

  public upsertOneOrganizationUser(
    organizationUser: Partial<OrganizationUser>,
    ids: OrganizationUserRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationUser> {
    this.store$.dispatch(OrganizationUserGeneratedActions.upsertOneOrganizationUser({ organizationUser, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationUserGeneratedActions.normalizeManyOrganizationUsersAfterUpsert,
        OrganizationUserGeneratedActions.organizationUsersFailure,
        true
      );
    }
  }

  public deleteOneOrganizationUser(idOrganizationUser: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(OrganizationUserGeneratedActions.deleteOneOrganizationUser({ idOrganizationUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationUserGeneratedActions.deleteOneOrganizationUserSuccess,
        OrganizationUserGeneratedActions.organizationUsersFailure
      );
    }
  }

  public setActiveOrganizationUsers(idOrganizationUsers: number[]): void {
    this.store$.dispatch(OrganizationUserGeneratedActions.clearActivesOrganizationUsers());
    this.store$.dispatch(OrganizationUserGeneratedActions.addManyActivesOrganizationUsers({ idOrganizationUsers }));
  }
}
