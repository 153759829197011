import { RepositoryService } from '@wip/services/repository';
import { CommunityModule } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityModuleApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityModules(params?: any): Observable<CommunityModule[]> {
    return this.repo.getData<CommunityModule[]>('community-module', params);
  }

  public getCommunityModule(params: { idCommunityModule: number; params?: any }): Observable<CommunityModule> {
    return this.repo.getData<CommunityModule>('community-module/' + params.idCommunityModule, params.params);
  }

  public addCommunityModule(communityModule: Partial<CommunityModule>): Observable<CommunityModule> {
    return this.repo.create<CommunityModule>('community-module', communityModule);
  }

  public updateCommunityModule(communityModule: Partial<CommunityModule>): Observable<CommunityModule> {
    return this.repo.update('community-module', communityModule);
  }

  public deleteCommunityModule(idCommunityModule: number): Observable<number> {
    return this.repo.delete('community-module/' + +idCommunityModule);
  }
}
