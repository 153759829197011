<app-avatar
  [user]="params"
  class="flex justify-center"
  [height]="26"
  [size]="'medium'"
  [tooltip]="true"
  [shouldDisplayInfo]="true"
  [width]="38"
  class="pointer"
/>
