import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationStep, OrganizationStepEntityState } from '@api/api-interfaces';
import { OrganizationStepApiService } from '@wip/store/api-services';
import { OrganizationStepGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationStep } from '@wip/store/configs/normalization';
import { OrganizationStepSelectors } from '@wip/store/selectors';
import { OrganizationStepRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { Community } from '@api/api-interfaces';

export function getDefaultAddOrganizationStepActions(
  organizationStep: OrganizationStepEntityState,
  ids?: OrganizationStepRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationStepGeneratedActions.normalizeManyOrganizationStepsAfterUpsert({
      organizationSteps: [organizationStep]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationStepSuccess({
        idOrganization: ids.organization,
        idOrganizationSteps: [organizationStep.idOrganizationStep]
      })
    );
    actions.push(
      OrganizationStepGeneratedActions.addOrganizationSuccess({
        idOrganizationStep: organizationStep.idOrganizationStep,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyOrganizationStepSuccess({
        idOrganizationFamily: ids.organizationFamily,
        idOrganizationSteps: [organizationStep.idOrganizationStep]
      })
    );
    actions.push(
      OrganizationStepGeneratedActions.addOrganizationFamilySuccess({
        idOrganizationStep: organizationStep.idOrganizationStep,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.communities) {
    if (!Array.isArray(ids.communities)) {
      actions.push(
        CommunityGeneratedActions.upsertOneCommunity({
          community: {
            idOrganizationStep: organizationStep.idOrganizationStep,
            idCommunity: ids.communities as number
          } as Community
        })
      );
      actions.push(
        OrganizationStepGeneratedActions.addManyCommunitySuccess({
          idOrganizationStep: organizationStep.idOrganizationStep,
          idCommunities: [ids.communities as number]
        })
      );
    } else {
      actions.push(
        CommunityGeneratedActions.upsertManyCommunities({
          communities: (ids.communities as number[]).map((idCommunity: number) => ({
            idOrganizationStep: organizationStep.idOrganizationStep,
            idCommunity
          })) as Community[]
        })
      );
      actions.push(
        OrganizationStepGeneratedActions.addManyCommunitySuccess({
          idOrganizationStep: organizationStep.idOrganizationStep,
          idCommunities: ids.communities as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationStepActions(organizationStep: OrganizationStepEntityState): Action[] {
  const actions: Action[] = [
    OrganizationStepGeneratedActions.deleteOneOrganizationStepSuccess({
      idOrganizationStep: organizationStep.idOrganizationStep
    })
  ];

  if (organizationStep.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationStepSuccess({
        idOrganizationSteps: [organizationStep.idOrganizationStep],
        idOrganizations: [organizationStep.organization as number]
      })
    );
  }

  if (organizationStep.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationStepSuccess({
        idOrganizationSteps: [organizationStep.idOrganizationStep],
        idOrganizationFamilys: [organizationStep.organizationFamily as number]
      })
    );
  }

  if (organizationStep.communities) {
    actions.push(
      CommunityGeneratedActions.deleteManyOrganizationStepSuccess({
        idOrganizationSteps: [organizationStep.idOrganizationStep],
        idCommunities: organizationStep.communities as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationStepEffects {
  constructor(
    protected actions$: Actions,
    protected organizationStepApiService: OrganizationStepApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationSteps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationStepGeneratedActions.getManyOrganizationSteps),
      switchMap(({ params }) =>
        this.organizationStepApiService.getOrganizationSteps(params).pipe(
          map((organizationSteps: OrganizationStep[]) => {
            return OrganizationStepGeneratedActions.normalizeManyOrganizationStepsAfterUpsert({ organizationSteps });
          }),
          catchError(error => of(OrganizationStepGeneratedActions.organizationStepsFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationStepGeneratedActions.getOneOrganizationStep),
      switchMap(idOrganizationStep =>
        this.organizationStepApiService.getOrganizationStep(idOrganizationStep).pipe(
          map((organizationStep: OrganizationStep) => {
            return OrganizationStepGeneratedActions.normalizeManyOrganizationStepsAfterUpsert({
              organizationSteps: [organizationStep]
            });
          }),
          catchError(error => of(OrganizationStepGeneratedActions.organizationStepsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationStepGeneratedActions.upsertOneOrganizationStep),
      concatMap(
        ({
          organizationStep,
          ids
        }: {
          organizationStep: Partial<OrganizationStep>;
          ids?: OrganizationStepRelationsIds;
        }) => {
          if (organizationStep.idOrganizationStep) {
            return this.organizationStepApiService.updateOrganizationStep(organizationStep).pipe(
              map((organizationStepReturned: OrganizationStep) => {
                return OrganizationStepGeneratedActions.normalizeManyOrganizationStepsAfterUpsert({
                  organizationSteps: [organizationStepReturned]
                });
              }),
              catchError(error => of(OrganizationStepGeneratedActions.organizationStepsFailure({ error })))
            );
          } else {
            return this.organizationStepApiService.addOrganizationStep(organizationStep).pipe(
              mergeMap((organizationStepReturned: OrganizationStep) =>
                getDefaultAddOrganizationStepActions(organizationStepReturned, ids)
              ),
              catchError(error => of(OrganizationStepGeneratedActions.organizationStepsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationStep$ = createEffect(() => {
    const selectOrganizationStepState$ = this.store$.select(OrganizationStepSelectors.selectOrganizationStepState);
    return this.actions$.pipe(
      ofType(OrganizationStepGeneratedActions.deleteOneOrganizationStep),
      withLatestFrom(selectOrganizationStepState$),
      concatMap(([{ idOrganizationStep }, state]) =>
        this.organizationStepApiService.deleteOrganizationStep(idOrganizationStep).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationStepActions(state.entities[idOrganizationStep] as OrganizationStepEntityState)
          ),
          catchError(error => of(OrganizationStepGeneratedActions.organizationStepsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationStepsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationStepGeneratedActions.normalizeManyOrganizationStepsAfterUpsert),
      concatMap(({ organizationSteps }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationStep(organizationSteps, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganizationStep] Normalization After Upsert Success')];
      })
    );
  });
}
