import { NotesHistoryState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { notesHistoryReducersGeneratedFunctions } from './notes-history-generated.reducer';

const notesHistoryReducersFunctions = [...notesHistoryReducersGeneratedFunctions];

const notesHistoryReducer = createReducer(NotesHistoryState.initialState, ...notesHistoryReducersFunctions);

export function reducer(state: NotesHistoryState.IState | undefined, action: Action) {
  return notesHistoryReducer(state, action);
}
