export enum LibraryTypeEnum {
  projectKpi = 'project_kpi',
  projectCaracteristic = 'project_caracteristic',
  projectMilestoneFamily = 'project_milestone_family',
  projectMilestone = 'project_milestone',
  projectSubFamily = 'project_sub_family',
  projectFamily = 'project_family',
  projectParameters = 'project_parameters',
  projectRisk = 'project_risk',
  projectTemplate = 'project_template',
  projectStepCurrent = 'project_step_current',
  tagNom = 'tag_nom',
  tagidCommunity = 'tag_idCommunity',
  userProfile = 'user_profile',
  projectReminder = 'todo_deadline',
  projectStep = 'project_phase',
}
