import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationUser, OrganizationUserEntityState } from '@api/api-interfaces';
import { OrganizationUserApiService } from '@wip/store/api-services';
import { OrganizationUserGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationUser } from '@wip/store/configs/normalization';
import { OrganizationUserSelectors } from '@wip/store/selectors';
import { OrganizationUserRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { UserGeneratedActions } from '@wip/store/actions';
import { UserBoardStateGeneratedActions } from '@wip/store/actions';
import { UserBoardState } from '@api/api-interfaces';
import { UserRightGeneratedActions } from '@wip/store/actions';
import { UserRight } from '@api/api-interfaces';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { Profil } from '@api/api-interfaces';

export function getDefaultAddOrganizationUserActions(
  organizationUser: OrganizationUserEntityState,
  ids?: OrganizationUserRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationUserGeneratedActions.normalizeManyOrganizationUsersAfterUpsert({
      organizationUsers: [organizationUser]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationUserSuccess({
        idOrganization: ids.organization,
        idOrganizationUsers: [organizationUser.idOrganizationUser]
      })
    );
    actions.push(
      OrganizationUserGeneratedActions.addOrganizationSuccess({
        idOrganizationUser: organizationUser.idOrganizationUser,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyOrganizationUserSuccess({
        idUser: ids.user,
        idOrganizationUsers: [organizationUser.idOrganizationUser]
      })
    );
    actions.push(
      OrganizationUserGeneratedActions.addUserSuccess({
        idOrganizationUser: organizationUser.idOrganizationUser,
        idUser: ids.user
      })
    );
  }

  if (ids?.userBoardStates) {
    if (!Array.isArray(ids.userBoardStates)) {
      actions.push(
        UserBoardStateGeneratedActions.upsertOneUserBoardState({
          userBoardState: {
            idOrganizationUser: organizationUser.idOrganizationUser,
            idUserBoardState: ids.userBoardStates as number
          } as UserBoardState
        })
      );
      actions.push(
        OrganizationUserGeneratedActions.addManyUserBoardStateSuccess({
          idOrganizationUser: organizationUser.idOrganizationUser,
          idUserBoardStates: [ids.userBoardStates as number]
        })
      );
    } else {
      actions.push(
        UserBoardStateGeneratedActions.upsertManyUserBoardStates({
          userBoardStates: (ids.userBoardStates as number[]).map((idUserBoardState: number) => ({
            idOrganizationUser: organizationUser.idOrganizationUser,
            idUserBoardState
          })) as UserBoardState[]
        })
      );
      actions.push(
        OrganizationUserGeneratedActions.addManyUserBoardStateSuccess({
          idOrganizationUser: organizationUser.idOrganizationUser,
          idUserBoardStates: ids.userBoardStates as number[]
        })
      );
    }
  }

  if (ids?.userRights) {
    if (!Array.isArray(ids.userRights)) {
      actions.push(
        UserRightGeneratedActions.upsertOneUserRight({
          userRight: {
            idOrganizationUser: organizationUser.idOrganizationUser,
            idUserRight: ids.userRights as number
          } as UserRight
        })
      );
      actions.push(
        OrganizationUserGeneratedActions.addManyUserRightSuccess({
          idOrganizationUser: organizationUser.idOrganizationUser,
          idUserRights: [ids.userRights as number]
        })
      );
    } else {
      actions.push(
        UserRightGeneratedActions.upsertManyUserRights({
          userRights: (ids.userRights as number[]).map((idUserRight: number) => ({
            idOrganizationUser: organizationUser.idOrganizationUser,
            idUserRight
          })) as UserRight[]
        })
      );
      actions.push(
        OrganizationUserGeneratedActions.addManyUserRightSuccess({
          idOrganizationUser: organizationUser.idOrganizationUser,
          idUserRights: ids.userRights as number[]
        })
      );
    }
  }

  if (ids?.profils) {
    if (!Array.isArray(ids.profils)) {
      actions.push(
        ProfilGeneratedActions.upsertOneProfil({
          profil: {
            idOrganizationUser: organizationUser.idOrganizationUser,
            idProfil: ids.profils as number
          } as Profil & any
        })
      );
      actions.push(
        OrganizationUserGeneratedActions.addManyProfilSuccess({
          idOrganizationUser: organizationUser.idOrganizationUser,
          idProfils: [ids.profils as number]
        })
      );
    } else {
      actions.push(
        ProfilGeneratedActions.upsertManyProfils({
          profils: (ids.profils as number[]).map((idProfil: number) => ({
            idOrganizationUser: organizationUser.idOrganizationUser,
            idProfil
          })) as Profil[] & any[]
        })
      );
      actions.push(
        OrganizationUserGeneratedActions.addManyProfilSuccess({
          idOrganizationUser: organizationUser.idOrganizationUser,
          idProfils: ids.profils as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationUserActions(organizationUser: OrganizationUserEntityState): Action[] {
  const actions: Action[] = [
    OrganizationUserGeneratedActions.deleteOneOrganizationUserSuccess({
      idOrganizationUser: organizationUser.idOrganizationUser
    })
  ];

  if (organizationUser.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationUserSuccess({
        idOrganizationUsers: [organizationUser.idOrganizationUser],
        idOrganizations: [organizationUser.organization as number]
      })
    );
  }

  if (organizationUser.user) {
    actions.push(
      UserGeneratedActions.deleteManyOrganizationUserSuccess({
        idOrganizationUsers: [organizationUser.idOrganizationUser],
        idUsers: [organizationUser.user as number]
      })
    );
  }

  if (organizationUser.userBoardStates) {
    actions.push(
      UserBoardStateGeneratedActions.deleteManyOrganizationUserSuccess({
        idOrganizationUsers: [organizationUser.idOrganizationUser],
        idUserBoardStates: organizationUser.userBoardStates as number[]
      })
    );
  }

  if (organizationUser.userRights) {
    actions.push(
      UserRightGeneratedActions.deleteManyOrganizationUserSuccess({
        idOrganizationUsers: [organizationUser.idOrganizationUser],
        idUserRights: organizationUser.userRights as number[]
      })
    );
  }

  if (organizationUser.profils) {
    actions.push(
      ProfilGeneratedActions.deleteManyOrganizationUserSuccess({
        idOrganizationUsers: [organizationUser.idOrganizationUser],
        idProfils: organizationUser.profils as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationUserEffects {
  constructor(
    protected actions$: Actions,
    protected organizationUserApiService: OrganizationUserApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUserGeneratedActions.getManyOrganizationUsers),
      switchMap(({ params }) =>
        this.organizationUserApiService.getOrganizationUsers(params).pipe(
          map((organizationUsers: OrganizationUser[]) => {
            return OrganizationUserGeneratedActions.normalizeManyOrganizationUsersAfterUpsert({ organizationUsers });
          }),
          catchError(error => of(OrganizationUserGeneratedActions.organizationUsersFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUserGeneratedActions.getOneOrganizationUser),
      switchMap(idOrganizationUser =>
        this.organizationUserApiService.getOrganizationUser(idOrganizationUser).pipe(
          map((organizationUser: OrganizationUser) => {
            return OrganizationUserGeneratedActions.normalizeManyOrganizationUsersAfterUpsert({
              organizationUsers: [organizationUser]
            });
          }),
          catchError(error => of(OrganizationUserGeneratedActions.organizationUsersFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUserGeneratedActions.upsertOneOrganizationUser),
      concatMap(
        ({
          organizationUser,
          ids
        }: {
          organizationUser: Partial<OrganizationUser>;
          ids?: OrganizationUserRelationsIds;
        }) => {
          if (organizationUser.idOrganizationUser) {
            return this.organizationUserApiService.updateOrganizationUser(organizationUser).pipe(
              map((organizationUserReturned: OrganizationUser) => {
                return OrganizationUserGeneratedActions.normalizeManyOrganizationUsersAfterUpsert({
                  organizationUsers: [organizationUserReturned]
                });
              }),
              catchError(error => of(OrganizationUserGeneratedActions.organizationUsersFailure({ error })))
            );
          } else {
            return this.organizationUserApiService.addOrganizationUser(organizationUser).pipe(
              mergeMap((organizationUserReturned: OrganizationUser) =>
                getDefaultAddOrganizationUserActions(organizationUserReturned, ids)
              ),
              catchError(error => of(OrganizationUserGeneratedActions.organizationUsersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationUser$ = createEffect(() => {
    const selectOrganizationUserState$ = this.store$.select(OrganizationUserSelectors.selectOrganizationUserState);
    return this.actions$.pipe(
      ofType(OrganizationUserGeneratedActions.deleteOneOrganizationUser),
      withLatestFrom(selectOrganizationUserState$),
      concatMap(([{ idOrganizationUser }, state]) =>
        this.organizationUserApiService.deleteOrganizationUser(idOrganizationUser).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationUserActions(state.entities[idOrganizationUser] as OrganizationUserEntityState)
          ),
          catchError(error => of(OrganizationUserGeneratedActions.organizationUsersFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUserGeneratedActions.normalizeManyOrganizationUsersAfterUpsert),
      concatMap(({ organizationUsers }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationUser(organizationUsers, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganizationUser] Normalization After Upsert Success')];
      })
    );
  });
}
