import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { MeetingUser } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { MeetingUserRelationsIds } from '@wip/store/ids-interfaces';
import { MeetingUserGeneratedActions } from '@wip/store/actions';
import { MeetingUserSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedMeetingUserService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(MeetingUserSelectors.selectIsLoadedMeetingUser));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(MeetingUserSelectors.selectIsLoadingMeetingUser));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [MeetingUserSelectors.selectIsReadyAndLoadedMeetingUser as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllMeetingUsers(schema: SelectSchema = {}): Observable<MeetingUser[]> {
    return this.store$.pipe(select(MeetingUserSelectors.selectAllMeetingUsers(schema))).pipe(
      switchMap(({ meetingUsers }: { meetingUsers: MeetingUser[] }) => {
        return this.getReady(schema).pipe(mapTo(meetingUsers));
      })
    );
  }

  public selectOneMeetingUser(idMeetingUser: number, schema: SelectSchema = {}): Observable<MeetingUser> {
    return this.store$.pipe(select(MeetingUserSelectors.selectOneMeetingUser(schema, idMeetingUser))).pipe(
      switchMap(({ meetingUser }: { meetingUser: MeetingUser }) => {
        return this.getReady(schema).pipe(mapTo(meetingUser));
      })
    );
  }

  public selectAllActiveMeetingUsers(schema: SelectSchema = {}): Observable<MeetingUser[]> {
    return this.store$.pipe(select(MeetingUserSelectors.selectActiveMeetingUsers(schema))).pipe(
      switchMap(({ meetingUsers }: { meetingUsers: MeetingUser[] }) => {
        return this.getReady(schema).pipe(mapTo(meetingUsers));
      })
    );
  }

  public selectIdMeetingUsersActive(): Observable<number[]> {
    return this.store$.pipe(select(MeetingUserSelectors.selectIdMeetingUsersActive)).pipe(
      switchMap((idMeetingUsers: number[]) => {
        return this.getReady().pipe(mapTo(idMeetingUsers));
      })
    );
  }

  public getOneMeetingUser(
    idMeetingUser: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<MeetingUser> {
    this.store$.dispatch(MeetingUserGeneratedActions.getOneMeetingUser({ idMeetingUser, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingUserGeneratedActions.normalizeManyMeetingUsersAfterUpsert,
        MeetingUserGeneratedActions.meetingUsersFailure,
        true
      );
    }
  }

  public getManyMeetingUsers(params: any = {}, getResult?: boolean): void | Observable<MeetingUser[]> {
    this.store$.dispatch(MeetingUserGeneratedActions.getManyMeetingUsers({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingUserGeneratedActions.normalizeManyMeetingUsersAfterUpsert,
        MeetingUserGeneratedActions.meetingUsersFailure
      );
    }
  }

  public upsertOneMeetingUser(
    meetingUser: Partial<MeetingUser>,
    ids: MeetingUserRelationsIds = {},
    getResult?: boolean
  ): void | Observable<MeetingUser> {
    this.store$.dispatch(MeetingUserGeneratedActions.upsertOneMeetingUser({ meetingUser, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingUserGeneratedActions.normalizeManyMeetingUsersAfterUpsert,
        MeetingUserGeneratedActions.meetingUsersFailure,
        true
      );
    }
  }

  public deleteOneMeetingUser(idMeetingUser: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(MeetingUserGeneratedActions.deleteOneMeetingUser({ idMeetingUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingUserGeneratedActions.deleteOneMeetingUserSuccess,
        MeetingUserGeneratedActions.meetingUsersFailure
      );
    }
  }

  public setActiveMeetingUsers(idMeetingUsers: number[]): void {
    this.store$.dispatch(MeetingUserGeneratedActions.clearActivesMeetingUsers());
    this.store$.dispatch(MeetingUserGeneratedActions.addManyActivesMeetingUsers({ idMeetingUsers }));
  }
}
