import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TodoElement, TodoElementEntityState } from '@api/api-interfaces';
import { TodoElementRelationsIds } from '@wip/store/ids-interfaces';

export const TodoElementGeneratedActions = createActionGroup({
  source: 'Todo Element Generated',
  events: {
    'Get One Todo Element': props<{ idTodoElement: number; params?: any }>(),
    'Get Many Todo Elements': props<{ params: any }>(),
    'Add Many Actives Todo Elements': props<{ idTodoElements: number[] }>(),
    'Delete One Active Todo Element': props<{ idTodoElement: number }>(),
    'Clear Actives Todo Elements': emptyProps(),
    'Upsert One Todo Element': props<{ todoElement: Partial<TodoElement>; ids?: TodoElementRelationsIds }>(),
    'Upsert Many Todo Elements': props<{ todoElements: Partial<TodoElement>[]; ids?: TodoElementRelationsIds }>(),
    'Upsert Many Todo Elements Success': props<{ todoElements: TodoElementEntityState[] }>(),
    'Delete One Todo Element': props<{ idTodoElement: number }>(),
    'Delete One Todo Element Success': props<{ idTodoElement: number }>(),
    'Normalize Many Todo Elements After Upsert': props<{ todoElements: TodoElementEntityState[] }>(),
    'Todo Elements Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Todo Elements': emptyProps(),
    'Add Todo Success': props<{ idTodoElement: number; idTodo: number }>(),
    'Delete Many Todo Success': props<{ idTodos: number[]; idTodoElements: number[] }>(),
    'Add Element Success': props<{ idTodoElement: number; idElement: number }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idTodoElements: number[] }>()
  }
});
