import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserDeviceApiService } from '@wip/store/api-services';
import { GeneratedUserDeviceEffects } from './user-device-generated.effects';

@Injectable()
export class UserDeviceEffects extends GeneratedUserDeviceEffects {
  constructor(actions$: Actions, userDeviceApiService: UserDeviceApiService, store$: Store<AppState>) {
    super(actions$, userDeviceApiService, store$);
  }
}
