import { RepositoryService } from '@wip/services/repository';
import { CommunityRisk } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityRiskApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityRisks(params?: any): Observable<CommunityRisk[]> {
    return this.repo.getData<CommunityRisk[]>('community-risk', params);
  }

  public getCommunityRisk(params: { idCommunityRisk: number; params?: any }): Observable<CommunityRisk> {
    return this.repo.getData<CommunityRisk>('community-risk/' + params.idCommunityRisk, params.params);
  }

  public addCommunityRisk(communityRisk: Partial<CommunityRisk>): Observable<CommunityRisk> {
    return this.repo.create<CommunityRisk>('community-risk', communityRisk);
  }

  public updateCommunityRisk(communityRisk: Partial<CommunityRisk>): Observable<CommunityRisk> {
    return this.repo.update('community-risk', communityRisk);
  }

  public deleteCommunityRisk(idCommunityRisk: number): Observable<number> {
    return this.repo.delete('community-risk/' + +idCommunityRisk);
  }
}
