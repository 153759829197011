import { RepositoryService } from '@wip/services/repository';
import { UserDevice } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserDeviceApiService {
  constructor(protected repo: RepositoryService) {}

  public getUserDevices(params?: any): Observable<UserDevice[]> {
    return this.repo.getData<UserDevice[]>('user-device', params);
  }

  public getUserDevice(params: { idUserDevice: number; params?: any }): Observable<UserDevice> {
    return this.repo.getData<UserDevice>('user-device/' + params.idUserDevice, params.params);
  }

  public addUserDevice(userDevice: Partial<UserDevice>): Observable<UserDevice> {
    return this.repo.create<UserDevice>('user-device', userDevice);
  }

  public updateUserDevice(userDevice: Partial<UserDevice>): Observable<UserDevice> {
    return this.repo.update('user-device', userDevice);
  }

  public deleteUserDevice(idUserDevice: number): Observable<number> {
    return this.repo.delete('user-device/' + +idUserDevice);
  }
}
