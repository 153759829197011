<div mat-dialog-title>
  <h1>Choix du modèle de document</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
</div>
<mat-form-field style="padding: 24px">
  <mat-label>Forme</mat-label>
  <mat-select [(value)]="selected">
    @if (data.documentType === OrganizationElemTypeEnumNotes) {
      <mat-option [value]="-1">Export compte-rendu par défaut</mat-option>
      <mat-divider />
    }

    @if (templates) {
      @for (template of templates; track template.idOrganization) {
        <mat-option [value]="template.element?.idElement">
          @if (template.element?.description?.length) {
            {{ template.element?.description }}
          } @else {
            {{ template.element?.titre }}
          }
        </mat-option>
      }
    }
  </mat-select>
</mat-form-field>
<div mat-dialog-actions>
  <div class="flex flex-row gap-8 justify-end" style="width: 100%">
    <button mat-flat-button type="button" (click)="cancel()">Annuler</button>
    <button mat-flat-button type="submit" (click)="validate()" color="primary" tabindex="0">Valider</button>
  </div>
</div>
