import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationElement, OrganizationElementEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationElementState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationElementRelations: string[] = ['organizations', 'elements'];

export const { selectEntities, selectAll } = OrganizationElementState.adapter.getSelectors();

export const selectOrganizationElementState = createFeatureSelector<OrganizationElementState.IState>(
  OrganizationElementState.organizationElementFeatureKey
);

export const selectIsLoadedOrganizationElement = createSelector(
  selectOrganizationElementState,
  (state: OrganizationElementState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationElement = createSelector(
  selectOrganizationElementState,
  (state: OrganizationElementState.IState) => state.isLoading
);

export const selectIsReadyOrganizationElement = createSelector(
  selectOrganizationElementState,
  (state: OrganizationElementState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationElement = createSelector(
  selectOrganizationElementState,
  (state: OrganizationElementState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationElementsEntities = createSelector(selectOrganizationElementState, selectEntities);

export const selectOrganizationElementsArray = createSelector(selectOrganizationElementState, selectAll);

export const selectIdOrganizationElementsActive = createSelector(
  selectOrganizationElementState,
  (state: OrganizationElementState.IState) => state.actives
);

const organizationElementsInObject = (organizationElements: Dictionary<OrganizationElementEntityState>) => ({
  organizationElements
});

const selectOrganizationElementsEntitiesDictionary = createSelector(
  selectOrganizationElementsEntities,
  organizationElementsInObject
);

const selectAllOrganizationElementsObject = createSelector(selectOrganizationElementsEntities, organizationElements => {
  return hydrateAll({ organizationElements });
});

const selectOneOrganizationElementDictionary = (idOrganizationElement: number) =>
  createSelector(selectOrganizationElementsEntities, organizationElements => ({
    organizationElements: { [idOrganizationElement]: organizationElements[idOrganizationElement] }
  }));

const selectOneOrganizationElementDictionaryWithoutChild = (idOrganizationElement: number) =>
  createSelector(selectOrganizationElementsEntities, organizationElements => ({
    organizationElement: organizationElements[idOrganizationElement]
  }));

const selectActiveOrganizationElementsEntities = createSelector(
  selectIdOrganizationElementsActive,
  selectOrganizationElementsEntities,
  (actives: number[], organizationElements: Dictionary<OrganizationElementEntityState>) =>
    getOrganizationElementsFromActives(actives, organizationElements)
);

function getOrganizationElementsFromActives(
  actives: number[],
  organizationElements: Dictionary<OrganizationElementEntityState>
): Dictionary<OrganizationElementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationElements[idActive]) {
      acc[idActive] = organizationElements[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationElementEntityState>);
}

const selectAllOrganizationElementsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationElements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationElement>(
      schema,
      selectAllOrganizationElementsSelectors,
      selectOrganizationElementsEntitiesDictionary,
      getRelationSelectors,
      organizationElementRelations,
      hydrateAll,
      'organizationElement'
    );
  } else {
    return selectAllOrganizationElementsObject;
  }
}

export function selectAllOrganizationElementsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationElements'
): Selector {
  return createSelector(selectAllOrganizationElements(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationElementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationElements.length; i++) {
      res[customKey][result.organizationElements[i].idOrganizationElement] = result.organizationElements[i];
    }
    return res;
  });
}

export function selectOneOrganizationElement(schema: SelectSchema = {}, idOrganizationElement: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationElementDictionary(idOrganizationElement)];
    selectors.push(...getRelationSelectors(schema, organizationElementRelations, 'organizationElement'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationElementDictionaryWithoutChild(idOrganizationElement);
  }
}

export function selectActiveOrganizationElements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationElementsEntities, organizationElements => ({
      organizationElements
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationElementRelations, 'organizationElement'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationElements: Dictionary<OrganizationElementEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  elements?: Dictionary<ElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationElements: (OrganizationElement | null)[] } {
  const { organizationElements, organizations, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationElements: Object.keys(organizationElements).map(idOrganizationElement =>
      hydrate(organizationElements[idOrganizationElement] as OrganizationElementEntityState, organizations, elements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationElement: OrganizationElementEntityState | null } {
  const { organizationElements, organizations, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationElement = Object.values(organizationElements)[0];
  return {
    organizationElement: hydrate(organizationElement as OrganizationElementEntityState, organizations, elements)
  };
}

function hydrate(
  organizationElement: OrganizationElementEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  elementEntities?: Dictionary<ElementEntityState>
): OrganizationElement | null {
  if (!organizationElement) {
    return null;
  }

  const organizationElementHydrated: OrganizationElementEntityState = { ...organizationElement };
  if (organizationEntities) {
    organizationElementHydrated.organization = organizationEntities[
      organizationElement.organization as number
    ] as Organization;
  } else {
    delete organizationElementHydrated.organization;
  }
  if (elementEntities) {
    organizationElementHydrated.element = elementEntities[organizationElement.element as number] as Element;
  } else {
    delete organizationElementHydrated.element;
  }

  return organizationElementHydrated as OrganizationElement;
}
