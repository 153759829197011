import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Right, RightEntityState } from '@api/api-interfaces';
import { RightApiService } from '@wip/store/api-services';
import { RightGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeRight } from '@wip/store/configs/normalization';
import { RightSelectors } from '@wip/store/selectors';
import { RightRelationsIds } from '@wip/store/ids-interfaces';
import { RightProfilGeneratedActions } from '@wip/store/actions';
import { RightProfil } from '@api/api-interfaces';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { Profil } from '@api/api-interfaces';

export function getDefaultAddRightActions(right: RightEntityState, ids?: RightRelationsIds): Action[] {
  const actions: Action[] = [RightGeneratedActions.normalizeManyRightsAfterUpsert({ rights: [right] })];

  if (ids?.rightProfils) {
    if (!Array.isArray(ids.rightProfils)) {
      actions.push(
        RightProfilGeneratedActions.upsertOneRightProfil({
          rightProfil: {
            idRight: right.idRight,
            idRightProfil: ids.rightProfils as number
          } as RightProfil
        })
      );
      actions.push(
        RightGeneratedActions.addManyRightProfilSuccess({
          idRight: right.idRight,
          idRightProfils: [ids.rightProfils as number]
        })
      );
    } else {
      actions.push(
        RightProfilGeneratedActions.upsertManyRightProfils({
          rightProfils: (ids.rightProfils as number[]).map((idRightProfil: number) => ({
            idRight: right.idRight,
            idRightProfil
          })) as RightProfil[]
        })
      );
      actions.push(
        RightGeneratedActions.addManyRightProfilSuccess({
          idRight: right.idRight,
          idRightProfils: ids.rightProfils as number[]
        })
      );
    }
  }

  if (ids?.profils) {
    if (!Array.isArray(ids.profils)) {
      actions.push(
        ProfilGeneratedActions.upsertOneProfil({
          profil: {
            idRight: right.idRight,
            idProfil: ids.profils as number
          } as Profil & any
        })
      );
      actions.push(
        RightGeneratedActions.addManyProfilSuccess({
          idRight: right.idRight,
          idProfils: [ids.profils as number]
        })
      );
    } else {
      actions.push(
        ProfilGeneratedActions.upsertManyProfils({
          profils: (ids.profils as number[]).map((idProfil: number) => ({
            idRight: right.idRight,
            idProfil
          })) as Profil[] & any[]
        })
      );
      actions.push(
        RightGeneratedActions.addManyProfilSuccess({
          idRight: right.idRight,
          idProfils: ids.profils as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteRightActions(right: RightEntityState): Action[] {
  const actions: Action[] = [RightGeneratedActions.deleteOneRightSuccess({ idRight: right.idRight })];

  if (right.rightProfils) {
    actions.push(
      RightProfilGeneratedActions.deleteManyRightSuccess({
        idRights: [right.idRight],
        idRightProfils: right.rightProfils as number[]
      })
    );
  }

  if (right.profils) {
    actions.push(
      ProfilGeneratedActions.deleteManyRightSuccess({
        idRights: [right.idRight],
        idProfils: right.profils as number[]
      })
    );
  }

  return actions;
}

export class GeneratedRightEffects {
  constructor(
    protected actions$: Actions,
    protected rightApiService: RightApiService,
    protected store$: Store<AppState>
  ) {}

  getManyRights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RightGeneratedActions.getManyRights),
      switchMap(({ params }) =>
        this.rightApiService.getRights(params).pipe(
          map((rights: Right[]) => {
            return RightGeneratedActions.normalizeManyRightsAfterUpsert({ rights });
          }),
          catchError(error => of(RightGeneratedActions.rightsFailure({ error })))
        )
      )
    );
  });

  getOneRight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RightGeneratedActions.getOneRight),
      switchMap(idRight =>
        this.rightApiService.getRight(idRight).pipe(
          map((right: Right) => {
            return RightGeneratedActions.normalizeManyRightsAfterUpsert({ rights: [right] });
          }),
          catchError(error => of(RightGeneratedActions.rightsFailure({ error })))
        )
      )
    );
  });

  upsertOneRight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RightGeneratedActions.upsertOneRight),
      concatMap(({ right, ids }: { right: Partial<Right>; ids?: RightRelationsIds }) => {
        if (right.idRight) {
          return this.rightApiService.updateRight(right).pipe(
            map((rightReturned: Right) => {
              return RightGeneratedActions.normalizeManyRightsAfterUpsert({ rights: [rightReturned] });
            }),
            catchError(error => of(RightGeneratedActions.rightsFailure({ error })))
          );
        } else {
          return this.rightApiService.addRight(right).pipe(
            mergeMap((rightReturned: Right) => getDefaultAddRightActions(rightReturned, ids)),
            catchError(error => of(RightGeneratedActions.rightsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneRight$ = createEffect(() => {
    const selectRightState$ = this.store$.select(RightSelectors.selectRightState);
    return this.actions$.pipe(
      ofType(RightGeneratedActions.deleteOneRight),
      withLatestFrom(selectRightState$),
      concatMap(([{ idRight }, state]) =>
        this.rightApiService.deleteRight(idRight).pipe(
          mergeMap(_success => getDefaultDeleteRightActions(state.entities[idRight] as RightEntityState)),
          catchError(error => of(RightGeneratedActions.rightsFailure({ error })))
        )
      )
    );
  });

  normalizeManyRightsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RightGeneratedActions.normalizeManyRightsAfterUpsert),
      concatMap(({ rights }) => {
        const actions: Action[] = getActionsToNormalizeRight(rights, StoreActionType.upsert);
        return [getMultiAction(actions, '[Right] Normalization After Upsert Success')];
      })
    );
  });
}
