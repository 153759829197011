import { Injectable } from '@angular/core';
import { TimelineElementService } from '@wip/store/services';

@Injectable()
export class MessageService {
  constructor(private newTimelineElementService: TimelineElementService) {}

  /**
   * @description mandatory
   */
  editInline() {
    this.newTimelineElementService.setActiveTimelineElements([]);
  }
}
