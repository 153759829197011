import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationMilestoneAssociation, OrganizationMilestoneAssociationEntityState } from '@api/api-interfaces';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationMilestoneAssociationState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationMilestoneAssociationRelations: string[] = [
  'organizationMilestones',
  'organizationMilestoneFamilys'
];

export const { selectEntities, selectAll } = OrganizationMilestoneAssociationState.adapter.getSelectors();

export const selectOrganizationMilestoneAssociationState =
  createFeatureSelector<OrganizationMilestoneAssociationState.IState>(
    OrganizationMilestoneAssociationState.organizationMilestoneAssociationFeatureKey
  );

export const selectIsLoadedOrganizationMilestoneAssociation = createSelector(
  selectOrganizationMilestoneAssociationState,
  (state: OrganizationMilestoneAssociationState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationMilestoneAssociation = createSelector(
  selectOrganizationMilestoneAssociationState,
  (state: OrganizationMilestoneAssociationState.IState) => state.isLoading
);

export const selectIsReadyOrganizationMilestoneAssociation = createSelector(
  selectOrganizationMilestoneAssociationState,
  (state: OrganizationMilestoneAssociationState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationMilestoneAssociation = createSelector(
  selectOrganizationMilestoneAssociationState,
  (state: OrganizationMilestoneAssociationState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationMilestoneAssociationsEntities = createSelector(
  selectOrganizationMilestoneAssociationState,
  selectEntities
);

export const selectOrganizationMilestoneAssociationsArray = createSelector(
  selectOrganizationMilestoneAssociationState,
  selectAll
);

export const selectIdOrganizationMilestoneAssociationsActive = createSelector(
  selectOrganizationMilestoneAssociationState,
  (state: OrganizationMilestoneAssociationState.IState) => state.actives
);

const organizationMilestoneAssociationsInObject = (
  organizationMilestoneAssociations: Dictionary<OrganizationMilestoneAssociationEntityState>
) => ({ organizationMilestoneAssociations });

const selectOrganizationMilestoneAssociationsEntitiesDictionary = createSelector(
  selectOrganizationMilestoneAssociationsEntities,
  organizationMilestoneAssociationsInObject
);

const selectAllOrganizationMilestoneAssociationsObject = createSelector(
  selectOrganizationMilestoneAssociationsEntities,
  organizationMilestoneAssociations => {
    return hydrateAll({ organizationMilestoneAssociations });
  }
);

const selectOneOrganizationMilestoneAssociationDictionary = (idOrganizationMilestoneAssociation: number) =>
  createSelector(selectOrganizationMilestoneAssociationsEntities, organizationMilestoneAssociations => ({
    organizationMilestoneAssociations: {
      [idOrganizationMilestoneAssociation]: organizationMilestoneAssociations[idOrganizationMilestoneAssociation]
    }
  }));

const selectOneOrganizationMilestoneAssociationDictionaryWithoutChild = (idOrganizationMilestoneAssociation: number) =>
  createSelector(selectOrganizationMilestoneAssociationsEntities, organizationMilestoneAssociations => ({
    organizationMilestoneAssociation: organizationMilestoneAssociations[idOrganizationMilestoneAssociation]
  }));

const selectActiveOrganizationMilestoneAssociationsEntities = createSelector(
  selectIdOrganizationMilestoneAssociationsActive,
  selectOrganizationMilestoneAssociationsEntities,
  (actives: number[], organizationMilestoneAssociations: Dictionary<OrganizationMilestoneAssociationEntityState>) =>
    getOrganizationMilestoneAssociationsFromActives(actives, organizationMilestoneAssociations)
);

function getOrganizationMilestoneAssociationsFromActives(
  actives: number[],
  organizationMilestoneAssociations: Dictionary<OrganizationMilestoneAssociationEntityState>
): Dictionary<OrganizationMilestoneAssociationEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationMilestoneAssociations[idActive]) {
      acc[idActive] = organizationMilestoneAssociations[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationMilestoneAssociationEntityState>);
}

const selectAllOrganizationMilestoneAssociationsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationMilestoneAssociations(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationMilestoneAssociation>(
      schema,
      selectAllOrganizationMilestoneAssociationsSelectors,
      selectOrganizationMilestoneAssociationsEntitiesDictionary,
      getRelationSelectors,
      organizationMilestoneAssociationRelations,
      hydrateAll,
      'organizationMilestoneAssociation'
    );
  } else {
    return selectAllOrganizationMilestoneAssociationsObject;
  }
}

export function selectAllOrganizationMilestoneAssociationsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationMilestoneAssociations'
): Selector {
  return createSelector(selectAllOrganizationMilestoneAssociations(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationMilestoneAssociationEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationMilestoneAssociations.length; i++) {
      res[customKey][result.organizationMilestoneAssociations[i].idOrganizationMilestoneAssociation] =
        result.organizationMilestoneAssociations[i];
    }
    return res;
  });
}

export function selectOneOrganizationMilestoneAssociation(
  schema: SelectSchema = {},
  idOrganizationMilestoneAssociation: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneOrganizationMilestoneAssociationDictionary(idOrganizationMilestoneAssociation)
    ];
    selectors.push(
      ...getRelationSelectors(schema, organizationMilestoneAssociationRelations, 'organizationMilestoneAssociation')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationMilestoneAssociationDictionaryWithoutChild(idOrganizationMilestoneAssociation);
  }
}

export function selectActiveOrganizationMilestoneAssociations(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationMilestoneAssociationsEntities, organizationMilestoneAssociations => ({
      organizationMilestoneAssociations
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationMilestoneAssociationRelations, 'organizationMilestoneAssociation')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationMilestoneAssociations: Dictionary<OrganizationMilestoneAssociationEntityState>;
  organizationMilestones?: Dictionary<OrganizationMilestoneEntityState>;
  organizationMilestoneFamilys?: Dictionary<OrganizationMilestoneFamilyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationMilestoneAssociations: (OrganizationMilestoneAssociation | null)[];
} {
  const { organizationMilestoneAssociations, organizationMilestones, organizationMilestoneFamilys } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationMilestoneAssociations: Object.keys(organizationMilestoneAssociations).map(
      idOrganizationMilestoneAssociation =>
        hydrate(
          organizationMilestoneAssociations[
            idOrganizationMilestoneAssociation
          ] as OrganizationMilestoneAssociationEntityState,
          organizationMilestones,
          organizationMilestoneFamilys
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationMilestoneAssociation: OrganizationMilestoneAssociationEntityState | null;
} {
  const { organizationMilestoneAssociations, organizationMilestones, organizationMilestoneFamilys } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationMilestoneAssociation = Object.values(organizationMilestoneAssociations)[0];
  return {
    organizationMilestoneAssociation: hydrate(
      organizationMilestoneAssociation as OrganizationMilestoneAssociationEntityState,
      organizationMilestones,
      organizationMilestoneFamilys
    )
  };
}

function hydrate(
  organizationMilestoneAssociation: OrganizationMilestoneAssociationEntityState,
  organizationMilestoneEntities?: Dictionary<OrganizationMilestoneEntityState>,
  organizationMilestoneFamilyEntities?: Dictionary<OrganizationMilestoneFamilyEntityState>
): OrganizationMilestoneAssociation | null {
  if (!organizationMilestoneAssociation) {
    return null;
  }

  const organizationMilestoneAssociationHydrated: OrganizationMilestoneAssociationEntityState = {
    ...organizationMilestoneAssociation
  };
  if (organizationMilestoneEntities) {
    organizationMilestoneAssociationHydrated.organizationMilestone = organizationMilestoneEntities[
      organizationMilestoneAssociation.organizationMilestone as number
    ] as OrganizationMilestone;
  } else {
    delete organizationMilestoneAssociationHydrated.organizationMilestone;
  }
  if (organizationMilestoneFamilyEntities) {
    organizationMilestoneAssociationHydrated.organizationMilestoneFamily = organizationMilestoneFamilyEntities[
      organizationMilestoneAssociation.organizationMilestoneFamily as number
    ] as OrganizationMilestoneFamily;
  } else {
    delete organizationMilestoneAssociationHydrated.organizationMilestoneFamily;
  }

  return organizationMilestoneAssociationHydrated as OrganizationMilestoneAssociation;
}
