import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Community, CommunityCaracteristic } from '@api/api-interfaces';

@Component({
  selector: 'wip-project-caracteristics',
  templateUrl: './project-caracteristics.component.html',
  styleUrls: ['./project-caracteristics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCaracteristicsComponent implements OnChanges {
  @Input() community: Community;
  @Input() canEdit: boolean;
  public communityCaracteristic: CommunityCaracteristic[];
  public isCommunityResponsableValid: boolean;
  public missedCaracteristics: number;

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.community) {
      this.communityCaracteristic = this.community?.communityCaracteristics.sort(
        (a, b) => a.organizationCaracteristic?.ordre - b.organizationCaracteristic?.ordre
      );
      const communityResponsable = this.communityCaracteristic?.find(b => b.libelle === 'RP - Responsable de projet');
      this.missedCaracteristics = this.communityCaracteristic.filter(cc => !cc.valeur).length;
      this.isCommunityResponsableValid = !!communityResponsable?.valeur;
    }
  }

  public addCaracteristics() {
    this.router.navigate([`app/project/${this.community.idCommunity}/caracteristics`]);
  }
}
