import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class CommentService {
  private isEditing: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  getIsEditing(): Observable<boolean> {
    return this.isEditing.asObservable();
  }

  startEditing() {
    this.isEditing.next(true);
  }

  stopEditing() {
    this.isEditing.next(false);
  }
}
