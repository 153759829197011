import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FolderElement, FolderElementEntityState } from '@api/api-interfaces';
import { FolderElementRelationsIds } from '@wip/store/ids-interfaces';

export const FolderElementGeneratedActions = createActionGroup({
  source: 'Folder Element Generated',
  events: {
    'Get One Folder Element': props<{ idFolderElement: number; params?: any }>(),
    'Get Many Folder Elements': props<{ params: any }>(),
    'Add Many Actives Folder Elements': props<{ idFolderElements: number[] }>(),
    'Delete One Active Folder Element': props<{ idFolderElement: number }>(),
    'Clear Actives Folder Elements': emptyProps(),
    'Upsert One Folder Element': props<{ folderElement: Partial<FolderElement>; ids?: FolderElementRelationsIds }>(),
    'Upsert Many Folder Elements': props<{
      folderElements: Partial<FolderElement>[];
      ids?: FolderElementRelationsIds;
    }>(),
    'Upsert Many Folder Elements Success': props<{ folderElements: FolderElementEntityState[] }>(),
    'Delete One Folder Element': props<{ idFolderElement: number }>(),
    'Delete One Folder Element Success': props<{ idFolderElement: number }>(),
    'Normalize Many Folder Elements After Upsert': props<{ folderElements: FolderElementEntityState[] }>(),
    'Folder Elements Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Folder Elements': emptyProps(),
    'Add Folder Success': props<{ idFolderElement: number; idFolder: number }>(),
    'Delete Many Folder Success': props<{ idFolders: number[]; idFolderElements: number[] }>(),
    'Add Element Success': props<{ idFolderElement: number; idElement: number }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idFolderElements: number[] }>()
  }
});
