import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationCaracteristicValueGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicValueState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationCaracteristicValueEntityState } from '@api/api-interfaces';

export const organizationCaracteristicValueReducersGeneratedFunctions: ReducerTypes<
  OrganizationCaracteristicValueState.IState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationCaracteristicValueGeneratedActions.getOneOrganizationCaracteristicValue,
    (state: OrganizationCaracteristicValueState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicValueGeneratedActions.getManyOrganizationCaracteristicValues,
    (state: OrganizationCaracteristicValueState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicValueGeneratedActions.upsertOneOrganizationCaracteristicValue,
    (state: OrganizationCaracteristicValueState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationCaracteristicValueGeneratedActions.upsertManyOrganizationCaracteristicValuesSuccess,
    (state: OrganizationCaracteristicValueState.IState, { organizationCaracteristicValues }) =>
      OrganizationCaracteristicValueState.adapter.upsertMany(
        organizationCaracteristicValues,
        setLoadingsState(state, false)
      )
  ),
  on(
    OrganizationCaracteristicValueGeneratedActions.deleteOneOrganizationCaracteristicValue,
    (state: OrganizationCaracteristicValueState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicValueGeneratedActions.deleteOneOrganizationCaracteristicValueSuccess,
    (state: OrganizationCaracteristicValueState.IState, { idOrganizationCaracteristicValue }) =>
      OrganizationCaracteristicValueState.adapter.removeOne(
        idOrganizationCaracteristicValue,
        setLoadingsState(state, false)
      )
  ),
  on(
    OrganizationCaracteristicValueGeneratedActions.clearActivesOrganizationCaracteristicValues,
    (state: OrganizationCaracteristicValueState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationCaracteristicValueGeneratedActions.addManyActivesOrganizationCaracteristicValues,
    (state: OrganizationCaracteristicValueState.IState, { idOrganizationCaracteristicValues }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationCaracteristicValues)
    })
  ),
  on(
    OrganizationCaracteristicValueGeneratedActions.deleteOneActiveOrganizationCaracteristicValue,
    (state: OrganizationCaracteristicValueState.IState, { idOrganizationCaracteristicValue }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationCaracteristicValue)
    })
  ),

  on(
    OrganizationCaracteristicValueGeneratedActions.clearOrganizationCaracteristicValues,
    () => OrganizationCaracteristicValueState.initialState
  ),

  on(
    OrganizationCaracteristicValueGeneratedActions.addOrganizationCaracteristicColumnSuccess,
    (
      state: OrganizationCaracteristicValueState.IState,
      { idOrganizationCaracteristicValue, idOrganizationCaracteristicColumn }
    ) => {
      if (!state.entities[idOrganizationCaracteristicValue]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristicValue]: {
            ...state.entities[idOrganizationCaracteristicValue],
            organizationCaracteristicColumn: idOrganizationCaracteristicColumn
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicValueGeneratedActions.deleteManyOrganizationCaracteristicColumnSuccess,
    (
      state: OrganizationCaracteristicValueState.IState,
      { idOrganizationCaracteristicColumns, idOrganizationCaracteristicValues }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristicValues.reduce((entities, idOrganizationCaracteristicValue) => {
            if (!state.entities[idOrganizationCaracteristicValue]?.organizationCaracteristicColumn) {
              return entities;
            }
            entities[idOrganizationCaracteristicValue] = {
              ...state.entities[idOrganizationCaracteristicValue],
              organizationCaracteristicColumn: idOrganizationCaracteristicColumns.some(
                (idOrganizationCaracteristicColumn: number) =>
                  idOrganizationCaracteristicColumn ===
                  state.entities[idOrganizationCaracteristicValue]?.organizationCaracteristicColumn
              )
                ? undefined
                : state.entities[idOrganizationCaracteristicValue]?.organizationCaracteristicColumn
            } as OrganizationCaracteristicValueEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicValueEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicValueGeneratedActions.addOrganizationCaracteristicRowSuccess,
    (
      state: OrganizationCaracteristicValueState.IState,
      { idOrganizationCaracteristicValue, idOrganizationCaracteristicRow }
    ) => {
      if (!state.entities[idOrganizationCaracteristicValue]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristicValue]: {
            ...state.entities[idOrganizationCaracteristicValue],
            organizationCaracteristicRow: idOrganizationCaracteristicRow
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicValueGeneratedActions.deleteManyOrganizationCaracteristicRowSuccess,
    (
      state: OrganizationCaracteristicValueState.IState,
      { idOrganizationCaracteristicRows, idOrganizationCaracteristicValues }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristicValues.reduce((entities, idOrganizationCaracteristicValue) => {
            if (!state.entities[idOrganizationCaracteristicValue]?.organizationCaracteristicRow) {
              return entities;
            }
            entities[idOrganizationCaracteristicValue] = {
              ...state.entities[idOrganizationCaracteristicValue],
              organizationCaracteristicRow: idOrganizationCaracteristicRows.some(
                (idOrganizationCaracteristicRow: number) =>
                  idOrganizationCaracteristicRow ===
                  state.entities[idOrganizationCaracteristicValue]?.organizationCaracteristicRow
              )
                ? undefined
                : state.entities[idOrganizationCaracteristicValue]?.organizationCaracteristicRow
            } as OrganizationCaracteristicValueEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicValueEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicValueGeneratedActions.addOrganizationDataColumnValueSuccess,
    (
      state: OrganizationCaracteristicValueState.IState,
      { idOrganizationCaracteristicValue, idOrganizationDataColumnValue }
    ) => {
      if (!state.entities[idOrganizationCaracteristicValue]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristicValue]: {
            ...state.entities[idOrganizationCaracteristicValue],
            organizationDataColumnValue: idOrganizationDataColumnValue
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicValueGeneratedActions.deleteManyOrganizationDataColumnValueSuccess,
    (
      state: OrganizationCaracteristicValueState.IState,
      { idOrganizationDataColumnValues, idOrganizationCaracteristicValues }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristicValues.reduce((entities, idOrganizationCaracteristicValue) => {
            if (!state.entities[idOrganizationCaracteristicValue]?.organizationDataColumnValue) {
              return entities;
            }
            entities[idOrganizationCaracteristicValue] = {
              ...state.entities[idOrganizationCaracteristicValue],
              organizationDataColumnValue: idOrganizationDataColumnValues.some(
                (idOrganizationDataColumnValue: number) =>
                  idOrganizationDataColumnValue ===
                  state.entities[idOrganizationCaracteristicValue]?.organizationDataColumnValue
              )
                ? undefined
                : state.entities[idOrganizationCaracteristicValue]?.organizationDataColumnValue
            } as OrganizationCaracteristicValueEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicValueEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationCaracteristicValueState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationCaracteristicValueState.IState {
  return { ...state, isLoaded, isLoading };
}
