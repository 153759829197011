<div class="container" (click)="showCommunityDetails($event)">
  @if (typeStructureEnum[project.typeStructure] === 'Projet') {
    <div>
      <div>
        <i
          class="icon icon-ic_fluent_arrow_forward_24_filled open-project-icon"
          matTooltip="Voir synthèse {{ typeStructureEnum[project.typeStructure] | lowercase }}"
        ></i>
      </div>
    </div>
  }
  <div class="line-layout">
    <div style="overflow: hidden; width: 100%" class="flex flex-col">
      <span class="community-name break-line"> {{ project.nom }} </span>
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row">
          @for (subfamily of project.communitySubFamilys; track subfamily.idCommunitySubFamily) {
            <div class="margin-left-5">
              <div
                [matTooltip]="subfamily.organizationSubFamily?.libelle"
                class="flex flex-row items-center badge-side-menu"
                [ngStyle]="{ 'background-color': subfamily.organizationSubFamily?.couleur }"
              >
                <span class="inside-badge">{{ subfamily.organizationSubFamily?.libelle }}</span>
              </div>
            </div>
          }
        </div>
        <!-- @for (subfamily of project.communitySubFamilys; track subfamily) {
          <div
            [ngStyle]="{ 'background-color': subfamily?.organizationSubFamily?.couleur }"
            class="flex flex-row justify-center items-center"
          >
            <span>
              {{ subfamily.organizationSubFamily?.libelle }}
            </span>
          </div>
        } -->
        @if (user) {
          <app-avatar
            class="avatar"
            size="small"
            [tooltip]="true"
            [user]="user"
            [width]="25"
            [height]="20"
            [shouldDisplayInfo]="true"
          />
        }
      </div>
    </div>
  </div>
</div>
