import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { CommunityState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityEntityState } from '@api/api-interfaces';

export const communityReducersGeneratedFunctions: ReducerTypes<CommunityState.IState, readonly ActionCreator[]>[] = [
  on(CommunityGeneratedActions.getOneCommunity, (state: CommunityState.IState) => setLoadingsState(state, true)),
  on(CommunityGeneratedActions.getManyCommunities, (state: CommunityState.IState) => setLoadingsState(state, true)),
  on(CommunityGeneratedActions.upsertOneCommunity, (state: CommunityState.IState) => setLoadingsState(state, true)),

  on(CommunityGeneratedActions.upsertManyCommunitiesSuccess, (state: CommunityState.IState, { communities }) =>
    CommunityState.adapter.upsertMany(communities, setLoadingsState(state, false))
  ),
  on(CommunityGeneratedActions.deleteOneCommunity, (state: CommunityState.IState) => setLoadingsState(state, true)),
  on(CommunityGeneratedActions.deleteOneCommunitySuccess, (state: CommunityState.IState, { idCommunity }) =>
    CommunityState.adapter.removeOne(idCommunity, setLoadingsState(state, false))
  ),
  on(CommunityGeneratedActions.clearActivesCommunities, (state: CommunityState.IState) => ({ ...state, actives: [] })),
  on(CommunityGeneratedActions.addManyActivesCommunities, (state: CommunityState.IState, { idCommunities }) => ({
    ...state,
    actives: state.actives.concat(idCommunities)
  })),
  on(CommunityGeneratedActions.deleteOneActiveCommunity, (state: CommunityState.IState, { idCommunity }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCommunity)
  })),

  on(CommunityGeneratedActions.clearCommunities, () => CommunityState.initialState),

  on(
    CommunityGeneratedActions.addManyCommunityUserSuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityUsers }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityUsers = (state.entities[idCommunity]?.communityUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityUsers: communityUsers.concat(idCommunityUsers.filter(id => communityUsers.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityUserSuccess,
    (state: CommunityState.IState, { idCommunityUsers, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityUsers) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityUsers: (state.entities[idCommunity]?.communityUsers as number[])?.filter(
                (idCommunityUser: number) => !idCommunityUsers.some((id: number) => id === idCommunityUser)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunityUserProfilSuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityUserProfils }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityUserProfils = (state.entities[idCommunity]?.communityUserProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityUserProfils: communityUserProfils.concat(
              idCommunityUserProfils.filter(id => communityUserProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityUserProfilSuccess,
    (state: CommunityState.IState, { idCommunityUserProfils, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityUserProfils) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityUserProfils: (state.entities[idCommunity]?.communityUserProfils as number[])?.filter(
                (idCommunityUserProfil: number) =>
                  !idCommunityUserProfils.some((id: number) => id === idCommunityUserProfil)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunityCaracteristicSuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityCaracteristics }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityCaracteristics = (state.entities[idCommunity]?.communityCaracteristics as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityCaracteristics: communityCaracteristics.concat(
              idCommunityCaracteristics.filter(id => communityCaracteristics.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityCaracteristicSuccess,
    (state: CommunityState.IState, { idCommunityCaracteristics, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityCaracteristics) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityCaracteristics: (state.entities[idCommunity]?.communityCaracteristics as number[])?.filter(
                (idCommunityCaracteristic: number) =>
                  !idCommunityCaracteristics.some((id: number) => id === idCommunityCaracteristic)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunityKpiSuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityKpis }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityKpis = (state.entities[idCommunity]?.communityKpis as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityKpis: communityKpis.concat(idCommunityKpis.filter(id => communityKpis.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityKpiSuccess,
    (state: CommunityState.IState, { idCommunityKpis, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityKpis) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityKpis: (state.entities[idCommunity]?.communityKpis as number[])?.filter(
                (idCommunityKpi: number) => !idCommunityKpis.some((id: number) => id === idCommunityKpi)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunityDataRowSuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityDataRows }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityDataRows = (state.entities[idCommunity]?.communityDataRows as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityDataRows: communityDataRows.concat(
              idCommunityDataRows.filter(id => communityDataRows.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityDataRowSuccess,
    (state: CommunityState.IState, { idCommunityDataRows, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityDataRows) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityDataRows: (state.entities[idCommunity]?.communityDataRows as number[])?.filter(
                (idCommunityDataRow: number) => !idCommunityDataRows.some((id: number) => id === idCommunityDataRow)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunityGroupSuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityGroups }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityGroups = (state.entities[idCommunity]?.communityGroups as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityGroups: communityGroups.concat(idCommunityGroups.filter(id => communityGroups.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityGroupSuccess,
    (state: CommunityState.IState, { idCommunityGroups, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityGroups) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityGroups: (state.entities[idCommunity]?.communityGroups as number[])?.filter(
                (idCommunityGroup: number) => !idCommunityGroups.some((id: number) => id === idCommunityGroup)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunityMilestoneSuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityMilestones }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityMilestones = (state.entities[idCommunity]?.communityMilestones as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityMilestones: communityMilestones.concat(
              idCommunityMilestones.filter(id => communityMilestones.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityMilestoneSuccess,
    (state: CommunityState.IState, { idCommunityMilestones, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityMilestones) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityMilestones: (state.entities[idCommunity]?.communityMilestones as number[])?.filter(
                (idCommunityMilestone: number) =>
                  !idCommunityMilestones.some((id: number) => id === idCommunityMilestone)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunityMilestoneFamilySuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityMilestoneFamilys }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityMilestoneFamilys = (state.entities[idCommunity]?.communityMilestoneFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityMilestoneFamilys: communityMilestoneFamilys.concat(
              idCommunityMilestoneFamilys.filter(id => communityMilestoneFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityMilestoneFamilySuccess,
    (state: CommunityState.IState, { idCommunityMilestoneFamilys, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityMilestoneFamilys) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityMilestoneFamilys: (state.entities[idCommunity]?.communityMilestoneFamilys as number[])?.filter(
                (idCommunityMilestoneFamily: number) =>
                  !idCommunityMilestoneFamilys.some((id: number) => id === idCommunityMilestoneFamily)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunityRiskSuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityRisks }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityRisks = (state.entities[idCommunity]?.communityRisks as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityRisks: communityRisks.concat(idCommunityRisks.filter(id => communityRisks.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityRiskSuccess,
    (state: CommunityState.IState, { idCommunityRisks, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityRisks) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityRisks: (state.entities[idCommunity]?.communityRisks as number[])?.filter(
                (idCommunityRisk: number) => !idCommunityRisks.some((id: number) => id === idCommunityRisk)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunitySubFamilySuccess,
    (state: CommunityState.IState, { idCommunity, idCommunitySubFamilys }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communitySubFamilys = (state.entities[idCommunity]?.communitySubFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communitySubFamilys: communitySubFamilys.concat(
              idCommunitySubFamilys.filter(id => communitySubFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunitySubFamilySuccess,
    (state: CommunityState.IState, { idCommunitySubFamilys, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communitySubFamilys) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communitySubFamilys: (state.entities[idCommunity]?.communitySubFamilys as number[])?.filter(
                (idCommunitySubFamily: number) =>
                  !idCommunitySubFamilys.some((id: number) => id === idCommunitySubFamily)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyCommunityModuleSuccess,
    (state: CommunityState.IState, { idCommunity, idCommunityModules }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const communityModules = (state.entities[idCommunity]?.communityModules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            communityModules: communityModules.concat(idCommunityModules.filter(id => communityModules.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyCommunityModuleSuccess,
    (state: CommunityState.IState, { idCommunityModules, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.communityModules) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              communityModules: (state.entities[idCommunity]?.communityModules as number[])?.filter(
                (idCommunityModule: number) => !idCommunityModules.some((id: number) => id === idCommunityModule)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyProjectElementSuccess,
    (state: CommunityState.IState, { idCommunity, idProjectElements }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const projectElements = (state.entities[idCommunity]?.projectElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            projectElements: projectElements.concat(idProjectElements.filter(id => projectElements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyProjectElementSuccess,
    (state: CommunityState.IState, { idProjectElements, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.projectElements) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              projectElements: (state.entities[idCommunity]?.projectElements as number[])?.filter(
                (idProjectElement: number) => !idProjectElements.some((id: number) => id === idProjectElement)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyGanttLinkSuccess,
    (state: CommunityState.IState, { idCommunity, idGanttLinks }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const ganttLinks = (state.entities[idCommunity]?.ganttLinks as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            ganttLinks: ganttLinks.concat(idGanttLinks.filter(id => ganttLinks.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyGanttLinkSuccess,
    (state: CommunityState.IState, { idGanttLinks, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.ganttLinks) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              ganttLinks: (state.entities[idCommunity]?.ganttLinks as number[])?.filter(
                (idGanttLink: number) => !idGanttLinks.some((id: number) => id === idGanttLink)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(CommunityGeneratedActions.addManyFolderSuccess, (state: CommunityState.IState, { idCommunity, idFolders }) => {
    if (!state.entities[idCommunity]) {
      return state;
    }
    const folders = (state.entities[idCommunity]?.folders as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCommunity]: {
          ...state.entities[idCommunity],
          folders: folders.concat(idFolders.filter(id => folders.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    CommunityGeneratedActions.deleteManyFolderSuccess,
    (state: CommunityState.IState, { idFolders, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.folders) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              folders: (state.entities[idCommunity]?.folders as number[])?.filter(
                (idFolder: number) => !idFolders.some((id: number) => id === idFolder)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(CommunityGeneratedActions.addManyTodoSuccess, (state: CommunityState.IState, { idCommunity, idTodos }) => {
    if (!state.entities[idCommunity]) {
      return state;
    }
    const todos = (state.entities[idCommunity]?.todos as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCommunity]: {
          ...state.entities[idCommunity],
          todos: todos.concat(idTodos.filter(id => todos.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CommunityGeneratedActions.deleteManyTodoSuccess, (state: CommunityState.IState, { idTodos, idCommunities }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCommunities.reduce((entities, idCommunity) => {
          if (!state.entities[idCommunity]?.todos) {
            return entities;
          }
          entities[idCommunity] = {
            ...state.entities[idCommunity],
            todos: (state.entities[idCommunity]?.todos as number[])?.filter(
              (idTodo: number) => !idTodos.some((id: number) => id === idTodo)
            )
          } as CommunityEntityState;
          return entities;
        }, {} as Dictionary<CommunityEntityState>)
      }
    };
  }),

  on(CommunityGeneratedActions.addManyMeetingSuccess, (state: CommunityState.IState, { idCommunity, idMeetings }) => {
    if (!state.entities[idCommunity]) {
      return state;
    }
    const meetings = (state.entities[idCommunity]?.meetings as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCommunity]: {
          ...state.entities[idCommunity],
          meetings: meetings.concat(idMeetings.filter(id => meetings.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    CommunityGeneratedActions.deleteManyMeetingSuccess,
    (state: CommunityState.IState, { idMeetings, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.meetings) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              meetings: (state.entities[idCommunity]?.meetings as number[])?.filter(
                (idMeeting: number) => !idMeetings.some((id: number) => id === idMeeting)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyTimelineElementSuccess,
    (state: CommunityState.IState, { idCommunity, idTimelineElements }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const timelineElements = (state.entities[idCommunity]?.timelineElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            timelineElements: timelineElements.concat(idTimelineElements.filter(id => timelineElements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyTimelineElementSuccess,
    (state: CommunityState.IState, { idTimelineElements, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.timelineElements) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              timelineElements: (state.entities[idCommunity]?.timelineElements as number[])?.filter(
                (idTimelineElement: number) => !idTimelineElements.some((id: number) => id === idTimelineElement)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyNotesHistorySuccess,
    (state: CommunityState.IState, { idCommunity, idNotesHistories }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const notesHistories = (state.entities[idCommunity]?.notesHistories as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            notesHistories: notesHistories.concat(idNotesHistories.filter(id => notesHistories.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyNotesHistorySuccess,
    (state: CommunityState.IState, { idNotesHistories, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.notesHistories) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              notesHistories: (state.entities[idCommunity]?.notesHistories as number[])?.filter(
                (idNotesHistory: number) => !idNotesHistories.some((id: number) => id === idNotesHistory)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addManyProjectModuleSuccess,
    (state: CommunityState.IState, { idCommunity, idProjectModules }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      const projectModules = (state.entities[idCommunity]?.projectModules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            projectModules: projectModules.concat(idProjectModules.filter(id => projectModules.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyProjectModuleSuccess,
    (state: CommunityState.IState, { idProjectModules, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.projectModules) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              projectModules: (state.entities[idCommunity]?.projectModules as number[])?.filter(
                (idProjectModule: number) => !idProjectModules.some((id: number) => id === idProjectModule)
              )
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(CommunityGeneratedActions.addManyGroupSuccess, (state: CommunityState.IState, { idCommunity, idGroups }) => {
    if (!state.entities[idCommunity]) {
      return state;
    }
    const groups = (state.entities[idCommunity]?.groups as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCommunity]: {
          ...state.entities[idCommunity],
          groups: groups.concat(idGroups.filter(id => groups.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CommunityGeneratedActions.deleteManyGroupSuccess, (state: CommunityState.IState, { idGroups, idCommunities }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCommunities.reduce((entities, idCommunity) => {
          if (!state.entities[idCommunity]?.groups) {
            return entities;
          }
          entities[idCommunity] = {
            ...state.entities[idCommunity],
            groups: (state.entities[idCommunity]?.groups as number[])?.filter(
              (idGroup: number) => !idGroups.some((id: number) => id === idGroup)
            )
          } as CommunityEntityState;
          return entities;
        }, {} as Dictionary<CommunityEntityState>)
      }
    };
  }),

  on(CommunityGeneratedActions.addManyUserSuccess, (state: CommunityState.IState, { idCommunity, idUsers }) => {
    if (!state.entities[idCommunity]) {
      return state;
    }
    const users = (state.entities[idCommunity]?.users as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCommunity]: {
          ...state.entities[idCommunity],
          users: users.concat(idUsers.filter(id => users.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CommunityGeneratedActions.deleteManyUserSuccess, (state: CommunityState.IState, { idUsers, idCommunities }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCommunities.reduce((entities, idCommunity) => {
          if (!state.entities[idCommunity]?.users) {
            return entities;
          }
          entities[idCommunity] = {
            ...state.entities[idCommunity],
            users: (state.entities[idCommunity]?.users as number[])?.filter(
              (idUser: number) => !idUsers.some((id: number) => id === idUser)
            )
          } as CommunityEntityState;
          return entities;
        }, {} as Dictionary<CommunityEntityState>)
      }
    };
  }),

  on(
    CommunityGeneratedActions.addOrganizationSuccess,
    (state: CommunityState.IState, { idCommunity, idOrganization }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyOrganizationSuccess,
    (state: CommunityState.IState, { idOrganizations, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.organization) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idCommunity]?.organization
              )
                ? undefined
                : state.entities[idCommunity]?.organization
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addOrganizationFamilySuccess,
    (state: CommunityState.IState, { idCommunity, idOrganizationFamily }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: CommunityState.IState, { idOrganizationFamilys, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.organizationFamily) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idCommunity]?.organizationFamily
              )
                ? undefined
                : state.entities[idCommunity]?.organizationFamily
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.addOrganizationStepSuccess,
    (state: CommunityState.IState, { idCommunity, idOrganizationStep }) => {
      if (!state.entities[idCommunity]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunity]: {
            ...state.entities[idCommunity],
            organizationStep: idOrganizationStep
          }
        }
      };
    }
  ),

  on(
    CommunityGeneratedActions.deleteManyOrganizationStepSuccess,
    (state: CommunityState.IState, { idOrganizationSteps, idCommunities }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunities.reduce((entities, idCommunity) => {
            if (!state.entities[idCommunity]?.organizationStep) {
              return entities;
            }
            entities[idCommunity] = {
              ...state.entities[idCommunity],
              organizationStep: idOrganizationSteps.some(
                (idOrganizationStep: number) => idOrganizationStep === state.entities[idCommunity]?.organizationStep
              )
                ? undefined
                : state.entities[idCommunity]?.organizationStep
            } as CommunityEntityState;
            return entities;
          }, {} as Dictionary<CommunityEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityState.IState {
  return { ...state, isLoaded, isLoading };
}
