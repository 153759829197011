import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GanttLink, GanttLinkEntityState } from '@api/api-interfaces';
import { GanttLinkRelationsIds } from '@wip/store/ids-interfaces';

export const GanttLinkGeneratedActions = createActionGroup({
  source: 'Gantt Link Generated',
  events: {
    'Get One Gantt Link': props<{ idGanttLink: number; params?: any }>(),
    'Get Many Gantt Links': props<{ params: any }>(),
    'Add Many Actives Gantt Links': props<{ idGanttLinks: number[] }>(),
    'Delete One Active Gantt Link': props<{ idGanttLink: number }>(),
    'Clear Actives Gantt Links': emptyProps(),
    'Upsert One Gantt Link': props<{ ganttLink: Partial<GanttLink>; ids?: GanttLinkRelationsIds }>(),
    'Upsert Many Gantt Links': props<{ ganttLinks: Partial<GanttLink>[]; ids?: GanttLinkRelationsIds }>(),
    'Upsert Many Gantt Links Success': props<{ ganttLinks: GanttLinkEntityState[] }>(),
    'Delete One Gantt Link': props<{ idGanttLink: number }>(),
    'Delete One Gantt Link Success': props<{ idGanttLink: number }>(),
    'Normalize Many Gantt Links After Upsert': props<{ ganttLinks: GanttLinkEntityState[] }>(),
    'Gantt Links Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Gantt Links': emptyProps(),
    'Add Community Success': props<{ idGanttLink: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idGanttLinks: number[] }>()
  }
});
