import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChildrenElement, ChildrenElementEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { ChildrenElementState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const childrenElementRelations: string[] = ['elements'];

export const { selectEntities, selectAll } = ChildrenElementState.adapter.getSelectors();

export const selectChildrenElementState = createFeatureSelector<ChildrenElementState.IState>(
  ChildrenElementState.childrenElementFeatureKey
);

export const selectIsLoadedChildrenElement = createSelector(
  selectChildrenElementState,
  (state: ChildrenElementState.IState) => state.isLoaded
);

export const selectIsLoadingChildrenElement = createSelector(
  selectChildrenElementState,
  (state: ChildrenElementState.IState) => state.isLoading
);

export const selectIsReadyChildrenElement = createSelector(
  selectChildrenElementState,
  (state: ChildrenElementState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedChildrenElement = createSelector(
  selectChildrenElementState,
  (state: ChildrenElementState.IState) => state.isLoaded && !state.isLoading
);

export const selectChildrenElementsEntities = createSelector(selectChildrenElementState, selectEntities);

export const selectChildrenElementsArray = createSelector(selectChildrenElementState, selectAll);

export const selectIdChildrenElementsActive = createSelector(
  selectChildrenElementState,
  (state: ChildrenElementState.IState) => state.actives
);

const childrenElementsInObject = (childrenElements: Dictionary<ChildrenElementEntityState>) => ({ childrenElements });

const selectChildrenElementsEntitiesDictionary = createSelector(
  selectChildrenElementsEntities,
  childrenElementsInObject
);

const selectAllChildrenElementsObject = createSelector(selectChildrenElementsEntities, childrenElements => {
  return hydrateAll({ childrenElements });
});

const selectOneChildrenElementDictionary = (idChildrenElement: number) =>
  createSelector(selectChildrenElementsEntities, childrenElements => ({
    childrenElements: { [idChildrenElement]: childrenElements[idChildrenElement] }
  }));

const selectOneChildrenElementDictionaryWithoutChild = (idChildrenElement: number) =>
  createSelector(selectChildrenElementsEntities, childrenElements => ({
    childrenElement: childrenElements[idChildrenElement]
  }));

const selectActiveChildrenElementsEntities = createSelector(
  selectIdChildrenElementsActive,
  selectChildrenElementsEntities,
  (actives: number[], childrenElements: Dictionary<ChildrenElementEntityState>) =>
    getChildrenElementsFromActives(actives, childrenElements)
);

function getChildrenElementsFromActives(
  actives: number[],
  childrenElements: Dictionary<ChildrenElementEntityState>
): Dictionary<ChildrenElementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (childrenElements[idActive]) {
      acc[idActive] = childrenElements[idActive];
    }
    return acc;
  }, {} as Dictionary<ChildrenElementEntityState>);
}

const selectAllChildrenElementsSelectors: Dictionary<Selector> = {};
export function selectAllChildrenElements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ChildrenElement>(
      schema,
      selectAllChildrenElementsSelectors,
      selectChildrenElementsEntitiesDictionary,
      getRelationSelectors,
      childrenElementRelations,
      hydrateAll,
      'childrenElement'
    );
  } else {
    return selectAllChildrenElementsObject;
  }
}

export function selectAllChildrenElementsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'childrenElements'
): Selector {
  return createSelector(selectAllChildrenElements(schema), result => {
    const res = { [customKey]: {} as Dictionary<ChildrenElementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.childrenElements.length; i++) {
      res[customKey][result.childrenElements[i].idChildrenElement] = result.childrenElements[i];
    }
    return res;
  });
}

export function selectOneChildrenElement(schema: SelectSchema = {}, idChildrenElement: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneChildrenElementDictionary(idChildrenElement)];
    selectors.push(...getRelationSelectors(schema, childrenElementRelations, 'childrenElement'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneChildrenElementDictionaryWithoutChild(idChildrenElement);
  }
}

export function selectActiveChildrenElements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveChildrenElementsEntities, childrenElements => ({
      childrenElements
    }))
  ];
  selectors.push(...getRelationSelectors(schema, childrenElementRelations, 'childrenElement'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  childrenElements: Dictionary<ChildrenElementEntityState>;
  elements?: Dictionary<ElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { childrenElements: (ChildrenElement | null)[] } {
  const { childrenElements, elements } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    childrenElements: Object.keys(childrenElements).map(idChildrenElement =>
      hydrate(childrenElements[idChildrenElement] as ChildrenElementEntityState, elements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { childrenElement: ChildrenElementEntityState | null } {
  const { childrenElements, elements } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const childrenElement = Object.values(childrenElements)[0];
  return {
    childrenElement: hydrate(childrenElement as ChildrenElementEntityState, elements)
  };
}

function hydrate(
  childrenElement: ChildrenElementEntityState,
  elementEntities?: Dictionary<ElementEntityState>
): ChildrenElement | null {
  if (!childrenElement) {
    return null;
  }

  const childrenElementHydrated: ChildrenElementEntityState = { ...childrenElement };
  if (elementEntities) {
    childrenElementHydrated.element = elementEntities[childrenElement.element as number] as Element;
  } else {
    delete childrenElementHydrated.element;
  }

  return childrenElementHydrated as ChildrenElement;
}
