import { DroitInterneEnum, DurationFormat } from '@enums';
import { GanttStatic } from '@wip/gantt-static';
import { Community, Task } from '@api/api-interfaces';
import { durationFormatterParams } from '../new-gantt.component';
import {
  getPredecessors,
  templateFormatDate,
  templateFormatText,
  templateLag,
  templatePredecessor
} from './new-gantt-templates';
import { getResponsiblesInnerHtml } from './responsible.utils';

export function setColumns(gantt: GanttStatic, community: Community) {
  gantt.config.grid_width = 650;

  const ganttLite = community.organizationFamily?.ganttLite;
  const businessDay = community.organizationFamily?.businessDay;

  const formatter = gantt.ext.formatters.durationFormatter(durationFormatterParams(businessDay, DurationFormat.auto));
  const canUpdate = community.communityRights.crud.includes(DroitInterneEnum.elementUpdate);
  const canCreate = community.communityRights.crud.includes(DroitInterneEnum.elementCreateDelete);

  const index = {
    name: 'index',
    width: 36,
    resize: false,
    min_width: 36,
    max_width: 36,
    label: '',
    template: task => {
      return `<div class="link-index">${task.index === undefined ? '' : task.index}</div>`;
    }
  };
  const text = {
    name: 'text',
    label: 'Titre',
    tree: true,
    width: '300',
    resize: true,
    template: task => {
      return templateFormatText(task, gantt);
    },
    editor: canUpdate ? { type: 'text', map_to: 'text' } : null
  };

  const startDate = {
    name: 'start_date',
    label: ganttLite ? 'Date' : 'Début',
    align: 'center',
    width: 70,
    template: task => {
      return templateFormatDate(task as Task, gantt, 'start_date', community?.organizationFamily?.businessDay);
    },
    editor: canUpdate ? gantt.config.editor_types.start_date_editor : null
  };

  const duration = {
    name: 'duration',
    label: 'Durée',
    align: 'center',
    width: 50,
    template: task => {
      if (task.type === 'project' && task.duration) {
        return `<span style="font-weight: bold;color: #1e4c64">${roundDuration(
          formatter.format(task.duration)
        )}</span>`;
      } else if (task.duration) {
        return `<span style="font-weight: bold;">${roundDuration(formatter.format(task.duration))}</span>`;
      }
    },
    editor: gantt.config.editor_types.duration_editor
  };

  const endDate = {
    name: 'end_date',
    label: 'Fin',
    align: 'center',
    width: 70,
    template: task => {
      return templateFormatDate(task as Task, gantt, 'end_date', community.organizationFamily?.businessDay);
    },
    editor: gantt.config.editor_types.end_date_editor
  };

  const predecessor = {
    name: 'previous',
    label: 'Pr.',
    width: 44,
    template: task => {
      return templatePredecessor(task, gantt);
    },
    editor: gantt.config.editor_types.previous_editor
  };

  const lag = {
    name: 'lag',
    label: 'Deca',
    width: 44,
    template: task => {
      return templateLag(task, gantt, community.organizationFamily?.businessDay);
    },
    editor: gantt.config.editor_types.lag_editor
  };

  const predecessorAndLag = {
    name: 'predecessor_and_lag',
    label: 'Pred.',
    width: 70,
    resize: true,
    min_width: 30,
    max_width: 110,
    editor: gantt.config.editor_types.predecessor_and_lag_custom_editor,
    template: (task: Task) => {
      const predecessors = getPredecessors(task, gantt, community.organizationFamily.businessDay);
      return `<div class="link-info">${predecessors}</div>`;
    }
  };

  const responsibles = {
    name: 'responsibles',
    label: 'Resp',
    width: 44,
    align: 'center',
    template: task => {
      const resps = getResponsiblesInnerHtml(task);
      return `<div class="responsible-with-picture">${resps}</div>`;
    },
    editor: gantt.config.editor_types.responsible_editor
  };

  const options = {
    name: 'edit',
    label: '',
    width: 30,
    resize: false,
    min_width: 30,
    max_width: 30,
    align: 'center',
    template: () => (canCreate ? '<span class="gulp gulp-34-more more-edition cursor-pointer"></span>' : ''),
    editor: gantt.config.editor_types.edit_menu
  };

  if (ganttLite) {
    gantt.config.columns = [index, text, startDate, predecessor, lag, responsibles, options];
  } else {
    gantt.config.columns = [index, text, startDate, endDate, duration, predecessorAndLag, responsibles, options];
  }
}

export function roundDuration(value: string) {
  const resultat = value.match(/(\d+(\.\d+)?)([a-zA-Z])?/);

  // Vérifier si un nombre a été trouvé
  if (resultat) {
    const nombre = Math.round(parseFloat(resultat[1]) * 10) / 10;
    const lettre = resultat[3] || null; // Utilisez null si aucune lettre n'est trouvée

    return nombre.toLocaleString() + lettre;
  } else {
    // Retourner null si aucun nombre n'a été trouvé
    return null;
  }
}
