import { OrganizationProjectModuleState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationProjectModuleReducersGeneratedFunctions } from './organization-project-module-generated.reducer';

const organizationProjectModuleReducersFunctions = [...organizationProjectModuleReducersGeneratedFunctions];

const organizationProjectModuleReducer = createReducer(
  OrganizationProjectModuleState.initialState,
  ...organizationProjectModuleReducersFunctions
);

export function reducer(state: OrganizationProjectModuleState.IState | undefined, action: Action) {
  return organizationProjectModuleReducer(state, action);
}
