import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GeneratedUserHistoryEffects } from './user-history-generated.effects';
import { UserHistoryApiService } from '@wip/store/api-services';

@Injectable()
export class UserHistoryEffects extends GeneratedUserHistoryEffects {
  constructor(actions$: Actions, userHistoryApiService: UserHistoryApiService, store$: Store<AppState>) {
    super(actions$, userHistoryApiService, store$);
  }
}
