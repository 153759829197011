import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserProfilRightGeneratedActions } from '@wip/store/actions';
import { UserProfilRightState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserProfilRightEntityState } from '@api/api-interfaces';

export const userProfilRightReducersGeneratedFunctions: ReducerTypes<
  UserProfilRightState.IState,
  readonly ActionCreator[]
>[] = [
  on(UserProfilRightGeneratedActions.getOneUserProfilRight, (state: UserProfilRightState.IState) =>
    setLoadingsState(state, true)
  ),
  on(UserProfilRightGeneratedActions.getManyUserProfilRights, (state: UserProfilRightState.IState) =>
    setLoadingsState(state, true)
  ),
  on(UserProfilRightGeneratedActions.upsertOneUserProfilRight, (state: UserProfilRightState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    UserProfilRightGeneratedActions.upsertManyUserProfilRightsSuccess,
    (state: UserProfilRightState.IState, { userProfilRights }) =>
      UserProfilRightState.adapter.upsertMany(userProfilRights, setLoadingsState(state, false))
  ),
  on(UserProfilRightGeneratedActions.deleteOneUserProfilRight, (state: UserProfilRightState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    UserProfilRightGeneratedActions.deleteOneUserProfilRightSuccess,
    (state: UserProfilRightState.IState, { idUserProfilRight }) =>
      UserProfilRightState.adapter.removeOne(idUserProfilRight, setLoadingsState(state, false))
  ),
  on(UserProfilRightGeneratedActions.clearActivesUserProfilRights, (state: UserProfilRightState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    UserProfilRightGeneratedActions.addManyActivesUserProfilRights,
    (state: UserProfilRightState.IState, { idUserProfilRights }) => ({
      ...state,
      actives: state.actives.concat(idUserProfilRights)
    })
  ),
  on(
    UserProfilRightGeneratedActions.deleteOneActiveUserProfilRight,
    (state: UserProfilRightState.IState, { idUserProfilRight }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idUserProfilRight)
    })
  ),

  on(UserProfilRightGeneratedActions.clearUserProfilRights, () => UserProfilRightState.initialState),

  on(
    UserProfilRightGeneratedActions.addProfilSuccess,
    (state: UserProfilRightState.IState, { idUserProfilRight, idProfil }) => {
      if (!state.entities[idUserProfilRight]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserProfilRight]: {
            ...state.entities[idUserProfilRight],
            profil: idProfil
          }
        }
      };
    }
  ),

  on(
    UserProfilRightGeneratedActions.deleteManyProfilSuccess,
    (state: UserProfilRightState.IState, { idProfils, idUserProfilRights }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserProfilRights.reduce((entities, idUserProfilRight) => {
            if (!state.entities[idUserProfilRight]?.profil) {
              return entities;
            }
            entities[idUserProfilRight] = {
              ...state.entities[idUserProfilRight],
              profil: idProfils.some((idProfil: number) => idProfil === state.entities[idUserProfilRight]?.profil)
                ? undefined
                : state.entities[idUserProfilRight]?.profil
            } as UserProfilRightEntityState;
            return entities;
          }, {} as Dictionary<UserProfilRightEntityState>)
        }
      };
    }
  ),

  on(
    UserProfilRightGeneratedActions.addOrganizationUserProfilSuccess,
    (state: UserProfilRightState.IState, { idUserProfilRight, idOrganizationUserProfil }) => {
      if (!state.entities[idUserProfilRight]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserProfilRight]: {
            ...state.entities[idUserProfilRight],
            organizationUserProfil: idOrganizationUserProfil
          }
        }
      };
    }
  ),

  on(
    UserProfilRightGeneratedActions.deleteManyOrganizationUserProfilSuccess,
    (state: UserProfilRightState.IState, { idOrganizationUserProfils, idUserProfilRights }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserProfilRights.reduce((entities, idUserProfilRight) => {
            if (!state.entities[idUserProfilRight]?.organizationUserProfil) {
              return entities;
            }
            entities[idUserProfilRight] = {
              ...state.entities[idUserProfilRight],
              organizationUserProfil: idOrganizationUserProfils.some(
                (idOrganizationUserProfil: number) =>
                  idOrganizationUserProfil === state.entities[idUserProfilRight]?.organizationUserProfil
              )
                ? undefined
                : state.entities[idUserProfilRight]?.organizationUserProfil
            } as UserProfilRightEntityState;
            return entities;
          }, {} as Dictionary<UserProfilRightEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: UserProfilRightState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): UserProfilRightState.IState {
  return { ...state, isLoaded, isLoading };
}
