import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Meeting } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { MeetingRelationsIds } from '@wip/store/ids-interfaces';
import { MeetingGeneratedActions } from '@wip/store/actions';
import { MeetingSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedMeetingService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(MeetingSelectors.selectIsLoadedMeeting));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(MeetingSelectors.selectIsLoadingMeeting));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [MeetingSelectors.selectIsReadyAndLoadedMeeting as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllMeetings(schema: SelectSchema = {}): Observable<Meeting[]> {
    return this.store$.pipe(select(MeetingSelectors.selectAllMeetings(schema))).pipe(
      switchMap(({ meetings }: { meetings: Meeting[] }) => {
        return this.getReady(schema).pipe(mapTo(meetings));
      })
    );
  }

  public selectOneMeeting(idMeeting: number, schema: SelectSchema = {}): Observable<Meeting> {
    return this.store$.pipe(select(MeetingSelectors.selectOneMeeting(schema, idMeeting))).pipe(
      switchMap(({ meeting }: { meeting: Meeting }) => {
        return this.getReady(schema).pipe(mapTo(meeting));
      })
    );
  }

  public selectAllActiveMeetings(schema: SelectSchema = {}): Observable<Meeting[]> {
    return this.store$.pipe(select(MeetingSelectors.selectActiveMeetings(schema))).pipe(
      switchMap(({ meetings }: { meetings: Meeting[] }) => {
        return this.getReady(schema).pipe(mapTo(meetings));
      })
    );
  }

  public selectIdMeetingsActive(): Observable<number[]> {
    return this.store$.pipe(select(MeetingSelectors.selectIdMeetingsActive)).pipe(
      switchMap((idMeetings: number[]) => {
        return this.getReady().pipe(mapTo(idMeetings));
      })
    );
  }

  public getOneMeeting(idMeeting: number, params: any = {}, getResult?: boolean): void | Observable<Meeting> {
    this.store$.dispatch(MeetingGeneratedActions.getOneMeeting({ idMeeting, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingGeneratedActions.normalizeManyMeetingsAfterUpsert,
        MeetingGeneratedActions.meetingsFailure,
        true
      );
    }
  }

  public getManyMeetings(params: any = {}, getResult?: boolean): void | Observable<Meeting[]> {
    this.store$.dispatch(MeetingGeneratedActions.getManyMeetings({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingGeneratedActions.normalizeManyMeetingsAfterUpsert,
        MeetingGeneratedActions.meetingsFailure
      );
    }
  }

  public upsertOneMeeting(
    meeting: Partial<Meeting>,
    ids: MeetingRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Meeting> {
    this.store$.dispatch(MeetingGeneratedActions.upsertOneMeeting({ meeting, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingGeneratedActions.normalizeManyMeetingsAfterUpsert,
        MeetingGeneratedActions.meetingsFailure,
        true
      );
    }
  }

  public deleteOneMeeting(idMeeting: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(MeetingGeneratedActions.deleteOneMeeting({ idMeeting }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingGeneratedActions.deleteOneMeetingSuccess,
        MeetingGeneratedActions.meetingsFailure
      );
    }
  }

  public setActiveMeetings(idMeetings: number[]): void {
    this.store$.dispatch(MeetingGeneratedActions.clearActivesMeetings());
    this.store$.dispatch(MeetingGeneratedActions.addManyActivesMeetings({ idMeetings }));
  }
}
