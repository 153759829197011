import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProjectElement, ProjectElementEntityState } from '@api/api-interfaces';
import { ProjectElementRelationsIds } from '@wip/store/ids-interfaces';

export const ProjectElementGeneratedActions = createActionGroup({
  source: 'Project Element Generated',
  events: {
    'Get One Project Element': props<{ idProjectElement: number; params?: any }>(),
    'Get Many Project Elements': props<{ params: any }>(),
    'Add Many Actives Project Elements': props<{ idProjectElements: number[] }>(),
    'Delete One Active Project Element': props<{ idProjectElement: number }>(),
    'Clear Actives Project Elements': emptyProps(),
    'Upsert One Project Element': props<{
      projectElement: Partial<ProjectElement>;
      ids?: ProjectElementRelationsIds;
    }>(),
    'Upsert Many Project Elements': props<{
      projectElements: Partial<ProjectElement>[];
      ids?: ProjectElementRelationsIds;
    }>(),
    'Upsert Many Project Elements Success': props<{ projectElements: ProjectElementEntityState[] }>(),
    'Delete One Project Element': props<{ idProjectElement: number }>(),
    'Delete One Project Element Success': props<{ idProjectElement: number }>(),
    'Normalize Many Project Elements After Upsert': props<{ projectElements: ProjectElementEntityState[] }>(),
    'Project Elements Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Project Elements': emptyProps(),
    'Add Community Success': props<{ idProjectElement: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idProjectElements: number[] }>(),
    'Add Element Success': props<{ idProjectElement: number; idElement: number }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idProjectElements: number[] }>()
  }
});
