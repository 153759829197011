import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationMilestoneAssociationGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneAssociationState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationMilestoneAssociationEntityState } from '@api/api-interfaces';

export const organizationMilestoneAssociationReducersGeneratedFunctions: ReducerTypes<
  OrganizationMilestoneAssociationState.IState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationMilestoneAssociationGeneratedActions.getOneOrganizationMilestoneAssociation,
    (state: OrganizationMilestoneAssociationState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationMilestoneAssociationGeneratedActions.getManyOrganizationMilestoneAssociations,
    (state: OrganizationMilestoneAssociationState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationMilestoneAssociationGeneratedActions.upsertOneOrganizationMilestoneAssociation,
    (state: OrganizationMilestoneAssociationState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationMilestoneAssociationGeneratedActions.upsertManyOrganizationMilestoneAssociationsSuccess,
    (state: OrganizationMilestoneAssociationState.IState, { organizationMilestoneAssociations }) =>
      OrganizationMilestoneAssociationState.adapter.upsertMany(
        organizationMilestoneAssociations,
        setLoadingsState(state, false)
      )
  ),
  on(
    OrganizationMilestoneAssociationGeneratedActions.deleteOneOrganizationMilestoneAssociation,
    (state: OrganizationMilestoneAssociationState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationMilestoneAssociationGeneratedActions.deleteOneOrganizationMilestoneAssociationSuccess,
    (state: OrganizationMilestoneAssociationState.IState, { idOrganizationMilestoneAssociation }) =>
      OrganizationMilestoneAssociationState.adapter.removeOne(
        idOrganizationMilestoneAssociation,
        setLoadingsState(state, false)
      )
  ),
  on(
    OrganizationMilestoneAssociationGeneratedActions.clearActivesOrganizationMilestoneAssociations,
    (state: OrganizationMilestoneAssociationState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationMilestoneAssociationGeneratedActions.addManyActivesOrganizationMilestoneAssociations,
    (state: OrganizationMilestoneAssociationState.IState, { idOrganizationMilestoneAssociations }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationMilestoneAssociations)
    })
  ),
  on(
    OrganizationMilestoneAssociationGeneratedActions.deleteOneActiveOrganizationMilestoneAssociation,
    (state: OrganizationMilestoneAssociationState.IState, { idOrganizationMilestoneAssociation }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationMilestoneAssociation)
    })
  ),

  on(
    OrganizationMilestoneAssociationGeneratedActions.clearOrganizationMilestoneAssociations,
    () => OrganizationMilestoneAssociationState.initialState
  ),

  on(
    OrganizationMilestoneAssociationGeneratedActions.addOrganizationMilestoneSuccess,
    (
      state: OrganizationMilestoneAssociationState.IState,
      { idOrganizationMilestoneAssociation, idOrganizationMilestone }
    ) => {
      if (!state.entities[idOrganizationMilestoneAssociation]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestoneAssociation]: {
            ...state.entities[idOrganizationMilestoneAssociation],
            organizationMilestone: idOrganizationMilestone
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneAssociationGeneratedActions.deleteManyOrganizationMilestoneSuccess,
    (
      state: OrganizationMilestoneAssociationState.IState,
      { idOrganizationMilestones, idOrganizationMilestoneAssociations }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestoneAssociations.reduce((entities, idOrganizationMilestoneAssociation) => {
            if (!state.entities[idOrganizationMilestoneAssociation]?.organizationMilestone) {
              return entities;
            }
            entities[idOrganizationMilestoneAssociation] = {
              ...state.entities[idOrganizationMilestoneAssociation],
              organizationMilestone: idOrganizationMilestones.some(
                (idOrganizationMilestone: number) =>
                  idOrganizationMilestone === state.entities[idOrganizationMilestoneAssociation]?.organizationMilestone
              )
                ? undefined
                : state.entities[idOrganizationMilestoneAssociation]?.organizationMilestone
            } as OrganizationMilestoneAssociationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneAssociationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneAssociationGeneratedActions.addOrganizationMilestoneFamilySuccess,
    (
      state: OrganizationMilestoneAssociationState.IState,
      { idOrganizationMilestoneAssociation, idOrganizationMilestoneFamily }
    ) => {
      if (!state.entities[idOrganizationMilestoneAssociation]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestoneAssociation]: {
            ...state.entities[idOrganizationMilestoneAssociation],
            organizationMilestoneFamily: idOrganizationMilestoneFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneAssociationGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess,
    (
      state: OrganizationMilestoneAssociationState.IState,
      { idOrganizationMilestoneFamilys, idOrganizationMilestoneAssociations }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestoneAssociations.reduce((entities, idOrganizationMilestoneAssociation) => {
            if (!state.entities[idOrganizationMilestoneAssociation]?.organizationMilestoneFamily) {
              return entities;
            }
            entities[idOrganizationMilestoneAssociation] = {
              ...state.entities[idOrganizationMilestoneAssociation],
              organizationMilestoneFamily: idOrganizationMilestoneFamilys.some(
                (idOrganizationMilestoneFamily: number) =>
                  idOrganizationMilestoneFamily ===
                  state.entities[idOrganizationMilestoneAssociation]?.organizationMilestoneFamily
              )
                ? undefined
                : state.entities[idOrganizationMilestoneAssociation]?.organizationMilestoneFamily
            } as OrganizationMilestoneAssociationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneAssociationEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationMilestoneAssociationState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationMilestoneAssociationState.IState {
  return { ...state, isLoaded, isLoading };
}
