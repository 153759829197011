import { RepositoryService } from '@wip/services/repository';
import { CommunityGroup } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityGroupApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityGroups(params?: any): Observable<CommunityGroup[]> {
    return this.repo.getData<CommunityGroup[]>('community-group', params);
  }

  public getCommunityGroup(params: { idCommunityGroup: number; params?: any }): Observable<CommunityGroup> {
    return this.repo.getData<CommunityGroup>('community-group/' + params.idCommunityGroup, params.params);
  }

  public addCommunityGroup(communityGroup: Partial<CommunityGroup>): Observable<CommunityGroup> {
    return this.repo.create<CommunityGroup>('community-group', communityGroup);
  }

  public updateCommunityGroup(communityGroup: Partial<CommunityGroup>): Observable<CommunityGroup> {
    return this.repo.update('community-group', communityGroup);
  }

  public deleteCommunityGroup(idCommunityGroup: number): Observable<number> {
    return this.repo.delete('community-group/' + +idCommunityGroup);
  }
}
