export const getRowId = (id: number | string | null | undefined): string | null => {
  switch (typeof id) {
    case 'string':
      return id;
    case 'number':
      return id?.toString();
    default:
      return null;
  }
};
