import { ConfirmationChoicesInterface } from '@wip/interfaces';
import { ConfirmationChoicesDialogContainerComponent } from '@wip/dialogs/confirmation-choices-dialog';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChildrenElement } from '@api/api-interfaces';
import { FolderService } from '@wip/store/services';
@UntilDestroy()
@Component({
  selector: 'app-document-list-form',
  templateUrl: './document-list-form.component.html',
  styleUrls: ['./document-list-form.component.scss']
})
export class DocumentListFormComponent implements OnInit, OnChanges {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() IDparent: number;
  @Input() uploadHandler: Function;
  @Input() downloadHandler: Function;
  @Input() deletionHandler: Function;
  @Input() previewFile: Function;
  @Input() isViewer: boolean;
  @Input() fromAdminPage: boolean = false;
  @Input() documents: ChildrenElement[];

  @Output() added = new EventEmitter<{ document: ChildrenElement; file: File }[]>();
  @Output() removed = new EventEmitter<{ document: ChildrenElement; file: File }[]>();

  public files: { document: ChildrenElement; file: File; isLoading?: boolean }[] = [];
  public uploading: boolean = false;

  constructor(
    private dialog: MatDialog,
    private newFolderService: FolderService
  ) {}

  ngOnInit() {
    this.newFolderService.uploadInProgress.subscribe(value => {
      this.uploading = value;
    });
  }

  ngOnChanges(changes) {
    if (changes.documents) {
      this.files = this.documents
        .map(doc => {
          return { document: doc, file: null };
        })
        .sort((a, b) => a.document.titre.toLowerCase().localeCompare(b.document.titre.toLowerCase()));
    }
  }

  browseFiles() {
    this.fileInput.nativeElement.click();
  }

  showFile(index) {
    const element = { detail: this.files[index].document };
    const elements = this.files.map(file => file.document);
    this.previewFile(element, elements);
  }

  downloadFile(i) {
    const element = { detail: this.files[i].document };
    this.downloadHandler(element);
  }

  addFiles($event) {
    const files = $event.target ? $event.target.files : $event;
    const addedFiles = [];
    for (const file of files) {
      const fileInfo = this.getFileInfo(file);
      addedFiles.push({
        document: {
          titre: fileInfo.title,
          extension: fileInfo.extension,
          size: file.size,
          type: file.type
        },
        file,
        isLoading: false
      });
    }

    addedFiles.forEach(file => {
      this.uploadHandler(file).pipe(untilDestroyed(this)).subscribe();
    });
  }

  removeItem(index) {
    const removedItem = this.files[index];
    const body = 'Êtes-vous sur de vouloir supprimer ce document ?';
    const actions: ConfirmationChoicesInterface[] = [
      {
        color: 'warn',
        label: 'Suppression'
      },
      {
        color: '',
        label: 'Annuler'
      }
    ];

    const dialogRef = this.dialog.open(ConfirmationChoicesDialogContainerComponent, {
      data: {
        title: 'Suppression',
        body,
        actions
      }
    });

    dialogRef.beforeClosed().subscribe(value => {
      if (value) {
        this.deletionHandler(removedItem);
      }
    });
  }

  private getFileInfo(file) {
    const re = /(?:\.([^.]+))?$/;
    const filename = file.name;
    const extension = '.' + re.exec(file.name)[1];
    let title = '';
    if (extension && extension.length) {
      title = file.name.substring(0, filename.length - extension.length);
    } else {
      title = file.name;
    }
    return { filename, extension, title };
  }
}
