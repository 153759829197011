import { Injectable } from '@angular/core';
import { OrganizationDataColumn, OrganizationFamily } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedOrganizationDataColumnApiService } from './organization-data-column-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationDataColumnApiService extends GeneratedOrganizationDataColumnApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateDataColumn(organizationDataColumn: Partial<OrganizationDataColumn>): Observable<OrganizationFamily> {
    if (organizationDataColumn.idOrganizationDataColumn) {
      return this.repo.update('organization-data-column/update', organizationDataColumn);
    } else {
      return this.repo.create('organization-data-column', organizationDataColumn);
    }
  }
}
