import { OrganizationMilestoneState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationMilestoneReducersGeneratedFunctions } from './organization-milestone-generated.reducer';

const organizationMilestoneReducersFunctions = [...organizationMilestoneReducersGeneratedFunctions];

const organizationMilestoneReducer = createReducer(
  OrganizationMilestoneState.initialState,
  ...organizationMilestoneReducersFunctions
);

export function reducer(state: OrganizationMilestoneState.IState | undefined, action: Action) {
  return organizationMilestoneReducer(state, action);
}
