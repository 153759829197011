@if (data) {
  <mat-dialog-content>
    <div class="infos-container flex flex-row justify-start items-start gap-16">
      <app-avatar class="avatar" size="medium" [user]="user" [height]="50" [tooltip]="false"> </app-avatar>
      <div class="user-detail-container">
        <div class="nom-prenom" class="infos-container">{{ user.prenom }} {{ user.nom }}</div>
        <div class="email-font">{{ user.email }}</div>
        <div style="padding-top: 10px">
          @if (user.societe) {
            <div class="email-font">Société: {{ user.societe }}</div>
          }
          @if (user.fonction) {
            <div class="email-font">Fonction: {{ user.fonction }}</div>
          }
          @if (user.phoneFixe) {
            <div class="email-font">Téléphone fixe: {{ user.phoneFixe }}</div>
          }
          @if (user.phoneMobile) {
            <div class="email-font">Téléphone mobile: {{ user.phoneMobile }}</div>
          }
        </div>
      </div>
    </div>
  </mat-dialog-content>
}

<mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary">Ok</button>
</mat-dialog-actions>
