import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunitySubFamilyGeneratedActions } from '@wip/store/actions';
import { CommunitySubFamilyState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunitySubFamilyEntityState } from '@api/api-interfaces';

export const communitySubFamilyReducersGeneratedFunctions: ReducerTypes<
  CommunitySubFamilyState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunitySubFamilyGeneratedActions.getOneCommunitySubFamily, (state: CommunitySubFamilyState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunitySubFamilyGeneratedActions.getManyCommunitySubFamilys, (state: CommunitySubFamilyState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunitySubFamilyGeneratedActions.upsertOneCommunitySubFamily, (state: CommunitySubFamilyState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    CommunitySubFamilyGeneratedActions.upsertManyCommunitySubFamilysSuccess,
    (state: CommunitySubFamilyState.IState, { communitySubFamilys }) =>
      CommunitySubFamilyState.adapter.upsertMany(communitySubFamilys, setLoadingsState(state, false))
  ),
  on(CommunitySubFamilyGeneratedActions.deleteOneCommunitySubFamily, (state: CommunitySubFamilyState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    CommunitySubFamilyGeneratedActions.deleteOneCommunitySubFamilySuccess,
    (state: CommunitySubFamilyState.IState, { idCommunitySubFamily }) =>
      CommunitySubFamilyState.adapter.removeOne(idCommunitySubFamily, setLoadingsState(state, false))
  ),
  on(CommunitySubFamilyGeneratedActions.clearActivesCommunitySubFamilys, (state: CommunitySubFamilyState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    CommunitySubFamilyGeneratedActions.addManyActivesCommunitySubFamilys,
    (state: CommunitySubFamilyState.IState, { idCommunitySubFamilys }) => ({
      ...state,
      actives: state.actives.concat(idCommunitySubFamilys)
    })
  ),
  on(
    CommunitySubFamilyGeneratedActions.deleteOneActiveCommunitySubFamily,
    (state: CommunitySubFamilyState.IState, { idCommunitySubFamily }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunitySubFamily)
    })
  ),

  on(CommunitySubFamilyGeneratedActions.clearCommunitySubFamilys, () => CommunitySubFamilyState.initialState),

  on(
    CommunitySubFamilyGeneratedActions.addOrganizationSubFamilySuccess,
    (state: CommunitySubFamilyState.IState, { idCommunitySubFamily, idOrganizationSubFamily }) => {
      if (!state.entities[idCommunitySubFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunitySubFamily]: {
            ...state.entities[idCommunitySubFamily],
            organizationSubFamily: idOrganizationSubFamily
          }
        }
      };
    }
  ),

  on(
    CommunitySubFamilyGeneratedActions.deleteManyOrganizationSubFamilySuccess,
    (state: CommunitySubFamilyState.IState, { idOrganizationSubFamilys, idCommunitySubFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunitySubFamilys.reduce((entities, idCommunitySubFamily) => {
            if (!state.entities[idCommunitySubFamily]?.organizationSubFamily) {
              return entities;
            }
            entities[idCommunitySubFamily] = {
              ...state.entities[idCommunitySubFamily],
              organizationSubFamily: idOrganizationSubFamilys.some(
                (idOrganizationSubFamily: number) =>
                  idOrganizationSubFamily === state.entities[idCommunitySubFamily]?.organizationSubFamily
              )
                ? undefined
                : state.entities[idCommunitySubFamily]?.organizationSubFamily
            } as CommunitySubFamilyEntityState;
            return entities;
          }, {} as Dictionary<CommunitySubFamilyEntityState>)
        }
      };
    }
  ),

  on(
    CommunitySubFamilyGeneratedActions.addCommunitySuccess,
    (state: CommunitySubFamilyState.IState, { idCommunitySubFamily, idCommunity }) => {
      if (!state.entities[idCommunitySubFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunitySubFamily]: {
            ...state.entities[idCommunitySubFamily],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunitySubFamilyGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunitySubFamilyState.IState, { idCommunities, idCommunitySubFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunitySubFamilys.reduce((entities, idCommunitySubFamily) => {
            if (!state.entities[idCommunitySubFamily]?.community) {
              return entities;
            }
            entities[idCommunitySubFamily] = {
              ...state.entities[idCommunitySubFamily],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunitySubFamily]?.community
              )
                ? undefined
                : state.entities[idCommunitySubFamily]?.community
            } as CommunitySubFamilyEntityState;
            return entities;
          }, {} as Dictionary<CommunitySubFamilyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunitySubFamilyState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunitySubFamilyState.IState {
  return { ...state, isLoaded, isLoading };
}
