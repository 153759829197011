<div mat-dialog-title>
  <h1>{{ title }}</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close"></button>
</div>
<div mat-dialog-content>
  <div style="position: relative">
    <img [hidden]="!this.isPicture" class="img-preview" #preview />
    @if (!this.isPicture) {
      <pdf-viewer
        [src]="pdfSrc"
        [render-text]="true"
        [original-size]="false"
        style="height: 75vh; width: 75vw"
      ></pdf-viewer>
    }
  </div>
</div>
<div
  mat-dialog-actions
  style="position: absolute; bottom: 16px; right: 16px"
  class="margin-10-0-0 flex justify-end items-center gap-8"
>
  <button mat-icon-button (click)="goPreviousPicture()" class="export-icon-button" [disabled]="!hasPreviousPicture()">
    <i class="icon icon-ic_fluent_chevron_left_24_regular" style="font-size: 20px"></i>
  </button>
  <button mat-icon-button (click)="goNextPicture()" class="export-icon-button" [disabled]="!hasNextPicture()">
    <i class="icon icon-ic_fluent_chevron_right_24_regular" style="font-size: 20px"></i>
  </button>
  <button mat-button color="accent" tabindex="-1" (click)="onClickDelete()">Supprimer</button>
  <button mat-raised-button color="primary" (click)="onClickDownload()">Télécharger</button>
</div>
