import { ConfirmationChoicesInterface } from '@wip/interfaces';
import { ConfirmationChoicesDialogContainerComponent } from '@wip/dialogs/confirmation-choices-dialog';
import { InformationDialogContainerComponent } from '@wip/dialogs/information-dialog';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Element } from '@api/api-interfaces';
import { AppState, clearState } from '@wip/store/configs/reducers';

@Injectable({
  providedIn: 'root'
})
export class OldUserService {
  private currentUserId: number;
  private elementAuthorId: number;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private store$: Store<AppState>
  ) {}

  /**
   * @description Return if the current user is the author of the element given in param
   * @param element The element you want to know if it was created by the current user
   */
  checkIsAuthor(element: Element) {
    // On récupère l'Id de l'utilisateur actuel

    // On récupère l'Id de l'auteur de l'élément
    this.elementAuthorId = element.createdBy;

    return this.currentUserId === this.elementAuthorId;
  }

  logOut(fromInterceptor: boolean = false) {
    let confirmationDialog;
    if (fromInterceptor) {
      confirmationDialog = this.dialog.open(InformationDialogContainerComponent, {
        data: {
          title: 'Votre session est expirée',
          body: 'Déconnexion'
        }
      });
    } else {
      const actions: ConfirmationChoicesInterface[] = [
        {
          color: 'primary',
          label: 'Confirmer'
        },
        {
          color: '',
          label: 'Annuler'
        }
      ];

      confirmationDialog = this.dialog.open(ConfirmationChoicesDialogContainerComponent, {
        data: {
          title: 'Déconnexion',
          body: 'Êtes-vous sur de vouloir vous déconnecter ?',
          actions
        }
      });
    }

    confirmationDialog.beforeClosed().subscribe((isDelete: boolean) => {
      if (isDelete || fromInterceptor) {
        localStorage.clear();
        if (localStorage.getItem('ngStorage-things') !== null) {
          localStorage.removeItem('ngStorage-things');
        }
        this.router.navigate(['/']);
        this.store$.dispatch(clearState({ states: [] }));
      }
    });
  }
  checkPasswordComplexity(value) {
    let numberTypes = 0;
    if (new RegExp('[a-z]+').test(value)) {
      numberTypes++;
    }
    if (new RegExp('[A-Z]+').test(value)) {
      numberTypes++;
    }
    if (new RegExp('[0-9]+').test(value)) {
      numberTypes++;
    }
    if (new RegExp('[@#!]+').test(value)) {
      numberTypes++;
    }
    if (numberTypes >= 2 && value.length >= 8) {
      return true;
    } else {
      return false;
    }
  }
}
