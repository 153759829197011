import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { GroupGeneratedActions } from '@wip/store/actions';
import { GroupState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { GroupEntityState } from '@api/api-interfaces';

export const groupReducersGeneratedFunctions: ReducerTypes<GroupState.IState, readonly ActionCreator[]>[] = [
  on(GroupGeneratedActions.getOneGroup, (state: GroupState.IState) => setLoadingsState(state, true)),
  on(GroupGeneratedActions.getManyGroups, (state: GroupState.IState) => setLoadingsState(state, true)),
  on(GroupGeneratedActions.upsertOneGroup, (state: GroupState.IState) => setLoadingsState(state, true)),

  on(GroupGeneratedActions.upsertManyGroupsSuccess, (state: GroupState.IState, { groups }) =>
    GroupState.adapter.upsertMany(groups, setLoadingsState(state, false))
  ),
  on(GroupGeneratedActions.deleteOneGroup, (state: GroupState.IState) => setLoadingsState(state, true)),
  on(GroupGeneratedActions.deleteOneGroupSuccess, (state: GroupState.IState, { idGroup }) =>
    GroupState.adapter.removeOne(idGroup, setLoadingsState(state, false))
  ),
  on(GroupGeneratedActions.clearActivesGroups, (state: GroupState.IState) => ({ ...state, actives: [] })),
  on(GroupGeneratedActions.addManyActivesGroups, (state: GroupState.IState, { idGroups }) => ({
    ...state,
    actives: state.actives.concat(idGroups)
  })),
  on(GroupGeneratedActions.deleteOneActiveGroup, (state: GroupState.IState, { idGroup }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idGroup)
  })),

  on(GroupGeneratedActions.clearGroups, () => GroupState.initialState),

  on(GroupGeneratedActions.addManyCommunityGroupSuccess, (state: GroupState.IState, { idGroup, idCommunityGroups }) => {
    if (!state.entities[idGroup]) {
      return state;
    }
    const communityGroups = (state.entities[idGroup]?.communityGroups as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idGroup]: {
          ...state.entities[idGroup],
          communityGroups: communityGroups.concat(idCommunityGroups.filter(id => communityGroups.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    GroupGeneratedActions.deleteManyCommunityGroupSuccess,
    (state: GroupState.IState, { idCommunityGroups, idGroups }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGroups.reduce((entities, idGroup) => {
            if (!state.entities[idGroup]?.communityGroups) {
              return entities;
            }
            entities[idGroup] = {
              ...state.entities[idGroup],
              communityGroups: (state.entities[idGroup]?.communityGroups as number[])?.filter(
                (idCommunityGroup: number) => !idCommunityGroups.some((id: number) => id === idCommunityGroup)
              )
            } as GroupEntityState;
            return entities;
          }, {} as Dictionary<GroupEntityState>)
        }
      };
    }
  ),

  on(GroupGeneratedActions.addManyUserGroupSuccess, (state: GroupState.IState, { idGroup, idUserGroups }) => {
    if (!state.entities[idGroup]) {
      return state;
    }
    const userGroups = (state.entities[idGroup]?.userGroups as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idGroup]: {
          ...state.entities[idGroup],
          userGroups: userGroups.concat(idUserGroups.filter(id => userGroups.indexOf(id) < 0))
        }
      }
    };
  }),

  on(GroupGeneratedActions.deleteManyUserGroupSuccess, (state: GroupState.IState, { idUserGroups, idGroups }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idGroups.reduce((entities, idGroup) => {
          if (!state.entities[idGroup]?.userGroups) {
            return entities;
          }
          entities[idGroup] = {
            ...state.entities[idGroup],
            userGroups: (state.entities[idGroup]?.userGroups as number[])?.filter(
              (idUserGroup: number) => !idUserGroups.some((id: number) => id === idUserGroup)
            )
          } as GroupEntityState;
          return entities;
        }, {} as Dictionary<GroupEntityState>)
      }
    };
  }),

  on(
    GroupGeneratedActions.addManyOrganizationAzureSuccess,
    (state: GroupState.IState, { idGroup, idOrganizationAzures }) => {
      if (!state.entities[idGroup]) {
        return state;
      }
      const organizationAzures = (state.entities[idGroup]?.organizationAzures as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGroup]: {
            ...state.entities[idGroup],
            organizationAzures: organizationAzures.concat(
              idOrganizationAzures.filter(id => organizationAzures.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    GroupGeneratedActions.deleteManyOrganizationAzureSuccess,
    (state: GroupState.IState, { idOrganizationAzures, idGroups }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGroups.reduce((entities, idGroup) => {
            if (!state.entities[idGroup]?.organizationAzures) {
              return entities;
            }
            entities[idGroup] = {
              ...state.entities[idGroup],
              organizationAzures: (state.entities[idGroup]?.organizationAzures as number[])?.filter(
                (idOrganizationAzure: number) => !idOrganizationAzures.some((id: number) => id === idOrganizationAzure)
              )
            } as GroupEntityState;
            return entities;
          }, {} as Dictionary<GroupEntityState>)
        }
      };
    }
  ),

  on(GroupGeneratedActions.addManyUserSuccess, (state: GroupState.IState, { idGroup, idUsers }) => {
    if (!state.entities[idGroup]) {
      return state;
    }
    const users = (state.entities[idGroup]?.users as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idGroup]: {
          ...state.entities[idGroup],
          users: users.concat(idUsers.filter(id => users.indexOf(id) < 0))
        }
      }
    };
  }),

  on(GroupGeneratedActions.deleteManyUserSuccess, (state: GroupState.IState, { idUsers, idGroups }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idGroups.reduce((entities, idGroup) => {
          if (!state.entities[idGroup]?.users) {
            return entities;
          }
          entities[idGroup] = {
            ...state.entities[idGroup],
            users: (state.entities[idGroup]?.users as number[])?.filter(
              (idUser: number) => !idUsers.some((id: number) => id === idUser)
            )
          } as GroupEntityState;
          return entities;
        }, {} as Dictionary<GroupEntityState>)
      }
    };
  }),

  on(GroupGeneratedActions.addManyCommunitySuccess, (state: GroupState.IState, { idGroup, idCommunities }) => {
    if (!state.entities[idGroup]) {
      return state;
    }
    const communities = (state.entities[idGroup]?.communities as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idGroup]: {
          ...state.entities[idGroup],
          communities: communities.concat(idCommunities.filter(id => communities.indexOf(id) < 0))
        }
      }
    };
  }),

  on(GroupGeneratedActions.deleteManyCommunitySuccess, (state: GroupState.IState, { idCommunities, idGroups }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idGroups.reduce((entities, idGroup) => {
          if (!state.entities[idGroup]?.communities) {
            return entities;
          }
          entities[idGroup] = {
            ...state.entities[idGroup],
            communities: (state.entities[idGroup]?.communities as number[])?.filter(
              (idCommunity: number) => !idCommunities.some((id: number) => id === idCommunity)
            )
          } as GroupEntityState;
          return entities;
        }, {} as Dictionary<GroupEntityState>)
      }
    };
  }),

  on(GroupGeneratedActions.addOrganizationSuccess, (state: GroupState.IState, { idGroup, idOrganization }) => {
    if (!state.entities[idGroup]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idGroup]: {
          ...state.entities[idGroup],
          organization: idOrganization
        }
      }
    };
  }),

  on(GroupGeneratedActions.deleteManyOrganizationSuccess, (state: GroupState.IState, { idOrganizations, idGroups }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idGroups.reduce((entities, idGroup) => {
          if (!state.entities[idGroup]?.organization) {
            return entities;
          }
          entities[idGroup] = {
            ...state.entities[idGroup],
            organization: idOrganizations.some(
              (idOrganization: number) => idOrganization === state.entities[idGroup]?.organization
            )
              ? undefined
              : state.entities[idGroup]?.organization
          } as GroupEntityState;
          return entities;
        }, {} as Dictionary<GroupEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: GroupState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): GroupState.IState {
  return { ...state, isLoaded, isLoading };
}
