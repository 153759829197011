import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Element } from '@api/api-interfaces';
import * as AppState from '@wip/store/configs/reducers';
import * as TimelineElementAction from '@wip/store/actions';
import { GeneratedTimelineElementService } from './timeline-element-generated.service';

@Injectable({
  providedIn: 'root'
})
export class TimelineElementService extends GeneratedTimelineElementService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public createTimelineElement(params: { idCommunity: number; element: Partial<Element> }, getResult: boolean = false) {
    this.store$.dispatch(TimelineElementAction.createTimelineElement({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TimelineElementAction.TimelineElementGeneratedActions.normalizeManyTimelineElementsAfterUpsert,
        TimelineElementAction.TimelineElementGeneratedActions.timelineElementsFailure,
        true
      );
    }
  }
}
