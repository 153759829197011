import { RepositoryService } from '@wip/services/repository';
import { Profil } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getProfils(params?: any): Observable<Profil[]> {
    return this.repo.getData<Profil[]>('profil', params);
  }

  public getProfil(params: { idProfil: number; params?: any }): Observable<Profil> {
    return this.repo.getData<Profil>('profil/' + params.idProfil, params.params);
  }

  public addProfil(profil: Partial<Profil>): Observable<Profil> {
    return this.repo.create<Profil>('profil', profil);
  }

  public updateProfil(profil: Partial<Profil>): Observable<Profil> {
    return this.repo.update('profil', profil);
  }

  public deleteProfil(idProfil: number): Observable<number> {
    return this.repo.delete('profil/' + +idProfil);
  }
}
