import { createEntityAdapter } from '@ngrx/entity';
import { UserDeviceEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<UserDeviceEntityState>;

export const adapter = createEntityAdapter<UserDeviceEntityState>({
  selectId: o => o.idUserDevice
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const userDeviceFeatureKey = 'userDevice';
