import { createAction, props } from '@ngrx/store';

export const changeResponsibles = createAction(
  '[ElementLibrary] Change Responsibles',
  props<{
    responsibles: any[];
    idElement: number;
  }>()
);

export * from './element-library-generated.actions';
