import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { UserGroup, UserGroupEntityState } from '@api/api-interfaces';
import { UserGroupApiService } from '@wip/store/api-services';
import { UserGroupGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeUserGroup } from '@wip/store/configs/normalization';
import { UserGroupSelectors } from '@wip/store/selectors';
import { UserGroupRelationsIds } from '@wip/store/ids-interfaces';
import { UserGeneratedActions } from '@wip/store/actions';
import { GroupGeneratedActions } from '@wip/store/actions';

export function getDefaultAddUserGroupActions(userGroup: UserGroupEntityState, ids?: UserGroupRelationsIds): Action[] {
  const actions: Action[] = [UserGroupGeneratedActions.normalizeManyUserGroupsAfterUpsert({ userGroups: [userGroup] })];

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyUserGroupSuccess({
        idUser: ids.user,
        idUserGroups: [userGroup.idUserGroup]
      })
    );
    actions.push(
      UserGroupGeneratedActions.addUserSuccess({
        idUserGroup: userGroup.idUserGroup,
        idUser: ids.user
      })
    );
  }

  if (ids?.group) {
    actions.push(
      GroupGeneratedActions.addManyUserGroupSuccess({
        idGroup: ids.group,
        idUserGroups: [userGroup.idUserGroup]
      })
    );
    actions.push(
      UserGroupGeneratedActions.addGroupSuccess({
        idUserGroup: userGroup.idUserGroup,
        idGroup: ids.group
      })
    );
  }

  return actions;
}

export function getDefaultDeleteUserGroupActions(userGroup: UserGroupEntityState): Action[] {
  const actions: Action[] = [
    UserGroupGeneratedActions.deleteOneUserGroupSuccess({ idUserGroup: userGroup.idUserGroup })
  ];

  if (userGroup.user) {
    actions.push(
      UserGeneratedActions.deleteManyUserGroupSuccess({
        idUserGroups: [userGroup.idUserGroup],
        idUsers: [userGroup.user as number]
      })
    );
  }

  if (userGroup.group) {
    actions.push(
      GroupGeneratedActions.deleteManyUserGroupSuccess({
        idUserGroups: [userGroup.idUserGroup],
        idGroups: [userGroup.group as number]
      })
    );
  }

  return actions;
}

export class GeneratedUserGroupEffects {
  constructor(
    protected actions$: Actions,
    protected userGroupApiService: UserGroupApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUserGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGroupGeneratedActions.getManyUserGroups),
      switchMap(({ params }) =>
        this.userGroupApiService.getUserGroups(params).pipe(
          map((userGroups: UserGroup[]) => {
            return UserGroupGeneratedActions.normalizeManyUserGroupsAfterUpsert({ userGroups });
          }),
          catchError(error => of(UserGroupGeneratedActions.userGroupsFailure({ error })))
        )
      )
    );
  });

  getOneUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGroupGeneratedActions.getOneUserGroup),
      switchMap(idUserGroup =>
        this.userGroupApiService.getUserGroup(idUserGroup).pipe(
          map((userGroup: UserGroup) => {
            return UserGroupGeneratedActions.normalizeManyUserGroupsAfterUpsert({ userGroups: [userGroup] });
          }),
          catchError(error => of(UserGroupGeneratedActions.userGroupsFailure({ error })))
        )
      )
    );
  });

  upsertOneUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGroupGeneratedActions.upsertOneUserGroup),
      concatMap(({ userGroup, ids }: { userGroup: Partial<UserGroup>; ids?: UserGroupRelationsIds }) => {
        if (userGroup.idUserGroup) {
          return this.userGroupApiService.updateUserGroup(userGroup).pipe(
            map((userGroupReturned: UserGroup) => {
              return UserGroupGeneratedActions.normalizeManyUserGroupsAfterUpsert({ userGroups: [userGroupReturned] });
            }),
            catchError(error => of(UserGroupGeneratedActions.userGroupsFailure({ error })))
          );
        } else {
          return this.userGroupApiService.addUserGroup(userGroup).pipe(
            mergeMap((userGroupReturned: UserGroup) => getDefaultAddUserGroupActions(userGroupReturned, ids)),
            catchError(error => of(UserGroupGeneratedActions.userGroupsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneUserGroup$ = createEffect(() => {
    const selectUserGroupState$ = this.store$.select(UserGroupSelectors.selectUserGroupState);
    return this.actions$.pipe(
      ofType(UserGroupGeneratedActions.deleteOneUserGroup),
      withLatestFrom(selectUserGroupState$),
      concatMap(([{ idUserGroup }, state]) =>
        this.userGroupApiService.deleteUserGroup(idUserGroup).pipe(
          mergeMap(_success => getDefaultDeleteUserGroupActions(state.entities[idUserGroup] as UserGroupEntityState)),
          catchError(error => of(UserGroupGeneratedActions.userGroupsFailure({ error })))
        )
      )
    );
  });

  normalizeManyUserGroupsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGroupGeneratedActions.normalizeManyUserGroupsAfterUpsert),
      concatMap(({ userGroups }) => {
        const actions: Action[] = getActionsToNormalizeUserGroup(userGroups, StoreActionType.upsert);
        return [getMultiAction(actions, '[UserGroup] Normalization After Upsert Success')];
      })
    );
  });
}
