import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserRight, UserRightEntityState } from '@api/api-interfaces';
import { UserRightRelationsIds } from '@wip/store/ids-interfaces';

export const UserRightGeneratedActions = createActionGroup({
  source: 'User Right Generated',
  events: {
    'Get One User Right': props<{ idUserRight: number; params?: any }>(),
    'Get Many User Rights': props<{ params: any }>(),
    'Add Many Actives User Rights': props<{ idUserRights: number[] }>(),
    'Delete One Active User Right': props<{ idUserRight: number }>(),
    'Clear Actives User Rights': emptyProps(),
    'Upsert One User Right': props<{ userRight: Partial<UserRight>; ids?: UserRightRelationsIds }>(),
    'Upsert Many User Rights': props<{ userRights: Partial<UserRight>[]; ids?: UserRightRelationsIds }>(),
    'Upsert Many User Rights Success': props<{ userRights: UserRightEntityState[] }>(),
    'Delete One User Right': props<{ idUserRight: number }>(),
    'Delete One User Right Success': props<{ idUserRight: number }>(),
    'Normalize Many User Rights After Upsert': props<{ userRights: UserRightEntityState[] }>(),
    'User Rights Failure': props<{ error: HttpErrorResponse }>(),
    'Clear User Rights': emptyProps(),
    'Add Profil Success': props<{ idUserRight: number; idProfil: number }>(),
    'Delete Many Profil Success': props<{ idProfils: number[]; idUserRights: number[] }>(),
    'Add Organization Sub Family Success': props<{ idUserRight: number; idOrganizationSubFamily: number }>(),
    'Delete Many Organization Sub Family Success': props<{
      idOrganizationSubFamilys: number[];
      idUserRights: number[];
    }>(),
    'Add Organization User Success': props<{ idUserRight: number; idOrganizationUser: number }>(),
    'Delete Many Organization User Success': props<{ idOrganizationUsers: number[]; idUserRights: number[] }>()
  }
});
