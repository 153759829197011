import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationCaracteristicColumn, OrganizationCaracteristicColumnEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicValue, OrganizationCaracteristicValueEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationCaracteristicColumnState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationCaracteristicColumnRelations: string[] = [
  'organizationCaracteristicValues',
  'organizationCaracteristics'
];

export const { selectEntities, selectAll } = OrganizationCaracteristicColumnState.adapter.getSelectors();

export const selectOrganizationCaracteristicColumnState =
  createFeatureSelector<OrganizationCaracteristicColumnState.IState>(
    OrganizationCaracteristicColumnState.organizationCaracteristicColumnFeatureKey
  );

export const selectIsLoadedOrganizationCaracteristicColumn = createSelector(
  selectOrganizationCaracteristicColumnState,
  (state: OrganizationCaracteristicColumnState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationCaracteristicColumn = createSelector(
  selectOrganizationCaracteristicColumnState,
  (state: OrganizationCaracteristicColumnState.IState) => state.isLoading
);

export const selectIsReadyOrganizationCaracteristicColumn = createSelector(
  selectOrganizationCaracteristicColumnState,
  (state: OrganizationCaracteristicColumnState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationCaracteristicColumn = createSelector(
  selectOrganizationCaracteristicColumnState,
  (state: OrganizationCaracteristicColumnState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationCaracteristicColumnsEntities = createSelector(
  selectOrganizationCaracteristicColumnState,
  selectEntities
);

export const selectOrganizationCaracteristicColumnsArray = createSelector(
  selectOrganizationCaracteristicColumnState,
  selectAll
);

export const selectIdOrganizationCaracteristicColumnsActive = createSelector(
  selectOrganizationCaracteristicColumnState,
  (state: OrganizationCaracteristicColumnState.IState) => state.actives
);

const organizationCaracteristicColumnsInObject = (
  organizationCaracteristicColumns: Dictionary<OrganizationCaracteristicColumnEntityState>
) => ({ organizationCaracteristicColumns });

const selectOrganizationCaracteristicColumnsEntitiesDictionary = createSelector(
  selectOrganizationCaracteristicColumnsEntities,
  organizationCaracteristicColumnsInObject
);

const selectAllOrganizationCaracteristicColumnsObject = createSelector(
  selectOrganizationCaracteristicColumnsEntities,
  organizationCaracteristicColumns => {
    return hydrateAll({ organizationCaracteristicColumns });
  }
);

const selectOneOrganizationCaracteristicColumnDictionary = (idOrganizationCaracteristicColumn: number) =>
  createSelector(selectOrganizationCaracteristicColumnsEntities, organizationCaracteristicColumns => ({
    organizationCaracteristicColumns: {
      [idOrganizationCaracteristicColumn]: organizationCaracteristicColumns[idOrganizationCaracteristicColumn]
    }
  }));

const selectOneOrganizationCaracteristicColumnDictionaryWithoutChild = (idOrganizationCaracteristicColumn: number) =>
  createSelector(selectOrganizationCaracteristicColumnsEntities, organizationCaracteristicColumns => ({
    organizationCaracteristicColumn: organizationCaracteristicColumns[idOrganizationCaracteristicColumn]
  }));

const selectActiveOrganizationCaracteristicColumnsEntities = createSelector(
  selectIdOrganizationCaracteristicColumnsActive,
  selectOrganizationCaracteristicColumnsEntities,
  (actives: number[], organizationCaracteristicColumns: Dictionary<OrganizationCaracteristicColumnEntityState>) =>
    getOrganizationCaracteristicColumnsFromActives(actives, organizationCaracteristicColumns)
);

function getOrganizationCaracteristicColumnsFromActives(
  actives: number[],
  organizationCaracteristicColumns: Dictionary<OrganizationCaracteristicColumnEntityState>
): Dictionary<OrganizationCaracteristicColumnEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationCaracteristicColumns[idActive]) {
      acc[idActive] = organizationCaracteristicColumns[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationCaracteristicColumnEntityState>);
}

const selectAllOrganizationCaracteristicColumnsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationCaracteristicColumns(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationCaracteristicColumn>(
      schema,
      selectAllOrganizationCaracteristicColumnsSelectors,
      selectOrganizationCaracteristicColumnsEntitiesDictionary,
      getRelationSelectors,
      organizationCaracteristicColumnRelations,
      hydrateAll,
      'organizationCaracteristicColumn'
    );
  } else {
    return selectAllOrganizationCaracteristicColumnsObject;
  }
}

export function selectAllOrganizationCaracteristicColumnsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationCaracteristicColumns'
): Selector {
  return createSelector(selectAllOrganizationCaracteristicColumns(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationCaracteristicColumnEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationCaracteristicColumns.length; i++) {
      res[customKey][result.organizationCaracteristicColumns[i].idOrganizationCaracteristicColumn] =
        result.organizationCaracteristicColumns[i];
    }
    return res;
  });
}

export function selectOneOrganizationCaracteristicColumn(
  schema: SelectSchema = {},
  idOrganizationCaracteristicColumn: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneOrganizationCaracteristicColumnDictionary(idOrganizationCaracteristicColumn)
    ];
    selectors.push(
      ...getRelationSelectors(schema, organizationCaracteristicColumnRelations, 'organizationCaracteristicColumn')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationCaracteristicColumnDictionaryWithoutChild(idOrganizationCaracteristicColumn);
  }
}

export function selectActiveOrganizationCaracteristicColumns(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationCaracteristicColumnsEntities, organizationCaracteristicColumns => ({
      organizationCaracteristicColumns
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationCaracteristicColumnRelations, 'organizationCaracteristicColumn')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationCaracteristicColumns: Dictionary<OrganizationCaracteristicColumnEntityState>;
  organizationCaracteristics?: Dictionary<OrganizationCaracteristicEntityState>;
  organizationCaracteristicValues?: Dictionary<OrganizationCaracteristicValueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationCaracteristicColumns: (OrganizationCaracteristicColumn | null)[];
} {
  const { organizationCaracteristicColumns, organizationCaracteristics, organizationCaracteristicValues } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationCaracteristicColumns: Object.keys(organizationCaracteristicColumns).map(
      idOrganizationCaracteristicColumn =>
        hydrate(
          organizationCaracteristicColumns[
            idOrganizationCaracteristicColumn
          ] as OrganizationCaracteristicColumnEntityState,
          organizationCaracteristics,
          organizationCaracteristicValues
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationCaracteristicColumn: OrganizationCaracteristicColumnEntityState | null;
} {
  const { organizationCaracteristicColumns, organizationCaracteristics, organizationCaracteristicValues } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationCaracteristicColumn = Object.values(organizationCaracteristicColumns)[0];
  return {
    organizationCaracteristicColumn: hydrate(
      organizationCaracteristicColumn as OrganizationCaracteristicColumnEntityState,
      organizationCaracteristics,
      organizationCaracteristicValues
    )
  };
}

function hydrate(
  organizationCaracteristicColumn: OrganizationCaracteristicColumnEntityState,
  organizationCaracteristicEntities?: Dictionary<OrganizationCaracteristicEntityState>,
  organizationCaracteristicValueEntities?: Dictionary<OrganizationCaracteristicValueEntityState>
): OrganizationCaracteristicColumn | null {
  if (!organizationCaracteristicColumn) {
    return null;
  }

  const organizationCaracteristicColumnHydrated: OrganizationCaracteristicColumnEntityState = {
    ...organizationCaracteristicColumn
  };
  if (organizationCaracteristicEntities) {
    organizationCaracteristicColumnHydrated.organizationCaracteristic = organizationCaracteristicEntities[
      organizationCaracteristicColumn.organizationCaracteristic as number
    ] as OrganizationCaracteristic;
  } else {
    delete organizationCaracteristicColumnHydrated.organizationCaracteristic;
  }

  if (organizationCaracteristicValueEntities) {
    organizationCaracteristicColumnHydrated.organizationCaracteristicValues = (
      (organizationCaracteristicColumnHydrated.organizationCaracteristicValues as number[]) || []
    ).map(id => organizationCaracteristicValueEntities[id]) as OrganizationCaracteristicValue[];
  } else {
    delete organizationCaracteristicColumnHydrated.organizationCaracteristicValues;
  }

  return organizationCaracteristicColumnHydrated as OrganizationCaracteristicColumn;
}
