import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityRisk, CommunityRiskEntityState } from '@api/api-interfaces';
import { CommunityRiskApiService } from '@wip/store/api-services';
import { CommunityRiskGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityRisk } from '@wip/store/configs/normalization';
import { CommunityRiskSelectors } from '@wip/store/selectors';
import { CommunityRiskRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationRiskGeneratedActions } from '@wip/store/actions';
import { CommunityGeneratedActions } from '@wip/store/actions';

export function getDefaultAddCommunityRiskActions(
  communityRisk: CommunityRiskEntityState,
  ids?: CommunityRiskRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityRiskGeneratedActions.normalizeManyCommunityRisksAfterUpsert({ communityRisks: [communityRisk] })
  ];

  if (ids?.organizationRisk) {
    actions.push(
      OrganizationRiskGeneratedActions.addManyCommunityRiskSuccess({
        idOrganizationRisk: ids.organizationRisk,
        idCommunityRisks: [communityRisk.idCommunityRisk]
      })
    );
    actions.push(
      CommunityRiskGeneratedActions.addOrganizationRiskSuccess({
        idCommunityRisk: communityRisk.idCommunityRisk,
        idOrganizationRisk: ids.organizationRisk
      })
    );
  }

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityRiskSuccess({
        idCommunity: ids.community,
        idCommunityRisks: [communityRisk.idCommunityRisk]
      })
    );
    actions.push(
      CommunityRiskGeneratedActions.addCommunitySuccess({
        idCommunityRisk: communityRisk.idCommunityRisk,
        idCommunity: ids.community
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCommunityRiskActions(communityRisk: CommunityRiskEntityState): Action[] {
  const actions: Action[] = [
    CommunityRiskGeneratedActions.deleteOneCommunityRiskSuccess({ idCommunityRisk: communityRisk.idCommunityRisk })
  ];

  if (communityRisk.organizationRisk) {
    actions.push(
      OrganizationRiskGeneratedActions.deleteManyCommunityRiskSuccess({
        idCommunityRisks: [communityRisk.idCommunityRisk],
        idOrganizationRisks: [communityRisk.organizationRisk as number]
      })
    );
  }

  if (communityRisk.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityRiskSuccess({
        idCommunityRisks: [communityRisk.idCommunityRisk],
        idCommunities: [communityRisk.community as number]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityRiskEffects {
  constructor(
    protected actions$: Actions,
    protected communityRiskApiService: CommunityRiskApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityRisks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityRiskGeneratedActions.getManyCommunityRisks),
      switchMap(({ params }) =>
        this.communityRiskApiService.getCommunityRisks(params).pipe(
          map((communityRisks: CommunityRisk[]) => {
            return CommunityRiskGeneratedActions.normalizeManyCommunityRisksAfterUpsert({ communityRisks });
          }),
          catchError(error => of(CommunityRiskGeneratedActions.communityRisksFailure({ error })))
        )
      )
    );
  });

  getOneCommunityRisk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityRiskGeneratedActions.getOneCommunityRisk),
      switchMap(idCommunityRisk =>
        this.communityRiskApiService.getCommunityRisk(idCommunityRisk).pipe(
          map((communityRisk: CommunityRisk) => {
            return CommunityRiskGeneratedActions.normalizeManyCommunityRisksAfterUpsert({
              communityRisks: [communityRisk]
            });
          }),
          catchError(error => of(CommunityRiskGeneratedActions.communityRisksFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityRisk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityRiskGeneratedActions.upsertOneCommunityRisk),
      concatMap(
        ({ communityRisk, ids }: { communityRisk: Partial<CommunityRisk>; ids?: CommunityRiskRelationsIds }) => {
          if (communityRisk.idCommunityRisk) {
            return this.communityRiskApiService.updateCommunityRisk(communityRisk).pipe(
              map((communityRiskReturned: CommunityRisk) => {
                return CommunityRiskGeneratedActions.normalizeManyCommunityRisksAfterUpsert({
                  communityRisks: [communityRiskReturned]
                });
              }),
              catchError(error => of(CommunityRiskGeneratedActions.communityRisksFailure({ error })))
            );
          } else {
            return this.communityRiskApiService.addCommunityRisk(communityRisk).pipe(
              mergeMap((communityRiskReturned: CommunityRisk) =>
                getDefaultAddCommunityRiskActions(communityRiskReturned, ids)
              ),
              catchError(error => of(CommunityRiskGeneratedActions.communityRisksFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCommunityRisk$ = createEffect(() => {
    const selectCommunityRiskState$ = this.store$.select(CommunityRiskSelectors.selectCommunityRiskState);
    return this.actions$.pipe(
      ofType(CommunityRiskGeneratedActions.deleteOneCommunityRisk),
      withLatestFrom(selectCommunityRiskState$),
      concatMap(([{ idCommunityRisk }, state]) =>
        this.communityRiskApiService.deleteCommunityRisk(idCommunityRisk).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityRiskActions(state.entities[idCommunityRisk] as CommunityRiskEntityState)
          ),
          catchError(error => of(CommunityRiskGeneratedActions.communityRisksFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityRisksAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityRiskGeneratedActions.normalizeManyCommunityRisksAfterUpsert),
      concatMap(({ communityRisks }) => {
        const actions: Action[] = getActionsToNormalizeCommunityRisk(communityRisks, StoreActionType.upsert);
        return [getMultiAction(actions, '[CommunityRisk] Normalization After Upsert Success')];
      })
    );
  });
}
