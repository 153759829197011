<div mat-dialog-title>
  <h1>Sélectionner une date</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
</div>
<form [formGroup]="dateInputGroup">
  <mat-dialog-content class="flex justify-center items-center" style="height: 370px">
    <mat-calendar
      ngDefaultControl
      [selected]="dateInputGroup.get('date').value"
      [maxDate]="max"
      [minDate]="min"
      (selectedChange)="dateInputGroup.setValue({ date: $event })"
      style="width: 296px; height: 358px"
    >
    </mat-calendar>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button type="button" (click)="removeDate()">Aucune</button>
    <button mat-raised-button (click)="validate()" color="primary" type="submit" tabindex="0">Ok</button>
  </mat-dialog-actions>
</form>
