import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ElementLibrary, ElementLibraryEntityState } from '@api/api-interfaces';
import { ElementLibraryRelationsIds } from '@wip/store/ids-interfaces';

export const ElementLibraryGeneratedActions = createActionGroup({
  source: 'Element Library Generated',
  events: {
    'Get One Element Library': props<{ idElementLibrary: number; params?: any }>(),
    'Get Many Element Libraries': props<{ params: any }>(),
    'Add Many Actives Element Libraries': props<{ idElementLibraries: number[] }>(),
    'Delete One Active Element Library': props<{ idElementLibrary: number }>(),
    'Clear Actives Element Libraries': emptyProps(),
    'Upsert One Element Library': props<{
      elementLibrary: Partial<ElementLibrary>;
      ids?: ElementLibraryRelationsIds;
    }>(),
    'Upsert Many Element Libraries': props<{
      elementLibraries: Partial<ElementLibrary>[];
      ids?: ElementLibraryRelationsIds;
    }>(),
    'Upsert Many Element Libraries Success': props<{ elementLibraries: ElementLibraryEntityState[] }>(),
    'Delete One Element Library': props<{ idElementLibrary: number }>(),
    'Delete One Element Library Success': props<{ idElementLibrary: number }>(),
    'Normalize Many Element Libraries After Upsert': props<{ elementLibraries: ElementLibraryEntityState[] }>(),
    'Element Libraries Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Element Libraries': emptyProps(),
    'Add Element Success': props<{ idElementLibrary: number; idElement: number }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idElementLibraries: number[] }>(),
    'Add User Success': props<{ idElementLibrary: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idElementLibraries: number[] }>(),
    'Add Community User Profil Success': props<{ idElementLibrary: number; idCommunityUserProfil: number }>(),
    'Delete Many Community User Profil Success': props<{
      idCommunityUserProfils: number[];
      idElementLibraries: number[];
    }>()
  }
});
