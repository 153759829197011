import { GoogleMapsService } from '@_app/google-maps/googleMaps.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import {
  Community,
  Organization,
  OrganizationCaracteristic,
  OrganizationMilestone,
  OrganizationMilestoneFamily,
  OrganizationSubFamily,
  OrganizationUser,
  UserBoardState
} from '@api/api-interfaces';
import {
  BoardTypeEnum,
  CaracteristiqueTypeEnum,
  CommunityStatusActifEnum,
  CommunityTypeStructureEnum,
  OrganizationUserRoleEnum
} from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isFirstOrganizationRoleOverSecondRole } from '@utils';
import {
  CommunityModel,
  CommunitySubFamilyModel,
  CommunityUserProfilModel,
  OrganizationCaracteristicModel,
  OrganizationFamilyModel,
  OrganizationMilestoneFamilyModel,
  OrganizationMilestoneModel,
  OrganizationSubFamilyModel,
  OrganizationUserProfilModel,
  UserBoardStateModel,
  UserModel
} from '@wip/store/selectors-model';
import {
  CommunityService,
  OrganizationCaracteristicService,
  OrganizationMilestoneFamilyService,
  OrganizationMilestoneService,
  OrganizationService,
  OrganizationSubFamilyService,
  OrganizationUserService,
  UserBoardStateService
} from '@wip/store/services';
import { Observable, Subject, combineLatest, map, tap } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'wip-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public dashboardModules = ['Portefeuille projets', 'Dates clés', 'Planning', 'Analyse', 'Carte'];
  public dashboardRoutes = ['kanban', 'synthesis', 'grid', 'charts', 'map'];
  public moduleIndex: number;
  public organization$: Observable<Organization>;
  public organizationUser$: Observable<OrganizationUser>;
  public communities$: Observable<Community[]>;
  public configurationBoardStates$: Observable<UserBoardState[]>;
  public isUserAdmin: boolean = false;
  public editMode: boolean = false;
  public create: boolean = false;
  public save: boolean = false;
  public handle: boolean = false;
  public updateButton: boolean = false;
  public listHashtags: OrganizationSubFamily[];
  public orgaMilestoneFamilies$: Observable<OrganizationMilestoneFamily[]>;
  public projectCharacteristics: OrganizationCaracteristic[];
  public projectMilestones: OrganizationMilestone[];
  public currentOrgaUser: OrganizationUser;
  public chartInputsForm: FormGroup;

  public orgaFamilySubject$ = new Subject<number>();

  constructor(
    public router: Router,
    private newOrganizationUserService: OrganizationUserService,
    private newOrganizationSubFamilyService: OrganizationSubFamilyService,
    private newOrganizationService: OrganizationService,
    private boardStateService: UserBoardStateService,
    private googleMapsService: GoogleMapsService,
    private newCommunityService: CommunityService,
    private newOrganizationMilestoneFamilyService: OrganizationMilestoneFamilyService,
    private organizationMilestoneService: OrganizationMilestoneService,
    private organizationCaracteristicService: OrganizationCaracteristicService
  ) {}

  ngOnInit(): void {
    this.chartInputsForm = this.newOrganizationMilestoneFamilyService.chartInputsForm;
    this.setModuleIndex();
    this.googleMapsService.loadApi();

    this.communities$ = combineLatest([
      this.newCommunityService.selectAllCommunities({
        include: [
          { model: CommunityUserProfilModel, include: [UserModel] },
          { model: CommunitySubFamilyModel, include: [OrganizationSubFamilyModel] }
        ]
      }),
      this.newOrganizationSubFamilyService.selectAllOrganizationSubFamilys()
    ]).pipe(
      untilDestroyed(this),
      tap(([comms, subFamilies]) => {
        this.listHashtags = this.filterSubfamilies(comms, subFamilies);
      }),
      map(([comms, _]) =>
        comms.filter(
          c =>
            c.statusActif === CommunityStatusActifEnum.actif &&
            [CommunityTypeStructureEnum.project, CommunityTypeStructureEnum.development].includes(c.typeStructure)
        )
      )
    );

    this.organizationCaracteristicService
      .selectAllOrganizationCaracteristics()
      .pipe(
        untilDestroyed(this),
        map(orgaCaracs =>
          orgaCaracs.filter(
            orgCarac =>
              orgCarac.type === CaracteristiqueTypeEnum.decimal || orgCarac.type === CaracteristiqueTypeEnum.integer
          )
        ),
        tap(orgCaracs => {
          this.projectCharacteristics = orgCaracs;
          // this.isProjectCharacteristicsSelectOpened.next(false);
        })
      )
      .subscribe();

    this.orgaMilestoneFamilies$ = this.newOrganizationMilestoneFamilyService
      .selectAllOrganizationMilestoneFamilys()
      .pipe(
        untilDestroyed(this)
        // map(omf => omf.filter(el => el.idOrganizationFamily === this.idOrganizationFamily))
      );

    this.organization$ = this.newOrganizationService
      .selectAllActiveOrganizations({
        include: [
          {
            model: OrganizationFamilyModel,
            include: [
              OrganizationSubFamilyModel,
              OrganizationMilestoneModel,
              { model: OrganizationMilestoneFamilyModel, include: [OrganizationMilestoneModel] }
            ]
          },
          OrganizationMilestoneModel,
          { model: OrganizationMilestoneFamilyModel },
          OrganizationCaracteristicModel,
          OrganizationUserProfilModel,
          { model: CommunityModel, include: [{ model: CommunityUserProfilModel, include: [UserModel] }] }
        ]
      })
      .pipe(
        untilDestroyed(this),
        map(org => org[0])
      );

    this.organizationUser$ = this.newOrganizationUserService
      .selectAllActiveOrganizationUsers({ include: [UserBoardStateModel, UserModel] })
      .pipe(
        untilDestroyed(this),
        map(ou => ou[0]),
        tap(orgaUser => {
          if (
            orgaUser &&
            (isFirstOrganizationRoleOverSecondRole(orgaUser.role, OrganizationUserRoleEnum.userPlus) ||
              orgaUser.user.isRoot)
          ) {
            this.currentOrgaUser = orgaUser;
            this.isUserAdmin = true;
          }
          if (orgaUser && orgaUser.userBoardStates?.length) {
            const general = orgaUser.userBoardStates.find(bs => bs.type === BoardTypeEnum.project);
            if (general) {
              this.orgaFamilySubject$.next(general.idOrganizationFamily);
            }
          }
        })
      );
    this.newOrganizationUserService.editMode$.next(this.editMode);
    this.newOrganizationUserService.setUpdateButton$.subscribe({
      next: updateButton => (this.updateButton = updateButton)
    });

    this.configurationBoardStates$ = this.boardStateService.selectAllUserBoardStates();

    this.router.events.pipe(untilDestroyed(this)).subscribe((_event: NavigationEnd) => {
      // this.setModuleIndex();
    });
    this.getAllMilestones();
  }

  onModuleChange(dashboardModule: any) {
    const modulePath = this.dashboardRoutes[dashboardModule.index];
    const path: string[] = ['app/dashboard', modulePath];
    this.router.navigate(path);
  }

  public emitBoardState($event) {
    if ($event === 'reload') {
      this.boardStateService.getManyUserBoardStates({ idOrganizationUser: this.currentOrgaUser.idOrganizationUser });
    } else {
      this.boardStateService.upsertOneUserBoardState($event);
    }
  }

  public onActivateEdition() {
    this.editMode = !this.editMode;
    this.newOrganizationUserService.editMode$.next(this.editMode);
  }

  public handleConfiguration(event: string) {
    if (event === 'create') {
      this.create = !this.create;
      this.newOrganizationUserService.createConfiguration$.next(this.create);
    } else if (event === 'save') {
      this.save = !this.save;
      this.newOrganizationUserService.saveConfiguration$.next(this.save);
    } else if (event === 'handle') {
      this.handle = !this.handle;
      this.newOrganizationUserService.handleConfiguration$.next(this.handle);
    }
  }

  private setModuleIndex() {
    for (let i = 0; i < this.dashboardRoutes.length; i++) {
      if (this.router.url.includes(this.dashboardRoutes[i])) {
        this.moduleIndex = i;
        break;
      }
    }
  }

  private filterSubfamilies(communities: Community[], subFamilies: OrganizationSubFamily[]) {
    return subFamilies?.filter(sf =>
      communities?.some(
        c =>
          c.communitySubFamilys?.length &&
          c.communitySubFamilys?.some(csf => csf.idOrganizationSubFamily === sf.idOrganizationSubFamily)
      )
    );
  }

  private getAllMilestones(): void {
    combineLatest([
      this.orgaFamilySubject$,
      this.newOrganizationMilestoneFamilyService.selectAllOrganizationMilestoneFamilys({
        include: [OrganizationMilestoneModel]
      }),
      this.organizationMilestoneService.selectAllOrganizationMilestones()
    ])
      .pipe(
        untilDestroyed(this),
        tap(
          ([idOrganizationFamily, _orgaMilestoneFamilies, projectMilestones]: [
            number,
            OrganizationMilestoneFamily[],
            OrganizationMilestone[]
          ]) => {
            projectMilestones = projectMilestones
              .filter(pm => pm.idOrganizationFamily === idOrganizationFamily)
              .sort((a, b) => a.ordre - b.ordre);
            this.projectMilestones = projectMilestones;
            this.chartInputsForm.get('projectMilestone').setValue(this.projectMilestones);
          }
        )
      )
      .subscribe();
  }
}
