import { createAction, props } from '@ngrx/store';
import { Community } from '@api/api-interfaces';
import { CommunityTypeStructureEnum } from '@enums';

export * from './community-generated.actions';

export const getProjectsForSidelist = createAction(
  '[Community] Get Sidelist Projects',
  props<{ idOrganization: number; idUser: number; operationType: CommunityTypeStructureEnum }>()
);

export const getCommunitiesForSidelist = createAction(
  '[Community] Get Sidelist Communities',
  props<{ idOrganization: number; idUser: number }>()
);

export const getConversationsForSidelist = createAction(
  '[Community] Get Sidelist Conversations',
  props<{ idUser: number }>()
);

export const duplicate = createAction(
  '[Community] Duplicate Community',
  props<{ idCommunitySrc: number; idCommunityDst: number }>()
);

export const createCommunity = createAction(
  '[Community] Create Community',
  props<{ community: Partial<Community>; modules: number[] }>()
);

export const createDirectMessage = createAction(
  '[Community] Create Direct Message',
  props<{ community: Partial<Community>; listeUsersAdd: number[] }>()
);

export const getElementsForSynthesis = createAction(
  '[Community] Get Elements For Synthesis',
  props<{ idOrganization: number; idOrganizationFamily?: number }>()
);

export const getPlanningInfo = createAction('[Community] Get Planning Info', props<{ idOrganization: number }>());

export const getProjectTemplates = createAction(
  '[Community] Get Project Templates',
  props<{ idOrganization: number }>()
);

export const getPersonalSpace = createAction('[Community] Get Personal Space', props<{ idUser: number }>());

export const updateManyCommunities = createAction(
  '[Community] Update Many Communities',
  props<{
    communityIds: number[];
    key: string;
    value: any;
  }>()
);

export const bulkUpdateGeo = createAction(
  '[Community] Bulk Update Geo',
  props<{
    idOrganization: number;
    updateOnlyInseeCode: boolean;
  }>()
);

export const verifyProject = createAction('[Community] Verify Project', props<{ idCommunity: number }>());
