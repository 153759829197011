import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityMilestoneGeneratedActions } from '@wip/store/actions';
import { CommunityMilestoneState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityMilestoneEntityState } from '@api/api-interfaces';

export const communityMilestoneReducersGeneratedFunctions: ReducerTypes<
  CommunityMilestoneState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunityMilestoneGeneratedActions.getOneCommunityMilestone, (state: CommunityMilestoneState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityMilestoneGeneratedActions.getManyCommunityMilestones, (state: CommunityMilestoneState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityMilestoneGeneratedActions.upsertOneCommunityMilestone, (state: CommunityMilestoneState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    CommunityMilestoneGeneratedActions.upsertManyCommunityMilestonesSuccess,
    (state: CommunityMilestoneState.IState, { communityMilestones }) =>
      CommunityMilestoneState.adapter.upsertMany(communityMilestones, setLoadingsState(state, false))
  ),
  on(CommunityMilestoneGeneratedActions.deleteOneCommunityMilestone, (state: CommunityMilestoneState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    CommunityMilestoneGeneratedActions.deleteOneCommunityMilestoneSuccess,
    (state: CommunityMilestoneState.IState, { idCommunityMilestone }) =>
      CommunityMilestoneState.adapter.removeOne(idCommunityMilestone, setLoadingsState(state, false))
  ),
  on(CommunityMilestoneGeneratedActions.clearActivesCommunityMilestones, (state: CommunityMilestoneState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    CommunityMilestoneGeneratedActions.addManyActivesCommunityMilestones,
    (state: CommunityMilestoneState.IState, { idCommunityMilestones }) => ({
      ...state,
      actives: state.actives.concat(idCommunityMilestones)
    })
  ),
  on(
    CommunityMilestoneGeneratedActions.deleteOneActiveCommunityMilestone,
    (state: CommunityMilestoneState.IState, { idCommunityMilestone }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityMilestone)
    })
  ),

  on(CommunityMilestoneGeneratedActions.clearCommunityMilestones, () => CommunityMilestoneState.initialState),

  on(
    CommunityMilestoneGeneratedActions.addOrganizationMilestoneSuccess,
    (state: CommunityMilestoneState.IState, { idCommunityMilestone, idOrganizationMilestone }) => {
      if (!state.entities[idCommunityMilestone]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityMilestone]: {
            ...state.entities[idCommunityMilestone],
            organizationMilestone: idOrganizationMilestone
          }
        }
      };
    }
  ),

  on(
    CommunityMilestoneGeneratedActions.deleteManyOrganizationMilestoneSuccess,
    (state: CommunityMilestoneState.IState, { idOrganizationMilestones, idCommunityMilestones }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityMilestones.reduce((entities, idCommunityMilestone) => {
            if (!state.entities[idCommunityMilestone]?.organizationMilestone) {
              return entities;
            }
            entities[idCommunityMilestone] = {
              ...state.entities[idCommunityMilestone],
              organizationMilestone: idOrganizationMilestones.some(
                (idOrganizationMilestone: number) =>
                  idOrganizationMilestone === state.entities[idCommunityMilestone]?.organizationMilestone
              )
                ? undefined
                : state.entities[idCommunityMilestone]?.organizationMilestone
            } as CommunityMilestoneEntityState;
            return entities;
          }, {} as Dictionary<CommunityMilestoneEntityState>)
        }
      };
    }
  ),

  on(
    CommunityMilestoneGeneratedActions.addCommunitySuccess,
    (state: CommunityMilestoneState.IState, { idCommunityMilestone, idCommunity }) => {
      if (!state.entities[idCommunityMilestone]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityMilestone]: {
            ...state.entities[idCommunityMilestone],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityMilestoneGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityMilestoneState.IState, { idCommunities, idCommunityMilestones }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityMilestones.reduce((entities, idCommunityMilestone) => {
            if (!state.entities[idCommunityMilestone]?.community) {
              return entities;
            }
            entities[idCommunityMilestone] = {
              ...state.entities[idCommunityMilestone],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityMilestone]?.community
              )
                ? undefined
                : state.entities[idCommunityMilestone]?.community
            } as CommunityMilestoneEntityState;
            return entities;
          }, {} as Dictionary<CommunityMilestoneEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityMilestoneState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityMilestoneState.IState {
  return { ...state, isLoaded, isLoading };
}
