import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationAzureApiService } from '@wip/store/api-services';
import { GeneratedOrganizationAzureEffects } from './organization-azure-generated.effects';

@Injectable()
export class OrganizationAzureEffects extends GeneratedOrganizationAzureEffects {
  constructor(actions$: Actions, organizationAzureApiService: OrganizationAzureApiService, store$: Store<AppState>) {
    super(actions$, organizationAzureApiService, store$);
  }
}
