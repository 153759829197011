import { RepositoryService } from '@wip/services/repository';
import { MeetingElement } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedMeetingElementApiService {
  constructor(protected repo: RepositoryService) {}

  public getMeetingElements(params?: any): Observable<MeetingElement[]> {
    return this.repo.getData<MeetingElement[]>('meeting-element', params);
  }

  public getMeetingElement(params: { idMeetingElement: number; params?: any }): Observable<MeetingElement> {
    return this.repo.getData<MeetingElement>('meeting-element/' + params.idMeetingElement, params.params);
  }

  public addMeetingElement(meetingElement: Partial<MeetingElement>): Observable<MeetingElement> {
    return this.repo.create<MeetingElement>('meeting-element', meetingElement);
  }

  public updateMeetingElement(meetingElement: Partial<MeetingElement>): Observable<MeetingElement> {
    return this.repo.update('meeting-element', meetingElement);
  }

  public deleteMeetingElement(idMeetingElement: number): Observable<number> {
    return this.repo.delete('meeting-element/' + +idMeetingElement);
  }
}
