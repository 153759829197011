import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserDevice, UserDeviceEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { UserDeviceState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const userDeviceRelations: string[] = ['users'];

export const { selectEntities, selectAll } = UserDeviceState.adapter.getSelectors();

export const selectUserDeviceState = createFeatureSelector<UserDeviceState.IState>(
  UserDeviceState.userDeviceFeatureKey
);

export const selectIsLoadedUserDevice = createSelector(
  selectUserDeviceState,
  (state: UserDeviceState.IState) => state.isLoaded
);

export const selectIsLoadingUserDevice = createSelector(
  selectUserDeviceState,
  (state: UserDeviceState.IState) => state.isLoading
);

export const selectIsReadyUserDevice = createSelector(
  selectUserDeviceState,
  (state: UserDeviceState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUserDevice = createSelector(
  selectUserDeviceState,
  (state: UserDeviceState.IState) => state.isLoaded && !state.isLoading
);

export const selectUserDevicesEntities = createSelector(selectUserDeviceState, selectEntities);

export const selectUserDevicesArray = createSelector(selectUserDeviceState, selectAll);

export const selectIdUserDevicesActive = createSelector(
  selectUserDeviceState,
  (state: UserDeviceState.IState) => state.actives
);

const userDevicesInObject = (userDevices: Dictionary<UserDeviceEntityState>) => ({ userDevices });

const selectUserDevicesEntitiesDictionary = createSelector(selectUserDevicesEntities, userDevicesInObject);

const selectAllUserDevicesObject = createSelector(selectUserDevicesEntities, userDevices => {
  return hydrateAll({ userDevices });
});

const selectOneUserDeviceDictionary = (idUserDevice: number) =>
  createSelector(selectUserDevicesEntities, userDevices => ({
    userDevices: { [idUserDevice]: userDevices[idUserDevice] }
  }));

const selectOneUserDeviceDictionaryWithoutChild = (idUserDevice: number) =>
  createSelector(selectUserDevicesEntities, userDevices => ({
    userDevice: userDevices[idUserDevice]
  }));

const selectActiveUserDevicesEntities = createSelector(
  selectIdUserDevicesActive,
  selectUserDevicesEntities,
  (actives: number[], userDevices: Dictionary<UserDeviceEntityState>) => getUserDevicesFromActives(actives, userDevices)
);

function getUserDevicesFromActives(
  actives: number[],
  userDevices: Dictionary<UserDeviceEntityState>
): Dictionary<UserDeviceEntityState> {
  return actives.reduce((acc, idActive) => {
    if (userDevices[idActive]) {
      acc[idActive] = userDevices[idActive];
    }
    return acc;
  }, {} as Dictionary<UserDeviceEntityState>);
}

const selectAllUserDevicesSelectors: Dictionary<Selector> = {};
export function selectAllUserDevices(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UserDevice>(
      schema,
      selectAllUserDevicesSelectors,
      selectUserDevicesEntitiesDictionary,
      getRelationSelectors,
      userDeviceRelations,
      hydrateAll,
      'userDevice'
    );
  } else {
    return selectAllUserDevicesObject;
  }
}

export function selectAllUserDevicesDictionary(schema: SelectSchema = {}, customKey: string = 'userDevices'): Selector {
  return createSelector(selectAllUserDevices(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserDeviceEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.userDevices.length; i++) {
      res[customKey][result.userDevices[i].idUserDevice] = result.userDevices[i];
    }
    return res;
  });
}

export function selectOneUserDevice(schema: SelectSchema = {}, idUserDevice: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneUserDeviceDictionary(idUserDevice)];
    selectors.push(...getRelationSelectors(schema, userDeviceRelations, 'userDevice'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserDeviceDictionaryWithoutChild(idUserDevice);
  }
}

export function selectActiveUserDevices(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveUserDevicesEntities, userDevices => ({
      userDevices
    }))
  ];
  selectors.push(...getRelationSelectors(schema, userDeviceRelations, 'userDevice'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  userDevices: Dictionary<UserDeviceEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { userDevices: (UserDevice | null)[] } {
  const { userDevices, users } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    userDevices: Object.keys(userDevices).map(idUserDevice =>
      hydrate(userDevices[idUserDevice] as UserDeviceEntityState, users)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { userDevice: UserDeviceEntityState | null } {
  const { userDevices, users } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const userDevice = Object.values(userDevices)[0];
  return {
    userDevice: hydrate(userDevice as UserDeviceEntityState, users)
  };
}

function hydrate(userDevice: UserDeviceEntityState, userEntities?: Dictionary<UserEntityState>): UserDevice | null {
  if (!userDevice) {
    return null;
  }

  const userDeviceHydrated: UserDeviceEntityState = { ...userDevice };
  if (userEntities) {
    userDeviceHydrated.user = userEntities[userDevice.user as number] as User;
  } else {
    delete userDeviceHydrated.user;
  }

  return userDeviceHydrated as UserDevice;
}
