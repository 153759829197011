import { Component, OnInit } from '@angular/core';
import { BoardTypeEnum, CommunityStatusActifEnum, CommunityTypeStructureEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Community, UserBoardState } from '@api/api-interfaces';
import { CommunityService } from '@wip/store/services';
import { CommunityCaracteristicModel, CommunityModel, OrganizationModel } from '@wip/store/selectors-model';
import { CommunitySubFamilyModel } from '@wip/store/selectors-model';
import { CommunityUserModel } from '@wip/store/selectors-model';
import { CommunityUserProfilModel } from '@wip/store/selectors-model';
import { OrganizationService } from '@wip/store/services';
import { OrganizationSubFamilyModel } from '@wip/store/selectors-model';
import { OrganizationUserService } from '@wip/store/services';
import { UserModel } from '@wip/store/selectors-model';
import { UserBoardStateModel } from '@wip/store/selectors-model';
import { filter, map, Observable, take, tap } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'wip-global-map',
  templateUrl: './global-map.component.html',
  styleUrls: ['./global-map.component.scss']
})
export class GlobalMapComponent implements OnInit {
  public communities$: Observable<Community[]>;
  public communities: Community[];
  public communitiesFiltered: Community[];
  public searchString: string;
  public configurationBoardStates$: Observable<UserBoardState[]>;
  public selectedProjectId: number;
  private filters;

  constructor(
    private readonly newCommunityService: CommunityService,
    private readonly newOrganizationUserService: OrganizationUserService,
    private readonly newOrganizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.newOrganizationService
      .selectAllActiveOrganizations()
      .pipe(
        untilDestroyed(this),
        take(1),
        tap(o => {
          this.newCommunityService.getPlanningInfo(o[0].idOrganization);
        })
      )
      .subscribe();
    this.newCommunityService.searchString$.subscribe(str => {
      this.searchString = str;
    });
    this.communities$ = this.newOrganizationUserService
      .selectAllActiveOrganizationUsers({
        include: [
          UserBoardStateModel,
          {
            model: OrganizationModel,
            include: [
              {
                model: CommunityModel,
                include: [
                  CommunityUserModel,
                  CommunityCaracteristicModel,
                  { model: CommunityUserProfilModel, include: [UserModel] },
                  { model: CommunitySubFamilyModel, include: [OrganizationSubFamilyModel] }
                ]
              }
            ]
          }
        ]
      })
      .pipe(
        untilDestroyed(this),
        map(ou => ou[0]),
        filter(orgaUser => !!orgaUser),
        tap(orgaUser => {
          if (orgaUser.userBoardStates.length) {
            this.filters = JSON.parse(
              orgaUser.userBoardStates.find(bs => bs.type === BoardTypeEnum.project).filterState
            );
          }
        }),
        map(orgaUser =>
          orgaUser.organization?.communities.filter(
            comm =>
              comm.latitude !== '0' &&
              comm.idOrganization === orgaUser.idOrganization &&
              comm.statusActif === CommunityStatusActifEnum.actif &&
              [CommunityTypeStructureEnum.project, CommunityTypeStructureEnum.development].includes(comm.typeStructure)
          )
        ),
        filter(comms => !!comms),
        map(comms => {
          if (comms.length) {
            this.communities = this.newCommunityService
              .filterCommunities(comms, this.filters)
              .sort((a, b) => (a.nom < b.nom ? -1 : a.nom > b.nom ? 1 : 0));
            return this.communities;
          }
        }),
        map(c => this.filterProjectsInBounds(c?.map(c => c.idCommunity)))
      );
  }

  public changeSelectedProject($event) {
    this.selectedProjectId = $event;
  }

  public filterProjectsInBounds(communityIds: number[]) {
    return this.communities?.filter(c => communityIds.includes(c.idCommunity));
  }
}
