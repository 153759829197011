<div class="flex flex-row items-center">
  <div
    appearance="fill"
    class="outlined-field flex items-center"
    style="position: relative; width: 160px; height: 32px; cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.1)"
  >
    <mat-select [formControl]="filtersGroup.get('milestone')" style="padding: 0 4px; font-size: 12px" #matSelect>
      @for (orgaMilestone of milestones; track orgaMilestone.idOrganizationMilestone) {
        <mat-option [value]="orgaMilestone.idOrganizationMilestone">
          {{ orgaMilestone.libelle }}
        </mat-option>
      }
    </mat-select>
  </div>
</div>
