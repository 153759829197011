import { Injectable } from '@angular/core';
import { ElementStatusActionEnum } from '@enums';
import { Dictionary } from '@ngrx/entity';
import { TranslateService } from '@ngx-translate/core';
import {
  CommunityCaracteristic,
  OrganizationCaracteristic,
  OrganizationMilestone,
  ProjectElement
} from '@api/api-interfaces';
import { ChartData as ChartJsData, ChartOptions, TooltipItem } from 'chart.js';
import { BehaviorSubject, Observable } from 'rxjs';

interface ChartData {
  projectCharacteristics: CommunityCaracteristic[];
  milestone: ProjectElement;
}

@Injectable({
  providedIn: 'root'
})
export class ChartDatasService {
  private isProjectSubFamilySelectOpenedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isProjectSubFamilySelectOpened: Observable<boolean> = this.isProjectSubFamilySelectOpenedSource.asObservable();

  constructor(private translateService: TranslateService) {}

  public changeStateProjectSubFamilySelect(value: boolean) {
    this.isProjectSubFamilySelectOpenedSource.next(value);
  }

  public getChartOptions(projectCharacteristic: CommunityCaracteristic): ChartOptions {
    return {
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 50,
          right: 50,
          top: 0,
          bottom: 50
        }
      },
      legend: {
        position: 'bottom'
      },
      plugins: {
        datalabels: {
          display: function (context) {
            return context.dataset.data[context.dataIndex] !== 0;
          },
          datalabels: {
            display: true,
            align: 'center',
            anchor: 'center'
          }
        }
      },
      scales: {
        x: {
          scaleLabel: {
            display: true,
            labelString: this.translateService.instant('template.graphics.milestone')
          }
        },
        y: {
          scaleLabel: {
            display: true,
            labelString: this.getProjectCharacteristicInfo(projectCharacteristic)
          },
          ticks: {
            beginAtZero: true
          }
        }
      },
      title: {
        text: this.translateService.instant('template.graphics.byMilestone', {
          valueUnit: this.getProjectCharacteristicInfo(projectCharacteristic)
        }),
        display: true
      },
      tooltips: {
        mode: 'label',
        callbacks: {
          label: (tooltipItem: TooltipItem<any>, { datasets }: ChartJsData) => {
            return (datasets[tooltipItem.datasetIndex] as DatasetElement).status === ElementStatusActionEnum.close
              ? this.translateService.instant('template.graphics.done')
              : this.translateService.instant('template.graphics.planned');
          }
        }
      }
    } as ChartOptions;
  }

  private getProjectCharacteristicInfo(projectCharacteristic: CommunityCaracteristic): string {
    return `${projectCharacteristic?.libelle}${
      projectCharacteristic?.valueUnit ? ` (${projectCharacteristic?.valueUnit})` : ''
    }`;
  }

  public getChartDatas(chartDatas: Dictionary<ChartData[]>, selectedMilestones: OrganizationMilestone[]): ChartJsData {
    return {
      labels: this.getXAxisLabel(selectedMilestones),
      datasets: this.getDatasets(chartDatas, selectedMilestones)
    };
  }

  private getXAxisLabel(selectedMilestones: OrganizationMilestone[]): string[] {
    return [...selectedMilestones].sort((a, b) => a.ordre - b.ordre).map(projectMilestone => projectMilestone.libelle);
  }

  private getDatasets(
    chartDatasDictionary: Dictionary<ChartData[]>,
    selectedMilestones: OrganizationMilestone[]
  ): DatasetElement[] {
    // on crée un dictionnaire des ordres des milestones
    const milestoneOrder: { [idOrganizationMilestone: number]: number } = {};
    selectedMilestones.forEach(milestone => (milestoneOrder[milestone.idOrganizationMilestone] = milestone.ordre));

    let datasets = {};

    Object.keys(chartDatasDictionary).forEach((key: string) => {
      const chartDatas: ChartData[] = chartDatasDictionary[key];

      chartDatas.forEach((chartData: ChartData) => {
        let milestoneStatus: ElementStatusActionEnum = (chartData.milestone as unknown as ProjectElement).element
          .statusAction;
        if (
          milestoneStatus === ElementStatusActionEnum.progress ||
          milestoneStatus === ElementStatusActionEnum.blocked
        ) {
          milestoneStatus = ElementStatusActionEnum.open;
        } else if (
          milestoneStatus === ElementStatusActionEnum.abort ||
          milestoneStatus === ElementStatusActionEnum['not applicable'] ||
          milestoneStatus === ElementStatusActionEnum.replace
        ) {
          return;
        }
        const milestoneId: number = (chartData.milestone as unknown as ProjectElement).element.idOrganizationMilestone;

        chartData.projectCharacteristics.forEach((projectCharacteristic: CommunityCaracteristic) => {
          const projectCharacId: number = (projectCharacteristic as unknown as OrganizationCaracteristic)
            .idOrganizationCaracteristic;
          const datasetKeyOpen = `${projectCharacId}${ElementStatusActionEnum.open}`;
          const datasetKeyClose = `${projectCharacId}${ElementStatusActionEnum.close}`;
          const datasetKey = `${projectCharacId}${milestoneStatus}`;
          if (!datasets[datasetKey] || !datasets[datasetKey][milestoneId]) {
            const milestoneOrgaLibOrder: number = this.getMilestoneOrder(milestoneId, milestoneOrder);
            datasets = this.getNewDatasetWithProjectCharacteristic(
              datasets,
              datasetKeyOpen,
              ElementStatusActionEnum.open,
              projectCharacId,
              milestoneId,
              milestoneOrgaLibOrder
            );
            datasets = this.getNewDatasetWithProjectCharacteristic(
              datasets,
              datasetKeyClose,
              ElementStatusActionEnum.close,
              projectCharacId,
              milestoneId,
              milestoneOrgaLibOrder
            );
          }
          const newVal = (projectCharacteristic as unknown as CommunityCaracteristic).valeurDecimal;
          datasets[datasetKey][milestoneId].data += projectCharacteristic.valeur
            ? parseInt(projectCharacteristic.valeur, 10)
            : newVal;
        });
      });
    });
    return this.formatChartDataset(datasets, milestoneOrder);
  }

  private getMilestoneOrder(
    idOrganizationMilestone: number,
    milestoneOrder: { [idOrganizationMilestone: number]: number }
  ): number {
    return milestoneOrder[idOrganizationMilestone];
  }

  private formatChartDataset(
    datasets: CustomChartDatasets,
    milestoneOrder: { [idOrganizationMilestone: number]: number }
  ): DatasetElement[] {
    const openDatasetElement: DatasetElement[] = [];
    const closeDatasetElement: DatasetElement[] = [];

    const milestoneOrderResetToZero: { [idOrganizationMilestone: number]: number } =
      this.resetMilestoneOrderToZero(milestoneOrder);
    Object.values(datasets).forEach(dataset => {
      const element: DatasetElement = {
        label: dataset.label,
        stack: dataset.stack,
        data: new Array(Object.keys(milestoneOrder).length).fill(0),
        status: dataset.status,
        backgroundColor: dataset.backgroundColor,
        hoverBackgroundColor: dataset.backgroundColor
      };
      Object.keys(dataset).forEach(columnDatas => {
        if (!isNaN(Number(columnDatas))) {
          element.data[milestoneOrderResetToZero[columnDatas]] = dataset[columnDatas].data;
        }
      });
      element.status === ElementStatusActionEnum.close
        ? closeDatasetElement.push(element)
        : openDatasetElement.push(element);
    });
    return closeDatasetElement.concat(openDatasetElement);
  }

  private resetMilestoneOrderToZero(milestoneOrder: { [idOrganizationMilestone: number]: number }): {
    [idOrganizationMilestone: number]: number;
  } {
    const sortedMilestoneOrder = [];
    for (const orgalibId in milestoneOrder) {
      if (milestoneOrder.hasOwnProperty(orgalibId)) {
        sortedMilestoneOrder.push([orgalibId, milestoneOrder[orgalibId]]);
      }
    }
    sortedMilestoneOrder.sort((a, b) => a[1] - b[1]);
    let res = {};
    sortedMilestoneOrder.forEach(([key, _value], index: number) => {
      res = { ...res, [key]: index };
    });
    return res;
  }

  private getNewDatasetWithProjectCharacteristic(
    datasets: CustomChartDatasets,
    datasetKey: string,
    milestoneStatus: ElementStatusActionEnum,
    projectCharacId: number,
    milestoneId: number,
    milestoneOrder: number
  ): CustomChartDatasets {
    const stepGreenLvl1Color = '#66be96';
    const stepGreenLvl2Color = '#a9dbc1';
    return {
      ...datasets,
      [datasetKey]: {
        ...datasets[datasetKey],
        label: `${
          milestoneStatus === ElementStatusActionEnum.close
            ? `${this.translateService.instant('template.graphics.done')}`
            : `${this.translateService.instant('template.graphics.planned')}`
        }`,
        stack: `${projectCharacId}`,
        status: milestoneStatus,
        [milestoneId]: { data: 0, milestoneOrder },
        backgroundColor: milestoneStatus === ElementStatusActionEnum.close ? stepGreenLvl1Color : stepGreenLvl2Color
      }
    };
  }
}

export interface CustomChartDatasets {
  [projectCharacteristicOrgaLibId: string]: DatasetElement;
}

export interface DatasetElement {
  label: string;
  data: number[];
  backgroundColor: string;
  status: ElementStatusActionEnum;
  hoverBackgroundColor: string;
  stack?: string;
}
