import { DurationPickerDialogComponent } from '@_app/shared/dialogs/duration-picker-dialog/duration-picker-dialog.component';
import { IGroupMetaUsersSuggested } from '@_app/shared/interfaces/MetaUserGroup.interface';
import { SearchUsersDialogComponent } from '@_app/shared/search-users/search-users-dialog/search-users-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ElementLibraryTypeEnum, ElementTypeElement } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Element } from '@api/api-interfaces';
import { CommunityUserModel, MeetingUserModel, UserModel } from '@wip/store/selectors-model';
import { CommunityService, MeetingService, UserService } from '@wip/store/services';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class PojService {
  private suggestions: IGroupMetaUsersSuggested[];

  constructor(
    private dialog: MatDialog,
    private newCommunityService: CommunityService,
    private newMeetingService: MeetingService,
    private newUserService: UserService
  ) {}

  setElementDuration(element: Element, cb?: Function) {
    this.setDuration(element.duration, (duration: number) => {
      element.duration = duration;
      if (cb) {
        cb(element);
      }
    });
  }

  setDuration(duration: number, cb?: Function) {
    this.dialog
      .open(DurationPickerDialogComponent, {
        maxWidth: '100vw',
        data: { duration, fromMeeting: true }
      })
      .afterClosed()
      .pipe(untilDestroyed(this), take(1))
      .subscribe(dateReturn => {
        if (dateReturn !== undefined) {
          if (cb) {
            cb(dateReturn);
          }
        } else {
          return duration;
        }
      });
  }

  public setElementResponsibles2(element: Element, cb?: Function) {
    if (element.typeElement === ElementTypeElement.elementPoj) {
      this.setResponsibles2(element, data => {
        if (element.typeElement === ElementTypeElement.elementPoj) {
          element.listeResponsables = [...data.listUserSelected];
          element.listeElementsLibrariesAdd = [...data.listeUsersAdd];
          element.listeElementsLibrariesRemove = [...data.listeUsersRemove];
          if (cb) {
            cb(element, data);
          }
        }
      });
    }
  }

  /**
   * @description Handle POJ responsible attribution
   * @param poj Element to edit
   * @param isCallFromHover 'true' if independent (call from hover), 'false' if called from within a parent input
   */
  public setResponsibles(poj: Element, cb?: Function): void {
    this.selectSuggestedUsers();
    const dialogRef = this.dialog.open(SearchUsersDialogComponent, {
      data: {
        groupMetaUsersSuggested: this.suggestions,
        metaUsersSelected: poj.listeResponsables,
        add: poj.listeElementsLibrariesAdd,
        rmv: poj.listeElementsLibrariesRemove,
        displayList: 'list',
        canAddName: true,
        findExactMatch: true,
        textPlaceholder: 'Sélectionner un ou plusieurs utilisateurs à ajouter ou supprimer',
        maxChips: null
      },
      width: '500px',
      height: '700px',
      panelClass: ['dialog-content-no-paddings', 'custom-dialog-fullscreen-xs']
    });

    dialogRef.afterClosed().subscribe(data => {
      if (cb) {
        cb(data);
      }
    });
  }

  public setResponsibles2(poj: Element, cb?: Function): void {
    this.selectSuggestedUsers();
    const dialogRef = this.dialog.open(SearchUsersDialogComponent, {
      data: {
        groupMetaUsersSuggested: this.suggestions,
        metaUsersSelected: poj.elementLibraries.filter(el => el.type === ElementLibraryTypeEnum.user_idUser),
        add: [],
        rmv: [],
        displayList: 'list',
        canAddName: true,
        findExactMatch: true,
        textPlaceholder: 'Sélectionner un ou plusieurs utilisateurs à ajouter ou supprimer',
        maxChips: null
      },
      width: '500px',
      height: '700px',
      panelClass: ['dialog-content-no-paddings', 'custom-dialog-fullscreen-xs']
    });

    dialogRef.afterClosed().subscribe(data => {
      if (cb) {
        cb(data);
      }
    });
  }

  private selectSuggestedUsers() {
    const selectorsSubscription: Observable<any> = combineLatest([
      this.newUserService.selectOneUser(this.newUserService.currentIdUser),
      this.newCommunityService
        .selectAllActiveCommunities({ include: [{ model: CommunityUserModel, include: [UserModel] }] })
        .pipe(
          untilDestroyed(this),
          map(c => c[0]),
          map(c => c?.communityUsers)
        ),
      this.newMeetingService
        .selectAllActiveMeetings({ include: [{ model: MeetingUserModel, include: [UserModel] }] })
        .pipe(
          untilDestroyed(this),
          map(m => m[0]),
          map(m => m?.meetingUsers)
        )
    ]);

    selectorsSubscription.subscribe(([currentUser, communityUsers, meetingUsers]) => {
      const meetingUsersModified = meetingUsers.map(mu => {
        if (mu.idUser) {
          mu.type = 'user_idUser';
        }
        return mu;
      });
      this.suggestions = [
        {
          label: 'Vous',
          users: [{ user: currentUser }],
          checked: false,
          request: false
        },
        {
          label: 'Utilisateurs de la réunion',
          users: meetingUsersModified,
          checked: false,
          request: false
        },
        {
          label: 'Utilisateurs de la communauté',
          users: communityUsers,
          checked: false,
          request: false
        }
      ];
    });
  }

  public setUsersClasses2(poj: Element): string[] {
    const classes = ['icon', 'icon-ic_fluent_person_24_regular'];
    if (poj.listeResponsables?.length) {
      classes.push('cellance-primary');
    }
    return classes;
  }
}
