import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { ElementStatusActionEnum, TodoElementTypeEnum } from '@enums';

@Component({
  selector: 'app-action-checkbox-renderer',
  templateUrl: './action-checkbox-renderer.component.html',
  styleUrls: ['./action-checkbox-renderer.component.scss']
})
export class ActionCheckboxRendererComponent implements ICellRendererAngularComp {
  public status: ElementStatusActionEnum;
  public isProject: boolean;
  public checkboxColor;
  public checkboxStatus;
  private componentParent;
  private idElement;

  constructor() {}

  agInit(params) {
    this.status = params.value;
    this.idElement = params.data.idElement;
    this.isProject = params.data.type === TodoElementTypeEnum.project_element;
    this.componentParent = params.context.componentParent;

    const checkboxStatus = {
      open: 'icon icon-ic_fluent_circle_24_regular',
      delete: 'icon icon-ic_fluent_dismiss_circle_24_regular',
      close: 'icon icon-ic_fluent_checkmark_circle_24_regular',
      progress: 'icon icon-ic_fluent_arrow_circle_right_24_regular',
      pause: 'icon icon-ic_fluent_error_circle_24_regular',
      abort: 'icon icon-ic_fluent_dismiss_circle_24_regular',
      replace: 'icon icon-ic_fluent_arrow_sync_circle_24_regular',
      ['not applicable']: 'status-icon icon icon-ic_fluent_circle_24_filled grey'
    };

    const checkboxColor = {
      delete: '#ea5045',
      close: '#169142',
      progress: '#0293bf',
      pause: '#0293bf',
      abort: '#ea5045',
      replace: '#ea5045',
      ['not applicable']: 'lightgrey'
    };

    this.checkboxStatus = checkboxStatus[this.status];
    this.checkboxColor = checkboxColor[this.status];
  }

  refresh(): boolean {
    return false;
  }

  setNextStatus() {
    this.componentParent.setNextStatus({ idElement: this.idElement, statusAction: this.status });
  }
}
