import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommunityRiskApiService } from '@wip/store/api-services';
import { GeneratedCommunityRiskEffects } from './community-risk-generated.effects';

@Injectable()
export class CommunityRiskEffects extends GeneratedCommunityRiskEffects {
  constructor(actions$: Actions, communityRiskApiService: CommunityRiskApiService, store$: Store<AppState>) {
    super(actions$, communityRiskApiService, store$);
  }
}
