<div mat-dialog-title>
  <h1 class="titleleft">Vos configurations personnalisées</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
</div>
<p style="margin: 16px 16px">
  Vous pouvez sauvegarder plusieurs mises en forme personnalisées de tableau. Pour cela:<br />
  1/ Modifier directement dans le tableau les colonnes à afficher, leur ordre, les filtres, ainsi que les tris.<br />
  2/ Cliquer sur Ajouter et nommer votre configuration.
</p>
<div mat-dialog-content style="margin: 0 16px">
  <ag-grid-angular
    #agGrid
    class="ag-theme-quartz"
    style="width: 700px"
    [gridOptions]="gridOptions"
    [modules]="modules"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</div>
<div style="margin: 16px 16px">
  <button mat-stroked-button color="accent" (click)="onClickAddNewRow()">Ajouter Configuration</button>
</div>
