import { EditResponsibleComponent } from '@_app/project/gantt/edit-responsible/edit-responsible.component';
import { Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConstraintTypeEnum, DurationFormat, GanttLinkTypeEnum, TodoTypeEnum } from '@enums';
import { GanttStatic } from '@wip/gantt-static';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Community, OrganizationUser, Task } from '@api/api-interfaces';
import { ProfilModel, RightModel } from '@wip/store/selectors-model';
import { OrganizationUserService, ProjectElementService } from '@wip/store/services';
import dayjs from 'dayjs';
import { map, tap } from 'rxjs';
import { durationFormatterParams } from '../new-gantt.component';
import { NewTaskOptionsComponent } from '../new-task-options/new-task-options.component';
import {
  formatDateForEditor,
  formatDuration,
  getIntDurationFromStringDuration,
  getPredecessors
} from '../utils/new-gantt-templates';
import { GanttEventsService } from './new-gantt-events.service';
import { MoveTaskStrategy } from './task.service';

export interface TaskOptions {
  edit: boolean;
  insertStep: boolean;
  addSubStep: boolean;
  addProjectElement: boolean;
  exportExcel: boolean;
  exportPng: boolean;
  exportPDF: boolean;
  deleteAll: boolean;
  delete: boolean;
  duplicate: boolean;
}

export interface Predecessor {
  sourceLink: number;
  ganttLinkType: GanttLinkTypeEnum;
  lag: number;
  isValid: boolean;
}
@UntilDestroy()
@Injectable()
export class EditorService implements OnInit {
  constructor(
    private dialog: MatDialog,
    private ganttEventsService: GanttEventsService,
    private projectElementService: ProjectElementService,
    private organizationUserService: OrganizationUserService
  ) {}

  private inputHTMLElement: HTMLElement;
  private checkboxHTMLElement: HTMLElement;
  private currentCheckboxValue: boolean = true;
  private organizationUser: OrganizationUser;

  ngOnInit(): void {
    this.organizationUserService
      .selectAllActiveOrganizationUsers({ include: [{ model: ProfilModel, include: [RightModel] }] })
      .pipe(
        untilDestroyed(this),
        map(ou => ou[0]),
        tap(ou => (this.organizationUser = ou))
      )
      .subscribe();
  }

  public getEditors(gantt: GanttStatic, community: Community) {
    const editors: any = { ...gantt.config.editor_types };
    const businessDay = community.organizationFamily?.businessDay;

    editors.start_date_editor = { type: 'custom_start_date', map_to: 'start_date' };

    editors.predecessor_and_lag_custom_editor = {
      type: 'predecessor_custom_editor',
      map_to: 'predecessor'
    };
    const textEditor = editors.text;
    const dateEditor = editors.date;
    editors.end_date = gantt.mixin(
      {
        get_value: (_id, _column, node) => {
          if (node.firstElementChild) {
            return dayjs(new Date(node.firstElementChild.firstElementChild.value)).hour(3).toDate();
          }
        },
        is_changed: (value, _id, _column, node) => {
          const newDate = node.firstElementChild?.firstElementChild?.value;
          if (!newDate) {
            return false;
          }

          const newDateFormatted = new Date(newDate);

          const res = formatDateForEditor(value) !== formatDateForEditor(newDateFormatted);
          return res;
        },
        is_valid: (_value, _id, _column, node) => {
          const newDate = node.firstElementChild?.firstElementChild?.value;
          const year = newDate.split('-')[0];
          return +year < 2100 && +year > 1950;
        }
      },
      dateEditor
    );
    editors.end_date_editor = { type: 'end_date', map_to: 'end_date' };

    editors.duration = gantt.mixin(
      {
        show: function (id, _column, _config, placeholder) {
          const task = gantt.getTask(id);

          if (task.type === TodoTypeEnum.project) {
            return false;
          }
          let formatter = gantt.ext.formatters.durationFormatter(
            durationFormatterParams(businessDay, DurationFormat[task.durationFormat])
          );
          let formatted = formatter.format(task.duration);
          if (formatted.includes('.')) {
            formatter = gantt.ext.formatters.durationFormatter(durationFormatterParams(businessDay, DurationFormat.j));
            formatted = formatter.format(task.duration);
          }

          const html = "<input type='text' value='" + formatted + "'>";
          placeholder.innerHTML = html;
        },
        set_value: function (_value, _id, _column, node) {
          if (node.firstElementChild) {
            return (node.firstElementChild as HTMLInputElement).value;
          }
        },
        get_value: function (id, _column, node) {
          const task = gantt.getTask(id);
          const formatter = gantt.ext.formatters.durationFormatter(
            durationFormatterParams(businessDay, DurationFormat[task.durationFormat])
          );
          return formatter.parse(node.firstElementChild?.value);
        },
        is_valid: (value, id, _column, node) => {
          if (value) {
            const task = gantt.getTask(id);
            const newFormat = node.firstElementChild?.value.slice(-1);
            if (task.durationFormat !== newFormat) {
              this.projectElementService;
              this.projectElementService.upsertOneProjectElement({
                idProjectElement: +task.id,
                idElement: task.idElement,
                durationFormat: newFormat
              });
            }
          }
          return value ? true : false;
        }
      },
      textEditor
    );

    editors.duration_editor = { type: 'duration', map_to: 'duration' };
    editors.custom_start_date = gantt.mixin(
      {
        show: (id, _column, _config, placeholder) => {
          const task = gantt.getTask(id);
          if (task.type === TodoTypeEnum.project) {
            return false;
          }

          const formattedDate = formatDateForEditor(task.start_date);
          const currentDateState = task.constraint_type === ConstraintTypeEnum.MSO ? 'fixed-date' : 'calc-date';

          const html =
            '<div class="flex flex-col date-focus">' +
            '<div class="input-border  date-focus">' +
            '<div class="date-focus inline-date-editor gap-8">' +
            `<input id="inline-input-${id}" class="${currentDateState} inline-input date-focus" type='date' max="9999-01-01" value='` +
            formattedDate +
            "'/>" +
            `<span class="tooltip delete-${id} gulp gulp-34-close icon close date-focus" >` +
            '<span class="tooltiptext-warn date-focus">' +
            'Supprimer la date</span>' +
            `</span><span class="tooltip info-${id} gulp gulp-34-more icon info date-focus" >` +
            '<span class="tooltiptext date-focus">Afficher plus d\'options</span>' +
            '</span>' +
            '</div>' +
            '<div style="height: 32px" class="date-focus flex flex-row justify-start items-center">' +
            `<input id="checkbox-${id}" class="date-focus date-checkbox checkbox-${id}" style="width: 16px !important;width: 16px !important;" type='checkbox' ${
              this.currentCheckboxValue ? 'checked' : ''
            }>` +
            '<span class="date-focus">Décaler dates suivantes&nbsp;&nbsp;</span></div>' +
            '</span></div>' +
            '</div>';
          placeholder.innerHTML = html;
          setTimeout(_ => {
            this.inputHTMLElement = document.getElementById('inline-input-' + id);

            let dateValue: Date = dayjs(formattedDate).toDate();
            this.setMoveTaskStrategy(formattedDate, this.currentCheckboxValue, this.inputHTMLElement, task);

            this.checkboxHTMLElement = document.getElementById('checkbox-' + id);
            this.checkboxHTMLElement.addEventListener('input', (e: any) => {
              this.currentCheckboxValue = e.target.checked;
              this.setMoveTaskStrategy(dateValue, this.currentCheckboxValue, this.inputHTMLElement, task);
            });

            this.inputHTMLElement.addEventListener('input', (e: any) => {
              dateValue = e.target.value;

              if (!dayjs(e.target.value).isValid()) {
                this.inputHTMLElement.classList.add('red');
              } else {
                this.inputHTMLElement.classList.remove('red');
              }

              this.setMoveTaskStrategy(dateValue, this.currentCheckboxValue, this.inputHTMLElement, task);
            });
          });
        },
        set_value: function (_value, _id, _column, node) {
          if (node.firstElementChild) {
            return new Date(node.firstElementChild.firstElementChild.firstElementChild.firstElementChild.value);
          }
        },
        get_value: function (_id, _column, node) {
          if (node.firstElementChild) {
            return new Date(node.firstElementChild.firstElementChild.firstElementChild.firstElementChild.value);
          }
        },
        focus: (node: HTMLElement) => {
          const input = node.querySelector('input');
          if (!input) {
            return;
          }
          if (input.focus) {
            input.focus();
          }

          if (input.select) {
            input.select();
          }
        },
        is_changed: (value, _id, _column, node) => {
          const newDate = node.firstElementChild?.firstElementChild?.firstElementChild?.firstElementChild?.value;
          if (!newDate) {
            return false;
          }

          const newDateFormatted = new Date(newDate);

          const res = formatDateForEditor(value) !== formatDateForEditor(newDateFormatted);
          this.inputHTMLElement.removeAllListeners();
          this.checkboxHTMLElement.removeAllListeners();

          return res;
        },
        is_valid: () => {
          return true;
        },
        save: (_id, _column, _node) => {}
      },
      null
    );
    editors.previous = gantt.mixin(
      {
        show: function (id, _column, _config, placeholder) {
          const task = gantt.getTask(id);

          if (task.type === 'project') {
            return false;
          }

          const target = task.$target;
          let predecessor = '';
          if (target.length) {
            const prev = gantt.getLink(target[0]);
            if (prev) {
              predecessor = gantt.getTask(prev.source)?.index;
            }
          }
          const html = "<input type='text' value='" + predecessor + "'>";
          placeholder.innerHTML = html;
        },
        set_value: function (_value, _id, _column, node) {
          if (node.firstElementChild) {
            return (node.firstElementChild as HTMLInputElement).value;
          }
        },
        get_value: function (_id, _column, node) {
          if (node.firstElementChild) {
            return (node.firstElementChild as HTMLInputElement).value;
          }
        },
        is_changed: (_value, id, _column, node) => {
          const task = gantt.getTask(id);
          if (task.type === 'project') {
            return false;
          }
          const target = task.$target;
          let predecessor = '';
          if (target.length) {
            const prev = gantt.getLink(target[0]);
            if (prev) {
              predecessor = gantt.getTask(prev.source)?.index;
            }
          }

          return +predecessor !== +(node.firstElementChild as HTMLInputElement).value;
        }
      },
      textEditor
    );
    editors.lag = gantt.mixin(
      {
        show: function (id, _column, _config, placeholder) {
          const task = gantt.getTask(id);
          if (task.type === 'project') {
            return false;
          }
          const target = task.$target;
          let lag = '';
          if (target.length) {
            const prev = gantt.getLink(target[0]);
            if (prev && prev.lag) {
              lag = formatDuration(prev.lag, community.organizationFamily.businessDay);
            }
          }
          const html = "<input type='text' value='" + lag + "'>";
          placeholder.innerHTML = html;
        },
        set_value: function (_value, _id, _column, node) {
          if (node.firstElementChild) {
            return (node.firstElementChild as HTMLInputElement).value;
          }
        },
        get_value: function (_id, _column, node) {
          if (node.firstElementChild) {
            return (node.firstElementChild as HTMLInputElement).value;
          }
        },
        is_valid: (_value, _id, _column, node) => {
          const days = getIntDurationFromStringDuration(
            (node.firstElementChild as HTMLInputElement).value,
            community.businessDay
          );

          return days < 3651 && days > -3651;
        },
        is_changed: (_value, id, _column, node) => {
          const task = gantt.getTask(id);
          if (task.type === 'project') {
            return false;
          }
          const target = task.$target;
          let lag = '';
          if (target.length) {
            const prev = gantt.getLink(target[0]);
            if (prev && prev.lag) {
              lag = formatDuration(prev.lag, community.organizationFamily.businessDay);
            }
          }

          return lag !== (node.firstElementChild as HTMLInputElement).value;
        }
      },
      textEditor
    );
    editors.previous_editor = { type: 'previous', map_to: 'previous' };
    editors.lag_editor = { type: 'lag', map_to: 'lag' };

    editors.text_editor = {
      type: 'text',
      map_to: 'text'
    };

    editors.edit_custom_menu = {
      show: (id: number, _column: any, _config: Object, placeholder: HTMLElement) => {
        const task = gantt.getTask(id);
        const pos = placeholder.getBoundingClientRect();
        const top = pos.top + 20;
        const left = pos.left + 20;

        const offset = task.type === 'project' ? 90 : 150;

        if (!task.editable) {
          this.ganttEventsService.gantt.message({
            type: 'warning',
            text: 'Activez le mode édition pour éditer des lignes'
          });
          this.ganttEventsService.resetData$.next();
        } else if (task.canCreate) {
          const options = this.setTaskOptions(task as Task);
          const dialogRef = this.dialog.open(NewTaskOptionsComponent, {
            data: { gantt, task, options, community, organizationUser: this.organizationUser },
            position: {
              top: top - offset + 'px',
              left: left + 'px'
            }
          });
          dialogRef.afterClosed().subscribe(action => {
            if (action) {
              const isTask = task.type === 'task' || task.type === 'milestone';
              const parent = isTask ? task.parent : task.id;
              if (action === 'addTask') {
                gantt.addTask(
                  {
                    index: task.$index,
                    text: 'Livrable',
                    type: 'task',
                    start_date: task.start_date
                  },
                  parent,
                  task.index
                );
              } else if (action === 'deleteTask') {
                gantt.deleteTask(task.id);
              } else if (action === 'addStep') {
                gantt.addTask(
                  {
                    index: task.index,
                    text: 'Groupement',
                    type: 'project'
                  },
                  parent,
                  task.index
                );
              } else if (action === 'duplicate') {
                gantt.addTask(
                  {
                    idOrigin: task.id,
                    index: task.$index,
                    text: task.text,
                    type: 'task',
                    start_date: task.start_date,
                    end_date: task.end_date
                  },
                  task.parent,
                  task.index
                );
              }
            }
            this.ganttEventsService.resetData$.next();
          });
        }
      },
      hide: () => {},
      set_value: (_value, _id, _column, _node) => {
        return null;
      },
      is_changed: (_value, _id, _column, _node) => {
        return false;
      },
      focus: (_node: HTMLElement) => {}
    };
    editors.edit_menu = { type: 'edit_custom_menu', map_to: 'edit' };

    editors.responsible_custom_editor = {
      show: (id: number, _column: any, _config: Object, placeholder: HTMLElement) => {
        const task = gantt.getTask(id);
        if (task.type === 'task' || task.type === 'milestone') {
          const pos = placeholder.getBoundingClientRect();
          const top = pos.top + 20;
          const left = pos.left + 20;
          if (task.canUpdate) {
            const dialogRef = this.dialog.open(EditResponsibleComponent, {
              data: { responsibles: task.responsibles, dialog: this.dialog, idElement: task.idElement },
              position: {
                top: top + 'px',
                left: left + 'px'
              }
            });
            dialogRef.afterClosed().subscribe(_ => {
              gantt.refreshTask(id);
            });
          }
          this.ganttEventsService.resetData$.next();
        }
      },
      hide: () => {},
      set_value: (_value, _id, _column, _node) => {
        return null;
      },
      is_changed: (_value, _id, _column, _node) => {
        return false;
      },
      focus: (_node: HTMLElement) => {}
    };
    editors.responsible_editor = { type: 'responsible_custom_editor', map_to: 'responsibles' };

    editors.predecessor_custom_editor = gantt.mixin(
      {
        show: (id: number, _column: any, _config: Object, placeholder: HTMLElement) => {
          const task = gantt.getTask(id);
          const predecessor = getPredecessors(task as Task, gantt, community.organizationFamily.businessDay);
          if (task.type !== 'project') {
            const html = `<input type='text' value='${predecessor}' >`;
            placeholder.innerHTML = html;
          }
        },
        hide: () => {},
        set_value: (_value, _id, _column, node) => {
          if (node.firstElementChild) {
            return (node.firstElementChild as HTMLInputElement).value;
          }
        },
        get_value: (_id, _column, node) => {
          if (node.firstElementChild) {
            return (node.firstElementChild as HTMLInputElement).value;
          }
        },
        is_valid: (value, id, _column, _node) => {
          const predecessors = value.split(';');
          let err = 0;
          const sourceIndexes = [];

          predecessors.forEach(prev => {
            const parsed: string[] = prev
              .toLocaleLowerCase()
              .match(/([+-]?\d+(?:\.\d+)?)|([A-Z]+)|(-\d+(?:\.\d+)?)|([a-z])/g);
            let newType: number = 0;
            let newLagStr: string = '';
            let count = 0;

            if (!parsed) {
              return true;
            }
            if (sourceIndexes.includes(parsed[0])) {
              err = 1;
            }
            sourceIndexes.push(parsed[0]);
            const task = gantt.getTaskByIndex(parsed[0]);
            if (!task || +parsed[0] === gantt.getTask(id).index) {
              err = 1;
            }
            count++;
            if (count > parsed.length - 1) {
              return;
            }
            newType =
              parsed[count] === 'FD'
                ? 0
                : parsed[count] === 'DD'
                  ? 1
                  : parsed[count] === 'FF'
                    ? 2
                    : parsed[count] === 'DF'
                      ? 3
                      : 4;
            if (parsed.length === 2) {
              return true;
            }
            if (newType === 4 && !(parsed[count][0] === '+' || parsed[count][0] === '-')) {
              err = 1;
            }
            newLagStr = newType !== 4 ? parsed[count + 1] : parsed[count];
            if (newType !== 4) {
              count++;
            }
            if (!(newLagStr[0] === '+' || newLagStr[0] === '-')) {
              err = 2;
            }
            count++;

            if (
              parsed.length > count &&
              !(parsed[count] === 'j' || parsed[count] === 'm' || parsed[count] === 's' || parsed[count] === 'a')
            ) {
              err = 3;
            }
          });

          if (err) {
            return false;
          }

          return true;
        },
        is_changed: (_value, id, _column, node) => {
          let value = getPredecessors(gantt.getTask(id) as Task, gantt, community.organizationFamily.businessDay);

          if (node.firstElementChild) {
            let inputValue: string = (node.firstElementChild as HTMLInputElement).value.toLocaleLowerCase();
            value = value.replace(/\s/g, '');
            inputValue = inputValue.replace(/\s/g, '');
            return value !== inputValue;
          }

          return false;
        },
        focus: (node: HTMLElement) => {
          if (node.firstElementChild) {
            if (this.isClientOnChromiumBasedNavigator()) {
              (node.firstElementChild as HTMLInputElement).focus();
              (node.firstElementChild as HTMLInputElement).select();
            } else {
              (node.firstElementChild as HTMLInputElement).focus();
            }
          }
        }
      },
      textEditor
    );

    return editors;
  }

  public editRightCell(gantt, cell, inlineEditors): void {
    const task = gantt.getTask(cell.id);
    const nextColumn = inlineEditors.getNextCell(true);

    if (nextColumn === 'responsible') {
      if (task.type === 'project') {
        inlineEditors.startEdit(gantt.getNext(task.id), 'title_display');
      } else {
        inlineEditors.startEdit(task.id, 'predecessor');
      }
    } else if (task.type === 'milestone' && nextColumn !== null) {
      if (nextColumn === 'duration_display') {
        inlineEditors.startEdit(task.id, 'start_date_display');
      } else if (nextColumn === 'end_date_display') {
        inlineEditors.startEdit(task.id, 'predecessor');
      }
    } else {
      inlineEditors.editNextCell(true);
    }
  }

  public editPrevCell(gantt, cell, inlineEditors): void {
    const task = gantt.getTask(cell.id);

    if (cell.columnName === 'title_display') {
      const prevTask = gantt.getTask(gantt.getPrev(task.id));
      if (prevTask === undefined) {
        inlineEditors.save();
      } else {
        inlineEditors.startEdit(prevTask.id, prevTask.type === 'project' ? 'end_date_display' : 'predecessor');
      }
    } else if (task.type === 'milestone') {
      if (cell.columnName === 'start_date_display') {
        inlineEditors.startEdit(task.id, 'title_display');
      } else if (cell.columnName === 'predecessor') {
        inlineEditors.startEdit(task.id, 'start_date_display');
      }
    } else if (cell.columnName === 'predecessor') {
      inlineEditors.startEdit(task.id, 'end_date_display');
    } else {
      inlineEditors.editPrevCell(true);
    }
  }

  public editBotCell(gantt, cell, inlineEditors): void {
    let nextCellId = gantt.getNext(cell.id);
    let nextTask: Task = nextCellId ? gantt.getTask(nextCellId) : null;

    while (
      nextTask &&
      ((nextTask.type === 'milestone' &&
        (cell.columnName === 'duration_display' || cell.columnName === 'end_date_display')) ||
        (nextTask.type === 'project' && cell.columnName === 'predecessor'))
    ) {
      nextCellId = gantt.getNext(nextTask.id);
      nextTask = nextCellId ? gantt.getTask(nextCellId) : null;
    }
    inlineEditors.startEdit(nextCellId, cell.columnName);
  }

  public setTaskOptions(task: Task): TaskOptions {
    return {
      edit: task.type !== 'project',
      insertStep: true,
      addSubStep: false,
      addProjectElement: true,
      exportExcel: false,
      exportPng: false,
      exportPDF: false,
      deleteAll: false,
      delete: true,
      duplicate: task.type !== 'project'
    };
  }

  private getGanttLinkTypeDict(): any {
    return {
      FD: GanttLinkTypeEnum.finishToStart,
      DF: GanttLinkTypeEnum.startToFinish,
      FF: GanttLinkTypeEnum.finishToFinish,
      DD: GanttLinkTypeEnum.startToStart,
      ES: GanttLinkTypeEnum.finishToStart,
      SE: GanttLinkTypeEnum.startToFinish,
      EE: GanttLinkTypeEnum.finishToFinish,
      SS: GanttLinkTypeEnum.startToStart
    };
  }

  public getPredecessorFromString(input: string, businessDay: boolean): Predecessor[] {
    const predecessor: Predecessor[] = [];
    let subPredecessor: Predecessor;
    const ganttLinkTypeDictionary: any = this.getGanttLinkTypeDict();
    const regExp =
      /(^[0-9]+)?(FD|DD|FF|DF|SS|EE|SE|ES)?((\+|-)([0-9]+)(j|d|jour|jours|day|days|s|w|semaine|semaines|week|weeks|m|mois)?)?$/;

    // suppression whitespaces
    const separatedList: string[] = input.replace(/\s/g, '').match(/([^(,|;)]+)/g);
    if (!separatedList) {
      return null;
    } else {
      separatedList.forEach(subInput => {
        subPredecessor = this.getDefaultPredecessor();
        const [, regExpPredecessor, linkType, lag] = regExp.exec(subInput);
        subPredecessor.isValid = !!regExpPredecessor;
        if (subPredecessor.isValid) {
          // récup du prédécesseur
          subPredecessor.sourceLink = +regExpPredecessor;
          // récup du type de lien
          subPredecessor.ganttLinkType =
            linkType && ganttLinkTypeDictionary.hasOwnProperty(linkType)
              ? ganttLinkTypeDictionary[linkType]
              : GanttLinkTypeEnum.finishToStart;

          // récup du lag
          subPredecessor.lag = lag
            ? getIntDurationFromStringDuration(lag.replace('+', ''), businessDay) || parseInt(lag, 10)
            : 0;
        }

        // ajout de ce lien au tableau général;
        if (!predecessor.some((p: Predecessor) => p.sourceLink === subPredecessor.sourceLink)) {
          predecessor.push(subPredecessor);
        }
      });
      return predecessor;
    }
  }

  private getDefaultPredecessor(): Predecessor {
    return {
      sourceLink: 0,
      ganttLinkType: GanttLinkTypeEnum.finishToStart,
      lag: 0,
      isValid: true
    };
  }

  private isClientOnChromiumBasedNavigator() {
    return !!(window as any).chrome && !/Edge/.test(navigator.userAgent);
  }

  public setMoveTaskStrategy(date, check, inputHTML, _) {
    if (date) {
      inputHTML.classList.remove('fixed-date');
      inputHTML.classList.add('calc-date');
      if (dayjs(date).isAfter(dayjs(new Date()).hour(3))) {
        if (check) {
          this.ganttEventsService.moveTaskStrategy = MoveTaskStrategy.moveTaskWithChildren;
        } else {
          this.ganttEventsService.moveTaskStrategy = MoveTaskStrategy.moveTaskWithoutChildren;
        }
      } else {
        inputHTML.classList.add('fixed-date');
        inputHTML.classList.remove('calc-date');
        if (check) {
          this.ganttEventsService.moveTaskStrategy = MoveTaskStrategy.fixTaskWithMoveChildren;
        } else {
          this.ganttEventsService.moveTaskStrategy = MoveTaskStrategy.fixTaskWithoutMoveChildren;
        }
      }
    }
  }
}
