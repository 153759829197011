import { CommunitySubFamilyState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communitySubFamilyReducersGeneratedFunctions } from './community-sub-family-generated.reducer';

const communitySubFamilyReducersFunctions = [...communitySubFamilyReducersGeneratedFunctions];

const communitySubFamilyReducer = createReducer(
  CommunitySubFamilyState.initialState,
  ...communitySubFamilyReducersFunctions
);

export function reducer(state: CommunitySubFamilyState.IState | undefined, action: Action) {
  return communitySubFamilyReducer(state, action);
}
