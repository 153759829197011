import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedMeetingElementApiService } from './meeting-element-api-generated.service';
import { MeetingElement, ReorderBasicInterface } from '@api/api-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingElementApiService extends GeneratedMeetingElementApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public reorder(params: ReorderBasicInterface): Observable<MeetingElement[]> {
    return this.repo.update('meeting-element/reorder', {
      ...params,
      idMeetingElement: Object.keys(params.values)[0]
    });
  }
}
