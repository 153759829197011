import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityUserProfil, CommunityUserProfilEntityState } from '@api/api-interfaces';
import { ElementLibrary, ElementLibraryEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { OrganizationUserProfil, OrganizationUserProfilEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityUserProfilState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityUserProfilRelations: string[] = [
  'elementLibraries',
  'communities',
  'users',
  'organizationUserProfils'
];

export const { selectEntities, selectAll } = CommunityUserProfilState.adapter.getSelectors();

export const selectCommunityUserProfilState = createFeatureSelector<CommunityUserProfilState.IState>(
  CommunityUserProfilState.communityUserProfilFeatureKey
);

export const selectIsLoadedCommunityUserProfil = createSelector(
  selectCommunityUserProfilState,
  (state: CommunityUserProfilState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityUserProfil = createSelector(
  selectCommunityUserProfilState,
  (state: CommunityUserProfilState.IState) => state.isLoading
);

export const selectIsReadyCommunityUserProfil = createSelector(
  selectCommunityUserProfilState,
  (state: CommunityUserProfilState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityUserProfil = createSelector(
  selectCommunityUserProfilState,
  (state: CommunityUserProfilState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityUserProfilsEntities = createSelector(selectCommunityUserProfilState, selectEntities);

export const selectCommunityUserProfilsArray = createSelector(selectCommunityUserProfilState, selectAll);

export const selectIdCommunityUserProfilsActive = createSelector(
  selectCommunityUserProfilState,
  (state: CommunityUserProfilState.IState) => state.actives
);

const communityUserProfilsInObject = (communityUserProfils: Dictionary<CommunityUserProfilEntityState>) => ({
  communityUserProfils
});

const selectCommunityUserProfilsEntitiesDictionary = createSelector(
  selectCommunityUserProfilsEntities,
  communityUserProfilsInObject
);

const selectAllCommunityUserProfilsObject = createSelector(selectCommunityUserProfilsEntities, communityUserProfils => {
  return hydrateAll({ communityUserProfils });
});

const selectOneCommunityUserProfilDictionary = (idCommunityUserProfil: number) =>
  createSelector(selectCommunityUserProfilsEntities, communityUserProfils => ({
    communityUserProfils: { [idCommunityUserProfil]: communityUserProfils[idCommunityUserProfil] }
  }));

const selectOneCommunityUserProfilDictionaryWithoutChild = (idCommunityUserProfil: number) =>
  createSelector(selectCommunityUserProfilsEntities, communityUserProfils => ({
    communityUserProfil: communityUserProfils[idCommunityUserProfil]
  }));

const selectActiveCommunityUserProfilsEntities = createSelector(
  selectIdCommunityUserProfilsActive,
  selectCommunityUserProfilsEntities,
  (actives: number[], communityUserProfils: Dictionary<CommunityUserProfilEntityState>) =>
    getCommunityUserProfilsFromActives(actives, communityUserProfils)
);

function getCommunityUserProfilsFromActives(
  actives: number[],
  communityUserProfils: Dictionary<CommunityUserProfilEntityState>
): Dictionary<CommunityUserProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityUserProfils[idActive]) {
      acc[idActive] = communityUserProfils[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityUserProfilEntityState>);
}

const selectAllCommunityUserProfilsSelectors: Dictionary<Selector> = {};
export function selectAllCommunityUserProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityUserProfil>(
      schema,
      selectAllCommunityUserProfilsSelectors,
      selectCommunityUserProfilsEntitiesDictionary,
      getRelationSelectors,
      communityUserProfilRelations,
      hydrateAll,
      'communityUserProfil'
    );
  } else {
    return selectAllCommunityUserProfilsObject;
  }
}

export function selectAllCommunityUserProfilsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityUserProfils'
): Selector {
  return createSelector(selectAllCommunityUserProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityUserProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityUserProfils.length; i++) {
      res[customKey][result.communityUserProfils[i].idCommunityUserProfil] = result.communityUserProfils[i];
    }
    return res;
  });
}

export function selectOneCommunityUserProfil(schema: SelectSchema = {}, idCommunityUserProfil: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityUserProfilDictionary(idCommunityUserProfil)];
    selectors.push(...getRelationSelectors(schema, communityUserProfilRelations, 'communityUserProfil'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityUserProfilDictionaryWithoutChild(idCommunityUserProfil);
  }
}

export function selectActiveCommunityUserProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityUserProfilsEntities, communityUserProfils => ({
      communityUserProfils
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityUserProfilRelations, 'communityUserProfil'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityUserProfils: Dictionary<CommunityUserProfilEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  users?: Dictionary<UserEntityState>;
  organizationUserProfils?: Dictionary<OrganizationUserProfilEntityState>;
  elementLibraries?: Dictionary<ElementLibraryEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityUserProfils: (CommunityUserProfil | null)[] } {
  const { communityUserProfils, communities, users, organizationUserProfils, elementLibraries } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communityUserProfils: Object.keys(communityUserProfils).map(idCommunityUserProfil =>
      hydrate(
        communityUserProfils[idCommunityUserProfil] as CommunityUserProfilEntityState,
        communities,
        users,
        organizationUserProfils,
        elementLibraries
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityUserProfil: CommunityUserProfilEntityState | null } {
  const { communityUserProfils, communities, users, organizationUserProfils, elementLibraries } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communityUserProfil = Object.values(communityUserProfils)[0];
  return {
    communityUserProfil: hydrate(
      communityUserProfil as CommunityUserProfilEntityState,
      communities,
      users,
      organizationUserProfils,
      elementLibraries
    )
  };
}

function hydrate(
  communityUserProfil: CommunityUserProfilEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  organizationUserProfilEntities?: Dictionary<OrganizationUserProfilEntityState>,
  elementLibraryEntities?: Dictionary<ElementLibraryEntityState>
): CommunityUserProfil | null {
  if (!communityUserProfil) {
    return null;
  }

  const communityUserProfilHydrated: CommunityUserProfilEntityState = { ...communityUserProfil };
  if (communityEntities) {
    communityUserProfilHydrated.community = communityEntities[communityUserProfil.community as number] as Community;
  } else {
    delete communityUserProfilHydrated.community;
  }
  if (userEntities) {
    communityUserProfilHydrated.user = userEntities[communityUserProfil.user as number] as User;
  } else {
    delete communityUserProfilHydrated.user;
  }
  if (organizationUserProfilEntities) {
    communityUserProfilHydrated.organizationUserProfil = organizationUserProfilEntities[
      communityUserProfil.organizationUserProfil as number
    ] as OrganizationUserProfil;
  } else {
    delete communityUserProfilHydrated.organizationUserProfil;
  }

  if (elementLibraryEntities) {
    communityUserProfilHydrated.elementLibraries = (
      (communityUserProfilHydrated.elementLibraries as number[]) || []
    ).map(id => elementLibraryEntities[id]) as ElementLibrary[];
  } else {
    delete communityUserProfilHydrated.elementLibraries;
  }

  return communityUserProfilHydrated as CommunityUserProfil;
}
