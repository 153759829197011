import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CapacitorService } from '@_app/core/device/capacitor-device-ready.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@wip/environments';
import { Effects } from './index.effects';
import { DocumentService } from '@_app/shared/elements/documents/document.service';
import { appState, metaReducers } from '@wip/store/configs/reducers';
import { ConfigurationApiService } from '@api/old-api-services';

@NgModule({
  imports: [
    StoreModule.forRoot(appState, {
      metaReducers: metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: false
      }
    }),
    EffectsModule.forRoot(Effects),
    environment.reduxTools ? StoreDevtoolsModule.instrument({ connectInZone: true }) : []
  ],
  providers: [CapacitorService, DocumentService, ConfigurationApiService]
})
export class NgrxModule {}
