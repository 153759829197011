import { createEntityAdapter } from '@ngrx/entity';
import { UserBoardStateEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<UserBoardStateEntityState>;

export const adapter = createEntityAdapter<UserBoardStateEntityState>({
  selectId: o => o.idUserBoardState
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const userBoardStateFeatureKey = 'userBoardState';
