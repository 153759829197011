import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityModuleGeneratedActions } from '@wip/store/actions';
import { CommunityModuleState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityModuleEntityState } from '@api/api-interfaces';

export const communityModuleReducersGeneratedFunctions: ReducerTypes<
  CommunityModuleState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunityModuleGeneratedActions.getOneCommunityModule, (state: CommunityModuleState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityModuleGeneratedActions.getManyCommunityModules, (state: CommunityModuleState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityModuleGeneratedActions.upsertOneCommunityModule, (state: CommunityModuleState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    CommunityModuleGeneratedActions.upsertManyCommunityModulesSuccess,
    (state: CommunityModuleState.IState, { communityModules }) =>
      CommunityModuleState.adapter.upsertMany(communityModules, setLoadingsState(state, false))
  ),
  on(CommunityModuleGeneratedActions.deleteOneCommunityModule, (state: CommunityModuleState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    CommunityModuleGeneratedActions.deleteOneCommunityModuleSuccess,
    (state: CommunityModuleState.IState, { idCommunityModule }) =>
      CommunityModuleState.adapter.removeOne(idCommunityModule, setLoadingsState(state, false))
  ),
  on(CommunityModuleGeneratedActions.clearActivesCommunityModules, (state: CommunityModuleState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    CommunityModuleGeneratedActions.addManyActivesCommunityModules,
    (state: CommunityModuleState.IState, { idCommunityModules }) => ({
      ...state,
      actives: state.actives.concat(idCommunityModules)
    })
  ),
  on(
    CommunityModuleGeneratedActions.deleteOneActiveCommunityModule,
    (state: CommunityModuleState.IState, { idCommunityModule }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityModule)
    })
  ),

  on(CommunityModuleGeneratedActions.clearCommunityModules, () => CommunityModuleState.initialState),

  on(
    CommunityModuleGeneratedActions.addCommunitySuccess,
    (state: CommunityModuleState.IState, { idCommunityModule, idCommunity }) => {
      if (!state.entities[idCommunityModule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityModule]: {
            ...state.entities[idCommunityModule],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityModuleGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityModuleState.IState, { idCommunities, idCommunityModules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityModules.reduce((entities, idCommunityModule) => {
            if (!state.entities[idCommunityModule]?.community) {
              return entities;
            }
            entities[idCommunityModule] = {
              ...state.entities[idCommunityModule],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityModule]?.community
              )
                ? undefined
                : state.entities[idCommunityModule]?.community
            } as CommunityModuleEntityState;
            return entities;
          }, {} as Dictionary<CommunityModuleEntityState>)
        }
      };
    }
  ),

  on(
    CommunityModuleGeneratedActions.addProjectModuleSuccess,
    (state: CommunityModuleState.IState, { idCommunityModule, idProjectModule }) => {
      if (!state.entities[idCommunityModule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityModule]: {
            ...state.entities[idCommunityModule],
            projectModule: idProjectModule
          }
        }
      };
    }
  ),

  on(
    CommunityModuleGeneratedActions.deleteManyProjectModuleSuccess,
    (state: CommunityModuleState.IState, { idProjectModules, idCommunityModules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityModules.reduce((entities, idCommunityModule) => {
            if (!state.entities[idCommunityModule]?.projectModule) {
              return entities;
            }
            entities[idCommunityModule] = {
              ...state.entities[idCommunityModule],
              projectModule: idProjectModules.some(
                (idProjectModule: number) => idProjectModule === state.entities[idCommunityModule]?.projectModule
              )
                ? undefined
                : state.entities[idCommunityModule]?.projectModule
            } as CommunityModuleEntityState;
            return entities;
          }, {} as Dictionary<CommunityModuleEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityModuleState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityModuleState.IState {
  return { ...state, isLoaded, isLoading };
}
