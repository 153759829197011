export enum ModuleNameToRoute {
  'Synthèse' = 'synthesis',
  'Planning' = 'planning',
  'Équipe' = 'team',
  'Caractéristiques' = 'caracteristics',
  'Réunions' = 'meetings',
  'Tâches' = 'todo',
  'Documents' = 'documents',
  'Trésorerie' = 'financialmanagement',
  'Notes' = 'notes',
  'Publications' = 'timeline',
  'Administration' = 'admin'
}

export enum ModuleRouteToName {
  'synthesis' = 'Synthèse',
  'planning' = 'Planning',
  'team' = 'Équipe',
  'caracteristics' = 'Caractéristiques',
  'meetings' = 'Réunions',
  'todo' = 'Tâches',
  'documents' = 'Documents',
  'financialmanagement' = 'Trésorerie',
  'notes' = 'Notes',
  'timeline' = 'Publications',
  'admin' = 'Administration'
}
