import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationSubFamily, OrganizationSubFamilyEntityState } from '@api/api-interfaces';
import { CommunitySubFamily, CommunitySubFamilyEntityState } from '@api/api-interfaces';
import { UserRight, UserRightEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationSubFamilyState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationSubFamilyRelations: string[] = [
  'communitySubFamilys',
  'userRights',
  'organizations',
  'organizationFamilys'
];

export const { selectEntities, selectAll } = OrganizationSubFamilyState.adapter.getSelectors();

export const selectOrganizationSubFamilyState = createFeatureSelector<OrganizationSubFamilyState.IState>(
  OrganizationSubFamilyState.organizationSubFamilyFeatureKey
);

export const selectIsLoadedOrganizationSubFamily = createSelector(
  selectOrganizationSubFamilyState,
  (state: OrganizationSubFamilyState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationSubFamily = createSelector(
  selectOrganizationSubFamilyState,
  (state: OrganizationSubFamilyState.IState) => state.isLoading
);

export const selectIsReadyOrganizationSubFamily = createSelector(
  selectOrganizationSubFamilyState,
  (state: OrganizationSubFamilyState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationSubFamily = createSelector(
  selectOrganizationSubFamilyState,
  (state: OrganizationSubFamilyState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationSubFamilysEntities = createSelector(selectOrganizationSubFamilyState, selectEntities);

export const selectOrganizationSubFamilysArray = createSelector(selectOrganizationSubFamilyState, selectAll);

export const selectIdOrganizationSubFamilysActive = createSelector(
  selectOrganizationSubFamilyState,
  (state: OrganizationSubFamilyState.IState) => state.actives
);

const organizationSubFamilysInObject = (organizationSubFamilys: Dictionary<OrganizationSubFamilyEntityState>) => ({
  organizationSubFamilys
});

const selectOrganizationSubFamilysEntitiesDictionary = createSelector(
  selectOrganizationSubFamilysEntities,
  organizationSubFamilysInObject
);

const selectAllOrganizationSubFamilysObject = createSelector(
  selectOrganizationSubFamilysEntities,
  organizationSubFamilys => {
    return hydrateAll({ organizationSubFamilys });
  }
);

const selectOneOrganizationSubFamilyDictionary = (idOrganizationSubFamily: number) =>
  createSelector(selectOrganizationSubFamilysEntities, organizationSubFamilys => ({
    organizationSubFamilys: { [idOrganizationSubFamily]: organizationSubFamilys[idOrganizationSubFamily] }
  }));

const selectOneOrganizationSubFamilyDictionaryWithoutChild = (idOrganizationSubFamily: number) =>
  createSelector(selectOrganizationSubFamilysEntities, organizationSubFamilys => ({
    organizationSubFamily: organizationSubFamilys[idOrganizationSubFamily]
  }));

const selectActiveOrganizationSubFamilysEntities = createSelector(
  selectIdOrganizationSubFamilysActive,
  selectOrganizationSubFamilysEntities,
  (actives: number[], organizationSubFamilys: Dictionary<OrganizationSubFamilyEntityState>) =>
    getOrganizationSubFamilysFromActives(actives, organizationSubFamilys)
);

function getOrganizationSubFamilysFromActives(
  actives: number[],
  organizationSubFamilys: Dictionary<OrganizationSubFamilyEntityState>
): Dictionary<OrganizationSubFamilyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationSubFamilys[idActive]) {
      acc[idActive] = organizationSubFamilys[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationSubFamilyEntityState>);
}

const selectAllOrganizationSubFamilysSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationSubFamilys(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationSubFamily>(
      schema,
      selectAllOrganizationSubFamilysSelectors,
      selectOrganizationSubFamilysEntitiesDictionary,
      getRelationSelectors,
      organizationSubFamilyRelations,
      hydrateAll,
      'organizationSubFamily'
    );
  } else {
    return selectAllOrganizationSubFamilysObject;
  }
}

export function selectAllOrganizationSubFamilysDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationSubFamilys'
): Selector {
  return createSelector(selectAllOrganizationSubFamilys(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationSubFamilyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationSubFamilys.length; i++) {
      res[customKey][result.organizationSubFamilys[i].idOrganizationSubFamily] = result.organizationSubFamilys[i];
    }
    return res;
  });
}

export function selectOneOrganizationSubFamily(schema: SelectSchema = {}, idOrganizationSubFamily: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationSubFamilyDictionary(idOrganizationSubFamily)];
    selectors.push(...getRelationSelectors(schema, organizationSubFamilyRelations, 'organizationSubFamily'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationSubFamilyDictionaryWithoutChild(idOrganizationSubFamily);
  }
}

export function selectActiveOrganizationSubFamilys(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationSubFamilysEntities, organizationSubFamilys => ({
      organizationSubFamilys
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationSubFamilyRelations, 'organizationSubFamily'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationSubFamilys: Dictionary<OrganizationSubFamilyEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  communitySubFamilys?: Dictionary<CommunitySubFamilyEntityState>;
  userRights?: Dictionary<UserRightEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationSubFamilys: (OrganizationSubFamily | null)[] } {
  const { organizationSubFamilys, organizations, organizationFamilys, communitySubFamilys, userRights } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationSubFamilys: Object.keys(organizationSubFamilys).map(idOrganizationSubFamily =>
      hydrate(
        organizationSubFamilys[idOrganizationSubFamily] as OrganizationSubFamilyEntityState,
        organizations,
        organizationFamilys,
        communitySubFamilys,
        userRights
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationSubFamily: OrganizationSubFamilyEntityState | null } {
  const { organizationSubFamilys, organizations, organizationFamilys, communitySubFamilys, userRights } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationSubFamily = Object.values(organizationSubFamilys)[0];
  return {
    organizationSubFamily: hydrate(
      organizationSubFamily as OrganizationSubFamilyEntityState,
      organizations,
      organizationFamilys,
      communitySubFamilys,
      userRights
    )
  };
}

function hydrate(
  organizationSubFamily: OrganizationSubFamilyEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  communitySubFamilyEntities?: Dictionary<CommunitySubFamilyEntityState>,
  userRightEntities?: Dictionary<UserRightEntityState>
): OrganizationSubFamily | null {
  if (!organizationSubFamily) {
    return null;
  }

  const organizationSubFamilyHydrated: OrganizationSubFamilyEntityState = { ...organizationSubFamily };
  if (organizationEntities) {
    organizationSubFamilyHydrated.organization = organizationEntities[
      organizationSubFamily.organization as number
    ] as Organization;
  } else {
    delete organizationSubFamilyHydrated.organization;
  }
  if (organizationFamilyEntities) {
    organizationSubFamilyHydrated.organizationFamily = organizationFamilyEntities[
      organizationSubFamily.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete organizationSubFamilyHydrated.organizationFamily;
  }

  if (communitySubFamilyEntities) {
    organizationSubFamilyHydrated.communitySubFamilys = (
      (organizationSubFamilyHydrated.communitySubFamilys as number[]) || []
    ).map(id => communitySubFamilyEntities[id]) as CommunitySubFamily[];
  } else {
    delete organizationSubFamilyHydrated.communitySubFamilys;
  }

  if (userRightEntities) {
    organizationSubFamilyHydrated.userRights = ((organizationSubFamilyHydrated.userRights as number[]) || []).map(
      id => userRightEntities[id]
    ) as UserRight[];
  } else {
    delete organizationSubFamilyHydrated.userRights;
  }

  return organizationSubFamilyHydrated as OrganizationSubFamily;
}
