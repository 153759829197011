import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { FolderGeneratedActions } from '@wip/store/actions';
import { FolderState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { FolderEntityState } from '@api/api-interfaces';

export const folderReducersGeneratedFunctions: ReducerTypes<FolderState.IState, readonly ActionCreator[]>[] = [
  on(FolderGeneratedActions.getOneFolder, (state: FolderState.IState) => setLoadingsState(state, true)),
  on(FolderGeneratedActions.getManyFolders, (state: FolderState.IState) => setLoadingsState(state, true)),
  on(FolderGeneratedActions.upsertOneFolder, (state: FolderState.IState) => setLoadingsState(state, true)),

  on(FolderGeneratedActions.upsertManyFoldersSuccess, (state: FolderState.IState, { folders }) =>
    FolderState.adapter.upsertMany(folders, setLoadingsState(state, false))
  ),
  on(FolderGeneratedActions.deleteOneFolder, (state: FolderState.IState) => setLoadingsState(state, true)),
  on(FolderGeneratedActions.deleteOneFolderSuccess, (state: FolderState.IState, { idFolder }) =>
    FolderState.adapter.removeOne(idFolder, setLoadingsState(state, false))
  ),
  on(FolderGeneratedActions.clearActivesFolders, (state: FolderState.IState) => ({ ...state, actives: [] })),
  on(FolderGeneratedActions.addManyActivesFolders, (state: FolderState.IState, { idFolders }) => ({
    ...state,
    actives: state.actives.concat(idFolders)
  })),
  on(FolderGeneratedActions.deleteOneActiveFolder, (state: FolderState.IState, { idFolder }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idFolder)
  })),

  on(FolderGeneratedActions.clearFolders, () => FolderState.initialState),

  on(
    FolderGeneratedActions.addManyFolderElementSuccess,
    (state: FolderState.IState, { idFolder, idFolderElements }) => {
      if (!state.entities[idFolder]) {
        return state;
      }
      const folderElements = (state.entities[idFolder]?.folderElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFolder]: {
            ...state.entities[idFolder],
            folderElements: folderElements.concat(idFolderElements.filter(id => folderElements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    FolderGeneratedActions.deleteManyFolderElementSuccess,
    (state: FolderState.IState, { idFolderElements, idFolders }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFolders.reduce((entities, idFolder) => {
            if (!state.entities[idFolder]?.folderElements) {
              return entities;
            }
            entities[idFolder] = {
              ...state.entities[idFolder],
              folderElements: (state.entities[idFolder]?.folderElements as number[])?.filter(
                (idFolderElement: number) => !idFolderElements.some((id: number) => id === idFolderElement)
              )
            } as FolderEntityState;
            return entities;
          }, {} as Dictionary<FolderEntityState>)
        }
      };
    }
  ),

  on(FolderGeneratedActions.addCommunitySuccess, (state: FolderState.IState, { idFolder, idCommunity }) => {
    if (!state.entities[idFolder]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idFolder]: {
          ...state.entities[idFolder],
          community: idCommunity
        }
      }
    };
  }),

  on(FolderGeneratedActions.deleteManyCommunitySuccess, (state: FolderState.IState, { idCommunities, idFolders }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idFolders.reduce((entities, idFolder) => {
          if (!state.entities[idFolder]?.community) {
            return entities;
          }
          entities[idFolder] = {
            ...state.entities[idFolder],
            community: idCommunities.some((idCommunity: number) => idCommunity === state.entities[idFolder]?.community)
              ? undefined
              : state.entities[idFolder]?.community
          } as FolderEntityState;
          return entities;
        }, {} as Dictionary<FolderEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: FolderState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): FolderState.IState {
  return { ...state, isLoaded, isLoading };
}
