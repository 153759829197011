import { Action, createReducer } from '@ngrx/store';
import { organizationDataColumnValueReducersGeneratedFunctions } from './organization-data-column-value-generated.reducer';
import { OrganizationDataColumnValueGeneratedState, OrganizationDataColumnValueState } from '@wip/store/states';

const organizationDataColumnValueReducersFunctions = [...organizationDataColumnValueReducersGeneratedFunctions];

const organizationDataColumnValueReducer = createReducer(
  OrganizationDataColumnValueGeneratedState.initialState,
  ...organizationDataColumnValueReducersFunctions
);

export function reducer(state: OrganizationDataColumnValueState.IState | undefined, action: Action) {
  return organizationDataColumnValueReducer(state, action);
}
