import { createEntityAdapter } from '@ngrx/entity';
import { RightEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<RightEntityState>;

export const adapter = createEntityAdapter<RightEntityState>({
  selectId: o => o.idRight
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const rightFeatureKey = 'right';
