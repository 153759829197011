import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { NotesHistory } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { NotesHistoryRelationsIds } from '@wip/store/ids-interfaces';
import { NotesHistoryGeneratedActions } from '@wip/store/actions';
import { NotesHistorySelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedNotesHistoryService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(NotesHistorySelectors.selectIsLoadedNotesHistory));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(NotesHistorySelectors.selectIsLoadingNotesHistory));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [NotesHistorySelectors.selectIsReadyAndLoadedNotesHistory as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllNotesHistories(schema: SelectSchema = {}): Observable<NotesHistory[]> {
    return this.store$.pipe(select(NotesHistorySelectors.selectAllNotesHistories(schema))).pipe(
      switchMap(({ notesHistories }: { notesHistories: NotesHistory[] }) => {
        return this.getReady(schema).pipe(mapTo(notesHistories));
      })
    );
  }

  public selectOneNotesHistory(idNotesHistory: number, schema: SelectSchema = {}): Observable<NotesHistory> {
    return this.store$.pipe(select(NotesHistorySelectors.selectOneNotesHistory(schema, idNotesHistory))).pipe(
      switchMap(({ notesHistory }: { notesHistory: NotesHistory }) => {
        return this.getReady(schema).pipe(mapTo(notesHistory));
      })
    );
  }

  public selectAllActiveNotesHistories(schema: SelectSchema = {}): Observable<NotesHistory[]> {
    return this.store$.pipe(select(NotesHistorySelectors.selectActiveNotesHistories(schema))).pipe(
      switchMap(({ notesHistories }: { notesHistories: NotesHistory[] }) => {
        return this.getReady(schema).pipe(mapTo(notesHistories));
      })
    );
  }

  public selectIdNotesHistoriesActive(): Observable<number[]> {
    return this.store$.pipe(select(NotesHistorySelectors.selectIdNotesHistoriesActive)).pipe(
      switchMap((idNotesHistories: number[]) => {
        return this.getReady().pipe(mapTo(idNotesHistories));
      })
    );
  }

  public getOneNotesHistory(
    idNotesHistory: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<NotesHistory> {
    this.store$.dispatch(NotesHistoryGeneratedActions.getOneNotesHistory({ idNotesHistory, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NotesHistoryGeneratedActions.normalizeManyNotesHistoriesAfterUpsert,
        NotesHistoryGeneratedActions.notesHistoriesFailure,
        true
      );
    }
  }

  public getManyNotesHistories(params: any = {}, getResult?: boolean): void | Observable<NotesHistory[]> {
    this.store$.dispatch(NotesHistoryGeneratedActions.getManyNotesHistories({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NotesHistoryGeneratedActions.normalizeManyNotesHistoriesAfterUpsert,
        NotesHistoryGeneratedActions.notesHistoriesFailure
      );
    }
  }

  public upsertOneNotesHistory(
    notesHistory: Partial<NotesHistory>,
    ids: NotesHistoryRelationsIds = {},
    getResult?: boolean
  ): void | Observable<NotesHistory> {
    this.store$.dispatch(NotesHistoryGeneratedActions.upsertOneNotesHistory({ notesHistory, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NotesHistoryGeneratedActions.normalizeManyNotesHistoriesAfterUpsert,
        NotesHistoryGeneratedActions.notesHistoriesFailure,
        true
      );
    }
  }

  public deleteOneNotesHistory(idNotesHistory: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(NotesHistoryGeneratedActions.deleteOneNotesHistory({ idNotesHistory }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NotesHistoryGeneratedActions.deleteOneNotesHistorySuccess,
        NotesHistoryGeneratedActions.notesHistoriesFailure
      );
    }
  }

  public setActiveNotesHistories(idNotesHistories: number[]): void {
    this.store$.dispatch(NotesHistoryGeneratedActions.clearActivesNotesHistories());
    this.store$.dispatch(NotesHistoryGeneratedActions.addManyActivesNotesHistories({ idNotesHistories }));
  }
}
