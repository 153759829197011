import { createEntityAdapter } from '@ngrx/entity';
import { FolderElementEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<FolderElementEntityState>;

export const adapter = createEntityAdapter<FolderElementEntityState>({
  selectId: o => o.idFolderElement
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const folderElementFeatureKey = 'folderElement';
