import { RepositoryService } from '@wip/services/repository';
import { ProjectModule } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedProjectModuleApiService {
  constructor(protected repo: RepositoryService) {}

  public getProjectModules(params?: any): Observable<ProjectModule[]> {
    return this.repo.getData<ProjectModule[]>('project-module', params);
  }

  public getProjectModule(params: { idProjectModule: number; params?: any }): Observable<ProjectModule> {
    return this.repo.getData<ProjectModule>('project-module/' + params.idProjectModule, params.params);
  }

  public addProjectModule(projectModule: Partial<ProjectModule>): Observable<ProjectModule> {
    return this.repo.create<ProjectModule>('project-module', projectModule);
  }

  public updateProjectModule(projectModule: Partial<ProjectModule>): Observable<ProjectModule> {
    return this.repo.update('project-module', projectModule);
  }

  public deleteProjectModule(idProjectModule: number): Observable<number> {
    return this.repo.delete('project-module/' + +idProjectModule);
  }
}
