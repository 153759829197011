import { RepositoryService } from '@wip/services/repository';
import { OrganizationCaracteristicColumn } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationCaracteristicColumnApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationCaracteristicColumns(params?: any): Observable<OrganizationCaracteristicColumn[]> {
    return this.repo.getData<OrganizationCaracteristicColumn[]>('organization-caracteristic-column', params);
  }

  public getOrganizationCaracteristicColumn(params: {
    idOrganizationCaracteristicColumn: number;
    params?: any;
  }): Observable<OrganizationCaracteristicColumn> {
    return this.repo.getData<OrganizationCaracteristicColumn>(
      'organization-caracteristic-column/' + params.idOrganizationCaracteristicColumn,
      params.params
    );
  }

  public addOrganizationCaracteristicColumn(
    organizationCaracteristicColumn: Partial<OrganizationCaracteristicColumn>
  ): Observable<OrganizationCaracteristicColumn> {
    return this.repo.create<OrganizationCaracteristicColumn>(
      'organization-caracteristic-column',
      organizationCaracteristicColumn
    );
  }

  public updateOrganizationCaracteristicColumn(
    organizationCaracteristicColumn: Partial<OrganizationCaracteristicColumn>
  ): Observable<OrganizationCaracteristicColumn> {
    return this.repo.update('organization-caracteristic-column', organizationCaracteristicColumn);
  }

  public deleteOrganizationCaracteristicColumn(idOrganizationCaracteristicColumn: number): Observable<number> {
    return this.repo.delete('organization-caracteristic-column/' + +idOrganizationCaracteristicColumn);
  }
}
