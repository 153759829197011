import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationElementGeneratedActions } from '@wip/store/actions';
import { OrganizationElementState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationElementEntityState } from '@api/api-interfaces';

export const organizationElementReducersGeneratedFunctions: ReducerTypes<
  OrganizationElementState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationElementGeneratedActions.getOneOrganizationElement, (state: OrganizationElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationElementGeneratedActions.getManyOrganizationElements, (state: OrganizationElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationElementGeneratedActions.upsertOneOrganizationElement, (state: OrganizationElementState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationElementGeneratedActions.upsertManyOrganizationElementsSuccess,
    (state: OrganizationElementState.IState, { organizationElements }) =>
      OrganizationElementState.adapter.upsertMany(organizationElements, setLoadingsState(state, false))
  ),
  on(OrganizationElementGeneratedActions.deleteOneOrganizationElement, (state: OrganizationElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationElementGeneratedActions.deleteOneOrganizationElementSuccess,
    (state: OrganizationElementState.IState, { idOrganizationElement }) =>
      OrganizationElementState.adapter.removeOne(idOrganizationElement, setLoadingsState(state, false))
  ),
  on(
    OrganizationElementGeneratedActions.clearActivesOrganizationElements,
    (state: OrganizationElementState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationElementGeneratedActions.addManyActivesOrganizationElements,
    (state: OrganizationElementState.IState, { idOrganizationElements }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationElements)
    })
  ),
  on(
    OrganizationElementGeneratedActions.deleteOneActiveOrganizationElement,
    (state: OrganizationElementState.IState, { idOrganizationElement }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationElement)
    })
  ),

  on(OrganizationElementGeneratedActions.clearOrganizationElements, () => OrganizationElementState.initialState),

  on(
    OrganizationElementGeneratedActions.addOrganizationSuccess,
    (state: OrganizationElementState.IState, { idOrganizationElement, idOrganization }) => {
      if (!state.entities[idOrganizationElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationElement]: {
            ...state.entities[idOrganizationElement],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationElementGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationElementState.IState, { idOrganizations, idOrganizationElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationElements.reduce((entities, idOrganizationElement) => {
            if (!state.entities[idOrganizationElement]?.organization) {
              return entities;
            }
            entities[idOrganizationElement] = {
              ...state.entities[idOrganizationElement],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationElement]?.organization
              )
                ? undefined
                : state.entities[idOrganizationElement]?.organization
            } as OrganizationElementEntityState;
            return entities;
          }, {} as Dictionary<OrganizationElementEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationElementGeneratedActions.addElementSuccess,
    (state: OrganizationElementState.IState, { idOrganizationElement, idElement }) => {
      if (!state.entities[idOrganizationElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationElement]: {
            ...state.entities[idOrganizationElement],
            element: idElement
          }
        }
      };
    }
  ),

  on(
    OrganizationElementGeneratedActions.deleteManyElementSuccess,
    (state: OrganizationElementState.IState, { idElements, idOrganizationElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationElements.reduce((entities, idOrganizationElement) => {
            if (!state.entities[idOrganizationElement]?.element) {
              return entities;
            }
            entities[idOrganizationElement] = {
              ...state.entities[idOrganizationElement],
              element: idElements.some(
                (idElement: number) => idElement === state.entities[idOrganizationElement]?.element
              )
                ? undefined
                : state.entities[idOrganizationElement]?.element
            } as OrganizationElementEntityState;
            return entities;
          }, {} as Dictionary<OrganizationElementEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationElementState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationElementState.IState {
  return { ...state, isLoaded, isLoading };
}
