import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationRisk, OrganizationRiskEntityState } from '@api/api-interfaces';
import { CommunityRisk, CommunityRiskEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationRiskState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationRiskRelations: string[] = ['communityRisks', 'organizations', 'organizationFamilys'];

export const { selectEntities, selectAll } = OrganizationRiskState.adapter.getSelectors();

export const selectOrganizationRiskState = createFeatureSelector<OrganizationRiskState.IState>(
  OrganizationRiskState.organizationRiskFeatureKey
);

export const selectIsLoadedOrganizationRisk = createSelector(
  selectOrganizationRiskState,
  (state: OrganizationRiskState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationRisk = createSelector(
  selectOrganizationRiskState,
  (state: OrganizationRiskState.IState) => state.isLoading
);

export const selectIsReadyOrganizationRisk = createSelector(
  selectOrganizationRiskState,
  (state: OrganizationRiskState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationRisk = createSelector(
  selectOrganizationRiskState,
  (state: OrganizationRiskState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationRisksEntities = createSelector(selectOrganizationRiskState, selectEntities);

export const selectOrganizationRisksArray = createSelector(selectOrganizationRiskState, selectAll);

export const selectIdOrganizationRisksActive = createSelector(
  selectOrganizationRiskState,
  (state: OrganizationRiskState.IState) => state.actives
);

const organizationRisksInObject = (organizationRisks: Dictionary<OrganizationRiskEntityState>) => ({
  organizationRisks
});

const selectOrganizationRisksEntitiesDictionary = createSelector(
  selectOrganizationRisksEntities,
  organizationRisksInObject
);

const selectAllOrganizationRisksObject = createSelector(selectOrganizationRisksEntities, organizationRisks => {
  return hydrateAll({ organizationRisks });
});

const selectOneOrganizationRiskDictionary = (idOrganizationRisk: number) =>
  createSelector(selectOrganizationRisksEntities, organizationRisks => ({
    organizationRisks: { [idOrganizationRisk]: organizationRisks[idOrganizationRisk] }
  }));

const selectOneOrganizationRiskDictionaryWithoutChild = (idOrganizationRisk: number) =>
  createSelector(selectOrganizationRisksEntities, organizationRisks => ({
    organizationRisk: organizationRisks[idOrganizationRisk]
  }));

const selectActiveOrganizationRisksEntities = createSelector(
  selectIdOrganizationRisksActive,
  selectOrganizationRisksEntities,
  (actives: number[], organizationRisks: Dictionary<OrganizationRiskEntityState>) =>
    getOrganizationRisksFromActives(actives, organizationRisks)
);

function getOrganizationRisksFromActives(
  actives: number[],
  organizationRisks: Dictionary<OrganizationRiskEntityState>
): Dictionary<OrganizationRiskEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationRisks[idActive]) {
      acc[idActive] = organizationRisks[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationRiskEntityState>);
}

const selectAllOrganizationRisksSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationRisks(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationRisk>(
      schema,
      selectAllOrganizationRisksSelectors,
      selectOrganizationRisksEntitiesDictionary,
      getRelationSelectors,
      organizationRiskRelations,
      hydrateAll,
      'organizationRisk'
    );
  } else {
    return selectAllOrganizationRisksObject;
  }
}

export function selectAllOrganizationRisksDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationRisks'
): Selector {
  return createSelector(selectAllOrganizationRisks(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationRiskEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationRisks.length; i++) {
      res[customKey][result.organizationRisks[i].idOrganizationRisk] = result.organizationRisks[i];
    }
    return res;
  });
}

export function selectOneOrganizationRisk(schema: SelectSchema = {}, idOrganizationRisk: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationRiskDictionary(idOrganizationRisk)];
    selectors.push(...getRelationSelectors(schema, organizationRiskRelations, 'organizationRisk'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationRiskDictionaryWithoutChild(idOrganizationRisk);
  }
}

export function selectActiveOrganizationRisks(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationRisksEntities, organizationRisks => ({
      organizationRisks
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationRiskRelations, 'organizationRisk'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationRisks: Dictionary<OrganizationRiskEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  communityRisks?: Dictionary<CommunityRiskEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationRisks: (OrganizationRisk | null)[] } {
  const { organizationRisks, organizations, organizationFamilys, communityRisks } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationRisks: Object.keys(organizationRisks).map(idOrganizationRisk =>
      hydrate(
        organizationRisks[idOrganizationRisk] as OrganizationRiskEntityState,
        organizations,
        organizationFamilys,
        communityRisks
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationRisk: OrganizationRiskEntityState | null } {
  const { organizationRisks, organizations, organizationFamilys, communityRisks } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationRisk = Object.values(organizationRisks)[0];
  return {
    organizationRisk: hydrate(
      organizationRisk as OrganizationRiskEntityState,
      organizations,
      organizationFamilys,
      communityRisks
    )
  };
}

function hydrate(
  organizationRisk: OrganizationRiskEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  communityRiskEntities?: Dictionary<CommunityRiskEntityState>
): OrganizationRisk | null {
  if (!organizationRisk) {
    return null;
  }

  const organizationRiskHydrated: OrganizationRiskEntityState = { ...organizationRisk };
  if (organizationEntities) {
    organizationRiskHydrated.organization = organizationEntities[
      organizationRisk.organization as number
    ] as Organization;
  } else {
    delete organizationRiskHydrated.organization;
  }
  if (organizationFamilyEntities) {
    organizationRiskHydrated.organizationFamily = organizationFamilyEntities[
      organizationRisk.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete organizationRiskHydrated.organizationFamily;
  }

  if (communityRiskEntities) {
    organizationRiskHydrated.communityRisks = ((organizationRiskHydrated.communityRisks as number[]) || []).map(
      id => communityRiskEntities[id]
    ) as CommunityRisk[];
  } else {
    delete organizationRiskHydrated.communityRisks;
  }

  return organizationRiskHydrated as OrganizationRisk;
}
