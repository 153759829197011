import { CapacitorModule } from '@_app/core/device/capacitor.module';
import { ElectronService } from '@_app/core/device/electron.service';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GanttLinkApiService, ProjectElementApiService } from '@wip/store/api-services';
import { DeviceInformationsService } from './device/device-information.service';
import { MomentLocaleService } from './locals/locale.service';
import {
  AdminApiService,
  ConfigurationApiService,
  RequestInterceptorService,
  UsersDeviceApiService
} from '@api/old-api-services';

@NgModule({
  imports: [CommonModule, CapacitorModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    }
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        DeviceInformationsService,
        MomentLocaleService,
        ConfigurationApiService,
        GanttLinkApiService,
        ProjectElementApiService,
        RequestInterceptorService,
        TranslateService,
        UsersDeviceApiService,
        ElectronService,
        AdminApiService
      ]
    };
  }
}
