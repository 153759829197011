import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChildrenElement, ChildrenElementEntityState } from '@api/api-interfaces';
import { ChildrenElementRelationsIds } from '@wip/store/ids-interfaces';

export const ChildrenElementGeneratedActions = createActionGroup({
  source: 'Children Element Generated',
  events: {
    'Get One Children Element': props<{ idChildrenElement: number; params?: any }>(),
    'Get Many Children Elements': props<{ params: any }>(),
    'Add Many Actives Children Elements': props<{ idChildrenElements: number[] }>(),
    'Delete One Active Children Element': props<{ idChildrenElement: number }>(),
    'Clear Actives Children Elements': emptyProps(),
    'Upsert One Children Element': props<{
      childrenElement: Partial<ChildrenElement>;
      ids?: ChildrenElementRelationsIds;
    }>(),
    'Upsert Many Children Elements': props<{
      childrenElements: Partial<ChildrenElement>[];
      ids?: ChildrenElementRelationsIds;
    }>(),
    'Upsert Many Children Elements Success': props<{ childrenElements: ChildrenElementEntityState[] }>(),
    'Delete One Children Element': props<{ idChildrenElement: number }>(),
    'Delete One Children Element Success': props<{ idChildrenElement: number }>(),
    'Normalize Many Children Elements After Upsert': props<{ childrenElements: ChildrenElementEntityState[] }>(),
    'Children Elements Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Children Elements': emptyProps(),
    'Add Element Success': props<{ idChildrenElement: number; idElement: number }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idChildrenElements: number[] }>()
  }
});
