<div class="h-full" (keyup.enter)="$event.stopPropagation()">
  <div class="h-full" [ngSwitch]="displayList">
    <!-- DROPDOWN AUTOCOMPLETE-->
    <div *ngSwitchCase="'dropDown'">
      <mat-form-field [floatLabel]="floatLabel">
        <mat-label>Responsables</mat-label>
        <mat-chip-grid addOnBlur="false" disabled="false" #chipGrid (click)="setFocus()">
          @for (metaUser of metaUsersSelected; track metaUser) {
            <mat-chip-row [removable]="!metaUser.readOnly || !isViewer" (removed)="removeMetaUser(metaUser)">
              <app-chip-content [metaUser]="metaUser" />
              <button matChipRemove [attr.aria-label]="supprimer">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          }
        </mat-chip-grid>

        <input
          matInput
          placeholder="{{ textPlaceholder || 'Choisissez-en un' }}"
          [matAutocomplete]="auto"
          [formControl]="myControl"
          [matChipInputFor]="chipGrid"
          #UserInput
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="addMetaUserFromAutocomplete($event)"
          panelWidth="290px"
        >
          @if ((!suggestedElementsDisplay.length || !suggestedElementsDisplay[0].users.length) && didTyped) {
            <mat-optgroup [label]="'Pas de résultats'">
              <mat-option style="display: none"> </mat-option>
            </mat-optgroup>
          }

          @for (suggestedElementDisplay of suggestedElementsDisplay; track suggestedElementDisplay) {
            <mat-optgroup [label]="suggestedElementDisplay.label" class="flex flex-col gap">
              @for (metaUser of suggestedElementDisplay.users; track getUserId) {
                <mat-option [value]="metaUser" [ngSwitch]="metaUser.type" class="user-row flex flex-row">
                  <div *ngSwitchCase="'user_idUser'" class="flex flex-row gap-8 justify-start items-center">
                    <!--other-->
                    <app-avatar
                      class="items-center justify-center"
                      size="medium"
                      [height]="28"
                      [tooltip]="false"
                      [metaUser]="metaUser"
                      [check]="true"
                      class="flex justify-center items-center"
                    >
                    </app-avatar>
                    <div class="flex flex-col">
                      @if (metaUser.user) {
                        <div style="max-width: 180px; overflow: hidden">
                          {{ metaUser.user.prenom + ' ' }} <strong>{{ metaUser.user.nom }}</strong>
                          {{ metaUser.user.email }}
                        </div>
                      }
                    </div>
                  </div>
                  <div *ngSwitchCase="'user_profile'" class="flex flex-row justify-start items-center">
                    <!--role-->

                    @if (metaUser) {
                      <div class="test flex flex-row gap-8">
                        <!-- // myControl.value-->
                        <app-avatar
                          class="md-contact-avatar flex justify-center items-center"
                          size="micro"
                          [metaUser]="metaUser"
                          [height]="28"
                        >
                        </app-avatar>
                        <div class="flex flex-col justify-center items-start">
                          <strong>
                            {{
                              metaUser.idOrganizationUserProfil
                                ? metaUser.organizationUserProfil?.libelle
                                : metaUser.libelle
                            }}
                          </strong>
                          @if (metaUser.user && metaUser.user.idUser) {
                            <span>
                              {{ metaUser.user.prenom }}
                              <strong>{{ metaUser.user.nom }}</strong>
                            </span>
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <div *ngSwitchCase="'user_nom'">{{ metaUser.nom }}</div>
                </mat-option>
              }
            </mat-optgroup>
          }
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- LIST -->
    <div class="h-full flex flex-col" *ngSwitchCase="'list'">
      <mat-form-field class="flex-none users-input-override" [floatLabel]="floatLabel">
        <mat-chip-list class="no-padding-left search-chip" addOnBlur="false" disabled="false" #chipList>
          @for (metaUser of metaUsersSelected | slice: 0 : 10; track metaUser) {
            <mat-chip [removable]="!metaUser.readOnly" (removed)="removeMetaUser(metaUser)" class="flex flex-row">
              <app-chip-content [metaUser]="metaUser" />
              <button class="item" matChipRemove [attr.aria-label]="supprimer">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          }
          <input
            matInput
            autocomplete="off"
            type="text"
            placeholder="{{ textPlaceholder || 'Choisissez-en un' }}"
            [formControl]="myControl"
            autofocus
            #UserInput
          />
        </mat-chip-list>
      </mat-form-field>

      <cdk-virtual-scroll-viewport itemSize="24" class="flex-1" style="display: block; min-height: 300px">
        <div
          *cdkVirtualFor="let suggestedElementDisplay of suggestedElementsDisplay; let i = index"
          class="flex flex-col"
        >
          <mat-nav-list class="flex flex-1 box-border">
            <div class="list-header flex flex-row justify-start items-center">
              <mat-checkbox
                class="flex justify-center items-center"
                (click)="selectMetaUsersGroupAtIndex(i)"
                [(ngModel)]="suggestedElementDisplay.checked"
              >
              </mat-checkbox>
              <h3 matSubheader class="suggested-subheader">{{ suggestedElementDisplay.label }}</h3>
            </div>
            @for (metaUser of suggestedElementDisplay.users; track metaUser) {
              <mat-selection-list class="flex flex-row" (click)="addMetaUser(metaUser); setFocus()">
                @if (metaUser.user) {
                  <div class="flex flex-row gap-8">
                    <mat-checkbox
                      class="flex justify-center items-center"
                      [(ngModel)]="metaUser.checked"
                      (click)="clickFromCheckbox(metaUser)"
                    >
                    </mat-checkbox>
                    <div class="flex justify-start items-center">
                      <app-avatar style="margin-right: 10px" size="micro" [metaUser]="metaUser" [height]="28">
                      </app-avatar>
                      <div>
                        @if (metaUser.user.nom) {
                          <span class="checkbox-item-label">
                            {{ metaUser.user.prenom }}
                            <b>{{ metaUser.user.nom }}</b>
                          </span>
                        }

                        @if (metaUser.user.email) {
                          <div>
                            {{ metaUser.user.email }}
                          </div>
                        }
                      </div>
                    </div>

                    @if (metaUser.invite) {
                      <div>
                        <span class="checkbox-item-label">
                          <b>{{ metaUser.user.email }}</b>
                        </span>
                      </div>
                    }
                  </div>
                }

                @if (metaUser.info) {
                  <div class="flex flex-row">
                    {{ metaUser.info }}
                  </div>
                }
                <mat-divider />
              </mat-selection-list>
            }
          </mat-nav-list></div
      ></cdk-virtual-scroll-viewport>
    </div>

    @if (suggestedElementsDisplay.length === 0 && displayList === 'list' && didTyped) {
      <div>Pas de résultats</div>
    }
  </div>
</div>
