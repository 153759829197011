import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccessRightEnum } from '@enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationUserApiService } from '@wip/store/api-services';
import * as AppState from '@wip/store/configs/reducers';
import { Observable, Subject, map } from 'rxjs';
import { CommunityService } from './community.service';
import { OldUserService } from './oldUser.service';
import { GeneratedOrganizationUserService } from './organization-user-generated.service';
import { UserBoardState } from '@api/api-interfaces';
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class OrganizationUserService extends GeneratedOrganizationUserService {
  public isCurrentUserAdmin: boolean;
  public editMode$ = new Subject<boolean>();
  public createConfiguration$ = new Subject<boolean>();
  public saveConfiguration$ = new Subject<boolean>();
  public saveColumns$ = new Subject<boolean>();
  public handleConfiguration$ = new Subject<boolean>();
  public setUpdateButton$ = new Subject<boolean>();
  public setButtons$ = new Subject<Partial<UserBoardState>>();
  public toggleSideBar$ = new Subject<boolean>();

  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private organizationUserApiService: OrganizationUserApiService,
    private communityService: CommunityService,
    private oldUserService: OldUserService
  ) {
    super(store$, actions$);
  }

  public setSelectedOrganisationForUser(oldIdOrganizationUser: number, newIdOrganizationUser: number) {
    this.upsertOneOrganizationUser({
      idOrganizationUser: oldIdOrganizationUser,
      selected: false
    });
    this.upsertOneOrganizationUser({
      idOrganizationUser: newIdOrganizationUser,
      selected: true
    });
    this.setActiveOrganizationUsers([newIdOrganizationUser]);
  }

  public checkUserRights(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    const idCommunity = route.parent?.params['idCommunity'];
    if (this.communityService.currentIdCommunity === +idCommunity) {
      return this.communityService.selectAllActiveCommunities().pipe(
        map(comms => comms[0]),
        map(comm => {
          if (comm.communityRights?.navigation.includes(AccessRightEnum[route.url[0].path as keyof AccessRightEnum])) {
            return true;
          } else {
            this.oldUserService.logOut(true);
          }
        })
      );
    }
    return this.organizationUserApiService.getRightsForCommunity(idCommunity).pipe(
      map(res => {
        if ((res as any).data.navigation.includes(AccessRightEnum[route.url[0].path])) {
          return true;
        } else {
          this.oldUserService.logOut(true);
        }
      })
    );
  }
}
