import { OrganizationUserProfilState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationUserProfilReducersGeneratedFunctions } from './organization-user-profil-generated.reducer';

const organizationUserProfilReducersFunctions = [...organizationUserProfilReducersGeneratedFunctions];

const organizationUserProfilReducer = createReducer(
  OrganizationUserProfilState.initialState,
  ...organizationUserProfilReducersFunctions
);

export function reducer(state: OrganizationUserProfilState.IState | undefined, action: Action) {
  return organizationUserProfilReducer(state, action);
}
