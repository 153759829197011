<!-- <mat-form-field class="remove-infix background-grey" style="width: 150px">
  <mat-select placeholder="Plannings" [formControl]="filtersGroup.get('organizationMilestoneFamily')">
    <mat-option [value]="0"> Aucun </mat-option>
    @for (
      organizationMilestoneFamily of organizationFamily.organizationMilestoneFamilys;
      track organizationMilestoneFamily.idOrganizationMilestoneFamily
    ) {
      <mat-option [value]="organizationMilestoneFamily.idOrganizationMilestoneFamily">
        {{ organizationMilestoneFamily.libelle }}
      </mat-option>
    }
  </mat-select>
</mat-form-field> -->

<div class="flex flex-row items-center">
  <div
    appearance="fill"
    class="outlined-field flex items-center"
    style="position: relative; width: 160px; height: 32px; cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.1)"
  >
    <mat-select
      [formControl]="filtersGroup.get('organizationMilestoneFamily')"
      style="padding: 0 4px; font-size: 12px"
      #matSelect
    >
      <mat-option [value]="0"> Aucun </mat-option>
      @for (
        organizationMilestoneFamily of organizationFamily.organizationMilestoneFamilys;
        track organizationMilestoneFamily.idOrganizationMilestoneFamily
      ) {
        <mat-option [value]="organizationMilestoneFamily.idOrganizationMilestoneFamily">
          {{ organizationMilestoneFamily.libelle }}
        </mat-option>
      }
    </mat-select>
  </div>
</div>
