import { ElementCashState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { elementCashReducersGeneratedFunctions } from './element-cash-generated.reducer';

const elementCashReducersFunctions = [...elementCashReducersGeneratedFunctions];

const elementCashReducer = createReducer(ElementCashState.initialState, ...elementCashReducersFunctions);

export function reducer(state: ElementCashState.IState | undefined, action: Action) {
  return elementCashReducer(state, action);
}
