import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GroupApiService } from '@wip/store/api-services';
import { GeneratedGroupEffects } from './group-generated.effects';

@Injectable()
export class GroupEffects extends GeneratedGroupEffects {
  constructor(actions$: Actions, groupApiService: GroupApiService, store$: Store<AppState>) {
    super(actions$, groupApiService, store$);
  }
}
