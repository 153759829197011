import { Action, createReducer } from '@ngrx/store';
import { organizationCaracteristicRowReducersGeneratedFunctions } from './organization-caracteristic-row-generated.reducer';
import { OrganizationCaracteristicRowState } from '@wip/store/states';

const organizationCaracteristicRowReducersFunctions = [...organizationCaracteristicRowReducersGeneratedFunctions];

const organizationCaracteristicRowReducer = createReducer(
  OrganizationCaracteristicRowState.initialState,
  ...organizationCaracteristicRowReducersFunctions
);

export function reducer(state: OrganizationCaracteristicRowState.IState | undefined, action: Action) {
  return organizationCaracteristicRowReducer(state, action);
}
