import { RepositoryService } from '@wip/services/repository';
import { OrganizationCaracteristicRow } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationCaracteristicRowApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationCaracteristicRows(params?: any): Observable<OrganizationCaracteristicRow[]> {
    return this.repo.getData<OrganizationCaracteristicRow[]>('organization-caracteristic-row', params);
  }

  public getOrganizationCaracteristicRow(params: {
    idOrganizationCaracteristicRow: number;
    params?: any;
  }): Observable<OrganizationCaracteristicRow> {
    return this.repo.getData<OrganizationCaracteristicRow>(
      'organization-caracteristic-row/' + params.idOrganizationCaracteristicRow,
      params.params
    );
  }

  public addOrganizationCaracteristicRow(
    organizationCaracteristicRow: Partial<OrganizationCaracteristicRow>
  ): Observable<OrganizationCaracteristicRow> {
    return this.repo.create<OrganizationCaracteristicRow>(
      'organization-caracteristic-row',
      organizationCaracteristicRow
    );
  }

  public updateOrganizationCaracteristicRow(
    organizationCaracteristicRow: Partial<OrganizationCaracteristicRow>
  ): Observable<OrganizationCaracteristicRow> {
    return this.repo.update('organization-caracteristic-row', organizationCaracteristicRow);
  }

  public deleteOrganizationCaracteristicRow(idOrganizationCaracteristicRow: number): Observable<number> {
    return this.repo.delete('organization-caracteristic-row/' + +idOrganizationCaracteristicRow);
  }
}
