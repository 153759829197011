import { RepositoryService } from '@wip/services/repository';
import { OrganizationCaracteristicValue } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationCaracteristicValueApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationCaracteristicValues(params?: any): Observable<OrganizationCaracteristicValue[]> {
    return this.repo.getData<OrganizationCaracteristicValue[]>('organization-caracteristic-value', params);
  }

  public getOrganizationCaracteristicValue(params: {
    idOrganizationCaracteristicValue: number;
    params?: any;
  }): Observable<OrganizationCaracteristicValue> {
    return this.repo.getData<OrganizationCaracteristicValue>(
      'organization-caracteristic-value/' + params.idOrganizationCaracteristicValue,
      params.params
    );
  }

  public addOrganizationCaracteristicValue(
    organizationCaracteristicValue: Partial<OrganizationCaracteristicValue>
  ): Observable<OrganizationCaracteristicValue> {
    return this.repo.create<OrganizationCaracteristicValue>(
      'organization-caracteristic-value',
      organizationCaracteristicValue
    );
  }

  public updateOrganizationCaracteristicValue(
    organizationCaracteristicValue: Partial<OrganizationCaracteristicValue>
  ): Observable<OrganizationCaracteristicValue> {
    return this.repo.update('organization-caracteristic-value', organizationCaracteristicValue);
  }

  public deleteOrganizationCaracteristicValue(idOrganizationCaracteristicValue: number): Observable<number> {
    return this.repo.delete('organization-caracteristic-value/' + +idOrganizationCaracteristicValue);
  }
}
