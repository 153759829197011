import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@wip/store/configs/reducers';
import { TodoApiService } from '@wip/store/api-services';
import { GeneratedTodoEffects } from './todo-generated.effects';
@Injectable()
export class TodoEffects extends GeneratedTodoEffects {
  constructor(actions$: Actions, todoApiService: TodoApiService, store$: Store<AppState>) {
    super(actions$, todoApiService, store$);
  }
}
