import { DurationPickerDialogComponent } from '@_app/shared/dialogs/duration-picker-dialog/duration-picker-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Community, GanttReorderInterface, Link, Task } from '@api/api-interfaces';
import {
  DroitInterneEnum,
  DurationFormat,
  ElementStatusActionEnum,
  ElementStatusActionEnumNext,
  TodoTypeEnum
} from '@enums';
import { Gantt, GanttStatic } from '@wip/gantt-static';
import { OrganizationFamilyModel } from '@wip/store/selectors-model';
import { CommunityService, GanttLinkService, ProjectElementService, UserService } from '@wip/store/services';
import dayjs from 'dayjs';
import { Subject, map, tap } from 'rxjs';
import { CycleFoundDialogComponent } from '../cycle-found/cycle-found-dialog.component';
import { NewActionFormContainerComponent } from '../new-action-form-container/new-action-form-container.component';
import { durationFormatterParams } from '../new-gantt.component';
import { getIntDurationFromStringDuration, setNextDatesFilter } from '../utils/new-gantt-templates';
import { LinkService } from './link.service';
import { MoveTaskStrategy, TaskService } from './task.service';
@Injectable()
export class GanttEventsService {
  public gantt: GanttStatic;
  private dialogRef = null;
  public moveTaskStrategy: MoveTaskStrategy;
  private idLinksToDelete: number[] = [];
  public resetData$ = new Subject<void>();
  public currentCommunity: Community;

  constructor(
    private dialog: MatDialog,
    private linkService: LinkService,
    private taskService: TaskService,
    private ganttLinkService: GanttLinkService,
    private projectElementService: ProjectElementService,
    private router: Router,
    private route: ActivatedRoute,
    private communityService: CommunityService,
    private userService: UserService
  ) {
    this.gantt = Gantt.getGanttInstance();

    this.communityService
      .selectAllActiveCommunities({ include: [OrganizationFamilyModel] })
      .pipe(
        map(communities => communities[0]),
        tap(com => (this.currentCommunity = com))
      )
      .subscribe();
  }

  public handleClickout() {
    document.addEventListener('click', event => {
      const editor = document.querySelector('this.gantt');
      const ganttTask = document.querySelector('.gantt_task_bg');
      const ganttResizer = document.querySelector('.gantt_resizer');
      const ganttGridScale = document.querySelector('.gantt_grid_scale');

      if (this.gantt.ext.inlineEditors._columnName === 'start_date') {
        if (editor && !editor.contains(event.target as Node)) {
          this.gantt.ext.inlineEditors.save();
        } else if (ganttTask && ganttTask.contains(event.target as Node)) {
          this.gantt.ext.inlineEditors.save();
        } else if (ganttResizer && ganttResizer.contains(event.target as Node)) {
          this.gantt.ext.inlineEditors.save();
        } else if (ganttGridScale && ganttGridScale.contains(event.target as Node)) {
          this.gantt.ext.inlineEditors.save();
        }
      }
    });
  }

  public onBeforeTaskDisplay(): string {
    return this.gantt.attachEvent('onBeforeTaskDisplay', (_id, task) => {
      const filterParams = this.projectElementService.filterParams.getValue();
      if (task.text === 'rootProject') {
        return false;
      }
      if (
        filterParams?.textFilter?.length &&
        task.type !== 'project' &&
        !task.text.toLowerCase().includes(filterParams.textFilter.toLowerCase())
      ) {
        return false;
      }
      if (filterParams?.milestoneFilter && !(task.type === 'milestone') && task.type !== 'project') {
        return false;
      }
      if (
        filterParams?.nextDatesFilter &&
        !setNextDatesFilter(task, this.currentCommunity.organizationFamily?.ganttLite ? 'start_date' : 'end_date')
      ) {
        return false;
      }
      if (
        filterParams?.responsibleFilter &&
        task.responsibles &&
        !task.responsibles.some(
          r =>
            r.idUser === filterParams?.responsibleFilter ||
            r.communityUserProfil?.idUser === filterParams?.responsibleFilter ||
            r.idCommunityUserProfil === filterParams?.responsibleFilter
        )
      ) {
        return false;
      }
      return true;
    });
  }

  public onTaskClick(): string {
    return this.gantt.attachEvent('onTaskClick', (id: number, e) => {
      const task = this.gantt.getTask(id);
      if (e.target.classList.contains('checkbox-style-' + id)) {
        this.changeStatusAction(task, this.gantt);
        return false;
      } else {
        this.gantt.selectTask(id);
        return true;
      }
    });
  }

  private changeStatusAction(task, _gantt) {
    if (task.canUpdate) {
      task.statusAction = ElementStatusActionEnumNext[task.statusAction];
      this.gantt.updateTask(task.id);
      this.taskService.save({
        idElement: task.idElement,
        statusAction: task.statusAction,
        rightNeeded: DroitInterneEnum.elementUpdate
      });
    }
  }

  public onLinkDblClick(): string {
    return this.gantt.attachEvent('onLinkDblClick', (id: number, _) => {
      const link = this.gantt.getLink(id);
      const source = this.gantt.getTask(link.source);
      const target = this.gantt.getTask(link.target);

      const dialogRef = this.dialog.open(DurationPickerDialogComponent, {
        data: {
          duration: link.lag,
          text: 'Lien entre ' + source.text + ' et ' + target.text
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res === 'delete') {
          this.linkService.delete(link.id);
        } else if (res !== link.lag) {
          this.ganttLinkService.upsertOneGanttLink({
            idGanttLink: +link.id,
            lag: res
          });
        }
      });
      return false;
    });
  }

  public onTaskDblClick(): string {
    return this.gantt.attachEvent('onTaskDblClick', (id: number, e) => {
      const task = this.gantt.getTask(id);

      if (e.target.classList.contains('dbclick') && task.type !== 'project') {
        this.openTaskModal(id, this.currentCommunity);
      } else if (task.type === 'project') {
        this.gantt.message({ type: 'warning', text: 'Activez le mode édition pour renommer les groupements' });
      }

      return false;
    });
  }

  public openTaskModal(id: number, community: Community) {
    this.router.navigate([], {
      queryParams: {
        edit: id
      },
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });

    if (this.dialogRef) {
      return;
    }

    const task = this.gantt.getTask(id);

    this.dialogRef = this.dialog.open(NewActionFormContainerComponent, {
      data: {
        type: task.type,
        idProjectElement: id,
        idElement: task.idElement,
        community: community,
        rightNeeded: DroitInterneEnum.elementUpdate,
        constraintType: task.constraint_type,
        isEndDateComputed: task.isEndDateComputed,
        isTaskLinked: task.$source.length || task.$target.length,
        isViewer: !task.canUpdate,
        businessDay: community.organizationFamily?.businessDay,
        ganttLinksRelated: this.gantt.getLinks().filter(link => link.source === +id || link.target === +id)
      },
      maxWidth: '100vw',
      maxHeight: '97vh',
      width: task.type === 'project' ? '400px' : '700px',
      panelClass: ['custom-dialog-fullheight', 'custom-dialog-fullscreen-xs'],
      disableClose: true,
      autoFocus: false
    });

    this.dialogRef.afterClosed().subscribe(res => {
      if (res) {
        task.statusAction = res.statusAction.value;
        this.gantt.updateTask(task.id);
        this.gantt.autoSchedule();
      }
      this.dialogRef = null;
    });

    return this.dialogRef;
  }

  public onBeforeRowDragMove(): string {
    return this.gantt.attachEvent('onBeforeRowDragMove', (id: number, _) => {
      const task = this.gantt.getTask(id);
      return task.editable;
    });
  }

  public onBeforeRowDragEnd(): string {
    return this.gantt.attachEvent('onBeforeRowDragEnd', (id: number, targetId) => {
      const task = this.gantt.getTask(id);
      if (!task.editable) {
        this.gantt.message({ type: 'warning', text: 'Activez le mode édition pour déplacer les lignes' });
        return false;
      }
      if (targetId === 0) {
        return true;
      }
      const target = this.gantt.getTask(targetId);
      if (target?.type === 'task' || target?.type === 'milestone') {
        return false;
      }
      return true;
    });
  }

  public onRowDragEnd(): string {
    return this.gantt.attachEvent('onRowDragEnd', (_id: number) => {
      const reorder: GanttReorderInterface = {
        idCommunity: this.communityService.currentIdCommunity,
        tasks: {}
      };

      this.gantt.eachTask(task => {
        task.index = task.$index;
        reorder['tasks'][task.id] = {
          index: task.$index,
          parent: task.parent
        };
      });
      this.taskService.reorder(reorder);
      return true;
    });
  }

  public onBeforeEditStart(): string {
    return this.gantt.ext.inlineEditors.attachEvent(
      'onBeforeEditStart',
      (state: { id: string | number; columnName: string }) => {
        const task = this.gantt.getTask(state.id);
        if (state.columnName === 'text' && task.editable) {
          return true;
        }
        if (
          state.columnName === 'text' ||
          (task.type === 'project' && state.columnName !== 'edit') ||
          (task.type === 'milestone' && (state.columnName === 'end_date' || state.columnName === 'duration'))
        ) {
          return false;
        }
        return true;
      }
    ) as unknown as string;
  }

  public onSave(): string {
    return this.gantt.ext.inlineEditors.attachEvent(
      'onSave',
      (state: { id: string | number; columnName: string; oldValue: any; newValue: any }) => {
        const task = this.gantt.getTask(+state.id);

        if (state.columnName === 'start_date') {
          this.taskService.updateTaskCustom(
            task as Task,
            this.moveTaskStrategy,
            state.newValue,
            state.oldValue,
            this.gantt
          );
        } else if (state.columnName === 'end_date' || state.columnName === 'duration') {
          this.gantt.updateTask(task.id);
          this.gantt.autoSchedule(task.id);
        } else if (state.columnName === 'text') {
          this.gantt.updateTask(task.id);
          this.taskService.saveProjectElement({
            type: task.type,
            idProjectElement: +task.id,
            idElement: +task.idElement,
            libelle: state.newValue
          });
        } else if (state.columnName === 'previous') {
          const links = this.gantt.getLinks().filter(link => link.target === +state.id) as Link[];
          if (links.length && !state.newValue.length) {
            this.linkService.delete(links[0].id);
            return;
          }
          const newSource = this.gantt.getTaskBy('index', +state.newValue);

          if (!newSource.length || newSource[0].id === +state.id) {
            return;
          }

          let newLink;
          if (links.length) {
            newLink = links[0];
            newLink.source = newSource[0].id;
            this.gantt.updateLink(newLink.id);
          } else {
            this.gantt.addLink({
              source: +newSource[0].id,
              target: +state.id,
              lag: 0,
              type: '0'
            });
          }
          this.gantt.autoSchedule();
        } else if (state.columnName === 'lag') {
          const links = this.gantt.getLinks().filter(link => link.target === +state.id) as Link[];
          if (links.length) {
            const lag = getIntDurationFromStringDuration(
              state.newValue,
              this.currentCommunity.organizationFamily?.businessDay
            );
            links[0].lag = lag;
            this.gantt.updateLink(links[0].id);
            this.gantt.autoSchedule(task.id);
          }
        } else if (state.columnName === 'predecessor_and_lag') {
          const predecessors = state.newValue.split(';').filter(p => p.length);

          const oldLinks = this.gantt.getLinks().filter(link => link.target === +state.id);
          if (!state.newValue.length) {
            oldLinks.forEach(link => {
              this.linkService.delete(link.id);
            });
            return;
          }

          const linksAlreadyDone = [];
          predecessors.forEach((prev, index) => {
            const parsed: string[] = prev
              .toLocaleLowerCase()
              .match(/([+-]?\d+(?:\.\d+)?)|([A-Z]+)|(-\d+(?:\.\d+)?)|([a-z])/g);
            if (linksAlreadyDone.includes(parsed[0])) {
              return;
            }
            linksAlreadyDone.push(parsed[0]);
            const sourceIndex = parsed[0];
            const taskCode = this.gantt.getWBSCode(this.gantt.getTaskByIndex(sourceIndex));
            parsed[0] = taskCode;
            const newParsed = parsed.join('');

            const linkFormatter = this.gantt.ext.formatters.linkFormatter({
              durationFormatter: this.gantt.ext.formatters.durationFormatter(
                durationFormatterParams(this.currentCommunity.organizationFamily.businessDay, DurationFormat.auto)
              ),
              labels: {
                finish_to_start: 'FD',
                start_to_start: 'DD',
                finish_to_finish: 'FF',
                start_to_finish: 'DF'
              }
            });
            const newParams = linkFormatter.parse(newParsed) as Link;
            if (index + 1 <= oldLinks.length) {
              const link = oldLinks[index];
              link.lag = newParams.lag;
              link.type = newParams.type;
              link.source = newParams.source;
              this.gantt.updateLink(link.id);
            } else {
              this.gantt.addLink({
                ...newParams,
                target: task.id
              });
            }
          });

          let index = predecessors.length;
          if (predecessors.length < oldLinks.length) {
            while (oldLinks[index]) {
              this.linkService.delete(oldLinks[index].id);
              this.gantt.deleteLink(oldLinks[index].id);
              index++;
            }
          }
          this.gantt.autoSchedule(task.id);
        }
      }
    ) as unknown as string;
  }

  public onAfterAutoSchedule() {
    return this.gantt.attachEvent('onAfterAutoSchedule', (taskId: number, updatedTasks: any) => {
      const scheduledTasks = updatedTasks.map(t => this.gantt.getTask(t) as Task);
      let tasks = scheduledTasks;
      if (taskId) {
        this.communityService.upsertOneCommunity({
          idCommunity: this.communityService.currentIdCommunity,
          projetDernierMajDate: new Date(),
          projetDernierMajUser: this.userService.currentIdUser
        });
        const task = this.gantt.getTask(taskId);
        if (
          (task.statusAction === ElementStatusActionEnum.open ||
            task.statusAction === ElementStatusActionEnum.progress) &&
          dayjs(task.end_date).isBefore(dayjs(new Date()).hour(3))
        ) {
          task.statusAction = ElementStatusActionEnum.close;
        }
        tasks = [task, ...scheduledTasks];
      }
      const links = this.gantt.getLinks().map(l => l as Link);
      setTimeout(_ => {
        this.taskService.saveAll(
          { idCommunity: this.communityService.currentIdCommunity, tasks, links },
          this.idLinksToDelete
        );
        this.idLinksToDelete = [];
      });
    });
  }

  public onAfterTaskAdd() {
    return this.gantt.attachEvent('onAfterTaskAdd', (idTask: number) => {
      const task = this.gantt.getTask(idTask);
      const type = task.type === 'project' ? TodoTypeEnum.project : TodoTypeEnum.projectElement;
      this.taskService.createTask(task, this.communityService.currentIdCommunity, type);
      this.gantt.autoSchedule();
    });
  }

  public onAfterLinkAdd() {
    return this.gantt.attachEvent('onAfterLinkAdd', (idLink: number) => {
      this.idLinksToDelete.push(idLink);
    });
  }

  public onAfterTaskDelete() {
    return this.gantt.attachEvent('onAfterTaskDelete', (id: number, _task: Task) => {
      this.ganttLinkService.linksToDeactivate = this.ganttLinkService.linksToDeactivate.concat(
        this.gantt
          .getLinks()
          .filter(link => link.target === id || link.source === id)
          .map(link => +link.id)
      );
      this.projectElementService.deleteOneProjectElementCustom(id);
      this.taskService.firstScheduleDone = false;
    });
  }

  public openCycleDetail() {
    let tasks: any[] = [];
    let ganttTasks: any[] = [];

    const ganttCycles = this.gantt?.findCycles();

    ganttCycles.forEach(elem => {
      tasks = tasks.concat(elem.tasks);
    });
    tasks.forEach(taskId => {
      ganttTasks = ganttTasks.concat(this.gantt.getTask(taskId));
    });
    this.dialog.open(CycleFoundDialogComponent, {
      width: '600px',
      height: '600px',
      data: { ganttTasks }
    });
  }
}
