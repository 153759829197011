import { ConstraintTypeEnum } from '@enums';
import { TaskFormatInspector } from './TaskFormatInspector.interface';

interface DumpTaskElement {
  isEndDateComputed: boolean;
  constraintType: ConstraintTypeEnum;
}

export class DumpTaskElementFormatInspector implements TaskFormatInspector {
  private element: DumpTaskElement;

  constructor(elementToInspect: DumpTaskElement) {
    this.element = elementToInspect;
  }

  public durationMustBeDisplayedInBold(): boolean {
    function callbackIfTrue(): boolean {
      if (!this.element.isEndDateComputed && this.element.constraintType === ConstraintTypeEnum.MSO) {
        return false;
      } else {
        return true;
      }
    }

    function callbackIfFalse(): boolean {
      return !this.startDateMustBeDisplayedInBold() && !this.endDateMustBeDisplayedInBold();
    }

    return this.checkIsContraintTypeExists(callbackIfTrue.bind(this), callbackIfFalse.bind(this));
  }

  public startDateMustBeDisplayedInBold(): boolean {
    function callbackIfTrue(): boolean {
      if (
        (this.element.constraintType === ConstraintTypeEnum.MFO && !this.element.isEndDateComputed) ||
        this.element.constraintType === ConstraintTypeEnum.ASAP
      ) {
        return false;
      } else {
        return true;
      }
    }

    return this.checkIsContraintTypeExists(callbackIfTrue.bind(this));
  }

  public endDateMustBeDisplayedInBold(): boolean {
    function callbackIfTrue(): boolean {
      if (
        (this.element.constraintType === ConstraintTypeEnum.MSO && this.element.isEndDateComputed) ||
        this.element.constraintType === ConstraintTypeEnum.ASAP
      ) {
        return false;
      } else {
        return true;
      }
    }

    return this.checkIsContraintTypeExists(callbackIfTrue.bind(this));
  }

  private checkIsContraintTypeExists(callbackIfTrue: () => boolean, callbackIfFalse?: () => boolean): boolean {
    return this.element.constraintType ? callbackIfTrue() : callbackIfFalse ? callbackIfFalse() : false;
  }
}
