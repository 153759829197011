import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityUser, CommunityUserEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityUserState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityUserRelations: string[] = ['communities', 'users'];

export const { selectEntities, selectAll } = CommunityUserState.adapter.getSelectors();

export const selectCommunityUserState = createFeatureSelector<CommunityUserState.IState>(
  CommunityUserState.communityUserFeatureKey
);

export const selectIsLoadedCommunityUser = createSelector(
  selectCommunityUserState,
  (state: CommunityUserState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityUser = createSelector(
  selectCommunityUserState,
  (state: CommunityUserState.IState) => state.isLoading
);

export const selectIsReadyCommunityUser = createSelector(
  selectCommunityUserState,
  (state: CommunityUserState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityUser = createSelector(
  selectCommunityUserState,
  (state: CommunityUserState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityUsersEntities = createSelector(selectCommunityUserState, selectEntities);

export const selectCommunityUsersArray = createSelector(selectCommunityUserState, selectAll);

export const selectIdCommunityUsersActive = createSelector(
  selectCommunityUserState,
  (state: CommunityUserState.IState) => state.actives
);

const communityUsersInObject = (communityUsers: Dictionary<CommunityUserEntityState>) => ({ communityUsers });

const selectCommunityUsersEntitiesDictionary = createSelector(selectCommunityUsersEntities, communityUsersInObject);

const selectAllCommunityUsersObject = createSelector(selectCommunityUsersEntities, communityUsers => {
  return hydrateAll({ communityUsers });
});

const selectOneCommunityUserDictionary = (idCommunityUser: number) =>
  createSelector(selectCommunityUsersEntities, communityUsers => ({
    communityUsers: { [idCommunityUser]: communityUsers[idCommunityUser] }
  }));

const selectOneCommunityUserDictionaryWithoutChild = (idCommunityUser: number) =>
  createSelector(selectCommunityUsersEntities, communityUsers => ({
    communityUser: communityUsers[idCommunityUser]
  }));

const selectActiveCommunityUsersEntities = createSelector(
  selectIdCommunityUsersActive,
  selectCommunityUsersEntities,
  (actives: number[], communityUsers: Dictionary<CommunityUserEntityState>) =>
    getCommunityUsersFromActives(actives, communityUsers)
);

function getCommunityUsersFromActives(
  actives: number[],
  communityUsers: Dictionary<CommunityUserEntityState>
): Dictionary<CommunityUserEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityUsers[idActive]) {
      acc[idActive] = communityUsers[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityUserEntityState>);
}

const selectAllCommunityUsersSelectors: Dictionary<Selector> = {};
export function selectAllCommunityUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityUser>(
      schema,
      selectAllCommunityUsersSelectors,
      selectCommunityUsersEntitiesDictionary,
      getRelationSelectors,
      communityUserRelations,
      hydrateAll,
      'communityUser'
    );
  } else {
    return selectAllCommunityUsersObject;
  }
}

export function selectAllCommunityUsersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityUsers'
): Selector {
  return createSelector(selectAllCommunityUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityUserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityUsers.length; i++) {
      res[customKey][result.communityUsers[i].idCommunityUser] = result.communityUsers[i];
    }
    return res;
  });
}

export function selectOneCommunityUser(schema: SelectSchema = {}, idCommunityUser: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityUserDictionary(idCommunityUser)];
    selectors.push(...getRelationSelectors(schema, communityUserRelations, 'communityUser'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityUserDictionaryWithoutChild(idCommunityUser);
  }
}

export function selectActiveCommunityUsers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityUsersEntities, communityUsers => ({
      communityUsers
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityUserRelations, 'communityUser'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityUsers: Dictionary<CommunityUserEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityUsers: (CommunityUser | null)[] } {
  const { communityUsers, communities, users } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    communityUsers: Object.keys(communityUsers).map(idCommunityUser =>
      hydrate(communityUsers[idCommunityUser] as CommunityUserEntityState, communities, users)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityUser: CommunityUserEntityState | null } {
  const { communityUsers, communities, users } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const communityUser = Object.values(communityUsers)[0];
  return {
    communityUser: hydrate(communityUser as CommunityUserEntityState, communities, users)
  };
}

function hydrate(
  communityUser: CommunityUserEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  userEntities?: Dictionary<UserEntityState>
): CommunityUser | null {
  if (!communityUser) {
    return null;
  }

  const communityUserHydrated: CommunityUserEntityState = { ...communityUser };
  if (communityEntities) {
    communityUserHydrated.community = communityEntities[communityUser.community as number] as Community;
  } else {
    delete communityUserHydrated.community;
  }
  if (userEntities) {
    communityUserHydrated.user = userEntities[communityUser.user as number] as User;
  } else {
    delete communityUserHydrated.user;
  }

  return communityUserHydrated as CommunityUser;
}
