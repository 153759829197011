import { createEntityAdapter } from '@ngrx/entity';
import { RightProfilEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<RightProfilEntityState>;

export const adapter = createEntityAdapter<RightProfilEntityState>({
  selectId: o => o.idRightProfil
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const rightProfilFeatureKey = 'rightProfil';
