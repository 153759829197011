import { DroitInterneEnum } from './droit-interne.enum';

export enum AccessRightEnum {
  timeline = DroitInterneEnum.timelineAccess,
  documents = DroitInterneEnum.documentsAccess,
  meetings = DroitInterneEnum.meetingsAccess,
  todo = DroitInterneEnum.todosAccess,
  notes = DroitInterneEnum.notesAccess,
  admin = DroitInterneEnum.adminAccess,
  planning = DroitInterneEnum.planningAccess,
  team = DroitInterneEnum.teamAccess,
  caracteristics = DroitInterneEnum.caracteristicsAccess
}
