import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import * as ElementLibraryAction from '@wip/store/actions';
import { GeneratedElementLibraryService } from './element-library-generated.service';
@Injectable({
  providedIn: 'root'
})
export class ElementLibraryService extends GeneratedElementLibraryService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public changeResponsibles(data: { responsibles: any[]; idElement: number }, getResult?: boolean) {
    this.store$.dispatch(ElementLibraryAction.changeResponsibles(data));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementLibraryAction.ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert,
        ElementLibraryAction.ElementLibraryGeneratedActions.elementLibrariesFailure,
        true
      );
    }
  }
}
