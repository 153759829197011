import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Todo, TodoEntityState } from '@api/api-interfaces';
import { TodoRelationsIds } from '@wip/store/ids-interfaces';

export const TodoGeneratedActions = createActionGroup({
  source: 'Todo Generated',
  events: {
    'Get One Todo': props<{ idTodo: number; params?: any }>(),
    'Get Many Todos': props<{ params: any }>(),
    'Add Many Actives Todos': props<{ idTodos: number[] }>(),
    'Delete One Active Todo': props<{ idTodo: number }>(),
    'Clear Actives Todos': emptyProps(),
    'Upsert One Todo': props<{ todo: Partial<Todo>; ids?: TodoRelationsIds }>(),
    'Upsert Many Todos': props<{ todos: Partial<Todo>[]; ids?: TodoRelationsIds }>(),
    'Upsert Many Todos Success': props<{ todos: TodoEntityState[] }>(),
    'Delete One Todo': props<{ idTodo: number }>(),
    'Delete One Todo Success': props<{ idTodo: number }>(),
    'Normalize Many Todos After Upsert': props<{ todos: TodoEntityState[] }>(),
    'Todos Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Todos': emptyProps(),
    'Add Many Todo Element Success': props<{ idTodo: number; idTodoElements: number[] }>(),
    'Delete Many Todo Element Success': props<{ idTodoElements: number[]; idTodos: number[] }>(),
    'Add Many Element Success': props<{ idTodo: number; idElements: number[] }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idTodos: number[] }>(),
    'Add Community Success': props<{ idTodo: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idTodos: number[] }>()
  }
});
