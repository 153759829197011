import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationKpi, OrganizationKpiEntityState } from '@api/api-interfaces';
import { OrganizationKpiApiService } from '@wip/store/api-services';
import { OrganizationKpiGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationKpi } from '@wip/store/configs/normalization';
import { OrganizationKpiSelectors } from '@wip/store/selectors';
import { OrganizationKpiRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityKpiGeneratedActions } from '@wip/store/actions';
import { CommunityKpi } from '@api/api-interfaces';

export function getDefaultAddOrganizationKpiActions(
  organizationKpi: OrganizationKpiEntityState,
  ids?: OrganizationKpiRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationKpiGeneratedActions.normalizeManyOrganizationKpisAfterUpsert({ organizationKpis: [organizationKpi] })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationKpiSuccess({
        idOrganization: ids.organization,
        idOrganizationKpis: [organizationKpi.idOrganizationKpi]
      })
    );
    actions.push(
      OrganizationKpiGeneratedActions.addOrganizationSuccess({
        idOrganizationKpi: organizationKpi.idOrganizationKpi,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyOrganizationKpiSuccess({
        idOrganizationFamily: ids.organizationFamily,
        idOrganizationKpis: [organizationKpi.idOrganizationKpi]
      })
    );
    actions.push(
      OrganizationKpiGeneratedActions.addOrganizationFamilySuccess({
        idOrganizationKpi: organizationKpi.idOrganizationKpi,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.communityKpis) {
    if (!Array.isArray(ids.communityKpis)) {
      actions.push(
        CommunityKpiGeneratedActions.upsertOneCommunityKpi({
          communityKpi: {
            idOrganizationKpi: organizationKpi.idOrganizationKpi,
            idCommunityKpi: ids.communityKpis as number
          } as CommunityKpi
        })
      );
      actions.push(
        OrganizationKpiGeneratedActions.addManyCommunityKpiSuccess({
          idOrganizationKpi: organizationKpi.idOrganizationKpi,
          idCommunityKpis: [ids.communityKpis as number]
        })
      );
    } else {
      actions.push(
        CommunityKpiGeneratedActions.upsertManyCommunityKpis({
          communityKpis: (ids.communityKpis as number[]).map((idCommunityKpi: number) => ({
            idOrganizationKpi: organizationKpi.idOrganizationKpi,
            idCommunityKpi
          })) as CommunityKpi[]
        })
      );
      actions.push(
        OrganizationKpiGeneratedActions.addManyCommunityKpiSuccess({
          idOrganizationKpi: organizationKpi.idOrganizationKpi,
          idCommunityKpis: ids.communityKpis as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationKpiActions(organizationKpi: OrganizationKpiEntityState): Action[] {
  const actions: Action[] = [
    OrganizationKpiGeneratedActions.deleteOneOrganizationKpiSuccess({
      idOrganizationKpi: organizationKpi.idOrganizationKpi
    })
  ];

  if (organizationKpi.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationKpiSuccess({
        idOrganizationKpis: [organizationKpi.idOrganizationKpi],
        idOrganizations: [organizationKpi.organization as number]
      })
    );
  }

  if (organizationKpi.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationKpiSuccess({
        idOrganizationKpis: [organizationKpi.idOrganizationKpi],
        idOrganizationFamilys: [organizationKpi.organizationFamily as number]
      })
    );
  }

  if (organizationKpi.communityKpis) {
    actions.push(
      CommunityKpiGeneratedActions.deleteManyOrganizationKpiSuccess({
        idOrganizationKpis: [organizationKpi.idOrganizationKpi],
        idCommunityKpis: organizationKpi.communityKpis as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationKpiEffects {
  constructor(
    protected actions$: Actions,
    protected organizationKpiApiService: OrganizationKpiApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationKpis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationKpiGeneratedActions.getManyOrganizationKpis),
      switchMap(({ params }) =>
        this.organizationKpiApiService.getOrganizationKpis(params).pipe(
          map((organizationKpis: OrganizationKpi[]) => {
            return OrganizationKpiGeneratedActions.normalizeManyOrganizationKpisAfterUpsert({ organizationKpis });
          }),
          catchError(error => of(OrganizationKpiGeneratedActions.organizationKpisFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationKpi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationKpiGeneratedActions.getOneOrganizationKpi),
      switchMap(idOrganizationKpi =>
        this.organizationKpiApiService.getOrganizationKpi(idOrganizationKpi).pipe(
          map((organizationKpi: OrganizationKpi) => {
            return OrganizationKpiGeneratedActions.normalizeManyOrganizationKpisAfterUpsert({
              organizationKpis: [organizationKpi]
            });
          }),
          catchError(error => of(OrganizationKpiGeneratedActions.organizationKpisFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationKpi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationKpiGeneratedActions.upsertOneOrganizationKpi),
      concatMap(
        ({
          organizationKpi,
          ids
        }: {
          organizationKpi: Partial<OrganizationKpi>;
          ids?: OrganizationKpiRelationsIds;
        }) => {
          if (organizationKpi.idOrganizationKpi) {
            return this.organizationKpiApiService.updateOrganizationKpi(organizationKpi).pipe(
              map((organizationKpiReturned: OrganizationKpi) => {
                return OrganizationKpiGeneratedActions.normalizeManyOrganizationKpisAfterUpsert({
                  organizationKpis: [organizationKpiReturned]
                });
              }),
              catchError(error => of(OrganizationKpiGeneratedActions.organizationKpisFailure({ error })))
            );
          } else {
            return this.organizationKpiApiService.addOrganizationKpi(organizationKpi).pipe(
              mergeMap((organizationKpiReturned: OrganizationKpi) =>
                getDefaultAddOrganizationKpiActions(organizationKpiReturned, ids)
              ),
              catchError(error => of(OrganizationKpiGeneratedActions.organizationKpisFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationKpi$ = createEffect(() => {
    const selectOrganizationKpiState$ = this.store$.select(OrganizationKpiSelectors.selectOrganizationKpiState);
    return this.actions$.pipe(
      ofType(OrganizationKpiGeneratedActions.deleteOneOrganizationKpi),
      withLatestFrom(selectOrganizationKpiState$),
      concatMap(([{ idOrganizationKpi }, state]) =>
        this.organizationKpiApiService.deleteOrganizationKpi(idOrganizationKpi).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationKpiActions(state.entities[idOrganizationKpi] as OrganizationKpiEntityState)
          ),
          catchError(error => of(OrganizationKpiGeneratedActions.organizationKpisFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationKpisAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationKpiGeneratedActions.normalizeManyOrganizationKpisAfterUpsert),
      concatMap(({ organizationKpis }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationKpi(organizationKpis, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganizationKpi] Normalization After Upsert Success')];
      })
    );
  });
}
