import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationAzure, OrganizationAzureEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationAzureState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationAzureRelations: string[] = ['organizations', 'groups'];

export const { selectEntities, selectAll } = OrganizationAzureState.adapter.getSelectors();

export const selectOrganizationAzureState = createFeatureSelector<OrganizationAzureState.IState>(
  OrganizationAzureState.organizationAzureFeatureKey
);

export const selectIsLoadedOrganizationAzure = createSelector(
  selectOrganizationAzureState,
  (state: OrganizationAzureState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationAzure = createSelector(
  selectOrganizationAzureState,
  (state: OrganizationAzureState.IState) => state.isLoading
);

export const selectIsReadyOrganizationAzure = createSelector(
  selectOrganizationAzureState,
  (state: OrganizationAzureState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationAzure = createSelector(
  selectOrganizationAzureState,
  (state: OrganizationAzureState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationAzuresEntities = createSelector(selectOrganizationAzureState, selectEntities);

export const selectOrganizationAzuresArray = createSelector(selectOrganizationAzureState, selectAll);

export const selectIdOrganizationAzuresActive = createSelector(
  selectOrganizationAzureState,
  (state: OrganizationAzureState.IState) => state.actives
);

const organizationAzuresInObject = (organizationAzures: Dictionary<OrganizationAzureEntityState>) => ({
  organizationAzures
});

const selectOrganizationAzuresEntitiesDictionary = createSelector(
  selectOrganizationAzuresEntities,
  organizationAzuresInObject
);

const selectAllOrganizationAzuresObject = createSelector(selectOrganizationAzuresEntities, organizationAzures => {
  return hydrateAll({ organizationAzures });
});

const selectOneOrganizationAzureDictionary = (idOrganizationAzure: number) =>
  createSelector(selectOrganizationAzuresEntities, organizationAzures => ({
    organizationAzures: { [idOrganizationAzure]: organizationAzures[idOrganizationAzure] }
  }));

const selectOneOrganizationAzureDictionaryWithoutChild = (idOrganizationAzure: number) =>
  createSelector(selectOrganizationAzuresEntities, organizationAzures => ({
    organizationAzure: organizationAzures[idOrganizationAzure]
  }));

const selectActiveOrganizationAzuresEntities = createSelector(
  selectIdOrganizationAzuresActive,
  selectOrganizationAzuresEntities,
  (actives: number[], organizationAzures: Dictionary<OrganizationAzureEntityState>) =>
    getOrganizationAzuresFromActives(actives, organizationAzures)
);

function getOrganizationAzuresFromActives(
  actives: number[],
  organizationAzures: Dictionary<OrganizationAzureEntityState>
): Dictionary<OrganizationAzureEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationAzures[idActive]) {
      acc[idActive] = organizationAzures[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationAzureEntityState>);
}

const selectAllOrganizationAzuresSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationAzures(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationAzure>(
      schema,
      selectAllOrganizationAzuresSelectors,
      selectOrganizationAzuresEntitiesDictionary,
      getRelationSelectors,
      organizationAzureRelations,
      hydrateAll,
      'organizationAzure'
    );
  } else {
    return selectAllOrganizationAzuresObject;
  }
}

export function selectAllOrganizationAzuresDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationAzures'
): Selector {
  return createSelector(selectAllOrganizationAzures(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationAzureEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationAzures.length; i++) {
      res[customKey][result.organizationAzures[i].idOrganizationAzure] = result.organizationAzures[i];
    }
    return res;
  });
}

export function selectOneOrganizationAzure(schema: SelectSchema = {}, idOrganizationAzure: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationAzureDictionary(idOrganizationAzure)];
    selectors.push(...getRelationSelectors(schema, organizationAzureRelations, 'organizationAzure'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationAzureDictionaryWithoutChild(idOrganizationAzure);
  }
}

export function selectActiveOrganizationAzures(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationAzuresEntities, organizationAzures => ({
      organizationAzures
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationAzureRelations, 'organizationAzure'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationAzures: Dictionary<OrganizationAzureEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  groups?: Dictionary<GroupEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationAzures: (OrganizationAzure | null)[] } {
  const { organizationAzures, organizations, groups } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationAzures: Object.keys(organizationAzures).map(idOrganizationAzure =>
      hydrate(organizationAzures[idOrganizationAzure] as OrganizationAzureEntityState, organizations, groups)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationAzure: OrganizationAzureEntityState | null } {
  const { organizationAzures, organizations, groups } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationAzure = Object.values(organizationAzures)[0];
  return {
    organizationAzure: hydrate(organizationAzure as OrganizationAzureEntityState, organizations, groups)
  };
}

function hydrate(
  organizationAzure: OrganizationAzureEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  groupEntities?: Dictionary<GroupEntityState>
): OrganizationAzure | null {
  if (!organizationAzure) {
    return null;
  }

  const organizationAzureHydrated: OrganizationAzureEntityState = { ...organizationAzure };
  if (organizationEntities) {
    organizationAzureHydrated.organization = organizationEntities[
      organizationAzure.organization as number
    ] as Organization;
  } else {
    delete organizationAzureHydrated.organization;
  }
  if (groupEntities) {
    organizationAzureHydrated.group = groupEntities[organizationAzure.group as number] as Group;
  } else {
    delete organizationAzureHydrated.group;
  }

  return organizationAzureHydrated as OrganizationAzure;
}
