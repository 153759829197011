import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationApiService } from 'libs/api/old-api-services/src';

@Injectable()
export class UsersDeviceApiService {
  constructor(
    private http: HttpClient,
    private configurationApi: ConfigurationApiService
  ) {}

  create(values: CreateUserDevice) {
    return this.http.post(this.configurationApi.fullAddressV1 + '/users_devices/create', values);
  }

  delete(values: DeleteUserDevice) {
    return this.http.post(this.configurationApi.fullAddressV1 + '/users_devices/delete', values);
  }
}

interface CreateUserDevice {
  idUser: number;
  idDevice: string;
  firebase: boolean;
}

interface DeleteUserDevice {
  idUser: number;
  idDevice: string;
}
