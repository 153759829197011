import { RepositoryService } from '@wip/services/repository';
import { FolderElement } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedFolderElementApiService {
  constructor(protected repo: RepositoryService) {}

  public getFolderElements(params?: any): Observable<FolderElement[]> {
    return this.repo.getData<FolderElement[]>('folder-element', params);
  }

  public getFolderElement(params: { idFolderElement: number; params?: any }): Observable<FolderElement> {
    return this.repo.getData<FolderElement>('folder-element/' + params.idFolderElement, params.params);
  }

  public addFolderElement(folderElement: Partial<FolderElement>): Observable<FolderElement> {
    return this.repo.create<FolderElement>('folder-element', folderElement);
  }

  public updateFolderElement(folderElement: Partial<FolderElement>): Observable<FolderElement> {
    return this.repo.update('folder-element', folderElement);
  }

  public deleteFolderElement(idFolderElement: number): Observable<number> {
    return this.repo.delete('folder-element/' + +idFolderElement);
  }
}
