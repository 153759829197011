import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationEntityState } from '@api/api-interfaces';

export const organizationReducersGeneratedFunctions: ReducerTypes<
  OrganizationState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationGeneratedActions.getOneOrganization, (state: OrganizationState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationGeneratedActions.getManyOrganizations, (state: OrganizationState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationGeneratedActions.upsertOneOrganization, (state: OrganizationState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationGeneratedActions.upsertManyOrganizationsSuccess,
    (state: OrganizationState.IState, { organizations }) =>
      OrganizationState.adapter.upsertMany(organizations, setLoadingsState(state, false))
  ),
  on(OrganizationGeneratedActions.deleteOneOrganization, (state: OrganizationState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationGeneratedActions.deleteOneOrganizationSuccess, (state: OrganizationState.IState, { idOrganization }) =>
    OrganizationState.adapter.removeOne(idOrganization, setLoadingsState(state, false))
  ),
  on(OrganizationGeneratedActions.clearActivesOrganizations, (state: OrganizationState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationGeneratedActions.addManyActivesOrganizations,
    (state: OrganizationState.IState, { idOrganizations }) => ({
      ...state,
      actives: state.actives.concat(idOrganizations)
    })
  ),
  on(
    OrganizationGeneratedActions.deleteOneActiveOrganization,
    (state: OrganizationState.IState, { idOrganization }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganization)
    })
  ),

  on(OrganizationGeneratedActions.clearOrganizations, () => OrganizationState.initialState),

  on(
    OrganizationGeneratedActions.addManyCommunitySuccess,
    (state: OrganizationState.IState, { idOrganization, idCommunities }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const communities = (state.entities[idOrganization]?.communities as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            communities: communities.concat(idCommunities.filter(id => communities.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyCommunitySuccess,
    (state: OrganizationState.IState, { idCommunities, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.communities) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              communities: (state.entities[idOrganization]?.communities as number[])?.filter(
                (idCommunity: number) => !idCommunities.some((id: number) => id === idCommunity)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationAzureSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationAzures }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationAzures = (state.entities[idOrganization]?.organizationAzures as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationAzures: organizationAzures.concat(
              idOrganizationAzures.filter(id => organizationAzures.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationAzureSuccess,
    (state: OrganizationState.IState, { idOrganizationAzures, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationAzures) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationAzures: (state.entities[idOrganization]?.organizationAzures as number[])?.filter(
                (idOrganizationAzure: number) => !idOrganizationAzures.some((id: number) => id === idOrganizationAzure)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationElementSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationElements }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationElements = (state.entities[idOrganization]?.organizationElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationElements: organizationElements.concat(
              idOrganizationElements.filter(id => organizationElements.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationElementSuccess,
    (state: OrganizationState.IState, { idOrganizationElements, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationElements) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationElements: (state.entities[idOrganization]?.organizationElements as number[])?.filter(
                (idOrganizationElement: number) =>
                  !idOrganizationElements.some((id: number) => id === idOrganizationElement)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyGroupSuccess,
    (state: OrganizationState.IState, { idOrganization, idGroups }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const groups = (state.entities[idOrganization]?.groups as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            groups: groups.concat(idGroups.filter(id => groups.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyGroupSuccess,
    (state: OrganizationState.IState, { idGroups, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.groups) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              groups: (state.entities[idOrganization]?.groups as number[])?.filter(
                (idGroup: number) => !idGroups.some((id: number) => id === idGroup)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationUserSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationUsers }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationUsers = (state.entities[idOrganization]?.organizationUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationUsers: organizationUsers.concat(
              idOrganizationUsers.filter(id => organizationUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationUserSuccess,
    (state: OrganizationState.IState, { idOrganizationUsers, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationUsers) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationUsers: (state.entities[idOrganization]?.organizationUsers as number[])?.filter(
                (idOrganizationUser: number) => !idOrganizationUsers.some((id: number) => id === idOrganizationUser)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationFamilySuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationFamilys }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationFamilys = (state.entities[idOrganization]?.organizationFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationFamilys: organizationFamilys.concat(
              idOrganizationFamilys.filter(id => organizationFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationState.IState, { idOrganizationFamilys, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationFamilys) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationFamilys: (state.entities[idOrganization]?.organizationFamilys as number[])?.filter(
                (idOrganizationFamily: number) =>
                  !idOrganizationFamilys.some((id: number) => id === idOrganizationFamily)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationMilestoneFamilySuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationMilestoneFamilys }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationMilestoneFamilys =
        (state.entities[idOrganization]?.organizationMilestoneFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationMilestoneFamilys: organizationMilestoneFamilys.concat(
              idOrganizationMilestoneFamilys.filter(id => organizationMilestoneFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess,
    (state: OrganizationState.IState, { idOrganizationMilestoneFamilys, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationMilestoneFamilys) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationMilestoneFamilys: (
                state.entities[idOrganization]?.organizationMilestoneFamilys as number[]
              )?.filter(
                (idOrganizationMilestoneFamily: number) =>
                  !idOrganizationMilestoneFamilys.some((id: number) => id === idOrganizationMilestoneFamily)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationMilestoneSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationMilestones }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationMilestones = (state.entities[idOrganization]?.organizationMilestones as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationMilestones: organizationMilestones.concat(
              idOrganizationMilestones.filter(id => organizationMilestones.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationMilestoneSuccess,
    (state: OrganizationState.IState, { idOrganizationMilestones, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationMilestones) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationMilestones: (state.entities[idOrganization]?.organizationMilestones as number[])?.filter(
                (idOrganizationMilestone: number) =>
                  !idOrganizationMilestones.some((id: number) => id === idOrganizationMilestone)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationRiskSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationRisks }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationRisks = (state.entities[idOrganization]?.organizationRisks as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationRisks: organizationRisks.concat(
              idOrganizationRisks.filter(id => organizationRisks.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationRiskSuccess,
    (state: OrganizationState.IState, { idOrganizationRisks, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationRisks) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationRisks: (state.entities[idOrganization]?.organizationRisks as number[])?.filter(
                (idOrganizationRisk: number) => !idOrganizationRisks.some((id: number) => id === idOrganizationRisk)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationKpiSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationKpis }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationKpis = (state.entities[idOrganization]?.organizationKpis as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationKpis: organizationKpis.concat(idOrganizationKpis.filter(id => organizationKpis.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationKpiSuccess,
    (state: OrganizationState.IState, { idOrganizationKpis, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationKpis) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationKpis: (state.entities[idOrganization]?.organizationKpis as number[])?.filter(
                (idOrganizationKpi: number) => !idOrganizationKpis.some((id: number) => id === idOrganizationKpi)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationSubFamilySuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationSubFamilys }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationSubFamilys = (state.entities[idOrganization]?.organizationSubFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationSubFamilys: organizationSubFamilys.concat(
              idOrganizationSubFamilys.filter(id => organizationSubFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationSubFamilySuccess,
    (state: OrganizationState.IState, { idOrganizationSubFamilys, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationSubFamilys) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationSubFamilys: (state.entities[idOrganization]?.organizationSubFamilys as number[])?.filter(
                (idOrganizationSubFamily: number) =>
                  !idOrganizationSubFamilys.some((id: number) => id === idOrganizationSubFamily)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationCaracteristicSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationCaracteristics }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationCaracteristics = (state.entities[idOrganization]?.organizationCaracteristics as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationCaracteristics: organizationCaracteristics.concat(
              idOrganizationCaracteristics.filter(id => organizationCaracteristics.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationCaracteristicSuccess,
    (state: OrganizationState.IState, { idOrganizationCaracteristics, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationCaracteristics) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationCaracteristics: (
                state.entities[idOrganization]?.organizationCaracteristics as number[]
              )?.filter(
                (idOrganizationCaracteristic: number) =>
                  !idOrganizationCaracteristics.some((id: number) => id === idOrganizationCaracteristic)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationUserProfilSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationUserProfils }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationUserProfils = (state.entities[idOrganization]?.organizationUserProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationUserProfils: organizationUserProfils.concat(
              idOrganizationUserProfils.filter(id => organizationUserProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationUserProfilSuccess,
    (state: OrganizationState.IState, { idOrganizationUserProfils, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationUserProfils) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationUserProfils: (state.entities[idOrganization]?.organizationUserProfils as number[])?.filter(
                (idOrganizationUserProfil: number) =>
                  !idOrganizationUserProfils.some((id: number) => id === idOrganizationUserProfil)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationStepSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationSteps }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationSteps = (state.entities[idOrganization]?.organizationSteps as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationSteps: organizationSteps.concat(
              idOrganizationSteps.filter(id => organizationSteps.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationStepSuccess,
    (state: OrganizationState.IState, { idOrganizationSteps, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationSteps) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationSteps: (state.entities[idOrganization]?.organizationSteps as number[])?.filter(
                (idOrganizationStep: number) => !idOrganizationSteps.some((id: number) => id === idOrganizationStep)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationProjectModuleSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationProjectModules }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationProjectModules = (state.entities[idOrganization]?.organizationProjectModules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationProjectModules: organizationProjectModules.concat(
              idOrganizationProjectModules.filter(id => organizationProjectModules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationProjectModuleSuccess,
    (state: OrganizationState.IState, { idOrganizationProjectModules, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationProjectModules) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationProjectModules: (
                state.entities[idOrganization]?.organizationProjectModules as number[]
              )?.filter(
                (idOrganizationProjectModule: number) =>
                  !idOrganizationProjectModules.some((id: number) => id === idOrganizationProjectModule)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyOrganizationSectionSuccess,
    (state: OrganizationState.IState, { idOrganization, idOrganizationSections }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationSections = (state.entities[idOrganization]?.organizationSections as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationSections: organizationSections.concat(
              idOrganizationSections.filter(id => organizationSections.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyOrganizationSectionSuccess,
    (state: OrganizationState.IState, { idOrganizationSections, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationSections) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationSections: (state.entities[idOrganization]?.organizationSections as number[])?.filter(
                (idOrganizationSection: number) =>
                  !idOrganizationSections.some((id: number) => id === idOrganizationSection)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyProfilSuccess,
    (state: OrganizationState.IState, { idOrganization, idProfils }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const profils = (state.entities[idOrganization]?.profils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            profils: profils.concat(idProfils.filter(id => profils.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyProfilSuccess,
    (state: OrganizationState.IState, { idProfils, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.profils) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              profils: (state.entities[idOrganization]?.profils as number[])?.filter(
                (idProfil: number) => !idProfils.some((id: number) => id === idProfil)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManyUserSuccess,
    (state: OrganizationState.IState, { idOrganization, idUsers }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const users = (state.entities[idOrganization]?.users as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            users: users.concat(idUsers.filter(id => users.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManyUserSuccess,
    (state: OrganizationState.IState, { idUsers, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.users) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              users: (state.entities[idOrganization]?.users as number[])?.filter(
                (idUser: number) => !idUsers.some((id: number) => id === idUser)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.addManySectionSuccess,
    (state: OrganizationState.IState, { idOrganization, idSections }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const sections = (state.entities[idOrganization]?.sections as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            sections: sections.concat(idSections.filter(id => sections.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationGeneratedActions.deleteManySectionSuccess,
    (state: OrganizationState.IState, { idSections, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.sections) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              sections: (state.entities[idOrganization]?.sections as number[])?.filter(
                (idSection: number) => !idSections.some((id: number) => id === idSection)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationState.IState {
  return { ...state, isLoaded, isLoading };
}
