import { RepositoryService } from '@wip/services/repository';
import { Right } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedRightApiService {
  constructor(protected repo: RepositoryService) {}

  public getRights(params?: any): Observable<Right[]> {
    return this.repo.getData<Right[]>('right', params);
  }

  public getRight(params: { idRight: number; params?: any }): Observable<Right> {
    return this.repo.getData<Right>('right/' + params.idRight, params.params);
  }

  public addRight(right: Partial<Right>): Observable<Right> {
    return this.repo.create<Right>('right', right);
  }

  public updateRight(right: Partial<Right>): Observable<Right> {
    return this.repo.update('right', right);
  }

  public deleteRight(idRight: number): Observable<number> {
    return this.repo.delete('right/' + +idRight);
  }
}
