@if (title) {
  <div mat-dialog-title>
    <h1>{{ title }}</h1>
    <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
  </div>
}
<div mat-dialog-content>
  <p class="text-padding" [innerHTML]="body"></p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="confirm()" color="primary" type="submit">Ok</button>
</div>
