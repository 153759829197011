import { RepositoryService } from '@wip/services/repository';
import { OrganizationMilestone } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationMilestoneApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationMilestones(params?: any): Observable<OrganizationMilestone[]> {
    return this.repo.getData<OrganizationMilestone[]>('organization-milestone', params);
  }

  public getOrganizationMilestone(params: {
    idOrganizationMilestone: number;
    params?: any;
  }): Observable<OrganizationMilestone> {
    return this.repo.getData<OrganizationMilestone>(
      'organization-milestone/' + params.idOrganizationMilestone,
      params.params
    );
  }

  public addOrganizationMilestone(
    organizationMilestone: Partial<OrganizationMilestone>
  ): Observable<OrganizationMilestone> {
    return this.repo.create<OrganizationMilestone>('organization-milestone', organizationMilestone);
  }

  public updateOrganizationMilestone(
    organizationMilestone: Partial<OrganizationMilestone>
  ): Observable<OrganizationMilestone> {
    return this.repo.update('organization-milestone', organizationMilestone);
  }

  public deleteOrganizationMilestone(idOrganizationMilestone: number): Observable<number> {
    return this.repo.delete('organization-milestone/' + +idOrganizationMilestone);
  }
}
