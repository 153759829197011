import { RepositoryService } from '@wip/services/repository';
import { Section } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSectionApiService {
  constructor(protected repo: RepositoryService) {}

  public getSections(params?: any): Observable<Section[]> {
    return this.repo.getData<Section[]>('section', params);
  }

  public getSection(params: { idSection: number; params?: any }): Observable<Section> {
    return this.repo.getData<Section>('section/' + params.idSection, params.params);
  }

  public addSection(section: Partial<Section>): Observable<Section> {
    return this.repo.create<Section>('section', section);
  }

  public updateSection(section: Partial<Section>): Observable<Section> {
    return this.repo.update('section', section);
  }

  public deleteSection(idSection: number): Observable<number> {
    return this.repo.delete('section/' + +idSection);
  }
}
