import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedCommunityModuleApiService } from './community-module-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityModuleApiService extends GeneratedCommunityModuleApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
