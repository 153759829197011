import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationAzureApiService } from './organization-azure-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationAzureApiService extends GeneratedOrganizationAzureApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}

