import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @nx/enforce-module-boundaries */
import { DeviceInformationsService } from '@_app/core/device/device-information.service';
import { ISeachUserChanges } from '@_app/shared/interfaces/SearchUserChanges.interface';
import { ElementLibraryTypeEnum } from '@enums';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FloatLabelType } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, has, isEmpty, isNil, find as lodashFind, orderBy, uniq } from 'lodash-es';
import { debounceTime } from 'rxjs/operators';
import { IMetaUser } from '../../interfaces/MetaUser.interface';
import { IGroupMetaUsersSuggested } from '../../interfaces/MetaUserGroup.interface';
import { User } from '@api/api-interfaces';

@UntilDestroy()
@Component({
  selector: 'app-search-users-directive',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss'],
  providers: []
})
export class SearchUsersComponent implements OnInit, AfterViewInit {
  @ViewChild('UserInput') el: ElementRef;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  @Input() isProjectElementInTodoModule = false;
  @Input() findExactMatch: boolean; // recherche mail complet, meme si non present dans l'organisation
  @Input() searchService;
  @Input() searchMethod; // function de recherche des utilisateurs
  @Input() metaUsersBlacklist: IMetaUser[]; // liste d'utilisateurs a ne pas afficher
  // liste des utilisateurs proposes par defaut /!\ [[name: str, users: []], ...]
  @Input() groupMetaUsersSuggestedOrigin: any[];
  @Input() canAddName: boolean; // possibilite d'ajouter du texte libre, de type element_library user_nim
  @Input() textPlaceholder: string;
  @Input() displayList; // list ou dropdown
  @Input() maxChips: number; // nombre de chips max
  @Input() minChips: number; // nombre de chips max
  @Input() listOrigin: IMetaUser[];
  @Input() fromGantt: boolean = false;
  @Input() selectOnlyOnce: boolean; // juste un user selectionnable
  @Input() labelSuggestion: string; // texte de la search method
  @Input() focus: boolean; //
  @Input() defaultMetaUserAttributes: {}; // objet contenant les attributs des nouveaux metaUser par default
  @Input() metaUsersSelected: IMetaUser[]; //  liste d'utilisateurs deja selectionne
  @Input() floatLabel: FloatLabelType;
  @Input() isViewer: boolean;
  @Output() metaUsersSelectedChange = new EventEmitter<IMetaUser[]>(); //  liste d'utilisateurs deja selectionne
  // liste des users ajoute
  // TODO faire Output https://blog.thoughtram.io/angular/2016/10/13/two-way-data-binding-in-angular-2.html
  @Input() add: IMetaUser[];
  @Output() addChange = new EventEmitter<IMetaUser[]>();
  @Input() rmv: IMetaUser[]; // liste des users supprime // TODO faire Output
  @Output() rmvChange = new EventEmitter<IMetaUser[]>();
  @Output() setAsDirty = new EventEmitter<void>();
  @Output() change = new EventEmitter<ISeachUserChanges>();

  private oldSearch;

  public suggestedElementsDisplay: IGroupMetaUsersSuggested[]; // liste affiche calcule
  public myControl: FormControl;
  public separatorKeysCodes = [ENTER, COMMA];
  public didTyped = false;
  public groupMetaUsersSuggested: any[];
  public metaUsersExpanded: boolean = false;

  constructor(
    private translate: TranslateService,
    private deviceInformations: DeviceInformationsService
  ) {}

  ngOnInit() {
    this.myControl = new FormControl({ value: '', disabled: !!this.isViewer });
    this.myControl.valueChanges.pipe(untilDestroyed(this), debounceTime(300)).subscribe(element => {
      this.querySearch(element);
    });

    // Unnecessary variable as the back is always doing an exact match
    this.findExactMatch = this.findExactMatch || true;
    this.canAddName = this.canAddName || true;
    this.focus = this.focus || false;
    this.searchService = this.searchService;
    this.maxChips = this.maxChips || 1000;
    this.searchMethod = this.searchMethod || 'searchUsers';
    this.textPlaceholder = this.textPlaceholder || '';
    this.displayList = this.displayList ? this.displayList : 'list';
    this.metaUsersBlacklist = this.metaUsersBlacklist || [];
    this.groupMetaUsersSuggested = this.groupMetaUsersSuggested || [];
    this.metaUsersSelected = this.metaUsersSelected || [];
    this.add = this.add || [];
    this.rmv = this.rmv || [];
    this.oldSearch = '';
    this.suggestedElementsDisplay = [];
  }

  ngAfterViewInit() {
    if (this.isViewer) {
      this.myControl.disable();
    }
    // Otherwise in dropdown mode metaUsersSelected are not set
    setTimeout(() => {
      this.groupMetaUsersSuggested = cloneDeep(this.groupMetaUsersSuggestedOrigin);
      this.listOrigin = cloneDeep(this.listOrigin ? this.listOrigin : this.metaUsersSelected);
      this.initChipType();
      this.setSuggestedDisplay();
    });
  }

  /**
   * Initialize chips type based on user type and initialize the meta users suggested
   */
  initChipType() {
    if (this.metaUsersSelected && this.metaUsersSelected.length) {
      this.metaUsersSelected.forEach(userSelected => {
        this.initMetaUserType(userSelected);
      });
    }
    if (this.groupMetaUsersSuggested) {
      this.groupMetaUsersSuggested.forEach((groupMetaUsersSuggested, indexGroup) => {
        if (groupMetaUsersSuggested && groupMetaUsersSuggested.users && groupMetaUsersSuggested.users.length) {
          groupMetaUsersSuggested.users.forEach((metaUserSuggested, indexUser) => {
            this.initMetaUserType(metaUserSuggested);
            // si la suggestion est l'un des metaUsersSelected
            // alors on le remplace par le metaUserSelected pour récuperer ses informations
            // (ex: idElementLibrary)
            const userSelectedAndSuggested: IMetaUser = this.metaUsersSelected.find(metaUserSelected => {
              if (metaUserSuggested?.user?.idUser) {
                return (
                  metaUserSelected?.user?.idUser === metaUserSuggested?.user?.idUser &&
                  metaUserSuggested?.type === metaUserSelected?.type
                );
              } else {
                return (
                  (metaUserSelected.idUser || metaUserSuggested.idUser) &&
                  metaUserSelected?.idUser === metaUserSuggested?.idUser
                );
              }
            });
            if (userSelectedAndSuggested) {
              this.groupMetaUsersSuggested[indexGroup].users[indexUser] = userSelectedAndSuggested;
            }
          });
        }
      });
    }
  }

  /**
   * Initialisation de la balise meta et de la sous-propriete checked pour un element
   */
  initMetaUserType(metaUser: IMetaUser) {
    if (!has(metaUser, 'type')) {
      if (has(metaUser, 'idCommunityUserProfil')) {
        metaUser.type = ElementLibraryTypeEnum.user_profile;
      } else if (has(metaUser, 'user.idUser')) {
        metaUser.type = ElementLibraryTypeEnum.user_idUser;
      } else {
        metaUser.type = ElementLibraryTypeEnum.user_nom;
      }
    }
    return metaUser;
  }

  /**
   * Compare two metausers to decide if they are equal or not
   * @param {IMetaUser} metaUser1
   * @param {IMetaUser} metaUser2
   * @returns {boolean} true if metaUsers are equal and false if not
   */
  isMetaUserEqual(metaUser1: IMetaUser, metaUser2: IMetaUser): boolean {
    if (metaUser1 && metaUser2 && metaUser1.type === metaUser2.type) {
      switch (metaUser1.type) {
        case ElementLibraryTypeEnum.user_idUser:
          return (
            has(metaUser1, 'user') &&
            has(metaUser2, 'user') &&
            !isNil(metaUser1.user.idUser) &&
            metaUser1.user.idUser === metaUser2.user.idUser
          );
        case ElementLibraryTypeEnum.user_nom:
          return (
            has(metaUser1, 'nom') && has(metaUser2, 'nom') && !isNil(metaUser1.nom) && metaUser1.nom === metaUser2.nom
          );
        case ElementLibraryTypeEnum.user_profile:
          return (
            has(metaUser1, 'idElementLibrary') &&
            has(metaUser2, 'idElementLibrary') &&
            !isNil(metaUser1.idElementLibrary) &&
            metaUser1.idElementLibrary === metaUser2.idElementLibrary
          );
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Check if user match with the string searched
   * @param {IMetaUser} metaUser
   * @param {string} search
   * @returns {boolean} return true if the string match and false if not
   */
  isSearchInMetaUser(metaUser: IMetaUser, search: string): boolean {
    switch (metaUser.type) {
      case ElementLibraryTypeEnum.user_idUser:
        return (
          has(metaUser, 'user') &&
          ((metaUser.user.email || '').toLowerCase().includes(search) ||
            (metaUser.user.nom || '').toLowerCase().includes(search) ||
            (metaUser.user.prenom || '').toLowerCase().includes(search))
        );
      case ElementLibraryTypeEnum.user_nom:
        return (metaUser.nom || '').toLowerCase().includes(search);
      case ElementLibraryTypeEnum.user_profile:
        return (
          (metaUser.user_profile || '').toLowerCase().includes(search) ||
          (has(metaUser, 'user') &&
            ((metaUser.user.email || '').toLowerCase().includes(search) ||
              (metaUser.user.nom || '').toLowerCase().includes(search) ||
              (metaUser.user.prenom || '').toLowerCase().includes(search)))
        );
      default:
        return false;
    }
  }

  /**
   * Get suggested users from the string given in parameters
   * @param {string} [search]
   */
  setSuggestedDisplay(search?: string): void {
    this.suggestedElementsDisplay = [];
    const listUniqId = [];
    let isIncludeUniqueListConstraint = false;
    let isIncludeSearchConstraint = false;
    let isIncludeMetauserSelectedConstraint = false;
    let isIncludeMetauserBlacklistConstraint = false;
    let isReadOnly = false;

    if (this.groupMetaUsersSuggested && this.groupMetaUsersSuggested.length) {
      this.groupMetaUsersSuggested.forEach((groupSuggested: IGroupMetaUsersSuggested) => {
        if (groupSuggested.users && groupSuggested.users.length) {
          const metaUsersFromSuggestedGroup: IMetaUser[] = groupSuggested.users.filter(metaUserFromGroup => {
            isIncludeUniqueListConstraint = false;
            isIncludeSearchConstraint = true;
            isIncludeMetauserSelectedConstraint = false;
            isIncludeMetauserBlacklistConstraint = false;
            isReadOnly = false;

            const metaUsersSelectedAndSuggested = this.metaUsersSelected.find((metaUserSelected: IMetaUser) => {
              return this.isMetaUserEqual(metaUserSelected, metaUserFromGroup);
            });

            if (!isNil(metaUsersSelectedAndSuggested)) {
              metaUserFromGroup.checked = true;
            } else {
              metaUserFromGroup.checked = false;
            }

            switch (metaUserFromGroup.type) {
              case ElementLibraryTypeEnum.user_profile:
                isIncludeMetauserSelectedConstraint = !isNil(
                  this.metaUsersSelected.find((metaUserSelected: IMetaUser) => {
                    if (this.displayList === 'list') {
                      return (
                        !metaUserFromGroup.checked &&
                        metaUserSelected.idCommunityUserProfil === metaUserFromGroup.idCommunityUserProfil
                      );
                    } else if (this.displayList === 'dropDown') {
                      return metaUserSelected.idCommunityUserProfil === metaUserFromGroup.idCommunityUserProfil;
                    }
                  })
                );
                break;
              case ElementLibraryTypeEnum.user_idUser:
                if (metaUserFromGroup.user) {
                  if (isNil(groupSuggested.request) || groupSuggested.request !== true) {
                    isIncludeUniqueListConstraint = listUniqId.includes(metaUserFromGroup.user.idUser);
                    listUniqId.push(metaUserFromGroup.user.idUser);
                  }

                  if (search && !isNil(search) && search !== '') {
                    search = Object.prototype.toString.call(search) === '[object String]' ? search.toLowerCase() : '';
                    isIncludeSearchConstraint = this.isSearchInMetaUser(metaUserFromGroup, search);
                  } else {
                    isIncludeSearchConstraint = true;
                  }
                  if (this.displayList === 'dropDown') {
                    isIncludeMetauserSelectedConstraint = !isNil(
                      this.metaUsersSelected.find((metaUserSelected: IMetaUser) => {
                        return this.isMetaUserEqual(metaUserSelected, metaUserFromGroup);
                      })
                    );
                  } else {
                    isIncludeMetauserSelectedConstraint = false;
                  }

                  isIncludeMetauserBlacklistConstraint = !isNil(
                    lodashFind(this.metaUsersBlacklist, (blacklistedMetaUser: IMetaUser) => {
                      return this.isMetaUserEqual(blacklistedMetaUser, metaUserFromGroup);
                    })
                  );
                } else {
                  isIncludeSearchConstraint = true;
                }
                break;
              default:
                break;
            }
            isReadOnly = !isNil(
              this.metaUsersSelected.find(user => {
                return (
                  user.readOnly &&
                  ((user.idCommunityLibrary && user.idCommunityLibrary === metaUserFromGroup.idCommunityLibrary) ||
                    (user.user && user.user.idUser === metaUserFromGroup.user.idUser))
                );
              })
            );
            return (
              !isIncludeMetauserSelectedConstraint &&
              !isIncludeMetauserBlacklistConstraint &&
              !isIncludeUniqueListConstraint &&
              isIncludeSearchConstraint &&
              !isReadOnly
            );
          });
          if (
            (metaUsersFromSuggestedGroup.length > 0 &&
              groupSuggested.label !== this.translate.instant('General.Role')) ||
            (groupSuggested.label === this.translate.instant('General.Role') &&
              this.sortRole(metaUsersFromSuggestedGroup, search))
            //&& (ModuleList.project === this.currentModule || this.isProjectElementInTodoModule))
          ) {
            this.suggestedElementsDisplay.push({
              label: groupSuggested.label,
              request: groupSuggested.request || false,
              users: metaUsersFromSuggestedGroup,
              checked: false
            });
          } else if (!isNil(search) || !isEmpty(search)) {
            // TODO rename var with more meaningful name
            const tmp2 = {
              label: groupSuggested.label,
              request: groupSuggested.request || false,
              users: metaUsersFromSuggestedGroup,
              checked: false
            };
            if (
              metaUsersFromSuggestedGroup.length === 0 &&
              tmp2 &&
              tmp2.request &&
              this.suggestedElementsDisplay[0] &&
              !this.sortRole(this.suggestedElementsDisplay[0].users, search)
            ) {
              this.suggestedElementsDisplay = [];
            }
          }
          if (this.suggestedElementsDisplay.length && this.isAllChecked(this.suggestedElementsDisplay.length - 1)) {
            this.suggestedElementsDisplay[this.suggestedElementsDisplay.length - 1].checked = true;
          }
        }
      });
    } else {
      this.suggestedElementsDisplay = [];
    }
    this.sortSuggestedDisplay();
  }

  sortRole(metaUsersFromSuggestedGroup, search) {
    let role: string;
    let searchValue: string;
    let check = false;
    let name: string;
    if (!search) {
      return true;
    }
    metaUsersFromSuggestedGroup.forEach(element => {
      if (element.libelle) {
        role = element.libelle.toLowerCase();
      }
      searchValue = search;
      searchValue = searchValue.toString().toLowerCase();
      if (element.user?.prenom) {
        name = element.user.prenom.toLowerCase();
      }
      if (element.user?.nom) {
        name = name + ' ' + element.user.nom.toLowerCase();
      }
      if ((!!role && role.includes(searchValue)) || (!!name && name.includes(searchValue))) {
        check = true;
      }
    });
    return check;
  }

  /**
   *
   * @param {IMetaUser} selectedMetaUser
   * @param {boolean} isAdd
   */
  transfer(selectedMetaUser: IMetaUser, isAdd: boolean): void {
    if (!isNil(selectedMetaUser)) {
      const selectedMetaUserToAddOrRemove: IMetaUser = {
        type: null
      };
      let attr: string;
      // Depending on our meta user selected we should get different informations
      if (selectedMetaUser.type === ElementLibraryTypeEnum.user_idUser) {
        attr = 'idUser';
      } else if (selectedMetaUser.type === ElementLibraryTypeEnum.user_profile) {
        attr = 'idCommunityUserProfil';
      } else {
        attr = 'nom';
      }

      if (selectedMetaUser.type === ElementLibraryTypeEnum.user_profile) {
        selectedMetaUserToAddOrRemove[attr] = selectedMetaUser[attr];
      } else {
        selectedMetaUserToAddOrRemove[attr] = selectedMetaUser.user
          ? selectedMetaUser.user[attr]
          : selectedMetaUser[attr];
      }

      // these lines were added because:
      // if no idMeetingUser is sent when removing a user nom,
      // it wipes the table completely.
      if (selectedMetaUser.type === ElementLibraryTypeEnum.user_nom && selectedMetaUser.idMeetingUser) {
        selectedMetaUserToAddOrRemove.idMeetingUser = selectedMetaUser.idMeetingUser;
      }

      selectedMetaUserToAddOrRemove.type = selectedMetaUser.type || ElementLibraryTypeEnum.user_idUser;
      selectedMetaUserToAddOrRemove.idElementLibrary = selectedMetaUser.idElementLibrary;

      const find = lodashFind(this.listOrigin, metaUser => {
        switch (selectedMetaUser.type) {
          case ElementLibraryTypeEnum.user_idUser:
            return (
              metaUser.idUser === selectedMetaUser.user.idUser || metaUser.user.idUser === selectedMetaUser.user.idUser
            );
          case ElementLibraryTypeEnum.user_profile:
            return metaUser.idElementLibrary === selectedMetaUser.idElementLibrary;
          case ElementLibraryTypeEnum.user_nom:
            return metaUser?.user?.nom === selectedMetaUser?.user?.nom;
          default:
            return null;
        }
      });

      if (isAdd) {
        if (this.selectOnlyOnce) {
          if (this.rmv.length) {
            if (this.isMetaUserEqual(this.rmv[0], selectedMetaUser)) {
              this.rmv.splice(0);
              this.add.splice(0);
            }
          } else {
            this.rmv.push(...this.listOrigin);
          }
          if (this.metaUsersSelected.length) {
            this.metaUsersSelected.splice(0);
          }
          this.add.splice(0);

          this.add.push(selectedMetaUserToAddOrRemove);
        } else {
          if (isNil(find) && !this.fromGantt) {
            this.add.push(selectedMetaUserToAddOrRemove);
          }
          this.rmv = this.rmv.filter(metaUserInRemoveList => {
            return metaUserInRemoveList[attr] !== selectedMetaUser.user[attr];
          });
        }
      } else {
        if (!isNil(find)) {
          this.rmv.push(selectedMetaUserToAddOrRemove);
        }
        this.add = this.add.filter((metaUserInAddList: IMetaUser) => {
          return (
            !!selectedMetaUser.user &&
            (metaUserInAddList[attr] === selectedMetaUser.user[attr] ||
              metaUserInAddList[attr] === selectedMetaUser[attr]) &&
            metaUserInAddList.type === selectedMetaUser.type
          );
        });
      }
    }
  }

  /**
   *
   * @param {MatAutocompleteSelectedEvent} event
   */
  addMetaUserFromAutocomplete(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) {
      this.addMetaUser(event.option.value, false);
    } else {
      this.addMetaUserWithName(null);
    }
    this.el.nativeElement.value = '';
    if (this.selectOnlyOnce) {
      this.setBlur();
    } else {
      this.setFocus();
    }
  }

  clickFromCheckbox(metaUser) {
    metaUser.checked = !metaUser.checked;
  }

  /**
   * Check or uncheck the user given in parameter
   * @param {IMetaUser} selectedMetaUser
   * @param {boolean} [fromButtonAll]
   */
  addMetaUser(selectedMetaUser: IMetaUser, fromButtonAll?: boolean): void {
    if (selectedMetaUser.checked && this.maxChips === 1) {
      return this.removeMetaUser(selectedMetaUser);
    }
    if (isNil(fromButtonAll)) {
      fromButtonAll = false;
    }
    if (!this.maxChips || !this.metaUsersSelected || this.metaUsersSelected.length < this.maxChips) {
      if (this.displayList === 'dropDown') {
        this.transfer(selectedMetaUser, true);
        this.metaUsersSelected.push(this.initMetaUserType(selectedMetaUser));
        this.suggestedElementsDisplay = this.suggestedElementsDisplay.filter((suggestedElement: any) => {
          if (suggestedElement.user) {
            return suggestedElement.user.idUser === selectedMetaUser.user.idUser;
          } else {
            return suggestedElement.idUser === selectedMetaUser.user?.idUser;
          }
        });
      } else if (this.displayList === 'list' && !selectedMetaUser.checked) {
        this.transfer(selectedMetaUser, true);

        this.metaUsersSelected.push(selectedMetaUser);
      } else if (this.displayList === 'list' && selectedMetaUser.checked === true && !fromButtonAll) {
        this.removeMetaUser(selectedMetaUser);
      }
    } else if (has(this, 'maxChips') && this.metaUsersSelected.length >= this.maxChips) {
      this.metaUsersSelected.splice(0);

      this.transfer(selectedMetaUser, true);
      this.metaUsersSelected.push(selectedMetaUser);
    }
    this.setSuggestedDisplay();
    this.emitChange();
  }

  /**
   * Uncheck the meta user given in parameter
   * @param {IMetaUser} metaUser
   */
  removeMetaUser(metaUser: IMetaUser) {
    if (metaUser.readOnly) {
      this.metaUsersSelected.unshift(metaUser);
    } else {
      let metaUserIndex: number;
      if (metaUser.idUser) {
        metaUserIndex = this.metaUsersSelected.findIndex(userSelected => {
          return userSelected.idUser === metaUser.idUser;
        });
      } else if (metaUser.idCommunityLibrary) {
        metaUserIndex = this.metaUsersSelected.findIndex((userSelected: IMetaUser) => {
          return userSelected.idCommunityLibrary === metaUser.idCommunityLibrary;
        });
      } else {
        metaUserIndex = this.metaUsersSelected.findIndex((userSelected: IMetaUser) => {
          return !userSelected.idUser && userSelected?.user?.nom === metaUser?.user?.nom;
        });
      }
      this.metaUsersSelected.splice(metaUserIndex, 1);
      this.transfer(metaUser, false);

      this.setSuggestedDisplay();
    }

    this.emitChange();

    setTimeout(() => {
      if (this.displayList === 'dropDown') {
        this.trigger.openPanel();
      }
    });
  }

  querySearch(search) {
    if (search && search.length) {
      this.didTyped = true;
    } else {
      this.didTyped = false;
    }
    if (search && search.length && (!this.oldSearch || search !== this.oldSearch)) {
      this.oldSearch = cloneDeep(search);
      const params: any = {
        searchQuery: search
      };
      let listeID = [];
      this.metaUsersSelected.forEach((metaUserSelected: IMetaUser) => {
        if (metaUserSelected.user && metaUserSelected.user.idUser) {
          listeID.push(metaUserSelected.user.idUser);
        }
      });
      this.metaUsersBlacklist.forEach(blacklistedMetaUser => {
        if (blacklistedMetaUser?.user && blacklistedMetaUser.user.idUser) {
          listeID.push(blacklistedMetaUser.user.idUser);
        } else if (blacklistedMetaUser?.idUser) {
          listeID.push(blacklistedMetaUser.idUser);
        }
      });
      if (listeID && listeID.length) {
        listeID = uniq(listeID);
        params.blacklist = JSON.stringify(listeID);
      }
      this.setSuggestedDisplay(search);
    } else if (this.oldSearch && (this.oldSearch !== search || (this.oldSearch === '' && search === ''))) {
      this.oldSearch = null;
      this.groupMetaUsersSuggested = this.groupMetaUsersSuggested.filter((groupSuggested: IGroupMetaUsersSuggested) => {
        return groupSuggested.request !== true;
      });
      this.setSuggestedDisplay(search);
      return this.suggestedElementsDisplay;
    } else {
      return this.suggestedElementsDisplay;
    }
    return null;
  }

  /**
   * Either check or uncheck the user given in parameter
   * @param {IMetaUser} metaUser
   * @param {boolean} [fromButtonAll]
   */
  addEditable(metaUser: IMetaUser, fromButtonAll?: boolean) {
    if (this.displayList === 'list') {
      this.addMetaUser(metaUser, fromButtonAll);
    }
  }

  /**
   * Decide if all members of a group are checked if no index is given check if all groups are checked
   * @param {number} [index] the index of a group to be checked
   * @returns {boolean} return true if all users are checked else it returns false
   */
  isAllChecked(index?: number): boolean {
    let result = true;
    if (!isNil(index) && index < this.suggestedElementsDisplay.length) {
      this.suggestedElementsDisplay[index].users.forEach(metaUser => {
        if (metaUser.checked === false) {
          result = false;
        }
      });
      this.suggestedElementsDisplay[index].checked = result;
    } else {
      this.suggestedElementsDisplay.forEach(suggestedElement => {
        suggestedElement.users.forEach(o2 => {
          if (o2.checked === false) {
            result = false;
          }
        });
      });
    }
    return result;
  }

  /**
   * Sort suggested users
   */
  sortSuggestedDisplay() {
    this.suggestedElementsDisplay.forEach(metaUserGroup => {
      metaUserGroup.users = orderBy(
        metaUserGroup.users,
        (metaUser: IMetaUser) => {
          return (
            metaUser.user && (metaUser.user.prenom || metaUser.user.nom || metaUser.user.email || metaUser.user_profile)
          );
        },
        ['asc']
      );
    });
  }

  /**
   * Check or uncheck all usersfrom a group
   * @param {number} index the index of the group that will be checked/unchecked
   */
  selectMetaUsersGroupAtIndex(index: number): void {
    if (this.isAllChecked(index)) {
      this.suggestedElementsDisplay[index].users.forEach(metaUserSuggested => {
        this.removeMetaUser(metaUserSuggested);
        metaUserSuggested.checked = false;
      });
    } else {
      this.suggestedElementsDisplay[index].users.forEach(metaUserSuggested => {
        if (this.displayList === 'list') {
          this.addEditable(metaUserSuggested, true);
        } else if (this.displayList === 'dropDown') {
          this.addMetaUser(metaUserSuggested, true);
        }
        metaUserSuggested.checked = true;
      });
    }
  }

  /**
   * Add the chip of a selected user and set focus in the input
   * @param {MatChipInputEvent} event
   */
  addMetaUserWithName(event: MatChipInputEvent): void {
    let input: HTMLInputElement;
    if (event) {
      input = event.input;
    }

    if (this.myControl.value) {
      const newMetaName: IMetaUser = {
        nom: this.myControl.value,
        // user: { nom: this.myControl.value },
        type: ElementLibraryTypeEnum.user_nom
      };
      this.addEditable(newMetaName);
      this.setSuggestedDisplay();

      // Reset the input value
      if (input) {
        input.value = '';
      }
      this.setFocus();
    }
  }

  /**
   * Clear and set focus to the input
   */
  setFocus() {
    this.el.nativeElement.value = '';

    if (!this.deviceInformations.isMobileOS()) {
      this.el.nativeElement.focus();
    }
  }
  setBlur() {
    this.el.nativeElement.blur();
  }

  emitChange() {
    this.setAsDirty.emit();
    this.change.emit({ add: [...this.add], rmv: [...this.rmv], metaUsersSelected: [...this.metaUsersSelected] });
  }

  checkRole(metaUser) {
    let role: string;
    let searchValue: string;
    let name: string;

    if (!this.myControl.value) {
      return true;
    } else {
      if (metaUser.user?.prenom) {
        name = metaUser.user.prenom.toLowerCase();
      }
      if (metaUser.user?.nom) {
        name = name + ' ' + metaUser.user.nom.toLowerCase();
      }
      role = metaUser.libelle ? metaUser.libelle.toLowerCase() : metaUser.organizationUserProfil?.libelle.toLowerCase();
      searchValue = this.myControl.value;
      searchValue = searchValue.toString().toLowerCase();
      if (role.includes(searchValue) || (name && name.includes(searchValue))) {
        return true;
      }
    }
    return false;
  }

  getUserId(_index, user: User) {
    return user.idUser;
  }
}
