import { RepositoryService } from '@wip/services/repository';
import { OrganizationSubFamily } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationSubFamilyApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationSubFamilys(params?: any): Observable<OrganizationSubFamily[]> {
    return this.repo.getData<OrganizationSubFamily[]>('organization-sub-family', params);
  }

  public getOrganizationSubFamily(params: {
    idOrganizationSubFamily: number;
    params?: any;
  }): Observable<OrganizationSubFamily> {
    return this.repo.getData<OrganizationSubFamily>(
      'organization-sub-family/' + params.idOrganizationSubFamily,
      params.params
    );
  }

  public addOrganizationSubFamily(
    organizationSubFamily: Partial<OrganizationSubFamily>
  ): Observable<OrganizationSubFamily> {
    return this.repo.create<OrganizationSubFamily>('organization-sub-family', organizationSubFamily);
  }

  public updateOrganizationSubFamily(
    organizationSubFamily: Partial<OrganizationSubFamily>
  ): Observable<OrganizationSubFamily> {
    return this.repo.update('organization-sub-family', organizationSubFamily);
  }

  public deleteOrganizationSubFamily(idOrganizationSubFamily: number): Observable<number> {
    return this.repo.delete('organization-sub-family/' + +idOrganizationSubFamily);
  }
}
