/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@wip/environments';
import { Observable } from 'rxjs';
import { LoginData } from './login-data.interface';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private storageService: StorageService,
    private http: HttpClient // private messageBusService: MessageBusService
  ) {}

  public isLogged = false;
  public loginData: LoginData;

  public login(credentials: { email: string; password: string }): Observable<any> {
    return this.http.post(environment.apiV2Url + '/auth/login', credentials);
  }
  public reset(credentials: { password: string; token: string }): Observable<any> {
    return this.http.put(environment.apiV2Url + '/auth/reset', credentials);
  }

  //fix
  public sendReset(credentials: { email: string; type: string }): Observable<any> {
    return this.http.post(environment.apiV2Url + '/auth/send-reset-email', credentials);
  }

  public tokenUser(credentials: { token: string }): Observable<any> {
    return this.http.get(environment.apiV2Url + '/auth/token-user?token=' + credentials.token);
  }

  public renew(): Observable<any> {
    return this.http.get(environment.apiV2Url + '/auth/renew');
  }

  public getLoginStorage(): void {
    const loginData = this.storageService.isLogged();
    if (!loginData) {
      return;
    } else {
      this.loginData = loginData;
      this.isLogged = true;
    }
  }

  public logout(): void {
    this.storageService.clearAll();
    this.loginData = undefined;
    this.isLogged = false;
  }
}
export enum AuthMessage {
  logout = 'logout'
}
