import { ActionService, ActionStatus } from '@_app/shared/elements/actions/action.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ElementStatusActionEnum, ElementStatusActionEnumNext, ElementStatusActionEnumTrad } from '@enums';
import { Element } from '@api/api-interfaces';

@Component({
  selector: 'wip-new-action-status-section',
  templateUrl: './new-action-status-section.component.html',
  styleUrls: ['./new-action-status-section.component.scss']
})
export class NewActionStatusSectionComponent implements OnInit {
  @Input() isViewer = false;
  @Input() actionStatus: ActionStatus;
  @Input() actionService: ActionService;
  @Input() element: Element;
  @Input() actionForm: FormGroup;
  @Output() callSettingActionFormAsDirty = new EventEmitter();

  public checkboxStatus = {
    open: 'status-icon icon icon-ic_fluent_circle_24_regular black',
    delete: 'status-icon icon icon-ic_fluent_dismiss_circle_24_regular red',
    close: 'status-icon icon icon-ic_fluent_checkmark_circle_24_regular green',
    progress: 'status-icon icon icon-ic_fluent_arrow_circle_right_24_regular blue',
    blocked: 'status-icon icon icon-ic_fluent_error_circle_24_regular red',
    abort: 'status-icon icon icon-ic_fluent_dismiss_circle_24_regular red',
    replace: 'status-icon icon icon-ic_fluent_arrow_sync_circle_24_regular red',
    ['not applicable']: 'status-icon icon icon-ic_fluent_circle_24_filled grey'
  };

  constructor() {}

  ngOnInit() {
    this.actionStatus = this.actionService.getActionStatus(this.element.statusAction, true);
  }

  setNextStatus(statusName?: ElementStatusActionEnum | ElementStatusActionEnum): void {
    if (statusName) {
      this.actionStatus = this.actionService.getActionStatus(statusName, true);
    }
    this.actionForm.controls.statusAction.patchValue(
      ElementStatusActionEnumNext[this.actionForm.controls.statusAction.value]
    );
    this.actionStatus = this.actionService.getActionStatus(this.actionForm.controls.statusAction.value, true);
    this.actionForm.markAsDirty();
  }

  setImportant(): void {
    const isImportant = this.actionForm.controls.important.value;
    this.actionForm.controls.important.setValue(!isImportant);
    this.callSettingActionFormAsDirty.emit(this.actionForm);
  }

  setStatus(value: ElementStatusActionEnum) {
    this.actionForm.controls.statusAction.setValue(value);
  }

  setTraduction(value: keyof typeof ElementStatusActionEnumTrad) {
    return ElementStatusActionEnumTrad[value];
  }
}
