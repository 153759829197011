import { createAction, props } from '@ngrx/store';

export * from './organization-generated.actions';

export const getManyOrganizationsWithIdUser = createAction(
  '[Organization] Get Many Organizations With idUser',
  props<{ idUser: number }>()
);

export const getOrganizationsFilters = createAction(
  '[Organization] Get Many Organizations For Filters',
  props<{ idUser: number }>()
);

export const getManyOrganizationsForSynthesis = createAction(
  '[Organization] Get Many Organizations For Synthesis',
  props<{ idUser: number }>()
);
