import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ChildrenElementEntityState } from '@api/api-interfaces';
import { ChildrenElementGeneratedActions } from '@wip/store/actions';
import { ChildrenElementActions } from '@wip/store/actions';
import { ChildrenElementApiService } from '@wip/store/api-services';
import { AppState } from '@wip/store/configs/reducers';
import { ChildrenElementSelectors } from '@wip/store/selectors';
import { of } from 'rxjs';
import { catchError, concatMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { GeneratedChildrenElementEffects } from './children-element-generated.effects';
import * as generatedChildrenElementEffects from './children-element-generated.effects';

@Injectable()
export class ChildrenElementEffects extends GeneratedChildrenElementEffects {
  constructor(actions$: Actions, childrenElementApiService: ChildrenElementApiService, store$: Store<AppState>) {
    super(actions$, childrenElementApiService, store$);
  }

  deleteOneChildrenElementCustom$ = createEffect(() => {
    const selectChildrenElementState$ = this.store$.select(ChildrenElementSelectors.selectChildrenElementState);
    return this.actions$.pipe(
      ofType(ChildrenElementActions.deleteOneChildrenElementCustom),
      withLatestFrom(selectChildrenElementState$),
      concatMap(([{ params }, state]) =>
        this.childrenElementApiService.deleteOneChildrenElementCustom(params).pipe(
          mergeMap(_success =>
            generatedChildrenElementEffects.getDefaultDeleteChildrenElementActions(
              state.entities[params.idChildrenElement] as ChildrenElementEntityState
            )
          ),
          catchError(error => of(ChildrenElementGeneratedActions.childrenElementsFailure({ error })))
        )
      )
    );
  });
}
