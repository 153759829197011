import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserGroup } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import * as userGroupActions from '@wip/store/actions';
import { UserGroupApiService } from '@wip/store/api-services';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedUserGroupEffects } from './user-group-generated.effects';
import { upsertUserGroupDTO } from '../../../actions/src';

@Injectable()
export class UserGroupEffects extends GeneratedUserGroupEffects {
  constructor(actions$: Actions, userGroupApiService: UserGroupApiService, store$: Store<AppState>) {
    super(actions$, userGroupApiService, store$);
  }

  upsertManyUserGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userGroupActions.upsertAndRemoveUserGroups),
      concatMap(({ params }: { params: upsertUserGroupDTO }) => {
        return this.userGroupApiService.updateAndRemoveUserGroups(params).pipe(
          map((userGroupReturned: UserGroup[]) => {
            return userGroupActions.UserGroupGeneratedActions.normalizeManyUserGroupsAfterUpsert({
              userGroups: userGroupReturned
            });
          }),
          catchError(error => of(userGroupActions.UserGroupGeneratedActions.userGroupsFailure({ error })))
        );
      })
    );
  });
}
