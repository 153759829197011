export enum ModelType {
  communities = 'communities',
  communityLibraries = 'communityLibraries',
  documents = 'documents',
  documentsElements = 'documents_elements',
  elementLibraries = 'elements_libraries',
  meeting = 'meeting',
  meetings = 'meetings',
  meetingsElements = 'meetingUsers',
  meetingsUsers = 'meetingUsers',
  organizations = 'organizations',
  organizationsLibraries = 'organizations_libraries',
  projects = 'projects',
  projectsElements = 'projects_elements',
  timelinesElements = 'timelineElements',
  todos = 'todos',
  todosElements = 'todoElements',
}
