import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { ElementCash, ElementCashEntityState } from '@api/api-interfaces';
import { ElementCashApiService } from '@wip/store/api-services';
import { ElementCashGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeElementCash } from '@wip/store/configs/normalization';
import { ElementCashSelectors } from '@wip/store/selectors';
import { ElementCashRelationsIds } from '@wip/store/ids-interfaces';
import { ElementGeneratedActions } from '@wip/store/actions';

export function getDefaultAddElementCashActions(
  elementCash: ElementCashEntityState,
  ids?: ElementCashRelationsIds
): Action[] {
  const actions: Action[] = [
    ElementCashGeneratedActions.normalizeManyElementCashsAfterUpsert({ elementCashs: [elementCash] })
  ];

  if (ids?.element) {
    actions.push(
      ElementGeneratedActions.addManyElementCashSuccess({
        idElement: ids.element,
        idElementCashs: [elementCash.idElementCash]
      })
    );
    actions.push(
      ElementCashGeneratedActions.addElementSuccess({
        idElementCash: elementCash.idElementCash,
        idElement: ids.element
      })
    );
  }

  return actions;
}

export function getDefaultDeleteElementCashActions(elementCash: ElementCashEntityState): Action[] {
  const actions: Action[] = [
    ElementCashGeneratedActions.deleteOneElementCashSuccess({ idElementCash: elementCash.idElementCash })
  ];

  if (elementCash.element) {
    actions.push(
      ElementGeneratedActions.deleteManyElementCashSuccess({
        idElementCashs: [elementCash.idElementCash],
        idElements: [elementCash.element as number]
      })
    );
  }

  return actions;
}

export class GeneratedElementCashEffects {
  constructor(
    protected actions$: Actions,
    protected elementCashApiService: ElementCashApiService,
    protected store$: Store<AppState>
  ) {}

  getManyElementCashs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementCashGeneratedActions.getManyElementCashs),
      switchMap(({ params }) =>
        this.elementCashApiService.getElementCashs(params).pipe(
          map((elementCashs: ElementCash[]) => {
            return ElementCashGeneratedActions.normalizeManyElementCashsAfterUpsert({ elementCashs });
          }),
          catchError(error => of(ElementCashGeneratedActions.elementCashsFailure({ error })))
        )
      )
    );
  });

  getOneElementCash$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementCashGeneratedActions.getOneElementCash),
      switchMap(idElementCash =>
        this.elementCashApiService.getElementCash(idElementCash).pipe(
          map((elementCash: ElementCash) => {
            return ElementCashGeneratedActions.normalizeManyElementCashsAfterUpsert({ elementCashs: [elementCash] });
          }),
          catchError(error => of(ElementCashGeneratedActions.elementCashsFailure({ error })))
        )
      )
    );
  });

  upsertOneElementCash$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementCashGeneratedActions.upsertOneElementCash),
      concatMap(({ elementCash, ids }: { elementCash: Partial<ElementCash>; ids?: ElementCashRelationsIds }) => {
        if (elementCash.idElementCash) {
          return this.elementCashApiService.updateElementCash(elementCash).pipe(
            map((elementCashReturned: ElementCash) => {
              return ElementCashGeneratedActions.normalizeManyElementCashsAfterUpsert({
                elementCashs: [elementCashReturned]
              });
            }),
            catchError(error => of(ElementCashGeneratedActions.elementCashsFailure({ error })))
          );
        } else {
          return this.elementCashApiService.addElementCash(elementCash).pipe(
            mergeMap((elementCashReturned: ElementCash) => getDefaultAddElementCashActions(elementCashReturned, ids)),
            catchError(error => of(ElementCashGeneratedActions.elementCashsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneElementCash$ = createEffect(() => {
    const selectElementCashState$ = this.store$.select(ElementCashSelectors.selectElementCashState);
    return this.actions$.pipe(
      ofType(ElementCashGeneratedActions.deleteOneElementCash),
      withLatestFrom(selectElementCashState$),
      concatMap(([{ idElementCash }, state]) =>
        this.elementCashApiService.deleteElementCash(idElementCash).pipe(
          mergeMap(_success =>
            getDefaultDeleteElementCashActions(state.entities[idElementCash] as ElementCashEntityState)
          ),
          catchError(error => of(ElementCashGeneratedActions.elementCashsFailure({ error })))
        )
      )
    );
  });

  normalizeManyElementCashsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementCashGeneratedActions.normalizeManyElementCashsAfterUpsert),
      concatMap(({ elementCashs }) => {
        const actions: Action[] = getActionsToNormalizeElementCash(elementCashs, StoreActionType.upsert);
        return [getMultiAction(actions, '[ElementCash] Normalization After Upsert Success')];
      })
    );
  });
}
