import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityRisk, CommunityRiskEntityState } from '@api/api-interfaces';
import { OrganizationRisk, OrganizationRiskEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityRiskState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityRiskRelations: string[] = ['organizationRisks', 'communities'];

export const { selectEntities, selectAll } = CommunityRiskState.adapter.getSelectors();

export const selectCommunityRiskState = createFeatureSelector<CommunityRiskState.IState>(
  CommunityRiskState.communityRiskFeatureKey
);

export const selectIsLoadedCommunityRisk = createSelector(
  selectCommunityRiskState,
  (state: CommunityRiskState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityRisk = createSelector(
  selectCommunityRiskState,
  (state: CommunityRiskState.IState) => state.isLoading
);

export const selectIsReadyCommunityRisk = createSelector(
  selectCommunityRiskState,
  (state: CommunityRiskState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityRisk = createSelector(
  selectCommunityRiskState,
  (state: CommunityRiskState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityRisksEntities = createSelector(selectCommunityRiskState, selectEntities);

export const selectCommunityRisksArray = createSelector(selectCommunityRiskState, selectAll);

export const selectIdCommunityRisksActive = createSelector(
  selectCommunityRiskState,
  (state: CommunityRiskState.IState) => state.actives
);

const communityRisksInObject = (communityRisks: Dictionary<CommunityRiskEntityState>) => ({ communityRisks });

const selectCommunityRisksEntitiesDictionary = createSelector(selectCommunityRisksEntities, communityRisksInObject);

const selectAllCommunityRisksObject = createSelector(selectCommunityRisksEntities, communityRisks => {
  return hydrateAll({ communityRisks });
});

const selectOneCommunityRiskDictionary = (idCommunityRisk: number) =>
  createSelector(selectCommunityRisksEntities, communityRisks => ({
    communityRisks: { [idCommunityRisk]: communityRisks[idCommunityRisk] }
  }));

const selectOneCommunityRiskDictionaryWithoutChild = (idCommunityRisk: number) =>
  createSelector(selectCommunityRisksEntities, communityRisks => ({
    communityRisk: communityRisks[idCommunityRisk]
  }));

const selectActiveCommunityRisksEntities = createSelector(
  selectIdCommunityRisksActive,
  selectCommunityRisksEntities,
  (actives: number[], communityRisks: Dictionary<CommunityRiskEntityState>) =>
    getCommunityRisksFromActives(actives, communityRisks)
);

function getCommunityRisksFromActives(
  actives: number[],
  communityRisks: Dictionary<CommunityRiskEntityState>
): Dictionary<CommunityRiskEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityRisks[idActive]) {
      acc[idActive] = communityRisks[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityRiskEntityState>);
}

const selectAllCommunityRisksSelectors: Dictionary<Selector> = {};
export function selectAllCommunityRisks(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityRisk>(
      schema,
      selectAllCommunityRisksSelectors,
      selectCommunityRisksEntitiesDictionary,
      getRelationSelectors,
      communityRiskRelations,
      hydrateAll,
      'communityRisk'
    );
  } else {
    return selectAllCommunityRisksObject;
  }
}

export function selectAllCommunityRisksDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityRisks'
): Selector {
  return createSelector(selectAllCommunityRisks(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityRiskEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityRisks.length; i++) {
      res[customKey][result.communityRisks[i].idCommunityRisk] = result.communityRisks[i];
    }
    return res;
  });
}

export function selectOneCommunityRisk(schema: SelectSchema = {}, idCommunityRisk: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityRiskDictionary(idCommunityRisk)];
    selectors.push(...getRelationSelectors(schema, communityRiskRelations, 'communityRisk'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityRiskDictionaryWithoutChild(idCommunityRisk);
  }
}

export function selectActiveCommunityRisks(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityRisksEntities, communityRisks => ({
      communityRisks
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityRiskRelations, 'communityRisk'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityRisks: Dictionary<CommunityRiskEntityState>;
  organizationRisks?: Dictionary<OrganizationRiskEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityRisks: (CommunityRisk | null)[] } {
  const { communityRisks, organizationRisks, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communityRisks: Object.keys(communityRisks).map(idCommunityRisk =>
      hydrate(communityRisks[idCommunityRisk] as CommunityRiskEntityState, organizationRisks, communities)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityRisk: CommunityRiskEntityState | null } {
  const { communityRisks, organizationRisks, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communityRisk = Object.values(communityRisks)[0];
  return {
    communityRisk: hydrate(communityRisk as CommunityRiskEntityState, organizationRisks, communities)
  };
}

function hydrate(
  communityRisk: CommunityRiskEntityState,
  organizationRiskEntities?: Dictionary<OrganizationRiskEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>
): CommunityRisk | null {
  if (!communityRisk) {
    return null;
  }

  const communityRiskHydrated: CommunityRiskEntityState = { ...communityRisk };
  if (organizationRiskEntities) {
    communityRiskHydrated.organizationRisk = organizationRiskEntities[
      communityRisk.organizationRisk as number
    ] as OrganizationRisk;
  } else {
    delete communityRiskHydrated.organizationRisk;
  }
  if (communityEntities) {
    communityRiskHydrated.community = communityEntities[communityRisk.community as number] as Community;
  } else {
    delete communityRiskHydrated.community;
  }

  return communityRiskHydrated as CommunityRisk;
}
