import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Profil } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { ProfilRelationsIds } from '@wip/store/ids-interfaces';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { ProfilSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedProfilService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProfilSelectors.selectIsLoadedProfil));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProfilSelectors.selectIsLoadingProfil));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [ProfilSelectors.selectIsReadyAndLoadedProfil as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProfils(schema: SelectSchema = {}): Observable<Profil[]> {
    return this.store$.pipe(select(ProfilSelectors.selectAllProfils(schema))).pipe(
      switchMap(({ profils }: { profils: Profil[] }) => {
        return this.getReady(schema).pipe(mapTo(profils));
      })
    );
  }

  public selectOneProfil(idProfil: number, schema: SelectSchema = {}): Observable<Profil> {
    return this.store$.pipe(select(ProfilSelectors.selectOneProfil(schema, idProfil))).pipe(
      switchMap(({ profil }: { profil: Profil }) => {
        return this.getReady(schema).pipe(mapTo(profil));
      })
    );
  }

  public selectAllActiveProfils(schema: SelectSchema = {}): Observable<Profil[]> {
    return this.store$.pipe(select(ProfilSelectors.selectActiveProfils(schema))).pipe(
      switchMap(({ profils }: { profils: Profil[] }) => {
        return this.getReady(schema).pipe(mapTo(profils));
      })
    );
  }

  public selectIdProfilsActive(): Observable<number[]> {
    return this.store$.pipe(select(ProfilSelectors.selectIdProfilsActive)).pipe(
      switchMap((idProfils: number[]) => {
        return this.getReady().pipe(mapTo(idProfils));
      })
    );
  }

  public getOneProfil(idProfil: number, params: any = {}, getResult?: boolean): void | Observable<Profil> {
    this.store$.dispatch(ProfilGeneratedActions.getOneProfil({ idProfil, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProfilGeneratedActions.normalizeManyProfilsAfterUpsert,
        ProfilGeneratedActions.profilsFailure,
        true
      );
    }
  }

  public getManyProfils(params: any = {}, getResult?: boolean): void | Observable<Profil[]> {
    this.store$.dispatch(ProfilGeneratedActions.getManyProfils({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProfilGeneratedActions.normalizeManyProfilsAfterUpsert,
        ProfilGeneratedActions.profilsFailure
      );
    }
  }

  public upsertOneProfil(
    profil: Partial<Profil>,
    ids: ProfilRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Profil> {
    this.store$.dispatch(ProfilGeneratedActions.upsertOneProfil({ profil, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProfilGeneratedActions.normalizeManyProfilsAfterUpsert,
        ProfilGeneratedActions.profilsFailure,
        true
      );
    }
  }

  public deleteOneProfil(idProfil: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ProfilGeneratedActions.deleteOneProfil({ idProfil }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProfilGeneratedActions.deleteOneProfilSuccess,
        ProfilGeneratedActions.profilsFailure
      );
    }
  }

  public setActiveProfils(idProfils: number[]): void {
    this.store$.dispatch(ProfilGeneratedActions.clearActivesProfils());
    this.store$.dispatch(ProfilGeneratedActions.addManyActivesProfils({ idProfils }));
  }
}
