import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { ProfilApiService } from '@wip/store/api-services';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeProfil } from '@wip/store/configs/normalization';
import { ProfilSelectors } from '@wip/store/selectors';
import { ProfilRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { RightProfilGeneratedActions } from '@wip/store/actions';
import { RightProfil } from '@api/api-interfaces';
import { UserRightGeneratedActions } from '@wip/store/actions';
import { UserRight } from '@api/api-interfaces';
import { UserProfilRightGeneratedActions } from '@wip/store/actions';
import { UserProfilRight } from '@api/api-interfaces';
import { RightGeneratedActions } from '@wip/store/actions';
import { Right } from '@api/api-interfaces';
import { OrganizationUserProfilGeneratedActions } from '@wip/store/actions';
import { OrganizationUserProfil } from '@api/api-interfaces';
import { OrganizationUserGeneratedActions } from '@wip/store/actions';
import { OrganizationUser } from '@api/api-interfaces';

export function getDefaultAddProfilActions(profil: ProfilEntityState, ids?: ProfilRelationsIds): Action[] {
  const actions: Action[] = [ProfilGeneratedActions.normalizeManyProfilsAfterUpsert({ profils: [profil] })];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyProfilSuccess({
        idOrganization: ids.organization,
        idProfils: [profil.idProfil]
      })
    );
    actions.push(
      ProfilGeneratedActions.addOrganizationSuccess({
        idProfil: profil.idProfil,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.rightProfils) {
    if (!Array.isArray(ids.rightProfils)) {
      actions.push(
        RightProfilGeneratedActions.upsertOneRightProfil({
          rightProfil: {
            idProfil: profil.idProfil,
            idRightProfil: ids.rightProfils as number
          } as RightProfil
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyRightProfilSuccess({
          idProfil: profil.idProfil,
          idRightProfils: [ids.rightProfils as number]
        })
      );
    } else {
      actions.push(
        RightProfilGeneratedActions.upsertManyRightProfils({
          rightProfils: (ids.rightProfils as number[]).map((idRightProfil: number) => ({
            idProfil: profil.idProfil,
            idRightProfil
          })) as RightProfil[]
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyRightProfilSuccess({
          idProfil: profil.idProfil,
          idRightProfils: ids.rightProfils as number[]
        })
      );
    }
  }

  if (ids?.userRights) {
    if (!Array.isArray(ids.userRights)) {
      actions.push(
        UserRightGeneratedActions.upsertOneUserRight({
          userRight: {
            idProfil: profil.idProfil,
            idUserRight: ids.userRights as number
          } as UserRight
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyUserRightSuccess({
          idProfil: profil.idProfil,
          idUserRights: [ids.userRights as number]
        })
      );
    } else {
      actions.push(
        UserRightGeneratedActions.upsertManyUserRights({
          userRights: (ids.userRights as number[]).map((idUserRight: number) => ({
            idProfil: profil.idProfil,
            idUserRight
          })) as UserRight[]
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyUserRightSuccess({
          idProfil: profil.idProfil,
          idUserRights: ids.userRights as number[]
        })
      );
    }
  }

  if (ids?.userProfilRights) {
    if (!Array.isArray(ids.userProfilRights)) {
      actions.push(
        UserProfilRightGeneratedActions.upsertOneUserProfilRight({
          userProfilRight: {
            idProfil: profil.idProfil,
            idUserProfilRight: ids.userProfilRights as number
          } as UserProfilRight
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyUserProfilRightSuccess({
          idProfil: profil.idProfil,
          idUserProfilRights: [ids.userProfilRights as number]
        })
      );
    } else {
      actions.push(
        UserProfilRightGeneratedActions.upsertManyUserProfilRights({
          userProfilRights: (ids.userProfilRights as number[]).map((idUserProfilRight: number) => ({
            idProfil: profil.idProfil,
            idUserProfilRight
          })) as UserProfilRight[]
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyUserProfilRightSuccess({
          idProfil: profil.idProfil,
          idUserProfilRights: ids.userProfilRights as number[]
        })
      );
    }
  }

  if (ids?.rights) {
    if (!Array.isArray(ids.rights)) {
      actions.push(
        RightGeneratedActions.upsertOneRight({
          right: {
            idProfil: profil.idProfil,
            idRight: ids.rights as number
          } as Right & any
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyRightSuccess({
          idProfil: profil.idProfil,
          idRights: [ids.rights as number]
        })
      );
    } else {
      actions.push(
        RightGeneratedActions.upsertManyRights({
          rights: (ids.rights as number[]).map((idRight: number) => ({
            idProfil: profil.idProfil,
            idRight
          })) as Right[] & any[]
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyRightSuccess({
          idProfil: profil.idProfil,
          idRights: ids.rights as number[]
        })
      );
    }
  }

  if (ids?.organizationUserProfils) {
    if (!Array.isArray(ids.organizationUserProfils)) {
      actions.push(
        OrganizationUserProfilGeneratedActions.upsertOneOrganizationUserProfil({
          organizationUserProfil: {
            idProfil: profil.idProfil,
            idOrganizationUserProfil: ids.organizationUserProfils as number
          } as OrganizationUserProfil & any
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyOrganizationUserProfilSuccess({
          idProfil: profil.idProfil,
          idOrganizationUserProfils: [ids.organizationUserProfils as number]
        })
      );
    } else {
      actions.push(
        OrganizationUserProfilGeneratedActions.upsertManyOrganizationUserProfils({
          organizationUserProfils: (ids.organizationUserProfils as number[]).map(
            (idOrganizationUserProfil: number) => ({
              idProfil: profil.idProfil,
              idOrganizationUserProfil
            })
          ) as OrganizationUserProfil[] & any[]
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyOrganizationUserProfilSuccess({
          idProfil: profil.idProfil,
          idOrganizationUserProfils: ids.organizationUserProfils as number[]
        })
      );
    }
  }

  if (ids?.organizationUsers) {
    if (!Array.isArray(ids.organizationUsers)) {
      actions.push(
        OrganizationUserGeneratedActions.upsertOneOrganizationUser({
          organizationUser: {
            idProfil: profil.idProfil,
            idOrganizationUser: ids.organizationUsers as number
          } as OrganizationUser & any
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyOrganizationUserSuccess({
          idProfil: profil.idProfil,
          idOrganizationUsers: [ids.organizationUsers as number]
        })
      );
    } else {
      actions.push(
        OrganizationUserGeneratedActions.upsertManyOrganizationUsers({
          organizationUsers: (ids.organizationUsers as number[]).map((idOrganizationUser: number) => ({
            idProfil: profil.idProfil,
            idOrganizationUser
          })) as OrganizationUser[] & any[]
        })
      );
      actions.push(
        ProfilGeneratedActions.addManyOrganizationUserSuccess({
          idProfil: profil.idProfil,
          idOrganizationUsers: ids.organizationUsers as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProfilActions(profil: ProfilEntityState): Action[] {
  const actions: Action[] = [ProfilGeneratedActions.deleteOneProfilSuccess({ idProfil: profil.idProfil })];

  if (profil.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idOrganizations: [profil.organization as number]
      })
    );
  }

  if (profil.rightProfils) {
    actions.push(
      RightProfilGeneratedActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idRightProfils: profil.rightProfils as number[]
      })
    );
  }

  if (profil.userRights) {
    actions.push(
      UserRightGeneratedActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idUserRights: profil.userRights as number[]
      })
    );
  }

  if (profil.userProfilRights) {
    actions.push(
      UserProfilRightGeneratedActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idUserProfilRights: profil.userProfilRights as number[]
      })
    );
  }

  if (profil.rights) {
    actions.push(
      RightGeneratedActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idRights: profil.rights as number[]
      })
    );
  }

  if (profil.organizationUserProfils) {
    actions.push(
      OrganizationUserProfilGeneratedActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idOrganizationUserProfils: profil.organizationUserProfils as number[]
      })
    );
  }

  if (profil.organizationUsers) {
    actions.push(
      OrganizationUserGeneratedActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idOrganizationUsers: profil.organizationUsers as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProfilEffects {
  constructor(
    protected actions$: Actions,
    protected profilApiService: ProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilGeneratedActions.getManyProfils),
      switchMap(({ params }) =>
        this.profilApiService.getProfils(params).pipe(
          map((profils: Profil[]) => {
            return ProfilGeneratedActions.normalizeManyProfilsAfterUpsert({ profils });
          }),
          catchError(error => of(ProfilGeneratedActions.profilsFailure({ error })))
        )
      )
    );
  });

  getOneProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilGeneratedActions.getOneProfil),
      switchMap(idProfil =>
        this.profilApiService.getProfil(idProfil).pipe(
          map((profil: Profil) => {
            return ProfilGeneratedActions.normalizeManyProfilsAfterUpsert({ profils: [profil] });
          }),
          catchError(error => of(ProfilGeneratedActions.profilsFailure({ error })))
        )
      )
    );
  });

  upsertOneProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilGeneratedActions.upsertOneProfil),
      concatMap(({ profil, ids }: { profil: Partial<Profil>; ids?: ProfilRelationsIds }) => {
        if (profil.idProfil) {
          return this.profilApiService.updateProfil(profil).pipe(
            map((profilReturned: Profil) => {
              return ProfilGeneratedActions.normalizeManyProfilsAfterUpsert({ profils: [profilReturned] });
            }),
            catchError(error => of(ProfilGeneratedActions.profilsFailure({ error })))
          );
        } else {
          return this.profilApiService.addProfil(profil).pipe(
            mergeMap((profilReturned: Profil) => getDefaultAddProfilActions(profilReturned, ids)),
            catchError(error => of(ProfilGeneratedActions.profilsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneProfil$ = createEffect(() => {
    const selectProfilState$ = this.store$.select(ProfilSelectors.selectProfilState);
    return this.actions$.pipe(
      ofType(ProfilGeneratedActions.deleteOneProfil),
      withLatestFrom(selectProfilState$),
      concatMap(([{ idProfil }, state]) =>
        this.profilApiService.deleteProfil(idProfil).pipe(
          mergeMap(_success => getDefaultDeleteProfilActions(state.entities[idProfil] as ProfilEntityState)),
          catchError(error => of(ProfilGeneratedActions.profilsFailure({ error })))
        )
      )
    );
  });

  normalizeManyProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilGeneratedActions.normalizeManyProfilsAfterUpsert),
      concatMap(({ profils }) => {
        const actions: Action[] = getActionsToNormalizeProfil(profils, StoreActionType.upsert);
        return [getMultiAction(actions, '[Profil] Normalization After Upsert Success')];
      })
    );
  });
}
