import { Component, OnInit } from '@angular/core';
import {
  Community,
  OrganizationCaracteristic,
  OrganizationMilestone,
  OrganizationUserProfil
} from '@api/api-interfaces';
import {
  GetPlanningProjectOutput,
  InputPlanningProjectsControllerInterface
} from '@api/projects/get-planning-projects';
import { BoardTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommunityApiService } from '@wip/store/api-services';
import {
  CommunityCaracteristicModel,
  CommunitySubFamilyModel,
  CommunityUserProfilModel,
  OrganizationCaracteristicModel,
  OrganizationMilestoneModel,
  OrganizationStepModel,
  OrganizationSubFamilyModel,
  OrganizationUserProfilModel,
  UserBoardStateModel,
  UserModel
} from '@wip/store/selectors-model';
import {
  CommunityService,
  OrganizationCaracteristicService,
  OrganizationMilestoneFamilyService,
  OrganizationMilestoneService,
  OrganizationService,
  OrganizationUserProfilService,
  OrganizationUserService
} from '@wip/store/services';
import { Observable, Subject, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'wip-grid-container',
  templateUrl: './grid-container.component.html',
  styleUrls: ['./grid-container.component.scss']
})
export class GridContainerComponent implements OnInit {
  public filters;
  public datas$: Observable<GetPlanningProjectOutput>;
  private getCommunities$ = new Subject<void>();
  private getParams$ = new Subject<void>();

  public communities$: Observable<Community[]>;
  public organizationUserProfils$: Observable<OrganizationUserProfil[]>;
  public organizationCaracteristics$: Observable<OrganizationCaracteristic[]>;
  public organizationMilestones$: Observable<OrganizationMilestone[]>;
  public idOrganizationFamily: number;
  public idOrganization: number;
  public idOrganizationMilestoneFamily: number;
  public idOrganizationMilestone: number;
  public idOrganizationCaracteristic: number;
  public excelStyles;
  public displayData: boolean = false;
  public titleMilestone: string;
  public isLoading: boolean = true;

  constructor(
    private newCommunityService: CommunityService,
    private newCommunityApiService: CommunityApiService,
    private newOrganizationUserService: OrganizationUserService,
    private newOrganizationUserProfilService: OrganizationUserProfilService,
    private newOrganizationMilestoneService: OrganizationMilestoneService,
    private newOrganizationMilestoneFamilyService: OrganizationMilestoneFamilyService,
    private newOrganizationCaracteristicService: OrganizationCaracteristicService,
    private newOrganizationService: OrganizationService
  ) {}

  ngOnInit(): void {
    this.newOrganizationUserService
      .selectAllActiveOrganizationUsers()
      .pipe(
        untilDestroyed(this),
        takeUntil(this.getCommunities$),
        map(ou => ou[0]),
        tap(ou => {
          if (ou) {
            this.idOrganization = ou.idOrganization;
            this.newCommunityService.getPlanningInfo(this.newOrganizationService.currentIdOrganization);
            this.getCommunities$.next();
          }
        })
      )
      .subscribe();
    this.newOrganizationUserService
      .selectAllActiveOrganizationUsers({ include: [UserBoardStateModel] })
      .pipe(
        untilDestroyed(this),
        filter(orgaUser => !!orgaUser[0]),
        map(ou => ou[0].userBoardStates.find(bs => bs.type === BoardTypeEnum.project)),
        distinctUntilChanged(),
        tap(userBoardStates => {
          this.isLoading = true;

          this.filters = userBoardStates?.filterState ? JSON.parse(userBoardStates.filterState) : {};

          if (this.filters.organizationFamily) {
            this.idOrganizationFamily = this.filters.organizationFamily;
          }

          if (
            this.filters.organizationMilestoneFamily &&
            this.filters.organizationMilestoneFamily[this.idOrganizationFamily] !== 0
          ) {
            this.idOrganizationMilestoneFamily = this.filters.organizationMilestoneFamily[this.idOrganizationFamily];
            this.displayData = true;
          } else {
            this.displayData = false;
          }

          if (this.filters.organizationCaracteristic) {
            this.idOrganizationCaracteristic = this.filters.organizationCaracteristic;
          }

          if (this.filters.organizationMilestoneFamily) {
            this.idOrganizationMilestone = this.filters.organizationMilestone;
          }

          const findGridDatasParams: InputPlanningProjectsControllerInterface = {
            idMilestoneFamily: this.filters.organizationMilestoneFamily
              ? this.filters.organizationMilestoneFamily[this.idOrganizationFamily]
              : 0,
            idOrganizationFamily: this.idOrganizationFamily,
            idOrganizationMilestone:
              this.filters.organizationMilestone &&
              this.filters.organizationMilestone[this.idOrganizationMilestoneFamily]
                ? this.filters.organizationMilestone[this.idOrganizationMilestoneFamily]
                : 0,
            idsSubFamily: this.filters.organizationSubFamilies
              ? this.filters.organizationSubFamilies[this.idOrganizationFamily]
              : [],
            idOrganization: this.idOrganization,
            textFilter: this.filters.searchString
          };

          this.datas$ = this.newCommunityApiService.findGridDatas(findGridDatasParams).pipe(untilDestroyed(this));

          this.communities$ = this.newCommunityService
            .selectAllCommunities({
              include: [
                { model: CommunityCaracteristicModel, include: [OrganizationCaracteristicModel] },
                OrganizationStepModel,
                { model: CommunitySubFamilyModel, include: [OrganizationSubFamilyModel] },
                { model: CommunityUserProfilModel, include: [OrganizationUserProfilModel, UserModel] }
              ]
            })
            .pipe(
              untilDestroyed(this),
              map(comms =>
                comms.filter(comm => comm.idOrganization === this.newOrganizationService.currentIdOrganization)
              )
            );
          this.organizationCaracteristics$ = this.newOrganizationCaracteristicService
            .selectAllOrganizationCaracteristics()
            .pipe(
              untilDestroyed(this),
              map(caracs =>
                caracs.filter(carac => carac.idOrganization === this.newOrganizationService.currentIdOrganization)
              )
            );
          this.organizationMilestones$ = this.newOrganizationMilestoneService.selectAllOrganizationMilestones().pipe(
            untilDestroyed(this),
            map(milestones =>
              milestones.filter(
                milestone => milestone.idOrganization === this.newOrganizationService.currentIdOrganization
              )
            )
          );
          this.organizationUserProfils$ = this.newOrganizationUserProfilService.selectAllOrganizationUserProfils().pipe(
            untilDestroyed(this),
            map(oup => oup.filter(orgaUser => orgaUser.idOrganization === orgaUser.idOrganization))
          );
          if (this.filters.organizationMilestoneFamily) {
            this.newOrganizationMilestoneFamilyService
              .selectOneOrganizationMilestoneFamily(
                this.filters.organizationMilestoneFamily[this.idOrganizationFamily],
                { include: [OrganizationMilestoneModel, OrganizationCaracteristicModel] }
              )
              .pipe(
                untilDestroyed(this),
                tap(orgaFam => {
                  if (orgaFam) {
                    this.excelStyles = this.getGridExcelStyles(orgaFam.organizationMilestones);
                  }
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();

    this.getParams$.subscribe({ next: _ => {} });
  }

  getGridExcelStyles(listOrganizationMilestones: OrganizationMilestone[]) {
    const excelStyles = [
      {
        id: 'header',
        font: {
          size: 11,
          bold: true
        }
      },
      {
        id: 'font11',
        font: {
          size: 11
        }
      },
      {
        id: 'bold',
        font: {
          color: 'black',
          bold: true
        }
      },
      {
        id: 'total',
        interior: {
          color: '#a9a9a9',
          pattern: 'Solid'
        }
      },
      {
        id: 'date',
        dataType: 'dateTime'
      },
      {
        id: 'number',
        dataType: 'number'
      },
      {
        id: 'string',
        dataType: 'string'
      }
    ];
    let allColors = [];
    for (const milestone of listOrganizationMilestones) {
      allColors.push(milestone.couleur, milestone.couleurSuite);
    }
    allColors = [...new Set(allColors)];
    return [
      ...excelStyles,
      ...allColors.map(color => ({
        id: color,
        interior: {
          color: color,
          pattern: 'Solid'
        }
      }))
    ];
  }
}
