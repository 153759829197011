import { AgGridUtils } from '@_app/utils/ag-grid/cell-formatter/ag-grid.utils';
import { BoardStateManager } from '@_app/utils/ag-grid/status-bar/board-states';
import { ToolPanelsIds } from '@_app/utils/ag-grid/status-bar/types';
import {
  ColDef,
  ColGroupDef,
  ExcelExportParams,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  Module
} from '@ag-grid-community/core';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  Community,
  CommunityCaracteristic,
  CommunityUserProfil,
  OrganizationCaracteristic,
  OrganizationDataColumn,
  OrganizationFamily,
  OrganizationMilestone,
  OrganizationStep,
  OrganizationUser,
  OrganizationUserProfil,
  UserBoardState
} from '@api/api-interfaces';
import { CommunityTypeStructureEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { sortByMultipleKey, sortNumber } from '@utils';
import dayjs from 'dayjs';
import { ReplaySubject, Subject, combineLatest, tap } from 'rxjs';
import { FilterConfigurationComponent } from './filter-configuration/filter-configuration.component';
import { FilterConfirmationDialogComponent } from './filter-confirmation-dialog/filter-confirmation-dialog.component';
import { FilterSaveComponent } from './filter-save/filter-save.component';
import { ProjectGridAdressService } from './project-grid-adress.service';
import { ProjectGridCaracteristicsService } from './project-grid-caracteristics.service';
import { ProjectGridDataService } from './project-grid-data.service';
import { ProjectGridMilestonesService } from './project-grid-milestones.service';
import { RetourTypeEnum, excelStyles } from './project-grid-params';
import { ProjectGridResponsiblesService } from './project-grid-responsibles.service';

@UntilDestroy()
@Component({
  selector: 'wip-project-grid',
  templateUrl: './project-grid.component.html',
  styleUrls: ['./project-grid.component.scss']
})
export class ProjectGridComponent implements OnInit, OnChanges, BoardStateManager {
  @Input() organizationUser: OrganizationUser;
  @Input() listProjects: Community[];
  @Input() listSteps: OrganizationStep[];
  @Input() idOrganization: number;
  @Input() listCaracteristics: OrganizationCaracteristic[];
  @Input() listMilestones: OrganizationMilestone[];
  @Input() listOrganizationUserProfils: OrganizationUserProfil[];
  @Input() listOrganizationUsers: OrganizationUser[];
  @Input() idOrganizationMilestoneFamily: number;
  @Input() idOrganizationFamily: number;
  @Input() organizationFamily: OrganizationFamily;
  @Input() editMode: boolean = false;
  @Input() create: boolean;
  @Input() save: boolean;
  @Input() saveColumns: boolean;
  @Input() handle: boolean;
  @Input() toggle: boolean;
  @Input() boardState: UserBoardState;
  @Input() organizationDataColumns: OrganizationDataColumn[];
  @Input() displayRepartition: boolean;

  @Output() updateCaracteristic: EventEmitter<Partial<CommunityCaracteristic[]>> = new EventEmitter();
  @Output() updateCommunity: EventEmitter<any> = new EventEmitter();
  @Output() updateUserProfil: EventEmitter<Partial<CommunityUserProfil>[]> = new EventEmitter();
  @Output() updateBoardState: EventEmitter<any> = new EventEmitter();
  @Output() updateCurrentFilterState: EventEmitter<any> = new EventEmitter();
  @Output() setUpdateButton: EventEmitter<boolean> = new EventEmitter();
  @Output() public boardStateChanges = new EventEmitter<any>();
  @Output() private disabledFilter: EventEmitter<boolean> = new EventEmitter();

  public gridOptions: GridOptions;
  public modules: Module[] = AgGridUtils.getCommonModules();
  public fullHeightGrid: boolean;
  public projectsRowData: any;
  public activeFilters = [];
  public isFilterButtonDisabled = true;
  public applyColumnState: () => void;
  public filterStateLoaded$ = new Subject<void>();
  public openAdress: boolean = false;
  public openCaracs: boolean = false;
  public openResps: boolean = false;
  public gridApi: GridApi;

  private gridData: any[] = [];
  private gridReady$: ReplaySubject<void> = new ReplaySubject(1);
  private dataReady$ = new ReplaySubject<void>();
  private boardStateLoaded$ = new ReplaySubject<UserBoardState>();
  private handleChange = new ReplaySubject<void>();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private projectGridCaracteristicsService: ProjectGridCaracteristicsService,
    private projectGridMilestonesService: ProjectGridMilestonesService,
    private projectGridAdressService: ProjectGridAdressService,
    private projectGridResponsiblesService: ProjectGridResponsiblesService,
    private projectGridDataService: ProjectGridDataService
  ) {}

  ngOnInit() {
    this.gridOptions = this.getDefaultGridOptions();
    combineLatest([this.gridReady$, this.boardStateLoaded$, this.dataReady$])
      .pipe(
        untilDestroyed(this),
        tap(_ => {
          this.applyColumnState();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes) {
    if (changes.create && this.gridApi) {
      this.onCreateConfiguration();
    }
    if (changes.saveColumns && this.gridApi) {
      this.onSaveConfiguration(true);
    }
    if (changes.save && this.gridApi) {
      this.onSaveConfiguration();
    }
    if (changes.handle && this.gridApi) {
      this.onHandleConfiguration();
    }
    if (changes.toggle && this.gridApi) {
      this.onHandleToggle();
    }
    if (
      changes.listCaracteristics ||
      changes.listMilestones ||
      changes.idMilestoneFamily ||
      changes.idProjectFamily ||
      changes.listOrganizationUserProfils ||
      changes.editMode ||
      changes.listProjects ||
      changes.displayRepartition
    ) {
      this.projectGridCaracteristicsService.listCaracteristics = this.listCaracteristics;
      this.projectGridResponsiblesService.listProjects = this.listProjects;
      this.gridApi?.setGridOption('columnDefs', this.createColumnDefs(this.displayRepartition));
      this.gridData = this.projectToRowData(this.listProjects, this.displayRepartition).sort(
        sortByMultipleKey([{ name: 'organizationStepOrdre', cb: sortNumber }, { name: 'nom' }])
      );
      this.setRowData();

      this.gridApi?.redrawRows();
    }
    if (changes.boardState) {
      this.boardStateLoaded$.next(this.boardState);
    }
    if (changes.handleChange) {
      this.gridApi?.redrawRows();
    }
    if (changes.organizationUser) {
      this.projectGridResponsiblesService.organizationUser = this.organizationUser;
    }
    if (changes.listOrganizationUsers) {
      this.projectGridResponsiblesService.listOrganizationUsers = this.listOrganizationUsers;
    }
  }

  public setRowData(): void {
    this.gridApi?.setGridOption('rowData', this.gridData);
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.projectGridResponsiblesService.gridApi = params.api;
    this.gridApi.hideOverlay();
    this.gridApi.showLoadingOverlay();
    this.gridApi?.setGridOption('columnDefs', this.createColumnDefs());
    this.gridApi.getColumnState();

    if (this.gridData.length) {
      this.setRowData();
    }
    this.gridApi.onFilterChanged();
    this.gridReady$.next();
  }

  private getDefaultGridOptions(): GridOptions {
    return {
      ...AgGridUtils.getCommonGridOption(false, {
        statusBar: {
          component: this,
          onFilterChanged: this.checkFilterActif.bind(this)
        }
      }),
      defaultExcelExportParams: {
        fileName: 'Tableau récapitulatif',
        sheetName: 'Dates clés',
        processCellCallback(params): string {
          let jsonValue;
          try {
            jsonValue = JSON.parse(params.value);
          } catch (e) {
            jsonValue = null;
          }

          if (jsonValue && jsonValue.hasOwnProperty('displayName')) {
            return jsonValue.displayName;
          }
          return params.value;
        }
      },
      rowData: [],
      columnDefs: [],
      rowSelection: this.editMode ? 'multiple' : 'single',
      enableRangeSelection: true,
      enableFillHandle: this.editMode ? true : false,
      suppressAggFuncInHeader: true,
      getRowId: row => row.data.idCommunity + '-' + row.data.idCommunityDataRow,
      suppressColumnMoveAnimation: true,
      suppressAnimationFrame: true,
      onColumnGroupOpened: params => {
        if (params.columnGroup.getGroupId() === 'adress') {
          this.openAdress = !this.openAdress;
        } else if (params.columnGroup.getGroupId() === 'caracs') {
          this.openAdress = !this.openCaracs;
        } else if (params.columnGroup.getGroupId() === 'resps') {
          this.openAdress = !this.openResps;
        }
      },
      enableCharts: true,
      autoGroupColumnDef: {
        headerName: '',
        maxWidth: 24,
        width: 24,
        minWidth: 24,
        resizable: false,
        sortable: false,
        filter: false,
        editable: false
      },
      getRowStyle: params => {
        if (!params.data) {
          return { background: '#d3d3d3', 'font-weight': 'bold' };
        }
      },
      defaultColDef: {
        ...AgGridUtils.getCommonGridOption().defaultColDef,
        filter: true,
        sortable: true,
        editable: this.editMode,
        menuTabs: ['filterMenuTab'],
        wrapHeaderText: true,
        autoHeaderHeight: true
      },
      groupHeaderHeight: 10,
      headerHeight: 70,
      excelStyles,
      onRowDoubleClicked: this.onDoubleClick.bind(this),
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'always'
    };
  }

  private createColumnDefs(displayRepartition: boolean = false): (ColDef | ColGroupDef)[] {
    const ret: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Cumul opérations',
        resizable: false,
        editable: false,
        width: 20,
        pinned: true,
        cellStyle: { textAlign: 'left', backgroundColor: this.editMode ? '#ceeaff' : '' },
        hide: true,
        valueGetter: () => 'Cumul opérations',
        rowGroup: true
      },
      this.projectGridAdressService.createAdressColumnDefsFormat(this.openAdress),
      {
        headerName: "Stade d'avancement",
        resizable: true,
        editable: this.editMode,
        enableRowGroup: true,
        cellStyle: { textAlign: 'left', backgroundColor: this.editMode ? '#ceeaff' : '' },
        cellEditorSelector: this.cellCaracteristicEditorSelectorStep.bind(this),
        valueGetter: params => params.data?.organizationStepLibelle,
        valueSetter: params => {
          params.data.organizationStepLibelle = params.newValue;
          return true;
        },
        valueFormatter: params => params.value,
        onCellValueChanged: params => {
          const idOrganizationStep = this.listSteps.find(step => step.libelle === params.newValue).idOrganizationStep;
          this.updateCommunity.emit({ idCommunity: params.data.idCommunity, idOrganizationStep });
          this.handleChange.next();
        },
        field: 'organizationStepLibelle',
        initialWidth: 120,
        comparator: (_valueA, _valueB, nodeA, nodeB) => {
          if (nodeA?.data?.organizationStepOrdre == nodeB?.data?.organizationStepOrdre) {
            return 0;
          }
          return nodeA?.data?.organizationStepOrdre > nodeB?.data?.organizationStepOrdre ? 1 : -1;
        }
      },
      {
        headerName: "Catégorie d'opération",
        resizable: true,
        enableRowGroup: true,
        editable: false,
        cellStyle: { textAlign: 'left', backgroundColor: this.editMode ? '#ceeaff' : '' },
        valueGetter: params => params.data?.subFamilies,
        valueFormatter: params => params.value,
        field: 'subFamilies',
        initialWidth: 120
      },
      {
        headerName: 'Dernière modification',
        columnGroupShow: 'closed',
        groupId: 'resps',
        hide: true,
        children: [
          {
            headerName: 'Date',
            resizable: true,
            enableRowGroup: true,
            editable: false,
            hide: true,
            cellStyle: { textAlign: 'center', backgroundColor: this.editMode ? '#ceeaff' : '' },
            valueGetter: params => params.data?.lastUpdate,
            valueFormatter: params => params.value,
            field: 'lastUpdate',
            initialWidth: 80
          },
          {
            headerName: 'Modifié par',
            resizable: true,
            enableRowGroup: true,
            editable: false,
            hide: true,
            cellStyle: { textAlign: 'left', backgroundColor: this.editMode ? '#ceeaff' : '' },
            valueGetter: params => params.data?.lastUpdateBy,
            valueFormatter: params => params.value,
            field: 'lastUpdateBy',
            initialWidth: 120
          }
        ]
      },
      this.projectGridResponsiblesService.createResponsibleColumnDefsFormat(
        this.listOrganizationUserProfils,
        this.idOrganization,
        this.idOrganizationFamily,
        this.openResps
      ),
      this.projectGridCaracteristicsService.createCaracteristicsColumnDefsFormat(
        this.listCaracteristics,
        this.idOrganization,
        this.idOrganizationFamily,
        this.openCaracs
      )
    ];
    if (displayRepartition) {
      ret.push(this.projectGridDataService.createDataColumnDefsFormat(this.organizationDataColumns));
    }
    ret.push(
      this.projectGridMilestonesService.createMilestonesColumnDefsFormat(this.listMilestones, this.organizationFamily)
    );
    return ret;
  }

  public registerApplyColumnState(applyColumnState: () => void): void {
    this.applyColumnState = applyColumnState;
  }

  private cellCaracteristicEditorSelectorStep(_params: any) {
    return {
      component: 'agRichSelectCellEditor',
      params: {
        values: this.listSteps.map(step => step.libelle)
      },
      popup: true
    };
  }

  private projectToRowData(projects: Community[], displayRepartition: boolean = false) {
    const ret: any = [];

    for (const project of projects) {
      const step = this.listSteps.find(o => o.idOrganizationStep === project.idOrganizationStep);

      let lastUpdateBy = '';

      const foundLastUpdateBy = this.listOrganizationUsers.find(ou => ou.idUser === project.projetDernierMajUser);
      if (foundLastUpdateBy) {
        lastUpdateBy = foundLastUpdateBy.user.prenom + ' ' + foundLastUpdateBy.user.nom;
      }

      const obj = {
        idCommunity: project.idCommunity,
        nom: project.nom,
        commune: project.commune,
        codeInseeCommune: project.codeInseeCommune,
        codePostal: project.codePostal,
        adresse: project.adresse,
        organizationStepLibelle: step?.libelle,
        organizationStepOrdre: step?.ordre,
        subFamilies: project.communitySubFamilys.map(c => c.organizationSubFamily?.libelle),
        lastUpdate: project.projetDernierMajDate ? dayjs(project.projetDernierMajDate).format('DD/MM/YY') : '',
        lastUpdateBy,
        responsable:
          project.communityUserProfils.length && project.communityUserProfils[0].user
            ? project.communityUserProfils[0].user.prenom + ' ' + project.communityUserProfils[0].user.nom
            : ''
      };

      project.communityCaracteristics.forEach(caracteristic => {
        const caracType = this.listCaracteristics.find(
          carac =>
            carac.idOrganizationCaracteristic === caracteristic.organizationCaracteristic?.idOrganizationCaracteristic
        )?.type;
        obj[caracteristic.organizationCaracteristic?.idOrganizationCaracteristic + '-'] = {
          type: caracType,
          value: this.projectGridCaracteristicsService.isNumber(caracType)
            ? caracteristic.valeurDecimal
            : caracteristic.valeur,
          idCommunityCaracteristic: caracteristic.idCommunityCaracteristic
        };
      });
      project.projectElements.forEach(projectElement => {
        if (projectElement.element) {
          obj[projectElement.element?.idOrganizationMilestone] = {
            ...obj[projectElement.element?.idOrganizationMilestone],
            constraintType: projectElement.constraintType,
            echeance: projectElement.element.echeance
              ? new Date(projectElement.element.echeance).toISOString().split('T')[0]
              : null,
            statusAction: projectElement.element.statusAction
          };
          obj[projectElement.element.idOrganizationMilestone + '-initial'] = projectElement.element.dateInitial
            ? new Date(projectElement.element.dateInitial).toISOString().split('T')[0]
            : null;
          obj[projectElement.element.idOrganizationMilestone + '-objective'] = projectElement.element.dateObjectif
            ? new Date(projectElement.element.dateObjectif).toISOString().split('T')[0]
            : null;
        }
      });
      project.communityUserProfils.forEach(commUserProfil => {
        if (commUserProfil.user?.prenom && commUserProfil.user?.nom) {
          obj[commUserProfil.organizationUserProfil?.idOrganizationUserProfil] = {
            ...obj[commUserProfil.organizationUserProfil?.idOrganizationUserProfil],
            idOrganizationUserProfil: commUserProfil.organizationUserProfil?.idOrganizationUserProfil,
            idCommunityUserProfil: commUserProfil.idCommunityUserProfil,
            idUser: commUserProfil.idUser,
            displayName: !!commUserProfil.user ? commUserProfil.user?.prenom + ' ' + commUserProfil.user?.nom : '',
            value: JSON.stringify({
              idUser: commUserProfil.idUser,
              displayName: !!commUserProfil.user ? commUserProfil.user?.prenom + ' ' + commUserProfil.user?.nom : ''
            })
          };
        }
      });
      if (displayRepartition) {
        if (!project.communityDataRows.length) {
          ret.push(obj);
        } else {
          project.communityDataRows.forEach(row => {
            const newObj = { ...obj };
            newObj['idCommunityDataRow'] = row.idCommunityDataRow;
            this.organizationDataColumns.forEach(col => {
              const valeur = row.valeurs.find(
                val =>
                  val.idOrganizationDataColumn === col.idOrganizationDataColumn &&
                  val.idCommunityDataRow === row.idCommunityDataRow
              );
              if (valeur) {
                newObj[col.idOrganizationDataColumn + '-rep'] = {};
                newObj[col.idOrganizationDataColumn + '-rep']['type'] = col.type;
                newObj[col.idOrganizationDataColumn + '-rep']['value'] = valeur[RetourTypeEnum[col.type]];
              }
            });
            ret.push(newObj);
          });
        }
      } else {
        ret.push(obj);
      }
    }
    this.dataReady$.next();
    return ret;
  }

  public downloadReport() {
    if (this.gridApi) {
      const options: ExcelExportParams = {
        fileName: 'Tableau récapitulatif',
        sheetName: 'Dates clés',
        processCellCallback(params): string {
          const value = params.value;
          if (value && value.displayName) {
            return value.displayName;
          }
        }
      };
      this.gridApi.exportDataAsExcel(options);
    }
  }

  public onDoubleClick(row): void {
    if (!this.editMode) {
      const comm = this.listProjects.find(el => el.idCommunity === row.data.idCommunity);
      const section = comm.typeStructure === CommunityTypeStructureEnum.project ? 'project' : 'development';
      const currentUrl = this.router.createUrlTree([`/#/app/${section}/${row.data.idCommunity}`]);
      const fullUrl = this.router.serializeUrl(currentUrl);
      const newUrl = fullUrl.replace('%23', '#');
      window.open(newUrl, '_blank');
    }
  }

  public onCreateConfiguration() {
    this.dialog
      .open(FilterSaveComponent, {
        panelClass: 'custom-dialog-fullscreen-sm',
        disableClose: true
      })
      .afterClosed()
      .pipe(
        untilDestroyed(this),
        tap(value => {
          if (!!value) {
            this.updateBoardState.emit({
              type: 'create',
              libelle: value,
              columnState: JSON.stringify(this.gridApi.getColumnState()),
              filterState: JSON.stringify(this.gridApi.getFilterModel())
            });
          }
        })
      )
      .subscribe();
  }

  public onSaveConfiguration(fromCols: boolean = false) {
    if (fromCols) {
      this.updateBoardState.emit({
        type: 'update',
        columnState: JSON.stringify(this.gridApi.getColumnState()),
        filterState: JSON.stringify(this.gridApi.getFilterModel())
      });
    } else {
      this.dialog
        .open(FilterConfirmationDialogComponent, {
          panelClass: 'custom-dialog-fullscreen-sm',
          disableClose: true,
          data: {
            content: 'Voulez vous écraser la configuration actuelle ?',
            columnState: JSON.stringify(this.gridApi.getColumnState()),
            filterState: JSON.stringify(this.gridApi.getFilterModel())
          }
        })
        .afterClosed()
        .pipe(
          untilDestroyed(this),
          tap(value => {
            if (value) {
              this.updateBoardState.emit({
                type: 'update',
                libelle: value,
                columnState: JSON.stringify(this.gridApi.getColumnState()),
                filterState: JSON.stringify(this.gridApi.getFilterModel())
              });
            }
          })
        )
        .subscribe();
    }
  }

  public onHandleConfiguration() {
    this.dialog
      .open(FilterConfigurationComponent, {
        panelClass: 'custom-dialog-fullscreen-sm',
        disableClose: true,
        data: {
          organizationUser: this.organizationUser,
          idOrganizationFamily: this.idOrganizationFamily,
          columnState: this.gridApi.getColumnState(),
          filterState: this.gridApi.getFilterModel()
        }
      })
      .afterClosed()
      .pipe(
        untilDestroyed(this),
        tap(value => {
          if (value) {
            this.updateBoardState.emit({
              type: 'delete',
              libelle: value,
              columnState: JSON.stringify(this.gridApi.getColumnState()),
              filterState: JSON.stringify(this.gridApi.getFilterModel())
            });
          }
        })
      )
      .subscribe();
  }

  public onHandleToggle() {
    this.gridApi.openToolPanel(ToolPanelsIds.columns);
    this.gridApi.setSideBarVisible(true);
  }

  public removeFilter(id) {
    const foundIndex = this.activeFilters.findIndex(el => el.id === id);
    this.activeFilters.splice(foundIndex, 1);

    const filtersRaw = this.gridApi.getFilterModel();
    delete filtersRaw[id];
    this.gridApi.setFilterModel(filtersRaw);
  }

  private checkFilterActif(event: FilterChangedEvent): void {
    if (event.type === 'filterChanged') {
      this.isFilterButtonDisabled = Object.keys(event.api.getFilterModel()).length === 0;
      this.disabledFilter.emit(this.isFilterButtonDisabled);
    }
  }

  public checkForUpdateButton(value: boolean) {
    this.setUpdateButton.emit(value);
  }
}
