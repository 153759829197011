import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RightProfil, RightProfilEntityState } from '@api/api-interfaces';
import { Right, RightEntityState } from '@api/api-interfaces';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { RightProfilState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const rightProfilRelations: string[] = ['rights', 'profils'];

export const { selectEntities, selectAll } = RightProfilState.adapter.getSelectors();

export const selectRightProfilState = createFeatureSelector<RightProfilState.IState>(
  RightProfilState.rightProfilFeatureKey
);

export const selectIsLoadedRightProfil = createSelector(
  selectRightProfilState,
  (state: RightProfilState.IState) => state.isLoaded
);

export const selectIsLoadingRightProfil = createSelector(
  selectRightProfilState,
  (state: RightProfilState.IState) => state.isLoading
);

export const selectIsReadyRightProfil = createSelector(
  selectRightProfilState,
  (state: RightProfilState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedRightProfil = createSelector(
  selectRightProfilState,
  (state: RightProfilState.IState) => state.isLoaded && !state.isLoading
);

export const selectRightProfilsEntities = createSelector(selectRightProfilState, selectEntities);

export const selectRightProfilsArray = createSelector(selectRightProfilState, selectAll);

export const selectIdRightProfilsActive = createSelector(
  selectRightProfilState,
  (state: RightProfilState.IState) => state.actives
);

const rightProfilsInObject = (rightProfils: Dictionary<RightProfilEntityState>) => ({ rightProfils });

const selectRightProfilsEntitiesDictionary = createSelector(selectRightProfilsEntities, rightProfilsInObject);

const selectAllRightProfilsObject = createSelector(selectRightProfilsEntities, rightProfils => {
  return hydrateAll({ rightProfils });
});

const selectOneRightProfilDictionary = (idRightProfil: number) =>
  createSelector(selectRightProfilsEntities, rightProfils => ({
    rightProfils: { [idRightProfil]: rightProfils[idRightProfil] }
  }));

const selectOneRightProfilDictionaryWithoutChild = (idRightProfil: number) =>
  createSelector(selectRightProfilsEntities, rightProfils => ({
    rightProfil: rightProfils[idRightProfil]
  }));

const selectActiveRightProfilsEntities = createSelector(
  selectIdRightProfilsActive,
  selectRightProfilsEntities,
  (actives: number[], rightProfils: Dictionary<RightProfilEntityState>) =>
    getRightProfilsFromActives(actives, rightProfils)
);

function getRightProfilsFromActives(
  actives: number[],
  rightProfils: Dictionary<RightProfilEntityState>
): Dictionary<RightProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (rightProfils[idActive]) {
      acc[idActive] = rightProfils[idActive];
    }
    return acc;
  }, {} as Dictionary<RightProfilEntityState>);
}

const selectAllRightProfilsSelectors: Dictionary<Selector> = {};
export function selectAllRightProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<RightProfil>(
      schema,
      selectAllRightProfilsSelectors,
      selectRightProfilsEntitiesDictionary,
      getRelationSelectors,
      rightProfilRelations,
      hydrateAll,
      'rightProfil'
    );
  } else {
    return selectAllRightProfilsObject;
  }
}

export function selectAllRightProfilsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'rightProfils'
): Selector {
  return createSelector(selectAllRightProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<RightProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.rightProfils.length; i++) {
      res[customKey][result.rightProfils[i].idRightProfil] = result.rightProfils[i];
    }
    return res;
  });
}

export function selectOneRightProfil(schema: SelectSchema = {}, idRightProfil: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneRightProfilDictionary(idRightProfil)];
    selectors.push(...getRelationSelectors(schema, rightProfilRelations, 'rightProfil'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneRightProfilDictionaryWithoutChild(idRightProfil);
  }
}

export function selectActiveRightProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveRightProfilsEntities, rightProfils => ({
      rightProfils
    }))
  ];
  selectors.push(...getRelationSelectors(schema, rightProfilRelations, 'rightProfil'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  rightProfils: Dictionary<RightProfilEntityState>;
  rights?: Dictionary<RightEntityState>;
  profils?: Dictionary<ProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { rightProfils: (RightProfil | null)[] } {
  const { rightProfils, rights, profils } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    rightProfils: Object.keys(rightProfils).map(idRightProfil =>
      hydrate(rightProfils[idRightProfil] as RightProfilEntityState, rights, profils)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { rightProfil: RightProfilEntityState | null } {
  const { rightProfils, rights, profils } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const rightProfil = Object.values(rightProfils)[0];
  return {
    rightProfil: hydrate(rightProfil as RightProfilEntityState, rights, profils)
  };
}

function hydrate(
  rightProfil: RightProfilEntityState,
  rightEntities?: Dictionary<RightEntityState>,
  profilEntities?: Dictionary<ProfilEntityState>
): RightProfil | null {
  if (!rightProfil) {
    return null;
  }

  const rightProfilHydrated: RightProfilEntityState = { ...rightProfil };
  if (rightEntities) {
    rightProfilHydrated.right = rightEntities[rightProfil.right as number] as Right;
  } else {
    delete rightProfilHydrated.right;
  }
  if (profilEntities) {
    rightProfilHydrated.profil = profilEntities[rightProfil.profil as number] as Profil;
  } else {
    delete rightProfilHydrated.profil;
  }

  return rightProfilHydrated as RightProfil;
}
