import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MeetingUserApiService } from '@wip/store/api-services';
import { GeneratedMeetingUserEffects } from './meeting-user-generated.effects';

@Injectable()
export class MeetingUserEffects extends GeneratedMeetingUserEffects {
  constructor(actions$: Actions, meetingUserApiService: MeetingUserApiService, store$: Store<AppState>) {
    super(actions$, meetingUserApiService, store$);
  }
}
