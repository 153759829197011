import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationKpi, OrganizationKpiEntityState } from '@api/api-interfaces';
import { CommunityKpi, CommunityKpiEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationKpiState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationKpiRelations: string[] = ['communityKpis', 'organizations', 'organizationFamilys'];

export const { selectEntities, selectAll } = OrganizationKpiState.adapter.getSelectors();

export const selectOrganizationKpiState = createFeatureSelector<OrganizationKpiState.IState>(
  OrganizationKpiState.organizationKpiFeatureKey
);

export const selectIsLoadedOrganizationKpi = createSelector(
  selectOrganizationKpiState,
  (state: OrganizationKpiState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationKpi = createSelector(
  selectOrganizationKpiState,
  (state: OrganizationKpiState.IState) => state.isLoading
);

export const selectIsReadyOrganizationKpi = createSelector(
  selectOrganizationKpiState,
  (state: OrganizationKpiState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationKpi = createSelector(
  selectOrganizationKpiState,
  (state: OrganizationKpiState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationKpisEntities = createSelector(selectOrganizationKpiState, selectEntities);

export const selectOrganizationKpisArray = createSelector(selectOrganizationKpiState, selectAll);

export const selectIdOrganizationKpisActive = createSelector(
  selectOrganizationKpiState,
  (state: OrganizationKpiState.IState) => state.actives
);

const organizationKpisInObject = (organizationKpis: Dictionary<OrganizationKpiEntityState>) => ({ organizationKpis });

const selectOrganizationKpisEntitiesDictionary = createSelector(
  selectOrganizationKpisEntities,
  organizationKpisInObject
);

const selectAllOrganizationKpisObject = createSelector(selectOrganizationKpisEntities, organizationKpis => {
  return hydrateAll({ organizationKpis });
});

const selectOneOrganizationKpiDictionary = (idOrganizationKpi: number) =>
  createSelector(selectOrganizationKpisEntities, organizationKpis => ({
    organizationKpis: { [idOrganizationKpi]: organizationKpis[idOrganizationKpi] }
  }));

const selectOneOrganizationKpiDictionaryWithoutChild = (idOrganizationKpi: number) =>
  createSelector(selectOrganizationKpisEntities, organizationKpis => ({
    organizationKpi: organizationKpis[idOrganizationKpi]
  }));

const selectActiveOrganizationKpisEntities = createSelector(
  selectIdOrganizationKpisActive,
  selectOrganizationKpisEntities,
  (actives: number[], organizationKpis: Dictionary<OrganizationKpiEntityState>) =>
    getOrganizationKpisFromActives(actives, organizationKpis)
);

function getOrganizationKpisFromActives(
  actives: number[],
  organizationKpis: Dictionary<OrganizationKpiEntityState>
): Dictionary<OrganizationKpiEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationKpis[idActive]) {
      acc[idActive] = organizationKpis[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationKpiEntityState>);
}

const selectAllOrganizationKpisSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationKpis(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationKpi>(
      schema,
      selectAllOrganizationKpisSelectors,
      selectOrganizationKpisEntitiesDictionary,
      getRelationSelectors,
      organizationKpiRelations,
      hydrateAll,
      'organizationKpi'
    );
  } else {
    return selectAllOrganizationKpisObject;
  }
}

export function selectAllOrganizationKpisDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationKpis'
): Selector {
  return createSelector(selectAllOrganizationKpis(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationKpiEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationKpis.length; i++) {
      res[customKey][result.organizationKpis[i].idOrganizationKpi] = result.organizationKpis[i];
    }
    return res;
  });
}

export function selectOneOrganizationKpi(schema: SelectSchema = {}, idOrganizationKpi: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationKpiDictionary(idOrganizationKpi)];
    selectors.push(...getRelationSelectors(schema, organizationKpiRelations, 'organizationKpi'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationKpiDictionaryWithoutChild(idOrganizationKpi);
  }
}

export function selectActiveOrganizationKpis(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationKpisEntities, organizationKpis => ({
      organizationKpis
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationKpiRelations, 'organizationKpi'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationKpis: Dictionary<OrganizationKpiEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  communityKpis?: Dictionary<CommunityKpiEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationKpis: (OrganizationKpi | null)[] } {
  const { organizationKpis, organizations, organizationFamilys, communityKpis } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationKpis: Object.keys(organizationKpis).map(idOrganizationKpi =>
      hydrate(
        organizationKpis[idOrganizationKpi] as OrganizationKpiEntityState,
        organizations,
        organizationFamilys,
        communityKpis
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationKpi: OrganizationKpiEntityState | null } {
  const { organizationKpis, organizations, organizationFamilys, communityKpis } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationKpi = Object.values(organizationKpis)[0];
  return {
    organizationKpi: hydrate(
      organizationKpi as OrganizationKpiEntityState,
      organizations,
      organizationFamilys,
      communityKpis
    )
  };
}

function hydrate(
  organizationKpi: OrganizationKpiEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  communityKpiEntities?: Dictionary<CommunityKpiEntityState>
): OrganizationKpi | null {
  if (!organizationKpi) {
    return null;
  }

  const organizationKpiHydrated: OrganizationKpiEntityState = { ...organizationKpi };
  if (organizationEntities) {
    organizationKpiHydrated.organization = organizationEntities[organizationKpi.organization as number] as Organization;
  } else {
    delete organizationKpiHydrated.organization;
  }
  if (organizationFamilyEntities) {
    organizationKpiHydrated.organizationFamily = organizationFamilyEntities[
      organizationKpi.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete organizationKpiHydrated.organizationFamily;
  }

  if (communityKpiEntities) {
    organizationKpiHydrated.communityKpis = ((organizationKpiHydrated.communityKpis as number[]) || []).map(
      id => communityKpiEntities[id]
    ) as CommunityKpi[];
  } else {
    delete organizationKpiHydrated.communityKpis;
  }

  return organizationKpiHydrated as OrganizationKpi;
}
