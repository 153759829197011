import { Component, OnInit } from '@angular/core';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import { BoardTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserBoardStateModel } from '@wip/store/selectors-model';
import { OrganizationMilestoneFamilyService, OrganizationUserService } from '@wip/store/services';
import { Observable, map, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'wip-charts-container',
  templateUrl: './charts-container.component.html',
  styleUrls: ['./charts-container.component.scss']
})
export class ChartsContainerComponent implements OnInit {
  public idOrganization: number;
  public idOrganizationFamily: number;
  public idsProjectSubFamily: number;
  public orgaMilestoneFamilies$: Observable<OrganizationMilestoneFamily[]>;
  public filters;
  public chartParams$: Observable<any>;

  constructor(
    private newOrganizationUserService: OrganizationUserService,
    private newOrganizationMilestoneFamilyService: OrganizationMilestoneFamilyService
  ) {}

  ngOnInit(): void {
    this.newOrganizationUserService
      .selectAllActiveOrganizationUsers({
        include: [UserBoardStateModel]
      })
      .pipe(
        untilDestroyed(this),
        map(ou => ou[0]),
        tap(orgaUser => {
          if (orgaUser?.userBoardStates?.length) {
            this.idOrganization = orgaUser.idOrganization;
            const boardState = orgaUser.userBoardStates?.find(bs => bs.type === BoardTypeEnum.project);

            if (boardState.filterState) {
              this.filters = JSON.parse(boardState.filterState);

              if (this.filters?.organizationFamily) {
                this.idOrganizationFamily = this.filters?.organizationFamily;
              }

              if (this.filters?.organizationSubFamilies && this.idOrganizationFamily) {
                this.idsProjectSubFamily = this.filters?.organizationSubFamilies[this.idOrganizationFamily];
              }
              this.orgaMilestoneFamilies$ = this.newOrganizationMilestoneFamilyService
                .selectAllOrganizationMilestoneFamilys()
                .pipe(
                  untilDestroyed(this),
                  map(omf => omf.filter(el => el.idOrganizationFamily === this.idOrganizationFamily))
                );
            }
          }
        })
      )
      .subscribe();
  }
}
