import {
  ConstraintTypeEnum,
  DurationFormat,
  ElementStatusActionEnum,
  ElementStatusActionEnumTrad,
  ElementTypeElement
} from '@enums';
import { GanttStatic } from '@wip/gantt-static';
import { Task } from '@api/api-interfaces';
import { durationFormatterParams } from '../new-gantt.component';
import { roundDuration } from './new-gantt-columns';

const checkboxStatus = {
  open: 'ic_fluent_circle_24_regular',
  ['not applicable']: 'ic_fluent_circle_24_filled',
  close: 'ic_fluent_checkmark_circle_24_regular',
  progress: 'ic_fluent_arrow_circle_right_24_regular',
  blocked: 'ic_fluent_error_circle_24_regular',
  abort: 'ic_fluent_dismiss_circle_24_regular',
  replace: 'ic_fluent_arrow_sync_circle_24_regular'
};

const checkboxColorStatus = {
  ['not applicable']: 'grey',
  close: 'green',
  progress: 'blue',
  blocked: 'red',
  abort: 'red',
  replace: 'red'
};

export function simpleTemplateFormatDate(task: Task, name: string, gantt) {
  if (!task[name]) {
    return '';
  }

  const formatFunc = gantt.date.date_to_str('%d/%m/%y');
  const date = formatFunc(new Date(task[name]));

  const css = 'color: grey';
  return '<div class="date-class"><span style=\'"' + css + "'>" + date + '</span></div>';
}

export function templateFormatDate(task: Task, gantt, name, businessDay) {
  const formatFunc = gantt.date.date_to_str('%d/%m/%y');
  if (!task[name]) {
    return '';
  }
  let date = formatFunc(task[name]);
  const isProject = task.type === gantt.config.types.project;
  const isTask = task.type === gantt.config.types.task;
  const isMilestone = task.idOrganizationMilestone;

  let css;

  if (isProject && task.ganttLite) {
    css = 'font-weight: bold; font-style: italic; color: #1e4c64;';
    date = formatDurationForProject(task, businessDay, task.ganttLite);
  } else if (isProject || (isMilestone && name === 'end_date')) {
    return '';
  } else if (isTask || isMilestone) {
    if (name === 'start_date' && task.constraint_type === ConstraintTypeEnum.MSO) {
      css = 'font-weight: bold';
    } else {
      css = 'font-style: italic; color: grey';
    }
  } else if (isMilestone && name === 'end_date') {
    return '';
  } else if (isMilestone) {
    if (name === 'start_date' && task.constraint_type === ConstraintTypeEnum.MSO) {
      css = 'font-weight: bold';
    } else {
      css = 'font-style: italic; color: grey';
    }
  }

  let cssBackground;
  if (isProject) {
    cssBackground = '';
  } else {
    cssBackground = setBackgroundColor(task, name);
  }

  return '<div class="date-class" style=\'' + cssBackground + "'><span style='" + css + "'>" + date + '</span></div>';
}

export function templateFormatText(task, gantt) {
  if (task.statusAction) {
    const cursorPointer = gantt.config.readonly ? '' : 'cursor-pointer';
    const isMilestone = task.idOrganizationMilestone;

    const milestone = isMilestone ? '"font-weight: bold"' : '';
    const isNotApplicable = task.statusAction === ElementStatusActionEnum['not applicable'] ? 'not-applicable' : '';
    const milestoneIcon = isMilestone
      ? '<span class="losange gulp gulp-34-losange" style="color: #ffc109; line-height: 30px; font-size: 15px"></span>'
      : '';

    const hasChecklists = task.children?.filter(c => c.typeElement === ElementTypeElement.elementAction);
    const checkedChildren = hasChecklists?.filter(c => c.statusAction === ElementStatusActionEnum.close).length;

    const hasDocuments = task.children?.filter(c => c.typeElement === ElementTypeElement.elementDocument);

    const hasReminder = task.reminders?.length;
    const checkbox = `<span><i title="${ElementStatusActionEnumTrad[task.statusAction]}" class=" tooltip icon icon-${
      checkboxStatus[task.statusAction]
    } ${checkboxColorStatus[task.statusAction]} checkbox-style-${task.id} ${cursorPointer} status-icon" >
    </i></span>`;

    return `<div class="dbclick flex flex-row align-items justify-between gap-4">
              <div class="background-circle-${
                checkboxStatus[task.statusAction]
              } flex flex-row justify center gap-4 row-text">
              
                ${checkbox}
                ${milestoneIcon}
                <span class="dbclick task-text ${isNotApplicable}" style=${milestone}>${task.text} </span>
              </div>
              <div class="flex flex-row gap-4">
                <div class="children-style flex flex-row ${!hasDocuments?.length ? 'display-none' : ''}">
                  <i class="icon icon-ic_fluent_attach_24_regular"></i>
                  <span class="text">${hasDocuments?.length}</span>
                </div>
                <div class="children-style flex flex-row ${!hasChecklists?.length ? 'display-none' : ''}">
                  <i class="icon icon-ic_fluent_task_list_ltr_24_regular"></i>
                  <span class="text">${checkedChildren}/${hasChecklists?.length}</span>
                </div>
                <div class="${!hasReminder ? 'display-none' : ''}">
                  <i class="bell icon icon-ic_fluent_alert_24_regular"></i>
                </div>
                <div class="${!task.note?.length ? 'display-none' : ''}">
                  <i class="bell icon icon-ic_fluent_chat_24_regular"></i>
                </div>
              </div>
            </div>
          `;
  } else {
    return `<span class="dbclick" style='font-weight: bold; color:#1e4c64'> ${task.text} </span>`;
  }
}

export function templateFormatDuration(task, businessDay, ganttLite) {
  if (task.type === 'milestone') {
    return '<span>-</span>';
  }

  const durationText = formatDurationForProject(task, businessDay, ganttLite);

  const css =
    task.type === 'project' ? '"color: #1e4c64; font-weight: bold"' : task.type === 'task' ? '"font-weight: bold"' : '';
  // const duration = task.type === 'milestone' ? '-' : task.duration + 'j';

  return `<span style=${css}> ${durationText} </span>`;
}

export function templatePredecessor(task, gantt) {
  const target = task.$target;
  let predecessor = '';
  if (target.length) {
    const prev = gantt.getLink(target[0]);
    if (prev) {
      predecessor = gantt.getTask(prev.source)?.index;
    }
  }

  return `<span class="right-align"> ${predecessor} </span>`;
}

export function templateLag(task, gantt, businessDay) {
  const target = task.$target;
  let lag = '';
  if (target.length) {
    const prev = gantt.getLink(target[0]);
    if (prev && prev.lag) {
      lag = formatDuration(prev.lag, businessDay);
    }
  }

  return `<span class="right-align"> ${lag} </span>`;
}

export function formatDuration(duration: number, businessDay: boolean): string {
  let daysInOneMonth, daysInOneWeek;

  if (businessDay) {
    daysInOneMonth = 20;
    daysInOneWeek = 5;
  } else {
    daysInOneMonth = 30;
    daysInOneWeek = 7;
  }

  if (duration === null || duration === undefined) {
    return null;
  } else if (duration === 0) {
    return `${duration}j`;
  } else if (duration % daysInOneMonth === 0) {
    return `${Math.floor(duration / daysInOneMonth)}m`;
  } else if (duration % daysInOneWeek === 0) {
    return `${Math.floor(duration / daysInOneWeek)}s`;
  } else {
    return `${duration}j`;
  }
}

export function getIntDurationFromStringDuration(durationDisplay: string, businessDay: boolean): number {
  let daysInOneMonth, daysInOneWeek, daysInOneYear;

  if (businessDay) {
    daysInOneMonth = 20;
    daysInOneWeek = 5;
    daysInOneYear = 250;
  } else {
    daysInOneMonth = 30;
    daysInOneWeek = 7;
    daysInOneYear = 365;
  }

  const val = parseInt(durationDisplay);
  const unit = durationDisplay.slice(-1);

  if (unit === 's') {
    return val * daysInOneWeek;
  } else if (unit === 'm') {
    return val * daysInOneMonth;
  } else if (unit === 'a') {
    return val * daysInOneYear;
  } else {
    return val;
  }
}

export function formatDateForEditor(date: Date): string {
  if (!date) {
    return '';
  }

  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  return year + '-' + month + '-' + day;
}

export function formatDurationForProject(task: Task, businessDay, ganttLite) {
  let daysInOneMonth;
  let daysInOneYear;

  if (businessDay) {
    daysInOneYear = 250;
    daysInOneMonth = 20;
  } else {
    daysInOneYear = 365;
    daysInOneMonth = 30;
  }

  const year = ganttLite ? ' an' : 'a';
  const years = ganttLite ? ' ans' : 'a';
  const month = ganttLite ? ' mois' : 'm';
  const day = ganttLite ? ' jour' : 'j';
  const days = ganttLite ? ' jours' : 'j';

  if (task.duration <= 0) {
    return '1' + day;
  } else if (task.duration < daysInOneMonth) {
    return task.duration === 1 ? '1' + day : task.duration + days;
  } else if (task.duration < daysInOneYear) {
    const months = (task.duration / daysInOneMonth).toFixed(1);
    const split = months.split('.');
    const unit = split[0];

    let dec;
    if (split.length > 1 && split[1] !== '0') {
      dec = ',' + split[1];
    } else {
      dec = '';
    }
    return unit + dec + month;
  } else if (task.duration >= daysInOneYear) {
    const months = (task.duration / daysInOneYear).toFixed(1);
    const split = months.split('.');
    const unit = split[0];

    let dec;
    if (split.length > 1 && split[1] !== '0') {
      dec = ',' + split[1];
    } else {
      dec = '';
    }
    return unit === '1' ? unit + dec + year : unit + dec + years;
  }
}

export function setBackgroundColor(task: Task, name: string): string {
  const fifteenDays = new Date().getTime() + 1296000000;

  if (
    task.statusAction === ElementStatusActionEnum.close ||
    task.statusAction === ElementStatusActionEnum['not applicable'] ||
    task.statusAction === ElementStatusActionEnum.abort
  ) {
    return '';
  }
  if (task[name] < new Date()) {
    return 'background-color: #FAEFEB';
  } else if (task[name] < new Date(fifteenDays)) {
    return 'background-color: #FFF6E5';
  }
  return '';
}

export function setNextDatesFilter(task: Task, name: string): boolean {
  const fifteenDays = new Date().getTime() + 1296000000;

  if (task.type === 'project') {
    return true;
  }
  if (task.statusAction === ElementStatusActionEnum.close || task.statusAction === ElementStatusActionEnum.abort) {
    return false;
  }
  if (task[name] < new Date() || task[name] < new Date(fifteenDays)) {
    return true;
  }
  return false;
}

export function getTaskPredecessorFormatted(taskPredecessors: string, businessDay: boolean): string {
  if (!taskPredecessors) {
    return '';
  }
  const taskPredecessorsArray: string[] = taskPredecessors.split('; ');
  const predecessors: string[] = [];
  taskPredecessorsArray.forEach(predecessor => {
    const [antiLag] = /(^[0-9]+)?(FD|DD|FF|DF|SS|EE|SE|ES)?(\+|-)+/.exec(predecessor) || [null];
    if (antiLag) {
      const integerLag = predecessor.replace(antiLag, '');
      const lag = getIntDurationFromStringDuration(integerLag, businessDay);
      predecessor = antiLag + formatDuration(lag, businessDay).replace(' ', '');
    }
    predecessors.push(predecessor);
  });
  return predecessors.join('; ');
}

export function getPredecessors(task: Task, gantt: GanttStatic, businessDay: boolean): string {
  let ret = '';

  const linkType = {
    0: 'FD',
    1: 'DD',
    2: 'FF',
    3: 'DF'
  };

  const links = gantt.getLinks().filter(link => link.target === task.id);
  if (links.length) {
    links.forEach((link, index) => {
      const sourceTask = gantt.getTask(link.source);
      let formatter = gantt.ext.formatters.durationFormatter(
        durationFormatterParams(businessDay, DurationFormat[link.lagFormat])
      );
      if (formatter.format(link.lag).includes('.')) {
        formatter = gantt.ext.formatters.durationFormatter(durationFormatterParams(businessDay, DurationFormat.j));
      }

      if (sourceTask) {
        let str = '';

        str += sourceTask.index;
        if (link.lag !== 0) {
          str += linkType[link.type] === 'FD' ? '' : linkType[link.type];
          str += link.lag > 0 ? '+' : '-';
          str += roundDuration(formatter.format(link.lag));

          if (link.lag < 0) {
            str = str.replace('+', '');
          }
        }
        if (index < links.length - 1) {
          str += ';';
        }

        ret += str;
      }
    });
  }

  return ret;
}
