import { createAction, props } from '@ngrx/store';
import { Element, GanttLink } from '@api/api-interfaces';
import { ActionFormDTO } from '@dto';

export * from './project-element-generated.actions';

export const validateActionForm = createAction('[ProjectElement] Validate Action Form', props<ActionFormDTO>());

export const save = createAction('[ProjectElement] Save', props<any>());

export const insertProjectElement = createAction('[ProjectElement] Insert Project Element', props<any>());

export const reschedule = createAction(
  '[ProjectElement] Reschedule',
  props<{ data: { idCommunity: number; tasks: Partial<Element>[]; links: Partial<GanttLink>[] } }>()
);

export const deleteOneProjectElementCustom = createAction(
  '[ProjectElement] Delete One ProjectElement Custom',
  props<{ idProjectElement: number }>()
);

export const reorderProjectElements = createAction('[ProjectElement] Reorder Gantt Elements', props<{ tasks: any }>());
