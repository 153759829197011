export default {
  // TODO : traduire cet objet en fonction du contexte de chaque propriété durant l'utilisatioon d'ag-grid
  // for filter panel
  // https://www.ag-grid.com/angular-data-grid/localisation/
  page: 'Page',
  more: 'Plus',
  to: 'A',
  of: 'De',
  next: 'Suivant',
  last: 'Dernier',
  first: 'Premier',
  previous: 'Précédent',
  loadingOoo: 'Chargement...',

  // for set filter
  selectAll: 'Tout sélectionner',
  searchOoo: 'Recherche...',
  blanks: 'Blanc',

  // for number filter and text filter
  filterOoo: 'Filtre...',
  equals: 'Egal à',
  notEqual: 'Différent',

  // for number filter
  lessThan: 'Inférieur à',
  greaterThan: 'Supérieur à',
  lessThanOrEqual: 'Inférieur ou égal à',
  greaterThanOrEqual: 'Supérieur ou égal à',
  // prettier-ignore
  inRange: 'Dans l\'intervalle',
  inRangeStart: 'De',
  inRangeEnd: 'A',

  // for text filter
  contains: 'Contient',
  notContains: 'Ne contient pas',
  startsWith: 'Commence par',
  endsWith: 'Se termine par',

  // filter conditions
  andCondition: 'ET',
  orCondition: 'OU',

  // Filter Buttons
  applyFilter: 'Ok',
  resetFilter: 'Réinitialiser filtre',
  clearFilter: 'Vider',
  cancelFilter: 'Annuler',

  // the header of the default group column
  group: 'Groupe',

  // tool panel
  columns: 'Colonnes',
  filters: 'Filtres',
  rowGroupColumns: 'Groupe de colonnes',
  rowGroupColumnsEmptyMessage: 'Groupe de colonnes vides',
  valueColumns: 'Valeur des colonnes',
  pivotMode: 'Mode pivot',
  groups: 'Groupes',
  values: 'Valeurs',
  pivots: 'Pivots',
  valueColumnsEmptyMessage: 'Colonnes de valeur nulle',
  pivotColumnsEmptyMessage: 'Colonnes-pivot vides',
  // prettier-ignore
  toolPanelButton: 'Panneau d\'outils',

  // other
  noRowsToShow: 'Pas de ligne à afficher',

  // enterprise menu
  pinColumn: 'Colonne épinglée',
  valueAggregation: 'Agrégation de valeur',
  autosizeThiscolumn: 'Redimensionner cette colonne',
  autosizeAllColumns: 'Tout redimensionner',
  groupBy: 'Groupé par',
  ungroupBy: 'Non-groupé par',
  resetColumns: 'Réinitialiser les colonnes',
  expandAll: 'Tout étendre',
  collapseAll: 'Tout remplier',
  toolPanel: 'laTool Panelo',
  export: 'Exporter',
  csvExport: 'Exporter au format .csv',
  excelExport: 'Exporter sous Excel au format .xlsx',
  excelXmlExport: 'Exporter sous Excel au format .xml',

  // enterprise menu (charts)
  pivotChartAndPivotMode: 'Tableau croisé dynamique et mode Pivot',
  pivotChart: 'Tableau croisé dynamique',
  chartRange: 'Plage de graphiques',

  columnChart: 'Graphique en colonnes',
  groupedColumn: 'Colonne groupé',
  stackedColumn: 'Colonne emplilée',
  normalizedColumn: 'Colonne normalisée',

  barChart: 'Graphique en barres',
  groupedBar: 'Barre groupée',
  stackedBar: 'Barre empilée',
  normalizedBar: 'Barre normalisée',

  pieChart: 'Graphique en camembert',
  pie: 'Camembert',
  doughnut: 'Torus',

  line: 'Ligne',

  xyChart: 'laX Y (Scatter)',
  scatter: 'laScatter',
  bubble: 'laBubble',

  areaChart: 'laArea',
  area: 'laArea',
  stackedArea: 'laStacked',
  normalizedArea: 'la100% Stacked',

  // enterprise menu pinning
  pinLeft: 'laPin <<',
  pinRight: 'laPin >>',
  noPin: 'laDontPin <>',

  // enterprise menu aggregation and status bar
  sum: 'laSum',
  min: 'laMin',
  max: 'laMax',
  none: 'laNone',
  count: 'laCount',
  average: 'laAverage',
  filteredRows: 'laFiltered',
  selectedRows: 'laSelected',
  totalRows: 'laTotal Rows',
  totalAndFilteredRows: 'laRows',

  // standard menu
  copy: 'Copier',
  copyWithHeaders: 'Copier avec en-têtes',
  ctrlC: 'Ctrl + C',
  paste: 'Coller',
  ctrlV: 'Ctrl + V',

  // charts
  pivotChartTitle: 'laPivot Chart',
  rangeChartTitle: 'laRange Chart',
  settings: 'laSettings',
  data: 'laData',
  format: 'laFormat',
  categories: 'laCategories',
  series: 'laSeries',
  axis: 'laAxis',
  color: 'laColor',
  thickness: 'laThickness',
  xRotation: 'laX Rotation',
  yRotation: 'laY Rotation',
  ticks: 'laTicks',
  width: 'laWidth',
  length: 'laLength',
  padding: 'laPadding',
  chart: 'laChart',
  title: 'laTitle',
  font: 'laFont',
  top: 'laTop',
  right: 'laRight',
  bottom: 'laBottom',
  left: 'laLeft',
  labels: 'laLabels',
  size: 'laSize',
  legend: 'laLegend',
  position: 'laPosition',
  markerSize: 'laMarker Size',
  markerStroke: 'laMarker Stroke',
  markerPadding: 'laMarker Padding',
  itemPaddingX: 'laItem Padding X',
  itemPaddingY: 'laItem Padding Y',
  strokeWidth: 'laStroke Width',
  offset: 'laOffset',
  tooltips: 'laTooltips',
  offsets: 'laOffsets',
  callout: 'laCallout',
  markers: 'laMarkers',
  shadow: 'laShadow',
  blur: 'laBlur',
  xOffset: 'laX Offset',
  yOffset: 'laY Offset',
  lineWidth: 'laLine Width',
  normal: 'laNormal',
  bold: 'laBold',
  italic: 'laItalic',
  boldItalic: 'laBold Italic',
  fillOpacity: 'laFill Opacity',
  strokeOpacity: 'laLine Opacity',
  columnGroup: 'Column',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Line',
  scatterGroup: 'Scatter',
  areaGroup: 'Area',
  groupedColumnTooltip: 'laGrouped',
  stackedColumnTooltip: 'laStacked',
  normalizedColumnTooltip: 'la100% Stacked',
  groupedBarTooltip: 'laGrouped',
  stackedBarTooltip: 'laStacked',
  normalizedBarTooltip: 'la100% Stacked',
  pieTooltip: 'laPie',
  doughnutTooltip: 'laDoughnut',
  lineTooltip: 'laLine',
  groupedAreaTooltip: 'laGrouped',
  stackedAreaTooltip: 'laStacked',
  normalizedAreaTooltip: 'la100% Stacked',
  scatterTooltip: 'laScatter',
  bubbleTooltip: 'laBubble',
  noDataToChart: 'Pas de données à afficher.',
  pivotChartRequiresPivotMode: 'Le tableau croisé dynamique nécessite que le mode pivot soit activé.'
};
