import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedCommunityCaracteristicService } from './community-caracteristic-generated.service';
import { Actions } from '@ngrx/effects';
import { TypeEnum } from '@enums';
import { Observable } from 'rxjs';
import * as CommunityCaracteristicAction from '@wip/store/actions';

import { CommunityCaracteristic } from '@api/api-interfaces';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';

@Injectable({
  providedIn: 'root'
})
export class CommunityCaracteristicService extends GeneratedCommunityCaracteristicService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public changeValueType(
    params: { idOrganizationCaracteristic: number; valueType: TypeEnum },
    getResult?: boolean
  ): void | Observable<CommunityCaracteristic> {
    this.store$.dispatch(CommunityCaracteristicAction.changeValueType(params));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityCaracteristicAction.CommunityCaracteristicGeneratedActions
          .normalizeManyCommunityCaracteristicsAfterUpsert,
        CommunityCaracteristicAction.CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure,
        true
      );
    }
  }

  public fillHandleCaracteristics(
    communityCaracteristics: Partial<CommunityCaracteristic>[],
    getResult?: boolean
  ): void | Observable<CommunityCaracteristic> {
    this.store$.dispatch(CommunityCaracteristicAction.FillHandleCaracteristics({ communityCaracteristics }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityCaracteristicAction.CommunityCaracteristicGeneratedActions
          .normalizeManyCommunityCaracteristicsAfterUpsert,
        CommunityCaracteristicAction.CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure,
        true
      );
    }
  }
}
