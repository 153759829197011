export enum OrganizationElemTypeEnum {
  synthesis = 'synthesis',
  notes = 'notes',
  other = 'other'
}

export enum OrganizationElemTypeEnumTradEToFr {
  synthesis = 'Synthèse',
  notes = 'Notes',
  other = 'Autre'
}

export enum OrganizationElemTypeEnumTradFrToE {
  Synthèse = 'synthesis',
  Notes = 'notes',
  Autre = 'other'
}
