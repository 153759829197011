<div>
  <div class="container flex flex-col justify-start items-center">
    @if (options.edit) {
      <div (click)="onEdit()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <i class="icon icon-ic_fluent_edit_24_regular"></i>
        <span>Modifier</span>
      </div>
    }

    @if (options.addSubStep) {
      <div (click)="onStep()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <mat-icon svgIcon="add-substep" />
        <span>Insérer sous-étape</span>
      </div>
    }
    @if (options.addProjectElement) {
      <div (click)="onLivrable()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <i class="icon icon-ic_fluent_add_circle_24_regular"></i>
        <span>Insérer livrable</span>
      </div>
    }
    @if (options.insertStep) {
      <div (click)="onStep()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <i class="icon icon-ic_fluent_group_list_24_regular"></i>
        <span>Insérer groupement</span>
      </div>
    }
    @if (options.duplicate) {
      <div (click)="onDuplicate()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <i class="icon icon-ic_fluent_save_copy_24_regular"></i>
        <span>Dupliquer</span>
      </div>
    }
    @if (options.exportExcel) {
      <div (click)="onExcelExport()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <mat-icon class="gulp gulp-34-download" />
        <span>"Exporter Excel</span>
      </div>
    }
    @if (options.exportPDF) {
      <div (click)="onPDFExport()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <img style="width: 20px; height: auto" src="assets/images/icons/34-file-pdf.svg" />
        <span>export PDF</span>
      </div>
    }
    @if (options.exportPng) {
      <div (click)="onPngExport()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <img style="width: 20px; height: auto" src="assets/images/icons/34-file-img.svg" />
        <span>export image</span>
      </div>
    }
    @if (expandSons) {
      <div (click)="onExpandSons()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <span matListIcon class="gulp gulp-34-expand"></span>
        <span>Déplier cette étape</span>
      </div>
    }
    @if (collapseSons) {
      <div (click)="onCollapseSons()" class="flex flex-row justify-start items-center gap-8 full-width items">
        <span matListIcon class="gulp gulp-34-collapse"></span>
        <span>Plier cette étape</span>
      </div>
    }
    @if (options.deleteAll) {
      <div (click)="onDeleteAll()" class="flex flex-row justify-start items-center gap-8 full-width items red-color">
        <i class="icon icon-ic_fluent_delete_24_regular"></i>
        <span>Tout supprimer</span>
      </div>
    }
    @if (options.delete) {
      <div (click)="onDelete()" class="flex flex-row justify-start items-center gap-8 full-width items red-color">
        <i class="icon icon-ic_fluent_delete_24_regular"></i> <span>Supprimer</span>
      </div>
    }
  </div>
</div>
