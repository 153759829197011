import { UserBoardStateState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userBoardStateReducersGeneratedFunctions } from './user-board-state-generated.reducer';

const userBoardStateReducersFunctions = [...userBoardStateReducersGeneratedFunctions];

const userBoardStateReducer = createReducer(UserBoardStateState.initialState, ...userBoardStateReducersFunctions);

export function reducer(state: UserBoardStateState.IState | undefined, action: Action) {
  return userBoardStateReducer(state, action);
}
