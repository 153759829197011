import { OrganizationFamilyState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationFamilyReducersGeneratedFunctions } from './organization-family-generated.reducer';

const organizationFamilyReducersFunctions = [...organizationFamilyReducersGeneratedFunctions];

const organizationFamilyReducer = createReducer(
  OrganizationFamilyState.initialState,
  ...organizationFamilyReducersFunctions
);

export function reducer(state: OrganizationFamilyState.IState | undefined, action: Action) {
  return organizationFamilyReducer(state, action);
}
