import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityCaracteristic, CommunityCaracteristicEntityState } from '@api/api-interfaces';
import { CommunityCaracteristicApiService } from '@wip/store/api-services';
import { CommunityCaracteristicGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityCaracteristic } from '@wip/store/configs/normalization';
import { CommunityCaracteristicSelectors } from '@wip/store/selectors';
import { CommunityCaracteristicRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { CommunityGeneratedActions } from '@wip/store/actions';

export function getDefaultAddCommunityCaracteristicActions(
  communityCaracteristic: CommunityCaracteristicEntityState,
  ids?: CommunityCaracteristicRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert({
      communityCaracteristics: [communityCaracteristic]
    })
  ];

  if (ids?.organizationCaracteristic) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.addManyCommunityCaracteristicSuccess({
        idOrganizationCaracteristic: ids.organizationCaracteristic,
        idCommunityCaracteristics: [communityCaracteristic.idCommunityCaracteristic]
      })
    );
    actions.push(
      CommunityCaracteristicGeneratedActions.addOrganizationCaracteristicSuccess({
        idCommunityCaracteristic: communityCaracteristic.idCommunityCaracteristic,
        idOrganizationCaracteristic: ids.organizationCaracteristic
      })
    );
  }

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityCaracteristicSuccess({
        idCommunity: ids.community,
        idCommunityCaracteristics: [communityCaracteristic.idCommunityCaracteristic]
      })
    );
    actions.push(
      CommunityCaracteristicGeneratedActions.addCommunitySuccess({
        idCommunityCaracteristic: communityCaracteristic.idCommunityCaracteristic,
        idCommunity: ids.community
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCommunityCaracteristicActions(
  communityCaracteristic: CommunityCaracteristicEntityState
): Action[] {
  const actions: Action[] = [
    CommunityCaracteristicGeneratedActions.deleteOneCommunityCaracteristicSuccess({
      idCommunityCaracteristic: communityCaracteristic.idCommunityCaracteristic
    })
  ];

  if (communityCaracteristic.organizationCaracteristic) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.deleteManyCommunityCaracteristicSuccess({
        idCommunityCaracteristics: [communityCaracteristic.idCommunityCaracteristic],
        idOrganizationCaracteristics: [communityCaracteristic.organizationCaracteristic as number]
      })
    );
  }

  if (communityCaracteristic.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityCaracteristicSuccess({
        idCommunityCaracteristics: [communityCaracteristic.idCommunityCaracteristic],
        idCommunities: [communityCaracteristic.community as number]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityCaracteristicEffects {
  constructor(
    protected actions$: Actions,
    protected communityCaracteristicApiService: CommunityCaracteristicApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityCaracteristics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityCaracteristicGeneratedActions.getManyCommunityCaracteristics),
      switchMap(({ params }) =>
        this.communityCaracteristicApiService.getCommunityCaracteristics(params).pipe(
          map((communityCaracteristics: CommunityCaracteristic[]) => {
            return CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert({
              communityCaracteristics
            });
          }),
          catchError(error => of(CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure({ error })))
        )
      )
    );
  });

  getOneCommunityCaracteristic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityCaracteristicGeneratedActions.getOneCommunityCaracteristic),
      switchMap(idCommunityCaracteristic =>
        this.communityCaracteristicApiService.getCommunityCaracteristic(idCommunityCaracteristic).pipe(
          map((communityCaracteristic: CommunityCaracteristic) => {
            return CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert({
              communityCaracteristics: [communityCaracteristic]
            });
          }),
          catchError(error => of(CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityCaracteristic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityCaracteristicGeneratedActions.upsertOneCommunityCaracteristic),
      concatMap(
        ({
          communityCaracteristic,
          ids
        }: {
          communityCaracteristic: Partial<CommunityCaracteristic>;
          ids?: CommunityCaracteristicRelationsIds;
        }) => {
          if (communityCaracteristic.idCommunityCaracteristic) {
            return this.communityCaracteristicApiService.updateCommunityCaracteristic(communityCaracteristic).pipe(
              map((communityCaracteristicReturned: CommunityCaracteristic) => {
                return CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert({
                  communityCaracteristics: [communityCaracteristicReturned]
                });
              }),
              catchError(error => of(CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure({ error })))
            );
          } else {
            return this.communityCaracteristicApiService.addCommunityCaracteristic(communityCaracteristic).pipe(
              mergeMap((communityCaracteristicReturned: CommunityCaracteristic) =>
                getDefaultAddCommunityCaracteristicActions(communityCaracteristicReturned, ids)
              ),
              catchError(error => of(CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCommunityCaracteristic$ = createEffect(() => {
    const selectCommunityCaracteristicState$ = this.store$.select(
      CommunityCaracteristicSelectors.selectCommunityCaracteristicState
    );
    return this.actions$.pipe(
      ofType(CommunityCaracteristicGeneratedActions.deleteOneCommunityCaracteristic),
      withLatestFrom(selectCommunityCaracteristicState$),
      concatMap(([{ idCommunityCaracteristic }, state]) =>
        this.communityCaracteristicApiService.deleteCommunityCaracteristic(idCommunityCaracteristic).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityCaracteristicActions(
              state.entities[idCommunityCaracteristic] as CommunityCaracteristicEntityState
            )
          ),
          catchError(error => of(CommunityCaracteristicGeneratedActions.communityCaracteristicsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityCaracteristicsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityCaracteristicGeneratedActions.normalizeManyCommunityCaracteristicsAfterUpsert),
      concatMap(({ communityCaracteristics }) => {
        const actions: Action[] = getActionsToNormalizeCommunityCaracteristic(
          communityCaracteristics,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CommunityCaracteristic] Normalization After Upsert Success')];
      })
    );
  });
}
