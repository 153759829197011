<div class="wrap">
  @if (quickFilterText) {
    <div class="pill">
      <span class="ellipsis"
        >Rechercher : <b>{{ quickFilterText }}</b></span
      >
      <span class="remove" (click)="removeQuickFilter()"><i class="icon-ic_fluent_dismiss_24_filled"></i></span>
    </div>
  }
  @for (i of filterList; track i.key) {
    <div class="pill">
      <span class="ellipsis"
        >{{ i.column }} : <b>{{ i.values }}</b></span
      >
      <span class="remove" (click)="removeFilter(i)"><i class="icon-ic_fluent_dismiss_24_filled"></i></span>
    </div>
  }
</div>
