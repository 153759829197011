import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserRight } from '@api/api-interfaces';
import { UserRightGeneratedActions, deleteManyUserRights } from '@wip/store/actions';
import { applyManyUserRights } from '@wip/store/actions';
import * as AppState from '@wip/store/configs/reducers';
import { catchApiActions } from '@wip/store/utils';
import { Observable } from 'rxjs';
import { GeneratedUserRightService } from './user-right-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UserRightService extends GeneratedUserRightService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public applyManyUserRights(
    params: { idProfil: number; idOrganizationSubFamily: number; ids: number[] },
    getResult?: boolean
  ): void | Observable<UserRight> {
    this.store$.dispatch(applyManyUserRights({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserRightGeneratedActions.normalizeManyUserRightsAfterUpsert,
        UserRightGeneratedActions.userRightsFailure,
        true
      );
    }
  }

  public deleteManyUserRights(params: { ids: number[] }, getResult?: boolean) {
    this.store$.dispatch(deleteManyUserRights({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserRightGeneratedActions.normalizeManyUserRightsAfterUpsert,
        UserRightGeneratedActions.userRightsFailure,
        true
      );
    }
  }
}
