import { Injectable } from '@angular/core';
import { Profil, ReorderProfilInterface } from '@api/api-interfaces';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProfilActions, ProfilGeneratedActions } from '@wip/store/actions';
import { AppState } from '@wip/store/configs/reducers';
import { catchApiActions } from '@wip/store/utils';
import { Observable } from 'rxjs';
import { GeneratedProfilService } from './profil-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProfilService extends GeneratedProfilService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public reorder(params: ReorderProfilInterface, getResult?: boolean): void | Observable<Profil[]> {
    this.store$.dispatch(ProfilActions.reorderProfils({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProfilGeneratedActions.normalizeManyProfilsAfterUpsert,
        ProfilGeneratedActions.profilsFailure,
        true
      );
    }
  }

  public duplicateProfil(idProfil: number): void | Observable<Profil> {
    this.store$.dispatch(ProfilActions.duplicateProfil({ idProfil }));
  }
}
