import { CommunityCaracteristicState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityCaracteristicReducersGeneratedFunctions } from './community-caracteristic-generated.reducer';

const communityCaracteristicReducersFunctions = [...communityCaracteristicReducersGeneratedFunctions];

const communityCaracteristicReducer = createReducer(
  CommunityCaracteristicState.initialState,
  ...communityCaracteristicReducersFunctions
);

export function reducer(state: CommunityCaracteristicState.IState | undefined, action: Action) {
  return communityCaracteristicReducer(state, action);
}
