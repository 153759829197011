import { CommunityRiskState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityRiskReducersGeneratedFunctions } from './community-risk-generated.reducer';

const communityRiskReducersFunctions = [...communityRiskReducersGeneratedFunctions];

const communityRiskReducer = createReducer(CommunityRiskState.initialState, ...communityRiskReducersFunctions);

export function reducer(state: CommunityRiskState.IState | undefined, action: Action) {
  return communityRiskReducer(state, action);
}
