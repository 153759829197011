import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationMilestoneAssociation } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationMilestoneAssociationRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationMilestoneAssociationGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneAssociationSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationMilestoneAssociationService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(
      select(OrganizationMilestoneAssociationSelectors.selectIsLoadedOrganizationMilestoneAssociation)
    );
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(
      select(OrganizationMilestoneAssociationSelectors.selectIsLoadingOrganizationMilestoneAssociation)
    );
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationMilestoneAssociationSelectors.selectIsReadyAndLoadedOrganizationMilestoneAssociation as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationMilestoneAssociations(
    schema: SelectSchema = {}
  ): Observable<OrganizationMilestoneAssociation[]> {
    return this.store$
      .pipe(select(OrganizationMilestoneAssociationSelectors.selectAllOrganizationMilestoneAssociations(schema)))
      .pipe(
        switchMap(
          ({
            organizationMilestoneAssociations
          }: {
            organizationMilestoneAssociations: OrganizationMilestoneAssociation[];
          }) => {
            return this.getReady(schema).pipe(mapTo(organizationMilestoneAssociations));
          }
        )
      );
  }

  public selectOneOrganizationMilestoneAssociation(
    idOrganizationMilestoneAssociation: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationMilestoneAssociation> {
    return this.store$
      .pipe(
        select(
          OrganizationMilestoneAssociationSelectors.selectOneOrganizationMilestoneAssociation(
            schema,
            idOrganizationMilestoneAssociation
          )
        )
      )
      .pipe(
        switchMap(
          ({
            organizationMilestoneAssociation
          }: {
            organizationMilestoneAssociation: OrganizationMilestoneAssociation;
          }) => {
            return this.getReady(schema).pipe(mapTo(organizationMilestoneAssociation));
          }
        )
      );
  }

  public selectAllActiveOrganizationMilestoneAssociations(
    schema: SelectSchema = {}
  ): Observable<OrganizationMilestoneAssociation[]> {
    return this.store$
      .pipe(select(OrganizationMilestoneAssociationSelectors.selectActiveOrganizationMilestoneAssociations(schema)))
      .pipe(
        switchMap(
          ({
            organizationMilestoneAssociations
          }: {
            organizationMilestoneAssociations: OrganizationMilestoneAssociation[];
          }) => {
            return this.getReady(schema).pipe(mapTo(organizationMilestoneAssociations));
          }
        )
      );
  }

  public selectIdOrganizationMilestoneAssociationsActive(): Observable<number[]> {
    return this.store$
      .pipe(select(OrganizationMilestoneAssociationSelectors.selectIdOrganizationMilestoneAssociationsActive))
      .pipe(
        switchMap((idOrganizationMilestoneAssociations: number[]) => {
          return this.getReady().pipe(mapTo(idOrganizationMilestoneAssociations));
        })
      );
  }

  public getOneOrganizationMilestoneAssociation(
    idOrganizationMilestoneAssociation: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestoneAssociation> {
    this.store$.dispatch(
      OrganizationMilestoneAssociationGeneratedActions.getOneOrganizationMilestoneAssociation({
        idOrganizationMilestoneAssociation,
        params
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneAssociationGeneratedActions.normalizeManyOrganizationMilestoneAssociationsAfterUpsert,
        OrganizationMilestoneAssociationGeneratedActions.organizationMilestoneAssociationsFailure,
        true
      );
    }
  }

  public getManyOrganizationMilestoneAssociations(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestoneAssociation[]> {
    this.store$.dispatch(
      OrganizationMilestoneAssociationGeneratedActions.getManyOrganizationMilestoneAssociations({ params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneAssociationGeneratedActions.normalizeManyOrganizationMilestoneAssociationsAfterUpsert,
        OrganizationMilestoneAssociationGeneratedActions.organizationMilestoneAssociationsFailure
      );
    }
  }

  public upsertOneOrganizationMilestoneAssociation(
    organizationMilestoneAssociation: Partial<OrganizationMilestoneAssociation>,
    ids: OrganizationMilestoneAssociationRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestoneAssociation> {
    this.store$.dispatch(
      OrganizationMilestoneAssociationGeneratedActions.upsertOneOrganizationMilestoneAssociation({
        organizationMilestoneAssociation,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneAssociationGeneratedActions.normalizeManyOrganizationMilestoneAssociationsAfterUpsert,
        OrganizationMilestoneAssociationGeneratedActions.organizationMilestoneAssociationsFailure,
        true
      );
    }
  }

  public deleteOneOrganizationMilestoneAssociation(
    idOrganizationMilestoneAssociation: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationMilestoneAssociationGeneratedActions.deleteOneOrganizationMilestoneAssociation({
        idOrganizationMilestoneAssociation
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneAssociationGeneratedActions.deleteOneOrganizationMilestoneAssociationSuccess,
        OrganizationMilestoneAssociationGeneratedActions.organizationMilestoneAssociationsFailure
      );
    }
  }

  public setActiveOrganizationMilestoneAssociations(idOrganizationMilestoneAssociations: number[]): void {
    this.store$.dispatch(
      OrganizationMilestoneAssociationGeneratedActions.clearActivesOrganizationMilestoneAssociations()
    );
    this.store$.dispatch(
      OrganizationMilestoneAssociationGeneratedActions.addManyActivesOrganizationMilestoneAssociations({
        idOrganizationMilestoneAssociations
      })
    );
  }
}
