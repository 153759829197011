import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationCaracteristicColumn, OrganizationCaracteristicColumnEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicColumnRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationCaracteristicColumnGeneratedActions = createActionGroup({
  source: 'Organization Caracteristic Column Generated',
  events: {
    'Get One Organization Caracteristic Column': props<{ idOrganizationCaracteristicColumn: number; params?: any }>(),
    'Get Many Organization Caracteristic Columns': props<{ params: any }>(),
    'Add Many Actives Organization Caracteristic Columns': props<{ idOrganizationCaracteristicColumns: number[] }>(),
    'Delete One Active Organization Caracteristic Column': props<{ idOrganizationCaracteristicColumn: number }>(),
    'Clear Actives Organization Caracteristic Columns': emptyProps(),
    'Upsert One Organization Caracteristic Column': props<{
      organizationCaracteristicColumn: Partial<OrganizationCaracteristicColumn>;
      ids?: OrganizationCaracteristicColumnRelationsIds;
    }>(),
    'Upsert Many Organization Caracteristic Columns': props<{
      organizationCaracteristicColumns: Partial<OrganizationCaracteristicColumn>[];
      ids?: OrganizationCaracteristicColumnRelationsIds;
    }>(),
    'Upsert Many Organization Caracteristic Columns Success': props<{
      organizationCaracteristicColumns: OrganizationCaracteristicColumnEntityState[];
    }>(),
    'Delete One Organization Caracteristic Column': props<{ idOrganizationCaracteristicColumn: number }>(),
    'Delete One Organization Caracteristic Column Success': props<{ idOrganizationCaracteristicColumn: number }>(),
    'Normalize Many Organization Caracteristic Columns After Upsert': props<{
      organizationCaracteristicColumns: OrganizationCaracteristicColumnEntityState[];
    }>(),
    'Organization Caracteristic Columns Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Caracteristic Columns': emptyProps(),
    'Add Many Organization Caracteristic Value Success': props<{
      idOrganizationCaracteristicColumn: number;
      idOrganizationCaracteristicValues: number[];
    }>(),
    'Delete Many Organization Caracteristic Value Success': props<{
      idOrganizationCaracteristicValues: number[];
      idOrganizationCaracteristicColumns: number[];
    }>(),
    'Add Organization Caracteristic Success': props<{
      idOrganizationCaracteristicColumn: number;
      idOrganizationCaracteristic: number;
    }>(),
    'Delete Many Organization Caracteristic Success': props<{
      idOrganizationCaracteristics: number[];
      idOrganizationCaracteristicColumns: number[];
    }>()
  }
});
