import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { CommunityRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityGeneratedActions = createActionGroup({
  source: 'Community Generated',
  events: {
    'Get One Community': props<{ idCommunity: number; params?: any }>(),
    'Get Many Communities': props<{ params: any }>(),
    'Add Many Actives Communities': props<{ idCommunities: number[] }>(),
    'Delete One Active Community': props<{ idCommunity: number }>(),
    'Clear Actives Communities': emptyProps(),
    'Upsert One Community': props<{ community: Partial<Community>; ids?: CommunityRelationsIds }>(),
    'Upsert Many Communities': props<{ communities: Partial<Community>[]; ids?: CommunityRelationsIds }>(),
    'Upsert Many Communities Success': props<{ communities: CommunityEntityState[] }>(),
    'Delete One Community': props<{ idCommunity: number }>(),
    'Delete One Community Success': props<{ idCommunity: number }>(),
    'Normalize Many Communities After Upsert': props<{ communities: CommunityEntityState[] }>(),
    'Communities Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Communities': emptyProps(),
    'Add Many Community User Success': props<{ idCommunity: number; idCommunityUsers: number[] }>(),
    'Delete Many Community User Success': props<{ idCommunityUsers: number[]; idCommunities: number[] }>(),
    'Add Many Community User Profil Success': props<{ idCommunity: number; idCommunityUserProfils: number[] }>(),
    'Delete Many Community User Profil Success': props<{ idCommunityUserProfils: number[]; idCommunities: number[] }>(),
    'Add Many Community Caracteristic Success': props<{ idCommunity: number; idCommunityCaracteristics: number[] }>(),
    'Delete Many Community Caracteristic Success': props<{
      idCommunityCaracteristics: number[];
      idCommunities: number[];
    }>(),
    'Add Many Community Kpi Success': props<{ idCommunity: number; idCommunityKpis: number[] }>(),
    'Delete Many Community Kpi Success': props<{ idCommunityKpis: number[]; idCommunities: number[] }>(),
    'Add Many Community Data Row Success': props<{ idCommunity: number; idCommunityDataRows: number[] }>(),
    'Delete Many Community Data Row Success': props<{ idCommunityDataRows: number[]; idCommunities: number[] }>(),
    'Add Many Community Group Success': props<{ idCommunity: number; idCommunityGroups: number[] }>(),
    'Delete Many Community Group Success': props<{ idCommunityGroups: number[]; idCommunities: number[] }>(),
    'Add Many Community Milestone Success': props<{ idCommunity: number; idCommunityMilestones: number[] }>(),
    'Delete Many Community Milestone Success': props<{ idCommunityMilestones: number[]; idCommunities: number[] }>(),
    'Add Many Community Milestone Family Success': props<{
      idCommunity: number;
      idCommunityMilestoneFamilys: number[];
    }>(),
    'Delete Many Community Milestone Family Success': props<{
      idCommunityMilestoneFamilys: number[];
      idCommunities: number[];
    }>(),
    'Add Many Community Risk Success': props<{ idCommunity: number; idCommunityRisks: number[] }>(),
    'Delete Many Community Risk Success': props<{ idCommunityRisks: number[]; idCommunities: number[] }>(),
    'Add Many Community Sub Family Success': props<{ idCommunity: number; idCommunitySubFamilys: number[] }>(),
    'Delete Many Community Sub Family Success': props<{ idCommunitySubFamilys: number[]; idCommunities: number[] }>(),
    'Add Many Community Module Success': props<{ idCommunity: number; idCommunityModules: number[] }>(),
    'Delete Many Community Module Success': props<{ idCommunityModules: number[]; idCommunities: number[] }>(),
    'Add Many Project Element Success': props<{ idCommunity: number; idProjectElements: number[] }>(),
    'Delete Many Project Element Success': props<{ idProjectElements: number[]; idCommunities: number[] }>(),
    'Add Many Gantt Link Success': props<{ idCommunity: number; idGanttLinks: number[] }>(),
    'Delete Many Gantt Link Success': props<{ idGanttLinks: number[]; idCommunities: number[] }>(),
    'Add Many Folder Success': props<{ idCommunity: number; idFolders: number[] }>(),
    'Delete Many Folder Success': props<{ idFolders: number[]; idCommunities: number[] }>(),
    'Add Many Todo Success': props<{ idCommunity: number; idTodos: number[] }>(),
    'Delete Many Todo Success': props<{ idTodos: number[]; idCommunities: number[] }>(),
    'Add Many Meeting Success': props<{ idCommunity: number; idMeetings: number[] }>(),
    'Delete Many Meeting Success': props<{ idMeetings: number[]; idCommunities: number[] }>(),
    'Add Many Timeline Element Success': props<{ idCommunity: number; idTimelineElements: number[] }>(),
    'Delete Many Timeline Element Success': props<{ idTimelineElements: number[]; idCommunities: number[] }>(),
    'Add Many Notes History Success': props<{ idCommunity: number; idNotesHistories: number[] }>(),
    'Delete Many Notes History Success': props<{ idNotesHistories: number[]; idCommunities: number[] }>(),
    'Add Many Project Module Success': props<{ idCommunity: number; idProjectModules: number[] }>(),
    'Delete Many Project Module Success': props<{ idProjectModules: number[]; idCommunities: number[] }>(),
    'Add Many Group Success': props<{ idCommunity: number; idGroups: number[] }>(),
    'Delete Many Group Success': props<{ idGroups: number[]; idCommunities: number[] }>(),
    'Add Many User Success': props<{ idCommunity: number; idUsers: number[] }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idCommunities: number[] }>(),
    'Add Organization Success': props<{ idCommunity: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idCommunities: number[] }>(),
    'Add Organization Family Success': props<{ idCommunity: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{ idOrganizationFamilys: number[]; idCommunities: number[] }>(),
    'Add Organization Step Success': props<{ idCommunity: number; idOrganizationStep: number }>(),
    'Delete Many Organization Step Success': props<{ idOrganizationSteps: number[]; idCommunities: number[] }>()
  }
});
