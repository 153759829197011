import { createEntityAdapter } from '@ngrx/entity';
import { CommunityUserProfilEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<CommunityUserProfilEntityState>;

export const adapter = createEntityAdapter<CommunityUserProfilEntityState>({
  selectId: o => o.idCommunityUserProfil
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const communityUserProfilFeatureKey = 'communityUserProfil';
