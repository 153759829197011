<div mat-dialog-title>
  <h1 mat-dialog-title>Erreur de dépendance circulaire</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
</div>

<div style="margin: 0 24px">
  <h3>Concernant les éléments :</h3>
  @for (ganttTask of data.ganttTasks; track ganttTask) {
    <div>
      <li>Ligne {{ ganttTask?.index }} : {{ ganttTask?.text }}</li>
    </div>
  }
</div>
