import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganizationElement } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { map, Observable } from 'rxjs';
import { GeneratedOrganizationElementApiService } from './organization-element-api-generated.service';
import { ConfigurationApiService } from 'libs/api/old-api-services/src';
import { IupdateOrganizationElement } from '@dto';

@Injectable({
  providedIn: 'root'
})
export class OrganizationElementApiService extends GeneratedOrganizationElementApiService {
  constructor(
    repo: RepositoryService,
    private http: HttpClient,
    private configurationApi: ConfigurationApiService
  ) {
    super(repo);
  }

  public upload(params: {
    formData: FormData;
    idOrganization: number;
    ordre?: number;
  }): Observable<OrganizationElement[]> {
    const options = {
      params: {
        ordre: params.ordre
      },
      reportProgress: true
    };

    return this.http
      .post(
        this.configurationApi.fullAddressV2 + '/organization-element/uploadFile/' + params.idOrganization,
        params.formData,
        options
      )
      .pipe(
        map((retour: any) => {
          return retour.data;
        })
      );
  }

  public updateNewOrganizationElement(body: IupdateOrganizationElement): Observable<OrganizationElement> {
    return this.repo.update('organization-element/update', body);
  }
}
