import { IMetaUser } from '@_app/shared/interfaces/MetaUser.interface';
import { IGroupMetaUsersSuggested } from '@_app/shared/interfaces/MetaUserGroup.interface';
import { SearchUsersComponent } from '@_app/shared/search-users/search-users/search-users.component';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ElementLibrary } from '@api/api-interfaces';
import {
  CommunityUserModel,
  CommunityUserProfilModel,
  OrganizationUserProfilModel,
  UserModel
} from '@wip/store/selectors-model';
import { CommunityService, ElementLibraryService, OrganizationUserService, UserService } from '@wip/store/services';
import { Subject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-edit-responsible',
  templateUrl: './edit-responsible.component.html',
  styleUrls: ['./edit-responsible.component.scss']
})
export class EditResponsibleComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild(SearchUsersComponent) searchUsersComponent: SearchUsersComponent;
  constructor(
    public dialogRef: MatDialogRef<EditResponsibleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { responsibles: ElementLibrary[]; dialog: MatDialog; idElement: number },
    private newCommunityService: CommunityService,
    private newUserService: UserService,
    private newElementLibraryService: ElementLibraryService,
    private organizationUserService: OrganizationUserService
  ) {}
  userInfo: IMetaUser[] | ElementLibrary[];
  suggestions: IGroupMetaUsersSuggested[];
  shouldUpdate: boolean;
  initialData: any;
  idCommunity: number;

  ngOnInit() {
    this.getData();
    this.getSuggestedUser();
    this.initialData = [].concat(this.data.responsibles);
    this.shouldUpdate = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    if (this.shouldUpdate) {
      this.newElementLibraryService.changeResponsibles({
        responsibles: this.data.responsibles,
        idElement: this.data.idElement
      });
    }
  }

  getData(): void {
    this.userInfo = this.data.responsibles;
  }

  @HostListener('document:keyup.escape', ['$event']) onKeyupHandler() {
    this.shouldUpdate = false;
    this.cancel();
    this.dialogRef.close();
  }

  getSuggestedUser(): void {
    combineLatest([
      this.newCommunityService
        .selectAllActiveCommunities({
          include: [
            { model: CommunityUserModel, include: [UserModel] },
            { model: CommunityUserProfilModel, include: [UserModel, OrganizationUserProfilModel] }
          ]
        })
        .pipe(
          untilDestroyed(this),
          map(comms => comms[0])
        ),
      this.newUserService.selectAllActiveUsers().pipe(
        untilDestroyed(this),
        map(users => users[0])
      ),
      this.organizationUserService.selectAllOrganizationUsers({ include: [UserModel] })
    ])
      .pipe(
        untilDestroyed(this),
        tap(([community, user, orgaUsers]) => {
          if (community && user) {
            const authorizedUsers = orgaUsers.filter(ou =>
              community.authorizedUsers.some(au => au.idUser === ou.idUser)
            );
            this.idCommunity = community.idCommunity;
            this.suggestions = [
              {
                label: 'Vous',
                users: [{ user }],
                checked: false,
                request: false
              },
              {
                label: 'Équipe',
                users: community.communityUserProfils,
                checked: false,
                request: false
              },
              {
                label: 'Autres utilisateurs',
                users: authorizedUsers,
                checked: false,
                request: false
              }
            ];
          }
        })
      )
      .subscribe();
  }

  cancel(): void {}
}
