import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MeetingApiService } from '@wip/store/api-services';
import { GeneratedMeetingEffects } from './meeting-generated.effects';

@Injectable()
export class MeetingEffects extends GeneratedMeetingEffects {
  constructor(actions$: Actions, meetingApiService: MeetingApiService, store$: Store<AppState>) {
    super(actions$, meetingApiService, store$);
  }
}
