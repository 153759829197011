import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationSection, OrganizationSectionEntityState } from '@api/api-interfaces';
import { OrganizationSectionRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationSectionGeneratedActions = createActionGroup({
  source: 'Organization Section Generated',
  events: {
    'Get One Organization Section': props<{ idOrganizationSection: number; params?: any }>(),
    'Get Many Organization Sections': props<{ params: any }>(),
    'Add Many Actives Organization Sections': props<{ idOrganizationSections: number[] }>(),
    'Delete One Active Organization Section': props<{ idOrganizationSection: number }>(),
    'Clear Actives Organization Sections': emptyProps(),
    'Upsert One Organization Section': props<{
      organizationSection: Partial<OrganizationSection>;
      ids?: OrganizationSectionRelationsIds;
    }>(),
    'Upsert Many Organization Sections': props<{
      organizationSections: Partial<OrganizationSection>[];
      ids?: OrganizationSectionRelationsIds;
    }>(),
    'Upsert Many Organization Sections Success': props<{ organizationSections: OrganizationSectionEntityState[] }>(),
    'Delete One Organization Section': props<{ idOrganizationSection: number }>(),
    'Delete One Organization Section Success': props<{ idOrganizationSection: number }>(),
    'Normalize Many Organization Sections After Upsert': props<{
      organizationSections: OrganizationSectionEntityState[];
    }>(),
    'Organization Sections Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Sections': emptyProps(),
    'Add Organization Success': props<{ idOrganizationSection: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationSections: number[] }>(),
    'Add Section Success': props<{ idOrganizationSection: number; idSection: number }>(),
    'Delete Many Section Success': props<{ idSections: number[]; idOrganizationSections: number[] }>()
  }
});
