import { Action, createReducer } from '@ngrx/store';
import { organizationCaracteristicColumnReducersGeneratedFunctions } from './organization-caracteristic-column-generated.reducer';
import { OrganizationCaracteristicColumnState } from '@wip/store/states';

const organizationCaracteristicColumnReducersFunctions = [...organizationCaracteristicColumnReducersGeneratedFunctions];

const organizationCaracteristicColumnReducer = createReducer(
  OrganizationCaracteristicColumnState.initialState,
  ...organizationCaracteristicColumnReducersFunctions
);

export function reducer(state: OrganizationCaracteristicColumnState.IState | undefined, action: Action) {
  return organizationCaracteristicColumnReducer(state, action);
}
