import { OrganizationMilestoneFamilyState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationMilestoneFamilyReducersGeneratedFunctions } from './organization-milestone-family-generated.reducer';

const organizationMilestoneFamilyReducersFunctions = [...organizationMilestoneFamilyReducersGeneratedFunctions];

const organizationMilestoneFamilyReducer = createReducer(
  OrganizationMilestoneFamilyState.initialState,
  ...organizationMilestoneFamilyReducersFunctions
);

export function reducer(state: OrganizationMilestoneFamilyState.IState | undefined, action: Action) {
  return organizationMilestoneFamilyReducer(state, action);
}
