import { RepositoryService } from '@wip/services/repository';
import { RightProfil } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedRightProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getRightProfils(params?: any): Observable<RightProfil[]> {
    return this.repo.getData<RightProfil[]>('right-profil', params);
  }

  public getRightProfil(params: { idRightProfil: number; params?: any }): Observable<RightProfil> {
    return this.repo.getData<RightProfil>('right-profil/' + params.idRightProfil, params.params);
  }

  public addRightProfil(rightProfil: Partial<RightProfil>): Observable<RightProfil> {
    return this.repo.create<RightProfil>('right-profil', rightProfil);
  }

  public updateRightProfil(rightProfil: Partial<RightProfil>): Observable<RightProfil> {
    return this.repo.update('right-profil', rightProfil);
  }

  public deleteRightProfil(idRightProfil: number): Observable<number> {
    return this.repo.delete('right-profil/' + +idRightProfil);
  }
}
