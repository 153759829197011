import { RepositoryService } from '@wip/services/repository';
import { UserRight } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserRightApiService {
  constructor(protected repo: RepositoryService) {}

  public getUserRights(params?: any): Observable<UserRight[]> {
    return this.repo.getData<UserRight[]>('user-right', params);
  }

  public getUserRight(params: { idUserRight: number; params?: any }): Observable<UserRight> {
    return this.repo.getData<UserRight>('user-right/' + params.idUserRight, params.params);
  }

  public addUserRight(userRight: Partial<UserRight>): Observable<UserRight> {
    return this.repo.create<UserRight>('user-right', userRight);
  }

  public updateUserRight(userRight: Partial<UserRight>): Observable<UserRight> {
    return this.repo.update('user-right', userRight);
  }

  public deleteUserRight(idUserRight: number): Observable<number> {
    return this.repo.delete('user-right/' + +idUserRight);
  }
}
