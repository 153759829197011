import AgGridCellCommonFormatter from '@_app/utils/ag-grid/cell-formatter/common-cell-formatter';
import { ColDef, ColGroupDef, ValueFormatterParams } from '@ag-grid-community/core';
import { Injectable, OnInit } from '@angular/core';
import { CommunityCaracteristic, OrganizationCaracteristic } from '@api/api-interfaces';
import { CaracteristiqueTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommunityCaracteristicService, OrganizationUserService } from '@wip/store/services';
import { Subject, debounceTime, distinctUntilChanged, tap } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ProjectGridCaracteristicsService implements OnInit {
  constructor(
    private readonly communityCaracteristicService: CommunityCaracteristicService,
    private readonly organizationUserService: OrganizationUserService
  ) {}

  public valuesToUpdate: Partial<CommunityCaracteristic>[];
  public editMode: boolean = false;
  public listCaracteristics: OrganizationCaracteristic[];

  private valuesSubject = new Subject<any[]>();

  ngOnInit() {
    this.valuesSubject
      .pipe(
        untilDestroyed(this),
        debounceTime(200),
        distinctUntilChanged(),
        tap(values => {
          this.communityCaracteristicService.fillHandleCaracteristics(values);
          this.valuesToUpdate = [];
        })
      )
      .subscribe();

    this.organizationUserService.editMode$.pipe(untilDestroyed(this)).subscribe(val => (this.editMode = val));
  }

  public createCaracteristicsColumnDefsFormat(
    caracteristics: OrganizationCaracteristic[],
    idOrganization: number,
    idOrganizationFamily: number,
    openCaracs: boolean
  ): ColGroupDef {
    const caracteristicsForOrganization = caracteristics.filter(
      caracteristic =>
        caracteristic.idOrganization === idOrganization && caracteristic.idOrganizationFamily === idOrganizationFamily
    );

    const caracteristicsColumnsDefs: ColDef[] = this.getOpenAndCloseColDefCaracs(
      caracteristicsForOrganization,
      idOrganization,
      null
    );

    const caracteristicsColumnsGroupDefsDefault: ColGroupDef = {
      headerName: 'Caractéristiques',
      children: caracteristicsColumnsDefs,
      columnGroupShow: 'closed',
      openByDefault: openCaracs,
      groupId: 'caracs'
    };

    return caracteristicsColumnsGroupDefsDefault;
  }

  public getOpenAndCloseColDefCaracs(
    caracteristic: OrganizationCaracteristic[],
    idOrganization: number,
    handleChange: Subject<void>
  ) {
    return caracteristic.map((caracteristic: OrganizationCaracteristic): ColDef => {
      if (caracteristic.idOrganization === idOrganization) {
        return {
          headerName: caracteristic.libelle,
          enableRowGroup: true,
          field: caracteristic.idOrganizationCaracteristic.toString() + '-',
          columnGroupShow: caracteristic.shouldDisplayInDashboard ? undefined : 'open',
          width: 100,
          resizable: true,
          valueFormatter: (params: ValueFormatterParams): string => {
            return this.isNumber(caracteristic.type) && params.value
              ? AgGridCellCommonFormatter.formatNumberWithSpace(params.value)
              : params.value;
          },
          cellStyle: {
            textAlign: this.isNumber(caracteristic.type) ? 'right' : 'left',
            backgroundColor: this.editMode ? '#ceeaff' : ''
          },
          editable: this.editMode,
          aggFunc: this.isNumber(caracteristic.type) && !this.editMode ? 'sum' : null,
          cellEditorSelector: this.cellCaracteristicEditorSelector.bind(this),
          valueGetter: this.getValue,
          valueSetter: this.setValue,
          onCellValueChanged: params => {
            const newValue = params.newValue;
            if (!this.isNumber(caracteristic.type)) {
              this.valuesToUpdate.push({
                idCommunityCaracteristic:
                  params.data[caracteristic.idOrganizationCaracteristic.toString() + '-'].idCommunityCaracteristic,
                valeur: newValue
              });
            } else {
              this.valuesToUpdate.push({
                idCommunityCaracteristic:
                  params.data[caracteristic.idOrganizationCaracteristic.toString() + '-'].idCommunityCaracteristic,
                valeurInteger: newValue
              });
            }
            this.valuesSubject.next(this.valuesToUpdate);
            handleChange.next();
          }
        };
      }
    });
  }

  private getValue(params) {
    return params.data ? params.data[params.column.colId]?.value : null;
  }

  private setValue(params) {
    params.data[params.column.colId] = { ...params.data[params.column.colId], value: params.newValue };
    return true;
  }

  private cellCaracteristicEditorSelector(params: any) {
    const orgaCaracteristic = this.listCaracteristics.find(
      el => el.idOrganizationCaracteristic === +params.column.colId.split('-')[0]
    );
    if (orgaCaracteristic.type === CaracteristiqueTypeEnum.choix) {
      return {
        component: 'agRichSelectCellEditor',
        params: {
          values: orgaCaracteristic.valueEnum.split(';')
        },
        popup: true
      };
    }
  }

  public isNumber(type: CaracteristiqueTypeEnum) {
    return [
      CaracteristiqueTypeEnum.decimal,
      CaracteristiqueTypeEnum.integer,
      CaracteristiqueTypeEnum.formula,
      CaracteristiqueTypeEnum.criteria
    ].includes(type);
  }
}
