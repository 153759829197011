/* eslint-disable @nx/enforce-module-boundaries */
import { ActionCheckboxRendererComponent } from '@_app/ag-grid-templates/action-checkbox-renderer/action-checkbox-renderer.component';
import { MilestoneRendererComponent } from '@_app/ag-grid-templates/milestone-renderer/milestone-renderer.component';
import { NewActionFormContainerComponent } from '@_app/project/new-project-container/new-gantt/new-action-form-container/new-action-form-container.component';
import { ActionService } from '@_app/shared/elements/actions/action.service';
import { AgGridUtils } from '@_app/utils/ag-grid/cell-formatter/ag-grid.utils';
import AgGridCellCommonFormatter from '@_app/utils/ag-grid/cell-formatter/common-cell-formatter';
import { ColDef, GridOptions, Module, ValueFormatterParams, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Community, Element, OrganizationUser, ProjectElement } from '@api/api-interfaces';
import { ConstraintTypeEnum, DroitInterneEnum, ElementTypeElement } from '@enums';

@Component({
  selector: 'wip-project-milestones',
  templateUrl: './project-milestones.component.html',
  styleUrls: ['./project-milestones.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectMilestonesComponent implements OnInit, OnChanges {
  @Input() community: Community;
  @Input() canEdit: boolean;
  @Input() organizationUser: OrganizationUser;
  @Output() updateStatus = new EventEmitter<Partial<Element>>();
  @Output() updateElement = new EventEmitter<Partial<Element>>();

  public gridReady = false;
  public overlayNoRowsTemplate: string;
  public gridOptions: GridOptions;
  public components: { buttonsCellRenderer: () => void };
  public displayTrash: boolean;
  public displayOptions: boolean;
  public loaded: boolean;
  public modules: Module[] = AgGridUtils.getCommonModules();
  public displayInitial: boolean;
  public displayObjective: boolean;
  public gridApi: GridApi;

  constructor(
    private readonly actionService: ActionService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.gridOptions = this.getDefaultGridOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.community) {
      if (
        this.community.organizationFamily?.dateInitialEnable !== this.displayInitial ||
        this.community.organizationFamily?.dateObjectiveEnable !== this.displayObjective
      ) {
        this.gridApi?.setGridOption('columnDefs', this.createColumnDefs());
      }
      this.setRowData();
    }
    if (changes.organizationUser) {
      this.gridApi?.setGridOption('columnDefs', this.createColumnDefs());
    }
  }

  private getDefaultGridOptions(): GridOptions {
    return {
      ...AgGridUtils.getCommonGridOption(),
      defaultExcelExportParams: {
        fileName: this.community.nom + ' - Dates clés',
        sheetName: 'Dates clés'
      },
      columnDefs: this.createColumnDefs(), // Define columns
      context: {
        componentParent: this // allow us to access to the component from the grid
      },
      excelStyles: [
        {
          id: 'other-dates',
          dataType: 'DateTime',
          alignment: {
            horizontal: 'Center'
          },
          numberFormat: { format: 'dd/MM/yyyy' }
        }
      ],
      defaultColDef: {
        ...AgGridUtils.getCommonGridOption().defaultColDef,
        editable: false,
        lockPosition: true,
        filter: false,
        resizable: false,
        sortable: false
      },
      onCellValueChanged: this.updateValues.bind(this),
      domLayout: 'autoHeight',
      rowData: []
    };
  }

  public changeData(event) {
    const element = event.value;
    this.actionService.toggleTodoStatusAction(element);
  }

  public setRowData(): void {
    this.gridApi?.setGridOption('rowData', this.displayRow(this.community.projectElements));
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.setRowData();
  }

  private displayRow(projectElements: ProjectElement[]) {
    return projectElements
      ?.filter(
        pe =>
          pe?.element?.organizationMilestone?.libelle !== undefined && pe?.element?.organizationMilestone?.realDisplay
      )
      .map(projectElement => {
        return {
          idProjectElement: projectElement.idProjectElement,
          idElement: projectElement.idElement,
          ordre: projectElement.element?.organizationMilestone?.ordre,
          statut: projectElement.element?.statusAction,
          libelle: projectElement.element?.organizationMilestone?.libelle,
          dateReel: projectElement.element?.echeance
            ? new Date(projectElement.element.echeance).toISOString().split('T')[0]
            : null,
          dateInitial: projectElement.element?.dateInitial
            ? new Date(projectElement.element.dateInitial).toISOString().split('T')[0]
            : null,
          dateObjectif: projectElement.element?.dateObjectif
            ? new Date(projectElement.element.dateObjectif).toISOString().split('T')[0]
            : null,
          numberOfDocuments: projectElement.element.childrenElements?.filter(
            c => c.typeElement === ElementTypeElement.elementDocument
          ).length,
          enabledInitial: projectElement.element?.organizationMilestone?.initialDisplay,
          enabledObjective: projectElement.element?.organizationMilestone?.objectiveDisplay,
          computedDate:
            projectElement.constraintType?.toLowerCase() === ConstraintTypeEnum.MSO ||
            projectElement.constraintType?.toLowerCase() === ConstraintTypeEnum.MFO
        };
      })
      .sort((a, b) => a.ordre - b.ordre);
  }

  private createColumnDefs(): ColDef[] {
    this.displayInitial = this.community.organizationFamily?.dateInitialEnable;
    this.displayObjective = this.community.organizationFamily?.dateObjectiveEnable;
    const width =
      200 +
      (!this.community.organizationFamily?.dateObjectiveEnable as unknown as number) * 70 +
      (!this.community.organizationFamily?.dateInitialEnable as unknown as number) * 70;
    return [
      {
        headerName: 'Statut',
        field: 'statut',
        colId: 'statut',
        width: 60,
        cellStyle: { 'text-align': 'center' },
        cellRenderer: ActionCheckboxRendererComponent
      },
      {
        headerName: 'Jalon',
        field: 'libelle',
        colId: 'libelle',
        cellRenderer: MilestoneRendererComponent,
        onCellDoubleClicked: params => {
          this.openModal(params);
        },
        width
      },
      {
        headerName: this.community.organizationFamily?.dateInitialNom
          ? this.community.organizationFamily?.dateInitialNom
          : 'Initiale',
        colId: 'dateInitial',
        field: 'dateInitial',
        hide: !this.community.organizationFamily?.dateInitialEnable,
        cellClass: 'other-dates',
        editable: params => {
          return (
            params.node.data.enabledInitial &&
            this.community.communityRights?.crud.includes(DroitInterneEnum.dateInitialUpdate)
          );
        },
        cellEditor: AgGridUtils.NativeDatePickerEditor,
        cellStyle: params => {
          return { 'text-align': 'center', 'background-color': params.data.enabledInitial ? 'initial' : '#e7e7e7' };
        },
        valueFormatter: (params: ValueFormatterParams<ProjectElement | any, Date>) => {
          if (!params.data.enabledInitial) {
            return '';
          }
          return AgGridCellCommonFormatter.formatDateOrNull(params.value);
        },
        width: 70
      },
      {
        headerName: this.community.organizationFamily?.dateReelNom
          ? this.community.organizationFamily?.dateReelNom
          : 'Réelle',
        field: 'dateReel',
        colId: 'dateReel',
        editable: false,
        cellClass: 'other-dates',
        cellStyle: params => {
          if (params.node.data.computedDate) {
            return { 'font-weight': 'bold' };
          }
          return { 'font-style': 'italic', color: 'grey' };
        },
        cellEditor: AgGridUtils.NativeDatePickerEditor,
        valueFormatter: (params: ValueFormatterParams<ProjectElement, Date>) =>
          AgGridCellCommonFormatter.formatDateOrNull(params.value),
        width: 70
      },
      {
        headerName: this.community.organizationFamily?.dateObjectiveNom
          ? this.community.organizationFamily?.dateObjectiveNom
          : 'Objectif',
        hide: !this.community.organizationFamily?.dateObjectiveEnable,
        field: 'dateObjectif',
        colId: 'dateObjectif',
        cellClass: 'other-dates',
        editable: params => {
          return (
            params.node.data.enabledObjective &&
            this.community.communityRights?.crud.includes(DroitInterneEnum.dateObjectifUpdate)
          );
        },
        cellEditor: AgGridUtils.NativeDatePickerEditor,
        cellStyle: params => {
          return { 'text-align': 'center', 'background-color': params.data.enabledObjective ? 'initial' : '#e7e7e7' };
        },
        valueFormatter: (params: ValueFormatterParams<ProjectElement | any, Date>) => {
          if (!params.data.enabledObjective) {
            return '';
          }
          return AgGridCellCommonFormatter.formatDateOrNull(params.value);
        },
        width: 70
      }
    ];
  }

  private updateValues(params) {
    this.updateElement.emit({
      idElement: params.node.data.idElement,
      [params.column.colId]: params.value
    });
  }

  private openModal(params) {
    const projectElement = this.community.projectElements.find(
      pe => pe.idProjectElement === params.node.data.idProjectElement
    );

    this.dialog.open(NewActionFormContainerComponent, {
      data: {
        fromSynthesisComponent: true,
        type: projectElement.type,
        idProjectElement: projectElement.idProjectElement,
        idElement: projectElement.idElement,
        community: this.community,
        rightNeeded: DroitInterneEnum.elementUpdate,
        constraintType: projectElement.constraintType,
        isEndDateComputed: projectElement.isEndDateComputed,
        isViewer: !this.canEdit,
        businessDay: this.community.organizationFamily.businessDay,
        organizationUser: this.organizationUser
      },
      maxWidth: '100vw',
      maxHeight: '97vh',
      // height: '700px',
      width: '800px',
      panelClass: ['custom-dialog-fullheight', 'custom-dialog-fullscreen-xs'],
      disableClose: true,
      autoFocus: false
    });
  }

  public setNextStatus(element: Partial<Element>) {
    this.updateStatus.emit(element);
  }
}
