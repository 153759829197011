import { RepositoryService } from '@wip/services/repository';
import { ElementLibrary } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedElementLibraryApiService {
  constructor(protected repo: RepositoryService) {}

  public getElementLibraries(params?: any): Observable<ElementLibrary[]> {
    return this.repo.getData<ElementLibrary[]>('element-library', params);
  }

  public getElementLibrary(params: { idElementLibrary: number; params?: any }): Observable<ElementLibrary> {
    return this.repo.getData<ElementLibrary>('element-library/' + params.idElementLibrary, params.params);
  }

  public addElementLibrary(elementLibrary: Partial<ElementLibrary>): Observable<ElementLibrary> {
    return this.repo.create<ElementLibrary>('element-library', elementLibrary);
  }

  public updateElementLibrary(elementLibrary: Partial<ElementLibrary>): Observable<ElementLibrary> {
    return this.repo.update('element-library', elementLibrary);
  }

  public deleteElementLibrary(idElementLibrary: number): Observable<number> {
    return this.repo.delete('element-library/' + +idElementLibrary);
  }
}
