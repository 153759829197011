/* eslint-disable @typescript-eslint/no-namespace */

import { ICellRendererParams, ValueFormatterParams } from '@ag-grid-community/core';
import { User } from '@api/api-interfaces';
import dayjs from 'dayjs';

namespace AgGridCellCommonFormatter {
  export function formatNumberWithSpace(nb: number): string {
    return nb.toLocaleString(undefined, {
      maximumFractionDigits: 2
    });

    const [integerPart, fractionnalPart]: string[] = nb.toString().split(/[,|.]/);
    const integerPartSpace: string = integerPart
      .split('')
      .reverse()
      .reduce(
        (accumulator: string, currentValue: string, index: number) =>
          currentValue + (index % 3 === 0 && index !== 0 ? ' ' : '') + accumulator,
        ''
      );

    if (fractionnalPart) {
      return integerPartSpace + ',' + fractionnalPart;
    }
    return integerPartSpace;
  }

  export function cellRendererPillUser(user: Partial<User>): string {
    if (!user) {
      return '';
    }
    const pseudo = user.pseudo?.length ? user.pseudo : (user.prenom.charAt(0) + user.nom.charAt(0)).toUpperCase();
    if (pseudo) {
      return (
        '<span class="Pill-container">' +
        '<span style="background-color:' +
        user.couleur +
        '" class="Pill ' +
        user.couleur +
        '">' +
        pseudo +
        '</span></span>'
      );
    }
  }

  export function formatToEuro(nb: number, kEuro: boolean = false, addSuffix = true): string {
    if (kEuro) {
      return formatNumberWithSpace(Math.round(nb / 1000)) + (addSuffix ? ' K€' : '');
    }
    return formatNumberWithSpace(nb) + (addSuffix ? '€' : '');
  }

  export function formatPhoneNumber(phoneNumber: any): string {
    const phoneNumberWithoutSpace = phoneNumber.replace(/[\s\.\-]/g, '');
    if (phoneNumberWithoutSpace) {
      if (phoneNumberWithoutSpace.length === 10) {
        return `${phoneNumberWithoutSpace.substr(0, 2)} ${phoneNumberWithoutSpace.substr(
          2,
          2
        )} ${phoneNumberWithoutSpace.substr(4, 2)} ${phoneNumberWithoutSpace.substr(
          6,
          2
        )} ${phoneNumberWithoutSpace.substr(8, 2)}`;
      }
      return phoneNumberWithoutSpace;
    }
    return '';
  }

  export function nullableNumberFormatter(params: ValueFormatterParams): string {
    return params.value ?? 0;
  }

  export function formatDate(date: Date): string {
    return dayjs(date).isValid() ? dayjs(date).format('DD/MM/YY') : null;
  }
  export function formatDateOrNull(date: Date): string {
    return dayjs(date).isValid() ? dayjs(date).format('DD/MM/YY') : '-';
  }
  export function formatDateFullOrNull(date: Date): string {
    return dayjs(date).isValid() ? dayjs(date).format('DD/MM/YYYY') : '-';
  }

  export function formatDateFullYear(date: Date): string {
    return dayjs(date).format('DD/MM/YYYY');
  }

  export function formatDateTime(date: Date): string {
    return dayjs(date).format('DD/MM/YY HH:mm');
  }

  export function formatNumberWithBase(value: number, base: number): string {
    const left = value || value === 0 ? formatNumberWithSpace(value) : '';
    const right = base ? formatNumberWithSpace(base) : 'base';
    return left + ' / ' + right;
  }

  export function valueFormatterPhoneNumber(params: ValueFormatterParams): string {
    return params.value ? formatPhoneNumber(params.value) : '';
  }

  export function valueFormatterEuro(params: ValueFormatterParams): string {
    return params.value !== null && params.value !== undefined && params.value !== '' ? formatToEuro(params.value) : '';
  }

  export function valueFormatterKiloEuro(params: ValueFormatterParams): string {
    if (params.data) {
      return params.value ? formatToEuro(params.value, true) : '';
    } else {
      return;
    }
  }

  export function valueFormatterKiloEuroWithAggregate(params: ValueFormatterParams): string {
    return params.value ? formatToEuro(params.value, true) : '';
  }

  export function valueFormatterKiloEuroWithoutSuffix(params: ValueFormatterParams): string {
    return params.value ? formatToEuro(params.value, true, false) : '';
  }

  export function valueFormatterDate(params: ValueFormatterParams, blankChar: string = '-'): string {
    if (params.data) {
      return params.value ? formatDate(params.value) : blankChar;
    } else {
      return null;
    }
  }

  export function valueFormatterNumber1Decimal(params: ValueFormatterParams): string {
    if (params.data) {
      return params.value ? params.value.toFixed(1) : '-';
    } else {
      return;
    }
  }

  export function valueFormatterNumberZeroToTiret(params: ValueFormatterParams): string {
    if (params.data) {
      return params.value ? params.value : '-';
    } else {
      return;
    }
  }

  export function valueFormatterNumber2Decimal(params: ValueFormatterParams): string {
    if (params.data) {
      return params.value ? params.value.toFixed(2) : '-';
    } else {
      return;
    }
  }

  export function valueFormatterPercent(params: ValueFormatterParams): string {
    if (params.data) {
      params.value = +params.value;
      return params.value ? (params.value.toFixed(2) * 100).toFixed(0) + '%' : '-';
    } else {
      return;
    }
  }

  export function valueFormatterPercentNoModification(params: ValueFormatterParams, fixed: number = 2): string {
    if (params.data) {
      params.value = +params.value;
      return params.value ? params.value.toFixed(fixed) + '%' : '-';
    } else {
      return;
    }
  }

  export function valueFormatterDateTime(params: ValueFormatterParams): string {
    if (params.data) {
      return params.value ? formatDateTime(params.value) : '-';
    } else {
      return;
    }
  }

  export function valueFormatterDateTimeFullYear(params: ValueFormatterParams): string {
    if (params.data) {
      return params.value ? formatDateFullYear(params.value) : '-';
    } else {
      return;
    }
  }

  export function valueFormatterPlafond(params: ValueFormatterParams): string {
    if (params?.value <= 0) {
      return '-';
    } else {
      return valueFormatterEuro(params);
    }
  }

  export function daysFromNow(date: Date): string {
    if (date) {
      return dayjs(new Date()).diff(dayjs(date), 'day') + 'j';
    } else {
      return '';
    }
  }

  export function valueFormatterNumberWithSpace(params: ValueFormatterParams): string {
    return params.value ? formatNumberWithSpace(params.value) : '';
  }

  export function valueFormatterBoolean(params: ValueFormatterParams): string {
    return params.value ? 'Oui' : 'Non';
  }

  export function valueFormatterNumberTypologie(params: ValueFormatterParams): string {
    return params.value ? 'T' + params.value : '';
  }

  export const pillColor = [
    'Pill--colorRed',
    'Pill--colorOrange',
    'Pill--colorYellowOrange',
    'Pill--colorYellow',
    'Pill--colorYellowGreen',
    'Pill--colorGreen',
    'Pill--colorBlueGreen',
    'Pill--colorAqua',
    'Pill--colorBlue',
    'Pill--colorIndigo',
    'Pill--colorPurple',
    'Pill--colorMagenta',
    'Pill--colorHotPink',
    'Pill--colorPink',
    'Pill--colorCoolGray'
  ];

  export function positiveHashString(str): number {
    str = str || '';
    let hash = 0;
    let i;
    let chr;
    let len;
    if (str.length === 0) {
      return hash;
    }
    for (i = 0, len = str.length; i < len; i++) {
      chr = str.charCodeAt(i);
      hash = hash * 31 + chr + chr;
      hash = hash || 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }

  export function getColorForText(str: string): string {
    return pillColor[positiveHashString(str) % pillColor.length];
  }

  export function changeRowStyleIfResponsible(param): object {
    if (param.data === undefined) {
      return;
    }
    if (
      param.data?.responsables?.length &&
      !param.data?.responsables?.find(it => this.userService.currentUser.idUser === it.idUser)
    ) {
      return { background: '#F1F1F1 !important', color: 'darkgrey' };
    }
  }
}

export function cellRenderedPillMoreThanOne(params: ICellRendererParams): string {
  if (params.value && params.value >= 1) {
    const color = 'Pill--colorGreen';
    return (
      '<div class="Pill-container"><div class="Pill ' +
      color +
      '" style="margin: auto;">' +
      params.value +
      '</div><div>'
    );
  }
}

export function cellRendererPillDiagnostic(params: ICellRendererParams): string {
  if (params.value && params.value >= 1) {
    let color = 'Pill--color';
    if (params.colDef.headerName === 'A faire') {
      color += 'Orange';
    } else if (params.colDef.headerName === 'Comm.') {
      color += 'Aqua';
    } else if (params.colDef.headerName === 'Recep.' && params.value === params.data?.stratalotTodosTotal) {
      color += 'Green';
    } else {
      color += 'YellowGreen';
    }

    return (
      '<div class="Pill-container"><div class="Pill ' +
      color +
      '" style="margin: auto;">' +
      params.value +
      '</div><div>'
    );
  }
}

export function cellRendererPillResidenceBoard(params: ICellRendererParams): string {
  if (params.value && params.value >= 1 && params.colDef) {
    let color = 'Pill--colorCoolGray';
    if (params.colDef.headerName === 'A qualifier') {
      color = 'Pill--colorOrange';
    } else if (params.colDef.headerName === 'Interessé') {
      color = 'Pill--colorGreen';
    }

    return (
      '<div class="Pill-container"><div class="Pill ' +
      color +
      '" style="margin: auto;">' +
      params.value +
      '</div><div>'
    );
  }
}

export function cellRendererPillEndettement(params: ICellRendererParams): string {
  if (params.value > 0.35) {
    return (
      '<div class="Pill-container"><div class="Pill Pill--colorRed" style="margin: auto;">' +
      (params.value.toFixed(2) * 100).toFixed(0) +
      '%</div><div>'
    );
  }
  return params.value ? (params.value.toFixed(2) * 100).toFixed(0) + '%' : '-';
}

export function cellRendererPillPrixVente(params: ICellRendererParams): string {
  const value: number = +params.value;
  if (params.data.valeurOffreAcquereur !== params.data.leadPriceValue) {
    return (
      '<div class="Pill-container"><div class="Pill Pill--colorRed" style="margin: auto;">' +
      value.toLocaleString() +
      '€</div><div>'
    );
  }
  return params.value ? value.toLocaleString() + '€' : '-';
}

export function cellRendererPillPriorite(params: ICellRendererParams): string {
  let color = '';
  switch (params.value) {
    case 'P1.1':
      color = 'Pill--colorPurple';
      break;
    case 'P1.2':
      color = 'Pill--colorIndigo';
      break;
    case 'P2':
      color = 'Pill--colorBlue';
      break;
    case 'P3':
      color = 'Pill--colorAqua';
      break;
  }
  if (params.value) {
    return (
      '<div class="Pill-container"><div class="Pill ' +
      color +
      '" style="margin: auto;">' +
      params.value +
      '</div><div>'
    );
  }
}

export function sortNumberComparator(valueA, valueB): 0 | 1 | -1 {
  if (+valueA === +valueB) {
    return 0;
  }
  return +valueA > +valueB ? 1 : -1;
}

export default AgGridCellCommonFormatter;
