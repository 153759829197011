import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationSectionEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationSectionEntityState>;

export const adapter = createEntityAdapter<OrganizationSectionEntityState>({
  selectId: o => o.idOrganizationSection
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationSectionFeatureKey = 'organizationSection';
