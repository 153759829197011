import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { ElementRelationsIds } from '@wip/store/ids-interfaces';

export const ElementGeneratedActions = createActionGroup({
  source: 'Element Generated',
  events: {
    'Get One Element': props<{ idElement: number; params?: any }>(),
    'Get Many Elements': props<{ params: any }>(),
    'Add Many Actives Elements': props<{ idElements: number[] }>(),
    'Delete One Active Element': props<{ idElement: number }>(),
    'Clear Actives Elements': emptyProps(),
    'Upsert One Element': props<{ element: Partial<Element>; ids?: ElementRelationsIds }>(),
    'Upsert Many Elements': props<{ elements: Partial<Element>[]; ids?: ElementRelationsIds }>(),
    'Upsert Many Elements Success': props<{ elements: ElementEntityState[] }>(),
    'Delete One Element': props<{ idElement: number }>(),
    'Delete One Element Success': props<{ idElement: number }>(),
    'Normalize Many Elements After Upsert': props<{ elements: ElementEntityState[] }>(),
    'Elements Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Elements': emptyProps(),
    'Add Many Todo Element Success': props<{ idElement: number; idTodoElements: number[] }>(),
    'Delete Many Todo Element Success': props<{ idTodoElements: number[]; idElements: number[] }>(),
    'Add Many Element Cash Success': props<{ idElement: number; idElementCashs: number[] }>(),
    'Delete Many Element Cash Success': props<{ idElementCashs: number[]; idElements: number[] }>(),
    'Add Many Meeting Element Success': props<{ idElement: number; idMeetingElements: number[] }>(),
    'Delete Many Meeting Element Success': props<{ idMeetingElements: number[]; idElements: number[] }>(),
    'Add Many Timeline Element Success': props<{ idElement: number; idTimelineElements: number[] }>(),
    'Delete Many Timeline Element Success': props<{ idTimelineElements: number[]; idElements: number[] }>(),
    'Add Many Folder Element Success': props<{ idElement: number; idFolderElements: number[] }>(),
    'Delete Many Folder Element Success': props<{ idFolderElements: number[]; idElements: number[] }>(),
    'Add Many Project Element Success': props<{ idElement: number; idProjectElements: number[] }>(),
    'Delete Many Project Element Success': props<{ idProjectElements: number[]; idElements: number[] }>(),
    'Add Many Organization Element Success': props<{ idElement: number; idOrganizationElements: number[] }>(),
    'Delete Many Organization Element Success': props<{ idOrganizationElements: number[]; idElements: number[] }>(),
    'Add Many Children Element Success': props<{ idElement: number; idChildrenElements: number[] }>(),
    'Delete Many Children Element Success': props<{ idChildrenElements: number[]; idElements: number[] }>(),
    'Add Many Element Library Success': props<{ idElement: number; idElementLibraries: number[] }>(),
    'Delete Many Element Library Success': props<{ idElementLibraries: number[]; idElements: number[] }>(),
    'Add Many Todo Success': props<{ idElement: number; idTodos: number[] }>(),
    'Delete Many Todo Success': props<{ idTodos: number[]; idElements: number[] }>(),
    'Add Organization Milestone Success': props<{ idElement: number; idOrganizationMilestone: number }>(),
    'Delete Many Organization Milestone Success': props<{ idOrganizationMilestones: number[]; idElements: number[] }>()
  }
});
