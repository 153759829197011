import { ActionFormDTO } from '@dto';
import { createAction, props } from '@ngrx/store';
import { Element, ReorderBasicInterface } from '@api/api-interfaces';

export * from './element-generated.actions';

export const validateActionFormElement = createAction('[Element] Validate Action Form', props<ActionFormDTO>());

export const getGridDashboardDatas = createAction(
  '[Community] Get Grid Data',
  props<{ idOrganization: number; idOrganizationMilestoneFamily: number }>()
);

export const getPersonalElements = createAction('[Community] Get Personal Elements', props<{ params: any }>());

export const updateInitial = createAction('[Element] Update Date Initial', props<{ element: Partial<Element> }>());
export const updateObjectif = createAction('[Element] Update Date Objectif', props<{ element: Partial<Element> }>());
export const reorder = createAction('[Element] Reorder', props<{ params: ReorderBasicInterface }>());
