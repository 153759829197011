import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'wip-action-form-title-section',
  templateUrl: './action-form-title-section.component.html',
  styleUrls: ['./action-form-title-section.component.scss']
})
export class ActionFormTitleSectionComponent {
  @Input() actionForm: FormGroup;

  constructor() {}
}
