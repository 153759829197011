import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationStep, OrganizationStepEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationStepState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationStepRelations: string[] = ['communities', 'organizations', 'organizationFamilys'];

export const { selectEntities, selectAll } = OrganizationStepState.adapter.getSelectors();

export const selectOrganizationStepState = createFeatureSelector<OrganizationStepState.IState>(
  OrganizationStepState.organizationStepFeatureKey
);

export const selectIsLoadedOrganizationStep = createSelector(
  selectOrganizationStepState,
  (state: OrganizationStepState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationStep = createSelector(
  selectOrganizationStepState,
  (state: OrganizationStepState.IState) => state.isLoading
);

export const selectIsReadyOrganizationStep = createSelector(
  selectOrganizationStepState,
  (state: OrganizationStepState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationStep = createSelector(
  selectOrganizationStepState,
  (state: OrganizationStepState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationStepsEntities = createSelector(selectOrganizationStepState, selectEntities);

export const selectOrganizationStepsArray = createSelector(selectOrganizationStepState, selectAll);

export const selectIdOrganizationStepsActive = createSelector(
  selectOrganizationStepState,
  (state: OrganizationStepState.IState) => state.actives
);

const organizationStepsInObject = (organizationSteps: Dictionary<OrganizationStepEntityState>) => ({
  organizationSteps
});

const selectOrganizationStepsEntitiesDictionary = createSelector(
  selectOrganizationStepsEntities,
  organizationStepsInObject
);

const selectAllOrganizationStepsObject = createSelector(selectOrganizationStepsEntities, organizationSteps => {
  return hydrateAll({ organizationSteps });
});

const selectOneOrganizationStepDictionary = (idOrganizationStep: number) =>
  createSelector(selectOrganizationStepsEntities, organizationSteps => ({
    organizationSteps: { [idOrganizationStep]: organizationSteps[idOrganizationStep] }
  }));

const selectOneOrganizationStepDictionaryWithoutChild = (idOrganizationStep: number) =>
  createSelector(selectOrganizationStepsEntities, organizationSteps => ({
    organizationStep: organizationSteps[idOrganizationStep]
  }));

const selectActiveOrganizationStepsEntities = createSelector(
  selectIdOrganizationStepsActive,
  selectOrganizationStepsEntities,
  (actives: number[], organizationSteps: Dictionary<OrganizationStepEntityState>) =>
    getOrganizationStepsFromActives(actives, organizationSteps)
);

function getOrganizationStepsFromActives(
  actives: number[],
  organizationSteps: Dictionary<OrganizationStepEntityState>
): Dictionary<OrganizationStepEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationSteps[idActive]) {
      acc[idActive] = organizationSteps[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationStepEntityState>);
}

const selectAllOrganizationStepsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationSteps(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationStep>(
      schema,
      selectAllOrganizationStepsSelectors,
      selectOrganizationStepsEntitiesDictionary,
      getRelationSelectors,
      organizationStepRelations,
      hydrateAll,
      'organizationStep'
    );
  } else {
    return selectAllOrganizationStepsObject;
  }
}

export function selectAllOrganizationStepsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationSteps'
): Selector {
  return createSelector(selectAllOrganizationSteps(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationStepEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationSteps.length; i++) {
      res[customKey][result.organizationSteps[i].idOrganizationStep] = result.organizationSteps[i];
    }
    return res;
  });
}

export function selectOneOrganizationStep(schema: SelectSchema = {}, idOrganizationStep: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationStepDictionary(idOrganizationStep)];
    selectors.push(...getRelationSelectors(schema, organizationStepRelations, 'organizationStep'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationStepDictionaryWithoutChild(idOrganizationStep);
  }
}

export function selectActiveOrganizationSteps(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationStepsEntities, organizationSteps => ({
      organizationSteps
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationStepRelations, 'organizationStep'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationSteps: Dictionary<OrganizationStepEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationSteps: (OrganizationStep | null)[] } {
  const { organizationSteps, organizations, organizationFamilys, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationSteps: Object.keys(organizationSteps).map(idOrganizationStep =>
      hydrate(
        organizationSteps[idOrganizationStep] as OrganizationStepEntityState,
        organizations,
        organizationFamilys,
        communities
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationStep: OrganizationStepEntityState | null } {
  const { organizationSteps, organizations, organizationFamilys, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationStep = Object.values(organizationSteps)[0];
  return {
    organizationStep: hydrate(
      organizationStep as OrganizationStepEntityState,
      organizations,
      organizationFamilys,
      communities
    )
  };
}

function hydrate(
  organizationStep: OrganizationStepEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>
): OrganizationStep | null {
  if (!organizationStep) {
    return null;
  }

  const organizationStepHydrated: OrganizationStepEntityState = { ...organizationStep };
  if (organizationEntities) {
    organizationStepHydrated.organization = organizationEntities[
      organizationStep.organization as number
    ] as Organization;
  } else {
    delete organizationStepHydrated.organization;
  }
  if (organizationFamilyEntities) {
    organizationStepHydrated.organizationFamily = organizationFamilyEntities[
      organizationStep.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete organizationStepHydrated.organizationFamily;
  }

  if (communityEntities) {
    organizationStepHydrated.communities = ((organizationStepHydrated.communities as number[]) || []).map(
      id => communityEntities[id]
    ) as Community[];
  } else {
    delete organizationStepHydrated.communities;
  }

  return organizationStepHydrated as OrganizationStep;
}
