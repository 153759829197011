import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { User } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { UserRelationsIds } from '@wip/store/ids-interfaces';
import { UserGeneratedActions } from '@wip/store/actions';
import { UserSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedUserService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(UserSelectors.selectIsLoadedUser));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(UserSelectors.selectIsLoadingUser));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [UserSelectors.selectIsReadyAndLoadedUser as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllUsers(schema: SelectSchema = {}): Observable<User[]> {
    return this.store$.pipe(select(UserSelectors.selectAllUsers(schema))).pipe(
      switchMap(({ users }: { users: User[] }) => {
        return this.getReady(schema).pipe(mapTo(users));
      })
    );
  }

  public selectOneUser(idUser: number, schema: SelectSchema = {}): Observable<User> {
    return this.store$.pipe(select(UserSelectors.selectOneUser(schema, idUser))).pipe(
      switchMap(({ user }: { user: User }) => {
        return this.getReady(schema).pipe(mapTo(user));
      })
    );
  }

  public selectAllActiveUsers(schema: SelectSchema = {}): Observable<User[]> {
    return this.store$.pipe(select(UserSelectors.selectActiveUsers(schema))).pipe(
      switchMap(({ users }: { users: User[] }) => {
        return this.getReady(schema).pipe(mapTo(users));
      })
    );
  }

  public selectIdUsersActive(): Observable<number[]> {
    return this.store$.pipe(select(UserSelectors.selectIdUsersActive)).pipe(
      switchMap((idUsers: number[]) => {
        return this.getReady().pipe(mapTo(idUsers));
      })
    );
  }

  public getOneUser(idUser: number, params: any = {}, getResult?: boolean): void | Observable<User> {
    this.store$.dispatch(UserGeneratedActions.getOneUser({ idUser, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserGeneratedActions.normalizeManyUsersAfterUpsert,
        UserGeneratedActions.usersFailure,
        true
      );
    }
  }

  public getManyUsers(params: any = {}, getResult?: boolean): void | Observable<User[]> {
    this.store$.dispatch(UserGeneratedActions.getManyUsers({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserGeneratedActions.normalizeManyUsersAfterUpsert,
        UserGeneratedActions.usersFailure
      );
    }
  }

  public upsertOneUser(user: Partial<User>, ids: UserRelationsIds = {}, getResult?: boolean): void | Observable<User> {
    this.store$.dispatch(UserGeneratedActions.upsertOneUser({ user, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserGeneratedActions.normalizeManyUsersAfterUpsert,
        UserGeneratedActions.usersFailure,
        true
      );
    }
  }

  public deleteOneUser(idUser: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(UserGeneratedActions.deleteOneUser({ idUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserGeneratedActions.deleteOneUserSuccess,
        UserGeneratedActions.usersFailure
      );
    }
  }

  public setActiveUsers(idUsers: number[]): void {
    this.store$.dispatch(UserGeneratedActions.clearActivesUsers());
    this.store$.dispatch(UserGeneratedActions.addManyActivesUsers({ idUsers }));
  }
}
