import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ElementLibrary, ElementLibraryEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { CommunityUserProfil, CommunityUserProfilEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { ElementLibraryState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const elementLibraryRelations: string[] = ['elements', 'users', 'communityUserProfils'];

export const { selectEntities, selectAll } = ElementLibraryState.adapter.getSelectors();

export const selectElementLibraryState = createFeatureSelector<ElementLibraryState.IState>(
  ElementLibraryState.elementLibraryFeatureKey
);

export const selectIsLoadedElementLibrary = createSelector(
  selectElementLibraryState,
  (state: ElementLibraryState.IState) => state.isLoaded
);

export const selectIsLoadingElementLibrary = createSelector(
  selectElementLibraryState,
  (state: ElementLibraryState.IState) => state.isLoading
);

export const selectIsReadyElementLibrary = createSelector(
  selectElementLibraryState,
  (state: ElementLibraryState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedElementLibrary = createSelector(
  selectElementLibraryState,
  (state: ElementLibraryState.IState) => state.isLoaded && !state.isLoading
);

export const selectElementLibrariesEntities = createSelector(selectElementLibraryState, selectEntities);

export const selectElementLibrariesArray = createSelector(selectElementLibraryState, selectAll);

export const selectIdElementLibrariesActive = createSelector(
  selectElementLibraryState,
  (state: ElementLibraryState.IState) => state.actives
);

const elementLibrariesInObject = (elementLibraries: Dictionary<ElementLibraryEntityState>) => ({ elementLibraries });

const selectElementLibrariesEntitiesDictionary = createSelector(
  selectElementLibrariesEntities,
  elementLibrariesInObject
);

const selectAllElementLibrariesObject = createSelector(selectElementLibrariesEntities, elementLibraries => {
  return hydrateAll({ elementLibraries });
});

const selectOneElementLibraryDictionary = (idElementLibrary: number) =>
  createSelector(selectElementLibrariesEntities, elementLibraries => ({
    elementLibraries: { [idElementLibrary]: elementLibraries[idElementLibrary] }
  }));

const selectOneElementLibraryDictionaryWithoutChild = (idElementLibrary: number) =>
  createSelector(selectElementLibrariesEntities, elementLibraries => ({
    elementLibrary: elementLibraries[idElementLibrary]
  }));

const selectActiveElementLibrariesEntities = createSelector(
  selectIdElementLibrariesActive,
  selectElementLibrariesEntities,
  (actives: number[], elementLibraries: Dictionary<ElementLibraryEntityState>) =>
    getElementLibrariesFromActives(actives, elementLibraries)
);

function getElementLibrariesFromActives(
  actives: number[],
  elementLibraries: Dictionary<ElementLibraryEntityState>
): Dictionary<ElementLibraryEntityState> {
  return actives.reduce((acc, idActive) => {
    if (elementLibraries[idActive]) {
      acc[idActive] = elementLibraries[idActive];
    }
    return acc;
  }, {} as Dictionary<ElementLibraryEntityState>);
}

const selectAllElementLibrariesSelectors: Dictionary<Selector> = {};
export function selectAllElementLibraries(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ElementLibrary>(
      schema,
      selectAllElementLibrariesSelectors,
      selectElementLibrariesEntitiesDictionary,
      getRelationSelectors,
      elementLibraryRelations,
      hydrateAll,
      'elementLibrary'
    );
  } else {
    return selectAllElementLibrariesObject;
  }
}

export function selectAllElementLibrariesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'elementLibraries'
): Selector {
  return createSelector(selectAllElementLibraries(schema), result => {
    const res = { [customKey]: {} as Dictionary<ElementLibraryEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.elementLibraries.length; i++) {
      res[customKey][result.elementLibraries[i].idElementLibrary] = result.elementLibraries[i];
    }
    return res;
  });
}

export function selectOneElementLibrary(schema: SelectSchema = {}, idElementLibrary: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneElementLibraryDictionary(idElementLibrary)];
    selectors.push(...getRelationSelectors(schema, elementLibraryRelations, 'elementLibrary'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneElementLibraryDictionaryWithoutChild(idElementLibrary);
  }
}

export function selectActiveElementLibraries(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveElementLibrariesEntities, elementLibraries => ({
      elementLibraries
    }))
  ];
  selectors.push(...getRelationSelectors(schema, elementLibraryRelations, 'elementLibrary'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  elementLibraries: Dictionary<ElementLibraryEntityState>;
  elements?: Dictionary<ElementEntityState>;
  users?: Dictionary<UserEntityState>;
  communityUserProfils?: Dictionary<CommunityUserProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { elementLibraries: (ElementLibrary | null)[] } {
  const { elementLibraries, elements, users, communityUserProfils } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    elementLibraries: Object.keys(elementLibraries).map(idElementLibrary =>
      hydrate(elementLibraries[idElementLibrary] as ElementLibraryEntityState, elements, users, communityUserProfils)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { elementLibrary: ElementLibraryEntityState | null } {
  const { elementLibraries, elements, users, communityUserProfils } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const elementLibrary = Object.values(elementLibraries)[0];
  return {
    elementLibrary: hydrate(elementLibrary as ElementLibraryEntityState, elements, users, communityUserProfils)
  };
}

function hydrate(
  elementLibrary: ElementLibraryEntityState,
  elementEntities?: Dictionary<ElementEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  communityUserProfilEntities?: Dictionary<CommunityUserProfilEntityState>
): ElementLibrary | null {
  if (!elementLibrary) {
    return null;
  }

  const elementLibraryHydrated: ElementLibraryEntityState = { ...elementLibrary };
  if (elementEntities) {
    elementLibraryHydrated.element = elementEntities[elementLibrary.element as number] as Element;
  } else {
    delete elementLibraryHydrated.element;
  }
  if (userEntities) {
    elementLibraryHydrated.user = userEntities[elementLibrary.user as number] as User;
  } else {
    delete elementLibraryHydrated.user;
  }
  if (communityUserProfilEntities) {
    elementLibraryHydrated.communityUserProfil = communityUserProfilEntities[
      elementLibrary.communityUserProfil as number
    ] as CommunityUserProfil;
  } else {
    delete elementLibraryHydrated.communityUserProfil;
  }

  return elementLibraryHydrated as ElementLibrary;
}
