import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { TodoGeneratedActions } from '@wip/store/actions';
import { TodoState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { TodoEntityState } from '@api/api-interfaces';

export const todoReducersGeneratedFunctions: ReducerTypes<TodoState.IState, readonly ActionCreator[]>[] = [
  on(TodoGeneratedActions.getOneTodo, (state: TodoState.IState) => setLoadingsState(state, true)),
  on(TodoGeneratedActions.getManyTodos, (state: TodoState.IState) => setLoadingsState(state, true)),
  on(TodoGeneratedActions.upsertOneTodo, (state: TodoState.IState) => setLoadingsState(state, true)),

  on(TodoGeneratedActions.upsertManyTodosSuccess, (state: TodoState.IState, { todos }) =>
    TodoState.adapter.upsertMany(todos, setLoadingsState(state, false))
  ),
  on(TodoGeneratedActions.deleteOneTodo, (state: TodoState.IState) => setLoadingsState(state, true)),
  on(TodoGeneratedActions.deleteOneTodoSuccess, (state: TodoState.IState, { idTodo }) =>
    TodoState.adapter.removeOne(idTodo, setLoadingsState(state, false))
  ),
  on(TodoGeneratedActions.clearActivesTodos, (state: TodoState.IState) => ({ ...state, actives: [] })),
  on(TodoGeneratedActions.addManyActivesTodos, (state: TodoState.IState, { idTodos }) => ({
    ...state,
    actives: state.actives.concat(idTodos)
  })),
  on(TodoGeneratedActions.deleteOneActiveTodo, (state: TodoState.IState, { idTodo }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idTodo)
  })),

  on(TodoGeneratedActions.clearTodos, () => TodoState.initialState),

  on(TodoGeneratedActions.addManyTodoElementSuccess, (state: TodoState.IState, { idTodo, idTodoElements }) => {
    if (!state.entities[idTodo]) {
      return state;
    }
    const todoElements = (state.entities[idTodo]?.todoElements as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idTodo]: {
          ...state.entities[idTodo],
          todoElements: todoElements.concat(idTodoElements.filter(id => todoElements.indexOf(id) < 0))
        }
      }
    };
  }),

  on(TodoGeneratedActions.deleteManyTodoElementSuccess, (state: TodoState.IState, { idTodoElements, idTodos }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idTodos.reduce((entities, idTodo) => {
          if (!state.entities[idTodo]?.todoElements) {
            return entities;
          }
          entities[idTodo] = {
            ...state.entities[idTodo],
            todoElements: (state.entities[idTodo]?.todoElements as number[])?.filter(
              (idTodoElement: number) => !idTodoElements.some((id: number) => id === idTodoElement)
            )
          } as TodoEntityState;
          return entities;
        }, {} as Dictionary<TodoEntityState>)
      }
    };
  }),

  on(TodoGeneratedActions.addManyElementSuccess, (state: TodoState.IState, { idTodo, idElements }) => {
    if (!state.entities[idTodo]) {
      return state;
    }
    const elements = (state.entities[idTodo]?.elements as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idTodo]: {
          ...state.entities[idTodo],
          elements: elements.concat(idElements.filter(id => elements.indexOf(id) < 0))
        }
      }
    };
  }),

  on(TodoGeneratedActions.deleteManyElementSuccess, (state: TodoState.IState, { idElements, idTodos }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idTodos.reduce((entities, idTodo) => {
          if (!state.entities[idTodo]?.elements) {
            return entities;
          }
          entities[idTodo] = {
            ...state.entities[idTodo],
            elements: (state.entities[idTodo]?.elements as number[])?.filter(
              (idElement: number) => !idElements.some((id: number) => id === idElement)
            )
          } as TodoEntityState;
          return entities;
        }, {} as Dictionary<TodoEntityState>)
      }
    };
  }),

  on(TodoGeneratedActions.addCommunitySuccess, (state: TodoState.IState, { idTodo, idCommunity }) => {
    if (!state.entities[idTodo]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idTodo]: {
          ...state.entities[idTodo],
          community: idCommunity
        }
      }
    };
  }),

  on(TodoGeneratedActions.deleteManyCommunitySuccess, (state: TodoState.IState, { idCommunities, idTodos }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idTodos.reduce((entities, idTodo) => {
          if (!state.entities[idTodo]?.community) {
            return entities;
          }
          entities[idTodo] = {
            ...state.entities[idTodo],
            community: idCommunities.some((idCommunity: number) => idCommunity === state.entities[idTodo]?.community)
              ? undefined
              : state.entities[idTodo]?.community
          } as TodoEntityState;
          return entities;
        }, {} as Dictionary<TodoEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: TodoState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): TodoState.IState {
  return { ...state, isLoaded, isLoading };
}
