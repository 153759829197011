import { LicenseManager } from '@ag-grid-enterprise/core';
import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { environment } from '@wip/environments';
import { AppModule } from './app/app.module';
import { hmrBootstrap } from './hmr';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-032921 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( CELLANCE )_is_granted_a_( Single Application )_Developer_License_for_the_application_( ouassim_ben_mosbah )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( ouassim_ben_mosbah )_need_to_be_licensed___( ouassim_ben_mosbah )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7_November_2024 )____[v2]_MTczMDkzNzYwMDAwMA==8e3828fddc2e05add48e00b7a3d67222'
);

if (environment.production) {
  enableProdMode();
}

const bootstrap = platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }]
});

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, () => bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  (() => bootstrap)();
}

function getCurrentEnv(): 'integration' | 'qualif' | 'production' | 'development' | 'test' {
  if (environment.integration) {
    return 'integration';
  } else if (environment.qualif) {
    return 'qualif';
  } else if (environment.test) {
    return 'test';
  } else if (environment.production) {
    return 'production';
  } else {
    return 'development';
  }
}
Sentry.init({
  dsn: 'https://392a6a83c70a45ed9db0c250b152dd4e@o1171300.ingest.us.sentry.io/6677837',
  environment: getCurrentEnv(),
  enabled: getCurrentEnv() !== 'development',
  // debug: getCurrentEnv() === 'development',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

  tracesSampleRate: 1.0,

  tracePropagationTargets: ['localhost', environment.apiV2Url],

  ignoreErrors: ['Non-Error exception captured'],
  tracesSampler: () => {
    return 0.5;
  },
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0
});
