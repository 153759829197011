import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationCaracteristicColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicColumnState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationCaracteristicColumnEntityState } from '@api/api-interfaces';

export const organizationCaracteristicColumnReducersGeneratedFunctions: ReducerTypes<
  OrganizationCaracteristicColumnState.IState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationCaracteristicColumnGeneratedActions.getOneOrganizationCaracteristicColumn,
    (state: OrganizationCaracteristicColumnState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicColumnGeneratedActions.getManyOrganizationCaracteristicColumns,
    (state: OrganizationCaracteristicColumnState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicColumnGeneratedActions.upsertOneOrganizationCaracteristicColumn,
    (state: OrganizationCaracteristicColumnState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationCaracteristicColumnGeneratedActions.upsertManyOrganizationCaracteristicColumnsSuccess,
    (state: OrganizationCaracteristicColumnState.IState, { organizationCaracteristicColumns }) =>
      OrganizationCaracteristicColumnState.adapter.upsertMany(
        organizationCaracteristicColumns,
        setLoadingsState(state, false)
      )
  ),
  on(
    OrganizationCaracteristicColumnGeneratedActions.deleteOneOrganizationCaracteristicColumn,
    (state: OrganizationCaracteristicColumnState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicColumnGeneratedActions.deleteOneOrganizationCaracteristicColumnSuccess,
    (state: OrganizationCaracteristicColumnState.IState, { idOrganizationCaracteristicColumn }) =>
      OrganizationCaracteristicColumnState.adapter.removeOne(
        idOrganizationCaracteristicColumn,
        setLoadingsState(state, false)
      )
  ),
  on(
    OrganizationCaracteristicColumnGeneratedActions.clearActivesOrganizationCaracteristicColumns,
    (state: OrganizationCaracteristicColumnState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationCaracteristicColumnGeneratedActions.addManyActivesOrganizationCaracteristicColumns,
    (state: OrganizationCaracteristicColumnState.IState, { idOrganizationCaracteristicColumns }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationCaracteristicColumns)
    })
  ),
  on(
    OrganizationCaracteristicColumnGeneratedActions.deleteOneActiveOrganizationCaracteristicColumn,
    (state: OrganizationCaracteristicColumnState.IState, { idOrganizationCaracteristicColumn }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationCaracteristicColumn)
    })
  ),

  on(
    OrganizationCaracteristicColumnGeneratedActions.clearOrganizationCaracteristicColumns,
    () => OrganizationCaracteristicColumnState.initialState
  ),

  on(
    OrganizationCaracteristicColumnGeneratedActions.addManyOrganizationCaracteristicValueSuccess,
    (
      state: OrganizationCaracteristicColumnState.IState,
      { idOrganizationCaracteristicColumn, idOrganizationCaracteristicValues }
    ) => {
      if (!state.entities[idOrganizationCaracteristicColumn]) {
        return state;
      }
      const organizationCaracteristicValues =
        (state.entities[idOrganizationCaracteristicColumn]?.organizationCaracteristicValues as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristicColumn]: {
            ...state.entities[idOrganizationCaracteristicColumn],
            organizationCaracteristicValues: organizationCaracteristicValues.concat(
              idOrganizationCaracteristicValues.filter(id => organizationCaracteristicValues.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicColumnGeneratedActions.deleteManyOrganizationCaracteristicValueSuccess,
    (
      state: OrganizationCaracteristicColumnState.IState,
      { idOrganizationCaracteristicValues, idOrganizationCaracteristicColumns }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristicColumns.reduce((entities, idOrganizationCaracteristicColumn) => {
            if (!state.entities[idOrganizationCaracteristicColumn]?.organizationCaracteristicValues) {
              return entities;
            }
            entities[idOrganizationCaracteristicColumn] = {
              ...state.entities[idOrganizationCaracteristicColumn],
              organizationCaracteristicValues: (
                state.entities[idOrganizationCaracteristicColumn]?.organizationCaracteristicValues as number[]
              )?.filter(
                (idOrganizationCaracteristicValue: number) =>
                  !idOrganizationCaracteristicValues.some((id: number) => id === idOrganizationCaracteristicValue)
              )
            } as OrganizationCaracteristicColumnEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicColumnEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicColumnGeneratedActions.addOrganizationCaracteristicSuccess,
    (
      state: OrganizationCaracteristicColumnState.IState,
      { idOrganizationCaracteristicColumn, idOrganizationCaracteristic }
    ) => {
      if (!state.entities[idOrganizationCaracteristicColumn]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristicColumn]: {
            ...state.entities[idOrganizationCaracteristicColumn],
            organizationCaracteristic: idOrganizationCaracteristic
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicColumnGeneratedActions.deleteManyOrganizationCaracteristicSuccess,
    (
      state: OrganizationCaracteristicColumnState.IState,
      { idOrganizationCaracteristics, idOrganizationCaracteristicColumns }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristicColumns.reduce((entities, idOrganizationCaracteristicColumn) => {
            if (!state.entities[idOrganizationCaracteristicColumn]?.organizationCaracteristic) {
              return entities;
            }
            entities[idOrganizationCaracteristicColumn] = {
              ...state.entities[idOrganizationCaracteristicColumn],
              organizationCaracteristic: idOrganizationCaracteristics.some(
                (idOrganizationCaracteristic: number) =>
                  idOrganizationCaracteristic ===
                  state.entities[idOrganizationCaracteristicColumn]?.organizationCaracteristic
              )
                ? undefined
                : state.entities[idOrganizationCaracteristicColumn]?.organizationCaracteristic
            } as OrganizationCaracteristicColumnEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicColumnEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationCaracteristicColumnState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationCaracteristicColumnState.IState {
  return { ...state, isLoaded, isLoading };
}
