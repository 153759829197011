import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RightProfilApiService } from '@wip/store/api-services';
import { GeneratedRightProfilEffects } from './right-profil-generated.effects';

@Injectable()
export class RightProfilEffects extends GeneratedRightProfilEffects {
  constructor(actions$: Actions, rightProfilApiService: RightProfilApiService, store$: Store<AppState>) {
    super(actions$, rightProfilApiService, store$);
  }
}
