import { UserDeviceState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userDeviceReducersGeneratedFunctions } from './user-device-generated.reducer';

const userDeviceReducersFunctions = [...userDeviceReducersGeneratedFunctions];

const userDeviceReducer = createReducer(UserDeviceState.initialState, ...userDeviceReducersFunctions);

export function reducer(state: UserDeviceState.IState | undefined, action: Action) {
  return userDeviceReducer(state, action);
}
