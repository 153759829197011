import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CommunityUser } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { CommunityUserRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityUserGeneratedActions } from '@wip/store/actions';
import { CommunityUserSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCommunityUserService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CommunityUserSelectors.selectIsLoadedCommunityUser));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CommunityUserSelectors.selectIsLoadingCommunityUser));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [CommunityUserSelectors.selectIsReadyAndLoadedCommunityUser as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCommunityUsers(schema: SelectSchema = {}): Observable<CommunityUser[]> {
    return this.store$.pipe(select(CommunityUserSelectors.selectAllCommunityUsers(schema))).pipe(
      switchMap(({ communityUsers }: { communityUsers: CommunityUser[] }) => {
        return this.getReady(schema).pipe(mapTo(communityUsers));
      })
    );
  }

  public selectOneCommunityUser(idCommunityUser: number, schema: SelectSchema = {}): Observable<CommunityUser> {
    return this.store$.pipe(select(CommunityUserSelectors.selectOneCommunityUser(schema, idCommunityUser))).pipe(
      switchMap(({ communityUser }: { communityUser: CommunityUser }) => {
        return this.getReady(schema).pipe(mapTo(communityUser));
      })
    );
  }

  public selectAllActiveCommunityUsers(schema: SelectSchema = {}): Observable<CommunityUser[]> {
    return this.store$.pipe(select(CommunityUserSelectors.selectActiveCommunityUsers(schema))).pipe(
      switchMap(({ communityUsers }: { communityUsers: CommunityUser[] }) => {
        return this.getReady(schema).pipe(mapTo(communityUsers));
      })
    );
  }

  public selectIdCommunityUsersActive(): Observable<number[]> {
    return this.store$.pipe(select(CommunityUserSelectors.selectIdCommunityUsersActive)).pipe(
      switchMap((idCommunityUsers: number[]) => {
        return this.getReady().pipe(mapTo(idCommunityUsers));
      })
    );
  }

  public getOneCommunityUser(
    idCommunityUser: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<CommunityUser> {
    this.store$.dispatch(CommunityUserGeneratedActions.getOneCommunityUser({ idCommunityUser, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserGeneratedActions.normalizeManyCommunityUsersAfterUpsert,
        CommunityUserGeneratedActions.communityUsersFailure,
        true
      );
    }
  }

  public getManyCommunityUsers(params: any = {}, getResult?: boolean): void | Observable<CommunityUser[]> {
    this.store$.dispatch(CommunityUserGeneratedActions.getManyCommunityUsers({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserGeneratedActions.normalizeManyCommunityUsersAfterUpsert,
        CommunityUserGeneratedActions.communityUsersFailure
      );
    }
  }

  public upsertOneCommunityUser(
    communityUser: Partial<CommunityUser>,
    ids: CommunityUserRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CommunityUser> {
    this.store$.dispatch(CommunityUserGeneratedActions.upsertOneCommunityUser({ communityUser, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserGeneratedActions.normalizeManyCommunityUsersAfterUpsert,
        CommunityUserGeneratedActions.communityUsersFailure,
        true
      );
    }
  }

  public deleteOneCommunityUser(idCommunityUser: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CommunityUserGeneratedActions.deleteOneCommunityUser({ idCommunityUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityUserGeneratedActions.deleteOneCommunityUserSuccess,
        CommunityUserGeneratedActions.communityUsersFailure
      );
    }
  }

  public setActiveCommunityUsers(idCommunityUsers: number[]): void {
    this.store$.dispatch(CommunityUserGeneratedActions.clearActivesCommunityUsers());
    this.store$.dispatch(CommunityUserGeneratedActions.addManyActivesCommunityUsers({ idCommunityUsers }));
  }
}
