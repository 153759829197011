import { CommunityUserRoleEnum, OrganizationUserRoleEnum } from '@enums';

export function isFirstRoleOverSecondRole(role1: CommunityUserRoleEnum, role2: CommunityUserRoleEnum): boolean {
  if (role1 === role2) {
    return false;
  } else if (
    role1 === CommunityUserRoleEnum.officer &&
    (role2 === CommunityUserRoleEnum.member || role2 === CommunityUserRoleEnum.viewer)
  ) {
    return true;
  } else if (role1 === CommunityUserRoleEnum.member && role2 === CommunityUserRoleEnum.viewer) {
    return true;
  } else return false;
}

export function isFirstOrganizationRoleOverSecondRole(
  role1: OrganizationUserRoleEnum,
  role2: OrganizationUserRoleEnum
): boolean {
  if (!(role1 in OrganizationUserRoleEnum) || !(role2 in OrganizationUserRoleEnum)) {
    return false;
  }

  let ObjRoles = {
    [OrganizationUserRoleEnum.superAdmin]: 5,
    [OrganizationUserRoleEnum.admin]: 4,
    [OrganizationUserRoleEnum.userPlus]: 3,
    [OrganizationUserRoleEnum.user]: 2
  };

  if (ObjRoles[role1 as keyof typeof ObjRoles] > ObjRoles[role2 as keyof typeof ObjRoles]) {
    return true;
  }
  return false;
}
