import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityUser, CommunityUserEntityState } from '@api/api-interfaces';
import { CommunityUserRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityUserGeneratedActions = createActionGroup({
  source: 'Community User Generated',
  events: {
    'Get One Community User': props<{ idCommunityUser: number; params?: any }>(),
    'Get Many Community Users': props<{ params: any }>(),
    'Add Many Actives Community Users': props<{ idCommunityUsers: number[] }>(),
    'Delete One Active Community User': props<{ idCommunityUser: number }>(),
    'Clear Actives Community Users': emptyProps(),
    'Upsert One Community User': props<{ communityUser: Partial<CommunityUser>; ids?: CommunityUserRelationsIds }>(),
    'Upsert Many Community Users': props<{
      communityUsers: Partial<CommunityUser>[];
      ids?: CommunityUserRelationsIds;
    }>(),
    'Upsert Many Community Users Success': props<{ communityUsers: CommunityUserEntityState[] }>(),
    'Delete One Community User': props<{ idCommunityUser: number }>(),
    'Delete One Community User Success': props<{ idCommunityUser: number }>(),
    'Normalize Many Community Users After Upsert': props<{ communityUsers: CommunityUserEntityState[] }>(),
    'Community Users Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Users': emptyProps(),
    'Add Community Success': props<{ idCommunityUser: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityUsers: number[] }>(),
    'Add User Success': props<{ idCommunityUser: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idCommunityUsers: number[] }>()
  }
});
