import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedUserRightApiService } from './user-right-api-generated.service';
import { UserRight } from '@api/api-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRightApiService extends GeneratedUserRightApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public applyManyUserRights(params: {
    idProfil: number;
    idOrganizationSubFamily: number;
    ids: number[];
  }): Observable<UserRight[]> {
    return this.repo.create<UserRight[]>('user-right/applyManyUserRights', params);
  }

  public deleteManyUserRights(params: { ids: number[] }): Observable<UserRight[]> {
    return this.repo.update<UserRight[]>('user-right/deleteManyUserRights', params);
  }
}
