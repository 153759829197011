import { RepositoryService } from '@wip/services/repository';
import { Meeting } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedMeetingApiService {
  constructor(protected repo: RepositoryService) {}

  public getMeetings(params?: any): Observable<Meeting[]> {
    return this.repo.getData<Meeting[]>('meeting', params);
  }

  public getMeeting(params: { idMeeting: number; params?: any }): Observable<Meeting> {
    return this.repo.getData<Meeting>('meeting/' + params.idMeeting, params.params);
  }

  public addMeeting(meeting: Partial<Meeting>): Observable<Meeting> {
    return this.repo.create<Meeting>('meeting', meeting);
  }

  public updateMeeting(meeting: Partial<Meeting>): Observable<Meeting> {
    return this.repo.update('meeting', meeting);
  }

  public deleteMeeting(idMeeting: number): Observable<number> {
    return this.repo.delete('meeting/' + +idMeeting);
  }
}
