import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityGroup, CommunityGroupEntityState } from '@api/api-interfaces';
import { CommunityGroupRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityGroupGeneratedActions = createActionGroup({
  source: 'Community Group Generated',
  events: {
    'Get One Community Group': props<{ idCommunityGroup: number; params?: any }>(),
    'Get Many Community Groups': props<{ params: any }>(),
    'Add Many Actives Community Groups': props<{ idCommunityGroups: number[] }>(),
    'Delete One Active Community Group': props<{ idCommunityGroup: number }>(),
    'Clear Actives Community Groups': emptyProps(),
    'Upsert One Community Group': props<{
      communityGroup: Partial<CommunityGroup>;
      ids?: CommunityGroupRelationsIds;
    }>(),
    'Upsert Many Community Groups': props<{
      communityGroups: Partial<CommunityGroup>[];
      ids?: CommunityGroupRelationsIds;
    }>(),
    'Upsert Many Community Groups Success': props<{ communityGroups: CommunityGroupEntityState[] }>(),
    'Delete One Community Group': props<{ idCommunityGroup: number }>(),
    'Delete One Community Group Success': props<{ idCommunityGroup: number }>(),
    'Normalize Many Community Groups After Upsert': props<{ communityGroups: CommunityGroupEntityState[] }>(),
    'Community Groups Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Groups': emptyProps(),
    'Add Community Success': props<{ idCommunityGroup: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityGroups: number[] }>(),
    'Add Group Success': props<{ idCommunityGroup: number; idGroup: number }>(),
    'Delete Many Group Success': props<{ idGroups: number[]; idCommunityGroups: number[] }>()
  }
});
