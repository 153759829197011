export enum OrganizationFamilyOperationTypeEnum {
  project = 'project',
  development = 'development'
}

export enum OrganizationFamilyOperationTypeEnumTradEToFR {
  community = 'Communauté',
  project = 'Projet',
  development = 'Développement'
}

export enum OrganizationFamilyOperationTypeEnumTradFrToE {
  Projet = 'project',
  Développement = 'development'
}
