import { Injectable } from '@angular/core';
import { CommunityUserProfil } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedCommunityUserProfilApiService } from './community-user-profil-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityUserProfilApiService extends GeneratedCommunityUserProfilApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateCommunityUserProfilWithSideEffect(
    communityUserProfil: Partial<CommunityUserProfil>
  ): Observable<CommunityUserProfil[]> {
    return this.repo.update('community-user-profil', communityUserProfil);
  }

  public convertCommunityUserProfils(ids: number[]): Observable<CommunityUserProfil[]> {
    return this.repo.update('community-user-profil/convert', ids);
  }

  public fillHandleUserProfils(userProfils: Partial<CommunityUserProfil>[]): Observable<CommunityUserProfil[]> {
    return this.repo.update('community-user-profil/fillHandleUserProfils', userProfils);
  }
}
