import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationElement } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationElementRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationElementGeneratedActions } from '@wip/store/actions';
import { OrganizationElementSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationElementService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationElementSelectors.selectIsLoadedOrganizationElement));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationElementSelectors.selectIsLoadingOrganizationElement));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationElementSelectors.selectIsReadyAndLoadedOrganizationElement as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationElements(schema: SelectSchema = {}): Observable<OrganizationElement[]> {
    return this.store$.pipe(select(OrganizationElementSelectors.selectAllOrganizationElements(schema))).pipe(
      switchMap(({ organizationElements }: { organizationElements: OrganizationElement[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationElements));
      })
    );
  }

  public selectOneOrganizationElement(
    idOrganizationElement: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationElement> {
    return this.store$
      .pipe(select(OrganizationElementSelectors.selectOneOrganizationElement(schema, idOrganizationElement)))
      .pipe(
        switchMap(({ organizationElement }: { organizationElement: OrganizationElement }) => {
          return this.getReady(schema).pipe(mapTo(organizationElement));
        })
      );
  }

  public selectAllActiveOrganizationElements(schema: SelectSchema = {}): Observable<OrganizationElement[]> {
    return this.store$.pipe(select(OrganizationElementSelectors.selectActiveOrganizationElements(schema))).pipe(
      switchMap(({ organizationElements }: { organizationElements: OrganizationElement[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationElements));
      })
    );
  }

  public selectIdOrganizationElementsActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationElementSelectors.selectIdOrganizationElementsActive)).pipe(
      switchMap((idOrganizationElements: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationElements));
      })
    );
  }

  public getOneOrganizationElement(
    idOrganizationElement: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationElement> {
    this.store$.dispatch(
      OrganizationElementGeneratedActions.getOneOrganizationElement({ idOrganizationElement, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert,
        OrganizationElementGeneratedActions.organizationElementsFailure,
        true
      );
    }
  }

  public getManyOrganizationElements(params: any = {}, getResult?: boolean): void | Observable<OrganizationElement[]> {
    this.store$.dispatch(OrganizationElementGeneratedActions.getManyOrganizationElements({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert,
        OrganizationElementGeneratedActions.organizationElementsFailure
      );
    }
  }

  public upsertOneOrganizationElement(
    organizationElement: Partial<OrganizationElement>,
    ids: OrganizationElementRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationElement> {
    this.store$.dispatch(
      OrganizationElementGeneratedActions.upsertOneOrganizationElement({ organizationElement, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert,
        OrganizationElementGeneratedActions.organizationElementsFailure,
        true
      );
    }
  }

  public deleteOneOrganizationElement(idOrganizationElement: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(OrganizationElementGeneratedActions.deleteOneOrganizationElement({ idOrganizationElement }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationElementGeneratedActions.deleteOneOrganizationElementSuccess,
        OrganizationElementGeneratedActions.organizationElementsFailure
      );
    }
  }

  public setActiveOrganizationElements(idOrganizationElements: number[]): void {
    this.store$.dispatch(OrganizationElementGeneratedActions.clearActivesOrganizationElements());
    this.store$.dispatch(
      OrganizationElementGeneratedActions.addManyActivesOrganizationElements({ idOrganizationElements })
    );
  }
}
