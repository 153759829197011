import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationChoicesEnum } from '@enums';
import { ConfirmationChoicesDialogDumbComponent } from '../confirmation-choices-dialog-dumb/confirmation-choices-dialog-dumb.component';
import { ConfirmationChoicesInterface } from '@wip/interfaces';

@UntilDestroy()
@Component({
  selector: 'up-confirmation-choices-dialog-container',
  standalone: true,
  imports: [CommonModule, ConfirmationChoicesDialogDumbComponent],
  templateUrl: './confirmation-choices-dialog-container.component.html',
  styleUrls: ['./confirmation-choices-dialog-container.component.scss']
})
export class ConfirmationChoicesDialogContainerComponent implements OnInit {
  public title!: string;
  public body!: string;
  public actions!: Map<string, ConfirmationChoicesInterface>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: { actions: Map<string, ConfirmationChoicesInterface>; title: string; body: string }
  ) {}

  public ngOnInit(): void {
    this.title = this.dialogData.title;

    this.body = this.dialogData.body;

    this.actions = new Map();

    if (this.dialogData.actions) {
      this.dialogData.actions.forEach((action: ConfirmationChoicesInterface) => {
        this.actions.set(action.color ? ConfirmationChoicesEnum.CONFIRM : ConfirmationChoicesEnum.CANCEL, {
          color: action.color,
          label: action.label
        });
      });
    } else {
      this.actions.set(ConfirmationChoicesEnum.CONFIRM, {
        color: 'primary',
        label: 'Confirmer'
      });

      this.actions.set(ConfirmationChoicesEnum.CANCEL, {
        color: '',
        label: 'Annuler'
      });
    }
  }
}
