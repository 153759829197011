export enum GanttLinkTypeEnum {
  finishToStart = '0',
  startToStart = '1',
  finishToFinish = '2',
  startToFinish = '3',
}

export enum GanttLinkSourceTargetTypeEnum {
  projectElement = 'project_element',
  todo = 'todo',
}
