import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationUserProfil, OrganizationUserProfilEntityState } from '@api/api-interfaces';
import { OrganizationUserProfilRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationUserProfilGeneratedActions = createActionGroup({
  source: 'Organization User Profil Generated',
  events: {
    'Get One Organization User Profil': props<{ idOrganizationUserProfil: number; params?: any }>(),
    'Get Many Organization User Profils': props<{ params: any }>(),
    'Add Many Actives Organization User Profils': props<{ idOrganizationUserProfils: number[] }>(),
    'Delete One Active Organization User Profil': props<{ idOrganizationUserProfil: number }>(),
    'Clear Actives Organization User Profils': emptyProps(),
    'Upsert One Organization User Profil': props<{
      organizationUserProfil: Partial<OrganizationUserProfil>;
      ids?: OrganizationUserProfilRelationsIds;
    }>(),
    'Upsert Many Organization User Profils': props<{
      organizationUserProfils: Partial<OrganizationUserProfil>[];
      ids?: OrganizationUserProfilRelationsIds;
    }>(),
    'Upsert Many Organization User Profils Success': props<{
      organizationUserProfils: OrganizationUserProfilEntityState[];
    }>(),
    'Delete One Organization User Profil': props<{ idOrganizationUserProfil: number }>(),
    'Delete One Organization User Profil Success': props<{ idOrganizationUserProfil: number }>(),
    'Normalize Many Organization User Profils After Upsert': props<{
      organizationUserProfils: OrganizationUserProfilEntityState[];
    }>(),
    'Organization User Profils Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization User Profils': emptyProps(),
    'Add Many Community User Profil Success': props<{
      idOrganizationUserProfil: number;
      idCommunityUserProfils: number[];
    }>(),
    'Delete Many Community User Profil Success': props<{
      idCommunityUserProfils: number[];
      idOrganizationUserProfils: number[];
    }>(),
    'Add Many User Profil Right Success': props<{ idOrganizationUserProfil: number; idUserProfilRights: number[] }>(),
    'Delete Many User Profil Right Success': props<{
      idUserProfilRights: number[];
      idOrganizationUserProfils: number[];
    }>(),
    'Add Many Profil Success': props<{ idOrganizationUserProfil: number; idProfils: number[] }>(),
    'Delete Many Profil Success': props<{ idProfils: number[]; idOrganizationUserProfils: number[] }>(),
    'Add Organization Success': props<{ idOrganizationUserProfil: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationUserProfils: number[] }>(),
    'Add Organization Family Success': props<{ idOrganizationUserProfil: number; idOrganizationFamily: number }>(),
    'Delete Many Organization Family Success': props<{
      idOrganizationFamilys: number[];
      idOrganizationUserProfils: number[];
    }>()
  }
});
