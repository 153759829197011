import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationCaracteristicValue, OrganizationCaracteristicValueEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicValueApiService } from '@wip/store/api-services';
import { OrganizationCaracteristicValueGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationCaracteristicValue } from '@wip/store/configs/normalization';
import { OrganizationCaracteristicValueSelectors } from '@wip/store/selectors';
import { OrganizationCaracteristicValueRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationCaracteristicColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicRowGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnValueGeneratedActions } from '@wip/store/actions';

export function getDefaultAddOrganizationCaracteristicValueActions(
  organizationCaracteristicValue: OrganizationCaracteristicValueEntityState,
  ids?: OrganizationCaracteristicValueRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationCaracteristicValueGeneratedActions.normalizeManyOrganizationCaracteristicValuesAfterUpsert({
      organizationCaracteristicValues: [organizationCaracteristicValue]
    })
  ];

  if (ids?.organizationCaracteristicColumn) {
    actions.push(
      OrganizationCaracteristicColumnGeneratedActions.addManyOrganizationCaracteristicValueSuccess({
        idOrganizationCaracteristicColumn: ids.organizationCaracteristicColumn,
        idOrganizationCaracteristicValues: [organizationCaracteristicValue.idOrganizationCaracteristicValue]
      })
    );
    actions.push(
      OrganizationCaracteristicValueGeneratedActions.addOrganizationCaracteristicColumnSuccess({
        idOrganizationCaracteristicValue: organizationCaracteristicValue.idOrganizationCaracteristicValue,
        idOrganizationCaracteristicColumn: ids.organizationCaracteristicColumn
      })
    );
  }

  if (ids?.organizationCaracteristicRow) {
    actions.push(
      OrganizationCaracteristicRowGeneratedActions.addManyOrganizationCaracteristicValueSuccess({
        idOrganizationCaracteristicRow: ids.organizationCaracteristicRow,
        idOrganizationCaracteristicValues: [organizationCaracteristicValue.idOrganizationCaracteristicValue]
      })
    );
    actions.push(
      OrganizationCaracteristicValueGeneratedActions.addOrganizationCaracteristicRowSuccess({
        idOrganizationCaracteristicValue: organizationCaracteristicValue.idOrganizationCaracteristicValue,
        idOrganizationCaracteristicRow: ids.organizationCaracteristicRow
      })
    );
  }

  if (ids?.organizationDataColumnValue) {
    actions.push(
      OrganizationDataColumnValueGeneratedActions.addManyOrganizationCaracteristicValueSuccess({
        idOrganizationDataColumnValue: ids.organizationDataColumnValue,
        idOrganizationCaracteristicValues: [organizationCaracteristicValue.idOrganizationCaracteristicValue]
      })
    );
    actions.push(
      OrganizationCaracteristicValueGeneratedActions.addOrganizationDataColumnValueSuccess({
        idOrganizationCaracteristicValue: organizationCaracteristicValue.idOrganizationCaracteristicValue,
        idOrganizationDataColumnValue: ids.organizationDataColumnValue
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationCaracteristicValueActions(
  organizationCaracteristicValue: OrganizationCaracteristicValueEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationCaracteristicValueGeneratedActions.deleteOneOrganizationCaracteristicValueSuccess({
      idOrganizationCaracteristicValue: organizationCaracteristicValue.idOrganizationCaracteristicValue
    })
  ];

  if (organizationCaracteristicValue.organizationCaracteristicColumn) {
    actions.push(
      OrganizationCaracteristicColumnGeneratedActions.deleteManyOrganizationCaracteristicValueSuccess({
        idOrganizationCaracteristicValues: [organizationCaracteristicValue.idOrganizationCaracteristicValue],
        idOrganizationCaracteristicColumns: [organizationCaracteristicValue.organizationCaracteristicColumn as number]
      })
    );
  }

  if (organizationCaracteristicValue.organizationCaracteristicRow) {
    actions.push(
      OrganizationCaracteristicRowGeneratedActions.deleteManyOrganizationCaracteristicValueSuccess({
        idOrganizationCaracteristicValues: [organizationCaracteristicValue.idOrganizationCaracteristicValue],
        idOrganizationCaracteristicRows: [organizationCaracteristicValue.organizationCaracteristicRow as number]
      })
    );
  }

  if (organizationCaracteristicValue.organizationDataColumnValue) {
    actions.push(
      OrganizationDataColumnValueGeneratedActions.deleteManyOrganizationCaracteristicValueSuccess({
        idOrganizationCaracteristicValues: [organizationCaracteristicValue.idOrganizationCaracteristicValue],
        idOrganizationDataColumnValues: [organizationCaracteristicValue.organizationDataColumnValue as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationCaracteristicValueEffects {
  constructor(
    protected actions$: Actions,
    protected organizationCaracteristicValueApiService: OrganizationCaracteristicValueApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationCaracteristicValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicValueGeneratedActions.getManyOrganizationCaracteristicValues),
      switchMap(({ params }) =>
        this.organizationCaracteristicValueApiService.getOrganizationCaracteristicValues(params).pipe(
          map((organizationCaracteristicValues: OrganizationCaracteristicValue[]) => {
            return OrganizationCaracteristicValueGeneratedActions.normalizeManyOrganizationCaracteristicValuesAfterUpsert(
              { organizationCaracteristicValues }
            );
          }),
          catchError(error =>
            of(OrganizationCaracteristicValueGeneratedActions.organizationCaracteristicValuesFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationCaracteristicValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicValueGeneratedActions.getOneOrganizationCaracteristicValue),
      switchMap(idOrganizationCaracteristicValue =>
        this.organizationCaracteristicValueApiService
          .getOrganizationCaracteristicValue(idOrganizationCaracteristicValue)
          .pipe(
            map((organizationCaracteristicValue: OrganizationCaracteristicValue) => {
              return OrganizationCaracteristicValueGeneratedActions.normalizeManyOrganizationCaracteristicValuesAfterUpsert(
                { organizationCaracteristicValues: [organizationCaracteristicValue] }
              );
            }),
            catchError(error =>
              of(OrganizationCaracteristicValueGeneratedActions.organizationCaracteristicValuesFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationCaracteristicValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicValueGeneratedActions.upsertOneOrganizationCaracteristicValue),
      concatMap(
        ({
          organizationCaracteristicValue,
          ids
        }: {
          organizationCaracteristicValue: Partial<OrganizationCaracteristicValue>;
          ids?: OrganizationCaracteristicValueRelationsIds;
        }) => {
          if (organizationCaracteristicValue.idOrganizationCaracteristicValue) {
            return this.organizationCaracteristicValueApiService
              .updateOrganizationCaracteristicValue(organizationCaracteristicValue)
              .pipe(
                map((organizationCaracteristicValueReturned: OrganizationCaracteristicValue) => {
                  return OrganizationCaracteristicValueGeneratedActions.normalizeManyOrganizationCaracteristicValuesAfterUpsert(
                    { organizationCaracteristicValues: [organizationCaracteristicValueReturned] }
                  );
                }),
                catchError(error =>
                  of(OrganizationCaracteristicValueGeneratedActions.organizationCaracteristicValuesFailure({ error }))
                )
              );
          } else {
            return this.organizationCaracteristicValueApiService
              .addOrganizationCaracteristicValue(organizationCaracteristicValue)
              .pipe(
                mergeMap((organizationCaracteristicValueReturned: OrganizationCaracteristicValue) =>
                  getDefaultAddOrganizationCaracteristicValueActions(organizationCaracteristicValueReturned, ids)
                ),
                catchError(error =>
                  of(OrganizationCaracteristicValueGeneratedActions.organizationCaracteristicValuesFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationCaracteristicValue$ = createEffect(() => {
    const selectOrganizationCaracteristicValueState$ = this.store$.select(
      OrganizationCaracteristicValueSelectors.selectOrganizationCaracteristicValueState
    );
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicValueGeneratedActions.deleteOneOrganizationCaracteristicValue),
      withLatestFrom(selectOrganizationCaracteristicValueState$),
      concatMap(([{ idOrganizationCaracteristicValue }, state]) =>
        this.organizationCaracteristicValueApiService
          .deleteOrganizationCaracteristicValue(idOrganizationCaracteristicValue)
          .pipe(
            mergeMap(_success =>
              getDefaultDeleteOrganizationCaracteristicValueActions(
                state.entities[idOrganizationCaracteristicValue] as OrganizationCaracteristicValueEntityState
              )
            ),
            catchError(error =>
              of(OrganizationCaracteristicValueGeneratedActions.organizationCaracteristicValuesFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationCaracteristicValuesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicValueGeneratedActions.normalizeManyOrganizationCaracteristicValuesAfterUpsert),
      concatMap(({ organizationCaracteristicValues }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationCaracteristicValue(
          organizationCaracteristicValues,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationCaracteristicValue] Normalization After Upsert Success')];
      })
    );
  });
}
