import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'wip-google-maps-embed',
  templateUrl: './google-maps-embed.component.html',
  styleUrls: ['./google-maps-embed.component.scss']
})
export class GoogleMapsEmbedComponent implements OnChanges {
  @Input() title: string;
  @Input() adresse: string;
  @Input() width: number;
  @Input() height: number;

  public adressToURI: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.adresse) {
      this.adressToURI = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.google.com/maps/embed/v1/place?key=AIzaSyDzhNs8sntuOuozPTO0ax4xxTDSDHGeLU8&zoom=15&q=' +
          encodeURIComponent(this.adresse)
      );
    }
  }
}
