import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'wip-information-dialog-dumb',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './information-dialog-dumb.component.html',
  styleUrls: ['./information-dialog-dumb.component.scss']
})
export class InformationDialogDumbComponent implements OnInit {
  @Input() title!: string;
  @Input() body!: string;

  @Output() emitConfirm = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public confirm() {
    this.emitConfirm.emit();
  }
}
