import { CommunityEffects } from './community.effects';
import { CommunityCaracteristicEffects } from './community-caracteristic.effects';
import { CommunityKpiEffects } from './community-kpi.effects';
import { ChildrenElementEffects } from './children-element.effects';
import { CommunityMilestoneEffects } from './community-milestone.effects';
import { CommunityMilestoneFamilyEffects } from './community-milestone-family.effects';
import { CommunityRiskEffects } from './community-risk.effects';
import { CommunitySubFamilyEffects } from './community-sub-family.effects';
import { CommunityGroupEffects } from './community-group.effects';
import { CommunityModuleEffects } from './community-module.effects';
import { CommunityUserEffects } from './community-user.effects';
import { FolderEffects } from './folder.effects';
import { FolderElementEffects } from './folder-element.effects';
import { ElementEffects } from './element.effects';
import { ElementCashEffects } from './element-cash.effects';
import { ElementLibraryEffects } from './element-library.effects';
import { GanttLinkEffects } from './gantt-link.effects';
import { GroupEffects } from './group.effects';
import { MeetingEffects } from './meeting.effects';
import { MeetingElementEffects } from './meeting-element.effects';
import { MeetingUserEffects } from './meeting-user.effects';
import { NotesHistoryEffects } from './notes-history.effects';
import { ProjectModuleEffects } from './project-module.effects';
import { OrganizationAzureEffects } from './organization-azure.effects';
import { OrganizationCaracteristicEffects } from './organization-caracteristic.effects';
import { OrganizationCaracteristicColumnEffects } from './organization-caracteristic-column.effects';
import { OrganizationCaracteristicRowEffects } from './organization-caracteristic-row.effects';
import { OrganizationCaracteristicValueEffects } from './organization-caracteristic-value.effects';
import { OrganizationMilestoneEffects } from './organization-milestone.effects';
import { OrganizationStepEffects } from './organization-step.effects';
import { OrganizationEffects } from './organization.effects';
import { OrganizationFamilyEffects } from './organization-family.effects';
import { OrganizationKpiEffects } from './organization-kpi.effects';
import { OrganizationMilestoneFamilyEffects } from './organization-milestone-family.effects';
import { OrganizationMilestoneAssociationEffects } from './organization-milestone-association.effects';
import { OrganizationDataColumnEffects } from './organization-data-column.effects';
import { OrganizationDataColumnValueEffects } from './organization-data-column-value.effects';
import { CommunityDataRowEffects } from './community-data-row.effects';
import { OrganizationProjectModuleEffects } from './organization-project-module.effects';
import { OrganizationRiskEffects } from './organization-risk.effects';
import { OrganizationUserProfilEffects } from './organization-user-profil.effects';
import { OrganizationSubFamilyEffects } from './organization-sub-family.effects';
import { OrganizationSectionEffects } from './organization-section.effects';
import { OrganizationUserEffects } from './organization-user.effects';
import { OrganizationElementEffects } from './organization-element.effects';
import { ProfilEffects } from './profil.effects';
import { ProjectElementEffects } from './project-element.effects';
import { RightEffects } from './right.effects';
import { RightProfilEffects } from './right-profil.effects';
import { SectionEffects } from './section.effects';
import { TimelineElementEffects } from './timeline-element.effects';
import { TodoEffects } from './todo.effects';
import { TodoElementEffects } from './todo-element.effects';
import { UserEffects } from './user.effects';
import { UserGroupEffects } from './user-group.effects';
import { UserProfilRightEffects } from './user-profil-right.effects';
import { UserRightEffects } from './user-right.effects';
import { UserDeviceEffects } from './user-device.effects';
import { UserBoardStateEffects } from './user-board-state.effects';
import { UserHistoryEffects } from './user-history.effects';
import { CommunityUserProfilEffects } from './community-user-profil.effects';
import { ValeurEffects } from './valeur.effects';

export const generatedEffects = [
  CommunityEffects,
  CommunityCaracteristicEffects,
  CommunityKpiEffects,
  ChildrenElementEffects,
  CommunityMilestoneEffects,
  CommunityMilestoneFamilyEffects,
  CommunityRiskEffects,
  CommunitySubFamilyEffects,
  CommunityGroupEffects,
  CommunityModuleEffects,
  CommunityUserEffects,
  FolderEffects,
  FolderElementEffects,
  ElementEffects,
  ElementCashEffects,
  ElementLibraryEffects,
  GanttLinkEffects,
  GroupEffects,
  MeetingEffects,
  MeetingElementEffects,
  MeetingUserEffects,
  NotesHistoryEffects,
  ProjectModuleEffects,
  OrganizationAzureEffects,
  OrganizationCaracteristicEffects,
  OrganizationCaracteristicColumnEffects,
  OrganizationCaracteristicRowEffects,
  OrganizationCaracteristicValueEffects,
  OrganizationMilestoneEffects,
  OrganizationStepEffects,
  OrganizationEffects,
  OrganizationFamilyEffects,
  OrganizationKpiEffects,
  OrganizationMilestoneFamilyEffects,
  OrganizationMilestoneAssociationEffects,
  OrganizationDataColumnEffects,
  OrganizationDataColumnValueEffects,
  CommunityDataRowEffects,
  OrganizationProjectModuleEffects,
  OrganizationRiskEffects,
  OrganizationUserProfilEffects,
  OrganizationSubFamilyEffects,
  OrganizationSectionEffects,
  OrganizationUserEffects,
  OrganizationElementEffects,
  ProfilEffects,
  ProjectElementEffects,
  RightEffects,
  RightProfilEffects,
  SectionEffects,
  TimelineElementEffects,
  TodoEffects,
  TodoElementEffects,
  UserEffects,
  UserGroupEffects,
  UserProfilRightEffects,
  UserRightEffects,
  UserDeviceEffects,
  UserBoardStateEffects,
  UserHistoryEffects,
  CommunityUserProfilEffects,
  ValeurEffects,
];
