import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Community } from '@api/api-interfaces';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'wip-global-map-list',
  templateUrl: './global-map-list.component.html',
  styleUrls: ['./global-map-list.component.scss']
})
export class GlobalMapListComponent implements OnChanges {
  @Input() projects: Community[];
  @Input() selectedProjectId: number;

  @Output() selectProject = new EventEmitter<number>();
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  public currentProject: Community;
  private preventScroll: boolean = false;

  constructor(private router: Router) {}

  ngOnChanges(changes) {
    if (changes.selectedProjectId && this.projects?.length) {
      this.currentProject = this.projects.find(p => p.idCommunity === this.selectedProjectId);
      if (!this.preventScroll) {
        this.scrollToIndex(this.selectedProjectId);
      }
      this.preventScroll = false;
    }
  }

  onCommunityChange(project) {
    this.router.navigate(['app/project', project.idCommunity]);
  }

  selectCommunity(project) {
    this.preventScroll = true;
    this.currentProject = project;
    this.selectProject.emit(project.idCommunity);
  }

  scrollToIndex(id: number) {
    const index = this.projects.findIndex(p => p.idCommunity === id);
    this.viewport?.scrollToIndex(index);
  }

  getCommunityId(project: Community) {
    return project.idCommunity;
  }
}
