import { AvatarRendererComponent } from '@_app/ag-grid-templates/ag-grid-avatar-renderer/ag-grid-avatar-renderer.component';
import { GridDashboardRow } from '@_app/shared/interfaces/GridDashboard.interface';
import { createEmptyYear } from '@_app/shared/utils/dates';
import { AgGridUtils } from '@_app/utils/ag-grid/cell-formatter/ag-grid.utils';
import {
  ColDef,
  ColGroupDef,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  Module
} from '@ag-grid-community/core';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Community,
  OrganizationCaracteristic,
  OrganizationMilestone,
  OrganizationMilestoneFamily,
  OrganizationUserProfil
} from '@api/api-interfaces';
import { GetPlanningProjectOutput } from '@api/projects/get-planning-projects';
import { CaracteristiqueTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { sortByMultipleKey, sortNumber } from '@utils';
import { Subject, combineLatest, startWith, tap } from 'rxjs';
import { Observable } from 'tinymce';
@UntilDestroy()
@Component({
  selector: 'wip-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnDestroy, OnChanges {
  @Input() listCaracteristics: OrganizationCaracteristic[];
  @Input() listOrganizationUserProfils: OrganizationUserProfil[];
  @Input() listOrganizationMilestones: OrganizationMilestone[];
  @Input() listProject: Community[];
  @Input() organizationMilestoneFamily: OrganizationMilestoneFamily;
  @Input() idOrganization: number;
  @Input() idOrganizationFamily: number;
  @Input() idOrganizationCaracteristic: number;
  @Input() idOrganizationMilestone: number;
  @Input() excelStyles: any[];
  @Input() datas: GetPlanningProjectOutput;
  @Input() isLoading: boolean;

  public today: Date;
  public todayMinusOneYear: Date;
  public todayPlusOneYear: Date;
  public todayPlusTwoYear: Date;
  public todayPlusThreeYear: Date;
  public currentCaracteristic: OrganizationCaracteristic;
  public currentMilestone: OrganizationMilestone;
  public year0Estimate: number;
  public year1Estimate: number;
  public year2Estimate: number;
  public year3Estimate: number;
  public year4Estimate: number;
  public overlayNoRowsTemplate: string;
  public components: { buttonsCellRenderer: () => void };
  public displayTrash: boolean;
  public loaded: boolean;
  public hasDatas$: Observable<boolean>;
  public modules: Module[] = AgGridUtils.getCommonModules();
  public gridApi: GridApi;
  public overlayLoadingTemplate: string;
  public stylesLoaded: boolean = false;

  private gridDatas: GridDashboardRow[] = [];
  private destroy$ = new Subject<void>();
  private gridReady$ = new Subject<void>();
  private dataReady$ = new Subject<void>();
  private projectReady$ = new Subject<void>();

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    this.today = new Date();
    this.todayMinusOneYear = new Date();
    this.todayMinusOneYear.setFullYear(this.today.getFullYear() - 1);
    this.todayPlusOneYear = new Date();
    this.todayPlusOneYear.setFullYear(this.today.getFullYear() + 1);
    this.todayPlusTwoYear = new Date();
    this.todayPlusTwoYear.setFullYear(this.today.getFullYear() + 2);
    this.todayPlusThreeYear = new Date();
    this.todayPlusThreeYear.setFullYear(this.today.getFullYear() + 3);
    this.year0Estimate = 0;
    this.year1Estimate = 0;
    this.year2Estimate = 0;
    this.year3Estimate = 0;
    this.year4Estimate = 0;
  }

  ngOnChanges(changes) {
    if (changes.datas && this.datas) {
      this.dataReady$.next();
    }
    if (changes.listProject && this.listProject.length) {
      this.projectReady$.next();
    }
  }

  ngOnInit() {
    this.gridDatas = [];
    this.overlayNoRowsTemplate = '<span></span>';
    combineLatest([
      this.dataReady$.pipe(untilDestroyed(this), startWith(null)),
      this.projectReady$.pipe(untilDestroyed(this), startWith(null)),
      this.gridReady$
    ])
      .pipe(
        untilDestroyed(this),
        tap(([_, __, ___]) => {
          this.updateRowData();
          this.isLoading = false;
          this.cd.markForCheck();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridReady$.next();
  }

  public getDefaultGridOptions(): GridOptions {
    return {
      ...AgGridUtils.getCommonGridOption(),
      defaultExcelExportParams: {
        fileName: 'Planning',
        sheetName: 'Planning',
        skipRowGroups: true,
        columnWidth: params => {
          return params.column.getColDef().width;
        },
        processHeaderCallback: params => {
          const colDef = params.api.getColumnDef(params.column);
          colDef.columnGroupShow = undefined;
          params.column.getUserProvidedColDef().columnGroupShow = undefined;
          return colDef.headerName;
        }
      },
      rowData: [], // there is no data during the init
      columnDefs: [], // Define columns
      rowDragManaged: false, // The grid supports drag and drop for reordering rows
      context: {
        componentParent: this // allow us to access to the component from the grid
      },
      overlayLoadingTemplate: this.overlayLoadingTemplate,
      defaultColDef: {
        ...AgGridUtils.getDefaultColDef(),
        // Default options for the columns
        // suppressMenu: true,
        editable: false,
        lockPosition: true,
        resizable: true,
        sortable: false
      },
      columnTypes: {
        // Default options for action type columns
        action: {
          editable: false,
          cellRenderer: 'buttonsCellRenderer'
        }
      },
      autoGroupColumnDef: {
        headerName: '',
        maxWidth: 24,
        width: 24,
        minWidth: 24,
        resizable: false,
        sortable: false,
        filter: false,
        editable: false
      },
      groupDefaultExpanded: -1,
      getRowClass: params => {
        if (params.node.data?.operation?.isSumFirstLine) {
          return 'planning-current-first-line';
        }
        if (params.node.data?.operation?.isSumSecondLine) {
          return 'planning-current-second-line';
        }
      },
      excelStyles: this.excelStyles
    };
  }

  private updateRowData(): void {
    this.gridDatas = [];
    // récupération des couleurs affichées

    this.gridApi?.setGridOption('columnDefs', []);

    if (this.datas?.datas) {
      this.gridApi?.setGridOption(
        'columnDefs',
        this.createColumnDefs(this.listCaracteristics, this.listOrganizationUserProfils, this.datas.titleMilestone)
      );

      const datas = this.datas.datas;
      for (const key in datas.operationsRows) {
        this.gridDatas.push(datas.operationsRows[key]);
      }

      const totalRows = [];
      const totalRowsPinned = [];
      if (this.gridDatas.length) {
        // ligne de total

        this.currentMilestone = this.listOrganizationMilestones.find(
          milestone => milestone.idOrganizationMilestone === this.idOrganizationMilestone
        );

        this.currentCaracteristic = this.listCaracteristics.find(
          Caracteristic => Caracteristic.idOrganizationCaracteristic === this.idOrganizationCaracteristic
        );

        if (this.datas.hasSum) {
          totalRows.push({
            operation: {
              name: 'Nb de projets avec ' + this.datas.titleMilestone,
              bold: true,
              colSpan: true,
              isSumFirstLine: true
            },
            [this.todayMinusOneYear.getFullYear()]: createEmptyYear(this.todayMinusOneYear.getFullYear()),
            [this.today.getFullYear()]: createEmptyYear(this.today.getFullYear()),
            [this.todayPlusOneYear.getFullYear()]: createEmptyYear(this.todayPlusOneYear.getFullYear()),
            [this.todayPlusTwoYear.getFullYear()]: createEmptyYear(this.todayPlusTwoYear.getFullYear()),
            [this.todayPlusThreeYear.getFullYear()]: createEmptyYear(this.todayPlusThreeYear.getFullYear()),

            [this.todayMinusOneYear.getFullYear()]: datas.footers.onGoing[this.todayMinusOneYear.getFullYear()],
            [this.today.getFullYear()]: datas.footers.onGoing[this.today.getFullYear()],
            [this.todayPlusOneYear.getFullYear()]: datas.footers.onGoing[this.todayPlusOneYear.getFullYear()],
            [this.todayPlusTwoYear.getFullYear()]: datas.footers.onGoing[this.todayPlusTwoYear.getFullYear()],
            [this.todayPlusThreeYear.getFullYear()]: datas.footers.onGoing[this.todayPlusThreeYear.getFullYear()],

            deliveries: {
              [this.todayMinusOneYear.getFullYear()]: datas.totalRow[this.todayMinusOneYear.getFullYear()],
              [this.today.getFullYear()]: datas.totalRow[this.today.getFullYear()],
              [this.todayPlusOneYear.getFullYear()]: datas.totalRow[this.todayPlusOneYear.getFullYear()],
              [this.todayPlusTwoYear.getFullYear()]: datas.totalRow[this.todayPlusTwoYear.getFullYear()],
              [this.todayPlusThreeYear.getFullYear()]: datas.totalRow[this.todayPlusThreeYear.getFullYear()],
              plus: datas.totalRow['plus']
            }
          });

          if (!!this.datas.titleCarac) {
            totalRows.push({
              operation: {
                name: this.datas.titleCarac + ' avec ' + this.datas.titleMilestone,
                colSpan: true,
                bold: true,
                isSumSecondLine: true
              },
              [this.todayMinusOneYear.getFullYear()]: datas.footers.onGoingCarac[this.todayMinusOneYear.getFullYear()],
              [this.today.getFullYear()]: datas.footers.onGoingCarac[this.today.getFullYear()],
              [this.todayPlusOneYear.getFullYear()]: datas.footers.onGoingCarac[this.todayPlusOneYear.getFullYear()],
              [this.todayPlusTwoYear.getFullYear()]: datas.footers.onGoingCarac[this.todayPlusTwoYear.getFullYear()],
              [this.todayPlusThreeYear.getFullYear()]:
                datas.footers.onGoingCarac[this.todayPlusThreeYear.getFullYear()],
              deliveries: {
                [this.todayMinusOneYear.getFullYear()]: datas.totalRowCarac[this.todayMinusOneYear.getFullYear()],
                [this.today.getFullYear()]: datas.totalRowCarac[this.today.getFullYear()],
                [this.todayPlusOneYear.getFullYear()]: datas.totalRowCarac[this.todayPlusOneYear.getFullYear()],
                [this.todayPlusTwoYear.getFullYear()]: datas.totalRowCarac[this.todayPlusTwoYear.getFullYear()],
                [this.todayPlusThreeYear.getFullYear()]: datas.totalRowCarac[this.todayPlusThreeYear.getFullYear()],
                plus: datas.totalRowCarac['plus']
              }
            });
          }
          totalRowsPinned.push({
            operation: {
              name: 'Projets en cours',
              bold: true,
              colSpan: true,
              isSumFirstLine: true
            },
            [this.todayMinusOneYear.getFullYear()]: createEmptyYear(this.todayMinusOneYear.getFullYear()),
            [this.today.getFullYear()]: createEmptyYear(this.today.getFullYear()),
            [this.todayPlusOneYear.getFullYear()]: createEmptyYear(this.todayPlusOneYear.getFullYear()),
            [this.todayPlusTwoYear.getFullYear()]: createEmptyYear(this.todayPlusTwoYear.getFullYear()),
            [this.todayPlusThreeYear.getFullYear()]: createEmptyYear(this.todayPlusThreeYear.getFullYear()),

            [this.todayMinusOneYear.getFullYear()]: datas.footers.onGoingColored[this.todayMinusOneYear.getFullYear()],
            [this.today.getFullYear()]: datas.footers.onGoingColored[this.today.getFullYear()],
            [this.todayPlusOneYear.getFullYear()]: datas.footers.onGoingColored[this.todayPlusOneYear.getFullYear()],
            [this.todayPlusTwoYear.getFullYear()]: datas.footers.onGoingColored[this.todayPlusTwoYear.getFullYear()],
            [this.todayPlusThreeYear.getFullYear()]: datas.footers.onGoingColored[this.todayPlusThreeYear.getFullYear()]
          });

          if (this.datas.titleCarac) {
            totalRowsPinned.push({
              operation: {
                id: 'light2',
                name: this.datas.titleCarac + ' en cours',
                bold: true,
                colSpan: true,
                isSumSecondLine: true
              },
              [this.todayMinusOneYear.getFullYear()]: createEmptyYear(this.todayMinusOneYear.getFullYear()),
              [this.today.getFullYear()]: createEmptyYear(this.today.getFullYear()),
              [this.todayPlusOneYear.getFullYear()]: createEmptyYear(this.todayPlusOneYear.getFullYear()),
              [this.todayPlusTwoYear.getFullYear()]: createEmptyYear(this.todayPlusTwoYear.getFullYear()),
              [this.todayPlusThreeYear.getFullYear()]: createEmptyYear(this.todayPlusThreeYear.getFullYear()),

              [this.todayMinusOneYear.getFullYear()]:
                datas.footers.onGoingColoredCarac[this.todayMinusOneYear.getFullYear()],
              [this.today.getFullYear()]: datas.footers.onGoingColoredCarac[this.today.getFullYear()],
              [this.todayPlusOneYear.getFullYear()]:
                datas.footers.onGoingColoredCarac[this.todayPlusOneYear.getFullYear()],
              [this.todayPlusTwoYear.getFullYear()]:
                datas.footers.onGoingColoredCarac[this.todayPlusTwoYear.getFullYear()],
              [this.todayPlusThreeYear.getFullYear()]:
                datas.footers.onGoingColoredCarac[this.todayPlusThreeYear.getFullYear()]
            });
          }
        }
      }

      if (this.gridApi) {
        this.projectToRowData();
        this.gridApi?.setGridOption('rowData', this.gridDatas);
        this.gridApi.applyTransaction({ add: totalRows });
        this.gridApi.setGridOption('pinnedBottomRowData', totalRowsPinned);
        this.gridApi.refreshHeader();
      }
    }
  }

  /**
   * @description Define column attributes and specificities
   */
  private createColumnDefs(
    listCaracteristics: OrganizationCaracteristic[],
    listResponsables: OrganizationUserProfil[],
    titleMilestone: string
  ): ColGroupDef[] {
    return [
      {
        headerName: ' ',
        children: this.getLeftCols()
      },
      {
        ...this.getCaracsCols(listCaracteristics)
      },
      {
        ...this.getRespCols(listResponsables)
      },
      {
        headerName: this.todayMinusOneYear.getFullYear().toString(),
        children: this.getChildrenCols(this.todayMinusOneYear, 12),
        openByDefault: false
      },
      {
        headerName: this.today.getFullYear().toString(),
        children: this.getChildrenCols(this.today)
      },
      {
        headerName: this.todayPlusOneYear.getFullYear().toString(),
        children: this.getChildrenCols(this.todayPlusOneYear)
      },
      {
        headerName: this.todayPlusTwoYear.getFullYear().toString(),
        children: this.getChildrenCols(this.todayPlusTwoYear)
      },
      {
        headerName: this.todayPlusThreeYear.getFullYear().toString(),
        openByDefault: false,
        children: this.getChildrenCols(this.todayPlusThreeYear, 1)
      },
      {
        headerName: titleMilestone,
        groupId: 'total',
        headerClass: '',
        children: [
          this.getLivraisonCols(this.todayMinusOneYear.getFullYear()),
          this.getLivraisonCols(this.today.getFullYear()),
          this.getLivraisonCols(this.todayPlusOneYear.getFullYear()),
          this.getLivraisonCols(this.todayPlusTwoYear.getFullYear()),
          this.getLivraisonCols(this.todayPlusThreeYear.getFullYear()),
          this.getLivraisonCols(this.todayPlusThreeYear.getFullYear(), true)
        ]
      }
    ];
  }

  private getCaracValue(params) {
    return params.data && params.data.caracteristics ? params.data.caracteristics[params.column.colId]?.value : null;
  }

  private getLeftCols(): ColDef[] {
    return [
      {
        headerName: 'Projets',
        sortable: true,
        pinned: true,
        resizable: true,
        filter: true,
        width: 250,
        field: 'operation.name',

        onCellDoubleClicked: ({ data }) => {
          this.router.navigate(['/app/project/', data?.operation?.idCommunity]);
        },
        cellClass: ['font11', 'string', 'bold'],
        cellStyle: ({ data }) => {
          return {
            border: 'none',
            borderRight: '2px solid lightgray',
            textOverflow: 'clip',
            fontWeight: data?.operation?.bold ? 'bold' : 'normal',
            backgroundColor: data?.operation?.color
          };
        },
        cellClassRules: {
          bold: ({ data }) => {
            return data?.operation?.bold;
          },
          total: ({ data }) => {
            return data?.operation?.colSpan;
          }
        }
      },

      {
        headerName: "Stade d'avancement",
        resizable: true,
        field: 'organizationStepLibelle',
        initialWidth: 120,
        width: 120,
        sortable: true,
        comparator: (_valueA, _valueB, nodeA, nodeB) => {
          if (nodeA?.data?.organizationStepOrdre == nodeB?.data?.organizationStepOrdre) {
            return 0;
          }
          return nodeA?.data?.organizationStepOrdre > nodeB?.data?.organizationStepOrdre ? 1 : -1;
        }
      }
    ];
  }

  private getCaracsCols(list: OrganizationCaracteristic[]): ColGroupDef {
    const listFiltered = list.filter(
      carac => carac.shouldDisplayInDashboard && carac.idOrganizationFamily === this.idOrganizationFamily
    );

    const caracsCol = listFiltered.map((orgaCarac: OrganizationCaracteristic): ColDef => {
      if (orgaCarac.shouldDisplayInDashboard) {
        return {
          headerName: orgaCarac.libelle,
          colId: orgaCarac.idOrganizationCaracteristic.toString(),
          field: orgaCarac.idOrganizationCaracteristic.toString(),
          width: 50,
          resizable: true,
          valueGetter: this.getCaracValue
        };
      }
    });

    const caracsGroups: ColGroupDef = {
      headerName: 'Caractéristiques',
      children: caracsCol
    };

    return caracsGroups;
  }

  private getRespCols(listResps: OrganizationUserProfil[]): ColGroupDef {
    const listFiltered = listResps.filter(
      orgaUser =>
        orgaUser.idOrganization === this.idOrganization &&
        orgaUser.idOrganizationFamily === this.idOrganizationFamily &&
        orgaUser.shouldDisplayInDashboard
    );

    const respAvatar = listFiltered.map((orgResp: OrganizationUserProfil): ColDef => {
      return {
        headerName: orgResp.libelle,
        colId: 'avatar',
        field: 'avatar',
        valueGetter: params => {
          if (
            params.data &&
            params.data.responsibles &&
            params.data.responsibles['resp_' + orgResp.idOrganizationUserProfil.toString()]
          ) {
            const user = params.data.responsibles['resp_' + orgResp.idOrganizationUserProfil.toString()]?.avatar;
            return user.prenom + ' ' + user.nom;
          }
        },
        valueFormatter: params => {
          if (
            params.data &&
            params.data.responsibles &&
            params.data.responsibles['resp_' + orgResp.idOrganizationUserProfil.toString()]
          ) {
            return params.data.responsibles['resp_' + orgResp.idOrganizationUserProfil.toString()]?.avatar;
          }
        },
        cellRenderer: AvatarRendererComponent,
        cellStyle: { 'margin-top': '3px' },
        width: 50,
        resizable: false,
        editable: false
      };
    });

    const respGroups: ColGroupDef = {
      headerName: 'Responsables',
      children: respAvatar
    };

    return respGroups;
  }

  private getChildrenCols(date: Date, group: number = 0): ColDef[] {
    const childrenCols = [];
    const cellClassRules: any = {
      string: ({ data }) => !data.operation.bold,
      number: ({ data }) => data.operation.bold,
      bold: ({ data }) => {
        return data.operation.bold;
      },
      light: _ => {
        return { 'background-color': 'lightgray' };
      }
    };

    const colorCellClassRules = [];
    for (let i = 1; i < 13; i++) {
      colorCellClassRules[i] = {};
      for (const classRule of this.excelStyles) {
        colorCellClassRules[i][classRule.id] = params => {
          const color: string = params.data[date.getFullYear()][params.colDef.headerName]?.color;
          if (!color) {
            return false;
          }
          return color === classRule.id;
        };
      }
    }

    for (let i = 1; i < 13; i++) {
      const formatedN = ('0' + i).slice(-2);

      const show = i == group;

      const col = {
        headerName: formatedN,
        suppressMenu: true,
        colId: `${date.getFullYear()}.${formatedN}`,
        field: `${date.getFullYear()}.${formatedN}.value`,
        columnGroupShow: show ? undefined : 'open',
        width: 24,
        cellClass: ['font11'],
        cellClassRules: { ...cellClassRules, ...colorCellClassRules[i] },
        cellStyle: params => {
          const lastIndex = i === 12;
          const style: any = {
            padding: '0px 0px !important',
            backgroundColor:
              params.data.operation.color === 'lightgray'
                ? 'lightgray'
                : params.data[date.getFullYear()][params.colDef.headerName].color,
            textAlign: 'center',
            textOverflow: 'clip',
            fontSize: +params.value > 999 ? '8px' : '11px',
            fontWeight: params.data.operation.bold ? 'bold' : 'normal'
          };
          if (lastIndex) {
            style.border = 'none';
            style.borderRight = '2px solid lightgray';
          } else {
            style.borderRight = '1px solid lightgray';
          }
          return style;
        }
      };

      if (!group) {
        delete col.columnGroupShow;
      }

      childrenCols.push(col);
    }
    return childrenCols;
  }

  /**
   * @description colonnes de fin: livraison
   */
  private getLivraisonCols(year: number, next?: boolean): ColDef {
    let headerName = String(year);
    if (next) {
      headerName = String(this.todayPlusThreeYear.getFullYear()).slice(2, 4) + '+';
    }
    return {
      colId: 'deliveries' + year,
      resizable: true,
      headerName,
      field: `deliveries.${next ? 'plus' : year}.value`,
      minWidth: 38,
      maxWidth: 38,
      width: 38,
      cellClass: ['font11', 'number', 'total'],
      cellStyle: params => {
        return {
          fontWeight: params.value !== 0 ? 'bold' : 'normal',
          textAlign: 'center',
          borderRight: '1px solid lightgray',
          textOverflow: 'clip',
          fontSize: '12px',
          backgroundColor: 'lightgray'
        };
      },
      headerClass: 'darkgray'
    };
  }

  private projectToRowData() {
    for (const op of this.gridDatas) {
      op.caracteristics = {};
      op.responsibles = {};
      const actual = this.listProject.find(project => project.idCommunity === op.operation.idCommunity);

      op.organizationStepLibelle = actual?.organizationStep?.libelle;
      op.organizationStepOrdre = actual?.organizationStep?.ordre ? actual?.organizationStep?.ordre : 99;
      op.name = actual?.nom;

      const getDeliveryCellValue = (year: number | 'plus') => {
        const parseDeliveryCellValue = (value: number | undefined) => {
          if (value === undefined) {
            return 1;
          }
          return value;
        };

        return {
          value: parseDeliveryCellValue((op as GridDashboardRow)[year].value)
        };
      };

      op.deliveries = {
        [this.todayMinusOneYear.getFullYear()]: getDeliveryCellValue(this.todayMinusOneYear.getFullYear()),
        [this.today.getFullYear()]: getDeliveryCellValue(this.today.getFullYear()),
        [this.todayPlusOneYear.getFullYear()]: getDeliveryCellValue(this.todayPlusOneYear.getFullYear()),
        [this.todayPlusTwoYear.getFullYear()]: getDeliveryCellValue(this.todayPlusTwoYear.getFullYear()),
        [this.todayPlusThreeYear.getFullYear()]: getDeliveryCellValue(this.todayPlusThreeYear.getFullYear()),
        plus: getDeliveryCellValue('plus')
      } as any;
      actual?.communityCaracteristics.forEach(caracteristic => {
        const caracType = this.listCaracteristics.find(
          carac =>
            carac.idOrganizationCaracteristic === caracteristic.organizationCaracteristic?.idOrganizationCaracteristic
        )?.type;
        op.caracteristics[caracteristic.organizationCaracteristic?.idOrganizationCaracteristic] = {
          type: caracType,
          value:
            caracteristic.organizationCaracteristic?.type === CaracteristiqueTypeEnum.decimal ||
            caracteristic.organizationCaracteristic?.type === CaracteristiqueTypeEnum.integer
              ? caracteristic.valeurDecimal
              : caracteristic.valeur,
          idCommunityCaracteristic: caracteristic.idCommunityCaracteristic
        };
      });
      actual?.communityUserProfils.forEach(responsable => {
        const respToDisplay = this.listOrganizationUserProfils.find(
          profil => profil.idOrganizationUserProfil === responsable.organizationUserProfil?.idOrganizationUserProfil
        );
        if (respToDisplay?.shouldDisplayInDashboard && responsable.user) {
          op.responsibles['resp_' + responsable.organizationUserProfil?.idOrganizationUserProfil] = {
            value: responsable.user.prenom + ' ' + responsable.user.nom,
            idCommunityUserProfil: responsable.idCommunityUserProfil,
            avatar: responsable.user
          };
        }
      });
    }
    this.gridDatas.sort(sortByMultipleKey([{ name: 'organizationStepOrdre', cb: sortNumber }, { name: 'name' }]));
  }

  public downloadReport() {
    if (this.gridApi) {
      const options: ExcelExportParams = {
        // fileName: 'Planning',
        // sheetName: 'Planning',
        // columnWidth: 24,
        // skipRowGroups: true,
        // skipHeader: false,
        // columnGroups: false,
        // allColumns: true
      };
      this.gridApi.exportDataAsExcel(options);
    }
  }
}
