import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FolderElement, FolderElementEntityState } from '@api/api-interfaces';
import { Folder, FolderEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { FolderElementState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const folderElementRelations: string[] = ['folders', 'elements'];

export const { selectEntities, selectAll } = FolderElementState.adapter.getSelectors();

export const selectFolderElementState = createFeatureSelector<FolderElementState.IState>(
  FolderElementState.folderElementFeatureKey
);

export const selectIsLoadedFolderElement = createSelector(
  selectFolderElementState,
  (state: FolderElementState.IState) => state.isLoaded
);

export const selectIsLoadingFolderElement = createSelector(
  selectFolderElementState,
  (state: FolderElementState.IState) => state.isLoading
);

export const selectIsReadyFolderElement = createSelector(
  selectFolderElementState,
  (state: FolderElementState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedFolderElement = createSelector(
  selectFolderElementState,
  (state: FolderElementState.IState) => state.isLoaded && !state.isLoading
);

export const selectFolderElementsEntities = createSelector(selectFolderElementState, selectEntities);

export const selectFolderElementsArray = createSelector(selectFolderElementState, selectAll);

export const selectIdFolderElementsActive = createSelector(
  selectFolderElementState,
  (state: FolderElementState.IState) => state.actives
);

const folderElementsInObject = (folderElements: Dictionary<FolderElementEntityState>) => ({ folderElements });

const selectFolderElementsEntitiesDictionary = createSelector(selectFolderElementsEntities, folderElementsInObject);

const selectAllFolderElementsObject = createSelector(selectFolderElementsEntities, folderElements => {
  return hydrateAll({ folderElements });
});

const selectOneFolderElementDictionary = (idFolderElement: number) =>
  createSelector(selectFolderElementsEntities, folderElements => ({
    folderElements: { [idFolderElement]: folderElements[idFolderElement] }
  }));

const selectOneFolderElementDictionaryWithoutChild = (idFolderElement: number) =>
  createSelector(selectFolderElementsEntities, folderElements => ({
    folderElement: folderElements[idFolderElement]
  }));

const selectActiveFolderElementsEntities = createSelector(
  selectIdFolderElementsActive,
  selectFolderElementsEntities,
  (actives: number[], folderElements: Dictionary<FolderElementEntityState>) =>
    getFolderElementsFromActives(actives, folderElements)
);

function getFolderElementsFromActives(
  actives: number[],
  folderElements: Dictionary<FolderElementEntityState>
): Dictionary<FolderElementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (folderElements[idActive]) {
      acc[idActive] = folderElements[idActive];
    }
    return acc;
  }, {} as Dictionary<FolderElementEntityState>);
}

const selectAllFolderElementsSelectors: Dictionary<Selector> = {};
export function selectAllFolderElements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<FolderElement>(
      schema,
      selectAllFolderElementsSelectors,
      selectFolderElementsEntitiesDictionary,
      getRelationSelectors,
      folderElementRelations,
      hydrateAll,
      'folderElement'
    );
  } else {
    return selectAllFolderElementsObject;
  }
}

export function selectAllFolderElementsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'folderElements'
): Selector {
  return createSelector(selectAllFolderElements(schema), result => {
    const res = { [customKey]: {} as Dictionary<FolderElementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.folderElements.length; i++) {
      res[customKey][result.folderElements[i].idFolderElement] = result.folderElements[i];
    }
    return res;
  });
}

export function selectOneFolderElement(schema: SelectSchema = {}, idFolderElement: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneFolderElementDictionary(idFolderElement)];
    selectors.push(...getRelationSelectors(schema, folderElementRelations, 'folderElement'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneFolderElementDictionaryWithoutChild(idFolderElement);
  }
}

export function selectActiveFolderElements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveFolderElementsEntities, folderElements => ({
      folderElements
    }))
  ];
  selectors.push(...getRelationSelectors(schema, folderElementRelations, 'folderElement'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  folderElements: Dictionary<FolderElementEntityState>;
  folders?: Dictionary<FolderEntityState>;
  elements?: Dictionary<ElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { folderElements: (FolderElement | null)[] } {
  const { folderElements, folders, elements } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    folderElements: Object.keys(folderElements).map(idFolderElement =>
      hydrate(folderElements[idFolderElement] as FolderElementEntityState, folders, elements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { folderElement: FolderElementEntityState | null } {
  const { folderElements, folders, elements } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const folderElement = Object.values(folderElements)[0];
  return {
    folderElement: hydrate(folderElement as FolderElementEntityState, folders, elements)
  };
}

function hydrate(
  folderElement: FolderElementEntityState,
  folderEntities?: Dictionary<FolderEntityState>,
  elementEntities?: Dictionary<ElementEntityState>
): FolderElement | null {
  if (!folderElement) {
    return null;
  }

  const folderElementHydrated: FolderElementEntityState = { ...folderElement };
  if (folderEntities) {
    folderElementHydrated.folder = folderEntities[folderElement.folder as number] as Folder;
  } else {
    delete folderElementHydrated.folder;
  }
  if (elementEntities) {
    folderElementHydrated.element = elementEntities[folderElement.element as number] as Element;
  } else {
    delete folderElementHydrated.element;
  }

  return folderElementHydrated as FolderElement;
}
