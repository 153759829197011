import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityMilestone, CommunityMilestoneEntityState } from '@api/api-interfaces';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityMilestoneState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityMilestoneRelations: string[] = ['organizationMilestones', 'communities'];

export const { selectEntities, selectAll } = CommunityMilestoneState.adapter.getSelectors();

export const selectCommunityMilestoneState = createFeatureSelector<CommunityMilestoneState.IState>(
  CommunityMilestoneState.communityMilestoneFeatureKey
);

export const selectIsLoadedCommunityMilestone = createSelector(
  selectCommunityMilestoneState,
  (state: CommunityMilestoneState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityMilestone = createSelector(
  selectCommunityMilestoneState,
  (state: CommunityMilestoneState.IState) => state.isLoading
);

export const selectIsReadyCommunityMilestone = createSelector(
  selectCommunityMilestoneState,
  (state: CommunityMilestoneState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityMilestone = createSelector(
  selectCommunityMilestoneState,
  (state: CommunityMilestoneState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityMilestonesEntities = createSelector(selectCommunityMilestoneState, selectEntities);

export const selectCommunityMilestonesArray = createSelector(selectCommunityMilestoneState, selectAll);

export const selectIdCommunityMilestonesActive = createSelector(
  selectCommunityMilestoneState,
  (state: CommunityMilestoneState.IState) => state.actives
);

const communityMilestonesInObject = (communityMilestones: Dictionary<CommunityMilestoneEntityState>) => ({
  communityMilestones
});

const selectCommunityMilestonesEntitiesDictionary = createSelector(
  selectCommunityMilestonesEntities,
  communityMilestonesInObject
);

const selectAllCommunityMilestonesObject = createSelector(selectCommunityMilestonesEntities, communityMilestones => {
  return hydrateAll({ communityMilestones });
});

const selectOneCommunityMilestoneDictionary = (idCommunityMilestone: number) =>
  createSelector(selectCommunityMilestonesEntities, communityMilestones => ({
    communityMilestones: { [idCommunityMilestone]: communityMilestones[idCommunityMilestone] }
  }));

const selectOneCommunityMilestoneDictionaryWithoutChild = (idCommunityMilestone: number) =>
  createSelector(selectCommunityMilestonesEntities, communityMilestones => ({
    communityMilestone: communityMilestones[idCommunityMilestone]
  }));

const selectActiveCommunityMilestonesEntities = createSelector(
  selectIdCommunityMilestonesActive,
  selectCommunityMilestonesEntities,
  (actives: number[], communityMilestones: Dictionary<CommunityMilestoneEntityState>) =>
    getCommunityMilestonesFromActives(actives, communityMilestones)
);

function getCommunityMilestonesFromActives(
  actives: number[],
  communityMilestones: Dictionary<CommunityMilestoneEntityState>
): Dictionary<CommunityMilestoneEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityMilestones[idActive]) {
      acc[idActive] = communityMilestones[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityMilestoneEntityState>);
}

const selectAllCommunityMilestonesSelectors: Dictionary<Selector> = {};
export function selectAllCommunityMilestones(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityMilestone>(
      schema,
      selectAllCommunityMilestonesSelectors,
      selectCommunityMilestonesEntitiesDictionary,
      getRelationSelectors,
      communityMilestoneRelations,
      hydrateAll,
      'communityMilestone'
    );
  } else {
    return selectAllCommunityMilestonesObject;
  }
}

export function selectAllCommunityMilestonesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityMilestones'
): Selector {
  return createSelector(selectAllCommunityMilestones(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityMilestoneEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityMilestones.length; i++) {
      res[customKey][result.communityMilestones[i].idCommunityMilestone] = result.communityMilestones[i];
    }
    return res;
  });
}

export function selectOneCommunityMilestone(schema: SelectSchema = {}, idCommunityMilestone: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityMilestoneDictionary(idCommunityMilestone)];
    selectors.push(...getRelationSelectors(schema, communityMilestoneRelations, 'communityMilestone'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityMilestoneDictionaryWithoutChild(idCommunityMilestone);
  }
}

export function selectActiveCommunityMilestones(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityMilestonesEntities, communityMilestones => ({
      communityMilestones
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityMilestoneRelations, 'communityMilestone'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityMilestones: Dictionary<CommunityMilestoneEntityState>;
  organizationMilestones?: Dictionary<OrganizationMilestoneEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityMilestones: (CommunityMilestone | null)[] } {
  const { communityMilestones, organizationMilestones, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communityMilestones: Object.keys(communityMilestones).map(idCommunityMilestone =>
      hydrate(
        communityMilestones[idCommunityMilestone] as CommunityMilestoneEntityState,
        organizationMilestones,
        communities
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityMilestone: CommunityMilestoneEntityState | null } {
  const { communityMilestones, organizationMilestones, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communityMilestone = Object.values(communityMilestones)[0];
  return {
    communityMilestone: hydrate(
      communityMilestone as CommunityMilestoneEntityState,
      organizationMilestones,
      communities
    )
  };
}

function hydrate(
  communityMilestone: CommunityMilestoneEntityState,
  organizationMilestoneEntities?: Dictionary<OrganizationMilestoneEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>
): CommunityMilestone | null {
  if (!communityMilestone) {
    return null;
  }

  const communityMilestoneHydrated: CommunityMilestoneEntityState = { ...communityMilestone };
  if (organizationMilestoneEntities) {
    communityMilestoneHydrated.organizationMilestone = organizationMilestoneEntities[
      communityMilestone.organizationMilestone as number
    ] as OrganizationMilestone;
  } else {
    delete communityMilestoneHydrated.organizationMilestone;
  }
  if (communityEntities) {
    communityMilestoneHydrated.community = communityEntities[communityMilestone.community as number] as Community;
  } else {
    delete communityMilestoneHydrated.community;
  }

  return communityMilestoneHydrated as CommunityMilestone;
}
