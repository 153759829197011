import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as organizationElementActions from '@wip/store/actions';
import { OrganizationElementApiService } from '@wip/store/api-services';
import { GeneratedOrganizationElementEffects } from './organization-element-generated.effects';
import { catchError, concatMap, map, of } from 'rxjs';
import { OrganizationElement } from '@api/api-interfaces';

@Injectable()
export class OrganizationElementEffects extends GeneratedOrganizationElementEffects {
  constructor(
    actions$: Actions,
    organizationElementApiService: OrganizationElementApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationElementApiService, store$);
  }

  uploadFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationElementActions.uploadNewFile),
      concatMap(({ params }) => {
        return this.organizationElementApiService.upload(params).pipe(
          map((organizationElements: OrganizationElement[]) => {
            return organizationElementActions.OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert(
              {
                organizationElements
              }
            );
          }),
          catchError(error =>
            of(organizationElementActions.OrganizationElementGeneratedActions.organizationElementsFailure({ error }))
          )
        );
      })
    );
  });

  updateOrganizationElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationElementActions.updateOrganizationElement),
      concatMap(({ params }) => {
        return this.organizationElementApiService.updateNewOrganizationElement(params).pipe(
          map((organizationElement: OrganizationElement) => {
            return organizationElementActions.OrganizationElementGeneratedActions.normalizeManyOrganizationElementsAfterUpsert(
              {
                organizationElements: [organizationElement]
              }
            );
          }),
          catchError(error =>
            of(organizationElementActions.OrganizationElementGeneratedActions.organizationElementsFailure({ error }))
          )
        );
      })
    );
  });
}
