import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationSection } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationSectionRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationSectionGeneratedActions } from '@wip/store/actions';
import { OrganizationSectionSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationSectionService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationSectionSelectors.selectIsLoadedOrganizationSection));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationSectionSelectors.selectIsLoadingOrganizationSection));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationSectionSelectors.selectIsReadyAndLoadedOrganizationSection as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationSections(schema: SelectSchema = {}): Observable<OrganizationSection[]> {
    return this.store$.pipe(select(OrganizationSectionSelectors.selectAllOrganizationSections(schema))).pipe(
      switchMap(({ organizationSections }: { organizationSections: OrganizationSection[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationSections));
      })
    );
  }

  public selectOneOrganizationSection(
    idOrganizationSection: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationSection> {
    return this.store$
      .pipe(select(OrganizationSectionSelectors.selectOneOrganizationSection(schema, idOrganizationSection)))
      .pipe(
        switchMap(({ organizationSection }: { organizationSection: OrganizationSection }) => {
          return this.getReady(schema).pipe(mapTo(organizationSection));
        })
      );
  }

  public selectAllActiveOrganizationSections(schema: SelectSchema = {}): Observable<OrganizationSection[]> {
    return this.store$.pipe(select(OrganizationSectionSelectors.selectActiveOrganizationSections(schema))).pipe(
      switchMap(({ organizationSections }: { organizationSections: OrganizationSection[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationSections));
      })
    );
  }

  public selectIdOrganizationSectionsActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationSectionSelectors.selectIdOrganizationSectionsActive)).pipe(
      switchMap((idOrganizationSections: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationSections));
      })
    );
  }

  public getOneOrganizationSection(
    idOrganizationSection: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationSection> {
    this.store$.dispatch(
      OrganizationSectionGeneratedActions.getOneOrganizationSection({ idOrganizationSection, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationSectionGeneratedActions.normalizeManyOrganizationSectionsAfterUpsert,
        OrganizationSectionGeneratedActions.organizationSectionsFailure,
        true
      );
    }
  }

  public getManyOrganizationSections(params: any = {}, getResult?: boolean): void | Observable<OrganizationSection[]> {
    this.store$.dispatch(OrganizationSectionGeneratedActions.getManyOrganizationSections({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationSectionGeneratedActions.normalizeManyOrganizationSectionsAfterUpsert,
        OrganizationSectionGeneratedActions.organizationSectionsFailure
      );
    }
  }

  public upsertOneOrganizationSection(
    organizationSection: Partial<OrganizationSection>,
    ids: OrganizationSectionRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationSection> {
    this.store$.dispatch(
      OrganizationSectionGeneratedActions.upsertOneOrganizationSection({ organizationSection, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationSectionGeneratedActions.normalizeManyOrganizationSectionsAfterUpsert,
        OrganizationSectionGeneratedActions.organizationSectionsFailure,
        true
      );
    }
  }

  public deleteOneOrganizationSection(idOrganizationSection: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(OrganizationSectionGeneratedActions.deleteOneOrganizationSection({ idOrganizationSection }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationSectionGeneratedActions.deleteOneOrganizationSectionSuccess,
        OrganizationSectionGeneratedActions.organizationSectionsFailure
      );
    }
  }

  public setActiveOrganizationSections(idOrganizationSections: number[]): void {
    this.store$.dispatch(OrganizationSectionGeneratedActions.clearActivesOrganizationSections());
    this.store$.dispatch(
      OrganizationSectionGeneratedActions.addManyActivesOrganizationSections({ idOrganizationSections })
    );
  }
}
