import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';

@Component({
  selector: 'app-total-milestone-choices',
  templateUrl: './total-milestone-choices.component.html',
  styleUrls: ['./total-milestone-choices.component.scss']
})
export class TotalMilestoneComponent implements OnChanges {
  @Input() filtersGroup: FormGroup;
  @Input() public organizationMilestoneFamily: OrganizationMilestoneFamily;
  @Input() public organizationFamily: OrganizationMilestoneFamily;

  public milestones;

  ngOnChanges(changes) {
    if (changes.organizationMilestoneFamily) {
      this.milestones = this.organizationFamily.organizationMilestones.filter(milestone =>
        this.organizationMilestoneFamily.organizationMilestones.some(
          ms => ms.idOrganizationMilestone === milestone.idOrganizationMilestone
        )
      );
    }
  }
}
