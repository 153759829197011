import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { ElementApiService } from '@wip/store/api-services';
import { ElementGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeElement } from '@wip/store/configs/normalization';
import { ElementSelectors } from '@wip/store/selectors';
import { ElementRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { TodoElementGeneratedActions } from '@wip/store/actions';
import { TodoElement } from '@api/api-interfaces';
import { ElementCashGeneratedActions } from '@wip/store/actions';
import { ElementCash } from '@api/api-interfaces';
import { MeetingElementGeneratedActions } from '@wip/store/actions';
import { MeetingElement } from '@api/api-interfaces';
import { TimelineElementGeneratedActions } from '@wip/store/actions';
import { TimelineElement } from '@api/api-interfaces';
import { FolderElementGeneratedActions } from '@wip/store/actions';
import { FolderElement } from '@api/api-interfaces';
import { ProjectElementGeneratedActions } from '@wip/store/actions';
import { ProjectElement } from '@api/api-interfaces';
import { OrganizationElementGeneratedActions } from '@wip/store/actions';
import { OrganizationElement } from '@api/api-interfaces';
import { ChildrenElementGeneratedActions } from '@wip/store/actions';
import { ChildrenElement } from '@api/api-interfaces';
import { ElementLibraryGeneratedActions } from '@wip/store/actions';
import { ElementLibrary } from '@api/api-interfaces';
import { TodoGeneratedActions } from '@wip/store/actions';
import { Todo } from '@api/api-interfaces';

export function getDefaultAddElementActions(element: ElementEntityState, ids?: ElementRelationsIds): Action[] {
  const actions: Action[] = [ElementGeneratedActions.normalizeManyElementsAfterUpsert({ elements: [element] })];

  if (ids?.organizationMilestone) {
    actions.push(
      OrganizationMilestoneGeneratedActions.addManyElementSuccess({
        idOrganizationMilestone: ids.organizationMilestone,
        idElements: [element.idElement]
      })
    );
    actions.push(
      ElementGeneratedActions.addOrganizationMilestoneSuccess({
        idElement: element.idElement,
        idOrganizationMilestone: ids.organizationMilestone
      })
    );
  }

  if (ids?.todoElements) {
    if (!Array.isArray(ids.todoElements)) {
      actions.push(
        TodoElementGeneratedActions.upsertOneTodoElement({
          todoElement: {
            idElement: element.idElement,
            idTodoElement: ids.todoElements as number
          } as TodoElement
        })
      );
      actions.push(
        ElementGeneratedActions.addManyTodoElementSuccess({
          idElement: element.idElement,
          idTodoElements: [ids.todoElements as number]
        })
      );
    } else {
      actions.push(
        TodoElementGeneratedActions.upsertManyTodoElements({
          todoElements: (ids.todoElements as number[]).map((idTodoElement: number) => ({
            idElement: element.idElement,
            idTodoElement
          })) as TodoElement[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyTodoElementSuccess({
          idElement: element.idElement,
          idTodoElements: ids.todoElements as number[]
        })
      );
    }
  }

  if (ids?.elementCashs) {
    if (!Array.isArray(ids.elementCashs)) {
      actions.push(
        ElementCashGeneratedActions.upsertOneElementCash({
          elementCash: {
            idElement: element.idElement,
            idElementCash: ids.elementCashs as number
          } as ElementCash
        })
      );
      actions.push(
        ElementGeneratedActions.addManyElementCashSuccess({
          idElement: element.idElement,
          idElementCashs: [ids.elementCashs as number]
        })
      );
    } else {
      actions.push(
        ElementCashGeneratedActions.upsertManyElementCashs({
          elementCashs: (ids.elementCashs as number[]).map((idElementCash: number) => ({
            idElement: element.idElement,
            idElementCash
          })) as ElementCash[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyElementCashSuccess({
          idElement: element.idElement,
          idElementCashs: ids.elementCashs as number[]
        })
      );
    }
  }

  if (ids?.meetingElements) {
    if (!Array.isArray(ids.meetingElements)) {
      actions.push(
        MeetingElementGeneratedActions.upsertOneMeetingElement({
          meetingElement: {
            idElement: element.idElement,
            idMeetingElement: ids.meetingElements as number
          } as MeetingElement
        })
      );
      actions.push(
        ElementGeneratedActions.addManyMeetingElementSuccess({
          idElement: element.idElement,
          idMeetingElements: [ids.meetingElements as number]
        })
      );
    } else {
      actions.push(
        MeetingElementGeneratedActions.upsertManyMeetingElements({
          meetingElements: (ids.meetingElements as number[]).map((idMeetingElement: number) => ({
            idElement: element.idElement,
            idMeetingElement
          })) as MeetingElement[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyMeetingElementSuccess({
          idElement: element.idElement,
          idMeetingElements: ids.meetingElements as number[]
        })
      );
    }
  }

  if (ids?.timelineElements) {
    if (!Array.isArray(ids.timelineElements)) {
      actions.push(
        TimelineElementGeneratedActions.upsertOneTimelineElement({
          timelineElement: {
            idElement: element.idElement,
            idTimelineElement: ids.timelineElements as number
          } as TimelineElement
        })
      );
      actions.push(
        ElementGeneratedActions.addManyTimelineElementSuccess({
          idElement: element.idElement,
          idTimelineElements: [ids.timelineElements as number]
        })
      );
    } else {
      actions.push(
        TimelineElementGeneratedActions.upsertManyTimelineElements({
          timelineElements: (ids.timelineElements as number[]).map((idTimelineElement: number) => ({
            idElement: element.idElement,
            idTimelineElement
          })) as TimelineElement[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyTimelineElementSuccess({
          idElement: element.idElement,
          idTimelineElements: ids.timelineElements as number[]
        })
      );
    }
  }

  if (ids?.folderElements) {
    if (!Array.isArray(ids.folderElements)) {
      actions.push(
        FolderElementGeneratedActions.upsertOneFolderElement({
          folderElement: {
            idElement: element.idElement,
            idFolderElement: ids.folderElements as number
          } as FolderElement
        })
      );
      actions.push(
        ElementGeneratedActions.addManyFolderElementSuccess({
          idElement: element.idElement,
          idFolderElements: [ids.folderElements as number]
        })
      );
    } else {
      actions.push(
        FolderElementGeneratedActions.upsertManyFolderElements({
          folderElements: (ids.folderElements as number[]).map((idFolderElement: number) => ({
            idElement: element.idElement,
            idFolderElement
          })) as FolderElement[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyFolderElementSuccess({
          idElement: element.idElement,
          idFolderElements: ids.folderElements as number[]
        })
      );
    }
  }

  if (ids?.projectElements) {
    if (!Array.isArray(ids.projectElements)) {
      actions.push(
        ProjectElementGeneratedActions.upsertOneProjectElement({
          projectElement: {
            idElement: element.idElement,
            idProjectElement: ids.projectElements as number
          } as ProjectElement
        })
      );
      actions.push(
        ElementGeneratedActions.addManyProjectElementSuccess({
          idElement: element.idElement,
          idProjectElements: [ids.projectElements as number]
        })
      );
    } else {
      actions.push(
        ProjectElementGeneratedActions.upsertManyProjectElements({
          projectElements: (ids.projectElements as number[]).map((idProjectElement: number) => ({
            idElement: element.idElement,
            idProjectElement
          })) as ProjectElement[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyProjectElementSuccess({
          idElement: element.idElement,
          idProjectElements: ids.projectElements as number[]
        })
      );
    }
  }

  if (ids?.organizationElements) {
    if (!Array.isArray(ids.organizationElements)) {
      actions.push(
        OrganizationElementGeneratedActions.upsertOneOrganizationElement({
          organizationElement: {
            idElement: element.idElement,
            idOrganizationElement: ids.organizationElements as number
          } as OrganizationElement
        })
      );
      actions.push(
        ElementGeneratedActions.addManyOrganizationElementSuccess({
          idElement: element.idElement,
          idOrganizationElements: [ids.organizationElements as number]
        })
      );
    } else {
      actions.push(
        OrganizationElementGeneratedActions.upsertManyOrganizationElements({
          organizationElements: (ids.organizationElements as number[]).map((idOrganizationElement: number) => ({
            idElement: element.idElement,
            idOrganizationElement
          })) as OrganizationElement[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyOrganizationElementSuccess({
          idElement: element.idElement,
          idOrganizationElements: ids.organizationElements as number[]
        })
      );
    }
  }

  if (ids?.childrenElements) {
    if (!Array.isArray(ids.childrenElements)) {
      actions.push(
        ChildrenElementGeneratedActions.upsertOneChildrenElement({
          childrenElement: {
            idElement: element.idElement,
            idChildrenElement: ids.childrenElements as number
          } as ChildrenElement
        })
      );
      actions.push(
        ElementGeneratedActions.addManyChildrenElementSuccess({
          idElement: element.idElement,
          idChildrenElements: [ids.childrenElements as number]
        })
      );
    } else {
      actions.push(
        ChildrenElementGeneratedActions.upsertManyChildrenElements({
          childrenElements: (ids.childrenElements as number[]).map((idChildrenElement: number) => ({
            idElement: element.idElement,
            idChildrenElement
          })) as ChildrenElement[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyChildrenElementSuccess({
          idElement: element.idElement,
          idChildrenElements: ids.childrenElements as number[]
        })
      );
    }
  }

  if (ids?.elementLibraries) {
    if (!Array.isArray(ids.elementLibraries)) {
      actions.push(
        ElementLibraryGeneratedActions.upsertOneElementLibrary({
          elementLibrary: {
            idElement: element.idElement,
            idElementLibrary: ids.elementLibraries as number
          } as ElementLibrary
        })
      );
      actions.push(
        ElementGeneratedActions.addManyElementLibrarySuccess({
          idElement: element.idElement,
          idElementLibraries: [ids.elementLibraries as number]
        })
      );
    } else {
      actions.push(
        ElementLibraryGeneratedActions.upsertManyElementLibraries({
          elementLibraries: (ids.elementLibraries as number[]).map((idElementLibrary: number) => ({
            idElement: element.idElement,
            idElementLibrary
          })) as ElementLibrary[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyElementLibrarySuccess({
          idElement: element.idElement,
          idElementLibraries: ids.elementLibraries as number[]
        })
      );
    }
  }

  if (ids?.todos) {
    if (!Array.isArray(ids.todos)) {
      actions.push(
        TodoGeneratedActions.upsertOneTodo({
          todo: {
            idElement: element.idElement,
            idTodo: ids.todos as number
          } as Todo & any
        })
      );
      actions.push(
        ElementGeneratedActions.addManyTodoSuccess({
          idElement: element.idElement,
          idTodos: [ids.todos as number]
        })
      );
    } else {
      actions.push(
        TodoGeneratedActions.upsertManyTodos({
          todos: (ids.todos as number[]).map((idTodo: number) => ({
            idElement: element.idElement,
            idTodo
          })) as Todo[] & any[]
        })
      );
      actions.push(
        ElementGeneratedActions.addManyTodoSuccess({
          idElement: element.idElement,
          idTodos: ids.todos as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteElementActions(element: ElementEntityState): Action[] {
  const actions: Action[] = [ElementGeneratedActions.deleteOneElementSuccess({ idElement: element.idElement })];

  if (element.organizationMilestone) {
    actions.push(
      OrganizationMilestoneGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idOrganizationMilestones: [element.organizationMilestone as number]
      })
    );
  }

  if (element.todoElements) {
    actions.push(
      TodoElementGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idTodoElements: element.todoElements as number[]
      })
    );
  }

  if (element.elementCashs) {
    actions.push(
      ElementCashGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idElementCashs: element.elementCashs as number[]
      })
    );
  }

  if (element.meetingElements) {
    actions.push(
      MeetingElementGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idMeetingElements: element.meetingElements as number[]
      })
    );
  }

  if (element.timelineElements) {
    actions.push(
      TimelineElementGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idTimelineElements: element.timelineElements as number[]
      })
    );
  }

  if (element.folderElements) {
    actions.push(
      FolderElementGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idFolderElements: element.folderElements as number[]
      })
    );
  }

  if (element.projectElements) {
    actions.push(
      ProjectElementGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idProjectElements: element.projectElements as number[]
      })
    );
  }

  if (element.organizationElements) {
    actions.push(
      OrganizationElementGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idOrganizationElements: element.organizationElements as number[]
      })
    );
  }

  if (element.childrenElements) {
    actions.push(
      ChildrenElementGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idChildrenElements: element.childrenElements as number[]
      })
    );
  }

  if (element.elementLibraries) {
    actions.push(
      ElementLibraryGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idElementLibraries: element.elementLibraries as number[]
      })
    );
  }

  if (element.todos) {
    actions.push(
      TodoGeneratedActions.deleteManyElementSuccess({
        idElements: [element.idElement],
        idTodos: element.todos as number[]
      })
    );
  }

  return actions;
}

export class GeneratedElementEffects {
  constructor(
    protected actions$: Actions,
    protected elementApiService: ElementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementGeneratedActions.getManyElements),
      switchMap(({ params }) =>
        this.elementApiService.getElements(params).pipe(
          map((elements: Element[]) => {
            return ElementGeneratedActions.normalizeManyElementsAfterUpsert({ elements });
          }),
          catchError(error => of(ElementGeneratedActions.elementsFailure({ error })))
        )
      )
    );
  });

  getOneElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementGeneratedActions.getOneElement),
      switchMap(idElement =>
        this.elementApiService.getElement(idElement).pipe(
          map((element: Element) => {
            return ElementGeneratedActions.normalizeManyElementsAfterUpsert({ elements: [element] });
          }),
          catchError(error => of(ElementGeneratedActions.elementsFailure({ error })))
        )
      )
    );
  });

  upsertOneElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementGeneratedActions.upsertOneElement),
      concatMap(({ element, ids }: { element: Partial<Element>; ids?: ElementRelationsIds }) => {
        if (element.idElement) {
          return this.elementApiService.updateElement(element).pipe(
            map((elementReturned: Element) => {
              return ElementGeneratedActions.normalizeManyElementsAfterUpsert({ elements: [elementReturned] });
            }),
            catchError(error => of(ElementGeneratedActions.elementsFailure({ error })))
          );
        } else {
          return this.elementApiService.addElement(element).pipe(
            mergeMap((elementReturned: Element) => getDefaultAddElementActions(elementReturned, ids)),
            catchError(error => of(ElementGeneratedActions.elementsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneElement$ = createEffect(() => {
    const selectElementState$ = this.store$.select(ElementSelectors.selectElementState);
    return this.actions$.pipe(
      ofType(ElementGeneratedActions.deleteOneElement),
      withLatestFrom(selectElementState$),
      concatMap(([{ idElement }, state]) =>
        this.elementApiService.deleteElement(idElement).pipe(
          mergeMap(_success => getDefaultDeleteElementActions(state.entities[idElement] as ElementEntityState)),
          catchError(error => of(ElementGeneratedActions.elementsFailure({ error })))
        )
      )
    );
  });

  normalizeManyElementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementGeneratedActions.normalizeManyElementsAfterUpsert),
      concatMap(({ elements }) => {
        const actions: Action[] = getActionsToNormalizeElement(elements, StoreActionType.upsert);
        return [getMultiAction(actions, '[Element] Normalization After Upsert Success')];
      })
    );
  });
}
