import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { OrganizationFamilyApiService } from '@wip/store/api-services';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationFamily } from '@wip/store/configs/normalization';
import { OrganizationFamilySelectors } from '@wip/store/selectors';
import { OrganizationFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationSubFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationSubFamily } from '@api/api-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { Community } from '@api/api-interfaces';
import { OrganizationStepGeneratedActions } from '@wip/store/actions';
import { OrganizationStep } from '@api/api-interfaces';
import { OrganizationKpiGeneratedActions } from '@wip/store/actions';
import { OrganizationKpi } from '@api/api-interfaces';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumn } from '@api/api-interfaces';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristic } from '@api/api-interfaces';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestone } from '@api/api-interfaces';
import { OrganizationRiskGeneratedActions } from '@wip/store/actions';
import { OrganizationRisk } from '@api/api-interfaces';
import { OrganizationUserProfilGeneratedActions } from '@wip/store/actions';
import { OrganizationUserProfil } from '@api/api-interfaces';
import { UserBoardStateGeneratedActions } from '@wip/store/actions';
import { UserBoardState } from '@api/api-interfaces';

export function getDefaultAddOrganizationFamilyActions(
  organizationFamily: OrganizationFamilyEntityState,
  ids?: OrganizationFamilyRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert({
      organizationFamilys: [organizationFamily]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationFamilySuccess({
        idOrganization: ids.organization,
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily]
      })
    );
    actions.push(
      OrganizationFamilyGeneratedActions.addOrganizationSuccess({
        idOrganizationFamily: organizationFamily.idOrganizationFamily,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationSubFamilys) {
    if (!Array.isArray(ids.organizationSubFamilys)) {
      actions.push(
        OrganizationSubFamilyGeneratedActions.upsertOneOrganizationSubFamily({
          organizationSubFamily: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationSubFamily: ids.organizationSubFamilys as number
          } as OrganizationSubFamily
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationSubFamilySuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationSubFamilys: [ids.organizationSubFamilys as number]
        })
      );
    } else {
      actions.push(
        OrganizationSubFamilyGeneratedActions.upsertManyOrganizationSubFamilys({
          organizationSubFamilys: (ids.organizationSubFamilys as number[]).map((idOrganizationSubFamily: number) => ({
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationSubFamily
          })) as OrganizationSubFamily[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationSubFamilySuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationSubFamilys: ids.organizationSubFamilys as number[]
        })
      );
    }
  }

  if (ids?.communities) {
    if (!Array.isArray(ids.communities)) {
      actions.push(
        CommunityGeneratedActions.upsertOneCommunity({
          community: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idCommunity: ids.communities as number
          } as Community
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyCommunitySuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idCommunities: [ids.communities as number]
        })
      );
    } else {
      actions.push(
        CommunityGeneratedActions.upsertManyCommunities({
          communities: (ids.communities as number[]).map((idCommunity: number) => ({
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idCommunity
          })) as Community[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyCommunitySuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idCommunities: ids.communities as number[]
        })
      );
    }
  }

  if (ids?.organizationSteps) {
    if (!Array.isArray(ids.organizationSteps)) {
      actions.push(
        OrganizationStepGeneratedActions.upsertOneOrganizationStep({
          organizationStep: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationStep: ids.organizationSteps as number
          } as OrganizationStep
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationStepSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationSteps: [ids.organizationSteps as number]
        })
      );
    } else {
      actions.push(
        OrganizationStepGeneratedActions.upsertManyOrganizationSteps({
          organizationSteps: (ids.organizationSteps as number[]).map((idOrganizationStep: number) => ({
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationStep
          })) as OrganizationStep[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationStepSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationSteps: ids.organizationSteps as number[]
        })
      );
    }
  }

  if (ids?.organizationKpis) {
    if (!Array.isArray(ids.organizationKpis)) {
      actions.push(
        OrganizationKpiGeneratedActions.upsertOneOrganizationKpi({
          organizationKpi: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationKpi: ids.organizationKpis as number
          } as OrganizationKpi
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationKpiSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationKpis: [ids.organizationKpis as number]
        })
      );
    } else {
      actions.push(
        OrganizationKpiGeneratedActions.upsertManyOrganizationKpis({
          organizationKpis: (ids.organizationKpis as number[]).map((idOrganizationKpi: number) => ({
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationKpi
          })) as OrganizationKpi[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationKpiSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationKpis: ids.organizationKpis as number[]
        })
      );
    }
  }

  if (ids?.organizationDataColumns) {
    if (!Array.isArray(ids.organizationDataColumns)) {
      actions.push(
        OrganizationDataColumnGeneratedActions.upsertOneOrganizationDataColumn({
          organizationDataColumn: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationDataColumn: ids.organizationDataColumns as number
          } as OrganizationDataColumn
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationDataColumnSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationDataColumns: [ids.organizationDataColumns as number]
        })
      );
    } else {
      actions.push(
        OrganizationDataColumnGeneratedActions.upsertManyOrganizationDataColumns({
          organizationDataColumns: (ids.organizationDataColumns as number[]).map(
            (idOrganizationDataColumn: number) => ({
              idOrganizationFamily: organizationFamily.idOrganizationFamily,
              idOrganizationDataColumn
            })
          ) as OrganizationDataColumn[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationDataColumnSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationDataColumns: ids.organizationDataColumns as number[]
        })
      );
    }
  }

  if (ids?.organizationMilestoneFamilys) {
    if (!Array.isArray(ids.organizationMilestoneFamilys)) {
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.upsertOneOrganizationMilestoneFamily({
          organizationMilestoneFamily: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationMilestoneFamily: ids.organizationMilestoneFamilys as number
          } as OrganizationMilestoneFamily
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationMilestoneFamilys: [ids.organizationMilestoneFamilys as number]
        })
      );
    } else {
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.upsertManyOrganizationMilestoneFamilys({
          organizationMilestoneFamilys: (ids.organizationMilestoneFamilys as number[]).map(
            (idOrganizationMilestoneFamily: number) => ({
              idOrganizationFamily: organizationFamily.idOrganizationFamily,
              idOrganizationMilestoneFamily
            })
          ) as OrganizationMilestoneFamily[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationMilestoneFamilys: ids.organizationMilestoneFamilys as number[]
        })
      );
    }
  }

  if (ids?.organizationCaracteristics) {
    if (!Array.isArray(ids.organizationCaracteristics)) {
      actions.push(
        OrganizationCaracteristicGeneratedActions.upsertOneOrganizationCaracteristic({
          organizationCaracteristic: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationCaracteristic: ids.organizationCaracteristics as number
          } as OrganizationCaracteristic
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationCaracteristicSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationCaracteristics: [ids.organizationCaracteristics as number]
        })
      );
    } else {
      actions.push(
        OrganizationCaracteristicGeneratedActions.upsertManyOrganizationCaracteristics({
          organizationCaracteristics: (ids.organizationCaracteristics as number[]).map(
            (idOrganizationCaracteristic: number) => ({
              idOrganizationFamily: organizationFamily.idOrganizationFamily,
              idOrganizationCaracteristic
            })
          ) as OrganizationCaracteristic[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationCaracteristicSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationCaracteristics: ids.organizationCaracteristics as number[]
        })
      );
    }
  }

  if (ids?.organizationMilestones) {
    if (!Array.isArray(ids.organizationMilestones)) {
      actions.push(
        OrganizationMilestoneGeneratedActions.upsertOneOrganizationMilestone({
          organizationMilestone: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationMilestone: ids.organizationMilestones as number
          } as OrganizationMilestone
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationMilestoneSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationMilestones: [ids.organizationMilestones as number]
        })
      );
    } else {
      actions.push(
        OrganizationMilestoneGeneratedActions.upsertManyOrganizationMilestones({
          organizationMilestones: (ids.organizationMilestones as number[]).map((idOrganizationMilestone: number) => ({
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationMilestone
          })) as OrganizationMilestone[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationMilestoneSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationMilestones: ids.organizationMilestones as number[]
        })
      );
    }
  }

  if (ids?.organizationRisks) {
    if (!Array.isArray(ids.organizationRisks)) {
      actions.push(
        OrganizationRiskGeneratedActions.upsertOneOrganizationRisk({
          organizationRisk: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationRisk: ids.organizationRisks as number
          } as OrganizationRisk
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationRiskSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationRisks: [ids.organizationRisks as number]
        })
      );
    } else {
      actions.push(
        OrganizationRiskGeneratedActions.upsertManyOrganizationRisks({
          organizationRisks: (ids.organizationRisks as number[]).map((idOrganizationRisk: number) => ({
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationRisk
          })) as OrganizationRisk[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationRiskSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationRisks: ids.organizationRisks as number[]
        })
      );
    }
  }

  if (ids?.organizationUserProfils) {
    if (!Array.isArray(ids.organizationUserProfils)) {
      actions.push(
        OrganizationUserProfilGeneratedActions.upsertOneOrganizationUserProfil({
          organizationUserProfil: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idOrganizationUserProfil: ids.organizationUserProfils as number
          } as OrganizationUserProfil
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationUserProfilSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationUserProfils: [ids.organizationUserProfils as number]
        })
      );
    } else {
      actions.push(
        OrganizationUserProfilGeneratedActions.upsertManyOrganizationUserProfils({
          organizationUserProfils: (ids.organizationUserProfils as number[]).map(
            (idOrganizationUserProfil: number) => ({
              idOrganizationFamily: organizationFamily.idOrganizationFamily,
              idOrganizationUserProfil
            })
          ) as OrganizationUserProfil[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyOrganizationUserProfilSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idOrganizationUserProfils: ids.organizationUserProfils as number[]
        })
      );
    }
  }

  if (ids?.userBoardStates) {
    if (!Array.isArray(ids.userBoardStates)) {
      actions.push(
        UserBoardStateGeneratedActions.upsertOneUserBoardState({
          userBoardState: {
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idUserBoardState: ids.userBoardStates as number
          } as UserBoardState
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyUserBoardStateSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idUserBoardStates: [ids.userBoardStates as number]
        })
      );
    } else {
      actions.push(
        UserBoardStateGeneratedActions.upsertManyUserBoardStates({
          userBoardStates: (ids.userBoardStates as number[]).map((idUserBoardState: number) => ({
            idOrganizationFamily: organizationFamily.idOrganizationFamily,
            idUserBoardState
          })) as UserBoardState[]
        })
      );
      actions.push(
        OrganizationFamilyGeneratedActions.addManyUserBoardStateSuccess({
          idOrganizationFamily: organizationFamily.idOrganizationFamily,
          idUserBoardStates: ids.userBoardStates as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationFamilyActions(organizationFamily: OrganizationFamilyEntityState): Action[] {
  const actions: Action[] = [
    OrganizationFamilyGeneratedActions.deleteOneOrganizationFamilySuccess({
      idOrganizationFamily: organizationFamily.idOrganizationFamily
    })
  ];

  if (organizationFamily.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizations: [organizationFamily.organization as number]
      })
    );
  }

  if (organizationFamily.organizationSubFamilys) {
    actions.push(
      OrganizationSubFamilyGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizationSubFamilys: organizationFamily.organizationSubFamilys as number[]
      })
    );
  }

  if (organizationFamily.communities) {
    actions.push(
      CommunityGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idCommunities: organizationFamily.communities as number[]
      })
    );
  }

  if (organizationFamily.organizationSteps) {
    actions.push(
      OrganizationStepGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizationSteps: organizationFamily.organizationSteps as number[]
      })
    );
  }

  if (organizationFamily.organizationKpis) {
    actions.push(
      OrganizationKpiGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizationKpis: organizationFamily.organizationKpis as number[]
      })
    );
  }

  if (organizationFamily.organizationDataColumns) {
    actions.push(
      OrganizationDataColumnGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizationDataColumns: organizationFamily.organizationDataColumns as number[]
      })
    );
  }

  if (organizationFamily.organizationMilestoneFamilys) {
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizationMilestoneFamilys: organizationFamily.organizationMilestoneFamilys as number[]
      })
    );
  }

  if (organizationFamily.organizationCaracteristics) {
    actions.push(
      OrganizationCaracteristicGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizationCaracteristics: organizationFamily.organizationCaracteristics as number[]
      })
    );
  }

  if (organizationFamily.organizationMilestones) {
    actions.push(
      OrganizationMilestoneGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizationMilestones: organizationFamily.organizationMilestones as number[]
      })
    );
  }

  if (organizationFamily.organizationRisks) {
    actions.push(
      OrganizationRiskGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizationRisks: organizationFamily.organizationRisks as number[]
      })
    );
  }

  if (organizationFamily.organizationUserProfils) {
    actions.push(
      OrganizationUserProfilGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idOrganizationUserProfils: organizationFamily.organizationUserProfils as number[]
      })
    );
  }

  if (organizationFamily.userBoardStates) {
    actions.push(
      UserBoardStateGeneratedActions.deleteManyOrganizationFamilySuccess({
        idOrganizationFamilys: [organizationFamily.idOrganizationFamily],
        idUserBoardStates: organizationFamily.userBoardStates as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationFamilyEffects {
  constructor(
    protected actions$: Actions,
    protected organizationFamilyApiService: OrganizationFamilyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationFamilys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationFamilyGeneratedActions.getManyOrganizationFamilys),
      switchMap(({ params }) =>
        this.organizationFamilyApiService.getOrganizationFamilys(params).pipe(
          map((organizationFamilys: OrganizationFamily[]) => {
            return OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert({
              organizationFamilys
            });
          }),
          catchError(error => of(OrganizationFamilyGeneratedActions.organizationFamilysFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationFamilyGeneratedActions.getOneOrganizationFamily),
      switchMap(idOrganizationFamily =>
        this.organizationFamilyApiService.getOrganizationFamily(idOrganizationFamily).pipe(
          map((organizationFamily: OrganizationFamily) => {
            return OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert({
              organizationFamilys: [organizationFamily]
            });
          }),
          catchError(error => of(OrganizationFamilyGeneratedActions.organizationFamilysFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationFamilyGeneratedActions.upsertOneOrganizationFamily),
      concatMap(
        ({
          organizationFamily,
          ids
        }: {
          organizationFamily: Partial<OrganizationFamily>;
          ids?: OrganizationFamilyRelationsIds;
        }) => {
          if (organizationFamily.idOrganizationFamily) {
            return this.organizationFamilyApiService.updateOrganizationFamily(organizationFamily).pipe(
              map((organizationFamilyReturned: OrganizationFamily) => {
                return OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert({
                  organizationFamilys: [organizationFamilyReturned]
                });
              }),
              catchError(error => of(OrganizationFamilyGeneratedActions.organizationFamilysFailure({ error })))
            );
          } else {
            return this.organizationFamilyApiService.addOrganizationFamily(organizationFamily).pipe(
              mergeMap((organizationFamilyReturned: OrganizationFamily) =>
                getDefaultAddOrganizationFamilyActions(organizationFamilyReturned, ids)
              ),
              catchError(error => of(OrganizationFamilyGeneratedActions.organizationFamilysFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationFamily$ = createEffect(() => {
    const selectOrganizationFamilyState$ = this.store$.select(
      OrganizationFamilySelectors.selectOrganizationFamilyState
    );
    return this.actions$.pipe(
      ofType(OrganizationFamilyGeneratedActions.deleteOneOrganizationFamily),
      withLatestFrom(selectOrganizationFamilyState$),
      concatMap(([{ idOrganizationFamily }, state]) =>
        this.organizationFamilyApiService.deleteOrganizationFamily(idOrganizationFamily).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationFamilyActions(
              state.entities[idOrganizationFamily] as OrganizationFamilyEntityState
            )
          ),
          catchError(error => of(OrganizationFamilyGeneratedActions.organizationFamilysFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationFamilysAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert),
      concatMap(({ organizationFamilys }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationFamily(organizationFamilys, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganizationFamily] Normalization After Upsert Success')];
      })
    );
  });
}
