import { IGroupMetaUsersSuggested } from '@_app/shared/interfaces/MetaUserGroup.interface';
import { SearchUsersDialogComponent } from '@_app/shared/search-users/search-users-dialog/search-users-dialog.component';
import { DatePickerDialogComponent } from '@_shared/dialogs/date-picker-dialog/date-picker-dialog.component';
import { TimePickerDialogComponent } from '@_shared/dialogs/time-picker-dialog/time-picker-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Element, TodoElement } from '@api/api-interfaces';
import { ElementLibraryTypeEnum, ElementStatusActionEnum, ElementTypeElement, ModuleList } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InformationDialogContainerComponent } from '@wip/dialogs/information-dialog';
import { ProjectElementApiService } from '@wip/store/api-services';
import { CommunityUserModel, MeetingUserModel, OrganizationUserModel, UserModel } from '@wip/store/selectors-model';
import { CommunityService, MeetingService, OrganizationService, UserService } from '@wip/store/services';
import dayjs from 'dayjs';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

export interface ActionStatus {
  value: ElementStatusActionEnum;
  text: string;
  icon: string;
  index: number;
}
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ActionService {
  public suggestions: IGroupMetaUsersSuggested[];
  public callSave = new Subject<boolean>();
  public callRemind = new Subject<boolean>();

  constructor(
    private dialog: MatDialog,
    private projectElementApi: ProjectElementApiService,
    private newCommunityService: CommunityService,
    private newMeetingService: MeetingService,
    private newOrganizationService: OrganizationService,
    private newUserService: UserService
  ) {}

  public readonly statusList: ActionStatus[] = [
    { value: ElementStatusActionEnum.open, text: 'A faire', icon: 'checkbox-empty', index: 0 },
    {
      value: ElementStatusActionEnum.progress,
      text: 'En cours',
      icon: 'checkbox-progress',
      index: 1
    },
    { value: ElementStatusActionEnum.close, text: 'Fait', icon: 'checkbox-checked', index: 2 },
    {
      value: ElementStatusActionEnum.blocked,
      text: 'Bloqué',
      icon: 'checkbox-pause',
      index: 3
    },
    { value: ElementStatusActionEnum.abort, text: 'Abandonné', icon: 'checkbox-abort', index: 4 },
    {
      value: ElementStatusActionEnum.replace,
      text: 'Remplacé',
      icon: 'checkbox-replace',
      index: 5
    },
    {
      value: ElementStatusActionEnum['not applicable'],
      text: 'Sans objet',
      icon: 'checkbox-abort',
      index: 6
    }
  ];

  public emitNewValue<T>(subject$: Subject<T>, value: T) {
    subject$.next(value);
  }

  setElementDate2(element: Element, cb?: Function) {
    if (element.typeElement === ElementTypeElement.elementAction) {
      this.setDate(element.echeance, function (date: Date) {
        if (element.typeElement === ElementTypeElement.elementAction) {
          element.echeance = date;
        }
        if (cb) {
          cb();
        }
      });
    }
  }

  setDate(date: Date, cb: Function) {
    this.dialog
      .open(DatePickerDialogComponent, {
        width: '360px',
        data: { date }
      })
      .afterClosed()
      .subscribe(dateReturn => cb(dateReturn));
  }

  setElementTime2(element: Element, cb?: Function) {
    if (element.typeElement === ElementTypeElement.elementAction) {
      this.setTime(element.echeance, function (date: Date) {
        if (date) {
          element.echeance = date;
        }
        if (cb) {
          cb();
        }
      });
    }
  }

  setTime(date: Date, cb: Function) {
    this.dialog
      .open(TimePickerDialogComponent, {
        maxWidth: '100vw',
        data: { date },
        autoFocus: false
      })
      .afterClosed()
      .subscribe(dateReturn => {
        if (dateReturn) {
          cb(dateReturn);
        } else {
          return date;
        }
      });
  }

  selectSuggestedUsers(_moduleSelected?: ModuleList) {
    const selectorsSubscription = combineLatest([
      this.newUserService.selectOneUser(this.newUserService.currentIdUser).pipe(untilDestroyed(this)),
      this.newCommunityService
        .selectAllActiveCommunities({ include: [{ model: CommunityUserModel, include: [UserModel] }] })
        .pipe(
          untilDestroyed(this),
          startWith([]),
          map(c => c[0]),
          map(c => c?.communityUsers)
        ),
      this.newMeetingService
        .selectAllActiveMeetings({ include: [{ model: MeetingUserModel, include: [UserModel] }] })
        .pipe(
          untilDestroyed(this),
          startWith([]),
          map(m => m[0]),
          map(m => m?.meetingUsers)
        ),
      this.newOrganizationService
        .selectAllActiveOrganizations({ include: [{ model: OrganizationUserModel, include: [UserModel] }] })
        .pipe(
          untilDestroyed(this),
          startWith([]),
          map(o => o[0]),
          map(o => o?.organizationUsers)
        )
    ]);
    selectorsSubscription.subscribe(([currentUser, communityUsers, meetingUsers, organizationUsers]) => {
      this.suggestions = [
        {
          label: 'Vous',
          users: [{ user: currentUser }],
          checked: false,
          request: false
        },
        {
          label: 'Utilisateurs de la communauté',
          users: communityUsers,
          checked: false,
          request: false
        },
        {
          label: 'Utilisateurs de la réunion',
          users: meetingUsers,
          checked: false,
          request: false
        },
        {
          label: "Utilisateurs de l'organisation",
          users: organizationUsers,
          checked: false,
          request: false
        }
      ];
    });
  }

  setUsersElement(element, moduleSelected?: ModuleList, params?: any, cb: Function = () => {}) {
    this.selectSuggestedUsers(moduleSelected || null);
    const dialogRef = this.dialog.open(SearchUsersDialogComponent, {
      data: {
        groupMetaUsersSuggested: this.suggestions,
        metaUsersSelected: element.elementLibraries.filter(el => el.type === ElementLibraryTypeEnum.user_idUser),
        add: [],
        rmv: [],
        displayList: 'list',
        canAddName: true,
        findExactMatch: true,
        textPlaceholder: 'Sélectionner un ou plusieurs utilisateurs à ajouter ou supprimer',
        maxChips: params ? params.maxChips : null
      },
      width: '500px',
      height: '700px',
      panelClass: ['dialog-content-no-paddings', 'custom-dialog-fullscreen-xs']
    });
    dialogRef
      .afterClosed()
      .pipe(
        untilDestroyed(this),
        filter(res => !!res)
      )
      .subscribe(() => {
        cb(element);
      });
  }

  toggleImportantElement(todoElement: TodoElement) {
    if (todoElement && todoElement.element.type === ElementTypeElement.elementAction) {
      todoElement.element.important = this.toggleImportant(todoElement.element.important);
    }
  }
  toggleImportant(important: boolean) {
    return !important;
  }

  getActionStatus(statusAction: ElementStatusActionEnum, isProjectElement?: boolean): ActionStatus {
    let actionStatus: ActionStatus;
    if (statusAction) {
      actionStatus = this.statusList.find(status => status.value === statusAction);
    } else {
      actionStatus = this.statusList[0];
    }
    if (isProjectElement) {
      actionStatus = { ...actionStatus, icon: actionStatus.icon + '-round' };
    }
    return actionStatus;
  }

  toggleQuickStatusAction(todoElement: TodoElement): void {
    if (todoElement && todoElement.element.typeElement === ElementTypeElement.elementAction) {
      if (todoElement.element.statusAction === ElementStatusActionEnum.open) {
        todoElement.element.statusAction = ElementStatusActionEnum.progress;
      } else if (todoElement.element.statusAction === ElementStatusActionEnum.progress) {
        todoElement.element.statusAction = ElementStatusActionEnum.close;
      } else {
        todoElement.element.statusAction = ElementStatusActionEnum.open;
      }
    }
  }

  newToggleQuickStatusAction(element: Element): ElementStatusActionEnum {
    if (element && element.typeElement === ElementTypeElement.elementAction) {
      if (element.statusAction === ElementStatusActionEnum.open) {
        return ElementStatusActionEnum.progress;
      } else if (element.statusAction === ElementStatusActionEnum.progress) {
        return ElementStatusActionEnum.close;
      } else {
        return ElementStatusActionEnum.open;
      }
    }
  }

  toggleTodoStatusAction(todoElement: TodoElement): void {
    if (todoElement && todoElement.element.type === ElementTypeElement.elementAction) {
      if (todoElement.element.statusAction === ElementStatusActionEnum.open) {
        todoElement.element.statusAction = ElementStatusActionEnum.progress;
      } else if (todoElement.element.statusAction === ElementStatusActionEnum.progress) {
        todoElement.element.statusAction = ElementStatusActionEnum.close;
      } else if (todoElement.element.statusAction === ElementStatusActionEnum.close) {
        todoElement.element.statusAction = ElementStatusActionEnum.open;
      } else if (todoElement.element.statusAction === ElementStatusActionEnum.blocked) {
        todoElement.element.statusAction = ElementStatusActionEnum.progress;
      } else if (todoElement.element.statusAction === ElementStatusActionEnum.abort) {
        todoElement.element.statusAction = ElementStatusActionEnum.open;
      } else if (todoElement.element.statusAction === ElementStatusActionEnum.replace) {
        todoElement.element.statusAction = ElementStatusActionEnum.open;
      }
    }
  }

  toggleTwoSubStatusAction(statusActionValue: ElementStatusActionEnum): ElementStatusActionEnum {
    switch (statusActionValue) {
      case ElementStatusActionEnum.open:
        return ElementStatusActionEnum.close;
      case ElementStatusActionEnum.close:
        return ElementStatusActionEnum.abort;
      case ElementStatusActionEnum.abort:
        return ElementStatusActionEnum.open;
      default:
        throw new Error('Unknow action status');
    }
  }

  toggleAllStatusAction(statusAction: ElementStatusActionEnum): ElementStatusActionEnum {
    if (statusAction) {
      const actionStatus = this.getActionStatus(statusAction);

      if (actionStatus) {
        const nextIndex = (actionStatus.index + 1) % this.statusList.length;
        return this.statusList[nextIndex].value;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  setDateClasses2(element: Element): string[] {
    const classes = ['icon', 'icon-ic_fluent_calendar_24_regular'];
    if (element.echeance) {
      classes.push('cellance-primary');
    }
    return classes;
  }

  setTimeClasses2(element: Element): string[] {
    const classes = ['icon', 'icon-ic_fluent_clock_24_regular'];
    const time = dayjs(element.echeance).format('HH:mm');
    if (time !== '00:00') {
      classes.push('cellance-primary');
    }
    return classes;
  }

  setUsersClasses2(element: Element): string[] {
    const classes = ['icon', 'icon-ic_fluent_person_24_regular'];
    if (element.elementLibraries.filter(el => el.type === ElementLibraryTypeEnum.user_idUser).length) {
      classes.push('cellance-primary');
    }
    return classes;
  }

  setReminder(idElement, idCommunity, url, content) {
    this.projectElementApi
      .reminder({
        idElement,
        idCommunity,
        url,
        content
      })
      .pipe(
        untilDestroyed(this),
        filter(res => !!res)
      )
      .subscribe(_res => {
        this.dialog.open(InformationDialogContainerComponent, {
          data: {
            body: "Un rappel vient d'être envoyé aux responsables."
          }
        });
      });
  }

  openDialogFormReminder() {
    this.dialog.open(InformationDialogContainerComponent, {
      data: {
        body: 'Un rappel sera envoyé avec la sauvegarde.'
      }
    });
  }
}
