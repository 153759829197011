import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapsComponent } from './google-maps.component';

@NgModule({
  declarations: [GoogleMapsComponent],
  exports: [GoogleMapsComponent],
  imports: [CommonModule, GoogleMapsModule]
})
export class GoogleMapsComponentModule {}
