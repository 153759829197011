import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ElementCash, ElementCashEntityState } from '@api/api-interfaces';
import { ElementCashRelationsIds } from '@wip/store/ids-interfaces';

export const ElementCashGeneratedActions = createActionGroup({
  source: 'Element Cash Generated',
  events: {
    'Get One Element Cash': props<{ idElementCash: number; params?: any }>(),
    'Get Many Element Cashs': props<{ params: any }>(),
    'Add Many Actives Element Cashs': props<{ idElementCashs: number[] }>(),
    'Delete One Active Element Cash': props<{ idElementCash: number }>(),
    'Clear Actives Element Cashs': emptyProps(),
    'Upsert One Element Cash': props<{ elementCash: Partial<ElementCash>; ids?: ElementCashRelationsIds }>(),
    'Upsert Many Element Cashs': props<{ elementCashs: Partial<ElementCash>[]; ids?: ElementCashRelationsIds }>(),
    'Upsert Many Element Cashs Success': props<{ elementCashs: ElementCashEntityState[] }>(),
    'Delete One Element Cash': props<{ idElementCash: number }>(),
    'Delete One Element Cash Success': props<{ idElementCash: number }>(),
    'Normalize Many Element Cashs After Upsert': props<{ elementCashs: ElementCashEntityState[] }>(),
    'Element Cashs Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Element Cashs': emptyProps(),
    'Add Element Success': props<{ idElementCash: number; idElement: number }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idElementCashs: number[] }>()
  }
});
