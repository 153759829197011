import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityMilestoneFamily, CommunityMilestoneFamilyEntityState } from '@api/api-interfaces';
import { CommunityMilestoneFamilyApiService } from '@wip/store/api-services';
import { CommunityMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityMilestoneFamily } from '@wip/store/configs/normalization';
import { CommunityMilestoneFamilySelectors } from '@wip/store/selectors';
import { CommunityMilestoneFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityGeneratedActions } from '@wip/store/actions';

export function getDefaultAddCommunityMilestoneFamilyActions(
  communityMilestoneFamily: CommunityMilestoneFamilyEntityState,
  ids?: CommunityMilestoneFamilyRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityMilestoneFamilyGeneratedActions.normalizeManyCommunityMilestoneFamilysAfterUpsert({
      communityMilestoneFamilys: [communityMilestoneFamily]
    })
  ];

  if (ids?.organizationMilestoneFamily) {
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.addManyCommunityMilestoneFamilySuccess({
        idOrganizationMilestoneFamily: ids.organizationMilestoneFamily,
        idCommunityMilestoneFamilys: [communityMilestoneFamily.idCommunityMilestoneFamily]
      })
    );
    actions.push(
      CommunityMilestoneFamilyGeneratedActions.addOrganizationMilestoneFamilySuccess({
        idCommunityMilestoneFamily: communityMilestoneFamily.idCommunityMilestoneFamily,
        idOrganizationMilestoneFamily: ids.organizationMilestoneFamily
      })
    );
  }

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityMilestoneFamilySuccess({
        idCommunity: ids.community,
        idCommunityMilestoneFamilys: [communityMilestoneFamily.idCommunityMilestoneFamily]
      })
    );
    actions.push(
      CommunityMilestoneFamilyGeneratedActions.addCommunitySuccess({
        idCommunityMilestoneFamily: communityMilestoneFamily.idCommunityMilestoneFamily,
        idCommunity: ids.community
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCommunityMilestoneFamilyActions(
  communityMilestoneFamily: CommunityMilestoneFamilyEntityState
): Action[] {
  const actions: Action[] = [
    CommunityMilestoneFamilyGeneratedActions.deleteOneCommunityMilestoneFamilySuccess({
      idCommunityMilestoneFamily: communityMilestoneFamily.idCommunityMilestoneFamily
    })
  ];

  if (communityMilestoneFamily.organizationMilestoneFamily) {
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.deleteManyCommunityMilestoneFamilySuccess({
        idCommunityMilestoneFamilys: [communityMilestoneFamily.idCommunityMilestoneFamily],
        idOrganizationMilestoneFamilys: [communityMilestoneFamily.organizationMilestoneFamily as number]
      })
    );
  }

  if (communityMilestoneFamily.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityMilestoneFamilySuccess({
        idCommunityMilestoneFamilys: [communityMilestoneFamily.idCommunityMilestoneFamily],
        idCommunities: [communityMilestoneFamily.community as number]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityMilestoneFamilyEffects {
  constructor(
    protected actions$: Actions,
    protected communityMilestoneFamilyApiService: CommunityMilestoneFamilyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityMilestoneFamilys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityMilestoneFamilyGeneratedActions.getManyCommunityMilestoneFamilys),
      switchMap(({ params }) =>
        this.communityMilestoneFamilyApiService.getCommunityMilestoneFamilys(params).pipe(
          map((communityMilestoneFamilys: CommunityMilestoneFamily[]) => {
            return CommunityMilestoneFamilyGeneratedActions.normalizeManyCommunityMilestoneFamilysAfterUpsert({
              communityMilestoneFamilys
            });
          }),
          catchError(error => of(CommunityMilestoneFamilyGeneratedActions.communityMilestoneFamilysFailure({ error })))
        )
      )
    );
  });

  getOneCommunityMilestoneFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityMilestoneFamilyGeneratedActions.getOneCommunityMilestoneFamily),
      switchMap(idCommunityMilestoneFamily =>
        this.communityMilestoneFamilyApiService.getCommunityMilestoneFamily(idCommunityMilestoneFamily).pipe(
          map((communityMilestoneFamily: CommunityMilestoneFamily) => {
            return CommunityMilestoneFamilyGeneratedActions.normalizeManyCommunityMilestoneFamilysAfterUpsert({
              communityMilestoneFamilys: [communityMilestoneFamily]
            });
          }),
          catchError(error => of(CommunityMilestoneFamilyGeneratedActions.communityMilestoneFamilysFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityMilestoneFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityMilestoneFamilyGeneratedActions.upsertOneCommunityMilestoneFamily),
      concatMap(
        ({
          communityMilestoneFamily,
          ids
        }: {
          communityMilestoneFamily: Partial<CommunityMilestoneFamily>;
          ids?: CommunityMilestoneFamilyRelationsIds;
        }) => {
          if (communityMilestoneFamily.idCommunityMilestoneFamily) {
            return this.communityMilestoneFamilyApiService
              .updateCommunityMilestoneFamily(communityMilestoneFamily)
              .pipe(
                map((communityMilestoneFamilyReturned: CommunityMilestoneFamily) => {
                  return CommunityMilestoneFamilyGeneratedActions.normalizeManyCommunityMilestoneFamilysAfterUpsert({
                    communityMilestoneFamilys: [communityMilestoneFamilyReturned]
                  });
                }),
                catchError(error =>
                  of(CommunityMilestoneFamilyGeneratedActions.communityMilestoneFamilysFailure({ error }))
                )
              );
          } else {
            return this.communityMilestoneFamilyApiService.addCommunityMilestoneFamily(communityMilestoneFamily).pipe(
              mergeMap((communityMilestoneFamilyReturned: CommunityMilestoneFamily) =>
                getDefaultAddCommunityMilestoneFamilyActions(communityMilestoneFamilyReturned, ids)
              ),
              catchError(error =>
                of(CommunityMilestoneFamilyGeneratedActions.communityMilestoneFamilysFailure({ error }))
              )
            );
          }
        }
      )
    );
  });

  deleteOneCommunityMilestoneFamily$ = createEffect(() => {
    const selectCommunityMilestoneFamilyState$ = this.store$.select(
      CommunityMilestoneFamilySelectors.selectCommunityMilestoneFamilyState
    );
    return this.actions$.pipe(
      ofType(CommunityMilestoneFamilyGeneratedActions.deleteOneCommunityMilestoneFamily),
      withLatestFrom(selectCommunityMilestoneFamilyState$),
      concatMap(([{ idCommunityMilestoneFamily }, state]) =>
        this.communityMilestoneFamilyApiService.deleteCommunityMilestoneFamily(idCommunityMilestoneFamily).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityMilestoneFamilyActions(
              state.entities[idCommunityMilestoneFamily] as CommunityMilestoneFamilyEntityState
            )
          ),
          catchError(error => of(CommunityMilestoneFamilyGeneratedActions.communityMilestoneFamilysFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityMilestoneFamilysAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityMilestoneFamilyGeneratedActions.normalizeManyCommunityMilestoneFamilysAfterUpsert),
      concatMap(({ communityMilestoneFamilys }) => {
        const actions: Action[] = getActionsToNormalizeCommunityMilestoneFamily(
          communityMilestoneFamilys,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CommunityMilestoneFamily] Normalization After Upsert Success')];
      })
    );
  });
}
