/* eslint-disable max-lines */
import { StoreActionType } from '@enums';
import { Action } from '@ngrx/store';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { CommunityCaracteristicGeneratedActions } from '@wip/store/actions';
import { CommunityCaracteristic, CommunityCaracteristicEntityState } from '@api/api-interfaces';
import { CommunityKpiGeneratedActions } from '@wip/store/actions';
import { CommunityKpi, CommunityKpiEntityState } from '@api/api-interfaces';
import { ChildrenElementGeneratedActions } from '@wip/store/actions';
import { ChildrenElement, ChildrenElementEntityState } from '@api/api-interfaces';
import { CommunityMilestoneGeneratedActions } from '@wip/store/actions';
import { CommunityMilestone, CommunityMilestoneEntityState } from '@api/api-interfaces';
import { CommunityMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityMilestoneFamily, CommunityMilestoneFamilyEntityState } from '@api/api-interfaces';
import { CommunityRiskGeneratedActions } from '@wip/store/actions';
import { CommunityRisk, CommunityRiskEntityState } from '@api/api-interfaces';
import { CommunitySubFamilyGeneratedActions } from '@wip/store/actions';
import { CommunitySubFamily, CommunitySubFamilyEntityState } from '@api/api-interfaces';
import { CommunityGroupGeneratedActions } from '@wip/store/actions';
import { CommunityGroup, CommunityGroupEntityState } from '@api/api-interfaces';
import { CommunityModuleGeneratedActions } from '@wip/store/actions';
import { CommunityModule, CommunityModuleEntityState } from '@api/api-interfaces';
import { CommunityUserGeneratedActions } from '@wip/store/actions';
import { CommunityUser, CommunityUserEntityState } from '@api/api-interfaces';
import { FolderGeneratedActions } from '@wip/store/actions';
import { Folder, FolderEntityState } from '@api/api-interfaces';
import { FolderElementGeneratedActions } from '@wip/store/actions';
import { FolderElement, FolderElementEntityState } from '@api/api-interfaces';
import { ElementGeneratedActions } from '@wip/store/actions';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { ElementCashGeneratedActions } from '@wip/store/actions';
import { ElementCash, ElementCashEntityState } from '@api/api-interfaces';
import { ElementLibraryGeneratedActions } from '@wip/store/actions';
import { ElementLibrary, ElementLibraryEntityState } from '@api/api-interfaces';
import { GanttLinkGeneratedActions } from '@wip/store/actions';
import { GanttLink, GanttLinkEntityState } from '@api/api-interfaces';
import { GroupGeneratedActions } from '@wip/store/actions';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { MeetingGeneratedActions } from '@wip/store/actions';
import { Meeting, MeetingEntityState } from '@api/api-interfaces';
import { MeetingElementGeneratedActions } from '@wip/store/actions';
import { MeetingElement, MeetingElementEntityState } from '@api/api-interfaces';
import { MeetingUserGeneratedActions } from '@wip/store/actions';
import { MeetingUser, MeetingUserEntityState } from '@api/api-interfaces';
import { NotesHistoryGeneratedActions } from '@wip/store/actions';
import { NotesHistory, NotesHistoryEntityState } from '@api/api-interfaces';
import { ProjectModuleGeneratedActions } from '@wip/store/actions';
import { ProjectModule, ProjectModuleEntityState } from '@api/api-interfaces';
import { OrganizationAzureGeneratedActions } from '@wip/store/actions';
import { OrganizationAzure, OrganizationAzureEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicColumn, OrganizationCaracteristicColumnEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicRowGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicRow, OrganizationCaracteristicRowEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicValueGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicValue, OrganizationCaracteristicValueEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { OrganizationStepGeneratedActions } from '@wip/store/actions';
import { OrganizationStep, OrganizationStepEntityState } from '@api/api-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { OrganizationKpiGeneratedActions } from '@wip/store/actions';
import { OrganizationKpi, OrganizationKpiEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneAssociationGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneAssociation, OrganizationMilestoneAssociationEntityState } from '@api/api-interfaces';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumn, OrganizationDataColumnEntityState } from '@api/api-interfaces';
import { OrganizationDataColumnValueGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnValue, OrganizationDataColumnValueEntityState } from '@api/api-interfaces';
import { CommunityDataRowGeneratedActions } from '@wip/store/actions';
import { CommunityDataRow, CommunityDataRowEntityState } from '@api/api-interfaces';
import { OrganizationProjectModuleGeneratedActions } from '@wip/store/actions';
import { OrganizationProjectModule, OrganizationProjectModuleEntityState } from '@api/api-interfaces';
import { OrganizationRiskGeneratedActions } from '@wip/store/actions';
import { OrganizationRisk, OrganizationRiskEntityState } from '@api/api-interfaces';
import { OrganizationUserProfilGeneratedActions } from '@wip/store/actions';
import { OrganizationUserProfil, OrganizationUserProfilEntityState } from '@api/api-interfaces';
import { OrganizationSubFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationSubFamily, OrganizationSubFamilyEntityState } from '@api/api-interfaces';
import { OrganizationSectionGeneratedActions } from '@wip/store/actions';
import { OrganizationSection, OrganizationSectionEntityState } from '@api/api-interfaces';
import { OrganizationUserGeneratedActions } from '@wip/store/actions';
import { OrganizationUser, OrganizationUserEntityState } from '@api/api-interfaces';
import { OrganizationElementGeneratedActions } from '@wip/store/actions';
import { OrganizationElement, OrganizationElementEntityState } from '@api/api-interfaces';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { ProjectElementGeneratedActions } from '@wip/store/actions';
import { ProjectElement, ProjectElementEntityState } from '@api/api-interfaces';
import { RightGeneratedActions } from '@wip/store/actions';
import { Right, RightEntityState } from '@api/api-interfaces';
import { RightProfilGeneratedActions } from '@wip/store/actions';
import { RightProfil, RightProfilEntityState } from '@api/api-interfaces';
import { SectionGeneratedActions } from '@wip/store/actions';
import { Section, SectionEntityState } from '@api/api-interfaces';
import { TimelineElementGeneratedActions } from '@wip/store/actions';
import { TimelineElement, TimelineElementEntityState } from '@api/api-interfaces';
import { TodoGeneratedActions } from '@wip/store/actions';
import { Todo, TodoEntityState } from '@api/api-interfaces';
import { TodoElementGeneratedActions } from '@wip/store/actions';
import { TodoElement, TodoElementEntityState } from '@api/api-interfaces';
import { UserGeneratedActions } from '@wip/store/actions';
import { User, UserEntityState } from '@api/api-interfaces';
import { UserGroupGeneratedActions } from '@wip/store/actions';
import { UserGroup, UserGroupEntityState } from '@api/api-interfaces';
import { UserProfilRightGeneratedActions } from '@wip/store/actions';
import { UserProfilRight, UserProfilRightEntityState } from '@api/api-interfaces';
import { UserRightGeneratedActions } from '@wip/store/actions';
import { UserRight, UserRightEntityState } from '@api/api-interfaces';
import { UserDeviceGeneratedActions } from '@wip/store/actions';
import { UserDeviceEntityState } from '@api/api-interfaces';
import { UserBoardStateGeneratedActions } from '@wip/store/actions';
import { UserBoardState, UserBoardStateEntityState } from '@api/api-interfaces';
import { UserHistoryGeneratedActions } from '@wip/store/actions';
import { UserHistory, UserHistoryEntityState } from '@api/api-interfaces';
import { CommunityUserProfilGeneratedActions } from '@wip/store/actions';
import { CommunityUserProfil, CommunityUserProfilEntityState } from '@api/api-interfaces';
import { ValeurGeneratedActions } from '@wip/store/actions';
import { Valeur, ValeurEntityState } from '@api/api-interfaces';

/**
 *  Community
 */
export function getActionsToNormalizeCommunity(
  communities: CommunityEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const organizationStepsToNormalize: { [id: number]: OrganizationStepEntityState } = {};
  const communityUsersToNormalize: CommunityUserEntityState[] = [];
  const communityUserProfilsToNormalize: CommunityUserProfilEntityState[] = [];
  const communityCaracteristicsToNormalize: CommunityCaracteristicEntityState[] = [];
  const communityKpisToNormalize: CommunityKpiEntityState[] = [];
  const communityDataRowsToNormalize: CommunityDataRowEntityState[] = [];
  const communityGroupsToNormalize: CommunityGroupEntityState[] = [];
  const communityMilestonesToNormalize: CommunityMilestoneEntityState[] = [];
  const communityMilestoneFamilysToNormalize: CommunityMilestoneFamilyEntityState[] = [];
  const communityRisksToNormalize: CommunityRiskEntityState[] = [];
  const communitySubFamilysToNormalize: CommunitySubFamilyEntityState[] = [];
  const communityModulesToNormalize: CommunityModuleEntityState[] = [];
  const projectElementsToNormalize: ProjectElementEntityState[] = [];
  const ganttLinksToNormalize: GanttLinkEntityState[] = [];
  const foldersToNormalize: FolderEntityState[] = [];
  const todosToNormalize: TodoEntityState[] = [];
  const meetingsToNormalize: MeetingEntityState[] = [];
  const timelineElementsToNormalize: TimelineElementEntityState[] = [];
  const notesHistoriesToNormalize: NotesHistoryEntityState[] = [];
  const projectModulesToNormalize: { [id: number]: ProjectModuleEntityState } = {};
  const groupsToNormalize: { [id: number]: GroupEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedCommunities = communities.map((community: CommunityEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    communityNormalizeCommunityUser(community, communityUsersToNormalize, relations);
    communityNormalizeCommunityUserProfil(community, communityUserProfilsToNormalize, relations);
    communityNormalizeCommunityCaracteristic(community, communityCaracteristicsToNormalize, relations);
    communityNormalizeCommunityKpi(community, communityKpisToNormalize, relations);
    communityNormalizeCommunityDataRow(community, communityDataRowsToNormalize, relations);
    communityNormalizeCommunityGroup(community, communityGroupsToNormalize, relations);
    communityNormalizeCommunityMilestone(community, communityMilestonesToNormalize, relations);
    communityNormalizeCommunityMilestoneFamily(community, communityMilestoneFamilysToNormalize, relations);
    communityNormalizeCommunityRisk(community, communityRisksToNormalize, relations);
    communityNormalizeCommunitySubFamily(community, communitySubFamilysToNormalize, relations);
    communityNormalizeCommunityModule(community, communityModulesToNormalize, relations);
    communityNormalizeProjectElement(community, projectElementsToNormalize, relations);
    communityNormalizeGanttLink(community, ganttLinksToNormalize, relations);
    communityNormalizeFolder(community, foldersToNormalize, relations);
    communityNormalizeTodo(community, todosToNormalize, relations);
    communityNormalizeMeeting(community, meetingsToNormalize, relations);
    communityNormalizeTimelineElement(community, timelineElementsToNormalize, relations);
    communityNormalizeNotesHistory(community, notesHistoriesToNormalize, relations);
    communityNormalizeProjectModule(community, projectModulesToNormalize, relations);
    communityNormalizeGroup(community, groupsToNormalize, relations);
    communityNormalizeUser(community, usersToNormalize, relations);
    communityNormalizeOrganization(community, organizationsToNormalize, relations);
    communityNormalizeOrganizationFamily(community, organizationFamilysToNormalize, relations);
    communityNormalizeOrganizationStep(community, organizationStepsToNormalize, relations);

    return {
      ...community,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  const organizationStepsToNormalizeArray = Object.values(organizationStepsToNormalize);
  if (organizationStepsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationStep(organizationStepsToNormalizeArray, actionType));
  }
  if (communityUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityUser(communityUsersToNormalize, actionType));
  }
  if (communityUserProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityUserProfil(communityUserProfilsToNormalize, actionType));
  }
  if (communityCaracteristicsToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityCaracteristic(communityCaracteristicsToNormalize, actionType));
  }
  if (communityKpisToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityKpi(communityKpisToNormalize, actionType));
  }
  if (communityDataRowsToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityDataRow(communityDataRowsToNormalize, actionType));
  }
  if (communityGroupsToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityGroup(communityGroupsToNormalize, actionType));
  }
  if (communityMilestonesToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityMilestone(communityMilestonesToNormalize, actionType));
  }
  if (communityMilestoneFamilysToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityMilestoneFamily(communityMilestoneFamilysToNormalize, actionType));
  }
  if (communityRisksToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityRisk(communityRisksToNormalize, actionType));
  }
  if (communitySubFamilysToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunitySubFamily(communitySubFamilysToNormalize, actionType));
  }
  if (communityModulesToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityModule(communityModulesToNormalize, actionType));
  }
  if (projectElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeProjectElement(projectElementsToNormalize, actionType));
  }
  if (ganttLinksToNormalize.length) {
    actions.push(...getActionsToNormalizeGanttLink(ganttLinksToNormalize, actionType));
  }
  if (foldersToNormalize.length) {
    actions.push(...getActionsToNormalizeFolder(foldersToNormalize, actionType));
  }
  if (todosToNormalize.length) {
    actions.push(...getActionsToNormalizeTodo(todosToNormalize, actionType));
  }
  if (meetingsToNormalize.length) {
    actions.push(...getActionsToNormalizeMeeting(meetingsToNormalize, actionType));
  }
  if (timelineElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeTimelineElement(timelineElementsToNormalize, actionType));
  }
  if (notesHistoriesToNormalize.length) {
    actions.push(...getActionsToNormalizeNotesHistory(notesHistoriesToNormalize, actionType));
  }
  const projectModulesToNormalizeArray = Object.values(projectModulesToNormalize);
  if (projectModulesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProjectModule(projectModulesToNormalizeArray, actionType));
  }
  const groupsToNormalizeArray = Object.values(groupsToNormalize);
  if (groupsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGroup(groupsToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const communityActionCreator = CommunityGeneratedActions.upsertManyCommunitiesSuccess;
  actions.push(communityActionCreator({ communities: normalizedCommunities }));

  return actions;
}

function communityNormalizeCommunityUser(
  community: CommunityEntityState,
  communityUsersToNormalize: CommunityUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityUsers?.length && typeof community.communityUsers[0] !== 'number') {
    const idsCommunityUsers: number[] = [];
    for (let i = 0; i < community.communityUsers.length; i++) {
      const communityUser: CommunityUser | number = community.communityUsers[i];
      if (typeof communityUser !== 'number') {
        communityUsersToNormalize.push({ ...communityUser, community: community.idCommunity });
        idsCommunityUsers.push(communityUser.idCommunityUser);
      } else {
        idsCommunityUsers.push(communityUser);
      }
    }
    relations['communityUsers'] = idsCommunityUsers;
  }
}

function communityNormalizeCommunityUserProfil(
  community: CommunityEntityState,
  communityUserProfilsToNormalize: CommunityUserProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityUserProfils?.length && typeof community.communityUserProfils[0] !== 'number') {
    const idsCommunityUserProfils: number[] = [];
    for (let i = 0; i < community.communityUserProfils.length; i++) {
      const communityUserProfil: CommunityUserProfil | number = community.communityUserProfils[i];
      if (typeof communityUserProfil !== 'number') {
        communityUserProfilsToNormalize.push({ ...communityUserProfil, community: community.idCommunity });
        idsCommunityUserProfils.push(communityUserProfil.idCommunityUserProfil);
      } else {
        idsCommunityUserProfils.push(communityUserProfil);
      }
    }
    relations['communityUserProfils'] = idsCommunityUserProfils;
  }
}

function communityNormalizeCommunityCaracteristic(
  community: CommunityEntityState,
  communityCaracteristicsToNormalize: CommunityCaracteristicEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityCaracteristics?.length && typeof community.communityCaracteristics[0] !== 'number') {
    const idsCommunityCaracteristics: number[] = [];
    for (let i = 0; i < community.communityCaracteristics.length; i++) {
      const communityCaracteristic: CommunityCaracteristic | number = community.communityCaracteristics[i];
      if (typeof communityCaracteristic !== 'number') {
        communityCaracteristicsToNormalize.push({ ...communityCaracteristic, community: community.idCommunity });
        idsCommunityCaracteristics.push(communityCaracteristic.idCommunityCaracteristic);
      } else {
        idsCommunityCaracteristics.push(communityCaracteristic);
      }
    }
    relations['communityCaracteristics'] = idsCommunityCaracteristics;
  }
}

function communityNormalizeCommunityKpi(
  community: CommunityEntityState,
  communityKpisToNormalize: CommunityKpiEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityKpis?.length && typeof community.communityKpis[0] !== 'number') {
    const idsCommunityKpis: number[] = [];
    for (let i = 0; i < community.communityKpis.length; i++) {
      const communityKpi: CommunityKpi | number = community.communityKpis[i];
      if (typeof communityKpi !== 'number') {
        communityKpisToNormalize.push({ ...communityKpi, community: community.idCommunity });
        idsCommunityKpis.push(communityKpi.idCommunityKpi);
      } else {
        idsCommunityKpis.push(communityKpi);
      }
    }
    relations['communityKpis'] = idsCommunityKpis;
  }
}

function communityNormalizeCommunityDataRow(
  community: CommunityEntityState,
  communityDataRowsToNormalize: CommunityDataRowEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityDataRows?.length && typeof community.communityDataRows[0] !== 'number') {
    const idsCommunityDataRows: number[] = [];
    for (let i = 0; i < community.communityDataRows.length; i++) {
      const communityDataRow: CommunityDataRow | number = community.communityDataRows[i];
      if (typeof communityDataRow !== 'number') {
        communityDataRowsToNormalize.push({ ...communityDataRow, community: community.idCommunity });
        idsCommunityDataRows.push(communityDataRow.idCommunityDataRow);
      } else {
        idsCommunityDataRows.push(communityDataRow);
      }
    }
    relations['communityDataRows'] = idsCommunityDataRows;
  }
}

function communityNormalizeCommunityGroup(
  community: CommunityEntityState,
  communityGroupsToNormalize: CommunityGroupEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityGroups?.length && typeof community.communityGroups[0] !== 'number') {
    const idsCommunityGroups: number[] = [];
    for (let i = 0; i < community.communityGroups.length; i++) {
      const communityGroup: CommunityGroup | number = community.communityGroups[i];
      if (typeof communityGroup !== 'number') {
        communityGroupsToNormalize.push({ ...communityGroup, community: community.idCommunity });
        idsCommunityGroups.push(communityGroup.idCommunityGroup);
      } else {
        idsCommunityGroups.push(communityGroup);
      }
    }
    relations['communityGroups'] = idsCommunityGroups;
  }
}

function communityNormalizeCommunityMilestone(
  community: CommunityEntityState,
  communityMilestonesToNormalize: CommunityMilestoneEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityMilestones?.length && typeof community.communityMilestones[0] !== 'number') {
    const idsCommunityMilestones: number[] = [];
    for (let i = 0; i < community.communityMilestones.length; i++) {
      const communityMilestone: CommunityMilestone | number = community.communityMilestones[i];
      if (typeof communityMilestone !== 'number') {
        communityMilestonesToNormalize.push({ ...communityMilestone, community: community.idCommunity });
        idsCommunityMilestones.push(communityMilestone.idCommunityMilestone);
      } else {
        idsCommunityMilestones.push(communityMilestone);
      }
    }
    relations['communityMilestones'] = idsCommunityMilestones;
  }
}

function communityNormalizeCommunityMilestoneFamily(
  community: CommunityEntityState,
  communityMilestoneFamilysToNormalize: CommunityMilestoneFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityMilestoneFamilys?.length && typeof community.communityMilestoneFamilys[0] !== 'number') {
    const idsCommunityMilestoneFamilys: number[] = [];
    for (let i = 0; i < community.communityMilestoneFamilys.length; i++) {
      const communityMilestoneFamily: CommunityMilestoneFamily | number = community.communityMilestoneFamilys[i];
      if (typeof communityMilestoneFamily !== 'number') {
        communityMilestoneFamilysToNormalize.push({ ...communityMilestoneFamily, community: community.idCommunity });
        idsCommunityMilestoneFamilys.push(communityMilestoneFamily.idCommunityMilestoneFamily);
      } else {
        idsCommunityMilestoneFamilys.push(communityMilestoneFamily);
      }
    }
    relations['communityMilestoneFamilys'] = idsCommunityMilestoneFamilys;
  }
}

function communityNormalizeCommunityRisk(
  community: CommunityEntityState,
  communityRisksToNormalize: CommunityRiskEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityRisks?.length && typeof community.communityRisks[0] !== 'number') {
    const idsCommunityRisks: number[] = [];
    for (let i = 0; i < community.communityRisks.length; i++) {
      const communityRisk: CommunityRisk | number = community.communityRisks[i];
      if (typeof communityRisk !== 'number') {
        communityRisksToNormalize.push({ ...communityRisk, community: community.idCommunity });
        idsCommunityRisks.push(communityRisk.idCommunityRisk);
      } else {
        idsCommunityRisks.push(communityRisk);
      }
    }
    relations['communityRisks'] = idsCommunityRisks;
  }
}

function communityNormalizeCommunitySubFamily(
  community: CommunityEntityState,
  communitySubFamilysToNormalize: CommunitySubFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communitySubFamilys?.length && typeof community.communitySubFamilys[0] !== 'number') {
    const idsCommunitySubFamilys: number[] = [];
    for (let i = 0; i < community.communitySubFamilys.length; i++) {
      const communitySubFamily: CommunitySubFamily | number = community.communitySubFamilys[i];
      if (typeof communitySubFamily !== 'number') {
        communitySubFamilysToNormalize.push({ ...communitySubFamily, community: community.idCommunity });
        idsCommunitySubFamilys.push(communitySubFamily.idCommunitySubFamily);
      } else {
        idsCommunitySubFamilys.push(communitySubFamily);
      }
    }
    relations['communitySubFamilys'] = idsCommunitySubFamilys;
  }
}

function communityNormalizeCommunityModule(
  community: CommunityEntityState,
  communityModulesToNormalize: CommunityModuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.communityModules?.length && typeof community.communityModules[0] !== 'number') {
    const idsCommunityModules: number[] = [];
    for (let i = 0; i < community.communityModules.length; i++) {
      const communityModule: CommunityModule | number = community.communityModules[i];
      if (typeof communityModule !== 'number') {
        communityModulesToNormalize.push({ ...communityModule, community: community.idCommunity });
        idsCommunityModules.push(communityModule.idCommunityModule);
      } else {
        idsCommunityModules.push(communityModule);
      }
    }
    relations['communityModules'] = idsCommunityModules;
  }
}

function communityNormalizeProjectElement(
  community: CommunityEntityState,
  projectElementsToNormalize: ProjectElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.projectElements?.length && typeof community.projectElements[0] !== 'number') {
    const idsProjectElements: number[] = [];
    for (let i = 0; i < community.projectElements.length; i++) {
      const projectElement: ProjectElement | number = community.projectElements[i];
      if (typeof projectElement !== 'number') {
        projectElementsToNormalize.push({ ...projectElement, community: community.idCommunity });
        idsProjectElements.push(projectElement.idProjectElement);
      } else {
        idsProjectElements.push(projectElement);
      }
    }
    relations['projectElements'] = idsProjectElements;
  }
}

function communityNormalizeGanttLink(
  community: CommunityEntityState,
  ganttLinksToNormalize: GanttLinkEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.ganttLinks?.length && typeof community.ganttLinks[0] !== 'number') {
    const idsGanttLinks: number[] = [];
    for (let i = 0; i < community.ganttLinks.length; i++) {
      const ganttLink: GanttLink | number = community.ganttLinks[i];
      if (typeof ganttLink !== 'number') {
        ganttLinksToNormalize.push({ ...ganttLink, community: community.idCommunity });
        idsGanttLinks.push(ganttLink.idGanttLink);
      } else {
        idsGanttLinks.push(ganttLink);
      }
    }
    relations['ganttLinks'] = idsGanttLinks;
  }
}

function communityNormalizeFolder(
  community: CommunityEntityState,
  foldersToNormalize: FolderEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.folders?.length && typeof community.folders[0] !== 'number') {
    const idsFolders: number[] = [];
    for (let i = 0; i < community.folders.length; i++) {
      const folder: Folder | number = community.folders[i];
      if (typeof folder !== 'number') {
        foldersToNormalize.push({ ...folder, community: community.idCommunity });
        idsFolders.push(folder.idFolder);
      } else {
        idsFolders.push(folder);
      }
    }
    relations['folders'] = idsFolders;
  }
}

function communityNormalizeTodo(
  community: CommunityEntityState,
  todosToNormalize: TodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.todos?.length && typeof community.todos[0] !== 'number') {
    const idsTodos: number[] = [];
    for (let i = 0; i < community.todos.length; i++) {
      const todo: Todo | number = community.todos[i];
      if (typeof todo !== 'number') {
        todosToNormalize.push({ ...todo, community: community.idCommunity });
        idsTodos.push(todo.idTodo);
      } else {
        idsTodos.push(todo);
      }
    }
    relations['todos'] = idsTodos;
  }
}

function communityNormalizeMeeting(
  community: CommunityEntityState,
  meetingsToNormalize: MeetingEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.meetings?.length && typeof community.meetings[0] !== 'number') {
    const idsMeetings: number[] = [];
    for (let i = 0; i < community.meetings.length; i++) {
      const meeting: Meeting | number = community.meetings[i];
      if (typeof meeting !== 'number') {
        meetingsToNormalize.push({ ...meeting, community: community.idCommunity });
        idsMeetings.push(meeting.idMeeting);
      } else {
        idsMeetings.push(meeting);
      }
    }
    relations['meetings'] = idsMeetings;
  }
}

function communityNormalizeTimelineElement(
  community: CommunityEntityState,
  timelineElementsToNormalize: TimelineElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.timelineElements?.length && typeof community.timelineElements[0] !== 'number') {
    const idsTimelineElements: number[] = [];
    for (let i = 0; i < community.timelineElements.length; i++) {
      const timelineElement: TimelineElement | number = community.timelineElements[i];
      if (typeof timelineElement !== 'number') {
        timelineElementsToNormalize.push({ ...timelineElement, community: community.idCommunity });
        idsTimelineElements.push(timelineElement.idTimelineElement);
      } else {
        idsTimelineElements.push(timelineElement);
      }
    }
    relations['timelineElements'] = idsTimelineElements;
  }
}

function communityNormalizeNotesHistory(
  community: CommunityEntityState,
  notesHistoriesToNormalize: NotesHistoryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (community.notesHistories?.length && typeof community.notesHistories[0] !== 'number') {
    const idsNotesHistories: number[] = [];
    for (let i = 0; i < community.notesHistories.length; i++) {
      const notesHistory: NotesHistory | number = community.notesHistories[i];
      if (typeof notesHistory !== 'number') {
        notesHistoriesToNormalize.push({ ...notesHistory, community: community.idCommunity });
        idsNotesHistories.push(notesHistory.idNotesHistory);
      } else {
        idsNotesHistories.push(notesHistory);
      }
    }
    relations['notesHistories'] = idsNotesHistories;
  }
}

function communityNormalizeProjectModule(
  community: CommunityEntityState,
  projectModulesToNormalize: { [id: number]: ProjectModuleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (community.projectModules?.length && typeof community.projectModules[0] !== 'number') {
    const idsProjectModules: number[] = [];
    for (let i = 0; i < community.projectModules.length; i++) {
      const projectModule: ProjectModule | number = community.projectModules[i];

      if (typeof projectModule !== 'number') {
        if (!projectModulesToNormalize[projectModule.idProjectModule]) {
          projectModulesToNormalize[projectModule.idProjectModule] = {
            ...projectModule,
            communities: projectModule.communities?.concat() ?? []
          };
        }
        (projectModulesToNormalize[projectModule.idProjectModule].communities as number[]).push(community.idCommunity);
        idsProjectModules.push(projectModule.idProjectModule);
      } else {
        idsProjectModules.push(projectModule);
      }
    }
    relations['projectModules'] = idsProjectModules;
  }
}

function communityNormalizeGroup(
  community: CommunityEntityState,
  groupsToNormalize: { [id: number]: GroupEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (community.groups?.length && typeof community.groups[0] !== 'number') {
    const idsGroups: number[] = [];
    for (let i = 0; i < community.groups.length; i++) {
      const group: Group | number = community.groups[i];

      if (typeof group !== 'number') {
        if (!groupsToNormalize[group.idGroup]) {
          groupsToNormalize[group.idGroup] = {
            ...group,
            communities: group.communities?.concat() ?? []
          };
        }
        (groupsToNormalize[group.idGroup].communities as number[]).push(community.idCommunity);
        idsGroups.push(group.idGroup);
      } else {
        idsGroups.push(group);
      }
    }
    relations['groups'] = idsGroups;
  }
}

function communityNormalizeUser(
  community: CommunityEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (community.users?.length && typeof community.users[0] !== 'number') {
    const idsUsers: number[] = [];
    for (let i = 0; i < community.users.length; i++) {
      const user: User | number = community.users[i];

      if (typeof user !== 'number') {
        if (!usersToNormalize[user.idUser]) {
          usersToNormalize[user.idUser] = {
            ...user,
            communities: user.communities?.concat() ?? []
          };
        }
        (usersToNormalize[user.idUser].communities as number[]).push(community.idCommunity);
        idsUsers.push(user.idUser);
      } else {
        idsUsers.push(user);
      }
    }
    relations['users'] = idsUsers;
  }
}

function communityNormalizeOrganization(
  community: CommunityEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (community.organization && typeof community.organization !== 'number') {
    if (!organizationsToNormalize[community.organization.idOrganization]) {
      organizationsToNormalize[community.organization.idOrganization] = {
        ...community.organization,
        communities: community.organization.communities?.concat() ?? []
      };
    }
    (organizationsToNormalize[community.organization.idOrganization].communities as number[]).push(
      community.idCommunity
    );
    relations['organization'] = community.organization.idOrganization;
  }
}

function communityNormalizeOrganizationFamily(
  community: CommunityEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (community.organizationFamily && typeof community.organizationFamily !== 'number') {
    if (!organizationFamilysToNormalize[community.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[community.organizationFamily.idOrganizationFamily] = {
        ...community.organizationFamily,
        communities: community.organizationFamily.communities?.concat() ?? []
      };
    }
    (organizationFamilysToNormalize[community.organizationFamily.idOrganizationFamily].communities as number[]).push(
      community.idCommunity
    );
    relations['organizationFamily'] = community.organizationFamily.idOrganizationFamily;
  }
}

function communityNormalizeOrganizationStep(
  community: CommunityEntityState,
  organizationStepsToNormalize: { [id: number]: OrganizationStepEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (community.organizationStep && typeof community.organizationStep !== 'number') {
    if (!organizationStepsToNormalize[community.organizationStep.idOrganizationStep]) {
      organizationStepsToNormalize[community.organizationStep.idOrganizationStep] = {
        ...community.organizationStep,
        communities: community.organizationStep.communities?.concat() ?? []
      };
    }
    (organizationStepsToNormalize[community.organizationStep.idOrganizationStep].communities as number[]).push(
      community.idCommunity
    );
    relations['organizationStep'] = community.organizationStep.idOrganizationStep;
  }
}

/**
 *  CommunityCaracteristic
 */
export function getActionsToNormalizeCommunityCaracteristic(
  communityCaracteristics: CommunityCaracteristicEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationCaracteristicsToNormalize: { [id: number]: OrganizationCaracteristicEntityState } = {};
  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedCommunityCaracteristics = communityCaracteristics.map(
    (communityCaracteristic: CommunityCaracteristicEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      communityCaracteristicNormalizeOrganizationCaracteristic(
        communityCaracteristic,
        organizationCaracteristicsToNormalize,
        relations
      );
      communityCaracteristicNormalizeCommunity(communityCaracteristic, communitiesToNormalize, relations);

      return {
        ...communityCaracteristic,
        ...relations
      };
    }
  );
  const organizationCaracteristicsToNormalizeArray = Object.values(organizationCaracteristicsToNormalize);
  if (organizationCaracteristicsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristic(organizationCaracteristicsToNormalizeArray, actionType)
    );
  }
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const communityCaracteristicActionCreator =
    CommunityCaracteristicGeneratedActions.upsertManyCommunityCaracteristicsSuccess;
  actions.push(communityCaracteristicActionCreator({ communityCaracteristics: normalizedCommunityCaracteristics }));

  return actions;
}

function communityCaracteristicNormalizeOrganizationCaracteristic(
  communityCaracteristic: CommunityCaracteristicEntityState,
  organizationCaracteristicsToNormalize: { [id: number]: OrganizationCaracteristicEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    communityCaracteristic.organizationCaracteristic &&
    typeof communityCaracteristic.organizationCaracteristic !== 'number'
  ) {
    if (
      !organizationCaracteristicsToNormalize[
        communityCaracteristic.organizationCaracteristic.idOrganizationCaracteristic
      ]
    ) {
      organizationCaracteristicsToNormalize[
        communityCaracteristic.organizationCaracteristic.idOrganizationCaracteristic
      ] = {
        ...communityCaracteristic.organizationCaracteristic,
        communityCaracteristics:
          communityCaracteristic.organizationCaracteristic.communityCaracteristics?.concat() ?? []
      };
    }
    (
      organizationCaracteristicsToNormalize[
        communityCaracteristic.organizationCaracteristic.idOrganizationCaracteristic
      ].communityCaracteristics as number[]
    ).push(communityCaracteristic.idCommunityCaracteristic);
    relations['organizationCaracteristic'] =
      communityCaracteristic.organizationCaracteristic.idOrganizationCaracteristic;
  }
}

function communityCaracteristicNormalizeCommunity(
  communityCaracteristic: CommunityCaracteristicEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityCaracteristic.community && typeof communityCaracteristic.community !== 'number') {
    if (!communitiesToNormalize[communityCaracteristic.community.idCommunity]) {
      communitiesToNormalize[communityCaracteristic.community.idCommunity] = {
        ...communityCaracteristic.community,
        communityCaracteristics: communityCaracteristic.community.communityCaracteristics?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityCaracteristic.community.idCommunity].communityCaracteristics as number[]).push(
      communityCaracteristic.idCommunityCaracteristic
    );
    relations['community'] = communityCaracteristic.community.idCommunity;
  }
}

/**
 *  CommunityKpi
 */
export function getActionsToNormalizeCommunityKpi(
  communityKpis: CommunityKpiEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationKpisToNormalize: { [id: number]: OrganizationKpiEntityState } = {};
  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedCommunityKpis = communityKpis.map((communityKpi: CommunityKpiEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    communityKpiNormalizeOrganizationKpi(communityKpi, organizationKpisToNormalize, relations);
    communityKpiNormalizeCommunity(communityKpi, communitiesToNormalize, relations);

    return {
      ...communityKpi,
      ...relations
    };
  });
  const organizationKpisToNormalizeArray = Object.values(organizationKpisToNormalize);
  if (organizationKpisToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationKpi(organizationKpisToNormalizeArray, actionType));
  }
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const communityKpiActionCreator = CommunityKpiGeneratedActions.upsertManyCommunityKpisSuccess;
  actions.push(communityKpiActionCreator({ communityKpis: normalizedCommunityKpis }));

  return actions;
}

function communityKpiNormalizeOrganizationKpi(
  communityKpi: CommunityKpiEntityState,
  organizationKpisToNormalize: { [id: number]: OrganizationKpiEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityKpi.organizationKpi && typeof communityKpi.organizationKpi !== 'number') {
    if (!organizationKpisToNormalize[communityKpi.organizationKpi.idOrganizationKpi]) {
      organizationKpisToNormalize[communityKpi.organizationKpi.idOrganizationKpi] = {
        ...communityKpi.organizationKpi,
        communityKpis: communityKpi.organizationKpi.communityKpis?.concat() ?? []
      };
    }
    (organizationKpisToNormalize[communityKpi.organizationKpi.idOrganizationKpi].communityKpis as number[]).push(
      communityKpi.idCommunityKpi
    );
    relations['organizationKpi'] = communityKpi.organizationKpi.idOrganizationKpi;
  }
}

function communityKpiNormalizeCommunity(
  communityKpi: CommunityKpiEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityKpi.community && typeof communityKpi.community !== 'number') {
    if (!communitiesToNormalize[communityKpi.community.idCommunity]) {
      communitiesToNormalize[communityKpi.community.idCommunity] = {
        ...communityKpi.community,
        communityKpis: communityKpi.community.communityKpis?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityKpi.community.idCommunity].communityKpis as number[]).push(
      communityKpi.idCommunityKpi
    );
    relations['community'] = communityKpi.community.idCommunity;
  }
}

/**
 *  ChildrenElement
 */
export function getActionsToNormalizeChildrenElement(
  childrenElements: ChildrenElementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const elementsToNormalize: { [id: number]: ElementEntityState } = {};

  const normalizedChildrenElements = childrenElements.map((childrenElement: ChildrenElementEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    childrenElementNormalizeElement(childrenElement, elementsToNormalize, relations);

    return {
      ...childrenElement,
      ...relations
    };
  });
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }

  const childrenElementActionCreator = ChildrenElementGeneratedActions.upsertManyChildrenElementsSuccess;
  actions.push(childrenElementActionCreator({ childrenElements: normalizedChildrenElements }));

  return actions;
}

function childrenElementNormalizeElement(
  childrenElement: ChildrenElementEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (childrenElement.element && typeof childrenElement.element !== 'number') {
    if (!elementsToNormalize[childrenElement.element.idElement]) {
      elementsToNormalize[childrenElement.element.idElement] = {
        ...childrenElement.element,
        childrenElements: childrenElement.element.childrenElements?.concat() ?? []
      };
    }
    (elementsToNormalize[childrenElement.element.idElement].childrenElements as number[]).push(
      childrenElement.idChildrenElement
    );
    relations['element'] = childrenElement.element.idElement;
  }
}

/**
 *  CommunityMilestone
 */
export function getActionsToNormalizeCommunityMilestone(
  communityMilestones: CommunityMilestoneEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationMilestonesToNormalize: { [id: number]: OrganizationMilestoneEntityState } = {};
  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedCommunityMilestones = communityMilestones.map((communityMilestone: CommunityMilestoneEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    communityMilestoneNormalizeOrganizationMilestone(communityMilestone, organizationMilestonesToNormalize, relations);
    communityMilestoneNormalizeCommunity(communityMilestone, communitiesToNormalize, relations);

    return {
      ...communityMilestone,
      ...relations
    };
  });
  const organizationMilestonesToNormalizeArray = Object.values(organizationMilestonesToNormalize);
  if (organizationMilestonesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationMilestone(organizationMilestonesToNormalizeArray, actionType));
  }
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const communityMilestoneActionCreator = CommunityMilestoneGeneratedActions.upsertManyCommunityMilestonesSuccess;
  actions.push(communityMilestoneActionCreator({ communityMilestones: normalizedCommunityMilestones }));

  return actions;
}

function communityMilestoneNormalizeOrganizationMilestone(
  communityMilestone: CommunityMilestoneEntityState,
  organizationMilestonesToNormalize: { [id: number]: OrganizationMilestoneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityMilestone.organizationMilestone && typeof communityMilestone.organizationMilestone !== 'number') {
    if (!organizationMilestonesToNormalize[communityMilestone.organizationMilestone.idOrganizationMilestone]) {
      organizationMilestonesToNormalize[communityMilestone.organizationMilestone.idOrganizationMilestone] = {
        ...communityMilestone.organizationMilestone,
        communityMilestones: communityMilestone.organizationMilestone.communityMilestones?.concat() ?? []
      };
    }
    (
      organizationMilestonesToNormalize[communityMilestone.organizationMilestone.idOrganizationMilestone]
        .communityMilestones as number[]
    ).push(communityMilestone.idCommunityMilestone);
    relations['organizationMilestone'] = communityMilestone.organizationMilestone.idOrganizationMilestone;
  }
}

function communityMilestoneNormalizeCommunity(
  communityMilestone: CommunityMilestoneEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityMilestone.community && typeof communityMilestone.community !== 'number') {
    if (!communitiesToNormalize[communityMilestone.community.idCommunity]) {
      communitiesToNormalize[communityMilestone.community.idCommunity] = {
        ...communityMilestone.community,
        communityMilestones: communityMilestone.community.communityMilestones?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityMilestone.community.idCommunity].communityMilestones as number[]).push(
      communityMilestone.idCommunityMilestone
    );
    relations['community'] = communityMilestone.community.idCommunity;
  }
}

/**
 *  CommunityMilestoneFamily
 */
export function getActionsToNormalizeCommunityMilestoneFamily(
  communityMilestoneFamilys: CommunityMilestoneFamilyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationMilestoneFamilysToNormalize: { [id: number]: OrganizationMilestoneFamilyEntityState } = {};
  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedCommunityMilestoneFamilys = communityMilestoneFamilys.map(
    (communityMilestoneFamily: CommunityMilestoneFamilyEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      communityMilestoneFamilyNormalizeOrganizationMilestoneFamily(
        communityMilestoneFamily,
        organizationMilestoneFamilysToNormalize,
        relations
      );
      communityMilestoneFamilyNormalizeCommunity(communityMilestoneFamily, communitiesToNormalize, relations);

      return {
        ...communityMilestoneFamily,
        ...relations
      };
    }
  );
  const organizationMilestoneFamilysToNormalizeArray = Object.values(organizationMilestoneFamilysToNormalize);
  if (organizationMilestoneFamilysToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationMilestoneFamily(organizationMilestoneFamilysToNormalizeArray, actionType)
    );
  }
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const communityMilestoneFamilyActionCreator =
    CommunityMilestoneFamilyGeneratedActions.upsertManyCommunityMilestoneFamilysSuccess;
  actions.push(
    communityMilestoneFamilyActionCreator({ communityMilestoneFamilys: normalizedCommunityMilestoneFamilys })
  );

  return actions;
}

function communityMilestoneFamilyNormalizeOrganizationMilestoneFamily(
  communityMilestoneFamily: CommunityMilestoneFamilyEntityState,
  organizationMilestoneFamilysToNormalize: { [id: number]: OrganizationMilestoneFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    communityMilestoneFamily.organizationMilestoneFamily &&
    typeof communityMilestoneFamily.organizationMilestoneFamily !== 'number'
  ) {
    if (
      !organizationMilestoneFamilysToNormalize[
        communityMilestoneFamily.organizationMilestoneFamily.idOrganizationMilestoneFamily
      ]
    ) {
      organizationMilestoneFamilysToNormalize[
        communityMilestoneFamily.organizationMilestoneFamily.idOrganizationMilestoneFamily
      ] = {
        ...communityMilestoneFamily.organizationMilestoneFamily,
        communityMilestoneFamilys:
          communityMilestoneFamily.organizationMilestoneFamily.communityMilestoneFamilys?.concat() ?? []
      };
    }
    (
      organizationMilestoneFamilysToNormalize[
        communityMilestoneFamily.organizationMilestoneFamily.idOrganizationMilestoneFamily
      ].communityMilestoneFamilys as number[]
    ).push(communityMilestoneFamily.idCommunityMilestoneFamily);
    relations['organizationMilestoneFamily'] =
      communityMilestoneFamily.organizationMilestoneFamily.idOrganizationMilestoneFamily;
  }
}

function communityMilestoneFamilyNormalizeCommunity(
  communityMilestoneFamily: CommunityMilestoneFamilyEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityMilestoneFamily.community && typeof communityMilestoneFamily.community !== 'number') {
    if (!communitiesToNormalize[communityMilestoneFamily.community.idCommunity]) {
      communitiesToNormalize[communityMilestoneFamily.community.idCommunity] = {
        ...communityMilestoneFamily.community,
        communityMilestoneFamilys: communityMilestoneFamily.community.communityMilestoneFamilys?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityMilestoneFamily.community.idCommunity].communityMilestoneFamilys as number[]).push(
      communityMilestoneFamily.idCommunityMilestoneFamily
    );
    relations['community'] = communityMilestoneFamily.community.idCommunity;
  }
}

/**
 *  CommunityRisk
 */
export function getActionsToNormalizeCommunityRisk(
  communityRisks: CommunityRiskEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationRisksToNormalize: { [id: number]: OrganizationRiskEntityState } = {};
  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedCommunityRisks = communityRisks.map((communityRisk: CommunityRiskEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    communityRiskNormalizeOrganizationRisk(communityRisk, organizationRisksToNormalize, relations);
    communityRiskNormalizeCommunity(communityRisk, communitiesToNormalize, relations);

    return {
      ...communityRisk,
      ...relations
    };
  });
  const organizationRisksToNormalizeArray = Object.values(organizationRisksToNormalize);
  if (organizationRisksToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationRisk(organizationRisksToNormalizeArray, actionType));
  }
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const communityRiskActionCreator = CommunityRiskGeneratedActions.upsertManyCommunityRisksSuccess;
  actions.push(communityRiskActionCreator({ communityRisks: normalizedCommunityRisks }));

  return actions;
}

function communityRiskNormalizeOrganizationRisk(
  communityRisk: CommunityRiskEntityState,
  organizationRisksToNormalize: { [id: number]: OrganizationRiskEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityRisk.organizationRisk && typeof communityRisk.organizationRisk !== 'number') {
    if (!organizationRisksToNormalize[communityRisk.organizationRisk.idOrganizationRisk]) {
      organizationRisksToNormalize[communityRisk.organizationRisk.idOrganizationRisk] = {
        ...communityRisk.organizationRisk,
        communityRisks: communityRisk.organizationRisk.communityRisks?.concat() ?? []
      };
    }
    (organizationRisksToNormalize[communityRisk.organizationRisk.idOrganizationRisk].communityRisks as number[]).push(
      communityRisk.idCommunityRisk
    );
    relations['organizationRisk'] = communityRisk.organizationRisk.idOrganizationRisk;
  }
}

function communityRiskNormalizeCommunity(
  communityRisk: CommunityRiskEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityRisk.community && typeof communityRisk.community !== 'number') {
    if (!communitiesToNormalize[communityRisk.community.idCommunity]) {
      communitiesToNormalize[communityRisk.community.idCommunity] = {
        ...communityRisk.community,
        communityRisks: communityRisk.community.communityRisks?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityRisk.community.idCommunity].communityRisks as number[]).push(
      communityRisk.idCommunityRisk
    );
    relations['community'] = communityRisk.community.idCommunity;
  }
}

/**
 *  CommunitySubFamily
 */
export function getActionsToNormalizeCommunitySubFamily(
  communitySubFamilys: CommunitySubFamilyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationSubFamilysToNormalize: { [id: number]: OrganizationSubFamilyEntityState } = {};
  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedCommunitySubFamilys = communitySubFamilys.map((communitySubFamily: CommunitySubFamilyEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    communitySubFamilyNormalizeOrganizationSubFamily(communitySubFamily, organizationSubFamilysToNormalize, relations);
    communitySubFamilyNormalizeCommunity(communitySubFamily, communitiesToNormalize, relations);

    return {
      ...communitySubFamily,
      ...relations
    };
  });
  const organizationSubFamilysToNormalizeArray = Object.values(organizationSubFamilysToNormalize);
  if (organizationSubFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationSubFamily(organizationSubFamilysToNormalizeArray, actionType));
  }
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const communitySubFamilyActionCreator = CommunitySubFamilyGeneratedActions.upsertManyCommunitySubFamilysSuccess;
  actions.push(communitySubFamilyActionCreator({ communitySubFamilys: normalizedCommunitySubFamilys }));

  return actions;
}

function communitySubFamilyNormalizeOrganizationSubFamily(
  communitySubFamily: CommunitySubFamilyEntityState,
  organizationSubFamilysToNormalize: { [id: number]: OrganizationSubFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communitySubFamily.organizationSubFamily && typeof communitySubFamily.organizationSubFamily !== 'number') {
    if (!organizationSubFamilysToNormalize[communitySubFamily.organizationSubFamily.idOrganizationSubFamily]) {
      organizationSubFamilysToNormalize[communitySubFamily.organizationSubFamily.idOrganizationSubFamily] = {
        ...communitySubFamily.organizationSubFamily,
        communitySubFamilys: communitySubFamily.organizationSubFamily.communitySubFamilys?.concat() ?? []
      };
    }
    (
      organizationSubFamilysToNormalize[communitySubFamily.organizationSubFamily.idOrganizationSubFamily]
        .communitySubFamilys as number[]
    ).push(communitySubFamily.idCommunitySubFamily);
    relations['organizationSubFamily'] = communitySubFamily.organizationSubFamily.idOrganizationSubFamily;
  }
}

function communitySubFamilyNormalizeCommunity(
  communitySubFamily: CommunitySubFamilyEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communitySubFamily.community && typeof communitySubFamily.community !== 'number') {
    if (!communitiesToNormalize[communitySubFamily.community.idCommunity]) {
      communitiesToNormalize[communitySubFamily.community.idCommunity] = {
        ...communitySubFamily.community,
        communitySubFamilys: communitySubFamily.community.communitySubFamilys?.concat() ?? []
      };
    }
    (communitiesToNormalize[communitySubFamily.community.idCommunity].communitySubFamilys as number[]).push(
      communitySubFamily.idCommunitySubFamily
    );
    relations['community'] = communitySubFamily.community.idCommunity;
  }
}

/**
 *  CommunityGroup
 */
export function getActionsToNormalizeCommunityGroup(
  communityGroups: CommunityGroupEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const groupsToNormalize: { [id: number]: GroupEntityState } = {};

  const normalizedCommunityGroups = communityGroups.map((communityGroup: CommunityGroupEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    communityGroupNormalizeCommunity(communityGroup, communitiesToNormalize, relations);
    communityGroupNormalizeGroup(communityGroup, groupsToNormalize, relations);

    return {
      ...communityGroup,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  const groupsToNormalizeArray = Object.values(groupsToNormalize);
  if (groupsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGroup(groupsToNormalizeArray, actionType));
  }

  const communityGroupActionCreator = CommunityGroupGeneratedActions.upsertManyCommunityGroupsSuccess;
  actions.push(communityGroupActionCreator({ communityGroups: normalizedCommunityGroups }));

  return actions;
}

function communityGroupNormalizeCommunity(
  communityGroup: CommunityGroupEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityGroup.community && typeof communityGroup.community !== 'number') {
    if (!communitiesToNormalize[communityGroup.community.idCommunity]) {
      communitiesToNormalize[communityGroup.community.idCommunity] = {
        ...communityGroup.community,
        communityGroups: communityGroup.community.communityGroups?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityGroup.community.idCommunity].communityGroups as number[]).push(
      communityGroup.idCommunityGroup
    );
    relations['community'] = communityGroup.community.idCommunity;
  }
}

function communityGroupNormalizeGroup(
  communityGroup: CommunityGroupEntityState,
  groupsToNormalize: { [id: number]: GroupEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityGroup.group && typeof communityGroup.group !== 'number') {
    if (!groupsToNormalize[communityGroup.group.idGroup]) {
      groupsToNormalize[communityGroup.group.idGroup] = {
        ...communityGroup.group,
        communityGroups: communityGroup.group.communityGroups?.concat() ?? []
      };
    }
    (groupsToNormalize[communityGroup.group.idGroup].communityGroups as number[]).push(communityGroup.idCommunityGroup);
    relations['group'] = communityGroup.group.idGroup;
  }
}

/**
 *  CommunityModule
 */
export function getActionsToNormalizeCommunityModule(
  communityModules: CommunityModuleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const projectModulesToNormalize: { [id: number]: ProjectModuleEntityState } = {};

  const normalizedCommunityModules = communityModules.map((communityModule: CommunityModuleEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    communityModuleNormalizeCommunity(communityModule, communitiesToNormalize, relations);
    communityModuleNormalizeProjectModule(communityModule, projectModulesToNormalize, relations);

    return {
      ...communityModule,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  const projectModulesToNormalizeArray = Object.values(projectModulesToNormalize);
  if (projectModulesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProjectModule(projectModulesToNormalizeArray, actionType));
  }

  const communityModuleActionCreator = CommunityModuleGeneratedActions.upsertManyCommunityModulesSuccess;
  actions.push(communityModuleActionCreator({ communityModules: normalizedCommunityModules }));

  return actions;
}

function communityModuleNormalizeCommunity(
  communityModule: CommunityModuleEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityModule.community && typeof communityModule.community !== 'number') {
    if (!communitiesToNormalize[communityModule.community.idCommunity]) {
      communitiesToNormalize[communityModule.community.idCommunity] = {
        ...communityModule.community,
        communityModules: communityModule.community.communityModules?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityModule.community.idCommunity].communityModules as number[]).push(
      communityModule.idCommunityModule
    );
    relations['community'] = communityModule.community.idCommunity;
  }
}

function communityModuleNormalizeProjectModule(
  communityModule: CommunityModuleEntityState,
  projectModulesToNormalize: { [id: number]: ProjectModuleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityModule.projectModule && typeof communityModule.projectModule !== 'number') {
    if (!projectModulesToNormalize[communityModule.projectModule.idProjectModule]) {
      projectModulesToNormalize[communityModule.projectModule.idProjectModule] = {
        ...communityModule.projectModule,
        communityModules: communityModule.projectModule.communityModules?.concat() ?? []
      };
    }
    (projectModulesToNormalize[communityModule.projectModule.idProjectModule].communityModules as number[]).push(
      communityModule.idCommunityModule
    );
    relations['projectModule'] = communityModule.projectModule.idProjectModule;
  }
}

/**
 *  CommunityUser
 */
export function getActionsToNormalizeCommunityUser(
  communityUsers: CommunityUserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedCommunityUsers = communityUsers.map((communityUser: CommunityUserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    communityUserNormalizeCommunity(communityUser, communitiesToNormalize, relations);
    communityUserNormalizeUser(communityUser, usersToNormalize, relations);

    return {
      ...communityUser,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const communityUserActionCreator = CommunityUserGeneratedActions.upsertManyCommunityUsersSuccess;
  actions.push(communityUserActionCreator({ communityUsers: normalizedCommunityUsers }));

  return actions;
}

function communityUserNormalizeCommunity(
  communityUser: CommunityUserEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityUser.community && typeof communityUser.community !== 'number') {
    if (!communitiesToNormalize[communityUser.community.idCommunity]) {
      communitiesToNormalize[communityUser.community.idCommunity] = {
        ...communityUser.community,
        communityUsers: communityUser.community.communityUsers?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityUser.community.idCommunity].communityUsers as number[]).push(
      communityUser.idCommunityUser
    );
    relations['community'] = communityUser.community.idCommunity;
  }
}

function communityUserNormalizeUser(
  communityUser: CommunityUserEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityUser.user && typeof communityUser.user !== 'number') {
    if (!usersToNormalize[communityUser.user.idUser]) {
      usersToNormalize[communityUser.user.idUser] = {
        ...communityUser.user,
        communityUsers: communityUser.user.communityUsers?.concat() ?? []
      };
    }
    (usersToNormalize[communityUser.user.idUser].communityUsers as number[]).push(communityUser.idCommunityUser);
    relations['user'] = communityUser.user.idUser;
  }
}

/**
 *  Folder
 */
export function getActionsToNormalizeFolder(folders: FolderEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const folderElementsToNormalize: FolderElementEntityState[] = [];

  const normalizedFolders = folders.map((folder: FolderEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    folderNormalizeFolderElement(folder, folderElementsToNormalize, relations);
    folderNormalizeCommunity(folder, communitiesToNormalize, relations);

    return {
      ...folder,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  if (folderElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeFolderElement(folderElementsToNormalize, actionType));
  }

  const folderActionCreator = FolderGeneratedActions.upsertManyFoldersSuccess;
  actions.push(folderActionCreator({ folders: normalizedFolders }));

  return actions;
}

function folderNormalizeFolderElement(
  folder: FolderEntityState,
  folderElementsToNormalize: FolderElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (folder.folderElements?.length && typeof folder.folderElements[0] !== 'number') {
    const idsFolderElements: number[] = [];
    for (let i = 0; i < folder.folderElements.length; i++) {
      const folderElement: FolderElement | number = folder.folderElements[i];
      if (typeof folderElement !== 'number') {
        folderElementsToNormalize.push({ ...folderElement, folder: folder.idFolder });
        idsFolderElements.push(folderElement.idFolderElement);
      } else {
        idsFolderElements.push(folderElement);
      }
    }
    relations['folderElements'] = idsFolderElements;
  }
}

function folderNormalizeCommunity(
  folder: FolderEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (folder.community && typeof folder.community !== 'number') {
    if (!communitiesToNormalize[folder.community.idCommunity]) {
      communitiesToNormalize[folder.community.idCommunity] = {
        ...folder.community,
        folders: folder.community.folders?.concat() ?? []
      };
    }
    (communitiesToNormalize[folder.community.idCommunity].folders as number[]).push(folder.idFolder);
    relations['community'] = folder.community.idCommunity;
  }
}

/**
 *  FolderElement
 */
export function getActionsToNormalizeFolderElement(
  folderElements: FolderElementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const foldersToNormalize: { [id: number]: FolderEntityState } = {};
  const elementsToNormalize: { [id: number]: ElementEntityState } = {};

  const normalizedFolderElements = folderElements.map((folderElement: FolderElementEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    folderElementNormalizeFolder(folderElement, foldersToNormalize, relations);
    folderElementNormalizeElement(folderElement, elementsToNormalize, relations);

    return {
      ...folderElement,
      ...relations
    };
  });
  const foldersToNormalizeArray = Object.values(foldersToNormalize);
  if (foldersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFolder(foldersToNormalizeArray, actionType));
  }
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }

  const folderElementActionCreator = FolderElementGeneratedActions.upsertManyFolderElementsSuccess;
  actions.push(folderElementActionCreator({ folderElements: normalizedFolderElements }));

  return actions;
}

function folderElementNormalizeFolder(
  folderElement: FolderElementEntityState,
  foldersToNormalize: { [id: number]: FolderEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (folderElement.folder && typeof folderElement.folder !== 'number') {
    if (!foldersToNormalize[folderElement.folder.idFolder]) {
      foldersToNormalize[folderElement.folder.idFolder] = {
        ...folderElement.folder,
        folderElements: folderElement.folder.folderElements?.concat() ?? []
      };
    }
    (foldersToNormalize[folderElement.folder.idFolder].folderElements as number[]).push(folderElement.idFolderElement);
    relations['folder'] = folderElement.folder.idFolder;
  }
}

function folderElementNormalizeElement(
  folderElement: FolderElementEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (folderElement.element && typeof folderElement.element !== 'number') {
    if (!elementsToNormalize[folderElement.element.idElement]) {
      elementsToNormalize[folderElement.element.idElement] = {
        ...folderElement.element,
        folderElements: folderElement.element.folderElements?.concat() ?? []
      };
    }
    (elementsToNormalize[folderElement.element.idElement].folderElements as number[]).push(
      folderElement.idFolderElement
    );
    relations['element'] = folderElement.element.idElement;
  }
}

/**
 *  Element
 */
export function getActionsToNormalizeElement(elements: ElementEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const organizationMilestonesToNormalize: { [id: number]: OrganizationMilestoneEntityState } = {};
  const todoElementsToNormalize: TodoElementEntityState[] = [];
  const elementCashsToNormalize: ElementCashEntityState[] = [];
  const meetingElementsToNormalize: MeetingElementEntityState[] = [];
  const timelineElementsToNormalize: TimelineElementEntityState[] = [];
  const folderElementsToNormalize: FolderElementEntityState[] = [];
  const projectElementsToNormalize: ProjectElementEntityState[] = [];
  const organizationElementsToNormalize: OrganizationElementEntityState[] = [];
  const childrenElementsToNormalize: ChildrenElementEntityState[] = [];
  const elementLibrariesToNormalize: ElementLibraryEntityState[] = [];
  const todosToNormalize: { [id: number]: TodoEntityState } = {};

  const normalizedElements = elements.map((element: ElementEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    elementNormalizeTodoElement(element, todoElementsToNormalize, relations);
    elementNormalizeElementCash(element, elementCashsToNormalize, relations);
    elementNormalizeMeetingElement(element, meetingElementsToNormalize, relations);
    elementNormalizeTimelineElement(element, timelineElementsToNormalize, relations);
    elementNormalizeFolderElement(element, folderElementsToNormalize, relations);
    elementNormalizeProjectElement(element, projectElementsToNormalize, relations);
    elementNormalizeOrganizationElement(element, organizationElementsToNormalize, relations);
    elementNormalizeChildrenElement(element, childrenElementsToNormalize, relations);
    elementNormalizeElementLibrary(element, elementLibrariesToNormalize, relations);
    elementNormalizeTodo(element, todosToNormalize, relations);
    elementNormalizeOrganizationMilestone(element, organizationMilestonesToNormalize, relations);

    return {
      ...element,
      ...relations
    };
  });
  const organizationMilestonesToNormalizeArray = Object.values(organizationMilestonesToNormalize);
  if (organizationMilestonesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationMilestone(organizationMilestonesToNormalizeArray, actionType));
  }
  if (todoElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeTodoElement(todoElementsToNormalize, actionType));
  }
  if (elementCashsToNormalize.length) {
    actions.push(...getActionsToNormalizeElementCash(elementCashsToNormalize, actionType));
  }
  if (meetingElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeMeetingElement(meetingElementsToNormalize, actionType));
  }
  if (timelineElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeTimelineElement(timelineElementsToNormalize, actionType));
  }
  if (folderElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeFolderElement(folderElementsToNormalize, actionType));
  }
  if (projectElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeProjectElement(projectElementsToNormalize, actionType));
  }
  if (organizationElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationElement(organizationElementsToNormalize, actionType));
  }
  if (childrenElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeChildrenElement(childrenElementsToNormalize, actionType));
  }
  if (elementLibrariesToNormalize.length) {
    actions.push(...getActionsToNormalizeElementLibrary(elementLibrariesToNormalize, actionType));
  }
  const todosToNormalizeArray = Object.values(todosToNormalize);
  if (todosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeTodo(todosToNormalizeArray, actionType));
  }

  const elementActionCreator = ElementGeneratedActions.upsertManyElementsSuccess;
  actions.push(elementActionCreator({ elements: normalizedElements }));

  return actions;
}

function elementNormalizeTodoElement(
  element: ElementEntityState,
  todoElementsToNormalize: TodoElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (element.todoElements?.length && typeof element.todoElements[0] !== 'number') {
    const idsTodoElements: number[] = [];
    for (let i = 0; i < element.todoElements.length; i++) {
      const todoElement: TodoElement | number = element.todoElements[i];
      if (typeof todoElement !== 'number') {
        todoElementsToNormalize.push({ ...todoElement, element: element.idElement });
        idsTodoElements.push(todoElement.idTodoElement);
      } else {
        idsTodoElements.push(todoElement);
      }
    }
    relations['todoElements'] = idsTodoElements;
  }
}

function elementNormalizeElementCash(
  element: ElementEntityState,
  elementCashsToNormalize: ElementCashEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (element.elementCashs?.length && typeof element.elementCashs[0] !== 'number') {
    const idsElementCashs: number[] = [];
    for (let i = 0; i < element.elementCashs.length; i++) {
      const elementCash: ElementCash | number = element.elementCashs[i];
      if (typeof elementCash !== 'number') {
        elementCashsToNormalize.push({ ...elementCash, element: element.idElement });
        idsElementCashs.push(elementCash.idElementCash);
      } else {
        idsElementCashs.push(elementCash);
      }
    }
    relations['elementCashs'] = idsElementCashs;
  }
}

function elementNormalizeMeetingElement(
  element: ElementEntityState,
  meetingElementsToNormalize: MeetingElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (element.meetingElements?.length && typeof element.meetingElements[0] !== 'number') {
    const idsMeetingElements: number[] = [];
    for (let i = 0; i < element.meetingElements.length; i++) {
      const meetingElement: MeetingElement | number = element.meetingElements[i];
      if (typeof meetingElement !== 'number') {
        meetingElementsToNormalize.push({ ...meetingElement, element: element.idElement });
        idsMeetingElements.push(meetingElement.idMeetingElement);
      } else {
        idsMeetingElements.push(meetingElement);
      }
    }
    relations['meetingElements'] = idsMeetingElements;
  }
}

function elementNormalizeTimelineElement(
  element: ElementEntityState,
  timelineElementsToNormalize: TimelineElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (element.timelineElements?.length && typeof element.timelineElements[0] !== 'number') {
    const idsTimelineElements: number[] = [];
    for (let i = 0; i < element.timelineElements.length; i++) {
      const timelineElement: TimelineElement | number = element.timelineElements[i];
      if (typeof timelineElement !== 'number') {
        timelineElementsToNormalize.push({ ...timelineElement, element: element.idElement });
        idsTimelineElements.push(timelineElement.idTimelineElement);
      } else {
        idsTimelineElements.push(timelineElement);
      }
    }
    relations['timelineElements'] = idsTimelineElements;
  }
}

function elementNormalizeFolderElement(
  element: ElementEntityState,
  folderElementsToNormalize: FolderElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (element.folderElements?.length && typeof element.folderElements[0] !== 'number') {
    const idsFolderElements: number[] = [];
    for (let i = 0; i < element.folderElements.length; i++) {
      const folderElement: FolderElement | number = element.folderElements[i];
      if (typeof folderElement !== 'number') {
        folderElementsToNormalize.push({ ...folderElement, element: element.idElement });
        idsFolderElements.push(folderElement.idFolderElement);
      } else {
        idsFolderElements.push(folderElement);
      }
    }
    relations['folderElements'] = idsFolderElements;
  }
}

function elementNormalizeProjectElement(
  element: ElementEntityState,
  projectElementsToNormalize: ProjectElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (element.projectElements?.length && typeof element.projectElements[0] !== 'number') {
    const idsProjectElements: number[] = [];
    for (let i = 0; i < element.projectElements.length; i++) {
      const projectElement: ProjectElement | number = element.projectElements[i];
      if (typeof projectElement !== 'number') {
        projectElementsToNormalize.push({ ...projectElement, element: element.idElement });
        idsProjectElements.push(projectElement.idProjectElement);
      } else {
        idsProjectElements.push(projectElement);
      }
    }
    relations['projectElements'] = idsProjectElements;
  }
}

function elementNormalizeOrganizationElement(
  element: ElementEntityState,
  organizationElementsToNormalize: OrganizationElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (element.organizationElements?.length && typeof element.organizationElements[0] !== 'number') {
    const idsOrganizationElements: number[] = [];
    for (let i = 0; i < element.organizationElements.length; i++) {
      const organizationElement: OrganizationElement | number = element.organizationElements[i];
      if (typeof organizationElement !== 'number') {
        organizationElementsToNormalize.push({ ...organizationElement, element: element.idElement });
        idsOrganizationElements.push(organizationElement.idOrganizationElement);
      } else {
        idsOrganizationElements.push(organizationElement);
      }
    }
    relations['organizationElements'] = idsOrganizationElements;
  }
}

function elementNormalizeChildrenElement(
  element: ElementEntityState,
  childrenElementsToNormalize: ChildrenElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (element.childrenElements?.length && typeof element.childrenElements[0] !== 'number') {
    const idsChildrenElements: number[] = [];
    for (let i = 0; i < element.childrenElements.length; i++) {
      const childrenElement: ChildrenElement | number = element.childrenElements[i];
      if (typeof childrenElement !== 'number') {
        childrenElementsToNormalize.push({ ...childrenElement, element: element.idElement });
        idsChildrenElements.push(childrenElement.idChildrenElement);
      } else {
        idsChildrenElements.push(childrenElement);
      }
    }
    relations['childrenElements'] = idsChildrenElements;
  }
}

function elementNormalizeElementLibrary(
  element: ElementEntityState,
  elementLibrariesToNormalize: ElementLibraryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (element.elementLibraries?.length && typeof element.elementLibraries[0] !== 'number') {
    const idsElementLibraries: number[] = [];
    for (let i = 0; i < element.elementLibraries.length; i++) {
      const elementLibrary: ElementLibrary | number = element.elementLibraries[i];
      if (typeof elementLibrary !== 'number') {
        elementLibrariesToNormalize.push({ ...elementLibrary, element: element.idElement });
        idsElementLibraries.push(elementLibrary.idElementLibrary);
      } else {
        idsElementLibraries.push(elementLibrary);
      }
    }
    relations['elementLibraries'] = idsElementLibraries;
  }
}

function elementNormalizeTodo(
  element: ElementEntityState,
  todosToNormalize: { [id: number]: TodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (element.todos?.length && typeof element.todos[0] !== 'number') {
    const idsTodos: number[] = [];
    for (let i = 0; i < element.todos.length; i++) {
      const todo: Todo | number = element.todos[i];

      if (typeof todo !== 'number') {
        if (!todosToNormalize[todo.idTodo]) {
          todosToNormalize[todo.idTodo] = {
            ...todo,
            elements: todo.elements?.concat() ?? []
          };
        }
        (todosToNormalize[todo.idTodo].elements as number[]).push(element.idElement);
        idsTodos.push(todo.idTodo);
      } else {
        idsTodos.push(todo);
      }
    }
    relations['todos'] = idsTodos;
  }
}

function elementNormalizeOrganizationMilestone(
  element: ElementEntityState,
  organizationMilestonesToNormalize: { [id: number]: OrganizationMilestoneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (element.organizationMilestone && typeof element.organizationMilestone !== 'number') {
    if (!organizationMilestonesToNormalize[element.organizationMilestone.idOrganizationMilestone]) {
      organizationMilestonesToNormalize[element.organizationMilestone.idOrganizationMilestone] = {
        ...element.organizationMilestone,
        elements: element.organizationMilestone.elements?.concat() ?? []
      };
    }
    (
      organizationMilestonesToNormalize[element.organizationMilestone.idOrganizationMilestone].elements as number[]
    ).push(element.idElement);
    relations['organizationMilestone'] = element.organizationMilestone.idOrganizationMilestone;
  }
}

/**
 *  ElementCash
 */
export function getActionsToNormalizeElementCash(
  elementCashs: ElementCashEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const elementsToNormalize: { [id: number]: ElementEntityState } = {};

  const normalizedElementCashs = elementCashs.map((elementCash: ElementCashEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    elementCashNormalizeElement(elementCash, elementsToNormalize, relations);

    return {
      ...elementCash,
      ...relations
    };
  });
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }

  const elementCashActionCreator = ElementCashGeneratedActions.upsertManyElementCashsSuccess;
  actions.push(elementCashActionCreator({ elementCashs: normalizedElementCashs }));

  return actions;
}

function elementCashNormalizeElement(
  elementCash: ElementCashEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (elementCash.element && typeof elementCash.element !== 'number') {
    if (!elementsToNormalize[elementCash.element.idElement]) {
      elementsToNormalize[elementCash.element.idElement] = {
        ...elementCash.element,
        elementCashs: elementCash.element.elementCashs?.concat() ?? []
      };
    }
    (elementsToNormalize[elementCash.element.idElement].elementCashs as number[]).push(elementCash.idElementCash);
    relations['element'] = elementCash.element.idElement;
  }
}

/**
 *  ElementLibrary
 */
export function getActionsToNormalizeElementLibrary(
  elementLibraries: ElementLibraryEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const elementsToNormalize: { [id: number]: ElementEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const communityUserProfilsToNormalize: { [id: number]: CommunityUserProfilEntityState } = {};

  const normalizedElementLibraries = elementLibraries.map((elementLibrary: ElementLibraryEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    elementLibraryNormalizeElement(elementLibrary, elementsToNormalize, relations);
    elementLibraryNormalizeUser(elementLibrary, usersToNormalize, relations);
    elementLibraryNormalizeCommunityUserProfil(elementLibrary, communityUserProfilsToNormalize, relations);

    return {
      ...elementLibrary,
      ...relations
    };
  });
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const communityUserProfilsToNormalizeArray = Object.values(communityUserProfilsToNormalize);
  if (communityUserProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunityUserProfil(communityUserProfilsToNormalizeArray, actionType));
  }

  const elementLibraryActionCreator = ElementLibraryGeneratedActions.upsertManyElementLibrariesSuccess;
  actions.push(elementLibraryActionCreator({ elementLibraries: normalizedElementLibraries }));

  return actions;
}

function elementLibraryNormalizeElement(
  elementLibrary: ElementLibraryEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (elementLibrary.element && typeof elementLibrary.element !== 'number') {
    if (!elementsToNormalize[elementLibrary.element.idElement]) {
      elementsToNormalize[elementLibrary.element.idElement] = {
        ...elementLibrary.element,
        elementLibraries: elementLibrary.element.elementLibraries?.concat() ?? []
      };
    }
    (elementsToNormalize[elementLibrary.element.idElement].elementLibraries as number[]).push(
      elementLibrary.idElementLibrary
    );
    relations['element'] = elementLibrary.element.idElement;
  }
}

function elementLibraryNormalizeUser(
  elementLibrary: ElementLibraryEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (elementLibrary.user && typeof elementLibrary.user !== 'number') {
    if (!usersToNormalize[elementLibrary.user.idUser]) {
      usersToNormalize[elementLibrary.user.idUser] = {
        ...elementLibrary.user,
        elementLibraries: elementLibrary.user.elementLibraries?.concat() ?? []
      };
    }
    (usersToNormalize[elementLibrary.user.idUser].elementLibraries as number[]).push(elementLibrary.idElementLibrary);
    relations['user'] = elementLibrary.user.idUser;
  }
}

function elementLibraryNormalizeCommunityUserProfil(
  elementLibrary: ElementLibraryEntityState,
  communityUserProfilsToNormalize: { [id: number]: CommunityUserProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (elementLibrary.communityUserProfil && typeof elementLibrary.communityUserProfil !== 'number') {
    if (!communityUserProfilsToNormalize[elementLibrary.communityUserProfil.idCommunityUserProfil]) {
      communityUserProfilsToNormalize[elementLibrary.communityUserProfil.idCommunityUserProfil] = {
        ...elementLibrary.communityUserProfil,
        elementLibraries: elementLibrary.communityUserProfil.elementLibraries?.concat() ?? []
      };
    }
    (
      communityUserProfilsToNormalize[elementLibrary.communityUserProfil.idCommunityUserProfil]
        .elementLibraries as number[]
    ).push(elementLibrary.idElementLibrary);
    relations['communityUserProfil'] = elementLibrary.communityUserProfil.idCommunityUserProfil;
  }
}

/**
 *  GanttLink
 */
export function getActionsToNormalizeGanttLink(
  ganttLinks: GanttLinkEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedGanttLinks = ganttLinks.map((ganttLink: GanttLinkEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    ganttLinkNormalizeCommunity(ganttLink, communitiesToNormalize, relations);

    return {
      ...ganttLink,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const ganttLinkActionCreator = GanttLinkGeneratedActions.upsertManyGanttLinksSuccess;
  actions.push(ganttLinkActionCreator({ ganttLinks: normalizedGanttLinks }));

  return actions;
}

function ganttLinkNormalizeCommunity(
  ganttLink: GanttLinkEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (ganttLink.community && typeof ganttLink.community !== 'number') {
    if (!communitiesToNormalize[ganttLink.community.idCommunity]) {
      communitiesToNormalize[ganttLink.community.idCommunity] = {
        ...ganttLink.community,
        ganttLinks: ganttLink.community.ganttLinks?.concat() ?? []
      };
    }
    (communitiesToNormalize[ganttLink.community.idCommunity].ganttLinks as number[]).push(ganttLink.idGanttLink);
    relations['community'] = ganttLink.community.idCommunity;
  }
}

/**
 *  Group
 */
export function getActionsToNormalizeGroup(groups: GroupEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const communityGroupsToNormalize: CommunityGroupEntityState[] = [];
  const userGroupsToNormalize: UserGroupEntityState[] = [];
  const organizationAzuresToNormalize: OrganizationAzureEntityState[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedGroups = groups.map((group: GroupEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    groupNormalizeCommunityGroup(group, communityGroupsToNormalize, relations);
    groupNormalizeUserGroup(group, userGroupsToNormalize, relations);
    groupNormalizeOrganizationAzure(group, organizationAzuresToNormalize, relations);
    groupNormalizeUser(group, usersToNormalize, relations);
    groupNormalizeCommunity(group, communitiesToNormalize, relations);
    groupNormalizeOrganization(group, organizationsToNormalize, relations);

    return {
      ...group,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (communityGroupsToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityGroup(communityGroupsToNormalize, actionType));
  }
  if (userGroupsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserGroup(userGroupsToNormalize, actionType));
  }
  if (organizationAzuresToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationAzure(organizationAzuresToNormalize, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const groupActionCreator = GroupGeneratedActions.upsertManyGroupsSuccess;
  actions.push(groupActionCreator({ groups: normalizedGroups }));

  return actions;
}

function groupNormalizeCommunityGroup(
  group: GroupEntityState,
  communityGroupsToNormalize: CommunityGroupEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (group.communityGroups?.length && typeof group.communityGroups[0] !== 'number') {
    const idsCommunityGroups: number[] = [];
    for (let i = 0; i < group.communityGroups.length; i++) {
      const communityGroup: CommunityGroup | number = group.communityGroups[i];
      if (typeof communityGroup !== 'number') {
        communityGroupsToNormalize.push({ ...communityGroup, group: group.idGroup });
        idsCommunityGroups.push(communityGroup.idCommunityGroup);
      } else {
        idsCommunityGroups.push(communityGroup);
      }
    }
    relations['communityGroups'] = idsCommunityGroups;
  }
}

function groupNormalizeUserGroup(
  group: GroupEntityState,
  userGroupsToNormalize: UserGroupEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (group.userGroups?.length && typeof group.userGroups[0] !== 'number') {
    const idsUserGroups: number[] = [];
    for (let i = 0; i < group.userGroups.length; i++) {
      const userGroup: UserGroup | number = group.userGroups[i];
      if (typeof userGroup !== 'number') {
        userGroupsToNormalize.push({ ...userGroup, group: group.idGroup });
        idsUserGroups.push(userGroup.idUserGroup);
      } else {
        idsUserGroups.push(userGroup);
      }
    }
    relations['userGroups'] = idsUserGroups;
  }
}

function groupNormalizeOrganizationAzure(
  group: GroupEntityState,
  organizationAzuresToNormalize: OrganizationAzureEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (group.organizationAzures?.length && typeof group.organizationAzures[0] !== 'number') {
    const idsOrganizationAzures: number[] = [];
    for (let i = 0; i < group.organizationAzures.length; i++) {
      const organizationAzure: OrganizationAzure | number = group.organizationAzures[i];
      if (typeof organizationAzure !== 'number') {
        organizationAzuresToNormalize.push({ ...organizationAzure, group: group.idGroup });
        idsOrganizationAzures.push(organizationAzure.idOrganizationAzure);
      } else {
        idsOrganizationAzures.push(organizationAzure);
      }
    }
    relations['organizationAzures'] = idsOrganizationAzures;
  }
}

function groupNormalizeUser(
  group: GroupEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (group.users?.length && typeof group.users[0] !== 'number') {
    const idsUsers: number[] = [];
    for (let i = 0; i < group.users.length; i++) {
      const user: User | number = group.users[i];

      if (typeof user !== 'number') {
        if (!usersToNormalize[user.idUser]) {
          usersToNormalize[user.idUser] = {
            ...user,
            groups: user.groups?.concat() ?? []
          };
        }
        (usersToNormalize[user.idUser].groups as number[]).push(group.idGroup);
        idsUsers.push(user.idUser);
      } else {
        idsUsers.push(user);
      }
    }
    relations['users'] = idsUsers;
  }
}

function groupNormalizeCommunity(
  group: GroupEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (group.communities?.length && typeof group.communities[0] !== 'number') {
    const idsCommunities: number[] = [];
    for (let i = 0; i < group.communities.length; i++) {
      const community: Community | number = group.communities[i];

      if (typeof community !== 'number') {
        if (!communitiesToNormalize[community.idCommunity]) {
          communitiesToNormalize[community.idCommunity] = {
            ...community,
            groups: community.groups?.concat() ?? []
          };
        }
        (communitiesToNormalize[community.idCommunity].groups as number[]).push(group.idGroup);
        idsCommunities.push(community.idCommunity);
      } else {
        idsCommunities.push(community);
      }
    }
    relations['communities'] = idsCommunities;
  }
}

function groupNormalizeOrganization(
  group: GroupEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (group.organization && typeof group.organization !== 'number') {
    if (!organizationsToNormalize[group.organization.idOrganization]) {
      organizationsToNormalize[group.organization.idOrganization] = {
        ...group.organization,
        groups: group.organization.groups?.concat() ?? []
      };
    }
    (organizationsToNormalize[group.organization.idOrganization].groups as number[]).push(group.idGroup);
    relations['organization'] = group.organization.idOrganization;
  }
}

/**
 *  Meeting
 */
export function getActionsToNormalizeMeeting(meetings: MeetingEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const meetingElementsToNormalize: MeetingElementEntityState[] = [];
  const meetingUsersToNormalize: MeetingUserEntityState[] = [];

  const normalizedMeetings = meetings.map((meeting: MeetingEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    meetingNormalizeMeetingElement(meeting, meetingElementsToNormalize, relations);
    meetingNormalizeMeetingUser(meeting, meetingUsersToNormalize, relations);
    meetingNormalizeCommunity(meeting, communitiesToNormalize, relations);

    return {
      ...meeting,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  if (meetingElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeMeetingElement(meetingElementsToNormalize, actionType));
  }
  if (meetingUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeMeetingUser(meetingUsersToNormalize, actionType));
  }

  const meetingActionCreator = MeetingGeneratedActions.upsertManyMeetingsSuccess;
  actions.push(meetingActionCreator({ meetings: normalizedMeetings }));

  return actions;
}

function meetingNormalizeMeetingElement(
  meeting: MeetingEntityState,
  meetingElementsToNormalize: MeetingElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (meeting.meetingElements?.length && typeof meeting.meetingElements[0] !== 'number') {
    const idsMeetingElements: number[] = [];
    for (let i = 0; i < meeting.meetingElements.length; i++) {
      const meetingElement: MeetingElement | number = meeting.meetingElements[i];
      if (typeof meetingElement !== 'number') {
        meetingElementsToNormalize.push({ ...meetingElement, meeting: meeting.idMeeting });
        idsMeetingElements.push(meetingElement.idMeetingElement);
      } else {
        idsMeetingElements.push(meetingElement);
      }
    }
    relations['meetingElements'] = idsMeetingElements;
  }
}

function meetingNormalizeMeetingUser(
  meeting: MeetingEntityState,
  meetingUsersToNormalize: MeetingUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (meeting.meetingUsers?.length && typeof meeting.meetingUsers[0] !== 'number') {
    const idsMeetingUsers: number[] = [];
    for (let i = 0; i < meeting.meetingUsers.length; i++) {
      const meetingUser: MeetingUser | number = meeting.meetingUsers[i];
      if (typeof meetingUser !== 'number') {
        meetingUsersToNormalize.push({ ...meetingUser, meeting: meeting.idMeeting });
        idsMeetingUsers.push(meetingUser.idMeetingUser);
      } else {
        idsMeetingUsers.push(meetingUser);
      }
    }
    relations['meetingUsers'] = idsMeetingUsers;
  }
}

function meetingNormalizeCommunity(
  meeting: MeetingEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (meeting.community && typeof meeting.community !== 'number') {
    if (!communitiesToNormalize[meeting.community.idCommunity]) {
      communitiesToNormalize[meeting.community.idCommunity] = {
        ...meeting.community,
        meetings: meeting.community.meetings?.concat() ?? []
      };
    }
    (communitiesToNormalize[meeting.community.idCommunity].meetings as number[]).push(meeting.idMeeting);
    relations['community'] = meeting.community.idCommunity;
  }
}

/**
 *  MeetingElement
 */
export function getActionsToNormalizeMeetingElement(
  meetingElements: MeetingElementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const meetingsToNormalize: { [id: number]: MeetingEntityState } = {};
  const elementsToNormalize: { [id: number]: ElementEntityState } = {};

  const normalizedMeetingElements = meetingElements.map((meetingElement: MeetingElementEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    meetingElementNormalizeMeeting(meetingElement, meetingsToNormalize, relations);
    meetingElementNormalizeElement(meetingElement, elementsToNormalize, relations);

    return {
      ...meetingElement,
      ...relations
    };
  });
  const meetingsToNormalizeArray = Object.values(meetingsToNormalize);
  if (meetingsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeMeeting(meetingsToNormalizeArray, actionType));
  }
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }

  const meetingElementActionCreator = MeetingElementGeneratedActions.upsertManyMeetingElementsSuccess;
  actions.push(meetingElementActionCreator({ meetingElements: normalizedMeetingElements }));

  return actions;
}

function meetingElementNormalizeMeeting(
  meetingElement: MeetingElementEntityState,
  meetingsToNormalize: { [id: number]: MeetingEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (meetingElement.meeting && typeof meetingElement.meeting !== 'number') {
    if (!meetingsToNormalize[meetingElement.meeting.idMeeting]) {
      meetingsToNormalize[meetingElement.meeting.idMeeting] = {
        ...meetingElement.meeting,
        meetingElements: meetingElement.meeting.meetingElements?.concat() ?? []
      };
    }
    (meetingsToNormalize[meetingElement.meeting.idMeeting].meetingElements as number[]).push(
      meetingElement.idMeetingElement
    );
    relations['meeting'] = meetingElement.meeting.idMeeting;
  }
}

function meetingElementNormalizeElement(
  meetingElement: MeetingElementEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (meetingElement.element && typeof meetingElement.element !== 'number') {
    if (!elementsToNormalize[meetingElement.element.idElement]) {
      elementsToNormalize[meetingElement.element.idElement] = {
        ...meetingElement.element,
        meetingElements: meetingElement.element.meetingElements?.concat() ?? []
      };
    }
    (elementsToNormalize[meetingElement.element.idElement].meetingElements as number[]).push(
      meetingElement.idMeetingElement
    );
    relations['element'] = meetingElement.element.idElement;
  }
}

/**
 *  MeetingUser
 */
export function getActionsToNormalizeMeetingUser(
  meetingUsers: MeetingUserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const meetingsToNormalize: { [id: number]: MeetingEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedMeetingUsers = meetingUsers.map((meetingUser: MeetingUserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    meetingUserNormalizeMeeting(meetingUser, meetingsToNormalize, relations);
    meetingUserNormalizeUser(meetingUser, usersToNormalize, relations);

    return {
      ...meetingUser,
      ...relations
    };
  });
  const meetingsToNormalizeArray = Object.values(meetingsToNormalize);
  if (meetingsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeMeeting(meetingsToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const meetingUserActionCreator = MeetingUserGeneratedActions.upsertManyMeetingUsersSuccess;
  actions.push(meetingUserActionCreator({ meetingUsers: normalizedMeetingUsers }));

  return actions;
}

function meetingUserNormalizeMeeting(
  meetingUser: MeetingUserEntityState,
  meetingsToNormalize: { [id: number]: MeetingEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (meetingUser.meeting && typeof meetingUser.meeting !== 'number') {
    if (!meetingsToNormalize[meetingUser.meeting.idMeeting]) {
      meetingsToNormalize[meetingUser.meeting.idMeeting] = {
        ...meetingUser.meeting,
        meetingUsers: meetingUser.meeting.meetingUsers?.concat() ?? []
      };
    }
    (meetingsToNormalize[meetingUser.meeting.idMeeting].meetingUsers as number[]).push(meetingUser.idMeetingUser);
    relations['meeting'] = meetingUser.meeting.idMeeting;
  }
}

function meetingUserNormalizeUser(
  meetingUser: MeetingUserEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (meetingUser.user && typeof meetingUser.user !== 'number') {
    if (!usersToNormalize[meetingUser.user.idUser]) {
      usersToNormalize[meetingUser.user.idUser] = {
        ...meetingUser.user,
        meetingUsers: meetingUser.user.meetingUsers?.concat() ?? []
      };
    }
    (usersToNormalize[meetingUser.user.idUser].meetingUsers as number[]).push(meetingUser.idMeetingUser);
    relations['user'] = meetingUser.user.idUser;
  }
}

/**
 *  NotesHistory
 */
export function getActionsToNormalizeNotesHistory(
  notesHistories: NotesHistoryEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedNotesHistories = notesHistories.map((notesHistory: NotesHistoryEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    notesHistoryNormalizeCommunity(notesHistory, communitiesToNormalize, relations);

    return {
      ...notesHistory,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const notesHistoryActionCreator = NotesHistoryGeneratedActions.upsertManyNotesHistoriesSuccess;
  actions.push(notesHistoryActionCreator({ notesHistories: normalizedNotesHistories }));

  return actions;
}

function notesHistoryNormalizeCommunity(
  notesHistory: NotesHistoryEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (notesHistory.community && typeof notesHistory.community !== 'number') {
    if (!communitiesToNormalize[notesHistory.community.idCommunity]) {
      communitiesToNormalize[notesHistory.community.idCommunity] = {
        ...notesHistory.community,
        notesHistories: notesHistory.community.notesHistories?.concat() ?? []
      };
    }
    (communitiesToNormalize[notesHistory.community.idCommunity].notesHistories as number[]).push(
      notesHistory.idNotesHistory
    );
    relations['community'] = notesHistory.community.idCommunity;
  }
}

/**
 *  ProjectModule
 */
export function getActionsToNormalizeProjectModule(
  projectModules: ProjectModuleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communityModulesToNormalize: CommunityModuleEntityState[] = [];
  const organizationProjectModulesToNormalize: OrganizationProjectModuleEntityState[] = [];
  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};

  const normalizedProjectModules = projectModules.map((projectModule: ProjectModuleEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    projectModuleNormalizeCommunityModule(projectModule, communityModulesToNormalize, relations);
    projectModuleNormalizeOrganizationProjectModule(projectModule, organizationProjectModulesToNormalize, relations);
    projectModuleNormalizeCommunity(projectModule, communitiesToNormalize, relations);

    return {
      ...projectModule,
      ...relations
    };
  });
  if (communityModulesToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityModule(communityModulesToNormalize, actionType));
  }
  if (organizationProjectModulesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationProjectModule(organizationProjectModulesToNormalize, actionType));
  }
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }

  const projectModuleActionCreator = ProjectModuleGeneratedActions.upsertManyProjectModulesSuccess;
  actions.push(projectModuleActionCreator({ projectModules: normalizedProjectModules }));

  return actions;
}

function projectModuleNormalizeCommunityModule(
  projectModule: ProjectModuleEntityState,
  communityModulesToNormalize: CommunityModuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (projectModule.communityModules?.length && typeof projectModule.communityModules[0] !== 'number') {
    const idsCommunityModules: number[] = [];
    for (let i = 0; i < projectModule.communityModules.length; i++) {
      const communityModule: CommunityModule | number = projectModule.communityModules[i];
      if (typeof communityModule !== 'number') {
        communityModulesToNormalize.push({ ...communityModule, projectModule: projectModule.idProjectModule });
        idsCommunityModules.push(communityModule.idCommunityModule);
      } else {
        idsCommunityModules.push(communityModule);
      }
    }
    relations['communityModules'] = idsCommunityModules;
  }
}

function projectModuleNormalizeOrganizationProjectModule(
  projectModule: ProjectModuleEntityState,
  organizationProjectModulesToNormalize: OrganizationProjectModuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    projectModule.organizationProjectModules?.length &&
    typeof projectModule.organizationProjectModules[0] !== 'number'
  ) {
    const idsOrganizationProjectModules: number[] = [];
    for (let i = 0; i < projectModule.organizationProjectModules.length; i++) {
      const organizationProjectModule: OrganizationProjectModule | number = projectModule.organizationProjectModules[i];
      if (typeof organizationProjectModule !== 'number') {
        organizationProjectModulesToNormalize.push({
          ...organizationProjectModule,
          projectModule: projectModule.idProjectModule
        });
        idsOrganizationProjectModules.push(organizationProjectModule.idOrganizationProjectModule);
      } else {
        idsOrganizationProjectModules.push(organizationProjectModule);
      }
    }
    relations['organizationProjectModules'] = idsOrganizationProjectModules;
  }
}

function projectModuleNormalizeCommunity(
  projectModule: ProjectModuleEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (projectModule.communities?.length && typeof projectModule.communities[0] !== 'number') {
    const idsCommunities: number[] = [];
    for (let i = 0; i < projectModule.communities.length; i++) {
      const community: Community | number = projectModule.communities[i];

      if (typeof community !== 'number') {
        if (!communitiesToNormalize[community.idCommunity]) {
          communitiesToNormalize[community.idCommunity] = {
            ...community,
            projectModules: community.projectModules?.concat() ?? []
          };
        }
        (communitiesToNormalize[community.idCommunity].projectModules as number[]).push(projectModule.idProjectModule);
        idsCommunities.push(community.idCommunity);
      } else {
        idsCommunities.push(community);
      }
    }
    relations['communities'] = idsCommunities;
  }
}

/**
 *  OrganizationAzure
 */
export function getActionsToNormalizeOrganizationAzure(
  organizationAzures: OrganizationAzureEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const groupsToNormalize: { [id: number]: GroupEntityState } = {};

  const normalizedOrganizationAzures = organizationAzures.map((organizationAzure: OrganizationAzureEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationAzureNormalizeOrganization(organizationAzure, organizationsToNormalize, relations);
    organizationAzureNormalizeGroup(organizationAzure, groupsToNormalize, relations);

    return {
      ...organizationAzure,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const groupsToNormalizeArray = Object.values(groupsToNormalize);
  if (groupsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGroup(groupsToNormalizeArray, actionType));
  }

  const organizationAzureActionCreator = OrganizationAzureGeneratedActions.upsertManyOrganizationAzuresSuccess;
  actions.push(organizationAzureActionCreator({ organizationAzures: normalizedOrganizationAzures }));

  return actions;
}

function organizationAzureNormalizeOrganization(
  organizationAzure: OrganizationAzureEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationAzure.organization && typeof organizationAzure.organization !== 'number') {
    if (!organizationsToNormalize[organizationAzure.organization.idOrganization]) {
      organizationsToNormalize[organizationAzure.organization.idOrganization] = {
        ...organizationAzure.organization,
        organizationAzures: organizationAzure.organization.organizationAzures?.concat() ?? []
      };
    }
    (organizationsToNormalize[organizationAzure.organization.idOrganization].organizationAzures as number[]).push(
      organizationAzure.idOrganizationAzure
    );
    relations['organization'] = organizationAzure.organization.idOrganization;
  }
}

function organizationAzureNormalizeGroup(
  organizationAzure: OrganizationAzureEntityState,
  groupsToNormalize: { [id: number]: GroupEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationAzure.group && typeof organizationAzure.group !== 'number') {
    if (!groupsToNormalize[organizationAzure.group.idGroup]) {
      groupsToNormalize[organizationAzure.group.idGroup] = {
        ...organizationAzure.group,
        organizationAzures: organizationAzure.group.organizationAzures?.concat() ?? []
      };
    }
    (groupsToNormalize[organizationAzure.group.idGroup].organizationAzures as number[]).push(
      organizationAzure.idOrganizationAzure
    );
    relations['group'] = organizationAzure.group.idGroup;
  }
}

/**
 *  OrganizationCaracteristic
 */
export function getActionsToNormalizeOrganizationCaracteristic(
  organizationCaracteristics: OrganizationCaracteristicEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const organizationDataColumnsToNormalize: { [id: number]: OrganizationDataColumnEntityState } = {};
  const communityCaracteristicsToNormalize: CommunityCaracteristicEntityState[] = [];
  const organizationMilestoneFamilysToNormalize: OrganizationMilestoneFamilyEntityState[] = [];
  const organizationCaracteristicColumnsToNormalize: OrganizationCaracteristicColumnEntityState[] = [];
  const organizationCaracteristicRowsToNormalize: OrganizationCaracteristicRowEntityState[] = [];

  const normalizedOrganizationCaracteristics = organizationCaracteristics.map(
    (organizationCaracteristic: OrganizationCaracteristicEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationCaracteristicNormalizeCommunityCaracteristic(
        organizationCaracteristic,
        communityCaracteristicsToNormalize,
        relations
      );
      organizationCaracteristicNormalizeOrganizationMilestoneFamily(
        organizationCaracteristic,
        organizationMilestoneFamilysToNormalize,
        relations
      );
      organizationCaracteristicNormalizeOrganizationCaracteristicColumn(
        organizationCaracteristic,
        organizationCaracteristicColumnsToNormalize,
        relations
      );
      organizationCaracteristicNormalizeOrganizationCaracteristicRow(
        organizationCaracteristic,
        organizationCaracteristicRowsToNormalize,
        relations
      );
      organizationCaracteristicNormalizeOrganization(organizationCaracteristic, organizationsToNormalize, relations);
      organizationCaracteristicNormalizeOrganizationFamily(
        organizationCaracteristic,
        organizationFamilysToNormalize,
        relations
      );
      organizationCaracteristicNormalizeOrganizationDataColumn(
        organizationCaracteristic,
        organizationDataColumnsToNormalize,
        relations
      );

      return {
        ...organizationCaracteristic,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  const organizationDataColumnsToNormalizeArray = Object.values(organizationDataColumnsToNormalize);
  if (organizationDataColumnsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationDataColumn(organizationDataColumnsToNormalizeArray, actionType));
  }
  if (communityCaracteristicsToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityCaracteristic(communityCaracteristicsToNormalize, actionType));
  }
  if (organizationMilestoneFamilysToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationMilestoneFamily(organizationMilestoneFamilysToNormalize, actionType)
    );
  }
  if (organizationCaracteristicColumnsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristicColumn(organizationCaracteristicColumnsToNormalize, actionType)
    );
  }
  if (organizationCaracteristicRowsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristicRow(organizationCaracteristicRowsToNormalize, actionType)
    );
  }

  const organizationCaracteristicActionCreator =
    OrganizationCaracteristicGeneratedActions.upsertManyOrganizationCaracteristicsSuccess;
  actions.push(
    organizationCaracteristicActionCreator({ organizationCaracteristics: normalizedOrganizationCaracteristics })
  );

  return actions;
}

function organizationCaracteristicNormalizeCommunityCaracteristic(
  organizationCaracteristic: OrganizationCaracteristicEntityState,
  communityCaracteristicsToNormalize: CommunityCaracteristicEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristic.communityCaracteristics?.length &&
    typeof organizationCaracteristic.communityCaracteristics[0] !== 'number'
  ) {
    const idsCommunityCaracteristics: number[] = [];
    for (let i = 0; i < organizationCaracteristic.communityCaracteristics.length; i++) {
      const communityCaracteristic: CommunityCaracteristic | number =
        organizationCaracteristic.communityCaracteristics[i];
      if (typeof communityCaracteristic !== 'number') {
        communityCaracteristicsToNormalize.push({
          ...communityCaracteristic,
          organizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic
        });
        idsCommunityCaracteristics.push(communityCaracteristic.idCommunityCaracteristic);
      } else {
        idsCommunityCaracteristics.push(communityCaracteristic);
      }
    }
    relations['communityCaracteristics'] = idsCommunityCaracteristics;
  }
}

function organizationCaracteristicNormalizeOrganizationMilestoneFamily(
  organizationCaracteristic: OrganizationCaracteristicEntityState,
  organizationMilestoneFamilysToNormalize: OrganizationMilestoneFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristic.organizationMilestoneFamilys?.length &&
    typeof organizationCaracteristic.organizationMilestoneFamilys[0] !== 'number'
  ) {
    const idsOrganizationMilestoneFamilys: number[] = [];
    for (let i = 0; i < organizationCaracteristic.organizationMilestoneFamilys.length; i++) {
      const organizationMilestoneFamily: OrganizationMilestoneFamily | number =
        organizationCaracteristic.organizationMilestoneFamilys[i];
      if (typeof organizationMilestoneFamily !== 'number') {
        organizationMilestoneFamilysToNormalize.push({
          ...organizationMilestoneFamily,
          organizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic
        });
        idsOrganizationMilestoneFamilys.push(organizationMilestoneFamily.idOrganizationMilestoneFamily);
      } else {
        idsOrganizationMilestoneFamilys.push(organizationMilestoneFamily);
      }
    }
    relations['organizationMilestoneFamilys'] = idsOrganizationMilestoneFamilys;
  }
}

function organizationCaracteristicNormalizeOrganizationCaracteristicColumn(
  organizationCaracteristic: OrganizationCaracteristicEntityState,
  organizationCaracteristicColumnsToNormalize: OrganizationCaracteristicColumnEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristic.organizationCaracteristicColumns?.length &&
    typeof organizationCaracteristic.organizationCaracteristicColumns[0] !== 'number'
  ) {
    const idsOrganizationCaracteristicColumns: number[] = [];
    for (let i = 0; i < organizationCaracteristic.organizationCaracteristicColumns.length; i++) {
      const organizationCaracteristicColumn: OrganizationCaracteristicColumn | number =
        organizationCaracteristic.organizationCaracteristicColumns[i];
      if (typeof organizationCaracteristicColumn !== 'number') {
        organizationCaracteristicColumnsToNormalize.push({
          ...organizationCaracteristicColumn,
          organizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic
        });
        idsOrganizationCaracteristicColumns.push(organizationCaracteristicColumn.idOrganizationCaracteristicColumn);
      } else {
        idsOrganizationCaracteristicColumns.push(organizationCaracteristicColumn);
      }
    }
    relations['organizationCaracteristicColumns'] = idsOrganizationCaracteristicColumns;
  }
}

function organizationCaracteristicNormalizeOrganizationCaracteristicRow(
  organizationCaracteristic: OrganizationCaracteristicEntityState,
  organizationCaracteristicRowsToNormalize: OrganizationCaracteristicRowEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristic.organizationCaracteristicRows?.length &&
    typeof organizationCaracteristic.organizationCaracteristicRows[0] !== 'number'
  ) {
    const idsOrganizationCaracteristicRows: number[] = [];
    for (let i = 0; i < organizationCaracteristic.organizationCaracteristicRows.length; i++) {
      const organizationCaracteristicRow: OrganizationCaracteristicRow | number =
        organizationCaracteristic.organizationCaracteristicRows[i];
      if (typeof organizationCaracteristicRow !== 'number') {
        organizationCaracteristicRowsToNormalize.push({
          ...organizationCaracteristicRow,
          organizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic
        });
        idsOrganizationCaracteristicRows.push(organizationCaracteristicRow.idOrganizationCaracteristicRow);
      } else {
        idsOrganizationCaracteristicRows.push(organizationCaracteristicRow);
      }
    }
    relations['organizationCaracteristicRows'] = idsOrganizationCaracteristicRows;
  }
}

function organizationCaracteristicNormalizeOrganization(
  organizationCaracteristic: OrganizationCaracteristicEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationCaracteristic.organization && typeof organizationCaracteristic.organization !== 'number') {
    if (!organizationsToNormalize[organizationCaracteristic.organization.idOrganization]) {
      organizationsToNormalize[organizationCaracteristic.organization.idOrganization] = {
        ...organizationCaracteristic.organization,
        organizationCaracteristics: organizationCaracteristic.organization.organizationCaracteristics?.concat() ?? []
      };
    }
    (
      organizationsToNormalize[organizationCaracteristic.organization.idOrganization]
        .organizationCaracteristics as number[]
    ).push(organizationCaracteristic.idOrganizationCaracteristic);
    relations['organization'] = organizationCaracteristic.organization.idOrganization;
  }
}

function organizationCaracteristicNormalizeOrganizationFamily(
  organizationCaracteristic: OrganizationCaracteristicEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristic.organizationFamily &&
    typeof organizationCaracteristic.organizationFamily !== 'number'
  ) {
    if (!organizationFamilysToNormalize[organizationCaracteristic.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[organizationCaracteristic.organizationFamily.idOrganizationFamily] = {
        ...organizationCaracteristic.organizationFamily,
        organizationCaracteristics:
          organizationCaracteristic.organizationFamily.organizationCaracteristics?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[organizationCaracteristic.organizationFamily.idOrganizationFamily]
        .organizationCaracteristics as number[]
    ).push(organizationCaracteristic.idOrganizationCaracteristic);
    relations['organizationFamily'] = organizationCaracteristic.organizationFamily.idOrganizationFamily;
  }
}

function organizationCaracteristicNormalizeOrganizationDataColumn(
  organizationCaracteristic: OrganizationCaracteristicEntityState,
  organizationDataColumnsToNormalize: { [id: number]: OrganizationDataColumnEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristic.organizationDataColumn &&
    typeof organizationCaracteristic.organizationDataColumn !== 'number'
  ) {
    if (
      !organizationDataColumnsToNormalize[organizationCaracteristic.organizationDataColumn.idOrganizationDataColumn]
    ) {
      organizationDataColumnsToNormalize[organizationCaracteristic.organizationDataColumn.idOrganizationDataColumn] = {
        ...organizationCaracteristic.organizationDataColumn,
        organizationCaracteristics:
          organizationCaracteristic.organizationDataColumn.organizationCaracteristics?.concat() ?? []
      };
    }
    (
      organizationDataColumnsToNormalize[organizationCaracteristic.organizationDataColumn.idOrganizationDataColumn]
        .organizationCaracteristics as number[]
    ).push(organizationCaracteristic.idOrganizationCaracteristic);
    relations['organizationDataColumn'] = organizationCaracteristic.organizationDataColumn.idOrganizationDataColumn;
  }
}

/**
 *  OrganizationCaracteristicColumn
 */
export function getActionsToNormalizeOrganizationCaracteristicColumn(
  organizationCaracteristicColumns: OrganizationCaracteristicColumnEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationCaracteristicsToNormalize: { [id: number]: OrganizationCaracteristicEntityState } = {};
  const organizationCaracteristicValuesToNormalize: OrganizationCaracteristicValueEntityState[] = [];

  const normalizedOrganizationCaracteristicColumns = organizationCaracteristicColumns.map(
    (organizationCaracteristicColumn: OrganizationCaracteristicColumnEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationCaracteristicColumnNormalizeOrganizationCaracteristicValue(
        organizationCaracteristicColumn,
        organizationCaracteristicValuesToNormalize,
        relations
      );
      organizationCaracteristicColumnNormalizeOrganizationCaracteristic(
        organizationCaracteristicColumn,
        organizationCaracteristicsToNormalize,
        relations
      );

      return {
        ...organizationCaracteristicColumn,
        ...relations
      };
    }
  );
  const organizationCaracteristicsToNormalizeArray = Object.values(organizationCaracteristicsToNormalize);
  if (organizationCaracteristicsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristic(organizationCaracteristicsToNormalizeArray, actionType)
    );
  }
  if (organizationCaracteristicValuesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristicValue(organizationCaracteristicValuesToNormalize, actionType)
    );
  }

  const organizationCaracteristicColumnActionCreator =
    OrganizationCaracteristicColumnGeneratedActions.upsertManyOrganizationCaracteristicColumnsSuccess;
  actions.push(
    organizationCaracteristicColumnActionCreator({
      organizationCaracteristicColumns: normalizedOrganizationCaracteristicColumns
    })
  );

  return actions;
}

function organizationCaracteristicColumnNormalizeOrganizationCaracteristicValue(
  organizationCaracteristicColumn: OrganizationCaracteristicColumnEntityState,
  organizationCaracteristicValuesToNormalize: OrganizationCaracteristicValueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristicColumn.organizationCaracteristicValues?.length &&
    typeof organizationCaracteristicColumn.organizationCaracteristicValues[0] !== 'number'
  ) {
    const idsOrganizationCaracteristicValues: number[] = [];
    for (let i = 0; i < organizationCaracteristicColumn.organizationCaracteristicValues.length; i++) {
      const organizationCaracteristicValue: OrganizationCaracteristicValue | number =
        organizationCaracteristicColumn.organizationCaracteristicValues[i];
      if (typeof organizationCaracteristicValue !== 'number') {
        organizationCaracteristicValuesToNormalize.push({
          ...organizationCaracteristicValue,
          organizationCaracteristicColumn: organizationCaracteristicColumn.idOrganizationCaracteristicColumn
        });
        idsOrganizationCaracteristicValues.push(organizationCaracteristicValue.idOrganizationCaracteristicValue);
      } else {
        idsOrganizationCaracteristicValues.push(organizationCaracteristicValue);
      }
    }
    relations['organizationCaracteristicValues'] = idsOrganizationCaracteristicValues;
  }
}

function organizationCaracteristicColumnNormalizeOrganizationCaracteristic(
  organizationCaracteristicColumn: OrganizationCaracteristicColumnEntityState,
  organizationCaracteristicsToNormalize: { [id: number]: OrganizationCaracteristicEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristicColumn.organizationCaracteristic &&
    typeof organizationCaracteristicColumn.organizationCaracteristic !== 'number'
  ) {
    if (
      !organizationCaracteristicsToNormalize[
        organizationCaracteristicColumn.organizationCaracteristic.idOrganizationCaracteristic
      ]
    ) {
      organizationCaracteristicsToNormalize[
        organizationCaracteristicColumn.organizationCaracteristic.idOrganizationCaracteristic
      ] = {
        ...organizationCaracteristicColumn.organizationCaracteristic,
        organizationCaracteristicColumns:
          organizationCaracteristicColumn.organizationCaracteristic.organizationCaracteristicColumns?.concat() ?? []
      };
    }
    (
      organizationCaracteristicsToNormalize[
        organizationCaracteristicColumn.organizationCaracteristic.idOrganizationCaracteristic
      ].organizationCaracteristicColumns as number[]
    ).push(organizationCaracteristicColumn.idOrganizationCaracteristicColumn);
    relations['organizationCaracteristic'] =
      organizationCaracteristicColumn.organizationCaracteristic.idOrganizationCaracteristic;
  }
}

/**
 *  OrganizationCaracteristicRow
 */
export function getActionsToNormalizeOrganizationCaracteristicRow(
  organizationCaracteristicRows: OrganizationCaracteristicRowEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationCaracteristicsToNormalize: { [id: number]: OrganizationCaracteristicEntityState } = {};
  const organizationDataColumnsToNormalize: { [id: number]: OrganizationDataColumnEntityState } = {};
  const organizationCaracteristicValuesToNormalize: OrganizationCaracteristicValueEntityState[] = [];

  const normalizedOrganizationCaracteristicRows = organizationCaracteristicRows.map(
    (organizationCaracteristicRow: OrganizationCaracteristicRowEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationCaracteristicRowNormalizeOrganizationCaracteristicValue(
        organizationCaracteristicRow,
        organizationCaracteristicValuesToNormalize,
        relations
      );
      organizationCaracteristicRowNormalizeOrganizationCaracteristic(
        organizationCaracteristicRow,
        organizationCaracteristicsToNormalize,
        relations
      );
      organizationCaracteristicRowNormalizeOrganizationDataColumn(
        organizationCaracteristicRow,
        organizationDataColumnsToNormalize,
        relations
      );

      return {
        ...organizationCaracteristicRow,
        ...relations
      };
    }
  );
  const organizationCaracteristicsToNormalizeArray = Object.values(organizationCaracteristicsToNormalize);
  if (organizationCaracteristicsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristic(organizationCaracteristicsToNormalizeArray, actionType)
    );
  }
  const organizationDataColumnsToNormalizeArray = Object.values(organizationDataColumnsToNormalize);
  if (organizationDataColumnsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationDataColumn(organizationDataColumnsToNormalizeArray, actionType));
  }
  if (organizationCaracteristicValuesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristicValue(organizationCaracteristicValuesToNormalize, actionType)
    );
  }

  const organizationCaracteristicRowActionCreator =
    OrganizationCaracteristicRowGeneratedActions.upsertManyOrganizationCaracteristicRowsSuccess;
  actions.push(
    organizationCaracteristicRowActionCreator({
      organizationCaracteristicRows: normalizedOrganizationCaracteristicRows
    })
  );

  return actions;
}

function organizationCaracteristicRowNormalizeOrganizationCaracteristicValue(
  organizationCaracteristicRow: OrganizationCaracteristicRowEntityState,
  organizationCaracteristicValuesToNormalize: OrganizationCaracteristicValueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristicRow.organizationCaracteristicValues?.length &&
    typeof organizationCaracteristicRow.organizationCaracteristicValues[0] !== 'number'
  ) {
    const idsOrganizationCaracteristicValues: number[] = [];
    for (let i = 0; i < organizationCaracteristicRow.organizationCaracteristicValues.length; i++) {
      const organizationCaracteristicValue: OrganizationCaracteristicValue | number =
        organizationCaracteristicRow.organizationCaracteristicValues[i];
      if (typeof organizationCaracteristicValue !== 'number') {
        organizationCaracteristicValuesToNormalize.push({
          ...organizationCaracteristicValue,
          organizationCaracteristicRow: organizationCaracteristicRow.idOrganizationCaracteristicRow
        });
        idsOrganizationCaracteristicValues.push(organizationCaracteristicValue.idOrganizationCaracteristicValue);
      } else {
        idsOrganizationCaracteristicValues.push(organizationCaracteristicValue);
      }
    }
    relations['organizationCaracteristicValues'] = idsOrganizationCaracteristicValues;
  }
}

function organizationCaracteristicRowNormalizeOrganizationCaracteristic(
  organizationCaracteristicRow: OrganizationCaracteristicRowEntityState,
  organizationCaracteristicsToNormalize: { [id: number]: OrganizationCaracteristicEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristicRow.organizationCaracteristic &&
    typeof organizationCaracteristicRow.organizationCaracteristic !== 'number'
  ) {
    if (
      !organizationCaracteristicsToNormalize[
        organizationCaracteristicRow.organizationCaracteristic.idOrganizationCaracteristic
      ]
    ) {
      organizationCaracteristicsToNormalize[
        organizationCaracteristicRow.organizationCaracteristic.idOrganizationCaracteristic
      ] = {
        ...organizationCaracteristicRow.organizationCaracteristic,
        organizationCaracteristicRows:
          organizationCaracteristicRow.organizationCaracteristic.organizationCaracteristicRows?.concat() ?? []
      };
    }
    (
      organizationCaracteristicsToNormalize[
        organizationCaracteristicRow.organizationCaracteristic.idOrganizationCaracteristic
      ].organizationCaracteristicRows as number[]
    ).push(organizationCaracteristicRow.idOrganizationCaracteristicRow);
    relations['organizationCaracteristic'] =
      organizationCaracteristicRow.organizationCaracteristic.idOrganizationCaracteristic;
  }
}

function organizationCaracteristicRowNormalizeOrganizationDataColumn(
  organizationCaracteristicRow: OrganizationCaracteristicRowEntityState,
  organizationDataColumnsToNormalize: { [id: number]: OrganizationDataColumnEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristicRow.organizationDataColumn &&
    typeof organizationCaracteristicRow.organizationDataColumn !== 'number'
  ) {
    if (
      !organizationDataColumnsToNormalize[organizationCaracteristicRow.organizationDataColumn.idOrganizationDataColumn]
    ) {
      organizationDataColumnsToNormalize[organizationCaracteristicRow.organizationDataColumn.idOrganizationDataColumn] =
        {
          ...organizationCaracteristicRow.organizationDataColumn,
          organizationCaracteristicRows:
            organizationCaracteristicRow.organizationDataColumn.organizationCaracteristicRows?.concat() ?? []
        };
    }
    (
      organizationDataColumnsToNormalize[organizationCaracteristicRow.organizationDataColumn.idOrganizationDataColumn]
        .organizationCaracteristicRows as number[]
    ).push(organizationCaracteristicRow.idOrganizationCaracteristicRow);
    relations['organizationDataColumn'] = organizationCaracteristicRow.organizationDataColumn.idOrganizationDataColumn;
  }
}

/**
 *  OrganizationCaracteristicValue
 */
export function getActionsToNormalizeOrganizationCaracteristicValue(
  organizationCaracteristicValues: OrganizationCaracteristicValueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationCaracteristicColumnsToNormalize: { [id: number]: OrganizationCaracteristicColumnEntityState } = {};
  const organizationCaracteristicRowsToNormalize: { [id: number]: OrganizationCaracteristicRowEntityState } = {};
  const organizationDataColumnValuesToNormalize: { [id: number]: OrganizationDataColumnValueEntityState } = {};

  const normalizedOrganizationCaracteristicValues = organizationCaracteristicValues.map(
    (organizationCaracteristicValue: OrganizationCaracteristicValueEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationCaracteristicValueNormalizeOrganizationCaracteristicColumn(
        organizationCaracteristicValue,
        organizationCaracteristicColumnsToNormalize,
        relations
      );
      organizationCaracteristicValueNormalizeOrganizationCaracteristicRow(
        organizationCaracteristicValue,
        organizationCaracteristicRowsToNormalize,
        relations
      );
      organizationCaracteristicValueNormalizeOrganizationDataColumnValue(
        organizationCaracteristicValue,
        organizationDataColumnValuesToNormalize,
        relations
      );

      return {
        ...organizationCaracteristicValue,
        ...relations
      };
    }
  );
  const organizationCaracteristicColumnsToNormalizeArray = Object.values(organizationCaracteristicColumnsToNormalize);
  if (organizationCaracteristicColumnsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristicColumn(
        organizationCaracteristicColumnsToNormalizeArray,
        actionType
      )
    );
  }
  const organizationCaracteristicRowsToNormalizeArray = Object.values(organizationCaracteristicRowsToNormalize);
  if (organizationCaracteristicRowsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristicRow(organizationCaracteristicRowsToNormalizeArray, actionType)
    );
  }
  const organizationDataColumnValuesToNormalizeArray = Object.values(organizationDataColumnValuesToNormalize);
  if (organizationDataColumnValuesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationDataColumnValue(organizationDataColumnValuesToNormalizeArray, actionType)
    );
  }

  const organizationCaracteristicValueActionCreator =
    OrganizationCaracteristicValueGeneratedActions.upsertManyOrganizationCaracteristicValuesSuccess;
  actions.push(
    organizationCaracteristicValueActionCreator({
      organizationCaracteristicValues: normalizedOrganizationCaracteristicValues
    })
  );

  return actions;
}

function organizationCaracteristicValueNormalizeOrganizationCaracteristicColumn(
  organizationCaracteristicValue: OrganizationCaracteristicValueEntityState,
  organizationCaracteristicColumnsToNormalize: { [id: number]: OrganizationCaracteristicColumnEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristicValue.organizationCaracteristicColumn &&
    typeof organizationCaracteristicValue.organizationCaracteristicColumn !== 'number'
  ) {
    if (
      !organizationCaracteristicColumnsToNormalize[
        organizationCaracteristicValue.organizationCaracteristicColumn.idOrganizationCaracteristicColumn
      ]
    ) {
      organizationCaracteristicColumnsToNormalize[
        organizationCaracteristicValue.organizationCaracteristicColumn.idOrganizationCaracteristicColumn
      ] = {
        ...organizationCaracteristicValue.organizationCaracteristicColumn,
        organizationCaracteristicValues:
          organizationCaracteristicValue.organizationCaracteristicColumn.organizationCaracteristicValues?.concat() ?? []
      };
    }
    (
      organizationCaracteristicColumnsToNormalize[
        organizationCaracteristicValue.organizationCaracteristicColumn.idOrganizationCaracteristicColumn
      ].organizationCaracteristicValues as number[]
    ).push(organizationCaracteristicValue.idOrganizationCaracteristicValue);
    relations['organizationCaracteristicColumn'] =
      organizationCaracteristicValue.organizationCaracteristicColumn.idOrganizationCaracteristicColumn;
  }
}

function organizationCaracteristicValueNormalizeOrganizationCaracteristicRow(
  organizationCaracteristicValue: OrganizationCaracteristicValueEntityState,
  organizationCaracteristicRowsToNormalize: { [id: number]: OrganizationCaracteristicRowEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristicValue.organizationCaracteristicRow &&
    typeof organizationCaracteristicValue.organizationCaracteristicRow !== 'number'
  ) {
    if (
      !organizationCaracteristicRowsToNormalize[
        organizationCaracteristicValue.organizationCaracteristicRow.idOrganizationCaracteristicRow
      ]
    ) {
      organizationCaracteristicRowsToNormalize[
        organizationCaracteristicValue.organizationCaracteristicRow.idOrganizationCaracteristicRow
      ] = {
        ...organizationCaracteristicValue.organizationCaracteristicRow,
        organizationCaracteristicValues:
          organizationCaracteristicValue.organizationCaracteristicRow.organizationCaracteristicValues?.concat() ?? []
      };
    }
    (
      organizationCaracteristicRowsToNormalize[
        organizationCaracteristicValue.organizationCaracteristicRow.idOrganizationCaracteristicRow
      ].organizationCaracteristicValues as number[]
    ).push(organizationCaracteristicValue.idOrganizationCaracteristicValue);
    relations['organizationCaracteristicRow'] =
      organizationCaracteristicValue.organizationCaracteristicRow.idOrganizationCaracteristicRow;
  }
}

function organizationCaracteristicValueNormalizeOrganizationDataColumnValue(
  organizationCaracteristicValue: OrganizationCaracteristicValueEntityState,
  organizationDataColumnValuesToNormalize: { [id: number]: OrganizationDataColumnValueEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationCaracteristicValue.organizationDataColumnValue &&
    typeof organizationCaracteristicValue.organizationDataColumnValue !== 'number'
  ) {
    if (
      !organizationDataColumnValuesToNormalize[
        organizationCaracteristicValue.organizationDataColumnValue.idOrganizationDataColumnValue
      ]
    ) {
      organizationDataColumnValuesToNormalize[
        organizationCaracteristicValue.organizationDataColumnValue.idOrganizationDataColumnValue
      ] = {
        ...organizationCaracteristicValue.organizationDataColumnValue,
        organizationCaracteristicValues:
          organizationCaracteristicValue.organizationDataColumnValue.organizationCaracteristicValues?.concat() ?? []
      };
    }
    (
      organizationDataColumnValuesToNormalize[
        organizationCaracteristicValue.organizationDataColumnValue.idOrganizationDataColumnValue
      ].organizationCaracteristicValues as number[]
    ).push(organizationCaracteristicValue.idOrganizationCaracteristicValue);
    relations['organizationDataColumnValue'] =
      organizationCaracteristicValue.organizationDataColumnValue.idOrganizationDataColumnValue;
  }
}

/**
 *  OrganizationMilestone
 */
export function getActionsToNormalizeOrganizationMilestone(
  organizationMilestones: OrganizationMilestoneEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const communityMilestonesToNormalize: CommunityMilestoneEntityState[] = [];
  const organizationMilestoneAssociationsToNormalize: OrganizationMilestoneAssociationEntityState[] = [];
  const elementsToNormalize: ElementEntityState[] = [];
  const organizationMilestoneFamilysToNormalize: { [id: number]: OrganizationMilestoneFamilyEntityState } = {};

  const normalizedOrganizationMilestones = organizationMilestones.map(
    (organizationMilestone: OrganizationMilestoneEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationMilestoneNormalizeCommunityMilestone(
        organizationMilestone,
        communityMilestonesToNormalize,
        relations
      );
      organizationMilestoneNormalizeOrganizationMilestoneAssociation(
        organizationMilestone,
        organizationMilestoneAssociationsToNormalize,
        relations
      );
      organizationMilestoneNormalizeElement(organizationMilestone, elementsToNormalize, relations);
      organizationMilestoneNormalizeOrganizationMilestoneFamily(
        organizationMilestone,
        organizationMilestoneFamilysToNormalize,
        relations
      );
      organizationMilestoneNormalizeOrganization(organizationMilestone, organizationsToNormalize, relations);
      organizationMilestoneNormalizeOrganizationFamily(
        organizationMilestone,
        organizationFamilysToNormalize,
        relations
      );

      return {
        ...organizationMilestone,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  if (communityMilestonesToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityMilestone(communityMilestonesToNormalize, actionType));
  }
  if (organizationMilestoneAssociationsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationMilestoneAssociation(organizationMilestoneAssociationsToNormalize, actionType)
    );
  }
  if (elementsToNormalize.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalize, actionType));
  }
  const organizationMilestoneFamilysToNormalizeArray = Object.values(organizationMilestoneFamilysToNormalize);
  if (organizationMilestoneFamilysToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationMilestoneFamily(organizationMilestoneFamilysToNormalizeArray, actionType)
    );
  }

  const organizationMilestoneActionCreator =
    OrganizationMilestoneGeneratedActions.upsertManyOrganizationMilestonesSuccess;
  actions.push(organizationMilestoneActionCreator({ organizationMilestones: normalizedOrganizationMilestones }));

  return actions;
}

function organizationMilestoneNormalizeCommunityMilestone(
  organizationMilestone: OrganizationMilestoneEntityState,
  communityMilestonesToNormalize: CommunityMilestoneEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestone.communityMilestones?.length &&
    typeof organizationMilestone.communityMilestones[0] !== 'number'
  ) {
    const idsCommunityMilestones: number[] = [];
    for (let i = 0; i < organizationMilestone.communityMilestones.length; i++) {
      const communityMilestone: CommunityMilestone | number = organizationMilestone.communityMilestones[i];
      if (typeof communityMilestone !== 'number') {
        communityMilestonesToNormalize.push({
          ...communityMilestone,
          organizationMilestone: organizationMilestone.idOrganizationMilestone
        });
        idsCommunityMilestones.push(communityMilestone.idCommunityMilestone);
      } else {
        idsCommunityMilestones.push(communityMilestone);
      }
    }
    relations['communityMilestones'] = idsCommunityMilestones;
  }
}

function organizationMilestoneNormalizeOrganizationMilestoneAssociation(
  organizationMilestone: OrganizationMilestoneEntityState,
  organizationMilestoneAssociationsToNormalize: OrganizationMilestoneAssociationEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestone.organizationMilestoneAssociations?.length &&
    typeof organizationMilestone.organizationMilestoneAssociations[0] !== 'number'
  ) {
    const idsOrganizationMilestoneAssociations: number[] = [];
    for (let i = 0; i < organizationMilestone.organizationMilestoneAssociations.length; i++) {
      const organizationMilestoneAssociation: OrganizationMilestoneAssociation | number =
        organizationMilestone.organizationMilestoneAssociations[i];
      if (typeof organizationMilestoneAssociation !== 'number') {
        organizationMilestoneAssociationsToNormalize.push({
          ...organizationMilestoneAssociation,
          organizationMilestone: organizationMilestone.idOrganizationMilestone
        });
        idsOrganizationMilestoneAssociations.push(organizationMilestoneAssociation.idOrganizationMilestoneAssociation);
      } else {
        idsOrganizationMilestoneAssociations.push(organizationMilestoneAssociation);
      }
    }
    relations['organizationMilestoneAssociations'] = idsOrganizationMilestoneAssociations;
  }
}

function organizationMilestoneNormalizeElement(
  organizationMilestone: OrganizationMilestoneEntityState,
  elementsToNormalize: ElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationMilestone.elements?.length && typeof organizationMilestone.elements[0] !== 'number') {
    const idsElements: number[] = [];
    for (let i = 0; i < organizationMilestone.elements.length; i++) {
      const element: Element | number = organizationMilestone.elements[i];
      if (typeof element !== 'number') {
        elementsToNormalize.push({ ...element, organizationMilestone: organizationMilestone.idOrganizationMilestone });
        idsElements.push(element.idElement);
      } else {
        idsElements.push(element);
      }
    }
    relations['elements'] = idsElements;
  }
}

function organizationMilestoneNormalizeOrganizationMilestoneFamily(
  organizationMilestone: OrganizationMilestoneEntityState,
  organizationMilestoneFamilysToNormalize: { [id: number]: OrganizationMilestoneFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestone.organizationMilestoneFamilys?.length &&
    typeof organizationMilestone.organizationMilestoneFamilys[0] !== 'number'
  ) {
    const idsOrganizationMilestoneFamilys: number[] = [];
    for (let i = 0; i < organizationMilestone.organizationMilestoneFamilys.length; i++) {
      const organizationMilestoneFamily: OrganizationMilestoneFamily | number =
        organizationMilestone.organizationMilestoneFamilys[i];

      if (typeof organizationMilestoneFamily !== 'number') {
        if (!organizationMilestoneFamilysToNormalize[organizationMilestoneFamily.idOrganizationMilestoneFamily]) {
          organizationMilestoneFamilysToNormalize[organizationMilestoneFamily.idOrganizationMilestoneFamily] = {
            ...organizationMilestoneFamily,
            organizationMilestones: organizationMilestoneFamily.organizationMilestones?.concat() ?? []
          };
        }
        (
          organizationMilestoneFamilysToNormalize[organizationMilestoneFamily.idOrganizationMilestoneFamily]
            .organizationMilestones as number[]
        ).push(organizationMilestone.idOrganizationMilestone);
        idsOrganizationMilestoneFamilys.push(organizationMilestoneFamily.idOrganizationMilestoneFamily);
      } else {
        idsOrganizationMilestoneFamilys.push(organizationMilestoneFamily);
      }
    }
    relations['organizationMilestoneFamilys'] = idsOrganizationMilestoneFamilys;
  }
}

function organizationMilestoneNormalizeOrganization(
  organizationMilestone: OrganizationMilestoneEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationMilestone.organization && typeof organizationMilestone.organization !== 'number') {
    if (!organizationsToNormalize[organizationMilestone.organization.idOrganization]) {
      organizationsToNormalize[organizationMilestone.organization.idOrganization] = {
        ...organizationMilestone.organization,
        organizationMilestones: organizationMilestone.organization.organizationMilestones?.concat() ?? []
      };
    }
    (
      organizationsToNormalize[organizationMilestone.organization.idOrganization].organizationMilestones as number[]
    ).push(organizationMilestone.idOrganizationMilestone);
    relations['organization'] = organizationMilestone.organization.idOrganization;
  }
}

function organizationMilestoneNormalizeOrganizationFamily(
  organizationMilestone: OrganizationMilestoneEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationMilestone.organizationFamily && typeof organizationMilestone.organizationFamily !== 'number') {
    if (!organizationFamilysToNormalize[organizationMilestone.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[organizationMilestone.organizationFamily.idOrganizationFamily] = {
        ...organizationMilestone.organizationFamily,
        organizationMilestones: organizationMilestone.organizationFamily.organizationMilestones?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[organizationMilestone.organizationFamily.idOrganizationFamily]
        .organizationMilestones as number[]
    ).push(organizationMilestone.idOrganizationMilestone);
    relations['organizationFamily'] = organizationMilestone.organizationFamily.idOrganizationFamily;
  }
}

/**
 *  OrganizationStep
 */
export function getActionsToNormalizeOrganizationStep(
  organizationSteps: OrganizationStepEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const communitiesToNormalize: CommunityEntityState[] = [];

  const normalizedOrganizationSteps = organizationSteps.map((organizationStep: OrganizationStepEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationStepNormalizeCommunity(organizationStep, communitiesToNormalize, relations);
    organizationStepNormalizeOrganization(organizationStep, organizationsToNormalize, relations);
    organizationStepNormalizeOrganizationFamily(organizationStep, organizationFamilysToNormalize, relations);

    return {
      ...organizationStep,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  if (communitiesToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalize, actionType));
  }

  const organizationStepActionCreator = OrganizationStepGeneratedActions.upsertManyOrganizationStepsSuccess;
  actions.push(organizationStepActionCreator({ organizationSteps: normalizedOrganizationSteps }));

  return actions;
}

function organizationStepNormalizeCommunity(
  organizationStep: OrganizationStepEntityState,
  communitiesToNormalize: CommunityEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStep.communities?.length && typeof organizationStep.communities[0] !== 'number') {
    const idsCommunities: number[] = [];
    for (let i = 0; i < organizationStep.communities.length; i++) {
      const community: Community | number = organizationStep.communities[i];
      if (typeof community !== 'number') {
        communitiesToNormalize.push({ ...community, organizationStep: organizationStep.idOrganizationStep });
        idsCommunities.push(community.idCommunity);
      } else {
        idsCommunities.push(community);
      }
    }
    relations['communities'] = idsCommunities;
  }
}

function organizationStepNormalizeOrganization(
  organizationStep: OrganizationStepEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStep.organization && typeof organizationStep.organization !== 'number') {
    if (!organizationsToNormalize[organizationStep.organization.idOrganization]) {
      organizationsToNormalize[organizationStep.organization.idOrganization] = {
        ...organizationStep.organization,
        organizationSteps: organizationStep.organization.organizationSteps?.concat() ?? []
      };
    }
    (organizationsToNormalize[organizationStep.organization.idOrganization].organizationSteps as number[]).push(
      organizationStep.idOrganizationStep
    );
    relations['organization'] = organizationStep.organization.idOrganization;
  }
}

function organizationStepNormalizeOrganizationFamily(
  organizationStep: OrganizationStepEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStep.organizationFamily && typeof organizationStep.organizationFamily !== 'number') {
    if (!organizationFamilysToNormalize[organizationStep.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[organizationStep.organizationFamily.idOrganizationFamily] = {
        ...organizationStep.organizationFamily,
        organizationSteps: organizationStep.organizationFamily.organizationSteps?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[organizationStep.organizationFamily.idOrganizationFamily]
        .organizationSteps as number[]
    ).push(organizationStep.idOrganizationStep);
    relations['organizationFamily'] = organizationStep.organizationFamily.idOrganizationFamily;
  }
}

/**
 *  Organization
 */
export function getActionsToNormalizeOrganization(
  organizations: OrganizationEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: CommunityEntityState[] = [];
  const organizationAzuresToNormalize: OrganizationAzureEntityState[] = [];
  const organizationElementsToNormalize: OrganizationElementEntityState[] = [];
  const groupsToNormalize: GroupEntityState[] = [];
  const organizationUsersToNormalize: OrganizationUserEntityState[] = [];
  const organizationFamilysToNormalize: OrganizationFamilyEntityState[] = [];
  const organizationMilestoneFamilysToNormalize: OrganizationMilestoneFamilyEntityState[] = [];
  const organizationMilestonesToNormalize: OrganizationMilestoneEntityState[] = [];
  const organizationRisksToNormalize: OrganizationRiskEntityState[] = [];
  const organizationKpisToNormalize: OrganizationKpiEntityState[] = [];
  const organizationSubFamilysToNormalize: OrganizationSubFamilyEntityState[] = [];
  const organizationCaracteristicsToNormalize: OrganizationCaracteristicEntityState[] = [];
  const organizationUserProfilsToNormalize: OrganizationUserProfilEntityState[] = [];
  const organizationStepsToNormalize: OrganizationStepEntityState[] = [];
  const organizationProjectModulesToNormalize: OrganizationProjectModuleEntityState[] = [];
  const organizationSectionsToNormalize: OrganizationSectionEntityState[] = [];
  const profilsToNormalize: ProfilEntityState[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const sectionsToNormalize: { [id: number]: SectionEntityState } = {};

  const normalizedOrganizations = organizations.map((organization: OrganizationEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationNormalizeCommunity(organization, communitiesToNormalize, relations);
    organizationNormalizeOrganizationAzure(organization, organizationAzuresToNormalize, relations);
    organizationNormalizeOrganizationElement(organization, organizationElementsToNormalize, relations);
    organizationNormalizeGroup(organization, groupsToNormalize, relations);
    organizationNormalizeOrganizationUser(organization, organizationUsersToNormalize, relations);
    organizationNormalizeOrganizationFamily(organization, organizationFamilysToNormalize, relations);
    organizationNormalizeOrganizationMilestoneFamily(organization, organizationMilestoneFamilysToNormalize, relations);
    organizationNormalizeOrganizationMilestone(organization, organizationMilestonesToNormalize, relations);
    organizationNormalizeOrganizationRisk(organization, organizationRisksToNormalize, relations);
    organizationNormalizeOrganizationKpi(organization, organizationKpisToNormalize, relations);
    organizationNormalizeOrganizationSubFamily(organization, organizationSubFamilysToNormalize, relations);
    organizationNormalizeOrganizationCaracteristic(organization, organizationCaracteristicsToNormalize, relations);
    organizationNormalizeOrganizationUserProfil(organization, organizationUserProfilsToNormalize, relations);
    organizationNormalizeOrganizationStep(organization, organizationStepsToNormalize, relations);
    organizationNormalizeOrganizationProjectModule(organization, organizationProjectModulesToNormalize, relations);
    organizationNormalizeOrganizationSection(organization, organizationSectionsToNormalize, relations);
    organizationNormalizeProfil(organization, profilsToNormalize, relations);
    organizationNormalizeUser(organization, usersToNormalize, relations);
    organizationNormalizeSection(organization, sectionsToNormalize, relations);

    return {
      ...organization,
      ...relations
    };
  });
  if (communitiesToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalize, actionType));
  }
  if (organizationAzuresToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationAzure(organizationAzuresToNormalize, actionType));
  }
  if (organizationElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationElement(organizationElementsToNormalize, actionType));
  }
  if (groupsToNormalize.length) {
    actions.push(...getActionsToNormalizeGroup(groupsToNormalize, actionType));
  }
  if (organizationUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationUser(organizationUsersToNormalize, actionType));
  }
  if (organizationFamilysToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalize, actionType));
  }
  if (organizationMilestoneFamilysToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationMilestoneFamily(organizationMilestoneFamilysToNormalize, actionType)
    );
  }
  if (organizationMilestonesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationMilestone(organizationMilestonesToNormalize, actionType));
  }
  if (organizationRisksToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationRisk(organizationRisksToNormalize, actionType));
  }
  if (organizationKpisToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationKpi(organizationKpisToNormalize, actionType));
  }
  if (organizationSubFamilysToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationSubFamily(organizationSubFamilysToNormalize, actionType));
  }
  if (organizationCaracteristicsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationCaracteristic(organizationCaracteristicsToNormalize, actionType));
  }
  if (organizationUserProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationUserProfil(organizationUserProfilsToNormalize, actionType));
  }
  if (organizationStepsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationStep(organizationStepsToNormalize, actionType));
  }
  if (organizationProjectModulesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationProjectModule(organizationProjectModulesToNormalize, actionType));
  }
  if (organizationSectionsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationSection(organizationSectionsToNormalize, actionType));
  }
  if (profilsToNormalize.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalize, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const sectionsToNormalizeArray = Object.values(sectionsToNormalize);
  if (sectionsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSection(sectionsToNormalizeArray, actionType));
  }

  const organizationActionCreator = OrganizationGeneratedActions.upsertManyOrganizationsSuccess;
  actions.push(organizationActionCreator({ organizations: normalizedOrganizations }));

  return actions;
}

function organizationNormalizeCommunity(
  organization: OrganizationEntityState,
  communitiesToNormalize: CommunityEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.communities?.length && typeof organization.communities[0] !== 'number') {
    const idsCommunities: number[] = [];
    for (let i = 0; i < organization.communities.length; i++) {
      const community: Community | number = organization.communities[i];
      if (typeof community !== 'number') {
        communitiesToNormalize.push({ ...community, organization: organization.idOrganization });
        idsCommunities.push(community.idCommunity);
      } else {
        idsCommunities.push(community);
      }
    }
    relations['communities'] = idsCommunities;
  }
}

function organizationNormalizeOrganizationAzure(
  organization: OrganizationEntityState,
  organizationAzuresToNormalize: OrganizationAzureEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationAzures?.length && typeof organization.organizationAzures[0] !== 'number') {
    const idsOrganizationAzures: number[] = [];
    for (let i = 0; i < organization.organizationAzures.length; i++) {
      const organizationAzure: OrganizationAzure | number = organization.organizationAzures[i];
      if (typeof organizationAzure !== 'number') {
        organizationAzuresToNormalize.push({ ...organizationAzure, organization: organization.idOrganization });
        idsOrganizationAzures.push(organizationAzure.idOrganizationAzure);
      } else {
        idsOrganizationAzures.push(organizationAzure);
      }
    }
    relations['organizationAzures'] = idsOrganizationAzures;
  }
}

function organizationNormalizeOrganizationElement(
  organization: OrganizationEntityState,
  organizationElementsToNormalize: OrganizationElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationElements?.length && typeof organization.organizationElements[0] !== 'number') {
    const idsOrganizationElements: number[] = [];
    for (let i = 0; i < organization.organizationElements.length; i++) {
      const organizationElement: OrganizationElement | number = organization.organizationElements[i];
      if (typeof organizationElement !== 'number') {
        organizationElementsToNormalize.push({ ...organizationElement, organization: organization.idOrganization });
        idsOrganizationElements.push(organizationElement.idOrganizationElement);
      } else {
        idsOrganizationElements.push(organizationElement);
      }
    }
    relations['organizationElements'] = idsOrganizationElements;
  }
}

function organizationNormalizeGroup(
  organization: OrganizationEntityState,
  groupsToNormalize: GroupEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.groups?.length && typeof organization.groups[0] !== 'number') {
    const idsGroups: number[] = [];
    for (let i = 0; i < organization.groups.length; i++) {
      const group: Group | number = organization.groups[i];
      if (typeof group !== 'number') {
        groupsToNormalize.push({ ...group, organization: organization.idOrganization });
        idsGroups.push(group.idGroup);
      } else {
        idsGroups.push(group);
      }
    }
    relations['groups'] = idsGroups;
  }
}

function organizationNormalizeOrganizationUser(
  organization: OrganizationEntityState,
  organizationUsersToNormalize: OrganizationUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationUsers?.length && typeof organization.organizationUsers[0] !== 'number') {
    const idsOrganizationUsers: number[] = [];
    for (let i = 0; i < organization.organizationUsers.length; i++) {
      const organizationUser: OrganizationUser | number = organization.organizationUsers[i];
      if (typeof organizationUser !== 'number') {
        organizationUsersToNormalize.push({ ...organizationUser, organization: organization.idOrganization });
        idsOrganizationUsers.push(organizationUser.idOrganizationUser);
      } else {
        idsOrganizationUsers.push(organizationUser);
      }
    }
    relations['organizationUsers'] = idsOrganizationUsers;
  }
}

function organizationNormalizeOrganizationFamily(
  organization: OrganizationEntityState,
  organizationFamilysToNormalize: OrganizationFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationFamilys?.length && typeof organization.organizationFamilys[0] !== 'number') {
    const idsOrganizationFamilys: number[] = [];
    for (let i = 0; i < organization.organizationFamilys.length; i++) {
      const organizationFamily: OrganizationFamily | number = organization.organizationFamilys[i];
      if (typeof organizationFamily !== 'number') {
        organizationFamilysToNormalize.push({ ...organizationFamily, organization: organization.idOrganization });
        idsOrganizationFamilys.push(organizationFamily.idOrganizationFamily);
      } else {
        idsOrganizationFamilys.push(organizationFamily);
      }
    }
    relations['organizationFamilys'] = idsOrganizationFamilys;
  }
}

function organizationNormalizeOrganizationMilestoneFamily(
  organization: OrganizationEntityState,
  organizationMilestoneFamilysToNormalize: OrganizationMilestoneFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationMilestoneFamilys?.length &&
    typeof organization.organizationMilestoneFamilys[0] !== 'number'
  ) {
    const idsOrganizationMilestoneFamilys: number[] = [];
    for (let i = 0; i < organization.organizationMilestoneFamilys.length; i++) {
      const organizationMilestoneFamily: OrganizationMilestoneFamily | number =
        organization.organizationMilestoneFamilys[i];
      if (typeof organizationMilestoneFamily !== 'number') {
        organizationMilestoneFamilysToNormalize.push({
          ...organizationMilestoneFamily,
          organization: organization.idOrganization
        });
        idsOrganizationMilestoneFamilys.push(organizationMilestoneFamily.idOrganizationMilestoneFamily);
      } else {
        idsOrganizationMilestoneFamilys.push(organizationMilestoneFamily);
      }
    }
    relations['organizationMilestoneFamilys'] = idsOrganizationMilestoneFamilys;
  }
}

function organizationNormalizeOrganizationMilestone(
  organization: OrganizationEntityState,
  organizationMilestonesToNormalize: OrganizationMilestoneEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationMilestones?.length && typeof organization.organizationMilestones[0] !== 'number') {
    const idsOrganizationMilestones: number[] = [];
    for (let i = 0; i < organization.organizationMilestones.length; i++) {
      const organizationMilestone: OrganizationMilestone | number = organization.organizationMilestones[i];
      if (typeof organizationMilestone !== 'number') {
        organizationMilestonesToNormalize.push({ ...organizationMilestone, organization: organization.idOrganization });
        idsOrganizationMilestones.push(organizationMilestone.idOrganizationMilestone);
      } else {
        idsOrganizationMilestones.push(organizationMilestone);
      }
    }
    relations['organizationMilestones'] = idsOrganizationMilestones;
  }
}

function organizationNormalizeOrganizationRisk(
  organization: OrganizationEntityState,
  organizationRisksToNormalize: OrganizationRiskEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationRisks?.length && typeof organization.organizationRisks[0] !== 'number') {
    const idsOrganizationRisks: number[] = [];
    for (let i = 0; i < organization.organizationRisks.length; i++) {
      const organizationRisk: OrganizationRisk | number = organization.organizationRisks[i];
      if (typeof organizationRisk !== 'number') {
        organizationRisksToNormalize.push({ ...organizationRisk, organization: organization.idOrganization });
        idsOrganizationRisks.push(organizationRisk.idOrganizationRisk);
      } else {
        idsOrganizationRisks.push(organizationRisk);
      }
    }
    relations['organizationRisks'] = idsOrganizationRisks;
  }
}

function organizationNormalizeOrganizationKpi(
  organization: OrganizationEntityState,
  organizationKpisToNormalize: OrganizationKpiEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationKpis?.length && typeof organization.organizationKpis[0] !== 'number') {
    const idsOrganizationKpis: number[] = [];
    for (let i = 0; i < organization.organizationKpis.length; i++) {
      const organizationKpi: OrganizationKpi | number = organization.organizationKpis[i];
      if (typeof organizationKpi !== 'number') {
        organizationKpisToNormalize.push({ ...organizationKpi, organization: organization.idOrganization });
        idsOrganizationKpis.push(organizationKpi.idOrganizationKpi);
      } else {
        idsOrganizationKpis.push(organizationKpi);
      }
    }
    relations['organizationKpis'] = idsOrganizationKpis;
  }
}

function organizationNormalizeOrganizationSubFamily(
  organization: OrganizationEntityState,
  organizationSubFamilysToNormalize: OrganizationSubFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationSubFamilys?.length && typeof organization.organizationSubFamilys[0] !== 'number') {
    const idsOrganizationSubFamilys: number[] = [];
    for (let i = 0; i < organization.organizationSubFamilys.length; i++) {
      const organizationSubFamily: OrganizationSubFamily | number = organization.organizationSubFamilys[i];
      if (typeof organizationSubFamily !== 'number') {
        organizationSubFamilysToNormalize.push({ ...organizationSubFamily, organization: organization.idOrganization });
        idsOrganizationSubFamilys.push(organizationSubFamily.idOrganizationSubFamily);
      } else {
        idsOrganizationSubFamilys.push(organizationSubFamily);
      }
    }
    relations['organizationSubFamilys'] = idsOrganizationSubFamilys;
  }
}

function organizationNormalizeOrganizationCaracteristic(
  organization: OrganizationEntityState,
  organizationCaracteristicsToNormalize: OrganizationCaracteristicEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationCaracteristics?.length &&
    typeof organization.organizationCaracteristics[0] !== 'number'
  ) {
    const idsOrganizationCaracteristics: number[] = [];
    for (let i = 0; i < organization.organizationCaracteristics.length; i++) {
      const organizationCaracteristic: OrganizationCaracteristic | number = organization.organizationCaracteristics[i];
      if (typeof organizationCaracteristic !== 'number') {
        organizationCaracteristicsToNormalize.push({
          ...organizationCaracteristic,
          organization: organization.idOrganization
        });
        idsOrganizationCaracteristics.push(organizationCaracteristic.idOrganizationCaracteristic);
      } else {
        idsOrganizationCaracteristics.push(organizationCaracteristic);
      }
    }
    relations['organizationCaracteristics'] = idsOrganizationCaracteristics;
  }
}

function organizationNormalizeOrganizationUserProfil(
  organization: OrganizationEntityState,
  organizationUserProfilsToNormalize: OrganizationUserProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationUserProfils?.length && typeof organization.organizationUserProfils[0] !== 'number') {
    const idsOrganizationUserProfils: number[] = [];
    for (let i = 0; i < organization.organizationUserProfils.length; i++) {
      const organizationUserProfil: OrganizationUserProfil | number = organization.organizationUserProfils[i];
      if (typeof organizationUserProfil !== 'number') {
        organizationUserProfilsToNormalize.push({
          ...organizationUserProfil,
          organization: organization.idOrganization
        });
        idsOrganizationUserProfils.push(organizationUserProfil.idOrganizationUserProfil);
      } else {
        idsOrganizationUserProfils.push(organizationUserProfil);
      }
    }
    relations['organizationUserProfils'] = idsOrganizationUserProfils;
  }
}

function organizationNormalizeOrganizationStep(
  organization: OrganizationEntityState,
  organizationStepsToNormalize: OrganizationStepEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationSteps?.length && typeof organization.organizationSteps[0] !== 'number') {
    const idsOrganizationSteps: number[] = [];
    for (let i = 0; i < organization.organizationSteps.length; i++) {
      const organizationStep: OrganizationStep | number = organization.organizationSteps[i];
      if (typeof organizationStep !== 'number') {
        organizationStepsToNormalize.push({ ...organizationStep, organization: organization.idOrganization });
        idsOrganizationSteps.push(organizationStep.idOrganizationStep);
      } else {
        idsOrganizationSteps.push(organizationStep);
      }
    }
    relations['organizationSteps'] = idsOrganizationSteps;
  }
}

function organizationNormalizeOrganizationProjectModule(
  organization: OrganizationEntityState,
  organizationProjectModulesToNormalize: OrganizationProjectModuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationProjectModules?.length &&
    typeof organization.organizationProjectModules[0] !== 'number'
  ) {
    const idsOrganizationProjectModules: number[] = [];
    for (let i = 0; i < organization.organizationProjectModules.length; i++) {
      const organizationProjectModule: OrganizationProjectModule | number = organization.organizationProjectModules[i];
      if (typeof organizationProjectModule !== 'number') {
        organizationProjectModulesToNormalize.push({
          ...organizationProjectModule,
          organization: organization.idOrganization
        });
        idsOrganizationProjectModules.push(organizationProjectModule.idOrganizationProjectModule);
      } else {
        idsOrganizationProjectModules.push(organizationProjectModule);
      }
    }
    relations['organizationProjectModules'] = idsOrganizationProjectModules;
  }
}

function organizationNormalizeOrganizationSection(
  organization: OrganizationEntityState,
  organizationSectionsToNormalize: OrganizationSectionEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationSections?.length && typeof organization.organizationSections[0] !== 'number') {
    const idsOrganizationSections: number[] = [];
    for (let i = 0; i < organization.organizationSections.length; i++) {
      const organizationSection: OrganizationSection | number = organization.organizationSections[i];
      if (typeof organizationSection !== 'number') {
        organizationSectionsToNormalize.push({ ...organizationSection, organization: organization.idOrganization });
        idsOrganizationSections.push(organizationSection.idOrganizationSection);
      } else {
        idsOrganizationSections.push(organizationSection);
      }
    }
    relations['organizationSections'] = idsOrganizationSections;
  }
}

function organizationNormalizeProfil(
  organization: OrganizationEntityState,
  profilsToNormalize: ProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.profils?.length && typeof organization.profils[0] !== 'number') {
    const idsProfils: number[] = [];
    for (let i = 0; i < organization.profils.length; i++) {
      const profil: Profil | number = organization.profils[i];
      if (typeof profil !== 'number') {
        profilsToNormalize.push({ ...profil, organization: organization.idOrganization });
        idsProfils.push(profil.idProfil);
      } else {
        idsProfils.push(profil);
      }
    }
    relations['profils'] = idsProfils;
  }
}

function organizationNormalizeUser(
  organization: OrganizationEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organization.users?.length && typeof organization.users[0] !== 'number') {
    const idsUsers: number[] = [];
    for (let i = 0; i < organization.users.length; i++) {
      const user: User | number = organization.users[i];

      if (typeof user !== 'number') {
        if (!usersToNormalize[user.idUser]) {
          usersToNormalize[user.idUser] = {
            ...user,
            organizations: user.organizations?.concat() ?? []
          };
        }
        (usersToNormalize[user.idUser].organizations as number[]).push(organization.idOrganization);
        idsUsers.push(user.idUser);
      } else {
        idsUsers.push(user);
      }
    }
    relations['users'] = idsUsers;
  }
}

function organizationNormalizeSection(
  organization: OrganizationEntityState,
  sectionsToNormalize: { [id: number]: SectionEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organization.sections?.length && typeof organization.sections[0] !== 'number') {
    const idsSections: number[] = [];
    for (let i = 0; i < organization.sections.length; i++) {
      const section: Section | number = organization.sections[i];

      if (typeof section !== 'number') {
        if (!sectionsToNormalize[section.idSection]) {
          sectionsToNormalize[section.idSection] = {
            ...section,
            organizations: section.organizations?.concat() ?? []
          };
        }
        (sectionsToNormalize[section.idSection].organizations as number[]).push(organization.idOrganization);
        idsSections.push(section.idSection);
      } else {
        idsSections.push(section);
      }
    }
    relations['sections'] = idsSections;
  }
}

/**
 *  OrganizationFamily
 */
export function getActionsToNormalizeOrganizationFamily(
  organizationFamilys: OrganizationFamilyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationSubFamilysToNormalize: OrganizationSubFamilyEntityState[] = [];
  const communitiesToNormalize: CommunityEntityState[] = [];
  const organizationStepsToNormalize: OrganizationStepEntityState[] = [];
  const organizationKpisToNormalize: OrganizationKpiEntityState[] = [];
  const organizationDataColumnsToNormalize: OrganizationDataColumnEntityState[] = [];
  const organizationMilestoneFamilysToNormalize: OrganizationMilestoneFamilyEntityState[] = [];
  const organizationCaracteristicsToNormalize: OrganizationCaracteristicEntityState[] = [];
  const organizationMilestonesToNormalize: OrganizationMilestoneEntityState[] = [];
  const organizationRisksToNormalize: OrganizationRiskEntityState[] = [];
  const organizationUserProfilsToNormalize: OrganizationUserProfilEntityState[] = [];
  const userBoardStatesToNormalize: UserBoardStateEntityState[] = [];

  const normalizedOrganizationFamilys = organizationFamilys.map((organizationFamily: OrganizationFamilyEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationFamilyNormalizeOrganizationSubFamily(organizationFamily, organizationSubFamilysToNormalize, relations);
    organizationFamilyNormalizeCommunity(organizationFamily, communitiesToNormalize, relations);
    organizationFamilyNormalizeOrganizationStep(organizationFamily, organizationStepsToNormalize, relations);
    organizationFamilyNormalizeOrganizationKpi(organizationFamily, organizationKpisToNormalize, relations);
    organizationFamilyNormalizeOrganizationDataColumn(
      organizationFamily,
      organizationDataColumnsToNormalize,
      relations
    );
    organizationFamilyNormalizeOrganizationMilestoneFamily(
      organizationFamily,
      organizationMilestoneFamilysToNormalize,
      relations
    );
    organizationFamilyNormalizeOrganizationCaracteristic(
      organizationFamily,
      organizationCaracteristicsToNormalize,
      relations
    );
    organizationFamilyNormalizeOrganizationMilestone(organizationFamily, organizationMilestonesToNormalize, relations);
    organizationFamilyNormalizeOrganizationRisk(organizationFamily, organizationRisksToNormalize, relations);
    organizationFamilyNormalizeOrganizationUserProfil(
      organizationFamily,
      organizationUserProfilsToNormalize,
      relations
    );
    organizationFamilyNormalizeUserBoardState(organizationFamily, userBoardStatesToNormalize, relations);
    organizationFamilyNormalizeOrganization(organizationFamily, organizationsToNormalize, relations);

    return {
      ...organizationFamily,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (organizationSubFamilysToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationSubFamily(organizationSubFamilysToNormalize, actionType));
  }
  if (communitiesToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalize, actionType));
  }
  if (organizationStepsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationStep(organizationStepsToNormalize, actionType));
  }
  if (organizationKpisToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationKpi(organizationKpisToNormalize, actionType));
  }
  if (organizationDataColumnsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationDataColumn(organizationDataColumnsToNormalize, actionType));
  }
  if (organizationMilestoneFamilysToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationMilestoneFamily(organizationMilestoneFamilysToNormalize, actionType)
    );
  }
  if (organizationCaracteristicsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationCaracteristic(organizationCaracteristicsToNormalize, actionType));
  }
  if (organizationMilestonesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationMilestone(organizationMilestonesToNormalize, actionType));
  }
  if (organizationRisksToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationRisk(organizationRisksToNormalize, actionType));
  }
  if (organizationUserProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationUserProfil(organizationUserProfilsToNormalize, actionType));
  }
  if (userBoardStatesToNormalize.length) {
    actions.push(...getActionsToNormalizeUserBoardState(userBoardStatesToNormalize, actionType));
  }

  const organizationFamilyActionCreator = OrganizationFamilyGeneratedActions.upsertManyOrganizationFamilysSuccess;
  actions.push(organizationFamilyActionCreator({ organizationFamilys: normalizedOrganizationFamilys }));

  return actions;
}

function organizationFamilyNormalizeOrganizationSubFamily(
  organizationFamily: OrganizationFamilyEntityState,
  organizationSubFamilysToNormalize: OrganizationSubFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationFamily.organizationSubFamilys?.length &&
    typeof organizationFamily.organizationSubFamilys[0] !== 'number'
  ) {
    const idsOrganizationSubFamilys: number[] = [];
    for (let i = 0; i < organizationFamily.organizationSubFamilys.length; i++) {
      const organizationSubFamily: OrganizationSubFamily | number = organizationFamily.organizationSubFamilys[i];
      if (typeof organizationSubFamily !== 'number') {
        organizationSubFamilysToNormalize.push({
          ...organizationSubFamily,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsOrganizationSubFamilys.push(organizationSubFamily.idOrganizationSubFamily);
      } else {
        idsOrganizationSubFamilys.push(organizationSubFamily);
      }
    }
    relations['organizationSubFamilys'] = idsOrganizationSubFamilys;
  }
}

function organizationFamilyNormalizeCommunity(
  organizationFamily: OrganizationFamilyEntityState,
  communitiesToNormalize: CommunityEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationFamily.communities?.length && typeof organizationFamily.communities[0] !== 'number') {
    const idsCommunities: number[] = [];
    for (let i = 0; i < organizationFamily.communities.length; i++) {
      const community: Community | number = organizationFamily.communities[i];
      if (typeof community !== 'number') {
        communitiesToNormalize.push({ ...community, organizationFamily: organizationFamily.idOrganizationFamily });
        idsCommunities.push(community.idCommunity);
      } else {
        idsCommunities.push(community);
      }
    }
    relations['communities'] = idsCommunities;
  }
}

function organizationFamilyNormalizeOrganizationStep(
  organizationFamily: OrganizationFamilyEntityState,
  organizationStepsToNormalize: OrganizationStepEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationFamily.organizationSteps?.length && typeof organizationFamily.organizationSteps[0] !== 'number') {
    const idsOrganizationSteps: number[] = [];
    for (let i = 0; i < organizationFamily.organizationSteps.length; i++) {
      const organizationStep: OrganizationStep | number = organizationFamily.organizationSteps[i];
      if (typeof organizationStep !== 'number') {
        organizationStepsToNormalize.push({
          ...organizationStep,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsOrganizationSteps.push(organizationStep.idOrganizationStep);
      } else {
        idsOrganizationSteps.push(organizationStep);
      }
    }
    relations['organizationSteps'] = idsOrganizationSteps;
  }
}

function organizationFamilyNormalizeOrganizationKpi(
  organizationFamily: OrganizationFamilyEntityState,
  organizationKpisToNormalize: OrganizationKpiEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationFamily.organizationKpis?.length && typeof organizationFamily.organizationKpis[0] !== 'number') {
    const idsOrganizationKpis: number[] = [];
    for (let i = 0; i < organizationFamily.organizationKpis.length; i++) {
      const organizationKpi: OrganizationKpi | number = organizationFamily.organizationKpis[i];
      if (typeof organizationKpi !== 'number') {
        organizationKpisToNormalize.push({
          ...organizationKpi,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsOrganizationKpis.push(organizationKpi.idOrganizationKpi);
      } else {
        idsOrganizationKpis.push(organizationKpi);
      }
    }
    relations['organizationKpis'] = idsOrganizationKpis;
  }
}

function organizationFamilyNormalizeOrganizationDataColumn(
  organizationFamily: OrganizationFamilyEntityState,
  organizationDataColumnsToNormalize: OrganizationDataColumnEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationFamily.organizationDataColumns?.length &&
    typeof organizationFamily.organizationDataColumns[0] !== 'number'
  ) {
    const idsOrganizationDataColumns: number[] = [];
    for (let i = 0; i < organizationFamily.organizationDataColumns.length; i++) {
      const organizationDataColumn: OrganizationDataColumn | number = organizationFamily.organizationDataColumns[i];
      if (typeof organizationDataColumn !== 'number') {
        organizationDataColumnsToNormalize.push({
          ...organizationDataColumn,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsOrganizationDataColumns.push(organizationDataColumn.idOrganizationDataColumn);
      } else {
        idsOrganizationDataColumns.push(organizationDataColumn);
      }
    }
    relations['organizationDataColumns'] = idsOrganizationDataColumns;
  }
}

function organizationFamilyNormalizeOrganizationMilestoneFamily(
  organizationFamily: OrganizationFamilyEntityState,
  organizationMilestoneFamilysToNormalize: OrganizationMilestoneFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationFamily.organizationMilestoneFamilys?.length &&
    typeof organizationFamily.organizationMilestoneFamilys[0] !== 'number'
  ) {
    const idsOrganizationMilestoneFamilys: number[] = [];
    for (let i = 0; i < organizationFamily.organizationMilestoneFamilys.length; i++) {
      const organizationMilestoneFamily: OrganizationMilestoneFamily | number =
        organizationFamily.organizationMilestoneFamilys[i];
      if (typeof organizationMilestoneFamily !== 'number') {
        organizationMilestoneFamilysToNormalize.push({
          ...organizationMilestoneFamily,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsOrganizationMilestoneFamilys.push(organizationMilestoneFamily.idOrganizationMilestoneFamily);
      } else {
        idsOrganizationMilestoneFamilys.push(organizationMilestoneFamily);
      }
    }
    relations['organizationMilestoneFamilys'] = idsOrganizationMilestoneFamilys;
  }
}

function organizationFamilyNormalizeOrganizationCaracteristic(
  organizationFamily: OrganizationFamilyEntityState,
  organizationCaracteristicsToNormalize: OrganizationCaracteristicEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationFamily.organizationCaracteristics?.length &&
    typeof organizationFamily.organizationCaracteristics[0] !== 'number'
  ) {
    const idsOrganizationCaracteristics: number[] = [];
    for (let i = 0; i < organizationFamily.organizationCaracteristics.length; i++) {
      const organizationCaracteristic: OrganizationCaracteristic | number =
        organizationFamily.organizationCaracteristics[i];
      if (typeof organizationCaracteristic !== 'number') {
        organizationCaracteristicsToNormalize.push({
          ...organizationCaracteristic,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsOrganizationCaracteristics.push(organizationCaracteristic.idOrganizationCaracteristic);
      } else {
        idsOrganizationCaracteristics.push(organizationCaracteristic);
      }
    }
    relations['organizationCaracteristics'] = idsOrganizationCaracteristics;
  }
}

function organizationFamilyNormalizeOrganizationMilestone(
  organizationFamily: OrganizationFamilyEntityState,
  organizationMilestonesToNormalize: OrganizationMilestoneEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationFamily.organizationMilestones?.length &&
    typeof organizationFamily.organizationMilestones[0] !== 'number'
  ) {
    const idsOrganizationMilestones: number[] = [];
    for (let i = 0; i < organizationFamily.organizationMilestones.length; i++) {
      const organizationMilestone: OrganizationMilestone | number = organizationFamily.organizationMilestones[i];
      if (typeof organizationMilestone !== 'number') {
        organizationMilestonesToNormalize.push({
          ...organizationMilestone,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsOrganizationMilestones.push(organizationMilestone.idOrganizationMilestone);
      } else {
        idsOrganizationMilestones.push(organizationMilestone);
      }
    }
    relations['organizationMilestones'] = idsOrganizationMilestones;
  }
}

function organizationFamilyNormalizeOrganizationRisk(
  organizationFamily: OrganizationFamilyEntityState,
  organizationRisksToNormalize: OrganizationRiskEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationFamily.organizationRisks?.length && typeof organizationFamily.organizationRisks[0] !== 'number') {
    const idsOrganizationRisks: number[] = [];
    for (let i = 0; i < organizationFamily.organizationRisks.length; i++) {
      const organizationRisk: OrganizationRisk | number = organizationFamily.organizationRisks[i];
      if (typeof organizationRisk !== 'number') {
        organizationRisksToNormalize.push({
          ...organizationRisk,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsOrganizationRisks.push(organizationRisk.idOrganizationRisk);
      } else {
        idsOrganizationRisks.push(organizationRisk);
      }
    }
    relations['organizationRisks'] = idsOrganizationRisks;
  }
}

function organizationFamilyNormalizeOrganizationUserProfil(
  organizationFamily: OrganizationFamilyEntityState,
  organizationUserProfilsToNormalize: OrganizationUserProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationFamily.organizationUserProfils?.length &&
    typeof organizationFamily.organizationUserProfils[0] !== 'number'
  ) {
    const idsOrganizationUserProfils: number[] = [];
    for (let i = 0; i < organizationFamily.organizationUserProfils.length; i++) {
      const organizationUserProfil: OrganizationUserProfil | number = organizationFamily.organizationUserProfils[i];
      if (typeof organizationUserProfil !== 'number') {
        organizationUserProfilsToNormalize.push({
          ...organizationUserProfil,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsOrganizationUserProfils.push(organizationUserProfil.idOrganizationUserProfil);
      } else {
        idsOrganizationUserProfils.push(organizationUserProfil);
      }
    }
    relations['organizationUserProfils'] = idsOrganizationUserProfils;
  }
}

function organizationFamilyNormalizeUserBoardState(
  organizationFamily: OrganizationFamilyEntityState,
  userBoardStatesToNormalize: UserBoardStateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationFamily.userBoardStates?.length && typeof organizationFamily.userBoardStates[0] !== 'number') {
    const idsUserBoardStates: number[] = [];
    for (let i = 0; i < organizationFamily.userBoardStates.length; i++) {
      const userBoardState: UserBoardState | number = organizationFamily.userBoardStates[i];
      if (typeof userBoardState !== 'number') {
        userBoardStatesToNormalize.push({
          ...userBoardState,
          organizationFamily: organizationFamily.idOrganizationFamily
        });
        idsUserBoardStates.push(userBoardState.idUserBoardState);
      } else {
        idsUserBoardStates.push(userBoardState);
      }
    }
    relations['userBoardStates'] = idsUserBoardStates;
  }
}

function organizationFamilyNormalizeOrganization(
  organizationFamily: OrganizationFamilyEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationFamily.organization && typeof organizationFamily.organization !== 'number') {
    if (!organizationsToNormalize[organizationFamily.organization.idOrganization]) {
      organizationsToNormalize[organizationFamily.organization.idOrganization] = {
        ...organizationFamily.organization,
        organizationFamilys: organizationFamily.organization.organizationFamilys?.concat() ?? []
      };
    }
    (organizationsToNormalize[organizationFamily.organization.idOrganization].organizationFamilys as number[]).push(
      organizationFamily.idOrganizationFamily
    );
    relations['organization'] = organizationFamily.organization.idOrganization;
  }
}

/**
 *  OrganizationKpi
 */
export function getActionsToNormalizeOrganizationKpi(
  organizationKpis: OrganizationKpiEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const communityKpisToNormalize: CommunityKpiEntityState[] = [];

  const normalizedOrganizationKpis = organizationKpis.map((organizationKpi: OrganizationKpiEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationKpiNormalizeCommunityKpi(organizationKpi, communityKpisToNormalize, relations);
    organizationKpiNormalizeOrganization(organizationKpi, organizationsToNormalize, relations);
    organizationKpiNormalizeOrganizationFamily(organizationKpi, organizationFamilysToNormalize, relations);

    return {
      ...organizationKpi,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  if (communityKpisToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityKpi(communityKpisToNormalize, actionType));
  }

  const organizationKpiActionCreator = OrganizationKpiGeneratedActions.upsertManyOrganizationKpisSuccess;
  actions.push(organizationKpiActionCreator({ organizationKpis: normalizedOrganizationKpis }));

  return actions;
}

function organizationKpiNormalizeCommunityKpi(
  organizationKpi: OrganizationKpiEntityState,
  communityKpisToNormalize: CommunityKpiEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationKpi.communityKpis?.length && typeof organizationKpi.communityKpis[0] !== 'number') {
    const idsCommunityKpis: number[] = [];
    for (let i = 0; i < organizationKpi.communityKpis.length; i++) {
      const communityKpi: CommunityKpi | number = organizationKpi.communityKpis[i];
      if (typeof communityKpi !== 'number') {
        communityKpisToNormalize.push({ ...communityKpi, organizationKpi: organizationKpi.idOrganizationKpi });
        idsCommunityKpis.push(communityKpi.idCommunityKpi);
      } else {
        idsCommunityKpis.push(communityKpi);
      }
    }
    relations['communityKpis'] = idsCommunityKpis;
  }
}

function organizationKpiNormalizeOrganization(
  organizationKpi: OrganizationKpiEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationKpi.organization && typeof organizationKpi.organization !== 'number') {
    if (!organizationsToNormalize[organizationKpi.organization.idOrganization]) {
      organizationsToNormalize[organizationKpi.organization.idOrganization] = {
        ...organizationKpi.organization,
        organizationKpis: organizationKpi.organization.organizationKpis?.concat() ?? []
      };
    }
    (organizationsToNormalize[organizationKpi.organization.idOrganization].organizationKpis as number[]).push(
      organizationKpi.idOrganizationKpi
    );
    relations['organization'] = organizationKpi.organization.idOrganization;
  }
}

function organizationKpiNormalizeOrganizationFamily(
  organizationKpi: OrganizationKpiEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationKpi.organizationFamily && typeof organizationKpi.organizationFamily !== 'number') {
    if (!organizationFamilysToNormalize[organizationKpi.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[organizationKpi.organizationFamily.idOrganizationFamily] = {
        ...organizationKpi.organizationFamily,
        organizationKpis: organizationKpi.organizationFamily.organizationKpis?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[organizationKpi.organizationFamily.idOrganizationFamily]
        .organizationKpis as number[]
    ).push(organizationKpi.idOrganizationKpi);
    relations['organizationFamily'] = organizationKpi.organizationFamily.idOrganizationFamily;
  }
}

/**
 *  OrganizationMilestoneFamily
 */
export function getActionsToNormalizeOrganizationMilestoneFamily(
  organizationMilestoneFamilys: OrganizationMilestoneFamilyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const organizationCaracteristicsToNormalize: { [id: number]: OrganizationCaracteristicEntityState } = {};
  const communityMilestoneFamilysToNormalize: CommunityMilestoneFamilyEntityState[] = [];
  const organizationMilestoneAssociationsToNormalize: OrganizationMilestoneAssociationEntityState[] = [];
  const organizationMilestonesToNormalize: { [id: number]: OrganizationMilestoneEntityState } = {};

  const normalizedOrganizationMilestoneFamilys = organizationMilestoneFamilys.map(
    (organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationMilestoneFamilyNormalizeCommunityMilestoneFamily(
        organizationMilestoneFamily,
        communityMilestoneFamilysToNormalize,
        relations
      );
      organizationMilestoneFamilyNormalizeOrganizationMilestoneAssociation(
        organizationMilestoneFamily,
        organizationMilestoneAssociationsToNormalize,
        relations
      );
      organizationMilestoneFamilyNormalizeOrganizationMilestone(
        organizationMilestoneFamily,
        organizationMilestonesToNormalize,
        relations
      );
      organizationMilestoneFamilyNormalizeOrganization(
        organizationMilestoneFamily,
        organizationsToNormalize,
        relations
      );
      organizationMilestoneFamilyNormalizeOrganizationFamily(
        organizationMilestoneFamily,
        organizationFamilysToNormalize,
        relations
      );
      organizationMilestoneFamilyNormalizeOrganizationCaracteristic(
        organizationMilestoneFamily,
        organizationCaracteristicsToNormalize,
        relations
      );

      return {
        ...organizationMilestoneFamily,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  const organizationCaracteristicsToNormalizeArray = Object.values(organizationCaracteristicsToNormalize);
  if (organizationCaracteristicsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristic(organizationCaracteristicsToNormalizeArray, actionType)
    );
  }
  if (communityMilestoneFamilysToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityMilestoneFamily(communityMilestoneFamilysToNormalize, actionType));
  }
  if (organizationMilestoneAssociationsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationMilestoneAssociation(organizationMilestoneAssociationsToNormalize, actionType)
    );
  }
  const organizationMilestonesToNormalizeArray = Object.values(organizationMilestonesToNormalize);
  if (organizationMilestonesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationMilestone(organizationMilestonesToNormalizeArray, actionType));
  }

  const organizationMilestoneFamilyActionCreator =
    OrganizationMilestoneFamilyGeneratedActions.upsertManyOrganizationMilestoneFamilysSuccess;
  actions.push(
    organizationMilestoneFamilyActionCreator({ organizationMilestoneFamilys: normalizedOrganizationMilestoneFamilys })
  );

  return actions;
}

function organizationMilestoneFamilyNormalizeCommunityMilestoneFamily(
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState,
  communityMilestoneFamilysToNormalize: CommunityMilestoneFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestoneFamily.communityMilestoneFamilys?.length &&
    typeof organizationMilestoneFamily.communityMilestoneFamilys[0] !== 'number'
  ) {
    const idsCommunityMilestoneFamilys: number[] = [];
    for (let i = 0; i < organizationMilestoneFamily.communityMilestoneFamilys.length; i++) {
      const communityMilestoneFamily: CommunityMilestoneFamily | number =
        organizationMilestoneFamily.communityMilestoneFamilys[i];
      if (typeof communityMilestoneFamily !== 'number') {
        communityMilestoneFamilysToNormalize.push({
          ...communityMilestoneFamily,
          organizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily
        });
        idsCommunityMilestoneFamilys.push(communityMilestoneFamily.idCommunityMilestoneFamily);
      } else {
        idsCommunityMilestoneFamilys.push(communityMilestoneFamily);
      }
    }
    relations['communityMilestoneFamilys'] = idsCommunityMilestoneFamilys;
  }
}

function organizationMilestoneFamilyNormalizeOrganizationMilestoneAssociation(
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState,
  organizationMilestoneAssociationsToNormalize: OrganizationMilestoneAssociationEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestoneFamily.organizationMilestoneAssociations?.length &&
    typeof organizationMilestoneFamily.organizationMilestoneAssociations[0] !== 'number'
  ) {
    const idsOrganizationMilestoneAssociations: number[] = [];
    for (let i = 0; i < organizationMilestoneFamily.organizationMilestoneAssociations.length; i++) {
      const organizationMilestoneAssociation: OrganizationMilestoneAssociation | number =
        organizationMilestoneFamily.organizationMilestoneAssociations[i];
      if (typeof organizationMilestoneAssociation !== 'number') {
        organizationMilestoneAssociationsToNormalize.push({
          ...organizationMilestoneAssociation,
          organizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily
        });
        idsOrganizationMilestoneAssociations.push(organizationMilestoneAssociation.idOrganizationMilestoneAssociation);
      } else {
        idsOrganizationMilestoneAssociations.push(organizationMilestoneAssociation);
      }
    }
    relations['organizationMilestoneAssociations'] = idsOrganizationMilestoneAssociations;
  }
}

function organizationMilestoneFamilyNormalizeOrganizationMilestone(
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState,
  organizationMilestonesToNormalize: { [id: number]: OrganizationMilestoneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestoneFamily.organizationMilestones?.length &&
    typeof organizationMilestoneFamily.organizationMilestones[0] !== 'number'
  ) {
    const idsOrganizationMilestones: number[] = [];
    for (let i = 0; i < organizationMilestoneFamily.organizationMilestones.length; i++) {
      const organizationMilestone: OrganizationMilestone | number =
        organizationMilestoneFamily.organizationMilestones[i];

      if (typeof organizationMilestone !== 'number') {
        if (!organizationMilestonesToNormalize[organizationMilestone.idOrganizationMilestone]) {
          organizationMilestonesToNormalize[organizationMilestone.idOrganizationMilestone] = {
            ...organizationMilestone,
            organizationMilestoneFamilys: organizationMilestone.organizationMilestoneFamilys?.concat() ?? []
          };
        }
        (
          organizationMilestonesToNormalize[organizationMilestone.idOrganizationMilestone]
            .organizationMilestoneFamilys as number[]
        ).push(organizationMilestoneFamily.idOrganizationMilestoneFamily);
        idsOrganizationMilestones.push(organizationMilestone.idOrganizationMilestone);
      } else {
        idsOrganizationMilestones.push(organizationMilestone);
      }
    }
    relations['organizationMilestones'] = idsOrganizationMilestones;
  }
}

function organizationMilestoneFamilyNormalizeOrganization(
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationMilestoneFamily.organization && typeof organizationMilestoneFamily.organization !== 'number') {
    if (!organizationsToNormalize[organizationMilestoneFamily.organization.idOrganization]) {
      organizationsToNormalize[organizationMilestoneFamily.organization.idOrganization] = {
        ...organizationMilestoneFamily.organization,
        organizationMilestoneFamilys:
          organizationMilestoneFamily.organization.organizationMilestoneFamilys?.concat() ?? []
      };
    }
    (
      organizationsToNormalize[organizationMilestoneFamily.organization.idOrganization]
        .organizationMilestoneFamilys as number[]
    ).push(organizationMilestoneFamily.idOrganizationMilestoneFamily);
    relations['organization'] = organizationMilestoneFamily.organization.idOrganization;
  }
}

function organizationMilestoneFamilyNormalizeOrganizationFamily(
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestoneFamily.organizationFamily &&
    typeof organizationMilestoneFamily.organizationFamily !== 'number'
  ) {
    if (!organizationFamilysToNormalize[organizationMilestoneFamily.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[organizationMilestoneFamily.organizationFamily.idOrganizationFamily] = {
        ...organizationMilestoneFamily.organizationFamily,
        organizationMilestoneFamilys:
          organizationMilestoneFamily.organizationFamily.organizationMilestoneFamilys?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[organizationMilestoneFamily.organizationFamily.idOrganizationFamily]
        .organizationMilestoneFamilys as number[]
    ).push(organizationMilestoneFamily.idOrganizationMilestoneFamily);
    relations['organizationFamily'] = organizationMilestoneFamily.organizationFamily.idOrganizationFamily;
  }
}

function organizationMilestoneFamilyNormalizeOrganizationCaracteristic(
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState,
  organizationCaracteristicsToNormalize: { [id: number]: OrganizationCaracteristicEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestoneFamily.organizationCaracteristic &&
    typeof organizationMilestoneFamily.organizationCaracteristic !== 'number'
  ) {
    if (
      !organizationCaracteristicsToNormalize[
        organizationMilestoneFamily.organizationCaracteristic.idOrganizationCaracteristic
      ]
    ) {
      organizationCaracteristicsToNormalize[
        organizationMilestoneFamily.organizationCaracteristic.idOrganizationCaracteristic
      ] = {
        ...organizationMilestoneFamily.organizationCaracteristic,
        organizationMilestoneFamilys:
          organizationMilestoneFamily.organizationCaracteristic.organizationMilestoneFamilys?.concat() ?? []
      };
    }
    (
      organizationCaracteristicsToNormalize[
        organizationMilestoneFamily.organizationCaracteristic.idOrganizationCaracteristic
      ].organizationMilestoneFamilys as number[]
    ).push(organizationMilestoneFamily.idOrganizationMilestoneFamily);
    relations['organizationCaracteristic'] =
      organizationMilestoneFamily.organizationCaracteristic.idOrganizationCaracteristic;
  }
}

/**
 *  OrganizationMilestoneAssociation
 */
export function getActionsToNormalizeOrganizationMilestoneAssociation(
  organizationMilestoneAssociations: OrganizationMilestoneAssociationEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationMilestonesToNormalize: { [id: number]: OrganizationMilestoneEntityState } = {};
  const organizationMilestoneFamilysToNormalize: { [id: number]: OrganizationMilestoneFamilyEntityState } = {};

  const normalizedOrganizationMilestoneAssociations = organizationMilestoneAssociations.map(
    (organizationMilestoneAssociation: OrganizationMilestoneAssociationEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationMilestoneAssociationNormalizeOrganizationMilestone(
        organizationMilestoneAssociation,
        organizationMilestonesToNormalize,
        relations
      );
      organizationMilestoneAssociationNormalizeOrganizationMilestoneFamily(
        organizationMilestoneAssociation,
        organizationMilestoneFamilysToNormalize,
        relations
      );

      return {
        ...organizationMilestoneAssociation,
        ...relations
      };
    }
  );
  const organizationMilestonesToNormalizeArray = Object.values(organizationMilestonesToNormalize);
  if (organizationMilestonesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationMilestone(organizationMilestonesToNormalizeArray, actionType));
  }
  const organizationMilestoneFamilysToNormalizeArray = Object.values(organizationMilestoneFamilysToNormalize);
  if (organizationMilestoneFamilysToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationMilestoneFamily(organizationMilestoneFamilysToNormalizeArray, actionType)
    );
  }

  const organizationMilestoneAssociationActionCreator =
    OrganizationMilestoneAssociationGeneratedActions.upsertManyOrganizationMilestoneAssociationsSuccess;
  actions.push(
    organizationMilestoneAssociationActionCreator({
      organizationMilestoneAssociations: normalizedOrganizationMilestoneAssociations
    })
  );

  return actions;
}

function organizationMilestoneAssociationNormalizeOrganizationMilestone(
  organizationMilestoneAssociation: OrganizationMilestoneAssociationEntityState,
  organizationMilestonesToNormalize: { [id: number]: OrganizationMilestoneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestoneAssociation.organizationMilestone &&
    typeof organizationMilestoneAssociation.organizationMilestone !== 'number'
  ) {
    if (
      !organizationMilestonesToNormalize[organizationMilestoneAssociation.organizationMilestone.idOrganizationMilestone]
    ) {
      organizationMilestonesToNormalize[
        organizationMilestoneAssociation.organizationMilestone.idOrganizationMilestone
      ] = {
        ...organizationMilestoneAssociation.organizationMilestone,
        organizationMilestoneAssociations:
          organizationMilestoneAssociation.organizationMilestone.organizationMilestoneAssociations?.concat() ?? []
      };
    }
    (
      organizationMilestonesToNormalize[organizationMilestoneAssociation.organizationMilestone.idOrganizationMilestone]
        .organizationMilestoneAssociations as number[]
    ).push(organizationMilestoneAssociation.idOrganizationMilestoneAssociation);
    relations['organizationMilestone'] = organizationMilestoneAssociation.organizationMilestone.idOrganizationMilestone;
  }
}

function organizationMilestoneAssociationNormalizeOrganizationMilestoneFamily(
  organizationMilestoneAssociation: OrganizationMilestoneAssociationEntityState,
  organizationMilestoneFamilysToNormalize: { [id: number]: OrganizationMilestoneFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMilestoneAssociation.organizationMilestoneFamily &&
    typeof organizationMilestoneAssociation.organizationMilestoneFamily !== 'number'
  ) {
    if (
      !organizationMilestoneFamilysToNormalize[
        organizationMilestoneAssociation.organizationMilestoneFamily.idOrganizationMilestoneFamily
      ]
    ) {
      organizationMilestoneFamilysToNormalize[
        organizationMilestoneAssociation.organizationMilestoneFamily.idOrganizationMilestoneFamily
      ] = {
        ...organizationMilestoneAssociation.organizationMilestoneFamily,
        organizationMilestoneAssociations:
          organizationMilestoneAssociation.organizationMilestoneFamily.organizationMilestoneAssociations?.concat() ?? []
      };
    }
    (
      organizationMilestoneFamilysToNormalize[
        organizationMilestoneAssociation.organizationMilestoneFamily.idOrganizationMilestoneFamily
      ].organizationMilestoneAssociations as number[]
    ).push(organizationMilestoneAssociation.idOrganizationMilestoneAssociation);
    relations['organizationMilestoneFamily'] =
      organizationMilestoneAssociation.organizationMilestoneFamily.idOrganizationMilestoneFamily;
  }
}

/**
 *  OrganizationDataColumn
 */
export function getActionsToNormalizeOrganizationDataColumn(
  organizationDataColumns: OrganizationDataColumnEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const organizationDataColumnValuesToNormalize: OrganizationDataColumnValueEntityState[] = [];
  const valeursToNormalize: ValeurEntityState[] = [];
  const organizationCaracteristicRowsToNormalize: OrganizationCaracteristicRowEntityState[] = [];
  const organizationCaracteristicsToNormalize: OrganizationCaracteristicEntityState[] = [];

  const normalizedOrganizationDataColumns = organizationDataColumns.map(
    (organizationDataColumn: OrganizationDataColumnEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationDataColumnNormalizeOrganizationDataColumnValue(
        organizationDataColumn,
        organizationDataColumnValuesToNormalize,
        relations
      );
      organizationDataColumnNormalizeValeur(organizationDataColumn, valeursToNormalize, relations);
      organizationDataColumnNormalizeOrganizationCaracteristicRow(
        organizationDataColumn,
        organizationCaracteristicRowsToNormalize,
        relations
      );
      organizationDataColumnNormalizeOrganizationCaracteristic(
        organizationDataColumn,
        organizationCaracteristicsToNormalize,
        relations
      );
      organizationDataColumnNormalizeOrganizationFamily(
        organizationDataColumn,
        organizationFamilysToNormalize,
        relations
      );

      return {
        ...organizationDataColumn,
        ...relations
      };
    }
  );
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  if (organizationDataColumnValuesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationDataColumnValue(organizationDataColumnValuesToNormalize, actionType)
    );
  }
  if (valeursToNormalize.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalize, actionType));
  }
  if (organizationCaracteristicRowsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristicRow(organizationCaracteristicRowsToNormalize, actionType)
    );
  }
  if (organizationCaracteristicsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationCaracteristic(organizationCaracteristicsToNormalize, actionType));
  }

  const organizationDataColumnActionCreator =
    OrganizationDataColumnGeneratedActions.upsertManyOrganizationDataColumnsSuccess;
  actions.push(organizationDataColumnActionCreator({ organizationDataColumns: normalizedOrganizationDataColumns }));

  return actions;
}

function organizationDataColumnNormalizeOrganizationDataColumnValue(
  organizationDataColumn: OrganizationDataColumnEntityState,
  organizationDataColumnValuesToNormalize: OrganizationDataColumnValueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationDataColumn.organizationDataColumnValues?.length &&
    typeof organizationDataColumn.organizationDataColumnValues[0] !== 'number'
  ) {
    const idsOrganizationDataColumnValues: number[] = [];
    for (let i = 0; i < organizationDataColumn.organizationDataColumnValues.length; i++) {
      const organizationDataColumnValue: OrganizationDataColumnValue | number =
        organizationDataColumn.organizationDataColumnValues[i];
      if (typeof organizationDataColumnValue !== 'number') {
        organizationDataColumnValuesToNormalize.push({
          ...organizationDataColumnValue,
          organizationDataColumn: organizationDataColumn.idOrganizationDataColumn
        });
        idsOrganizationDataColumnValues.push(organizationDataColumnValue.idOrganizationDataColumnValue);
      } else {
        idsOrganizationDataColumnValues.push(organizationDataColumnValue);
      }
    }
    relations['organizationDataColumnValues'] = idsOrganizationDataColumnValues;
  }
}

function organizationDataColumnNormalizeValeur(
  organizationDataColumn: OrganizationDataColumnEntityState,
  valeursToNormalize: ValeurEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationDataColumn.valeurs?.length && typeof organizationDataColumn.valeurs[0] !== 'number') {
    const idsValeurs: number[] = [];
    for (let i = 0; i < organizationDataColumn.valeurs.length; i++) {
      const valeur: Valeur | number = organizationDataColumn.valeurs[i];
      if (typeof valeur !== 'number') {
        valeursToNormalize.push({ ...valeur, organizationDataColumn: organizationDataColumn.idOrganizationDataColumn });
        idsValeurs.push(valeur.idValeur);
      } else {
        idsValeurs.push(valeur);
      }
    }
    relations['valeurs'] = idsValeurs;
  }
}

function organizationDataColumnNormalizeOrganizationCaracteristicRow(
  organizationDataColumn: OrganizationDataColumnEntityState,
  organizationCaracteristicRowsToNormalize: OrganizationCaracteristicRowEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationDataColumn.organizationCaracteristicRows?.length &&
    typeof organizationDataColumn.organizationCaracteristicRows[0] !== 'number'
  ) {
    const idsOrganizationCaracteristicRows: number[] = [];
    for (let i = 0; i < organizationDataColumn.organizationCaracteristicRows.length; i++) {
      const organizationCaracteristicRow: OrganizationCaracteristicRow | number =
        organizationDataColumn.organizationCaracteristicRows[i];
      if (typeof organizationCaracteristicRow !== 'number') {
        organizationCaracteristicRowsToNormalize.push({
          ...organizationCaracteristicRow,
          organizationDataColumn: organizationDataColumn.idOrganizationDataColumn
        });
        idsOrganizationCaracteristicRows.push(organizationCaracteristicRow.idOrganizationCaracteristicRow);
      } else {
        idsOrganizationCaracteristicRows.push(organizationCaracteristicRow);
      }
    }
    relations['organizationCaracteristicRows'] = idsOrganizationCaracteristicRows;
  }
}

function organizationDataColumnNormalizeOrganizationCaracteristic(
  organizationDataColumn: OrganizationDataColumnEntityState,
  organizationCaracteristicsToNormalize: OrganizationCaracteristicEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationDataColumn.organizationCaracteristics?.length &&
    typeof organizationDataColumn.organizationCaracteristics[0] !== 'number'
  ) {
    const idsOrganizationCaracteristics: number[] = [];
    for (let i = 0; i < organizationDataColumn.organizationCaracteristics.length; i++) {
      const organizationCaracteristic: OrganizationCaracteristic | number =
        organizationDataColumn.organizationCaracteristics[i];
      if (typeof organizationCaracteristic !== 'number') {
        organizationCaracteristicsToNormalize.push({
          ...organizationCaracteristic,
          organizationDataColumn: organizationDataColumn.idOrganizationDataColumn
        });
        idsOrganizationCaracteristics.push(organizationCaracteristic.idOrganizationCaracteristic);
      } else {
        idsOrganizationCaracteristics.push(organizationCaracteristic);
      }
    }
    relations['organizationCaracteristics'] = idsOrganizationCaracteristics;
  }
}

function organizationDataColumnNormalizeOrganizationFamily(
  organizationDataColumn: OrganizationDataColumnEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationDataColumn.organizationFamily && typeof organizationDataColumn.organizationFamily !== 'number') {
    if (!organizationFamilysToNormalize[organizationDataColumn.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[organizationDataColumn.organizationFamily.idOrganizationFamily] = {
        ...organizationDataColumn.organizationFamily,
        organizationDataColumns: organizationDataColumn.organizationFamily.organizationDataColumns?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[organizationDataColumn.organizationFamily.idOrganizationFamily]
        .organizationDataColumns as number[]
    ).push(organizationDataColumn.idOrganizationDataColumn);
    relations['organizationFamily'] = organizationDataColumn.organizationFamily.idOrganizationFamily;
  }
}

/**
 *  OrganizationDataColumnValue
 */
export function getActionsToNormalizeOrganizationDataColumnValue(
  organizationDataColumnValues: OrganizationDataColumnValueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationDataColumnsToNormalize: { [id: number]: OrganizationDataColumnEntityState } = {};
  const valeursToNormalize: ValeurEntityState[] = [];
  const organizationCaracteristicValuesToNormalize: OrganizationCaracteristicValueEntityState[] = [];

  const normalizedOrganizationDataColumnValues = organizationDataColumnValues.map(
    (organizationDataColumnValue: OrganizationDataColumnValueEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationDataColumnValueNormalizeValeur(organizationDataColumnValue, valeursToNormalize, relations);
      organizationDataColumnValueNormalizeOrganizationCaracteristicValue(
        organizationDataColumnValue,
        organizationCaracteristicValuesToNormalize,
        relations
      );
      organizationDataColumnValueNormalizeOrganizationDataColumn(
        organizationDataColumnValue,
        organizationDataColumnsToNormalize,
        relations
      );

      return {
        ...organizationDataColumnValue,
        ...relations
      };
    }
  );
  const organizationDataColumnsToNormalizeArray = Object.values(organizationDataColumnsToNormalize);
  if (organizationDataColumnsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationDataColumn(organizationDataColumnsToNormalizeArray, actionType));
  }
  if (valeursToNormalize.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalize, actionType));
  }
  if (organizationCaracteristicValuesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationCaracteristicValue(organizationCaracteristicValuesToNormalize, actionType)
    );
  }

  const organizationDataColumnValueActionCreator =
    OrganizationDataColumnValueGeneratedActions.upsertManyOrganizationDataColumnValuesSuccess;
  actions.push(
    organizationDataColumnValueActionCreator({ organizationDataColumnValues: normalizedOrganizationDataColumnValues })
  );

  return actions;
}

function organizationDataColumnValueNormalizeValeur(
  organizationDataColumnValue: OrganizationDataColumnValueEntityState,
  valeursToNormalize: ValeurEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationDataColumnValue.valeurs?.length && typeof organizationDataColumnValue.valeurs[0] !== 'number') {
    const idsValeurs: number[] = [];
    for (let i = 0; i < organizationDataColumnValue.valeurs.length; i++) {
      const valeur: Valeur | number = organizationDataColumnValue.valeurs[i];
      if (typeof valeur !== 'number') {
        valeursToNormalize.push({
          ...valeur,
          organizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue
        });
        idsValeurs.push(valeur.idValeur);
      } else {
        idsValeurs.push(valeur);
      }
    }
    relations['valeurs'] = idsValeurs;
  }
}

function organizationDataColumnValueNormalizeOrganizationCaracteristicValue(
  organizationDataColumnValue: OrganizationDataColumnValueEntityState,
  organizationCaracteristicValuesToNormalize: OrganizationCaracteristicValueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationDataColumnValue.organizationCaracteristicValues?.length &&
    typeof organizationDataColumnValue.organizationCaracteristicValues[0] !== 'number'
  ) {
    const idsOrganizationCaracteristicValues: number[] = [];
    for (let i = 0; i < organizationDataColumnValue.organizationCaracteristicValues.length; i++) {
      const organizationCaracteristicValue: OrganizationCaracteristicValue | number =
        organizationDataColumnValue.organizationCaracteristicValues[i];
      if (typeof organizationCaracteristicValue !== 'number') {
        organizationCaracteristicValuesToNormalize.push({
          ...organizationCaracteristicValue,
          organizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue
        });
        idsOrganizationCaracteristicValues.push(organizationCaracteristicValue.idOrganizationCaracteristicValue);
      } else {
        idsOrganizationCaracteristicValues.push(organizationCaracteristicValue);
      }
    }
    relations['organizationCaracteristicValues'] = idsOrganizationCaracteristicValues;
  }
}

function organizationDataColumnValueNormalizeOrganizationDataColumn(
  organizationDataColumnValue: OrganizationDataColumnValueEntityState,
  organizationDataColumnsToNormalize: { [id: number]: OrganizationDataColumnEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationDataColumnValue.organizationDataColumn &&
    typeof organizationDataColumnValue.organizationDataColumn !== 'number'
  ) {
    if (
      !organizationDataColumnsToNormalize[organizationDataColumnValue.organizationDataColumn.idOrganizationDataColumn]
    ) {
      organizationDataColumnsToNormalize[organizationDataColumnValue.organizationDataColumn.idOrganizationDataColumn] =
        {
          ...organizationDataColumnValue.organizationDataColumn,
          organizationDataColumnValues:
            organizationDataColumnValue.organizationDataColumn.organizationDataColumnValues?.concat() ?? []
        };
    }
    (
      organizationDataColumnsToNormalize[organizationDataColumnValue.organizationDataColumn.idOrganizationDataColumn]
        .organizationDataColumnValues as number[]
    ).push(organizationDataColumnValue.idOrganizationDataColumnValue);
    relations['organizationDataColumn'] = organizationDataColumnValue.organizationDataColumn.idOrganizationDataColumn;
  }
}

/**
 *  CommunityDataRow
 */
export function getActionsToNormalizeCommunityDataRow(
  communityDataRows: CommunityDataRowEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const valeursToNormalize: ValeurEntityState[] = [];

  const normalizedCommunityDataRows = communityDataRows.map((communityDataRow: CommunityDataRowEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    communityDataRowNormalizeValeur(communityDataRow, valeursToNormalize, relations);
    communityDataRowNormalizeCommunity(communityDataRow, communitiesToNormalize, relations);

    return {
      ...communityDataRow,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  if (valeursToNormalize.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalize, actionType));
  }

  const communityDataRowActionCreator = CommunityDataRowGeneratedActions.upsertManyCommunityDataRowsSuccess;
  actions.push(communityDataRowActionCreator({ communityDataRows: normalizedCommunityDataRows }));

  return actions;
}

function communityDataRowNormalizeValeur(
  communityDataRow: CommunityDataRowEntityState,
  valeursToNormalize: ValeurEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (communityDataRow.valeurs?.length && typeof communityDataRow.valeurs[0] !== 'number') {
    const idsValeurs: number[] = [];
    for (let i = 0; i < communityDataRow.valeurs.length; i++) {
      const valeur: Valeur | number = communityDataRow.valeurs[i];
      if (typeof valeur !== 'number') {
        valeursToNormalize.push({ ...valeur, communityDataRow: communityDataRow.idCommunityDataRow });
        idsValeurs.push(valeur.idValeur);
      } else {
        idsValeurs.push(valeur);
      }
    }
    relations['valeurs'] = idsValeurs;
  }
}

function communityDataRowNormalizeCommunity(
  communityDataRow: CommunityDataRowEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityDataRow.community && typeof communityDataRow.community !== 'number') {
    if (!communitiesToNormalize[communityDataRow.community.idCommunity]) {
      communitiesToNormalize[communityDataRow.community.idCommunity] = {
        ...communityDataRow.community,
        communityDataRows: communityDataRow.community.communityDataRows?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityDataRow.community.idCommunity].communityDataRows as number[]).push(
      communityDataRow.idCommunityDataRow
    );
    relations['community'] = communityDataRow.community.idCommunity;
  }
}

/**
 *  OrganizationProjectModule
 */
export function getActionsToNormalizeOrganizationProjectModule(
  organizationProjectModules: OrganizationProjectModuleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const projectModulesToNormalize: { [id: number]: ProjectModuleEntityState } = {};

  const normalizedOrganizationProjectModules = organizationProjectModules.map(
    (organizationProjectModule: OrganizationProjectModuleEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationProjectModuleNormalizeOrganization(organizationProjectModule, organizationsToNormalize, relations);
      organizationProjectModuleNormalizeProjectModule(organizationProjectModule, projectModulesToNormalize, relations);

      return {
        ...organizationProjectModule,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const projectModulesToNormalizeArray = Object.values(projectModulesToNormalize);
  if (projectModulesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProjectModule(projectModulesToNormalizeArray, actionType));
  }

  const organizationProjectModuleActionCreator =
    OrganizationProjectModuleGeneratedActions.upsertManyOrganizationProjectModulesSuccess;
  actions.push(
    organizationProjectModuleActionCreator({ organizationProjectModules: normalizedOrganizationProjectModules })
  );

  return actions;
}

function organizationProjectModuleNormalizeOrganization(
  organizationProjectModule: OrganizationProjectModuleEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationProjectModule.organization && typeof organizationProjectModule.organization !== 'number') {
    if (!organizationsToNormalize[organizationProjectModule.organization.idOrganization]) {
      organizationsToNormalize[organizationProjectModule.organization.idOrganization] = {
        ...organizationProjectModule.organization,
        organizationProjectModules: organizationProjectModule.organization.organizationProjectModules?.concat() ?? []
      };
    }
    (
      organizationsToNormalize[organizationProjectModule.organization.idOrganization]
        .organizationProjectModules as number[]
    ).push(organizationProjectModule.idOrganizationProjectModule);
    relations['organization'] = organizationProjectModule.organization.idOrganization;
  }
}

function organizationProjectModuleNormalizeProjectModule(
  organizationProjectModule: OrganizationProjectModuleEntityState,
  projectModulesToNormalize: { [id: number]: ProjectModuleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationProjectModule.projectModule && typeof organizationProjectModule.projectModule !== 'number') {
    if (!projectModulesToNormalize[organizationProjectModule.projectModule.idProjectModule]) {
      projectModulesToNormalize[organizationProjectModule.projectModule.idProjectModule] = {
        ...organizationProjectModule.projectModule,
        organizationProjectModules: organizationProjectModule.projectModule.organizationProjectModules?.concat() ?? []
      };
    }
    (
      projectModulesToNormalize[organizationProjectModule.projectModule.idProjectModule]
        .organizationProjectModules as number[]
    ).push(organizationProjectModule.idOrganizationProjectModule);
    relations['projectModule'] = organizationProjectModule.projectModule.idProjectModule;
  }
}

/**
 *  OrganizationRisk
 */
export function getActionsToNormalizeOrganizationRisk(
  organizationRisks: OrganizationRiskEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const communityRisksToNormalize: CommunityRiskEntityState[] = [];

  const normalizedOrganizationRisks = organizationRisks.map((organizationRisk: OrganizationRiskEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationRiskNormalizeCommunityRisk(organizationRisk, communityRisksToNormalize, relations);
    organizationRiskNormalizeOrganization(organizationRisk, organizationsToNormalize, relations);
    organizationRiskNormalizeOrganizationFamily(organizationRisk, organizationFamilysToNormalize, relations);

    return {
      ...organizationRisk,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  if (communityRisksToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityRisk(communityRisksToNormalize, actionType));
  }

  const organizationRiskActionCreator = OrganizationRiskGeneratedActions.upsertManyOrganizationRisksSuccess;
  actions.push(organizationRiskActionCreator({ organizationRisks: normalizedOrganizationRisks }));

  return actions;
}

function organizationRiskNormalizeCommunityRisk(
  organizationRisk: OrganizationRiskEntityState,
  communityRisksToNormalize: CommunityRiskEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationRisk.communityRisks?.length && typeof organizationRisk.communityRisks[0] !== 'number') {
    const idsCommunityRisks: number[] = [];
    for (let i = 0; i < organizationRisk.communityRisks.length; i++) {
      const communityRisk: CommunityRisk | number = organizationRisk.communityRisks[i];
      if (typeof communityRisk !== 'number') {
        communityRisksToNormalize.push({ ...communityRisk, organizationRisk: organizationRisk.idOrganizationRisk });
        idsCommunityRisks.push(communityRisk.idCommunityRisk);
      } else {
        idsCommunityRisks.push(communityRisk);
      }
    }
    relations['communityRisks'] = idsCommunityRisks;
  }
}

function organizationRiskNormalizeOrganization(
  organizationRisk: OrganizationRiskEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationRisk.organization && typeof organizationRisk.organization !== 'number') {
    if (!organizationsToNormalize[organizationRisk.organization.idOrganization]) {
      organizationsToNormalize[organizationRisk.organization.idOrganization] = {
        ...organizationRisk.organization,
        organizationRisks: organizationRisk.organization.organizationRisks?.concat() ?? []
      };
    }
    (organizationsToNormalize[organizationRisk.organization.idOrganization].organizationRisks as number[]).push(
      organizationRisk.idOrganizationRisk
    );
    relations['organization'] = organizationRisk.organization.idOrganization;
  }
}

function organizationRiskNormalizeOrganizationFamily(
  organizationRisk: OrganizationRiskEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationRisk.organizationFamily && typeof organizationRisk.organizationFamily !== 'number') {
    if (!organizationFamilysToNormalize[organizationRisk.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[organizationRisk.organizationFamily.idOrganizationFamily] = {
        ...organizationRisk.organizationFamily,
        organizationRisks: organizationRisk.organizationFamily.organizationRisks?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[organizationRisk.organizationFamily.idOrganizationFamily]
        .organizationRisks as number[]
    ).push(organizationRisk.idOrganizationRisk);
    relations['organizationFamily'] = organizationRisk.organizationFamily.idOrganizationFamily;
  }
}

/**
 *  OrganizationUserProfil
 */
export function getActionsToNormalizeOrganizationUserProfil(
  organizationUserProfils: OrganizationUserProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const communityUserProfilsToNormalize: CommunityUserProfilEntityState[] = [];
  const userProfilRightsToNormalize: UserProfilRightEntityState[] = [];
  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};

  const normalizedOrganizationUserProfils = organizationUserProfils.map(
    (organizationUserProfil: OrganizationUserProfilEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationUserProfilNormalizeCommunityUserProfil(
        organizationUserProfil,
        communityUserProfilsToNormalize,
        relations
      );
      organizationUserProfilNormalizeUserProfilRight(organizationUserProfil, userProfilRightsToNormalize, relations);
      organizationUserProfilNormalizeProfil(organizationUserProfil, profilsToNormalize, relations);
      organizationUserProfilNormalizeOrganization(organizationUserProfil, organizationsToNormalize, relations);
      organizationUserProfilNormalizeOrganizationFamily(
        organizationUserProfil,
        organizationFamilysToNormalize,
        relations
      );

      return {
        ...organizationUserProfil,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  if (communityUserProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityUserProfil(communityUserProfilsToNormalize, actionType));
  }
  if (userProfilRightsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserProfilRight(userProfilRightsToNormalize, actionType));
  }
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }

  const organizationUserProfilActionCreator =
    OrganizationUserProfilGeneratedActions.upsertManyOrganizationUserProfilsSuccess;
  actions.push(organizationUserProfilActionCreator({ organizationUserProfils: normalizedOrganizationUserProfils }));

  return actions;
}

function organizationUserProfilNormalizeCommunityUserProfil(
  organizationUserProfil: OrganizationUserProfilEntityState,
  communityUserProfilsToNormalize: CommunityUserProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationUserProfil.communityUserProfils?.length &&
    typeof organizationUserProfil.communityUserProfils[0] !== 'number'
  ) {
    const idsCommunityUserProfils: number[] = [];
    for (let i = 0; i < organizationUserProfil.communityUserProfils.length; i++) {
      const communityUserProfil: CommunityUserProfil | number = organizationUserProfil.communityUserProfils[i];
      if (typeof communityUserProfil !== 'number') {
        communityUserProfilsToNormalize.push({
          ...communityUserProfil,
          organizationUserProfil: organizationUserProfil.idOrganizationUserProfil
        });
        idsCommunityUserProfils.push(communityUserProfil.idCommunityUserProfil);
      } else {
        idsCommunityUserProfils.push(communityUserProfil);
      }
    }
    relations['communityUserProfils'] = idsCommunityUserProfils;
  }
}

function organizationUserProfilNormalizeUserProfilRight(
  organizationUserProfil: OrganizationUserProfilEntityState,
  userProfilRightsToNormalize: UserProfilRightEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationUserProfil.userProfilRights?.length &&
    typeof organizationUserProfil.userProfilRights[0] !== 'number'
  ) {
    const idsUserProfilRights: number[] = [];
    for (let i = 0; i < organizationUserProfil.userProfilRights.length; i++) {
      const userProfilRight: UserProfilRight | number = organizationUserProfil.userProfilRights[i];
      if (typeof userProfilRight !== 'number') {
        userProfilRightsToNormalize.push({
          ...userProfilRight,
          organizationUserProfil: organizationUserProfil.idOrganizationUserProfil
        });
        idsUserProfilRights.push(userProfilRight.idUserProfilRight);
      } else {
        idsUserProfilRights.push(userProfilRight);
      }
    }
    relations['userProfilRights'] = idsUserProfilRights;
  }
}

function organizationUserProfilNormalizeProfil(
  organizationUserProfil: OrganizationUserProfilEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationUserProfil.profils?.length && typeof organizationUserProfil.profils[0] !== 'number') {
    const idsProfils: number[] = [];
    for (let i = 0; i < organizationUserProfil.profils.length; i++) {
      const profil: Profil | number = organizationUserProfil.profils[i];

      if (typeof profil !== 'number') {
        if (!profilsToNormalize[profil.idProfil]) {
          profilsToNormalize[profil.idProfil] = {
            ...profil,
            organizationUserProfils: profil.organizationUserProfils?.concat() ?? []
          };
        }
        (profilsToNormalize[profil.idProfil].organizationUserProfils as number[]).push(
          organizationUserProfil.idOrganizationUserProfil
        );
        idsProfils.push(profil.idProfil);
      } else {
        idsProfils.push(profil);
      }
    }
    relations['profils'] = idsProfils;
  }
}

function organizationUserProfilNormalizeOrganization(
  organizationUserProfil: OrganizationUserProfilEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationUserProfil.organization && typeof organizationUserProfil.organization !== 'number') {
    if (!organizationsToNormalize[organizationUserProfil.organization.idOrganization]) {
      organizationsToNormalize[organizationUserProfil.organization.idOrganization] = {
        ...organizationUserProfil.organization,
        organizationUserProfils: organizationUserProfil.organization.organizationUserProfils?.concat() ?? []
      };
    }
    (
      organizationsToNormalize[organizationUserProfil.organization.idOrganization].organizationUserProfils as number[]
    ).push(organizationUserProfil.idOrganizationUserProfil);
    relations['organization'] = organizationUserProfil.organization.idOrganization;
  }
}

function organizationUserProfilNormalizeOrganizationFamily(
  organizationUserProfil: OrganizationUserProfilEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationUserProfil.organizationFamily && typeof organizationUserProfil.organizationFamily !== 'number') {
    if (!organizationFamilysToNormalize[organizationUserProfil.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[organizationUserProfil.organizationFamily.idOrganizationFamily] = {
        ...organizationUserProfil.organizationFamily,
        organizationUserProfils: organizationUserProfil.organizationFamily.organizationUserProfils?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[organizationUserProfil.organizationFamily.idOrganizationFamily]
        .organizationUserProfils as number[]
    ).push(organizationUserProfil.idOrganizationUserProfil);
    relations['organizationFamily'] = organizationUserProfil.organizationFamily.idOrganizationFamily;
  }
}

/**
 *  OrganizationSubFamily
 */
export function getActionsToNormalizeOrganizationSubFamily(
  organizationSubFamilys: OrganizationSubFamilyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};
  const communitySubFamilysToNormalize: CommunitySubFamilyEntityState[] = [];
  const userRightsToNormalize: UserRightEntityState[] = [];

  const normalizedOrganizationSubFamilys = organizationSubFamilys.map(
    (organizationSubFamily: OrganizationSubFamilyEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationSubFamilyNormalizeCommunitySubFamily(
        organizationSubFamily,
        communitySubFamilysToNormalize,
        relations
      );
      organizationSubFamilyNormalizeUserRight(organizationSubFamily, userRightsToNormalize, relations);
      organizationSubFamilyNormalizeOrganization(organizationSubFamily, organizationsToNormalize, relations);
      organizationSubFamilyNormalizeOrganizationFamily(
        organizationSubFamily,
        organizationFamilysToNormalize,
        relations
      );

      return {
        ...organizationSubFamily,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }
  if (communitySubFamilysToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunitySubFamily(communitySubFamilysToNormalize, actionType));
  }
  if (userRightsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserRight(userRightsToNormalize, actionType));
  }

  const organizationSubFamilyActionCreator =
    OrganizationSubFamilyGeneratedActions.upsertManyOrganizationSubFamilysSuccess;
  actions.push(organizationSubFamilyActionCreator({ organizationSubFamilys: normalizedOrganizationSubFamilys }));

  return actions;
}

function organizationSubFamilyNormalizeCommunitySubFamily(
  organizationSubFamily: OrganizationSubFamilyEntityState,
  communitySubFamilysToNormalize: CommunitySubFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationSubFamily.communitySubFamilys?.length &&
    typeof organizationSubFamily.communitySubFamilys[0] !== 'number'
  ) {
    const idsCommunitySubFamilys: number[] = [];
    for (let i = 0; i < organizationSubFamily.communitySubFamilys.length; i++) {
      const communitySubFamily: CommunitySubFamily | number = organizationSubFamily.communitySubFamilys[i];
      if (typeof communitySubFamily !== 'number') {
        communitySubFamilysToNormalize.push({
          ...communitySubFamily,
          organizationSubFamily: organizationSubFamily.idOrganizationSubFamily
        });
        idsCommunitySubFamilys.push(communitySubFamily.idCommunitySubFamily);
      } else {
        idsCommunitySubFamilys.push(communitySubFamily);
      }
    }
    relations['communitySubFamilys'] = idsCommunitySubFamilys;
  }
}

function organizationSubFamilyNormalizeUserRight(
  organizationSubFamily: OrganizationSubFamilyEntityState,
  userRightsToNormalize: UserRightEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationSubFamily.userRights?.length && typeof organizationSubFamily.userRights[0] !== 'number') {
    const idsUserRights: number[] = [];
    for (let i = 0; i < organizationSubFamily.userRights.length; i++) {
      const userRight: UserRight | number = organizationSubFamily.userRights[i];
      if (typeof userRight !== 'number') {
        userRightsToNormalize.push({
          ...userRight,
          organizationSubFamily: organizationSubFamily.idOrganizationSubFamily
        });
        idsUserRights.push(userRight.idUserRight);
      } else {
        idsUserRights.push(userRight);
      }
    }
    relations['userRights'] = idsUserRights;
  }
}

function organizationSubFamilyNormalizeOrganization(
  organizationSubFamily: OrganizationSubFamilyEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationSubFamily.organization && typeof organizationSubFamily.organization !== 'number') {
    if (!organizationsToNormalize[organizationSubFamily.organization.idOrganization]) {
      organizationsToNormalize[organizationSubFamily.organization.idOrganization] = {
        ...organizationSubFamily.organization,
        organizationSubFamilys: organizationSubFamily.organization.organizationSubFamilys?.concat() ?? []
      };
    }
    (
      organizationsToNormalize[organizationSubFamily.organization.idOrganization].organizationSubFamilys as number[]
    ).push(organizationSubFamily.idOrganizationSubFamily);
    relations['organization'] = organizationSubFamily.organization.idOrganization;
  }
}

function organizationSubFamilyNormalizeOrganizationFamily(
  organizationSubFamily: OrganizationSubFamilyEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationSubFamily.organizationFamily && typeof organizationSubFamily.organizationFamily !== 'number') {
    if (!organizationFamilysToNormalize[organizationSubFamily.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[organizationSubFamily.organizationFamily.idOrganizationFamily] = {
        ...organizationSubFamily.organizationFamily,
        organizationSubFamilys: organizationSubFamily.organizationFamily.organizationSubFamilys?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[organizationSubFamily.organizationFamily.idOrganizationFamily]
        .organizationSubFamilys as number[]
    ).push(organizationSubFamily.idOrganizationSubFamily);
    relations['organizationFamily'] = organizationSubFamily.organizationFamily.idOrganizationFamily;
  }
}

/**
 *  OrganizationSection
 */
export function getActionsToNormalizeOrganizationSection(
  organizationSections: OrganizationSectionEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const sectionsToNormalize: { [id: number]: SectionEntityState } = {};

  const normalizedOrganizationSections = organizationSections.map(
    (organizationSection: OrganizationSectionEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationSectionNormalizeOrganization(organizationSection, organizationsToNormalize, relations);
      organizationSectionNormalizeSection(organizationSection, sectionsToNormalize, relations);

      return {
        ...organizationSection,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const sectionsToNormalizeArray = Object.values(sectionsToNormalize);
  if (sectionsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSection(sectionsToNormalizeArray, actionType));
  }

  const organizationSectionActionCreator = OrganizationSectionGeneratedActions.upsertManyOrganizationSectionsSuccess;
  actions.push(organizationSectionActionCreator({ organizationSections: normalizedOrganizationSections }));

  return actions;
}

function organizationSectionNormalizeOrganization(
  organizationSection: OrganizationSectionEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationSection.organization && typeof organizationSection.organization !== 'number') {
    if (!organizationsToNormalize[organizationSection.organization.idOrganization]) {
      organizationsToNormalize[organizationSection.organization.idOrganization] = {
        ...organizationSection.organization,
        organizationSections: organizationSection.organization.organizationSections?.concat() ?? []
      };
    }
    (organizationsToNormalize[organizationSection.organization.idOrganization].organizationSections as number[]).push(
      organizationSection.idOrganizationSection
    );
    relations['organization'] = organizationSection.organization.idOrganization;
  }
}

function organizationSectionNormalizeSection(
  organizationSection: OrganizationSectionEntityState,
  sectionsToNormalize: { [id: number]: SectionEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationSection.section && typeof organizationSection.section !== 'number') {
    if (!sectionsToNormalize[organizationSection.section.idSection]) {
      sectionsToNormalize[organizationSection.section.idSection] = {
        ...organizationSection.section,
        organizationSections: organizationSection.section.organizationSections?.concat() ?? []
      };
    }
    (sectionsToNormalize[organizationSection.section.idSection].organizationSections as number[]).push(
      organizationSection.idOrganizationSection
    );
    relations['section'] = organizationSection.section.idSection;
  }
}

/**
 *  OrganizationUser
 */
export function getActionsToNormalizeOrganizationUser(
  organizationUsers: OrganizationUserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const userBoardStatesToNormalize: UserBoardStateEntityState[] = [];
  const userRightsToNormalize: UserRightEntityState[] = [];
  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};

  const normalizedOrganizationUsers = organizationUsers.map((organizationUser: OrganizationUserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationUserNormalizeUserBoardState(organizationUser, userBoardStatesToNormalize, relations);
    organizationUserNormalizeUserRight(organizationUser, userRightsToNormalize, relations);
    organizationUserNormalizeProfil(organizationUser, profilsToNormalize, relations);
    organizationUserNormalizeOrganization(organizationUser, organizationsToNormalize, relations);
    organizationUserNormalizeUser(organizationUser, usersToNormalize, relations);

    return {
      ...organizationUser,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (userBoardStatesToNormalize.length) {
    actions.push(...getActionsToNormalizeUserBoardState(userBoardStatesToNormalize, actionType));
  }
  if (userRightsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserRight(userRightsToNormalize, actionType));
  }
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }

  const organizationUserActionCreator = OrganizationUserGeneratedActions.upsertManyOrganizationUsersSuccess;
  actions.push(organizationUserActionCreator({ organizationUsers: normalizedOrganizationUsers }));

  return actions;
}

function organizationUserNormalizeUserBoardState(
  organizationUser: OrganizationUserEntityState,
  userBoardStatesToNormalize: UserBoardStateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationUser.userBoardStates?.length && typeof organizationUser.userBoardStates[0] !== 'number') {
    const idsUserBoardStates: number[] = [];
    for (let i = 0; i < organizationUser.userBoardStates.length; i++) {
      const userBoardState: UserBoardState | number = organizationUser.userBoardStates[i];
      if (typeof userBoardState !== 'number') {
        userBoardStatesToNormalize.push({ ...userBoardState, organizationUser: organizationUser.idOrganizationUser });
        idsUserBoardStates.push(userBoardState.idUserBoardState);
      } else {
        idsUserBoardStates.push(userBoardState);
      }
    }
    relations['userBoardStates'] = idsUserBoardStates;
  }
}

function organizationUserNormalizeUserRight(
  organizationUser: OrganizationUserEntityState,
  userRightsToNormalize: UserRightEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationUser.userRights?.length && typeof organizationUser.userRights[0] !== 'number') {
    const idsUserRights: number[] = [];
    for (let i = 0; i < organizationUser.userRights.length; i++) {
      const userRight: UserRight | number = organizationUser.userRights[i];
      if (typeof userRight !== 'number') {
        userRightsToNormalize.push({ ...userRight, organizationUser: organizationUser.idOrganizationUser });
        idsUserRights.push(userRight.idUserRight);
      } else {
        idsUserRights.push(userRight);
      }
    }
    relations['userRights'] = idsUserRights;
  }
}

function organizationUserNormalizeProfil(
  organizationUser: OrganizationUserEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationUser.profils?.length && typeof organizationUser.profils[0] !== 'number') {
    const idsProfils: number[] = [];
    for (let i = 0; i < organizationUser.profils.length; i++) {
      const profil: Profil | number = organizationUser.profils[i];

      if (typeof profil !== 'number') {
        if (!profilsToNormalize[profil.idProfil]) {
          profilsToNormalize[profil.idProfil] = {
            ...profil,
            organizationUsers: profil.organizationUsers?.concat() ?? []
          };
        }
        (profilsToNormalize[profil.idProfil].organizationUsers as number[]).push(organizationUser.idOrganizationUser);
        idsProfils.push(profil.idProfil);
      } else {
        idsProfils.push(profil);
      }
    }
    relations['profils'] = idsProfils;
  }
}

function organizationUserNormalizeOrganization(
  organizationUser: OrganizationUserEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationUser.organization && typeof organizationUser.organization !== 'number') {
    if (!organizationsToNormalize[organizationUser.organization.idOrganization]) {
      organizationsToNormalize[organizationUser.organization.idOrganization] = {
        ...organizationUser.organization,
        organizationUsers: organizationUser.organization.organizationUsers?.concat() ?? []
      };
    }
    (organizationsToNormalize[organizationUser.organization.idOrganization].organizationUsers as number[]).push(
      organizationUser.idOrganizationUser
    );
    relations['organization'] = organizationUser.organization.idOrganization;
  }
}

function organizationUserNormalizeUser(
  organizationUser: OrganizationUserEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationUser.user && typeof organizationUser.user !== 'number') {
    if (!usersToNormalize[organizationUser.user.idUser]) {
      usersToNormalize[organizationUser.user.idUser] = {
        ...organizationUser.user,
        organizationUsers: organizationUser.user.organizationUsers?.concat() ?? []
      };
    }
    (usersToNormalize[organizationUser.user.idUser].organizationUsers as number[]).push(
      organizationUser.idOrganizationUser
    );
    relations['user'] = organizationUser.user.idUser;
  }
}

/**
 *  OrganizationElement
 */
export function getActionsToNormalizeOrganizationElement(
  organizationElements: OrganizationElementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const elementsToNormalize: { [id: number]: ElementEntityState } = {};

  const normalizedOrganizationElements = organizationElements.map(
    (organizationElement: OrganizationElementEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationElementNormalizeOrganization(organizationElement, organizationsToNormalize, relations);
      organizationElementNormalizeElement(organizationElement, elementsToNormalize, relations);

      return {
        ...organizationElement,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }

  const organizationElementActionCreator = OrganizationElementGeneratedActions.upsertManyOrganizationElementsSuccess;
  actions.push(organizationElementActionCreator({ organizationElements: normalizedOrganizationElements }));

  return actions;
}

function organizationElementNormalizeOrganization(
  organizationElement: OrganizationElementEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationElement.organization && typeof organizationElement.organization !== 'number') {
    if (!organizationsToNormalize[organizationElement.organization.idOrganization]) {
      organizationsToNormalize[organizationElement.organization.idOrganization] = {
        ...organizationElement.organization,
        organizationElements: organizationElement.organization.organizationElements?.concat() ?? []
      };
    }
    (organizationsToNormalize[organizationElement.organization.idOrganization].organizationElements as number[]).push(
      organizationElement.idOrganizationElement
    );
    relations['organization'] = organizationElement.organization.idOrganization;
  }
}

function organizationElementNormalizeElement(
  organizationElement: OrganizationElementEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationElement.element && typeof organizationElement.element !== 'number') {
    if (!elementsToNormalize[organizationElement.element.idElement]) {
      elementsToNormalize[organizationElement.element.idElement] = {
        ...organizationElement.element,
        organizationElements: organizationElement.element.organizationElements?.concat() ?? []
      };
    }
    (elementsToNormalize[organizationElement.element.idElement].organizationElements as number[]).push(
      organizationElement.idOrganizationElement
    );
    relations['element'] = organizationElement.element.idElement;
  }
}

/**
 *  Profil
 */
export function getActionsToNormalizeProfil(profils: ProfilEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const rightProfilsToNormalize: RightProfilEntityState[] = [];
  const userRightsToNormalize: UserRightEntityState[] = [];
  const userProfilRightsToNormalize: UserProfilRightEntityState[] = [];
  const rightsToNormalize: { [id: number]: RightEntityState } = {};
  const organizationUserProfilsToNormalize: { [id: number]: OrganizationUserProfilEntityState } = {};
  const organizationUsersToNormalize: { [id: number]: OrganizationUserEntityState } = {};

  const normalizedProfils = profils.map((profil: ProfilEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    profilNormalizeRightProfil(profil, rightProfilsToNormalize, relations);
    profilNormalizeUserRight(profil, userRightsToNormalize, relations);
    profilNormalizeUserProfilRight(profil, userProfilRightsToNormalize, relations);
    profilNormalizeRight(profil, rightsToNormalize, relations);
    profilNormalizeOrganizationUserProfil(profil, organizationUserProfilsToNormalize, relations);
    profilNormalizeOrganizationUser(profil, organizationUsersToNormalize, relations);
    profilNormalizeOrganization(profil, organizationsToNormalize, relations);

    return {
      ...profil,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (rightProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeRightProfil(rightProfilsToNormalize, actionType));
  }
  if (userRightsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserRight(userRightsToNormalize, actionType));
  }
  if (userProfilRightsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserProfilRight(userProfilRightsToNormalize, actionType));
  }
  const rightsToNormalizeArray = Object.values(rightsToNormalize);
  if (rightsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeRight(rightsToNormalizeArray, actionType));
  }
  const organizationUserProfilsToNormalizeArray = Object.values(organizationUserProfilsToNormalize);
  if (organizationUserProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationUserProfil(organizationUserProfilsToNormalizeArray, actionType));
  }
  const organizationUsersToNormalizeArray = Object.values(organizationUsersToNormalize);
  if (organizationUsersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationUser(organizationUsersToNormalizeArray, actionType));
  }

  const profilActionCreator = ProfilGeneratedActions.upsertManyProfilsSuccess;
  actions.push(profilActionCreator({ profils: normalizedProfils }));

  return actions;
}

function profilNormalizeRightProfil(
  profil: ProfilEntityState,
  rightProfilsToNormalize: RightProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (profil.rightProfils?.length && typeof profil.rightProfils[0] !== 'number') {
    const idsRightProfils: number[] = [];
    for (let i = 0; i < profil.rightProfils.length; i++) {
      const rightProfil: RightProfil | number = profil.rightProfils[i];
      if (typeof rightProfil !== 'number') {
        rightProfilsToNormalize.push({ ...rightProfil, profil: profil.idProfil });
        idsRightProfils.push(rightProfil.idRightProfil);
      } else {
        idsRightProfils.push(rightProfil);
      }
    }
    relations['rightProfils'] = idsRightProfils;
  }
}

function profilNormalizeUserRight(
  profil: ProfilEntityState,
  userRightsToNormalize: UserRightEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (profil.userRights?.length && typeof profil.userRights[0] !== 'number') {
    const idsUserRights: number[] = [];
    for (let i = 0; i < profil.userRights.length; i++) {
      const userRight: UserRight | number = profil.userRights[i];
      if (typeof userRight !== 'number') {
        userRightsToNormalize.push({ ...userRight, profil: profil.idProfil });
        idsUserRights.push(userRight.idUserRight);
      } else {
        idsUserRights.push(userRight);
      }
    }
    relations['userRights'] = idsUserRights;
  }
}

function profilNormalizeUserProfilRight(
  profil: ProfilEntityState,
  userProfilRightsToNormalize: UserProfilRightEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (profil.userProfilRights?.length && typeof profil.userProfilRights[0] !== 'number') {
    const idsUserProfilRights: number[] = [];
    for (let i = 0; i < profil.userProfilRights.length; i++) {
      const userProfilRight: UserProfilRight | number = profil.userProfilRights[i];
      if (typeof userProfilRight !== 'number') {
        userProfilRightsToNormalize.push({ ...userProfilRight, profil: profil.idProfil });
        idsUserProfilRights.push(userProfilRight.idUserProfilRight);
      } else {
        idsUserProfilRights.push(userProfilRight);
      }
    }
    relations['userProfilRights'] = idsUserProfilRights;
  }
}

function profilNormalizeRight(
  profil: ProfilEntityState,
  rightsToNormalize: { [id: number]: RightEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (profil.rights?.length && typeof profil.rights[0] !== 'number') {
    const idsRights: number[] = [];
    for (let i = 0; i < profil.rights.length; i++) {
      const right: Right | number = profil.rights[i];

      if (typeof right !== 'number') {
        if (!rightsToNormalize[right.idRight]) {
          rightsToNormalize[right.idRight] = {
            ...right,
            profils: right.profils?.concat() ?? []
          };
        }
        (rightsToNormalize[right.idRight].profils as number[]).push(profil.idProfil);
        idsRights.push(right.idRight);
      } else {
        idsRights.push(right);
      }
    }
    relations['rights'] = idsRights;
  }
}

function profilNormalizeOrganizationUserProfil(
  profil: ProfilEntityState,
  organizationUserProfilsToNormalize: { [id: number]: OrganizationUserProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (profil.organizationUserProfils?.length && typeof profil.organizationUserProfils[0] !== 'number') {
    const idsOrganizationUserProfils: number[] = [];
    for (let i = 0; i < profil.organizationUserProfils.length; i++) {
      const organizationUserProfil: OrganizationUserProfil | number = profil.organizationUserProfils[i];

      if (typeof organizationUserProfil !== 'number') {
        if (!organizationUserProfilsToNormalize[organizationUserProfil.idOrganizationUserProfil]) {
          organizationUserProfilsToNormalize[organizationUserProfil.idOrganizationUserProfil] = {
            ...organizationUserProfil,
            profils: organizationUserProfil.profils?.concat() ?? []
          };
        }
        (organizationUserProfilsToNormalize[organizationUserProfil.idOrganizationUserProfil].profils as number[]).push(
          profil.idProfil
        );
        idsOrganizationUserProfils.push(organizationUserProfil.idOrganizationUserProfil);
      } else {
        idsOrganizationUserProfils.push(organizationUserProfil);
      }
    }
    relations['organizationUserProfils'] = idsOrganizationUserProfils;
  }
}

function profilNormalizeOrganizationUser(
  profil: ProfilEntityState,
  organizationUsersToNormalize: { [id: number]: OrganizationUserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (profil.organizationUsers?.length && typeof profil.organizationUsers[0] !== 'number') {
    const idsOrganizationUsers: number[] = [];
    for (let i = 0; i < profil.organizationUsers.length; i++) {
      const organizationUser: OrganizationUser | number = profil.organizationUsers[i];

      if (typeof organizationUser !== 'number') {
        if (!organizationUsersToNormalize[organizationUser.idOrganizationUser]) {
          organizationUsersToNormalize[organizationUser.idOrganizationUser] = {
            ...organizationUser,
            profils: organizationUser.profils?.concat() ?? []
          };
        }
        (organizationUsersToNormalize[organizationUser.idOrganizationUser].profils as number[]).push(profil.idProfil);
        idsOrganizationUsers.push(organizationUser.idOrganizationUser);
      } else {
        idsOrganizationUsers.push(organizationUser);
      }
    }
    relations['organizationUsers'] = idsOrganizationUsers;
  }
}

function profilNormalizeOrganization(
  profil: ProfilEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (profil.organization && typeof profil.organization !== 'number') {
    if (!organizationsToNormalize[profil.organization.idOrganization]) {
      organizationsToNormalize[profil.organization.idOrganization] = {
        ...profil.organization,
        profils: profil.organization.profils?.concat() ?? []
      };
    }
    (organizationsToNormalize[profil.organization.idOrganization].profils as number[]).push(profil.idProfil);
    relations['organization'] = profil.organization.idOrganization;
  }
}

/**
 *  ProjectElement
 */
export function getActionsToNormalizeProjectElement(
  projectElements: ProjectElementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const elementsToNormalize: { [id: number]: ElementEntityState } = {};

  const normalizedProjectElements = projectElements.map((projectElement: ProjectElementEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    projectElementNormalizeCommunity(projectElement, communitiesToNormalize, relations);
    projectElementNormalizeElement(projectElement, elementsToNormalize, relations);

    return {
      ...projectElement,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }

  const projectElementActionCreator = ProjectElementGeneratedActions.upsertManyProjectElementsSuccess;
  actions.push(projectElementActionCreator({ projectElements: normalizedProjectElements }));

  return actions;
}

function projectElementNormalizeCommunity(
  projectElement: ProjectElementEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (projectElement.community && typeof projectElement.community !== 'number') {
    if (!communitiesToNormalize[projectElement.community.idCommunity]) {
      communitiesToNormalize[projectElement.community.idCommunity] = {
        ...projectElement.community,
        projectElements: projectElement.community.projectElements?.concat() ?? []
      };
    }
    (communitiesToNormalize[projectElement.community.idCommunity].projectElements as number[]).push(
      projectElement.idProjectElement
    );
    relations['community'] = projectElement.community.idCommunity;
  }
}

function projectElementNormalizeElement(
  projectElement: ProjectElementEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (projectElement.element && typeof projectElement.element !== 'number') {
    if (!elementsToNormalize[projectElement.element.idElement]) {
      elementsToNormalize[projectElement.element.idElement] = {
        ...projectElement.element,
        projectElements: projectElement.element.projectElements?.concat() ?? []
      };
    }
    (elementsToNormalize[projectElement.element.idElement].projectElements as number[]).push(
      projectElement.idProjectElement
    );
    relations['element'] = projectElement.element.idElement;
  }
}

/**
 *  Right
 */
export function getActionsToNormalizeRight(rights: RightEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const rightProfilsToNormalize: RightProfilEntityState[] = [];
  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};

  const normalizedRights = rights.map((right: RightEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    rightNormalizeRightProfil(right, rightProfilsToNormalize, relations);
    rightNormalizeProfil(right, profilsToNormalize, relations);

    return {
      ...right,
      ...relations
    };
  });
  if (rightProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeRightProfil(rightProfilsToNormalize, actionType));
  }
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }

  const rightActionCreator = RightGeneratedActions.upsertManyRightsSuccess;
  actions.push(rightActionCreator({ rights: normalizedRights }));

  return actions;
}

function rightNormalizeRightProfil(
  right: RightEntityState,
  rightProfilsToNormalize: RightProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (right.rightProfils?.length && typeof right.rightProfils[0] !== 'number') {
    const idsRightProfils: number[] = [];
    for (let i = 0; i < right.rightProfils.length; i++) {
      const rightProfil: RightProfil | number = right.rightProfils[i];
      if (typeof rightProfil !== 'number') {
        rightProfilsToNormalize.push({ ...rightProfil, right: right.idRight });
        idsRightProfils.push(rightProfil.idRightProfil);
      } else {
        idsRightProfils.push(rightProfil);
      }
    }
    relations['rightProfils'] = idsRightProfils;
  }
}

function rightNormalizeProfil(
  right: RightEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (right.profils?.length && typeof right.profils[0] !== 'number') {
    const idsProfils: number[] = [];
    for (let i = 0; i < right.profils.length; i++) {
      const profil: Profil | number = right.profils[i];

      if (typeof profil !== 'number') {
        if (!profilsToNormalize[profil.idProfil]) {
          profilsToNormalize[profil.idProfil] = {
            ...profil,
            rights: profil.rights?.concat() ?? []
          };
        }
        (profilsToNormalize[profil.idProfil].rights as number[]).push(right.idRight);
        idsProfils.push(profil.idProfil);
      } else {
        idsProfils.push(profil);
      }
    }
    relations['profils'] = idsProfils;
  }
}

/**
 *  RightProfil
 */
export function getActionsToNormalizeRightProfil(
  rightProfils: RightProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const rightsToNormalize: { [id: number]: RightEntityState } = {};
  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};

  const normalizedRightProfils = rightProfils.map((rightProfil: RightProfilEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    rightProfilNormalizeRight(rightProfil, rightsToNormalize, relations);
    rightProfilNormalizeProfil(rightProfil, profilsToNormalize, relations);

    return {
      ...rightProfil,
      ...relations
    };
  });
  const rightsToNormalizeArray = Object.values(rightsToNormalize);
  if (rightsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeRight(rightsToNormalizeArray, actionType));
  }
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }

  const rightProfilActionCreator = RightProfilGeneratedActions.upsertManyRightProfilsSuccess;
  actions.push(rightProfilActionCreator({ rightProfils: normalizedRightProfils }));

  return actions;
}

function rightProfilNormalizeRight(
  rightProfil: RightProfilEntityState,
  rightsToNormalize: { [id: number]: RightEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (rightProfil.right && typeof rightProfil.right !== 'number') {
    if (!rightsToNormalize[rightProfil.right.idRight]) {
      rightsToNormalize[rightProfil.right.idRight] = {
        ...rightProfil.right,
        rightProfils: rightProfil.right.rightProfils?.concat() ?? []
      };
    }
    (rightsToNormalize[rightProfil.right.idRight].rightProfils as number[]).push(rightProfil.idRightProfil);
    relations['right'] = rightProfil.right.idRight;
  }
}

function rightProfilNormalizeProfil(
  rightProfil: RightProfilEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (rightProfil.profil && typeof rightProfil.profil !== 'number') {
    if (!profilsToNormalize[rightProfil.profil.idProfil]) {
      profilsToNormalize[rightProfil.profil.idProfil] = {
        ...rightProfil.profil,
        rightProfils: rightProfil.profil.rightProfils?.concat() ?? []
      };
    }
    (profilsToNormalize[rightProfil.profil.idProfil].rightProfils as number[]).push(rightProfil.idRightProfil);
    relations['profil'] = rightProfil.profil.idProfil;
  }
}

/**
 *  Section
 */
export function getActionsToNormalizeSection(sections: SectionEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const organizationSectionsToNormalize: OrganizationSectionEntityState[] = [];
  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};

  const normalizedSections = sections.map((section: SectionEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    sectionNormalizeOrganizationSection(section, organizationSectionsToNormalize, relations);
    sectionNormalizeOrganization(section, organizationsToNormalize, relations);

    return {
      ...section,
      ...relations
    };
  });
  if (organizationSectionsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationSection(organizationSectionsToNormalize, actionType));
  }
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }

  const sectionActionCreator = SectionGeneratedActions.upsertManySectionsSuccess;
  actions.push(sectionActionCreator({ sections: normalizedSections }));

  return actions;
}

function sectionNormalizeOrganizationSection(
  section: SectionEntityState,
  organizationSectionsToNormalize: OrganizationSectionEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (section.organizationSections?.length && typeof section.organizationSections[0] !== 'number') {
    const idsOrganizationSections: number[] = [];
    for (let i = 0; i < section.organizationSections.length; i++) {
      const organizationSection: OrganizationSection | number = section.organizationSections[i];
      if (typeof organizationSection !== 'number') {
        organizationSectionsToNormalize.push({ ...organizationSection, section: section.idSection });
        idsOrganizationSections.push(organizationSection.idOrganizationSection);
      } else {
        idsOrganizationSections.push(organizationSection);
      }
    }
    relations['organizationSections'] = idsOrganizationSections;
  }
}

function sectionNormalizeOrganization(
  section: SectionEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (section.organizations?.length && typeof section.organizations[0] !== 'number') {
    const idsOrganizations: number[] = [];
    for (let i = 0; i < section.organizations.length; i++) {
      const organization: Organization | number = section.organizations[i];

      if (typeof organization !== 'number') {
        if (!organizationsToNormalize[organization.idOrganization]) {
          organizationsToNormalize[organization.idOrganization] = {
            ...organization,
            sections: organization.sections?.concat() ?? []
          };
        }
        (organizationsToNormalize[organization.idOrganization].sections as number[]).push(section.idSection);
        idsOrganizations.push(organization.idOrganization);
      } else {
        idsOrganizations.push(organization);
      }
    }
    relations['organizations'] = idsOrganizations;
  }
}

/**
 *  TimelineElement
 */
export function getActionsToNormalizeTimelineElement(
  timelineElements: TimelineElementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const elementsToNormalize: { [id: number]: ElementEntityState } = {};

  const normalizedTimelineElements = timelineElements.map((timelineElement: TimelineElementEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    timelineElementNormalizeCommunity(timelineElement, communitiesToNormalize, relations);
    timelineElementNormalizeElement(timelineElement, elementsToNormalize, relations);

    return {
      ...timelineElement,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }

  const timelineElementActionCreator = TimelineElementGeneratedActions.upsertManyTimelineElementsSuccess;
  actions.push(timelineElementActionCreator({ timelineElements: normalizedTimelineElements }));

  return actions;
}

function timelineElementNormalizeCommunity(
  timelineElement: TimelineElementEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (timelineElement.community && typeof timelineElement.community !== 'number') {
    if (!communitiesToNormalize[timelineElement.community.idCommunity]) {
      communitiesToNormalize[timelineElement.community.idCommunity] = {
        ...timelineElement.community,
        timelineElements: timelineElement.community.timelineElements?.concat() ?? []
      };
    }
    (communitiesToNormalize[timelineElement.community.idCommunity].timelineElements as number[]).push(
      timelineElement.idTimelineElement
    );
    relations['community'] = timelineElement.community.idCommunity;
  }
}

function timelineElementNormalizeElement(
  timelineElement: TimelineElementEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (timelineElement.element && typeof timelineElement.element !== 'number') {
    if (!elementsToNormalize[timelineElement.element.idElement]) {
      elementsToNormalize[timelineElement.element.idElement] = {
        ...timelineElement.element,
        timelineElements: timelineElement.element.timelineElements?.concat() ?? []
      };
    }
    (elementsToNormalize[timelineElement.element.idElement].timelineElements as number[]).push(
      timelineElement.idTimelineElement
    );
    relations['element'] = timelineElement.element.idElement;
  }
}

/**
 *  Todo
 */
export function getActionsToNormalizeTodo(todos: TodoEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const todoElementsToNormalize: TodoElementEntityState[] = [];
  const elementsToNormalize: { [id: number]: ElementEntityState } = {};

  const normalizedTodos = todos.map((todo: TodoEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    todoNormalizeTodoElement(todo, todoElementsToNormalize, relations);
    todoNormalizeElement(todo, elementsToNormalize, relations);
    todoNormalizeCommunity(todo, communitiesToNormalize, relations);

    return {
      ...todo,
      ...relations
    };
  });
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  if (todoElementsToNormalize.length) {
    actions.push(...getActionsToNormalizeTodoElement(todoElementsToNormalize, actionType));
  }
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }

  const todoActionCreator = TodoGeneratedActions.upsertManyTodosSuccess;
  actions.push(todoActionCreator({ todos: normalizedTodos }));

  return actions;
}

function todoNormalizeTodoElement(
  todo: TodoEntityState,
  todoElementsToNormalize: TodoElementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (todo.todoElements?.length && typeof todo.todoElements[0] !== 'number') {
    const idsTodoElements: number[] = [];
    for (let i = 0; i < todo.todoElements.length; i++) {
      const todoElement: TodoElement | number = todo.todoElements[i];
      if (typeof todoElement !== 'number') {
        todoElementsToNormalize.push({ ...todoElement, todo: todo.idTodo });
        idsTodoElements.push(todoElement.idTodoElement);
      } else {
        idsTodoElements.push(todoElement);
      }
    }
    relations['todoElements'] = idsTodoElements;
  }
}

function todoNormalizeElement(
  todo: TodoEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (todo.elements?.length && typeof todo.elements[0] !== 'number') {
    const idsElements: number[] = [];
    for (let i = 0; i < todo.elements.length; i++) {
      const element: Element | number = todo.elements[i];

      if (typeof element !== 'number') {
        if (!elementsToNormalize[element.idElement]) {
          elementsToNormalize[element.idElement] = {
            ...element,
            todos: element.todos?.concat() ?? []
          };
        }
        (elementsToNormalize[element.idElement].todos as number[]).push(todo.idTodo);
        idsElements.push(element.idElement);
      } else {
        idsElements.push(element);
      }
    }
    relations['elements'] = idsElements;
  }
}

function todoNormalizeCommunity(
  todo: TodoEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (todo.community && typeof todo.community !== 'number') {
    if (!communitiesToNormalize[todo.community.idCommunity]) {
      communitiesToNormalize[todo.community.idCommunity] = {
        ...todo.community,
        todos: todo.community.todos?.concat() ?? []
      };
    }
    (communitiesToNormalize[todo.community.idCommunity].todos as number[]).push(todo.idTodo);
    relations['community'] = todo.community.idCommunity;
  }
}

/**
 *  TodoElement
 */
export function getActionsToNormalizeTodoElement(
  todoElements: TodoElementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const todosToNormalize: { [id: number]: TodoEntityState } = {};
  const elementsToNormalize: { [id: number]: ElementEntityState } = {};

  const normalizedTodoElements = todoElements.map((todoElement: TodoElementEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    todoElementNormalizeTodo(todoElement, todosToNormalize, relations);
    todoElementNormalizeElement(todoElement, elementsToNormalize, relations);

    return {
      ...todoElement,
      ...relations
    };
  });
  const todosToNormalizeArray = Object.values(todosToNormalize);
  if (todosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeTodo(todosToNormalizeArray, actionType));
  }
  const elementsToNormalizeArray = Object.values(elementsToNormalize);
  if (elementsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeElement(elementsToNormalizeArray, actionType));
  }

  const todoElementActionCreator = TodoElementGeneratedActions.upsertManyTodoElementsSuccess;
  actions.push(todoElementActionCreator({ todoElements: normalizedTodoElements }));

  return actions;
}

function todoElementNormalizeTodo(
  todoElement: TodoElementEntityState,
  todosToNormalize: { [id: number]: TodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (todoElement.todo && typeof todoElement.todo !== 'number') {
    if (!todosToNormalize[todoElement.todo.idTodo]) {
      todosToNormalize[todoElement.todo.idTodo] = {
        ...todoElement.todo,
        todoElements: todoElement.todo.todoElements?.concat() ?? []
      };
    }
    (todosToNormalize[todoElement.todo.idTodo].todoElements as number[]).push(todoElement.idTodoElement);
    relations['todo'] = todoElement.todo.idTodo;
  }
}

function todoElementNormalizeElement(
  todoElement: TodoElementEntityState,
  elementsToNormalize: { [id: number]: ElementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (todoElement.element && typeof todoElement.element !== 'number') {
    if (!elementsToNormalize[todoElement.element.idElement]) {
      elementsToNormalize[todoElement.element.idElement] = {
        ...todoElement.element,
        todoElements: todoElement.element.todoElements?.concat() ?? []
      };
    }
    (elementsToNormalize[todoElement.element.idElement].todoElements as number[]).push(todoElement.idTodoElement);
    relations['element'] = todoElement.element.idElement;
  }
}

/**
 *  User
 */
export function getActionsToNormalizeUser(users: UserEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const communityUserProfilsToNormalize: CommunityUserProfilEntityState[] = [];
  const userGroupsToNormalize: UserGroupEntityState[] = [];
  const communityUsersToNormalize: CommunityUserEntityState[] = [];
  const organizationUsersToNormalize: OrganizationUserEntityState[] = [];
  const userDevicesToNormalize: UserDeviceEntityState[] = [];
  const meetingUsersToNormalize: MeetingUserEntityState[] = [];
  const userHistoriesToNormalize: UserHistoryEntityState[] = [];
  const elementLibrariesToNormalize: ElementLibraryEntityState[] = [];
  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const groupsToNormalize: { [id: number]: GroupEntityState } = {};

  const normalizedUsers = users.map((user: UserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userNormalizeCommunityUserProfil(user, communityUserProfilsToNormalize, relations);
    userNormalizeUserGroup(user, userGroupsToNormalize, relations);
    userNormalizeCommunityUser(user, communityUsersToNormalize, relations);
    userNormalizeOrganizationUser(user, organizationUsersToNormalize, relations);
    userNormalizeUserDevice(user, userDevicesToNormalize, relations);
    userNormalizeMeetingUser(user, meetingUsersToNormalize, relations);
    userNormalizeUserHistory(user, userHistoriesToNormalize, relations);
    userNormalizeElementLibrary(user, elementLibrariesToNormalize, relations);
    userNormalizeOrganization(user, organizationsToNormalize, relations);
    userNormalizeCommunity(user, communitiesToNormalize, relations);
    userNormalizeGroup(user, groupsToNormalize, relations);

    return {
      ...user,
      ...relations
    };
  });
  if (communityUserProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityUserProfil(communityUserProfilsToNormalize, actionType));
  }
  if (userGroupsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserGroup(userGroupsToNormalize, actionType));
  }
  if (communityUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeCommunityUser(communityUsersToNormalize, actionType));
  }
  if (organizationUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationUser(organizationUsersToNormalize, actionType));
  }
  if (userDevicesToNormalize.length) {
    actions.push(...getActionsToNormalizeUserDevice(userDevicesToNormalize, actionType));
  }
  if (meetingUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeMeetingUser(meetingUsersToNormalize, actionType));
  }
  if (userHistoriesToNormalize.length) {
    actions.push(...getActionsToNormalizeUserHistory(userHistoriesToNormalize, actionType));
  }
  if (elementLibrariesToNormalize.length) {
    actions.push(...getActionsToNormalizeElementLibrary(elementLibrariesToNormalize, actionType));
  }
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  const groupsToNormalizeArray = Object.values(groupsToNormalize);
  if (groupsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGroup(groupsToNormalizeArray, actionType));
  }

  const userActionCreator = UserGeneratedActions.upsertManyUsersSuccess;
  actions.push(userActionCreator({ users: normalizedUsers }));

  return actions;
}

function userNormalizeCommunityUserProfil(
  user: UserEntityState,
  communityUserProfilsToNormalize: CommunityUserProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.communityUserProfils?.length && typeof user.communityUserProfils[0] !== 'number') {
    const idsCommunityUserProfils: number[] = [];
    for (let i = 0; i < user.communityUserProfils.length; i++) {
      const communityUserProfil: CommunityUserProfil | number = user.communityUserProfils[i];
      if (typeof communityUserProfil !== 'number') {
        communityUserProfilsToNormalize.push({ ...communityUserProfil, user: user.idUser });
        idsCommunityUserProfils.push(communityUserProfil.idCommunityUserProfil);
      } else {
        idsCommunityUserProfils.push(communityUserProfil);
      }
    }
    relations['communityUserProfils'] = idsCommunityUserProfils;
  }
}

function userNormalizeUserGroup(
  user: UserEntityState,
  userGroupsToNormalize: UserGroupEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.userGroups?.length && typeof user.userGroups[0] !== 'number') {
    const idsUserGroups: number[] = [];
    for (let i = 0; i < user.userGroups.length; i++) {
      const userGroup: UserGroup | number = user.userGroups[i];
      if (typeof userGroup !== 'number') {
        userGroupsToNormalize.push({ ...userGroup, user: user.idUser });
        idsUserGroups.push(userGroup.idUserGroup);
      } else {
        idsUserGroups.push(userGroup);
      }
    }
    relations['userGroups'] = idsUserGroups;
  }
}

function userNormalizeCommunityUser(
  user: UserEntityState,
  communityUsersToNormalize: CommunityUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.communityUsers?.length && typeof user.communityUsers[0] !== 'number') {
    const idsCommunityUsers: number[] = [];
    for (let i = 0; i < user.communityUsers.length; i++) {
      const communityUser: CommunityUser | number = user.communityUsers[i];
      if (typeof communityUser !== 'number') {
        communityUsersToNormalize.push({ ...communityUser, user: user.idUser });
        idsCommunityUsers.push(communityUser.idCommunityUser);
      } else {
        idsCommunityUsers.push(communityUser);
      }
    }
    relations['communityUsers'] = idsCommunityUsers;
  }
}

function userNormalizeOrganizationUser(
  user: UserEntityState,
  organizationUsersToNormalize: OrganizationUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.organizationUsers?.length && typeof user.organizationUsers[0] !== 'number') {
    const idsOrganizationUsers: number[] = [];
    for (let i = 0; i < user.organizationUsers.length; i++) {
      const organizationUser: OrganizationUser | number = user.organizationUsers[i];
      if (typeof organizationUser !== 'number') {
        organizationUsersToNormalize.push({ ...organizationUser, user: user.idUser });
        idsOrganizationUsers.push(organizationUser.idOrganizationUser);
      } else {
        idsOrganizationUsers.push(organizationUser);
      }
    }
    relations['organizationUsers'] = idsOrganizationUsers;
  }
}

function userNormalizeUserDevice(
  user: UserEntityState,
  userDevicesToNormalize: UserDeviceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.userDevice && typeof user.userDevice !== 'number') {
    userDevicesToNormalize.push({ ...user.userDevice, user: user.idUser });
    relations['userDevice'] = user.userDevice.idUserDevice;
  }
}

function userNormalizeMeetingUser(
  user: UserEntityState,
  meetingUsersToNormalize: MeetingUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.meetingUsers?.length && typeof user.meetingUsers[0] !== 'number') {
    const idsMeetingUsers: number[] = [];
    for (let i = 0; i < user.meetingUsers.length; i++) {
      const meetingUser: MeetingUser | number = user.meetingUsers[i];
      if (typeof meetingUser !== 'number') {
        meetingUsersToNormalize.push({ ...meetingUser, user: user.idUser });
        idsMeetingUsers.push(meetingUser.idMeetingUser);
      } else {
        idsMeetingUsers.push(meetingUser);
      }
    }
    relations['meetingUsers'] = idsMeetingUsers;
  }
}

function userNormalizeUserHistory(
  user: UserEntityState,
  userHistoriesToNormalize: UserHistoryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.userHistories?.length && typeof user.userHistories[0] !== 'number') {
    const idsUserHistories: number[] = [];
    for (let i = 0; i < user.userHistories.length; i++) {
      const userHistory: UserHistory | number = user.userHistories[i];
      if (typeof userHistory !== 'number') {
        userHistoriesToNormalize.push({ ...userHistory, user: user.idUser });
        idsUserHistories.push(userHistory.idUserHistory);
      } else {
        idsUserHistories.push(userHistory);
      }
    }
    relations['userHistories'] = idsUserHistories;
  }
}

function userNormalizeElementLibrary(
  user: UserEntityState,
  elementLibrariesToNormalize: ElementLibraryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.elementLibraries?.length && typeof user.elementLibraries[0] !== 'number') {
    const idsElementLibraries: number[] = [];
    for (let i = 0; i < user.elementLibraries.length; i++) {
      const elementLibrary: ElementLibrary | number = user.elementLibraries[i];
      if (typeof elementLibrary !== 'number') {
        elementLibrariesToNormalize.push({ ...elementLibrary, user: user.idUser });
        idsElementLibraries.push(elementLibrary.idElementLibrary);
      } else {
        idsElementLibraries.push(elementLibrary);
      }
    }
    relations['elementLibraries'] = idsElementLibraries;
  }
}

function userNormalizeOrganization(
  user: UserEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.organizations?.length && typeof user.organizations[0] !== 'number') {
    const idsOrganizations: number[] = [];
    for (let i = 0; i < user.organizations.length; i++) {
      const organization: Organization | number = user.organizations[i];

      if (typeof organization !== 'number') {
        if (!organizationsToNormalize[organization.idOrganization]) {
          organizationsToNormalize[organization.idOrganization] = {
            ...organization,
            users: organization.users?.concat() ?? []
          };
        }
        (organizationsToNormalize[organization.idOrganization].users as number[]).push(user.idUser);
        idsOrganizations.push(organization.idOrganization);
      } else {
        idsOrganizations.push(organization);
      }
    }
    relations['organizations'] = idsOrganizations;
  }
}

function userNormalizeCommunity(
  user: UserEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.communities?.length && typeof user.communities[0] !== 'number') {
    const idsCommunities: number[] = [];
    for (let i = 0; i < user.communities.length; i++) {
      const community: Community | number = user.communities[i];

      if (typeof community !== 'number') {
        if (!communitiesToNormalize[community.idCommunity]) {
          communitiesToNormalize[community.idCommunity] = {
            ...community,
            users: community.users?.concat() ?? []
          };
        }
        (communitiesToNormalize[community.idCommunity].users as number[]).push(user.idUser);
        idsCommunities.push(community.idCommunity);
      } else {
        idsCommunities.push(community);
      }
    }
    relations['communities'] = idsCommunities;
  }
}

function userNormalizeGroup(
  user: UserEntityState,
  groupsToNormalize: { [id: number]: GroupEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.groups?.length && typeof user.groups[0] !== 'number') {
    const idsGroups: number[] = [];
    for (let i = 0; i < user.groups.length; i++) {
      const group: Group | number = user.groups[i];

      if (typeof group !== 'number') {
        if (!groupsToNormalize[group.idGroup]) {
          groupsToNormalize[group.idGroup] = {
            ...group,
            users: group.users?.concat() ?? []
          };
        }
        (groupsToNormalize[group.idGroup].users as number[]).push(user.idUser);
        idsGroups.push(group.idGroup);
      } else {
        idsGroups.push(group);
      }
    }
    relations['groups'] = idsGroups;
  }
}

/**
 *  UserGroup
 */
export function getActionsToNormalizeUserGroup(
  userGroups: UserGroupEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const groupsToNormalize: { [id: number]: GroupEntityState } = {};

  const normalizedUserGroups = userGroups.map((userGroup: UserGroupEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userGroupNormalizeUser(userGroup, usersToNormalize, relations);
    userGroupNormalizeGroup(userGroup, groupsToNormalize, relations);

    return {
      ...userGroup,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const groupsToNormalizeArray = Object.values(groupsToNormalize);
  if (groupsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGroup(groupsToNormalizeArray, actionType));
  }

  const userGroupActionCreator = UserGroupGeneratedActions.upsertManyUserGroupsSuccess;
  actions.push(userGroupActionCreator({ userGroups: normalizedUserGroups }));

  return actions;
}

function userGroupNormalizeUser(
  userGroup: UserGroupEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userGroup.user && typeof userGroup.user !== 'number') {
    if (!usersToNormalize[userGroup.user.idUser]) {
      usersToNormalize[userGroup.user.idUser] = {
        ...userGroup.user,
        userGroups: userGroup.user.userGroups?.concat() ?? []
      };
    }
    (usersToNormalize[userGroup.user.idUser].userGroups as number[]).push(userGroup.idUserGroup);
    relations['user'] = userGroup.user.idUser;
  }
}

function userGroupNormalizeGroup(
  userGroup: UserGroupEntityState,
  groupsToNormalize: { [id: number]: GroupEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userGroup.group && typeof userGroup.group !== 'number') {
    if (!groupsToNormalize[userGroup.group.idGroup]) {
      groupsToNormalize[userGroup.group.idGroup] = {
        ...userGroup.group,
        userGroups: userGroup.group.userGroups?.concat() ?? []
      };
    }
    (groupsToNormalize[userGroup.group.idGroup].userGroups as number[]).push(userGroup.idUserGroup);
    relations['group'] = userGroup.group.idGroup;
  }
}

/**
 *  UserProfilRight
 */
export function getActionsToNormalizeUserProfilRight(
  userProfilRights: UserProfilRightEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};
  const organizationUserProfilsToNormalize: { [id: number]: OrganizationUserProfilEntityState } = {};

  const normalizedUserProfilRights = userProfilRights.map((userProfilRight: UserProfilRightEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userProfilRightNormalizeProfil(userProfilRight, profilsToNormalize, relations);
    userProfilRightNormalizeOrganizationUserProfil(userProfilRight, organizationUserProfilsToNormalize, relations);

    return {
      ...userProfilRight,
      ...relations
    };
  });
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }
  const organizationUserProfilsToNormalizeArray = Object.values(organizationUserProfilsToNormalize);
  if (organizationUserProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationUserProfil(organizationUserProfilsToNormalizeArray, actionType));
  }

  const userProfilRightActionCreator = UserProfilRightGeneratedActions.upsertManyUserProfilRightsSuccess;
  actions.push(userProfilRightActionCreator({ userProfilRights: normalizedUserProfilRights }));

  return actions;
}

function userProfilRightNormalizeProfil(
  userProfilRight: UserProfilRightEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userProfilRight.profil && typeof userProfilRight.profil !== 'number') {
    if (!profilsToNormalize[userProfilRight.profil.idProfil]) {
      profilsToNormalize[userProfilRight.profil.idProfil] = {
        ...userProfilRight.profil,
        userProfilRights: userProfilRight.profil.userProfilRights?.concat() ?? []
      };
    }
    (profilsToNormalize[userProfilRight.profil.idProfil].userProfilRights as number[]).push(
      userProfilRight.idUserProfilRight
    );
    relations['profil'] = userProfilRight.profil.idProfil;
  }
}

function userProfilRightNormalizeOrganizationUserProfil(
  userProfilRight: UserProfilRightEntityState,
  organizationUserProfilsToNormalize: { [id: number]: OrganizationUserProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userProfilRight.organizationUserProfil && typeof userProfilRight.organizationUserProfil !== 'number') {
    if (!organizationUserProfilsToNormalize[userProfilRight.organizationUserProfil.idOrganizationUserProfil]) {
      organizationUserProfilsToNormalize[userProfilRight.organizationUserProfil.idOrganizationUserProfil] = {
        ...userProfilRight.organizationUserProfil,
        userProfilRights: userProfilRight.organizationUserProfil.userProfilRights?.concat() ?? []
      };
    }
    (
      organizationUserProfilsToNormalize[userProfilRight.organizationUserProfil.idOrganizationUserProfil]
        .userProfilRights as number[]
    ).push(userProfilRight.idUserProfilRight);
    relations['organizationUserProfil'] = userProfilRight.organizationUserProfil.idOrganizationUserProfil;
  }
}

/**
 *  UserRight
 */
export function getActionsToNormalizeUserRight(
  userRights: UserRightEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};
  const organizationSubFamilysToNormalize: { [id: number]: OrganizationSubFamilyEntityState } = {};
  const organizationUsersToNormalize: { [id: number]: OrganizationUserEntityState } = {};

  const normalizedUserRights = userRights.map((userRight: UserRightEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userRightNormalizeProfil(userRight, profilsToNormalize, relations);
    userRightNormalizeOrganizationSubFamily(userRight, organizationSubFamilysToNormalize, relations);
    userRightNormalizeOrganizationUser(userRight, organizationUsersToNormalize, relations);

    return {
      ...userRight,
      ...relations
    };
  });
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }
  const organizationSubFamilysToNormalizeArray = Object.values(organizationSubFamilysToNormalize);
  if (organizationSubFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationSubFamily(organizationSubFamilysToNormalizeArray, actionType));
  }
  const organizationUsersToNormalizeArray = Object.values(organizationUsersToNormalize);
  if (organizationUsersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationUser(organizationUsersToNormalizeArray, actionType));
  }

  const userRightActionCreator = UserRightGeneratedActions.upsertManyUserRightsSuccess;
  actions.push(userRightActionCreator({ userRights: normalizedUserRights }));

  return actions;
}

function userRightNormalizeProfil(
  userRight: UserRightEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userRight.profil && typeof userRight.profil !== 'number') {
    if (!profilsToNormalize[userRight.profil.idProfil]) {
      profilsToNormalize[userRight.profil.idProfil] = {
        ...userRight.profil,
        userRights: userRight.profil.userRights?.concat() ?? []
      };
    }
    (profilsToNormalize[userRight.profil.idProfil].userRights as number[]).push(userRight.idUserRight);
    relations['profil'] = userRight.profil.idProfil;
  }
}

function userRightNormalizeOrganizationSubFamily(
  userRight: UserRightEntityState,
  organizationSubFamilysToNormalize: { [id: number]: OrganizationSubFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userRight.organizationSubFamily && typeof userRight.organizationSubFamily !== 'number') {
    if (!organizationSubFamilysToNormalize[userRight.organizationSubFamily.idOrganizationSubFamily]) {
      organizationSubFamilysToNormalize[userRight.organizationSubFamily.idOrganizationSubFamily] = {
        ...userRight.organizationSubFamily,
        userRights: userRight.organizationSubFamily.userRights?.concat() ?? []
      };
    }
    (
      organizationSubFamilysToNormalize[userRight.organizationSubFamily.idOrganizationSubFamily].userRights as number[]
    ).push(userRight.idUserRight);
    relations['organizationSubFamily'] = userRight.organizationSubFamily.idOrganizationSubFamily;
  }
}

function userRightNormalizeOrganizationUser(
  userRight: UserRightEntityState,
  organizationUsersToNormalize: { [id: number]: OrganizationUserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userRight.organizationUser && typeof userRight.organizationUser !== 'number') {
    if (!organizationUsersToNormalize[userRight.organizationUser.idOrganizationUser]) {
      organizationUsersToNormalize[userRight.organizationUser.idOrganizationUser] = {
        ...userRight.organizationUser,
        userRights: userRight.organizationUser.userRights?.concat() ?? []
      };
    }
    (organizationUsersToNormalize[userRight.organizationUser.idOrganizationUser].userRights as number[]).push(
      userRight.idUserRight
    );
    relations['organizationUser'] = userRight.organizationUser.idOrganizationUser;
  }
}

/**
 *  UserDevice
 */
export function getActionsToNormalizeUserDevice(
  userDevices: UserDeviceEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedUserDevices = userDevices.map((userDevice: UserDeviceEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userDeviceNormalizeUser(userDevice, usersToNormalize, relations);

    return {
      ...userDevice,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const userDeviceActionCreator = UserDeviceGeneratedActions.upsertManyUserDevicesSuccess;
  actions.push(userDeviceActionCreator({ userDevices: normalizedUserDevices }));

  return actions;
}

function userDeviceNormalizeUser(
  userDevice: UserDeviceEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userDevice.user && typeof userDevice.user !== 'number') {
    if (!usersToNormalize[userDevice.user.idUser]) {
      usersToNormalize[userDevice.user.idUser] = { ...userDevice.user, userDevice: userDevice.idUserDevice };
    }
    usersToNormalize[userDevice.user.idUser].userDevice = userDevice.idUserDevice;
    relations['user'] = userDevice.idUser;
  }
}

/**
 *  UserBoardState
 */
export function getActionsToNormalizeUserBoardState(
  userBoardStates: UserBoardStateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationUsersToNormalize: { [id: number]: OrganizationUserEntityState } = {};
  const organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState } = {};

  const normalizedUserBoardStates = userBoardStates.map((userBoardState: UserBoardStateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userBoardStateNormalizeOrganizationUser(userBoardState, organizationUsersToNormalize, relations);
    userBoardStateNormalizeOrganizationFamily(userBoardState, organizationFamilysToNormalize, relations);

    return {
      ...userBoardState,
      ...relations
    };
  });
  const organizationUsersToNormalizeArray = Object.values(organizationUsersToNormalize);
  if (organizationUsersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationUser(organizationUsersToNormalizeArray, actionType));
  }
  const organizationFamilysToNormalizeArray = Object.values(organizationFamilysToNormalize);
  if (organizationFamilysToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationFamily(organizationFamilysToNormalizeArray, actionType));
  }

  const userBoardStateActionCreator = UserBoardStateGeneratedActions.upsertManyUserBoardStatesSuccess;
  actions.push(userBoardStateActionCreator({ userBoardStates: normalizedUserBoardStates }));

  return actions;
}

function userBoardStateNormalizeOrganizationUser(
  userBoardState: UserBoardStateEntityState,
  organizationUsersToNormalize: { [id: number]: OrganizationUserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userBoardState.organizationUser && typeof userBoardState.organizationUser !== 'number') {
    if (!organizationUsersToNormalize[userBoardState.organizationUser.idOrganizationUser]) {
      organizationUsersToNormalize[userBoardState.organizationUser.idOrganizationUser] = {
        ...userBoardState.organizationUser,
        userBoardStates: userBoardState.organizationUser.userBoardStates?.concat() ?? []
      };
    }
    (organizationUsersToNormalize[userBoardState.organizationUser.idOrganizationUser].userBoardStates as number[]).push(
      userBoardState.idUserBoardState
    );
    relations['organizationUser'] = userBoardState.organizationUser.idOrganizationUser;
  }
}

function userBoardStateNormalizeOrganizationFamily(
  userBoardState: UserBoardStateEntityState,
  organizationFamilysToNormalize: { [id: number]: OrganizationFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userBoardState.organizationFamily && typeof userBoardState.organizationFamily !== 'number') {
    if (!organizationFamilysToNormalize[userBoardState.organizationFamily.idOrganizationFamily]) {
      organizationFamilysToNormalize[userBoardState.organizationFamily.idOrganizationFamily] = {
        ...userBoardState.organizationFamily,
        userBoardStates: userBoardState.organizationFamily.userBoardStates?.concat() ?? []
      };
    }
    (
      organizationFamilysToNormalize[userBoardState.organizationFamily.idOrganizationFamily].userBoardStates as number[]
    ).push(userBoardState.idUserBoardState);
    relations['organizationFamily'] = userBoardState.organizationFamily.idOrganizationFamily;
  }
}

/**
 *  UserHistory
 */
export function getActionsToNormalizeUserHistory(
  userHistories: UserHistoryEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedUserHistories = userHistories.map((userHistory: UserHistoryEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userHistoryNormalizeUser(userHistory, usersToNormalize, relations);

    return {
      ...userHistory,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const userHistoryActionCreator = UserHistoryGeneratedActions.upsertManyUserHistoriesSuccess;
  actions.push(userHistoryActionCreator({ userHistories: normalizedUserHistories }));

  return actions;
}

function userHistoryNormalizeUser(
  userHistory: UserHistoryEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userHistory.user && typeof userHistory.user !== 'number') {
    if (!usersToNormalize[userHistory.user.idUser]) {
      usersToNormalize[userHistory.user.idUser] = {
        ...userHistory.user,
        userHistories: userHistory.user.userHistories?.concat() ?? []
      };
    }
    (usersToNormalize[userHistory.user.idUser].userHistories as number[]).push(userHistory.idUserHistory);
    relations['user'] = userHistory.user.idUser;
  }
}

/**
 *  CommunityUserProfil
 */
export function getActionsToNormalizeCommunityUserProfil(
  communityUserProfils: CommunityUserProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const communitiesToNormalize: { [id: number]: CommunityEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const organizationUserProfilsToNormalize: { [id: number]: OrganizationUserProfilEntityState } = {};
  const elementLibrariesToNormalize: ElementLibraryEntityState[] = [];

  const normalizedCommunityUserProfils = communityUserProfils.map(
    (communityUserProfil: CommunityUserProfilEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      communityUserProfilNormalizeElementLibrary(communityUserProfil, elementLibrariesToNormalize, relations);
      communityUserProfilNormalizeCommunity(communityUserProfil, communitiesToNormalize, relations);
      communityUserProfilNormalizeUser(communityUserProfil, usersToNormalize, relations);
      communityUserProfilNormalizeOrganizationUserProfil(
        communityUserProfil,
        organizationUserProfilsToNormalize,
        relations
      );

      return {
        ...communityUserProfil,
        ...relations
      };
    }
  );
  const communitiesToNormalizeArray = Object.values(communitiesToNormalize);
  if (communitiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunity(communitiesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const organizationUserProfilsToNormalizeArray = Object.values(organizationUserProfilsToNormalize);
  if (organizationUserProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationUserProfil(organizationUserProfilsToNormalizeArray, actionType));
  }
  if (elementLibrariesToNormalize.length) {
    actions.push(...getActionsToNormalizeElementLibrary(elementLibrariesToNormalize, actionType));
  }

  const communityUserProfilActionCreator = CommunityUserProfilGeneratedActions.upsertManyCommunityUserProfilsSuccess;
  actions.push(communityUserProfilActionCreator({ communityUserProfils: normalizedCommunityUserProfils }));

  return actions;
}

function communityUserProfilNormalizeElementLibrary(
  communityUserProfil: CommunityUserProfilEntityState,
  elementLibrariesToNormalize: ElementLibraryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (communityUserProfil.elementLibraries?.length && typeof communityUserProfil.elementLibraries[0] !== 'number') {
    const idsElementLibraries: number[] = [];
    for (let i = 0; i < communityUserProfil.elementLibraries.length; i++) {
      const elementLibrary: ElementLibrary | number = communityUserProfil.elementLibraries[i];
      if (typeof elementLibrary !== 'number') {
        elementLibrariesToNormalize.push({
          ...elementLibrary,
          communityUserProfil: communityUserProfil.idCommunityUserProfil
        });
        idsElementLibraries.push(elementLibrary.idElementLibrary);
      } else {
        idsElementLibraries.push(elementLibrary);
      }
    }
    relations['elementLibraries'] = idsElementLibraries;
  }
}

function communityUserProfilNormalizeCommunity(
  communityUserProfil: CommunityUserProfilEntityState,
  communitiesToNormalize: { [id: number]: CommunityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityUserProfil.community && typeof communityUserProfil.community !== 'number') {
    if (!communitiesToNormalize[communityUserProfil.community.idCommunity]) {
      communitiesToNormalize[communityUserProfil.community.idCommunity] = {
        ...communityUserProfil.community,
        communityUserProfils: communityUserProfil.community.communityUserProfils?.concat() ?? []
      };
    }
    (communitiesToNormalize[communityUserProfil.community.idCommunity].communityUserProfils as number[]).push(
      communityUserProfil.idCommunityUserProfil
    );
    relations['community'] = communityUserProfil.community.idCommunity;
  }
}

function communityUserProfilNormalizeUser(
  communityUserProfil: CommunityUserProfilEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityUserProfil.user && typeof communityUserProfil.user !== 'number') {
    if (!usersToNormalize[communityUserProfil.user.idUser]) {
      usersToNormalize[communityUserProfil.user.idUser] = {
        ...communityUserProfil.user,
        communityUserProfils: communityUserProfil.user.communityUserProfils?.concat() ?? []
      };
    }
    (usersToNormalize[communityUserProfil.user.idUser].communityUserProfils as number[]).push(
      communityUserProfil.idCommunityUserProfil
    );
    relations['user'] = communityUserProfil.user.idUser;
  }
}

function communityUserProfilNormalizeOrganizationUserProfil(
  communityUserProfil: CommunityUserProfilEntityState,
  organizationUserProfilsToNormalize: { [id: number]: OrganizationUserProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (communityUserProfil.organizationUserProfil && typeof communityUserProfil.organizationUserProfil !== 'number') {
    if (!organizationUserProfilsToNormalize[communityUserProfil.organizationUserProfil.idOrganizationUserProfil]) {
      organizationUserProfilsToNormalize[communityUserProfil.organizationUserProfil.idOrganizationUserProfil] = {
        ...communityUserProfil.organizationUserProfil,
        communityUserProfils: communityUserProfil.organizationUserProfil.communityUserProfils?.concat() ?? []
      };
    }
    (
      organizationUserProfilsToNormalize[communityUserProfil.organizationUserProfil.idOrganizationUserProfil]
        .communityUserProfils as number[]
    ).push(communityUserProfil.idCommunityUserProfil);
    relations['organizationUserProfil'] = communityUserProfil.organizationUserProfil.idOrganizationUserProfil;
  }
}

/**
 *  Valeur
 */
export function getActionsToNormalizeValeur(valeurs: ValeurEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const communityDataRowsToNormalize: { [id: number]: CommunityDataRowEntityState } = {};
  const organizationDataColumnsToNormalize: { [id: number]: OrganizationDataColumnEntityState } = {};
  const organizationDataColumnValuesToNormalize: { [id: number]: OrganizationDataColumnValueEntityState } = {};

  const normalizedValeurs = valeurs.map((valeur: ValeurEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    valeurNormalizeCommunityDataRow(valeur, communityDataRowsToNormalize, relations);
    valeurNormalizeOrganizationDataColumn(valeur, organizationDataColumnsToNormalize, relations);
    valeurNormalizeOrganizationDataColumnValue(valeur, organizationDataColumnValuesToNormalize, relations);

    return {
      ...valeur,
      ...relations
    };
  });
  const communityDataRowsToNormalizeArray = Object.values(communityDataRowsToNormalize);
  if (communityDataRowsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCommunityDataRow(communityDataRowsToNormalizeArray, actionType));
  }
  const organizationDataColumnsToNormalizeArray = Object.values(organizationDataColumnsToNormalize);
  if (organizationDataColumnsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationDataColumn(organizationDataColumnsToNormalizeArray, actionType));
  }
  const organizationDataColumnValuesToNormalizeArray = Object.values(organizationDataColumnValuesToNormalize);
  if (organizationDataColumnValuesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationDataColumnValue(organizationDataColumnValuesToNormalizeArray, actionType)
    );
  }

  const valeurActionCreator = ValeurGeneratedActions.upsertManyValeursSuccess;
  actions.push(valeurActionCreator({ valeurs: normalizedValeurs }));

  return actions;
}

function valeurNormalizeCommunityDataRow(
  valeur: ValeurEntityState,
  communityDataRowsToNormalize: { [id: number]: CommunityDataRowEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.communityDataRow && typeof valeur.communityDataRow !== 'number') {
    if (!communityDataRowsToNormalize[valeur.communityDataRow.idCommunityDataRow]) {
      communityDataRowsToNormalize[valeur.communityDataRow.idCommunityDataRow] = {
        ...valeur.communityDataRow,
        valeurs: valeur.communityDataRow.valeurs?.concat() ?? []
      };
    }
    (communityDataRowsToNormalize[valeur.communityDataRow.idCommunityDataRow].valeurs as number[]).push(
      valeur.idValeur
    );
    relations['communityDataRow'] = valeur.communityDataRow.idCommunityDataRow;
  }
}

function valeurNormalizeOrganizationDataColumn(
  valeur: ValeurEntityState,
  organizationDataColumnsToNormalize: { [id: number]: OrganizationDataColumnEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.organizationDataColumn && typeof valeur.organizationDataColumn !== 'number') {
    if (!organizationDataColumnsToNormalize[valeur.organizationDataColumn.idOrganizationDataColumn]) {
      organizationDataColumnsToNormalize[valeur.organizationDataColumn.idOrganizationDataColumn] = {
        ...valeur.organizationDataColumn,
        valeurs: valeur.organizationDataColumn.valeurs?.concat() ?? []
      };
    }
    (
      organizationDataColumnsToNormalize[valeur.organizationDataColumn.idOrganizationDataColumn].valeurs as number[]
    ).push(valeur.idValeur);
    relations['organizationDataColumn'] = valeur.organizationDataColumn.idOrganizationDataColumn;
  }
}

function valeurNormalizeOrganizationDataColumnValue(
  valeur: ValeurEntityState,
  organizationDataColumnValuesToNormalize: { [id: number]: OrganizationDataColumnValueEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.organizationDataColumnValue && typeof valeur.organizationDataColumnValue !== 'number') {
    if (!organizationDataColumnValuesToNormalize[valeur.organizationDataColumnValue.idOrganizationDataColumnValue]) {
      organizationDataColumnValuesToNormalize[valeur.organizationDataColumnValue.idOrganizationDataColumnValue] = {
        ...valeur.organizationDataColumnValue,
        valeurs: valeur.organizationDataColumnValue.valeurs?.concat() ?? []
      };
    }
    (
      organizationDataColumnValuesToNormalize[valeur.organizationDataColumnValue.idOrganizationDataColumnValue]
        .valeurs as number[]
    ).push(valeur.idValeur);
    relations['organizationDataColumnValue'] = valeur.organizationDataColumnValue.idOrganizationDataColumnValue;
  }
}
