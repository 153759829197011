import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconRegistrationService {
  constructor(
    iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'checkbox-abort',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-abort.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-abort-round',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-abort-round.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-checked',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-checked.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-checked-round',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-checked-round.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-empty',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-empty.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-empty-round',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-empty-round.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-pause',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-pause.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-pause-round',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-pause-round.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-progress',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-progress.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-progress-round',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-progress-round.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-replace',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-replace.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-replace-round',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/34-checkbox-replace-round.svg')
    );

    iconRegistry.addSvgIcon(
      'add-task',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-add-task.svg')
    );
    iconRegistry.addSvgIcon(
      'add-text',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-add-text.svg')
    );
    iconRegistry.addSvgIcon(
      'add-file',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-add-file.svg')
    );
    iconRegistry.addSvgIcon(
      'file-word',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-word.svg')
    );
    iconRegistry.addSvgIcon(
      'file-xls',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-xls.svg')
    );
    iconRegistry.addSvgIcon(
      'file-txt',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-txt.svg')
    );
    iconRegistry.addSvgIcon(
      'file-ppt',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-ppt.svg')
    );
    iconRegistry.addSvgIcon(
      'file-pdf',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-pdf.svg')
    );
    iconRegistry.addSvgIcon(
      'file-img',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-img.svg')
    );
    iconRegistry.addSvgIcon(
      'file-img-grey',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-img-grey.svg')
    );
    iconRegistry.addSvgIcon(
      'file-music',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-music.svg')
    );
    iconRegistry.addSvgIcon(
      'file-zip',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-zip.svg')
    );
    iconRegistry.addSvgIcon(
      'file-other',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-other.svg')
    );
    iconRegistry.addSvgIcon(
      'file-video',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-file-video.svg')
    );
    iconRegistry.addSvgIcon(
      'insert-step',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-add-flag.svg')
    );
    iconRegistry.addSvgIcon(
      'add-substep',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-add-flag-sub.svg')
    );

    iconRegistry.addSvgIcon('handler', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/34-handler.svg'));
  }

  helperActionStatusToIcon(status: string) {
    switch (status) {
      case 'open':
        return 'checkbox-empty';
      case 'close':
        return 'checkbox-checked';
      case 'abort':
        return 'checkbox-abort';
      case 'pause':
        return 'checkbox-pause';
      case 'progress':
        return 'checkbox-progress';
      case 'replace':
        return 'checkbox-replace';
      default:
        return 'checkbox-empty';
    }
  }
}
