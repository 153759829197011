import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Meeting, MeetingEntityState } from '@api/api-interfaces';
import { MeetingElement, MeetingElementEntityState } from '@api/api-interfaces';
import { MeetingUser, MeetingUserEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { MeetingState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const meetingRelations: string[] = ['meetingElements', 'meetingUsers', 'communities'];

export const { selectEntities, selectAll } = MeetingState.adapter.getSelectors();

export const selectMeetingState = createFeatureSelector<MeetingState.IState>(MeetingState.meetingFeatureKey);

export const selectIsLoadedMeeting = createSelector(selectMeetingState, (state: MeetingState.IState) => state.isLoaded);

export const selectIsLoadingMeeting = createSelector(
  selectMeetingState,
  (state: MeetingState.IState) => state.isLoading
);

export const selectIsReadyMeeting = createSelector(
  selectMeetingState,
  (state: MeetingState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedMeeting = createSelector(
  selectMeetingState,
  (state: MeetingState.IState) => state.isLoaded && !state.isLoading
);

export const selectMeetingsEntities = createSelector(selectMeetingState, selectEntities);

export const selectMeetingsArray = createSelector(selectMeetingState, selectAll);

export const selectIdMeetingsActive = createSelector(selectMeetingState, (state: MeetingState.IState) => state.actives);

const meetingsInObject = (meetings: Dictionary<MeetingEntityState>) => ({ meetings });

const selectMeetingsEntitiesDictionary = createSelector(selectMeetingsEntities, meetingsInObject);

const selectAllMeetingsObject = createSelector(selectMeetingsEntities, meetings => {
  return hydrateAll({ meetings });
});

const selectOneMeetingDictionary = (idMeeting: number) =>
  createSelector(selectMeetingsEntities, meetings => ({
    meetings: { [idMeeting]: meetings[idMeeting] }
  }));

const selectOneMeetingDictionaryWithoutChild = (idMeeting: number) =>
  createSelector(selectMeetingsEntities, meetings => ({
    meeting: meetings[idMeeting]
  }));

const selectActiveMeetingsEntities = createSelector(
  selectIdMeetingsActive,
  selectMeetingsEntities,
  (actives: number[], meetings: Dictionary<MeetingEntityState>) => getMeetingsFromActives(actives, meetings)
);

function getMeetingsFromActives(
  actives: number[],
  meetings: Dictionary<MeetingEntityState>
): Dictionary<MeetingEntityState> {
  return actives.reduce((acc, idActive) => {
    if (meetings[idActive]) {
      acc[idActive] = meetings[idActive];
    }
    return acc;
  }, {} as Dictionary<MeetingEntityState>);
}

const selectAllMeetingsSelectors: Dictionary<Selector> = {};
export function selectAllMeetings(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Meeting>(
      schema,
      selectAllMeetingsSelectors,
      selectMeetingsEntitiesDictionary,
      getRelationSelectors,
      meetingRelations,
      hydrateAll,
      'meeting'
    );
  } else {
    return selectAllMeetingsObject;
  }
}

export function selectAllMeetingsDictionary(schema: SelectSchema = {}, customKey: string = 'meetings'): Selector {
  return createSelector(selectAllMeetings(schema), result => {
    const res = { [customKey]: {} as Dictionary<MeetingEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.meetings.length; i++) {
      res[customKey][result.meetings[i].idMeeting] = result.meetings[i];
    }
    return res;
  });
}

export function selectOneMeeting(schema: SelectSchema = {}, idMeeting: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneMeetingDictionary(idMeeting)];
    selectors.push(...getRelationSelectors(schema, meetingRelations, 'meeting'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneMeetingDictionaryWithoutChild(idMeeting);
  }
}

export function selectActiveMeetings(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveMeetingsEntities, meetings => ({
      meetings
    }))
  ];
  selectors.push(...getRelationSelectors(schema, meetingRelations, 'meeting'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  meetings: Dictionary<MeetingEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  meetingElements?: Dictionary<MeetingElementEntityState>;
  meetingUsers?: Dictionary<MeetingUserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { meetings: (Meeting | null)[] } {
  const { meetings, communities, meetingElements, meetingUsers } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    meetings: Object.keys(meetings).map(idMeeting =>
      hydrate(meetings[idMeeting] as MeetingEntityState, communities, meetingElements, meetingUsers)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { meeting: MeetingEntityState | null } {
  const { meetings, communities, meetingElements, meetingUsers } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const meeting = Object.values(meetings)[0];
  return {
    meeting: hydrate(meeting as MeetingEntityState, communities, meetingElements, meetingUsers)
  };
}

function hydrate(
  meeting: MeetingEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  meetingElementEntities?: Dictionary<MeetingElementEntityState>,
  meetingUserEntities?: Dictionary<MeetingUserEntityState>
): Meeting | null {
  if (!meeting) {
    return null;
  }

  const meetingHydrated: MeetingEntityState = { ...meeting };
  if (communityEntities) {
    meetingHydrated.community = communityEntities[meeting.community as number] as Community;
  } else {
    delete meetingHydrated.community;
  }

  if (meetingElementEntities) {
    meetingHydrated.meetingElements = ((meetingHydrated.meetingElements as number[]) || []).map(
      id => meetingElementEntities[id]
    ) as MeetingElement[];
  } else {
    delete meetingHydrated.meetingElements;
  }

  if (meetingUserEntities) {
    meetingHydrated.meetingUsers = ((meetingHydrated.meetingUsers as number[]) || []).map(
      id => meetingUserEntities[id]
    ) as MeetingUser[];
  } else {
    delete meetingHydrated.meetingUsers;
  }

  return meetingHydrated as Meeting;
}
