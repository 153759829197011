import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { User, UserEntityState } from '@api/api-interfaces';
import { UserApiService } from '@wip/store/api-services';
import { UserGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeUser } from '@wip/store/configs/normalization';
import { UserSelectors } from '@wip/store/selectors';
import { UserRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityUserProfilGeneratedActions } from '@wip/store/actions';
import { CommunityUserProfil } from '@api/api-interfaces';
import { UserGroupGeneratedActions } from '@wip/store/actions';
import { UserGroup } from '@api/api-interfaces';
import { CommunityUserGeneratedActions } from '@wip/store/actions';
import { CommunityUser } from '@api/api-interfaces';
import { OrganizationUserGeneratedActions } from '@wip/store/actions';
import { OrganizationUser } from '@api/api-interfaces';
import { UserDeviceGeneratedActions } from '@wip/store/actions';
import { UserDevice } from '@api/api-interfaces';
import { MeetingUserGeneratedActions } from '@wip/store/actions';
import { MeetingUser } from '@api/api-interfaces';
import { UserHistoryGeneratedActions } from '@wip/store/actions';
import { UserHistory } from '@api/api-interfaces';
import { ElementLibraryGeneratedActions } from '@wip/store/actions';
import { ElementLibrary } from '@api/api-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { Organization } from '@api/api-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { Community } from '@api/api-interfaces';
import { GroupGeneratedActions } from '@wip/store/actions';
import { Group } from '@api/api-interfaces';

export function getDefaultAddUserActions(user: UserEntityState, ids?: UserRelationsIds): Action[] {
  const actions: Action[] = [UserGeneratedActions.normalizeManyUsersAfterUpsert({ users: [user] })];

  if (ids?.communityUserProfils) {
    if (!Array.isArray(ids.communityUserProfils)) {
      actions.push(
        CommunityUserProfilGeneratedActions.upsertOneCommunityUserProfil({
          communityUserProfil: {
            idUser: user.idUser,
            idCommunityUserProfil: ids.communityUserProfils as number
          } as CommunityUserProfil
        })
      );
      actions.push(
        UserGeneratedActions.addManyCommunityUserProfilSuccess({
          idUser: user.idUser,
          idCommunityUserProfils: [ids.communityUserProfils as number]
        })
      );
    } else {
      actions.push(
        CommunityUserProfilGeneratedActions.upsertManyCommunityUserProfils({
          communityUserProfils: (ids.communityUserProfils as number[]).map((idCommunityUserProfil: number) => ({
            idUser: user.idUser,
            idCommunityUserProfil
          })) as CommunityUserProfil[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyCommunityUserProfilSuccess({
          idUser: user.idUser,
          idCommunityUserProfils: ids.communityUserProfils as number[]
        })
      );
    }
  }

  if (ids?.userGroups) {
    if (!Array.isArray(ids.userGroups)) {
      actions.push(
        UserGroupGeneratedActions.upsertOneUserGroup({
          userGroup: {
            idUser: user.idUser,
            idUserGroup: ids.userGroups as number
          } as UserGroup
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserGroupSuccess({
          idUser: user.idUser,
          idUserGroups: [ids.userGroups as number]
        })
      );
    } else {
      actions.push(
        UserGroupGeneratedActions.upsertManyUserGroups({
          userGroups: (ids.userGroups as number[]).map((idUserGroup: number) => ({
            idUser: user.idUser,
            idUserGroup
          })) as UserGroup[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserGroupSuccess({
          idUser: user.idUser,
          idUserGroups: ids.userGroups as number[]
        })
      );
    }
  }

  if (ids?.communityUsers) {
    if (!Array.isArray(ids.communityUsers)) {
      actions.push(
        CommunityUserGeneratedActions.upsertOneCommunityUser({
          communityUser: {
            idUser: user.idUser,
            idCommunityUser: ids.communityUsers as number
          } as CommunityUser
        })
      );
      actions.push(
        UserGeneratedActions.addManyCommunityUserSuccess({
          idUser: user.idUser,
          idCommunityUsers: [ids.communityUsers as number]
        })
      );
    } else {
      actions.push(
        CommunityUserGeneratedActions.upsertManyCommunityUsers({
          communityUsers: (ids.communityUsers as number[]).map((idCommunityUser: number) => ({
            idUser: user.idUser,
            idCommunityUser
          })) as CommunityUser[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyCommunityUserSuccess({
          idUser: user.idUser,
          idCommunityUsers: ids.communityUsers as number[]
        })
      );
    }
  }

  if (ids?.organizationUsers) {
    if (!Array.isArray(ids.organizationUsers)) {
      actions.push(
        OrganizationUserGeneratedActions.upsertOneOrganizationUser({
          organizationUser: {
            idUser: user.idUser,
            idOrganizationUser: ids.organizationUsers as number
          } as OrganizationUser
        })
      );
      actions.push(
        UserGeneratedActions.addManyOrganizationUserSuccess({
          idUser: user.idUser,
          idOrganizationUsers: [ids.organizationUsers as number]
        })
      );
    } else {
      actions.push(
        OrganizationUserGeneratedActions.upsertManyOrganizationUsers({
          organizationUsers: (ids.organizationUsers as number[]).map((idOrganizationUser: number) => ({
            idUser: user.idUser,
            idOrganizationUser
          })) as OrganizationUser[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyOrganizationUserSuccess({
          idUser: user.idUser,
          idOrganizationUsers: ids.organizationUsers as number[]
        })
      );
    }
  }

  if (ids?.userDevice) {
    actions.push(
      UserDeviceGeneratedActions.upsertOneUserDevice({
        userDevice: {
          idUser: user.idUser,
          idUserDevice: ids.userDevice as number
        } as UserDevice
      })
    );
    actions.push(
      UserGeneratedActions.addUserDeviceSuccess({
        idUser: user.idUser,
        idUserDevice: ids.userDevice
      })
    );
  }

  if (ids?.meetingUsers) {
    if (!Array.isArray(ids.meetingUsers)) {
      actions.push(
        MeetingUserGeneratedActions.upsertOneMeetingUser({
          meetingUser: {
            idUser: user.idUser,
            idMeetingUser: ids.meetingUsers as number
          } as MeetingUser
        })
      );
      actions.push(
        UserGeneratedActions.addManyMeetingUserSuccess({
          idUser: user.idUser,
          idMeetingUsers: [ids.meetingUsers as number]
        })
      );
    } else {
      actions.push(
        MeetingUserGeneratedActions.upsertManyMeetingUsers({
          meetingUsers: (ids.meetingUsers as number[]).map((idMeetingUser: number) => ({
            idUser: user.idUser,
            idMeetingUser
          })) as MeetingUser[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyMeetingUserSuccess({
          idUser: user.idUser,
          idMeetingUsers: ids.meetingUsers as number[]
        })
      );
    }
  }

  if (ids?.userHistories) {
    if (!Array.isArray(ids.userHistories)) {
      actions.push(
        UserHistoryGeneratedActions.upsertOneUserHistory({
          userHistory: {
            idUser: user.idUser,
            idUserHistory: ids.userHistories as number
          } as UserHistory
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserHistorySuccess({
          idUser: user.idUser,
          idUserHistories: [ids.userHistories as number]
        })
      );
    } else {
      actions.push(
        UserHistoryGeneratedActions.upsertManyUserHistories({
          userHistories: (ids.userHistories as number[]).map((idUserHistory: number) => ({
            idUser: user.idUser,
            idUserHistory
          })) as UserHistory[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyUserHistorySuccess({
          idUser: user.idUser,
          idUserHistories: ids.userHistories as number[]
        })
      );
    }
  }

  if (ids?.elementLibraries) {
    if (!Array.isArray(ids.elementLibraries)) {
      actions.push(
        ElementLibraryGeneratedActions.upsertOneElementLibrary({
          elementLibrary: {
            idUser: user.idUser,
            idElementLibrary: ids.elementLibraries as number
          } as ElementLibrary
        })
      );
      actions.push(
        UserGeneratedActions.addManyElementLibrarySuccess({
          idUser: user.idUser,
          idElementLibraries: [ids.elementLibraries as number]
        })
      );
    } else {
      actions.push(
        ElementLibraryGeneratedActions.upsertManyElementLibraries({
          elementLibraries: (ids.elementLibraries as number[]).map((idElementLibrary: number) => ({
            idUser: user.idUser,
            idElementLibrary
          })) as ElementLibrary[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyElementLibrarySuccess({
          idUser: user.idUser,
          idElementLibraries: ids.elementLibraries as number[]
        })
      );
    }
  }

  if (ids?.organizations) {
    if (!Array.isArray(ids.organizations)) {
      actions.push(
        OrganizationGeneratedActions.upsertOneOrganization({
          organization: {
            idUser: user.idUser,
            idOrganization: ids.organizations as number
          } as Organization & any
        })
      );
      actions.push(
        UserGeneratedActions.addManyOrganizationSuccess({
          idUser: user.idUser,
          idOrganizations: [ids.organizations as number]
        })
      );
    } else {
      actions.push(
        OrganizationGeneratedActions.upsertManyOrganizations({
          organizations: (ids.organizations as number[]).map((idOrganization: number) => ({
            idUser: user.idUser,
            idOrganization
          })) as Organization[] & any[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyOrganizationSuccess({
          idUser: user.idUser,
          idOrganizations: ids.organizations as number[]
        })
      );
    }
  }

  if (ids?.communities) {
    if (!Array.isArray(ids.communities)) {
      actions.push(
        CommunityGeneratedActions.upsertOneCommunity({
          community: {
            idUser: user.idUser,
            idCommunity: ids.communities as number
          } as Community & any
        })
      );
      actions.push(
        UserGeneratedActions.addManyCommunitySuccess({
          idUser: user.idUser,
          idCommunities: [ids.communities as number]
        })
      );
    } else {
      actions.push(
        CommunityGeneratedActions.upsertManyCommunities({
          communities: (ids.communities as number[]).map((idCommunity: number) => ({
            idUser: user.idUser,
            idCommunity
          })) as Community[] & any[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyCommunitySuccess({
          idUser: user.idUser,
          idCommunities: ids.communities as number[]
        })
      );
    }
  }

  if (ids?.groups) {
    if (!Array.isArray(ids.groups)) {
      actions.push(
        GroupGeneratedActions.upsertOneGroup({
          group: {
            idUser: user.idUser,
            idGroup: ids.groups as number
          } as Group & any
        })
      );
      actions.push(
        UserGeneratedActions.addManyGroupSuccess({
          idUser: user.idUser,
          idGroups: [ids.groups as number]
        })
      );
    } else {
      actions.push(
        GroupGeneratedActions.upsertManyGroups({
          groups: (ids.groups as number[]).map((idGroup: number) => ({
            idUser: user.idUser,
            idGroup
          })) as Group[] & any[]
        })
      );
      actions.push(
        UserGeneratedActions.addManyGroupSuccess({
          idUser: user.idUser,
          idGroups: ids.groups as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteUserActions(user: UserEntityState): Action[] {
  const actions: Action[] = [UserGeneratedActions.deleteOneUserSuccess({ idUser: user.idUser })];

  if (user.communityUserProfils) {
    actions.push(
      CommunityUserProfilGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idCommunityUserProfils: user.communityUserProfils as number[]
      })
    );
  }

  if (user.userGroups) {
    actions.push(
      UserGroupGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idUserGroups: user.userGroups as number[]
      })
    );
  }

  if (user.communityUsers) {
    actions.push(
      CommunityUserGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idCommunityUsers: user.communityUsers as number[]
      })
    );
  }

  if (user.organizationUsers) {
    actions.push(
      OrganizationUserGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idOrganizationUsers: user.organizationUsers as number[]
      })
    );
  }

  if (user.userDevice) {
    actions.push(
      UserDeviceGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idUserDevices: [user.userDevice] as number[]
      })
    );
  }

  if (user.meetingUsers) {
    actions.push(
      MeetingUserGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idMeetingUsers: user.meetingUsers as number[]
      })
    );
  }

  if (user.userHistories) {
    actions.push(
      UserHistoryGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idUserHistories: user.userHistories as number[]
      })
    );
  }

  if (user.elementLibraries) {
    actions.push(
      ElementLibraryGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idElementLibraries: user.elementLibraries as number[]
      })
    );
  }

  if (user.organizations) {
    actions.push(
      OrganizationGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idOrganizations: user.organizations as number[]
      })
    );
  }

  if (user.communities) {
    actions.push(
      CommunityGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idCommunities: user.communities as number[]
      })
    );
  }

  if (user.groups) {
    actions.push(
      GroupGeneratedActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idGroups: user.groups as number[]
      })
    );
  }

  return actions;
}

export class GeneratedUserEffects {
  constructor(
    protected actions$: Actions,
    protected userApiService: UserApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGeneratedActions.getManyUsers),
      switchMap(({ params }) =>
        this.userApiService.getUsers(params).pipe(
          map((users: User[]) => {
            return UserGeneratedActions.normalizeManyUsersAfterUpsert({ users });
          }),
          catchError(error => of(UserGeneratedActions.usersFailure({ error })))
        )
      )
    );
  });

  getOneUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGeneratedActions.getOneUser),
      switchMap(idUser =>
        this.userApiService.getUser(idUser).pipe(
          map((user: User) => {
            return UserGeneratedActions.normalizeManyUsersAfterUpsert({ users: [user] });
          }),
          catchError(error => of(UserGeneratedActions.usersFailure({ error })))
        )
      )
    );
  });

  upsertOneUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGeneratedActions.upsertOneUser),
      concatMap(({ user, ids }: { user: Partial<User>; ids?: UserRelationsIds }) => {
        if (user.idUser) {
          return this.userApiService.updateUser(user).pipe(
            map((userReturned: User) => {
              return UserGeneratedActions.normalizeManyUsersAfterUpsert({ users: [userReturned] });
            }),
            catchError(error => of(UserGeneratedActions.usersFailure({ error })))
          );
        } else {
          return this.userApiService.addUser(user).pipe(
            mergeMap((userReturned: User) => getDefaultAddUserActions(userReturned, ids)),
            catchError(error => of(UserGeneratedActions.usersFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneUser$ = createEffect(() => {
    const selectUserState$ = this.store$.select(UserSelectors.selectUserState);
    return this.actions$.pipe(
      ofType(UserGeneratedActions.deleteOneUser),
      withLatestFrom(selectUserState$),
      concatMap(([{ idUser }, state]) =>
        this.userApiService.deleteUser(idUser).pipe(
          mergeMap(_success => getDefaultDeleteUserActions(state.entities[idUser] as UserEntityState)),
          catchError(error => of(UserGeneratedActions.usersFailure({ error })))
        )
      )
    );
  });

  normalizeManyUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGeneratedActions.normalizeManyUsersAfterUpsert),
      concatMap(({ users }) => {
        const actions: Action[] = getActionsToNormalizeUser(users, StoreActionType.upsert);
        return [getMultiAction(actions, '[User] Normalization After Upsert Success')];
      })
    );
  });
}
