import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunitySubFamily, CommunitySubFamilyEntityState } from '@api/api-interfaces';
import { CommunitySubFamilyApiService } from '@wip/store/api-services';
import { CommunitySubFamilyGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunitySubFamily } from '@wip/store/configs/normalization';
import { CommunitySubFamilySelectors } from '@wip/store/selectors';
import { CommunitySubFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationSubFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityGeneratedActions } from '@wip/store/actions';

export function getDefaultAddCommunitySubFamilyActions(
  communitySubFamily: CommunitySubFamilyEntityState,
  ids?: CommunitySubFamilyRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunitySubFamilyGeneratedActions.normalizeManyCommunitySubFamilysAfterUpsert({
      communitySubFamilys: [communitySubFamily]
    })
  ];

  if (ids?.organizationSubFamily) {
    actions.push(
      OrganizationSubFamilyGeneratedActions.addManyCommunitySubFamilySuccess({
        idOrganizationSubFamily: ids.organizationSubFamily,
        idCommunitySubFamilys: [communitySubFamily.idCommunitySubFamily]
      })
    );
    actions.push(
      CommunitySubFamilyGeneratedActions.addOrganizationSubFamilySuccess({
        idCommunitySubFamily: communitySubFamily.idCommunitySubFamily,
        idOrganizationSubFamily: ids.organizationSubFamily
      })
    );
  }

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunitySubFamilySuccess({
        idCommunity: ids.community,
        idCommunitySubFamilys: [communitySubFamily.idCommunitySubFamily]
      })
    );
    actions.push(
      CommunitySubFamilyGeneratedActions.addCommunitySuccess({
        idCommunitySubFamily: communitySubFamily.idCommunitySubFamily,
        idCommunity: ids.community
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCommunitySubFamilyActions(communitySubFamily: CommunitySubFamilyEntityState): Action[] {
  const actions: Action[] = [
    CommunitySubFamilyGeneratedActions.deleteOneCommunitySubFamilySuccess({
      idCommunitySubFamily: communitySubFamily.idCommunitySubFamily
    })
  ];

  if (communitySubFamily.organizationSubFamily) {
    actions.push(
      OrganizationSubFamilyGeneratedActions.deleteManyCommunitySubFamilySuccess({
        idCommunitySubFamilys: [communitySubFamily.idCommunitySubFamily],
        idOrganizationSubFamilys: [communitySubFamily.organizationSubFamily as number]
      })
    );
  }

  if (communitySubFamily.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunitySubFamilySuccess({
        idCommunitySubFamilys: [communitySubFamily.idCommunitySubFamily],
        idCommunities: [communitySubFamily.community as number]
      })
    );
  }

  return actions;
}

export class GeneratedCommunitySubFamilyEffects {
  constructor(
    protected actions$: Actions,
    protected communitySubFamilyApiService: CommunitySubFamilyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunitySubFamilys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunitySubFamilyGeneratedActions.getManyCommunitySubFamilys),
      switchMap(({ params }) =>
        this.communitySubFamilyApiService.getCommunitySubFamilys(params).pipe(
          map((communitySubFamilys: CommunitySubFamily[]) => {
            return CommunitySubFamilyGeneratedActions.normalizeManyCommunitySubFamilysAfterUpsert({
              communitySubFamilys
            });
          }),
          catchError(error => of(CommunitySubFamilyGeneratedActions.communitySubFamilysFailure({ error })))
        )
      )
    );
  });

  getOneCommunitySubFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunitySubFamilyGeneratedActions.getOneCommunitySubFamily),
      switchMap(idCommunitySubFamily =>
        this.communitySubFamilyApiService.getCommunitySubFamily(idCommunitySubFamily).pipe(
          map((communitySubFamily: CommunitySubFamily) => {
            return CommunitySubFamilyGeneratedActions.normalizeManyCommunitySubFamilysAfterUpsert({
              communitySubFamilys: [communitySubFamily]
            });
          }),
          catchError(error => of(CommunitySubFamilyGeneratedActions.communitySubFamilysFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunitySubFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunitySubFamilyGeneratedActions.upsertOneCommunitySubFamily),
      concatMap(
        ({
          communitySubFamily,
          ids
        }: {
          communitySubFamily: Partial<CommunitySubFamily>;
          ids?: CommunitySubFamilyRelationsIds;
        }) => {
          if (communitySubFamily.idCommunitySubFamily) {
            return this.communitySubFamilyApiService.updateCommunitySubFamily(communitySubFamily).pipe(
              map((communitySubFamilyReturned: CommunitySubFamily) => {
                return CommunitySubFamilyGeneratedActions.normalizeManyCommunitySubFamilysAfterUpsert({
                  communitySubFamilys: [communitySubFamilyReturned]
                });
              }),
              catchError(error => of(CommunitySubFamilyGeneratedActions.communitySubFamilysFailure({ error })))
            );
          } else {
            return this.communitySubFamilyApiService.addCommunitySubFamily(communitySubFamily).pipe(
              mergeMap((communitySubFamilyReturned: CommunitySubFamily) =>
                getDefaultAddCommunitySubFamilyActions(communitySubFamilyReturned, ids)
              ),
              catchError(error => of(CommunitySubFamilyGeneratedActions.communitySubFamilysFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCommunitySubFamily$ = createEffect(() => {
    const selectCommunitySubFamilyState$ = this.store$.select(
      CommunitySubFamilySelectors.selectCommunitySubFamilyState
    );
    return this.actions$.pipe(
      ofType(CommunitySubFamilyGeneratedActions.deleteOneCommunitySubFamily),
      withLatestFrom(selectCommunitySubFamilyState$),
      concatMap(([{ idCommunitySubFamily }, state]) =>
        this.communitySubFamilyApiService.deleteCommunitySubFamily(idCommunitySubFamily).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunitySubFamilyActions(
              state.entities[idCommunitySubFamily] as CommunitySubFamilyEntityState
            )
          ),
          catchError(error => of(CommunitySubFamilyGeneratedActions.communitySubFamilysFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunitySubFamilysAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunitySubFamilyGeneratedActions.normalizeManyCommunitySubFamilysAfterUpsert),
      concatMap(({ communitySubFamilys }) => {
        const actions: Action[] = getActionsToNormalizeCommunitySubFamily(communitySubFamilys, StoreActionType.upsert);
        return [getMultiAction(actions, '[CommunitySubFamily] Normalization After Upsert Success')];
      })
    );
  });
}
