import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import * as organizationMilestoneFamilyActions from '@wip/store/actions';
import { OrganizationMilestoneFamilyApiService } from '@wip/store/api-services';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import {
  GeneratedOrganizationMilestoneFamilyEffects,
  getDefaultAddOrganizationMilestoneFamilyActions
} from './organization-milestone-family-generated.effects';
import { OrganizationMilestoneFamilyRelationsIds } from '@wip/store/ids-interfaces';

@Injectable()
export class OrganizationMilestoneFamilyEffects extends GeneratedOrganizationMilestoneFamilyEffects {
  constructor(
    actions$: Actions,
    organizationMilestoneFamilyApiService: OrganizationMilestoneFamilyApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationMilestoneFamilyApiService, store$);
  }

  upsertOneOrganizationMilestoneFamilySelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationMilestoneFamilyActions.upsertOneOrganizationMilestoneFamilySelected),
      concatMap(
        ({
          organizationMilestoneFamily,
          ids
        }: {
          organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>;
          ids?: OrganizationMilestoneFamilyRelationsIds;
        }) => {
          if (organizationMilestoneFamily.idOrganizationMilestoneFamily) {
            return this.organizationMilestoneFamilyApiService
              .updateOrganizationMilestoneFamilySelected(organizationMilestoneFamily)
              .pipe(
                map((organizationMilestoneFamilyReturned: OrganizationMilestoneFamily[]) => {
                  return organizationMilestoneFamilyActions.OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert(
                    {
                      organizationMilestoneFamilys: organizationMilestoneFamilyReturned
                    }
                  );
                }),
                catchError(error => {
                  return of(
                    organizationMilestoneFamilyActions.OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure(
                      { error }
                    )
                  );
                })
              );
          } else {
            return this.organizationMilestoneFamilyApiService
              .addOrganizationMilestoneFamily(organizationMilestoneFamily)
              .pipe(
                mergeMap((organizationMilestoneFamilyReturned: OrganizationMilestoneFamily) =>
                  getDefaultAddOrganizationMilestoneFamilyActions(organizationMilestoneFamilyReturned, ids)
                ),
                catchError(error =>
                  of(
                    organizationMilestoneFamilyActions.OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure(
                      { error }
                    )
                  )
                )
              );
          }
        }
      )
    );
  });

  upsertOneOrganizationFamilyWithMilestones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationMilestoneFamilyActions.upsertOneOrganizationFamilyWithMilestones),
      concatMap(
        ({
          organizationMilestoneFamily,
          ids
        }: {
          organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>;
          ids?: OrganizationMilestoneFamilyRelationsIds;
        }) => {
          if (organizationMilestoneFamily.idOrganizationMilestoneFamily) {
            const idsFamilies = ids.organizationMilestones;
            return this.organizationMilestoneFamilyApiService
              .upsertOneOrganizationFamilyWithMilestones({
                idOrganizationMilestoneFamily: organizationMilestoneFamily.idOrganizationMilestoneFamily,
                idsMilestones: idsFamilies
              })
              .pipe(
                map((organizationMilestoneFamilysReturned: OrganizationMilestoneFamily) => {
                  return organizationMilestoneFamilyActions.OrganizationMilestoneFamilyGeneratedActions.normalizeManyOrganizationMilestoneFamilysAfterUpsert(
                    {
                      organizationMilestoneFamilys: [organizationMilestoneFamilysReturned]
                    }
                  );
                }),
                catchError(error =>
                  of(
                    organizationMilestoneFamilyActions.OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure(
                      { error }
                    )
                  )
                )
              );
          } else {
            return this.organizationMilestoneFamilyApiService
              .addOrganizationMilestoneFamily(organizationMilestoneFamily)
              .pipe(
                mergeMap((organizationMilestoneFamilyReturned: OrganizationMilestoneFamily) =>
                  getDefaultAddOrganizationMilestoneFamilyActions(organizationMilestoneFamilyReturned, ids)
                ),
                catchError(error =>
                  of(
                    organizationMilestoneFamilyActions.OrganizationMilestoneFamilyGeneratedActions.organizationMilestoneFamilysFailure(
                      { error }
                    )
                  )
                )
              );
          }
        }
      )
    );
  });
}
