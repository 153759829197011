import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { UserDevice, UserDeviceEntityState } from '@api/api-interfaces';
import { UserDeviceApiService } from '@wip/store/api-services';
import { UserDeviceGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeUserDevice } from '@wip/store/configs/normalization';
import { UserDeviceSelectors } from '@wip/store/selectors';
import { UserDeviceRelationsIds } from '@wip/store/ids-interfaces';
import { UserGeneratedActions } from '@wip/store/actions';

export function getDefaultAddUserDeviceActions(
  userDevice: UserDeviceEntityState,
  ids?: UserDeviceRelationsIds
): Action[] {
  const actions: Action[] = [
    UserDeviceGeneratedActions.normalizeManyUserDevicesAfterUpsert({ userDevices: [userDevice] })
  ];

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addUserDeviceSuccess({
        idUser: ids.user,
        idUserDevice: userDevice.idUserDevice
      })
    );
    actions.push(
      UserDeviceGeneratedActions.addUserSuccess({
        idUserDevice: userDevice.idUserDevice,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteUserDeviceActions(userDevice: UserDeviceEntityState): Action[] {
  const actions: Action[] = [
    UserDeviceGeneratedActions.deleteOneUserDeviceSuccess({ idUserDevice: userDevice.idUserDevice })
  ];

  if (userDevice.user) {
    actions.push(
      UserGeneratedActions.deleteUserDeviceSuccess({
        idUserDevices: [userDevice.idUserDevice],
        idUsers: [userDevice.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedUserDeviceEffects {
  constructor(
    protected actions$: Actions,
    protected userDeviceApiService: UserDeviceApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUserDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserDeviceGeneratedActions.getManyUserDevices),
      switchMap(({ params }) =>
        this.userDeviceApiService.getUserDevices(params).pipe(
          map((userDevices: UserDevice[]) => {
            return UserDeviceGeneratedActions.normalizeManyUserDevicesAfterUpsert({ userDevices });
          }),
          catchError(error => of(UserDeviceGeneratedActions.userDevicesFailure({ error })))
        )
      )
    );
  });

  getOneUserDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserDeviceGeneratedActions.getOneUserDevice),
      switchMap(idUserDevice =>
        this.userDeviceApiService.getUserDevice(idUserDevice).pipe(
          map((userDevice: UserDevice) => {
            return UserDeviceGeneratedActions.normalizeManyUserDevicesAfterUpsert({ userDevices: [userDevice] });
          }),
          catchError(error => of(UserDeviceGeneratedActions.userDevicesFailure({ error })))
        )
      )
    );
  });

  upsertOneUserDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserDeviceGeneratedActions.upsertOneUserDevice),
      concatMap(({ userDevice, ids }: { userDevice: Partial<UserDevice>; ids?: UserDeviceRelationsIds }) => {
        if (userDevice.idUserDevice) {
          return this.userDeviceApiService.updateUserDevice(userDevice).pipe(
            map((userDeviceReturned: UserDevice) => {
              return UserDeviceGeneratedActions.normalizeManyUserDevicesAfterUpsert({
                userDevices: [userDeviceReturned]
              });
            }),
            catchError(error => of(UserDeviceGeneratedActions.userDevicesFailure({ error })))
          );
        } else {
          return this.userDeviceApiService.addUserDevice(userDevice).pipe(
            mergeMap((userDeviceReturned: UserDevice) => getDefaultAddUserDeviceActions(userDeviceReturned, ids)),
            catchError(error => of(UserDeviceGeneratedActions.userDevicesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneUserDevice$ = createEffect(() => {
    const selectUserDeviceState$ = this.store$.select(UserDeviceSelectors.selectUserDeviceState);
    return this.actions$.pipe(
      ofType(UserDeviceGeneratedActions.deleteOneUserDevice),
      withLatestFrom(selectUserDeviceState$),
      concatMap(([{ idUserDevice }, state]) =>
        this.userDeviceApiService.deleteUserDevice(idUserDevice).pipe(
          mergeMap(_success =>
            getDefaultDeleteUserDeviceActions(state.entities[idUserDevice] as UserDeviceEntityState)
          ),
          catchError(error => of(UserDeviceGeneratedActions.userDevicesFailure({ error })))
        )
      )
    );
  });

  normalizeManyUserDevicesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserDeviceGeneratedActions.normalizeManyUserDevicesAfterUpsert),
      concatMap(({ userDevices }) => {
        const actions: Action[] = getActionsToNormalizeUserDevice(userDevices, StoreActionType.upsert);
        return [getMultiAction(actions, '[UserDevice] Normalization After Upsert Success')];
      })
    );
  });
}
