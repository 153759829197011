import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConfigurationApiService } from './configuration-api.service';

@UntilDestroy()
@Injectable()
export class AdminApiService {
  constructor(
    private http: HttpClient,
    private configurationApi: ConfigurationApiService
  ) {}

  create(fileToUpload?: File, importForm?: any) {
    if (fileToUpload) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('idOrganization', importForm.currentOrganizationId);
      formData.append('userAdd', importForm.userAdd);
      formData.append('upsertCommunities', importForm.upsertCommunities);
      formData.append('upsertMilestones', importForm.upsertMilestones);
      formData.append('upsertCharacteristics', importForm.upsertCharacteristics);
      formData.append('upsertUsers', importForm.upsertUsers);
      formData.append('upsertTeam', importForm.upsertTeam);
      formData.append('upsertRepartition', importForm.upsertRepartition);
      formData.append('idUser', importForm.idUser);

      const options = {
        params: { idOrganization: importForm.currentOrganizationId },
        reportProgress: true
      };

      return this.http.post(this.configurationApi.fullAddressV2 + '/organization/importFile', formData, options).pipe(
        untilDestroyed(this),
        map((retour: any) => {
          return retour;
        })
      );
    }
    return null;
  }
}
