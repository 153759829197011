import { environment } from '@wip/environments';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationUserApiService } from './organization-user-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationUserApiService extends GeneratedOrganizationUserApiService {
  constructor(
    repo: RepositoryService,
    private http: HttpClient
  ) {
    super(repo);
  }

  public getRightsForCommunity(idCommunity: number) {
    return this.http.get(environment.apiV2Url + '/organization-user/get-rights/' + idCommunity);
  }
}
