import { createAction, props } from '@ngrx/store';

export * from './community-user-generated.actions';

export const clearCommunityUsers = createAction(
  '[CommunityUser] Clear CommunityUsers In Group',
  props<{ idCommunityUsers: number[] }>()
);

export const resetCommunityUsers = createAction(
  '[CommunityUser] Clear CommunityUsers On Group Modification',
  props<{ idCommunityUser: number}>()
);
