import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationCaracteristicEntityState } from '@api/api-interfaces';

export const organizationCaracteristicReducersGeneratedFunctions: ReducerTypes<
  OrganizationCaracteristicState.IState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationCaracteristicGeneratedActions.getOneOrganizationCaracteristic,
    (state: OrganizationCaracteristicState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicGeneratedActions.getManyOrganizationCaracteristics,
    (state: OrganizationCaracteristicState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicGeneratedActions.upsertOneOrganizationCaracteristic,
    (state: OrganizationCaracteristicState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationCaracteristicGeneratedActions.upsertManyOrganizationCaracteristicsSuccess,
    (state: OrganizationCaracteristicState.IState, { organizationCaracteristics }) =>
      OrganizationCaracteristicState.adapter.upsertMany(organizationCaracteristics, setLoadingsState(state, false))
  ),
  on(
    OrganizationCaracteristicGeneratedActions.deleteOneOrganizationCaracteristic,
    (state: OrganizationCaracteristicState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationCaracteristicGeneratedActions.deleteOneOrganizationCaracteristicSuccess,
    (state: OrganizationCaracteristicState.IState, { idOrganizationCaracteristic }) =>
      OrganizationCaracteristicState.adapter.removeOne(idOrganizationCaracteristic, setLoadingsState(state, false))
  ),
  on(
    OrganizationCaracteristicGeneratedActions.clearActivesOrganizationCaracteristics,
    (state: OrganizationCaracteristicState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationCaracteristicGeneratedActions.addManyActivesOrganizationCaracteristics,
    (state: OrganizationCaracteristicState.IState, { idOrganizationCaracteristics }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationCaracteristics)
    })
  ),
  on(
    OrganizationCaracteristicGeneratedActions.deleteOneActiveOrganizationCaracteristic,
    (state: OrganizationCaracteristicState.IState, { idOrganizationCaracteristic }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationCaracteristic)
    })
  ),

  on(
    OrganizationCaracteristicGeneratedActions.clearOrganizationCaracteristics,
    () => OrganizationCaracteristicState.initialState
  ),

  on(
    OrganizationCaracteristicGeneratedActions.addManyCommunityCaracteristicSuccess,
    (state: OrganizationCaracteristicState.IState, { idOrganizationCaracteristic, idCommunityCaracteristics }) => {
      if (!state.entities[idOrganizationCaracteristic]) {
        return state;
      }
      const communityCaracteristics =
        (state.entities[idOrganizationCaracteristic]?.communityCaracteristics as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristic]: {
            ...state.entities[idOrganizationCaracteristic],
            communityCaracteristics: communityCaracteristics.concat(
              idCommunityCaracteristics.filter(id => communityCaracteristics.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.deleteManyCommunityCaracteristicSuccess,
    (state: OrganizationCaracteristicState.IState, { idCommunityCaracteristics, idOrganizationCaracteristics }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristics.reduce((entities, idOrganizationCaracteristic) => {
            if (!state.entities[idOrganizationCaracteristic]?.communityCaracteristics) {
              return entities;
            }
            entities[idOrganizationCaracteristic] = {
              ...state.entities[idOrganizationCaracteristic],
              communityCaracteristics: (
                state.entities[idOrganizationCaracteristic]?.communityCaracteristics as number[]
              )?.filter(
                (idCommunityCaracteristic: number) =>
                  !idCommunityCaracteristics.some((id: number) => id === idCommunityCaracteristic)
              )
            } as OrganizationCaracteristicEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.addManyOrganizationMilestoneFamilySuccess,
    (state: OrganizationCaracteristicState.IState, { idOrganizationCaracteristic, idOrganizationMilestoneFamilys }) => {
      if (!state.entities[idOrganizationCaracteristic]) {
        return state;
      }
      const organizationMilestoneFamilys =
        (state.entities[idOrganizationCaracteristic]?.organizationMilestoneFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristic]: {
            ...state.entities[idOrganizationCaracteristic],
            organizationMilestoneFamilys: organizationMilestoneFamilys.concat(
              idOrganizationMilestoneFamilys.filter(id => organizationMilestoneFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess,
    (
      state: OrganizationCaracteristicState.IState,
      { idOrganizationMilestoneFamilys, idOrganizationCaracteristics }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristics.reduce((entities, idOrganizationCaracteristic) => {
            if (!state.entities[idOrganizationCaracteristic]?.organizationMilestoneFamilys) {
              return entities;
            }
            entities[idOrganizationCaracteristic] = {
              ...state.entities[idOrganizationCaracteristic],
              organizationMilestoneFamilys: (
                state.entities[idOrganizationCaracteristic]?.organizationMilestoneFamilys as number[]
              )?.filter(
                (idOrganizationMilestoneFamily: number) =>
                  !idOrganizationMilestoneFamilys.some((id: number) => id === idOrganizationMilestoneFamily)
              )
            } as OrganizationCaracteristicEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.addManyOrganizationCaracteristicColumnSuccess,
    (
      state: OrganizationCaracteristicState.IState,
      { idOrganizationCaracteristic, idOrganizationCaracteristicColumns }
    ) => {
      if (!state.entities[idOrganizationCaracteristic]) {
        return state;
      }
      const organizationCaracteristicColumns =
        (state.entities[idOrganizationCaracteristic]?.organizationCaracteristicColumns as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristic]: {
            ...state.entities[idOrganizationCaracteristic],
            organizationCaracteristicColumns: organizationCaracteristicColumns.concat(
              idOrganizationCaracteristicColumns.filter(id => organizationCaracteristicColumns.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.deleteManyOrganizationCaracteristicColumnSuccess,
    (
      state: OrganizationCaracteristicState.IState,
      { idOrganizationCaracteristicColumns, idOrganizationCaracteristics }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristics.reduce((entities, idOrganizationCaracteristic) => {
            if (!state.entities[idOrganizationCaracteristic]?.organizationCaracteristicColumns) {
              return entities;
            }
            entities[idOrganizationCaracteristic] = {
              ...state.entities[idOrganizationCaracteristic],
              organizationCaracteristicColumns: (
                state.entities[idOrganizationCaracteristic]?.organizationCaracteristicColumns as number[]
              )?.filter(
                (idOrganizationCaracteristicColumn: number) =>
                  !idOrganizationCaracteristicColumns.some((id: number) => id === idOrganizationCaracteristicColumn)
              )
            } as OrganizationCaracteristicEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.addManyOrganizationCaracteristicRowSuccess,
    (
      state: OrganizationCaracteristicState.IState,
      { idOrganizationCaracteristic, idOrganizationCaracteristicRows }
    ) => {
      if (!state.entities[idOrganizationCaracteristic]) {
        return state;
      }
      const organizationCaracteristicRows =
        (state.entities[idOrganizationCaracteristic]?.organizationCaracteristicRows as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristic]: {
            ...state.entities[idOrganizationCaracteristic],
            organizationCaracteristicRows: organizationCaracteristicRows.concat(
              idOrganizationCaracteristicRows.filter(id => organizationCaracteristicRows.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.deleteManyOrganizationCaracteristicRowSuccess,
    (
      state: OrganizationCaracteristicState.IState,
      { idOrganizationCaracteristicRows, idOrganizationCaracteristics }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristics.reduce((entities, idOrganizationCaracteristic) => {
            if (!state.entities[idOrganizationCaracteristic]?.organizationCaracteristicRows) {
              return entities;
            }
            entities[idOrganizationCaracteristic] = {
              ...state.entities[idOrganizationCaracteristic],
              organizationCaracteristicRows: (
                state.entities[idOrganizationCaracteristic]?.organizationCaracteristicRows as number[]
              )?.filter(
                (idOrganizationCaracteristicRow: number) =>
                  !idOrganizationCaracteristicRows.some((id: number) => id === idOrganizationCaracteristicRow)
              )
            } as OrganizationCaracteristicEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.addOrganizationSuccess,
    (state: OrganizationCaracteristicState.IState, { idOrganizationCaracteristic, idOrganization }) => {
      if (!state.entities[idOrganizationCaracteristic]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristic]: {
            ...state.entities[idOrganizationCaracteristic],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationCaracteristicState.IState, { idOrganizations, idOrganizationCaracteristics }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristics.reduce((entities, idOrganizationCaracteristic) => {
            if (!state.entities[idOrganizationCaracteristic]?.organization) {
              return entities;
            }
            entities[idOrganizationCaracteristic] = {
              ...state.entities[idOrganizationCaracteristic],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationCaracteristic]?.organization
              )
                ? undefined
                : state.entities[idOrganizationCaracteristic]?.organization
            } as OrganizationCaracteristicEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.addOrganizationFamilySuccess,
    (state: OrganizationCaracteristicState.IState, { idOrganizationCaracteristic, idOrganizationFamily }) => {
      if (!state.entities[idOrganizationCaracteristic]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristic]: {
            ...state.entities[idOrganizationCaracteristic],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationCaracteristicState.IState, { idOrganizationFamilys, idOrganizationCaracteristics }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristics.reduce((entities, idOrganizationCaracteristic) => {
            if (!state.entities[idOrganizationCaracteristic]?.organizationFamily) {
              return entities;
            }
            entities[idOrganizationCaracteristic] = {
              ...state.entities[idOrganizationCaracteristic],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idOrganizationCaracteristic]?.organizationFamily
              )
                ? undefined
                : state.entities[idOrganizationCaracteristic]?.organizationFamily
            } as OrganizationCaracteristicEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.addOrganizationDataColumnSuccess,
    (state: OrganizationCaracteristicState.IState, { idOrganizationCaracteristic, idOrganizationDataColumn }) => {
      if (!state.entities[idOrganizationCaracteristic]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationCaracteristic]: {
            ...state.entities[idOrganizationCaracteristic],
            organizationDataColumn: idOrganizationDataColumn
          }
        }
      };
    }
  ),

  on(
    OrganizationCaracteristicGeneratedActions.deleteManyOrganizationDataColumnSuccess,
    (state: OrganizationCaracteristicState.IState, { idOrganizationDataColumns, idOrganizationCaracteristics }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationCaracteristics.reduce((entities, idOrganizationCaracteristic) => {
            if (!state.entities[idOrganizationCaracteristic]?.organizationDataColumn) {
              return entities;
            }
            entities[idOrganizationCaracteristic] = {
              ...state.entities[idOrganizationCaracteristic],
              organizationDataColumn: idOrganizationDataColumns.some(
                (idOrganizationDataColumn: number) =>
                  idOrganizationDataColumn === state.entities[idOrganizationCaracteristic]?.organizationDataColumn
              )
                ? undefined
                : state.entities[idOrganizationCaracteristic]?.organizationDataColumn
            } as OrganizationCaracteristicEntityState;
            return entities;
          }, {} as Dictionary<OrganizationCaracteristicEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationCaracteristicState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationCaracteristicState.IState {
  return { ...state, isLoaded, isLoading };
}
