import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { GroupApiService } from '@wip/store/api-services';
import { GroupGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeGroup } from '@wip/store/configs/normalization';
import { GroupSelectors } from '@wip/store/selectors';
import { GroupRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { CommunityGroupGeneratedActions } from '@wip/store/actions';
import { CommunityGroup } from '@api/api-interfaces';
import { UserGroupGeneratedActions } from '@wip/store/actions';
import { UserGroup } from '@api/api-interfaces';
import { OrganizationAzureGeneratedActions } from '@wip/store/actions';
import { OrganizationAzure } from '@api/api-interfaces';
import { UserGeneratedActions } from '@wip/store/actions';
import { User } from '@api/api-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { Community } from '@api/api-interfaces';

export function getDefaultAddGroupActions(group: GroupEntityState, ids?: GroupRelationsIds): Action[] {
  const actions: Action[] = [GroupGeneratedActions.normalizeManyGroupsAfterUpsert({ groups: [group] })];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyGroupSuccess({
        idOrganization: ids.organization,
        idGroups: [group.idGroup]
      })
    );
    actions.push(
      GroupGeneratedActions.addOrganizationSuccess({
        idGroup: group.idGroup,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.communityGroups) {
    if (!Array.isArray(ids.communityGroups)) {
      actions.push(
        CommunityGroupGeneratedActions.upsertOneCommunityGroup({
          communityGroup: {
            idGroup: group.idGroup,
            idCommunityGroup: ids.communityGroups as number
          } as CommunityGroup
        })
      );
      actions.push(
        GroupGeneratedActions.addManyCommunityGroupSuccess({
          idGroup: group.idGroup,
          idCommunityGroups: [ids.communityGroups as number]
        })
      );
    } else {
      actions.push(
        CommunityGroupGeneratedActions.upsertManyCommunityGroups({
          communityGroups: (ids.communityGroups as number[]).map((idCommunityGroup: number) => ({
            idGroup: group.idGroup,
            idCommunityGroup
          })) as CommunityGroup[]
        })
      );
      actions.push(
        GroupGeneratedActions.addManyCommunityGroupSuccess({
          idGroup: group.idGroup,
          idCommunityGroups: ids.communityGroups as number[]
        })
      );
    }
  }

  if (ids?.userGroups) {
    if (!Array.isArray(ids.userGroups)) {
      actions.push(
        UserGroupGeneratedActions.upsertOneUserGroup({
          userGroup: {
            idGroup: group.idGroup,
            idUserGroup: ids.userGroups as number
          } as UserGroup
        })
      );
      actions.push(
        GroupGeneratedActions.addManyUserGroupSuccess({
          idGroup: group.idGroup,
          idUserGroups: [ids.userGroups as number]
        })
      );
    } else {
      actions.push(
        UserGroupGeneratedActions.upsertManyUserGroups({
          userGroups: (ids.userGroups as number[]).map((idUserGroup: number) => ({
            idGroup: group.idGroup,
            idUserGroup
          })) as UserGroup[]
        })
      );
      actions.push(
        GroupGeneratedActions.addManyUserGroupSuccess({
          idGroup: group.idGroup,
          idUserGroups: ids.userGroups as number[]
        })
      );
    }
  }

  if (ids?.organizationAzures) {
    if (!Array.isArray(ids.organizationAzures)) {
      actions.push(
        OrganizationAzureGeneratedActions.upsertOneOrganizationAzure({
          organizationAzure: {
            idGroup: group.idGroup,
            idOrganizationAzure: ids.organizationAzures as number
          } as OrganizationAzure
        })
      );
      actions.push(
        GroupGeneratedActions.addManyOrganizationAzureSuccess({
          idGroup: group.idGroup,
          idOrganizationAzures: [ids.organizationAzures as number]
        })
      );
    } else {
      actions.push(
        OrganizationAzureGeneratedActions.upsertManyOrganizationAzures({
          organizationAzures: (ids.organizationAzures as number[]).map((idOrganizationAzure: number) => ({
            idGroup: group.idGroup,
            idOrganizationAzure
          })) as OrganizationAzure[]
        })
      );
      actions.push(
        GroupGeneratedActions.addManyOrganizationAzureSuccess({
          idGroup: group.idGroup,
          idOrganizationAzures: ids.organizationAzures as number[]
        })
      );
    }
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        UserGeneratedActions.upsertOneUser({
          user: {
            idGroup: group.idGroup,
            idUser: ids.users as number
          } as User & any
        })
      );
      actions.push(
        GroupGeneratedActions.addManyUserSuccess({
          idGroup: group.idGroup,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        UserGeneratedActions.upsertManyUsers({
          users: (ids.users as number[]).map((idUser: number) => ({
            idGroup: group.idGroup,
            idUser
          })) as User[] & any[]
        })
      );
      actions.push(
        GroupGeneratedActions.addManyUserSuccess({
          idGroup: group.idGroup,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.communities) {
    if (!Array.isArray(ids.communities)) {
      actions.push(
        CommunityGeneratedActions.upsertOneCommunity({
          community: {
            idGroup: group.idGroup,
            idCommunity: ids.communities as number
          } as Community & any
        })
      );
      actions.push(
        GroupGeneratedActions.addManyCommunitySuccess({
          idGroup: group.idGroup,
          idCommunities: [ids.communities as number]
        })
      );
    } else {
      actions.push(
        CommunityGeneratedActions.upsertManyCommunities({
          communities: (ids.communities as number[]).map((idCommunity: number) => ({
            idGroup: group.idGroup,
            idCommunity
          })) as Community[] & any[]
        })
      );
      actions.push(
        GroupGeneratedActions.addManyCommunitySuccess({
          idGroup: group.idGroup,
          idCommunities: ids.communities as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteGroupActions(group: GroupEntityState): Action[] {
  const actions: Action[] = [GroupGeneratedActions.deleteOneGroupSuccess({ idGroup: group.idGroup })];

  if (group.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyGroupSuccess({
        idGroups: [group.idGroup],
        idOrganizations: [group.organization as number]
      })
    );
  }

  if (group.communityGroups) {
    actions.push(
      CommunityGroupGeneratedActions.deleteManyGroupSuccess({
        idGroups: [group.idGroup],
        idCommunityGroups: group.communityGroups as number[]
      })
    );
  }

  if (group.userGroups) {
    actions.push(
      UserGroupGeneratedActions.deleteManyGroupSuccess({
        idGroups: [group.idGroup],
        idUserGroups: group.userGroups as number[]
      })
    );
  }

  if (group.organizationAzures) {
    actions.push(
      OrganizationAzureGeneratedActions.deleteManyGroupSuccess({
        idGroups: [group.idGroup],
        idOrganizationAzures: group.organizationAzures as number[]
      })
    );
  }

  if (group.users) {
    actions.push(
      UserGeneratedActions.deleteManyGroupSuccess({
        idGroups: [group.idGroup],
        idUsers: group.users as number[]
      })
    );
  }

  if (group.communities) {
    actions.push(
      CommunityGeneratedActions.deleteManyGroupSuccess({
        idGroups: [group.idGroup],
        idCommunities: group.communities as number[]
      })
    );
  }

  return actions;
}

export class GeneratedGroupEffects {
  constructor(
    protected actions$: Actions,
    protected groupApiService: GroupApiService,
    protected store$: Store<AppState>
  ) {}

  getManyGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupGeneratedActions.getManyGroups),
      switchMap(({ params }) =>
        this.groupApiService.getGroups(params).pipe(
          map((groups: Group[]) => {
            return GroupGeneratedActions.normalizeManyGroupsAfterUpsert({ groups });
          }),
          catchError(error => of(GroupGeneratedActions.groupsFailure({ error })))
        )
      )
    );
  });

  getOneGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupGeneratedActions.getOneGroup),
      switchMap(idGroup =>
        this.groupApiService.getGroup(idGroup).pipe(
          map((group: Group) => {
            return GroupGeneratedActions.normalizeManyGroupsAfterUpsert({ groups: [group] });
          }),
          catchError(error => of(GroupGeneratedActions.groupsFailure({ error })))
        )
      )
    );
  });

  upsertOneGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupGeneratedActions.upsertOneGroup),
      concatMap(({ group, ids }: { group: Partial<Group>; ids?: GroupRelationsIds }) => {
        if (group.idGroup) {
          return this.groupApiService.updateGroup(group).pipe(
            map((groupReturned: Group) => {
              return GroupGeneratedActions.normalizeManyGroupsAfterUpsert({ groups: [groupReturned] });
            }),
            catchError(error => of(GroupGeneratedActions.groupsFailure({ error })))
          );
        } else {
          return this.groupApiService.addGroup(group).pipe(
            mergeMap((groupReturned: Group) => getDefaultAddGroupActions(groupReturned, ids)),
            catchError(error => of(GroupGeneratedActions.groupsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneGroup$ = createEffect(() => {
    const selectGroupState$ = this.store$.select(GroupSelectors.selectGroupState);
    return this.actions$.pipe(
      ofType(GroupGeneratedActions.deleteOneGroup),
      withLatestFrom(selectGroupState$),
      concatMap(([{ idGroup }, state]) =>
        this.groupApiService.deleteGroup(idGroup).pipe(
          mergeMap(_success => getDefaultDeleteGroupActions(state.entities[idGroup] as GroupEntityState)),
          catchError(error => of(GroupGeneratedActions.groupsFailure({ error })))
        )
      )
    );
  });

  normalizeManyGroupsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupGeneratedActions.normalizeManyGroupsAfterUpsert),
      concatMap(({ groups }) => {
        const actions: Action[] = getActionsToNormalizeGroup(groups, StoreActionType.upsert);
        return [getMultiAction(actions, '[Group] Normalization After Upsert Success')];
      })
    );
  });
}
