import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserApiService } from '@wip/store/api-services';
import { GeneratedUserEffects } from './user-generated.effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { User } from '@api/api-interfaces';
import { UserService } from '@wip/store/services';
import * as userActions from '@wip/store/actions';

@Injectable()
export class UserEffects extends GeneratedUserEffects {
  private newUserService: UserService;

  constructor(
    actions$: Actions,
    userApiService2: UserApiService,
    store$: Store<AppState>,
    newUserService: UserService
  ) {
    super(actions$, userApiService2, store$);
    this.newUserService = newUserService;
  }

  getCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.getCurrentUser),
      switchMap(params =>
        this.userApiService.getCurrentUser(params.idUser).pipe(
          map((users: User[]) => {
            this.newUserService.isRoot = users[0].isRoot;
            return userActions.UserGeneratedActions.normalizeManyUsersAfterUpsert({ users });
          }),
          catchError(error => of(userActions.UserGeneratedActions.usersFailure({ error })))
        )
      )
    );
  });
}
