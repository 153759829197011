import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommunityMilestoneFamilyApiService } from '@wip/store/api-services';
import { GeneratedCommunityMilestoneFamilyEffects } from './community-milestone-family-generated.effects';

@Injectable()
export class CommunityMilestoneFamilyEffects extends GeneratedCommunityMilestoneFamilyEffects {
  constructor(
    actions$: Actions,
    communityMilestoneFamilyApiService: CommunityMilestoneFamilyApiService,
    store$: Store<AppState>
  ) {
    super(actions$, communityMilestoneFamilyApiService, store$);
  }
}
