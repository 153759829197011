import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@wip/environments';

@Injectable()
export class ConfigurationApiService {
  protected protocol: string;
  protected address: string;
  protected port: number;
  public fullAddressV1: string;
  public fullAddressV2: string;

  public url: string;

  constructor() {
    this.url = environment.apiV1Url.split(':').slice(0, 2).join('');
    this.fullAddressV1 = environment.apiV1Url;
    this.fullAddressV2 = environment.apiV2Url;
  }

  changeConnection(protocol: string, address: string, port: number) {
    this.protocol = protocol;
    this.address = address;
    this.port = port;
  }

  setParams(object: any) {
    let params = new HttpParams();
    for (const key in object) {
      if (object[key] !== null && object[key] !== undefined) {
        if (Array.isArray(object[key])) {
          object[key].forEach(element => {
            params = params.append(`${key}[]`, element);
          });
        } else {
          params = params.append(key, object[key]);
        }
      }
    }
    return params;
  }
}
