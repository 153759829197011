import { OrganizationState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationReducersGeneratedFunctions } from './organization-generated.reducer';

const organizationReducersFunctions = [...organizationReducersGeneratedFunctions];

const organizationReducer = createReducer(OrganizationState.initialState, ...organizationReducersFunctions);

export function reducer(state: OrganizationState.IState | undefined, action: Action) {
  return organizationReducer(state, action);
}
