import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProfilApiService } from '@wip/store/api-services';
import { GeneratedProfilEffects } from './profil-generated.effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { ProfilActions, ProfilGeneratedActions } from '@wip/store/actions';
import { Profil } from '@api/api-interfaces';

@Injectable()
export class ProfilEffects extends GeneratedProfilEffects {
  constructor(actions$: Actions, profilApiService: ProfilApiService, store$: Store<AppState>) {
    super(actions$, profilApiService, store$);
  }

  reorderProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilActions.reorderProfils),
      switchMap(({ params }) =>
        this.profilApiService.reorderProfils(params).pipe(
          map((profils: Profil[]) => {
            return ProfilGeneratedActions.normalizeManyProfilsAfterUpsert({ profils });
          }),
          catchError(error => of(ProfilGeneratedActions.profilsFailure({ error })))
        )
      )
    );
  });

  duplicateProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilActions.duplicateProfil),
      switchMap(({ idProfil }) =>
        this.profilApiService.duplicateProfil(idProfil).pipe(
          map((profil: Profil) => {
            return ProfilGeneratedActions.normalizeManyProfilsAfterUpsert({ profils: [profil] });
          }),
          catchError(error => of(ProfilGeneratedActions.profilsFailure({ error })))
        )
      )
    );
  });
}
