import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationChoicesInterface } from '@wip/interfaces';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'up-confirmation-choices-dialog-dumb',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './confirmation-choices-dialog-dumb.component.html',
  styleUrls: ['./confirmation-choices-dialog-dumb.component.scss']
})
export class ConfirmationChoicesDialogDumbComponent implements OnInit {
  @Input() title!: string;
  @Input() body!: string;
  @Input() actions!: Map<string, ConfirmationChoicesInterface>;

  constructor() {}

  ngOnInit(): void {}
}
