@if (organizationsSorted.length > 1) {
  <button mat-button [matMenuTriggerFor]="menu" class="orga-choice">
    {{ currentOrganization?.raisonSociale }}
  </button>
  <mat-menu #menu="matMenu">
    @for (organization of organizationsSorted; track organization.idOrganization) {
      <button mat-menu-item [value]="organization" (click)="onOrganizationSelected(organization)">
        {{ organization.raisonSociale }}
      </button>
    }
  </mat-menu>
}

<!-- <mat-form-field class="mini" style="color: white">
  <mat-select class="mini" placeholder="" [formControl]="userChoicesFormGroup?.get('organization')">
    @for (organization of listOrganization; track organization) {
      <mat-option [value]="organization">
        {{ organization.raisonSociale }}
      </mat-option>
    }
  </mat-select>
</mat-form-field> -->
