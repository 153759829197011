import { SectionState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { sectionReducersGeneratedFunctions } from './section-generated.reducer';

const sectionReducersFunctions = [...sectionReducersGeneratedFunctions];

const sectionReducer = createReducer(SectionState.initialState, ...sectionReducersFunctions);

export function reducer(state: SectionState.IState | undefined, action: Action) {
  return sectionReducer(state, action);
}
