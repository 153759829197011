import { RepositoryService } from '@wip/services/repository';
import { OrganizationElement } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationElementApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationElements(params?: any): Observable<OrganizationElement[]> {
    return this.repo.getData<OrganizationElement[]>('organization-element', params);
  }

  public getOrganizationElement(params: {
    idOrganizationElement: number;
    params?: any;
  }): Observable<OrganizationElement> {
    return this.repo.getData<OrganizationElement>(
      'organization-element/' + params.idOrganizationElement,
      params.params
    );
  }

  public addOrganizationElement(organizationElement: Partial<OrganizationElement>): Observable<OrganizationElement> {
    return this.repo.create<OrganizationElement>('organization-element', organizationElement);
  }

  public updateOrganizationElement(organizationElement: Partial<OrganizationElement>): Observable<OrganizationElement> {
    return this.repo.update('organization-element', organizationElement);
  }

  public deleteOrganizationElement(idOrganizationElement: number): Observable<number> {
    return this.repo.delete('organization-element/' + +idOrganizationElement);
  }
}
