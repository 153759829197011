import { ChildrenElementState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { childrenElementReducersGeneratedFunctions } from './children-element-generated.reducer';

const childrenElementReducersFunctions = [...childrenElementReducersGeneratedFunctions];

const childrenElementReducer = createReducer(ChildrenElementState.initialState, ...childrenElementReducersFunctions);

export function reducer(state: ChildrenElementState.IState | undefined, action: Action) {
  return childrenElementReducer(state, action);
}
