import { createEntityAdapter } from '@ngrx/entity';
import { GroupEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<GroupEntityState>;

export const adapter = createEntityAdapter<GroupEntityState>({
  selectId: o => o.idGroup
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const groupFeatureKey = 'group';
