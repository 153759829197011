import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { UserHistory, UserHistoryEntityState } from '@api/api-interfaces';
import { UserHistoryApiService } from '@wip/store/api-services';
import { UserHistoryGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeUserHistory } from '@wip/store/configs/normalization';
import { UserHistorySelectors } from '@wip/store/selectors';
import { UserHistoryRelationsIds } from '@wip/store/ids-interfaces';
import { UserGeneratedActions } from '@wip/store/actions';

export function getDefaultAddUserHistoryActions(
  userHistory: UserHistoryEntityState,
  ids?: UserHistoryRelationsIds
): Action[] {
  const actions: Action[] = [
    UserHistoryGeneratedActions.normalizeManyUserHistoriesAfterUpsert({ userHistories: [userHistory] })
  ];

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyUserHistorySuccess({
        idUser: ids.user,
        idUserHistories: [userHistory.idUserHistory]
      })
    );
    actions.push(
      UserHistoryGeneratedActions.addUserSuccess({
        idUserHistory: userHistory.idUserHistory,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteUserHistoryActions(userHistory: UserHistoryEntityState): Action[] {
  const actions: Action[] = [
    UserHistoryGeneratedActions.deleteOneUserHistorySuccess({ idUserHistory: userHistory.idUserHistory })
  ];

  if (userHistory.user) {
    actions.push(
      UserGeneratedActions.deleteManyUserHistorySuccess({
        idUserHistories: [userHistory.idUserHistory],
        idUsers: [userHistory.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedUserHistoryEffects {
  constructor(
    protected actions$: Actions,
    protected userHistoryApiService: UserHistoryApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUserHistories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserHistoryGeneratedActions.getManyUserHistories),
      switchMap(({ params }) =>
        this.userHistoryApiService.getUserHistories(params).pipe(
          map((userHistories: UserHistory[]) => {
            return UserHistoryGeneratedActions.normalizeManyUserHistoriesAfterUpsert({ userHistories });
          }),
          catchError(error => of(UserHistoryGeneratedActions.userHistoriesFailure({ error })))
        )
      )
    );
  });

  getOneUserHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserHistoryGeneratedActions.getOneUserHistory),
      switchMap(idUserHistory =>
        this.userHistoryApiService.getUserHistory(idUserHistory).pipe(
          map((userHistory: UserHistory) => {
            return UserHistoryGeneratedActions.normalizeManyUserHistoriesAfterUpsert({ userHistories: [userHistory] });
          }),
          catchError(error => of(UserHistoryGeneratedActions.userHistoriesFailure({ error })))
        )
      )
    );
  });

  upsertOneUserHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserHistoryGeneratedActions.upsertOneUserHistory),
      concatMap(({ userHistory, ids }: { userHistory: Partial<UserHistory>; ids?: UserHistoryRelationsIds }) => {
        if (userHistory.idUserHistory) {
          return this.userHistoryApiService.updateUserHistory(userHistory).pipe(
            map((userHistoryReturned: UserHistory) => {
              return UserHistoryGeneratedActions.normalizeManyUserHistoriesAfterUpsert({
                userHistories: [userHistoryReturned]
              });
            }),
            catchError(error => of(UserHistoryGeneratedActions.userHistoriesFailure({ error })))
          );
        } else {
          return this.userHistoryApiService.addUserHistory(userHistory).pipe(
            mergeMap((userHistoryReturned: UserHistory) => getDefaultAddUserHistoryActions(userHistoryReturned, ids)),
            catchError(error => of(UserHistoryGeneratedActions.userHistoriesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneUserHistory$ = createEffect(() => {
    const selectUserHistoryState$ = this.store$.select(UserHistorySelectors.selectUserHistoryState);
    return this.actions$.pipe(
      ofType(UserHistoryGeneratedActions.deleteOneUserHistory),
      withLatestFrom(selectUserHistoryState$),
      concatMap(([{ idUserHistory }, state]) =>
        this.userHistoryApiService.deleteUserHistory(idUserHistory).pipe(
          mergeMap(_success =>
            getDefaultDeleteUserHistoryActions(state.entities[idUserHistory] as UserHistoryEntityState)
          ),
          catchError(error => of(UserHistoryGeneratedActions.userHistoriesFailure({ error })))
        )
      )
    );
  });

  normalizeManyUserHistoriesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserHistoryGeneratedActions.normalizeManyUserHistoriesAfterUpsert),
      concatMap(({ userHistories }) => {
        const actions: Action[] = getActionsToNormalizeUserHistory(userHistories, StoreActionType.upsert);
        return [getMultiAction(actions, '[UserHistory] Normalization After Upsert Success')];
      })
    );
  });
}
