import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityCaracteristicGeneratedActions } from '@wip/store/actions';
import { CommunityCaracteristicState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityCaracteristicEntityState } from '@api/api-interfaces';

export const communityCaracteristicReducersGeneratedFunctions: ReducerTypes<
  CommunityCaracteristicState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunityCaracteristicGeneratedActions.getOneCommunityCaracteristic, (state: CommunityCaracteristicState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    CommunityCaracteristicGeneratedActions.getManyCommunityCaracteristics,
    (state: CommunityCaracteristicState.IState) => setLoadingsState(state, true)
  ),
  on(
    CommunityCaracteristicGeneratedActions.upsertOneCommunityCaracteristic,
    (state: CommunityCaracteristicState.IState) => setLoadingsState(state, true)
  ),

  on(
    CommunityCaracteristicGeneratedActions.upsertManyCommunityCaracteristicsSuccess,
    (state: CommunityCaracteristicState.IState, { communityCaracteristics }) =>
      CommunityCaracteristicState.adapter.upsertMany(communityCaracteristics, setLoadingsState(state, false))
  ),
  on(
    CommunityCaracteristicGeneratedActions.deleteOneCommunityCaracteristic,
    (state: CommunityCaracteristicState.IState) => setLoadingsState(state, true)
  ),
  on(
    CommunityCaracteristicGeneratedActions.deleteOneCommunityCaracteristicSuccess,
    (state: CommunityCaracteristicState.IState, { idCommunityCaracteristic }) =>
      CommunityCaracteristicState.adapter.removeOne(idCommunityCaracteristic, setLoadingsState(state, false))
  ),
  on(
    CommunityCaracteristicGeneratedActions.clearActivesCommunityCaracteristics,
    (state: CommunityCaracteristicState.IState) => ({ ...state, actives: [] })
  ),
  on(
    CommunityCaracteristicGeneratedActions.addManyActivesCommunityCaracteristics,
    (state: CommunityCaracteristicState.IState, { idCommunityCaracteristics }) => ({
      ...state,
      actives: state.actives.concat(idCommunityCaracteristics)
    })
  ),
  on(
    CommunityCaracteristicGeneratedActions.deleteOneActiveCommunityCaracteristic,
    (state: CommunityCaracteristicState.IState, { idCommunityCaracteristic }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityCaracteristic)
    })
  ),

  on(
    CommunityCaracteristicGeneratedActions.clearCommunityCaracteristics,
    () => CommunityCaracteristicState.initialState
  ),

  on(
    CommunityCaracteristicGeneratedActions.addOrganizationCaracteristicSuccess,
    (state: CommunityCaracteristicState.IState, { idCommunityCaracteristic, idOrganizationCaracteristic }) => {
      if (!state.entities[idCommunityCaracteristic]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityCaracteristic]: {
            ...state.entities[idCommunityCaracteristic],
            organizationCaracteristic: idOrganizationCaracteristic
          }
        }
      };
    }
  ),

  on(
    CommunityCaracteristicGeneratedActions.deleteManyOrganizationCaracteristicSuccess,
    (state: CommunityCaracteristicState.IState, { idOrganizationCaracteristics, idCommunityCaracteristics }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityCaracteristics.reduce((entities, idCommunityCaracteristic) => {
            if (!state.entities[idCommunityCaracteristic]?.organizationCaracteristic) {
              return entities;
            }
            entities[idCommunityCaracteristic] = {
              ...state.entities[idCommunityCaracteristic],
              organizationCaracteristic: idOrganizationCaracteristics.some(
                (idOrganizationCaracteristic: number) =>
                  idOrganizationCaracteristic === state.entities[idCommunityCaracteristic]?.organizationCaracteristic
              )
                ? undefined
                : state.entities[idCommunityCaracteristic]?.organizationCaracteristic
            } as CommunityCaracteristicEntityState;
            return entities;
          }, {} as Dictionary<CommunityCaracteristicEntityState>)
        }
      };
    }
  ),

  on(
    CommunityCaracteristicGeneratedActions.addCommunitySuccess,
    (state: CommunityCaracteristicState.IState, { idCommunityCaracteristic, idCommunity }) => {
      if (!state.entities[idCommunityCaracteristic]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityCaracteristic]: {
            ...state.entities[idCommunityCaracteristic],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityCaracteristicGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityCaracteristicState.IState, { idCommunities, idCommunityCaracteristics }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityCaracteristics.reduce((entities, idCommunityCaracteristic) => {
            if (!state.entities[idCommunityCaracteristic]?.community) {
              return entities;
            }
            entities[idCommunityCaracteristic] = {
              ...state.entities[idCommunityCaracteristic],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityCaracteristic]?.community
              )
                ? undefined
                : state.entities[idCommunityCaracteristic]?.community
            } as CommunityCaracteristicEntityState;
            return entities;
          }, {} as Dictionary<CommunityCaracteristicEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityCaracteristicState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityCaracteristicState.IState {
  return { ...state, isLoaded, isLoading };
}
