import { RepositoryService } from '@wip/services/repository';
import { CommunityCaracteristic } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunityCaracteristicApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunityCaracteristics(params?: any): Observable<CommunityCaracteristic[]> {
    return this.repo.getData<CommunityCaracteristic[]>('community-caracteristic', params);
  }

  public getCommunityCaracteristic(params: {
    idCommunityCaracteristic: number;
    params?: any;
  }): Observable<CommunityCaracteristic> {
    return this.repo.getData<CommunityCaracteristic>(
      'community-caracteristic/' + params.idCommunityCaracteristic,
      params.params
    );
  }

  public addCommunityCaracteristic(
    communityCaracteristic: Partial<CommunityCaracteristic>
  ): Observable<CommunityCaracteristic> {
    return this.repo.create<CommunityCaracteristic>('community-caracteristic', communityCaracteristic);
  }

  public updateCommunityCaracteristic(
    communityCaracteristic: Partial<CommunityCaracteristic>
  ): Observable<CommunityCaracteristic> {
    return this.repo.update('community-caracteristic', communityCaracteristic);
  }

  public deleteCommunityCaracteristic(idCommunityCaracteristic: number): Observable<number> {
    return this.repo.delete('community-caracteristic/' + +idCommunityCaracteristic);
  }
}
