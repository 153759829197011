import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { FolderElementGeneratedActions } from '@wip/store/actions';
import { FolderElementState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { FolderElementEntityState } from '@api/api-interfaces';

export const folderElementReducersGeneratedFunctions: ReducerTypes<
  FolderElementState.IState,
  readonly ActionCreator[]
>[] = [
  on(FolderElementGeneratedActions.getOneFolderElement, (state: FolderElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(FolderElementGeneratedActions.getManyFolderElements, (state: FolderElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(FolderElementGeneratedActions.upsertOneFolderElement, (state: FolderElementState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    FolderElementGeneratedActions.upsertManyFolderElementsSuccess,
    (state: FolderElementState.IState, { folderElements }) =>
      FolderElementState.adapter.upsertMany(folderElements, setLoadingsState(state, false))
  ),
  on(FolderElementGeneratedActions.deleteOneFolderElement, (state: FolderElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    FolderElementGeneratedActions.deleteOneFolderElementSuccess,
    (state: FolderElementState.IState, { idFolderElement }) =>
      FolderElementState.adapter.removeOne(idFolderElement, setLoadingsState(state, false))
  ),
  on(FolderElementGeneratedActions.clearActivesFolderElements, (state: FolderElementState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    FolderElementGeneratedActions.addManyActivesFolderElements,
    (state: FolderElementState.IState, { idFolderElements }) => ({
      ...state,
      actives: state.actives.concat(idFolderElements)
    })
  ),
  on(
    FolderElementGeneratedActions.deleteOneActiveFolderElement,
    (state: FolderElementState.IState, { idFolderElement }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idFolderElement)
    })
  ),

  on(FolderElementGeneratedActions.clearFolderElements, () => FolderElementState.initialState),

  on(
    FolderElementGeneratedActions.addFolderSuccess,
    (state: FolderElementState.IState, { idFolderElement, idFolder }) => {
      if (!state.entities[idFolderElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFolderElement]: {
            ...state.entities[idFolderElement],
            folder: idFolder
          }
        }
      };
    }
  ),

  on(
    FolderElementGeneratedActions.deleteManyFolderSuccess,
    (state: FolderElementState.IState, { idFolders, idFolderElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFolderElements.reduce((entities, idFolderElement) => {
            if (!state.entities[idFolderElement]?.folder) {
              return entities;
            }
            entities[idFolderElement] = {
              ...state.entities[idFolderElement],
              folder: idFolders.some((idFolder: number) => idFolder === state.entities[idFolderElement]?.folder)
                ? undefined
                : state.entities[idFolderElement]?.folder
            } as FolderElementEntityState;
            return entities;
          }, {} as Dictionary<FolderElementEntityState>)
        }
      };
    }
  ),

  on(
    FolderElementGeneratedActions.addElementSuccess,
    (state: FolderElementState.IState, { idFolderElement, idElement }) => {
      if (!state.entities[idFolderElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFolderElement]: {
            ...state.entities[idFolderElement],
            element: idElement
          }
        }
      };
    }
  ),

  on(
    FolderElementGeneratedActions.deleteManyElementSuccess,
    (state: FolderElementState.IState, { idElements, idFolderElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFolderElements.reduce((entities, idFolderElement) => {
            if (!state.entities[idFolderElement]?.element) {
              return entities;
            }
            entities[idFolderElement] = {
              ...state.entities[idFolderElement],
              element: idElements.some((idElement: number) => idElement === state.entities[idFolderElement]?.element)
                ? undefined
                : state.entities[idFolderElement]?.element
            } as FolderElementEntityState;
            return entities;
          }, {} as Dictionary<FolderElementEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: FolderElementState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): FolderElementState.IState {
  return { ...state, isLoaded, isLoading };
}
