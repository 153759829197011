import { RepositoryService } from '@wip/services/repository';
import { OrganizationSection } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationSectionApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationSections(params?: any): Observable<OrganizationSection[]> {
    return this.repo.getData<OrganizationSection[]>('organization-section', params);
  }

  public getOrganizationSection(params: {
    idOrganizationSection: number;
    params?: any;
  }): Observable<OrganizationSection> {
    return this.repo.getData<OrganizationSection>(
      'organization-section/' + params.idOrganizationSection,
      params.params
    );
  }

  public addOrganizationSection(organizationSection: Partial<OrganizationSection>): Observable<OrganizationSection> {
    return this.repo.create<OrganizationSection>('organization-section', organizationSection);
  }

  public updateOrganizationSection(organizationSection: Partial<OrganizationSection>): Observable<OrganizationSection> {
    return this.repo.update('organization-section', organizationSection);
  }

  public deleteOrganizationSection(idOrganizationSection: number): Observable<number> {
    return this.repo.delete('organization-section/' + +idOrganizationSection);
  }
}
