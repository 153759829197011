import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ElementLibrary } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import * as elementLibraryActions from '@wip/store/actions';
import { ElementLibraryApiService } from '@wip/store/api-services';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedElementLibraryEffects } from './element-library-generated.effects';
@Injectable()
export class ElementLibraryEffects extends GeneratedElementLibraryEffects {
  constructor(actions$: Actions, elementLibraryApiService: ElementLibraryApiService, store$: Store<AppState>) {
    super(actions$, elementLibraryApiService, store$);
  }

  changeResponsibles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(elementLibraryActions.changeResponsibles),
      concatMap((data: { responsibles: any[]; idElement: number }) => {
        return this.elementLibraryApiService.changeResponsibles(data).pipe(
          map((elementLibrariesReturned: ElementLibrary[]) => {
            return elementLibraryActions.ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert({
              elementLibraries: elementLibrariesReturned
            });
          }),
          catchError(error =>
            of(elementLibraryActions.ElementLibraryGeneratedActions.elementLibrariesFailure({ error }))
          )
        );
      })
    );
  });
}
