import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User, UserEntityState } from '@api/api-interfaces';
import { UserRelationsIds } from '@wip/store/ids-interfaces';

export const UserGeneratedActions = createActionGroup({
  source: 'User Generated',
  events: {
    'Get One User': props<{ idUser: number; params?: any }>(),
    'Get Many Users': props<{ params: any }>(),
    'Add Many Actives Users': props<{ idUsers: number[] }>(),
    'Delete One Active User': props<{ idUser: number }>(),
    'Clear Actives Users': emptyProps(),
    'Upsert One User': props<{ user: Partial<User>; ids?: UserRelationsIds }>(),
    'Upsert Many Users': props<{ users: Partial<User>[]; ids?: UserRelationsIds }>(),
    'Upsert Many Users Success': props<{ users: UserEntityState[] }>(),
    'Delete One User': props<{ idUser: number }>(),
    'Delete One User Success': props<{ idUser: number }>(),
    'Normalize Many Users After Upsert': props<{ users: UserEntityState[] }>(),
    'Users Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Users': emptyProps(),
    'Add Many Community User Profil Success': props<{ idUser: number; idCommunityUserProfils: number[] }>(),
    'Delete Many Community User Profil Success': props<{ idCommunityUserProfils: number[]; idUsers: number[] }>(),
    'Add Many User Group Success': props<{ idUser: number; idUserGroups: number[] }>(),
    'Delete Many User Group Success': props<{ idUserGroups: number[]; idUsers: number[] }>(),
    'Add Many Community User Success': props<{ idUser: number; idCommunityUsers: number[] }>(),
    'Delete Many Community User Success': props<{ idCommunityUsers: number[]; idUsers: number[] }>(),
    'Add Many Organization User Success': props<{ idUser: number; idOrganizationUsers: number[] }>(),
    'Delete Many Organization User Success': props<{ idOrganizationUsers: number[]; idUsers: number[] }>(),
    'Add User Device Success': props<{ idUser: number; idUserDevice: number }>(),
    'Delete User Device Success': props<{ idUserDevices: number[]; idUsers: number[] }>(),
    'Add Many Meeting User Success': props<{ idUser: number; idMeetingUsers: number[] }>(),
    'Delete Many Meeting User Success': props<{ idMeetingUsers: number[]; idUsers: number[] }>(),
    'Add Many User History Success': props<{ idUser: number; idUserHistories: number[] }>(),
    'Delete Many User History Success': props<{ idUserHistories: number[]; idUsers: number[] }>(),
    'Add Many Element Library Success': props<{ idUser: number; idElementLibraries: number[] }>(),
    'Delete Many Element Library Success': props<{ idElementLibraries: number[]; idUsers: number[] }>(),
    'Add Many Organization Success': props<{ idUser: number; idOrganizations: number[] }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idUsers: number[] }>(),
    'Add Many Community Success': props<{ idUser: number; idCommunities: number[] }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idUsers: number[] }>(),
    'Add Many Group Success': props<{ idUser: number; idGroups: number[] }>(),
    'Delete Many Group Success': props<{ idGroups: number[]; idUsers: number[] }>()
  }
});
