import { Action, createReducer } from '@ngrx/store';
import { profilReducersGeneratedFunctions } from './profil-generated.reducer';
import { ProfilState } from '@wip/store/states';

const profilReducersFunctions = [...profilReducersGeneratedFunctions];

const profilReducer = createReducer(ProfilState.initialState, ...profilReducersFunctions);

export function reducer(state: ProfilState.IState | undefined, action: Action) {
  return profilReducer(state, action);
}
