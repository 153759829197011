@if (metaUser.type !== 'user_profile' || !user.roleLibelle) {
  <div class="flex flex-row justify-start items-center gap-4">
    <app-avatar size="micro" [user]="metaUser.user || metaUser.communityUserProfil?.user || metaUser" [height]="28">
    </app-avatar>
    <div style="max-width: 200px; overflow: hidden">
      <div>
        {{ user.prenom }}
        <strong>{{ user.nom }}</strong>
      </div>
    </div>
  </div>
}
@if (metaUser.type === 'user_profile' && user.roleLibelle && metaUser.user) {
  <div class="flex flex-row justify-start items-center gap-4">
    <app-avatar
      size="micro"
      [user]="metaUser.user || metaUser.communityUserProfil?.user || metaUser"
      [metaUser]="metaUser.user || metaUser.communityUserProfil?.user || metaUser"
      [height]="28"
    >
    </app-avatar>
    <div style="max-width: 200px; overflow: hidden; height: 40px">
      <strong style="font-size: 10px">{{ user.roleLibelle }}</strong>
      <div style="margin-top: -5px">
        {{ user.prenom }}
        <strong>{{ user.nom }}</strong>
      </div>
    </div>
  </div>
}
@if (metaUser.type === 'user_profile' && !metaUser.user) {
  <div class="flex flex-row justify-start items-center gap-4">
    <app-avatar size="micro" [metaUser]="metaUser" [height]="28"> </app-avatar>
    <strong style="max-width: 200px; overflow: hidden">{{ user.roleLibelle }}</strong>
  </div>
}
