import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MeetingElement, MeetingElementEntityState } from '@api/api-interfaces';
import { Meeting, MeetingEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { MeetingElementState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const meetingElementRelations: string[] = ['meetings', 'elements'];

export const { selectEntities, selectAll } = MeetingElementState.adapter.getSelectors();

export const selectMeetingElementState = createFeatureSelector<MeetingElementState.IState>(
  MeetingElementState.meetingElementFeatureKey
);

export const selectIsLoadedMeetingElement = createSelector(
  selectMeetingElementState,
  (state: MeetingElementState.IState) => state.isLoaded
);

export const selectIsLoadingMeetingElement = createSelector(
  selectMeetingElementState,
  (state: MeetingElementState.IState) => state.isLoading
);

export const selectIsReadyMeetingElement = createSelector(
  selectMeetingElementState,
  (state: MeetingElementState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedMeetingElement = createSelector(
  selectMeetingElementState,
  (state: MeetingElementState.IState) => state.isLoaded && !state.isLoading
);

export const selectMeetingElementsEntities = createSelector(selectMeetingElementState, selectEntities);

export const selectMeetingElementsArray = createSelector(selectMeetingElementState, selectAll);

export const selectIdMeetingElementsActive = createSelector(
  selectMeetingElementState,
  (state: MeetingElementState.IState) => state.actives
);

const meetingElementsInObject = (meetingElements: Dictionary<MeetingElementEntityState>) => ({ meetingElements });

const selectMeetingElementsEntitiesDictionary = createSelector(selectMeetingElementsEntities, meetingElementsInObject);

const selectAllMeetingElementsObject = createSelector(selectMeetingElementsEntities, meetingElements => {
  return hydrateAll({ meetingElements });
});

const selectOneMeetingElementDictionary = (idMeetingElement: number) =>
  createSelector(selectMeetingElementsEntities, meetingElements => ({
    meetingElements: { [idMeetingElement]: meetingElements[idMeetingElement] }
  }));

const selectOneMeetingElementDictionaryWithoutChild = (idMeetingElement: number) =>
  createSelector(selectMeetingElementsEntities, meetingElements => ({
    meetingElement: meetingElements[idMeetingElement]
  }));

const selectActiveMeetingElementsEntities = createSelector(
  selectIdMeetingElementsActive,
  selectMeetingElementsEntities,
  (actives: number[], meetingElements: Dictionary<MeetingElementEntityState>) =>
    getMeetingElementsFromActives(actives, meetingElements)
);

function getMeetingElementsFromActives(
  actives: number[],
  meetingElements: Dictionary<MeetingElementEntityState>
): Dictionary<MeetingElementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (meetingElements[idActive]) {
      acc[idActive] = meetingElements[idActive];
    }
    return acc;
  }, {} as Dictionary<MeetingElementEntityState>);
}

const selectAllMeetingElementsSelectors: Dictionary<Selector> = {};
export function selectAllMeetingElements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<MeetingElement>(
      schema,
      selectAllMeetingElementsSelectors,
      selectMeetingElementsEntitiesDictionary,
      getRelationSelectors,
      meetingElementRelations,
      hydrateAll,
      'meetingElement'
    );
  } else {
    return selectAllMeetingElementsObject;
  }
}

export function selectAllMeetingElementsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'meetingElements'
): Selector {
  return createSelector(selectAllMeetingElements(schema), result => {
    const res = { [customKey]: {} as Dictionary<MeetingElementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.meetingElements.length; i++) {
      res[customKey][result.meetingElements[i].idMeetingElement] = result.meetingElements[i];
    }
    return res;
  });
}

export function selectOneMeetingElement(schema: SelectSchema = {}, idMeetingElement: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneMeetingElementDictionary(idMeetingElement)];
    selectors.push(...getRelationSelectors(schema, meetingElementRelations, 'meetingElement'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneMeetingElementDictionaryWithoutChild(idMeetingElement);
  }
}

export function selectActiveMeetingElements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveMeetingElementsEntities, meetingElements => ({
      meetingElements
    }))
  ];
  selectors.push(...getRelationSelectors(schema, meetingElementRelations, 'meetingElement'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  meetingElements: Dictionary<MeetingElementEntityState>;
  meetings?: Dictionary<MeetingEntityState>;
  elements?: Dictionary<ElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { meetingElements: (MeetingElement | null)[] } {
  const { meetingElements, meetings, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    meetingElements: Object.keys(meetingElements).map(idMeetingElement =>
      hydrate(meetingElements[idMeetingElement] as MeetingElementEntityState, meetings, elements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { meetingElement: MeetingElementEntityState | null } {
  const { meetingElements, meetings, elements } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const meetingElement = Object.values(meetingElements)[0];
  return {
    meetingElement: hydrate(meetingElement as MeetingElementEntityState, meetings, elements)
  };
}

function hydrate(
  meetingElement: MeetingElementEntityState,
  meetingEntities?: Dictionary<MeetingEntityState>,
  elementEntities?: Dictionary<ElementEntityState>
): MeetingElement | null {
  if (!meetingElement) {
    return null;
  }

  const meetingElementHydrated: MeetingElementEntityState = { ...meetingElement };
  if (meetingEntities) {
    meetingElementHydrated.meeting = meetingEntities[meetingElement.meeting as number] as Meeting;
  } else {
    delete meetingElementHydrated.meeting;
  }
  if (elementEntities) {
    meetingElementHydrated.element = elementEntities[meetingElement.element as number] as Element;
  } else {
    delete meetingElementHydrated.element;
  }

  return meetingElementHydrated as MeetingElement;
}
