import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UserProfilRight } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { UserProfilRightRelationsIds } from '@wip/store/ids-interfaces';
import { UserProfilRightGeneratedActions } from '@wip/store/actions';
import { UserProfilRightSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedUserProfilRightService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(UserProfilRightSelectors.selectIsLoadedUserProfilRight));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(UserProfilRightSelectors.selectIsLoadingUserProfilRight));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      UserProfilRightSelectors.selectIsReadyAndLoadedUserProfilRight as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllUserProfilRights(schema: SelectSchema = {}): Observable<UserProfilRight[]> {
    return this.store$.pipe(select(UserProfilRightSelectors.selectAllUserProfilRights(schema))).pipe(
      switchMap(({ userProfilRights }: { userProfilRights: UserProfilRight[] }) => {
        return this.getReady(schema).pipe(mapTo(userProfilRights));
      })
    );
  }

  public selectOneUserProfilRight(idUserProfilRight: number, schema: SelectSchema = {}): Observable<UserProfilRight> {
    return this.store$.pipe(select(UserProfilRightSelectors.selectOneUserProfilRight(schema, idUserProfilRight))).pipe(
      switchMap(({ userProfilRight }: { userProfilRight: UserProfilRight }) => {
        return this.getReady(schema).pipe(mapTo(userProfilRight));
      })
    );
  }

  public selectAllActiveUserProfilRights(schema: SelectSchema = {}): Observable<UserProfilRight[]> {
    return this.store$.pipe(select(UserProfilRightSelectors.selectActiveUserProfilRights(schema))).pipe(
      switchMap(({ userProfilRights }: { userProfilRights: UserProfilRight[] }) => {
        return this.getReady(schema).pipe(mapTo(userProfilRights));
      })
    );
  }

  public selectIdUserProfilRightsActive(): Observable<number[]> {
    return this.store$.pipe(select(UserProfilRightSelectors.selectIdUserProfilRightsActive)).pipe(
      switchMap((idUserProfilRights: number[]) => {
        return this.getReady().pipe(mapTo(idUserProfilRights));
      })
    );
  }

  public getOneUserProfilRight(
    idUserProfilRight: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<UserProfilRight> {
    this.store$.dispatch(UserProfilRightGeneratedActions.getOneUserProfilRight({ idUserProfilRight, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserProfilRightGeneratedActions.normalizeManyUserProfilRightsAfterUpsert,
        UserProfilRightGeneratedActions.userProfilRightsFailure,
        true
      );
    }
  }

  public getManyUserProfilRights(params: any = {}, getResult?: boolean): void | Observable<UserProfilRight[]> {
    this.store$.dispatch(UserProfilRightGeneratedActions.getManyUserProfilRights({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserProfilRightGeneratedActions.normalizeManyUserProfilRightsAfterUpsert,
        UserProfilRightGeneratedActions.userProfilRightsFailure
      );
    }
  }

  public upsertOneUserProfilRight(
    userProfilRight: Partial<UserProfilRight>,
    ids: UserProfilRightRelationsIds = {},
    getResult?: boolean
  ): void | Observable<UserProfilRight> {
    this.store$.dispatch(UserProfilRightGeneratedActions.upsertOneUserProfilRight({ userProfilRight, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserProfilRightGeneratedActions.normalizeManyUserProfilRightsAfterUpsert,
        UserProfilRightGeneratedActions.userProfilRightsFailure,
        true
      );
    }
  }

  public deleteOneUserProfilRight(idUserProfilRight: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(UserProfilRightGeneratedActions.deleteOneUserProfilRight({ idUserProfilRight }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserProfilRightGeneratedActions.deleteOneUserProfilRightSuccess,
        UserProfilRightGeneratedActions.userProfilRightsFailure
      );
    }
  }

  public setActiveUserProfilRights(idUserProfilRights: number[]): void {
    this.store$.dispatch(UserProfilRightGeneratedActions.clearActivesUserProfilRights());
    this.store$.dispatch(UserProfilRightGeneratedActions.addManyActivesUserProfilRights({ idUserProfilRights }));
  }
}
