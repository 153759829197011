import { Injectable } from '@angular/core';
import { ConstraintTypeEnum, DroitInterneEnum, TodoTypeEnum } from '@enums';
import { GanttStatic } from '@wip/gantt-static';
import { Element, Link, Task } from '@api/api-interfaces';
import { ProjectElementApiService } from '@wip/store/api-services';
import { CommunityService, ElementService, ProjectElementService } from '@wip/store/services';
import dayjs from 'dayjs';
import { lastValueFrom } from 'rxjs';

export enum MoveTaskStrategy {
  moveTaskWithChildren = 'moveTaskWithChildren',
  moveTaskWithoutChildren = 'moveTaskWithoutChildren',
  fixTaskWithMoveChildren = 'fixTaskWithMoveChildren',
  fixTaskWithoutMoveChildren = 'fixTaskWithoutMoveChildren'
}

@Injectable()
export class TaskService {
  public firstScheduleDone: boolean = false;
  public taskToSelectAfterCreation: number = 0;

  constructor(
    private communityService: CommunityService,
    private projectElementService: ProjectElementService,
    private elementService: ElementService,
    private projectElementApiService: ProjectElementApiService
  ) {}

  get(idCommunity: number): Promise<Task[]> {
    return lastValueFrom(this.projectElementApiService.getProjectTasks({ idCommunity }));
  }

  reorder(tasks: any) {
    this.projectElementService.reorder(tasks);
  }

  save(state: Partial<Element>) {
    this.elementService.upsertOneElement({ ...state, idCommunity: this.communityService.currentIdCommunity });
  }

  saveProjectElement(task: Partial<Task>) {
    if (task.type !== 'project') {
      this.elementService.upsertOneElement({
        idElement: task.idElement,
        rightNeeded: DroitInterneEnum.elementUpdate,

        titre: task.libelle
      });
    } else {
      this.projectElementService.upsertOneProjectElement({
        idProjectElement: task.idProjectElement,
        libelle: task.libelle
      });
    }
  }

  createTask(task: any, idCommunity: number, type: TodoTypeEnum) {
    this.projectElementService.insertProjectElement({
      ordre: task.index,
      titre: task.text,
      type,
      idCommunity,
      idParent: task.parent === 0 ? null : task.parent,
      dateStart: task.start_date,
      idOrigin: task.idOrigin,
      level: task.$level.toString()
    });
    this.taskToSelectAfterCreation = task.index + 1;
  }

  saveAll(data: { idCommunity: number; tasks: Task[]; links: Link[] }, idsLinkToNull: number[]) {
    if (!data.tasks.length && !data.links.length) {
      return;
    }

    const tasks = data.tasks.map((t, index) => {
      const echeanceFormatted = dayjs(t.end_date).hour(3).toDate();
      const dateStartFormatted = dayjs(t.start_date).hour(3).toDate();

      const ret: Partial<Element> = {
        idElement: t.idElement,
        statusAction: t.statusAction,
        echeance: t.ganttLite ? dateStartFormatted : echeanceFormatted,
        duration: t.duration,
        dateStart: dateStartFormatted
      };
      if (index === 0) {
        ret.constraintType = t.constraint_type;
      }
      return ret;
    });
    const links = data.links.map(link => {
      if (idsLinkToNull.includes(link.id)) {
        return {
          newSource: link.source,
          lag: link.lag,
          newTarget: link.target,
          type: link.type,
          idCommunity: data.idCommunity
        };
      } else {
        return {
          idGanttLink: link.id,
          newSource: link.source,
          lag: link.lag,
          newTarget: link.target,
          type: link.type,
          idCommunity: data.idCommunity
        };
      }
    });
    this.projectElementService.reschedule({ idCommunity: data.idCommunity, tasks, links });
    this.firstScheduleDone = true;
  }

  changeType(idProjectElement: number, isBold: boolean) {
    this.projectElementService.upsertOneProjectElement({
      idProjectElement,
      constraintType: isBold ? ConstraintTypeEnum.MSO : ConstraintTypeEnum.ASAP
    });
  }

  public updateTaskCustom(
    task: Task,
    moveTaskStrategy: MoveTaskStrategy,
    newDate: Date,
    oldDate: Date,
    gantt: GanttStatic
  ) {
    task.start_date = newDate;
    if (
      moveTaskStrategy === MoveTaskStrategy.moveTaskWithChildren ||
      moveTaskStrategy === MoveTaskStrategy.moveTaskWithoutChildren
    ) {
      task.constraint_type = ConstraintTypeEnum.ASAP;
    } else {
      task.constraint_type = ConstraintTypeEnum.MSO;
      task.constraint_date = newDate;
    }

    const diff = gantt.calculateDuration({ start_date: oldDate, end_date: newDate });
    task.end_date = gantt.calculateEndDate({ start_date: newDate, duration: task.duration });

    const linksSource = gantt.getLinks().filter(link => link.target === task.id);
    linksSource.forEach(link => {
      link.lag += diff;
      gantt.updateLink(link.id);
    });

    if (
      moveTaskStrategy === MoveTaskStrategy.fixTaskWithoutMoveChildren ||
      moveTaskStrategy === MoveTaskStrategy.moveTaskWithoutChildren
    ) {
      const linksTarget = gantt.getLinks().filter(link => link.source === task.id);
      linksTarget.forEach(link => {
        link.lag -= diff;
        gantt.updateLink(link.id);
      });
    }

    gantt.updateTask(task.id);
    gantt.autoSchedule(task.id);
  }
}
