import { createEntityAdapter } from '@ngrx/entity';
import { CommunityGroupEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<CommunityGroupEntityState>;

export const adapter = createEntityAdapter<CommunityGroupEntityState>({
  selectId: o => o.idCommunityGroup
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const communityGroupFeatureKey = 'communityGroup';
