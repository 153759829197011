<div [formGroup]="chartInputsForm" class="flex flex-row gap-16 items-center content-container">
  <div class="w150 flex flex-row gap-8 outlined-field">
    <input
      required
      placeholder="{{ startDatePlaceholder }}"
      matInput
      type="date"
      formControlName="dateStart"
      class="dateInput"
    />
  </div>
  <div class="w150 flex flex-row gap-8 outlined-field">
    <input
      required
      placeholder="{{ endDatePlaceholder }}"
      matInput
      type="date"
      formControlName="dateEnd"
      class="dateInput"
    />
  </div>
  <div class="flex flex-row items-center">
    <div
      appearance="fill"
      class="outlined-field flex items-center"
      style="position: relative; width: 160px; height: 32px; cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.1)"
    >
      <mat-select
        placeholder="{{ characteristicsPlaceholder }}"
        formControlName="projectCharacteristics"
        required
        (openedChange)="openedChangeCharacteristics($event)"
      >
        @for (projectCharacteristic of projectCharacteristics; track projectCharacteristic) {
          <mat-option [value]="projectCharacteristic">
            {{ projectCharacteristic.libelle }}
          </mat-option>
        }
      </mat-select>
    </div>
  </div>
  <div class="flex flex-row items-center">
    <div
      appearance="fill"
      class="outlined-field flex items-center"
      style="position: relative; width: 160px; height: 32px; cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.1)"
    >
      <mat-select
        placeholder="{{ milestonePlaceholder }}"
        formControlName="projectMilestone"
        required
        multiple
        (openedChange)="openedChangeMilestone($event)"
      >
        @for (projectMilestone of projectMilestones; track projectMilestone) {
          <mat-option [value]="projectMilestone">
            {{ projectMilestone.libelle }}
          </mat-option>
        }
      </mat-select>
    </div>
  </div>
</div>
