import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsEmbedComponent } from './google-maps-embed.component';

@NgModule({
  declarations: [GoogleMapsEmbedComponent],
  exports: [GoogleMapsEmbedComponent],
  imports: [CommonModule]
})
export class GoogleMapsEmbedModule {}
