import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommunityTypeStructureEnum } from '@enums';
import { Community, CommunityUserProfil } from '@api/api-interfaces';

@Component({
  selector: 'wip-project-team',
  templateUrl: './project-team.component.html',
  styleUrls: ['./project-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectTeamComponent implements OnChanges {
  @Input() community: Community;
  @Input() canEdit: boolean;
  @Input() wordingTypeStructure: CommunityTypeStructureEnum;

  public communityUserProfil: CommunityUserProfil[];
  public isCommunityResponsableValid: boolean;
  public icon: string[] = [];
  // public responsableLabel: boolean;
  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.community) {
      this.icon.push('icon icon-ic_fluent_arrow_circle_right_24_regular go-team');
      this.communityUserProfil = this.community?.communityUserProfils
        ?.filter(cup => cup.organizationUserProfil?.displayProject)
        .sort((a, b) => a.organizationUserProfil?.ordre - b.organizationUserProfil?.ordre);
      if (this.communityUserProfil) {
        const communityResponsable = this.communityUserProfil?.find(b => b.libelle === 'RP - Responsable de projet');

        this.isCommunityResponsableValid = !!communityResponsable?.idUser;
      }
    }
  }
  public addTeam() {
    this.router.navigate([`app/project/${this.community.idCommunity}/team`]);
  }
}
