import { TypeEnum } from '@enums';
import { createAction, props } from '@ngrx/store';
import { CommunityCaracteristic } from '@api/api-interfaces';
export * from './community-caracteristic-generated.actions';

export const changeValueType = createAction(
  '[OrganizationCaracteristic] Change Value Type',
  props<{
    idOrganizationCaracteristic: number;
    valueType: TypeEnum;
  }>()
);

export const FillHandleCaracteristics = createAction(
  '[CommunityCaracteristic] Fill Handle Caracteristics',
  props<{
    communityCaracteristics: Partial<CommunityCaracteristic>[];
  }>()
);
