@if (users) {
  <!-- <mat-form-field class="flex flex-row items-end items-center" style="width: 150px">
    <mat-select placeholder="Intervenant" [formControl]="filtersGroup.get('responsible')" [panelWidth]="'300px'">
      <div class="flex flex-row items-center">
        <span class="gulp gulp-34-search" style="margin-left: 16px"></span>
        <input
          matInput
          [formControl]="selectControl"
          placeholder="Rechercher"
          [(ngModel)]="filterValue"
          style="height: 48px; margin-left: 16px; width: 150px"
        />
      </div>
      <mat-option [value]="null">Tous</mat-option>
      @for (user of filteredUsers; track user.idUser) {
        <mat-option [value]="user.idUser">
          {{ user.prenom + ' ' + user.nom }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field> -->

  <div class="flex flex-row items-center">
    <div
      appearance="fill"
      class="outlined-field flex items-center"
      style="position: relative; width: 200px; height: 32px; cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.1)"
    >
      <i class="icon-ic_fluent_person_24_regular flex" style="font-size: 16px; margin-left: 8px" (click)="(null)"></i>
      <mat-select [formControl]="filtersGroup.get('responsible')" style="padding: 0 4px; font-size: 12px" #matSelect>
        <mat-option value="">Tous les responsables</mat-option>
        @for (user of filteredUsers; track user.idUser) {
          <mat-option [value]="user.idUser"> {{ user.prenom }} {{ user.nom }} </mat-option>
        }
      </mat-select>
      <!-- <i class="icon-ic_fluent_chevron_down_24_regular flex custom-arrow" (click)="(null)"></i> -->
    </div>

    @if (displayOnlyPilotToggle) {
      <mat-checkbox
        color="primary"
        [formControl]="filtersGroup.get('isResponsibleOnlyPilot')"
        style="margin-left: 5px; margin-bottom: -5px"
      >
        <div class="flex flex-row justify-start items-center">
          <div>Pilote uniquement</div>
        </div>
      </mat-checkbox>
    }
  </div>
}
