import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedCommunityMilestoneApiService } from './community-milestone-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityMilestoneApiService extends GeneratedCommunityMilestoneApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
