import { UserState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userReducersGeneratedFunctions } from './user-generated.reducer';

const userReducersFunctions = [...userReducersGeneratedFunctions];

const userReducer = createReducer(UserState.initialState, ...userReducersFunctions);

export function reducer(state: UserState.IState | undefined, action: Action) {
  return userReducer(state, action);
}
