import { RepositoryService } from '@wip/services/repository';
import { Element } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedElementApiService {
  constructor(protected repo: RepositoryService) {}

  public getElements(params?: any): Observable<Element[]> {
    return this.repo.getData<Element[]>('element', params);
  }

  public getElement(params: { idElement: number; params?: any }): Observable<Element> {
    return this.repo.getData<Element>('element/' + params.idElement, params.params);
  }

  public addElement(element: Partial<Element>): Observable<Element> {
    return this.repo.create<Element>('element', element);
  }

  public updateElement(element: Partial<Element>): Observable<Element> {
    return this.repo.update('element', element);
  }

  public deleteElement(idElement: number): Observable<number> {
    return this.repo.delete('element/' + +idElement);
  }
}
