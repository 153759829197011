import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationMilestoneAssociation, OrganizationMilestoneAssociationEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneAssociationRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationMilestoneAssociationGeneratedActions = createActionGroup({
  source: 'Organization Milestone Association Generated',
  events: {
    'Get One Organization Milestone Association': props<{ idOrganizationMilestoneAssociation: number; params?: any }>(),
    'Get Many Organization Milestone Associations': props<{ params: any }>(),
    'Add Many Actives Organization Milestone Associations': props<{ idOrganizationMilestoneAssociations: number[] }>(),
    'Delete One Active Organization Milestone Association': props<{ idOrganizationMilestoneAssociation: number }>(),
    'Clear Actives Organization Milestone Associations': emptyProps(),
    'Upsert One Organization Milestone Association': props<{
      organizationMilestoneAssociation: Partial<OrganizationMilestoneAssociation>;
      ids?: OrganizationMilestoneAssociationRelationsIds;
    }>(),
    'Upsert Many Organization Milestone Associations': props<{
      organizationMilestoneAssociations: Partial<OrganizationMilestoneAssociation>[];
      ids?: OrganizationMilestoneAssociationRelationsIds;
    }>(),
    'Upsert Many Organization Milestone Associations Success': props<{
      organizationMilestoneAssociations: OrganizationMilestoneAssociationEntityState[];
    }>(),
    'Delete One Organization Milestone Association': props<{ idOrganizationMilestoneAssociation: number }>(),
    'Delete One Organization Milestone Association Success': props<{ idOrganizationMilestoneAssociation: number }>(),
    'Normalize Many Organization Milestone Associations After Upsert': props<{
      organizationMilestoneAssociations: OrganizationMilestoneAssociationEntityState[];
    }>(),
    'Organization Milestone Associations Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Milestone Associations': emptyProps(),
    'Add Organization Milestone Success': props<{
      idOrganizationMilestoneAssociation: number;
      idOrganizationMilestone: number;
    }>(),
    'Delete Many Organization Milestone Success': props<{
      idOrganizationMilestones: number[];
      idOrganizationMilestoneAssociations: number[];
    }>(),
    'Add Organization Milestone Family Success': props<{
      idOrganizationMilestoneAssociation: number;
      idOrganizationMilestoneFamily: number;
    }>(),
    'Delete Many Organization Milestone Family Success': props<{
      idOrganizationMilestoneFamilys: number[];
      idOrganizationMilestoneAssociations: number[];
    }>()
  }
});
