import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import * as AppState from '@wip/store/configs/reducers';
import * as OrganizationMilestoneFamilyAction from '@wip/store/actions';
import { OrganizationMilestoneFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneratedOrganizationMilestoneFamilyService } from './organization-milestone-family-generated.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class OrganizationMilestoneFamilyService extends GeneratedOrganizationMilestoneFamilyService {
  public chartInputsForm: FormGroup;

  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private fb: FormBuilder
  ) {
    super(store$, actions$);
    this.chartInputsForm = this.fb.group({
      dateStart: null,
      dateEnd: null,
      projectCharacteristics: null,
      projectMilestone: [],
      orgaMilestones: []
    });
  }

  public isProjectCharacteristicsSelectOpened = new BehaviorSubject<boolean>(false);
  public isProjectMileStoneSelectOpened = new BehaviorSubject<boolean>(false);

  public upsertOneOrganizationMilestoneFamilySelected(
    organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>,
    ids: OrganizationMilestoneFamilyRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestoneFamily> {
    this.store$.dispatch(
      OrganizationMilestoneFamilyAction.upsertOneOrganizationMilestoneFamilySelected({
        organizationMilestoneFamily,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneFamilyAction.OrganizationMilestoneFamilyGeneratedActions
          .normalizeManyOrganizationMilestoneFamilysAfterUpsert,
        OrganizationMilestoneFamilyAction.OrganizationMilestoneFamilyGeneratedActions
          .organizationMilestoneFamilysFailure,
        true
      );
    }
  }

  public upsertOneOrganizationFamilyWithMilestones(
    organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>,
    ids: OrganizationMilestoneFamilyRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestoneFamily> {
    this.store$.dispatch(
      OrganizationMilestoneFamilyAction.upsertOneOrganizationFamilyWithMilestones({
        organizationMilestoneFamily: organizationMilestoneFamily,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneFamilyAction.OrganizationMilestoneFamilyGeneratedActions
          .normalizeManyOrganizationMilestoneFamilysAfterUpsert,
        OrganizationMilestoneFamilyAction.OrganizationMilestoneFamilyGeneratedActions
          .organizationMilestoneFamilysFailure,
        true
      );
    }
  }
}
