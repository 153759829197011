import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedCommunitySubFamilyApiService } from './community-sub-family-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunitySubFamilyApiService extends GeneratedCommunitySubFamilyApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
