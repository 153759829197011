import { Injectable } from '@angular/core';
import { TodoElement, TodoElementForm } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedTodoElementApiService } from './todo-element-api-generated.service';
import { ActionFormDTO } from '@dto';
import { HttpClient } from '@angular/common/http';
import { ConfigurationApiService } from 'libs/api/old-api-services/src';

@Injectable({
  providedIn: 'root'
})
export class TodoElementApiService extends GeneratedTodoElementApiService {
  constructor(
    repo: RepositoryService,
    private http: HttpClient,
    private configurationApi: ConfigurationApiService
  ) {
    super(repo);
  }

  public getTodoElementsByIdCommunity(params: { idCommunity: number }): Observable<TodoElement[]> {
    return this.repo.getData<TodoElement[]>('todo-element/get-elements/' + params.idCommunity);
  }

  public getTodoElementsForSynthesisDashboard(idCommunity: number): Observable<TodoElement[]> {
    return this.repo.getData<TodoElement[]>('todo-element/elements-synthesis/' + idCommunity, {});
  }

  public createTodoElement(todoElementForm: TodoElementForm): Observable<TodoElement[]> {
    return this.repo.create<TodoElement[]>('todo-element/create', todoElementForm);
  }

  public validateActionForm(controls: ActionFormDTO): Observable<TodoElement> {
    return this.repo.update('todo-element/validateActionForm', controls);
  }

  public informUsers(
    listeidUserSelected: number[],
    idTodoElement: number,
    idElement: number,
    url: string,
    sender,
    content: string
  ) {
    return this.http.post(this.configurationApi.fullAddressV2 + '/community/informUsers', {
      listeidUserSelected,
      idTodoElement,
      idElement,
      url,
      sender,
      content
    });
  }
}
