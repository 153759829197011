import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CommunityModule } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { CommunityModuleRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityModuleGeneratedActions } from '@wip/store/actions';
import { CommunityModuleSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCommunityModuleService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CommunityModuleSelectors.selectIsLoadedCommunityModule));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CommunityModuleSelectors.selectIsLoadingCommunityModule));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      CommunityModuleSelectors.selectIsReadyAndLoadedCommunityModule as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCommunityModules(schema: SelectSchema = {}): Observable<CommunityModule[]> {
    return this.store$.pipe(select(CommunityModuleSelectors.selectAllCommunityModules(schema))).pipe(
      switchMap(({ communityModules }: { communityModules: CommunityModule[] }) => {
        return this.getReady(schema).pipe(mapTo(communityModules));
      })
    );
  }

  public selectOneCommunityModule(idCommunityModule: number, schema: SelectSchema = {}): Observable<CommunityModule> {
    return this.store$.pipe(select(CommunityModuleSelectors.selectOneCommunityModule(schema, idCommunityModule))).pipe(
      switchMap(({ communityModule }: { communityModule: CommunityModule }) => {
        return this.getReady(schema).pipe(mapTo(communityModule));
      })
    );
  }

  public selectAllActiveCommunityModules(schema: SelectSchema = {}): Observable<CommunityModule[]> {
    return this.store$.pipe(select(CommunityModuleSelectors.selectActiveCommunityModules(schema))).pipe(
      switchMap(({ communityModules }: { communityModules: CommunityModule[] }) => {
        return this.getReady(schema).pipe(mapTo(communityModules));
      })
    );
  }

  public selectIdCommunityModulesActive(): Observable<number[]> {
    return this.store$.pipe(select(CommunityModuleSelectors.selectIdCommunityModulesActive)).pipe(
      switchMap((idCommunityModules: number[]) => {
        return this.getReady().pipe(mapTo(idCommunityModules));
      })
    );
  }

  public getOneCommunityModule(
    idCommunityModule: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<CommunityModule> {
    this.store$.dispatch(CommunityModuleGeneratedActions.getOneCommunityModule({ idCommunityModule, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityModuleGeneratedActions.normalizeManyCommunityModulesAfterUpsert,
        CommunityModuleGeneratedActions.communityModulesFailure,
        true
      );
    }
  }

  public getManyCommunityModules(params: any = {}, getResult?: boolean): void | Observable<CommunityModule[]> {
    this.store$.dispatch(CommunityModuleGeneratedActions.getManyCommunityModules({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityModuleGeneratedActions.normalizeManyCommunityModulesAfterUpsert,
        CommunityModuleGeneratedActions.communityModulesFailure
      );
    }
  }

  public upsertOneCommunityModule(
    communityModule: Partial<CommunityModule>,
    ids: CommunityModuleRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CommunityModule> {
    this.store$.dispatch(CommunityModuleGeneratedActions.upsertOneCommunityModule({ communityModule, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityModuleGeneratedActions.normalizeManyCommunityModulesAfterUpsert,
        CommunityModuleGeneratedActions.communityModulesFailure,
        true
      );
    }
  }

  public deleteOneCommunityModule(idCommunityModule: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CommunityModuleGeneratedActions.deleteOneCommunityModule({ idCommunityModule }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityModuleGeneratedActions.deleteOneCommunityModuleSuccess,
        CommunityModuleGeneratedActions.communityModulesFailure
      );
    }
  }

  public setActiveCommunityModules(idCommunityModules: number[]): void {
    this.store$.dispatch(CommunityModuleGeneratedActions.clearActivesCommunityModules());
    this.store$.dispatch(CommunityModuleGeneratedActions.addManyActivesCommunityModules({ idCommunityModules }));
  }
}
