import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationUserProfilApiService } from './organization-user-profil-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationUserProfilApiService extends GeneratedOrganizationUserProfilApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
