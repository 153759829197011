import { Injectable } from '@angular/core';
import { Element, ReorderBasicInterface } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedElementApiService } from './element-api-generated.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { ActionFormDTO } from '@dto';
import { ConfigurationApiService } from 'libs/api/old-api-services/src';

@Injectable({
  providedIn: 'root'
})
export class ElementApiService extends GeneratedElementApiService {
  constructor(
    repo: RepositoryService,
    private http: HttpClient,
    private configurationApi: ConfigurationApiService
  ) {
    super(repo);
  }

  public getGridDashboardDatas(idOrganization: number, idOrganizationMilestoneFamily: number): Observable<Element[]> {
    return this.repo.getData<Element[]>('element/getGridDashboardDatas', {
      idOrganization,
      idOrganizationMilestoneFamily
    });
  }

  public getPersonalElements(): Observable<Element[]> {
    return this.repo.getData<Element[]>('element/getPersonalElements', {});
  }

  public downloadFile(idElement: number, fileName: string, isChildrenElement: boolean) {
    return this.http
      .get(this.configurationApi.fullAddressV2 + '/element/downloadFile/' + idElement, {
        responseType: 'blob',
        params: { isChildrenElement }
      })
      .subscribe((blob: any) => {
        saveAs(blob, fileName);
      });
  }

  public validateActionForm(controls: ActionFormDTO): Observable<Element> {
    return this.repo.update('element/validateActionForm', controls);
  }

  public updateInitial(element: Partial<Element>): Observable<Element> {
    return this.repo.update('element/updateInitial', element);
  }

  public updateObjectif(element: Partial<Element>): Observable<Element> {
    return this.repo.update('element/updateObjectif', element);
  }

  preview(values: any) {
    const params: HttpParams = this.configurationApi.setParams(values);
    return this.http.get(this.configurationApi.fullAddressV2 + '/element/preview', {
      params,
      responseType: 'blob'
    });
  }

  public reorder(params: ReorderBasicInterface): Observable<Element[]> {
    return this.repo.update('element/reorder', params);
  }
}
