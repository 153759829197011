import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TodoElement } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { TodoElementRelationsIds } from '@wip/store/ids-interfaces';
import { TodoElementGeneratedActions } from '@wip/store/actions';
import { TodoElementSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedTodoElementService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(TodoElementSelectors.selectIsLoadedTodoElement));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(TodoElementSelectors.selectIsLoadingTodoElement));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [TodoElementSelectors.selectIsReadyAndLoadedTodoElement as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllTodoElements(schema: SelectSchema = {}): Observable<TodoElement[]> {
    return this.store$.pipe(select(TodoElementSelectors.selectAllTodoElements(schema))).pipe(
      switchMap(({ todoElements }: { todoElements: TodoElement[] }) => {
        return this.getReady(schema).pipe(mapTo(todoElements));
      })
    );
  }

  public selectOneTodoElement(idTodoElement: number, schema: SelectSchema = {}): Observable<TodoElement> {
    return this.store$.pipe(select(TodoElementSelectors.selectOneTodoElement(schema, idTodoElement))).pipe(
      switchMap(({ todoElement }: { todoElement: TodoElement }) => {
        return this.getReady(schema).pipe(mapTo(todoElement));
      })
    );
  }

  public selectAllActiveTodoElements(schema: SelectSchema = {}): Observable<TodoElement[]> {
    return this.store$.pipe(select(TodoElementSelectors.selectActiveTodoElements(schema))).pipe(
      switchMap(({ todoElements }: { todoElements: TodoElement[] }) => {
        return this.getReady(schema).pipe(mapTo(todoElements));
      })
    );
  }

  public selectIdTodoElementsActive(): Observable<number[]> {
    return this.store$.pipe(select(TodoElementSelectors.selectIdTodoElementsActive)).pipe(
      switchMap((idTodoElements: number[]) => {
        return this.getReady().pipe(mapTo(idTodoElements));
      })
    );
  }

  public getOneTodoElement(
    idTodoElement: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<TodoElement> {
    this.store$.dispatch(TodoElementGeneratedActions.getOneTodoElement({ idTodoElement, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert,
        TodoElementGeneratedActions.todoElementsFailure,
        true
      );
    }
  }

  public getManyTodoElements(params: any = {}, getResult?: boolean): void | Observable<TodoElement[]> {
    this.store$.dispatch(TodoElementGeneratedActions.getManyTodoElements({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert,
        TodoElementGeneratedActions.todoElementsFailure
      );
    }
  }

  public upsertOneTodoElement(
    todoElement: Partial<TodoElement>,
    ids: TodoElementRelationsIds = {},
    getResult?: boolean
  ): void | Observable<TodoElement> {
    this.store$.dispatch(TodoElementGeneratedActions.upsertOneTodoElement({ todoElement, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert,
        TodoElementGeneratedActions.todoElementsFailure,
        true
      );
    }
  }

  public deleteOneTodoElement(idTodoElement: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(TodoElementGeneratedActions.deleteOneTodoElement({ idTodoElement }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TodoElementGeneratedActions.deleteOneTodoElementSuccess,
        TodoElementGeneratedActions.todoElementsFailure
      );
    }
  }

  public setActiveTodoElements(idTodoElements: number[]): void {
    this.store$.dispatch(TodoElementGeneratedActions.clearActivesTodoElements());
    this.store$.dispatch(TodoElementGeneratedActions.addManyActivesTodoElements({ idTodoElements }));
  }
}
