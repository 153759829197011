import { MeetingElementState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { meetingElementReducersGeneratedFunctions } from './meeting-element-generated.reducer';

const meetingElementReducersFunctions = [...meetingElementReducersGeneratedFunctions];

const meetingElementReducer = createReducer(MeetingElementState.initialState, ...meetingElementReducersFunctions);

export function reducer(state: MeetingElementState.IState | undefined, action: Action) {
  return meetingElementReducer(state, action);
}
