import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { formatDuration } from '../utils/new-gantt-templates';
import { FormBuilder, FormGroup } from '@angular/forms';
import { tap } from 'rxjs';
import { MoveTaskStrategy } from '../services/task.service';

@UntilDestroy()
@Component({
  selector: 'wip-custom-date-editor',
  templateUrl: './custom-date-editor.component.html',
  styleUrls: ['./custom-date-editor.component.scss']
})
export class CustomDateEditorComponent implements OnInit {
  public selected: MoveTaskStrategy;
  public dateStart;
  public fg: FormGroup;
  public diffFormated: string;
  public dateFormatted: string;
  public sign;
  public moveTaskStrategy = MoveTaskStrategy;

  constructor(
    public dialogRef: MatDialogRef<CustomDateEditorComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: any // private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({ newDate: this.dialogData.newDate });
    this.dateFormatted = this.dialogData.newDate.toJSON().slice(0, 10).split('-').reverse().join('/');
    const diff = Math.round(
      (this.dialogData.newDate.getTime() - this.dialogData.task.start_date.getTime()) / (1000 * 3600 * 24)
    );
    if (diff < 0) {
      this.sign = '-';
    } else {
      this.sign = '+';
    }
    this.diffFormated = formatDuration(Math.abs(diff), false);

    this.fg.controls.newDate.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(val => {
          const diff = Math.round((val.getTime() - this.dialogData.task.start_date.getTime()) / (1000 * 3600 * 24));
          if (diff < 0) {
            this.sign = '-';
          } else {
            this.sign = '+';
          }
          this.diffFormated = formatDuration(Math.abs(diff), false);
          this.dateFormatted = val.toJSON().slice(0, 10).split('-').reverse().join('/');
        })
      )
      .subscribe();
  }

  public close() {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close({
      option: this.selected,
      date: this.fg.controls.newDate.value,
      oldDate: this.dialogData.oldDate
    });
  }
}
