import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { ElementLibrary, ElementLibraryEntityState } from '@api/api-interfaces';
import { ElementLibraryApiService } from '@wip/store/api-services';
import { ElementLibraryGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeElementLibrary } from '@wip/store/configs/normalization';
import { ElementLibrarySelectors } from '@wip/store/selectors';
import { ElementLibraryRelationsIds } from '@wip/store/ids-interfaces';
import { ElementGeneratedActions } from '@wip/store/actions';
import { UserGeneratedActions } from '@wip/store/actions';
import { CommunityUserProfilGeneratedActions } from '@wip/store/actions';

export function getDefaultAddElementLibraryActions(
  elementLibrary: ElementLibraryEntityState,
  ids?: ElementLibraryRelationsIds
): Action[] {
  const actions: Action[] = [
    ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert({ elementLibraries: [elementLibrary] })
  ];

  if (ids?.element) {
    actions.push(
      ElementGeneratedActions.addManyElementLibrarySuccess({
        idElement: ids.element,
        idElementLibraries: [elementLibrary.idElementLibrary]
      })
    );
    actions.push(
      ElementLibraryGeneratedActions.addElementSuccess({
        idElementLibrary: elementLibrary.idElementLibrary,
        idElement: ids.element
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyElementLibrarySuccess({
        idUser: ids.user,
        idElementLibraries: [elementLibrary.idElementLibrary]
      })
    );
    actions.push(
      ElementLibraryGeneratedActions.addUserSuccess({
        idElementLibrary: elementLibrary.idElementLibrary,
        idUser: ids.user
      })
    );
  }

  if (ids?.communityUserProfil) {
    actions.push(
      CommunityUserProfilGeneratedActions.addManyElementLibrarySuccess({
        idCommunityUserProfil: ids.communityUserProfil,
        idElementLibraries: [elementLibrary.idElementLibrary]
      })
    );
    actions.push(
      ElementLibraryGeneratedActions.addCommunityUserProfilSuccess({
        idElementLibrary: elementLibrary.idElementLibrary,
        idCommunityUserProfil: ids.communityUserProfil
      })
    );
  }

  return actions;
}

export function getDefaultDeleteElementLibraryActions(elementLibrary: ElementLibraryEntityState): Action[] {
  const actions: Action[] = [
    ElementLibraryGeneratedActions.deleteOneElementLibrarySuccess({ idElementLibrary: elementLibrary.idElementLibrary })
  ];

  if (elementLibrary.element) {
    actions.push(
      ElementGeneratedActions.deleteManyElementLibrarySuccess({
        idElementLibraries: [elementLibrary.idElementLibrary],
        idElements: [elementLibrary.element as number]
      })
    );
  }

  if (elementLibrary.user) {
    actions.push(
      UserGeneratedActions.deleteManyElementLibrarySuccess({
        idElementLibraries: [elementLibrary.idElementLibrary],
        idUsers: [elementLibrary.user as number]
      })
    );
  }

  if (elementLibrary.communityUserProfil) {
    actions.push(
      CommunityUserProfilGeneratedActions.deleteManyElementLibrarySuccess({
        idElementLibraries: [elementLibrary.idElementLibrary],
        idCommunityUserProfils: [elementLibrary.communityUserProfil as number]
      })
    );
  }

  return actions;
}

export class GeneratedElementLibraryEffects {
  constructor(
    protected actions$: Actions,
    protected elementLibraryApiService: ElementLibraryApiService,
    protected store$: Store<AppState>
  ) {}

  getManyElementLibraries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementLibraryGeneratedActions.getManyElementLibraries),
      switchMap(({ params }) =>
        this.elementLibraryApiService.getElementLibraries(params).pipe(
          map((elementLibraries: ElementLibrary[]) => {
            return ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert({ elementLibraries });
          }),
          catchError(error => of(ElementLibraryGeneratedActions.elementLibrariesFailure({ error })))
        )
      )
    );
  });

  getOneElementLibrary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementLibraryGeneratedActions.getOneElementLibrary),
      switchMap(idElementLibrary =>
        this.elementLibraryApiService.getElementLibrary(idElementLibrary).pipe(
          map((elementLibrary: ElementLibrary) => {
            return ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert({
              elementLibraries: [elementLibrary]
            });
          }),
          catchError(error => of(ElementLibraryGeneratedActions.elementLibrariesFailure({ error })))
        )
      )
    );
  });

  upsertOneElementLibrary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementLibraryGeneratedActions.upsertOneElementLibrary),
      concatMap(
        ({ elementLibrary, ids }: { elementLibrary: Partial<ElementLibrary>; ids?: ElementLibraryRelationsIds }) => {
          if (elementLibrary.idElementLibrary) {
            return this.elementLibraryApiService.updateElementLibrary(elementLibrary).pipe(
              map((elementLibraryReturned: ElementLibrary) => {
                return ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert({
                  elementLibraries: [elementLibraryReturned]
                });
              }),
              catchError(error => of(ElementLibraryGeneratedActions.elementLibrariesFailure({ error })))
            );
          } else {
            return this.elementLibraryApiService.addElementLibrary(elementLibrary).pipe(
              mergeMap((elementLibraryReturned: ElementLibrary) =>
                getDefaultAddElementLibraryActions(elementLibraryReturned, ids)
              ),
              catchError(error => of(ElementLibraryGeneratedActions.elementLibrariesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneElementLibrary$ = createEffect(() => {
    const selectElementLibraryState$ = this.store$.select(ElementLibrarySelectors.selectElementLibraryState);
    return this.actions$.pipe(
      ofType(ElementLibraryGeneratedActions.deleteOneElementLibrary),
      withLatestFrom(selectElementLibraryState$),
      concatMap(([{ idElementLibrary }, state]) =>
        this.elementLibraryApiService.deleteElementLibrary(idElementLibrary).pipe(
          mergeMap(_success =>
            getDefaultDeleteElementLibraryActions(state.entities[idElementLibrary] as ElementLibraryEntityState)
          ),
          catchError(error => of(ElementLibraryGeneratedActions.elementLibrariesFailure({ error })))
        )
      )
    );
  });

  normalizeManyElementLibrariesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert),
      concatMap(({ elementLibraries }) => {
        const actions: Action[] = getActionsToNormalizeElementLibrary(elementLibraries, StoreActionType.upsert);
        return [getMultiAction(actions, '[ElementLibrary] Normalization After Upsert Success')];
      })
    );
  });
}
