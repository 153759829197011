import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationGeneratedActions = createActionGroup({
  source: 'Organization Generated',
  events: {
    'Get One Organization': props<{ idOrganization: number; params?: any }>(),
    'Get Many Organizations': props<{ params: any }>(),
    'Add Many Actives Organizations': props<{ idOrganizations: number[] }>(),
    'Delete One Active Organization': props<{ idOrganization: number }>(),
    'Clear Actives Organizations': emptyProps(),
    'Upsert One Organization': props<{ organization: Partial<Organization>; ids?: OrganizationRelationsIds }>(),
    'Upsert Many Organizations': props<{ organizations: Partial<Organization>[]; ids?: OrganizationRelationsIds }>(),
    'Upsert Many Organizations Success': props<{ organizations: OrganizationEntityState[] }>(),
    'Delete One Organization': props<{ idOrganization: number }>(),
    'Delete One Organization Success': props<{ idOrganization: number }>(),
    'Normalize Many Organizations After Upsert': props<{ organizations: OrganizationEntityState[] }>(),
    'Organizations Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organizations': emptyProps(),
    'Add Many Community Success': props<{ idOrganization: number; idCommunities: number[] }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idOrganizations: number[] }>(),
    'Add Many Organization Azure Success': props<{ idOrganization: number; idOrganizationAzures: number[] }>(),
    'Delete Many Organization Azure Success': props<{ idOrganizationAzures: number[]; idOrganizations: number[] }>(),
    'Add Many Organization Element Success': props<{ idOrganization: number; idOrganizationElements: number[] }>(),
    'Delete Many Organization Element Success': props<{
      idOrganizationElements: number[];
      idOrganizations: number[];
    }>(),
    'Add Many Group Success': props<{ idOrganization: number; idGroups: number[] }>(),
    'Delete Many Group Success': props<{ idGroups: number[]; idOrganizations: number[] }>(),
    'Add Many Organization User Success': props<{ idOrganization: number; idOrganizationUsers: number[] }>(),
    'Delete Many Organization User Success': props<{ idOrganizationUsers: number[]; idOrganizations: number[] }>(),
    'Add Many Organization Family Success': props<{ idOrganization: number; idOrganizationFamilys: number[] }>(),
    'Delete Many Organization Family Success': props<{ idOrganizationFamilys: number[]; idOrganizations: number[] }>(),
    'Add Many Organization Milestone Family Success': props<{
      idOrganization: number;
      idOrganizationMilestoneFamilys: number[];
    }>(),
    'Delete Many Organization Milestone Family Success': props<{
      idOrganizationMilestoneFamilys: number[];
      idOrganizations: number[];
    }>(),
    'Add Many Organization Milestone Success': props<{ idOrganization: number; idOrganizationMilestones: number[] }>(),
    'Delete Many Organization Milestone Success': props<{
      idOrganizationMilestones: number[];
      idOrganizations: number[];
    }>(),
    'Add Many Organization Risk Success': props<{ idOrganization: number; idOrganizationRisks: number[] }>(),
    'Delete Many Organization Risk Success': props<{ idOrganizationRisks: number[]; idOrganizations: number[] }>(),
    'Add Many Organization Kpi Success': props<{ idOrganization: number; idOrganizationKpis: number[] }>(),
    'Delete Many Organization Kpi Success': props<{ idOrganizationKpis: number[]; idOrganizations: number[] }>(),
    'Add Many Organization Sub Family Success': props<{ idOrganization: number; idOrganizationSubFamilys: number[] }>(),
    'Delete Many Organization Sub Family Success': props<{
      idOrganizationSubFamilys: number[];
      idOrganizations: number[];
    }>(),
    'Add Many Organization Caracteristic Success': props<{
      idOrganization: number;
      idOrganizationCaracteristics: number[];
    }>(),
    'Delete Many Organization Caracteristic Success': props<{
      idOrganizationCaracteristics: number[];
      idOrganizations: number[];
    }>(),
    'Add Many Organization User Profil Success': props<{
      idOrganization: number;
      idOrganizationUserProfils: number[];
    }>(),
    'Delete Many Organization User Profil Success': props<{
      idOrganizationUserProfils: number[];
      idOrganizations: number[];
    }>(),
    'Add Many Organization Step Success': props<{ idOrganization: number; idOrganizationSteps: number[] }>(),
    'Delete Many Organization Step Success': props<{ idOrganizationSteps: number[]; idOrganizations: number[] }>(),
    'Add Many Organization Project Module Success': props<{
      idOrganization: number;
      idOrganizationProjectModules: number[];
    }>(),
    'Delete Many Organization Project Module Success': props<{
      idOrganizationProjectModules: number[];
      idOrganizations: number[];
    }>(),
    'Add Many Organization Section Success': props<{ idOrganization: number; idOrganizationSections: number[] }>(),
    'Delete Many Organization Section Success': props<{
      idOrganizationSections: number[];
      idOrganizations: number[];
    }>(),
    'Add Many Profil Success': props<{ idOrganization: number; idProfils: number[] }>(),
    'Delete Many Profil Success': props<{ idProfils: number[]; idOrganizations: number[] }>(),
    'Add Many User Success': props<{ idOrganization: number; idUsers: number[] }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idOrganizations: number[] }>(),
    'Add Many Section Success': props<{ idOrganization: number; idSections: number[] }>(),
    'Delete Many Section Success': props<{ idSections: number[]; idOrganizations: number[] }>()
  }
});
