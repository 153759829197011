import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityCaracteristic, CommunityCaracteristicEntityState } from '@api/api-interfaces';
import { CommunityCaracteristicRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityCaracteristicGeneratedActions = createActionGroup({
  source: 'Community Caracteristic Generated',
  events: {
    'Get One Community Caracteristic': props<{ idCommunityCaracteristic: number; params?: any }>(),
    'Get Many Community Caracteristics': props<{ params: any }>(),
    'Add Many Actives Community Caracteristics': props<{ idCommunityCaracteristics: number[] }>(),
    'Delete One Active Community Caracteristic': props<{ idCommunityCaracteristic: number }>(),
    'Clear Actives Community Caracteristics': emptyProps(),
    'Upsert One Community Caracteristic': props<{
      communityCaracteristic: Partial<CommunityCaracteristic>;
      ids?: CommunityCaracteristicRelationsIds;
    }>(),
    'Upsert Many Community Caracteristics': props<{
      communityCaracteristics: Partial<CommunityCaracteristic>[];
      ids?: CommunityCaracteristicRelationsIds;
    }>(),
    'Upsert Many Community Caracteristics Success': props<{
      communityCaracteristics: CommunityCaracteristicEntityState[];
    }>(),
    'Delete One Community Caracteristic': props<{ idCommunityCaracteristic: number }>(),
    'Delete One Community Caracteristic Success': props<{ idCommunityCaracteristic: number }>(),
    'Normalize Many Community Caracteristics After Upsert': props<{
      communityCaracteristics: CommunityCaracteristicEntityState[];
    }>(),
    'Community Caracteristics Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Caracteristics': emptyProps(),
    'Add Organization Caracteristic Success': props<{
      idCommunityCaracteristic: number;
      idOrganizationCaracteristic: number;
    }>(),
    'Delete Many Organization Caracteristic Success': props<{
      idOrganizationCaracteristics: number[];
      idCommunityCaracteristics: number[];
    }>(),
    'Add Community Success': props<{ idCommunityCaracteristic: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityCaracteristics: number[] }>()
  }
});
