import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationCaracteristic } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationCaracteristicRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationCaracteristicService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationCaracteristicSelectors.selectIsLoadedOrganizationCaracteristic));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationCaracteristicSelectors.selectIsLoadingOrganizationCaracteristic));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationCaracteristicSelectors.selectIsReadyAndLoadedOrganizationCaracteristic as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationCaracteristics(schema: SelectSchema = {}): Observable<OrganizationCaracteristic[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicSelectors.selectAllOrganizationCaracteristics(schema)))
      .pipe(
        switchMap(({ organizationCaracteristics }: { organizationCaracteristics: OrganizationCaracteristic[] }) => {
          return this.getReady(schema).pipe(mapTo(organizationCaracteristics));
        })
      );
  }

  public selectOneOrganizationCaracteristic(
    idOrganizationCaracteristic: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationCaracteristic> {
    return this.store$
      .pipe(
        select(
          OrganizationCaracteristicSelectors.selectOneOrganizationCaracteristic(schema, idOrganizationCaracteristic)
        )
      )
      .pipe(
        switchMap(({ organizationCaracteristic }: { organizationCaracteristic: OrganizationCaracteristic }) => {
          return this.getReady(schema).pipe(mapTo(organizationCaracteristic));
        })
      );
  }

  public selectAllActiveOrganizationCaracteristics(schema: SelectSchema = {}): Observable<OrganizationCaracteristic[]> {
    return this.store$
      .pipe(select(OrganizationCaracteristicSelectors.selectActiveOrganizationCaracteristics(schema)))
      .pipe(
        switchMap(({ organizationCaracteristics }: { organizationCaracteristics: OrganizationCaracteristic[] }) => {
          return this.getReady(schema).pipe(mapTo(organizationCaracteristics));
        })
      );
  }

  public selectIdOrganizationCaracteristicsActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationCaracteristicSelectors.selectIdOrganizationCaracteristicsActive)).pipe(
      switchMap((idOrganizationCaracteristics: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationCaracteristics));
      })
    );
  }

  public getOneOrganizationCaracteristic(
    idOrganizationCaracteristic: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristic> {
    this.store$.dispatch(
      OrganizationCaracteristicGeneratedActions.getOneOrganizationCaracteristic({ idOrganizationCaracteristic, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicGeneratedActions.normalizeManyOrganizationCaracteristicsAfterUpsert,
        OrganizationCaracteristicGeneratedActions.organizationCaracteristicsFailure,
        true
      );
    }
  }

  public getManyOrganizationCaracteristics(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristic[]> {
    this.store$.dispatch(OrganizationCaracteristicGeneratedActions.getManyOrganizationCaracteristics({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicGeneratedActions.normalizeManyOrganizationCaracteristicsAfterUpsert,
        OrganizationCaracteristicGeneratedActions.organizationCaracteristicsFailure
      );
    }
  }

  public upsertOneOrganizationCaracteristic(
    organizationCaracteristic: Partial<OrganizationCaracteristic>,
    ids: OrganizationCaracteristicRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationCaracteristic> {
    this.store$.dispatch(
      OrganizationCaracteristicGeneratedActions.upsertOneOrganizationCaracteristic({ organizationCaracteristic, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicGeneratedActions.normalizeManyOrganizationCaracteristicsAfterUpsert,
        OrganizationCaracteristicGeneratedActions.organizationCaracteristicsFailure,
        true
      );
    }
  }

  public deleteOneOrganizationCaracteristic(
    idOrganizationCaracteristic: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationCaracteristicGeneratedActions.deleteOneOrganizationCaracteristic({ idOrganizationCaracteristic })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationCaracteristicGeneratedActions.deleteOneOrganizationCaracteristicSuccess,
        OrganizationCaracteristicGeneratedActions.organizationCaracteristicsFailure
      );
    }
  }

  public setActiveOrganizationCaracteristics(idOrganizationCaracteristics: number[]): void {
    this.store$.dispatch(OrganizationCaracteristicGeneratedActions.clearActivesOrganizationCaracteristics());
    this.store$.dispatch(
      OrganizationCaracteristicGeneratedActions.addManyActivesOrganizationCaracteristics({
        idOrganizationCaracteristics
      })
    );
  }
}
