import { MemoizedSelector } from '@ngrx/store';

export type GetSelector = (schema?: SelectSchema, customKey?: string) => Selector;

export interface SelectorModel {
  name: string;
  getSelector: GetSelector;
  isReady: Selector;
}

export interface SelectSchema {
  include?: (SelectSchema | SelectorModel)[];
  model?: SelectorModel;
}

export type Selector = MemoizedSelector<any, any, any>;

export interface SelectorRelation {
  model: string;
  getSelector: (schema: SelectSchema) => Selector;
}

export function getRelationSelectors(schema: SelectSchema, relations: string[] = [], entity: string): Selector[] {
  const selectors = [];
  if (schema.include) {
    for (const childSchemaOrModel of schema.include) {
      if ((childSchemaOrModel as any).name) {
        const model: SelectorModel = childSchemaOrModel as SelectorModel;
        if (!relations.some((relation: string) => relation === model.name)) {
          throw new Error(`The model ${model.name} does not belong to the ${entity}'s relations`);
        }
        selectors.push(model.getSelector(undefined, model.name));
      } else {
        const childSchema: SelectSchema = childSchemaOrModel as SelectSchema;
        if (!relations.some((relation: string) => relation === childSchema?.model?.name)) {
          throw new Error(`The model ${childSchema?.model?.name} does not belong to the ${entity}'s relations`);
        }
        childSchema.model && selectors.push(childSchema.model.getSelector(childSchema, childSchema.model.name));
      }
    }
  }
  return selectors;
}

function getIsReadySelectorsRecursive(schemaOrModel: SelectSchema | SelectorModel, isReadySelectors: Selector[]): void {
  if ((schemaOrModel as any).name) {
    const model: SelectorModel = schemaOrModel as SelectorModel;
    isReadySelectors.push(model.isReady);
    return;
  }
  const schema: SelectSchema = schemaOrModel as SelectSchema;
  if (schema.model) {
    isReadySelectors.push(schema.model.isReady);
  }
  if (schema.include) {
    schema.include.forEach((childSchemaOrModel: SelectSchema | SelectorModel) =>
      getIsReadySelectorsRecursive(childSchemaOrModel, isReadySelectors)
    );
  }
}

export function getIsReadySelectors(schema: SelectSchema): Selector[] {
  const isReadySelectors: Selector[] = [];
  getIsReadySelectorsRecursive(schema, isReadySelectors);
  return isReadySelectors;
}
