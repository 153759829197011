import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationFamilyEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationFamilyEntityState>;

export const adapter = createEntityAdapter<OrganizationFamilyEntityState>({
  selectId: o => o.idOrganizationFamily
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationFamilyFeatureKey = 'organizationFamily';
