import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationSection, OrganizationSectionEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { Section, SectionEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationSectionState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationSectionRelations: string[] = ['organizations', 'sections'];

export const { selectEntities, selectAll } = OrganizationSectionState.adapter.getSelectors();

export const selectOrganizationSectionState = createFeatureSelector<OrganizationSectionState.IState>(
  OrganizationSectionState.organizationSectionFeatureKey
);

export const selectIsLoadedOrganizationSection = createSelector(
  selectOrganizationSectionState,
  (state: OrganizationSectionState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationSection = createSelector(
  selectOrganizationSectionState,
  (state: OrganizationSectionState.IState) => state.isLoading
);

export const selectIsReadyOrganizationSection = createSelector(
  selectOrganizationSectionState,
  (state: OrganizationSectionState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationSection = createSelector(
  selectOrganizationSectionState,
  (state: OrganizationSectionState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationSectionsEntities = createSelector(selectOrganizationSectionState, selectEntities);

export const selectOrganizationSectionsArray = createSelector(selectOrganizationSectionState, selectAll);

export const selectIdOrganizationSectionsActive = createSelector(
  selectOrganizationSectionState,
  (state: OrganizationSectionState.IState) => state.actives
);

const organizationSectionsInObject = (organizationSections: Dictionary<OrganizationSectionEntityState>) => ({
  organizationSections
});

const selectOrganizationSectionsEntitiesDictionary = createSelector(
  selectOrganizationSectionsEntities,
  organizationSectionsInObject
);

const selectAllOrganizationSectionsObject = createSelector(selectOrganizationSectionsEntities, organizationSections => {
  return hydrateAll({ organizationSections });
});

const selectOneOrganizationSectionDictionary = (idOrganizationSection: number) =>
  createSelector(selectOrganizationSectionsEntities, organizationSections => ({
    organizationSections: { [idOrganizationSection]: organizationSections[idOrganizationSection] }
  }));

const selectOneOrganizationSectionDictionaryWithoutChild = (idOrganizationSection: number) =>
  createSelector(selectOrganizationSectionsEntities, organizationSections => ({
    organizationSection: organizationSections[idOrganizationSection]
  }));

const selectActiveOrganizationSectionsEntities = createSelector(
  selectIdOrganizationSectionsActive,
  selectOrganizationSectionsEntities,
  (actives: number[], organizationSections: Dictionary<OrganizationSectionEntityState>) =>
    getOrganizationSectionsFromActives(actives, organizationSections)
);

function getOrganizationSectionsFromActives(
  actives: number[],
  organizationSections: Dictionary<OrganizationSectionEntityState>
): Dictionary<OrganizationSectionEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationSections[idActive]) {
      acc[idActive] = organizationSections[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationSectionEntityState>);
}

const selectAllOrganizationSectionsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationSections(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationSection>(
      schema,
      selectAllOrganizationSectionsSelectors,
      selectOrganizationSectionsEntitiesDictionary,
      getRelationSelectors,
      organizationSectionRelations,
      hydrateAll,
      'organizationSection'
    );
  } else {
    return selectAllOrganizationSectionsObject;
  }
}

export function selectAllOrganizationSectionsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationSections'
): Selector {
  return createSelector(selectAllOrganizationSections(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationSectionEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationSections.length; i++) {
      res[customKey][result.organizationSections[i].idOrganizationSection] = result.organizationSections[i];
    }
    return res;
  });
}

export function selectOneOrganizationSection(schema: SelectSchema = {}, idOrganizationSection: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationSectionDictionary(idOrganizationSection)];
    selectors.push(...getRelationSelectors(schema, organizationSectionRelations, 'organizationSection'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationSectionDictionaryWithoutChild(idOrganizationSection);
  }
}

export function selectActiveOrganizationSections(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationSectionsEntities, organizationSections => ({
      organizationSections
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationSectionRelations, 'organizationSection'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationSections: Dictionary<OrganizationSectionEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  sections?: Dictionary<SectionEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationSections: (OrganizationSection | null)[] } {
  const { organizationSections, organizations, sections } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationSections: Object.keys(organizationSections).map(idOrganizationSection =>
      hydrate(organizationSections[idOrganizationSection] as OrganizationSectionEntityState, organizations, sections)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationSection: OrganizationSectionEntityState | null } {
  const { organizationSections, organizations, sections } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationSection = Object.values(organizationSections)[0];
  return {
    organizationSection: hydrate(organizationSection as OrganizationSectionEntityState, organizations, sections)
  };
}

function hydrate(
  organizationSection: OrganizationSectionEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  sectionEntities?: Dictionary<SectionEntityState>
): OrganizationSection | null {
  if (!organizationSection) {
    return null;
  }

  const organizationSectionHydrated: OrganizationSectionEntityState = { ...organizationSection };
  if (organizationEntities) {
    organizationSectionHydrated.organization = organizationEntities[
      organizationSection.organization as number
    ] as Organization;
  } else {
    delete organizationSectionHydrated.organization;
  }
  if (sectionEntities) {
    organizationSectionHydrated.section = sectionEntities[organizationSection.section as number] as Section;
  } else {
    delete organizationSectionHydrated.section;
  }

  return organizationSectionHydrated as OrganizationSection;
}
