import { DashboardFilterModule } from '@_app/dashboard/filters/dashboard-filters.component';
import { AgGridUtils } from '@_app/utils/ag-grid/cell-formatter/ag-grid.utils';
import AgGridCellCommonFormatter from '@_app/utils/ag-grid/cell-formatter/common-cell-formatter';
import { AgGridModule } from '@ag-grid-community/angular';
import {
  ColDef,
  GridOptions,
  Module,
  ValueFormatterParams,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent
} from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CommunityTypeStructureEnum } from '@enums';
import { Community, OrganizationFamily, OrganizationSubFamily } from '@api/api-interfaces';
import { getRowId } from '@wip/wip/front-utils';

@Component({
  selector: 'wip-projects-checklist',
  standalone: true,
  imports: [AgGridModule, DashboardFilterModule, CommonModule],
  templateUrl: './projects-checklist.component.html',
  styleUrls: ['./projects-checklist.component.scss']
})
export class ProjectChecklistComponent implements OnChanges, OnInit {
  constructor(private fb: FormBuilder) {}

  @Input() communities: Community[];
  @Input() organizationFamilies: OrganizationFamily[];
  @Input() subFamilies: OrganizationSubFamily[];
  @Input() showFamilies: boolean;
  @Input() fromModal: boolean = false;
  @Input() module: string = '';

  @Output() onSelectionChange = new EventEmitter<number>();

  public gridOptions: GridOptions;
  public formGroup: FormGroup;
  public modules: Module[] = AgGridUtils.getCommonModules();
  public gridApi: GridApi;

  ngOnInit() {
    this.gridOptions = this.getDefaultGridOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.communities) {
      if (this.communities.length) {
        if (!this.formGroup) {
          this.formGroup = this.fb.group({
            subFamilyControl: new FormControl([]),
            subFamilyRule: new FormControl(false)
          });
          this.formGroup.valueChanges.subscribe(_ => {
            this.setRowData();
          });
        }
        this.setRowData();
      }
    }
  }

  private setRowData(): void {
    this.gridApi?.setGridOption(
      'rowData',
      this.getRowData(
        this.filterCommunities(
          this.communities,
          this.formGroup.controls.subFamilyControl.value,
          this.formGroup.controls.subFamilyRule.value
        )
      )
    );
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.setRowData();
  }

  public getRowData(communities: Community[]) {
    return communities.map(c => {
      let responsibleDisplay;
      const responsible = c.communityUserProfils?.find(cup => cup.selected);
      if (responsible && responsible.user) {
        responsibleDisplay = responsible.user.prenom + ' ' + responsible.user.nom;
      }

      return {
        idCommunity: c.idCommunity,
        nom: c.nom,
        organizationFamily: c.organizationFamily?.libelle,
        responsible: responsibleDisplay,
        organizationStep: c.organizationStep?.libelle,
        lastUpdate: c.projetDernierMajDate || c.updatedAt,
        projetVerifie: c.projetVerifie
      };
    });
  }

  private getDefaultGridOptions(): GridOptions {
    return {
      ...AgGridUtils.getCommonGridOption(),
      rowData: [],
      columnDefs: this.createColumnDefs(), // Define columns
      rowSelection: 'multiple', // Type of selection: single or multiple
      groupSelectsChildren: true,
      rowGroupPanelShow: 'always',
      suppressRowClickSelection: true,
      groupDefaultExpanded: -1,
      getRowId: params => getRowId(params.data.idCommunity),
      defaultColDef: {
        ...AgGridUtils.getCommonGridOption().defaultColDef,
        editable: false,
        filter: true,
        resizable: true,
        sortable: true,
        comparator: (a, b) => {
          if (typeof a === 'string') {
            return a.localeCompare(b);
          } else {
            return a > b ? 1 : a < b ? -1 : 0;
          }
        }
      }
    };
  }

  private createColumnDefs(): ColDef[] {
    return [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 30
      },
      {
        headerName: 'Nom du projet',
        resizable: false,
        colId: 'nom',
        field: 'nom',
        width: 200
      },
      {
        headerName: "Famille d'opération",
        field: 'organizationFamily',
        colId: 'organizationFamily',
        enableRowGroup: true,
        hide: !this.showFamilies,
        width: 200
      },
      {
        headerName: 'Pilote',
        colId: 'responsible',
        field: 'responsible',
        enableRowGroup: true,
        width: 200,
        cellStyle: { 'vertical-align': 'left' }
      },
      {
        headerName: "Stade d'avancement",
        colId: 'organizationStep',
        field: 'organizationStep',
        enableRowGroup: true,
        width: 200,
        cellStyle: { 'vertical-align': 'left' }
      },
      {
        headerName: 'Dernière mise à jour',
        filter: 'agDateColumnFilter',
        filterParams: AgGridUtils.getDefaultDateFilterParams(),
        valueFormatter: (params: ValueFormatterParams<Date>) =>
          AgGridCellCommonFormatter.formatDateOrNull(params.value),
        colId: 'lastUpdate',
        field: 'lastUpdate',
        width: 200,
        cellStyle: { 'vertical-align': 'left' }
      }
    ];
  }

  public onSelectionChanged() {
    this.onSelectionChange.emit(this.gridApi.getSelectedRows().length);
  }

  private filterCommunities(communities: Community[], subFamilyControl: number[], subFamilyRule: boolean) {
    let commFiltered = communities.filter(c => c.typeStructure === CommunityTypeStructureEnum.project);

    if (!subFamilyControl.length) {
      return commFiltered;
    }

    if (!subFamilyRule) {
      commFiltered = commFiltered.filter(
        comm =>
          subFamilyControl.filter(id => comm.communitySubFamilys.map(csf => csf.idOrganizationSubFamily).includes(id))
            .length
      );
    } else {
      commFiltered = commFiltered.filter(comm => {
        for (const orgaSubFamily of subFamilyControl) {
          if (!comm.communitySubFamilys.some(csf => csf.idOrganizationSubFamily === orgaSubFamily)) {
            return false;
          }
        }
        return true;
      });
    }
    return commFiltered;
  }

  public onFirstDataRendered(params: FirstDataRenderedEvent<any>) {
    if (this.module === 'verification') {
      params.api.forEachNode(node => {
        if (!node.data.projetVerifie) {
          // TODO : https://www.ag-grid.com/changelog/?fixVersion=30.0.0
          // node.setSelected(true, false, false);

          node.setSelected(true, false);
        }
      });
    }
  }
}
