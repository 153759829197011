import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { CommunityCaracteristic, CommunityCaracteristicEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicColumn, OrganizationCaracteristicColumnEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicRow, OrganizationCaracteristicRowEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { OrganizationDataColumn, OrganizationDataColumnEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationCaracteristicState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationCaracteristicRelations: string[] = [
  'communityCaracteristics',
  'organizationMilestoneFamilys',
  'organizationCaracteristicColumns',
  'organizationCaracteristicRows',
  'organizations',
  'organizationFamilys',
  'organizationDataColumns'
];

export const { selectEntities, selectAll } = OrganizationCaracteristicState.adapter.getSelectors();

export const selectOrganizationCaracteristicState = createFeatureSelector<OrganizationCaracteristicState.IState>(
  OrganizationCaracteristicState.organizationCaracteristicFeatureKey
);

export const selectIsLoadedOrganizationCaracteristic = createSelector(
  selectOrganizationCaracteristicState,
  (state: OrganizationCaracteristicState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationCaracteristic = createSelector(
  selectOrganizationCaracteristicState,
  (state: OrganizationCaracteristicState.IState) => state.isLoading
);

export const selectIsReadyOrganizationCaracteristic = createSelector(
  selectOrganizationCaracteristicState,
  (state: OrganizationCaracteristicState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationCaracteristic = createSelector(
  selectOrganizationCaracteristicState,
  (state: OrganizationCaracteristicState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationCaracteristicsEntities = createSelector(
  selectOrganizationCaracteristicState,
  selectEntities
);

export const selectOrganizationCaracteristicsArray = createSelector(selectOrganizationCaracteristicState, selectAll);

export const selectIdOrganizationCaracteristicsActive = createSelector(
  selectOrganizationCaracteristicState,
  (state: OrganizationCaracteristicState.IState) => state.actives
);

const organizationCaracteristicsInObject = (
  organizationCaracteristics: Dictionary<OrganizationCaracteristicEntityState>
) => ({ organizationCaracteristics });

const selectOrganizationCaracteristicsEntitiesDictionary = createSelector(
  selectOrganizationCaracteristicsEntities,
  organizationCaracteristicsInObject
);

const selectAllOrganizationCaracteristicsObject = createSelector(
  selectOrganizationCaracteristicsEntities,
  organizationCaracteristics => {
    return hydrateAll({ organizationCaracteristics });
  }
);

const selectOneOrganizationCaracteristicDictionary = (idOrganizationCaracteristic: number) =>
  createSelector(selectOrganizationCaracteristicsEntities, organizationCaracteristics => ({
    organizationCaracteristics: {
      [idOrganizationCaracteristic]: organizationCaracteristics[idOrganizationCaracteristic]
    }
  }));

const selectOneOrganizationCaracteristicDictionaryWithoutChild = (idOrganizationCaracteristic: number) =>
  createSelector(selectOrganizationCaracteristicsEntities, organizationCaracteristics => ({
    organizationCaracteristic: organizationCaracteristics[idOrganizationCaracteristic]
  }));

const selectActiveOrganizationCaracteristicsEntities = createSelector(
  selectIdOrganizationCaracteristicsActive,
  selectOrganizationCaracteristicsEntities,
  (actives: number[], organizationCaracteristics: Dictionary<OrganizationCaracteristicEntityState>) =>
    getOrganizationCaracteristicsFromActives(actives, organizationCaracteristics)
);

function getOrganizationCaracteristicsFromActives(
  actives: number[],
  organizationCaracteristics: Dictionary<OrganizationCaracteristicEntityState>
): Dictionary<OrganizationCaracteristicEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationCaracteristics[idActive]) {
      acc[idActive] = organizationCaracteristics[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationCaracteristicEntityState>);
}

const selectAllOrganizationCaracteristicsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationCaracteristics(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationCaracteristic>(
      schema,
      selectAllOrganizationCaracteristicsSelectors,
      selectOrganizationCaracteristicsEntitiesDictionary,
      getRelationSelectors,
      organizationCaracteristicRelations,
      hydrateAll,
      'organizationCaracteristic'
    );
  } else {
    return selectAllOrganizationCaracteristicsObject;
  }
}

export function selectAllOrganizationCaracteristicsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationCaracteristics'
): Selector {
  return createSelector(selectAllOrganizationCaracteristics(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationCaracteristicEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationCaracteristics.length; i++) {
      res[customKey][result.organizationCaracteristics[i].idOrganizationCaracteristic] =
        result.organizationCaracteristics[i];
    }
    return res;
  });
}

export function selectOneOrganizationCaracteristic(
  schema: SelectSchema = {},
  idOrganizationCaracteristic: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationCaracteristicDictionary(idOrganizationCaracteristic)];
    selectors.push(...getRelationSelectors(schema, organizationCaracteristicRelations, 'organizationCaracteristic'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationCaracteristicDictionaryWithoutChild(idOrganizationCaracteristic);
  }
}

export function selectActiveOrganizationCaracteristics(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationCaracteristicsEntities, organizationCaracteristics => ({
      organizationCaracteristics
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationCaracteristicRelations, 'organizationCaracteristic'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationCaracteristics: Dictionary<OrganizationCaracteristicEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  organizationDataColumns?: Dictionary<OrganizationDataColumnEntityState>;
  communityCaracteristics?: Dictionary<CommunityCaracteristicEntityState>;
  organizationMilestoneFamilys?: Dictionary<OrganizationMilestoneFamilyEntityState>;
  organizationCaracteristicColumns?: Dictionary<OrganizationCaracteristicColumnEntityState>;
  organizationCaracteristicRows?: Dictionary<OrganizationCaracteristicRowEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationCaracteristics: (OrganizationCaracteristic | null)[];
} {
  const {
    organizationCaracteristics,
    organizations,
    organizationFamilys,
    organizationDataColumns,
    communityCaracteristics,
    organizationMilestoneFamilys,
    organizationCaracteristicColumns,
    organizationCaracteristicRows
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationCaracteristics: Object.keys(organizationCaracteristics).map(idOrganizationCaracteristic =>
      hydrate(
        organizationCaracteristics[idOrganizationCaracteristic] as OrganizationCaracteristicEntityState,
        organizations,
        organizationFamilys,
        organizationDataColumns,
        communityCaracteristics,
        organizationMilestoneFamilys,
        organizationCaracteristicColumns,
        organizationCaracteristicRows
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationCaracteristic: OrganizationCaracteristicEntityState | null;
} {
  const {
    organizationCaracteristics,
    organizations,
    organizationFamilys,
    organizationDataColumns,
    communityCaracteristics,
    organizationMilestoneFamilys,
    organizationCaracteristicColumns,
    organizationCaracteristicRows
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationCaracteristic = Object.values(organizationCaracteristics)[0];
  return {
    organizationCaracteristic: hydrate(
      organizationCaracteristic as OrganizationCaracteristicEntityState,
      organizations,
      organizationFamilys,
      organizationDataColumns,
      communityCaracteristics,
      organizationMilestoneFamilys,
      organizationCaracteristicColumns,
      organizationCaracteristicRows
    )
  };
}

function hydrate(
  organizationCaracteristic: OrganizationCaracteristicEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  organizationDataColumnEntities?: Dictionary<OrganizationDataColumnEntityState>,
  communityCaracteristicEntities?: Dictionary<CommunityCaracteristicEntityState>,
  organizationMilestoneFamilyEntities?: Dictionary<OrganizationMilestoneFamilyEntityState>,
  organizationCaracteristicColumnEntities?: Dictionary<OrganizationCaracteristicColumnEntityState>,
  organizationCaracteristicRowEntities?: Dictionary<OrganizationCaracteristicRowEntityState>
): OrganizationCaracteristic | null {
  if (!organizationCaracteristic) {
    return null;
  }

  const organizationCaracteristicHydrated: OrganizationCaracteristicEntityState = { ...organizationCaracteristic };
  if (organizationEntities) {
    organizationCaracteristicHydrated.organization = organizationEntities[
      organizationCaracteristic.organization as number
    ] as Organization;
  } else {
    delete organizationCaracteristicHydrated.organization;
  }
  if (organizationFamilyEntities) {
    organizationCaracteristicHydrated.organizationFamily = organizationFamilyEntities[
      organizationCaracteristic.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete organizationCaracteristicHydrated.organizationFamily;
  }
  if (organizationDataColumnEntities) {
    organizationCaracteristicHydrated.organizationDataColumn = organizationDataColumnEntities[
      organizationCaracteristic.organizationDataColumn as number
    ] as OrganizationDataColumn;
  } else {
    delete organizationCaracteristicHydrated.organizationDataColumn;
  }

  if (communityCaracteristicEntities) {
    organizationCaracteristicHydrated.communityCaracteristics = (
      (organizationCaracteristicHydrated.communityCaracteristics as number[]) || []
    ).map(id => communityCaracteristicEntities[id]) as CommunityCaracteristic[];
  } else {
    delete organizationCaracteristicHydrated.communityCaracteristics;
  }

  if (organizationMilestoneFamilyEntities) {
    organizationCaracteristicHydrated.organizationMilestoneFamilys = (
      (organizationCaracteristicHydrated.organizationMilestoneFamilys as number[]) || []
    ).map(id => organizationMilestoneFamilyEntities[id]) as OrganizationMilestoneFamily[];
  } else {
    delete organizationCaracteristicHydrated.organizationMilestoneFamilys;
  }

  if (organizationCaracteristicColumnEntities) {
    organizationCaracteristicHydrated.organizationCaracteristicColumns = (
      (organizationCaracteristicHydrated.organizationCaracteristicColumns as number[]) || []
    ).map(id => organizationCaracteristicColumnEntities[id]) as OrganizationCaracteristicColumn[];
  } else {
    delete organizationCaracteristicHydrated.organizationCaracteristicColumns;
  }

  if (organizationCaracteristicRowEntities) {
    organizationCaracteristicHydrated.organizationCaracteristicRows = (
      (organizationCaracteristicHydrated.organizationCaracteristicRows as number[]) || []
    ).map(id => organizationCaracteristicRowEntities[id]) as OrganizationCaracteristicRow[];
  } else {
    delete organizationCaracteristicHydrated.organizationCaracteristicRows;
  }

  return organizationCaracteristicHydrated as OrganizationCaracteristic;
}
