import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MeetingElement, ReorderBasicInterface } from '@api/api-interfaces';
import * as AppState from '@wip/store/configs/reducers';
import * as MeetingElementAction from '@wip/store/actions';
import { Observable } from 'rxjs';
import { GeneratedMeetingElementService } from './meeting-element-generated.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingElementService extends GeneratedMeetingElementService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public reorder(params: ReorderBasicInterface, getResult?: boolean): void | Observable<MeetingElement> {
    this.store$.dispatch(MeetingElementAction.reorderMeetingElements({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        MeetingElementAction.MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert,
        MeetingElementAction.MeetingElementGeneratedActions.meetingElementsFailure,
        true
      );
    }
  }
}
