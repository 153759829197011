<div mat-dialog-title>
  <h1>Choisissez {{ data.fromMeeting ? 'une durée' : 'un décalage' }}</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
</div>
<form [formGroup]="durationInputGroup">
  <div mat-dialog-content class="flex justify-center items-center">
    <div class="flex flex-col justify-center items-center">
      @if (!data.fromMeeting) {
        <span>{{ data.text }}</span>
      }
      <div class="flex flex-row items-center">
        <mat-form-field style="width: 80px; margin-right: 8px">
          <input
            matInput
            autocomplete="off"
            type="number"
            formControlName="duration"
            min="0"
            step="5"
            placeholder="Durée en minutes"
            #inputElement
          />
        </mat-form-field>
        <span>{{ data.fromMeeting ? 'minutes' : 'jours' }}</span>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="warn" type="button" (click)="none()">
      Supprimer {{ data.fromMeeting ? 'la durée' : 'le lien' }}
    </button>
    <button mat-raised-button type="submit" (click)="validate()" color="primary" tabindex="0">Valider</button>
  </div>
</form>
