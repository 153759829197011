import { createEntityAdapter } from '@ngrx/entity';
import { CommunityMilestoneEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<CommunityMilestoneEntityState>;

export const adapter = createEntityAdapter<CommunityMilestoneEntityState>({
  selectId: o => o.idCommunityMilestone
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const communityMilestoneFeatureKey = 'communityMilestone';
