import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TodoElement, TodoElementEntityState } from '@api/api-interfaces';
import { Todo, TodoEntityState } from '@api/api-interfaces';
import { Element, ElementEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { TodoElementState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const todoElementRelations: string[] = ['todos', 'elements'];

export const { selectEntities, selectAll } = TodoElementState.adapter.getSelectors();

export const selectTodoElementState = createFeatureSelector<TodoElementState.IState>(
  TodoElementState.todoElementFeatureKey
);

export const selectIsLoadedTodoElement = createSelector(
  selectTodoElementState,
  (state: TodoElementState.IState) => state.isLoaded
);

export const selectIsLoadingTodoElement = createSelector(
  selectTodoElementState,
  (state: TodoElementState.IState) => state.isLoading
);

export const selectIsReadyTodoElement = createSelector(
  selectTodoElementState,
  (state: TodoElementState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedTodoElement = createSelector(
  selectTodoElementState,
  (state: TodoElementState.IState) => state.isLoaded && !state.isLoading
);

export const selectTodoElementsEntities = createSelector(selectTodoElementState, selectEntities);

export const selectTodoElementsArray = createSelector(selectTodoElementState, selectAll);

export const selectIdTodoElementsActive = createSelector(
  selectTodoElementState,
  (state: TodoElementState.IState) => state.actives
);

const todoElementsInObject = (todoElements: Dictionary<TodoElementEntityState>) => ({ todoElements });

const selectTodoElementsEntitiesDictionary = createSelector(selectTodoElementsEntities, todoElementsInObject);

const selectAllTodoElementsObject = createSelector(selectTodoElementsEntities, todoElements => {
  return hydrateAll({ todoElements });
});

const selectOneTodoElementDictionary = (idTodoElement: number) =>
  createSelector(selectTodoElementsEntities, todoElements => ({
    todoElements: { [idTodoElement]: todoElements[idTodoElement] }
  }));

const selectOneTodoElementDictionaryWithoutChild = (idTodoElement: number) =>
  createSelector(selectTodoElementsEntities, todoElements => ({
    todoElement: todoElements[idTodoElement]
  }));

const selectActiveTodoElementsEntities = createSelector(
  selectIdTodoElementsActive,
  selectTodoElementsEntities,
  (actives: number[], todoElements: Dictionary<TodoElementEntityState>) =>
    getTodoElementsFromActives(actives, todoElements)
);

function getTodoElementsFromActives(
  actives: number[],
  todoElements: Dictionary<TodoElementEntityState>
): Dictionary<TodoElementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (todoElements[idActive]) {
      acc[idActive] = todoElements[idActive];
    }
    return acc;
  }, {} as Dictionary<TodoElementEntityState>);
}

const selectAllTodoElementsSelectors: Dictionary<Selector> = {};
export function selectAllTodoElements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<TodoElement>(
      schema,
      selectAllTodoElementsSelectors,
      selectTodoElementsEntitiesDictionary,
      getRelationSelectors,
      todoElementRelations,
      hydrateAll,
      'todoElement'
    );
  } else {
    return selectAllTodoElementsObject;
  }
}

export function selectAllTodoElementsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'todoElements'
): Selector {
  return createSelector(selectAllTodoElements(schema), result => {
    const res = { [customKey]: {} as Dictionary<TodoElementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.todoElements.length; i++) {
      res[customKey][result.todoElements[i].idTodoElement] = result.todoElements[i];
    }
    return res;
  });
}

export function selectOneTodoElement(schema: SelectSchema = {}, idTodoElement: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneTodoElementDictionary(idTodoElement)];
    selectors.push(...getRelationSelectors(schema, todoElementRelations, 'todoElement'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneTodoElementDictionaryWithoutChild(idTodoElement);
  }
}

export function selectActiveTodoElements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveTodoElementsEntities, todoElements => ({
      todoElements
    }))
  ];
  selectors.push(...getRelationSelectors(schema, todoElementRelations, 'todoElement'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  todoElements: Dictionary<TodoElementEntityState>;
  todos?: Dictionary<TodoEntityState>;
  elements?: Dictionary<ElementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { todoElements: (TodoElement | null)[] } {
  const { todoElements, todos, elements } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    todoElements: Object.keys(todoElements).map(idTodoElement =>
      hydrate(todoElements[idTodoElement] as TodoElementEntityState, todos, elements)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { todoElement: TodoElementEntityState | null } {
  const { todoElements, todos, elements } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const todoElement = Object.values(todoElements)[0];
  return {
    todoElement: hydrate(todoElement as TodoElementEntityState, todos, elements)
  };
}

function hydrate(
  todoElement: TodoElementEntityState,
  todoEntities?: Dictionary<TodoEntityState>,
  elementEntities?: Dictionary<ElementEntityState>
): TodoElement | null {
  if (!todoElement) {
    return null;
  }

  const todoElementHydrated: TodoElementEntityState = { ...todoElement };
  if (todoEntities) {
    todoElementHydrated.todo = todoEntities[todoElement.todo as number] as Todo;
  } else {
    delete todoElementHydrated.todo;
  }
  if (elementEntities) {
    todoElementHydrated.element = elementEntities[todoElement.element as number] as Element;
  } else {
    delete todoElementHydrated.element;
  }

  return todoElementHydrated as TodoElement;
}
