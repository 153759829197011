import { Injectable } from '@angular/core';import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationCaracteristicRowApiService } from './organization-caracteristic-row-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationCaracteristicRowApiService extends GeneratedOrganizationCaracteristicRowApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
