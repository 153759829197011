import { RepositoryService } from '@wip/services/repository';
import { UserProfilRight } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserProfilRightApiService {
  constructor(protected repo: RepositoryService) {}

  public getUserProfilRights(params?: any): Observable<UserProfilRight[]> {
    return this.repo.getData<UserProfilRight[]>('user-profil-right', params);
  }

  public getUserProfilRight(params: { idUserProfilRight: number; params?: any }): Observable<UserProfilRight> {
    return this.repo.getData<UserProfilRight>('user-profil-right/' + params.idUserProfilRight, params.params);
  }

  public addUserProfilRight(userProfilRight: Partial<UserProfilRight>): Observable<UserProfilRight> {
    return this.repo.create<UserProfilRight>('user-profil-right', userProfilRight);
  }

  public updateUserProfilRight(userProfilRight: Partial<UserProfilRight>): Observable<UserProfilRight> {
    return this.repo.update('user-profil-right', userProfilRight);
  }

  public deleteUserProfilRight(idUserProfilRight: number): Observable<number> {
    return this.repo.delete('user-profil-right/' + +idUserProfilRight);
  }
}
