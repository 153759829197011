import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Community, OrganizationStep } from '@api/api-interfaces';
import { BoardTypeEnum, CommunityStatusActifEnum, CommunityTypeStructureEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CommunitySubFamilyModel,
  CommunityUserProfilModel,
  OrganizationSubFamilyModel,
  UserBoardStateModel,
  UserModel
} from '@wip/store/selectors-model';
import {
  CommunityService,
  OrganizationStepService,
  OrganizationUserService,
  UserBoardStateService
} from '@wip/store/services';
import { Observable, combineLatest, debounceTime, map, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'wip-kanban-container',
  templateUrl: './kanban-container.component.html',
  styleUrls: ['./kanban-container.component.scss']
})
export class KanbanContainerComponent implements OnInit {
  @ViewChild('scrollingWrapper') scrollingWrapper: ElementRef;

  private filters;

  public communities$: Observable<Community[]>;
  public organizationSteps$: Observable<OrganizationStep[]>;
  public idOrganizationFamily: number;
  public idOrganization: number = 0;

  constructor(
    private newCommunityService: CommunityService,
    private newOrganizationUserService: OrganizationUserService,
    private newOrganizationStepService: OrganizationStepService,
    private userBoardStateService: UserBoardStateService
  ) {}

  ngOnInit(): void {
    this.newOrganizationUserService
      .selectAllActiveOrganizationUsers()
      .pipe(
        untilDestroyed(this),
        map(ou => ou[0]),
        debounceTime(30),
        tap(ou => {
          if (ou && this.idOrganization !== ou.idOrganization) {
            this.idOrganization = ou.idOrganization;
            this.newCommunityService.getPlanningInfo(ou.idOrganization);
          }
        })
      )
      .subscribe();
    combineLatest([
      this.newOrganizationUserService
        .selectAllActiveOrganizationUsers({ include: [UserBoardStateModel] })
        .pipe(map(ou => ou[0])),
      this.userBoardStateService.selectAllUserBoardStates()
    ])
      .pipe(
        untilDestroyed(this),
        tap(([orgaUser, boardStates]) => {
          if (orgaUser && boardStates.length) {
            const generalBoardState = boardStates.find(
              bs => bs.type === BoardTypeEnum.project && bs.idOrganizationUser === orgaUser.idOrganizationUser
            );
            if (generalBoardState) {
              this.filters = JSON.parse(
                boardStates.find(
                  bs => bs.type === BoardTypeEnum.project && bs.idOrganizationUser === orgaUser.idOrganizationUser
                )?.filterState
              );
              if (this.filters?.organizationFamily) {
                this.idOrganizationFamily = this.filters.organizationFamily;
              }
            }
            this.communities$ = this.newCommunityService
              .selectAllCommunities({
                include: [
                  { model: CommunitySubFamilyModel, include: [OrganizationSubFamilyModel] },
                  { model: CommunityUserProfilModel, include: [UserModel] }
                ]
              })
              .pipe(
                untilDestroyed(this),
                map(comms =>
                  comms.filter(
                    comm =>
                      comm.idOrganization === orgaUser.idOrganization &&
                      comm.statusActif === CommunityStatusActifEnum.actif &&
                      [CommunityTypeStructureEnum.project, CommunityTypeStructureEnum.development].includes(
                        comm.typeStructure
                      )
                  )
                ),
                map(comms => {
                  if (comms.length && generalBoardState) {
                    return this.newCommunityService.filterCommunities(comms, this.filters);
                  }
                })
              );
            this.organizationSteps$ = this.newOrganizationStepService.selectAllOrganizationSteps().pipe(
              untilDestroyed(this),
              map(steps =>
                steps.filter(
                  step =>
                    step.idOrganization === orgaUser.idOrganization &&
                    step.idOrganizationFamily === this.filters.organizationFamily
                )
              )
            );
          }
        })
      )
      .subscribe();
  }
}
