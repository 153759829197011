import { UserProfilRightState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userProfilRightReducersGeneratedFunctions } from './user-profil-right-generated.reducer';

const userProfilRightReducersFunctions = [...userProfilRightReducersGeneratedFunctions];

const userProfilRightReducer = createReducer(UserProfilRightState.initialState, ...userProfilRightReducersFunctions);

export function reducer(state: UserProfilRightState.IState | undefined, action: Action) {
  return userProfilRightReducer(state, action);
}
