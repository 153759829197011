import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationProjectModule, OrganizationProjectModuleEntityState } from '@api/api-interfaces';
import { OrganizationProjectModuleRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationProjectModuleGeneratedActions = createActionGroup({
  source: 'Organization Project Module Generated',
  events: {
    'Get One Organization Project Module': props<{ idOrganizationProjectModule: number; params?: any }>(),
    'Get Many Organization Project Modules': props<{ params: any }>(),
    'Add Many Actives Organization Project Modules': props<{ idOrganizationProjectModules: number[] }>(),
    'Delete One Active Organization Project Module': props<{ idOrganizationProjectModule: number }>(),
    'Clear Actives Organization Project Modules': emptyProps(),
    'Upsert One Organization Project Module': props<{
      organizationProjectModule: Partial<OrganizationProjectModule>;
      ids?: OrganizationProjectModuleRelationsIds;
    }>(),
    'Upsert Many Organization Project Modules': props<{
      organizationProjectModules: Partial<OrganizationProjectModule>[];
      ids?: OrganizationProjectModuleRelationsIds;
    }>(),
    'Upsert Many Organization Project Modules Success': props<{
      organizationProjectModules: OrganizationProjectModuleEntityState[];
    }>(),
    'Delete One Organization Project Module': props<{ idOrganizationProjectModule: number }>(),
    'Delete One Organization Project Module Success': props<{ idOrganizationProjectModule: number }>(),
    'Normalize Many Organization Project Modules After Upsert': props<{
      organizationProjectModules: OrganizationProjectModuleEntityState[];
    }>(),
    'Organization Project Modules Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Project Modules': emptyProps(),
    'Add Organization Success': props<{ idOrganizationProjectModule: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationProjectModules: number[] }>(),
    'Add Project Module Success': props<{ idOrganizationProjectModule: number; idProjectModule: number }>(),
    'Delete Many Project Module Success': props<{
      idProjectModules: number[];
      idOrganizationProjectModules: number[];
    }>()
  }
});
