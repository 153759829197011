import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { MeetingElement, MeetingElementEntityState } from '@api/api-interfaces';
import { MeetingElementApiService } from '@wip/store/api-services';
import { MeetingElementGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeMeetingElement } from '@wip/store/configs/normalization';
import { MeetingElementSelectors } from '@wip/store/selectors';
import { MeetingElementRelationsIds } from '@wip/store/ids-interfaces';
import { MeetingGeneratedActions } from '@wip/store/actions';
import { ElementGeneratedActions } from '@wip/store/actions';

export function getDefaultAddMeetingElementActions(
  meetingElement: MeetingElementEntityState,
  ids?: MeetingElementRelationsIds
): Action[] {
  const actions: Action[] = [
    MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert({ meetingElements: [meetingElement] })
  ];

  if (ids?.meeting) {
    actions.push(
      MeetingGeneratedActions.addManyMeetingElementSuccess({
        idMeeting: ids.meeting,
        idMeetingElements: [meetingElement.idMeetingElement]
      })
    );
    actions.push(
      MeetingElementGeneratedActions.addMeetingSuccess({
        idMeetingElement: meetingElement.idMeetingElement,
        idMeeting: ids.meeting
      })
    );
  }

  if (ids?.element) {
    actions.push(
      ElementGeneratedActions.addManyMeetingElementSuccess({
        idElement: ids.element,
        idMeetingElements: [meetingElement.idMeetingElement]
      })
    );
    actions.push(
      MeetingElementGeneratedActions.addElementSuccess({
        idMeetingElement: meetingElement.idMeetingElement,
        idElement: ids.element
      })
    );
  }

  return actions;
}

export function getDefaultDeleteMeetingElementActions(meetingElement: MeetingElementEntityState): Action[] {
  const actions: Action[] = [
    MeetingElementGeneratedActions.deleteOneMeetingElementSuccess({ idMeetingElement: meetingElement.idMeetingElement })
  ];

  if (meetingElement.meeting) {
    actions.push(
      MeetingGeneratedActions.deleteManyMeetingElementSuccess({
        idMeetingElements: [meetingElement.idMeetingElement],
        idMeetings: [meetingElement.meeting as number]
      })
    );
  }

  if (meetingElement.element) {
    actions.push(
      ElementGeneratedActions.deleteManyMeetingElementSuccess({
        idMeetingElements: [meetingElement.idMeetingElement],
        idElements: [meetingElement.element as number]
      })
    );
  }

  return actions;
}

export class GeneratedMeetingElementEffects {
  constructor(
    protected actions$: Actions,
    protected meetingElementApiService: MeetingElementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyMeetingElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingElementGeneratedActions.getManyMeetingElements),
      switchMap(({ params }) =>
        this.meetingElementApiService.getMeetingElements(params).pipe(
          map((meetingElements: MeetingElement[]) => {
            return MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert({ meetingElements });
          }),
          catchError(error => of(MeetingElementGeneratedActions.meetingElementsFailure({ error })))
        )
      )
    );
  });

  getOneMeetingElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingElementGeneratedActions.getOneMeetingElement),
      switchMap(idMeetingElement =>
        this.meetingElementApiService.getMeetingElement(idMeetingElement).pipe(
          map((meetingElement: MeetingElement) => {
            return MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert({
              meetingElements: [meetingElement]
            });
          }),
          catchError(error => of(MeetingElementGeneratedActions.meetingElementsFailure({ error })))
        )
      )
    );
  });

  upsertOneMeetingElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingElementGeneratedActions.upsertOneMeetingElement),
      concatMap(
        ({ meetingElement, ids }: { meetingElement: Partial<MeetingElement>; ids?: MeetingElementRelationsIds }) => {
          if (meetingElement.idMeetingElement) {
            return this.meetingElementApiService.updateMeetingElement(meetingElement).pipe(
              map((meetingElementReturned: MeetingElement) => {
                return MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert({
                  meetingElements: [meetingElementReturned]
                });
              }),
              catchError(error => of(MeetingElementGeneratedActions.meetingElementsFailure({ error })))
            );
          } else {
            return this.meetingElementApiService.addMeetingElement(meetingElement).pipe(
              mergeMap((meetingElementReturned: MeetingElement) =>
                getDefaultAddMeetingElementActions(meetingElementReturned, ids)
              ),
              catchError(error => of(MeetingElementGeneratedActions.meetingElementsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneMeetingElement$ = createEffect(() => {
    const selectMeetingElementState$ = this.store$.select(MeetingElementSelectors.selectMeetingElementState);
    return this.actions$.pipe(
      ofType(MeetingElementGeneratedActions.deleteOneMeetingElement),
      withLatestFrom(selectMeetingElementState$),
      concatMap(([{ idMeetingElement }, state]) =>
        this.meetingElementApiService.deleteMeetingElement(idMeetingElement).pipe(
          mergeMap(_success =>
            getDefaultDeleteMeetingElementActions(state.entities[idMeetingElement] as MeetingElementEntityState)
          ),
          catchError(error => of(MeetingElementGeneratedActions.meetingElementsFailure({ error })))
        )
      )
    );
  });

  normalizeManyMeetingElementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingElementGeneratedActions.normalizeManyMeetingElementsAfterUpsert),
      concatMap(({ meetingElements }) => {
        const actions: Action[] = getActionsToNormalizeMeetingElement(meetingElements, StoreActionType.upsert);
        return [getMultiAction(actions, '[MeetingElement] Normalization After Upsert Success')];
      })
    );
  });
}
