export enum CaracteristiqueTypeEnum {
  integer = 'Entier',
  decimal = 'Décimal',
  booleen = 'Binaire',
  date = 'Date',
  string = 'Texte court',
  longText = 'Texte long',
  choix = 'Liste',
  hyperlink = 'Lien hypertexte',
  phone = 'Téléphone',
  email = 'Email',
  formula = 'Formule Simple',
  criteria = 'Formule Rep. Lots',
  epci = "Nom de l'EPCI",
  area = 'Nom de la région',
  department = 'Nom du département',
  zonageABC = 'Zonage ABC',
  zonage123 = 'Zonage 123'
}
