import { Injectable } from '@angular/core';
import { Community } from '@api/api-interfaces';
import {
  GetPlanningProjectOutput,
  InputPlanningProjectsControllerInterface
} from '@api/projects/get-planning-projects';
import { CommunityTypeStructureEnum } from '@enums';
import { RepositoryService } from '@wip/services/repository';
import { UserService } from '@wip/store/services';
import { ZonageODtoViaCodePostal } from 'libs/api/use-cases/geo/get-zonage-by-code-postal/src';
import { Observable } from 'rxjs';
import { GeneratedCommunityApiService } from './community-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityApiService extends GeneratedCommunityApiService {
  private userService: UserService;

  constructor(repo: RepositoryService, userService: UserService) {
    super(repo);
    this.userService = userService;
  }

  public getProjectsForSidelist(
    idOrganization: number,
    idUser: number,
    operationType: CommunityTypeStructureEnum
  ): Observable<Community[]> {
    return this.repo.getData<Community[]>('community/findProjectsSidelist', { idOrganization, idUser, operationType });
  }

  public getCommunitiesForSidelist(idOrganization: number, idUser: number): Observable<Community[]> {
    return this.repo.getData<Community[]>('community/findCommunitiesSidelist', { idOrganization, idUser });
  }

  public getConversationsForSidelist(idUser: number): Observable<Community[]> {
    return this.repo.getData<Community[]>('community/findChatSidelist', { idUser });
  }

  public getElementsForSynthesis(params: {
    idOrganization: number;
    idOrganizationFamily?: number;
  }): Observable<Community[]> {
    return this.repo.getData<Community[]>('community/getSynthesisElements', params);
  }

  public getPlanningInfo(idOrganization: number): Observable<Community[]> {
    return this.repo.getData<Community[]>('community/getPlanningInfo', { idOrganization });
  }

  public getProjectTemplates(idOrganization: number): Observable<Community[]> {
    return this.repo.getData<Community[]>('community/getProjectTemplates', { idOrganization });
  }

  public getPersonalSpace(idUser: number): Observable<Community> {
    return this.repo.getData<Community>('community/findPersonalSpace', { idUser });
  }

  public duplicate(idCommunitySrc: number, idCommunity: number) {
    return this.repo.update<Community>('community/duplicate', { idCommunitySrc, idCommunity });
  }

  public findGridDatas(values: InputPlanningProjectsControllerInterface): Observable<GetPlanningProjectOutput> {
    return this.repo.getData<GetPlanningProjectOutput>('community/getPlanningData', values);
  }

  public createCommunity(params: { community: Partial<Community>; modules: number[] }): Observable<Community> {
    const newParams = { ...params, idUser: this.userService.currentIdUser };
    return this.repo.create<Community>('community/createCommunity', newParams);
  }

  public createDirectMessage(params: {
    community: Partial<Community>;
    listeUsersAdd: number[];
  }): Observable<Community> {
    const newParams = { ...params, idUser: this.userService.currentIdUser };
    return this.repo.create<Community>('community/createDirectMessage', newParams);
  }

  public propagateRoles(idCommunity: number) {
    return this.repo.getData<any>('community/propagateRoles/' + idCommunity, { idCommunity });
  }

  public deleteGantt(idCommunity: number) {
    return this.repo.update<any>('community/deleteMask', { idCommunity });
  }

  public updateManyCommunities(params: { communityIds: number[]; key: string; value: any }) {
    return this.repo.update<Community[]>('community/updateManyCommunities', params);
  }

  public verifyProject(idCommunity: number) {
    return this.repo.update<any>('community/verify-project', { idCommunity });
  }

  public getZonage(codePostal: string) {
    return this.repo.getData<ZonageODtoViaCodePostal[]>(`community/zonage/${codePostal}`);
  }

  public bulkUpdateGeo(idOrganization: number, updateOnlyInseeCode: boolean) {
    return this.repo.update<Community[]>(`community/bulkUpdateGeo/${idOrganization}`, { updateOnlyInseeCode });
  }
}
