import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationMilestoneEntityState>;

export const adapter = createEntityAdapter<OrganizationMilestoneEntityState>({
  selectId: o => o.idOrganizationMilestone
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationMilestoneFeatureKey = 'organizationMilestone';
