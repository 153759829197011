<div class="margin-bottom user-select">
  <h2>Caractéristiques</h2>

  <ul class="liste user-select">
    @for (communityCaracteristic of communityCaracteristic; track communityCaracteristic) {
      <li [class.active]="communityCaracteristic.libelle === 'RP - Responsable de projet'">
        @if (communityCaracteristic?.libelle === 'RP - Responsable de projet') {
          <i class="icon icon-ic_fluent_star_24_filled"></i>
        }
        {{ communityCaracteristic.organizationCaracteristic?.libelle || communityCaracteristic?.libelle }}
        :
        <span class="bold user-select"> &nbsp; {{ communityCaracteristic?.valeur }}</span>
        @if (!communityCaracteristic.valeur) {
          <div>
            @if (canEdit) {
              <div class="cursor-pointer" (click)="addCaracteristics()" [matTooltip]="'Compléter caractéristiques'">
                <i class="icon icon-ic_fluent_arrow_circle_right_24_filled go-team"></i>
              </div>
            }
          </div>
        }
      </li>
    }
  </ul>
</div>
