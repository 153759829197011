import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedNotesHistoryApiService } from './notes-history-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class NotesHistoryApiService extends GeneratedNotesHistoryApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}

