import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Profil, ProfilEntityState } from '@api/api-interfaces';
import { RightProfil, RightProfilEntityState } from '@api/api-interfaces';
import { UserRight, UserRightEntityState } from '@api/api-interfaces';
import { UserProfilRight, UserProfilRightEntityState } from '@api/api-interfaces';
import { Right, RightEntityState } from '@api/api-interfaces';
import { OrganizationUserProfil, OrganizationUserProfilEntityState } from '@api/api-interfaces';
import { OrganizationUser, OrganizationUserEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { ProfilState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const profilRelations: string[] = [
  'rightProfils',
  'userRights',
  'userProfilRights',
  'rights',
  'organizationUserProfils',
  'organizationUsers',
  'organizations'
];

export const { selectEntities, selectAll } = ProfilState.adapter.getSelectors();

export const selectProfilState = createFeatureSelector<ProfilState.IState>(ProfilState.profilFeatureKey);

export const selectIsLoadedProfil = createSelector(selectProfilState, (state: ProfilState.IState) => state.isLoaded);

export const selectIsLoadingProfil = createSelector(selectProfilState, (state: ProfilState.IState) => state.isLoading);

export const selectIsReadyProfil = createSelector(selectProfilState, (state: ProfilState.IState) => !state.isLoading);

export const selectIsReadyAndLoadedProfil = createSelector(
  selectProfilState,
  (state: ProfilState.IState) => state.isLoaded && !state.isLoading
);

export const selectProfilsEntities = createSelector(selectProfilState, selectEntities);

export const selectProfilsArray = createSelector(selectProfilState, selectAll);

export const selectIdProfilsActive = createSelector(selectProfilState, (state: ProfilState.IState) => state.actives);

const profilsInObject = (profils: Dictionary<ProfilEntityState>) => ({ profils });

const selectProfilsEntitiesDictionary = createSelector(selectProfilsEntities, profilsInObject);

const selectAllProfilsObject = createSelector(selectProfilsEntities, profils => {
  return hydrateAll({ profils });
});

const selectOneProfilDictionary = (idProfil: number) =>
  createSelector(selectProfilsEntities, profils => ({
    profils: { [idProfil]: profils[idProfil] }
  }));

const selectOneProfilDictionaryWithoutChild = (idProfil: number) =>
  createSelector(selectProfilsEntities, profils => ({
    profil: profils[idProfil]
  }));

const selectActiveProfilsEntities = createSelector(
  selectIdProfilsActive,
  selectProfilsEntities,
  (actives: number[], profils: Dictionary<ProfilEntityState>) => getProfilsFromActives(actives, profils)
);

function getProfilsFromActives(
  actives: number[],
  profils: Dictionary<ProfilEntityState>
): Dictionary<ProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (profils[idActive]) {
      acc[idActive] = profils[idActive];
    }
    return acc;
  }, {} as Dictionary<ProfilEntityState>);
}

const selectAllProfilsSelectors: Dictionary<Selector> = {};
export function selectAllProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Profil>(
      schema,
      selectAllProfilsSelectors,
      selectProfilsEntitiesDictionary,
      getRelationSelectors,
      profilRelations,
      hydrateAll,
      'profil'
    );
  } else {
    return selectAllProfilsObject;
  }
}

export function selectAllProfilsDictionary(schema: SelectSchema = {}, customKey: string = 'profils'): Selector {
  return createSelector(selectAllProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.profils.length; i++) {
      res[customKey][result.profils[i].idProfil] = result.profils[i];
    }
    return res;
  });
}

export function selectOneProfil(schema: SelectSchema = {}, idProfil: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneProfilDictionary(idProfil)];
    selectors.push(...getRelationSelectors(schema, profilRelations, 'profil'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProfilDictionaryWithoutChild(idProfil);
  }
}

export function selectActiveProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveProfilsEntities, profils => ({
      profils
    }))
  ];
  selectors.push(...getRelationSelectors(schema, profilRelations, 'profil'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  profils: Dictionary<ProfilEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  rightProfils?: Dictionary<RightProfilEntityState>;
  userRights?: Dictionary<UserRightEntityState>;
  userProfilRights?: Dictionary<UserProfilRightEntityState>;
  rights?: Dictionary<RightEntityState>;
  organizationUserProfils?: Dictionary<OrganizationUserProfilEntityState>;
  organizationUsers?: Dictionary<OrganizationUserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { profils: (Profil | null)[] } {
  const {
    profils,
    organizations,
    rightProfils,
    userRights,
    userProfilRights,
    rights,
    organizationUserProfils,
    organizationUsers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    profils: Object.keys(profils).map(idProfil =>
      hydrate(
        profils[idProfil] as ProfilEntityState,
        organizations,
        rightProfils,
        userRights,
        userProfilRights,
        rights,
        organizationUserProfils,
        organizationUsers
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { profil: ProfilEntityState | null } {
  const {
    profils,
    organizations,
    rightProfils,
    userRights,
    userProfilRights,
    rights,
    organizationUserProfils,
    organizationUsers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const profil = Object.values(profils)[0];
  return {
    profil: hydrate(
      profil as ProfilEntityState,
      organizations,
      rightProfils,
      userRights,
      userProfilRights,
      rights,
      organizationUserProfils,
      organizationUsers
    )
  };
}

function hydrate(
  profil: ProfilEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  rightProfilEntities?: Dictionary<RightProfilEntityState>,
  userRightEntities?: Dictionary<UserRightEntityState>,
  userProfilRightEntities?: Dictionary<UserProfilRightEntityState>,
  rightEntities?: Dictionary<RightEntityState>,
  organizationUserProfilEntities?: Dictionary<OrganizationUserProfilEntityState>,
  organizationUserEntities?: Dictionary<OrganizationUserEntityState>
): Profil | null {
  if (!profil) {
    return null;
  }

  const profilHydrated: ProfilEntityState = { ...profil };
  if (organizationEntities) {
    profilHydrated.organization = organizationEntities[profil.organization as number] as Organization;
  } else {
    delete profilHydrated.organization;
  }

  if (rightProfilEntities) {
    profilHydrated.rightProfils = ((profilHydrated.rightProfils as number[]) || []).map(
      id => rightProfilEntities[id]
    ) as RightProfil[];
  } else {
    delete profilHydrated.rightProfils;
  }

  if (userRightEntities) {
    profilHydrated.userRights = ((profilHydrated.userRights as number[]) || []).map(
      id => userRightEntities[id]
    ) as UserRight[];
  } else {
    delete profilHydrated.userRights;
  }

  if (userProfilRightEntities) {
    profilHydrated.userProfilRights = ((profilHydrated.userProfilRights as number[]) || []).map(
      id => userProfilRightEntities[id]
    ) as UserProfilRight[];
  } else {
    delete profilHydrated.userProfilRights;
  }

  if (rightEntities) {
    profilHydrated.rights = ((profilHydrated.rights as number[]) || []).map(id => rightEntities[id]) as Right[];
  } else {
    delete profilHydrated.rights;
  }

  if (organizationUserProfilEntities) {
    profilHydrated.organizationUserProfils = ((profilHydrated.organizationUserProfils as number[]) || []).map(
      id => organizationUserProfilEntities[id]
    ) as OrganizationUserProfil[];
  } else {
    delete profilHydrated.organizationUserProfils;
  }

  if (organizationUserEntities) {
    profilHydrated.organizationUsers = ((profilHydrated.organizationUsers as number[]) || []).map(
      id => organizationUserEntities[id]
    ) as OrganizationUser[];
  } else {
    delete profilHydrated.organizationUsers;
  }

  return profilHydrated as Profil;
}
