import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Meeting, MeetingEntityState } from '@api/api-interfaces';
import { MeetingRelationsIds } from '@wip/store/ids-interfaces';

export const MeetingGeneratedActions = createActionGroup({
  source: 'Meeting Generated',
  events: {
    'Get One Meeting': props<{ idMeeting: number; params?: any }>(),
    'Get Many Meetings': props<{ params: any }>(),
    'Add Many Actives Meetings': props<{ idMeetings: number[] }>(),
    'Delete One Active Meeting': props<{ idMeeting: number }>(),
    'Clear Actives Meetings': emptyProps(),
    'Upsert One Meeting': props<{ meeting: Partial<Meeting>; ids?: MeetingRelationsIds }>(),
    'Upsert Many Meetings': props<{ meetings: Partial<Meeting>[]; ids?: MeetingRelationsIds }>(),
    'Upsert Many Meetings Success': props<{ meetings: MeetingEntityState[] }>(),
    'Delete One Meeting': props<{ idMeeting: number }>(),
    'Delete One Meeting Success': props<{ idMeeting: number }>(),
    'Normalize Many Meetings After Upsert': props<{ meetings: MeetingEntityState[] }>(),
    'Meetings Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Meetings': emptyProps(),
    'Add Many Meeting Element Success': props<{ idMeeting: number; idMeetingElements: number[] }>(),
    'Delete Many Meeting Element Success': props<{ idMeetingElements: number[]; idMeetings: number[] }>(),
    'Add Many Meeting User Success': props<{ idMeeting: number; idMeetingUsers: number[] }>(),
    'Delete Many Meeting User Success': props<{ idMeetingUsers: number[]; idMeetings: number[] }>(),
    'Add Community Success': props<{ idMeeting: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idMeetings: number[] }>()
  }
});
