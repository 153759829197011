import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationCaracteristicColumnApiService } from '@wip/store/api-services';
import { GeneratedOrganizationCaracteristicColumnEffects } from './organization-caracteristic-column-generated.effects';

@Injectable()
export class OrganizationCaracteristicColumnEffects extends GeneratedOrganizationCaracteristicColumnEffects {
  constructor(actions$: Actions, organizationCaracteristicColumnApiService: OrganizationCaracteristicColumnApiService, store$: Store<AppState>) {
    super(actions$, organizationCaracteristicColumnApiService, store$);
  }
}
