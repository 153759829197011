import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationProjectModule, OrganizationProjectModuleEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { ProjectModule, ProjectModuleEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationProjectModuleState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationProjectModuleRelations: string[] = ['organizations', 'projectModules'];

export const { selectEntities, selectAll } = OrganizationProjectModuleState.adapter.getSelectors();

export const selectOrganizationProjectModuleState = createFeatureSelector<OrganizationProjectModuleState.IState>(
  OrganizationProjectModuleState.organizationProjectModuleFeatureKey
);

export const selectIsLoadedOrganizationProjectModule = createSelector(
  selectOrganizationProjectModuleState,
  (state: OrganizationProjectModuleState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationProjectModule = createSelector(
  selectOrganizationProjectModuleState,
  (state: OrganizationProjectModuleState.IState) => state.isLoading
);

export const selectIsReadyOrganizationProjectModule = createSelector(
  selectOrganizationProjectModuleState,
  (state: OrganizationProjectModuleState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationProjectModule = createSelector(
  selectOrganizationProjectModuleState,
  (state: OrganizationProjectModuleState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationProjectModulesEntities = createSelector(
  selectOrganizationProjectModuleState,
  selectEntities
);

export const selectOrganizationProjectModulesArray = createSelector(selectOrganizationProjectModuleState, selectAll);

export const selectIdOrganizationProjectModulesActive = createSelector(
  selectOrganizationProjectModuleState,
  (state: OrganizationProjectModuleState.IState) => state.actives
);

const organizationProjectModulesInObject = (
  organizationProjectModules: Dictionary<OrganizationProjectModuleEntityState>
) => ({ organizationProjectModules });

const selectOrganizationProjectModulesEntitiesDictionary = createSelector(
  selectOrganizationProjectModulesEntities,
  organizationProjectModulesInObject
);

const selectAllOrganizationProjectModulesObject = createSelector(
  selectOrganizationProjectModulesEntities,
  organizationProjectModules => {
    return hydrateAll({ organizationProjectModules });
  }
);

const selectOneOrganizationProjectModuleDictionary = (idOrganizationProjectModule: number) =>
  createSelector(selectOrganizationProjectModulesEntities, organizationProjectModules => ({
    organizationProjectModules: {
      [idOrganizationProjectModule]: organizationProjectModules[idOrganizationProjectModule]
    }
  }));

const selectOneOrganizationProjectModuleDictionaryWithoutChild = (idOrganizationProjectModule: number) =>
  createSelector(selectOrganizationProjectModulesEntities, organizationProjectModules => ({
    organizationProjectModule: organizationProjectModules[idOrganizationProjectModule]
  }));

const selectActiveOrganizationProjectModulesEntities = createSelector(
  selectIdOrganizationProjectModulesActive,
  selectOrganizationProjectModulesEntities,
  (actives: number[], organizationProjectModules: Dictionary<OrganizationProjectModuleEntityState>) =>
    getOrganizationProjectModulesFromActives(actives, organizationProjectModules)
);

function getOrganizationProjectModulesFromActives(
  actives: number[],
  organizationProjectModules: Dictionary<OrganizationProjectModuleEntityState>
): Dictionary<OrganizationProjectModuleEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationProjectModules[idActive]) {
      acc[idActive] = organizationProjectModules[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationProjectModuleEntityState>);
}

const selectAllOrganizationProjectModulesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationProjectModules(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationProjectModule>(
      schema,
      selectAllOrganizationProjectModulesSelectors,
      selectOrganizationProjectModulesEntitiesDictionary,
      getRelationSelectors,
      organizationProjectModuleRelations,
      hydrateAll,
      'organizationProjectModule'
    );
  } else {
    return selectAllOrganizationProjectModulesObject;
  }
}

export function selectAllOrganizationProjectModulesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationProjectModules'
): Selector {
  return createSelector(selectAllOrganizationProjectModules(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationProjectModuleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationProjectModules.length; i++) {
      res[customKey][result.organizationProjectModules[i].idOrganizationProjectModule] =
        result.organizationProjectModules[i];
    }
    return res;
  });
}

export function selectOneOrganizationProjectModule(
  schema: SelectSchema = {},
  idOrganizationProjectModule: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationProjectModuleDictionary(idOrganizationProjectModule)];
    selectors.push(...getRelationSelectors(schema, organizationProjectModuleRelations, 'organizationProjectModule'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationProjectModuleDictionaryWithoutChild(idOrganizationProjectModule);
  }
}

export function selectActiveOrganizationProjectModules(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationProjectModulesEntities, organizationProjectModules => ({
      organizationProjectModules
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationProjectModuleRelations, 'organizationProjectModule'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationProjectModules: Dictionary<OrganizationProjectModuleEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  projectModules?: Dictionary<ProjectModuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationProjectModules: (OrganizationProjectModule | null)[];
} {
  const { organizationProjectModules, organizations, projectModules } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationProjectModules: Object.keys(organizationProjectModules).map(idOrganizationProjectModule =>
      hydrate(
        organizationProjectModules[idOrganizationProjectModule] as OrganizationProjectModuleEntityState,
        organizations,
        projectModules
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationProjectModule: OrganizationProjectModuleEntityState | null;
} {
  const { organizationProjectModules, organizations, projectModules } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationProjectModule = Object.values(organizationProjectModules)[0];
  return {
    organizationProjectModule: hydrate(
      organizationProjectModule as OrganizationProjectModuleEntityState,
      organizations,
      projectModules
    )
  };
}

function hydrate(
  organizationProjectModule: OrganizationProjectModuleEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  projectModuleEntities?: Dictionary<ProjectModuleEntityState>
): OrganizationProjectModule | null {
  if (!organizationProjectModule) {
    return null;
  }

  const organizationProjectModuleHydrated: OrganizationProjectModuleEntityState = { ...organizationProjectModule };
  if (organizationEntities) {
    organizationProjectModuleHydrated.organization = organizationEntities[
      organizationProjectModule.organization as number
    ] as Organization;
  } else {
    delete organizationProjectModuleHydrated.organization;
  }
  if (projectModuleEntities) {
    organizationProjectModuleHydrated.projectModule = projectModuleEntities[
      organizationProjectModule.projectModule as number
    ] as ProjectModule;
  } else {
    delete organizationProjectModuleHydrated.projectModule;
  }

  return organizationProjectModuleHydrated as OrganizationProjectModule;
}
