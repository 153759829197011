import { RepositoryService } from '@wip/services/repository';
import { TimelineElement } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedTimelineElementApiService {
  constructor(protected repo: RepositoryService) {}

  public getTimelineElements(params?: any): Observable<TimelineElement[]> {
    return this.repo.getData<TimelineElement[]>('timeline-element', params);
  }

  public getTimelineElement(params: { idTimelineElement: number; params?: any }): Observable<TimelineElement> {
    return this.repo.getData<TimelineElement>('timeline-element/' + params.idTimelineElement, params.params);
  }

  public addTimelineElement(timelineElement: Partial<TimelineElement>): Observable<TimelineElement> {
    return this.repo.create<TimelineElement>('timeline-element', timelineElement);
  }

  public updateTimelineElement(timelineElement: Partial<TimelineElement>): Observable<TimelineElement> {
    return this.repo.update('timeline-element', timelineElement);
  }

  public deleteTimelineElement(idTimelineElement: number): Observable<number> {
    return this.repo.delete('timeline-element/' + +idTimelineElement);
  }
}
