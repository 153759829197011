import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ElementLibrary } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { ElementLibraryRelationsIds } from '@wip/store/ids-interfaces';
import { ElementLibraryGeneratedActions } from '@wip/store/actions';
import { ElementLibrarySelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedElementLibraryService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ElementLibrarySelectors.selectIsLoadedElementLibrary));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ElementLibrarySelectors.selectIsLoadingElementLibrary));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ElementLibrarySelectors.selectIsReadyAndLoadedElementLibrary as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllElementLibraries(schema: SelectSchema = {}): Observable<ElementLibrary[]> {
    return this.store$.pipe(select(ElementLibrarySelectors.selectAllElementLibraries(schema))).pipe(
      switchMap(({ elementLibraries }: { elementLibraries: ElementLibrary[] }) => {
        return this.getReady(schema).pipe(mapTo(elementLibraries));
      })
    );
  }

  public selectOneElementLibrary(idElementLibrary: number, schema: SelectSchema = {}): Observable<ElementLibrary> {
    return this.store$.pipe(select(ElementLibrarySelectors.selectOneElementLibrary(schema, idElementLibrary))).pipe(
      switchMap(({ elementLibrary }: { elementLibrary: ElementLibrary }) => {
        return this.getReady(schema).pipe(mapTo(elementLibrary));
      })
    );
  }

  public selectAllActiveElementLibraries(schema: SelectSchema = {}): Observable<ElementLibrary[]> {
    return this.store$.pipe(select(ElementLibrarySelectors.selectActiveElementLibraries(schema))).pipe(
      switchMap(({ elementLibraries }: { elementLibraries: ElementLibrary[] }) => {
        return this.getReady(schema).pipe(mapTo(elementLibraries));
      })
    );
  }

  public selectIdElementLibrariesActive(): Observable<number[]> {
    return this.store$.pipe(select(ElementLibrarySelectors.selectIdElementLibrariesActive)).pipe(
      switchMap((idElementLibraries: number[]) => {
        return this.getReady().pipe(mapTo(idElementLibraries));
      })
    );
  }

  public getOneElementLibrary(
    idElementLibrary: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<ElementLibrary> {
    this.store$.dispatch(ElementLibraryGeneratedActions.getOneElementLibrary({ idElementLibrary, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert,
        ElementLibraryGeneratedActions.elementLibrariesFailure,
        true
      );
    }
  }

  public getManyElementLibraries(params: any = {}, getResult?: boolean): void | Observable<ElementLibrary[]> {
    this.store$.dispatch(ElementLibraryGeneratedActions.getManyElementLibraries({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert,
        ElementLibraryGeneratedActions.elementLibrariesFailure
      );
    }
  }

  public upsertOneElementLibrary(
    elementLibrary: Partial<ElementLibrary>,
    ids: ElementLibraryRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ElementLibrary> {
    this.store$.dispatch(ElementLibraryGeneratedActions.upsertOneElementLibrary({ elementLibrary, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementLibraryGeneratedActions.normalizeManyElementLibrariesAfterUpsert,
        ElementLibraryGeneratedActions.elementLibrariesFailure,
        true
      );
    }
  }

  public deleteOneElementLibrary(idElementLibrary: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ElementLibraryGeneratedActions.deleteOneElementLibrary({ idElementLibrary }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementLibraryGeneratedActions.deleteOneElementLibrarySuccess,
        ElementLibraryGeneratedActions.elementLibrariesFailure
      );
    }
  }

  public setActiveElementLibraries(idElementLibraries: number[]): void {
    this.store$.dispatch(ElementLibraryGeneratedActions.clearActivesElementLibraries());
    this.store$.dispatch(ElementLibraryGeneratedActions.addManyActivesElementLibraries({ idElementLibraries }));
  }
}
