import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Section, SectionEntityState } from '@api/api-interfaces';
import { SectionApiService } from '@wip/store/api-services';
import { SectionGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeSection } from '@wip/store/configs/normalization';
import { SectionSelectors } from '@wip/store/selectors';
import { SectionRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationSectionGeneratedActions } from '@wip/store/actions';
import { OrganizationSection } from '@api/api-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { Organization } from '@api/api-interfaces';

export function getDefaultAddSectionActions(section: SectionEntityState, ids?: SectionRelationsIds): Action[] {
  const actions: Action[] = [SectionGeneratedActions.normalizeManySectionsAfterUpsert({ sections: [section] })];

  if (ids?.organizationSections) {
    if (!Array.isArray(ids.organizationSections)) {
      actions.push(
        OrganizationSectionGeneratedActions.upsertOneOrganizationSection({
          organizationSection: {
            idSection: section.idSection,
            idOrganizationSection: ids.organizationSections as number
          } as OrganizationSection
        })
      );
      actions.push(
        SectionGeneratedActions.addManyOrganizationSectionSuccess({
          idSection: section.idSection,
          idOrganizationSections: [ids.organizationSections as number]
        })
      );
    } else {
      actions.push(
        OrganizationSectionGeneratedActions.upsertManyOrganizationSections({
          organizationSections: (ids.organizationSections as number[]).map((idOrganizationSection: number) => ({
            idSection: section.idSection,
            idOrganizationSection
          })) as OrganizationSection[]
        })
      );
      actions.push(
        SectionGeneratedActions.addManyOrganizationSectionSuccess({
          idSection: section.idSection,
          idOrganizationSections: ids.organizationSections as number[]
        })
      );
    }
  }

  if (ids?.organizations) {
    if (!Array.isArray(ids.organizations)) {
      actions.push(
        OrganizationGeneratedActions.upsertOneOrganization({
          organization: {
            idSection: section.idSection,
            idOrganization: ids.organizations as number
          } as Organization & any
        })
      );
      actions.push(
        SectionGeneratedActions.addManyOrganizationSuccess({
          idSection: section.idSection,
          idOrganizations: [ids.organizations as number]
        })
      );
    } else {
      actions.push(
        OrganizationGeneratedActions.upsertManyOrganizations({
          organizations: (ids.organizations as number[]).map((idOrganization: number) => ({
            idSection: section.idSection,
            idOrganization
          })) as Organization[] & any[]
        })
      );
      actions.push(
        SectionGeneratedActions.addManyOrganizationSuccess({
          idSection: section.idSection,
          idOrganizations: ids.organizations as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSectionActions(section: SectionEntityState): Action[] {
  const actions: Action[] = [SectionGeneratedActions.deleteOneSectionSuccess({ idSection: section.idSection })];

  if (section.organizationSections) {
    actions.push(
      OrganizationSectionGeneratedActions.deleteManySectionSuccess({
        idSections: [section.idSection],
        idOrganizationSections: section.organizationSections as number[]
      })
    );
  }

  if (section.organizations) {
    actions.push(
      OrganizationGeneratedActions.deleteManySectionSuccess({
        idSections: [section.idSection],
        idOrganizations: section.organizations as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSectionEffects {
  constructor(
    protected actions$: Actions,
    protected sectionApiService: SectionApiService,
    protected store$: Store<AppState>
  ) {}

  getManySections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SectionGeneratedActions.getManySections),
      switchMap(({ params }) =>
        this.sectionApiService.getSections(params).pipe(
          map((sections: Section[]) => {
            return SectionGeneratedActions.normalizeManySectionsAfterUpsert({ sections });
          }),
          catchError(error => of(SectionGeneratedActions.sectionsFailure({ error })))
        )
      )
    );
  });

  getOneSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SectionGeneratedActions.getOneSection),
      switchMap(idSection =>
        this.sectionApiService.getSection(idSection).pipe(
          map((section: Section) => {
            return SectionGeneratedActions.normalizeManySectionsAfterUpsert({ sections: [section] });
          }),
          catchError(error => of(SectionGeneratedActions.sectionsFailure({ error })))
        )
      )
    );
  });

  upsertOneSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SectionGeneratedActions.upsertOneSection),
      concatMap(({ section, ids }: { section: Partial<Section>; ids?: SectionRelationsIds }) => {
        if (section.idSection) {
          return this.sectionApiService.updateSection(section).pipe(
            map((sectionReturned: Section) => {
              return SectionGeneratedActions.normalizeManySectionsAfterUpsert({ sections: [sectionReturned] });
            }),
            catchError(error => of(SectionGeneratedActions.sectionsFailure({ error })))
          );
        } else {
          return this.sectionApiService.addSection(section).pipe(
            mergeMap((sectionReturned: Section) => getDefaultAddSectionActions(sectionReturned, ids)),
            catchError(error => of(SectionGeneratedActions.sectionsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneSection$ = createEffect(() => {
    const selectSectionState$ = this.store$.select(SectionSelectors.selectSectionState);
    return this.actions$.pipe(
      ofType(SectionGeneratedActions.deleteOneSection),
      withLatestFrom(selectSectionState$),
      concatMap(([{ idSection }, state]) =>
        this.sectionApiService.deleteSection(idSection).pipe(
          mergeMap(_success => getDefaultDeleteSectionActions(state.entities[idSection] as SectionEntityState)),
          catchError(error => of(SectionGeneratedActions.sectionsFailure({ error })))
        )
      )
    );
  });

  normalizeManySectionsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SectionGeneratedActions.normalizeManySectionsAfterUpsert),
      concatMap(({ sections }) => {
        const actions: Action[] = getActionsToNormalizeSection(sections, StoreActionType.upsert);
        return [getMultiAction(actions, '[Section] Normalization After Upsert Success')];
      })
    );
  });
}
