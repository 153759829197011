import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneApiService } from '@wip/store/api-services';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationMilestone } from '@wip/store/configs/normalization';
import { OrganizationMilestoneSelectors } from '@wip/store/selectors';
import { OrganizationMilestoneRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityMilestoneGeneratedActions } from '@wip/store/actions';
import { CommunityMilestone } from '@api/api-interfaces';
import { OrganizationMilestoneAssociationGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneAssociation } from '@api/api-interfaces';
import { ElementGeneratedActions } from '@wip/store/actions';
import { Element } from '@api/api-interfaces';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';

export function getDefaultAddOrganizationMilestoneActions(
  organizationMilestone: OrganizationMilestoneEntityState,
  ids?: OrganizationMilestoneRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationMilestoneGeneratedActions.normalizeManyOrganizationMilestonesAfterUpsert({
      organizationMilestones: [organizationMilestone]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationMilestoneSuccess({
        idOrganization: ids.organization,
        idOrganizationMilestones: [organizationMilestone.idOrganizationMilestone]
      })
    );
    actions.push(
      OrganizationMilestoneGeneratedActions.addOrganizationSuccess({
        idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyOrganizationMilestoneSuccess({
        idOrganizationFamily: ids.organizationFamily,
        idOrganizationMilestones: [organizationMilestone.idOrganizationMilestone]
      })
    );
    actions.push(
      OrganizationMilestoneGeneratedActions.addOrganizationFamilySuccess({
        idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.communityMilestones) {
    if (!Array.isArray(ids.communityMilestones)) {
      actions.push(
        CommunityMilestoneGeneratedActions.upsertOneCommunityMilestone({
          communityMilestone: {
            idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
            idCommunityMilestone: ids.communityMilestones as number
          } as CommunityMilestone
        })
      );
      actions.push(
        OrganizationMilestoneGeneratedActions.addManyCommunityMilestoneSuccess({
          idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
          idCommunityMilestones: [ids.communityMilestones as number]
        })
      );
    } else {
      actions.push(
        CommunityMilestoneGeneratedActions.upsertManyCommunityMilestones({
          communityMilestones: (ids.communityMilestones as number[]).map((idCommunityMilestone: number) => ({
            idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
            idCommunityMilestone
          })) as CommunityMilestone[]
        })
      );
      actions.push(
        OrganizationMilestoneGeneratedActions.addManyCommunityMilestoneSuccess({
          idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
          idCommunityMilestones: ids.communityMilestones as number[]
        })
      );
    }
  }

  if (ids?.organizationMilestoneAssociations) {
    if (!Array.isArray(ids.organizationMilestoneAssociations)) {
      actions.push(
        OrganizationMilestoneAssociationGeneratedActions.upsertOneOrganizationMilestoneAssociation({
          organizationMilestoneAssociation: {
            idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
            idOrganizationMilestoneAssociation: ids.organizationMilestoneAssociations as number
          } as OrganizationMilestoneAssociation
        })
      );
      actions.push(
        OrganizationMilestoneGeneratedActions.addManyOrganizationMilestoneAssociationSuccess({
          idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
          idOrganizationMilestoneAssociations: [ids.organizationMilestoneAssociations as number]
        })
      );
    } else {
      actions.push(
        OrganizationMilestoneAssociationGeneratedActions.upsertManyOrganizationMilestoneAssociations({
          organizationMilestoneAssociations: (ids.organizationMilestoneAssociations as number[]).map(
            (idOrganizationMilestoneAssociation: number) => ({
              idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
              idOrganizationMilestoneAssociation
            })
          ) as OrganizationMilestoneAssociation[]
        })
      );
      actions.push(
        OrganizationMilestoneGeneratedActions.addManyOrganizationMilestoneAssociationSuccess({
          idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
          idOrganizationMilestoneAssociations: ids.organizationMilestoneAssociations as number[]
        })
      );
    }
  }

  if (ids?.elements) {
    if (!Array.isArray(ids.elements)) {
      actions.push(
        ElementGeneratedActions.upsertOneElement({
          element: {
            idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
            idElement: ids.elements as number
          } as Element
        })
      );
      actions.push(
        OrganizationMilestoneGeneratedActions.addManyElementSuccess({
          idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
          idElements: [ids.elements as number]
        })
      );
    } else {
      actions.push(
        ElementGeneratedActions.upsertManyElements({
          elements: (ids.elements as number[]).map((idElement: number) => ({
            idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
            idElement
          })) as Element[]
        })
      );
      actions.push(
        OrganizationMilestoneGeneratedActions.addManyElementSuccess({
          idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
          idElements: ids.elements as number[]
        })
      );
    }
  }

  if (ids?.organizationMilestoneFamilys) {
    if (!Array.isArray(ids.organizationMilestoneFamilys)) {
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.upsertOneOrganizationMilestoneFamily({
          organizationMilestoneFamily: {
            idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
            idOrganizationMilestoneFamily: ids.organizationMilestoneFamilys as number
          } as OrganizationMilestoneFamily & any
        })
      );
      actions.push(
        OrganizationMilestoneGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
          idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
          idOrganizationMilestoneFamilys: [ids.organizationMilestoneFamilys as number]
        })
      );
    } else {
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.upsertManyOrganizationMilestoneFamilys({
          organizationMilestoneFamilys: (ids.organizationMilestoneFamilys as number[]).map(
            (idOrganizationMilestoneFamily: number) => ({
              idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
              idOrganizationMilestoneFamily
            })
          ) as OrganizationMilestoneFamily[] & any[]
        })
      );
      actions.push(
        OrganizationMilestoneGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
          idOrganizationMilestone: organizationMilestone.idOrganizationMilestone,
          idOrganizationMilestoneFamilys: ids.organizationMilestoneFamilys as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationMilestoneActions(
  organizationMilestone: OrganizationMilestoneEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationMilestoneGeneratedActions.deleteOneOrganizationMilestoneSuccess({
      idOrganizationMilestone: organizationMilestone.idOrganizationMilestone
    })
  ];

  if (organizationMilestone.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationMilestoneSuccess({
        idOrganizationMilestones: [organizationMilestone.idOrganizationMilestone],
        idOrganizations: [organizationMilestone.organization as number]
      })
    );
  }

  if (organizationMilestone.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationMilestoneSuccess({
        idOrganizationMilestones: [organizationMilestone.idOrganizationMilestone],
        idOrganizationFamilys: [organizationMilestone.organizationFamily as number]
      })
    );
  }

  if (organizationMilestone.communityMilestones) {
    actions.push(
      CommunityMilestoneGeneratedActions.deleteManyOrganizationMilestoneSuccess({
        idOrganizationMilestones: [organizationMilestone.idOrganizationMilestone],
        idCommunityMilestones: organizationMilestone.communityMilestones as number[]
      })
    );
  }

  if (organizationMilestone.organizationMilestoneAssociations) {
    actions.push(
      OrganizationMilestoneAssociationGeneratedActions.deleteManyOrganizationMilestoneSuccess({
        idOrganizationMilestones: [organizationMilestone.idOrganizationMilestone],
        idOrganizationMilestoneAssociations: organizationMilestone.organizationMilestoneAssociations as number[]
      })
    );
  }

  if (organizationMilestone.elements) {
    actions.push(
      ElementGeneratedActions.deleteManyOrganizationMilestoneSuccess({
        idOrganizationMilestones: [organizationMilestone.idOrganizationMilestone],
        idElements: organizationMilestone.elements as number[]
      })
    );
  }

  if (organizationMilestone.organizationMilestoneFamilys) {
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationMilestoneSuccess({
        idOrganizationMilestones: [organizationMilestone.idOrganizationMilestone],
        idOrganizationMilestoneFamilys: organizationMilestone.organizationMilestoneFamilys as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationMilestoneEffects {
  constructor(
    protected actions$: Actions,
    protected organizationMilestoneApiService: OrganizationMilestoneApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationMilestones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneGeneratedActions.getManyOrganizationMilestones),
      switchMap(({ params }) =>
        this.organizationMilestoneApiService.getOrganizationMilestones(params).pipe(
          map((organizationMilestones: OrganizationMilestone[]) => {
            return OrganizationMilestoneGeneratedActions.normalizeManyOrganizationMilestonesAfterUpsert({
              organizationMilestones
            });
          }),
          catchError(error => of(OrganizationMilestoneGeneratedActions.organizationMilestonesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationMilestone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneGeneratedActions.getOneOrganizationMilestone),
      switchMap(idOrganizationMilestone =>
        this.organizationMilestoneApiService.getOrganizationMilestone(idOrganizationMilestone).pipe(
          map((organizationMilestone: OrganizationMilestone) => {
            return OrganizationMilestoneGeneratedActions.normalizeManyOrganizationMilestonesAfterUpsert({
              organizationMilestones: [organizationMilestone]
            });
          }),
          catchError(error => of(OrganizationMilestoneGeneratedActions.organizationMilestonesFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationMilestone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneGeneratedActions.upsertOneOrganizationMilestone),
      concatMap(
        ({
          organizationMilestone,
          ids
        }: {
          organizationMilestone: Partial<OrganizationMilestone>;
          ids?: OrganizationMilestoneRelationsIds;
        }) => {
          if (organizationMilestone.idOrganizationMilestone) {
            return this.organizationMilestoneApiService.updateOrganizationMilestone(organizationMilestone).pipe(
              map((organizationMilestoneReturned: OrganizationMilestone) => {
                return OrganizationMilestoneGeneratedActions.normalizeManyOrganizationMilestonesAfterUpsert({
                  organizationMilestones: [organizationMilestoneReturned]
                });
              }),
              catchError(error => of(OrganizationMilestoneGeneratedActions.organizationMilestonesFailure({ error })))
            );
          } else {
            return this.organizationMilestoneApiService.addOrganizationMilestone(organizationMilestone).pipe(
              mergeMap((organizationMilestoneReturned: OrganizationMilestone) =>
                getDefaultAddOrganizationMilestoneActions(organizationMilestoneReturned, ids)
              ),
              catchError(error => of(OrganizationMilestoneGeneratedActions.organizationMilestonesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationMilestone$ = createEffect(() => {
    const selectOrganizationMilestoneState$ = this.store$.select(
      OrganizationMilestoneSelectors.selectOrganizationMilestoneState
    );
    return this.actions$.pipe(
      ofType(OrganizationMilestoneGeneratedActions.deleteOneOrganizationMilestone),
      withLatestFrom(selectOrganizationMilestoneState$),
      concatMap(([{ idOrganizationMilestone }, state]) =>
        this.organizationMilestoneApiService.deleteOrganizationMilestone(idOrganizationMilestone).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationMilestoneActions(
              state.entities[idOrganizationMilestone] as OrganizationMilestoneEntityState
            )
          ),
          catchError(error => of(OrganizationMilestoneGeneratedActions.organizationMilestonesFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationMilestonesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationMilestoneGeneratedActions.normalizeManyOrganizationMilestonesAfterUpsert),
      concatMap(({ organizationMilestones }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationMilestone(
          organizationMilestones,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationMilestone] Normalization After Upsert Success')];
      })
    );
  });
}
