import { Injectable } from '@angular/core';
import { GanttLinkSourceTargetTypeEnum } from '@enums';
import { GanttLink, Link } from '@api/api-interfaces';
import { ProjectElementApiService } from '@wip/store/api-services';
import { GanttLinkService } from '@wip/store/services';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class LinkService {
  constructor(
    private projectElementApiService: ProjectElementApiService,
    private ganttLinkService: GanttLinkService
  ) {}
  get(idCommunity: number): Promise<Link[]> {
    return lastValueFrom(this.projectElementApiService.getProjectLinks({ idCommunity }));
  }

  save(newSource: number, lag: number, link: Link) {
    this.ganttLinkService.upsertOneGanttLink({
      idGanttLink: link.id,
      newSource,
      lag,
      type: link.type
    });
  }

  saveNew(params: Partial<GanttLink>) {
    this.ganttLinkService.upsertOneGanttLink({
      newSource: params.newSource,
      newTarget: params.newTarget,
      idCommunity: params.idCommunity,
      type: params.type,
      lag: params.lag,
      lagFormat: params.lagFormat,
      sourceType: GanttLinkSourceTargetTypeEnum.projectElement,
      targetType: GanttLinkSourceTargetTypeEnum.projectElement
    });
  }

  delete(idGanttLink) {
    this.ganttLinkService.deleteOneGanttLink(idGanttLink);
  }
}
