import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationCaracteristicValue, OrganizationCaracteristicValueEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicValueRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationCaracteristicValueGeneratedActions = createActionGroup({
  source: 'Organization Caracteristic Value Generated',
  events: {
    'Get One Organization Caracteristic Value': props<{ idOrganizationCaracteristicValue: number; params?: any }>(),
    'Get Many Organization Caracteristic Values': props<{ params: any }>(),
    'Add Many Actives Organization Caracteristic Values': props<{ idOrganizationCaracteristicValues: number[] }>(),
    'Delete One Active Organization Caracteristic Value': props<{ idOrganizationCaracteristicValue: number }>(),
    'Clear Actives Organization Caracteristic Values': emptyProps(),
    'Upsert One Organization Caracteristic Value': props<{
      organizationCaracteristicValue: Partial<OrganizationCaracteristicValue>;
      ids?: OrganizationCaracteristicValueRelationsIds;
    }>(),
    'Upsert Many Organization Caracteristic Values': props<{
      organizationCaracteristicValues: Partial<OrganizationCaracteristicValue>[];
      ids?: OrganizationCaracteristicValueRelationsIds;
    }>(),
    'Upsert Many Organization Caracteristic Values Success': props<{
      organizationCaracteristicValues: OrganizationCaracteristicValueEntityState[];
    }>(),
    'Delete One Organization Caracteristic Value': props<{ idOrganizationCaracteristicValue: number }>(),
    'Delete One Organization Caracteristic Value Success': props<{ idOrganizationCaracteristicValue: number }>(),
    'Normalize Many Organization Caracteristic Values After Upsert': props<{
      organizationCaracteristicValues: OrganizationCaracteristicValueEntityState[];
    }>(),
    'Organization Caracteristic Values Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Caracteristic Values': emptyProps(),
    'Add Organization Caracteristic Column Success': props<{
      idOrganizationCaracteristicValue: number;
      idOrganizationCaracteristicColumn: number;
    }>(),
    'Delete Many Organization Caracteristic Column Success': props<{
      idOrganizationCaracteristicColumns: number[];
      idOrganizationCaracteristicValues: number[];
    }>(),
    'Add Organization Caracteristic Row Success': props<{
      idOrganizationCaracteristicValue: number;
      idOrganizationCaracteristicRow: number;
    }>(),
    'Delete Many Organization Caracteristic Row Success': props<{
      idOrganizationCaracteristicRows: number[];
      idOrganizationCaracteristicValues: number[];
    }>(),
    'Add Organization Data Column Value Success': props<{
      idOrganizationCaracteristicValue: number;
      idOrganizationDataColumnValue: number;
    }>(),
    'Delete Many Organization Data Column Value Success': props<{
      idOrganizationDataColumnValues: number[];
      idOrganizationCaracteristicValues: number[];
    }>()
  }
});
