import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProjectModule } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { ProjectModuleRelationsIds } from '@wip/store/ids-interfaces';
import { ProjectModuleGeneratedActions } from '@wip/store/actions';
import { ProjectModuleSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedProjectModuleService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ProjectModuleSelectors.selectIsLoadedProjectModule));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ProjectModuleSelectors.selectIsLoadingProjectModule));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [ProjectModuleSelectors.selectIsReadyAndLoadedProjectModule as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllProjectModules(schema: SelectSchema = {}): Observable<ProjectModule[]> {
    return this.store$.pipe(select(ProjectModuleSelectors.selectAllProjectModules(schema))).pipe(
      switchMap(({ projectModules }: { projectModules: ProjectModule[] }) => {
        return this.getReady(schema).pipe(mapTo(projectModules));
      })
    );
  }

  public selectOneProjectModule(idProjectModule: number, schema: SelectSchema = {}): Observable<ProjectModule> {
    return this.store$.pipe(select(ProjectModuleSelectors.selectOneProjectModule(schema, idProjectModule))).pipe(
      switchMap(({ projectModule }: { projectModule: ProjectModule }) => {
        return this.getReady(schema).pipe(mapTo(projectModule));
      })
    );
  }

  public selectAllActiveProjectModules(schema: SelectSchema = {}): Observable<ProjectModule[]> {
    return this.store$.pipe(select(ProjectModuleSelectors.selectActiveProjectModules(schema))).pipe(
      switchMap(({ projectModules }: { projectModules: ProjectModule[] }) => {
        return this.getReady(schema).pipe(mapTo(projectModules));
      })
    );
  }

  public selectIdProjectModulesActive(): Observable<number[]> {
    return this.store$.pipe(select(ProjectModuleSelectors.selectIdProjectModulesActive)).pipe(
      switchMap((idProjectModules: number[]) => {
        return this.getReady().pipe(mapTo(idProjectModules));
      })
    );
  }

  public getOneProjectModule(
    idProjectModule: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<ProjectModule> {
    this.store$.dispatch(ProjectModuleGeneratedActions.getOneProjectModule({ idProjectModule, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectModuleGeneratedActions.normalizeManyProjectModulesAfterUpsert,
        ProjectModuleGeneratedActions.projectModulesFailure,
        true
      );
    }
  }

  public getManyProjectModules(params: any = {}, getResult?: boolean): void | Observable<ProjectModule[]> {
    this.store$.dispatch(ProjectModuleGeneratedActions.getManyProjectModules({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectModuleGeneratedActions.normalizeManyProjectModulesAfterUpsert,
        ProjectModuleGeneratedActions.projectModulesFailure
      );
    }
  }

  public upsertOneProjectModule(
    projectModule: Partial<ProjectModule>,
    ids: ProjectModuleRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ProjectModule> {
    this.store$.dispatch(ProjectModuleGeneratedActions.upsertOneProjectModule({ projectModule, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectModuleGeneratedActions.normalizeManyProjectModulesAfterUpsert,
        ProjectModuleGeneratedActions.projectModulesFailure,
        true
      );
    }
  }

  public deleteOneProjectModule(idProjectModule: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ProjectModuleGeneratedActions.deleteOneProjectModule({ idProjectModule }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectModuleGeneratedActions.deleteOneProjectModuleSuccess,
        ProjectModuleGeneratedActions.projectModulesFailure
      );
    }
  }

  public setActiveProjectModules(idProjectModules: number[]): void {
    this.store$.dispatch(ProjectModuleGeneratedActions.clearActivesProjectModules());
    this.store$.dispatch(ProjectModuleGeneratedActions.addManyActivesProjectModules({ idProjectModules }));
  }
}
