import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { MediaScreenSize } from '@enums';
const { Device } = Plugins;
import { Subscription } from 'rxjs';

declare let swfobject: any;
declare let window: any;

@Injectable()
export class DeviceInformationsService {
  private getDeviceValue: any;
  private getModelValue: any;
  z;
  private getPlatformValue: any;
  private getUUIDValue: any;
  private getVersionValue: any;

  subscriptionMediaQuery: Subscription;
  currentMediaScreenSize: string;

  constructor() {
    this.initAll();
    this.getDeviceValue = undefined;
    this.getModelValue = undefined;
    this.getPlatformValue = window.jscd.browser;
    this.getUUIDValue = undefined;
    this.getVersionValue = '';
    if (!this.isElectron()) {
      this.getVersionPackageJSON(version => {
        this.getVersionValue = version;
      });
    }

    // Si lancement de Capacitor : alors on ecrase les valeurs
    document.addEventListener('deviceready', () => {
      this.getCapacitorDeviceInformation();
    });
  }

  isMediaScreenSize(_size: MediaScreenSize) {
    // const targetSize = size.slice(-2);
    // let operator = 'equal';
    // if (size.length > 2) {
    //   operator = size.substring(0, 2);
    // }

    return false;
  }

  initAll() {
    /*
            window.jscd = {
                screen: screenSize,
                browser: browser,
                browserVersion: version,
                browserMajorVersion: majorVersion,
                mobile: mobile,
                os: os,
                osVersion: osVersion,
                cookies: cookieEnabled,
                flashVersion: flashVersion
            }; */

    // ((window) => {
    const unknown = '-';

    // screen
    let screenSize = '';
    if (screen.width) {
      const width = screen.width ? screen.width : '';
      const height = screen.height ? screen.height : '';
      screenSize += '' + width + ' x ' + height;
    }

    // browser
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + +navigator.appVersion;
    let majorVersion = +navigator.appVersion;
    let nameOffset;
    let verOffset;
    let ix;

    if (nAgt.indexOf('Opera') !== -1) {
      // Opera
      verOffset = nAgt.indexOf('Opera');
      browser = 'Opera';
      version = nAgt.substring(verOffset + 6);
      if (nAgt.indexOf('Version') !== -1) {
        verOffset = nAgt.indexOf('Version');
        version = nAgt.substring(verOffset + 8);
      }
    }
    if (nAgt.indexOf('OPR') !== -1) {
      // Opera Next
      verOffset = nAgt.indexOf('OPR');
      browser = 'Opera';
      version = nAgt.substring(verOffset + 4);
    } else if (nAgt.indexOf('Edge') !== -1) {
      // Edge
      verOffset = nAgt.indexOf('Edge');
      browser = 'Microsoft Edge';
      version = nAgt.substring(verOffset + 5);
    } else if (nAgt.indexOf('MSIE') !== -1) {
      // MSIE
      verOffset = nAgt.indexOf('MSIE');
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(verOffset + 5);
    } else if (nAgt.indexOf('Chrome') !== -1) {
      // Chrome
      verOffset = nAgt.indexOf('Chrome');
      browser = 'Chrome';
      version = nAgt.substring(verOffset + 7);
    } else if (nAgt.indexOf('Safari') !== -1) {
      // Safari
      verOffset = nAgt.indexOf('Safari');
      browser = 'Safari';
      version = nAgt.substring(verOffset + 7);
      if (nAgt.indexOf('Version') !== -1) {
        verOffset = nAgt.indexOf('Version');
        version = nAgt.substring(verOffset + 8);
      }
    } else if (nAgt.indexOf('Firefox') !== -1) {
      // Firefox
      verOffset = nAgt.indexOf('Firefox');
      browser = 'Firefox';
      version = nAgt.substring(verOffset + 8);
    } else if (nAgt.indexOf('Trident/') !== -1) {
      // MSIE 11+
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    } else if (nAgt.lastIndexOf(' ') + 1 < nAgt.lastIndexOf('/')) {
      // Other browsers
      nameOffset = nAgt.lastIndexOf(' ') + 1;
      verOffset = nAgt.lastIndexOf('/');
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() === browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if (version.indexOf(';') !== -1) {
      ix = version.indexOf(';');
      version = version.substring(0, ix);
    }
    if (version.indexOf(' ') !== -1) {
      ix = version.indexOf(' ');
      version = version.substring(0, ix);
    }
    if (version.indexOf(')') !== -1) {
      ix = version.indexOf(')');
      version = version.substring(0, ix);
    }

    majorVersion = +version;
    if (isNaN(majorVersion)) {
      version = '' + +navigator.appVersion;
      majorVersion = +navigator.appVersion;
    }

    // mobile version
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // cookie
    let cookieEnabled = navigator.cookieEnabled ? true : false;

    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1 ? true : false;
    }

    // system
    let os = unknown;
    const clientStrings = [
      {
        s: 'Windows 10',
        r: /(Windows 10.0|Windows NT 10.0)/
      },
      {
        s: 'Windows 8.1',
        r: /(Windows 8.1|Windows NT 6.3)/
      },
      {
        s: 'Windows 8',
        r: /(Windows 8|Windows NT 6.2)/
      },
      {
        s: 'Windows 7',
        r: /(Windows 7|Windows NT 6.1)/
      },
      {
        s: 'Windows Vista',
        r: /Windows NT 6.0/
      },
      {
        s: 'Windows Server 2003',
        r: /Windows NT 5.2/
      },
      {
        s: 'Windows XP',
        r: /(Windows NT 5.1|Windows XP)/
      },
      {
        s: 'Windows 2000',
        r: /(Windows NT 5.0|Windows 2000)/
      },
      {
        s: 'Windows ME',
        r: /(Win 9x 4.90|Windows ME)/
      },
      {
        s: 'Windows 98',
        r: /(Windows 98|Win98)/
      },
      {
        s: 'Windows 95',
        r: /(Windows 95|Win95|Windows_95)/
      },
      {
        s: 'Windows NT 4.0',
        r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
      },
      {
        s: 'Windows CE',
        r: /Windows CE/
      },
      {
        s: 'Windows 3.11',
        r: /Win16/
      },
      {
        s: 'Android',
        r: /Android/
      },
      {
        s: 'Open BSD',
        r: /OpenBSD/
      },
      {
        s: 'Sun OS',
        r: /SunOS/
      },
      {
        s: 'Linux',
        r: /(Linux|X11)/
      },
      {
        s: 'iOS',
        r: /(iPhone|iPad|iPod)/
      },
      {
        s: 'Mac OS X',
        r: /Mac OS X/
      },
      {
        s: 'Mac OS',
        r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
      },
      {
        s: 'QNX',
        r: /QNX/
      },
      {
        s: 'UNIX',
        r: /UNIX/
      },
      {
        s: 'BeOS',
        r: /BeOS/
      },
      {
        s: 'OS/2',
        r: /OS\/2/
      },
      {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
      }
    ];
    for (const id of Object.keys(clientStrings)) {
      const cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    let osVersion: any = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X ([0-9]+[\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        if (osVersion) {
          osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] || 0);
        }
        break;
      default:
        break;
    }

    // flash (you'll need to include swfobject)
    /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */

    let flashVersion = 'no check';

    if (typeof swfobject !== 'undefined') {
      const fv = swfobject.getFlashPlayerVersion();
      if (fv.major > 0) {
        flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
      } else {
        flashVersion = unknown;
      }
    }

    window.jscd = {
      screen: screenSize,
      browser,
      browserVersion: version,
      browserMajorVersion: majorVersion,
      mobile,
      os,
      osVersion,
      cookies: cookieEnabled,
      flashVersion
    };
    //    }(window)
  }

  getLanguage() {
    let language;
    let langNavigator = window.navigator.languages ? window.navigator.languages[0] : null;
    langNavigator =
      langNavigator || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
    if (langNavigator.indexOf('-') !== -1) {
      langNavigator = langNavigator.split('-')[0];
    }
    if (langNavigator.indexOf('_') !== -1) {
      langNavigator = langNavigator.split('_')[0];
    }
    switch (langNavigator) {
      case 'fr':
        language = 'fr_FR';
        break;
      case 'en':
      default:
        language = 'en_US';
    }
    return language;
  }

  getOS() {
    return window.jscd.os;
  }

  getOSVersion() {
    return window.jscd.osVersion;
  }

  getDevice() {
    return this.getDeviceValue;
  }

  /**
   * @desc : The getModel() returns the name of the device’s model or product. The value is set by the device manufacturer and may be different across versions of the same product.
   * @returns : String — eg: iPhone 5 : "iPhone 5,1", Nexus One : "Passion"
   */

  getModel() {
    return this.getModelValue;
  }

  /**
   * @desc : Get the device’s operating system name.
   * @returns : String — eg: "iOS", "Android", "WinCE"
   */

  getPlatform() {
    return this.getPlatformValue;
  }

  /**
   * @desc : Get the device’s Universally Unique Identifier (UUID). The details of how a UUID is generated are determined by
   * the device manufacturer and are specific to the device’s platform or model.
   * @returns : String
   */

  getUUID() {
    return this.getUUIDValue;
  }

  /**
   * @desc :  Get the operating system version.
   * @returns : String — eg: iOS 8.2 : "8.2", Froyo OS : "2.2"
   */

  getVersion() {
    return this.getVersionValue;
  }

  getVersionPackageJSON(callback) {
    let version = '';
    const rawFile = new XMLHttpRequest();
    rawFile.open('GET', '../../../../package.json', true);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          const allText = rawFile.responseText;
          version = JSON.parse(allText).version;

          callback(version);
        }
      }
    };

    // rawFile.send(null);
  }

  async getCapacitorDeviceInformation() {
    try {
      const info = await Device.getInfo();
      this.getPlatformValue = info.platform;
      this.getDeviceValue = info.model;
      this.getModelValue = info.model;
      this.getUUIDValue = info.uuid;
      this.getVersionValue = info.osVersion;
    } catch (e) {
      console.warn("Problème dans l'instanciation Capacitor");
    }
  }

  getExecutable() {
    return this.isElectron ? 'Electron' : window.hasOwnPropperty('Capacitor') ? 'Capacitor' : 'Web';
  }

  /**
   * @desc :  TODO : is smartphone/tablette, get tailleecran, is browser, is electron
   */

  isElectron() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf(' electron/') > -1;
  }

  isBrowser() {
    return !this.isIOS() && !this.isAndroid() && !this.isElectron();
  }

  isBrowserOrElectron() {
    return !this.isIOS() && !this.isAndroid();
  }

  isIOS() {
    return this.getPlatform() === 'iOS' || this.getPlatform() === 'Mobile' || this.getPlatform() === 'Netscape';
  }

  isAndroid() {
    return this.getPlatform() === 'Android' || this.getPlatform() === 'Mobile';
  }

  isMobileOS() {
    return this.isIOS() || this.isAndroid() || window.jscd.mobile;
  }
}
