import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { UserBoardState, UserBoardStateEntityState } from '@api/api-interfaces';
import { UserBoardStateApiService } from '@wip/store/api-services';
import { UserBoardStateGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeUserBoardState } from '@wip/store/configs/normalization';
import { UserBoardStateSelectors } from '@wip/store/selectors';
import { UserBoardStateRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationUserGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';

export function getDefaultAddUserBoardStateActions(
  userBoardState: UserBoardStateEntityState,
  ids?: UserBoardStateRelationsIds
): Action[] {
  const actions: Action[] = [
    UserBoardStateGeneratedActions.normalizeManyUserBoardStatesAfterUpsert({ userBoardStates: [userBoardState] })
  ];

  if (ids?.organizationUser) {
    actions.push(
      OrganizationUserGeneratedActions.addManyUserBoardStateSuccess({
        idOrganizationUser: ids.organizationUser,
        idUserBoardStates: [userBoardState.idUserBoardState]
      })
    );
    actions.push(
      UserBoardStateGeneratedActions.addOrganizationUserSuccess({
        idUserBoardState: userBoardState.idUserBoardState,
        idOrganizationUser: ids.organizationUser
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyUserBoardStateSuccess({
        idOrganizationFamily: ids.organizationFamily,
        idUserBoardStates: [userBoardState.idUserBoardState]
      })
    );
    actions.push(
      UserBoardStateGeneratedActions.addOrganizationFamilySuccess({
        idUserBoardState: userBoardState.idUserBoardState,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  return actions;
}

export function getDefaultDeleteUserBoardStateActions(userBoardState: UserBoardStateEntityState): Action[] {
  const actions: Action[] = [
    UserBoardStateGeneratedActions.deleteOneUserBoardStateSuccess({ idUserBoardState: userBoardState.idUserBoardState })
  ];

  if (userBoardState.organizationUser) {
    actions.push(
      OrganizationUserGeneratedActions.deleteManyUserBoardStateSuccess({
        idUserBoardStates: [userBoardState.idUserBoardState],
        idOrganizationUsers: [userBoardState.organizationUser as number]
      })
    );
  }

  if (userBoardState.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyUserBoardStateSuccess({
        idUserBoardStates: [userBoardState.idUserBoardState],
        idOrganizationFamilys: [userBoardState.organizationFamily as number]
      })
    );
  }

  return actions;
}

export class GeneratedUserBoardStateEffects {
  constructor(
    protected actions$: Actions,
    protected userBoardStateApiService: UserBoardStateApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUserBoardStates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserBoardStateGeneratedActions.getManyUserBoardStates),
      switchMap(({ params }) =>
        this.userBoardStateApiService.getUserBoardStates(params).pipe(
          map((userBoardStates: UserBoardState[]) => {
            return UserBoardStateGeneratedActions.normalizeManyUserBoardStatesAfterUpsert({ userBoardStates });
          }),
          catchError(error => of(UserBoardStateGeneratedActions.userBoardStatesFailure({ error })))
        )
      )
    );
  });

  getOneUserBoardState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserBoardStateGeneratedActions.getOneUserBoardState),
      switchMap(idUserBoardState =>
        this.userBoardStateApiService.getUserBoardState(idUserBoardState).pipe(
          map((userBoardState: UserBoardState) => {
            return UserBoardStateGeneratedActions.normalizeManyUserBoardStatesAfterUpsert({
              userBoardStates: [userBoardState]
            });
          }),
          catchError(error => of(UserBoardStateGeneratedActions.userBoardStatesFailure({ error })))
        )
      )
    );
  });

  upsertOneUserBoardState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserBoardStateGeneratedActions.upsertOneUserBoardState),
      concatMap(
        ({ userBoardState, ids }: { userBoardState: Partial<UserBoardState>; ids?: UserBoardStateRelationsIds }) => {
          if (userBoardState.idUserBoardState) {
            return this.userBoardStateApiService.updateUserBoardState(userBoardState).pipe(
              map((userBoardStateReturned: UserBoardState) => {
                return UserBoardStateGeneratedActions.normalizeManyUserBoardStatesAfterUpsert({
                  userBoardStates: [userBoardStateReturned]
                });
              }),
              catchError(error => of(UserBoardStateGeneratedActions.userBoardStatesFailure({ error })))
            );
          } else {
            return this.userBoardStateApiService.addUserBoardState(userBoardState).pipe(
              mergeMap((userBoardStateReturned: UserBoardState) =>
                getDefaultAddUserBoardStateActions(userBoardStateReturned, ids)
              ),
              catchError(error => of(UserBoardStateGeneratedActions.userBoardStatesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneUserBoardState$ = createEffect(() => {
    const selectUserBoardStateState$ = this.store$.select(UserBoardStateSelectors.selectUserBoardStateState);
    return this.actions$.pipe(
      ofType(UserBoardStateGeneratedActions.deleteOneUserBoardState),
      withLatestFrom(selectUserBoardStateState$),
      concatMap(([{ idUserBoardState }, state]) =>
        this.userBoardStateApiService.deleteUserBoardState(idUserBoardState).pipe(
          mergeMap(_success =>
            getDefaultDeleteUserBoardStateActions(state.entities[idUserBoardState] as UserBoardStateEntityState)
          ),
          catchError(error => of(UserBoardStateGeneratedActions.userBoardStatesFailure({ error })))
        )
      )
    );
  });

  normalizeManyUserBoardStatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserBoardStateGeneratedActions.normalizeManyUserBoardStatesAfterUpsert),
      concatMap(({ userBoardStates }) => {
        const actions: Action[] = getActionsToNormalizeUserBoardState(userBoardStates, StoreActionType.upsert);
        return [getMultiAction(actions, '[UserBoardState] Normalization After Upsert Success')];
      })
    );
  });
}
