import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Community } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import * as communityActions from '@wip/store/actions';
import * as folderActions from '@wip/store/actions';
import { FolderApiService } from '@wip/store/api-services';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedFolderEffects } from './folder-generated.effects';
@Injectable()
export class FolderEffects extends GeneratedFolderEffects {
  constructor(actions$: Actions, folderApiService: FolderApiService, store$: Store<AppState>) {
    super(actions$, folderApiService, store$);
  }

  uploadFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(folderActions.uploadFile),
      concatMap(({ params }) => {
        return this.folderApiService.upload(params).pipe(
          map((communityReturned: Community) => {
            return communityActions.CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({
              communities: [communityReturned]
            });
          }),
          catchError(error => of(folderActions.FolderGeneratedActions.foldersFailure({ error })))
        );
      })
    );
  });
}
