<div>
  <div class="margin-bottom">
    <h2 class="title-synthesis">Dates clés</h2>
  </div>

  <div class="ag-grid-container flex flex-col gap-8">
    <ag-grid-angular
      #agGrid
      class="ag-theme-quartz"
      style="width: 100%; height: 100%"
      [gridOptions]="gridOptions"
      [modules]="modules"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
</div>
