import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationSection, OrganizationSectionEntityState } from '@api/api-interfaces';
import { OrganizationSectionApiService } from '@wip/store/api-services';
import { OrganizationSectionGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationSection } from '@wip/store/configs/normalization';
import { OrganizationSectionSelectors } from '@wip/store/selectors';
import { OrganizationSectionRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { SectionGeneratedActions } from '@wip/store/actions';

export function getDefaultAddOrganizationSectionActions(
  organizationSection: OrganizationSectionEntityState,
  ids?: OrganizationSectionRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationSectionGeneratedActions.normalizeManyOrganizationSectionsAfterUpsert({
      organizationSections: [organizationSection]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationSectionSuccess({
        idOrganization: ids.organization,
        idOrganizationSections: [organizationSection.idOrganizationSection]
      })
    );
    actions.push(
      OrganizationSectionGeneratedActions.addOrganizationSuccess({
        idOrganizationSection: organizationSection.idOrganizationSection,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.section) {
    actions.push(
      SectionGeneratedActions.addManyOrganizationSectionSuccess({
        idSection: ids.section,
        idOrganizationSections: [organizationSection.idOrganizationSection]
      })
    );
    actions.push(
      OrganizationSectionGeneratedActions.addSectionSuccess({
        idOrganizationSection: organizationSection.idOrganizationSection,
        idSection: ids.section
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationSectionActions(
  organizationSection: OrganizationSectionEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationSectionGeneratedActions.deleteOneOrganizationSectionSuccess({
      idOrganizationSection: organizationSection.idOrganizationSection
    })
  ];

  if (organizationSection.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationSectionSuccess({
        idOrganizationSections: [organizationSection.idOrganizationSection],
        idOrganizations: [organizationSection.organization as number]
      })
    );
  }

  if (organizationSection.section) {
    actions.push(
      SectionGeneratedActions.deleteManyOrganizationSectionSuccess({
        idOrganizationSections: [organizationSection.idOrganizationSection],
        idSections: [organizationSection.section as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationSectionEffects {
  constructor(
    protected actions$: Actions,
    protected organizationSectionApiService: OrganizationSectionApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationSections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationSectionGeneratedActions.getManyOrganizationSections),
      switchMap(({ params }) =>
        this.organizationSectionApiService.getOrganizationSections(params).pipe(
          map((organizationSections: OrganizationSection[]) => {
            return OrganizationSectionGeneratedActions.normalizeManyOrganizationSectionsAfterUpsert({
              organizationSections
            });
          }),
          catchError(error => of(OrganizationSectionGeneratedActions.organizationSectionsFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationSectionGeneratedActions.getOneOrganizationSection),
      switchMap(idOrganizationSection =>
        this.organizationSectionApiService.getOrganizationSection(idOrganizationSection).pipe(
          map((organizationSection: OrganizationSection) => {
            return OrganizationSectionGeneratedActions.normalizeManyOrganizationSectionsAfterUpsert({
              organizationSections: [organizationSection]
            });
          }),
          catchError(error => of(OrganizationSectionGeneratedActions.organizationSectionsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationSectionGeneratedActions.upsertOneOrganizationSection),
      concatMap(
        ({
          organizationSection,
          ids
        }: {
          organizationSection: Partial<OrganizationSection>;
          ids?: OrganizationSectionRelationsIds;
        }) => {
          if (organizationSection.idOrganizationSection) {
            return this.organizationSectionApiService.updateOrganizationSection(organizationSection).pipe(
              map((organizationSectionReturned: OrganizationSection) => {
                return OrganizationSectionGeneratedActions.normalizeManyOrganizationSectionsAfterUpsert({
                  organizationSections: [organizationSectionReturned]
                });
              }),
              catchError(error => of(OrganizationSectionGeneratedActions.organizationSectionsFailure({ error })))
            );
          } else {
            return this.organizationSectionApiService.addOrganizationSection(organizationSection).pipe(
              mergeMap((organizationSectionReturned: OrganizationSection) =>
                getDefaultAddOrganizationSectionActions(organizationSectionReturned, ids)
              ),
              catchError(error => of(OrganizationSectionGeneratedActions.organizationSectionsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationSection$ = createEffect(() => {
    const selectOrganizationSectionState$ = this.store$.select(
      OrganizationSectionSelectors.selectOrganizationSectionState
    );
    return this.actions$.pipe(
      ofType(OrganizationSectionGeneratedActions.deleteOneOrganizationSection),
      withLatestFrom(selectOrganizationSectionState$),
      concatMap(([{ idOrganizationSection }, state]) =>
        this.organizationSectionApiService.deleteOrganizationSection(idOrganizationSection).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationSectionActions(
              state.entities[idOrganizationSection] as OrganizationSectionEntityState
            )
          ),
          catchError(error => of(OrganizationSectionGeneratedActions.organizationSectionsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationSectionsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationSectionGeneratedActions.normalizeManyOrganizationSectionsAfterUpsert),
      concatMap(({ organizationSections }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationSection(
          organizationSections,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationSection] Normalization After Upsert Success')];
      })
    );
  });
}
