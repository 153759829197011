import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityRiskGeneratedActions } from '@wip/store/actions';
import { CommunityRiskState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityRiskEntityState } from '@api/api-interfaces';

export const communityRiskReducersGeneratedFunctions: ReducerTypes<
  CommunityRiskState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunityRiskGeneratedActions.getOneCommunityRisk, (state: CommunityRiskState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityRiskGeneratedActions.getManyCommunityRisks, (state: CommunityRiskState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityRiskGeneratedActions.upsertOneCommunityRisk, (state: CommunityRiskState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    CommunityRiskGeneratedActions.upsertManyCommunityRisksSuccess,
    (state: CommunityRiskState.IState, { communityRisks }) =>
      CommunityRiskState.adapter.upsertMany(communityRisks, setLoadingsState(state, false))
  ),
  on(CommunityRiskGeneratedActions.deleteOneCommunityRisk, (state: CommunityRiskState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    CommunityRiskGeneratedActions.deleteOneCommunityRiskSuccess,
    (state: CommunityRiskState.IState, { idCommunityRisk }) =>
      CommunityRiskState.adapter.removeOne(idCommunityRisk, setLoadingsState(state, false))
  ),
  on(CommunityRiskGeneratedActions.clearActivesCommunityRisks, (state: CommunityRiskState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    CommunityRiskGeneratedActions.addManyActivesCommunityRisks,
    (state: CommunityRiskState.IState, { idCommunityRisks }) => ({
      ...state,
      actives: state.actives.concat(idCommunityRisks)
    })
  ),
  on(
    CommunityRiskGeneratedActions.deleteOneActiveCommunityRisk,
    (state: CommunityRiskState.IState, { idCommunityRisk }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityRisk)
    })
  ),

  on(CommunityRiskGeneratedActions.clearCommunityRisks, () => CommunityRiskState.initialState),

  on(
    CommunityRiskGeneratedActions.addOrganizationRiskSuccess,
    (state: CommunityRiskState.IState, { idCommunityRisk, idOrganizationRisk }) => {
      if (!state.entities[idCommunityRisk]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityRisk]: {
            ...state.entities[idCommunityRisk],
            organizationRisk: idOrganizationRisk
          }
        }
      };
    }
  ),

  on(
    CommunityRiskGeneratedActions.deleteManyOrganizationRiskSuccess,
    (state: CommunityRiskState.IState, { idOrganizationRisks, idCommunityRisks }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityRisks.reduce((entities, idCommunityRisk) => {
            if (!state.entities[idCommunityRisk]?.organizationRisk) {
              return entities;
            }
            entities[idCommunityRisk] = {
              ...state.entities[idCommunityRisk],
              organizationRisk: idOrganizationRisks.some(
                (idOrganizationRisk: number) => idOrganizationRisk === state.entities[idCommunityRisk]?.organizationRisk
              )
                ? undefined
                : state.entities[idCommunityRisk]?.organizationRisk
            } as CommunityRiskEntityState;
            return entities;
          }, {} as Dictionary<CommunityRiskEntityState>)
        }
      };
    }
  ),

  on(
    CommunityRiskGeneratedActions.addCommunitySuccess,
    (state: CommunityRiskState.IState, { idCommunityRisk, idCommunity }) => {
      if (!state.entities[idCommunityRisk]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityRisk]: {
            ...state.entities[idCommunityRisk],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityRiskGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityRiskState.IState, { idCommunities, idCommunityRisks }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityRisks.reduce((entities, idCommunityRisk) => {
            if (!state.entities[idCommunityRisk]?.community) {
              return entities;
            }
            entities[idCommunityRisk] = {
              ...state.entities[idCommunityRisk],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityRisk]?.community
              )
                ? undefined
                : state.entities[idCommunityRisk]?.community
            } as CommunityRiskEntityState;
            return entities;
          }, {} as Dictionary<CommunityRiskEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityRiskState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityRiskState.IState {
  return { ...state, isLoaded, isLoading };
}
