import { RepositoryService } from '@wip/services/repository';
import { OrganizationAzure } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationAzureApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationAzures(params?: any): Observable<OrganizationAzure[]> {
    return this.repo.getData<OrganizationAzure[]>('organization-azure', params);
  }

  public getOrganizationAzure(params: { idOrganizationAzure: number; params?: any }): Observable<OrganizationAzure> {
    return this.repo.getData<OrganizationAzure>('organization-azure/' + params.idOrganizationAzure, params.params);
  }

  public addOrganizationAzure(organizationAzure: Partial<OrganizationAzure>): Observable<OrganizationAzure> {
    return this.repo.create<OrganizationAzure>('organization-azure', organizationAzure);
  }

  public updateOrganizationAzure(organizationAzure: Partial<OrganizationAzure>): Observable<OrganizationAzure> {
    return this.repo.update('organization-azure', organizationAzure);
  }

  public deleteOrganizationAzure(idOrganizationAzure: number): Observable<number> {
    return this.repo.delete('organization-azure/' + +idOrganizationAzure);
  }
}
