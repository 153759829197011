import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Element, GanttLink, ProjectElement } from '@api/api-interfaces';
import { ActionFormDTO } from '@dto';
import * as AppState from '@wip/store/configs/reducers';
import * as ProjectElementAction from '@wip/store/actions';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneratedProjectElementService } from './project-element-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectElementService extends GeneratedProjectElementService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public filterParams = new BehaviorSubject<{
    textFilter: string;
    milestoneFilter: boolean;
    nextDatesFilter: boolean;
    responsibleFilter: number | null;
    scale: string;
  }>({
    textFilter: '',
    milestoneFilter: false,
    nextDatesFilter: false,
    responsibleFilter: null,
    scale: 'trimester'
  });

  public validateActionForm(controls: ActionFormDTO) {
    this.store$.dispatch(ProjectElementAction.validateActionForm(controls));
  }

  public reschedule(data: { idCommunity: number; tasks: Partial<Element>[]; links: Partial<GanttLink>[] }) {
    this.store$.dispatch(ProjectElementAction.reschedule({ data }));
  }

  public insertProjectElement(projectElement: any, getResult?: boolean): void | Observable<ProjectElement> {
    this.store$.dispatch(ProjectElementAction.insertProjectElement({ projectElement }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectElementAction.ProjectElementGeneratedActions.normalizeManyProjectElementsAfterUpsert,
        ProjectElementAction.ProjectElementGeneratedActions.projectElementsFailure,
        true
      );
    }
  }

  public deleteOneProjectElementCustom(idProjectElement: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ProjectElementAction.deleteOneProjectElementCustom({ idProjectElement }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ProjectElementAction.ProjectElementGeneratedActions.deleteOneProjectElementSuccess,
        ProjectElementAction.ProjectElementGeneratedActions.projectElementsFailure
      );
    }
  }

  public clearProjectElements() {
    this.store$.dispatch(ProjectElementAction.ProjectElementGeneratedActions.clearProjectElements());
  }

  public reorder(tasks: any) {
    this.store$.dispatch(ProjectElementAction.reorderProjectElements(tasks));
  }
}
