import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { CommunityMilestoneFamily, CommunityMilestoneFamilyEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneAssociation, OrganizationMilestoneAssociationEntityState } from '@api/api-interfaces';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationMilestoneFamilyState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationMilestoneFamilyRelations: string[] = [
  'communityMilestoneFamilys',
  'organizationMilestoneAssociations',
  'organizationMilestones',
  'organizations',
  'organizationFamilys',
  'organizationCaracteristics'
];

export const { selectEntities, selectAll } = OrganizationMilestoneFamilyState.adapter.getSelectors();

export const selectOrganizationMilestoneFamilyState = createFeatureSelector<OrganizationMilestoneFamilyState.IState>(
  OrganizationMilestoneFamilyState.organizationMilestoneFamilyFeatureKey
);

export const selectIsLoadedOrganizationMilestoneFamily = createSelector(
  selectOrganizationMilestoneFamilyState,
  (state: OrganizationMilestoneFamilyState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationMilestoneFamily = createSelector(
  selectOrganizationMilestoneFamilyState,
  (state: OrganizationMilestoneFamilyState.IState) => state.isLoading
);

export const selectIsReadyOrganizationMilestoneFamily = createSelector(
  selectOrganizationMilestoneFamilyState,
  (state: OrganizationMilestoneFamilyState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationMilestoneFamily = createSelector(
  selectOrganizationMilestoneFamilyState,
  (state: OrganizationMilestoneFamilyState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationMilestoneFamilysEntities = createSelector(
  selectOrganizationMilestoneFamilyState,
  selectEntities
);

export const selectOrganizationMilestoneFamilysArray = createSelector(
  selectOrganizationMilestoneFamilyState,
  selectAll
);

export const selectIdOrganizationMilestoneFamilysActive = createSelector(
  selectOrganizationMilestoneFamilyState,
  (state: OrganizationMilestoneFamilyState.IState) => state.actives
);

const organizationMilestoneFamilysInObject = (
  organizationMilestoneFamilys: Dictionary<OrganizationMilestoneFamilyEntityState>
) => ({ organizationMilestoneFamilys });

const selectOrganizationMilestoneFamilysEntitiesDictionary = createSelector(
  selectOrganizationMilestoneFamilysEntities,
  organizationMilestoneFamilysInObject
);

const selectAllOrganizationMilestoneFamilysObject = createSelector(
  selectOrganizationMilestoneFamilysEntities,
  organizationMilestoneFamilys => {
    return hydrateAll({ organizationMilestoneFamilys });
  }
);

const selectOneOrganizationMilestoneFamilyDictionary = (idOrganizationMilestoneFamily: number) =>
  createSelector(selectOrganizationMilestoneFamilysEntities, organizationMilestoneFamilys => ({
    organizationMilestoneFamilys: {
      [idOrganizationMilestoneFamily]: organizationMilestoneFamilys[idOrganizationMilestoneFamily]
    }
  }));

const selectOneOrganizationMilestoneFamilyDictionaryWithoutChild = (idOrganizationMilestoneFamily: number) =>
  createSelector(selectOrganizationMilestoneFamilysEntities, organizationMilestoneFamilys => ({
    organizationMilestoneFamily: organizationMilestoneFamilys[idOrganizationMilestoneFamily]
  }));

const selectActiveOrganizationMilestoneFamilysEntities = createSelector(
  selectIdOrganizationMilestoneFamilysActive,
  selectOrganizationMilestoneFamilysEntities,
  (actives: number[], organizationMilestoneFamilys: Dictionary<OrganizationMilestoneFamilyEntityState>) =>
    getOrganizationMilestoneFamilysFromActives(actives, organizationMilestoneFamilys)
);

function getOrganizationMilestoneFamilysFromActives(
  actives: number[],
  organizationMilestoneFamilys: Dictionary<OrganizationMilestoneFamilyEntityState>
): Dictionary<OrganizationMilestoneFamilyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationMilestoneFamilys[idActive]) {
      acc[idActive] = organizationMilestoneFamilys[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationMilestoneFamilyEntityState>);
}

const selectAllOrganizationMilestoneFamilysSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationMilestoneFamilys(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationMilestoneFamily>(
      schema,
      selectAllOrganizationMilestoneFamilysSelectors,
      selectOrganizationMilestoneFamilysEntitiesDictionary,
      getRelationSelectors,
      organizationMilestoneFamilyRelations,
      hydrateAll,
      'organizationMilestoneFamily'
    );
  } else {
    return selectAllOrganizationMilestoneFamilysObject;
  }
}

export function selectAllOrganizationMilestoneFamilysDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationMilestoneFamilys'
): Selector {
  return createSelector(selectAllOrganizationMilestoneFamilys(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationMilestoneFamilyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationMilestoneFamilys.length; i++) {
      res[customKey][result.organizationMilestoneFamilys[i].idOrganizationMilestoneFamily] =
        result.organizationMilestoneFamilys[i];
    }
    return res;
  });
}

export function selectOneOrganizationMilestoneFamily(
  schema: SelectSchema = {},
  idOrganizationMilestoneFamily: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationMilestoneFamilyDictionary(idOrganizationMilestoneFamily)];
    selectors.push(
      ...getRelationSelectors(schema, organizationMilestoneFamilyRelations, 'organizationMilestoneFamily')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationMilestoneFamilyDictionaryWithoutChild(idOrganizationMilestoneFamily);
  }
}

export function selectActiveOrganizationMilestoneFamilys(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationMilestoneFamilysEntities, organizationMilestoneFamilys => ({
      organizationMilestoneFamilys
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationMilestoneFamilyRelations, 'organizationMilestoneFamily'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationMilestoneFamilys: Dictionary<OrganizationMilestoneFamilyEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  organizationCaracteristics?: Dictionary<OrganizationCaracteristicEntityState>;
  communityMilestoneFamilys?: Dictionary<CommunityMilestoneFamilyEntityState>;
  organizationMilestoneAssociations?: Dictionary<OrganizationMilestoneAssociationEntityState>;
  organizationMilestones?: Dictionary<OrganizationMilestoneEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationMilestoneFamilys: (OrganizationMilestoneFamily | null)[];
} {
  const {
    organizationMilestoneFamilys,
    organizations,
    organizationFamilys,
    organizationCaracteristics,
    communityMilestoneFamilys,
    organizationMilestoneAssociations,
    organizationMilestones
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationMilestoneFamilys: Object.keys(organizationMilestoneFamilys).map(idOrganizationMilestoneFamily =>
      hydrate(
        organizationMilestoneFamilys[idOrganizationMilestoneFamily] as OrganizationMilestoneFamilyEntityState,
        organizations,
        organizationFamilys,
        organizationCaracteristics,
        communityMilestoneFamilys,
        organizationMilestoneAssociations,
        organizationMilestones
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState | null;
} {
  const {
    organizationMilestoneFamilys,
    organizations,
    organizationFamilys,
    organizationCaracteristics,
    communityMilestoneFamilys,
    organizationMilestoneAssociations,
    organizationMilestones
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationMilestoneFamily = Object.values(organizationMilestoneFamilys)[0];
  return {
    organizationMilestoneFamily: hydrate(
      organizationMilestoneFamily as OrganizationMilestoneFamilyEntityState,
      organizations,
      organizationFamilys,
      organizationCaracteristics,
      communityMilestoneFamilys,
      organizationMilestoneAssociations,
      organizationMilestones
    )
  };
}

function hydrate(
  organizationMilestoneFamily: OrganizationMilestoneFamilyEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  organizationCaracteristicEntities?: Dictionary<OrganizationCaracteristicEntityState>,
  communityMilestoneFamilyEntities?: Dictionary<CommunityMilestoneFamilyEntityState>,
  organizationMilestoneAssociationEntities?: Dictionary<OrganizationMilestoneAssociationEntityState>,
  organizationMilestoneEntities?: Dictionary<OrganizationMilestoneEntityState>
): OrganizationMilestoneFamily | null {
  if (!organizationMilestoneFamily) {
    return null;
  }

  const organizationMilestoneFamilyHydrated: OrganizationMilestoneFamilyEntityState = {
    ...organizationMilestoneFamily
  };
  if (organizationEntities) {
    organizationMilestoneFamilyHydrated.organization = organizationEntities[
      organizationMilestoneFamily.organization as number
    ] as Organization;
  } else {
    delete organizationMilestoneFamilyHydrated.organization;
  }
  if (organizationFamilyEntities) {
    organizationMilestoneFamilyHydrated.organizationFamily = organizationFamilyEntities[
      organizationMilestoneFamily.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete organizationMilestoneFamilyHydrated.organizationFamily;
  }
  if (organizationCaracteristicEntities) {
    organizationMilestoneFamilyHydrated.organizationCaracteristic = organizationCaracteristicEntities[
      organizationMilestoneFamily.organizationCaracteristic as number
    ] as OrganizationCaracteristic;
  } else {
    delete organizationMilestoneFamilyHydrated.organizationCaracteristic;
  }

  if (communityMilestoneFamilyEntities) {
    organizationMilestoneFamilyHydrated.communityMilestoneFamilys = (
      (organizationMilestoneFamilyHydrated.communityMilestoneFamilys as number[]) || []
    ).map(id => communityMilestoneFamilyEntities[id]) as CommunityMilestoneFamily[];
  } else {
    delete organizationMilestoneFamilyHydrated.communityMilestoneFamilys;
  }

  if (organizationMilestoneAssociationEntities) {
    organizationMilestoneFamilyHydrated.organizationMilestoneAssociations = (
      (organizationMilestoneFamilyHydrated.organizationMilestoneAssociations as number[]) || []
    ).map(id => organizationMilestoneAssociationEntities[id]) as OrganizationMilestoneAssociation[];
  } else {
    delete organizationMilestoneFamilyHydrated.organizationMilestoneAssociations;
  }

  if (organizationMilestoneEntities) {
    organizationMilestoneFamilyHydrated.organizationMilestones = (
      (organizationMilestoneFamilyHydrated.organizationMilestones as number[]) || []
    ).map(id => organizationMilestoneEntities[id]) as OrganizationMilestone[];
  } else {
    delete organizationMilestoneFamilyHydrated.organizationMilestones;
  }

  return organizationMilestoneFamilyHydrated as OrganizationMilestoneFamily;
}
