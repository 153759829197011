import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MeetingElement, MeetingElementEntityState } from '@api/api-interfaces';
import { MeetingElementRelationsIds } from '@wip/store/ids-interfaces';

export const MeetingElementGeneratedActions = createActionGroup({
  source: 'Meeting Element Generated',
  events: {
    'Get One Meeting Element': props<{ idMeetingElement: number; params?: any }>(),
    'Get Many Meeting Elements': props<{ params: any }>(),
    'Add Many Actives Meeting Elements': props<{ idMeetingElements: number[] }>(),
    'Delete One Active Meeting Element': props<{ idMeetingElement: number }>(),
    'Clear Actives Meeting Elements': emptyProps(),
    'Upsert One Meeting Element': props<{
      meetingElement: Partial<MeetingElement>;
      ids?: MeetingElementRelationsIds;
    }>(),
    'Upsert Many Meeting Elements': props<{
      meetingElements: Partial<MeetingElement>[];
      ids?: MeetingElementRelationsIds;
    }>(),
    'Upsert Many Meeting Elements Success': props<{ meetingElements: MeetingElementEntityState[] }>(),
    'Delete One Meeting Element': props<{ idMeetingElement: number }>(),
    'Delete One Meeting Element Success': props<{ idMeetingElement: number }>(),
    'Normalize Many Meeting Elements After Upsert': props<{ meetingElements: MeetingElementEntityState[] }>(),
    'Meeting Elements Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Meeting Elements': emptyProps(),
    'Add Meeting Success': props<{ idMeetingElement: number; idMeeting: number }>(),
    'Delete Many Meeting Success': props<{ idMeetings: number[]; idMeetingElements: number[] }>(),
    'Add Element Success': props<{ idMeetingElement: number; idElement: number }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idMeetingElements: number[] }>()
  }
});
