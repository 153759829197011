import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Element } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { ElementRelationsIds } from '@wip/store/ids-interfaces';
import { ElementGeneratedActions } from '@wip/store/actions';
import { ElementSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedElementService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ElementSelectors.selectIsLoadedElement));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ElementSelectors.selectIsLoadingElement));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [ElementSelectors.selectIsReadyAndLoadedElement as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllElements(schema: SelectSchema = {}): Observable<Element[]> {
    return this.store$.pipe(select(ElementSelectors.selectAllElements(schema))).pipe(
      switchMap(({ elements }: { elements: Element[] }) => {
        return this.getReady(schema).pipe(mapTo(elements));
      })
    );
  }

  public selectOneElement(idElement: number, schema: SelectSchema = {}): Observable<Element> {
    return this.store$.pipe(select(ElementSelectors.selectOneElement(schema, idElement))).pipe(
      switchMap(({ element }: { element: Element }) => {
        return this.getReady(schema).pipe(mapTo(element));
      })
    );
  }

  public selectAllActiveElements(schema: SelectSchema = {}): Observable<Element[]> {
    return this.store$.pipe(select(ElementSelectors.selectActiveElements(schema))).pipe(
      switchMap(({ elements }: { elements: Element[] }) => {
        return this.getReady(schema).pipe(mapTo(elements));
      })
    );
  }

  public selectIdElementsActive(): Observable<number[]> {
    return this.store$.pipe(select(ElementSelectors.selectIdElementsActive)).pipe(
      switchMap((idElements: number[]) => {
        return this.getReady().pipe(mapTo(idElements));
      })
    );
  }

  public getOneElement(idElement: number, params: any = {}, getResult?: boolean): void | Observable<Element> {
    this.store$.dispatch(ElementGeneratedActions.getOneElement({ idElement, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementGeneratedActions.normalizeManyElementsAfterUpsert,
        ElementGeneratedActions.elementsFailure,
        true
      );
    }
  }

  public getManyElements(params: any = {}, getResult?: boolean): void | Observable<Element[]> {
    this.store$.dispatch(ElementGeneratedActions.getManyElements({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementGeneratedActions.normalizeManyElementsAfterUpsert,
        ElementGeneratedActions.elementsFailure
      );
    }
  }

  public upsertOneElement(
    element: Partial<Element>,
    ids: ElementRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Element> {
    this.store$.dispatch(ElementGeneratedActions.upsertOneElement({ element, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementGeneratedActions.normalizeManyElementsAfterUpsert,
        ElementGeneratedActions.elementsFailure,
        true
      );
    }
  }

  public deleteOneElement(idElement: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ElementGeneratedActions.deleteOneElement({ idElement }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ElementGeneratedActions.deleteOneElementSuccess,
        ElementGeneratedActions.elementsFailure
      );
    }
  }

  public setActiveElements(idElements: number[]): void {
    this.store$.dispatch(ElementGeneratedActions.clearActivesElements());
    this.store$.dispatch(ElementGeneratedActions.addManyActivesElements({ idElements }));
  }
}
