import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TodoElementForm } from '@api/api-interfaces';
import * as AppState from '@wip/store/configs/reducers';
import * as TodoElementAction from '@wip/store/actions';
import { GeneratedTodoElementService } from './todo-element-generated.service';
import { ActionFormDTO } from '@dto';

@Injectable({
  providedIn: 'root'
})
export class TodoElementService extends GeneratedTodoElementService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public validateActionForm(controls: ActionFormDTO) {
    this.store$.dispatch(TodoElementAction.validateActionFormElement(controls));
  }

  public getProjectTodoElements(idCommunity: number) {
    this.store$.dispatch(TodoElementAction.getTodoElementsByIdCommunity({ idCommunity }));
    return catchApiActions(
      this.actions$,
      TodoElementAction.TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert,
      TodoElementAction.TodoElementGeneratedActions.todoElementsFailure
    );
  }

  public getProjectTodoElementsForSynthesisDashboard(idCommunity: number) {
    this.store$.dispatch(TodoElementAction.getTodoElementsForSynthesisDashboard({ idCommunity }));
    return catchApiActions(
      this.actions$,
      TodoElementAction.TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert,
      TodoElementAction.TodoElementGeneratedActions.todoElementsFailure
    );
  }

  public createTodoElement(todoElementForm: TodoElementForm, getResult?: boolean) {
    this.store$.dispatch(TodoElementAction.createTodoElement({ todoElementForm }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        TodoElementAction.TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert,
        TodoElementAction.TodoElementGeneratedActions.todoElementsFailure,
        true
      );
    }
  }
}
