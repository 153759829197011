export enum NewModuleList {
  synthesis = 'Synthèse',
  planning = 'Planning',
  team = 'Equipe',
  caracteristics = 'Caractéristiques',
  meetings = 'Réunions',
  todos = 'Tâches',
  files = 'Fichiers',
  financialManagement = 'Trésorerie'
}
