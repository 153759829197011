import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationCaracteristicValueApiService } from '@wip/store/api-services';
import { GeneratedOrganizationCaracteristicValueEffects } from './organization-caracteristic-value-generated.effects';

@Injectable()
export class OrganizationCaracteristicValueEffects extends GeneratedOrganizationCaracteristicValueEffects {
  constructor(actions$: Actions, organizationCaracteristicValueApiService: OrganizationCaracteristicValueApiService, store$: Store<AppState>) {
    super(actions$, organizationCaracteristicValueApiService, store$);
  }
}
