import { Injectable } from '@angular/core';import { Store } from '@ngrx/store';
import { AppState } from '@wip/store/configs/reducers';
import { GeneratedOrganizationCaracteristicValueService } from './organization-caracteristic-value-generated.service';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class OrganizationCaracteristicValueService extends GeneratedOrganizationCaracteristicValueService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}

