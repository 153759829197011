import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationRiskGeneratedActions } from '@wip/store/actions';
import { OrganizationRiskState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationRiskEntityState } from '@api/api-interfaces';

export const organizationRiskReducersGeneratedFunctions: ReducerTypes<
  OrganizationRiskState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationRiskGeneratedActions.getOneOrganizationRisk, (state: OrganizationRiskState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationRiskGeneratedActions.getManyOrganizationRisks, (state: OrganizationRiskState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationRiskGeneratedActions.upsertOneOrganizationRisk, (state: OrganizationRiskState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationRiskGeneratedActions.upsertManyOrganizationRisksSuccess,
    (state: OrganizationRiskState.IState, { organizationRisks }) =>
      OrganizationRiskState.adapter.upsertMany(organizationRisks, setLoadingsState(state, false))
  ),
  on(OrganizationRiskGeneratedActions.deleteOneOrganizationRisk, (state: OrganizationRiskState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationRiskGeneratedActions.deleteOneOrganizationRiskSuccess,
    (state: OrganizationRiskState.IState, { idOrganizationRisk }) =>
      OrganizationRiskState.adapter.removeOne(idOrganizationRisk, setLoadingsState(state, false))
  ),
  on(OrganizationRiskGeneratedActions.clearActivesOrganizationRisks, (state: OrganizationRiskState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationRiskGeneratedActions.addManyActivesOrganizationRisks,
    (state: OrganizationRiskState.IState, { idOrganizationRisks }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationRisks)
    })
  ),
  on(
    OrganizationRiskGeneratedActions.deleteOneActiveOrganizationRisk,
    (state: OrganizationRiskState.IState, { idOrganizationRisk }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationRisk)
    })
  ),

  on(OrganizationRiskGeneratedActions.clearOrganizationRisks, () => OrganizationRiskState.initialState),

  on(
    OrganizationRiskGeneratedActions.addManyCommunityRiskSuccess,
    (state: OrganizationRiskState.IState, { idOrganizationRisk, idCommunityRisks }) => {
      if (!state.entities[idOrganizationRisk]) {
        return state;
      }
      const communityRisks = (state.entities[idOrganizationRisk]?.communityRisks as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationRisk]: {
            ...state.entities[idOrganizationRisk],
            communityRisks: communityRisks.concat(idCommunityRisks.filter(id => communityRisks.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationRiskGeneratedActions.deleteManyCommunityRiskSuccess,
    (state: OrganizationRiskState.IState, { idCommunityRisks, idOrganizationRisks }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationRisks.reduce((entities, idOrganizationRisk) => {
            if (!state.entities[idOrganizationRisk]?.communityRisks) {
              return entities;
            }
            entities[idOrganizationRisk] = {
              ...state.entities[idOrganizationRisk],
              communityRisks: (state.entities[idOrganizationRisk]?.communityRisks as number[])?.filter(
                (idCommunityRisk: number) => !idCommunityRisks.some((id: number) => id === idCommunityRisk)
              )
            } as OrganizationRiskEntityState;
            return entities;
          }, {} as Dictionary<OrganizationRiskEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationRiskGeneratedActions.addOrganizationSuccess,
    (state: OrganizationRiskState.IState, { idOrganizationRisk, idOrganization }) => {
      if (!state.entities[idOrganizationRisk]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationRisk]: {
            ...state.entities[idOrganizationRisk],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationRiskGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationRiskState.IState, { idOrganizations, idOrganizationRisks }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationRisks.reduce((entities, idOrganizationRisk) => {
            if (!state.entities[idOrganizationRisk]?.organization) {
              return entities;
            }
            entities[idOrganizationRisk] = {
              ...state.entities[idOrganizationRisk],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationRisk]?.organization
              )
                ? undefined
                : state.entities[idOrganizationRisk]?.organization
            } as OrganizationRiskEntityState;
            return entities;
          }, {} as Dictionary<OrganizationRiskEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationRiskGeneratedActions.addOrganizationFamilySuccess,
    (state: OrganizationRiskState.IState, { idOrganizationRisk, idOrganizationFamily }) => {
      if (!state.entities[idOrganizationRisk]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationRisk]: {
            ...state.entities[idOrganizationRisk],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationRiskGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationRiskState.IState, { idOrganizationFamilys, idOrganizationRisks }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationRisks.reduce((entities, idOrganizationRisk) => {
            if (!state.entities[idOrganizationRisk]?.organizationFamily) {
              return entities;
            }
            entities[idOrganizationRisk] = {
              ...state.entities[idOrganizationRisk],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idOrganizationRisk]?.organizationFamily
              )
                ? undefined
                : state.entities[idOrganizationRisk]?.organizationFamily
            } as OrganizationRiskEntityState;
            return entities;
          }, {} as Dictionary<OrganizationRiskEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationRiskState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationRiskState.IState {
  return { ...state, isLoaded, isLoading };
}
