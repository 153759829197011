import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityModule, CommunityModuleEntityState } from '@api/api-interfaces';
import { CommunityModuleApiService } from '@wip/store/api-services';
import { CommunityModuleGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityModule } from '@wip/store/configs/normalization';
import { CommunityModuleSelectors } from '@wip/store/selectors';
import { CommunityModuleRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { ProjectModuleGeneratedActions } from '@wip/store/actions';

export function getDefaultAddCommunityModuleActions(
  communityModule: CommunityModuleEntityState,
  ids?: CommunityModuleRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityModuleGeneratedActions.normalizeManyCommunityModulesAfterUpsert({ communityModules: [communityModule] })
  ];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityModuleSuccess({
        idCommunity: ids.community,
        idCommunityModules: [communityModule.idCommunityModule]
      })
    );
    actions.push(
      CommunityModuleGeneratedActions.addCommunitySuccess({
        idCommunityModule: communityModule.idCommunityModule,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.projectModule) {
    actions.push(
      ProjectModuleGeneratedActions.addManyCommunityModuleSuccess({
        idProjectModule: ids.projectModule,
        idCommunityModules: [communityModule.idCommunityModule]
      })
    );
    actions.push(
      CommunityModuleGeneratedActions.addProjectModuleSuccess({
        idCommunityModule: communityModule.idCommunityModule,
        idProjectModule: ids.projectModule
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCommunityModuleActions(communityModule: CommunityModuleEntityState): Action[] {
  const actions: Action[] = [
    CommunityModuleGeneratedActions.deleteOneCommunityModuleSuccess({
      idCommunityModule: communityModule.idCommunityModule
    })
  ];

  if (communityModule.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityModuleSuccess({
        idCommunityModules: [communityModule.idCommunityModule],
        idCommunities: [communityModule.community as number]
      })
    );
  }

  if (communityModule.projectModule) {
    actions.push(
      ProjectModuleGeneratedActions.deleteManyCommunityModuleSuccess({
        idCommunityModules: [communityModule.idCommunityModule],
        idProjectModules: [communityModule.projectModule as number]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityModuleEffects {
  constructor(
    protected actions$: Actions,
    protected communityModuleApiService: CommunityModuleApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityModules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityModuleGeneratedActions.getManyCommunityModules),
      switchMap(({ params }) =>
        this.communityModuleApiService.getCommunityModules(params).pipe(
          map((communityModules: CommunityModule[]) => {
            return CommunityModuleGeneratedActions.normalizeManyCommunityModulesAfterUpsert({ communityModules });
          }),
          catchError(error => of(CommunityModuleGeneratedActions.communityModulesFailure({ error })))
        )
      )
    );
  });

  getOneCommunityModule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityModuleGeneratedActions.getOneCommunityModule),
      switchMap(idCommunityModule =>
        this.communityModuleApiService.getCommunityModule(idCommunityModule).pipe(
          map((communityModule: CommunityModule) => {
            return CommunityModuleGeneratedActions.normalizeManyCommunityModulesAfterUpsert({
              communityModules: [communityModule]
            });
          }),
          catchError(error => of(CommunityModuleGeneratedActions.communityModulesFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityModule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityModuleGeneratedActions.upsertOneCommunityModule),
      concatMap(
        ({
          communityModule,
          ids
        }: {
          communityModule: Partial<CommunityModule>;
          ids?: CommunityModuleRelationsIds;
        }) => {
          if (communityModule.idCommunityModule) {
            return this.communityModuleApiService.updateCommunityModule(communityModule).pipe(
              map((communityModuleReturned: CommunityModule) => {
                return CommunityModuleGeneratedActions.normalizeManyCommunityModulesAfterUpsert({
                  communityModules: [communityModuleReturned]
                });
              }),
              catchError(error => of(CommunityModuleGeneratedActions.communityModulesFailure({ error })))
            );
          } else {
            return this.communityModuleApiService.addCommunityModule(communityModule).pipe(
              mergeMap((communityModuleReturned: CommunityModule) =>
                getDefaultAddCommunityModuleActions(communityModuleReturned, ids)
              ),
              catchError(error => of(CommunityModuleGeneratedActions.communityModulesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCommunityModule$ = createEffect(() => {
    const selectCommunityModuleState$ = this.store$.select(CommunityModuleSelectors.selectCommunityModuleState);
    return this.actions$.pipe(
      ofType(CommunityModuleGeneratedActions.deleteOneCommunityModule),
      withLatestFrom(selectCommunityModuleState$),
      concatMap(([{ idCommunityModule }, state]) =>
        this.communityModuleApiService.deleteCommunityModule(idCommunityModule).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityModuleActions(state.entities[idCommunityModule] as CommunityModuleEntityState)
          ),
          catchError(error => of(CommunityModuleGeneratedActions.communityModulesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityModulesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityModuleGeneratedActions.normalizeManyCommunityModulesAfterUpsert),
      concatMap(({ communityModules }) => {
        const actions: Action[] = getActionsToNormalizeCommunityModule(communityModules, StoreActionType.upsert);
        return [getMultiAction(actions, '[CommunityModule] Normalization After Upsert Success')];
      })
    );
  });
}
