import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MeetingUser, MeetingUserEntityState } from '@api/api-interfaces';
import { MeetingUserRelationsIds } from '@wip/store/ids-interfaces';

export const MeetingUserGeneratedActions = createActionGroup({
  source: 'Meeting User Generated',
  events: {
    'Get One Meeting User': props<{ idMeetingUser: number; params?: any }>(),
    'Get Many Meeting Users': props<{ params: any }>(),
    'Add Many Actives Meeting Users': props<{ idMeetingUsers: number[] }>(),
    'Delete One Active Meeting User': props<{ idMeetingUser: number }>(),
    'Clear Actives Meeting Users': emptyProps(),
    'Upsert One Meeting User': props<{ meetingUser: Partial<MeetingUser>; ids?: MeetingUserRelationsIds }>(),
    'Upsert Many Meeting Users': props<{ meetingUsers: Partial<MeetingUser>[]; ids?: MeetingUserRelationsIds }>(),
    'Upsert Many Meeting Users Success': props<{ meetingUsers: MeetingUserEntityState[] }>(),
    'Delete One Meeting User': props<{ idMeetingUser: number }>(),
    'Delete One Meeting User Success': props<{ idMeetingUser: number }>(),
    'Normalize Many Meeting Users After Upsert': props<{ meetingUsers: MeetingUserEntityState[] }>(),
    'Meeting Users Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Meeting Users': emptyProps(),
    'Add Meeting Success': props<{ idMeetingUser: number; idMeeting: number }>(),
    'Delete Many Meeting Success': props<{ idMeetings: number[]; idMeetingUsers: number[] }>(),
    'Add User Success': props<{ idMeetingUser: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idMeetingUsers: number[] }>()
  }
});
