import { Injectable } from '@angular/core';
import { User } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedUserApiService } from './user-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends GeneratedUserApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getCurrentUser(idUser: number): Observable<User[]> {
    return this.repo.getData<User[]>('user/currentUser', { idUser });
  }
}
