import { RepositoryService } from '@wip/services/repository';
import { OrganizationUser } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationUserApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationUsers(params?: any): Observable<OrganizationUser[]> {
    return this.repo.getData<OrganizationUser[]>('organization-user', params);
  }

  public getOrganizationUser(params: { idOrganizationUser: number; params?: any }): Observable<OrganizationUser> {
    return this.repo.getData<OrganizationUser>('organization-user/' + params.idOrganizationUser, params.params);
  }

  public addOrganizationUser(organizationUser: Partial<OrganizationUser>): Observable<OrganizationUser> {
    return this.repo.create<OrganizationUser>('organization-user', organizationUser);
  }

  public updateOrganizationUser(organizationUser: Partial<OrganizationUser>): Observable<OrganizationUser> {
    return this.repo.update('organization-user', organizationUser);
  }

  public deleteOrganizationUser(idOrganizationUser: number): Observable<number> {
    return this.repo.delete('organization-user/' + +idOrganizationUser);
  }
}
