@if ( (community$ | async) ?? { communityUserProfil: [], communityCaracteristics:[]}; as community) {
<div>
  <div class="project-description-and-export-button">
    <div class="description" style="max-width: 400px">{{ community?.description }}</div>
    <div class="export-button">
      <i
        class="icon icon-ic_fluent_cloud_download_24_regular"
        style="font-size: 20px; cursor: pointer"
        [matMenuTriggerFor]="exportMenu"
        matTooltip="Exporter"
      ></i>
      <mat-menu #exportMenu>
        <button mat-menu-item (click)="exportToDocx()">
          Exporter synthèse de ce {{wordingTypeStructure | lowercase}}
        </button>
        <button mat-menu-item (click)="exportToDocx(true)">
          Exporter synthèse de plusieurs {{wordingTypeStructure | lowercase}}s
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="container">
    <div class="team-milestone">
      <wip-project-team [canEdit]="canEdit" [community]="community" />
      <wip-project-milestones
        [canEdit]="canEdit"
        [community]="community"
        [organizationUser]="organizationUser$ | async"
        (updateStatus)="updateStatus($event)"
        (updateElement)="updateElement($event)"
      />
    </div>
    <div clas="prog-caract">
      <wip-project-programme [community]="community" />
      <wip-project-caracteristics [canEdit]="canEdit" [community]="community" />
    </div>
  </div>
</div>
}
