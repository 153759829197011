/* eslint-disable @nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppResolver } from '@_app/app.resolver';
import { LoginPageResolver } from './auth/login/login-page.resolver';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    resolve: {
      login: LoginPageResolver
    }
  },
  {
    path: 'app',
    loadChildren: () => import('./side-menu/side-menu.module').then(m => m.SideMenuModule),
    resolve: {
      app: AppResolver
    }
  },
  {
    path: 'meetings',
    loadChildren: () => import('./meeting/meeting.module').then(m => m.MeetingModule),
    data: { module: 'meetings' }
  },
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/auth/login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
      bindToComponentInputs: true
    })
  ],
  exports: [RouterModule],
  providers: [AppResolver]
})
export class AppRoutingModule {}
