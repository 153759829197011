import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { isString } from 'lodash-es';

@Component({
  selector: 'wip-avatar-renderer',
  templateUrl: './ag-grid-avatar-renderer.component.html',
  styleUrls: ['./ag-grid-avatar-renderer.component.scss']
})
export class AvatarRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = isString(params?.value) ? params?.valueFormatted : params?.value;
  }

  // demonstrates how you can do 'inline' editing of a cell

  refresh(_params: any): boolean {
    return false;
  }
}
