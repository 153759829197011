import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { UserProfilRight, UserProfilRightEntityState } from '@api/api-interfaces';
import { UserProfilRightApiService } from '@wip/store/api-services';
import { UserProfilRightGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeUserProfilRight } from '@wip/store/configs/normalization';
import { UserProfilRightSelectors } from '@wip/store/selectors';
import { UserProfilRightRelationsIds } from '@wip/store/ids-interfaces';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { OrganizationUserProfilGeneratedActions } from '@wip/store/actions';

export function getDefaultAddUserProfilRightActions(
  userProfilRight: UserProfilRightEntityState,
  ids?: UserProfilRightRelationsIds
): Action[] {
  const actions: Action[] = [
    UserProfilRightGeneratedActions.normalizeManyUserProfilRightsAfterUpsert({ userProfilRights: [userProfilRight] })
  ];

  if (ids?.profil) {
    actions.push(
      ProfilGeneratedActions.addManyUserProfilRightSuccess({
        idProfil: ids.profil,
        idUserProfilRights: [userProfilRight.idUserProfilRight]
      })
    );
    actions.push(
      UserProfilRightGeneratedActions.addProfilSuccess({
        idUserProfilRight: userProfilRight.idUserProfilRight,
        idProfil: ids.profil
      })
    );
  }

  if (ids?.organizationUserProfil) {
    actions.push(
      OrganizationUserProfilGeneratedActions.addManyUserProfilRightSuccess({
        idOrganizationUserProfil: ids.organizationUserProfil,
        idUserProfilRights: [userProfilRight.idUserProfilRight]
      })
    );
    actions.push(
      UserProfilRightGeneratedActions.addOrganizationUserProfilSuccess({
        idUserProfilRight: userProfilRight.idUserProfilRight,
        idOrganizationUserProfil: ids.organizationUserProfil
      })
    );
  }

  return actions;
}

export function getDefaultDeleteUserProfilRightActions(userProfilRight: UserProfilRightEntityState): Action[] {
  const actions: Action[] = [
    UserProfilRightGeneratedActions.deleteOneUserProfilRightSuccess({
      idUserProfilRight: userProfilRight.idUserProfilRight
    })
  ];

  if (userProfilRight.profil) {
    actions.push(
      ProfilGeneratedActions.deleteManyUserProfilRightSuccess({
        idUserProfilRights: [userProfilRight.idUserProfilRight],
        idProfils: [userProfilRight.profil as number]
      })
    );
  }

  if (userProfilRight.organizationUserProfil) {
    actions.push(
      OrganizationUserProfilGeneratedActions.deleteManyUserProfilRightSuccess({
        idUserProfilRights: [userProfilRight.idUserProfilRight],
        idOrganizationUserProfils: [userProfilRight.organizationUserProfil as number]
      })
    );
  }

  return actions;
}

export class GeneratedUserProfilRightEffects {
  constructor(
    protected actions$: Actions,
    protected userProfilRightApiService: UserProfilRightApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUserProfilRights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfilRightGeneratedActions.getManyUserProfilRights),
      switchMap(({ params }) =>
        this.userProfilRightApiService.getUserProfilRights(params).pipe(
          map((userProfilRights: UserProfilRight[]) => {
            return UserProfilRightGeneratedActions.normalizeManyUserProfilRightsAfterUpsert({ userProfilRights });
          }),
          catchError(error => of(UserProfilRightGeneratedActions.userProfilRightsFailure({ error })))
        )
      )
    );
  });

  getOneUserProfilRight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfilRightGeneratedActions.getOneUserProfilRight),
      switchMap(idUserProfilRight =>
        this.userProfilRightApiService.getUserProfilRight(idUserProfilRight).pipe(
          map((userProfilRight: UserProfilRight) => {
            return UserProfilRightGeneratedActions.normalizeManyUserProfilRightsAfterUpsert({
              userProfilRights: [userProfilRight]
            });
          }),
          catchError(error => of(UserProfilRightGeneratedActions.userProfilRightsFailure({ error })))
        )
      )
    );
  });

  upsertOneUserProfilRight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfilRightGeneratedActions.upsertOneUserProfilRight),
      concatMap(
        ({
          userProfilRight,
          ids
        }: {
          userProfilRight: Partial<UserProfilRight>;
          ids?: UserProfilRightRelationsIds;
        }) => {
          if (userProfilRight.idUserProfilRight) {
            return this.userProfilRightApiService.updateUserProfilRight(userProfilRight).pipe(
              map((userProfilRightReturned: UserProfilRight) => {
                return UserProfilRightGeneratedActions.normalizeManyUserProfilRightsAfterUpsert({
                  userProfilRights: [userProfilRightReturned]
                });
              }),
              catchError(error => of(UserProfilRightGeneratedActions.userProfilRightsFailure({ error })))
            );
          } else {
            return this.userProfilRightApiService.addUserProfilRight(userProfilRight).pipe(
              mergeMap((userProfilRightReturned: UserProfilRight) =>
                getDefaultAddUserProfilRightActions(userProfilRightReturned, ids)
              ),
              catchError(error => of(UserProfilRightGeneratedActions.userProfilRightsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneUserProfilRight$ = createEffect(() => {
    const selectUserProfilRightState$ = this.store$.select(UserProfilRightSelectors.selectUserProfilRightState);
    return this.actions$.pipe(
      ofType(UserProfilRightGeneratedActions.deleteOneUserProfilRight),
      withLatestFrom(selectUserProfilRightState$),
      concatMap(([{ idUserProfilRight }, state]) =>
        this.userProfilRightApiService.deleteUserProfilRight(idUserProfilRight).pipe(
          mergeMap(_success =>
            getDefaultDeleteUserProfilRightActions(state.entities[idUserProfilRight] as UserProfilRightEntityState)
          ),
          catchError(error => of(UserProfilRightGeneratedActions.userProfilRightsFailure({ error })))
        )
      )
    );
  });

  normalizeManyUserProfilRightsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfilRightGeneratedActions.normalizeManyUserProfilRightsAfterUpsert),
      concatMap(({ userProfilRights }) => {
        const actions: Action[] = getActionsToNormalizeUserProfilRight(userProfilRights, StoreActionType.upsert);
        return [getMultiAction(actions, '[UserProfilRight] Normalization After Upsert Success')];
      })
    );
  });
}
