import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { MeetingGeneratedActions } from '@wip/store/actions';
import { MeetingState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { MeetingEntityState } from '@api/api-interfaces';

export const meetingReducersGeneratedFunctions: ReducerTypes<MeetingState.IState, readonly ActionCreator[]>[] = [
  on(MeetingGeneratedActions.getOneMeeting, (state: MeetingState.IState) => setLoadingsState(state, true)),
  on(MeetingGeneratedActions.getManyMeetings, (state: MeetingState.IState) => setLoadingsState(state, true)),
  on(MeetingGeneratedActions.upsertOneMeeting, (state: MeetingState.IState) => setLoadingsState(state, true)),

  on(MeetingGeneratedActions.upsertManyMeetingsSuccess, (state: MeetingState.IState, { meetings }) =>
    MeetingState.adapter.upsertMany(meetings, setLoadingsState(state, false))
  ),
  on(MeetingGeneratedActions.deleteOneMeeting, (state: MeetingState.IState) => setLoadingsState(state, true)),
  on(MeetingGeneratedActions.deleteOneMeetingSuccess, (state: MeetingState.IState, { idMeeting }) =>
    MeetingState.adapter.removeOne(idMeeting, setLoadingsState(state, false))
  ),
  on(MeetingGeneratedActions.clearActivesMeetings, (state: MeetingState.IState) => ({ ...state, actives: [] })),
  on(MeetingGeneratedActions.addManyActivesMeetings, (state: MeetingState.IState, { idMeetings }) => ({
    ...state,
    actives: state.actives.concat(idMeetings)
  })),
  on(MeetingGeneratedActions.deleteOneActiveMeeting, (state: MeetingState.IState, { idMeeting }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idMeeting)
  })),

  on(MeetingGeneratedActions.clearMeetings, () => MeetingState.initialState),

  on(
    MeetingGeneratedActions.addManyMeetingElementSuccess,
    (state: MeetingState.IState, { idMeeting, idMeetingElements }) => {
      if (!state.entities[idMeeting]) {
        return state;
      }
      const meetingElements = (state.entities[idMeeting]?.meetingElements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idMeeting]: {
            ...state.entities[idMeeting],
            meetingElements: meetingElements.concat(idMeetingElements.filter(id => meetingElements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    MeetingGeneratedActions.deleteManyMeetingElementSuccess,
    (state: MeetingState.IState, { idMeetingElements, idMeetings }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idMeetings.reduce((entities, idMeeting) => {
            if (!state.entities[idMeeting]?.meetingElements) {
              return entities;
            }
            entities[idMeeting] = {
              ...state.entities[idMeeting],
              meetingElements: (state.entities[idMeeting]?.meetingElements as number[])?.filter(
                (idMeetingElement: number) => !idMeetingElements.some((id: number) => id === idMeetingElement)
              )
            } as MeetingEntityState;
            return entities;
          }, {} as Dictionary<MeetingEntityState>)
        }
      };
    }
  ),

  on(MeetingGeneratedActions.addManyMeetingUserSuccess, (state: MeetingState.IState, { idMeeting, idMeetingUsers }) => {
    if (!state.entities[idMeeting]) {
      return state;
    }
    const meetingUsers = (state.entities[idMeeting]?.meetingUsers as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idMeeting]: {
          ...state.entities[idMeeting],
          meetingUsers: meetingUsers.concat(idMeetingUsers.filter(id => meetingUsers.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    MeetingGeneratedActions.deleteManyMeetingUserSuccess,
    (state: MeetingState.IState, { idMeetingUsers, idMeetings }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idMeetings.reduce((entities, idMeeting) => {
            if (!state.entities[idMeeting]?.meetingUsers) {
              return entities;
            }
            entities[idMeeting] = {
              ...state.entities[idMeeting],
              meetingUsers: (state.entities[idMeeting]?.meetingUsers as number[])?.filter(
                (idMeetingUser: number) => !idMeetingUsers.some((id: number) => id === idMeetingUser)
              )
            } as MeetingEntityState;
            return entities;
          }, {} as Dictionary<MeetingEntityState>)
        }
      };
    }
  ),

  on(MeetingGeneratedActions.addCommunitySuccess, (state: MeetingState.IState, { idMeeting, idCommunity }) => {
    if (!state.entities[idMeeting]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idMeeting]: {
          ...state.entities[idMeeting],
          community: idCommunity
        }
      }
    };
  }),

  on(
    MeetingGeneratedActions.deleteManyCommunitySuccess,
    (state: MeetingState.IState, { idCommunities, idMeetings }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idMeetings.reduce((entities, idMeeting) => {
            if (!state.entities[idMeeting]?.community) {
              return entities;
            }
            entities[idMeeting] = {
              ...state.entities[idMeeting],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idMeeting]?.community
              )
                ? undefined
                : state.entities[idMeeting]?.community
            } as MeetingEntityState;
            return entities;
          }, {} as Dictionary<MeetingEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: MeetingState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): MeetingState.IState {
  return { ...state, isLoaded, isLoading };
}
