import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedOrganizationUserProfilService } from './organization-user-profil-generated.service';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class OrganizationUserProfilService extends GeneratedOrganizationUserProfilService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}
