import { Injectable } from '@angular/core';import { RepositoryService } from '@wip/services/repository';
import { GeneratedOrganizationCaracteristicValueApiService } from './organization-caracteristic-value-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationCaracteristicValueApiService extends GeneratedOrganizationCaracteristicValueApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
