/* eslint-disable @nx/enforce-module-boundaries */
import { GoogleMapsEmbedModule } from '@_app/shared/google-maps-embed/google-maps-embed.module';
import { SharedModule } from '@_app/shared/shared.module';
import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectCaracteristicsComponent } from './project-caracteristics/project-caracteristics.component';
import { ProjectDashboardComponent } from './project-dashboard.container';
import { ProjectDashboardRoutingModule } from './project-dashboard.routing';
import { ProjectMilestonesComponent } from './project-milestones/project-milestones.component';
import { ProjectTeamComponent } from './project-team/project-team.component';
import { ProjectProgrammeComponent } from './project-programme/project-programme.component';
// eslint-disable-next-line @nx/enforce-module-boundaries

@NgModule({
  imports: [
    TranslateModule.forChild(),
    SharedModule.forRoot(),
    ProjectDashboardRoutingModule,
    AgGridModule,
    GoogleMapsEmbedModule,
    CommonModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ProjectProgrammeComponent
  ],
  declarations: [
    ProjectTeamComponent,
    ProjectDashboardComponent,
    ProjectCaracteristicsComponent,
    ProjectMilestonesComponent
  ],
  exports: [ProjectDashboardComponent, ProjectTeamComponent],
  providers: [{ provide: MatDialogRef, useValue: {} }]
})
export class ProjectDashBoardModule {}
