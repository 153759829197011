import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommunityMilestoneApiService } from '@wip/store/api-services';
import { GeneratedCommunityMilestoneEffects } from './community-milestone-generated.effects';

@Injectable()
export class CommunityMilestoneEffects extends GeneratedCommunityMilestoneEffects {
  constructor(actions$: Actions, communityMilestoneApiService: CommunityMilestoneApiService, store$: Store<AppState>) {
    super(actions$, communityMilestoneApiService, store$);
  }
}
