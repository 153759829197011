import { RepositoryService } from '@wip/services/repository';
import { Folder } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedFolderApiService {
  constructor(protected repo: RepositoryService) {}

  public getFolders(params?: any): Observable<Folder[]> {
    return this.repo.getData<Folder[]>('folder', params);
  }

  public getFolder(params: { idFolder: number; params?: any }): Observable<Folder> {
    return this.repo.getData<Folder>('folder/' + params.idFolder, params.params);
  }

  public addFolder(folder: Partial<Folder>): Observable<Folder> {
    return this.repo.create<Folder>('folder', folder);
  }

  public updateFolder(folder: Partial<Folder>): Observable<Folder> {
    return this.repo.update('folder', folder);
  }

  public deleteFolder(idFolder: number): Observable<number> {
    return this.repo.delete('folder/' + +idFolder);
  }
}
