import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedGanttLinkApiService } from './gantt-link-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class GanttLinkApiService extends GeneratedGanttLinkApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}

