import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Folder, FolderEntityState } from '@api/api-interfaces';
import { FolderRelationsIds } from '@wip/store/ids-interfaces';

export const FolderGeneratedActions = createActionGroup({
  source: 'Folder Generated',
  events: {
    'Get One Folder': props<{ idFolder: number; params?: any }>(),
    'Get Many Folders': props<{ params: any }>(),
    'Add Many Actives Folders': props<{ idFolders: number[] }>(),
    'Delete One Active Folder': props<{ idFolder: number }>(),
    'Clear Actives Folders': emptyProps(),
    'Upsert One Folder': props<{ folder: Partial<Folder>; ids?: FolderRelationsIds }>(),
    'Upsert Many Folders': props<{ folders: Partial<Folder>[]; ids?: FolderRelationsIds }>(),
    'Upsert Many Folders Success': props<{ folders: FolderEntityState[] }>(),
    'Delete One Folder': props<{ idFolder: number }>(),
    'Delete One Folder Success': props<{ idFolder: number }>(),
    'Normalize Many Folders After Upsert': props<{ folders: FolderEntityState[] }>(),
    'Folders Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Folders': emptyProps(),
    'Add Many Folder Element Success': props<{ idFolder: number; idFolderElements: number[] }>(),
    'Delete Many Folder Element Success': props<{ idFolderElements: number[]; idFolders: number[] }>(),
    'Add Community Success': props<{ idFolder: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idFolders: number[] }>()
  }
});
