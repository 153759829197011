import { RepositoryService } from '@wip/services/repository';
import { ElementCash } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedElementCashApiService {
  constructor(protected repo: RepositoryService) {}

  public getElementCashs(params?: any): Observable<ElementCash[]> {
    return this.repo.getData<ElementCash[]>('element-cash', params);
  }

  public getElementCash(params: { idElementCash: number; params?: any }): Observable<ElementCash> {
    return this.repo.getData<ElementCash>('element-cash/' + params.idElementCash, params.params);
  }

  public addElementCash(elementCash: Partial<ElementCash>): Observable<ElementCash> {
    return this.repo.create<ElementCash>('element-cash', elementCash);
  }

  public updateElementCash(elementCash: Partial<ElementCash>): Observable<ElementCash> {
    return this.repo.update('element-cash', elementCash);
  }

  public deleteElementCash(idElementCash: number): Observable<number> {
    return this.repo.delete('element-cash/' + +idElementCash);
  }
}
