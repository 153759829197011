import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { CommunityApiService } from '@wip/store/api-services';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunity } from '@wip/store/configs/normalization';
import { CommunitySelectors } from '@wip/store/selectors';
import { CommunityRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationStepGeneratedActions } from '@wip/store/actions';
import { CommunityUserGeneratedActions } from '@wip/store/actions';
import { CommunityUser } from '@api/api-interfaces';
import { CommunityUserProfilGeneratedActions } from '@wip/store/actions';
import { CommunityUserProfil } from '@api/api-interfaces';
import { CommunityCaracteristicGeneratedActions } from '@wip/store/actions';
import { CommunityCaracteristic } from '@api/api-interfaces';
import { CommunityKpiGeneratedActions } from '@wip/store/actions';
import { CommunityKpi } from '@api/api-interfaces';
import { CommunityDataRowGeneratedActions } from '@wip/store/actions';
import { CommunityDataRow } from '@api/api-interfaces';
import { CommunityGroupGeneratedActions } from '@wip/store/actions';
import { CommunityGroup } from '@api/api-interfaces';
import { CommunityMilestoneGeneratedActions } from '@wip/store/actions';
import { CommunityMilestone } from '@api/api-interfaces';
import { CommunityMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { CommunityMilestoneFamily } from '@api/api-interfaces';
import { CommunityRiskGeneratedActions } from '@wip/store/actions';
import { CommunityRisk } from '@api/api-interfaces';
import { CommunitySubFamilyGeneratedActions } from '@wip/store/actions';
import { CommunitySubFamily } from '@api/api-interfaces';
import { CommunityModuleGeneratedActions } from '@wip/store/actions';
import { CommunityModule } from '@api/api-interfaces';
import { ProjectElementGeneratedActions } from '@wip/store/actions';
import { ProjectElement } from '@api/api-interfaces';
import { GanttLinkGeneratedActions } from '@wip/store/actions';
import { GanttLink } from '@api/api-interfaces';
import { FolderGeneratedActions } from '@wip/store/actions';
import { Folder } from '@api/api-interfaces';
import { TodoGeneratedActions } from '@wip/store/actions';
import { Todo } from '@api/api-interfaces';
import { MeetingGeneratedActions } from '@wip/store/actions';
import { Meeting } from '@api/api-interfaces';
import { TimelineElementGeneratedActions } from '@wip/store/actions';
import { TimelineElement } from '@api/api-interfaces';
import { NotesHistoryGeneratedActions } from '@wip/store/actions';
import { NotesHistory } from '@api/api-interfaces';
import { ProjectModuleGeneratedActions } from '@wip/store/actions';
import { ProjectModule } from '@api/api-interfaces';
import { GroupGeneratedActions } from '@wip/store/actions';
import { Group } from '@api/api-interfaces';
import { UserGeneratedActions } from '@wip/store/actions';
import { User } from '@api/api-interfaces';

export function getDefaultAddCommunityActions(community: CommunityEntityState, ids?: CommunityRelationsIds): Action[] {
  const actions: Action[] = [
    CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities: [community] })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyCommunitySuccess({
        idOrganization: ids.organization,
        idCommunities: [community.idCommunity]
      })
    );
    actions.push(
      CommunityGeneratedActions.addOrganizationSuccess({
        idCommunity: community.idCommunity,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyCommunitySuccess({
        idOrganizationFamily: ids.organizationFamily,
        idCommunities: [community.idCommunity]
      })
    );
    actions.push(
      CommunityGeneratedActions.addOrganizationFamilySuccess({
        idCommunity: community.idCommunity,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.organizationStep) {
    actions.push(
      OrganizationStepGeneratedActions.addManyCommunitySuccess({
        idOrganizationStep: ids.organizationStep,
        idCommunities: [community.idCommunity]
      })
    );
    actions.push(
      CommunityGeneratedActions.addOrganizationStepSuccess({
        idCommunity: community.idCommunity,
        idOrganizationStep: ids.organizationStep
      })
    );
  }

  if (ids?.communityUsers) {
    if (!Array.isArray(ids.communityUsers)) {
      actions.push(
        CommunityUserGeneratedActions.upsertOneCommunityUser({
          communityUser: {
            idCommunity: community.idCommunity,
            idCommunityUser: ids.communityUsers as number
          } as CommunityUser
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityUserSuccess({
          idCommunity: community.idCommunity,
          idCommunityUsers: [ids.communityUsers as number]
        })
      );
    } else {
      actions.push(
        CommunityUserGeneratedActions.upsertManyCommunityUsers({
          communityUsers: (ids.communityUsers as number[]).map((idCommunityUser: number) => ({
            idCommunity: community.idCommunity,
            idCommunityUser
          })) as CommunityUser[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityUserSuccess({
          idCommunity: community.idCommunity,
          idCommunityUsers: ids.communityUsers as number[]
        })
      );
    }
  }

  if (ids?.communityUserProfils) {
    if (!Array.isArray(ids.communityUserProfils)) {
      actions.push(
        CommunityUserProfilGeneratedActions.upsertOneCommunityUserProfil({
          communityUserProfil: {
            idCommunity: community.idCommunity,
            idCommunityUserProfil: ids.communityUserProfils as number
          } as CommunityUserProfil
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityUserProfilSuccess({
          idCommunity: community.idCommunity,
          idCommunityUserProfils: [ids.communityUserProfils as number]
        })
      );
    } else {
      actions.push(
        CommunityUserProfilGeneratedActions.upsertManyCommunityUserProfils({
          communityUserProfils: (ids.communityUserProfils as number[]).map((idCommunityUserProfil: number) => ({
            idCommunity: community.idCommunity,
            idCommunityUserProfil
          })) as CommunityUserProfil[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityUserProfilSuccess({
          idCommunity: community.idCommunity,
          idCommunityUserProfils: ids.communityUserProfils as number[]
        })
      );
    }
  }

  if (ids?.communityCaracteristics) {
    if (!Array.isArray(ids.communityCaracteristics)) {
      actions.push(
        CommunityCaracteristicGeneratedActions.upsertOneCommunityCaracteristic({
          communityCaracteristic: {
            idCommunity: community.idCommunity,
            idCommunityCaracteristic: ids.communityCaracteristics as number
          } as CommunityCaracteristic
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityCaracteristicSuccess({
          idCommunity: community.idCommunity,
          idCommunityCaracteristics: [ids.communityCaracteristics as number]
        })
      );
    } else {
      actions.push(
        CommunityCaracteristicGeneratedActions.upsertManyCommunityCaracteristics({
          communityCaracteristics: (ids.communityCaracteristics as number[]).map(
            (idCommunityCaracteristic: number) => ({
              idCommunity: community.idCommunity,
              idCommunityCaracteristic
            })
          ) as CommunityCaracteristic[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityCaracteristicSuccess({
          idCommunity: community.idCommunity,
          idCommunityCaracteristics: ids.communityCaracteristics as number[]
        })
      );
    }
  }

  if (ids?.communityKpis) {
    if (!Array.isArray(ids.communityKpis)) {
      actions.push(
        CommunityKpiGeneratedActions.upsertOneCommunityKpi({
          communityKpi: {
            idCommunity: community.idCommunity,
            idCommunityKpi: ids.communityKpis as number
          } as CommunityKpi
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityKpiSuccess({
          idCommunity: community.idCommunity,
          idCommunityKpis: [ids.communityKpis as number]
        })
      );
    } else {
      actions.push(
        CommunityKpiGeneratedActions.upsertManyCommunityKpis({
          communityKpis: (ids.communityKpis as number[]).map((idCommunityKpi: number) => ({
            idCommunity: community.idCommunity,
            idCommunityKpi
          })) as CommunityKpi[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityKpiSuccess({
          idCommunity: community.idCommunity,
          idCommunityKpis: ids.communityKpis as number[]
        })
      );
    }
  }

  if (ids?.communityDataRows) {
    if (!Array.isArray(ids.communityDataRows)) {
      actions.push(
        CommunityDataRowGeneratedActions.upsertOneCommunityDataRow({
          communityDataRow: {
            idCommunity: community.idCommunity,
            idCommunityDataRow: ids.communityDataRows as number
          } as CommunityDataRow
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityDataRowSuccess({
          idCommunity: community.idCommunity,
          idCommunityDataRows: [ids.communityDataRows as number]
        })
      );
    } else {
      actions.push(
        CommunityDataRowGeneratedActions.upsertManyCommunityDataRows({
          communityDataRows: (ids.communityDataRows as number[]).map((idCommunityDataRow: number) => ({
            idCommunity: community.idCommunity,
            idCommunityDataRow
          })) as CommunityDataRow[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityDataRowSuccess({
          idCommunity: community.idCommunity,
          idCommunityDataRows: ids.communityDataRows as number[]
        })
      );
    }
  }

  if (ids?.communityGroups) {
    if (!Array.isArray(ids.communityGroups)) {
      actions.push(
        CommunityGroupGeneratedActions.upsertOneCommunityGroup({
          communityGroup: {
            idCommunity: community.idCommunity,
            idCommunityGroup: ids.communityGroups as number
          } as CommunityGroup
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityGroupSuccess({
          idCommunity: community.idCommunity,
          idCommunityGroups: [ids.communityGroups as number]
        })
      );
    } else {
      actions.push(
        CommunityGroupGeneratedActions.upsertManyCommunityGroups({
          communityGroups: (ids.communityGroups as number[]).map((idCommunityGroup: number) => ({
            idCommunity: community.idCommunity,
            idCommunityGroup
          })) as CommunityGroup[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityGroupSuccess({
          idCommunity: community.idCommunity,
          idCommunityGroups: ids.communityGroups as number[]
        })
      );
    }
  }

  if (ids?.communityMilestones) {
    if (!Array.isArray(ids.communityMilestones)) {
      actions.push(
        CommunityMilestoneGeneratedActions.upsertOneCommunityMilestone({
          communityMilestone: {
            idCommunity: community.idCommunity,
            idCommunityMilestone: ids.communityMilestones as number
          } as CommunityMilestone
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityMilestoneSuccess({
          idCommunity: community.idCommunity,
          idCommunityMilestones: [ids.communityMilestones as number]
        })
      );
    } else {
      actions.push(
        CommunityMilestoneGeneratedActions.upsertManyCommunityMilestones({
          communityMilestones: (ids.communityMilestones as number[]).map((idCommunityMilestone: number) => ({
            idCommunity: community.idCommunity,
            idCommunityMilestone
          })) as CommunityMilestone[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityMilestoneSuccess({
          idCommunity: community.idCommunity,
          idCommunityMilestones: ids.communityMilestones as number[]
        })
      );
    }
  }

  if (ids?.communityMilestoneFamilys) {
    if (!Array.isArray(ids.communityMilestoneFamilys)) {
      actions.push(
        CommunityMilestoneFamilyGeneratedActions.upsertOneCommunityMilestoneFamily({
          communityMilestoneFamily: {
            idCommunity: community.idCommunity,
            idCommunityMilestoneFamily: ids.communityMilestoneFamilys as number
          } as CommunityMilestoneFamily
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityMilestoneFamilySuccess({
          idCommunity: community.idCommunity,
          idCommunityMilestoneFamilys: [ids.communityMilestoneFamilys as number]
        })
      );
    } else {
      actions.push(
        CommunityMilestoneFamilyGeneratedActions.upsertManyCommunityMilestoneFamilys({
          communityMilestoneFamilys: (ids.communityMilestoneFamilys as number[]).map(
            (idCommunityMilestoneFamily: number) => ({
              idCommunity: community.idCommunity,
              idCommunityMilestoneFamily
            })
          ) as CommunityMilestoneFamily[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityMilestoneFamilySuccess({
          idCommunity: community.idCommunity,
          idCommunityMilestoneFamilys: ids.communityMilestoneFamilys as number[]
        })
      );
    }
  }

  if (ids?.communityRisks) {
    if (!Array.isArray(ids.communityRisks)) {
      actions.push(
        CommunityRiskGeneratedActions.upsertOneCommunityRisk({
          communityRisk: {
            idCommunity: community.idCommunity,
            idCommunityRisk: ids.communityRisks as number
          } as CommunityRisk
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityRiskSuccess({
          idCommunity: community.idCommunity,
          idCommunityRisks: [ids.communityRisks as number]
        })
      );
    } else {
      actions.push(
        CommunityRiskGeneratedActions.upsertManyCommunityRisks({
          communityRisks: (ids.communityRisks as number[]).map((idCommunityRisk: number) => ({
            idCommunity: community.idCommunity,
            idCommunityRisk
          })) as CommunityRisk[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityRiskSuccess({
          idCommunity: community.idCommunity,
          idCommunityRisks: ids.communityRisks as number[]
        })
      );
    }
  }

  if (ids?.communitySubFamilys) {
    if (!Array.isArray(ids.communitySubFamilys)) {
      actions.push(
        CommunitySubFamilyGeneratedActions.upsertOneCommunitySubFamily({
          communitySubFamily: {
            idCommunity: community.idCommunity,
            idCommunitySubFamily: ids.communitySubFamilys as number
          } as CommunitySubFamily
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunitySubFamilySuccess({
          idCommunity: community.idCommunity,
          idCommunitySubFamilys: [ids.communitySubFamilys as number]
        })
      );
    } else {
      actions.push(
        CommunitySubFamilyGeneratedActions.upsertManyCommunitySubFamilys({
          communitySubFamilys: (ids.communitySubFamilys as number[]).map((idCommunitySubFamily: number) => ({
            idCommunity: community.idCommunity,
            idCommunitySubFamily
          })) as CommunitySubFamily[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunitySubFamilySuccess({
          idCommunity: community.idCommunity,
          idCommunitySubFamilys: ids.communitySubFamilys as number[]
        })
      );
    }
  }

  if (ids?.communityModules) {
    if (!Array.isArray(ids.communityModules)) {
      actions.push(
        CommunityModuleGeneratedActions.upsertOneCommunityModule({
          communityModule: {
            idCommunity: community.idCommunity,
            idCommunityModule: ids.communityModules as number
          } as CommunityModule
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityModuleSuccess({
          idCommunity: community.idCommunity,
          idCommunityModules: [ids.communityModules as number]
        })
      );
    } else {
      actions.push(
        CommunityModuleGeneratedActions.upsertManyCommunityModules({
          communityModules: (ids.communityModules as number[]).map((idCommunityModule: number) => ({
            idCommunity: community.idCommunity,
            idCommunityModule
          })) as CommunityModule[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyCommunityModuleSuccess({
          idCommunity: community.idCommunity,
          idCommunityModules: ids.communityModules as number[]
        })
      );
    }
  }

  if (ids?.projectElements) {
    if (!Array.isArray(ids.projectElements)) {
      actions.push(
        ProjectElementGeneratedActions.upsertOneProjectElement({
          projectElement: {
            idCommunity: community.idCommunity,
            idProjectElement: ids.projectElements as number
          } as ProjectElement
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyProjectElementSuccess({
          idCommunity: community.idCommunity,
          idProjectElements: [ids.projectElements as number]
        })
      );
    } else {
      actions.push(
        ProjectElementGeneratedActions.upsertManyProjectElements({
          projectElements: (ids.projectElements as number[]).map((idProjectElement: number) => ({
            idCommunity: community.idCommunity,
            idProjectElement
          })) as ProjectElement[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyProjectElementSuccess({
          idCommunity: community.idCommunity,
          idProjectElements: ids.projectElements as number[]
        })
      );
    }
  }

  if (ids?.ganttLinks) {
    if (!Array.isArray(ids.ganttLinks)) {
      actions.push(
        GanttLinkGeneratedActions.upsertOneGanttLink({
          ganttLink: {
            idCommunity: community.idCommunity,
            idGanttLink: ids.ganttLinks as number
          } as GanttLink
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyGanttLinkSuccess({
          idCommunity: community.idCommunity,
          idGanttLinks: [ids.ganttLinks as number]
        })
      );
    } else {
      actions.push(
        GanttLinkGeneratedActions.upsertManyGanttLinks({
          ganttLinks: (ids.ganttLinks as number[]).map((idGanttLink: number) => ({
            idCommunity: community.idCommunity,
            idGanttLink
          })) as GanttLink[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyGanttLinkSuccess({
          idCommunity: community.idCommunity,
          idGanttLinks: ids.ganttLinks as number[]
        })
      );
    }
  }

  if (ids?.folders) {
    if (!Array.isArray(ids.folders)) {
      actions.push(
        FolderGeneratedActions.upsertOneFolder({
          folder: {
            idCommunity: community.idCommunity,
            idFolder: ids.folders as number
          } as Folder
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyFolderSuccess({
          idCommunity: community.idCommunity,
          idFolders: [ids.folders as number]
        })
      );
    } else {
      actions.push(
        FolderGeneratedActions.upsertManyFolders({
          folders: (ids.folders as number[]).map((idFolder: number) => ({
            idCommunity: community.idCommunity,
            idFolder
          })) as Folder[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyFolderSuccess({
          idCommunity: community.idCommunity,
          idFolders: ids.folders as number[]
        })
      );
    }
  }

  if (ids?.todos) {
    if (!Array.isArray(ids.todos)) {
      actions.push(
        TodoGeneratedActions.upsertOneTodo({
          todo: {
            idCommunity: community.idCommunity,
            idTodo: ids.todos as number
          } as Todo
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyTodoSuccess({
          idCommunity: community.idCommunity,
          idTodos: [ids.todos as number]
        })
      );
    } else {
      actions.push(
        TodoGeneratedActions.upsertManyTodos({
          todos: (ids.todos as number[]).map((idTodo: number) => ({
            idCommunity: community.idCommunity,
            idTodo
          })) as Todo[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyTodoSuccess({
          idCommunity: community.idCommunity,
          idTodos: ids.todos as number[]
        })
      );
    }
  }

  if (ids?.meetings) {
    if (!Array.isArray(ids.meetings)) {
      actions.push(
        MeetingGeneratedActions.upsertOneMeeting({
          meeting: {
            idCommunity: community.idCommunity,
            idMeeting: ids.meetings as number
          } as Meeting
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyMeetingSuccess({
          idCommunity: community.idCommunity,
          idMeetings: [ids.meetings as number]
        })
      );
    } else {
      actions.push(
        MeetingGeneratedActions.upsertManyMeetings({
          meetings: (ids.meetings as number[]).map((idMeeting: number) => ({
            idCommunity: community.idCommunity,
            idMeeting
          })) as Meeting[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyMeetingSuccess({
          idCommunity: community.idCommunity,
          idMeetings: ids.meetings as number[]
        })
      );
    }
  }

  if (ids?.timelineElements) {
    if (!Array.isArray(ids.timelineElements)) {
      actions.push(
        TimelineElementGeneratedActions.upsertOneTimelineElement({
          timelineElement: {
            idCommunity: community.idCommunity,
            idTimelineElement: ids.timelineElements as number
          } as TimelineElement
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyTimelineElementSuccess({
          idCommunity: community.idCommunity,
          idTimelineElements: [ids.timelineElements as number]
        })
      );
    } else {
      actions.push(
        TimelineElementGeneratedActions.upsertManyTimelineElements({
          timelineElements: (ids.timelineElements as number[]).map((idTimelineElement: number) => ({
            idCommunity: community.idCommunity,
            idTimelineElement
          })) as TimelineElement[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyTimelineElementSuccess({
          idCommunity: community.idCommunity,
          idTimelineElements: ids.timelineElements as number[]
        })
      );
    }
  }

  if (ids?.notesHistories) {
    if (!Array.isArray(ids.notesHistories)) {
      actions.push(
        NotesHistoryGeneratedActions.upsertOneNotesHistory({
          notesHistory: {
            idCommunity: community.idCommunity,
            idNotesHistory: ids.notesHistories as number
          } as NotesHistory
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyNotesHistorySuccess({
          idCommunity: community.idCommunity,
          idNotesHistories: [ids.notesHistories as number]
        })
      );
    } else {
      actions.push(
        NotesHistoryGeneratedActions.upsertManyNotesHistories({
          notesHistories: (ids.notesHistories as number[]).map((idNotesHistory: number) => ({
            idCommunity: community.idCommunity,
            idNotesHistory
          })) as NotesHistory[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyNotesHistorySuccess({
          idCommunity: community.idCommunity,
          idNotesHistories: ids.notesHistories as number[]
        })
      );
    }
  }

  if (ids?.projectModules) {
    if (!Array.isArray(ids.projectModules)) {
      actions.push(
        ProjectModuleGeneratedActions.upsertOneProjectModule({
          projectModule: {
            idCommunity: community.idCommunity,
            idProjectModule: ids.projectModules as number
          } as ProjectModule & any
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyProjectModuleSuccess({
          idCommunity: community.idCommunity,
          idProjectModules: [ids.projectModules as number]
        })
      );
    } else {
      actions.push(
        ProjectModuleGeneratedActions.upsertManyProjectModules({
          projectModules: (ids.projectModules as number[]).map((idProjectModule: number) => ({
            idCommunity: community.idCommunity,
            idProjectModule
          })) as ProjectModule[] & any[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyProjectModuleSuccess({
          idCommunity: community.idCommunity,
          idProjectModules: ids.projectModules as number[]
        })
      );
    }
  }

  if (ids?.groups) {
    if (!Array.isArray(ids.groups)) {
      actions.push(
        GroupGeneratedActions.upsertOneGroup({
          group: {
            idCommunity: community.idCommunity,
            idGroup: ids.groups as number
          } as Group & any
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyGroupSuccess({
          idCommunity: community.idCommunity,
          idGroups: [ids.groups as number]
        })
      );
    } else {
      actions.push(
        GroupGeneratedActions.upsertManyGroups({
          groups: (ids.groups as number[]).map((idGroup: number) => ({
            idCommunity: community.idCommunity,
            idGroup
          })) as Group[] & any[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyGroupSuccess({
          idCommunity: community.idCommunity,
          idGroups: ids.groups as number[]
        })
      );
    }
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        UserGeneratedActions.upsertOneUser({
          user: {
            idCommunity: community.idCommunity,
            idUser: ids.users as number
          } as User & any
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyUserSuccess({
          idCommunity: community.idCommunity,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        UserGeneratedActions.upsertManyUsers({
          users: (ids.users as number[]).map((idUser: number) => ({
            idCommunity: community.idCommunity,
            idUser
          })) as User[] & any[]
        })
      );
      actions.push(
        CommunityGeneratedActions.addManyUserSuccess({
          idCommunity: community.idCommunity,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCommunityActions(community: CommunityEntityState): Action[] {
  const actions: Action[] = [
    CommunityGeneratedActions.deleteOneCommunitySuccess({ idCommunity: community.idCommunity })
  ];

  if (community.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idOrganizations: [community.organization as number]
      })
    );
  }

  if (community.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idOrganizationFamilys: [community.organizationFamily as number]
      })
    );
  }

  if (community.organizationStep) {
    actions.push(
      OrganizationStepGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idOrganizationSteps: [community.organizationStep as number]
      })
    );
  }

  if (community.communityUsers) {
    actions.push(
      CommunityUserGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityUsers: community.communityUsers as number[]
      })
    );
  }

  if (community.communityUserProfils) {
    actions.push(
      CommunityUserProfilGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityUserProfils: community.communityUserProfils as number[]
      })
    );
  }

  if (community.communityCaracteristics) {
    actions.push(
      CommunityCaracteristicGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityCaracteristics: community.communityCaracteristics as number[]
      })
    );
  }

  if (community.communityKpis) {
    actions.push(
      CommunityKpiGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityKpis: community.communityKpis as number[]
      })
    );
  }

  if (community.communityDataRows) {
    actions.push(
      CommunityDataRowGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityDataRows: community.communityDataRows as number[]
      })
    );
  }

  if (community.communityGroups) {
    actions.push(
      CommunityGroupGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityGroups: community.communityGroups as number[]
      })
    );
  }

  if (community.communityMilestones) {
    actions.push(
      CommunityMilestoneGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityMilestones: community.communityMilestones as number[]
      })
    );
  }

  if (community.communityMilestoneFamilys) {
    actions.push(
      CommunityMilestoneFamilyGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityMilestoneFamilys: community.communityMilestoneFamilys as number[]
      })
    );
  }

  if (community.communityRisks) {
    actions.push(
      CommunityRiskGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityRisks: community.communityRisks as number[]
      })
    );
  }

  if (community.communitySubFamilys) {
    actions.push(
      CommunitySubFamilyGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunitySubFamilys: community.communitySubFamilys as number[]
      })
    );
  }

  if (community.communityModules) {
    actions.push(
      CommunityModuleGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idCommunityModules: community.communityModules as number[]
      })
    );
  }

  if (community.projectElements) {
    actions.push(
      ProjectElementGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idProjectElements: community.projectElements as number[]
      })
    );
  }

  if (community.ganttLinks) {
    actions.push(
      GanttLinkGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idGanttLinks: community.ganttLinks as number[]
      })
    );
  }

  if (community.folders) {
    actions.push(
      FolderGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idFolders: community.folders as number[]
      })
    );
  }

  if (community.todos) {
    actions.push(
      TodoGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idTodos: community.todos as number[]
      })
    );
  }

  if (community.meetings) {
    actions.push(
      MeetingGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idMeetings: community.meetings as number[]
      })
    );
  }

  if (community.timelineElements) {
    actions.push(
      TimelineElementGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idTimelineElements: community.timelineElements as number[]
      })
    );
  }

  if (community.notesHistories) {
    actions.push(
      NotesHistoryGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idNotesHistories: community.notesHistories as number[]
      })
    );
  }

  if (community.projectModules) {
    actions.push(
      ProjectModuleGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idProjectModules: community.projectModules as number[]
      })
    );
  }

  if (community.groups) {
    actions.push(
      GroupGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idGroups: community.groups as number[]
      })
    );
  }

  if (community.users) {
    actions.push(
      UserGeneratedActions.deleteManyCommunitySuccess({
        idCommunities: [community.idCommunity],
        idUsers: community.users as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityEffects {
  constructor(
    protected actions$: Actions,
    protected communityApiService: CommunityApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityGeneratedActions.getManyCommunities),
      switchMap(({ params }) =>
        this.communityApiService.getCommunities(params).pipe(
          map((communities: Community[]) => {
            return CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities });
          }),
          catchError(error => of(CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  getOneCommunity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityGeneratedActions.getOneCommunity),
      switchMap(idCommunity =>
        this.communityApiService.getCommunity(idCommunity).pipe(
          map((community: Community) => {
            return CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({ communities: [community] });
          }),
          catchError(error => of(CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityGeneratedActions.upsertOneCommunity),
      concatMap(({ community, ids }: { community: Partial<Community>; ids?: CommunityRelationsIds }) => {
        if (community.idCommunity) {
          return this.communityApiService.updateCommunity(community).pipe(
            map((communityReturned: Community) => {
              return CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert({
                communities: [communityReturned]
              });
            }),
            catchError(error => of(CommunityGeneratedActions.communitiesFailure({ error })))
          );
        } else {
          return this.communityApiService.addCommunity(community).pipe(
            mergeMap((communityReturned: Community) => getDefaultAddCommunityActions(communityReturned, ids)),
            catchError(error => of(CommunityGeneratedActions.communitiesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneCommunity$ = createEffect(() => {
    const selectCommunityState$ = this.store$.select(CommunitySelectors.selectCommunityState);
    return this.actions$.pipe(
      ofType(CommunityGeneratedActions.deleteOneCommunity),
      withLatestFrom(selectCommunityState$),
      concatMap(([{ idCommunity }, state]) =>
        this.communityApiService.deleteCommunity(idCommunity).pipe(
          mergeMap(_success => getDefaultDeleteCommunityActions(state.entities[idCommunity] as CommunityEntityState)),
          catchError(error => of(CommunityGeneratedActions.communitiesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunitiesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert),
      concatMap(({ communities }) => {
        const actions: Action[] = getActionsToNormalizeCommunity(communities, StoreActionType.upsert);
        return [getMultiAction(actions, '[Community] Normalization After Upsert Success')];
      })
    );
  });
}
