import { RepositoryService } from '@wip/services/repository';
import { OrganizationUserProfil } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationUserProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationUserProfils(params?: any): Observable<OrganizationUserProfil[]> {
    return this.repo.getData<OrganizationUserProfil[]>('organization-user-profil', params);
  }

  public getOrganizationUserProfil(params: {
    idOrganizationUserProfil: number;
    params?: any;
  }): Observable<OrganizationUserProfil> {
    return this.repo.getData<OrganizationUserProfil>(
      'organization-user-profil/' + params.idOrganizationUserProfil,
      params.params
    );
  }

  public addOrganizationUserProfil(
    organizationUserProfil: Partial<OrganizationUserProfil>
  ): Observable<OrganizationUserProfil> {
    return this.repo.create<OrganizationUserProfil>('organization-user-profil', organizationUserProfil);
  }

  public updateOrganizationUserProfil(
    organizationUserProfil: Partial<OrganizationUserProfil>
  ): Observable<OrganizationUserProfil> {
    return this.repo.update('organization-user-profil', organizationUserProfil);
  }

  public deleteOrganizationUserProfil(idOrganizationUserProfil: number): Observable<number> {
    return this.repo.delete('organization-user-profil/' + +idOrganizationUserProfil);
  }
}
