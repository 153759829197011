import { Injectable } from '@angular/core';import { RepositoryService } from '@wip/services/repository';
import { GeneratedValeurApiService } from './valeur-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ValeurApiService extends GeneratedValeurApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
