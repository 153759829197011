export enum ElementStatusActionEnum {
  open = 'open',
  close = 'close',
  abort = 'abort',
  ['not applicable'] = 'not applicable',
  blocked = 'blocked',
  replace = 'replace',
  progress = 'progress'
}

export enum ElementStatusActionEnumTrad {
  open = 'A faire',
  close = 'Terminé',
  abort = 'Abandonné',
  ['not applicable'] = 'Sans objet',
  blocked = 'Bloqué',
  replace = 'Remplacé',
  progress = 'En cours'
}

export enum ElementStatusActionEnumNext {
  open = 'progress',
  close = 'not applicable',
  ['not applicable'] = 'blocked',
  blocked = 'open',
  abort = 'open',
  replace = 'open',
  progress = 'close'
}
