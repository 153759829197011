import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationProjectModuleGeneratedActions } from '@wip/store/actions';
import { OrganizationProjectModuleState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationProjectModuleEntityState } from '@api/api-interfaces';

export const organizationProjectModuleReducersGeneratedFunctions: ReducerTypes<
  OrganizationProjectModuleState.IState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationProjectModuleGeneratedActions.getOneOrganizationProjectModule,
    (state: OrganizationProjectModuleState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationProjectModuleGeneratedActions.getManyOrganizationProjectModules,
    (state: OrganizationProjectModuleState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationProjectModuleGeneratedActions.upsertOneOrganizationProjectModule,
    (state: OrganizationProjectModuleState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationProjectModuleGeneratedActions.upsertManyOrganizationProjectModulesSuccess,
    (state: OrganizationProjectModuleState.IState, { organizationProjectModules }) =>
      OrganizationProjectModuleState.adapter.upsertMany(organizationProjectModules, setLoadingsState(state, false))
  ),
  on(
    OrganizationProjectModuleGeneratedActions.deleteOneOrganizationProjectModule,
    (state: OrganizationProjectModuleState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationProjectModuleGeneratedActions.deleteOneOrganizationProjectModuleSuccess,
    (state: OrganizationProjectModuleState.IState, { idOrganizationProjectModule }) =>
      OrganizationProjectModuleState.adapter.removeOne(idOrganizationProjectModule, setLoadingsState(state, false))
  ),
  on(
    OrganizationProjectModuleGeneratedActions.clearActivesOrganizationProjectModules,
    (state: OrganizationProjectModuleState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationProjectModuleGeneratedActions.addManyActivesOrganizationProjectModules,
    (state: OrganizationProjectModuleState.IState, { idOrganizationProjectModules }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationProjectModules)
    })
  ),
  on(
    OrganizationProjectModuleGeneratedActions.deleteOneActiveOrganizationProjectModule,
    (state: OrganizationProjectModuleState.IState, { idOrganizationProjectModule }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationProjectModule)
    })
  ),

  on(
    OrganizationProjectModuleGeneratedActions.clearOrganizationProjectModules,
    () => OrganizationProjectModuleState.initialState
  ),

  on(
    OrganizationProjectModuleGeneratedActions.addOrganizationSuccess,
    (state: OrganizationProjectModuleState.IState, { idOrganizationProjectModule, idOrganization }) => {
      if (!state.entities[idOrganizationProjectModule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationProjectModule]: {
            ...state.entities[idOrganizationProjectModule],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationProjectModuleGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationProjectModuleState.IState, { idOrganizations, idOrganizationProjectModules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationProjectModules.reduce((entities, idOrganizationProjectModule) => {
            if (!state.entities[idOrganizationProjectModule]?.organization) {
              return entities;
            }
            entities[idOrganizationProjectModule] = {
              ...state.entities[idOrganizationProjectModule],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationProjectModule]?.organization
              )
                ? undefined
                : state.entities[idOrganizationProjectModule]?.organization
            } as OrganizationProjectModuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationProjectModuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationProjectModuleGeneratedActions.addProjectModuleSuccess,
    (state: OrganizationProjectModuleState.IState, { idOrganizationProjectModule, idProjectModule }) => {
      if (!state.entities[idOrganizationProjectModule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationProjectModule]: {
            ...state.entities[idOrganizationProjectModule],
            projectModule: idProjectModule
          }
        }
      };
    }
  ),

  on(
    OrganizationProjectModuleGeneratedActions.deleteManyProjectModuleSuccess,
    (state: OrganizationProjectModuleState.IState, { idProjectModules, idOrganizationProjectModules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationProjectModules.reduce((entities, idOrganizationProjectModule) => {
            if (!state.entities[idOrganizationProjectModule]?.projectModule) {
              return entities;
            }
            entities[idOrganizationProjectModule] = {
              ...state.entities[idOrganizationProjectModule],
              projectModule: idProjectModules.some(
                (idProjectModule: number) =>
                  idProjectModule === state.entities[idOrganizationProjectModule]?.projectModule
              )
                ? undefined
                : state.entities[idOrganizationProjectModule]?.projectModule
            } as OrganizationProjectModuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationProjectModuleEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationProjectModuleState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationProjectModuleState.IState {
  return { ...state, isLoaded, isLoading };
}
