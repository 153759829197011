<div class="form-milestone" [formGroup]="actionForm">
  <div class="flex flex-row items-center gap-4">
    <mat-icon
      style="vertical-align: middle"
      [ngStyle]="{ color: actionForm.get('idOrganizationMilestone').value ? '#ffc109' : '' }"
      class="no-margin gulp gulp-34-losange flag-size"
    >
    </mat-icon>
    <mat-form-field>
      <mat-label>Lier à une date clé</mat-label>
      <mat-select formControlName="idOrganizationMilestone" placeholder="Date clé" panelWidth="null">
        <mat-option [value]="null">Aucune</mat-option>
        @for (milestone of milestones; track milestone) {
          <mat-option [value]="milestone.idOrganizationMilestone">
            {{ milestone.libelle }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>
