import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProjectElementGeneratedActions } from '@wip/store/actions';
import { ProjectElementState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProjectElementEntityState } from '@api/api-interfaces';

export const projectElementReducersGeneratedFunctions: ReducerTypes<
  ProjectElementState.IState,
  readonly ActionCreator[]
>[] = [
  on(ProjectElementGeneratedActions.getOneProjectElement, (state: ProjectElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(ProjectElementGeneratedActions.getManyProjectElements, (state: ProjectElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(ProjectElementGeneratedActions.upsertOneProjectElement, (state: ProjectElementState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    ProjectElementGeneratedActions.upsertManyProjectElementsSuccess,
    (state: ProjectElementState.IState, { projectElements }) =>
      ProjectElementState.adapter.upsertMany(projectElements, setLoadingsState(state, false))
  ),
  on(ProjectElementGeneratedActions.deleteOneProjectElement, (state: ProjectElementState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    ProjectElementGeneratedActions.deleteOneProjectElementSuccess,
    (state: ProjectElementState.IState, { idProjectElement }) =>
      ProjectElementState.adapter.removeOne(idProjectElement, setLoadingsState(state, false))
  ),
  on(ProjectElementGeneratedActions.clearActivesProjectElements, (state: ProjectElementState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    ProjectElementGeneratedActions.addManyActivesProjectElements,
    (state: ProjectElementState.IState, { idProjectElements }) => ({
      ...state,
      actives: state.actives.concat(idProjectElements)
    })
  ),
  on(
    ProjectElementGeneratedActions.deleteOneActiveProjectElement,
    (state: ProjectElementState.IState, { idProjectElement }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idProjectElement)
    })
  ),

  on(ProjectElementGeneratedActions.clearProjectElements, () => ProjectElementState.initialState),

  on(
    ProjectElementGeneratedActions.addCommunitySuccess,
    (state: ProjectElementState.IState, { idProjectElement, idCommunity }) => {
      if (!state.entities[idProjectElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProjectElement]: {
            ...state.entities[idProjectElement],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    ProjectElementGeneratedActions.deleteManyCommunitySuccess,
    (state: ProjectElementState.IState, { idCommunities, idProjectElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProjectElements.reduce((entities, idProjectElement) => {
            if (!state.entities[idProjectElement]?.community) {
              return entities;
            }
            entities[idProjectElement] = {
              ...state.entities[idProjectElement],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idProjectElement]?.community
              )
                ? undefined
                : state.entities[idProjectElement]?.community
            } as ProjectElementEntityState;
            return entities;
          }, {} as Dictionary<ProjectElementEntityState>)
        }
      };
    }
  ),

  on(
    ProjectElementGeneratedActions.addElementSuccess,
    (state: ProjectElementState.IState, { idProjectElement, idElement }) => {
      if (!state.entities[idProjectElement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProjectElement]: {
            ...state.entities[idProjectElement],
            element: idElement
          }
        }
      };
    }
  ),

  on(
    ProjectElementGeneratedActions.deleteManyElementSuccess,
    (state: ProjectElementState.IState, { idElements, idProjectElements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProjectElements.reduce((entities, idProjectElement) => {
            if (!state.entities[idProjectElement]?.element) {
              return entities;
            }
            entities[idProjectElement] = {
              ...state.entities[idProjectElement],
              element: idElements.some((idElement: number) => idElement === state.entities[idProjectElement]?.element)
                ? undefined
                : state.entities[idProjectElement]?.element
            } as ProjectElementEntityState;
            return entities;
          }, {} as Dictionary<ProjectElementEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ProjectElementState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ProjectElementState.IState {
  return { ...state, isLoaded, isLoading };
}
