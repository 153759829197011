export enum ProjectModuleEnum {
  synthesis = 'synthesis',
  planning = 'planning',
  team = 'team',
  caracteristics = 'caracteristics',
  timeline = 'timeline',
  documents = 'documents',
  meetings = 'meetings',
  todo = 'todo',
  notes = 'notes',
  financialmanagement = 'financialmanagement',
  admin = 'admin'
}
