import { createEntityAdapter } from '@ngrx/entity';
import { UserRightEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<UserRightEntityState>;

export const adapter = createEntityAdapter<UserRightEntityState>({
  selectId: o => o.idUserRight
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const userRightFeatureKey = 'userRight';
