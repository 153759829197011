import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicApiService } from '@wip/store/api-services';
import { OrganizationCaracteristicGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationCaracteristic } from '@wip/store/configs/normalization';
import { OrganizationCaracteristicSelectors } from '@wip/store/selectors';
import { OrganizationCaracteristicRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { CommunityCaracteristicGeneratedActions } from '@wip/store/actions';
import { CommunityCaracteristic } from '@api/api-interfaces';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import { OrganizationCaracteristicColumnGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicColumn } from '@api/api-interfaces';
import { OrganizationCaracteristicRowGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicRow } from '@api/api-interfaces';

export function getDefaultAddOrganizationCaracteristicActions(
  organizationCaracteristic: OrganizationCaracteristicEntityState,
  ids?: OrganizationCaracteristicRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationCaracteristicGeneratedActions.normalizeManyOrganizationCaracteristicsAfterUpsert({
      organizationCaracteristics: [organizationCaracteristic]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationCaracteristicSuccess({
        idOrganization: ids.organization,
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic]
      })
    );
    actions.push(
      OrganizationCaracteristicGeneratedActions.addOrganizationSuccess({
        idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.addManyOrganizationCaracteristicSuccess({
        idOrganizationFamily: ids.organizationFamily,
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic]
      })
    );
    actions.push(
      OrganizationCaracteristicGeneratedActions.addOrganizationFamilySuccess({
        idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
        idOrganizationFamily: ids.organizationFamily
      })
    );
  }

  if (ids?.organizationDataColumn) {
    actions.push(
      OrganizationDataColumnGeneratedActions.addManyOrganizationCaracteristicSuccess({
        idOrganizationDataColumn: ids.organizationDataColumn,
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic]
      })
    );
    actions.push(
      OrganizationCaracteristicGeneratedActions.addOrganizationDataColumnSuccess({
        idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
        idOrganizationDataColumn: ids.organizationDataColumn
      })
    );
  }

  if (ids?.communityCaracteristics) {
    if (!Array.isArray(ids.communityCaracteristics)) {
      actions.push(
        CommunityCaracteristicGeneratedActions.upsertOneCommunityCaracteristic({
          communityCaracteristic: {
            idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
            idCommunityCaracteristic: ids.communityCaracteristics as number
          } as CommunityCaracteristic
        })
      );
      actions.push(
        OrganizationCaracteristicGeneratedActions.addManyCommunityCaracteristicSuccess({
          idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
          idCommunityCaracteristics: [ids.communityCaracteristics as number]
        })
      );
    } else {
      actions.push(
        CommunityCaracteristicGeneratedActions.upsertManyCommunityCaracteristics({
          communityCaracteristics: (ids.communityCaracteristics as number[]).map(
            (idCommunityCaracteristic: number) => ({
              idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
              idCommunityCaracteristic
            })
          ) as CommunityCaracteristic[]
        })
      );
      actions.push(
        OrganizationCaracteristicGeneratedActions.addManyCommunityCaracteristicSuccess({
          idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
          idCommunityCaracteristics: ids.communityCaracteristics as number[]
        })
      );
    }
  }

  if (ids?.organizationMilestoneFamilys) {
    if (!Array.isArray(ids.organizationMilestoneFamilys)) {
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.upsertOneOrganizationMilestoneFamily({
          organizationMilestoneFamily: {
            idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
            idOrganizationMilestoneFamily: ids.organizationMilestoneFamilys as number
          } as OrganizationMilestoneFamily
        })
      );
      actions.push(
        OrganizationCaracteristicGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
          idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
          idOrganizationMilestoneFamilys: [ids.organizationMilestoneFamilys as number]
        })
      );
    } else {
      actions.push(
        OrganizationMilestoneFamilyGeneratedActions.upsertManyOrganizationMilestoneFamilys({
          organizationMilestoneFamilys: (ids.organizationMilestoneFamilys as number[]).map(
            (idOrganizationMilestoneFamily: number) => ({
              idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
              idOrganizationMilestoneFamily
            })
          ) as OrganizationMilestoneFamily[]
        })
      );
      actions.push(
        OrganizationCaracteristicGeneratedActions.addManyOrganizationMilestoneFamilySuccess({
          idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
          idOrganizationMilestoneFamilys: ids.organizationMilestoneFamilys as number[]
        })
      );
    }
  }

  if (ids?.organizationCaracteristicColumns) {
    if (!Array.isArray(ids.organizationCaracteristicColumns)) {
      actions.push(
        OrganizationCaracteristicColumnGeneratedActions.upsertOneOrganizationCaracteristicColumn({
          organizationCaracteristicColumn: {
            idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
            idOrganizationCaracteristicColumn: ids.organizationCaracteristicColumns as number
          } as OrganizationCaracteristicColumn
        })
      );
      actions.push(
        OrganizationCaracteristicGeneratedActions.addManyOrganizationCaracteristicColumnSuccess({
          idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
          idOrganizationCaracteristicColumns: [ids.organizationCaracteristicColumns as number]
        })
      );
    } else {
      actions.push(
        OrganizationCaracteristicColumnGeneratedActions.upsertManyOrganizationCaracteristicColumns({
          organizationCaracteristicColumns: (ids.organizationCaracteristicColumns as number[]).map(
            (idOrganizationCaracteristicColumn: number) => ({
              idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
              idOrganizationCaracteristicColumn
            })
          ) as OrganizationCaracteristicColumn[]
        })
      );
      actions.push(
        OrganizationCaracteristicGeneratedActions.addManyOrganizationCaracteristicColumnSuccess({
          idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
          idOrganizationCaracteristicColumns: ids.organizationCaracteristicColumns as number[]
        })
      );
    }
  }

  if (ids?.organizationCaracteristicRows) {
    if (!Array.isArray(ids.organizationCaracteristicRows)) {
      actions.push(
        OrganizationCaracteristicRowGeneratedActions.upsertOneOrganizationCaracteristicRow({
          organizationCaracteristicRow: {
            idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
            idOrganizationCaracteristicRow: ids.organizationCaracteristicRows as number
          } as OrganizationCaracteristicRow
        })
      );
      actions.push(
        OrganizationCaracteristicGeneratedActions.addManyOrganizationCaracteristicRowSuccess({
          idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
          idOrganizationCaracteristicRows: [ids.organizationCaracteristicRows as number]
        })
      );
    } else {
      actions.push(
        OrganizationCaracteristicRowGeneratedActions.upsertManyOrganizationCaracteristicRows({
          organizationCaracteristicRows: (ids.organizationCaracteristicRows as number[]).map(
            (idOrganizationCaracteristicRow: number) => ({
              idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
              idOrganizationCaracteristicRow
            })
          ) as OrganizationCaracteristicRow[]
        })
      );
      actions.push(
        OrganizationCaracteristicGeneratedActions.addManyOrganizationCaracteristicRowSuccess({
          idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic,
          idOrganizationCaracteristicRows: ids.organizationCaracteristicRows as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationCaracteristicActions(
  organizationCaracteristic: OrganizationCaracteristicEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationCaracteristicGeneratedActions.deleteOneOrganizationCaracteristicSuccess({
      idOrganizationCaracteristic: organizationCaracteristic.idOrganizationCaracteristic
    })
  ];

  if (organizationCaracteristic.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationCaracteristicSuccess({
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic],
        idOrganizations: [organizationCaracteristic.organization as number]
      })
    );
  }

  if (organizationCaracteristic.organizationFamily) {
    actions.push(
      OrganizationFamilyGeneratedActions.deleteManyOrganizationCaracteristicSuccess({
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic],
        idOrganizationFamilys: [organizationCaracteristic.organizationFamily as number]
      })
    );
  }

  if (organizationCaracteristic.organizationDataColumn) {
    actions.push(
      OrganizationDataColumnGeneratedActions.deleteManyOrganizationCaracteristicSuccess({
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic],
        idOrganizationDataColumns: [organizationCaracteristic.organizationDataColumn as number]
      })
    );
  }

  if (organizationCaracteristic.communityCaracteristics) {
    actions.push(
      CommunityCaracteristicGeneratedActions.deleteManyOrganizationCaracteristicSuccess({
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic],
        idCommunityCaracteristics: organizationCaracteristic.communityCaracteristics as number[]
      })
    );
  }

  if (organizationCaracteristic.organizationMilestoneFamilys) {
    actions.push(
      OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationCaracteristicSuccess({
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic],
        idOrganizationMilestoneFamilys: organizationCaracteristic.organizationMilestoneFamilys as number[]
      })
    );
  }

  if (organizationCaracteristic.organizationCaracteristicColumns) {
    actions.push(
      OrganizationCaracteristicColumnGeneratedActions.deleteManyOrganizationCaracteristicSuccess({
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic],
        idOrganizationCaracteristicColumns: organizationCaracteristic.organizationCaracteristicColumns as number[]
      })
    );
  }

  if (organizationCaracteristic.organizationCaracteristicRows) {
    actions.push(
      OrganizationCaracteristicRowGeneratedActions.deleteManyOrganizationCaracteristicSuccess({
        idOrganizationCaracteristics: [organizationCaracteristic.idOrganizationCaracteristic],
        idOrganizationCaracteristicRows: organizationCaracteristic.organizationCaracteristicRows as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationCaracteristicEffects {
  constructor(
    protected actions$: Actions,
    protected organizationCaracteristicApiService: OrganizationCaracteristicApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationCaracteristics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicGeneratedActions.getManyOrganizationCaracteristics),
      switchMap(({ params }) =>
        this.organizationCaracteristicApiService.getOrganizationCaracteristics(params).pipe(
          map((organizationCaracteristics: OrganizationCaracteristic[]) => {
            return OrganizationCaracteristicGeneratedActions.normalizeManyOrganizationCaracteristicsAfterUpsert({
              organizationCaracteristics
            });
          }),
          catchError(error =>
            of(OrganizationCaracteristicGeneratedActions.organizationCaracteristicsFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationCaracteristic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicGeneratedActions.getOneOrganizationCaracteristic),
      switchMap(idOrganizationCaracteristic =>
        this.organizationCaracteristicApiService.getOrganizationCaracteristic(idOrganizationCaracteristic).pipe(
          map((organizationCaracteristic: OrganizationCaracteristic) => {
            return OrganizationCaracteristicGeneratedActions.normalizeManyOrganizationCaracteristicsAfterUpsert({
              organizationCaracteristics: [organizationCaracteristic]
            });
          }),
          catchError(error =>
            of(OrganizationCaracteristicGeneratedActions.organizationCaracteristicsFailure({ error }))
          )
        )
      )
    );
  });

  upsertOneOrganizationCaracteristic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicGeneratedActions.upsertOneOrganizationCaracteristic),
      concatMap(
        ({
          organizationCaracteristic,
          ids
        }: {
          organizationCaracteristic: Partial<OrganizationCaracteristic>;
          ids?: OrganizationCaracteristicRelationsIds;
        }) => {
          if (organizationCaracteristic.idOrganizationCaracteristic) {
            return this.organizationCaracteristicApiService
              .updateOrganizationCaracteristic(organizationCaracteristic)
              .pipe(
                map((organizationCaracteristicReturned: OrganizationCaracteristic) => {
                  return OrganizationCaracteristicGeneratedActions.normalizeManyOrganizationCaracteristicsAfterUpsert({
                    organizationCaracteristics: [organizationCaracteristicReturned]
                  });
                }),
                catchError(error =>
                  of(OrganizationCaracteristicGeneratedActions.organizationCaracteristicsFailure({ error }))
                )
              );
          } else {
            return this.organizationCaracteristicApiService
              .addOrganizationCaracteristic(organizationCaracteristic)
              .pipe(
                mergeMap((organizationCaracteristicReturned: OrganizationCaracteristic) =>
                  getDefaultAddOrganizationCaracteristicActions(organizationCaracteristicReturned, ids)
                ),
                catchError(error =>
                  of(OrganizationCaracteristicGeneratedActions.organizationCaracteristicsFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationCaracteristic$ = createEffect(() => {
    const selectOrganizationCaracteristicState$ = this.store$.select(
      OrganizationCaracteristicSelectors.selectOrganizationCaracteristicState
    );
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicGeneratedActions.deleteOneOrganizationCaracteristic),
      withLatestFrom(selectOrganizationCaracteristicState$),
      concatMap(([{ idOrganizationCaracteristic }, state]) =>
        this.organizationCaracteristicApiService.deleteOrganizationCaracteristic(idOrganizationCaracteristic).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationCaracteristicActions(
              state.entities[idOrganizationCaracteristic] as OrganizationCaracteristicEntityState
            )
          ),
          catchError(error =>
            of(OrganizationCaracteristicGeneratedActions.organizationCaracteristicsFailure({ error }))
          )
        )
      )
    );
  });

  normalizeManyOrganizationCaracteristicsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationCaracteristicGeneratedActions.normalizeManyOrganizationCaracteristicsAfterUpsert),
      concatMap(({ organizationCaracteristics }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationCaracteristic(
          organizationCaracteristics,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationCaracteristic] Normalization After Upsert Success')];
      })
    );
  });
}
