import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedCommunityUserService } from './community-user-generated.service';
import { Actions } from '@ngrx/effects';
import { Observable, Subject, map, mapTo, switchMap, takeUntil, tap } from 'rxjs';
import { CommunityUser } from '@api/api-interfaces';
import { SelectSchema } from '@wip/store/utils';
import * as CommunityUserSelector from '@wip/store/selectors';
import * as CommunityUserAction from '@wip/store/actions';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityUserService extends GeneratedCommunityUserService {
  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private userService: UserService
  ) {
    super(store$, actions$);
  }

  public clearCommunityUsers() {
    const done$ = new Subject<void>();
    this.selectAllActiveCommunityUsers()
      .pipe(
        map(cu => cu[0]),
        takeUntil(done$),
        tap(cu => {
          if (cu) {
            this.store$.dispatch(
              CommunityUserAction.resetCommunityUsers({
                idCommunityUser: cu.idCommunityUser
              })
            );
            done$.next();
          }
        })
      )
      .subscribe();
  }

  public selectCurrentCommunityUser(schema: SelectSchema = {}): Observable<CommunityUser> {
    return this.store$
      .pipe(select(CommunityUserSelector.CommunityUserGeneratedSelectors.selectActiveCommunityUsers(schema)))
      .pipe(
        switchMap(({ communityUsers }: { communityUsers: CommunityUser[] }) => {
          return this.getReady(schema).pipe(
            mapTo(communityUsers.find(cu => cu.idUser === this.userService.currentIdUser))
          );
        })
      );
  }
}
