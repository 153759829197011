import { CommunityUserProfilState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityUserProfilReducersGeneratedFunctions } from './community-user-profil-generated.reducer';

const communityUserProfilReducersFunctions = [...communityUserProfilReducersGeneratedFunctions];

const communityUserProfilReducer = createReducer(
  CommunityUserProfilState.initialState,
  ...communityUserProfilReducersFunctions
);

export function reducer(state: CommunityUserProfilState.IState | undefined, action: Action) {
  return communityUserProfilReducer(state, action);
}
