import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as AppState from '@wip/store/configs/reducers';
import { GeneratedGanttLinkService } from './gantt-link-generated.service';

@Injectable({
  providedIn: 'root'
})
export class GanttLinkService extends GeneratedGanttLinkService {
  public linksToDeactivate: number[] = [];

  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}
