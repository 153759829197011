import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Community, UploadFileInterface } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable, map } from 'rxjs';
import { GeneratedFolderApiService } from './folder-api-generated.service';
import { ConfigurationApiService } from 'libs/api/old-api-services/src';
import { FolderService } from '@wip/store/services';

@Injectable({
  providedIn: 'root'
})
export class FolderApiService extends GeneratedFolderApiService {
  constructor(
    repo: RepositoryService,
    private configurationApi: ConfigurationApiService,
    private http: HttpClient,
    private folderService: FolderService
  ) {
    super(repo);
  }

  public upload(params: UploadFileInterface): Observable<Community> {
    const options = {
      params: {
        idFolder: params.idFolder,
        idMeetingElement: params.idMeetingElement,
        idElementParent: params.idElementParent,
        isTimeline: params.isTimeline,
        ordre: params.ordre,
        idCommunity: params.idCommunity
      },
      reportProgress: true
    };

    return this.http
      .post(this.configurationApi.fullAddressV2 + '/folder/uploadFile/' + params.idCommunity, params.formData, options)
      .pipe(
        map((retour: any) => {
          this.folderService.uploadInProgress.next(false);
          return retour.data;
        })
      );
  }
}
