import { RepositoryService } from '@wip/services/repository';
import { OrganizationStep } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationStepApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationSteps(params?: any): Observable<OrganizationStep[]> {
    return this.repo.getData<OrganizationStep[]>('organization-step', params);
  }

  public getOrganizationStep(params: { idOrganizationStep: number; params?: any }): Observable<OrganizationStep> {
    return this.repo.getData<OrganizationStep>('organization-step/' + params.idOrganizationStep, params.params);
  }

  public addOrganizationStep(organizationStep: Partial<OrganizationStep>): Observable<OrganizationStep> {
    return this.repo.create<OrganizationStep>('organization-step', organizationStep);
  }

  public updateOrganizationStep(organizationStep: Partial<OrganizationStep>): Observable<OrganizationStep> {
    return this.repo.update('organization-step', organizationStep);
  }

  public deleteOrganizationStep(idOrganizationStep: number): Observable<number> {
    return this.repo.delete('organization-step/' + +idOrganizationStep);
  }
}
