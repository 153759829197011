import { RepositoryService } from '@wip/services/repository';
import { UserHistory } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserHistoryApiService {
  constructor(protected repo: RepositoryService) {}

  public getUserHistories(params?: any): Observable<UserHistory[]> {
    return this.repo.getData<UserHistory[]>('user-history', params);
  }

  public getUserHistory(params: { idUserHistory: number; params?: any }): Observable<UserHistory> {
    return this.repo.getData<UserHistory>('user-history/' + params.idUserHistory, params.params);
  }

  public addUserHistory(userHistory: Partial<UserHistory>): Observable<UserHistory> {
    return this.repo.create<UserHistory>('user-history', userHistory);
  }

  public updateUserHistory(userHistory: Partial<UserHistory>): Observable<UserHistory> {
    return this.repo.update('user-history', userHistory);
  }

  public deleteUserHistory(idUserHistory: number): Observable<number> {
    return this.repo.delete('user-history/' + +idUserHistory);
  }
}
