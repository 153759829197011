import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-duration-picker-dialog',
  templateUrl: './duration-picker-dialog.component.html',
  styleUrls: ['./duration-picker-dialog.component.scss']
})
export class DurationPickerDialogComponent implements OnInit {
  public durationInputGroup: FormGroup;
  @ViewChild('inputElement', { static: true }) private inputElement: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DurationPickerDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.durationInputGroup = this.formBuilder.group({
      duration: this.data.duration ? this.data.duration : 5
    });

    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    });
  }

  none(): void {
    this.dialogRef.close('delete');
  }

  validate(): void {
    if (this.durationInputGroup.get('duration').value) {
      this.dialogRef.close(this.durationInputGroup.get('duration').value);
    } else {
      this.none();
    }
  }
}
