import { RepositoryService } from '@wip/services/repository';
import { CommunitySubFamily } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCommunitySubFamilyApiService {
  constructor(protected repo: RepositoryService) {}

  public getCommunitySubFamilys(params?: any): Observable<CommunitySubFamily[]> {
    return this.repo.getData<CommunitySubFamily[]>('community-sub-family', params);
  }

  public getCommunitySubFamily(params: { idCommunitySubFamily: number; params?: any }): Observable<CommunitySubFamily> {
    return this.repo.getData<CommunitySubFamily>('community-sub-family/' + params.idCommunitySubFamily, params.params);
  }

  public addCommunitySubFamily(communitySubFamily: Partial<CommunitySubFamily>): Observable<CommunitySubFamily> {
    return this.repo.create<CommunitySubFamily>('community-sub-family', communitySubFamily);
  }

  public updateCommunitySubFamily(communitySubFamily: Partial<CommunitySubFamily>): Observable<CommunitySubFamily> {
    return this.repo.update('community-sub-family', communitySubFamily);
  }

  public deleteCommunitySubFamily(idCommunitySubFamily: number): Observable<number> {
    return this.repo.delete('community-sub-family/' + +idCommunitySubFamily);
  }
}
