import { GroupState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { groupReducersGeneratedFunctions } from './group-generated.reducer';

const groupReducersFunctions = [...groupReducersGeneratedFunctions];

const groupReducer = createReducer(GroupState.initialState, ...groupReducersFunctions);

export function reducer(state: GroupState.IState | undefined, action: Action) {
  return groupReducer(state, action);
}
