import { CommunityGroupState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { communityGroupReducersGeneratedFunctions } from './community-group-generated.reducer';

const communityGroupReducersFunctions = [...communityGroupReducersGeneratedFunctions];

const communityGroupReducer = createReducer(CommunityGroupState.initialState, ...communityGroupReducersFunctions);

export function reducer(state: CommunityGroupState.IState | undefined, action: Action) {
  return communityGroupReducer(state, action);
}
