import { ICustomDateTranslation, MomentLocaleService } from '@_app/core/locals/locale.service';
import { ConfirmationChoicesInterface } from '@wip/interfaces';
import { ConfirmationChoicesDialogContainerComponent } from '@wip/dialogs/confirmation-choices-dialog';
import { ActionService } from '@_app/shared/elements/actions/action.service';
import { DocumentService } from '@_app/shared/elements/documents/document.service';
import { ElementsService } from '@_app/shared/elements/elements.service';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  CommunityTypeStructureEnum,
  DroitInterneEnum,
  ElementLibraryTypeEnum,
  ElementStatusActionEnum,
  ElementTypeElement,
  TodoTypeEnum
} from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Element, ElementLibrary, Folder, OrganizationMilestone, User } from '@api/api-interfaces';
import { ActionFormDTO } from '@dto';
import { ElementApiService } from '@wip/store/api-services';
import {
  ChildrenElementModel,
  CommunityUserProfilModel,
  ElementLibraryModel,
  OrganizationMilestoneModel,
  OrganizationUserProfilModel,
  UserModel
} from '@wip/store/selectors-model';
import {
  ChildrenElementService,
  CommunityService,
  ElementService,
  FolderService,
  GanttLinkService,
  OrganizationMilestoneService,
  ProjectElementService,
  UserService
} from '@wip/store/services';
import dayjs from 'dayjs';
import { Observable, filter, take, tap } from 'rxjs';
import { formatDuration } from '../utils/new-gantt-templates';
import { NewActionChecklistComponent } from './new-action-checklist/new-action-checklist.component';
import { NewActionResponsiblesComponent } from './new-action-responsibles/new-action-responsibles.component';
import { NewActionStatusSectionComponent } from './new-action-status-section/new-action-status-section.component';
@UntilDestroy()
@Component({
  selector: 'wip-new-action-form-container',
  templateUrl: './new-action-form-container.component.html',
  styleUrls: ['./new-action-form-container.component.scss']
})
export class NewActionFormContainerComponent implements OnInit {
  public currentElement: Element;
  public actionForm: FormGroup;
  public formDone: boolean = false;
  public loadingElements = 0;
  public reminders: ElementLibrary[] = [];
  public changedReminders: ElementLibrary[] = [];
  public addedDocuments: any[] = [];
  public removedDocuments: any[] = [];
  public linkedDocuments: any[] = [];
  public milestones: OrganizationMilestone[] = [];
  public mailContent: string;
  public customDateTranslation: ICustomDateTranslation;
  public fromGanttLite: boolean;
  public createdBy: {
    prenom: string;
    nom: string;
  };
  public updatedBy: {
    prenom: string;
    nom: string;
  };

  public hasChecklist: boolean = false;
  public hasDocuments: boolean = false;
  public hasReminders: boolean = false;
  public hasCommentary: boolean = false;
  public hasResponsibles: boolean = false;

  private currentUser: User;

  @ViewChild(NewActionChecklistComponent, { static: true }) actionChecklist: NewActionChecklistComponent;
  @ViewChild('subtaskInput') subtaskInput: ElementRef;
  @ViewChild(NewActionStatusSectionComponent) actionStatusComponent: NewActionStatusSectionComponent;
  @ViewChild(NewActionResponsiblesComponent) responsiblesComponent: NewActionResponsiblesComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<NewActionFormContainerComponent>,
    private projectElementService: ProjectElementService,
    private newOrganizationMilestoneService: OrganizationMilestoneService,
    public router: Router,
    public actionService: ActionService,
    private fb: FormBuilder,
    public elementApi: ElementApiService,
    private documentService: DocumentService,
    private translateService: TranslateService,
    private userService: UserService,
    private momentLocaleService: MomentLocaleService,
    private elementsService: ElementsService,
    private ganttLinkService: GanttLinkService,
    private newCommunityService: CommunityService,
    private newFolderService: FolderService,
    private newElementService: ElementService,
    private newUserService: UserService,
    private childrenElementService: ChildrenElementService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userService
      .selectOneUser(this.userService.currentIdUser)
      .pipe(
        untilDestroyed(this),
        tap(user => (this.currentUser = user))
      )
      .subscribe();

    if (this.dialogData.idProjectElement) {
      this.newOrganizationMilestoneService
        .selectAllOrganizationMilestones()
        .pipe(
          untilDestroyed(this),
          tap(orgaMilestones => {
            this.milestones = orgaMilestones.filter(
              om => om.idOrganizationFamily === this.dialogData.community.idOrganizationFamily
            );
          })
        )
        .subscribe();
    }

    this.newElementService
      .selectOneElement(this.dialogData.idElement, {
        include: [
          ChildrenElementModel,
          OrganizationMilestoneModel,
          {
            model: ElementLibraryModel,
            include: [UserModel, { model: CommunityUserProfilModel, include: [UserModel, OrganizationUserProfilModel] }]
          }
        ]
      })
      .pipe(
        untilDestroyed(this),
        filter(el => !!el),
        tap(element => {
          this.currentElement = element;
          if (!this.formDone && element && element.type === TodoTypeEnum.project) {
            this.actionForm = this.fb.group({ titleControl: element.titre });
            this.formDone = true;
          } else if (!this.formDone && element) {
            const formOptions: AbstractControlOptions = {};
            this.actionForm = this.fb.group(
              {
                duration: {
                  value: formatDuration(element.duration, this.dialogData.businessDay),
                  disabled: true
                },
                echeance: {
                  value: element.echeance,
                  disabled: this.dialogData.fromSynthesisComponent || this.dialogData.idProjectElement
                },
                dateStart: {
                  value: element.dateStart,
                  disabled: this.dialogData.fromSynthesisComponent || this.dialogData.idProjectElement
                },
                hasDuration: element.duration || element.dateStart !== element.echeance,
                dateInitial: {
                  value: element.dateInitial,
                  disabled: !this.dialogData.community?.communityRights.crud.includes(
                    DroitInterneEnum.dateInitialUpdate
                  )
                },
                dateObjective: {
                  value: element.dateObjectif,
                  disabled: !this.dialogData.community.communityRights.crud.includes(
                    DroitInterneEnum.dateObjectifUpdate
                  )
                },
                constraintType: this.dialogData.constraintType,
                isEndDateComputed: this.dialogData.isEndDateComputed,
                fromNewGantt: true,
                titleControl: {
                  value: element.titre,
                  disabled:
                    this.dialogData.community.typeStructure === CommunityTypeStructureEnum.project
                      ? !this.dialogData.community?.communityRights.crud.includes(DroitInterneEnum.elementUpdate)
                      : false
                },
                description: element.description,
                statusAction: element.statusAction,
                important: element.important,
                idOrganizationMilestone: {
                  value: element.idOrganizationMilestone,
                  disabled: !this.dialogData.community?.communityRights.crud.includes(DroitInterneEnum.elementUpdate)
                },
                children: new FormArray([]),
                listeElementsLibrariesAdd: new FormControl([]),
                listeElementsLibrariesUpdate: new FormControl([]),
                listeElementsLibrariesRemove: new FormControl([]),
                listeResponsables: [
                  element.elementLibraries.filter(
                    el =>
                      el.type === ElementLibraryTypeEnum.user_idUser ||
                      el.type === ElementLibraryTypeEnum.user_nom ||
                      el.type === ElementLibraryTypeEnum.user_profile
                  )
                ],
                sendMail: false,
                subAction: this.fb.group({
                  subActionName: new FormControl(''),
                  subActionComment: new FormControl(''),
                  subActionDate: new FormControl('')
                })
              },
              formOptions
            );
            this.hasCommentary = element.description?.length > 0;
            this.hasResponsibles = this.actionForm.controls.listeResponsables.value.length > 0;
          }
          const children = element.childrenElements || [];
          const subactionsFGs = children
            .filter(el => el.typeElement === ElementTypeElement.elementAction)
            .map(subaction => {
              const subActionFG = this.fb.group({ ...subaction, isEditing: new FormControl(false) });
              return subActionFG;
            });
          this.hasChecklist = subactionsFGs.length > 0;
          const subActionsFormArray = this.fb.array(subactionsFGs);
          this.actionForm.setControl('children', subActionsFormArray);
          this.linkedDocuments = children.filter(el => el.typeElement === ElementTypeElement.elementDocument);
          this.hasDocuments = this.linkedDocuments.length > 0;
          this.reminders = element.elementLibraries.filter(el => el.type === ElementLibraryTypeEnum.reminder);
          this.hasReminders = this.reminders.length > 0;
          this.userService
            .selectOneUser(element.createdBy)
            .pipe(
              untilDestroyed(this),
              tap(user => {
                this.createdBy = { prenom: user?.prenom, nom: user?.nom };
              })
            )
            .subscribe();
          this.userService
            .selectOneUser(element.updatedBy)
            .pipe(
              untilDestroyed(this),
              tap(user => {
                this.updatedBy = { prenom: user?.prenom, nom: user?.nom };
              })
            )
            .subscribe();
          this.formDone = true;
        })
      )
      .subscribe();

    this.momentLocaleService.customDateTranslation$
      .pipe(untilDestroyed(this), take(1))
      .subscribe(customDateTranslation => (this.customDateTranslation = customDateTranslation));
  }

  cancel() {
    if (this.actionForm.dirty) {
      const body = 'Voulez-vous enregistrer avant de fermer ?';

      const actions: ConfirmationChoicesInterface[] = [
        {
          color: '',
          label: 'Oui'
        },
        {
          color: 'primary',
          label: 'Non'
        }
      ];
      const dialogRef = this.dialog.open(ConfirmationChoicesDialogContainerComponent, {
        data: {
          title: 'Confirmation',
          body,

          actions
        },
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.dialogRef.close();
        } else {
          this.save();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  public onRemindEvent([newContent, checkbox]) {
    this.mailContent = newContent;
    this.actionForm.get('sendMail').patchValue(true);
    this.actionForm.get('sendMail').markAsDirty();
    if (checkbox) {
      const existingComment = this.actionForm.get('description').value ? this.actionForm.get('description').value : '';
      const comment = this.translateService.instant('templates.project.remindComment', {
        date: dayjs().format('DD/MM/YY'),
        user: this.currentUser.prenom + ' ' + this.currentUser.nom
      });
      this.currentElement.description = existingComment + '\n' + comment + ' : ' + this.mailContent;
      this.actionForm.get('description').patchValue(this.currentElement.description);
      this.actionForm.markAsDirty();
    }

    this.actionForm.markAsPristine();
  }

  public onAllCheckListDone(): void {
    this.actionStatusComponent.setNextStatus(ElementStatusActionEnum.close);
  }

  public uploadFile: (doc) => Observable<Folder> = doc => {
    doc.isLoading = true;
    this.loadingElements = this.loadingElements + 1;

    const formData = new FormData();
    formData.append('files', doc.file, encodeURIComponent(doc.file.name));
    this.newFolderService.uploadInProgress.next(true);
    return this.newFolderService.uploadFile(
      {
        formData,
        idCommunity: this.newCommunityService.currentIdCommunity,
        idElementParent: this.currentElement.idElement
      },
      true
    );
  };

  previewFile = (element, elements?) => {
    this.documentService.preview(element.detail, elements, this.currentElement);
  };

  downloadFile = element => {
    this.newElementService.downloadFile(
      element.detail.idChildrenElement,
      element.detail.titre + element.detail.extension,
      true
    );
  };

  deleteFile = removedItem => {
    this.childrenElementService.deleteOneChildrenElementCustom({
      idCommunity: this.newCommunityService.currentIdCommunity,
      idChildrenElement: removedItem.document.idChildrenElement
    });
    this.newElementService.getOneElement(this.currentElement.idElement);
  };

  setAddedDocuments(addedDocuments) {
    this.actionForm.markAsDirty();
    this.addedDocuments = addedDocuments;
  }

  setRemovedDocuments(removedDocuments) {
    this.actionForm.markAsDirty();
    this.removedDocuments = removedDocuments;
  }

  getChangedReminders(changedReminders: ElementLibrary[]) {
    this.changedReminders = changedReminders;
  }

  setChangedReminders() {
    if (this.changedReminders) {
      this.reminders.forEach(reminder => {
        const index = this.changedReminders.findIndex(changedReminder => {
          return reminder.idElementLibrary === changedReminder.idElementLibrary;
        });
        if (index > -1) {
          this.changedReminders.splice(index, 1);
        } else {
          const listRemove = this.actionForm.get('listeElementsLibrariesRemove').value;
          listRemove.push(reminder);
          this.actionForm.controls.listeElementsLibrariesRemove.patchValue(listRemove);
        }
      });
      let listAdd = this.actionForm.get('listeElementsLibrariesAdd').value;
      listAdd = [...listAdd, ...this.changedReminders];
      this.actionForm.controls.listeElementsLibrariesAdd.patchValue(listAdd);
    }
  }

  save() {
    if (this.dialogData.idProjectElement) {
      this.projectElementService.validateActionForm(this.controlsToDto(this.actionForm.controls));
    } else {
      this.newElementService.validateActionForm(this.controlsToDto(this.actionForm.controls));
    }
    this.dialogRef.close(this.actionForm.controls);
  }

  private controlsToDto(controls: { [key: string]: AbstractControl }): ActionFormDTO {
    const ret = {
      idCommunity: this.newCommunityService.currentIdCommunity,
      title: controls.titleControl.value,
      idOrganizationMilestone: controls.idOrganizationMilestone.value,
      idProjectElement: this.dialogData.idProjectElement,
      idTodoElement: this.dialogData.idTodoElement,
      idMeetingElement: this.dialogData.idMeetingElement,
      idTimelineElement: this.dialogData.idTimelineElement,
      description: controls.description.value,
      rightNeeded: this.dialogData.rightNeeded,
      dateInitial: controls.dateInitial?.value,
      dateObjectif: controls.dateObjective?.value,
      idElement: this.currentElement.idElement,
      important: controls.important.value,
      updatedBy: this.newUserService.currentIdUser,
      listReminders: controls.listReminders?.value.length
        ? controls.listReminders.value.map(val => {
            return {
              idElement: val.idElement,
              idElementLibrary: val.idElementLibrary,
              reminder: val.reminder,
              type: val.type,
              value: val.value
            };
          })
        : [],
      checklist: controls.children.value.length
        ? controls.children.value.map(val => {
            return {
              idChildrenElement: val.idChildrenElement,
              idElement: val.idElement,
              description: val.description,
              titre: val.titre,
              statusAction: val.statusAction,
              ordre: val.ordre,
              echeance: val.echeance !== '' ? val.echeance : null
            };
          })
        : [],
      elementLibrariesRemoved: controls.listeElementsLibrariesRemove.value.map(el => el.idElementLibrary),
      responsibles:
        controls.listeResponsables.value.length && !controls.listeResponsables.pristine
          ? controls.listeResponsables.value.map(resp => {
              return {
                idCommunityUserProfil: resp.idCommunityUserProfil,
                idUser: resp.idUser || resp.user?.idUser,
                type: resp.type
              };
            })
          : {},
      statusAction: controls.statusAction.value
    };
    if (!this.dialogData.idProjectElement) {
      ret['echeance'] = controls.echeance.value;
    }
    return ret;
  }

  delete(): void {
    const text = 'Etes vous sûr de vouloir supprimer ce livrable ?';
    this.elementsService.confirmBeforeDelete(text).subscribe(confirmDelete => {
      if (confirmDelete) {
        this.dialogRef.close();
        if (this.dialogData.idProjectElement) {
          this.projectElementService.deleteOneProjectElement(this.dialogData.idProjectElement);
          this.dialogData.ganttLinksRelated.forEach(gl => {
            this.ganttLinkService.deleteOneGanttLink(+gl.id);
          });
        } else {
          this.newElementService.deleteOneElement(this.currentElement.idElement);
        }
      }
    });
  }

  formatDate(date) {
    return dayjs(date).format('DD/MM/YY');
  }

  @HostListener('window:keydown.esc', ['$event'])
  escClose() {
    this.cancel();
  }
}
