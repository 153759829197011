import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { GanttLink, GanttLinkEntityState } from '@api/api-interfaces';
import { GanttLinkApiService } from '@wip/store/api-services';
import { GanttLinkGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeGanttLink } from '@wip/store/configs/normalization';
import { GanttLinkSelectors } from '@wip/store/selectors';
import { GanttLinkRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';

export function getDefaultAddGanttLinkActions(ganttLink: GanttLinkEntityState, ids?: GanttLinkRelationsIds): Action[] {
  const actions: Action[] = [GanttLinkGeneratedActions.normalizeManyGanttLinksAfterUpsert({ ganttLinks: [ganttLink] })];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyGanttLinkSuccess({
        idCommunity: ids.community,
        idGanttLinks: [ganttLink.idGanttLink]
      })
    );
    actions.push(
      GanttLinkGeneratedActions.addCommunitySuccess({
        idGanttLink: ganttLink.idGanttLink,
        idCommunity: ids.community
      })
    );
  }

  return actions;
}

export function getDefaultDeleteGanttLinkActions(ganttLink: GanttLinkEntityState): Action[] {
  const actions: Action[] = [
    GanttLinkGeneratedActions.deleteOneGanttLinkSuccess({ idGanttLink: ganttLink.idGanttLink })
  ];

  if (ganttLink.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyGanttLinkSuccess({
        idGanttLinks: [ganttLink.idGanttLink],
        idCommunities: [ganttLink.community as number]
      })
    );
  }

  return actions;
}

export class GeneratedGanttLinkEffects {
  constructor(
    protected actions$: Actions,
    protected ganttLinkApiService: GanttLinkApiService,
    protected store$: Store<AppState>
  ) {}

  getManyGanttLinks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GanttLinkGeneratedActions.getManyGanttLinks),
      switchMap(({ params }) =>
        this.ganttLinkApiService.getGanttLinks(params).pipe(
          map((ganttLinks: GanttLink[]) => {
            return GanttLinkGeneratedActions.normalizeManyGanttLinksAfterUpsert({ ganttLinks });
          }),
          catchError(error => of(GanttLinkGeneratedActions.ganttLinksFailure({ error })))
        )
      )
    );
  });

  getOneGanttLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GanttLinkGeneratedActions.getOneGanttLink),
      switchMap(idGanttLink =>
        this.ganttLinkApiService.getGanttLink(idGanttLink).pipe(
          map((ganttLink: GanttLink) => {
            return GanttLinkGeneratedActions.normalizeManyGanttLinksAfterUpsert({ ganttLinks: [ganttLink] });
          }),
          catchError(error => of(GanttLinkGeneratedActions.ganttLinksFailure({ error })))
        )
      )
    );
  });

  upsertOneGanttLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GanttLinkGeneratedActions.upsertOneGanttLink),
      concatMap(({ ganttLink, ids }: { ganttLink: Partial<GanttLink>; ids?: GanttLinkRelationsIds }) => {
        if (ganttLink.idGanttLink) {
          return this.ganttLinkApiService.updateGanttLink(ganttLink).pipe(
            map((ganttLinkReturned: GanttLink) => {
              return GanttLinkGeneratedActions.normalizeManyGanttLinksAfterUpsert({ ganttLinks: [ganttLinkReturned] });
            }),
            catchError(error => of(GanttLinkGeneratedActions.ganttLinksFailure({ error })))
          );
        } else {
          return this.ganttLinkApiService.addGanttLink(ganttLink).pipe(
            mergeMap((ganttLinkReturned: GanttLink) => getDefaultAddGanttLinkActions(ganttLinkReturned, ids)),
            catchError(error => of(GanttLinkGeneratedActions.ganttLinksFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneGanttLink$ = createEffect(() => {
    const selectGanttLinkState$ = this.store$.select(GanttLinkSelectors.selectGanttLinkState);
    return this.actions$.pipe(
      ofType(GanttLinkGeneratedActions.deleteOneGanttLink),
      withLatestFrom(selectGanttLinkState$),
      concatMap(([{ idGanttLink }, state]) =>
        this.ganttLinkApiService.deleteGanttLink(idGanttLink).pipe(
          mergeMap(_success => getDefaultDeleteGanttLinkActions(state.entities[idGanttLink] as GanttLinkEntityState)),
          catchError(error => of(GanttLinkGeneratedActions.ganttLinksFailure({ error })))
        )
      )
    );
  });

  normalizeManyGanttLinksAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GanttLinkGeneratedActions.normalizeManyGanttLinksAfterUpsert),
      concatMap(({ ganttLinks }) => {
        const actions: Action[] = getActionsToNormalizeGanttLink(ganttLinks, StoreActionType.upsert);
        return [getMultiAction(actions, '[GanttLink] Normalization After Upsert Success')];
      })
    );
  });
}
