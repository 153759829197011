import { RepositoryService } from '@wip/services/repository';
import { OrganizationCaracteristic } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationCaracteristicApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationCaracteristics(params?: any): Observable<OrganizationCaracteristic[]> {
    return this.repo.getData<OrganizationCaracteristic[]>('organization-caracteristic', params);
  }

  public getOrganizationCaracteristic(params: {
    idOrganizationCaracteristic: number;
    params?: any;
  }): Observable<OrganizationCaracteristic> {
    return this.repo.getData<OrganizationCaracteristic>(
      'organization-caracteristic/' + params.idOrganizationCaracteristic,
      params.params
    );
  }

  public addOrganizationCaracteristic(
    organizationCaracteristic: Partial<OrganizationCaracteristic>
  ): Observable<OrganizationCaracteristic> {
    return this.repo.create<OrganizationCaracteristic>('organization-caracteristic', organizationCaracteristic);
  }

  public updateOrganizationCaracteristic(
    organizationCaracteristic: Partial<OrganizationCaracteristic>
  ): Observable<OrganizationCaracteristic> {
    return this.repo.update('organization-caracteristic', organizationCaracteristic);
  }

  public deleteOrganizationCaracteristic(idOrganizationCaracteristic: number): Observable<number> {
    return this.repo.delete('organization-caracteristic/' + +idOrganizationCaracteristic);
  }
}
