import { RepositoryService } from '@wip/services/repository';
import { OrganizationDataColumnValue } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationDataColumnValueApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationDataColumnValues(params?: any): Observable<OrganizationDataColumnValue[]> {
    return this.repo.getData<OrganizationDataColumnValue[]>('organization-data-column-value', params);
  }

  public getOrganizationDataColumnValue(params: {
    idOrganizationDataColumnValue: number;
    params?: any;
  }): Observable<OrganizationDataColumnValue> {
    return this.repo.getData<OrganizationDataColumnValue>(
      'organization-data-column-value/' + params.idOrganizationDataColumnValue,
      params.params
    );
  }

  public addOrganizationDataColumnValue(
    organizationDataColumnValue: Partial<OrganizationDataColumnValue>
  ): Observable<OrganizationDataColumnValue> {
    return this.repo.create<OrganizationDataColumnValue>('organization-data-column-value', organizationDataColumnValue);
  }

  public updateOrganizationDataColumnValue(
    organizationDataColumnValue: Partial<OrganizationDataColumnValue>
  ): Observable<OrganizationDataColumnValue> {
    return this.repo.update('organization-data-column-value', organizationDataColumnValue);
  }

  public deleteOrganizationDataColumnValue(idOrganizationDataColumnValue: number): Observable<number> {
    return this.repo.delete('organization-data-column-value/' + +idOrganizationDataColumnValue);
  }
}
