import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { Folder, FolderEntityState } from '@api/api-interfaces';
import { FolderApiService } from '@wip/store/api-services';
import { FolderGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeFolder } from '@wip/store/configs/normalization';
import { FolderSelectors } from '@wip/store/selectors';
import { FolderRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { FolderElementGeneratedActions } from '@wip/store/actions';
import { FolderElement } from '@api/api-interfaces';

export function getDefaultAddFolderActions(folder: FolderEntityState, ids?: FolderRelationsIds): Action[] {
  const actions: Action[] = [FolderGeneratedActions.normalizeManyFoldersAfterUpsert({ folders: [folder] })];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyFolderSuccess({
        idCommunity: ids.community,
        idFolders: [folder.idFolder]
      })
    );
    actions.push(
      FolderGeneratedActions.addCommunitySuccess({
        idFolder: folder.idFolder,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.folderElements) {
    if (!Array.isArray(ids.folderElements)) {
      actions.push(
        FolderElementGeneratedActions.upsertOneFolderElement({
          folderElement: {
            idFolder: folder.idFolder,
            idFolderElement: ids.folderElements as number
          } as FolderElement
        })
      );
      actions.push(
        FolderGeneratedActions.addManyFolderElementSuccess({
          idFolder: folder.idFolder,
          idFolderElements: [ids.folderElements as number]
        })
      );
    } else {
      actions.push(
        FolderElementGeneratedActions.upsertManyFolderElements({
          folderElements: (ids.folderElements as number[]).map((idFolderElement: number) => ({
            idFolder: folder.idFolder,
            idFolderElement
          })) as FolderElement[]
        })
      );
      actions.push(
        FolderGeneratedActions.addManyFolderElementSuccess({
          idFolder: folder.idFolder,
          idFolderElements: ids.folderElements as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteFolderActions(folder: FolderEntityState): Action[] {
  const actions: Action[] = [FolderGeneratedActions.deleteOneFolderSuccess({ idFolder: folder.idFolder })];

  if (folder.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyFolderSuccess({
        idFolders: [folder.idFolder],
        idCommunities: [folder.community as number]
      })
    );
  }

  if (folder.folderElements) {
    actions.push(
      FolderElementGeneratedActions.deleteManyFolderSuccess({
        idFolders: [folder.idFolder],
        idFolderElements: folder.folderElements as number[]
      })
    );
  }

  return actions;
}

export class GeneratedFolderEffects {
  constructor(
    protected actions$: Actions,
    protected folderApiService: FolderApiService,
    protected store$: Store<AppState>
  ) {}

  getManyFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FolderGeneratedActions.getManyFolders),
      switchMap(({ params }) =>
        this.folderApiService.getFolders(params).pipe(
          map((folders: Folder[]) => {
            return FolderGeneratedActions.normalizeManyFoldersAfterUpsert({ folders });
          }),
          catchError(error => of(FolderGeneratedActions.foldersFailure({ error })))
        )
      )
    );
  });

  getOneFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FolderGeneratedActions.getOneFolder),
      switchMap(idFolder =>
        this.folderApiService.getFolder(idFolder).pipe(
          map((folder: Folder) => {
            return FolderGeneratedActions.normalizeManyFoldersAfterUpsert({ folders: [folder] });
          }),
          catchError(error => of(FolderGeneratedActions.foldersFailure({ error })))
        )
      )
    );
  });

  upsertOneFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FolderGeneratedActions.upsertOneFolder),
      concatMap(({ folder, ids }: { folder: Partial<Folder>; ids?: FolderRelationsIds }) => {
        if (folder.idFolder) {
          return this.folderApiService.updateFolder(folder).pipe(
            map((folderReturned: Folder) => {
              return FolderGeneratedActions.normalizeManyFoldersAfterUpsert({ folders: [folderReturned] });
            }),
            catchError(error => of(FolderGeneratedActions.foldersFailure({ error })))
          );
        } else {
          return this.folderApiService.addFolder(folder).pipe(
            mergeMap((folderReturned: Folder) => getDefaultAddFolderActions(folderReturned, ids)),
            catchError(error => of(FolderGeneratedActions.foldersFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneFolder$ = createEffect(() => {
    const selectFolderState$ = this.store$.select(FolderSelectors.selectFolderState);
    return this.actions$.pipe(
      ofType(FolderGeneratedActions.deleteOneFolder),
      withLatestFrom(selectFolderState$),
      concatMap(([{ idFolder }, state]) =>
        this.folderApiService.deleteFolder(idFolder).pipe(
          mergeMap(_success => getDefaultDeleteFolderActions(state.entities[idFolder] as FolderEntityState)),
          catchError(error => of(FolderGeneratedActions.foldersFailure({ error })))
        )
      )
    );
  });

  normalizeManyFoldersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FolderGeneratedActions.normalizeManyFoldersAfterUpsert),
      concatMap(({ folders }) => {
        const actions: Action[] = getActionsToNormalizeFolder(folders, StoreActionType.upsert);
        return [getMultiAction(actions, '[Folder] Normalization After Upsert Success')];
      })
    );
  });
}
