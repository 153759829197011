import { ActionFormDTO } from '@dto';
import { createAction, props } from '@ngrx/store';
import { TodoElementForm } from '@api/api-interfaces';

export * from './todo-element-generated.actions';

export const validateActionFormForTodo = createAction('[TodoElement] Validate Action Form', props<ActionFormDTO>());

export const getTodoElementsByIdCommunity = createAction(
  '[TodoElement] Get Project Elements By idCommunity',
  props<{
    idCommunity: number;
  }>()
);

export const getTodoElementsForSynthesisDashboard = createAction(
  '[TodoElement] Get Project Elements For Synthesis',
  props<{
    idCommunity: number;
  }>()
);

export const createTodoElement = createAction(
  '[TodoElement] Create One TodoElement',
  props<{
    todoElementForm: TodoElementForm;
  }>()
);
