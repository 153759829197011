import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { ProfilState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { ProfilEntityState } from '@api/api-interfaces';

export const profilReducersGeneratedFunctions: ReducerTypes<ProfilState.IState, readonly ActionCreator[]>[] = [
  on(ProfilGeneratedActions.getOneProfil, (state: ProfilState.IState) => setLoadingsState(state, true)),
  on(ProfilGeneratedActions.getManyProfils, (state: ProfilState.IState) => setLoadingsState(state, true)),
  on(ProfilGeneratedActions.upsertOneProfil, (state: ProfilState.IState) => setLoadingsState(state, true)),

  on(ProfilGeneratedActions.upsertManyProfilsSuccess, (state: ProfilState.IState, { profils }) =>
    ProfilState.adapter.upsertMany(profils, setLoadingsState(state, false))
  ),
  on(ProfilGeneratedActions.deleteOneProfil, (state: ProfilState.IState) => setLoadingsState(state, true)),
  on(ProfilGeneratedActions.deleteOneProfilSuccess, (state: ProfilState.IState, { idProfil }) =>
    ProfilState.adapter.removeOne(idProfil, setLoadingsState(state, false))
  ),
  on(ProfilGeneratedActions.clearActivesProfils, (state: ProfilState.IState) => ({ ...state, actives: [] })),
  on(ProfilGeneratedActions.addManyActivesProfils, (state: ProfilState.IState, { idProfils }) => ({
    ...state,
    actives: state.actives.concat(idProfils)
  })),
  on(ProfilGeneratedActions.deleteOneActiveProfil, (state: ProfilState.IState, { idProfil }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idProfil)
  })),

  on(ProfilGeneratedActions.clearProfils, () => ProfilState.initialState),

  on(ProfilGeneratedActions.addManyRightProfilSuccess, (state: ProfilState.IState, { idProfil, idRightProfils }) => {
    if (!state.entities[idProfil]) {
      return state;
    }
    const rightProfils = (state.entities[idProfil]?.rightProfils as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProfil]: {
          ...state.entities[idProfil],
          rightProfils: rightProfils.concat(idRightProfils.filter(id => rightProfils.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    ProfilGeneratedActions.deleteManyRightProfilSuccess,
    (state: ProfilState.IState, { idRightProfils, idProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProfils.reduce((entities, idProfil) => {
            if (!state.entities[idProfil]?.rightProfils) {
              return entities;
            }
            entities[idProfil] = {
              ...state.entities[idProfil],
              rightProfils: (state.entities[idProfil]?.rightProfils as number[])?.filter(
                (idRightProfil: number) => !idRightProfils.some((id: number) => id === idRightProfil)
              )
            } as ProfilEntityState;
            return entities;
          }, {} as Dictionary<ProfilEntityState>)
        }
      };
    }
  ),

  on(ProfilGeneratedActions.addManyUserRightSuccess, (state: ProfilState.IState, { idProfil, idUserRights }) => {
    if (!state.entities[idProfil]) {
      return state;
    }
    const userRights = (state.entities[idProfil]?.userRights as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProfil]: {
          ...state.entities[idProfil],
          userRights: userRights.concat(idUserRights.filter(id => userRights.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ProfilGeneratedActions.deleteManyUserRightSuccess, (state: ProfilState.IState, { idUserRights, idProfils }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProfils.reduce((entities, idProfil) => {
          if (!state.entities[idProfil]?.userRights) {
            return entities;
          }
          entities[idProfil] = {
            ...state.entities[idProfil],
            userRights: (state.entities[idProfil]?.userRights as number[])?.filter(
              (idUserRight: number) => !idUserRights.some((id: number) => id === idUserRight)
            )
          } as ProfilEntityState;
          return entities;
        }, {} as Dictionary<ProfilEntityState>)
      }
    };
  }),

  on(
    ProfilGeneratedActions.addManyUserProfilRightSuccess,
    (state: ProfilState.IState, { idProfil, idUserProfilRights }) => {
      if (!state.entities[idProfil]) {
        return state;
      }
      const userProfilRights = (state.entities[idProfil]?.userProfilRights as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProfil]: {
            ...state.entities[idProfil],
            userProfilRights: userProfilRights.concat(idUserProfilRights.filter(id => userProfilRights.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ProfilGeneratedActions.deleteManyUserProfilRightSuccess,
    (state: ProfilState.IState, { idUserProfilRights, idProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProfils.reduce((entities, idProfil) => {
            if (!state.entities[idProfil]?.userProfilRights) {
              return entities;
            }
            entities[idProfil] = {
              ...state.entities[idProfil],
              userProfilRights: (state.entities[idProfil]?.userProfilRights as number[])?.filter(
                (idUserProfilRight: number) => !idUserProfilRights.some((id: number) => id === idUserProfilRight)
              )
            } as ProfilEntityState;
            return entities;
          }, {} as Dictionary<ProfilEntityState>)
        }
      };
    }
  ),

  on(ProfilGeneratedActions.addManyRightSuccess, (state: ProfilState.IState, { idProfil, idRights }) => {
    if (!state.entities[idProfil]) {
      return state;
    }
    const rights = (state.entities[idProfil]?.rights as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProfil]: {
          ...state.entities[idProfil],
          rights: rights.concat(idRights.filter(id => rights.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ProfilGeneratedActions.deleteManyRightSuccess, (state: ProfilState.IState, { idRights, idProfils }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProfils.reduce((entities, idProfil) => {
          if (!state.entities[idProfil]?.rights) {
            return entities;
          }
          entities[idProfil] = {
            ...state.entities[idProfil],
            rights: (state.entities[idProfil]?.rights as number[])?.filter(
              (idRight: number) => !idRights.some((id: number) => id === idRight)
            )
          } as ProfilEntityState;
          return entities;
        }, {} as Dictionary<ProfilEntityState>)
      }
    };
  }),

  on(
    ProfilGeneratedActions.addManyOrganizationUserProfilSuccess,
    (state: ProfilState.IState, { idProfil, idOrganizationUserProfils }) => {
      if (!state.entities[idProfil]) {
        return state;
      }
      const organizationUserProfils = (state.entities[idProfil]?.organizationUserProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProfil]: {
            ...state.entities[idProfil],
            organizationUserProfils: organizationUserProfils.concat(
              idOrganizationUserProfils.filter(id => organizationUserProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProfilGeneratedActions.deleteManyOrganizationUserProfilSuccess,
    (state: ProfilState.IState, { idOrganizationUserProfils, idProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProfils.reduce((entities, idProfil) => {
            if (!state.entities[idProfil]?.organizationUserProfils) {
              return entities;
            }
            entities[idProfil] = {
              ...state.entities[idProfil],
              organizationUserProfils: (state.entities[idProfil]?.organizationUserProfils as number[])?.filter(
                (idOrganizationUserProfil: number) =>
                  !idOrganizationUserProfils.some((id: number) => id === idOrganizationUserProfil)
              )
            } as ProfilEntityState;
            return entities;
          }, {} as Dictionary<ProfilEntityState>)
        }
      };
    }
  ),

  on(
    ProfilGeneratedActions.addManyOrganizationUserSuccess,
    (state: ProfilState.IState, { idProfil, idOrganizationUsers }) => {
      if (!state.entities[idProfil]) {
        return state;
      }
      const organizationUsers = (state.entities[idProfil]?.organizationUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProfil]: {
            ...state.entities[idProfil],
            organizationUsers: organizationUsers.concat(
              idOrganizationUsers.filter(id => organizationUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProfilGeneratedActions.deleteManyOrganizationUserSuccess,
    (state: ProfilState.IState, { idOrganizationUsers, idProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProfils.reduce((entities, idProfil) => {
            if (!state.entities[idProfil]?.organizationUsers) {
              return entities;
            }
            entities[idProfil] = {
              ...state.entities[idProfil],
              organizationUsers: (state.entities[idProfil]?.organizationUsers as number[])?.filter(
                (idOrganizationUser: number) => !idOrganizationUsers.some((id: number) => id === idOrganizationUser)
              )
            } as ProfilEntityState;
            return entities;
          }, {} as Dictionary<ProfilEntityState>)
        }
      };
    }
  ),

  on(ProfilGeneratedActions.addOrganizationSuccess, (state: ProfilState.IState, { idProfil, idOrganization }) => {
    if (!state.entities[idProfil]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProfil]: {
          ...state.entities[idProfil],
          organization: idOrganization
        }
      }
    };
  }),

  on(
    ProfilGeneratedActions.deleteManyOrganizationSuccess,
    (state: ProfilState.IState, { idOrganizations, idProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProfils.reduce((entities, idProfil) => {
            if (!state.entities[idProfil]?.organization) {
              return entities;
            }
            entities[idProfil] = {
              ...state.entities[idProfil],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idProfil]?.organization
              )
                ? undefined
                : state.entities[idProfil]?.organization
            } as ProfilEntityState;
            return entities;
          }, {} as Dictionary<ProfilEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ProfilState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ProfilState.IState {
  return { ...state, isLoaded, isLoading };
}
