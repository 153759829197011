import { SharedModule } from '@_app/shared/shared.module';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationElement } from '@api/api-interfaces';
import { OrganizationElemTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ElementModel, OrganizationElementModel } from '@wip/store/selectors-model';
import { OrganizationService, UserService } from '@wip/store/services';
import { filter, map, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'wip-list-documents-dialog',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './list-documents-dialog.component.html',
  styleUrl: './list-documents-dialog.component.scss'
})
export class ListDocumentsDialogComponent implements OnInit {
  constructor(
    private organizationService: OrganizationService,
    private userService: UserService,
    public dialogRef: MatDialogRef<ListDocumentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { documentType: OrganizationElemTypeEnum }
  ) {}

  public templates: OrganizationElement[];
  public selected = -1;
  public OrganizationElemTypeEnumNotes = OrganizationElemTypeEnum.notes;

  ngOnInit() {
    const idUser = this.userService.currentIdUser;

    // feeding orgaElement and element to get the correct templates
    this.organizationService.getManyOrganizationsWithIdUser(idUser);

    this.organizationService
      .selectAllActiveOrganizations({ include: [{ model: OrganizationElementModel, include: [ElementModel] }] })
      .pipe(
        untilDestroyed(this),
        map(o => o[0]),
        filter(o => !!o),
        tap(o => {
          this.templates = o.organizationElements
            .filter(oe => (oe.element?.extension === '.docx' || '.doc') && oe.type === this.data.documentType)
            .sort((a, b) => {
              const aFilename = a.element?.description?.length ? a.element?.description : a.element?.titre;
              const bFilename = b.element?.description?.length ? b.element?.description : b.element?.titre;
              return aFilename.localeCompare(bFilename);
            });
        })
      )
      .subscribe();
  }

  cancel() {
    this.dialogRef.close();
  }

  validate() {
    this.dialogRef.close(this.selected);
  }
}
