import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Organization } from '@api/api-interfaces';
import * as AppState from '@wip/store/configs/reducers';
import * as OrganizationAction from '@wip/store/actions';
import { OrganizationUserService } from '@wip/store/services';
import { UserService } from '@wip/store/services';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Observable, tap } from 'rxjs';
import { GeneratedOrganizationService } from './organization-generated.service';
import { OrganizationApiService } from '@wip/store/api-services';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends GeneratedOrganizationService {
  public currentIdOrganization: number;

  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private newUserService: UserService,
    private newOrganizationUserService: OrganizationUserService,
    private organizationApiService: OrganizationApiService
  ) {
    super(store$, actions$);
  }

  public setActiveAndCurrentOrganization(idsOrganization: number[]) {
    this.setActiveOrganizations(idsOrganization);
    this.newOrganizationUserService
      .selectAllOrganizationUsers()
      .pipe(
        tap(orgaUsers => {
          const newOrgaUser = orgaUsers.find(
            ou => ou.idUser === this.newUserService.currentIdUser && ou.idOrganization === idsOrganization[0]
          );
          this.newOrganizationUserService.setActiveOrganizationUsers(
            !!newOrgaUser?.idOrganizationUser ? [newOrgaUser?.idOrganizationUser] : []
          );
        })
      )
      .subscribe();
    this.currentIdOrganization = idsOrganization.length ? idsOrganization[0] : 0;
  }

  public getManyOrganizationsWithIdUser(idUser: number, getResult?: boolean): void | Observable<Organization[]> {
    this.store$.dispatch(OrganizationAction.getManyOrganizationsWithIdUser({ idUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationAction.OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert,
        OrganizationAction.OrganizationGeneratedActions.organizationsFailure
      );
    }
  }
  public getOrganizationsFilters(idUser: number, getResult?: boolean): void | Observable<Organization[]> {
    this.store$.dispatch(OrganizationAction.getOrganizationsFilters({ idUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationAction.OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert,
        OrganizationAction.OrganizationGeneratedActions.organizationsFailure
      );
    }
  }

  public getManyOrganizationsForSynthesis(idUser: number, getResult?: boolean): void | Observable<Organization[]> {
    this.store$.dispatch(OrganizationAction.getManyOrganizationsForSynthesis({ idUser }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationAction.OrganizationGeneratedActions.normalizeManyOrganizationsAfterUpsert,
        OrganizationAction.OrganizationGeneratedActions.organizationsFailure
      );
    }
  }

  public getDevelopmentAccess() {
    return this.organizationApiService.getOrganizations({});
  }
}
