import { RepositoryService } from '@wip/services/repository';
import { GanttLink } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedGanttLinkApiService {
  constructor(protected repo: RepositoryService) {}

  public getGanttLinks(params?: any): Observable<GanttLink[]> {
    return this.repo.getData<GanttLink[]>('gantt-link', params);
  }

  public getGanttLink(params: { idGanttLink: number; params?: any }): Observable<GanttLink> {
    return this.repo.getData<GanttLink>('gantt-link/' + params.idGanttLink, params.params);
  }

  public addGanttLink(ganttLink: Partial<GanttLink>): Observable<GanttLink> {
    return this.repo.create<GanttLink>('gantt-link', ganttLink);
  }

  public updateGanttLink(ganttLink: Partial<GanttLink>): Observable<GanttLink> {
    return this.repo.update('gantt-link', ganttLink);
  }

  public deleteGanttLink(idGanttLink: number): Observable<number> {
    return this.repo.delete('gantt-link/' + +idGanttLink);
  }
}
