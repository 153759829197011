import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FolderElementApiService } from '@wip/store/api-services';
import { GeneratedFolderElementEffects } from './folder-element-generated.effects';

@Injectable()
export class FolderElementEffects extends GeneratedFolderElementEffects {
  constructor(actions$: Actions, folderElementApiService: FolderElementApiService, store$: Store<AppState>) {
    super(actions$, folderElementApiService, store$);
  }
}
