import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { FolderElement } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { FolderElementRelationsIds } from '@wip/store/ids-interfaces';
import { FolderElementGeneratedActions } from '@wip/store/actions';
import { FolderElementSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedFolderElementService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(FolderElementSelectors.selectIsLoadedFolderElement));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(FolderElementSelectors.selectIsLoadingFolderElement));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [FolderElementSelectors.selectIsReadyAndLoadedFolderElement as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllFolderElements(schema: SelectSchema = {}): Observable<FolderElement[]> {
    return this.store$.pipe(select(FolderElementSelectors.selectAllFolderElements(schema))).pipe(
      switchMap(({ folderElements }: { folderElements: FolderElement[] }) => {
        return this.getReady(schema).pipe(mapTo(folderElements));
      })
    );
  }

  public selectOneFolderElement(idFolderElement: number, schema: SelectSchema = {}): Observable<FolderElement> {
    return this.store$.pipe(select(FolderElementSelectors.selectOneFolderElement(schema, idFolderElement))).pipe(
      switchMap(({ folderElement }: { folderElement: FolderElement }) => {
        return this.getReady(schema).pipe(mapTo(folderElement));
      })
    );
  }

  public selectAllActiveFolderElements(schema: SelectSchema = {}): Observable<FolderElement[]> {
    return this.store$.pipe(select(FolderElementSelectors.selectActiveFolderElements(schema))).pipe(
      switchMap(({ folderElements }: { folderElements: FolderElement[] }) => {
        return this.getReady(schema).pipe(mapTo(folderElements));
      })
    );
  }

  public selectIdFolderElementsActive(): Observable<number[]> {
    return this.store$.pipe(select(FolderElementSelectors.selectIdFolderElementsActive)).pipe(
      switchMap((idFolderElements: number[]) => {
        return this.getReady().pipe(mapTo(idFolderElements));
      })
    );
  }

  public getOneFolderElement(
    idFolderElement: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<FolderElement> {
    this.store$.dispatch(FolderElementGeneratedActions.getOneFolderElement({ idFolderElement, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FolderElementGeneratedActions.normalizeManyFolderElementsAfterUpsert,
        FolderElementGeneratedActions.folderElementsFailure,
        true
      );
    }
  }

  public getManyFolderElements(params: any = {}, getResult?: boolean): void | Observable<FolderElement[]> {
    this.store$.dispatch(FolderElementGeneratedActions.getManyFolderElements({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FolderElementGeneratedActions.normalizeManyFolderElementsAfterUpsert,
        FolderElementGeneratedActions.folderElementsFailure
      );
    }
  }

  public upsertOneFolderElement(
    folderElement: Partial<FolderElement>,
    ids: FolderElementRelationsIds = {},
    getResult?: boolean
  ): void | Observable<FolderElement> {
    this.store$.dispatch(FolderElementGeneratedActions.upsertOneFolderElement({ folderElement, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FolderElementGeneratedActions.normalizeManyFolderElementsAfterUpsert,
        FolderElementGeneratedActions.folderElementsFailure,
        true
      );
    }
  }

  public deleteOneFolderElement(idFolderElement: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(FolderElementGeneratedActions.deleteOneFolderElement({ idFolderElement }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        FolderElementGeneratedActions.deleteOneFolderElementSuccess,
        FolderElementGeneratedActions.folderElementsFailure
      );
    }
  }

  public setActiveFolderElements(idFolderElements: number[]): void {
    this.store$.dispatch(FolderElementGeneratedActions.clearActivesFolderElements());
    this.store$.dispatch(FolderElementGeneratedActions.addManyActivesFolderElements({ idFolderElements }));
  }
}
