import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { CommunityUser, CommunityUserEntityState } from '@api/api-interfaces';
import { CommunityUserProfil, CommunityUserProfilEntityState } from '@api/api-interfaces';
import { CommunityCaracteristic, CommunityCaracteristicEntityState } from '@api/api-interfaces';
import { CommunityKpi, CommunityKpiEntityState } from '@api/api-interfaces';
import { CommunityDataRow, CommunityDataRowEntityState } from '@api/api-interfaces';
import { CommunityGroup, CommunityGroupEntityState } from '@api/api-interfaces';
import { CommunityMilestone, CommunityMilestoneEntityState } from '@api/api-interfaces';
import { CommunityMilestoneFamily, CommunityMilestoneFamilyEntityState } from '@api/api-interfaces';
import { CommunityRisk, CommunityRiskEntityState } from '@api/api-interfaces';
import { CommunitySubFamily, CommunitySubFamilyEntityState } from '@api/api-interfaces';
import { CommunityModule, CommunityModuleEntityState } from '@api/api-interfaces';
import { ProjectElement, ProjectElementEntityState } from '@api/api-interfaces';
import { GanttLink, GanttLinkEntityState } from '@api/api-interfaces';
import { Folder, FolderEntityState } from '@api/api-interfaces';
import { Todo, TodoEntityState } from '@api/api-interfaces';
import { Meeting, MeetingEntityState } from '@api/api-interfaces';
import { TimelineElement, TimelineElementEntityState } from '@api/api-interfaces';
import { NotesHistory, NotesHistoryEntityState } from '@api/api-interfaces';
import { ProjectModule, ProjectModuleEntityState } from '@api/api-interfaces';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { OrganizationStep, OrganizationStepEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityRelations: string[] = [
  'communityUsers',
  'communityUserProfils',
  'communityCaracteristics',
  'communityKpis',
  'communityDataRows',
  'communityGroups',
  'communityMilestones',
  'communityMilestoneFamilys',
  'communityRisks',
  'communitySubFamilys',
  'communityModules',
  'projectElements',
  'ganttLinks',
  'folders',
  'todos',
  'meetings',
  'timelineElements',
  'notesHistories',
  'projectModules',
  'groups',
  'users',
  'organizations',
  'organizationFamilys',
  'organizationSteps'
];

export const { selectEntities, selectAll } = CommunityState.adapter.getSelectors();

export const selectCommunityState = createFeatureSelector<CommunityState.IState>(CommunityState.communityFeatureKey);

export const selectIsLoadedCommunity = createSelector(
  selectCommunityState,
  (state: CommunityState.IState) => state.isLoaded
);

export const selectIsLoadingCommunity = createSelector(
  selectCommunityState,
  (state: CommunityState.IState) => state.isLoading
);

export const selectIsReadyCommunity = createSelector(
  selectCommunityState,
  (state: CommunityState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunity = createSelector(
  selectCommunityState,
  (state: CommunityState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunitiesEntities = createSelector(selectCommunityState, selectEntities);

export const selectCommunitiesArray = createSelector(selectCommunityState, selectAll);

export const selectIdCommunitiesActive = createSelector(
  selectCommunityState,
  (state: CommunityState.IState) => state.actives
);

const communitiesInObject = (communities: Dictionary<CommunityEntityState>) => ({ communities });

const selectCommunitiesEntitiesDictionary = createSelector(selectCommunitiesEntities, communitiesInObject);

const selectAllCommunitiesObject = createSelector(selectCommunitiesEntities, communities => {
  return hydrateAll({ communities });
});

const selectOneCommunityDictionary = (idCommunity: number) =>
  createSelector(selectCommunitiesEntities, communities => ({
    communities: { [idCommunity]: communities[idCommunity] }
  }));

const selectOneCommunityDictionaryWithoutChild = (idCommunity: number) =>
  createSelector(selectCommunitiesEntities, communities => ({
    community: communities[idCommunity]
  }));

const selectActiveCommunitiesEntities = createSelector(
  selectIdCommunitiesActive,
  selectCommunitiesEntities,
  (actives: number[], communities: Dictionary<CommunityEntityState>) => getCommunitiesFromActives(actives, communities)
);

function getCommunitiesFromActives(
  actives: number[],
  communities: Dictionary<CommunityEntityState>
): Dictionary<CommunityEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communities[idActive]) {
      acc[idActive] = communities[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityEntityState>);
}

const selectAllCommunitiesSelectors: Dictionary<Selector> = {};
export function selectAllCommunities(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Community>(
      schema,
      selectAllCommunitiesSelectors,
      selectCommunitiesEntitiesDictionary,
      getRelationSelectors,
      communityRelations,
      hydrateAll,
      'community'
    );
  } else {
    return selectAllCommunitiesObject;
  }
}

export function selectAllCommunitiesDictionary(schema: SelectSchema = {}, customKey: string = 'communities'): Selector {
  return createSelector(selectAllCommunities(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communities.length; i++) {
      res[customKey][result.communities[i].idCommunity] = result.communities[i];
    }
    return res;
  });
}

export function selectOneCommunity(schema: SelectSchema = {}, idCommunity: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityDictionary(idCommunity)];
    selectors.push(...getRelationSelectors(schema, communityRelations, 'community'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityDictionaryWithoutChild(idCommunity);
  }
}

export function selectActiveCommunities(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunitiesEntities, communities => ({
      communities
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityRelations, 'community'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communities: Dictionary<CommunityEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationFamilys?: Dictionary<OrganizationFamilyEntityState>;
  organizationSteps?: Dictionary<OrganizationStepEntityState>;
  communityUsers?: Dictionary<CommunityUserEntityState>;
  communityUserProfils?: Dictionary<CommunityUserProfilEntityState>;
  communityCaracteristics?: Dictionary<CommunityCaracteristicEntityState>;
  communityKpis?: Dictionary<CommunityKpiEntityState>;
  communityDataRows?: Dictionary<CommunityDataRowEntityState>;
  communityGroups?: Dictionary<CommunityGroupEntityState>;
  communityMilestones?: Dictionary<CommunityMilestoneEntityState>;
  communityMilestoneFamilys?: Dictionary<CommunityMilestoneFamilyEntityState>;
  communityRisks?: Dictionary<CommunityRiskEntityState>;
  communitySubFamilys?: Dictionary<CommunitySubFamilyEntityState>;
  communityModules?: Dictionary<CommunityModuleEntityState>;
  projectElements?: Dictionary<ProjectElementEntityState>;
  ganttLinks?: Dictionary<GanttLinkEntityState>;
  folders?: Dictionary<FolderEntityState>;
  todos?: Dictionary<TodoEntityState>;
  meetings?: Dictionary<MeetingEntityState>;
  timelineElements?: Dictionary<TimelineElementEntityState>;
  notesHistories?: Dictionary<NotesHistoryEntityState>;
  projectModules?: Dictionary<ProjectModuleEntityState>;
  groups?: Dictionary<GroupEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communities: (Community | null)[] } {
  const {
    communities,
    organizations,
    organizationFamilys,
    organizationSteps,
    communityUsers,
    communityUserProfils,
    communityCaracteristics,
    communityKpis,
    communityDataRows,
    communityGroups,
    communityMilestones,
    communityMilestoneFamilys,
    communityRisks,
    communitySubFamilys,
    communityModules,
    projectElements,
    ganttLinks,
    folders,
    todos,
    meetings,
    timelineElements,
    notesHistories,
    projectModules,
    groups,
    users
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    communities: Object.keys(communities).map(idCommunity =>
      hydrate(
        communities[idCommunity] as CommunityEntityState,
        organizations,
        organizationFamilys,
        organizationSteps,
        communityUsers,
        communityUserProfils,
        communityCaracteristics,
        communityKpis,
        communityDataRows,
        communityGroups,
        communityMilestones,
        communityMilestoneFamilys,
        communityRisks,
        communitySubFamilys,
        communityModules,
        projectElements,
        ganttLinks,
        folders,
        todos,
        meetings,
        timelineElements,
        notesHistories,
        projectModules,
        groups,
        users
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { community: CommunityEntityState | null } {
  const {
    communities,
    organizations,
    organizationFamilys,
    organizationSteps,
    communityUsers,
    communityUserProfils,
    communityCaracteristics,
    communityKpis,
    communityDataRows,
    communityGroups,
    communityMilestones,
    communityMilestoneFamilys,
    communityRisks,
    communitySubFamilys,
    communityModules,
    projectElements,
    ganttLinks,
    folders,
    todos,
    meetings,
    timelineElements,
    notesHistories,
    projectModules,
    groups,
    users
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const community = Object.values(communities)[0];
  return {
    community: hydrate(
      community as CommunityEntityState,
      organizations,
      organizationFamilys,
      organizationSteps,
      communityUsers,
      communityUserProfils,
      communityCaracteristics,
      communityKpis,
      communityDataRows,
      communityGroups,
      communityMilestones,
      communityMilestoneFamilys,
      communityRisks,
      communitySubFamilys,
      communityModules,
      projectElements,
      ganttLinks,
      folders,
      todos,
      meetings,
      timelineElements,
      notesHistories,
      projectModules,
      groups,
      users
    )
  };
}

function hydrate(
  community: CommunityEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationFamilyEntities?: Dictionary<OrganizationFamilyEntityState>,
  organizationStepEntities?: Dictionary<OrganizationStepEntityState>,
  communityUserEntities?: Dictionary<CommunityUserEntityState>,
  communityUserProfilEntities?: Dictionary<CommunityUserProfilEntityState>,
  communityCaracteristicEntities?: Dictionary<CommunityCaracteristicEntityState>,
  communityKpiEntities?: Dictionary<CommunityKpiEntityState>,
  communityDataRowEntities?: Dictionary<CommunityDataRowEntityState>,
  communityGroupEntities?: Dictionary<CommunityGroupEntityState>,
  communityMilestoneEntities?: Dictionary<CommunityMilestoneEntityState>,
  communityMilestoneFamilyEntities?: Dictionary<CommunityMilestoneFamilyEntityState>,
  communityRiskEntities?: Dictionary<CommunityRiskEntityState>,
  communitySubFamilyEntities?: Dictionary<CommunitySubFamilyEntityState>,
  communityModuleEntities?: Dictionary<CommunityModuleEntityState>,
  projectElementEntities?: Dictionary<ProjectElementEntityState>,
  ganttLinkEntities?: Dictionary<GanttLinkEntityState>,
  folderEntities?: Dictionary<FolderEntityState>,
  todoEntities?: Dictionary<TodoEntityState>,
  meetingEntities?: Dictionary<MeetingEntityState>,
  timelineElementEntities?: Dictionary<TimelineElementEntityState>,
  notesHistoryEntities?: Dictionary<NotesHistoryEntityState>,
  projectModuleEntities?: Dictionary<ProjectModuleEntityState>,
  groupEntities?: Dictionary<GroupEntityState>,
  userEntities?: Dictionary<UserEntityState>
): Community | null {
  if (!community) {
    return null;
  }

  const communityHydrated: CommunityEntityState = { ...community };
  if (organizationEntities) {
    communityHydrated.organization = organizationEntities[community.organization as number] as Organization;
  } else {
    delete communityHydrated.organization;
  }
  if (organizationFamilyEntities) {
    communityHydrated.organizationFamily = organizationFamilyEntities[
      community.organizationFamily as number
    ] as OrganizationFamily;
  } else {
    delete communityHydrated.organizationFamily;
  }
  if (organizationStepEntities) {
    communityHydrated.organizationStep = organizationStepEntities[
      community.organizationStep as number
    ] as OrganizationStep;
  } else {
    delete communityHydrated.organizationStep;
  }

  if (communityUserEntities) {
    communityHydrated.communityUsers = ((communityHydrated.communityUsers as number[]) || []).map(
      id => communityUserEntities[id]
    ) as CommunityUser[];
  } else {
    delete communityHydrated.communityUsers;
  }

  if (communityUserProfilEntities) {
    communityHydrated.communityUserProfils = ((communityHydrated.communityUserProfils as number[]) || []).map(
      id => communityUserProfilEntities[id]
    ) as CommunityUserProfil[];
  } else {
    delete communityHydrated.communityUserProfils;
  }

  if (communityCaracteristicEntities) {
    communityHydrated.communityCaracteristics = ((communityHydrated.communityCaracteristics as number[]) || []).map(
      id => communityCaracteristicEntities[id]
    ) as CommunityCaracteristic[];
  } else {
    delete communityHydrated.communityCaracteristics;
  }

  if (communityKpiEntities) {
    communityHydrated.communityKpis = ((communityHydrated.communityKpis as number[]) || []).map(
      id => communityKpiEntities[id]
    ) as CommunityKpi[];
  } else {
    delete communityHydrated.communityKpis;
  }

  if (communityDataRowEntities) {
    communityHydrated.communityDataRows = ((communityHydrated.communityDataRows as number[]) || []).map(
      id => communityDataRowEntities[id]
    ) as CommunityDataRow[];
  } else {
    delete communityHydrated.communityDataRows;
  }

  if (communityGroupEntities) {
    communityHydrated.communityGroups = ((communityHydrated.communityGroups as number[]) || []).map(
      id => communityGroupEntities[id]
    ) as CommunityGroup[];
  } else {
    delete communityHydrated.communityGroups;
  }

  if (communityMilestoneEntities) {
    communityHydrated.communityMilestones = ((communityHydrated.communityMilestones as number[]) || []).map(
      id => communityMilestoneEntities[id]
    ) as CommunityMilestone[];
  } else {
    delete communityHydrated.communityMilestones;
  }

  if (communityMilestoneFamilyEntities) {
    communityHydrated.communityMilestoneFamilys = ((communityHydrated.communityMilestoneFamilys as number[]) || []).map(
      id => communityMilestoneFamilyEntities[id]
    ) as CommunityMilestoneFamily[];
  } else {
    delete communityHydrated.communityMilestoneFamilys;
  }

  if (communityRiskEntities) {
    communityHydrated.communityRisks = ((communityHydrated.communityRisks as number[]) || []).map(
      id => communityRiskEntities[id]
    ) as CommunityRisk[];
  } else {
    delete communityHydrated.communityRisks;
  }

  if (communitySubFamilyEntities) {
    communityHydrated.communitySubFamilys = ((communityHydrated.communitySubFamilys as number[]) || []).map(
      id => communitySubFamilyEntities[id]
    ) as CommunitySubFamily[];
  } else {
    delete communityHydrated.communitySubFamilys;
  }

  if (communityModuleEntities) {
    communityHydrated.communityModules = ((communityHydrated.communityModules as number[]) || []).map(
      id => communityModuleEntities[id]
    ) as CommunityModule[];
  } else {
    delete communityHydrated.communityModules;
  }

  if (projectElementEntities) {
    communityHydrated.projectElements = ((communityHydrated.projectElements as number[]) || []).map(
      id => projectElementEntities[id]
    ) as ProjectElement[];
  } else {
    delete communityHydrated.projectElements;
  }

  if (ganttLinkEntities) {
    communityHydrated.ganttLinks = ((communityHydrated.ganttLinks as number[]) || []).map(
      id => ganttLinkEntities[id]
    ) as GanttLink[];
  } else {
    delete communityHydrated.ganttLinks;
  }

  if (folderEntities) {
    communityHydrated.folders = ((communityHydrated.folders as number[]) || []).map(
      id => folderEntities[id]
    ) as Folder[];
  } else {
    delete communityHydrated.folders;
  }

  if (todoEntities) {
    communityHydrated.todos = ((communityHydrated.todos as number[]) || []).map(id => todoEntities[id]) as Todo[];
  } else {
    delete communityHydrated.todos;
  }

  if (meetingEntities) {
    communityHydrated.meetings = ((communityHydrated.meetings as number[]) || []).map(
      id => meetingEntities[id]
    ) as Meeting[];
  } else {
    delete communityHydrated.meetings;
  }

  if (timelineElementEntities) {
    communityHydrated.timelineElements = ((communityHydrated.timelineElements as number[]) || []).map(
      id => timelineElementEntities[id]
    ) as TimelineElement[];
  } else {
    delete communityHydrated.timelineElements;
  }

  if (notesHistoryEntities) {
    communityHydrated.notesHistories = ((communityHydrated.notesHistories as number[]) || []).map(
      id => notesHistoryEntities[id]
    ) as NotesHistory[];
  } else {
    delete communityHydrated.notesHistories;
  }

  if (projectModuleEntities) {
    communityHydrated.projectModules = ((communityHydrated.projectModules as number[]) || []).map(
      id => projectModuleEntities[id]
    ) as ProjectModule[];
  } else {
    delete communityHydrated.projectModules;
  }

  if (groupEntities) {
    communityHydrated.groups = ((communityHydrated.groups as number[]) || []).map(id => groupEntities[id]) as Group[];
  } else {
    delete communityHydrated.groups;
  }

  if (userEntities) {
    communityHydrated.users = ((communityHydrated.users as number[]) || []).map(id => userEntities[id]) as User[];
  } else {
    delete communityHydrated.users;
  }

  return communityHydrated as Community;
}
