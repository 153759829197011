import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserHistory, UserHistoryEntityState } from '@api/api-interfaces';
import { User, UserEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { UserHistoryState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const userHistoryRelations: string[] = ['users'];

export const { selectEntities, selectAll } = UserHistoryState.adapter.getSelectors();

export const selectUserHistoryState = createFeatureSelector<UserHistoryState.IState>(
  UserHistoryState.userHistoryFeatureKey
);

export const selectIsLoadedUserHistory = createSelector(
  selectUserHistoryState,
  (state: UserHistoryState.IState) => state.isLoaded
);

export const selectIsLoadingUserHistory = createSelector(
  selectUserHistoryState,
  (state: UserHistoryState.IState) => state.isLoading
);

export const selectIsReadyUserHistory = createSelector(
  selectUserHistoryState,
  (state: UserHistoryState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUserHistory = createSelector(
  selectUserHistoryState,
  (state: UserHistoryState.IState) => state.isLoaded && !state.isLoading
);

export const selectUserHistoriesEntities = createSelector(selectUserHistoryState, selectEntities);

export const selectUserHistoriesArray = createSelector(selectUserHistoryState, selectAll);

export const selectIdUserHistoriesActive = createSelector(
  selectUserHistoryState,
  (state: UserHistoryState.IState) => state.actives
);

const userHistoriesInObject = (userHistories: Dictionary<UserHistoryEntityState>) => ({ userHistories });

const selectUserHistoriesEntitiesDictionary = createSelector(selectUserHistoriesEntities, userHistoriesInObject);

const selectAllUserHistoriesObject = createSelector(selectUserHistoriesEntities, userHistories => {
  return hydrateAll({ userHistories });
});

const selectOneUserHistoryDictionary = (idUserHistory: number) =>
  createSelector(selectUserHistoriesEntities, userHistories => ({
    userHistories: { [idUserHistory]: userHistories[idUserHistory] }
  }));

const selectOneUserHistoryDictionaryWithoutChild = (idUserHistory: number) =>
  createSelector(selectUserHistoriesEntities, userHistories => ({
    userHistory: userHistories[idUserHistory]
  }));

const selectActiveUserHistoriesEntities = createSelector(
  selectIdUserHistoriesActive,
  selectUserHistoriesEntities,
  (actives: number[], userHistories: Dictionary<UserHistoryEntityState>) =>
    getUserHistoriesFromActives(actives, userHistories)
);

function getUserHistoriesFromActives(
  actives: number[],
  userHistories: Dictionary<UserHistoryEntityState>
): Dictionary<UserHistoryEntityState> {
  return actives.reduce((acc, idActive) => {
    if (userHistories[idActive]) {
      acc[idActive] = userHistories[idActive];
    }
    return acc;
  }, {} as Dictionary<UserHistoryEntityState>);
}

const selectAllUserHistoriesSelectors: Dictionary<Selector> = {};
export function selectAllUserHistories(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UserHistory>(
      schema,
      selectAllUserHistoriesSelectors,
      selectUserHistoriesEntitiesDictionary,
      getRelationSelectors,
      userHistoryRelations,
      hydrateAll,
      'userHistory'
    );
  } else {
    return selectAllUserHistoriesObject;
  }
}

export function selectAllUserHistoriesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'userHistories'
): Selector {
  return createSelector(selectAllUserHistories(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserHistoryEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.userHistories.length; i++) {
      res[customKey][result.userHistories[i].idUserHistory] = result.userHistories[i];
    }
    return res;
  });
}

export function selectOneUserHistory(schema: SelectSchema = {}, idUserHistory: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneUserHistoryDictionary(idUserHistory)];
    selectors.push(...getRelationSelectors(schema, userHistoryRelations, 'userHistory'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserHistoryDictionaryWithoutChild(idUserHistory);
  }
}

export function selectActiveUserHistories(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveUserHistoriesEntities, userHistories => ({
      userHistories
    }))
  ];
  selectors.push(...getRelationSelectors(schema, userHistoryRelations, 'userHistory'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  userHistories: Dictionary<UserHistoryEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { userHistories: (UserHistory | null)[] } {
  const { userHistories, users } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    userHistories: Object.keys(userHistories).map(idUserHistory =>
      hydrate(userHistories[idUserHistory] as UserHistoryEntityState, users)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { userHistory: UserHistoryEntityState | null } {
  const { userHistories, users } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const userHistory = Object.values(userHistories)[0];
  return {
    userHistory: hydrate(userHistory as UserHistoryEntityState, users)
  };
}

function hydrate(userHistory: UserHistoryEntityState, userEntities?: Dictionary<UserEntityState>): UserHistory | null {
  if (!userHistory) {
    return null;
  }

  const userHistoryHydrated: UserHistoryEntityState = { ...userHistory };
  if (userEntities) {
    userHistoryHydrated.user = userEntities[userHistory.user as number] as User;
  } else {
    delete userHistoryHydrated.user;
  }

  return userHistoryHydrated as UserHistory;
}
