import { Injectable } from '@angular/core';
import { ElementCash } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedElementCashApiService } from './element-cash-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ElementCashApiService extends GeneratedElementCashApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getElementForFinancial(idCommunity: number): Observable<ElementCash[]> {
    return this.repo.getData<ElementCash[]>('element-cash/financial/' + idCommunity, {});
  }
}
