import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ElementLibraryReminderEnum, ElementLibraryTypeEnum } from '@enums';
import { ElementLibrary } from '@api/api-interfaces';

@Component({
  selector: 'app-action-reminder',
  templateUrl: './action-reminder-list.component.html',
  styleUrls: ['./action-reminder-list.component.scss']
})
export class ActionReminderListComponent implements OnInit {
  @Input() reminders: ElementLibrary[];
  @Input() actionForm: FormGroup;
  @Input() idElement: number;
  @Input() isViewer: boolean;

  @Output() newReminderList = new EventEmitter<ElementLibrary[]>();

  changedReminders: ElementLibrary[] = [];
  reminderChoices;

  displayInput = false;

  constructor(private fb: FormBuilder) {
    this.reminderChoices = [
      {
        value: ElementLibraryReminderEnum.date_start,
        display: 'date début'
      },
      {
        value: ElementLibraryReminderEnum.date_end,
        display: 'date fin'
      }
    ];
  }

  ngOnInit() {
    this.initRemindersForm();
    this.changedReminders = [...this.reminders];
    this.setReminders(this.reminders);
  }

  initRemindersForm() {
    this.actionForm.setControl('listReminders', this.fb.array([]));
    this.actionForm.setControl(
      'newReminder',
      this.fb.group({
        value: new FormControl(null),
        reminder: new FormControl('date_start'),
        beforeOrAfter: new FormControl('before')
      })
    );
  }

  setReminders(reminders: ElementLibrary[]): void {
    reminders.forEach(reminder => {
      this.listReminders.push(
        this.fb.group({
          idElement: reminder.idElement,
          idElementLibrary: reminder.idElementLibrary,
          type: ElementLibraryTypeEnum.reminder,
          value: reminder.value,
          reminder: reminder.reminder,
          isEditing: false
        })
      );
    });
  }

  addReminder(): void {
    if (
      this.newReminder.status === 'VALID' &&
      this.newReminder.get('value').value !== null &&
      this.newReminder.get('beforeOrAfter').value !== null
    ) {
      this.listReminders.push(
        this.fb.group({
          idElement: this.idElement,
          type: ElementLibraryTypeEnum.reminder,
          value:
            this.newReminder.controls.beforeOrAfter.value === 'after'
              ? -this.newReminder.controls.value.value
              : this.newReminder.controls.value.value,
          reminder: this.newReminder.controls.reminder.value,
          isEditing: false
        })
      );
      this.newReminderList.emit(this.listReminders.value);
      this.newReminder.get('value').setValue(null);
      this.newReminder.get('reminder').setValue('date_start');
      this.actionForm.markAsDirty();
    }
  }

  removeReminder(itemIndex: number): void {
    this.listReminders.removeAt(itemIndex);
    this.newReminderList.emit(this.listReminders.value);
    this.actionForm.markAsDirty();
  }

  get listReminders(): FormArray {
    return this.actionForm.controls.listReminders as FormArray;
  }

  get newReminder(): FormGroup {
    return this.actionForm.controls.newReminder as FormGroup;
  }

  toogleShowInput() {
    this.displayInput = !this.displayInput;
  }
}
