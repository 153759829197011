import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationMilestoneFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneFamilyState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';

export const organizationMilestoneFamilyReducersGeneratedFunctions: ReducerTypes<
  OrganizationMilestoneFamilyState.IState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationMilestoneFamilyGeneratedActions.getOneOrganizationMilestoneFamily,
    (state: OrganizationMilestoneFamilyState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationMilestoneFamilyGeneratedActions.getManyOrganizationMilestoneFamilys,
    (state: OrganizationMilestoneFamilyState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationMilestoneFamilyGeneratedActions.upsertOneOrganizationMilestoneFamily,
    (state: OrganizationMilestoneFamilyState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.upsertManyOrganizationMilestoneFamilysSuccess,
    (state: OrganizationMilestoneFamilyState.IState, { organizationMilestoneFamilys }) =>
      OrganizationMilestoneFamilyState.adapter.upsertMany(organizationMilestoneFamilys, setLoadingsState(state, false))
  ),
  on(
    OrganizationMilestoneFamilyGeneratedActions.deleteOneOrganizationMilestoneFamily,
    (state: OrganizationMilestoneFamilyState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationMilestoneFamilyGeneratedActions.deleteOneOrganizationMilestoneFamilySuccess,
    (state: OrganizationMilestoneFamilyState.IState, { idOrganizationMilestoneFamily }) =>
      OrganizationMilestoneFamilyState.adapter.removeOne(idOrganizationMilestoneFamily, setLoadingsState(state, false))
  ),
  on(
    OrganizationMilestoneFamilyGeneratedActions.clearActivesOrganizationMilestoneFamilys,
    (state: OrganizationMilestoneFamilyState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationMilestoneFamilyGeneratedActions.addManyActivesOrganizationMilestoneFamilys,
    (state: OrganizationMilestoneFamilyState.IState, { idOrganizationMilestoneFamilys }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationMilestoneFamilys)
    })
  ),
  on(
    OrganizationMilestoneFamilyGeneratedActions.deleteOneActiveOrganizationMilestoneFamily,
    (state: OrganizationMilestoneFamilyState.IState, { idOrganizationMilestoneFamily }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationMilestoneFamily)
    })
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.clearOrganizationMilestoneFamilys,
    () => OrganizationMilestoneFamilyState.initialState
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.addManyCommunityMilestoneFamilySuccess,
    (
      state: OrganizationMilestoneFamilyState.IState,
      { idOrganizationMilestoneFamily, idCommunityMilestoneFamilys }
    ) => {
      if (!state.entities[idOrganizationMilestoneFamily]) {
        return state;
      }
      const communityMilestoneFamilys =
        (state.entities[idOrganizationMilestoneFamily]?.communityMilestoneFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestoneFamily]: {
            ...state.entities[idOrganizationMilestoneFamily],
            communityMilestoneFamilys: communityMilestoneFamilys.concat(
              idCommunityMilestoneFamilys.filter(id => communityMilestoneFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.deleteManyCommunityMilestoneFamilySuccess,
    (
      state: OrganizationMilestoneFamilyState.IState,
      { idCommunityMilestoneFamilys, idOrganizationMilestoneFamilys }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestoneFamilys.reduce((entities, idOrganizationMilestoneFamily) => {
            if (!state.entities[idOrganizationMilestoneFamily]?.communityMilestoneFamilys) {
              return entities;
            }
            entities[idOrganizationMilestoneFamily] = {
              ...state.entities[idOrganizationMilestoneFamily],
              communityMilestoneFamilys: (
                state.entities[idOrganizationMilestoneFamily]?.communityMilestoneFamilys as number[]
              )?.filter(
                (idCommunityMilestoneFamily: number) =>
                  !idCommunityMilestoneFamilys.some((id: number) => id === idCommunityMilestoneFamily)
              )
            } as OrganizationMilestoneFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.addManyOrganizationMilestoneAssociationSuccess,
    (
      state: OrganizationMilestoneFamilyState.IState,
      { idOrganizationMilestoneFamily, idOrganizationMilestoneAssociations }
    ) => {
      if (!state.entities[idOrganizationMilestoneFamily]) {
        return state;
      }
      const organizationMilestoneAssociations =
        (state.entities[idOrganizationMilestoneFamily]?.organizationMilestoneAssociations as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestoneFamily]: {
            ...state.entities[idOrganizationMilestoneFamily],
            organizationMilestoneAssociations: organizationMilestoneAssociations.concat(
              idOrganizationMilestoneAssociations.filter(id => organizationMilestoneAssociations.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationMilestoneAssociationSuccess,
    (
      state: OrganizationMilestoneFamilyState.IState,
      { idOrganizationMilestoneAssociations, idOrganizationMilestoneFamilys }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestoneFamilys.reduce((entities, idOrganizationMilestoneFamily) => {
            if (!state.entities[idOrganizationMilestoneFamily]?.organizationMilestoneAssociations) {
              return entities;
            }
            entities[idOrganizationMilestoneFamily] = {
              ...state.entities[idOrganizationMilestoneFamily],
              organizationMilestoneAssociations: (
                state.entities[idOrganizationMilestoneFamily]?.organizationMilestoneAssociations as number[]
              )?.filter(
                (idOrganizationMilestoneAssociation: number) =>
                  !idOrganizationMilestoneAssociations.some((id: number) => id === idOrganizationMilestoneAssociation)
              )
            } as OrganizationMilestoneFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.addManyOrganizationMilestoneSuccess,
    (state: OrganizationMilestoneFamilyState.IState, { idOrganizationMilestoneFamily, idOrganizationMilestones }) => {
      if (!state.entities[idOrganizationMilestoneFamily]) {
        return state;
      }
      const organizationMilestones =
        (state.entities[idOrganizationMilestoneFamily]?.organizationMilestones as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestoneFamily]: {
            ...state.entities[idOrganizationMilestoneFamily],
            organizationMilestones: organizationMilestones.concat(
              idOrganizationMilestones.filter(id => organizationMilestones.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationMilestoneSuccess,
    (state: OrganizationMilestoneFamilyState.IState, { idOrganizationMilestones, idOrganizationMilestoneFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestoneFamilys.reduce((entities, idOrganizationMilestoneFamily) => {
            if (!state.entities[idOrganizationMilestoneFamily]?.organizationMilestones) {
              return entities;
            }
            entities[idOrganizationMilestoneFamily] = {
              ...state.entities[idOrganizationMilestoneFamily],
              organizationMilestones: (
                state.entities[idOrganizationMilestoneFamily]?.organizationMilestones as number[]
              )?.filter(
                (idOrganizationMilestone: number) =>
                  !idOrganizationMilestones.some((id: number) => id === idOrganizationMilestone)
              )
            } as OrganizationMilestoneFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.addOrganizationSuccess,
    (state: OrganizationMilestoneFamilyState.IState, { idOrganizationMilestoneFamily, idOrganization }) => {
      if (!state.entities[idOrganizationMilestoneFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestoneFamily]: {
            ...state.entities[idOrganizationMilestoneFamily],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationMilestoneFamilyState.IState, { idOrganizations, idOrganizationMilestoneFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestoneFamilys.reduce((entities, idOrganizationMilestoneFamily) => {
            if (!state.entities[idOrganizationMilestoneFamily]?.organization) {
              return entities;
            }
            entities[idOrganizationMilestoneFamily] = {
              ...state.entities[idOrganizationMilestoneFamily],
              organization: idOrganizations.some(
                (idOrganization: number) =>
                  idOrganization === state.entities[idOrganizationMilestoneFamily]?.organization
              )
                ? undefined
                : state.entities[idOrganizationMilestoneFamily]?.organization
            } as OrganizationMilestoneFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.addOrganizationFamilySuccess,
    (state: OrganizationMilestoneFamilyState.IState, { idOrganizationMilestoneFamily, idOrganizationFamily }) => {
      if (!state.entities[idOrganizationMilestoneFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestoneFamily]: {
            ...state.entities[idOrganizationMilestoneFamily],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationMilestoneFamilyState.IState, { idOrganizationFamilys, idOrganizationMilestoneFamilys }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestoneFamilys.reduce((entities, idOrganizationMilestoneFamily) => {
            if (!state.entities[idOrganizationMilestoneFamily]?.organizationFamily) {
              return entities;
            }
            entities[idOrganizationMilestoneFamily] = {
              ...state.entities[idOrganizationMilestoneFamily],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idOrganizationMilestoneFamily]?.organizationFamily
              )
                ? undefined
                : state.entities[idOrganizationMilestoneFamily]?.organizationFamily
            } as OrganizationMilestoneFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneFamilyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.addOrganizationCaracteristicSuccess,
    (
      state: OrganizationMilestoneFamilyState.IState,
      { idOrganizationMilestoneFamily, idOrganizationCaracteristic }
    ) => {
      if (!state.entities[idOrganizationMilestoneFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestoneFamily]: {
            ...state.entities[idOrganizationMilestoneFamily],
            organizationCaracteristic: idOrganizationCaracteristic
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneFamilyGeneratedActions.deleteManyOrganizationCaracteristicSuccess,
    (
      state: OrganizationMilestoneFamilyState.IState,
      { idOrganizationCaracteristics, idOrganizationMilestoneFamilys }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestoneFamilys.reduce((entities, idOrganizationMilestoneFamily) => {
            if (!state.entities[idOrganizationMilestoneFamily]?.organizationCaracteristic) {
              return entities;
            }
            entities[idOrganizationMilestoneFamily] = {
              ...state.entities[idOrganizationMilestoneFamily],
              organizationCaracteristic: idOrganizationCaracteristics.some(
                (idOrganizationCaracteristic: number) =>
                  idOrganizationCaracteristic ===
                  state.entities[idOrganizationMilestoneFamily]?.organizationCaracteristic
              )
                ? undefined
                : state.entities[idOrganizationMilestoneFamily]?.organizationCaracteristic
            } as OrganizationMilestoneFamilyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneFamilyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationMilestoneFamilyState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationMilestoneFamilyState.IState {
  return { ...state, isLoaded, isLoading };
}
