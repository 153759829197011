import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityMilestoneFamily, CommunityMilestoneFamilyEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityMilestoneFamilyState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityMilestoneFamilyRelations: string[] = ['organizationMilestoneFamilys', 'communities'];

export const { selectEntities, selectAll } = CommunityMilestoneFamilyState.adapter.getSelectors();

export const selectCommunityMilestoneFamilyState = createFeatureSelector<CommunityMilestoneFamilyState.IState>(
  CommunityMilestoneFamilyState.communityMilestoneFamilyFeatureKey
);

export const selectIsLoadedCommunityMilestoneFamily = createSelector(
  selectCommunityMilestoneFamilyState,
  (state: CommunityMilestoneFamilyState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityMilestoneFamily = createSelector(
  selectCommunityMilestoneFamilyState,
  (state: CommunityMilestoneFamilyState.IState) => state.isLoading
);

export const selectIsReadyCommunityMilestoneFamily = createSelector(
  selectCommunityMilestoneFamilyState,
  (state: CommunityMilestoneFamilyState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityMilestoneFamily = createSelector(
  selectCommunityMilestoneFamilyState,
  (state: CommunityMilestoneFamilyState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityMilestoneFamilysEntities = createSelector(
  selectCommunityMilestoneFamilyState,
  selectEntities
);

export const selectCommunityMilestoneFamilysArray = createSelector(selectCommunityMilestoneFamilyState, selectAll);

export const selectIdCommunityMilestoneFamilysActive = createSelector(
  selectCommunityMilestoneFamilyState,
  (state: CommunityMilestoneFamilyState.IState) => state.actives
);

const communityMilestoneFamilysInObject = (
  communityMilestoneFamilys: Dictionary<CommunityMilestoneFamilyEntityState>
) => ({ communityMilestoneFamilys });

const selectCommunityMilestoneFamilysEntitiesDictionary = createSelector(
  selectCommunityMilestoneFamilysEntities,
  communityMilestoneFamilysInObject
);

const selectAllCommunityMilestoneFamilysObject = createSelector(
  selectCommunityMilestoneFamilysEntities,
  communityMilestoneFamilys => {
    return hydrateAll({ communityMilestoneFamilys });
  }
);

const selectOneCommunityMilestoneFamilyDictionary = (idCommunityMilestoneFamily: number) =>
  createSelector(selectCommunityMilestoneFamilysEntities, communityMilestoneFamilys => ({
    communityMilestoneFamilys: { [idCommunityMilestoneFamily]: communityMilestoneFamilys[idCommunityMilestoneFamily] }
  }));

const selectOneCommunityMilestoneFamilyDictionaryWithoutChild = (idCommunityMilestoneFamily: number) =>
  createSelector(selectCommunityMilestoneFamilysEntities, communityMilestoneFamilys => ({
    communityMilestoneFamily: communityMilestoneFamilys[idCommunityMilestoneFamily]
  }));

const selectActiveCommunityMilestoneFamilysEntities = createSelector(
  selectIdCommunityMilestoneFamilysActive,
  selectCommunityMilestoneFamilysEntities,
  (actives: number[], communityMilestoneFamilys: Dictionary<CommunityMilestoneFamilyEntityState>) =>
    getCommunityMilestoneFamilysFromActives(actives, communityMilestoneFamilys)
);

function getCommunityMilestoneFamilysFromActives(
  actives: number[],
  communityMilestoneFamilys: Dictionary<CommunityMilestoneFamilyEntityState>
): Dictionary<CommunityMilestoneFamilyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityMilestoneFamilys[idActive]) {
      acc[idActive] = communityMilestoneFamilys[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityMilestoneFamilyEntityState>);
}

const selectAllCommunityMilestoneFamilysSelectors: Dictionary<Selector> = {};
export function selectAllCommunityMilestoneFamilys(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityMilestoneFamily>(
      schema,
      selectAllCommunityMilestoneFamilysSelectors,
      selectCommunityMilestoneFamilysEntitiesDictionary,
      getRelationSelectors,
      communityMilestoneFamilyRelations,
      hydrateAll,
      'communityMilestoneFamily'
    );
  } else {
    return selectAllCommunityMilestoneFamilysObject;
  }
}

export function selectAllCommunityMilestoneFamilysDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityMilestoneFamilys'
): Selector {
  return createSelector(selectAllCommunityMilestoneFamilys(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityMilestoneFamilyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityMilestoneFamilys.length; i++) {
      res[customKey][result.communityMilestoneFamilys[i].idCommunityMilestoneFamily] =
        result.communityMilestoneFamilys[i];
    }
    return res;
  });
}

export function selectOneCommunityMilestoneFamily(
  schema: SelectSchema = {},
  idCommunityMilestoneFamily: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityMilestoneFamilyDictionary(idCommunityMilestoneFamily)];
    selectors.push(...getRelationSelectors(schema, communityMilestoneFamilyRelations, 'communityMilestoneFamily'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityMilestoneFamilyDictionaryWithoutChild(idCommunityMilestoneFamily);
  }
}

export function selectActiveCommunityMilestoneFamilys(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityMilestoneFamilysEntities, communityMilestoneFamilys => ({
      communityMilestoneFamilys
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityMilestoneFamilyRelations, 'communityMilestoneFamily'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityMilestoneFamilys: Dictionary<CommunityMilestoneFamilyEntityState>;
  organizationMilestoneFamilys?: Dictionary<OrganizationMilestoneFamilyEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityMilestoneFamilys: (CommunityMilestoneFamily | null)[] } {
  const { communityMilestoneFamilys, organizationMilestoneFamilys, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communityMilestoneFamilys: Object.keys(communityMilestoneFamilys).map(idCommunityMilestoneFamily =>
      hydrate(
        communityMilestoneFamilys[idCommunityMilestoneFamily] as CommunityMilestoneFamilyEntityState,
        organizationMilestoneFamilys,
        communities
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityMilestoneFamily: CommunityMilestoneFamilyEntityState | null } {
  const { communityMilestoneFamilys, organizationMilestoneFamilys, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communityMilestoneFamily = Object.values(communityMilestoneFamilys)[0];
  return {
    communityMilestoneFamily: hydrate(
      communityMilestoneFamily as CommunityMilestoneFamilyEntityState,
      organizationMilestoneFamilys,
      communities
    )
  };
}

function hydrate(
  communityMilestoneFamily: CommunityMilestoneFamilyEntityState,
  organizationMilestoneFamilyEntities?: Dictionary<OrganizationMilestoneFamilyEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>
): CommunityMilestoneFamily | null {
  if (!communityMilestoneFamily) {
    return null;
  }

  const communityMilestoneFamilyHydrated: CommunityMilestoneFamilyEntityState = { ...communityMilestoneFamily };
  if (organizationMilestoneFamilyEntities) {
    communityMilestoneFamilyHydrated.organizationMilestoneFamily = organizationMilestoneFamilyEntities[
      communityMilestoneFamily.organizationMilestoneFamily as number
    ] as OrganizationMilestoneFamily;
  } else {
    delete communityMilestoneFamilyHydrated.organizationMilestoneFamily;
  }
  if (communityEntities) {
    communityMilestoneFamilyHydrated.community = communityEntities[
      communityMilestoneFamily.community as number
    ] as Community;
  } else {
    delete communityMilestoneFamilyHydrated.community;
  }

  return communityMilestoneFamilyHydrated as CommunityMilestoneFamily;
}
