import { Injectable } from '@angular/core';
import { OrganizationMilestone } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedOrganizationMilestoneApiService } from './organization-milestone-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationMilestoneApiService extends GeneratedOrganizationMilestoneApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateOrganizationMilestoneWithFamilies(organizationMilestone: {
    idOrganizationMilestone: number;
    idsFamilies: number | number[];
  }): Observable<OrganizationMilestone> {
    return this.repo.update('organization-milestone/updateWithFamilies', organizationMilestone);
  }
}
