import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationSectionGeneratedActions } from '@wip/store/actions';
import { OrganizationSectionState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationSectionEntityState } from '@api/api-interfaces';

export const organizationSectionReducersGeneratedFunctions: ReducerTypes<
  OrganizationSectionState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationSectionGeneratedActions.getOneOrganizationSection, (state: OrganizationSectionState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationSectionGeneratedActions.getManyOrganizationSections, (state: OrganizationSectionState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationSectionGeneratedActions.upsertOneOrganizationSection, (state: OrganizationSectionState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationSectionGeneratedActions.upsertManyOrganizationSectionsSuccess,
    (state: OrganizationSectionState.IState, { organizationSections }) =>
      OrganizationSectionState.adapter.upsertMany(organizationSections, setLoadingsState(state, false))
  ),
  on(OrganizationSectionGeneratedActions.deleteOneOrganizationSection, (state: OrganizationSectionState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationSectionGeneratedActions.deleteOneOrganizationSectionSuccess,
    (state: OrganizationSectionState.IState, { idOrganizationSection }) =>
      OrganizationSectionState.adapter.removeOne(idOrganizationSection, setLoadingsState(state, false))
  ),
  on(
    OrganizationSectionGeneratedActions.clearActivesOrganizationSections,
    (state: OrganizationSectionState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationSectionGeneratedActions.addManyActivesOrganizationSections,
    (state: OrganizationSectionState.IState, { idOrganizationSections }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationSections)
    })
  ),
  on(
    OrganizationSectionGeneratedActions.deleteOneActiveOrganizationSection,
    (state: OrganizationSectionState.IState, { idOrganizationSection }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationSection)
    })
  ),

  on(OrganizationSectionGeneratedActions.clearOrganizationSections, () => OrganizationSectionState.initialState),

  on(
    OrganizationSectionGeneratedActions.addOrganizationSuccess,
    (state: OrganizationSectionState.IState, { idOrganizationSection, idOrganization }) => {
      if (!state.entities[idOrganizationSection]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSection]: {
            ...state.entities[idOrganizationSection],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationSectionGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationSectionState.IState, { idOrganizations, idOrganizationSections }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSections.reduce((entities, idOrganizationSection) => {
            if (!state.entities[idOrganizationSection]?.organization) {
              return entities;
            }
            entities[idOrganizationSection] = {
              ...state.entities[idOrganizationSection],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationSection]?.organization
              )
                ? undefined
                : state.entities[idOrganizationSection]?.organization
            } as OrganizationSectionEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSectionEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSectionGeneratedActions.addSectionSuccess,
    (state: OrganizationSectionState.IState, { idOrganizationSection, idSection }) => {
      if (!state.entities[idOrganizationSection]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSection]: {
            ...state.entities[idOrganizationSection],
            section: idSection
          }
        }
      };
    }
  ),

  on(
    OrganizationSectionGeneratedActions.deleteManySectionSuccess,
    (state: OrganizationSectionState.IState, { idSections, idOrganizationSections }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSections.reduce((entities, idOrganizationSection) => {
            if (!state.entities[idOrganizationSection]?.section) {
              return entities;
            }
            entities[idOrganizationSection] = {
              ...state.entities[idOrganizationSection],
              section: idSections.some(
                (idSection: number) => idSection === state.entities[idOrganizationSection]?.section
              )
                ? undefined
                : state.entities[idOrganizationSection]?.section
            } as OrganizationSectionEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSectionEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationSectionState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationSectionState.IState {
  return { ...state, isLoaded, isLoading };
}
