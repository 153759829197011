import { UserRightState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userRightReducersGeneratedFunctions } from './user-right-generated.reducer';

const userRightReducersFunctions = [...userRightReducersGeneratedFunctions];

const userRightReducer = createReducer(UserRightState.initialState, ...userRightReducersFunctions);

export function reducer(state: UserRightState.IState | undefined, action: Action) {
  return userRightReducer(state, action);
}
