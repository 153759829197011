import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CommunityUserGeneratedActions } from '@wip/store/actions';
import { CommunityUserState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { CommunityUserEntityState } from '@api/api-interfaces';

export const communityUserReducersGeneratedFunctions: ReducerTypes<
  CommunityUserState.IState,
  readonly ActionCreator[]
>[] = [
  on(CommunityUserGeneratedActions.getOneCommunityUser, (state: CommunityUserState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityUserGeneratedActions.getManyCommunityUsers, (state: CommunityUserState.IState) =>
    setLoadingsState(state, true)
  ),
  on(CommunityUserGeneratedActions.upsertOneCommunityUser, (state: CommunityUserState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    CommunityUserGeneratedActions.upsertManyCommunityUsersSuccess,
    (state: CommunityUserState.IState, { communityUsers }) =>
      CommunityUserState.adapter.upsertMany(communityUsers, setLoadingsState(state, false))
  ),
  on(CommunityUserGeneratedActions.deleteOneCommunityUser, (state: CommunityUserState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    CommunityUserGeneratedActions.deleteOneCommunityUserSuccess,
    (state: CommunityUserState.IState, { idCommunityUser }) =>
      CommunityUserState.adapter.removeOne(idCommunityUser, setLoadingsState(state, false))
  ),
  on(CommunityUserGeneratedActions.clearActivesCommunityUsers, (state: CommunityUserState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    CommunityUserGeneratedActions.addManyActivesCommunityUsers,
    (state: CommunityUserState.IState, { idCommunityUsers }) => ({
      ...state,
      actives: state.actives.concat(idCommunityUsers)
    })
  ),
  on(
    CommunityUserGeneratedActions.deleteOneActiveCommunityUser,
    (state: CommunityUserState.IState, { idCommunityUser }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCommunityUser)
    })
  ),

  on(CommunityUserGeneratedActions.clearCommunityUsers, () => CommunityUserState.initialState),

  on(
    CommunityUserGeneratedActions.addCommunitySuccess,
    (state: CommunityUserState.IState, { idCommunityUser, idCommunity }) => {
      if (!state.entities[idCommunityUser]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCommunityUser]: {
            ...state.entities[idCommunityUser],
            community: idCommunity
          }
        }
      };
    }
  ),

  on(
    CommunityUserGeneratedActions.deleteManyCommunitySuccess,
    (state: CommunityUserState.IState, { idCommunities, idCommunityUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityUsers.reduce((entities, idCommunityUser) => {
            if (!state.entities[idCommunityUser]?.community) {
              return entities;
            }
            entities[idCommunityUser] = {
              ...state.entities[idCommunityUser],
              community: idCommunities.some(
                (idCommunity: number) => idCommunity === state.entities[idCommunityUser]?.community
              )
                ? undefined
                : state.entities[idCommunityUser]?.community
            } as CommunityUserEntityState;
            return entities;
          }, {} as Dictionary<CommunityUserEntityState>)
        }
      };
    }
  ),

  on(CommunityUserGeneratedActions.addUserSuccess, (state: CommunityUserState.IState, { idCommunityUser, idUser }) => {
    if (!state.entities[idCommunityUser]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCommunityUser]: {
          ...state.entities[idCommunityUser],
          user: idUser
        }
      }
    };
  }),

  on(
    CommunityUserGeneratedActions.deleteManyUserSuccess,
    (state: CommunityUserState.IState, { idUsers, idCommunityUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCommunityUsers.reduce((entities, idCommunityUser) => {
            if (!state.entities[idCommunityUser]?.user) {
              return entities;
            }
            entities[idCommunityUser] = {
              ...state.entities[idCommunityUser],
              user: idUsers.some((idUser: number) => idUser === state.entities[idCommunityUser]?.user)
                ? undefined
                : state.entities[idCommunityUser]?.user
            } as CommunityUserEntityState;
            return entities;
          }, {} as Dictionary<CommunityUserEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CommunityUserState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CommunityUserState.IState {
  return { ...state, isLoaded, isLoading };
}
