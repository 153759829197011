import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationFamily } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationFamilyRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationFamilySelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationFamilyService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationFamilySelectors.selectIsLoadedOrganizationFamily));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationFamilySelectors.selectIsLoadingOrganizationFamily));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationFamilySelectors.selectIsReadyAndLoadedOrganizationFamily as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationFamilys(schema: SelectSchema = {}): Observable<OrganizationFamily[]> {
    return this.store$.pipe(select(OrganizationFamilySelectors.selectAllOrganizationFamilys(schema))).pipe(
      switchMap(({ organizationFamilys }: { organizationFamilys: OrganizationFamily[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationFamilys));
      })
    );
  }

  public selectOneOrganizationFamily(
    idOrganizationFamily: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationFamily> {
    return this.store$
      .pipe(select(OrganizationFamilySelectors.selectOneOrganizationFamily(schema, idOrganizationFamily)))
      .pipe(
        switchMap(({ organizationFamily }: { organizationFamily: OrganizationFamily }) => {
          return this.getReady(schema).pipe(mapTo(organizationFamily));
        })
      );
  }

  public selectAllActiveOrganizationFamilys(schema: SelectSchema = {}): Observable<OrganizationFamily[]> {
    return this.store$.pipe(select(OrganizationFamilySelectors.selectActiveOrganizationFamilys(schema))).pipe(
      switchMap(({ organizationFamilys }: { organizationFamilys: OrganizationFamily[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationFamilys));
      })
    );
  }

  public selectIdOrganizationFamilysActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationFamilySelectors.selectIdOrganizationFamilysActive)).pipe(
      switchMap((idOrganizationFamilys: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationFamilys));
      })
    );
  }

  public getOneOrganizationFamily(
    idOrganizationFamily: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationFamily> {
    this.store$.dispatch(OrganizationFamilyGeneratedActions.getOneOrganizationFamily({ idOrganizationFamily, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert,
        OrganizationFamilyGeneratedActions.organizationFamilysFailure,
        true
      );
    }
  }

  public getManyOrganizationFamilys(params: any = {}, getResult?: boolean): void | Observable<OrganizationFamily[]> {
    this.store$.dispatch(OrganizationFamilyGeneratedActions.getManyOrganizationFamilys({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert,
        OrganizationFamilyGeneratedActions.organizationFamilysFailure
      );
    }
  }

  public upsertOneOrganizationFamily(
    organizationFamily: Partial<OrganizationFamily>,
    ids: OrganizationFamilyRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationFamily> {
    this.store$.dispatch(OrganizationFamilyGeneratedActions.upsertOneOrganizationFamily({ organizationFamily, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationFamilyGeneratedActions.normalizeManyOrganizationFamilysAfterUpsert,
        OrganizationFamilyGeneratedActions.organizationFamilysFailure,
        true
      );
    }
  }

  public deleteOneOrganizationFamily(idOrganizationFamily: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(OrganizationFamilyGeneratedActions.deleteOneOrganizationFamily({ idOrganizationFamily }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationFamilyGeneratedActions.deleteOneOrganizationFamilySuccess,
        OrganizationFamilyGeneratedActions.organizationFamilysFailure
      );
    }
  }

  public setActiveOrganizationFamilys(idOrganizationFamilys: number[]): void {
    this.store$.dispatch(OrganizationFamilyGeneratedActions.clearActivesOrganizationFamilys());
    this.store$.dispatch(
      OrganizationFamilyGeneratedActions.addManyActivesOrganizationFamilys({ idOrganizationFamilys })
    );
  }
}
