import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserDevice, UserDeviceEntityState } from '@api/api-interfaces';
import { UserDeviceRelationsIds } from '@wip/store/ids-interfaces';

export const UserDeviceGeneratedActions = createActionGroup({
  source: 'User Device Generated',
  events: {
    'Get One User Device': props<{ idUserDevice: number; params?: any }>(),
    'Get Many User Devices': props<{ params: any }>(),
    'Add Many Actives User Devices': props<{ idUserDevices: number[] }>(),
    'Delete One Active User Device': props<{ idUserDevice: number }>(),
    'Clear Actives User Devices': emptyProps(),
    'Upsert One User Device': props<{ userDevice: Partial<UserDevice>; ids?: UserDeviceRelationsIds }>(),
    'Upsert Many User Devices': props<{ userDevices: Partial<UserDevice>[]; ids?: UserDeviceRelationsIds }>(),
    'Upsert Many User Devices Success': props<{ userDevices: UserDeviceEntityState[] }>(),
    'Delete One User Device': props<{ idUserDevice: number }>(),
    'Delete One User Device Success': props<{ idUserDevice: number }>(),
    'Normalize Many User Devices After Upsert': props<{ userDevices: UserDeviceEntityState[] }>(),
    'User Devices Failure': props<{ error: HttpErrorResponse }>(),
    'Clear User Devices': emptyProps(),
    'Add User Success': props<{ idUserDevice: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idUserDevices: number[] }>()
  }
});
