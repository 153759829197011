import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedCommunityDataRowApiService } from './community-data-row-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityDataRowApiService extends GeneratedCommunityDataRowApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
