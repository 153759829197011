import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Element, OrganizationMilestone } from '@api/api-interfaces';
@UntilDestroy()
@Component({
  selector: 'wip-new-action-milestone',
  templateUrl: './new-action-milestone.component.html',
  styleUrls: ['./new-action-milestone.component.scss']
})
export class NewActionMilestoneComponent implements OnInit, AfterViewInit {
  @Input() actionForm: FormGroup;
  @Input() module: string;
  @Input() milestones: OrganizationMilestone[];
  @Input() element: Element;

  constructor() {}

  ngOnInit() {
    this.milestones = this.milestones?.sort((a, b) => a.ordre - b.ordre);
  }

  updateFormWithMilestone(milestoneID: number): void {
    const milestone = this.milestones.find(el => el.idOrganizationMilestone === milestoneID);
    this.actionForm.controls.titleControl.setValue(milestone ? milestone.libelle : 'Livrable');
  }

  private handleMilestoneUpdate() {
    this.actionForm.controls.idOrganizationMilestone.valueChanges.subscribe(value => {
      this.updateFormWithMilestone(value);
    });
  }

  ngAfterViewInit() {
    this.handleMilestoneUpdate();
  }
}
