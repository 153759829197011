import { createEntityAdapter } from '@ngrx/entity';
import { UserHistoryEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<UserHistoryEntityState>;

export const adapter = createEntityAdapter<UserHistoryEntityState>({
  selectId: o => o.idUserHistory
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const userHistoryFeatureKey = 'userHistory';
