import { UserGroupState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userGroupReducersGeneratedFunctions } from './user-group-generated.reducer';

const userGroupReducersFunctions = [...userGroupReducersGeneratedFunctions];

const userGroupReducer = createReducer(UserGroupState.initialState, ...userGroupReducersFunctions);

export function reducer(state: UserGroupState.IState | undefined, action: Action) {
  return userGroupReducer(state, action);
}
