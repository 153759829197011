import { Injectable } from '@angular/core';
import { Organization } from '@api/api-interfaces';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { GeneratedOrganizationApiService } from './organization-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationApiService extends GeneratedOrganizationApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getUserOrganizations(idUser: number): Observable<Organization[]> {
    return this.repo.getData<Organization[]>('organization/findByUser', { idUser });
  }

  public getSynthesisOrganizations(idUser: number): Observable<Organization[]> {
    return this.repo.getData<Organization[]>('organization/synthesis', { idUser });
  }

  public getOrganizationsFilters(idUser: number): Observable<Organization[]> {
    return this.repo.getData<Organization[]>('organization/filters', { idUser });
  }
}
