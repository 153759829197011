import {
  ColumnGroupOpenedEvent,
  ColumnState,
  DragStoppedEvent,
  FilterChangedEvent,
  GridApi,
  GridColumnsChangedEvent,
  GridOptions,
  SortChangedEvent
} from '@ag-grid-community/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterVisualComponent } from './filter-visual/filter-visual.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { StatusBarOptions, ToolPanelsIds } from './types';
import { hasColumnStatesChanges } from './utils';

export function includeStatusBarOptions(gridOptions: GridOptions, statusBarOptions: StatusBarOptions): GridOptions {
  type ColumnEvent =
    | SortChangedEvent
    | GridColumnsChangedEvent
    | ColumnGroupOpenedEvent
    | DragStoppedEvent
    | FilterChangedEvent;

  const handleColumnEvents$ = new Subject<ColumnEvent>(),
    { component, onFilterChanged, minimal } = statusBarOptions;

  handleColumnEvents$.pipe(debounceTime(600), untilDestroyed(component)).subscribe(event => {
    component.boardStateChanges.emit({
      //@ts-ignore
      source: event.source,
      type: event.type,
      organizationUser: component.boardState.organizationUser,
      idUserBoardState: component.boardState.idUserBoardState,
      columnState: JSON.stringify(event.api.getColumnState()),
      filterState: JSON.stringify(event.api.getFilterModel())
      // If quickFilter is null, text isn't inserted in JSON request body.
    });
    const filterVisual = api.getStatusPanel('FilterVisualComponent') as any;
    filterVisual.displayFilter();
  });

  let allowToDetectDisplayedColumnsChanges = false,
    columnStateSetted = false,
    api: GridApi,
    initialColumnStates: ColumnState[],
    defaultColumnStates: ColumnState[],
    defaultFilter: any,
    quickFilter: string;

  const fillFromSavedConfig = (): void => {
    const boardState = component.boardState;
    // On met applique le boardState sauvegardé
    if (!!api && boardState?.columnState) {
      const newColumnState = JSON.parse(boardState.columnState) as ColumnState[];
      api.applyColumnState({ state: newColumnState });
      api.moveColumns(
        newColumnState.map(elem => elem.colId),
        0
      );

      // Si pas le même nombre de clés entre 'defaut' et 'sauvegarde' ou qu'une clé n'est pas présente dans le tableau alors reset
      // => Une migration à du ajouter une colonne donc "forceReset"
      // if (!checkIfAllColumnFound) {
      //   api.resetColumnState();
      //   boardState = component.boardState;
      // }
    } else {
      api.resetColumnState();
      initialColumnStates = api.getColumnState();
      api.setFilterModel(null);
    }

    if (api) {
      const filterVisual = api.getStatusPanel('FilterVisualComponent') as any;
      if (boardState?.filterState) {
        const newFilterState = JSON.parse(boardState.filterState);
        setTimeout(() => {
          api.setFilterModel(newFilterState);
          filterVisual.displayFilter();
        }, 150);
      }

      filterVisual.displayFilter();
    }
  };

  const applyColumnState = (): void => {
    allowToDetectDisplayedColumnsChanges = false;
    api = component.gridApi;

    if (component.boardState?.columnState) {
      api.resetColumnState();
      initialColumnStates = api.getColumnState();
      api.setFilterModel(null);
      defaultColumnStates = JSON.parse(component.boardState?.columnState);
    } else {
      defaultColumnStates = initialColumnStates;
    }

    defaultFilter = !!component.boardState?.filterState ? JSON.parse(component.boardState?.filterState) : {};

    fillFromSavedConfig();

    // On détermine à quel moment on définit par nous-mêmes le column state
    // (si la valeur "columnState" de l'input "boardState" n'est pas définie, cela signifie que le board state passé dans l'input vient d'être créé,
    // on considère tout de même le column state comme défini manuellement).
    columnStateSetted = true;

    // On lance un délai au bout duquel on autorise la modification du column state par l'utilisateur.
    // Si on attend moins d'une seconde, le callback onDisplayedColumnsChanged est exécuté à tort, ce qui envoie des requêtes inutiles au back.
    setTimeout(() => {
      allowToDetectDisplayedColumnsChanges = true;
    }, 2000);

    // TODO : améliorer le mécanisme expliqué plus haut car quelques fois une requête inutile est envoyée malgré la restriction.
  };

  component.registerApplyColumnState(applyColumnState);

  const eventCallback = (event: ColumnEvent): void => {
    if (api) {
      const statusPanel = api.getStatusPanel('StatusBarComponent') as any;

      const hasColumnChanges = hasColumnStatesChanges(api.getColumnState(), defaultColumnStates);

      statusPanel.setAreColumnStatesChanged(hasColumnChanges);

      statusPanel.setAreFiltersActives(!(JSON.stringify(defaultFilter) === JSON.stringify(api.getFilterModel())));
      if (JSON.stringify(defaultFilter) !== JSON.stringify(api.getFilterModel()) || hasColumnChanges) {
        component.checkForUpdateButton(true);
      } else {
        component.checkForUpdateButton(false);
      }
    }

    if (event && Object.keys(event).length > 0 && columnStateSetted && allowToDetectDisplayedColumnsChanges) {
      handleColumnEvents$.next(event);
    }
  };

  const onSortChanged = eventCallback,
    onGridColumnsChanged = eventCallback,
    onColumnGroupOpened = eventCallback,
    onDragStopped = eventCallback;

  return {
    ...gridOptions,
    onSortChanged,
    onGridColumnsChanged,
    onColumnGroupOpened,
    onDragStopped,
    onFilterChanged: (event): void => {
      // filterManager is a private method of event.api
      // quickFilter = ((event.api as any).filterManager.quickFilter as string)?.toLocaleLowerCase();

      eventCallback(event);

      if (onFilterChanged) {
        onFilterChanged(event);
      }
    },
    onFirstDataRendered: fillFromSavedConfig,
    components: {
      FilterVisualComponent,
      StatusBarComponent
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'FilterVisualComponent',
          align: 'left',
          statusPanelParams: {
            getColumnState: (): any => {
              return api.getColumnState();
            },
            resetFilter: async (newFilter: any, manually: boolean = false): Promise<void> => {
              const filterToReset = await api.getColumnFilterInstance(newFilter);
              filterToReset.setModel(null);
              if (manually) {
                api.onFilterChanged('columnFilter');
              } else {
                api.onFilterChanged();
              }
            },
            setColumnState: (): void => {
              api.applyColumnState({
                defaultState: { sort: null }
              });
            },
            getFilterModel: (): any => {
              return api.getFilterModel();
            },
            getColumnDef: (s: string): any => {
              return api.getColumnDef(s);
            },
            getColumnDefs: (): any => {
              return api.getColumnDefs();
            },
            getQuickFilter: (): any => {
              return quickFilter;
            },
            resetQuickFilter: (): void => {
              api.setGridOption('quickFilterText', '');
            }
          }
        },
        {
          statusPanel: 'StatusBarComponent',
          statusPanelParams: {
            minimal,
            boardStateLibelle: component.boardState?.libelle,
            onSideBarToggle: (): void => {
              if (api.getOpenedToolPanel() === ToolPanelsIds.columns) {
                api.closeToolPanel();
                api.setSideBarVisible(false);
              } else {
                api.openToolPanel(ToolPanelsIds.columns);
                api.setSideBarVisible(true);
              }
            },
            resetFilters: (): void => {
              api.setFilterModel(defaultFilter);
            },
            resetColumnState: (): void => {
              api.applyColumnState({ state: defaultColumnStates });
              api.moveColumns(
                defaultColumnStates.map(elem => elem.colId),
                0
              );
              eventCallback(null);
            }
          }
        }
      ]
    },
    sideBar: {
      hiddenByDefault: true,
      position: 'right',
      toolPanels: [
        {
          id: ToolPanelsIds.columns,
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: true,
            suppressRowGroups: true,
            suppressValues: true
          }
        }
      ]
    }
  };
}
