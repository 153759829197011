import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationCaracteristicColumnEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationCaracteristicColumnEntityState>;

export const adapter = createEntityAdapter<OrganizationCaracteristicColumnEntityState>({
  selectId: o => o.idOrganizationCaracteristicColumn
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationCaracteristicColumnFeatureKey = 'organizationCaracteristicColumn';
