import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityMilestoneFamily, CommunityMilestoneFamilyEntityState } from '@api/api-interfaces';
import { CommunityMilestoneFamilyRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityMilestoneFamilyGeneratedActions = createActionGroup({
  source: 'Community Milestone Family Generated',
  events: {
    'Get One Community Milestone Family': props<{ idCommunityMilestoneFamily: number; params?: any }>(),
    'Get Many Community Milestone Familys': props<{ params: any }>(),
    'Add Many Actives Community Milestone Familys': props<{ idCommunityMilestoneFamilys: number[] }>(),
    'Delete One Active Community Milestone Family': props<{ idCommunityMilestoneFamily: number }>(),
    'Clear Actives Community Milestone Familys': emptyProps(),
    'Upsert One Community Milestone Family': props<{
      communityMilestoneFamily: Partial<CommunityMilestoneFamily>;
      ids?: CommunityMilestoneFamilyRelationsIds;
    }>(),
    'Upsert Many Community Milestone Familys': props<{
      communityMilestoneFamilys: Partial<CommunityMilestoneFamily>[];
      ids?: CommunityMilestoneFamilyRelationsIds;
    }>(),
    'Upsert Many Community Milestone Familys Success': props<{
      communityMilestoneFamilys: CommunityMilestoneFamilyEntityState[];
    }>(),
    'Delete One Community Milestone Family': props<{ idCommunityMilestoneFamily: number }>(),
    'Delete One Community Milestone Family Success': props<{ idCommunityMilestoneFamily: number }>(),
    'Normalize Many Community Milestone Familys After Upsert': props<{
      communityMilestoneFamilys: CommunityMilestoneFamilyEntityState[];
    }>(),
    'Community Milestone Familys Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Milestone Familys': emptyProps(),
    'Add Organization Milestone Family Success': props<{
      idCommunityMilestoneFamily: number;
      idOrganizationMilestoneFamily: number;
    }>(),
    'Delete Many Organization Milestone Family Success': props<{
      idOrganizationMilestoneFamilys: number[];
      idCommunityMilestoneFamilys: number[];
    }>(),
    'Add Community Success': props<{ idCommunityMilestoneFamily: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityMilestoneFamilys: number[] }>()
  }
});
