import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationDataColumnValue } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationDataColumnValueRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationDataColumnValueGeneratedActions } from '@wip/store/actions';
import { OrganizationDataColumnValueSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationDataColumnValueService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationDataColumnValueSelectors.selectIsLoadedOrganizationDataColumnValue));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationDataColumnValueSelectors.selectIsLoadingOrganizationDataColumnValue));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationDataColumnValueSelectors.selectIsReadyAndLoadedOrganizationDataColumnValue as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationDataColumnValues(schema: SelectSchema = {}): Observable<OrganizationDataColumnValue[]> {
    return this.store$
      .pipe(select(OrganizationDataColumnValueSelectors.selectAllOrganizationDataColumnValues(schema)))
      .pipe(
        switchMap(
          ({ organizationDataColumnValues }: { organizationDataColumnValues: OrganizationDataColumnValue[] }) => {
            return this.getReady(schema).pipe(mapTo(organizationDataColumnValues));
          }
        )
      );
  }

  public selectOneOrganizationDataColumnValue(
    idOrganizationDataColumnValue: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationDataColumnValue> {
    return this.store$
      .pipe(
        select(
          OrganizationDataColumnValueSelectors.selectOneOrganizationDataColumnValue(
            schema,
            idOrganizationDataColumnValue
          )
        )
      )
      .pipe(
        switchMap(({ organizationDataColumnValue }: { organizationDataColumnValue: OrganizationDataColumnValue }) => {
          return this.getReady(schema).pipe(mapTo(organizationDataColumnValue));
        })
      );
  }

  public selectAllActiveOrganizationDataColumnValues(
    schema: SelectSchema = {}
  ): Observable<OrganizationDataColumnValue[]> {
    return this.store$
      .pipe(select(OrganizationDataColumnValueSelectors.selectActiveOrganizationDataColumnValues(schema)))
      .pipe(
        switchMap(
          ({ organizationDataColumnValues }: { organizationDataColumnValues: OrganizationDataColumnValue[] }) => {
            return this.getReady(schema).pipe(mapTo(organizationDataColumnValues));
          }
        )
      );
  }

  public selectIdOrganizationDataColumnValuesActive(): Observable<number[]> {
    return this.store$
      .pipe(select(OrganizationDataColumnValueSelectors.selectIdOrganizationDataColumnValuesActive))
      .pipe(
        switchMap((idOrganizationDataColumnValues: number[]) => {
          return this.getReady().pipe(mapTo(idOrganizationDataColumnValues));
        })
      );
  }

  public getOneOrganizationDataColumnValue(
    idOrganizationDataColumnValue: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationDataColumnValue> {
    this.store$.dispatch(
      OrganizationDataColumnValueGeneratedActions.getOneOrganizationDataColumnValue({
        idOrganizationDataColumnValue,
        params
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationDataColumnValueGeneratedActions.normalizeManyOrganizationDataColumnValuesAfterUpsert,
        OrganizationDataColumnValueGeneratedActions.organizationDataColumnValuesFailure,
        true
      );
    }
  }

  public getManyOrganizationDataColumnValues(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationDataColumnValue[]> {
    this.store$.dispatch(OrganizationDataColumnValueGeneratedActions.getManyOrganizationDataColumnValues({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationDataColumnValueGeneratedActions.normalizeManyOrganizationDataColumnValuesAfterUpsert,
        OrganizationDataColumnValueGeneratedActions.organizationDataColumnValuesFailure
      );
    }
  }

  public upsertOneOrganizationDataColumnValue(
    organizationDataColumnValue: Partial<OrganizationDataColumnValue>,
    ids: OrganizationDataColumnValueRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationDataColumnValue> {
    this.store$.dispatch(
      OrganizationDataColumnValueGeneratedActions.upsertOneOrganizationDataColumnValue({
        organizationDataColumnValue,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationDataColumnValueGeneratedActions.normalizeManyOrganizationDataColumnValuesAfterUpsert,
        OrganizationDataColumnValueGeneratedActions.organizationDataColumnValuesFailure,
        true
      );
    }
  }

  public deleteOneOrganizationDataColumnValue(
    idOrganizationDataColumnValue: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationDataColumnValueGeneratedActions.deleteOneOrganizationDataColumnValue({
        idOrganizationDataColumnValue
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationDataColumnValueGeneratedActions.deleteOneOrganizationDataColumnValueSuccess,
        OrganizationDataColumnValueGeneratedActions.organizationDataColumnValuesFailure
      );
    }
  }

  public setActiveOrganizationDataColumnValues(idOrganizationDataColumnValues: number[]): void {
    this.store$.dispatch(OrganizationDataColumnValueGeneratedActions.clearActivesOrganizationDataColumnValues());
    this.store$.dispatch(
      OrganizationDataColumnValueGeneratedActions.addManyActivesOrganizationDataColumnValues({
        idOrganizationDataColumnValues
      })
    );
  }
}
