import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityCaracteristic, CommunityCaracteristicEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityCaracteristicState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityCaracteristicRelations: string[] = ['organizationCaracteristics', 'communities'];

export const { selectEntities, selectAll } = CommunityCaracteristicState.adapter.getSelectors();

export const selectCommunityCaracteristicState = createFeatureSelector<CommunityCaracteristicState.IState>(
  CommunityCaracteristicState.communityCaracteristicFeatureKey
);

export const selectIsLoadedCommunityCaracteristic = createSelector(
  selectCommunityCaracteristicState,
  (state: CommunityCaracteristicState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityCaracteristic = createSelector(
  selectCommunityCaracteristicState,
  (state: CommunityCaracteristicState.IState) => state.isLoading
);

export const selectIsReadyCommunityCaracteristic = createSelector(
  selectCommunityCaracteristicState,
  (state: CommunityCaracteristicState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityCaracteristic = createSelector(
  selectCommunityCaracteristicState,
  (state: CommunityCaracteristicState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityCaracteristicsEntities = createSelector(selectCommunityCaracteristicState, selectEntities);

export const selectCommunityCaracteristicsArray = createSelector(selectCommunityCaracteristicState, selectAll);

export const selectIdCommunityCaracteristicsActive = createSelector(
  selectCommunityCaracteristicState,
  (state: CommunityCaracteristicState.IState) => state.actives
);

const communityCaracteristicsInObject = (communityCaracteristics: Dictionary<CommunityCaracteristicEntityState>) => ({
  communityCaracteristics
});

const selectCommunityCaracteristicsEntitiesDictionary = createSelector(
  selectCommunityCaracteristicsEntities,
  communityCaracteristicsInObject
);

const selectAllCommunityCaracteristicsObject = createSelector(
  selectCommunityCaracteristicsEntities,
  communityCaracteristics => {
    return hydrateAll({ communityCaracteristics });
  }
);

const selectOneCommunityCaracteristicDictionary = (idCommunityCaracteristic: number) =>
  createSelector(selectCommunityCaracteristicsEntities, communityCaracteristics => ({
    communityCaracteristics: { [idCommunityCaracteristic]: communityCaracteristics[idCommunityCaracteristic] }
  }));

const selectOneCommunityCaracteristicDictionaryWithoutChild = (idCommunityCaracteristic: number) =>
  createSelector(selectCommunityCaracteristicsEntities, communityCaracteristics => ({
    communityCaracteristic: communityCaracteristics[idCommunityCaracteristic]
  }));

const selectActiveCommunityCaracteristicsEntities = createSelector(
  selectIdCommunityCaracteristicsActive,
  selectCommunityCaracteristicsEntities,
  (actives: number[], communityCaracteristics: Dictionary<CommunityCaracteristicEntityState>) =>
    getCommunityCaracteristicsFromActives(actives, communityCaracteristics)
);

function getCommunityCaracteristicsFromActives(
  actives: number[],
  communityCaracteristics: Dictionary<CommunityCaracteristicEntityState>
): Dictionary<CommunityCaracteristicEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityCaracteristics[idActive]) {
      acc[idActive] = communityCaracteristics[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityCaracteristicEntityState>);
}

const selectAllCommunityCaracteristicsSelectors: Dictionary<Selector> = {};
export function selectAllCommunityCaracteristics(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityCaracteristic>(
      schema,
      selectAllCommunityCaracteristicsSelectors,
      selectCommunityCaracteristicsEntitiesDictionary,
      getRelationSelectors,
      communityCaracteristicRelations,
      hydrateAll,
      'communityCaracteristic'
    );
  } else {
    return selectAllCommunityCaracteristicsObject;
  }
}

export function selectAllCommunityCaracteristicsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityCaracteristics'
): Selector {
  return createSelector(selectAllCommunityCaracteristics(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityCaracteristicEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityCaracteristics.length; i++) {
      res[customKey][result.communityCaracteristics[i].idCommunityCaracteristic] = result.communityCaracteristics[i];
    }
    return res;
  });
}

export function selectOneCommunityCaracteristic(schema: SelectSchema = {}, idCommunityCaracteristic: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityCaracteristicDictionary(idCommunityCaracteristic)];
    selectors.push(...getRelationSelectors(schema, communityCaracteristicRelations, 'communityCaracteristic'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityCaracteristicDictionaryWithoutChild(idCommunityCaracteristic);
  }
}

export function selectActiveCommunityCaracteristics(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityCaracteristicsEntities, communityCaracteristics => ({
      communityCaracteristics
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityCaracteristicRelations, 'communityCaracteristic'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityCaracteristics: Dictionary<CommunityCaracteristicEntityState>;
  organizationCaracteristics?: Dictionary<OrganizationCaracteristicEntityState>;
  communities?: Dictionary<CommunityEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityCaracteristics: (CommunityCaracteristic | null)[] } {
  const { communityCaracteristics, organizationCaracteristics, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communityCaracteristics: Object.keys(communityCaracteristics).map(idCommunityCaracteristic =>
      hydrate(
        communityCaracteristics[idCommunityCaracteristic] as CommunityCaracteristicEntityState,
        organizationCaracteristics,
        communities
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityCaracteristic: CommunityCaracteristicEntityState | null } {
  const { communityCaracteristics, organizationCaracteristics, communities } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communityCaracteristic = Object.values(communityCaracteristics)[0];
  return {
    communityCaracteristic: hydrate(
      communityCaracteristic as CommunityCaracteristicEntityState,
      organizationCaracteristics,
      communities
    )
  };
}

function hydrate(
  communityCaracteristic: CommunityCaracteristicEntityState,
  organizationCaracteristicEntities?: Dictionary<OrganizationCaracteristicEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>
): CommunityCaracteristic | null {
  if (!communityCaracteristic) {
    return null;
  }

  const communityCaracteristicHydrated: CommunityCaracteristicEntityState = { ...communityCaracteristic };
  if (organizationCaracteristicEntities) {
    communityCaracteristicHydrated.organizationCaracteristic = organizationCaracteristicEntities[
      communityCaracteristic.organizationCaracteristic as number
    ] as OrganizationCaracteristic;
  } else {
    delete communityCaracteristicHydrated.organizationCaracteristic;
  }
  if (communityEntities) {
    communityCaracteristicHydrated.community = communityEntities[
      communityCaracteristic.community as number
    ] as Community;
  } else {
    delete communityCaracteristicHydrated.community;
  }

  return communityCaracteristicHydrated as CommunityCaracteristic;
}
