@if (isLoading) {
  <mat-spinner class="spinner" [color]="'primary'"> </mat-spinner>
}

<div [ngClass]="{ hiding: isLoading }" class="flex flex-col w-full h-full">
  <ag-grid-angular
    #agGrid
    class="ag-theme-quartz"
    style="width: 100%; height: 100%"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [modules]="modules"
    [gridOptions]="getDefaultGridOptions()"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
  <br />
</div>
