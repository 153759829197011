import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommunityUserProfil } from '@api/api-interfaces';
import { CommunityUserProfilApiService } from '@wip/store/api-services';
import { AppState } from '@wip/store/configs/reducers';
import { concatMap, map, catchError, of, mergeMap, switchMap } from 'rxjs';
import {
  GeneratedCommunityUserProfilEffects,
  getDefaultAddCommunityUserProfilActions
} from './community-user-profil-generated.effects';
import * as communityUserProfilActions from '@wip/store/actions';
import { CommunityUserProfilRelationsIds } from '@wip/store/ids-interfaces';
@Injectable()
export class CommunityUserProfilEffects extends GeneratedCommunityUserProfilEffects {
  constructor(
    actions$: Actions,
    communityUserProfilApiService: CommunityUserProfilApiService,
    store$: Store<AppState>
  ) {
    super(actions$, communityUserProfilApiService, store$);
  }

  fillHandleUserProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityUserProfilActions.FillHandleUserProfils),
      concatMap(({ userProfils }: { userProfils: Partial<CommunityUserProfil>[] }) => {
        return this.communityUserProfilApiService.fillHandleUserProfils(userProfils).pipe(
          map((communityUserProfilsReturned: CommunityUserProfil[]) => {
            return communityUserProfilActions.CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert(
              {
                communityUserProfils: communityUserProfilsReturned
              }
            );
          }),
          catchError(error =>
            of(communityUserProfilActions.CommunityUserProfilGeneratedActions.communityUserProfilsFailure({ error }))
          )
        );
      })
    );
  });

  public upsertOneCommunityUserProfil$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityUserProfilActions.CommunityUserProfilGeneratedActions.upsertOneCommunityUserProfil),
      concatMap(
        ({
          communityUserProfil,
          ids
        }: {
          communityUserProfil: Partial<CommunityUserProfil>;
          ids?: CommunityUserProfilRelationsIds;
        }) => {
          if (communityUserProfil.idCommunityUserProfil) {
            return this.communityUserProfilApiService.updateCommunityUserProfilWithSideEffect(communityUserProfil).pipe(
              map((communityUserProfilReturned: CommunityUserProfil[]) => {
                return communityUserProfilActions.CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert(
                  {
                    communityUserProfils: communityUserProfilReturned
                  }
                );
              }),
              catchError(error =>
                of(
                  communityUserProfilActions.CommunityUserProfilGeneratedActions.communityUserProfilsFailure({ error })
                )
              )
            );
          } else {
            return this.communityUserProfilApiService
              .addCommunityUserProfil(communityUserProfil)
              .pipe(
                mergeMap((communityUserProfilReturned: CommunityUserProfil) =>
                  getDefaultAddCommunityUserProfilActions(communityUserProfilReturned, ids)
                )
              );
          }
        }
      )
    );
  });

  convertCommunityUserProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(communityUserProfilActions.convertCommunityUserProfils),
      switchMap(({ ids }) =>
        this.communityUserProfilApiService.convertCommunityUserProfils(ids).pipe(
          map((communityUserProfils: CommunityUserProfil[]) => {
            return communityUserProfilActions.CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert(
              { communityUserProfils }
            );
          }),
          catchError(error =>
            of(communityUserProfilActions.CommunityUserProfilGeneratedActions.communityUserProfilsFailure({ error }))
          )
        )
      )
    );
  });
}
