import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationDataColumnValue, OrganizationDataColumnValueEntityState } from '@api/api-interfaces';
import { OrganizationDataColumnValueApiService } from '@wip/store/api-services';
import { OrganizationDataColumnValueGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationDataColumnValue } from '@wip/store/configs/normalization';
import { OrganizationDataColumnValueSelectors } from '@wip/store/selectors';
import { OrganizationDataColumnValueRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationDataColumnGeneratedActions } from '@wip/store/actions';
import { ValeurGeneratedActions } from '@wip/store/actions';
import { Valeur } from '@api/api-interfaces';
import { OrganizationCaracteristicValueGeneratedActions } from '@wip/store/actions';
import { OrganizationCaracteristicValue } from '@api/api-interfaces';

export function getDefaultAddOrganizationDataColumnValueActions(
  organizationDataColumnValue: OrganizationDataColumnValueEntityState,
  ids?: OrganizationDataColumnValueRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationDataColumnValueGeneratedActions.normalizeManyOrganizationDataColumnValuesAfterUpsert({
      organizationDataColumnValues: [organizationDataColumnValue]
    })
  ];

  if (ids?.organizationDataColumn) {
    actions.push(
      OrganizationDataColumnGeneratedActions.addManyOrganizationDataColumnValueSuccess({
        idOrganizationDataColumn: ids.organizationDataColumn,
        idOrganizationDataColumnValues: [organizationDataColumnValue.idOrganizationDataColumnValue]
      })
    );
    actions.push(
      OrganizationDataColumnValueGeneratedActions.addOrganizationDataColumnSuccess({
        idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue,
        idOrganizationDataColumn: ids.organizationDataColumn
      })
    );
  }

  if (ids?.valeurs) {
    if (!Array.isArray(ids.valeurs)) {
      actions.push(
        ValeurGeneratedActions.upsertOneValeur({
          valeur: {
            idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue,
            idValeur: ids.valeurs as number
          } as Valeur
        })
      );
      actions.push(
        OrganizationDataColumnValueGeneratedActions.addManyValeurSuccess({
          idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue,
          idValeurs: [ids.valeurs as number]
        })
      );
    } else {
      actions.push(
        ValeurGeneratedActions.upsertManyValeurs({
          valeurs: (ids.valeurs as number[]).map((idValeur: number) => ({
            idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue,
            idValeur
          })) as Valeur[]
        })
      );
      actions.push(
        OrganizationDataColumnValueGeneratedActions.addManyValeurSuccess({
          idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue,
          idValeurs: ids.valeurs as number[]
        })
      );
    }
  }

  if (ids?.organizationCaracteristicValues) {
    if (!Array.isArray(ids.organizationCaracteristicValues)) {
      actions.push(
        OrganizationCaracteristicValueGeneratedActions.upsertOneOrganizationCaracteristicValue({
          organizationCaracteristicValue: {
            idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue,
            idOrganizationCaracteristicValue: ids.organizationCaracteristicValues as number
          } as OrganizationCaracteristicValue
        })
      );
      actions.push(
        OrganizationDataColumnValueGeneratedActions.addManyOrganizationCaracteristicValueSuccess({
          idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue,
          idOrganizationCaracteristicValues: [ids.organizationCaracteristicValues as number]
        })
      );
    } else {
      actions.push(
        OrganizationCaracteristicValueGeneratedActions.upsertManyOrganizationCaracteristicValues({
          organizationCaracteristicValues: (ids.organizationCaracteristicValues as number[]).map(
            (idOrganizationCaracteristicValue: number) => ({
              idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue,
              idOrganizationCaracteristicValue
            })
          ) as OrganizationCaracteristicValue[]
        })
      );
      actions.push(
        OrganizationDataColumnValueGeneratedActions.addManyOrganizationCaracteristicValueSuccess({
          idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue,
          idOrganizationCaracteristicValues: ids.organizationCaracteristicValues as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationDataColumnValueActions(
  organizationDataColumnValue: OrganizationDataColumnValueEntityState
): Action[] {
  const actions: Action[] = [
    OrganizationDataColumnValueGeneratedActions.deleteOneOrganizationDataColumnValueSuccess({
      idOrganizationDataColumnValue: organizationDataColumnValue.idOrganizationDataColumnValue
    })
  ];

  if (organizationDataColumnValue.organizationDataColumn) {
    actions.push(
      OrganizationDataColumnGeneratedActions.deleteManyOrganizationDataColumnValueSuccess({
        idOrganizationDataColumnValues: [organizationDataColumnValue.idOrganizationDataColumnValue],
        idOrganizationDataColumns: [organizationDataColumnValue.organizationDataColumn as number]
      })
    );
  }

  if (organizationDataColumnValue.valeurs) {
    actions.push(
      ValeurGeneratedActions.deleteManyOrganizationDataColumnValueSuccess({
        idOrganizationDataColumnValues: [organizationDataColumnValue.idOrganizationDataColumnValue],
        idValeurs: organizationDataColumnValue.valeurs as number[]
      })
    );
  }

  if (organizationDataColumnValue.organizationCaracteristicValues) {
    actions.push(
      OrganizationCaracteristicValueGeneratedActions.deleteManyOrganizationDataColumnValueSuccess({
        idOrganizationDataColumnValues: [organizationDataColumnValue.idOrganizationDataColumnValue],
        idOrganizationCaracteristicValues: organizationDataColumnValue.organizationCaracteristicValues as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationDataColumnValueEffects {
  constructor(
    protected actions$: Actions,
    protected organizationDataColumnValueApiService: OrganizationDataColumnValueApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationDataColumnValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDataColumnValueGeneratedActions.getManyOrganizationDataColumnValues),
      switchMap(({ params }) =>
        this.organizationDataColumnValueApiService.getOrganizationDataColumnValues(params).pipe(
          map((organizationDataColumnValues: OrganizationDataColumnValue[]) => {
            return OrganizationDataColumnValueGeneratedActions.normalizeManyOrganizationDataColumnValuesAfterUpsert({
              organizationDataColumnValues
            });
          }),
          catchError(error =>
            of(OrganizationDataColumnValueGeneratedActions.organizationDataColumnValuesFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationDataColumnValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDataColumnValueGeneratedActions.getOneOrganizationDataColumnValue),
      switchMap(idOrganizationDataColumnValue =>
        this.organizationDataColumnValueApiService.getOrganizationDataColumnValue(idOrganizationDataColumnValue).pipe(
          map((organizationDataColumnValue: OrganizationDataColumnValue) => {
            return OrganizationDataColumnValueGeneratedActions.normalizeManyOrganizationDataColumnValuesAfterUpsert({
              organizationDataColumnValues: [organizationDataColumnValue]
            });
          }),
          catchError(error =>
            of(OrganizationDataColumnValueGeneratedActions.organizationDataColumnValuesFailure({ error }))
          )
        )
      )
    );
  });

  upsertOneOrganizationDataColumnValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDataColumnValueGeneratedActions.upsertOneOrganizationDataColumnValue),
      concatMap(
        ({
          organizationDataColumnValue,
          ids
        }: {
          organizationDataColumnValue: Partial<OrganizationDataColumnValue>;
          ids?: OrganizationDataColumnValueRelationsIds;
        }) => {
          if (organizationDataColumnValue.idOrganizationDataColumnValue) {
            return this.organizationDataColumnValueApiService
              .updateOrganizationDataColumnValue(organizationDataColumnValue)
              .pipe(
                map((organizationDataColumnValueReturned: OrganizationDataColumnValue) => {
                  return OrganizationDataColumnValueGeneratedActions.normalizeManyOrganizationDataColumnValuesAfterUpsert(
                    { organizationDataColumnValues: [organizationDataColumnValueReturned] }
                  );
                }),
                catchError(error =>
                  of(OrganizationDataColumnValueGeneratedActions.organizationDataColumnValuesFailure({ error }))
                )
              );
          } else {
            return this.organizationDataColumnValueApiService
              .addOrganizationDataColumnValue(organizationDataColumnValue)
              .pipe(
                mergeMap((organizationDataColumnValueReturned: OrganizationDataColumnValue) =>
                  getDefaultAddOrganizationDataColumnValueActions(organizationDataColumnValueReturned, ids)
                ),
                catchError(error =>
                  of(OrganizationDataColumnValueGeneratedActions.organizationDataColumnValuesFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationDataColumnValue$ = createEffect(() => {
    const selectOrganizationDataColumnValueState$ = this.store$.select(
      OrganizationDataColumnValueSelectors.selectOrganizationDataColumnValueState
    );
    return this.actions$.pipe(
      ofType(OrganizationDataColumnValueGeneratedActions.deleteOneOrganizationDataColumnValue),
      withLatestFrom(selectOrganizationDataColumnValueState$),
      concatMap(([{ idOrganizationDataColumnValue }, state]) =>
        this.organizationDataColumnValueApiService
          .deleteOrganizationDataColumnValue(idOrganizationDataColumnValue)
          .pipe(
            mergeMap(_success =>
              getDefaultDeleteOrganizationDataColumnValueActions(
                state.entities[idOrganizationDataColumnValue] as OrganizationDataColumnValueEntityState
              )
            ),
            catchError(error =>
              of(OrganizationDataColumnValueGeneratedActions.organizationDataColumnValuesFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationDataColumnValuesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationDataColumnValueGeneratedActions.normalizeManyOrganizationDataColumnValuesAfterUpsert),
      concatMap(({ organizationDataColumnValues }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationDataColumnValue(
          organizationDataColumnValues,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationDataColumnValue] Normalization After Upsert Success')];
      })
    );
  });
}
