import { IMetaUser } from '@_app/shared/interfaces/MetaUser.interface';
import Responsible from '@_app/utils/responsible.utils';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-chip-content',
  templateUrl: './chip-content.component.html',
  styleUrls: ['./chip-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipContentComponent implements OnChanges {
  public user: {
    nom: string;
    prenom: string;
    roleLibelle: string;
  } = { nom: '', prenom: '', roleLibelle: '' };

  @Input() metaUser: IMetaUser;
  constructor() {}

  ngOnChanges(changes) {
    if (changes.metaUser) {
      if (this.metaUser.user) {
        this.user.nom = this.metaUser.user.nom;
        this.user.prenom = this.metaUser.user.prenom;
      } else if (this.metaUser.communityUserProfil?.user) {
        this.user.nom = this.metaUser.communityUserProfil.user.nom;
        this.user.prenom = this.metaUser.communityUserProfil.user.prenom;
      }

      this.user.roleLibelle = Responsible.getWording(this.metaUser, undefined, this.user.prenom, this.user.nom)['text'];
    }
  }
}
