import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationCaracteristicValue, OrganizationCaracteristicValueEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicColumn, OrganizationCaracteristicColumnEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristicRow, OrganizationCaracteristicRowEntityState } from '@api/api-interfaces';
import { OrganizationDataColumnValue, OrganizationDataColumnValueEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationCaracteristicValueState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationCaracteristicValueRelations: string[] = [
  'organizationCaracteristicColumns',
  'organizationCaracteristicRows',
  'organizationDataColumnValues'
];

export const { selectEntities, selectAll } = OrganizationCaracteristicValueState.adapter.getSelectors();

export const selectOrganizationCaracteristicValueState =
  createFeatureSelector<OrganizationCaracteristicValueState.IState>(
    OrganizationCaracteristicValueState.organizationCaracteristicValueFeatureKey
  );

export const selectIsLoadedOrganizationCaracteristicValue = createSelector(
  selectOrganizationCaracteristicValueState,
  (state: OrganizationCaracteristicValueState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationCaracteristicValue = createSelector(
  selectOrganizationCaracteristicValueState,
  (state: OrganizationCaracteristicValueState.IState) => state.isLoading
);

export const selectIsReadyOrganizationCaracteristicValue = createSelector(
  selectOrganizationCaracteristicValueState,
  (state: OrganizationCaracteristicValueState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationCaracteristicValue = createSelector(
  selectOrganizationCaracteristicValueState,
  (state: OrganizationCaracteristicValueState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationCaracteristicValuesEntities = createSelector(
  selectOrganizationCaracteristicValueState,
  selectEntities
);

export const selectOrganizationCaracteristicValuesArray = createSelector(
  selectOrganizationCaracteristicValueState,
  selectAll
);

export const selectIdOrganizationCaracteristicValuesActive = createSelector(
  selectOrganizationCaracteristicValueState,
  (state: OrganizationCaracteristicValueState.IState) => state.actives
);

const organizationCaracteristicValuesInObject = (
  organizationCaracteristicValues: Dictionary<OrganizationCaracteristicValueEntityState>
) => ({ organizationCaracteristicValues });

const selectOrganizationCaracteristicValuesEntitiesDictionary = createSelector(
  selectOrganizationCaracteristicValuesEntities,
  organizationCaracteristicValuesInObject
);

const selectAllOrganizationCaracteristicValuesObject = createSelector(
  selectOrganizationCaracteristicValuesEntities,
  organizationCaracteristicValues => {
    return hydrateAll({ organizationCaracteristicValues });
  }
);

const selectOneOrganizationCaracteristicValueDictionary = (idOrganizationCaracteristicValue: number) =>
  createSelector(selectOrganizationCaracteristicValuesEntities, organizationCaracteristicValues => ({
    organizationCaracteristicValues: {
      [idOrganizationCaracteristicValue]: organizationCaracteristicValues[idOrganizationCaracteristicValue]
    }
  }));

const selectOneOrganizationCaracteristicValueDictionaryWithoutChild = (idOrganizationCaracteristicValue: number) =>
  createSelector(selectOrganizationCaracteristicValuesEntities, organizationCaracteristicValues => ({
    organizationCaracteristicValue: organizationCaracteristicValues[idOrganizationCaracteristicValue]
  }));

const selectActiveOrganizationCaracteristicValuesEntities = createSelector(
  selectIdOrganizationCaracteristicValuesActive,
  selectOrganizationCaracteristicValuesEntities,
  (actives: number[], organizationCaracteristicValues: Dictionary<OrganizationCaracteristicValueEntityState>) =>
    getOrganizationCaracteristicValuesFromActives(actives, organizationCaracteristicValues)
);

function getOrganizationCaracteristicValuesFromActives(
  actives: number[],
  organizationCaracteristicValues: Dictionary<OrganizationCaracteristicValueEntityState>
): Dictionary<OrganizationCaracteristicValueEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationCaracteristicValues[idActive]) {
      acc[idActive] = organizationCaracteristicValues[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationCaracteristicValueEntityState>);
}

const selectAllOrganizationCaracteristicValuesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationCaracteristicValues(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationCaracteristicValue>(
      schema,
      selectAllOrganizationCaracteristicValuesSelectors,
      selectOrganizationCaracteristicValuesEntitiesDictionary,
      getRelationSelectors,
      organizationCaracteristicValueRelations,
      hydrateAll,
      'organizationCaracteristicValue'
    );
  } else {
    return selectAllOrganizationCaracteristicValuesObject;
  }
}

export function selectAllOrganizationCaracteristicValuesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationCaracteristicValues'
): Selector {
  return createSelector(selectAllOrganizationCaracteristicValues(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationCaracteristicValueEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationCaracteristicValues.length; i++) {
      res[customKey][result.organizationCaracteristicValues[i].idOrganizationCaracteristicValue] =
        result.organizationCaracteristicValues[i];
    }
    return res;
  });
}

export function selectOneOrganizationCaracteristicValue(
  schema: SelectSchema = {},
  idOrganizationCaracteristicValue: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationCaracteristicValueDictionary(idOrganizationCaracteristicValue)];
    selectors.push(
      ...getRelationSelectors(schema, organizationCaracteristicValueRelations, 'organizationCaracteristicValue')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationCaracteristicValueDictionaryWithoutChild(idOrganizationCaracteristicValue);
  }
}

export function selectActiveOrganizationCaracteristicValues(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationCaracteristicValuesEntities, organizationCaracteristicValues => ({
      organizationCaracteristicValues
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationCaracteristicValueRelations, 'organizationCaracteristicValue')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationCaracteristicValues: Dictionary<OrganizationCaracteristicValueEntityState>;
  organizationCaracteristicColumns?: Dictionary<OrganizationCaracteristicColumnEntityState>;
  organizationCaracteristicRows?: Dictionary<OrganizationCaracteristicRowEntityState>;
  organizationDataColumnValues?: Dictionary<OrganizationDataColumnValueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationCaracteristicValues: (OrganizationCaracteristicValue | null)[];
} {
  const {
    organizationCaracteristicValues,
    organizationCaracteristicColumns,
    organizationCaracteristicRows,
    organizationDataColumnValues
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationCaracteristicValues: Object.keys(organizationCaracteristicValues).map(
      idOrganizationCaracteristicValue =>
        hydrate(
          organizationCaracteristicValues[
            idOrganizationCaracteristicValue
          ] as OrganizationCaracteristicValueEntityState,
          organizationCaracteristicColumns,
          organizationCaracteristicRows,
          organizationDataColumnValues
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationCaracteristicValue: OrganizationCaracteristicValueEntityState | null;
} {
  const {
    organizationCaracteristicValues,
    organizationCaracteristicColumns,
    organizationCaracteristicRows,
    organizationDataColumnValues
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationCaracteristicValue = Object.values(organizationCaracteristicValues)[0];
  return {
    organizationCaracteristicValue: hydrate(
      organizationCaracteristicValue as OrganizationCaracteristicValueEntityState,
      organizationCaracteristicColumns,
      organizationCaracteristicRows,
      organizationDataColumnValues
    )
  };
}

function hydrate(
  organizationCaracteristicValue: OrganizationCaracteristicValueEntityState,
  organizationCaracteristicColumnEntities?: Dictionary<OrganizationCaracteristicColumnEntityState>,
  organizationCaracteristicRowEntities?: Dictionary<OrganizationCaracteristicRowEntityState>,
  organizationDataColumnValueEntities?: Dictionary<OrganizationDataColumnValueEntityState>
): OrganizationCaracteristicValue | null {
  if (!organizationCaracteristicValue) {
    return null;
  }

  const organizationCaracteristicValueHydrated: OrganizationCaracteristicValueEntityState = {
    ...organizationCaracteristicValue
  };
  if (organizationCaracteristicColumnEntities) {
    organizationCaracteristicValueHydrated.organizationCaracteristicColumn = organizationCaracteristicColumnEntities[
      organizationCaracteristicValue.organizationCaracteristicColumn as number
    ] as OrganizationCaracteristicColumn;
  } else {
    delete organizationCaracteristicValueHydrated.organizationCaracteristicColumn;
  }
  if (organizationCaracteristicRowEntities) {
    organizationCaracteristicValueHydrated.organizationCaracteristicRow = organizationCaracteristicRowEntities[
      organizationCaracteristicValue.organizationCaracteristicRow as number
    ] as OrganizationCaracteristicRow;
  } else {
    delete organizationCaracteristicValueHydrated.organizationCaracteristicRow;
  }
  if (organizationDataColumnValueEntities) {
    organizationCaracteristicValueHydrated.organizationDataColumnValue = organizationDataColumnValueEntities[
      organizationCaracteristicValue.organizationDataColumnValue as number
    ] as OrganizationDataColumnValue;
  } else {
    delete organizationCaracteristicValueHydrated.organizationDataColumnValue;
  }

  return organizationCaracteristicValueHydrated as OrganizationCaracteristicValue;
}
