import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { OrganizationAzure, OrganizationAzureEntityState } from '@api/api-interfaces';
import { OrganizationAzureApiService } from '@wip/store/api-services';
import { OrganizationAzureGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeOrganizationAzure } from '@wip/store/configs/normalization';
import { OrganizationAzureSelectors } from '@wip/store/selectors';
import { OrganizationAzureRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationGeneratedActions } from '@wip/store/actions';
import { GroupGeneratedActions } from '@wip/store/actions';

export function getDefaultAddOrganizationAzureActions(
  organizationAzure: OrganizationAzureEntityState,
  ids?: OrganizationAzureRelationsIds
): Action[] {
  const actions: Action[] = [
    OrganizationAzureGeneratedActions.normalizeManyOrganizationAzuresAfterUpsert({
      organizationAzures: [organizationAzure]
    })
  ];

  if (ids?.organization) {
    actions.push(
      OrganizationGeneratedActions.addManyOrganizationAzureSuccess({
        idOrganization: ids.organization,
        idOrganizationAzures: [organizationAzure.idOrganizationAzure]
      })
    );
    actions.push(
      OrganizationAzureGeneratedActions.addOrganizationSuccess({
        idOrganizationAzure: organizationAzure.idOrganizationAzure,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.group) {
    actions.push(
      GroupGeneratedActions.addManyOrganizationAzureSuccess({
        idGroup: ids.group,
        idOrganizationAzures: [organizationAzure.idOrganizationAzure]
      })
    );
    actions.push(
      OrganizationAzureGeneratedActions.addGroupSuccess({
        idOrganizationAzure: organizationAzure.idOrganizationAzure,
        idGroup: ids.group
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationAzureActions(organizationAzure: OrganizationAzureEntityState): Action[] {
  const actions: Action[] = [
    OrganizationAzureGeneratedActions.deleteOneOrganizationAzureSuccess({
      idOrganizationAzure: organizationAzure.idOrganizationAzure
    })
  ];

  if (organizationAzure.organization) {
    actions.push(
      OrganizationGeneratedActions.deleteManyOrganizationAzureSuccess({
        idOrganizationAzures: [organizationAzure.idOrganizationAzure],
        idOrganizations: [organizationAzure.organization as number]
      })
    );
  }

  if (organizationAzure.group) {
    actions.push(
      GroupGeneratedActions.deleteManyOrganizationAzureSuccess({
        idOrganizationAzures: [organizationAzure.idOrganizationAzure],
        idGroups: [organizationAzure.group as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationAzureEffects {
  constructor(
    protected actions$: Actions,
    protected organizationAzureApiService: OrganizationAzureApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationAzures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationAzureGeneratedActions.getManyOrganizationAzures),
      switchMap(({ params }) =>
        this.organizationAzureApiService.getOrganizationAzures(params).pipe(
          map((organizationAzures: OrganizationAzure[]) => {
            return OrganizationAzureGeneratedActions.normalizeManyOrganizationAzuresAfterUpsert({ organizationAzures });
          }),
          catchError(error => of(OrganizationAzureGeneratedActions.organizationAzuresFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationAzure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationAzureGeneratedActions.getOneOrganizationAzure),
      switchMap(idOrganizationAzure =>
        this.organizationAzureApiService.getOrganizationAzure(idOrganizationAzure).pipe(
          map((organizationAzure: OrganizationAzure) => {
            return OrganizationAzureGeneratedActions.normalizeManyOrganizationAzuresAfterUpsert({
              organizationAzures: [organizationAzure]
            });
          }),
          catchError(error => of(OrganizationAzureGeneratedActions.organizationAzuresFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationAzure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationAzureGeneratedActions.upsertOneOrganizationAzure),
      concatMap(
        ({
          organizationAzure,
          ids
        }: {
          organizationAzure: Partial<OrganizationAzure>;
          ids?: OrganizationAzureRelationsIds;
        }) => {
          if (organizationAzure.idOrganizationAzure) {
            return this.organizationAzureApiService.updateOrganizationAzure(organizationAzure).pipe(
              map((organizationAzureReturned: OrganizationAzure) => {
                return OrganizationAzureGeneratedActions.normalizeManyOrganizationAzuresAfterUpsert({
                  organizationAzures: [organizationAzureReturned]
                });
              }),
              catchError(error => of(OrganizationAzureGeneratedActions.organizationAzuresFailure({ error })))
            );
          } else {
            return this.organizationAzureApiService.addOrganizationAzure(organizationAzure).pipe(
              mergeMap((organizationAzureReturned: OrganizationAzure) =>
                getDefaultAddOrganizationAzureActions(organizationAzureReturned, ids)
              ),
              catchError(error => of(OrganizationAzureGeneratedActions.organizationAzuresFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationAzure$ = createEffect(() => {
    const selectOrganizationAzureState$ = this.store$.select(OrganizationAzureSelectors.selectOrganizationAzureState);
    return this.actions$.pipe(
      ofType(OrganizationAzureGeneratedActions.deleteOneOrganizationAzure),
      withLatestFrom(selectOrganizationAzureState$),
      concatMap(([{ idOrganizationAzure }, state]) =>
        this.organizationAzureApiService.deleteOrganizationAzure(idOrganizationAzure).pipe(
          mergeMap(_success =>
            getDefaultDeleteOrganizationAzureActions(
              state.entities[idOrganizationAzure] as OrganizationAzureEntityState
            )
          ),
          catchError(error => of(OrganizationAzureGeneratedActions.organizationAzuresFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationAzuresAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationAzureGeneratedActions.normalizeManyOrganizationAzuresAfterUpsert),
      concatMap(({ organizationAzures }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationAzure(organizationAzures, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganizationAzure] Normalization After Upsert Success')];
      })
    );
  });
}
