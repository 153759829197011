import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UserBoardState } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { UserBoardStateRelationsIds } from '@wip/store/ids-interfaces';
import { UserBoardStateGeneratedActions } from '@wip/store/actions';
import { UserBoardStateSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedUserBoardStateService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(UserBoardStateSelectors.selectIsLoadedUserBoardState));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(UserBoardStateSelectors.selectIsLoadingUserBoardState));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      UserBoardStateSelectors.selectIsReadyAndLoadedUserBoardState as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllUserBoardStates(schema: SelectSchema = {}): Observable<UserBoardState[]> {
    return this.store$.pipe(select(UserBoardStateSelectors.selectAllUserBoardStates(schema))).pipe(
      switchMap(({ userBoardStates }: { userBoardStates: UserBoardState[] }) => {
        return this.getReady(schema).pipe(mapTo(userBoardStates));
      })
    );
  }

  public selectOneUserBoardState(idUserBoardState: number, schema: SelectSchema = {}): Observable<UserBoardState> {
    return this.store$.pipe(select(UserBoardStateSelectors.selectOneUserBoardState(schema, idUserBoardState))).pipe(
      switchMap(({ userBoardState }: { userBoardState: UserBoardState }) => {
        return this.getReady(schema).pipe(mapTo(userBoardState));
      })
    );
  }

  public selectAllActiveUserBoardStates(schema: SelectSchema = {}): Observable<UserBoardState[]> {
    return this.store$.pipe(select(UserBoardStateSelectors.selectActiveUserBoardStates(schema))).pipe(
      switchMap(({ userBoardStates }: { userBoardStates: UserBoardState[] }) => {
        return this.getReady(schema).pipe(mapTo(userBoardStates));
      })
    );
  }

  public selectIdUserBoardStatesActive(): Observable<number[]> {
    return this.store$.pipe(select(UserBoardStateSelectors.selectIdUserBoardStatesActive)).pipe(
      switchMap((idUserBoardStates: number[]) => {
        return this.getReady().pipe(mapTo(idUserBoardStates));
      })
    );
  }

  public getOneUserBoardState(
    idUserBoardState: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<UserBoardState> {
    this.store$.dispatch(UserBoardStateGeneratedActions.getOneUserBoardState({ idUserBoardState, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserBoardStateGeneratedActions.normalizeManyUserBoardStatesAfterUpsert,
        UserBoardStateGeneratedActions.userBoardStatesFailure,
        true
      );
    }
  }

  public getManyUserBoardStates(params: any = {}, getResult?: boolean): void | Observable<UserBoardState[]> {
    this.store$.dispatch(UserBoardStateGeneratedActions.getManyUserBoardStates({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserBoardStateGeneratedActions.normalizeManyUserBoardStatesAfterUpsert,
        UserBoardStateGeneratedActions.userBoardStatesFailure
      );
    }
  }

  public upsertOneUserBoardState(
    userBoardState: Partial<UserBoardState>,
    ids: UserBoardStateRelationsIds = {},
    getResult?: boolean
  ): void | Observable<UserBoardState> {
    this.store$.dispatch(UserBoardStateGeneratedActions.upsertOneUserBoardState({ userBoardState, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserBoardStateGeneratedActions.normalizeManyUserBoardStatesAfterUpsert,
        UserBoardStateGeneratedActions.userBoardStatesFailure,
        true
      );
    }
  }

  public deleteOneUserBoardState(idUserBoardState: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(UserBoardStateGeneratedActions.deleteOneUserBoardState({ idUserBoardState }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserBoardStateGeneratedActions.deleteOneUserBoardStateSuccess,
        UserBoardStateGeneratedActions.userBoardStatesFailure
      );
    }
  }

  public setActiveUserBoardStates(idUserBoardStates: number[]): void {
    this.store$.dispatch(UserBoardStateGeneratedActions.clearActivesUserBoardStates());
    this.store$.dispatch(UserBoardStateGeneratedActions.addManyActivesUserBoardStates({ idUserBoardStates }));
  }
}
