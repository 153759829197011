import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationCaracteristicApiService } from '@wip/store/api-services';
import { GeneratedOrganizationCaracteristicEffects } from './organization-caracteristic-generated.effects';

@Injectable()
export class OrganizationCaracteristicEffects extends GeneratedOrganizationCaracteristicEffects {
  constructor(
    actions$: Actions,
    organizationCaracteristicApiService: OrganizationCaracteristicApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationCaracteristicApiService, store$);
  }
}
