/* eslint-disable @nx/enforce-module-boundaries */
import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Community, User } from '@api/api-interfaces';
import { CommunityService } from '@wip/store/services';
import { UserService } from '@wip/store/services';
import { MomentLocaleService } from '@_app/core/locals/locale.service';
import { IconRegistrationService } from '@_app/shared/shared-material/icon-registration.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DeviceInformationsService } from './core/device/device-information.service';

const DEFAULT_TITLE = 'Wip by Cellance';
@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  template: ' <router-outlet></router-outlet> ',
  providers: [Title]
})
export class AppComponent implements OnInit {
  private currentCommunity$: Observable<Community>;
  private isIOS = false;

  constructor(
    private translate: TranslateService,
    public momentLocaleService: MomentLocaleService,
    public iconRegistrationService: IconRegistrationService,
    private titleService: Title,
    private router: Router,
    private deviceInformations: DeviceInformationsService,
    private renderer: Renderer2,
    private appRef: ApplicationRef,
    private newCommunityService: CommunityService,
    private newUserService: UserService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('fr');

    // Set default language (used when not logged)
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    // Subscribe to the currentUser language changes
    this.newUserService
      .selectAllActiveUsers()
      .pipe(
        untilDestroyed(this),
        map(users => users[0])
      )
      .subscribe((user: User) => {
        if (user && user.defaultLanguage) {
          const userLang = user.defaultLanguage.split('_')[0];
          this.translate.use(userLang);
        }
      });

    this.currentCommunity$ = this.newCommunityService.selectAllActiveCommunities().pipe(
      untilDestroyed(this),
      map(communities => communities[0])
    );

    this.currentCommunity$.subscribe((community: Community) => {
      this.setTitle(community ? community.nom : DEFAULT_TITLE);
    });
  }

  ngOnInit(): void {
    this.isIOS = this.deviceInformations.isIOS();
    if (this.isIOS) {
      this.renderer.setStyle(this.document.body, 'background-color', 'black');
    }
    this.appRef.isStable
      .pipe(
        untilDestroyed(this),
        first((isStable: boolean): boolean => isStable === true)
      )
      .subscribe((isStable: boolean): void => {
        if (isStable) {
          SplashScreen.hide();
        }
      });
  }

  private setTitle(communityName: string) {
    this.titleService.setTitle(communityName);
  }
}
