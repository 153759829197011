import { Injectable } from '@angular/core';
import { RepositoryService } from '@wip/services/repository';
import { GeneratedElementLibraryApiService } from './element-library-api-generated.service';
import { Observable } from 'rxjs';
import { ElementLibrary } from '@api/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class ElementLibraryApiService extends GeneratedElementLibraryApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public changeResponsibles(data: { responsibles: any[]; idElement: number }): Observable<ElementLibrary[]> {
    return this.repo.update('element-library/changeResponsibles', data);
  }
}
