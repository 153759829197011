import { RepositoryService } from '@wip/services/repository';
import { TodoElement } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedTodoElementApiService {
  constructor(protected repo: RepositoryService) {}

  public getTodoElements(params?: any): Observable<TodoElement[]> {
    return this.repo.getData<TodoElement[]>('todo-element', params);
  }

  public getTodoElement(params: { idTodoElement: number; params?: any }): Observable<TodoElement> {
    return this.repo.getData<TodoElement>('todo-element/' + params.idTodoElement, params.params);
  }

  public addTodoElement(todoElement: Partial<TodoElement>): Observable<TodoElement> {
    return this.repo.create<TodoElement>('todo-element', todoElement);
  }

  public updateTodoElement(todoElement: Partial<TodoElement>): Observable<TodoElement> {
    return this.repo.update('todo-element', todoElement);
  }

  public deleteTodoElement(idTodoElement: number): Observable<number> {
    return this.repo.delete('todo-element/' + +idTodoElement);
  }
}
