import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationElement, OrganizationElementEntityState } from '@api/api-interfaces';
import { OrganizationElementRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationElementGeneratedActions = createActionGroup({
  source: 'Organization Element Generated',
  events: {
    'Get One Organization Element': props<{ idOrganizationElement: number; params?: any }>(),
    'Get Many Organization Elements': props<{ params: any }>(),
    'Add Many Actives Organization Elements': props<{ idOrganizationElements: number[] }>(),
    'Delete One Active Organization Element': props<{ idOrganizationElement: number }>(),
    'Clear Actives Organization Elements': emptyProps(),
    'Upsert One Organization Element': props<{
      organizationElement: Partial<OrganizationElement>;
      ids?: OrganizationElementRelationsIds;
    }>(),
    'Upsert Many Organization Elements': props<{
      organizationElements: Partial<OrganizationElement>[];
      ids?: OrganizationElementRelationsIds;
    }>(),
    'Upsert Many Organization Elements Success': props<{ organizationElements: OrganizationElementEntityState[] }>(),
    'Delete One Organization Element': props<{ idOrganizationElement: number }>(),
    'Delete One Organization Element Success': props<{ idOrganizationElement: number }>(),
    'Normalize Many Organization Elements After Upsert': props<{
      organizationElements: OrganizationElementEntityState[];
    }>(),
    'Organization Elements Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Elements': emptyProps(),
    'Add Organization Success': props<{ idOrganizationElement: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationElements: number[] }>(),
    'Add Element Success': props<{ idOrganizationElement: number; idElement: number }>(),
    'Delete Many Element Success': props<{ idElements: number[]; idOrganizationElements: number[] }>()
  }
});
