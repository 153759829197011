export enum DroitInterneEnum {
  planningAccess = 'planning_page_access',
  documentsAccess = 'documents_page_access',
  teamAccess = 'team_page_access',
  caracteristicsAccess = 'caracteristics_page_access',
  meetingsAccess = 'meetings_page_access',
  notesAccess = 'notes_page_access',
  timelineAccess = 'timeline_page_access',
  todosAccess = 'todos_page_access',
  adminAccess = 'admin_page_access',

  dateInitialUpdate = 'dateInitial_U',
  dateObjectifUpdate = 'dateObjectif_U',
  elementUpdate = 'element_U',
  elementCreateDelete = 'element_CD',
  verificationUpdate = 'verification_U',
  documentsUpdate = 'folder_U',
  teamCreateUpdateDelete = 'team_CDU',
  caracteristicsCreateUpdateDelete = 'caracteristics_CDU',
  dataCreateUpdateDelete = 'data_CDU',
  meetingsCreateDeleteUpdate = 'meeting_CDU',
  notesUpdate = 'notes_U',
  timelineCreateDeleteUpdate = 'timeline_CDU',
  todosCreateDeleteUpdate = 'todo_CDU'
}
