import { Injectable } from '@angular/core';import { Store } from '@ngrx/store';
import { AppState } from '@wip/store/configs/reducers';
import { GeneratedOrganizationCaracteristicRowService } from './organization-caracteristic-row-generated.service';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class OrganizationCaracteristicRowService extends GeneratedOrganizationCaracteristicRowService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}

