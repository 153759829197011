import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RightApiService } from '@wip/store/api-services';
import { GeneratedRightEffects } from './right-generated.effects';

@Injectable()
export class RightEffects extends GeneratedRightEffects {
  constructor(actions$: Actions, rightApiService: RightApiService, store$: Store<AppState>) {
    super(actions$, rightApiService, store$);
  }
}
