import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Community } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { CommunityRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { CommunitySelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCommunityService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CommunitySelectors.selectIsLoadedCommunity));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CommunitySelectors.selectIsLoadingCommunity));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [CommunitySelectors.selectIsReadyAndLoadedCommunity as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCommunities(schema: SelectSchema = {}): Observable<Community[]> {
    return this.store$.pipe(select(CommunitySelectors.selectAllCommunities(schema))).pipe(
      switchMap(({ communities }: { communities: Community[] }) => {
        return this.getReady(schema).pipe(mapTo(communities));
      })
    );
  }

  public selectOneCommunity(idCommunity: number, schema: SelectSchema = {}): Observable<Community> {
    return this.store$.pipe(select(CommunitySelectors.selectOneCommunity(schema, idCommunity))).pipe(
      switchMap(({ community }: { community: Community }) => {
        return this.getReady(schema).pipe(mapTo(community));
      })
    );
  }

  public selectAllActiveCommunities(schema: SelectSchema = {}): Observable<Community[]> {
    return this.store$.pipe(select(CommunitySelectors.selectActiveCommunities(schema))).pipe(
      switchMap(({ communities }: { communities: Community[] }) => {
        return this.getReady(schema).pipe(mapTo(communities));
      })
    );
  }

  public selectIdCommunitiesActive(): Observable<number[]> {
    return this.store$.pipe(select(CommunitySelectors.selectIdCommunitiesActive)).pipe(
      switchMap((idCommunities: number[]) => {
        return this.getReady().pipe(mapTo(idCommunities));
      })
    );
  }

  public getOneCommunity(idCommunity: number, params: any = {}, getResult?: boolean): void | Observable<Community> {
    this.store$.dispatch(CommunityGeneratedActions.getOneCommunity({ idCommunity, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityGeneratedActions.communitiesFailure,
        true
      );
    }
  }

  public getManyCommunities(params: any = {}, getResult?: boolean): void | Observable<Community[]> {
    this.store$.dispatch(CommunityGeneratedActions.getManyCommunities({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityGeneratedActions.communitiesFailure
      );
    }
  }

  public upsertOneCommunity(
    community: Partial<Community>,
    ids: CommunityRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Community> {
    this.store$.dispatch(CommunityGeneratedActions.upsertOneCommunity({ community, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityGeneratedActions.normalizeManyCommunitiesAfterUpsert,
        CommunityGeneratedActions.communitiesFailure,
        true
      );
    }
  }

  public deleteOneCommunity(idCommunity: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CommunityGeneratedActions.deleteOneCommunity({ idCommunity }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityGeneratedActions.deleteOneCommunitySuccess,
        CommunityGeneratedActions.communitiesFailure
      );
    }
  }

  public setActiveCommunities(idCommunities: number[]): void {
    this.store$.dispatch(CommunityGeneratedActions.clearActivesCommunities());
    this.store$.dispatch(CommunityGeneratedActions.addManyActivesCommunities({ idCommunities }));
  }
}
