import { createEntityAdapter } from '@ngrx/entity';
import { ProjectElementEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<ProjectElementEntityState>;

export const adapter = createEntityAdapter<ProjectElementEntityState>({
  selectId: o => o.idProjectElement
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const projectElementFeatureKey = 'projectElement';
