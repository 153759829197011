import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { UserRight, UserRightEntityState } from '@api/api-interfaces';
import { UserRightApiService } from '@wip/store/api-services';
import { UserRightGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeUserRight } from '@wip/store/configs/normalization';
import { UserRightSelectors } from '@wip/store/selectors';
import { UserRightRelationsIds } from '@wip/store/ids-interfaces';
import { ProfilGeneratedActions } from '@wip/store/actions';
import { OrganizationSubFamilyGeneratedActions } from '@wip/store/actions';
import { OrganizationUserGeneratedActions } from '@wip/store/actions';

export function getDefaultAddUserRightActions(userRight: UserRightEntityState, ids?: UserRightRelationsIds): Action[] {
  const actions: Action[] = [UserRightGeneratedActions.normalizeManyUserRightsAfterUpsert({ userRights: [userRight] })];

  if (ids?.profil) {
    actions.push(
      ProfilGeneratedActions.addManyUserRightSuccess({
        idProfil: ids.profil,
        idUserRights: [userRight.idUserRight]
      })
    );
    actions.push(
      UserRightGeneratedActions.addProfilSuccess({
        idUserRight: userRight.idUserRight,
        idProfil: ids.profil
      })
    );
  }

  if (ids?.organizationSubFamily) {
    actions.push(
      OrganizationSubFamilyGeneratedActions.addManyUserRightSuccess({
        idOrganizationSubFamily: ids.organizationSubFamily,
        idUserRights: [userRight.idUserRight]
      })
    );
    actions.push(
      UserRightGeneratedActions.addOrganizationSubFamilySuccess({
        idUserRight: userRight.idUserRight,
        idOrganizationSubFamily: ids.organizationSubFamily
      })
    );
  }

  if (ids?.organizationUser) {
    actions.push(
      OrganizationUserGeneratedActions.addManyUserRightSuccess({
        idOrganizationUser: ids.organizationUser,
        idUserRights: [userRight.idUserRight]
      })
    );
    actions.push(
      UserRightGeneratedActions.addOrganizationUserSuccess({
        idUserRight: userRight.idUserRight,
        idOrganizationUser: ids.organizationUser
      })
    );
  }

  return actions;
}

export function getDefaultDeleteUserRightActions(userRight: UserRightEntityState): Action[] {
  const actions: Action[] = [
    UserRightGeneratedActions.deleteOneUserRightSuccess({ idUserRight: userRight.idUserRight })
  ];

  if (userRight.profil) {
    actions.push(
      ProfilGeneratedActions.deleteManyUserRightSuccess({
        idUserRights: [userRight.idUserRight],
        idProfils: [userRight.profil as number]
      })
    );
  }

  if (userRight.organizationSubFamily) {
    actions.push(
      OrganizationSubFamilyGeneratedActions.deleteManyUserRightSuccess({
        idUserRights: [userRight.idUserRight],
        idOrganizationSubFamilys: [userRight.organizationSubFamily as number]
      })
    );
  }

  if (userRight.organizationUser) {
    actions.push(
      OrganizationUserGeneratedActions.deleteManyUserRightSuccess({
        idUserRights: [userRight.idUserRight],
        idOrganizationUsers: [userRight.organizationUser as number]
      })
    );
  }

  return actions;
}

export class GeneratedUserRightEffects {
  constructor(
    protected actions$: Actions,
    protected userRightApiService: UserRightApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUserRights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserRightGeneratedActions.getManyUserRights),
      switchMap(({ params }) =>
        this.userRightApiService.getUserRights(params).pipe(
          map((userRights: UserRight[]) => {
            return UserRightGeneratedActions.normalizeManyUserRightsAfterUpsert({ userRights });
          }),
          catchError(error => of(UserRightGeneratedActions.userRightsFailure({ error })))
        )
      )
    );
  });

  getOneUserRight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserRightGeneratedActions.getOneUserRight),
      switchMap(idUserRight =>
        this.userRightApiService.getUserRight(idUserRight).pipe(
          map((userRight: UserRight) => {
            return UserRightGeneratedActions.normalizeManyUserRightsAfterUpsert({ userRights: [userRight] });
          }),
          catchError(error => of(UserRightGeneratedActions.userRightsFailure({ error })))
        )
      )
    );
  });

  upsertOneUserRight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserRightGeneratedActions.upsertOneUserRight),
      concatMap(({ userRight, ids }: { userRight: Partial<UserRight>; ids?: UserRightRelationsIds }) => {
        if (userRight.idUserRight) {
          return this.userRightApiService.updateUserRight(userRight).pipe(
            map((userRightReturned: UserRight) => {
              return UserRightGeneratedActions.normalizeManyUserRightsAfterUpsert({ userRights: [userRightReturned] });
            }),
            catchError(error => of(UserRightGeneratedActions.userRightsFailure({ error })))
          );
        } else {
          return this.userRightApiService.addUserRight(userRight).pipe(
            mergeMap((userRightReturned: UserRight) => getDefaultAddUserRightActions(userRightReturned, ids)),
            catchError(error => of(UserRightGeneratedActions.userRightsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneUserRight$ = createEffect(() => {
    const selectUserRightState$ = this.store$.select(UserRightSelectors.selectUserRightState);
    return this.actions$.pipe(
      ofType(UserRightGeneratedActions.deleteOneUserRight),
      withLatestFrom(selectUserRightState$),
      concatMap(([{ idUserRight }, state]) =>
        this.userRightApiService.deleteUserRight(idUserRight).pipe(
          mergeMap(_success => getDefaultDeleteUserRightActions(state.entities[idUserRight] as UserRightEntityState)),
          catchError(error => of(UserRightGeneratedActions.userRightsFailure({ error })))
        )
      )
    );
  });

  normalizeManyUserRightsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserRightGeneratedActions.normalizeManyUserRightsAfterUpsert),
      concatMap(({ userRights }) => {
        const actions: Action[] = getActionsToNormalizeUserRight(userRights, StoreActionType.upsert);
        return [getMultiAction(actions, '[UserRight] Normalization After Upsert Success')];
      })
    );
  });
}
