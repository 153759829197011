import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationUserProfilGeneratedActions } from '@wip/store/actions';
import { OrganizationUserProfilState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationUserProfilEntityState } from '@api/api-interfaces';

export const organizationUserProfilReducersGeneratedFunctions: ReducerTypes<
  OrganizationUserProfilState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationUserProfilGeneratedActions.getOneOrganizationUserProfil, (state: OrganizationUserProfilState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationUserProfilGeneratedActions.getManyOrganizationUserProfils,
    (state: OrganizationUserProfilState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationUserProfilGeneratedActions.upsertOneOrganizationUserProfil,
    (state: OrganizationUserProfilState.IState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationUserProfilGeneratedActions.upsertManyOrganizationUserProfilsSuccess,
    (state: OrganizationUserProfilState.IState, { organizationUserProfils }) =>
      OrganizationUserProfilState.adapter.upsertMany(organizationUserProfils, setLoadingsState(state, false))
  ),
  on(
    OrganizationUserProfilGeneratedActions.deleteOneOrganizationUserProfil,
    (state: OrganizationUserProfilState.IState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationUserProfilGeneratedActions.deleteOneOrganizationUserProfilSuccess,
    (state: OrganizationUserProfilState.IState, { idOrganizationUserProfil }) =>
      OrganizationUserProfilState.adapter.removeOne(idOrganizationUserProfil, setLoadingsState(state, false))
  ),
  on(
    OrganizationUserProfilGeneratedActions.clearActivesOrganizationUserProfils,
    (state: OrganizationUserProfilState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationUserProfilGeneratedActions.addManyActivesOrganizationUserProfils,
    (state: OrganizationUserProfilState.IState, { idOrganizationUserProfils }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationUserProfils)
    })
  ),
  on(
    OrganizationUserProfilGeneratedActions.deleteOneActiveOrganizationUserProfil,
    (state: OrganizationUserProfilState.IState, { idOrganizationUserProfil }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationUserProfil)
    })
  ),

  on(
    OrganizationUserProfilGeneratedActions.clearOrganizationUserProfils,
    () => OrganizationUserProfilState.initialState
  ),

  on(
    OrganizationUserProfilGeneratedActions.addManyCommunityUserProfilSuccess,
    (state: OrganizationUserProfilState.IState, { idOrganizationUserProfil, idCommunityUserProfils }) => {
      if (!state.entities[idOrganizationUserProfil]) {
        return state;
      }
      const communityUserProfils = (state.entities[idOrganizationUserProfil]?.communityUserProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUserProfil]: {
            ...state.entities[idOrganizationUserProfil],
            communityUserProfils: communityUserProfils.concat(
              idCommunityUserProfils.filter(id => communityUserProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationUserProfilGeneratedActions.deleteManyCommunityUserProfilSuccess,
    (state: OrganizationUserProfilState.IState, { idCommunityUserProfils, idOrganizationUserProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUserProfils.reduce((entities, idOrganizationUserProfil) => {
            if (!state.entities[idOrganizationUserProfil]?.communityUserProfils) {
              return entities;
            }
            entities[idOrganizationUserProfil] = {
              ...state.entities[idOrganizationUserProfil],
              communityUserProfils: (
                state.entities[idOrganizationUserProfil]?.communityUserProfils as number[]
              )?.filter(
                (idCommunityUserProfil: number) =>
                  !idCommunityUserProfils.some((id: number) => id === idCommunityUserProfil)
              )
            } as OrganizationUserProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserProfilEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationUserProfilGeneratedActions.addManyUserProfilRightSuccess,
    (state: OrganizationUserProfilState.IState, { idOrganizationUserProfil, idUserProfilRights }) => {
      if (!state.entities[idOrganizationUserProfil]) {
        return state;
      }
      const userProfilRights = (state.entities[idOrganizationUserProfil]?.userProfilRights as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUserProfil]: {
            ...state.entities[idOrganizationUserProfil],
            userProfilRights: userProfilRights.concat(idUserProfilRights.filter(id => userProfilRights.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationUserProfilGeneratedActions.deleteManyUserProfilRightSuccess,
    (state: OrganizationUserProfilState.IState, { idUserProfilRights, idOrganizationUserProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUserProfils.reduce((entities, idOrganizationUserProfil) => {
            if (!state.entities[idOrganizationUserProfil]?.userProfilRights) {
              return entities;
            }
            entities[idOrganizationUserProfil] = {
              ...state.entities[idOrganizationUserProfil],
              userProfilRights: (state.entities[idOrganizationUserProfil]?.userProfilRights as number[])?.filter(
                (idUserProfilRight: number) => !idUserProfilRights.some((id: number) => id === idUserProfilRight)
              )
            } as OrganizationUserProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserProfilEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationUserProfilGeneratedActions.addManyProfilSuccess,
    (state: OrganizationUserProfilState.IState, { idOrganizationUserProfil, idProfils }) => {
      if (!state.entities[idOrganizationUserProfil]) {
        return state;
      }
      const profils = (state.entities[idOrganizationUserProfil]?.profils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUserProfil]: {
            ...state.entities[idOrganizationUserProfil],
            profils: profils.concat(idProfils.filter(id => profils.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationUserProfilGeneratedActions.deleteManyProfilSuccess,
    (state: OrganizationUserProfilState.IState, { idProfils, idOrganizationUserProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUserProfils.reduce((entities, idOrganizationUserProfil) => {
            if (!state.entities[idOrganizationUserProfil]?.profils) {
              return entities;
            }
            entities[idOrganizationUserProfil] = {
              ...state.entities[idOrganizationUserProfil],
              profils: (state.entities[idOrganizationUserProfil]?.profils as number[])?.filter(
                (idProfil: number) => !idProfils.some((id: number) => id === idProfil)
              )
            } as OrganizationUserProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserProfilEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationUserProfilGeneratedActions.addOrganizationSuccess,
    (state: OrganizationUserProfilState.IState, { idOrganizationUserProfil, idOrganization }) => {
      if (!state.entities[idOrganizationUserProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUserProfil]: {
            ...state.entities[idOrganizationUserProfil],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationUserProfilGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationUserProfilState.IState, { idOrganizations, idOrganizationUserProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUserProfils.reduce((entities, idOrganizationUserProfil) => {
            if (!state.entities[idOrganizationUserProfil]?.organization) {
              return entities;
            }
            entities[idOrganizationUserProfil] = {
              ...state.entities[idOrganizationUserProfil],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationUserProfil]?.organization
              )
                ? undefined
                : state.entities[idOrganizationUserProfil]?.organization
            } as OrganizationUserProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserProfilEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationUserProfilGeneratedActions.addOrganizationFamilySuccess,
    (state: OrganizationUserProfilState.IState, { idOrganizationUserProfil, idOrganizationFamily }) => {
      if (!state.entities[idOrganizationUserProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationUserProfil]: {
            ...state.entities[idOrganizationUserProfil],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationUserProfilGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationUserProfilState.IState, { idOrganizationFamilys, idOrganizationUserProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationUserProfils.reduce((entities, idOrganizationUserProfil) => {
            if (!state.entities[idOrganizationUserProfil]?.organizationFamily) {
              return entities;
            }
            entities[idOrganizationUserProfil] = {
              ...state.entities[idOrganizationUserProfil],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idOrganizationUserProfil]?.organizationFamily
              )
                ? undefined
                : state.entities[idOrganizationUserProfil]?.organizationFamily
            } as OrganizationUserProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationUserProfilEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationUserProfilState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationUserProfilState.IState {
  return { ...state, isLoaded, isLoading };
}
