@if (idOrganization && idOrganizationFamily) {
  <app-charts
    [idOrganizationSelected]="idOrganization"
    [idProjectFamily]="idOrganizationFamily"
    [idOrganizationFamily]="idOrganizationFamily"
    [idsProjectSubFamily]="idsProjectSubFamily"
    [projectMilestoneFamilies]="(orgaMilestoneFamilies$ | async) || []"
    [chartParams]="chartParams$ | async"
    class="graphic-card"
  />
}
