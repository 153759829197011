import { Injectable } from '@angular/core';
import { DeviceInformationsService } from '@_app/core/device/device-information.service';

@Injectable()
export class ElectronService {
  ipcRenderer;

  private electron: any;

  constructor(private deviceInformation: DeviceInformationsService) {
    if (this.deviceInformation.isElectron()) {
      this.electron = (window as any).require('electron');
      this.ipcRenderer = this.electron.ipcRenderer;
    }
  }

  openUrl(url: string) {
    this.electron.remote.getGlobal('shell').openExternal(url);
  }

  previewDocument(url: string) {
    this.ipcRenderer.send('download-file', {
      url: url,
      isPreview: true,
      openAfterDownload: true
    });
  }

  wakeUpElectron() {
    this.ipcRenderer.send('wake-up');
  }
}
