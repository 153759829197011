import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ElementLibraryGeneratedActions } from '@wip/store/actions';
import { ElementLibraryState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { ElementLibraryEntityState } from '@api/api-interfaces';

export const elementLibraryReducersGeneratedFunctions: ReducerTypes<
  ElementLibraryState.IState,
  readonly ActionCreator[]
>[] = [
  on(ElementLibraryGeneratedActions.getOneElementLibrary, (state: ElementLibraryState.IState) =>
    setLoadingsState(state, true)
  ),
  on(ElementLibraryGeneratedActions.getManyElementLibraries, (state: ElementLibraryState.IState) =>
    setLoadingsState(state, true)
  ),
  on(ElementLibraryGeneratedActions.upsertOneElementLibrary, (state: ElementLibraryState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    ElementLibraryGeneratedActions.upsertManyElementLibrariesSuccess,
    (state: ElementLibraryState.IState, { elementLibraries }) =>
      ElementLibraryState.adapter.upsertMany(elementLibraries, setLoadingsState(state, false))
  ),
  on(ElementLibraryGeneratedActions.deleteOneElementLibrary, (state: ElementLibraryState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    ElementLibraryGeneratedActions.deleteOneElementLibrarySuccess,
    (state: ElementLibraryState.IState, { idElementLibrary }) =>
      ElementLibraryState.adapter.removeOne(idElementLibrary, setLoadingsState(state, false))
  ),
  on(ElementLibraryGeneratedActions.clearActivesElementLibraries, (state: ElementLibraryState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    ElementLibraryGeneratedActions.addManyActivesElementLibraries,
    (state: ElementLibraryState.IState, { idElementLibraries }) => ({
      ...state,
      actives: state.actives.concat(idElementLibraries)
    })
  ),
  on(
    ElementLibraryGeneratedActions.deleteOneActiveElementLibrary,
    (state: ElementLibraryState.IState, { idElementLibrary }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idElementLibrary)
    })
  ),

  on(ElementLibraryGeneratedActions.clearElementLibraries, () => ElementLibraryState.initialState),

  on(
    ElementLibraryGeneratedActions.addElementSuccess,
    (state: ElementLibraryState.IState, { idElementLibrary, idElement }) => {
      if (!state.entities[idElementLibrary]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElementLibrary]: {
            ...state.entities[idElementLibrary],
            element: idElement
          }
        }
      };
    }
  ),

  on(
    ElementLibraryGeneratedActions.deleteManyElementSuccess,
    (state: ElementLibraryState.IState, { idElements, idElementLibraries }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElementLibraries.reduce((entities, idElementLibrary) => {
            if (!state.entities[idElementLibrary]?.element) {
              return entities;
            }
            entities[idElementLibrary] = {
              ...state.entities[idElementLibrary],
              element: idElements.some((idElement: number) => idElement === state.entities[idElementLibrary]?.element)
                ? undefined
                : state.entities[idElementLibrary]?.element
            } as ElementLibraryEntityState;
            return entities;
          }, {} as Dictionary<ElementLibraryEntityState>)
        }
      };
    }
  ),

  on(
    ElementLibraryGeneratedActions.addUserSuccess,
    (state: ElementLibraryState.IState, { idElementLibrary, idUser }) => {
      if (!state.entities[idElementLibrary]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElementLibrary]: {
            ...state.entities[idElementLibrary],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    ElementLibraryGeneratedActions.deleteManyUserSuccess,
    (state: ElementLibraryState.IState, { idUsers, idElementLibraries }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElementLibraries.reduce((entities, idElementLibrary) => {
            if (!state.entities[idElementLibrary]?.user) {
              return entities;
            }
            entities[idElementLibrary] = {
              ...state.entities[idElementLibrary],
              user: idUsers.some((idUser: number) => idUser === state.entities[idElementLibrary]?.user)
                ? undefined
                : state.entities[idElementLibrary]?.user
            } as ElementLibraryEntityState;
            return entities;
          }, {} as Dictionary<ElementLibraryEntityState>)
        }
      };
    }
  ),

  on(
    ElementLibraryGeneratedActions.addCommunityUserProfilSuccess,
    (state: ElementLibraryState.IState, { idElementLibrary, idCommunityUserProfil }) => {
      if (!state.entities[idElementLibrary]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idElementLibrary]: {
            ...state.entities[idElementLibrary],
            communityUserProfil: idCommunityUserProfil
          }
        }
      };
    }
  ),

  on(
    ElementLibraryGeneratedActions.deleteManyCommunityUserProfilSuccess,
    (state: ElementLibraryState.IState, { idCommunityUserProfils, idElementLibraries }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idElementLibraries.reduce((entities, idElementLibrary) => {
            if (!state.entities[idElementLibrary]?.communityUserProfil) {
              return entities;
            }
            entities[idElementLibrary] = {
              ...state.entities[idElementLibrary],
              communityUserProfil: idCommunityUserProfils.some(
                (idCommunityUserProfil: number) =>
                  idCommunityUserProfil === state.entities[idElementLibrary]?.communityUserProfil
              )
                ? undefined
                : state.entities[idElementLibrary]?.communityUserProfil
            } as ElementLibraryEntityState;
            return entities;
          }, {} as Dictionary<ElementLibraryEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ElementLibraryState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ElementLibraryState.IState {
  return { ...state, isLoaded, isLoading };
}
