import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { TodoElement, TodoElementEntityState } from '@api/api-interfaces';
import { TodoElementApiService } from '@wip/store/api-services';
import { TodoElementGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeTodoElement } from '@wip/store/configs/normalization';
import { TodoElementSelectors } from '@wip/store/selectors';
import { TodoElementRelationsIds } from '@wip/store/ids-interfaces';
import { TodoGeneratedActions } from '@wip/store/actions';
import { ElementGeneratedActions } from '@wip/store/actions';

export function getDefaultAddTodoElementActions(
  todoElement: TodoElementEntityState,
  ids?: TodoElementRelationsIds
): Action[] {
  const actions: Action[] = [
    TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert({ todoElements: [todoElement] })
  ];

  if (ids?.todo) {
    actions.push(
      TodoGeneratedActions.addManyTodoElementSuccess({
        idTodo: ids.todo,
        idTodoElements: [todoElement.idTodoElement]
      })
    );
    actions.push(
      TodoElementGeneratedActions.addTodoSuccess({
        idTodoElement: todoElement.idTodoElement,
        idTodo: ids.todo
      })
    );
  }

  if (ids?.element) {
    actions.push(
      ElementGeneratedActions.addManyTodoElementSuccess({
        idElement: ids.element,
        idTodoElements: [todoElement.idTodoElement]
      })
    );
    actions.push(
      TodoElementGeneratedActions.addElementSuccess({
        idTodoElement: todoElement.idTodoElement,
        idElement: ids.element
      })
    );
  }

  return actions;
}

export function getDefaultDeleteTodoElementActions(todoElement: TodoElementEntityState): Action[] {
  const actions: Action[] = [
    TodoElementGeneratedActions.deleteOneTodoElementSuccess({ idTodoElement: todoElement.idTodoElement })
  ];

  if (todoElement.todo) {
    actions.push(
      TodoGeneratedActions.deleteManyTodoElementSuccess({
        idTodoElements: [todoElement.idTodoElement],
        idTodos: [todoElement.todo as number]
      })
    );
  }

  if (todoElement.element) {
    actions.push(
      ElementGeneratedActions.deleteManyTodoElementSuccess({
        idTodoElements: [todoElement.idTodoElement],
        idElements: [todoElement.element as number]
      })
    );
  }

  return actions;
}

export class GeneratedTodoElementEffects {
  constructor(
    protected actions$: Actions,
    protected todoElementApiService: TodoElementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyTodoElements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TodoElementGeneratedActions.getManyTodoElements),
      switchMap(({ params }) =>
        this.todoElementApiService.getTodoElements(params).pipe(
          map((todoElements: TodoElement[]) => {
            return TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert({ todoElements });
          }),
          catchError(error => of(TodoElementGeneratedActions.todoElementsFailure({ error })))
        )
      )
    );
  });

  getOneTodoElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TodoElementGeneratedActions.getOneTodoElement),
      switchMap(idTodoElement =>
        this.todoElementApiService.getTodoElement(idTodoElement).pipe(
          map((todoElement: TodoElement) => {
            return TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert({ todoElements: [todoElement] });
          }),
          catchError(error => of(TodoElementGeneratedActions.todoElementsFailure({ error })))
        )
      )
    );
  });

  upsertOneTodoElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TodoElementGeneratedActions.upsertOneTodoElement),
      concatMap(({ todoElement, ids }: { todoElement: Partial<TodoElement>; ids?: TodoElementRelationsIds }) => {
        if (todoElement.idTodoElement) {
          return this.todoElementApiService.updateTodoElement(todoElement).pipe(
            map((todoElementReturned: TodoElement) => {
              return TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert({
                todoElements: [todoElementReturned]
              });
            }),
            catchError(error => of(TodoElementGeneratedActions.todoElementsFailure({ error })))
          );
        } else {
          return this.todoElementApiService.addTodoElement(todoElement).pipe(
            mergeMap((todoElementReturned: TodoElement) => getDefaultAddTodoElementActions(todoElementReturned, ids)),
            catchError(error => of(TodoElementGeneratedActions.todoElementsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneTodoElement$ = createEffect(() => {
    const selectTodoElementState$ = this.store$.select(TodoElementSelectors.selectTodoElementState);
    return this.actions$.pipe(
      ofType(TodoElementGeneratedActions.deleteOneTodoElement),
      withLatestFrom(selectTodoElementState$),
      concatMap(([{ idTodoElement }, state]) =>
        this.todoElementApiService.deleteTodoElement(idTodoElement).pipe(
          mergeMap(_success =>
            getDefaultDeleteTodoElementActions(state.entities[idTodoElement] as TodoElementEntityState)
          ),
          catchError(error => of(TodoElementGeneratedActions.todoElementsFailure({ error })))
        )
      )
    );
  });

  normalizeManyTodoElementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert),
      concatMap(({ todoElements }) => {
        const actions: Action[] = getActionsToNormalizeTodoElement(todoElements, StoreActionType.upsert);
        return [getMultiAction(actions, '[TodoElement] Normalization After Upsert Success')];
      })
    );
  });
}
