<div mat-dialog-title>
  <h1>Créer nouvelle configuration</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
</div>
<div mat-dialog-content>
  <mat-form-field style="margin: 8px 24px">
    <input
      matInput
      autocomplete="off"
      type="text"
      [formControl]="configControl"
      placeholder="Nom de la configuration"
    />
  </mat-form-field>
</div>

<div mat-dialog-actions class="flex flex-row gap-8">
  <button mat-flat-button (click)="close()">Annuler</button>
  <button mat-flat-button color="primary" (click)="save()">Sauvegarder</button>
</div>
