import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunityGroup, CommunityGroupEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { CommunityGroupState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const communityGroupRelations: string[] = ['communities', 'groups'];

export const { selectEntities, selectAll } = CommunityGroupState.adapter.getSelectors();

export const selectCommunityGroupState = createFeatureSelector<CommunityGroupState.IState>(
  CommunityGroupState.communityGroupFeatureKey
);

export const selectIsLoadedCommunityGroup = createSelector(
  selectCommunityGroupState,
  (state: CommunityGroupState.IState) => state.isLoaded
);

export const selectIsLoadingCommunityGroup = createSelector(
  selectCommunityGroupState,
  (state: CommunityGroupState.IState) => state.isLoading
);

export const selectIsReadyCommunityGroup = createSelector(
  selectCommunityGroupState,
  (state: CommunityGroupState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCommunityGroup = createSelector(
  selectCommunityGroupState,
  (state: CommunityGroupState.IState) => state.isLoaded && !state.isLoading
);

export const selectCommunityGroupsEntities = createSelector(selectCommunityGroupState, selectEntities);

export const selectCommunityGroupsArray = createSelector(selectCommunityGroupState, selectAll);

export const selectIdCommunityGroupsActive = createSelector(
  selectCommunityGroupState,
  (state: CommunityGroupState.IState) => state.actives
);

const communityGroupsInObject = (communityGroups: Dictionary<CommunityGroupEntityState>) => ({ communityGroups });

const selectCommunityGroupsEntitiesDictionary = createSelector(selectCommunityGroupsEntities, communityGroupsInObject);

const selectAllCommunityGroupsObject = createSelector(selectCommunityGroupsEntities, communityGroups => {
  return hydrateAll({ communityGroups });
});

const selectOneCommunityGroupDictionary = (idCommunityGroup: number) =>
  createSelector(selectCommunityGroupsEntities, communityGroups => ({
    communityGroups: { [idCommunityGroup]: communityGroups[idCommunityGroup] }
  }));

const selectOneCommunityGroupDictionaryWithoutChild = (idCommunityGroup: number) =>
  createSelector(selectCommunityGroupsEntities, communityGroups => ({
    communityGroup: communityGroups[idCommunityGroup]
  }));

const selectActiveCommunityGroupsEntities = createSelector(
  selectIdCommunityGroupsActive,
  selectCommunityGroupsEntities,
  (actives: number[], communityGroups: Dictionary<CommunityGroupEntityState>) =>
    getCommunityGroupsFromActives(actives, communityGroups)
);

function getCommunityGroupsFromActives(
  actives: number[],
  communityGroups: Dictionary<CommunityGroupEntityState>
): Dictionary<CommunityGroupEntityState> {
  return actives.reduce((acc, idActive) => {
    if (communityGroups[idActive]) {
      acc[idActive] = communityGroups[idActive];
    }
    return acc;
  }, {} as Dictionary<CommunityGroupEntityState>);
}

const selectAllCommunityGroupsSelectors: Dictionary<Selector> = {};
export function selectAllCommunityGroups(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CommunityGroup>(
      schema,
      selectAllCommunityGroupsSelectors,
      selectCommunityGroupsEntitiesDictionary,
      getRelationSelectors,
      communityGroupRelations,
      hydrateAll,
      'communityGroup'
    );
  } else {
    return selectAllCommunityGroupsObject;
  }
}

export function selectAllCommunityGroupsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'communityGroups'
): Selector {
  return createSelector(selectAllCommunityGroups(schema), result => {
    const res = { [customKey]: {} as Dictionary<CommunityGroupEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.communityGroups.length; i++) {
      res[customKey][result.communityGroups[i].idCommunityGroup] = result.communityGroups[i];
    }
    return res;
  });
}

export function selectOneCommunityGroup(schema: SelectSchema = {}, idCommunityGroup: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCommunityGroupDictionary(idCommunityGroup)];
    selectors.push(...getRelationSelectors(schema, communityGroupRelations, 'communityGroup'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCommunityGroupDictionaryWithoutChild(idCommunityGroup);
  }
}

export function selectActiveCommunityGroups(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCommunityGroupsEntities, communityGroups => ({
      communityGroups
    }))
  ];
  selectors.push(...getRelationSelectors(schema, communityGroupRelations, 'communityGroup'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  communityGroups: Dictionary<CommunityGroupEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  groups?: Dictionary<GroupEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { communityGroups: (CommunityGroup | null)[] } {
  const { communityGroups, communities, groups } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    communityGroups: Object.keys(communityGroups).map(idCommunityGroup =>
      hydrate(communityGroups[idCommunityGroup] as CommunityGroupEntityState, communities, groups)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { communityGroup: CommunityGroupEntityState | null } {
  const { communityGroups, communities, groups } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const communityGroup = Object.values(communityGroups)[0];
  return {
    communityGroup: hydrate(communityGroup as CommunityGroupEntityState, communities, groups)
  };
}

function hydrate(
  communityGroup: CommunityGroupEntityState,
  communityEntities?: Dictionary<CommunityEntityState>,
  groupEntities?: Dictionary<GroupEntityState>
): CommunityGroup | null {
  if (!communityGroup) {
    return null;
  }

  const communityGroupHydrated: CommunityGroupEntityState = { ...communityGroup };
  if (communityEntities) {
    communityGroupHydrated.community = communityEntities[communityGroup.community as number] as Community;
  } else {
    delete communityGroupHydrated.community;
  }
  if (groupEntities) {
    communityGroupHydrated.group = groupEntities[communityGroup.group as number] as Group;
  } else {
    delete communityGroupHydrated.group;
  }

  return communityGroupHydrated as CommunityGroup;
}
