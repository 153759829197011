import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganizationMilestone } from '@api/api-interfaces';
import { AppState } from '@wip/store/configs/reducers';
import { OrganizationMilestoneRelationsIds } from '@wip/store/ids-interfaces';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneSelectors } from '@wip/store/selectors';
import { catchApiActions } from '@wip/store/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@wip/store/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationMilestoneService {
  constructor(
    protected store$: Store<AppState>,
    protected actions$: Actions
  ) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationMilestoneSelectors.selectIsLoadedOrganizationMilestone));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(OrganizationMilestoneSelectors.selectIsLoadingOrganizationMilestone));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      OrganizationMilestoneSelectors.selectIsReadyAndLoadedOrganizationMilestone as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizationMilestones(schema: SelectSchema = {}): Observable<OrganizationMilestone[]> {
    return this.store$.pipe(select(OrganizationMilestoneSelectors.selectAllOrganizationMilestones(schema))).pipe(
      switchMap(({ organizationMilestones }: { organizationMilestones: OrganizationMilestone[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationMilestones));
      })
    );
  }

  public selectOneOrganizationMilestone(
    idOrganizationMilestone: number,
    schema: SelectSchema = {}
  ): Observable<OrganizationMilestone> {
    return this.store$
      .pipe(select(OrganizationMilestoneSelectors.selectOneOrganizationMilestone(schema, idOrganizationMilestone)))
      .pipe(
        switchMap(({ organizationMilestone }: { organizationMilestone: OrganizationMilestone }) => {
          return this.getReady(schema).pipe(mapTo(organizationMilestone));
        })
      );
  }

  public selectAllActiveOrganizationMilestones(schema: SelectSchema = {}): Observable<OrganizationMilestone[]> {
    return this.store$.pipe(select(OrganizationMilestoneSelectors.selectActiveOrganizationMilestones(schema))).pipe(
      switchMap(({ organizationMilestones }: { organizationMilestones: OrganizationMilestone[] }) => {
        return this.getReady(schema).pipe(mapTo(organizationMilestones));
      })
    );
  }

  public selectIdOrganizationMilestonesActive(): Observable<number[]> {
    return this.store$.pipe(select(OrganizationMilestoneSelectors.selectIdOrganizationMilestonesActive)).pipe(
      switchMap((idOrganizationMilestones: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizationMilestones));
      })
    );
  }

  public getOneOrganizationMilestone(
    idOrganizationMilestone: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestone> {
    this.store$.dispatch(
      OrganizationMilestoneGeneratedActions.getOneOrganizationMilestone({ idOrganizationMilestone, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneGeneratedActions.normalizeManyOrganizationMilestonesAfterUpsert,
        OrganizationMilestoneGeneratedActions.organizationMilestonesFailure,
        true
      );
    }
  }

  public getManyOrganizationMilestones(
    params: any = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestone[]> {
    this.store$.dispatch(OrganizationMilestoneGeneratedActions.getManyOrganizationMilestones({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneGeneratedActions.normalizeManyOrganizationMilestonesAfterUpsert,
        OrganizationMilestoneGeneratedActions.organizationMilestonesFailure
      );
    }
  }

  public upsertOneOrganizationMilestone(
    organizationMilestone: Partial<OrganizationMilestone>,
    ids: OrganizationMilestoneRelationsIds = {},
    getResult?: boolean
  ): void | Observable<OrganizationMilestone> {
    this.store$.dispatch(
      OrganizationMilestoneGeneratedActions.upsertOneOrganizationMilestone({ organizationMilestone, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneGeneratedActions.normalizeManyOrganizationMilestonesAfterUpsert,
        OrganizationMilestoneGeneratedActions.organizationMilestonesFailure,
        true
      );
    }
  }

  public deleteOneOrganizationMilestone(
    idOrganizationMilestone: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      OrganizationMilestoneGeneratedActions.deleteOneOrganizationMilestone({ idOrganizationMilestone })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationMilestoneGeneratedActions.deleteOneOrganizationMilestoneSuccess,
        OrganizationMilestoneGeneratedActions.organizationMilestonesFailure
      );
    }
  }

  public setActiveOrganizationMilestones(idOrganizationMilestones: number[]): void {
    this.store$.dispatch(OrganizationMilestoneGeneratedActions.clearActivesOrganizationMilestones());
    this.store$.dispatch(
      OrganizationMilestoneGeneratedActions.addManyActivesOrganizationMilestones({ idOrganizationMilestones })
    );
  }
}
