import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Community, Link, ProjectElement, Task } from '@api/api-interfaces';
import { ActionFormDTO } from '@dto';
import { RepositoryService } from '@wip/services/repository';
import { Observable } from 'rxjs';
import { ConfigurationApiService } from 'libs/api/old-api-services/src';
import { GeneratedProjectElementApiService } from './project-element-api-generated.service';
import { TodoService } from '@wip/store/services';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ProjectElementApiService extends GeneratedProjectElementApiService {
  constructor(
    repo: RepositoryService,
    private configurationApi: ConfigurationApiService,
    private http: HttpClient,
    private todoService: TodoService
  ) {
    super(repo);
  }

  public getProjectTasks(params?: any): Observable<Task[]> {
    return this.repo.getData<Task[]>('project-element/getTasks', params);
  }

  public getProjectLinks(params?: any): Observable<Link[]> {
    return this.repo.getData<Link[]>('project-element/getLinks', params);
  }

  public reorder(params?: any): Observable<ProjectElement[]> {
    return this.repo.update('project-element/reorder', params);
  }

  public save(state: any): Observable<ProjectElement[]> {
    return this.repo.update('project-element/saveGantt', state);
  }

  public insertProjectElement(state: any): Observable<ProjectElement> {
    return this.repo.create('project-element/insertProjectElement', state);
  }

  public validateActionForm(controls: ActionFormDTO): Observable<Community> {
    return this.repo.update('project-element/validateActionForm', controls);
  }

  public reschedule(data: { tasks: Partial<Task>[]; links: Partial<Link>[] }): Observable<Community> {
    return this.repo.update('project-element/reschedule', data);
  }

  public deleteProjectElementCustom(idProjectElement: number): Observable<number> {
    return this.repo.delete('project-element/' + +idProjectElement);
  }

  public exportTodosToExcel(idCommunity: number, selectedTodoID: number | 'all' | 'projects') {
    const params = this.todoService.todoElementsToExport;
    return this.http
      .post(
        this.configurationApi.fullAddressV2 +
          '/community/exportTodosToXlsx/?idCommunity=' +
          idCommunity +
          '&selectedTodoID=' +
          selectedTodoID,
        { params },
        { responseType: 'blob' }
      )
      .subscribe((blob: any) => {
        saveAs(blob, 'out.xlsx');
      });
  }

  public reminder(values: any) {
    const params: HttpParams = this.configurationApi.setParams(values);
    return this.http.get(this.configurationApi.fullAddressV1 + '/todos_elements/sendReminder/', { params });
  }
}
