import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User, UserEntityState } from '@api/api-interfaces';
import { CommunityUserProfil, CommunityUserProfilEntityState } from '@api/api-interfaces';
import { UserGroup, UserGroupEntityState } from '@api/api-interfaces';
import { CommunityUser, CommunityUserEntityState } from '@api/api-interfaces';
import { OrganizationUser, OrganizationUserEntityState } from '@api/api-interfaces';
import { UserDevice, UserDeviceEntityState } from '@api/api-interfaces';
import { MeetingUser, MeetingUserEntityState } from '@api/api-interfaces';
import { UserHistory, UserHistoryEntityState } from '@api/api-interfaces';
import { ElementLibrary, ElementLibraryEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { UserState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const userRelations: string[] = [
  'communityUserProfils',
  'userGroups',
  'communityUsers',
  'organizationUsers',
  'userDevices',
  'meetingUsers',
  'userHistories',
  'elementLibraries',
  'organizations',
  'communities',
  'groups'
];

export const { selectEntities, selectAll } = UserState.adapter.getSelectors();

export const selectUserState = createFeatureSelector<UserState.IState>(UserState.userFeatureKey);

export const selectIsLoadedUser = createSelector(selectUserState, (state: UserState.IState) => state.isLoaded);

export const selectIsLoadingUser = createSelector(selectUserState, (state: UserState.IState) => state.isLoading);

export const selectIsReadyUser = createSelector(selectUserState, (state: UserState.IState) => !state.isLoading);

export const selectIsReadyAndLoadedUser = createSelector(
  selectUserState,
  (state: UserState.IState) => state.isLoaded && !state.isLoading
);

export const selectUsersEntities = createSelector(selectUserState, selectEntities);

export const selectUsersArray = createSelector(selectUserState, selectAll);

export const selectIdUsersActive = createSelector(selectUserState, (state: UserState.IState) => state.actives);

const usersInObject = (users: Dictionary<UserEntityState>) => ({ users });

const selectUsersEntitiesDictionary = createSelector(selectUsersEntities, usersInObject);

const selectAllUsersObject = createSelector(selectUsersEntities, users => {
  return hydrateAll({ users });
});

const selectOneUserDictionary = (idUser: number) =>
  createSelector(selectUsersEntities, users => ({
    users: { [idUser]: users[idUser] }
  }));

const selectOneUserDictionaryWithoutChild = (idUser: number) =>
  createSelector(selectUsersEntities, users => ({
    user: users[idUser]
  }));

const selectActiveUsersEntities = createSelector(
  selectIdUsersActive,
  selectUsersEntities,
  (actives: number[], users: Dictionary<UserEntityState>) => getUsersFromActives(actives, users)
);

function getUsersFromActives(actives: number[], users: Dictionary<UserEntityState>): Dictionary<UserEntityState> {
  return actives.reduce((acc, idActive) => {
    if (users[idActive]) {
      acc[idActive] = users[idActive];
    }
    return acc;
  }, {} as Dictionary<UserEntityState>);
}

const selectAllUsersSelectors: Dictionary<Selector> = {};
export function selectAllUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<User>(
      schema,
      selectAllUsersSelectors,
      selectUsersEntitiesDictionary,
      getRelationSelectors,
      userRelations,
      hydrateAll,
      'user'
    );
  } else {
    return selectAllUsersObject;
  }
}

export function selectAllUsersDictionary(schema: SelectSchema = {}, customKey: string = 'users'): Selector {
  return createSelector(selectAllUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.users.length; i++) {
      res[customKey][result.users[i].idUser] = result.users[i];
    }
    return res;
  });
}

export function selectOneUser(schema: SelectSchema = {}, idUser: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneUserDictionary(idUser)];
    selectors.push(...getRelationSelectors(schema, userRelations, 'user'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserDictionaryWithoutChild(idUser);
  }
}

export function selectActiveUsers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveUsersEntities, users => ({
      users
    }))
  ];
  selectors.push(...getRelationSelectors(schema, userRelations, 'user'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  users: Dictionary<UserEntityState>;
  communityUserProfils?: Dictionary<CommunityUserProfilEntityState>;
  userGroups?: Dictionary<UserGroupEntityState>;
  communityUsers?: Dictionary<CommunityUserEntityState>;
  organizationUsers?: Dictionary<OrganizationUserEntityState>;
  userDevices?: Dictionary<UserDeviceEntityState>;
  meetingUsers?: Dictionary<MeetingUserEntityState>;
  userHistories?: Dictionary<UserHistoryEntityState>;
  elementLibraries?: Dictionary<ElementLibraryEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  groups?: Dictionary<GroupEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { users: (User | null)[] } {
  const {
    users,
    communityUserProfils,
    userGroups,
    communityUsers,
    organizationUsers,
    userDevices,
    meetingUsers,
    userHistories,
    elementLibraries,
    organizations,
    communities,
    groups
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    users: Object.keys(users).map(idUser =>
      hydrate(
        users[idUser] as UserEntityState,
        communityUserProfils,
        userGroups,
        communityUsers,
        organizationUsers,
        userDevices,
        meetingUsers,
        userHistories,
        elementLibraries,
        organizations,
        communities,
        groups
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { user: UserEntityState | null } {
  const {
    users,
    communityUserProfils,
    userGroups,
    communityUsers,
    organizationUsers,
    userDevices,
    meetingUsers,
    userHistories,
    elementLibraries,
    organizations,
    communities,
    groups
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const user = Object.values(users)[0];
  return {
    user: hydrate(
      user as UserEntityState,
      communityUserProfils,
      userGroups,
      communityUsers,
      organizationUsers,
      userDevices,
      meetingUsers,
      userHistories,
      elementLibraries,
      organizations,
      communities,
      groups
    )
  };
}

function hydrate(
  user: UserEntityState,
  communityUserProfilEntities?: Dictionary<CommunityUserProfilEntityState>,
  userGroupEntities?: Dictionary<UserGroupEntityState>,
  communityUserEntities?: Dictionary<CommunityUserEntityState>,
  organizationUserEntities?: Dictionary<OrganizationUserEntityState>,
  userDeviceEntities?: Dictionary<UserDeviceEntityState>,
  meetingUserEntities?: Dictionary<MeetingUserEntityState>,
  userHistoryEntities?: Dictionary<UserHistoryEntityState>,
  elementLibraryEntities?: Dictionary<ElementLibraryEntityState>,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>,
  groupEntities?: Dictionary<GroupEntityState>
): User | null {
  if (!user) {
    return null;
  }

  const userHydrated: UserEntityState = { ...user };

  if (communityUserProfilEntities) {
    userHydrated.communityUserProfils = ((userHydrated.communityUserProfils as number[]) || []).map(
      id => communityUserProfilEntities[id]
    ) as CommunityUserProfil[];
  } else {
    delete userHydrated.communityUserProfils;
  }

  if (userGroupEntities) {
    userHydrated.userGroups = ((userHydrated.userGroups as number[]) || []).map(
      id => userGroupEntities[id]
    ) as UserGroup[];
  } else {
    delete userHydrated.userGroups;
  }

  if (communityUserEntities) {
    userHydrated.communityUsers = ((userHydrated.communityUsers as number[]) || []).map(
      id => communityUserEntities[id]
    ) as CommunityUser[];
  } else {
    delete userHydrated.communityUsers;
  }

  if (organizationUserEntities) {
    userHydrated.organizationUsers = ((userHydrated.organizationUsers as number[]) || []).map(
      id => organizationUserEntities[id]
    ) as OrganizationUser[];
  } else {
    delete userHydrated.organizationUsers;
  }

  if (userDeviceEntities) {
    userHydrated.userDevice = userDeviceEntities[user.userDevice as number] as UserDevice;
  } else {
    delete userHydrated.userDevice;
  }

  if (meetingUserEntities) {
    userHydrated.meetingUsers = ((userHydrated.meetingUsers as number[]) || []).map(
      id => meetingUserEntities[id]
    ) as MeetingUser[];
  } else {
    delete userHydrated.meetingUsers;
  }

  if (userHistoryEntities) {
    userHydrated.userHistories = ((userHydrated.userHistories as number[]) || []).map(
      id => userHistoryEntities[id]
    ) as UserHistory[];
  } else {
    delete userHydrated.userHistories;
  }

  if (elementLibraryEntities) {
    userHydrated.elementLibraries = ((userHydrated.elementLibraries as number[]) || []).map(
      id => elementLibraryEntities[id]
    ) as ElementLibrary[];
  } else {
    delete userHydrated.elementLibraries;
  }

  if (organizationEntities) {
    userHydrated.organizations = ((userHydrated.organizations as number[]) || []).map(
      id => organizationEntities[id]
    ) as Organization[];
  } else {
    delete userHydrated.organizations;
  }

  if (communityEntities) {
    userHydrated.communities = ((userHydrated.communities as number[]) || []).map(
      id => communityEntities[id]
    ) as Community[];
  } else {
    delete userHydrated.communities;
  }

  if (groupEntities) {
    userHydrated.groups = ((userHydrated.groups as number[]) || []).map(id => groupEntities[id]) as Group[];
  } else {
    delete userHydrated.groups;
  }

  return userHydrated as User;
}
