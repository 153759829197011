import { MeetingUserState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { meetingUserReducersGeneratedFunctions } from './meeting-user-generated.reducer';

const meetingUserReducersFunctions = [...meetingUserReducersGeneratedFunctions];

const meetingUserReducer = createReducer(MeetingUserState.initialState, ...meetingUserReducersFunctions);

export function reducer(state: MeetingUserState.IState | undefined, action: Action) {
  return meetingUserReducer(state, action);
}
