import { createEntityAdapter } from '@ngrx/entity';
import { SectionEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<SectionEntityState>;

export const adapter = createEntityAdapter<SectionEntityState>({
  selectId: o => o.idSection
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const sectionFeatureKey = 'section';
