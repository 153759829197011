<div [formGroup]="actionForm">
  @if (actionForm) {
    <app-search-users-directive
      [displayList]="'dropDown'"
      [metaUsersSelected]="actionForm.controls['listeResponsables'].value"
      [selectOnlyOnce]="false"
      [groupMetaUsersSuggestedOrigin]="isViewer ? [] : suggestions"
      [add]="actionForm.controls['listeElementsLibrariesAdd'].value"
      [rmv]="actionForm.controls['listeElementsLibrariesRemove'].value"
      [canAddName]="true"
      [isViewer]="isViewer"
      (change)="responsibleChange($event)"
      floatLabel="always"
    >
    </app-search-users-directive>
  }
</div>
