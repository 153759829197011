import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { OrganizationFamilyRelationsIds } from '@wip/store/ids-interfaces';

export const OrganizationFamilyGeneratedActions = createActionGroup({
  source: 'Organization Family Generated',
  events: {
    'Get One Organization Family': props<{ idOrganizationFamily: number; params?: any }>(),
    'Get Many Organization Familys': props<{ params: any }>(),
    'Add Many Actives Organization Familys': props<{ idOrganizationFamilys: number[] }>(),
    'Delete One Active Organization Family': props<{ idOrganizationFamily: number }>(),
    'Clear Actives Organization Familys': emptyProps(),
    'Upsert One Organization Family': props<{
      organizationFamily: Partial<OrganizationFamily>;
      ids?: OrganizationFamilyRelationsIds;
    }>(),
    'Upsert Many Organization Familys': props<{
      organizationFamilys: Partial<OrganizationFamily>[];
      ids?: OrganizationFamilyRelationsIds;
    }>(),
    'Upsert Many Organization Familys Success': props<{ organizationFamilys: OrganizationFamilyEntityState[] }>(),
    'Delete One Organization Family': props<{ idOrganizationFamily: number }>(),
    'Delete One Organization Family Success': props<{ idOrganizationFamily: number }>(),
    'Normalize Many Organization Familys After Upsert': props<{
      organizationFamilys: OrganizationFamilyEntityState[];
    }>(),
    'Organization Familys Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Organization Familys': emptyProps(),
    'Add Many Organization Sub Family Success': props<{
      idOrganizationFamily: number;
      idOrganizationSubFamilys: number[];
    }>(),
    'Delete Many Organization Sub Family Success': props<{
      idOrganizationSubFamilys: number[];
      idOrganizationFamilys: number[];
    }>(),
    'Add Many Community Success': props<{ idOrganizationFamily: number; idCommunities: number[] }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idOrganizationFamilys: number[] }>(),
    'Add Many Organization Step Success': props<{ idOrganizationFamily: number; idOrganizationSteps: number[] }>(),
    'Delete Many Organization Step Success': props<{
      idOrganizationSteps: number[];
      idOrganizationFamilys: number[];
    }>(),
    'Add Many Organization Kpi Success': props<{ idOrganizationFamily: number; idOrganizationKpis: number[] }>(),
    'Delete Many Organization Kpi Success': props<{ idOrganizationKpis: number[]; idOrganizationFamilys: number[] }>(),
    'Add Many Organization Data Column Success': props<{
      idOrganizationFamily: number;
      idOrganizationDataColumns: number[];
    }>(),
    'Delete Many Organization Data Column Success': props<{
      idOrganizationDataColumns: number[];
      idOrganizationFamilys: number[];
    }>(),
    'Add Many Organization Milestone Family Success': props<{
      idOrganizationFamily: number;
      idOrganizationMilestoneFamilys: number[];
    }>(),
    'Delete Many Organization Milestone Family Success': props<{
      idOrganizationMilestoneFamilys: number[];
      idOrganizationFamilys: number[];
    }>(),
    'Add Many Organization Caracteristic Success': props<{
      idOrganizationFamily: number;
      idOrganizationCaracteristics: number[];
    }>(),
    'Delete Many Organization Caracteristic Success': props<{
      idOrganizationCaracteristics: number[];
      idOrganizationFamilys: number[];
    }>(),
    'Add Many Organization Milestone Success': props<{
      idOrganizationFamily: number;
      idOrganizationMilestones: number[];
    }>(),
    'Delete Many Organization Milestone Success': props<{
      idOrganizationMilestones: number[];
      idOrganizationFamilys: number[];
    }>(),
    'Add Many Organization Risk Success': props<{ idOrganizationFamily: number; idOrganizationRisks: number[] }>(),
    'Delete Many Organization Risk Success': props<{
      idOrganizationRisks: number[];
      idOrganizationFamilys: number[];
    }>(),
    'Add Many Organization User Profil Success': props<{
      idOrganizationFamily: number;
      idOrganizationUserProfils: number[];
    }>(),
    'Delete Many Organization User Profil Success': props<{
      idOrganizationUserProfils: number[];
      idOrganizationFamilys: number[];
    }>(),
    'Add Many User Board State Success': props<{ idOrganizationFamily: number; idUserBoardStates: number[] }>(),
    'Delete Many User Board State Success': props<{ idUserBoardStates: number[]; idOrganizationFamilys: number[] }>(),
    'Add Organization Success': props<{ idOrganizationFamily: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idOrganizationFamilys: number[] }>()
  }
});
