<div mat-dialog-title>
  <h1>Modifier Date</h1>
  <button mat-icon-button mat-dialog-close class="gulp gulp-34-close" tabindex="-1"></button>
</div>

<div class="flex flex-row gap-32 justify-center items-center" [formGroup]="fg" style="margin-top: 16px">
  <up-date-input style="width: 140px" formControlName="newDate" placeholder="Date"> </up-date-input>

  <div class="flex items-center justify-center" style="margin-top: 8px">
    <span
      >Vous souhaitez modifier la date du
      <i
        ><b>{{ dialogData.oldDateFormatted }}</b></i
      >
      au
      <i
        ><b>{{ dateFormatted }}</b></i
      >
      (<i>{{ sign + diffFormated }}</i
      >).<br />
      Choisiez la méthode de calcul à retenir</span
    >
  </div>
</div>

<div class="center-options flex flex-row gap-16 justify-around items-center">
  <mat-card
    [ngStyle]="{ 'background-color': selected === moveTaskStrategy.moveTaskWithChildren ? '#ceeaff' : 'white' }"
    class="option"
    (click)="selected = moveTaskStrategy.moveTaskWithChildren"
    ><span class="text-option-st">Date prévisionnelle <u>avec</u> décalage des successeurs</span><mat-divider />
    <span class="text-option"
      >Date mobile calculée en fonction du prédécesseur (<i style="color: gray">gris italique</i>)</span
    >
    <span class="text-option">Allonge de {{ sign + diffFormated }} le délai au précédesseur</span>
    <span class="text-option">Décale de {{ sign + diffFormated }} les successeurs</span><br />
    <mat-divider />
    <br />
    <img src="assets/images/move-next.gif" />
    <br />
    @if (selected === moveTaskStrategy.moveTaskWithChildren) {
      <i class="check icon icon-ic_fluent_checkmark_circle_24_regular flex justify-center"></i>
    }
  </mat-card>
  <mat-card
    [ngStyle]="{ 'background-color': selected === moveTaskStrategy.moveTaskWithoutChildren ? '#ceeaff' : 'white' }"
    class="option"
    (click)="selected = moveTaskStrategy.moveTaskWithoutChildren"
    ><span class="text-option-st">Date prévisionnelle <u>sans</u> décalage des successeurs</span><mat-divider />
    <span class="text-option"
      >Date mobile calculée en fonction du prédécesseur (<i style="color: gray">gris italique</i>)
    </span>
    <span class="text-option">Allonge de {{ sign + diffFormated }} le délai au précédesseur </span>
    <span class="text-option">Maintien des dates des successeurs</span>
    <mat-divider />
    <br />
    <img src="assets/images/not-move-next.gif" />
    <br />
    @if (selected === moveTaskStrategy.moveTaskWithoutChildren) {
      <i class="check icon icon-ic_fluent_checkmark_circle_24_regular flex justify-center"></i>
    }
  </mat-card>
  <mat-card
    [ngStyle]="{ 'background-color': selected === moveTaskStrategy.fixTaskWithMoveChildren ? '#ceeaff' : 'white' }"
    class="option"
    (click)="selected = moveTaskStrategy.fixTaskWithMoveChildren"
    ><span class="text-option-st"><br />Date réelle<br /><br /></span><mat-divider />
    <span class="text-option"
      >Date figée. Ignore la contrainte de lien avec le prédécesseur <br />
      (<b>gras noir</b>)</span
    >
    <span class="text-option">Maintien du délai au précédesseur (mais ignoré)</span>
    <span class="text-option">Décale de {{ sign + diffFormated }} les successeurs</span>
    <mat-divider />
    <br />
    <img src="assets/images/fix-date.gif" />
    <br />
    @if (selected === moveTaskStrategy.fixTaskWithMoveChildren) {
      <i class="check icon icon-ic_fluent_checkmark_circle_24_regular flex justify-center"></i>
    }
  </mat-card>
  <!-- <mat-card
    [ngStyle]="{ 'background-color': selected === 3 ? '#ceeaff' : 'white' }"
    class="option"
    (click)="selected = 3"
    ><span class="text-option">Ne pas décaler les prochaines dates avec celle-ci.</span><mat-divider />
    <span class="text-option-st">Figer cette date</span>
    @if (selected === 3) {
      <i * class="check icon icon-ic_fluent_checkmark_circle_24_regular flex justify-end"></i>
    }
  </mat-card> -->
</div>
<div class="footer-options flex flex-row justify-end gap-16">
  <div>
    <button mat-button type="button" (click)="close()">Annuler</button>
  </div>
  <div>
    <button mat-flat-button type="button" (click)="save()" color="primary">Enregistrer</button>
  </div>
</div>
