import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Group, GroupEntityState } from '@api/api-interfaces';
import { GroupRelationsIds } from '@wip/store/ids-interfaces';

export const GroupGeneratedActions = createActionGroup({
  source: 'Group Generated',
  events: {
    'Get One Group': props<{ idGroup: number; params?: any }>(),
    'Get Many Groups': props<{ params: any }>(),
    'Add Many Actives Groups': props<{ idGroups: number[] }>(),
    'Delete One Active Group': props<{ idGroup: number }>(),
    'Clear Actives Groups': emptyProps(),
    'Upsert One Group': props<{ group: Partial<Group>; ids?: GroupRelationsIds }>(),
    'Upsert Many Groups': props<{ groups: Partial<Group>[]; ids?: GroupRelationsIds }>(),
    'Upsert Many Groups Success': props<{ groups: GroupEntityState[] }>(),
    'Delete One Group': props<{ idGroup: number }>(),
    'Delete One Group Success': props<{ idGroup: number }>(),
    'Normalize Many Groups After Upsert': props<{ groups: GroupEntityState[] }>(),
    'Groups Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Groups': emptyProps(),
    'Add Many Community Group Success': props<{ idGroup: number; idCommunityGroups: number[] }>(),
    'Delete Many Community Group Success': props<{ idCommunityGroups: number[]; idGroups: number[] }>(),
    'Add Many User Group Success': props<{ idGroup: number; idUserGroups: number[] }>(),
    'Delete Many User Group Success': props<{ idUserGroups: number[]; idGroups: number[] }>(),
    'Add Many Organization Azure Success': props<{ idGroup: number; idOrganizationAzures: number[] }>(),
    'Delete Many Organization Azure Success': props<{ idOrganizationAzures: number[]; idGroups: number[] }>(),
    'Add Many User Success': props<{ idGroup: number; idUsers: number[] }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idGroups: number[] }>(),
    'Add Many Community Success': props<{ idGroup: number; idCommunities: number[] }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idGroups: number[] }>(),
    'Add Organization Success': props<{ idGroup: number; idOrganization: number }>(),
    'Delete Many Organization Success': props<{ idOrganizations: number[]; idGroups: number[] }>()
  }
});
