import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationMilestoneGeneratedActions } from '@wip/store/actions';
import { OrganizationMilestoneState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationMilestoneEntityState } from '@api/api-interfaces';

export const organizationMilestoneReducersGeneratedFunctions: ReducerTypes<
  OrganizationMilestoneState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationMilestoneGeneratedActions.getOneOrganizationMilestone, (state: OrganizationMilestoneState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationMilestoneGeneratedActions.getManyOrganizationMilestones, (state: OrganizationMilestoneState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationMilestoneGeneratedActions.upsertOneOrganizationMilestone, (state: OrganizationMilestoneState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationMilestoneGeneratedActions.upsertManyOrganizationMilestonesSuccess,
    (state: OrganizationMilestoneState.IState, { organizationMilestones }) =>
      OrganizationMilestoneState.adapter.upsertMany(organizationMilestones, setLoadingsState(state, false))
  ),
  on(OrganizationMilestoneGeneratedActions.deleteOneOrganizationMilestone, (state: OrganizationMilestoneState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationMilestoneGeneratedActions.deleteOneOrganizationMilestoneSuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestone }) =>
      OrganizationMilestoneState.adapter.removeOne(idOrganizationMilestone, setLoadingsState(state, false))
  ),
  on(
    OrganizationMilestoneGeneratedActions.clearActivesOrganizationMilestones,
    (state: OrganizationMilestoneState.IState) => ({ ...state, actives: [] })
  ),
  on(
    OrganizationMilestoneGeneratedActions.addManyActivesOrganizationMilestones,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestones }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationMilestones)
    })
  ),
  on(
    OrganizationMilestoneGeneratedActions.deleteOneActiveOrganizationMilestone,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestone }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationMilestone)
    })
  ),

  on(OrganizationMilestoneGeneratedActions.clearOrganizationMilestones, () => OrganizationMilestoneState.initialState),

  on(
    OrganizationMilestoneGeneratedActions.addManyCommunityMilestoneSuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestone, idCommunityMilestones }) => {
      if (!state.entities[idOrganizationMilestone]) {
        return state;
      }
      const communityMilestones = (state.entities[idOrganizationMilestone]?.communityMilestones as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestone]: {
            ...state.entities[idOrganizationMilestone],
            communityMilestones: communityMilestones.concat(
              idCommunityMilestones.filter(id => communityMilestones.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.deleteManyCommunityMilestoneSuccess,
    (state: OrganizationMilestoneState.IState, { idCommunityMilestones, idOrganizationMilestones }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestones.reduce((entities, idOrganizationMilestone) => {
            if (!state.entities[idOrganizationMilestone]?.communityMilestones) {
              return entities;
            }
            entities[idOrganizationMilestone] = {
              ...state.entities[idOrganizationMilestone],
              communityMilestones: (state.entities[idOrganizationMilestone]?.communityMilestones as number[])?.filter(
                (idCommunityMilestone: number) =>
                  !idCommunityMilestones.some((id: number) => id === idCommunityMilestone)
              )
            } as OrganizationMilestoneEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.addManyOrganizationMilestoneAssociationSuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestone, idOrganizationMilestoneAssociations }) => {
      if (!state.entities[idOrganizationMilestone]) {
        return state;
      }
      const organizationMilestoneAssociations =
        (state.entities[idOrganizationMilestone]?.organizationMilestoneAssociations as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestone]: {
            ...state.entities[idOrganizationMilestone],
            organizationMilestoneAssociations: organizationMilestoneAssociations.concat(
              idOrganizationMilestoneAssociations.filter(id => organizationMilestoneAssociations.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.deleteManyOrganizationMilestoneAssociationSuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestoneAssociations, idOrganizationMilestones }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestones.reduce((entities, idOrganizationMilestone) => {
            if (!state.entities[idOrganizationMilestone]?.organizationMilestoneAssociations) {
              return entities;
            }
            entities[idOrganizationMilestone] = {
              ...state.entities[idOrganizationMilestone],
              organizationMilestoneAssociations: (
                state.entities[idOrganizationMilestone]?.organizationMilestoneAssociations as number[]
              )?.filter(
                (idOrganizationMilestoneAssociation: number) =>
                  !idOrganizationMilestoneAssociations.some((id: number) => id === idOrganizationMilestoneAssociation)
              )
            } as OrganizationMilestoneEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.addManyElementSuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestone, idElements }) => {
      if (!state.entities[idOrganizationMilestone]) {
        return state;
      }
      const elements = (state.entities[idOrganizationMilestone]?.elements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestone]: {
            ...state.entities[idOrganizationMilestone],
            elements: elements.concat(idElements.filter(id => elements.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.deleteManyElementSuccess,
    (state: OrganizationMilestoneState.IState, { idElements, idOrganizationMilestones }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestones.reduce((entities, idOrganizationMilestone) => {
            if (!state.entities[idOrganizationMilestone]?.elements) {
              return entities;
            }
            entities[idOrganizationMilestone] = {
              ...state.entities[idOrganizationMilestone],
              elements: (state.entities[idOrganizationMilestone]?.elements as number[])?.filter(
                (idElement: number) => !idElements.some((id: number) => id === idElement)
              )
            } as OrganizationMilestoneEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.addManyOrganizationMilestoneFamilySuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestone, idOrganizationMilestoneFamilys }) => {
      if (!state.entities[idOrganizationMilestone]) {
        return state;
      }
      const organizationMilestoneFamilys =
        (state.entities[idOrganizationMilestone]?.organizationMilestoneFamilys as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestone]: {
            ...state.entities[idOrganizationMilestone],
            organizationMilestoneFamilys: organizationMilestoneFamilys.concat(
              idOrganizationMilestoneFamilys.filter(id => organizationMilestoneFamilys.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.deleteManyOrganizationMilestoneFamilySuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestoneFamilys, idOrganizationMilestones }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestones.reduce((entities, idOrganizationMilestone) => {
            if (!state.entities[idOrganizationMilestone]?.organizationMilestoneFamilys) {
              return entities;
            }
            entities[idOrganizationMilestone] = {
              ...state.entities[idOrganizationMilestone],
              organizationMilestoneFamilys: (
                state.entities[idOrganizationMilestone]?.organizationMilestoneFamilys as number[]
              )?.filter(
                (idOrganizationMilestoneFamily: number) =>
                  !idOrganizationMilestoneFamilys.some((id: number) => id === idOrganizationMilestoneFamily)
              )
            } as OrganizationMilestoneEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.addOrganizationSuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestone, idOrganization }) => {
      if (!state.entities[idOrganizationMilestone]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestone]: {
            ...state.entities[idOrganizationMilestone],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizations, idOrganizationMilestones }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestones.reduce((entities, idOrganizationMilestone) => {
            if (!state.entities[idOrganizationMilestone]?.organization) {
              return entities;
            }
            entities[idOrganizationMilestone] = {
              ...state.entities[idOrganizationMilestone],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationMilestone]?.organization
              )
                ? undefined
                : state.entities[idOrganizationMilestone]?.organization
            } as OrganizationMilestoneEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.addOrganizationFamilySuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationMilestone, idOrganizationFamily }) => {
      if (!state.entities[idOrganizationMilestone]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMilestone]: {
            ...state.entities[idOrganizationMilestone],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationMilestoneGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationMilestoneState.IState, { idOrganizationFamilys, idOrganizationMilestones }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMilestones.reduce((entities, idOrganizationMilestone) => {
            if (!state.entities[idOrganizationMilestone]?.organizationFamily) {
              return entities;
            }
            entities[idOrganizationMilestone] = {
              ...state.entities[idOrganizationMilestone],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idOrganizationMilestone]?.organizationFamily
              )
                ? undefined
                : state.entities[idOrganizationMilestone]?.organizationFamily
            } as OrganizationMilestoneEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMilestoneEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationMilestoneState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationMilestoneState.IState {
  return { ...state, isLoaded, isLoading };
}
