import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFileDrop]'
})
export class FileDropDirective implements OnDestroy {
  @Input() multipleDropZones = true;
  @Input() disabled = false;
  @Output() fileDrop = new EventEmitter<FileList>();

  private comesFromDOMElement = false;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private zone: NgZone
  ) {
    this.zone.runOutsideAngular(() => {
      window.addEventListener('dragstart', this.windowDragStartHandler.bind(this));

      window.addEventListener('dragover', this.windowDragoverHandler.bind(this));

      window.addEventListener('dragleave', this.windowDragleaveHandler.bind(this));

      window.addEventListener('drop', this.windowDropHandler.bind(this));

      this.el.nativeElement.addEventListener('drop', this.elementDropHandler.bind(this));
    });
  }

  private windowDragStartHandler() {
    this.comesFromDOMElement = true;
  }

  private windowDragoverHandler($event: DragEvent) {
    if (!this.disabled && !this.comesFromDOMElement) {
      $event.dataTransfer.dropEffect = 'copy';
      this.renderer.addClass(this.el.nativeElement, 'drop-file');
      $event.preventDefault();
    }
  }

  private windowDragleaveHandler($event: DragEvent) {
    if (!this.disabled && $event['fromElement'] === null && this.el.nativeElement !== $event['fromElement']) {
      this.renderer.removeClass(this.el.nativeElement, 'drop-file');

      $event.preventDefault();
    }
  }

  private windowDropHandler($event: DragEvent) {
    if (!this.disabled) {
      this.renderer.removeClass(this.el.nativeElement, 'drop-file');

      $event.preventDefault();
    }

    this.comesFromDOMElement = false;
  }

  private elementDropHandler($event: DragEvent) {
    if (!this.disabled) {
      const { files } = $event.dataTransfer;
      this.fileDrop.emit(files);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('dragstart', this.windowDragStartHandler.bind(this));
    window.removeEventListener('dragover', this.windowDragoverHandler);
    window.removeEventListener('dragleave', this.windowDragleaveHandler);
    window.removeEventListener('drop', this.windowDropHandler);
    this.el.nativeElement.removeEventListener('drop', this.elementDropHandler);
  }
}
