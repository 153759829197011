import { Actions } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommunityUserApiService } from '@wip/store/api-services';
import { GeneratedCommunityUserEffects } from './community-user-generated.effects';

@Injectable()
export class CommunityUserEffects extends GeneratedCommunityUserEffects {
  constructor(actions$: Actions, communityUserApiService: CommunityUserApiService, store$: Store<AppState>) {
    super(actions$, communityUserApiService, store$);
  }
}
