import { createEntityAdapter } from '@ngrx/entity';
import { CommunityCaracteristicEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<CommunityCaracteristicEntityState>;

export const adapter = createEntityAdapter<CommunityCaracteristicEntityState>({
  selectId: o => o.idCommunityCaracteristic
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const communityCaracteristicFeatureKey = 'communityCaracteristic';
