import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { MeetingUser, MeetingUserEntityState } from '@api/api-interfaces';
import { MeetingUserApiService } from '@wip/store/api-services';
import { MeetingUserGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeMeetingUser } from '@wip/store/configs/normalization';
import { MeetingUserSelectors } from '@wip/store/selectors';
import { MeetingUserRelationsIds } from '@wip/store/ids-interfaces';
import { MeetingGeneratedActions } from '@wip/store/actions';
import { UserGeneratedActions } from '@wip/store/actions';

export function getDefaultAddMeetingUserActions(
  meetingUser: MeetingUserEntityState,
  ids?: MeetingUserRelationsIds
): Action[] {
  const actions: Action[] = [
    MeetingUserGeneratedActions.normalizeManyMeetingUsersAfterUpsert({ meetingUsers: [meetingUser] })
  ];

  if (ids?.meeting) {
    actions.push(
      MeetingGeneratedActions.addManyMeetingUserSuccess({
        idMeeting: ids.meeting,
        idMeetingUsers: [meetingUser.idMeetingUser]
      })
    );
    actions.push(
      MeetingUserGeneratedActions.addMeetingSuccess({
        idMeetingUser: meetingUser.idMeetingUser,
        idMeeting: ids.meeting
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyMeetingUserSuccess({
        idUser: ids.user,
        idMeetingUsers: [meetingUser.idMeetingUser]
      })
    );
    actions.push(
      MeetingUserGeneratedActions.addUserSuccess({
        idMeetingUser: meetingUser.idMeetingUser,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteMeetingUserActions(meetingUser: MeetingUserEntityState): Action[] {
  const actions: Action[] = [
    MeetingUserGeneratedActions.deleteOneMeetingUserSuccess({ idMeetingUser: meetingUser.idMeetingUser })
  ];

  if (meetingUser.meeting) {
    actions.push(
      MeetingGeneratedActions.deleteManyMeetingUserSuccess({
        idMeetingUsers: [meetingUser.idMeetingUser],
        idMeetings: [meetingUser.meeting as number]
      })
    );
  }

  if (meetingUser.user) {
    actions.push(
      UserGeneratedActions.deleteManyMeetingUserSuccess({
        idMeetingUsers: [meetingUser.idMeetingUser],
        idUsers: [meetingUser.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedMeetingUserEffects {
  constructor(
    protected actions$: Actions,
    protected meetingUserApiService: MeetingUserApiService,
    protected store$: Store<AppState>
  ) {}

  getManyMeetingUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingUserGeneratedActions.getManyMeetingUsers),
      switchMap(({ params }) =>
        this.meetingUserApiService.getMeetingUsers(params).pipe(
          map((meetingUsers: MeetingUser[]) => {
            return MeetingUserGeneratedActions.normalizeManyMeetingUsersAfterUpsert({ meetingUsers });
          }),
          catchError(error => of(MeetingUserGeneratedActions.meetingUsersFailure({ error })))
        )
      )
    );
  });

  getOneMeetingUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingUserGeneratedActions.getOneMeetingUser),
      switchMap(idMeetingUser =>
        this.meetingUserApiService.getMeetingUser(idMeetingUser).pipe(
          map((meetingUser: MeetingUser) => {
            return MeetingUserGeneratedActions.normalizeManyMeetingUsersAfterUpsert({ meetingUsers: [meetingUser] });
          }),
          catchError(error => of(MeetingUserGeneratedActions.meetingUsersFailure({ error })))
        )
      )
    );
  });

  upsertOneMeetingUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingUserGeneratedActions.upsertOneMeetingUser),
      concatMap(({ meetingUser, ids }: { meetingUser: Partial<MeetingUser>; ids?: MeetingUserRelationsIds }) => {
        if (meetingUser.idMeetingUser) {
          return this.meetingUserApiService.updateMeetingUser(meetingUser).pipe(
            map((meetingUserReturned: MeetingUser) => {
              return MeetingUserGeneratedActions.normalizeManyMeetingUsersAfterUpsert({
                meetingUsers: [meetingUserReturned]
              });
            }),
            catchError(error => of(MeetingUserGeneratedActions.meetingUsersFailure({ error })))
          );
        } else {
          return this.meetingUserApiService.addMeetingUser(meetingUser).pipe(
            mergeMap((meetingUserReturned: MeetingUser) => getDefaultAddMeetingUserActions(meetingUserReturned, ids)),
            catchError(error => of(MeetingUserGeneratedActions.meetingUsersFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneMeetingUser$ = createEffect(() => {
    const selectMeetingUserState$ = this.store$.select(MeetingUserSelectors.selectMeetingUserState);
    return this.actions$.pipe(
      ofType(MeetingUserGeneratedActions.deleteOneMeetingUser),
      withLatestFrom(selectMeetingUserState$),
      concatMap(([{ idMeetingUser }, state]) =>
        this.meetingUserApiService.deleteMeetingUser(idMeetingUser).pipe(
          mergeMap(_success =>
            getDefaultDeleteMeetingUserActions(state.entities[idMeetingUser] as MeetingUserEntityState)
          ),
          catchError(error => of(MeetingUserGeneratedActions.meetingUsersFailure({ error })))
        )
      )
    );
  });

  normalizeManyMeetingUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeetingUserGeneratedActions.normalizeManyMeetingUsersAfterUpsert),
      concatMap(({ meetingUsers }) => {
        const actions: Action[] = getActionsToNormalizeMeetingUser(meetingUsers, StoreActionType.upsert);
        return [getMultiAction(actions, '[MeetingUser] Normalization After Upsert Success')];
      })
    );
  });
}
