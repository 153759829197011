import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityUser, CommunityUserEntityState } from '@api/api-interfaces';
import { CommunityUserApiService } from '@wip/store/api-services';
import { CommunityUserGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityUser } from '@wip/store/configs/normalization';
import { CommunityUserSelectors } from '@wip/store/selectors';
import { CommunityUserRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { UserGeneratedActions } from '@wip/store/actions';

export function getDefaultAddCommunityUserActions(
  communityUser: CommunityUserEntityState,
  ids?: CommunityUserRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityUserGeneratedActions.normalizeManyCommunityUsersAfterUpsert({ communityUsers: [communityUser] })
  ];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityUserSuccess({
        idCommunity: ids.community,
        idCommunityUsers: [communityUser.idCommunityUser]
      })
    );
    actions.push(
      CommunityUserGeneratedActions.addCommunitySuccess({
        idCommunityUser: communityUser.idCommunityUser,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyCommunityUserSuccess({
        idUser: ids.user,
        idCommunityUsers: [communityUser.idCommunityUser]
      })
    );
    actions.push(
      CommunityUserGeneratedActions.addUserSuccess({
        idCommunityUser: communityUser.idCommunityUser,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCommunityUserActions(communityUser: CommunityUserEntityState): Action[] {
  const actions: Action[] = [
    CommunityUserGeneratedActions.deleteOneCommunityUserSuccess({ idCommunityUser: communityUser.idCommunityUser })
  ];

  if (communityUser.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityUserSuccess({
        idCommunityUsers: [communityUser.idCommunityUser],
        idCommunities: [communityUser.community as number]
      })
    );
  }

  if (communityUser.user) {
    actions.push(
      UserGeneratedActions.deleteManyCommunityUserSuccess({
        idCommunityUsers: [communityUser.idCommunityUser],
        idUsers: [communityUser.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityUserEffects {
  constructor(
    protected actions$: Actions,
    protected communityUserApiService: CommunityUserApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityUserGeneratedActions.getManyCommunityUsers),
      switchMap(({ params }) =>
        this.communityUserApiService.getCommunityUsers(params).pipe(
          map((communityUsers: CommunityUser[]) => {
            return CommunityUserGeneratedActions.normalizeManyCommunityUsersAfterUpsert({ communityUsers });
          }),
          catchError(error => of(CommunityUserGeneratedActions.communityUsersFailure({ error })))
        )
      )
    );
  });

  getOneCommunityUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityUserGeneratedActions.getOneCommunityUser),
      switchMap(idCommunityUser =>
        this.communityUserApiService.getCommunityUser(idCommunityUser).pipe(
          map((communityUser: CommunityUser) => {
            return CommunityUserGeneratedActions.normalizeManyCommunityUsersAfterUpsert({
              communityUsers: [communityUser]
            });
          }),
          catchError(error => of(CommunityUserGeneratedActions.communityUsersFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityUserGeneratedActions.upsertOneCommunityUser),
      concatMap(
        ({ communityUser, ids }: { communityUser: Partial<CommunityUser>; ids?: CommunityUserRelationsIds }) => {
          if (communityUser.idCommunityUser) {
            return this.communityUserApiService.updateCommunityUser(communityUser).pipe(
              map((communityUserReturned: CommunityUser) => {
                return CommunityUserGeneratedActions.normalizeManyCommunityUsersAfterUpsert({
                  communityUsers: [communityUserReturned]
                });
              }),
              catchError(error => of(CommunityUserGeneratedActions.communityUsersFailure({ error })))
            );
          } else {
            return this.communityUserApiService.addCommunityUser(communityUser).pipe(
              mergeMap((communityUserReturned: CommunityUser) =>
                getDefaultAddCommunityUserActions(communityUserReturned, ids)
              ),
              catchError(error => of(CommunityUserGeneratedActions.communityUsersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCommunityUser$ = createEffect(() => {
    const selectCommunityUserState$ = this.store$.select(CommunityUserSelectors.selectCommunityUserState);
    return this.actions$.pipe(
      ofType(CommunityUserGeneratedActions.deleteOneCommunityUser),
      withLatestFrom(selectCommunityUserState$),
      concatMap(([{ idCommunityUser }, state]) =>
        this.communityUserApiService.deleteCommunityUser(idCommunityUser).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityUserActions(state.entities[idCommunityUser] as CommunityUserEntityState)
          ),
          catchError(error => of(CommunityUserGeneratedActions.communityUsersFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityUserGeneratedActions.normalizeManyCommunityUsersAfterUpsert),
      concatMap(({ communityUsers }) => {
        const actions: Action[] = getActionsToNormalizeCommunityUser(communityUsers, StoreActionType.upsert);
        return [getMultiAction(actions, '[CommunityUser] Normalization After Upsert Success')];
      })
    );
  });
}
