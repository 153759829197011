import { CommunityReducer } from '@wip/store/reducers';
import { CommunityState } from '@wip/store/states';
import { CommunityCaracteristicReducer } from '@wip/store/reducers';
import { CommunityCaracteristicState } from '@wip/store/states';
import { CommunityKpiReducer } from '@wip/store/reducers';
import { CommunityKpiState } from '@wip/store/states';
import { ChildrenElementReducer } from '@wip/store/reducers';
import { ChildrenElementState } from '@wip/store/states';
import { CommunityMilestoneReducer } from '@wip/store/reducers';
import { CommunityMilestoneState } from '@wip/store/states';
import { CommunityMilestoneFamilyReducer } from '@wip/store/reducers';
import { CommunityMilestoneFamilyState } from '@wip/store/states';
import { CommunityRiskReducer } from '@wip/store/reducers';
import { CommunityRiskState } from '@wip/store/states';
import { CommunitySubFamilyReducer } from '@wip/store/reducers';
import { CommunitySubFamilyState } from '@wip/store/states';
import { CommunityGroupReducer } from '@wip/store/reducers';
import { CommunityGroupState } from '@wip/store/states';
import { CommunityModuleReducer } from '@wip/store/reducers';
import { CommunityModuleState } from '@wip/store/states';
import { CommunityUserReducer } from '@wip/store/reducers';
import { CommunityUserState } from '@wip/store/states';
import { FolderReducer } from '@wip/store/reducers';
import { FolderState } from '@wip/store/states';
import { FolderElementReducer } from '@wip/store/reducers';
import { FolderElementState } from '@wip/store/states';
import { ElementReducer } from '@wip/store/reducers';
import { ElementState } from '@wip/store/states';
import { ElementCashReducer } from '@wip/store/reducers';
import { ElementCashState } from '@wip/store/states';
import { ElementLibraryReducer } from '@wip/store/reducers';
import { ElementLibraryState } from '@wip/store/states';
import { GanttLinkReducer } from '@wip/store/reducers';
import { GanttLinkState } from '@wip/store/states';
import { GroupReducer } from '@wip/store/reducers';
import { GroupState } from '@wip/store/states';
import { MeetingReducer } from '@wip/store/reducers';
import { MeetingState } from '@wip/store/states';
import { MeetingElementReducer } from '@wip/store/reducers';
import { MeetingElementState } from '@wip/store/states';
import { MeetingUserReducer } from '@wip/store/reducers';
import { MeetingUserState } from '@wip/store/states';
import { NotesHistoryReducer } from '@wip/store/reducers';
import { NotesHistoryState } from '@wip/store/states';
import { ProjectModuleReducer } from '@wip/store/reducers';
import { ProjectModuleState } from '@wip/store/states';
import { OrganizationAzureReducer } from '@wip/store/reducers';
import { OrganizationAzureState } from '@wip/store/states';
import { OrganizationCaracteristicReducer } from '@wip/store/reducers';
import { OrganizationCaracteristicState } from '@wip/store/states';
import { OrganizationCaracteristicColumnReducer } from '@wip/store/reducers';
import { OrganizationCaracteristicColumnState } from '@wip/store/states';
import { OrganizationCaracteristicRowReducer } from '@wip/store/reducers';
import { OrganizationCaracteristicRowState } from '@wip/store/states';
import { OrganizationCaracteristicValueReducer } from '@wip/store/reducers';
import { OrganizationCaracteristicValueState } from '@wip/store/states';
import { OrganizationMilestoneReducer } from '@wip/store/reducers';
import { OrganizationMilestoneState } from '@wip/store/states';
import { OrganizationStepReducer } from '@wip/store/reducers';
import { OrganizationStepState } from '@wip/store/states';
import { OrganizationReducer } from '@wip/store/reducers';
import { OrganizationState } from '@wip/store/states';
import { OrganizationFamilyReducer } from '@wip/store/reducers';
import { OrganizationFamilyState } from '@wip/store/states';
import { OrganizationKpiReducer } from '@wip/store/reducers';
import { OrganizationKpiState } from '@wip/store/states';
import { OrganizationMilestoneFamilyReducer } from '@wip/store/reducers';
import { OrganizationMilestoneFamilyState } from '@wip/store/states';
import { OrganizationMilestoneAssociationReducer } from '@wip/store/reducers';
import { OrganizationMilestoneAssociationState } from '@wip/store/states';
import { OrganizationDataColumnReducer } from '@wip/store/reducers';
import { OrganizationDataColumnState } from '@wip/store/states';
import { OrganizationDataColumnValueReducer } from '@wip/store/reducers';
import { OrganizationDataColumnValueState } from '@wip/store/states';
import { CommunityDataRowReducer } from '@wip/store/reducers';
import { CommunityDataRowState } from '@wip/store/states';
import { OrganizationProjectModuleReducer } from '@wip/store/reducers';
import { OrganizationProjectModuleState } from '@wip/store/states';
import { OrganizationRiskReducer } from '@wip/store/reducers';
import { OrganizationRiskState } from '@wip/store/states';
import { OrganizationUserProfilReducer } from '@wip/store/reducers';
import { OrganizationUserProfilState } from '@wip/store/states';
import { OrganizationSubFamilyReducer } from '@wip/store/reducers';
import { OrganizationSubFamilyState } from '@wip/store/states';
import { OrganizationSectionReducer } from '@wip/store/reducers';
import { OrganizationSectionState } from '@wip/store/states';
import { OrganizationUserReducer } from '@wip/store/reducers';
import { OrganizationUserState } from '@wip/store/states';
import { OrganizationElementReducer } from '@wip/store/reducers';
import { OrganizationElementState } from '@wip/store/states';
import { ProfilReducer } from '@wip/store/reducers';
import { ProfilState } from '@wip/store/states';
import { ProjectElementReducer } from '@wip/store/reducers';
import { ProjectElementState } from '@wip/store/states';
import { RightReducer } from '@wip/store/reducers';
import { RightState } from '@wip/store/states';
import { RightProfilReducer } from '@wip/store/reducers';
import { RightProfilState } from '@wip/store/states';
import { SectionReducer } from '@wip/store/reducers';
import { SectionState } from '@wip/store/states';
import { TimelineElementReducer } from '@wip/store/reducers';
import { TimelineElementState } from '@wip/store/states';
import { TodoReducer } from '@wip/store/reducers';
import { TodoState } from '@wip/store/states';
import { TodoElementReducer } from '@wip/store/reducers';
import { TodoElementState } from '@wip/store/states';
import { UserReducer } from '@wip/store/reducers';
import { UserState } from '@wip/store/states';
import { UserGroupReducer } from '@wip/store/reducers';
import { UserGroupState } from '@wip/store/states';
import { UserProfilRightReducer } from '@wip/store/reducers';
import { UserProfilRightState } from '@wip/store/states';
import { UserRightReducer } from '@wip/store/reducers';
import { UserRightState } from '@wip/store/states';
import { UserDeviceReducer } from '@wip/store/reducers';
import { UserDeviceState } from '@wip/store/states';
import { UserBoardStateReducer } from '@wip/store/reducers';
import { UserBoardStateState } from '@wip/store/states';
import { UserHistoryReducer } from '@wip/store/reducers';
import { UserHistoryState } from '@wip/store/states';
import { CommunityUserProfilReducer } from '@wip/store/reducers';
import { CommunityUserProfilState } from '@wip/store/states';
import { ValeurReducer } from '@wip/store/reducers';
import { ValeurState } from '@wip/store/states';

export interface GeneratedAppState {
  community: CommunityState.IState;
  communityCaracteristic: CommunityCaracteristicState.IState;
  communityKpi: CommunityKpiState.IState;
  childrenElement: ChildrenElementState.IState;
  communityMilestone: CommunityMilestoneState.IState;
  communityMilestoneFamily: CommunityMilestoneFamilyState.IState;
  communityRisk: CommunityRiskState.IState;
  communitySubFamily: CommunitySubFamilyState.IState;
  communityGroup: CommunityGroupState.IState;
  communityModule: CommunityModuleState.IState;
  communityUser: CommunityUserState.IState;
  folder: FolderState.IState;
  folderElement: FolderElementState.IState;
  element: ElementState.IState;
  elementCash: ElementCashState.IState;
  elementLibrary: ElementLibraryState.IState;
  ganttLink: GanttLinkState.IState;
  group: GroupState.IState;
  meeting: MeetingState.IState;
  meetingElement: MeetingElementState.IState;
  meetingUser: MeetingUserState.IState;
  notesHistory: NotesHistoryState.IState;
  projectModule: ProjectModuleState.IState;
  organizationAzure: OrganizationAzureState.IState;
  organizationCaracteristic: OrganizationCaracteristicState.IState;
  organizationCaracteristicColumn: OrganizationCaracteristicColumnState.IState;
  organizationCaracteristicRow: OrganizationCaracteristicRowState.IState;
  organizationCaracteristicValue: OrganizationCaracteristicValueState.IState;
  organizationMilestone: OrganizationMilestoneState.IState;
  organizationStep: OrganizationStepState.IState;
  organization: OrganizationState.IState;
  organizationFamily: OrganizationFamilyState.IState;
  organizationKpi: OrganizationKpiState.IState;
  organizationMilestoneFamily: OrganizationMilestoneFamilyState.IState;
  organizationMilestoneAssociation: OrganizationMilestoneAssociationState.IState;
  organizationDataColumn: OrganizationDataColumnState.IState;
  organizationDataColumnValue: OrganizationDataColumnValueState.IState;
  communityDataRow: CommunityDataRowState.IState;
  organizationProjectModule: OrganizationProjectModuleState.IState;
  organizationRisk: OrganizationRiskState.IState;
  organizationUserProfil: OrganizationUserProfilState.IState;
  organizationSubFamily: OrganizationSubFamilyState.IState;
  organizationSection: OrganizationSectionState.IState;
  organizationUser: OrganizationUserState.IState;
  organizationElement: OrganizationElementState.IState;
  profil: ProfilState.IState;
  projectElement: ProjectElementState.IState;
  right: RightState.IState;
  rightProfil: RightProfilState.IState;
  section: SectionState.IState;
  timelineElement: TimelineElementState.IState;
  todo: TodoState.IState;
  todoElement: TodoElementState.IState;
  user: UserState.IState;
  userGroup: UserGroupState.IState;
  userProfilRight: UserProfilRightState.IState;
  userRight: UserRightState.IState;
  userDevice: UserDeviceState.IState;
  userBoardState: UserBoardStateState.IState;
  userHistory: UserHistoryState.IState;
  communityUserProfil: CommunityUserProfilState.IState;
  valeur: ValeurState.IState;
}

export const generatedAppState = {
  community: CommunityReducer.reducer,
  communityCaracteristic: CommunityCaracteristicReducer.reducer,
  communityKpi: CommunityKpiReducer.reducer,
  childrenElement: ChildrenElementReducer.reducer,
  communityMilestone: CommunityMilestoneReducer.reducer,
  communityMilestoneFamily: CommunityMilestoneFamilyReducer.reducer,
  communityRisk: CommunityRiskReducer.reducer,
  communitySubFamily: CommunitySubFamilyReducer.reducer,
  communityGroup: CommunityGroupReducer.reducer,
  communityModule: CommunityModuleReducer.reducer,
  communityUser: CommunityUserReducer.reducer,
  folder: FolderReducer.reducer,
  folderElement: FolderElementReducer.reducer,
  element: ElementReducer.reducer,
  elementCash: ElementCashReducer.reducer,
  elementLibrary: ElementLibraryReducer.reducer,
  ganttLink: GanttLinkReducer.reducer,
  group: GroupReducer.reducer,
  meeting: MeetingReducer.reducer,
  meetingElement: MeetingElementReducer.reducer,
  meetingUser: MeetingUserReducer.reducer,
  notesHistory: NotesHistoryReducer.reducer,
  projectModule: ProjectModuleReducer.reducer,
  organizationAzure: OrganizationAzureReducer.reducer,
  organizationCaracteristic: OrganizationCaracteristicReducer.reducer,
  organizationCaracteristicColumn: OrganizationCaracteristicColumnReducer.reducer,
  organizationCaracteristicRow: OrganizationCaracteristicRowReducer.reducer,
  organizationCaracteristicValue: OrganizationCaracteristicValueReducer.reducer,
  organizationMilestone: OrganizationMilestoneReducer.reducer,
  organizationStep: OrganizationStepReducer.reducer,
  organization: OrganizationReducer.reducer,
  organizationFamily: OrganizationFamilyReducer.reducer,
  organizationKpi: OrganizationKpiReducer.reducer,
  organizationMilestoneFamily: OrganizationMilestoneFamilyReducer.reducer,
  organizationMilestoneAssociation: OrganizationMilestoneAssociationReducer.reducer,
  organizationDataColumn: OrganizationDataColumnReducer.reducer,
  organizationDataColumnValue: OrganizationDataColumnValueReducer.reducer,
  communityDataRow: CommunityDataRowReducer.reducer,
  organizationProjectModule: OrganizationProjectModuleReducer.reducer,
  organizationRisk: OrganizationRiskReducer.reducer,
  organizationUserProfil: OrganizationUserProfilReducer.reducer,
  organizationSubFamily: OrganizationSubFamilyReducer.reducer,
  organizationSection: OrganizationSectionReducer.reducer,
  organizationUser: OrganizationUserReducer.reducer,
  organizationElement: OrganizationElementReducer.reducer,
  profil: ProfilReducer.reducer,
  projectElement: ProjectElementReducer.reducer,
  right: RightReducer.reducer,
  rightProfil: RightProfilReducer.reducer,
  section: SectionReducer.reducer,
  timelineElement: TimelineElementReducer.reducer,
  todo: TodoReducer.reducer,
  todoElement: TodoElementReducer.reducer,
  user: UserReducer.reducer,
  userGroup: UserGroupReducer.reducer,
  userProfilRight: UserProfilRightReducer.reducer,
  userRight: UserRightReducer.reducer,
  userDevice: UserDeviceReducer.reducer,
  userBoardState: UserBoardStateReducer.reducer,
  userHistory: UserHistoryReducer.reducer,
  communityUserProfil: CommunityUserProfilReducer.reducer,
  valeur: ValeurReducer.reducer,
};
