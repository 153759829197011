import { RepositoryService } from '@wip/services/repository';
import { OrganizationMilestoneFamily } from '@api/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganizationMilestoneFamilyApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationMilestoneFamilys(params?: any): Observable<OrganizationMilestoneFamily[]> {
    return this.repo.getData<OrganizationMilestoneFamily[]>('organization-milestone-family', params);
  }

  public getOrganizationMilestoneFamily(params: {
    idOrganizationMilestoneFamily: number;
    params?: any;
  }): Observable<OrganizationMilestoneFamily> {
    return this.repo.getData<OrganizationMilestoneFamily>(
      'organization-milestone-family/' + params.idOrganizationMilestoneFamily,
      params.params
    );
  }

  public addOrganizationMilestoneFamily(
    organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>
  ): Observable<OrganizationMilestoneFamily> {
    return this.repo.create<OrganizationMilestoneFamily>('organization-milestone-family', organizationMilestoneFamily);
  }

  public updateOrganizationMilestoneFamily(
    organizationMilestoneFamily: Partial<OrganizationMilestoneFamily>
  ): Observable<OrganizationMilestoneFamily> {
    return this.repo.update('organization-milestone-family', organizationMilestoneFamily);
  }

  public deleteOrganizationMilestoneFamily(idOrganizationMilestoneFamily: number): Observable<number> {
    return this.repo.delete('organization-milestone-family/' + +idOrganizationMilestoneFamily);
  }
}
