import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import dayjs from 'dayjs';
@Component({
  selector: 'app-time-picker-dialog',
  templateUrl: './time-picker-dialog.component.html',
  styleUrls: ['./time-picker-dialog.component.scss']
})
export class TimePickerDialogComponent implements OnInit {
  public timeInputGroup: FormGroup;
  @ViewChild('inputElement', { static: true }) private inputElement: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<TimePickerDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const initDate =
      dayjs(this.data.date).format('HH:mm') === '00:00' ? '08:00' : dayjs(this.data.date).format('HH:mm');

    this.timeInputGroup = this.formBuilder.group({
      time: [initDate]
    });

    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    });
  }

  cancel(): void {
    this.dialogRef.close(this.data.date);
  }

  none(): void {
    let dateString = dayjs(this.data.date).format('YYYY-MM-DD HH:mm');
    // On enlève l'ancienne heure
    dateString = dateString.slice(0, dateString.length - 5);
    // On ajout la nouvelle heure
    dateString += '00:00';

    this.dialogRef.close(dayjs(dateString).toDate());
  }

  validate(): void {
    if (this.timeInputGroup.get('time').value) {
      let dateString = dayjs(this.data.date).format('YYYY-MM-DD HH:mm');
      // On enlève l'ancienne heure
      dateString = dateString.slice(0, dateString.length - 5);
      // On ajout la nouvelle heure
      dateString += this.timeInputGroup.get('time').value;
      this.dialogRef.close(dayjs(dateString).toDate());
    } else {
      this.none();
    }
  }
}
