import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as CommunityGroupAction from '@wip/store/actions';
import * as AppState from '@wip/store/configs/reducers';
import { catchApiActions } from 'libs/wip/store/utils/src/lib/http.util';
import { Observable } from 'rxjs';
import { GeneratedCommunityGroupService } from './community-group-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityGroupService extends GeneratedCommunityGroupService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public deleteOneCommunityGroupAndCommunityUsersAssociated(
    idCommunity: number,
    idGroup: number,
    getResult?: boolean
  ): Observable<any> | void {
    this.store$.dispatch(
      CommunityGroupAction.deleteOneCommunityGroupAndCommunityUsersAssociated({ idCommunity, idGroup })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CommunityGroupAction.CommunityGroupGeneratedActions.deleteOneCommunityGroupSuccess,
        CommunityGroupAction.CommunityGroupGeneratedActions.communityGroupsFailure
      );
    }
  }
}
