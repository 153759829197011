import { createEntityAdapter } from '@ngrx/entity';
import { OrganizationCaracteristicValueEntityState } from '@api/api-interfaces';
import { CustomEntityState } from '@wip/store/configs/states';

export type IState = CustomEntityState<OrganizationCaracteristicValueEntityState>;

export const adapter = createEntityAdapter<OrganizationCaracteristicValueEntityState>({
  selectId: o => o.idOrganizationCaracteristicValue
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationCaracteristicValueFeatureKey = 'organizationCaracteristicValue';
