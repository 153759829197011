import { OrganizationStepState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organizationStepReducersGeneratedFunctions } from './organization-step-generated.reducer';

const organizationStepReducersFunctions = [...organizationStepReducersGeneratedFunctions];

const organizationStepReducer = createReducer(OrganizationStepState.initialState, ...organizationStepReducersFunctions);

export function reducer(state: OrganizationStepState.IState | undefined, action: Action) {
  return organizationStepReducer(state, action);
}
