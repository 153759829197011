import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { OrganizationKpiGeneratedActions } from '@wip/store/actions';
import { OrganizationKpiState } from '@wip/store/states';
import { Dictionary } from '@ngrx/entity';
import { OrganizationKpiEntityState } from '@api/api-interfaces';

export const organizationKpiReducersGeneratedFunctions: ReducerTypes<
  OrganizationKpiState.IState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationKpiGeneratedActions.getOneOrganizationKpi, (state: OrganizationKpiState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationKpiGeneratedActions.getManyOrganizationKpis, (state: OrganizationKpiState.IState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationKpiGeneratedActions.upsertOneOrganizationKpi, (state: OrganizationKpiState.IState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationKpiGeneratedActions.upsertManyOrganizationKpisSuccess,
    (state: OrganizationKpiState.IState, { organizationKpis }) =>
      OrganizationKpiState.adapter.upsertMany(organizationKpis, setLoadingsState(state, false))
  ),
  on(OrganizationKpiGeneratedActions.deleteOneOrganizationKpi, (state: OrganizationKpiState.IState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationKpiGeneratedActions.deleteOneOrganizationKpiSuccess,
    (state: OrganizationKpiState.IState, { idOrganizationKpi }) =>
      OrganizationKpiState.adapter.removeOne(idOrganizationKpi, setLoadingsState(state, false))
  ),
  on(OrganizationKpiGeneratedActions.clearActivesOrganizationKpis, (state: OrganizationKpiState.IState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationKpiGeneratedActions.addManyActivesOrganizationKpis,
    (state: OrganizationKpiState.IState, { idOrganizationKpis }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationKpis)
    })
  ),
  on(
    OrganizationKpiGeneratedActions.deleteOneActiveOrganizationKpi,
    (state: OrganizationKpiState.IState, { idOrganizationKpi }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationKpi)
    })
  ),

  on(OrganizationKpiGeneratedActions.clearOrganizationKpis, () => OrganizationKpiState.initialState),

  on(
    OrganizationKpiGeneratedActions.addManyCommunityKpiSuccess,
    (state: OrganizationKpiState.IState, { idOrganizationKpi, idCommunityKpis }) => {
      if (!state.entities[idOrganizationKpi]) {
        return state;
      }
      const communityKpis = (state.entities[idOrganizationKpi]?.communityKpis as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationKpi]: {
            ...state.entities[idOrganizationKpi],
            communityKpis: communityKpis.concat(idCommunityKpis.filter(id => communityKpis.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationKpiGeneratedActions.deleteManyCommunityKpiSuccess,
    (state: OrganizationKpiState.IState, { idCommunityKpis, idOrganizationKpis }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationKpis.reduce((entities, idOrganizationKpi) => {
            if (!state.entities[idOrganizationKpi]?.communityKpis) {
              return entities;
            }
            entities[idOrganizationKpi] = {
              ...state.entities[idOrganizationKpi],
              communityKpis: (state.entities[idOrganizationKpi]?.communityKpis as number[])?.filter(
                (idCommunityKpi: number) => !idCommunityKpis.some((id: number) => id === idCommunityKpi)
              )
            } as OrganizationKpiEntityState;
            return entities;
          }, {} as Dictionary<OrganizationKpiEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationKpiGeneratedActions.addOrganizationSuccess,
    (state: OrganizationKpiState.IState, { idOrganizationKpi, idOrganization }) => {
      if (!state.entities[idOrganizationKpi]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationKpi]: {
            ...state.entities[idOrganizationKpi],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationKpiGeneratedActions.deleteManyOrganizationSuccess,
    (state: OrganizationKpiState.IState, { idOrganizations, idOrganizationKpis }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationKpis.reduce((entities, idOrganizationKpi) => {
            if (!state.entities[idOrganizationKpi]?.organization) {
              return entities;
            }
            entities[idOrganizationKpi] = {
              ...state.entities[idOrganizationKpi],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationKpi]?.organization
              )
                ? undefined
                : state.entities[idOrganizationKpi]?.organization
            } as OrganizationKpiEntityState;
            return entities;
          }, {} as Dictionary<OrganizationKpiEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationKpiGeneratedActions.addOrganizationFamilySuccess,
    (state: OrganizationKpiState.IState, { idOrganizationKpi, idOrganizationFamily }) => {
      if (!state.entities[idOrganizationKpi]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationKpi]: {
            ...state.entities[idOrganizationKpi],
            organizationFamily: idOrganizationFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationKpiGeneratedActions.deleteManyOrganizationFamilySuccess,
    (state: OrganizationKpiState.IState, { idOrganizationFamilys, idOrganizationKpis }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationKpis.reduce((entities, idOrganizationKpi) => {
            if (!state.entities[idOrganizationKpi]?.organizationFamily) {
              return entities;
            }
            entities[idOrganizationKpi] = {
              ...state.entities[idOrganizationKpi],
              organizationFamily: idOrganizationFamilys.some(
                (idOrganizationFamily: number) =>
                  idOrganizationFamily === state.entities[idOrganizationKpi]?.organizationFamily
              )
                ? undefined
                : state.entities[idOrganizationKpi]?.organizationFamily
            } as OrganizationKpiEntityState;
            return entities;
          }, {} as Dictionary<OrganizationKpiEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationKpiState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationKpiState.IState {
  return { ...state, isLoaded, isLoading };
}
