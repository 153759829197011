import { FolderElementState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { folderElementReducersGeneratedFunctions } from './folder-element-generated.reducer';

const folderElementReducersFunctions = [...folderElementReducersGeneratedFunctions];

const folderElementReducer = createReducer(FolderElementState.initialState, ...folderElementReducersFunctions);

export function reducer(state: FolderElementState.IState | undefined, action: Action) {
  return folderElementReducer(state, action);
}
