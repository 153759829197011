import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RightProfil, RightProfilEntityState } from '@api/api-interfaces';
import { RightProfilRelationsIds } from '@wip/store/ids-interfaces';

export const RightProfilGeneratedActions = createActionGroup({
  source: 'Right Profil Generated',
  events: {
    'Get One Right Profil': props<{ idRightProfil: number; params?: any }>(),
    'Get Many Right Profils': props<{ params: any }>(),
    'Add Many Actives Right Profils': props<{ idRightProfils: number[] }>(),
    'Delete One Active Right Profil': props<{ idRightProfil: number }>(),
    'Clear Actives Right Profils': emptyProps(),
    'Upsert One Right Profil': props<{ rightProfil: Partial<RightProfil>; ids?: RightProfilRelationsIds }>(),
    'Upsert Many Right Profils': props<{ rightProfils: Partial<RightProfil>[]; ids?: RightProfilRelationsIds }>(),
    'Upsert Many Right Profils Success': props<{ rightProfils: RightProfilEntityState[] }>(),
    'Delete One Right Profil': props<{ idRightProfil: number }>(),
    'Delete One Right Profil Success': props<{ idRightProfil: number }>(),
    'Normalize Many Right Profils After Upsert': props<{ rightProfils: RightProfilEntityState[] }>(),
    'Right Profils Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Right Profils': emptyProps(),
    'Add Right Success': props<{ idRightProfil: number; idRight: number }>(),
    'Delete Many Right Success': props<{ idRights: number[]; idRightProfils: number[] }>(),
    'Add Profil Success': props<{ idRightProfil: number; idProfil: number }>(),
    'Delete Many Profil Success': props<{ idProfils: number[]; idRightProfils: number[] }>()
  }
});
