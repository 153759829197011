export enum RightFamilyEnum {
  navigation = 'Accès navigation',
  synthesis = 'Synthèse',
  planning = 'Planning',
  team = 'Équipe',
  caracteristics = 'Caractéristiques',
  documents = 'Documents',
  meetings = 'Réunions',
  notes = 'Notes',
  todos = 'Tâches'
}
