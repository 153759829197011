import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@wip/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TodoElementApiService } from '@wip/store/api-services';
import { GeneratedTodoElementEffects } from './todo-element-generated.effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as todoElementActions from '@wip/store/actions';
import { TodoElement } from '@api/api-interfaces';

@Injectable()
export class TodoElementEffects extends GeneratedTodoElementEffects {
  constructor(actions$: Actions, todoElementApiService: TodoElementApiService, store$: Store<AppState>) {
    super(actions$, todoElementApiService, store$);
  }

  getTodoElementsByIdCommunity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(todoElementActions.getTodoElementsByIdCommunity),
      switchMap(params =>
        this.todoElementApiService.getTodoElementsByIdCommunity(params).pipe(
          map((todoElements: TodoElement[]) => {
            return todoElementActions.TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert({
              todoElements
            });
          }),
          catchError(error => of(todoElementActions.TodoElementGeneratedActions.todoElementsFailure({ error })))
        )
      )
    );
  });

  getTodoElementsForSynthesisDashboard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(todoElementActions.getTodoElementsForSynthesisDashboard),
      switchMap(params =>
        this.todoElementApiService.getTodoElementsForSynthesisDashboard(params.idCommunity).pipe(
          map((todoElements: TodoElement[]) => {
            return todoElementActions.TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert({
              todoElements
            });
          }),
          catchError(error => of(todoElementActions.TodoElementGeneratedActions.todoElementsFailure({ error })))
        )
      )
    );
  });

  createTodoElement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(todoElementActions.createTodoElement),
      switchMap(params =>
        this.todoElementApiService.createTodoElement(params.todoElementForm).pipe(
          map((todoElements: TodoElement[]) => {
            return todoElementActions.TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert({
              todoElements
            });
          }),
          catchError(error => of(todoElementActions.TodoElementGeneratedActions.todoElementsFailure({ error })))
        )
      )
    );
  });

  validateActionFormForTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(todoElementActions.validateActionFormForTodo),
      switchMap(controls => {
        return this.todoElementApiService.validateActionForm(controls).pipe(
          map((todoElementsReturned: TodoElement) => {
            return todoElementActions.TodoElementGeneratedActions.normalizeManyTodoElementsAfterUpsert({
              todoElements: [todoElementsReturned]
            });
          }),
          catchError(error => of(todoElementActions.TodoElementGeneratedActions.todoElementsFailure({ error })))
        );
      })
    );
  });
}
