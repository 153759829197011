export enum ModuleList {
  timeline = 'timeline',
  todo = 'todo',
  meeting = 'meeting',
  notification = 'notification',
  project = 'project',
  document = 'document',
  edit = 'edit',
  folder = 'folder',
  community = 'community',
  organization = 'organization',
  reaction = 'reaction',
  synthesis = 'synthesis',
  kanban = 'kanban',
  charts = 'charts',
}
