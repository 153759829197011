import { MeetingState } from '@wip/store/states';
import { Action, createReducer } from '@ngrx/store';
import { meetingReducersGeneratedFunctions } from './meeting-generated.reducer';

const meetingReducersFunctions = [...meetingReducersGeneratedFunctions];

const meetingReducer = createReducer(MeetingState.initialState, ...meetingReducersFunctions);

export function reducer(state: MeetingState.IState | undefined, action: Action) {
  return meetingReducer(state, action);
}
