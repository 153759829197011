import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { GoogleMapsService } from './googleMaps.service';
import { Community } from '@api/api-interfaces';
import { environment } from '@wip/environments';

@Component({
  selector: 'wip-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnChanges {
  @ViewChild('gmap') public gmap: ElementRef;
  private map: google.maps.Map;
  private markers: google.maps.Marker[];
  private mapInfoWindow: google.maps.InfoWindow;
  private cluster: MarkerClusterer;

  @Input() private projects: Community[];
  @Input() public selectedProjectId: number;
  @Input() public searchString: string;

  @Output() public selectedProjectChange: EventEmitter<number> = new EventEmitter();
  @Output() public selectProjectsInBounds: EventEmitter<number[]> = new EventEmitter();

  private markerOptionsSelected: google.maps.MarkerOptions;
  private markerOptions: google.maps.MarkerOptions;
  private lastSelectedMarker: google.maps.Marker;

  public googleLoaded = false;

  constructor(public googleMapsService: GoogleMapsService) {
    this.waitGoogleMap();
  }

  private waitGoogleMap(): void {
    this.googleLoaded = this.googleMapsService.isApiLoaded();
    if (this.googleLoaded === false) {
      setTimeout(this.waitGoogleMap.bind(this), 200);
    } else {
      this.initMarkersOptions();
      this.initMap();
    }
  }

  private initMap(): void {
    if (this.gmap === undefined) {
      setTimeout(this.initMap.bind(this), 200);
    } else {
      this.map = new google.maps.Map(this.gmap.nativeElement, {
        mapTypeControl: false,
        streetViewControl: false,
        center: new google.maps.LatLng(46.71109, 1.7191036),
        zoom: 7
      });
      this.createAndFitMarkers();
    }
  }

  private createAndFitMarkers(): void {
    this.markers = [];
    this.mapInfoWindow = new google.maps.InfoWindow({ content: '', disableAutoPan: true });
    const bounds = new google.maps.LatLngBounds();
    this.projects.forEach(project => {
      if (project.latitude && project.longitude && this.isResidenceFiltered(project)) {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(+project.latitude, +project.longitude),
          map: this.map,
          title: project.idCommunity.toString(),
          ...(project.idCommunity === this.selectedProjectId ? this.markerOptionsSelected : this.markerOptions)
        });
        (marker as any).nom = project.nom;

        marker.addListener('click', () => {
          if (project.idCommunity !== this.selectedProjectId) {
            this.selectedProjectChange.emit(project.idCommunity);
          }

          this.mapInfoWindow.setContent(this.getInfoContent(project.idCommunity));
          this.mapInfoWindow.open(this.map, marker);
          this.map.panTo(marker.getPosition());
          this.setSelectedMarker(marker);
        });
        this.markers.push(marker);
        bounds.extend(marker.getPosition());
      }
    });
    if (this.map) {
      google.maps.event.addListener(this.map, 'bounds_changed', () => {
        const bounds = this.map.getBounds();
        const idsInBounds = [];
        for (const marker of this.markers) {
          if (bounds.contains(marker.getPosition())) {
            idsInBounds.push(+marker.getTitle());
          }
          this.selectProjectsInBounds.emit(idsInBounds);
        }
      });
      this.cluster?.clearMarkers();
      this.cluster = new MarkerClusterer({
        map: this.map,
        markers: this.markers,
        onClusterClick: (_event, cluster, _map): void => {
          this.showClusterInfoWindow(cluster);
        }
      });
      if (!bounds.isEmpty() && this.map) {
        this.map?.fitBounds(bounds);
      }
    }
  }

  private setSelectedMarker(marker: google.maps.Marker): void {
    if (this.lastSelectedMarker) {
      this.lastSelectedMarker.setIcon(this.markerOptions.icon);
      this.lastSelectedMarker.setZIndex(this.markerOptions.zIndex);
    }
    marker.setIcon(this.markerOptionsSelected.icon);
    marker.setZIndex(this.markerOptionsSelected.zIndex);
    this.lastSelectedMarker = marker;
  }

  private initMarkersOptions(): void {
    this.markerOptionsSelected = {
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 4,
        strokeColor: 'Red',
        strokeWeight: 5,
        fillColor: 'DarkRed',
        fillOpacity: 1
      },
      zIndex: 9
    };
    this.markerOptions = {
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 4,
        strokeColor: '#3434FA',
        strokeWeight: 5,
        fillColor: 'DarkBlue',
        fillOpacity: 1
      },
      zIndex: 0
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.googleLoaded && (changes.projects?.currentValue || changes.searchString)) {
      this.createAndFitMarkers();
    }

    if (this.markers !== undefined && changes.selectedProjectId?.currentValue) {
      const stringId = this.selectedProjectId.toString();

      //TODO: find a better way to do this
      if (this.mapInfoWindow.getContent() !== this.getInfoContent(this.selectedProjectId)) {
        const markerToOpen = this.markers.find(marker => marker.getTitle() === stringId);
        if (markerToOpen) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(markerToOpen.getPosition());
          // this.map?.fitBounds(bounds);
          // this.map.setZoom(this.map.getZoom() - 5);
          this.mapInfoWindow.setPosition(markerToOpen.getPosition());
          this.mapInfoWindow.setContent(this.getInfoContent(this.selectedProjectId));
          this.mapInfoWindow.open(this.map, markerToOpen);
          this.setSelectedMarker(markerToOpen);
        }
      }
    }
  }

  private isResidenceFiltered(project: Community): boolean {
    if (!this.searchString) {
      return true;
    }
    return (
      project.nom?.toLowerCase().includes(this.searchString.toLowerCase()) ||
      project.codePostal?.toLowerCase().includes(this.searchString.toLowerCase()) ||
      project.commune?.toLowerCase().includes(this.searchString.toLowerCase()) ||
      project.identifiant?.toLowerCase().includes(this.searchString.toLowerCase())
    );
  }

  public getInfoContent(idCommunity): string {
    const project = this.projects.find(it => it.idCommunity === idCommunity);
    return project
      ? `<b>${project.nom}</b><br>` +
          `${project.adresse}<br>` +
          `${project.codePostal} ${project.commune}<br>` +
          `<a target="_blank" href="${environment.frontUrl}/#/app/project/${idCommunity}">Accéder au projet</a>`
      : '';
  }

  private showClusterInfoWindow(cluster) {
    const markers = cluster.markers.slice(0, 8);

    let content = '<div>';
    markers.forEach(marker => {
      content += `<div>${marker.nom}</div>`;
    });
    content += '</div>';
    if (cluster.markers.length > 8) {
      content += `<div>...${cluster.markers.length - 8} autre(s) projet(s)</div>`;
    }
    content += '<a onclick="zoomToMarkers()"';
    content += 'style="color: blue;text-decoration: underline;cursor: pointer;display: block; margin-top: 10px;"';
    content += ' >Zoomer sur ces projets</a>';
    this.mapInfoWindow.setContent(content);
    this.mapInfoWindow.setPosition(cluster._position);
    this.mapInfoWindow.open(this.map);

    window['zoomToMarkers'] = () => {
      const bounds = new google.maps.LatLngBounds();
      cluster.markers.forEach(marker => {
        bounds.extend(marker.getPosition());
      });
      this.map.fitBounds(bounds);
      // this.map.setZoom(Math.min(this.map.getZoom()));
      this.mapInfoWindow.close();
    };
  }
}
