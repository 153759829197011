import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CommunityDataRow, CommunityDataRowEntityState } from '@api/api-interfaces';
import { CommunityDataRowRelationsIds } from '@wip/store/ids-interfaces';

export const CommunityDataRowGeneratedActions = createActionGroup({
  source: 'Community Data Row Generated',
  events: {
    'Get One Community Data Row': props<{ idCommunityDataRow: number; params?: any }>(),
    'Get Many Community Data Rows': props<{ params: any }>(),
    'Add Many Actives Community Data Rows': props<{ idCommunityDataRows: number[] }>(),
    'Delete One Active Community Data Row': props<{ idCommunityDataRow: number }>(),
    'Clear Actives Community Data Rows': emptyProps(),
    'Upsert One Community Data Row': props<{
      communityDataRow: Partial<CommunityDataRow>;
      ids?: CommunityDataRowRelationsIds;
    }>(),
    'Upsert Many Community Data Rows': props<{
      communityDataRows: Partial<CommunityDataRow>[];
      ids?: CommunityDataRowRelationsIds;
    }>(),
    'Upsert Many Community Data Rows Success': props<{ communityDataRows: CommunityDataRowEntityState[] }>(),
    'Delete One Community Data Row': props<{ idCommunityDataRow: number }>(),
    'Delete One Community Data Row Success': props<{ idCommunityDataRow: number }>(),
    'Normalize Many Community Data Rows After Upsert': props<{ communityDataRows: CommunityDataRowEntityState[] }>(),
    'Community Data Rows Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Community Data Rows': emptyProps(),
    'Add Many Valeur Success': props<{ idCommunityDataRow: number; idValeurs: number[] }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idCommunityDataRows: number[] }>(),
    'Add Community Success': props<{ idCommunityDataRow: number; idCommunity: number }>(),
    'Delete Many Community Success': props<{ idCommunities: number[]; idCommunityDataRows: number[] }>()
  }
});
