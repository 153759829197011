import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationFamily, OrganizationFamilyEntityState } from '@api/api-interfaces';
import { OrganizationSubFamily, OrganizationSubFamilyEntityState } from '@api/api-interfaces';
import { Community, CommunityEntityState } from '@api/api-interfaces';
import { OrganizationStep, OrganizationStepEntityState } from '@api/api-interfaces';
import { OrganizationKpi, OrganizationKpiEntityState } from '@api/api-interfaces';
import { OrganizationDataColumn, OrganizationDataColumnEntityState } from '@api/api-interfaces';
import { OrganizationMilestoneFamily, OrganizationMilestoneFamilyEntityState } from '@api/api-interfaces';
import { OrganizationCaracteristic, OrganizationCaracteristicEntityState } from '@api/api-interfaces';
import { OrganizationMilestone, OrganizationMilestoneEntityState } from '@api/api-interfaces';
import { OrganizationRisk, OrganizationRiskEntityState } from '@api/api-interfaces';
import { OrganizationUserProfil, OrganizationUserProfilEntityState } from '@api/api-interfaces';
import { UserBoardState, UserBoardStateEntityState } from '@api/api-interfaces';
import { Organization, OrganizationEntityState } from '@api/api-interfaces';
import { findOrCreateSelector } from '@wip/services/ngrx-helper';
import { OrganizationFamilyState } from '@wip/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@wip/store/utils';

export const organizationFamilyRelations: string[] = [
  'organizationSubFamilys',
  'communities',
  'organizationSteps',
  'organizationKpis',
  'organizationDataColumns',
  'organizationMilestoneFamilys',
  'organizationCaracteristics',
  'organizationMilestones',
  'organizationRisks',
  'organizationUserProfils',
  'userBoardStates',
  'organizations'
];

export const { selectEntities, selectAll } = OrganizationFamilyState.adapter.getSelectors();

export const selectOrganizationFamilyState = createFeatureSelector<OrganizationFamilyState.IState>(
  OrganizationFamilyState.organizationFamilyFeatureKey
);

export const selectIsLoadedOrganizationFamily = createSelector(
  selectOrganizationFamilyState,
  (state: OrganizationFamilyState.IState) => state.isLoaded
);

export const selectIsLoadingOrganizationFamily = createSelector(
  selectOrganizationFamilyState,
  (state: OrganizationFamilyState.IState) => state.isLoading
);

export const selectIsReadyOrganizationFamily = createSelector(
  selectOrganizationFamilyState,
  (state: OrganizationFamilyState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationFamily = createSelector(
  selectOrganizationFamilyState,
  (state: OrganizationFamilyState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganizationFamilysEntities = createSelector(selectOrganizationFamilyState, selectEntities);

export const selectOrganizationFamilysArray = createSelector(selectOrganizationFamilyState, selectAll);

export const selectIdOrganizationFamilysActive = createSelector(
  selectOrganizationFamilyState,
  (state: OrganizationFamilyState.IState) => state.actives
);

const organizationFamilysInObject = (organizationFamilys: Dictionary<OrganizationFamilyEntityState>) => ({
  organizationFamilys
});

const selectOrganizationFamilysEntitiesDictionary = createSelector(
  selectOrganizationFamilysEntities,
  organizationFamilysInObject
);

const selectAllOrganizationFamilysObject = createSelector(selectOrganizationFamilysEntities, organizationFamilys => {
  return hydrateAll({ organizationFamilys });
});

const selectOneOrganizationFamilyDictionary = (idOrganizationFamily: number) =>
  createSelector(selectOrganizationFamilysEntities, organizationFamilys => ({
    organizationFamilys: { [idOrganizationFamily]: organizationFamilys[idOrganizationFamily] }
  }));

const selectOneOrganizationFamilyDictionaryWithoutChild = (idOrganizationFamily: number) =>
  createSelector(selectOrganizationFamilysEntities, organizationFamilys => ({
    organizationFamily: organizationFamilys[idOrganizationFamily]
  }));

const selectActiveOrganizationFamilysEntities = createSelector(
  selectIdOrganizationFamilysActive,
  selectOrganizationFamilysEntities,
  (actives: number[], organizationFamilys: Dictionary<OrganizationFamilyEntityState>) =>
    getOrganizationFamilysFromActives(actives, organizationFamilys)
);

function getOrganizationFamilysFromActives(
  actives: number[],
  organizationFamilys: Dictionary<OrganizationFamilyEntityState>
): Dictionary<OrganizationFamilyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationFamilys[idActive]) {
      acc[idActive] = organizationFamilys[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationFamilyEntityState>);
}

const selectAllOrganizationFamilysSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationFamilys(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationFamily>(
      schema,
      selectAllOrganizationFamilysSelectors,
      selectOrganizationFamilysEntitiesDictionary,
      getRelationSelectors,
      organizationFamilyRelations,
      hydrateAll,
      'organizationFamily'
    );
  } else {
    return selectAllOrganizationFamilysObject;
  }
}

export function selectAllOrganizationFamilysDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationFamilys'
): Selector {
  return createSelector(selectAllOrganizationFamilys(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationFamilyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationFamilys.length; i++) {
      res[customKey][result.organizationFamilys[i].idOrganizationFamily] = result.organizationFamilys[i];
    }
    return res;
  });
}

export function selectOneOrganizationFamily(schema: SelectSchema = {}, idOrganizationFamily: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationFamilyDictionary(idOrganizationFamily)];
    selectors.push(...getRelationSelectors(schema, organizationFamilyRelations, 'organizationFamily'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationFamilyDictionaryWithoutChild(idOrganizationFamily);
  }
}

export function selectActiveOrganizationFamilys(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationFamilysEntities, organizationFamilys => ({
      organizationFamilys
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationFamilyRelations, 'organizationFamily'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationFamilys: Dictionary<OrganizationFamilyEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationSubFamilys?: Dictionary<OrganizationSubFamilyEntityState>;
  communities?: Dictionary<CommunityEntityState>;
  organizationSteps?: Dictionary<OrganizationStepEntityState>;
  organizationKpis?: Dictionary<OrganizationKpiEntityState>;
  organizationDataColumns?: Dictionary<OrganizationDataColumnEntityState>;
  organizationMilestoneFamilys?: Dictionary<OrganizationMilestoneFamilyEntityState>;
  organizationCaracteristics?: Dictionary<OrganizationCaracteristicEntityState>;
  organizationMilestones?: Dictionary<OrganizationMilestoneEntityState>;
  organizationRisks?: Dictionary<OrganizationRiskEntityState>;
  organizationUserProfils?: Dictionary<OrganizationUserProfilEntityState>;
  userBoardStates?: Dictionary<UserBoardStateEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationFamilys: (OrganizationFamily | null)[] } {
  const {
    organizationFamilys,
    organizations,
    organizationSubFamilys,
    communities,
    organizationSteps,
    organizationKpis,
    organizationDataColumns,
    organizationMilestoneFamilys,
    organizationCaracteristics,
    organizationMilestones,
    organizationRisks,
    organizationUserProfils,
    userBoardStates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationFamilys: Object.keys(organizationFamilys).map(idOrganizationFamily =>
      hydrate(
        organizationFamilys[idOrganizationFamily] as OrganizationFamilyEntityState,
        organizations,
        organizationSubFamilys,
        communities,
        organizationSteps,
        organizationKpis,
        organizationDataColumns,
        organizationMilestoneFamilys,
        organizationCaracteristics,
        organizationMilestones,
        organizationRisks,
        organizationUserProfils,
        userBoardStates
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationFamily: OrganizationFamilyEntityState | null } {
  const {
    organizationFamilys,
    organizations,
    organizationSubFamilys,
    communities,
    organizationSteps,
    organizationKpis,
    organizationDataColumns,
    organizationMilestoneFamilys,
    organizationCaracteristics,
    organizationMilestones,
    organizationRisks,
    organizationUserProfils,
    userBoardStates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationFamily = Object.values(organizationFamilys)[0];
  return {
    organizationFamily: hydrate(
      organizationFamily as OrganizationFamilyEntityState,
      organizations,
      organizationSubFamilys,
      communities,
      organizationSteps,
      organizationKpis,
      organizationDataColumns,
      organizationMilestoneFamilys,
      organizationCaracteristics,
      organizationMilestones,
      organizationRisks,
      organizationUserProfils,
      userBoardStates
    )
  };
}

function hydrate(
  organizationFamily: OrganizationFamilyEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationSubFamilyEntities?: Dictionary<OrganizationSubFamilyEntityState>,
  communityEntities?: Dictionary<CommunityEntityState>,
  organizationStepEntities?: Dictionary<OrganizationStepEntityState>,
  organizationKpiEntities?: Dictionary<OrganizationKpiEntityState>,
  organizationDataColumnEntities?: Dictionary<OrganizationDataColumnEntityState>,
  organizationMilestoneFamilyEntities?: Dictionary<OrganizationMilestoneFamilyEntityState>,
  organizationCaracteristicEntities?: Dictionary<OrganizationCaracteristicEntityState>,
  organizationMilestoneEntities?: Dictionary<OrganizationMilestoneEntityState>,
  organizationRiskEntities?: Dictionary<OrganizationRiskEntityState>,
  organizationUserProfilEntities?: Dictionary<OrganizationUserProfilEntityState>,
  userBoardStateEntities?: Dictionary<UserBoardStateEntityState>
): OrganizationFamily | null {
  if (!organizationFamily) {
    return null;
  }

  const organizationFamilyHydrated: OrganizationFamilyEntityState = { ...organizationFamily };
  if (organizationEntities) {
    organizationFamilyHydrated.organization = organizationEntities[
      organizationFamily.organization as number
    ] as Organization;
  } else {
    delete organizationFamilyHydrated.organization;
  }

  if (organizationSubFamilyEntities) {
    organizationFamilyHydrated.organizationSubFamilys = (
      (organizationFamilyHydrated.organizationSubFamilys as number[]) || []
    ).map(id => organizationSubFamilyEntities[id]) as OrganizationSubFamily[];
  } else {
    delete organizationFamilyHydrated.organizationSubFamilys;
  }

  if (communityEntities) {
    organizationFamilyHydrated.communities = ((organizationFamilyHydrated.communities as number[]) || []).map(
      id => communityEntities[id]
    ) as Community[];
  } else {
    delete organizationFamilyHydrated.communities;
  }

  if (organizationStepEntities) {
    organizationFamilyHydrated.organizationSteps = (
      (organizationFamilyHydrated.organizationSteps as number[]) || []
    ).map(id => organizationStepEntities[id]) as OrganizationStep[];
  } else {
    delete organizationFamilyHydrated.organizationSteps;
  }

  if (organizationKpiEntities) {
    organizationFamilyHydrated.organizationKpis = ((organizationFamilyHydrated.organizationKpis as number[]) || []).map(
      id => organizationKpiEntities[id]
    ) as OrganizationKpi[];
  } else {
    delete organizationFamilyHydrated.organizationKpis;
  }

  if (organizationDataColumnEntities) {
    organizationFamilyHydrated.organizationDataColumns = (
      (organizationFamilyHydrated.organizationDataColumns as number[]) || []
    ).map(id => organizationDataColumnEntities[id]) as OrganizationDataColumn[];
  } else {
    delete organizationFamilyHydrated.organizationDataColumns;
  }

  if (organizationMilestoneFamilyEntities) {
    organizationFamilyHydrated.organizationMilestoneFamilys = (
      (organizationFamilyHydrated.organizationMilestoneFamilys as number[]) || []
    ).map(id => organizationMilestoneFamilyEntities[id]) as OrganizationMilestoneFamily[];
  } else {
    delete organizationFamilyHydrated.organizationMilestoneFamilys;
  }

  if (organizationCaracteristicEntities) {
    organizationFamilyHydrated.organizationCaracteristics = (
      (organizationFamilyHydrated.organizationCaracteristics as number[]) || []
    ).map(id => organizationCaracteristicEntities[id]) as OrganizationCaracteristic[];
  } else {
    delete organizationFamilyHydrated.organizationCaracteristics;
  }

  if (organizationMilestoneEntities) {
    organizationFamilyHydrated.organizationMilestones = (
      (organizationFamilyHydrated.organizationMilestones as number[]) || []
    ).map(id => organizationMilestoneEntities[id]) as OrganizationMilestone[];
  } else {
    delete organizationFamilyHydrated.organizationMilestones;
  }

  if (organizationRiskEntities) {
    organizationFamilyHydrated.organizationRisks = (
      (organizationFamilyHydrated.organizationRisks as number[]) || []
    ).map(id => organizationRiskEntities[id]) as OrganizationRisk[];
  } else {
    delete organizationFamilyHydrated.organizationRisks;
  }

  if (organizationUserProfilEntities) {
    organizationFamilyHydrated.organizationUserProfils = (
      (organizationFamilyHydrated.organizationUserProfils as number[]) || []
    ).map(id => organizationUserProfilEntities[id]) as OrganizationUserProfil[];
  } else {
    delete organizationFamilyHydrated.organizationUserProfils;
  }

  if (userBoardStateEntities) {
    organizationFamilyHydrated.userBoardStates = ((organizationFamilyHydrated.userBoardStates as number[]) || []).map(
      id => userBoardStateEntities[id]
    ) as UserBoardState[];
  } else {
    delete organizationFamilyHydrated.userBoardStates;
  }

  return organizationFamilyHydrated as OrganizationFamily;
}
