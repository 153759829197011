import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@wip/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@wip/store/configs/batched-actions';
import { CommunityUserProfil, CommunityUserProfilEntityState } from '@api/api-interfaces';
import { CommunityUserProfilApiService } from '@wip/store/api-services';
import { CommunityUserProfilGeneratedActions } from '@wip/store/actions';
import { getActionsToNormalizeCommunityUserProfil } from '@wip/store/configs/normalization';
import { CommunityUserProfilSelectors } from '@wip/store/selectors';
import { CommunityUserProfilRelationsIds } from '@wip/store/ids-interfaces';
import { CommunityGeneratedActions } from '@wip/store/actions';
import { UserGeneratedActions } from '@wip/store/actions';
import { OrganizationUserProfilGeneratedActions } from '@wip/store/actions';
import { ElementLibraryGeneratedActions } from '@wip/store/actions';
import { ElementLibrary } from '@api/api-interfaces';

export function getDefaultAddCommunityUserProfilActions(
  communityUserProfil: CommunityUserProfilEntityState,
  ids?: CommunityUserProfilRelationsIds
): Action[] {
  const actions: Action[] = [
    CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert({
      communityUserProfils: [communityUserProfil]
    })
  ];

  if (ids?.community) {
    actions.push(
      CommunityGeneratedActions.addManyCommunityUserProfilSuccess({
        idCommunity: ids.community,
        idCommunityUserProfils: [communityUserProfil.idCommunityUserProfil]
      })
    );
    actions.push(
      CommunityUserProfilGeneratedActions.addCommunitySuccess({
        idCommunityUserProfil: communityUserProfil.idCommunityUserProfil,
        idCommunity: ids.community
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyCommunityUserProfilSuccess({
        idUser: ids.user,
        idCommunityUserProfils: [communityUserProfil.idCommunityUserProfil]
      })
    );
    actions.push(
      CommunityUserProfilGeneratedActions.addUserSuccess({
        idCommunityUserProfil: communityUserProfil.idCommunityUserProfil,
        idUser: ids.user
      })
    );
  }

  if (ids?.organizationUserProfil) {
    actions.push(
      OrganizationUserProfilGeneratedActions.addManyCommunityUserProfilSuccess({
        idOrganizationUserProfil: ids.organizationUserProfil,
        idCommunityUserProfils: [communityUserProfil.idCommunityUserProfil]
      })
    );
    actions.push(
      CommunityUserProfilGeneratedActions.addOrganizationUserProfilSuccess({
        idCommunityUserProfil: communityUserProfil.idCommunityUserProfil,
        idOrganizationUserProfil: ids.organizationUserProfil
      })
    );
  }

  if (ids?.elementLibraries) {
    if (!Array.isArray(ids.elementLibraries)) {
      actions.push(
        ElementLibraryGeneratedActions.upsertOneElementLibrary({
          elementLibrary: {
            idCommunityUserProfil: communityUserProfil.idCommunityUserProfil,
            idElementLibrary: ids.elementLibraries as number
          } as ElementLibrary
        })
      );
      actions.push(
        CommunityUserProfilGeneratedActions.addManyElementLibrarySuccess({
          idCommunityUserProfil: communityUserProfil.idCommunityUserProfil,
          idElementLibraries: [ids.elementLibraries as number]
        })
      );
    } else {
      actions.push(
        ElementLibraryGeneratedActions.upsertManyElementLibraries({
          elementLibraries: (ids.elementLibraries as number[]).map((idElementLibrary: number) => ({
            idCommunityUserProfil: communityUserProfil.idCommunityUserProfil,
            idElementLibrary
          })) as ElementLibrary[]
        })
      );
      actions.push(
        CommunityUserProfilGeneratedActions.addManyElementLibrarySuccess({
          idCommunityUserProfil: communityUserProfil.idCommunityUserProfil,
          idElementLibraries: ids.elementLibraries as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCommunityUserProfilActions(
  communityUserProfil: CommunityUserProfilEntityState
): Action[] {
  const actions: Action[] = [
    CommunityUserProfilGeneratedActions.deleteOneCommunityUserProfilSuccess({
      idCommunityUserProfil: communityUserProfil.idCommunityUserProfil
    })
  ];

  if (communityUserProfil.community) {
    actions.push(
      CommunityGeneratedActions.deleteManyCommunityUserProfilSuccess({
        idCommunityUserProfils: [communityUserProfil.idCommunityUserProfil],
        idCommunities: [communityUserProfil.community as number]
      })
    );
  }

  if (communityUserProfil.user) {
    actions.push(
      UserGeneratedActions.deleteManyCommunityUserProfilSuccess({
        idCommunityUserProfils: [communityUserProfil.idCommunityUserProfil],
        idUsers: [communityUserProfil.user as number]
      })
    );
  }

  if (communityUserProfil.organizationUserProfil) {
    actions.push(
      OrganizationUserProfilGeneratedActions.deleteManyCommunityUserProfilSuccess({
        idCommunityUserProfils: [communityUserProfil.idCommunityUserProfil],
        idOrganizationUserProfils: [communityUserProfil.organizationUserProfil as number]
      })
    );
  }

  if (communityUserProfil.elementLibraries) {
    actions.push(
      ElementLibraryGeneratedActions.deleteManyCommunityUserProfilSuccess({
        idCommunityUserProfils: [communityUserProfil.idCommunityUserProfil],
        idElementLibraries: communityUserProfil.elementLibraries as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCommunityUserProfilEffects {
  constructor(
    protected actions$: Actions,
    protected communityUserProfilApiService: CommunityUserProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCommunityUserProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityUserProfilGeneratedActions.getManyCommunityUserProfils),
      switchMap(({ params }) =>
        this.communityUserProfilApiService.getCommunityUserProfils(params).pipe(
          map((communityUserProfils: CommunityUserProfil[]) => {
            return CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert({
              communityUserProfils
            });
          }),
          catchError(error => of(CommunityUserProfilGeneratedActions.communityUserProfilsFailure({ error })))
        )
      )
    );
  });

  getOneCommunityUserProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityUserProfilGeneratedActions.getOneCommunityUserProfil),
      switchMap(idCommunityUserProfil =>
        this.communityUserProfilApiService.getCommunityUserProfil(idCommunityUserProfil).pipe(
          map((communityUserProfil: CommunityUserProfil) => {
            return CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert({
              communityUserProfils: [communityUserProfil]
            });
          }),
          catchError(error => of(CommunityUserProfilGeneratedActions.communityUserProfilsFailure({ error })))
        )
      )
    );
  });

  upsertOneCommunityUserProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityUserProfilGeneratedActions.upsertOneCommunityUserProfil),
      concatMap(
        ({
          communityUserProfil,
          ids
        }: {
          communityUserProfil: Partial<CommunityUserProfil>;
          ids?: CommunityUserProfilRelationsIds;
        }) => {
          if (communityUserProfil.idCommunityUserProfil) {
            return this.communityUserProfilApiService.updateCommunityUserProfil(communityUserProfil).pipe(
              map((communityUserProfilReturned: CommunityUserProfil) => {
                return CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert({
                  communityUserProfils: [communityUserProfilReturned]
                });
              }),
              catchError(error => of(CommunityUserProfilGeneratedActions.communityUserProfilsFailure({ error })))
            );
          } else {
            return this.communityUserProfilApiService.addCommunityUserProfil(communityUserProfil).pipe(
              mergeMap((communityUserProfilReturned: CommunityUserProfil) =>
                getDefaultAddCommunityUserProfilActions(communityUserProfilReturned, ids)
              ),
              catchError(error => of(CommunityUserProfilGeneratedActions.communityUserProfilsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCommunityUserProfil$ = createEffect(() => {
    const selectCommunityUserProfilState$ = this.store$.select(
      CommunityUserProfilSelectors.selectCommunityUserProfilState
    );
    return this.actions$.pipe(
      ofType(CommunityUserProfilGeneratedActions.deleteOneCommunityUserProfil),
      withLatestFrom(selectCommunityUserProfilState$),
      concatMap(([{ idCommunityUserProfil }, state]) =>
        this.communityUserProfilApiService.deleteCommunityUserProfil(idCommunityUserProfil).pipe(
          mergeMap(_success =>
            getDefaultDeleteCommunityUserProfilActions(
              state.entities[idCommunityUserProfil] as CommunityUserProfilEntityState
            )
          ),
          catchError(error => of(CommunityUserProfilGeneratedActions.communityUserProfilsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCommunityUserProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityUserProfilGeneratedActions.normalizeManyCommunityUserProfilsAfterUpsert),
      concatMap(({ communityUserProfils }) => {
        const actions: Action[] = getActionsToNormalizeCommunityUserProfil(
          communityUserProfils,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CommunityUserProfil] Normalization After Upsert Success')];
      })
    );
  });
}
