/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import dayjs from 'dayjs';

@Component({
  selector: 'up-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateInputComponent)
    }
  ]
})
export class DateInputComponent implements ControlValueAccessor {
  @Input() public placeholder: string;
  @Input() public hint: string;
  @Input() public isDisabled: boolean;
  @Input() public disabledCross = false;
  @Input() public autocomplete = 'off';
  @Input() public isOverMode = false;
  @Input() public dataCy: string;

  @Output() public clearEvent = new EventEmitter<void>();

  @ViewChild('input') public input: ElementRef<any>;

  public value: Date;
  public valueAsString = '';
  public isOver: boolean;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange = (_value: Date) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched = () => {};

  public writeValue(value: Date): void {
    this.isOver = false;

    this.value = value;
    this.valueAsString = this.value ? dayjs(this.value).format('YYYY-MM-DD') : null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public registerOnChange(fn: (_value: Date) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onSelectionChange($event: Event): void {
    const newDate = ($event.target as HTMLInputElement).valueAsDate;

    // If date year has less than 4 figures, return
    if (!newDate || !/[0-9]{4}/.test(newDate.getFullYear().toString())) {
      return;
    }

    this.writeValue(newDate);
    this.onChange(newDate);
  }

  public onKeypress($event: KeyboardEvent): void {
    if ($event.key === 'Enter') {
      this.input.nativeElement.blur();
    }
  }

  public onClear(): void {
    this.writeValue(null);
    this.onChange(null);
    this.clearEvent.next();
  }
}
